/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    putEstadoOrientacion
} from "./services"
import Label from "../../../../components/common/Label";
import Spinner from "../../../../components/common/Spinner";
import Toggle from "../../../../components/common/Toggle";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({

});

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);

    return (
        <Formik
            initialValues={{
                estado: props?.registro?.estado,
                usuario: usuario
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putEstadoOrientacion(props.registro.id, values, token)
                .then((data) => {
                    if (data?.status === 200 || data?.status === 201) {
                        toast.success("registro actualizado con éxito");
                        props.setReFetch(true);
                        setTimeout(() => {
                            props.setReFetch(false);
                        }, 400);
                        props.setCambiarEstado(false);
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400 || error.response.status === 401) {

                        toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                    }
                });
            }}
        >
            {({ values, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 flex flex-col items-center justify-center">
                        <Label text={`${values.estado ? 'Activa' : 'Inactiva'}`} />
                        <Toggle enabled={values.estado} name="estado" />
                    </div>
                    <ButtonSiavi
                                type="button"
                                disabled={isSubmitting}
                                text={"Actualizar"}
                                onClick={handleSubmit}
                            />
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};
