/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { PlusCircleIcon, XCircleIcon, ChatIcon } from "@heroicons/react/outline";
import { AudienciaIcon } from "../../../../../helpers/icons";
import { SelectSiavi } from "../../../../../components/common/Select";
import { GestionNombramiento } from "./gestionNombramiento";
import { GestionAccionesRecursos } from "../../../casos/editar/atencion_victimologica/trabajo_social/gestionAccionRecursos";
import { FormDetalleAudiencia } from "./detalleAudiencia";
import { clases } from "../../../../../helpers/clases";
import { MensajeriaSMS } from "../../../../../components/EnvioMensajes";
import {
    deleteAcciones,
    postAcciones,
    putAcciones,
    getEstadoProceso,
    getDeptosGestion,
    getModalidades,
    getInstitucionGestion,
    getProfesionales,
    getReferenciaCreada,
    getCatalogoSeguimientosAudiencia,
} from "../../../casos/editar/atencion_victimologica/trabajo_social/services";
import {
    getAudiencias,
    getQuerellantes
} from "./services";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import Spinner from "../../../../../components/common/Spinner";
import CustomTable from "../../../../../components/common/Table";
import ModalGenerico from "../../../../../components/common/Modal";
import FormEliminar from "../../../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import makeAnimated from "react-select/animated";
import Select from "react-select";
import * as Yup from "yup";


const validaciones = Yup.object().shape({
    tipo_audiencia: Yup.string().required("tipo audiencia es requerida"),
    fecha_hora_planificada_inicia: Yup.string().required("Fecha Planificada es requerida"),
    departamento: Yup.string().required("Departamento es requerido"),
    modalidad: Yup.string().required("Modalidad es requerida"),
    //    gestion_institucion: Yup.string().required("Órgano Jurisdiccional es requerido")
});

const tableColumns = [
    { title: "No.", value: "id", tipo: "text" },
    { title: "Tipo Audiencia", value: "tipo_audiencia.nombre", tipo: "text" },
    { title: "Fecha Planificada", value: "fecha_hora_planificada_inicia", tipo: "datetime" },
    {
        title: "Completada",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    /*{
        title: "Profesional Crea",
        value: null,
        values: [
            "profesional_crea.first_name",
            "profesional_crea.last_name"
        ],
        tipo: "text"
    },*/
    {
        title: "Profesional Asignado",
        value: null,
        values: [
            "profesional_asignado.first_name",
            "profesional_asignado.last_name"
        ],
        tipo: "text"
    },
    { title: "Fecha Completado", value: "fecha_completado", tipo: "text" },
    {
        title: "Profesional Realiza",
        value: null,
        values: [
            "profesional_realiza.first_name",
            "profesional_realiza.last_name"
        ],
        tipo: "text"
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

export const AgendaDeAudiencias = (props) => {
    const caso = props.infoExpediente.caso;
    const servicio = props.infoExpediente.servicio.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const animatedComponents = makeAnimated();
    const [verDetalle, setDetalle] = useState(false);
    const [accionesDetalle, setAccionesDetalle] = useState({});
    const idExpediente = props?.infoExpediente?.id;
    const profExpediente=props?.infoExpediente.profesional_asignado;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAcciones, setIdAcciones] = useState("");
    const [acciones, setAcciones] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [tipo_audiencias, setTipoAudiencias] = useState([]);
    const [deptos, setDeptos] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [seguimientoAudiencia, setSeguimientoAudiencia] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [institucionesAgregados, setInstitucionesAgregados] = useState([]);
    const [profesionalesAsigna, setProfesionalesAsigna] = useState([]);
    const [referencia, setReferencia] = useState([]);
    const [agregar, setAgregar] = useState(false);
    const [agregarNombramiento, setAgregarNombramiento] = useState(false);
    const [querellantes, setQuerellantes] = useState(null);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [enviarSms, setEnviarSms] = useState(false);
    const optionsInstituciones = [];
 const nvl = null;


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAL";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });

        //        retorno = true;  // mm comentar para que funcione
        //        retorno = true;  // mm comentar para que funcione
        return retorno;

    }


    let tipoSeguimiento = "";

    useEffect(() => {
        getEstadoProceso(token).then((data) => {
            setTipoAudiencias(data);
        });
    }, [token]);

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data)
        });
    }, [token]);

    useEffect(() => {
        getCatalogoSeguimientosAudiencia(token).then((data) => {
            setSeguimientoAudiencia(data)
        });
    }, [token]);

    useEffect(() => {
        getInstitucionGestion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    useEffect(() => {
        getProfesionales(props.infoExpediente.servicio.id, token).then((data) => {
            setProfesionalesAsigna(data)
        });
    }, [token]);

    useEffect(() => {
        getReferenciaCreada(caso, servicio, token).then((data) => {
            setReferencia(data)
        });
    }, [token, caso, servicio]);

    useEffect(() => {
        getAudiencias(urlFetch, idExpediente, token).then((acciones) => {
            setDataTable(acciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, reFetch, props.addEstadoProceso, urlFetch]);

    useEffect(() => {

        getQuerellantes(caso, token).then((data) => {
            if (data.count > 1) {
                setQuerellantes(data);
            } else {
                setQuerellantes(data?.results[0]?.persona?.id)
            }
        });
    }, [token, caso, servicio]);

    const changeSelectInstituciones = (e) => {
        setInstitucionesAgregados(e);
    };

    instituciones?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item?.nombre_fiscalia,
        };
        optionsInstituciones.push(reg);
    });

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "accionesseguimientoscaso";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleAgregar = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregar(true);
        } else {
            toast.error("No es posible asignar recurso, porque ya está completado el seguimiento")
        }
    };

    const handleAgregarNombramiento = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregarNombramiento(true);
        } else {
            toast.error("No es posible agregar nombramientos, porque ya está completado el seguimiento")
        }
    };

    const handleVer = (item) => {
        setAccionesDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdAcciones(id);
        setEliminar(true);
    };

    const handleEditar = (acciones) => {
        if (acciones.completada === false) {
            setEdit(true);
            setAcciones(acciones);
        } else {
            toast.error("No es posible editar, porque ya está completado el seguimiento")
        }
    };

    

    seguimientoAudiencia?.results?.forEach(element => {
        if (element.es_audiencia === true && element.estado === true) {
            tipoSeguimiento = element.id;
        } else {
            tipoSeguimiento = "";
        }
    });

    const opcion = (registro) => {
        return (
            <span>
                {(CheckPermiso('ResultadoAudiencia') === true) &&
                    <button
                        type="button"
                        className={`hover:bg-green-200 items-center p-1 border border-transparent 
                    rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                        onClick={() => {
                            if (registro?.es_completada) {
                                toast.error("Este registro ya esta marcado como completado")
                            }
                            else {
                                if (profExpediente == nvl) {
                                    toast.error("Este expediente no tiene profesional asignado")
                                }
                                else {
                                if ((registro?.profesional_asignado?.id == `${usuario?.id}`)) 
                                 {
                                    //if ((registro?.expediente.profesional_asignado == `${usuario?.id}`)) {

                        //            { toast.error(idExpediente +'.'+profExpediente +'.' //?.accion_seguimiento?.expediente?.profesional_asignado
                               //     )                                 }
                                    props.setRegistro(registro);
                                    props.setAddEstadoProceso(true);
                                }
                                else {
                                    toast.error("No puede registrar el resultado de la audiencia, porque usted no es el profesional asignado a la audiencia. ")
                                }
                            }

                        }}}
                    >
                        <PlusCircleIcon className="h-6 w-6 text-green-600" />
                        <span className="tooltiptext">resultado audiencia</span>
                    </button>
                }
                {
                    (CheckPermiso('AudienciaSMS') === true) &&
                    <button
                        type="button"
                        className={clases.addIcon}
                        onClick={() => {
                            setAcciones(registro);
                            setEnviarSms(true);
                        }}
                    >
                        <ChatIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">enviar sms</span>
                    </button>
                }
            </span >
        )
    }

    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validaciones}
                initialValues={{
                    seguimiento: "Audiencia",
                    es_nna: isEdit ? acciones?.es_nna : false,
                    tipo_audiencia: isEdit ? acciones?.tipo_audiencia?.id : "",
                    fecha_accion: isEdit ? acciones?.fecha_accion : "",
                    fecha_asigna: isEdit ? acciones?.fecha_asigna : "",
                    requiere_autorizacion: false,
                    fecha_autoriza: isEdit ? acciones?.fecha_autoriza : "",
                    autorizado: isEdit ? acciones?.autorizado : false,
                    fecha_completado: isEdit ? acciones?.fecha_completado : "",
                    estado: isEdit ? acciones?.estado : true,
                    completada: isEdit ? acciones?.completada : false,
                    es_accion: false,
                    es_seguimiento: false,
                    es_audiencia: true,
                    requiere_nombramiento: false,
                    observaciones: isEdit ? acciones?.observaciones : "",
                    detalle_lugar: isEdit ? acciones?.detalle_lugar : "",
                    especificaciones_modalidad: isEdit ? acciones?.especificaciones_modalidad : "",
                    tipo: isEdit ? acciones?.tipo : "",
                    caso: props.infoExpediente.caso,
                    expediente: idExpediente,
                    persona: props.infoExpediente.caso_persona.persona.id,
                    victima: (props.infoExpediente.caso_persona.victima === true) ? props.infoExpediente.caso_persona.persona.id : null,
                    querellante: querellantes,
                    modalidad: isEdit ? acciones?.modalidad?.id : "",
                    referencia: referencia?.results?.id,
                    departamento: isEdit ? acciones?.departamento?.id : "",
                    municipio: isEdit ? acciones?.municipio?.id : "",
                    gestion_institucion: isEdit ? acciones?.gestion_institucion?.id : "",
                    accion_seguimiento: tipoSeguimiento,
                    //profesional_asignado: "",
                    profesional_autoriza: isEdit ? acciones?.profesional_autoriza?.id : "",
                    profesional_realiza: isEdit ? acciones?.profesional_realiza?.id : "",
                    nombramiento: null,
                    profesional_crea: idUser,
                    sede_accion: sedeId,
                    fecha_hora_planificada_inicia: isEdit ? acciones?.fecha_hora_planificada_inicia : "",
                    fecha_hora_planificada_fin: isEdit ? acciones?.fecha_hora_planificada_fin : "",
                    accion_planificada: true,
                    asignar_accion: isEdit ? acciones?.asignar_accion : false,
                    habilita_planificacion: isEdit ? acciones?.habilita_planificacion : false,
                }}
                onSubmit={async (values, { resetForm }) => {
                    //     if (values.gestion_institucion==="") {
                    //       toast.error("Debe ingresar un órgano Jurisdiccional")
                    //  } else {

                    if (isEdit) {
                        await putAcciones(acciones.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({});
                                    setReFetch(false);
                                    setEdit(false);

                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    } else {
                        if (querellantes?.results?.length > 1) {

                        } else {
                            values.querellante = querellantes;
                        }
                        await postAcciones(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro creado con éxito...!');
                                    resetForm({});
                                    //data.gestion_institucion.id="";
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                        //}
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="datetime-local"
                                        name="fecha_hora_planificada_inicia"
                                        id="fecha_hora_planificada_inicia"
                                        value={values.fecha_hora_planificada_inicia}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Fecha Planificada"
                                    />
                                </div>)}
                            {values.asignar_accion && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Profesional Asignado"
                                        name="profesional_asignado"
                                        value={values.profesional_asignado}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir profesional"
                                        list={
                                            profesionalesAsigna?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.first_name + index} value={item.id}>
                                                        {item.first_name} {item.last_name}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>)}

                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo Audiencia"
                                    name="tipo_audiencia"
                                    value={values.tipo_audiencia}
                                    onChange={handleChange}
                                    validacion={true}
                                    valueInit=""
                                    selectInit="Elegir tipo audiencia"
                                    list={
                                        tipo_audiencias?.results?.filter(x => (x.etapa_proceso === Number(props?.infoExpediente?.ultima_etapa_procesal?.etapa_procesal?.id))).map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.etapa_proceso_detalle.nombre} - {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    validacion={true}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    list={
                                        modalidades?.results?.map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Departamento"
                                    name="departamento"
                                    value={values.departamento}
                                    validacion={true}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <h1 className="mb-1 text-sm text-maivi-primaryFont mt-2">Órgano Jurisdiccional</h1>
                                <Select
                                    placeholder="Seleccionar organo jurisdiccional"
                                    components={animatedComponents}
                                    name="gestion_institucion"
                                    className="basic-multi-select text-xs text-maivi-primaryFont"
                                    classNamePrefix="seleccione"
                                    options={optionsInstituciones}
                                    onChange={changeSelectInstituciones}
                                //validacion={true}
                                //valueInit=""

                                />
                            </div>
                            {
                                (querellantes?.results?.length > 1) &&
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Querellante"
                                        name="querellante"
                                        value={values.querellante}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir querellante"
                                        list={
                                            querellantes?.results?.map((item, index) => {
                                                return (
                                                    <option key={item?.persona?.primer_nombre + index} value={item?.persona.id}>
                                                        {item?.persona?.primer_nombre}  {item?.persona?.primer_apellido}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>
                            }
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4">

                                    {(CheckPermiso('GuardarAudiencia') === true) &&

                                        //(props.expediente?.profesional_asignado === usuario?.id)&&
                                        <ButtonSiavi
                                            type="submit"
                                            disabled={isSubmitting}
                                            text={isEdit ? "Actualizar" : "Guardar"}
                                            onClick={() => {
                                              //  if ((props.expediente?.profesional_asignado == `${usuario?.id}`)) {
                                                    values.gestion_institucion = institucionesAgregados.value;
                                                    handleSubmit();
                                             //   }



                                            }}
                                        />
                                    
 }

                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    //showEliminar={true}
                    showEliminar={(CheckPermiso('EliminarAudiencia') === true)}
                    showVer={true}
                    //showEditar={true}
                    showEditar={(CheckPermiso('EditarAudiencia') === true)}
                    agregar={false}
                    handleAgregar={handleAgregar}
                    agregar1="Asignar Recurso"
                    agregarSegundo={false}
                    handleSegundoAgregar={handleAgregarNombramiento}
                    agregar2="Nombramiento"
                    addAccion={true}
                    opciones={opcion}
                    pagination={true}
                    fetchPage={setUrlFech}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de audiencia"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <AudienciaIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleAudiencia detalle={accionesDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Acciones Recursos de ${acciones.seguimiento}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        children={
                            <GestionAccionesRecursos accion={acciones} />
                        }
                    />
                )}
                {agregarNombramiento && (
                    <ModalAmplio
                        title={`Gestión de Nombramiento de ${acciones.seguimiento}`}
                        open={agregarNombramiento}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarNombramiento(false);
                        }}
                        children={
                            <GestionNombramiento accion={acciones} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteAcciones(idAcciones, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}

                {enviarSms && (
                    <ModalAmplio
                        title={`Enviar sms`}
                        open={enviarSms}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEnviarSms(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ChatIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <MensajeriaSMS
                                por_accion={true}
                                registro={acciones}
                                token={token}
                                editable={true}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
