import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import {
  //getDeptosVictima,
  getEscolaridadVictima,
  getIdiomaVictima,
  //getMunicipioVictima,
  getOrigenEtnicoVictima,
  getPaisVictima,
  getReligionVictima,
  getOrientacionSexualVictima,
  getTipoProfesion,
  getEstadoCivilVictima,
  getIdiomaMaya,
  getIdentidadGeneroVictima,
  getSituacionLaboralVictima,

} from "../victima/services";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../../../components/common/InputText";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";

// const useFetchDepartamento = (token) => {
//   const [state, setState] = useState({
//     deptos: [],
//   });

//   useEffect(() => {
//     getDeptosVictima(token).then((deptos) => {
//       setState({
//         deptos: deptos,
//       });
//     });
//   }, [token]);

//   return state;
// };

// const useFetchMunicipio = (token) => {
//   const [state, setState] = useState({
//     municipios: [],
//   });

//   useEffect(() => {
//     getMunicipioVictima(token).then((municipios) => {
//       setState({
//         municipios: municipios,
//       });
//     });
//   }, [token]);

//   return state;
// };

const useFetchEscolaridad = (token) => {
  const [state, setState] = useState({
    escolaridad: [],
  });

  useEffect(() => {
    getEscolaridadVictima(token).then((escolaridad) => {
      setState({
        escolaridad: escolaridad,
      });
    });
  }, [token]);

  return state;
};

const useFetchOrigenEtnico = (token) => {
  const [state, setState] = useState({
    origenetnico: [],
  });

  useEffect(() => {
    getOrigenEtnicoVictima(token).then((origenetnico) => {
      setState({
        origenetnico: origenetnico,
      });
    });
  }, [token]);

  return state;
};

const useFetchIdioma = (token) => {
  const [state, setState] = useState({
    idiomas: [],
  });

  useEffect(() => {
    getIdiomaVictima(token).then((idiomas) => {
      setState({
        idiomas: idiomas,
      });
    });
  }, [token]);

  return state;
};

const useFetchReligion = (token) => {
  const [state, setState] = useState({
    religiones: [],
  });

  useEffect(() => {
    getReligionVictima(token).then((religiones) => {
      setState({
        religiones: religiones,
      });
    });
  }, [token]);

  return state;
};

const useFetchPais = (token) => {
  const [state, setState] = useState({
    paises: [],
  });

  useEffect(() => {
    getPaisVictima(token).then((paises) => {
      setState({
        paises: paises,
      });
    });
  }, [token]);

  return state;
};

const useFetchOrientacionSexual = (token) => {
  const [state, setState] = useState({
    orientacionsexual: [],
  });

  useEffect(() => {
    getOrientacionSexualVictima(token).then((orientacionsexual) => {
      setState({
        orientacionsexual: orientacionsexual,
      });
    });
  }, [token]);

  return state;
};

const useFetchProfesiones = (token) => {
  const [state, setState] = useState({
    profesiones: [],
  });

  useEffect(() => {
    getTipoProfesion(token).then((data) => {
      setState({
        profesiones: data,
      });
    });
  }, [token]);

  return state;
};

const useFetchEstadoCivil = (token) => {
  const [state, setState] = useState({
    estadocivil: [],
  });

  useEffect(() => {
    getEstadoCivilVictima(token).then((estadocivil) => {
      setState({
        estadocivil: estadocivil,
      });
    });
  }, [token]);

  return state;
};

const useFetchIdiomaMaya = (token) => {
  const [state, setState] = useState({
    idiomasmayas: [],
  });

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setState({
        idiomasmayas: data,
      });
    });
  }, [token]);

  return state;
};

const useFetchOrigenEtnicoMaya = (token) => {
  const [state, setState] = useState({
    origenetnicomaya: [],
  });

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setState({
        origenetnicomaya: data,
      });
    });
  }, [token]);

  return state;
};

const useFetchIdentidadGenero = (token) => {
  const [state, setState] = useState({
    identidadgenero: [],
  });

  useEffect(() => {
    getIdentidadGeneroVictima(token).then((identidadgenero) => {
      setState({
        identidadgenero: identidadgenero,
      });
    });
  }, [token]);

  return state;
};

const useFetchSituacionLaboral = (token) => {
  const [state, setState] = useState({
    situacionlaboral: [],
  });

  useEffect(() => {
    getSituacionLaboralVictima(token).then((situacionlaboral) => {
      setState({
        situacionlaboral: situacionlaboral,
      });
    });
  }, [token]);

  return state;
};

export const DatosDemograficos = (props) => {
  console.log(props)
  const token = useSelector((state) => state.loginReducer.data.token);
  //const { deptos } = useFetchDepartamento(token);
  //const { municipios } = useFetchMunicipio(token);
  const { escolaridad } = useFetchEscolaridad(token);
  const { origenetnico } = useFetchOrigenEtnico(token);
  const { idiomas } = useFetchIdioma(token);
  const { religiones } = useFetchReligion(token);
  const { paises } = useFetchPais(token);
  const { orientacionsexual } = useFetchOrientacionSexual(token);
  const { profesiones } = useFetchProfesiones(token);
  const { estadocivil } = useFetchEstadoCivil(token);
  const { idiomasmayas } = useFetchIdiomaMaya(token);
  const { origenetnicomaya } = useFetchOrigenEtnicoMaya(token);
  const { identidadgenero } = useFetchIdentidadGenero(token);
  const { situacionlaboral } = useFetchSituacionLaboral(token);
  const origenEtnicoMaya = props?.configuracion?.habilita_origen_etnico?.nombre;
  const idiomaMaya = props?.configuracion?.habilita_idioma_maya?.nombre;
  let origenEtnicoMayaTrue = false;
  //let idiomaMayaTrue = false;

  if (origenEtnicoMaya === "MAYA") {
    origenEtnicoMayaTrue = true
  }

  if (idiomaMaya === "MAYA") {
    //idiomaMayaTrue = true
  }

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Estado civil" />
        <select
          name="estado_civil"
          value={props.values?.estado_civil}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Estado Civil
          </option>
          {estadocivil?.results?.map((item) => {
            return (
              <option key={item.nombre + "ec"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="estado_civil"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Orientación sexual" />
        <select
          name="orientacion_sexual"
          value={props.values?.orientacion_sexual}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Orientación Sexual
          </option>
          {orientacionsexual?.results?.map((item) => {
            return (
              <option key={item.nombre + "os"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="orientacion_sexual"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Identidad de Género" />
        <select
          name="identidad_genero"
          value={props.values.identidad_genero}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir identidad género
          </option>
          {identidadgenero?.results?.map((item) => {
            return (
              <option key={item.nombre + "ig"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="identidad_genero"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      {/*       <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Departamento" />
        <select
          name="departamento"
          value={props.values?.departamento}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Departamento
          </option>
          {deptos?.results?.map((item) => {
            return (
              <option key={item.id + item.nombre + "dp"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="departamento"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Municipio" />
        <select
          name="municipio"
          value={props.values?.municipio}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Municipio
          </option>
          {municipios?.results?.filter(x => x.departamento === Number(props.values.departamento)).map((item) => {
            return (
              <option key={item.nombre + "mn" + item.id} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="municipio"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div> */}
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <Label text="Lee y escribe" />
        <select
          name="lee_escribe"
          value={props.values?.lee_escribe}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">Elegir alfabetismo</option>
          <option value="ALFABETO">ALFABETA</option>
          <option value="ANALFABETO">ANALFABETA</option>
        </select>
        <ErrorMessage
          name="lee_escribe"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Escolaridad" />
        <select
          name="escolaridad"
          value={props.values?.escolaridad}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Escolaridad
          </option>
          {escolaridad?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="escolaridad"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Profesión/oficio" />
        <select
          name="profesion"
          value={props.values?.profesion}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir tipo profesion
          </option>
          {profesiones?.results?.map((item) => {
            return (
              <option key={item.nombre + "tp"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="profesion"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Situación Laboral" />
        <select
          name="situacion_laboral"
          value={props.values.situacion_laboral}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir situación laboral
          </option>
          {situacionlaboral?.results?.map((item) => {
            return (
              <option key={item.nombre + "sl"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="situacion_laboral"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Origen Étnico" />
        <select
          name="origen_etnico"
          value={props.values?.origen_etnico}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir origen etnico
          </option>
          {origenetnico?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="origen_etnico"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      {origenEtnicoMayaTrue && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <Label text="Origen Étnico Maya" />
          <select
            name="origen_etnico_maya"
            value={props.values?.origen_etnico_maya}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="">
              Elegir origen etnico maya
            </option>
            {origenetnicomaya?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="origen_etnico_maya"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>)}
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Idioma" />
        <select
          name="idioma"
          value={props.values?.idioma}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir idioma
          </option>
          {idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="idioma"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <Label text="Idioma maya" />
          <select
            name="idioma_maya"
            value={props.values?.idioma_maya}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="">
              Elegir idioma maya
            </option>
            {idiomasmayas?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="idioma_maya"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Idioma de preferencia" />
        <select
          name="idioma_preferencia"
          value={props.values.idioma_preferencia}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir Idioma de preferencia
          </option>
          {idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="idioma_preferencia"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Religión" />
        <select
          name="religion"
          value={props.values?.religion}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">
            Elegir Religión
          </option>
          {religiones?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="religion"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="En contexto o tránsito migratorio" />
        <Toggle enabled={props.values.en_migracion} name="en_migracion" />
      </div>
      {props?.values?.en_migracion === true && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <Label text="En migración - país origen" />
          <select
            name="pais_origen"
            value={props.values?.pais_origen}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="">
              Elegir País Origen
            </option>
            {paises?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="pais_origen"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>
      )}

      {props?.values?.en_migracion === true && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <Label text="En migración - país destino" />
          <select
            name="pais_destino"
            value={props.values?.pais_destino}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="">
              Elegir País Destino
            </option>
            {paises?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="pais_destino"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>
      )}
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="correo_electronico"
          value={props.values.correo_electronico}
          onChange={props.handleChange}
          label="Correo Electrónico"
        />
      </div>
    </div>
  );
};
