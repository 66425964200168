import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { getSedes } from "./services";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../../components/common/InputText";
import { getProfesionales } from "./services";
import { SelectSiavi } from "../../../../components/common/Select"
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchProfesionales = () => {
        const [state, setState] = useState({
          profesionales: [],
        });
    
        useEffect(() => {
          getProfesionales(token).then((data) => {
            setState({
              profesionales: data,
            });
          });
        }, []);
    
        return state;
    };

    const useFetchSede = (token) => {
        const [state, setState] = useState({
            sedes: [],
        });

        useEffect(() => {
            getSedes(token).then((sedes) => {
                setState({
                    sedes: sedes,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        caso_admitido: "",
        sede: "",
        fecha_desde:"",
        fecha_hasta:"",
        profesional:""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const { sedes } = useFetchSede(token);
    const { profesionales } = useFetchProfesionales();
    const animatedComponents = makeAnimated();
    const optionsProfesionales = [];

    profesionales?.results?.forEach((item) => {
        let reg = {
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        };
        optionsProfesionales.push(reg);
    });

    const changeSelectProfesional = (e) => {
        setState({
            ...state,
            profesional: e.value,
        });
    };

    return (
        <div className="grid grid-cols-12 mx-5 mb-8">
            <div className="col-span-12 mt-1">
                <Label text="Profesional"/>
                    <Select
                        placeholder="Seleccionar"
                        components={animatedComponents}
                        name="profesional"
                        className={`basic-multi-select text-sm`}
                        classNamePrefix="seleccione"
                        options={optionsProfesionales}
                        onChange={changeSelectProfesional}
                    /> 
                </div>
            <div className="col-span-12 mt-3">
                <div className="mb-2">
                    <SelectSiavi
                        name="sede"
                        value={state.sede}
                        label="Sede"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir sede"
                        list={
                            sedes?.results?.map((item,index) => {
                                return (
                                    <option key={item.sede+index} value={item.id}>
                                        {item.sede}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <SelectSiavi 
                    label="Caso Admitido"
                    name="caso_admitido"
                    value={state.caso_admitido}
                    onChange={onChange}
                    valueInit=""
                    selectInit="Elegir opción"
                    list={[
                        <option value="caso_admitido=true">Sí</option>,
                        <option value="caso_admitido=false">No</option>,
                        <option value="caso_admitido__isnull=true">Pendientes</option>
                    ]}
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4 mx-1">
                <InputSiavi 
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha inicio"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4">
                <InputSiavi 
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha fin"
                />
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `sede=${state.sede}&${state.caso_admitido}&fecha__gte=${state.fecha_desde}&fecha__lte=${state.fecha_hasta}&profesional=${state.profesional}`;
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
