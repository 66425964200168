import { React, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { getCaso,getOrientacion } from "./services";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../../../app/redux/store/store";
import { ChangeCasoTab } from "../../../../app/redux/actions/casoTabs";
import { FormGenerales } from "./generales/form";
import { FormAsistencia } from "./asistencia/form";
import { FormAtencionVictimologica } from "./atencion_victimologica/form";
import { FormVictimas } from "../../orientacion/editar/victimas/form";
import { FormRiesgos } from "../../orientacion/editar/riesgos/form";
import { FormQuerellantes } from "../../orientacion/editar/querellantes/form";
import { FormEvaluacion } from "../../orientacion/editar/evaluacion/form";
import { FormDireccion } from "./direccion";
import { ReparacionesDignas } from "./reparacion_digna";
import { PrinterIcon } from "@heroicons/react/solid";
import {
  getPersonasCasoDetails,
  getExpedienteSindicados,
  getExpedienteSindicadoDelitos,
  getCasoEvaluacionRiesgo,
  getCasoHechos,
  getCasoHechosTemporalidad,
  postValidarFicha,
  getDiligenciasExpediente
} from "../../orientacion/editar/services";
import PdfViewer from "../../../../components/common/PdfViewer";
import Modal from "../../../../components/common/ModalAmplio";
import Return from "../../../../components/common/ButtonSalir";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import toast from "react-hot-toast";
import { getCausasPenales } from "./generales/services";

export const Editar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const menuCasos = useSelector((state) => state.ChangeCasoTab);
  const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user.username);
  const { id } = useParams();
  const [loading, setLoanding] = useState(true);
  const [orientacion,setOrientacion] = useState({});
  const [fichaData, setFichaData] = useState({});
  const [cargando, setCargando] = useState(false);
  const [ficha, setFicha] = useState(false);
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);

  const pages = [
    {
      name: "Expedientes",
      href: "/funcionalidades-generales/casos",
      current: false,
    },
    { name: "Editar", href: "#", current: true },
  ];

  const permisoView = 'Expedientes';

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro.forEach(menu => {
      if (menu.text === permisoView) {
        menu.opciones.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //retorno = true; //quitar al corregir
    return retorno;
  }

  const useFetchCaso = () => {
    const [state, setState] = useState({
      caso: {},
    });

    useEffect( () => {
       getCaso(id,token).then(async (data) => {
        await getOrientacion(data.orientacion,token).then((data2) =>{
          setState({
            ...state,
            caso: data,
          });
          setOrientacion(data2);
          setLoanding(false);
        })

       });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return state;
  };

  const { caso } = useFetchCaso();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getIndexTab = () => {
    let pocision = 0;

    menuCasos.tabs.forEach((element,index) => {
      if(element.active){
        pocision = index;
      }
    });

    return pocision;
  };

  const changeTab = (indexTab) => {
    const defaultTab = menuCasos.tabs;

    defaultTab.forEach((element,index) => {
      if(index === indexTab){
        element.active = true;
      } else {
        element.active =false;
      }
    });

    store.dispatch(ChangeCasoTab(defaultTab));
  };

  const printFicha = async () => {
    let personas = [];

    orientacion.caso.personas.forEach((p) => {
      personas.push(p.persona.id);
    });

    await postValidarFicha(orientacion.id, token).then(async (data) => {
      if (data?.status >= 200 && data?.status <= 210) {
        setCargando(true);
        setFicha(true);
        await getPersonasCasoDetails(personas, token).then(async (data) => {
          await getExpedienteSindicados(orientacion.caso.id, token).then(
            async (sindicadosList) => {
              await getExpedienteSindicadoDelitos(orientacion.caso.id, token).then(
                async (delitosSindicados) => {
                  await getCasoEvaluacionRiesgo(orientacion.caso.id, token).then(
                    async (evaluacionRiesgos) => {
                      await getCasoHechos(orientacion.caso.id, token).then(
                        async (casoHechos) => {
                          await getCasoHechosTemporalidad(
                            orientacion.caso.id,
                            token
                          ).then(async (casoHechosTemp) => {

                            let expedientes = orientacion.caso.expedientes;
                            let diligenciasUrgentes = [];

                            await expedientes.forEach(async (exp, index) => {
                              await getDiligenciasExpediente(exp.id, token).then(async diligencias => {
                                diligencias.results.forEach(dil => {
                                  diligenciasUrgentes.push(dil);
                                })

                                await getCausasPenales(orientacion.caso.id, token).then( causaPenal => {
                                  if (index === (expedientes.length - 1)) {
                                    setFichaData({
                                      orientacion: orientacion,
                                      causaPenal: causaPenal.results,
                                      personas: data.personas,
                                      sindicados: sindicadosList?.results,
                                      delitos: delitosSindicados?.results,
                                      riesgos: evaluacionRiesgos?.results,
                                      hechos: casoHechos?.results,
                                      hechosTemp: casoHechosTemp?.results,
                                      usuario: usuarioLogin,
                                      diligencias: diligenciasUrgentes,
                                      codigoIso: "DAL-DSV-FOR-3"
                                    });
                                    setCargando(false);
                                  }
                                })
                              });
                            });
                          });
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        });
      }
    }).catch(function (error) {
      if (
        error.response.status >= 400 &&
        error.response.status <= 450
      ) {
        toast.error(error.response.data.message);
      } else if (error.response.status >= 500) {
        toast.error("Error interno comuniquese con el administrador");
      }
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="mx-2 -mt-36 mb-10">
      <div className="shadow sm:rounded-md px-2 xl:px-6 lg:px-4  py-6 bg-maivi-secondaryWhite">
        <div className="flex flex-col justify-center items-center md:justify-between lg:justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-2">
          <BreadCrumbTwo navegacion={pages} />
           <div className="mt-5 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  flex flex-row justify-center items-center">
            <h1 className="mr-5 text-sm text-maivi-primaryFont">
              {orientacion?.caso?.numero_caso}{" "}
              {orientacion?.caso?.victima?.primer_nombre}{" "}
              {orientacion?.caso?.victima?.segundo_nombre}{" "}
              {orientacion?.caso?.victima?.primer_apellido}{" "}
              {orientacion?.caso?.victima?.segundo_apellido}
            </h1>
            <PrinterIcon
              className="h-6 w-6 text-maivi-primaryFont cursor-pointer"
              onClick={() => {
                printFicha();
              }}
            />
            <Return ruta="/funcionalidades-generales/casos" />
          </div>
        </div>
        <Tab.Group defaultIndex={getIndexTab()} onChange={changeTab}  >
          <Tab.List className="grid grid-cols-3 xl:grid-cols-9 lg:grid-cols-5 md:grid-cols-4 bg-blue-50 rounded-md p-1">
            {menuCasos.tabs.map((item) => {
              let permiso = ''
              if (item.index === 0) { permiso = 'Generales'; }
              if (item.index === 1) { permiso = 'AsistenciaLegal'; }
              if (item.index === 2) { permiso = 'Victimas'; }
              if (item.index === 3) { permiso = 'Querellante'; }
              if (item.index === 4) { permiso = 'atencionvictimologica'; }
              if (item.index === 5) { permiso = 'Riesgos'; }
              if (item.index === 6) { permiso = 'evaluaciondiagnostica'; }
              if (item.index === 7) { permiso = 'direccion'; }

              if(!CheckPermiso(permiso)){ return ''; }
              return (
                <Tab
                  key={item.name}
                  disabled={item.block}
                  className={({ selected }) =>
                    classNames(
                      "p-1 text-xs font-medium text-maivi-primaryFont rounded-md",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                      selected
                        ? "bg-white shadow"
                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                    )
                  }
                >
                  {item.name}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <FormGenerales caso={caso} orientacion={orientacion} />
            </Tab.Panel>
            <Tab.Panel>
             <FormVictimas caso={caso} />
            </Tab.Panel>
            <Tab.Panel>
             <FormQuerellantes caso={caso} />
            </Tab.Panel>
            <Tab.Panel>
              <FormAsistencia caso={caso} />
            </Tab.Panel>
            <Tab.Panel>
              <FormAtencionVictimologica caso={caso} delitos={orientacion.delitos_detalle} />
            </Tab.Panel>
            <Tab.Panel>
             <FormRiesgos caso={caso} />
            </Tab.Panel>
            <Tab.Panel>
              <ReparacionesDignas caso={caso} token={token} />
            </Tab.Panel>
            <Tab.Panel>
              <FormEvaluacion caso={caso} />
            </Tab.Panel>
            <Tab.Panel>
              <FormDireccion caso={caso} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        {ficha && (
          <Modal
            title={`Ficha técnica`}
            open={ficha}
            closeModal={() => { }}
            cerrar={() => {
              setFicha(false);
            }}
            children={
              cargando ?
                <Spinner /> : <PdfViewer
                  data={fichaData}
                  reporte={"ficha-tecnica"}
                  style={{ height: "80vh" }}
                />
            }
          />
        )}
      </div>
    </div>
  );
};
