export const FormDetalle = (props) => {
    return (
        <div className="bg-white border rounded-lg overflow-hidden sm:rounded-lg mt-4">
            <div className="border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.fecha}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Edad</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.edad}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Observaciones</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.observaciones}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">¿Es NNA?</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.es_nna === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Representante Legal</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.representante_legal?.first_name} {props?.detalle?.representante_legal?.last_name}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Delito</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.delito?.nombre}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Profesional</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.profesional?.first_name} {props?.detalle?.profesional?.last_name}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};