/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { deletePregunta } from "./services";
import { XCircleIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import FormEliminar from "../common/FormEliminar";
import { useSelector } from "react-redux";
import { Form } from "./Form";
import CustomTable from "../../../components/common/Table";
import { getGenericCatalogo, getGenericCatalogoSearch } from "../../../helpers/catalogos";

const tableColumns = [
  { title: "id", value: "id", tipo: "text", ordenar: "id" },
  { title: "Pregunta", value: "pregunta", tipo: "text", ordenar: "pregunta" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
  },
];

export const TablePreguntas = () => {
  const [open, setOpen] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [pregunta, setPregunta] = useState("");
  const [idPregunta, setIdPregunta] = useState("");
  const [nombrePregunta, setNombrePregunta] = useState("");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search)

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "preguntas";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order);
  }, [urlFetch, token]);
  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order);
  }, [order]);
  useEffect(() => {
    getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order);
  }, [search]);

  const handleEliminar = (id, nombre) => {
    setIdPregunta(id);
    setNombrePregunta(nombre);
    setEliminar(true);
  };

  const handleEditar = (tipo) => {
    setEdit(true);
    setPregunta(tipo);
    setIdPregunta(tipo.id);
    setOpen(true);
  };

  const pages = [
    { name: "Seguridad", href: "/seguridad", current: false },
    { name: "Preguntas", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <ButtonAdd
            text="Crear"
            type="button"
            onClick={() => {
              setEdit(false);
              setOpen(true);
            }}
          />
        )}
      </div>
      <div className="flex flex-col pt-2">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-maivi-gray-10 sm:rounded-lg">
              <CustomTable
                dataTable={dataTable}
                tableColumns={tableColumns}
                handleEditar={handleEditar}
                handleEliminar={handleEliminar}
                fetchPage={setUrlFech}
                pagination={true}
                permiso={permiso}
                setOrder={setOrder}
                order={order}
                showVer={false}
              />
              {tableLoading && (
                <div className="mt-10 mb-10">
                  <Spinner />
                </div>
              )}
              {open && (
                <ModalGenerico
                  title={isEdit ? "Actualizar pregunta" : "Agregar pregunta"}
                  open={open}
                  closeModal={() => { }}
                  cerrar={() => { setOpen(false) }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <DocumentAddIcon className="h-6 w-6 text-green-600" />
                    </div>
                  }
                  children={
                    <Form
                      value={pregunta}
                      editar={isEdit}
                      id={idPregunta}
                    />
                  }
                />
              )}
              {isEliminar && (
                <ModalGenerico
                  title={`¿Esta seguro de eliminar el registro ${nombrePregunta}?`}
                  open={isEliminar}
                  closeModal={() => { }}
                  cerrar={() => { setEliminar(false) }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                    </div>
                  }
                  children={
                    <FormEliminar
                      aceptar={() => {
                        setLoading(true);
                        deletePregunta(idPregunta, token).then(() => {
                          setLoading(false);
                          window.location = "/seguridad/preguntas";
                        });
                      }}
                      isloading={isloading}
                      cancelar={() => {
                        setEliminar(false);
                      }}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
