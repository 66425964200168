
const {
    REACT_APP_API_URL
} = process.env;

export const getNacionalidad = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/nacionalidad/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNacionalidad = async ({nombre,codigo,estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/nacionalidad/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putNacionalidad = async (id, {nombre,codigo,estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/nacionalidad/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNacionalidad = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/nacionalidad/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};