import { React, useEffect, useState } from "react";
import { getVisitas, visitas } from "./services";
import { useSelector } from "react-redux";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { ClipboardCheckIcon, DocumentAddIcon, DocumentTextIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { clases } from "../../../helpers/clases";
import { GestionesEspacioSeguro } from "./gestionEspacioSeguro";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import ModalAmplio from "../../../components/common/ModalAmplio";
import ModalTwo from "../../../components/common/ModalTwo";
import { SeguimientoCaso } from "./SeguimientoCaso";
/* import { FormDetalle } from "../registrarPersona/listar/Detalle";
import ModalTwo from "../../../components/common/ModalTwo"; */

const tableColumns = [
  { title: "Fecha", value: "fecha", tipo: "date", ordenar: "fecha" },
  { title: "Tipo identificación", value: "persona.tipo_documento_detalle.nombre", tipo: "text" },
  { title: "Identificación", value: "persona.no_documento", tipo: "text" },
  {
    title: "Nombre",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Motivo Visita", value: "motivo_visita.nombre", tipo: "text" },
  {
    title: "Profesional que visita",
    value: null,
    values: [
      "profesional_al_que_visita.first_name",
      "profesional_al_que_visita.last_name",
    ],
    tipo: "text"
  },
  {
    title: "Atendido",
    value: "atendido",
    tipo: "boolean",
    text: { true: "Sí", false: "No", null: "No" },
  },
];

export const TableVisitas = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sedeId = useSelector((state) => state.ChangeSede.sedeId);
  const fechaActual = new Date();
  const fechaHoy = fechaActual.getFullYear() + "-" + (fechaActual.getMonth() + 1) + "-" + fechaActual.getDate();
  const [id, setId] = useState("");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [order, setOrder] = useState("-fecha");
  const [filters, setFilters] = useState(`fecha=${fechaHoy}`);
  const search = useSelector((state) => state.inputChange.search);
  const [registro, setRegistro] = useState("");
  const [espacioSeguro, setEspacioSeguro] = useState(false);
  const [seguimientoCaso, setSeguimientoCaso] = useState(false);
  const [refetch,setRefetch] = useState(false);
  const permiso = "visitasedeorientacion";

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "visitas";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const fetchVisitas = () => {
    setTableLoading(true);
    visitas(urlFetch, filters, search, sedeId, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetVisitas = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getVisitas(urlFetch, search, sedeId, order, filters, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchVisitas, [urlFetch, order, filters,refetch, token]);
  useEffect(fetchGetVisitas, [urlFetch, token, sedeId, order, filters, search]);

  const handleAgregar = (item) => {
    setId(item?.persona?.id);
    setAgregar(true);
  };

  const opcion = (registro) => {
    return <span>
      {(CheckPermiso('visitaespacioseguro') === true) &&    //mm permiso agregado        
      <button
        type="button"
        className={clases.addIcon2}
        onClick={() => {
          setEspacioSeguro(true);
          setRegistro(registro);
        }}
      >
        <DocumentTextIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
        <span className="tooltiptext">Espacio Seguro</span>
      </button>
  }
  {(CheckPermiso('visitaseguimientocaso') === true) &&    //mm permiso agregado        
      <button
        type="button"
        className={clases.addIcon2}
        onClick={() => {
          setSeguimientoCaso(true);
          setRegistro(registro);
        }}
      >
        <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
        <span className="tooltiptext">Seguimiento Caso</span>
      </button>
  }
    </span>
  }

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Visitas", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div className="justify-end">
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
              setUrlFech(undefined);
              setFilters(`fecha=${fechaHoy}`);
            }}
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
          />

        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            //agregar={true}
            agregar={(CheckPermiso('visitaorientacion') === true) }   //mm permiso agregado        
            showEliminar={false}
            showEditar={false}
            showVer={false}
            order={order}
            setOrder={setOrder}
            handleAgregar={handleAgregar}
            addAccion={true}
            opciones={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {agregar && (
        <ModalGenerico
          title={"Desea crear una orientación?"}
          open={agregar}
          closeModal={() => { }}
          cerrar={() => {
            setAgregar(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentAddIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <div className="py-3 text-center">
              <button
                className="inline-flex w-5/12 m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
              rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                onClick={() => {
                  setAgregar(false);
                }}
              >
                No
              </button>
              <Link to={`/funcionalidades-generales/crear-orientacion/${id}`}>
                <button
                  className="inline-flex w-5/12 m-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
              rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                >
                  Si
                </button>
              </Link>
            </div>
          }
        />
      )}

      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              setUrlFech={setUrlFech}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
      {espacioSeguro && (
        <ModalAmplio
          title={`Espacio Seguro de ${registro?.persona?.primer_nombre} ${registro?.persona?.primer_apellido}`}
          open={espacioSeguro}
          closeModal={() => { }}
          cerrar={() => {
            setEspacioSeguro(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <GestionesEspacioSeguro registro={registro} setRefetch={setRefetch}/>
          }
        />
      )}
      {seguimientoCaso && (
        <ModalTwo
          title={"Seguimiento al caso"}
          open={seguimientoCaso}
          closeModal={() => { }}
          cerrar={() => {
            setSeguimientoCaso(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <SeguimientoCaso detalle={registro} Persona={registro.persona} />
          }
        />
      )}
    </div>
  );
};
