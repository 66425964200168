/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { XCircleIcon, LockClosedIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import FormEliminar from "../common/FormEliminar";
import { useSelector } from "react-redux";
import { Form } from "./Form";
import CustomTable from "../../../components/common/Table";
import { deleteRol, getRoles, getPermisos } from './services'
import toast from 'react-hot-toast';
import { FormDetalle } from "./Detalle";
import ModalAmplio from "../../../components/common/ModalAmplio";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  { title: "Rol", value: "nombre", tipo: "text" }
];

export const TableRolesView = () => {
  const [open, setOpen] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [roles, setRoles] = useState("");
  const [idRol, setIdRol] = useState("");
  const [nombreRol, setNombreRol] = useState("");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const [reFetch, setReFetch] = useState(false);
  const [verDetalle, setDetalle] = useState(false);
  const [rolDetalle, setRolDetalle] = useState({});

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "rolvista";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  const fetchRoles = () => {
    getRoles(urlFetch, token).then((roles) => {
      setDataTable(roles);
      setTableLoading(false);
      setEliminar(false);
      setOpen(false);
    });
  };

  useEffect(fetchRoles, [urlFetch, token, reFetch]);

  const handleEliminar = (id, nombre) => {
    setIdRol(id);
    setNombreRol(nombre);
    setEliminar(true);
  };


  const handleVer = (item) => {
    getPermisos(item.id).then(data => {
      setRolDetalle(data)
      setDetalle(true);
      setIdRol(item.id);
    })
  };

  const handleEditar = (tipo) => {
    setEdit(true);
    setRoles(tipo);
    setOpen(true);
  };

  const pages = [
    { name: "Seguridad", href: "/seguridad", current: false },
    { name: "Roles Vista", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <ButtonAdd
              text="Crear"
              type="button"
              onClick={() => {
                setEdit(false);
                setOpen(true);
              }}
            />
          </div>
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleEditar={handleEditar}
            handleEliminar={handleEliminar}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            handleVer={handleVer}
            showVer={true}
            setOrder={setOrder}
            order={order}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}

          {verDetalle && (
            <ModalAmplio
              title={"Permisos"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <LockClosedIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle detalle={rolDetalle} id={idRol} cancelar={setDetalle} />
              }
            />
          )}


          {open && (
            <ModalGenerico
              title={
                isEdit
                  ? "Actualizar área"
                  : "Agregar área"
              }
              open={open}
              closeModal={() => { }}
              cerrar={() => {
                setOpen(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <DocumentAddIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={<Form value={roles} setReFetch={setReFetch} editar={isEdit} />}
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${nombreRol}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteRol(idRol, token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false);
                        toast.error(data.message)
                      } else {
                        setReFetch(true);
                        setLoading(false);
                        setEliminar(false);
                        toast.success('Registro eliminado con éxito...!')
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
