const {
    REACT_APP_API_URL
} = process.env;

export const getClaseDocumentos = async (tipo,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/clasedocumento/?recurso=${tipo}&page_size=100`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getArchivos = async (idRecurso,recurso,urlPage,filtros, token) => {
    try {
        let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/catalogos/colecciondocumentos/?recurso_id=${idRecurso}&clase_documento__recurso=${recurso}&${filtros}`
        : `${urlPage}&recurso_id=${idRecurso}&clase_documento__recurso=${recurso}&${filtros}`;
        
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteArchivo = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/colecciondocumentos/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};