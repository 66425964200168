/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { getNacionalidades, getPaises, getTipoDocumentos } from "./services";
import { useSelector } from "react-redux";
import { PhoneIcon, PaperClipIcon, UserIcon } from "@heroicons/react/outline";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { GestionTelefono } from "../../../../components/gestionTelefono/gestionTelefono";
import { GestionArchivos } from "../../../../components/ArchivosSiavi/archivos";
import { OrientacionesPersona } from "../listar/orientaciones";
import { SelectSiavi } from "../../../../components/common/Select";
import { Link } from "react-router-dom";
import Toggle from "../../../../components/common/Toggle";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import Return from "../../../../components/common/ButtonSalir";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import ModalGenerico from "../../../../components/common/Modal";
import ButtonFiles from "../../../../components/common/ButtonFiles";

export const Actualizar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  hasError,
  success,
  mensaje,
  actualizar,
  idPersona
}) => {
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);
  const token = useSelector((state) => state.loginReducer.data.token);
  const [gestionarTelefonos, setGestionarTelefonos] = useState(false);
  const [agregar, setAgregar] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [paises, setPais] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const Persona = {
    id: idPersona
  };
  useEffect(() => {
    getTipoDocumentos(token).then((data) => {
      setTipoDocumentos(data);
    });
  }, [reFetch]);

  useEffect(() => {
    getPaises(token).then((data) => {
      setPais(data);
    });
  }, [reFetch]);

  useEffect(() => {
    getNacionalidades(token).then((data) => {
      setNacionalidades(data);
    });
  }, [reFetch]);

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales/listar",
      current: false,
    },
    { name: "Registrar", href: "#", current: true },
  ];

  const selectsexo = [
    {
      id: "HOMBRE",
      text: "Hombre"
    },
    {
      id: "MUJER",
      text: "Mujer"
    }
  ]

  const permisoView = "victimas";

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {

        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    retorno = true;
    return retorno;
  }


  return (
    <form autoComplete="off" onChange={customChange(values)}>
      <div className="lg:mx-6 xl:mx-40 -mt-32 mb-10 justify-center items-start shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
        <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-5">
          <BreadCrumbTwo navegacion={pages} />
          <div className="mt-5 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  flex flex-row justify-center items-center">
            <h1 className="mr-5 text-base text-maivi-primaryFont">
              Actualizar persona
            </h1>
            <Return ruta="/funcionalidades-generales/listar" />
          </div>
        </div>
        <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
          <h1 className="text-base text-maivi-primaryFont">Identificación</h1>
          <h1 className="text-base text-maivi-primaryFont">
            Creado en sede: {values.sede}
          </h1>
        </div>
        <Divider />
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12">
            <Label text="Sin Documento" />
            <Toggle enabled={values.sin_documento} name="sin_documento" />
          </div>
          {!values.sin_documento && (
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
              <SelectSiavi
                label="Tipo de documento"
                name="tipoDocumento"
                value={values.tipoDocumento}
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar"
                list={
                  tipoDocumentos?.data?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id} className="text-sm">
                        {item.nombre}
                      </option>
                    );
                  })
                }
              />
            </div>)}
          {!values.sin_documento && (
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
              <Label text="No. documento" />
              <InputSiavi
                type="text"
                name="noDocumento"
                onChange={handleChange}
                value={values.noDocumento}
              />
              <ErrorMessage
                name="noDocumento"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>)}
          <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
            <SelectSiavi
              label="Pais"
              validacion={true}
              name="pais"
              value={values.pais}
              onChange={handleChange}
              valueInit=""
              selectInit="Seleccionar"
              list={
                paises?.data?.results?.map((item) => {
                  return (
                    <option key={item.nombre} value={item.id} className="text-sm">
                      {item.nombre}
                    </option>
                  );
                })
              }
            />
          </div>
          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 text-center flex-col hidden">
            <Label text="Primera visita" />
            <div className="mt-1">
              <Toggle
                enabled={values.primeraVisita}
                name="primeraVisita"
                id="primeraVisita"
              />
            </div>
          </div>
        </div>
        <h1 className="mt-5 mb-1 text-base text-maivi-primaryFont">
          Datos generales
        </h1>
        <Divider />
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="primerNombre"
              value={values.primerNombre}
              onChange={handleChange}
              label="Primer nombre"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <Label text="" />
            <InputSiavi
              type="text"
              name="segundoNombre"
              value={values.segundoNombre}
              onChange={handleChange}
              validacion={true}
              label="Segundo nombre"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <Label text="" />
            <InputSiavi
              type="text"
              name="tercer_nombre"
              value={values.tercer_nombre}
              onChange={handleChange}
              label="Otros nombres"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              name="primerApellido"
              type="text"
              value={values.primerApellido}
              onChange={handleChange}
              label="Primer apellido"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              name="segundoApellido"
              type="text"
              value={values.segundoApellido}
              onChange={handleChange}
              label="Segundo apellido"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="apellidoCasada"
              value={values.apellidoCasada}
              onChange={handleChange}
              label="Apellido de casada"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="date"
              name="fechaNacimiento"
              value={values.fechaNacimiento}
              onChange={handleChange}
              label="Fecha nacimiento"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="number"
              name="edad"
              value={values.edad}
              onChange={handleChange}
              label="Edad"
              validacion={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <SelectSiavi
              label="Sexo"
              validacion={true}
              name="sexo"
              value={values.sexo}
              onChange={handleChange}
              valueInit=""
              selectInit="Seleccionar"
              list={
                selectsexo.map((item) => {
                  return (
                    <option key={item.value} value={item.id} className="text-sm">
                      {item.text}
                    </option>
                  );
                })
              }
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <SelectSiavi
              label="Nacionalidad"
              validacion={true}
              name="nacionalidad"
              value={values.nacionalidad}
              onChange={handleChange}
              valueInit=""
              selectInit="Seleccionar"
              list={
                nacionalidades?.data?.results?.map((item) => {
                  return (
                    <option key={item.nombre} value={item.id} className="text-sm">
                      {item.nombre}
                    </option>
                  );
                })
              }
            />
          </div>
          <div className="col-span-12 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1 flex items-end">
            <ButtonFiles
              type="button"
              onClick={() => {
                setArchivos(true);
              }}
            />
          </div>

          {(CheckPermiso('agregarTelefono') === true) &&
            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 flex items-end" >
              <button
                onClick={() => {
                  setGestionarTelefonos(true);
                }}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4
                   font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none
                   focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
              >
                Gestionar teléfonos
              </button>
            </div>}
          {(actualizar && CheckPermiso('MotivoVisita') === true) &&
            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 flex items-end">
              <button
                onClick={() => {
                  setAgregar(true);
                }}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4
                   font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none
                   focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
              >
                Motivo de visita
              </button>
            </div>
          }

          {CheckPermiso('CrearOrientacion') === true &&
            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6 flex items-end" >
              <Link to={`/funcionalidades-generales/crear-orientacion/${idPersona}`}>
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4
                   font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none
                   focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
                >
                  Crear Orientación
                </button>
              </Link>
            </div>}
          {archivos && (
            <ModalAmplio
              title={`Adjuntar archivos`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setArchivos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PaperClipIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <GestionArchivos
                  idRecurso={values.id}
                  recurso="GESTIONPERSONA"
                  claseDocumento="personas"
                />
              }
            />
          )}
          {gestionarTelefonos && (
            <ModalAmplio
              title={`Gestionar Teléfonos`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setGestionarTelefonos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PhoneIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={<GestionTelefono persona={values} />}
            />
          )}
          {agregar && (
            <ModalGenerico
              title={"¿Desea agregar la persona al listado de visitas?"}
              open={agregar}
              closeModal={() => { }}
              cerrar={() => {
                setAgregar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <OrientacionesPersona
                  cancelar={() => { setAgregar(false) }}
                  isFirst={false}
                  setReFetch={setReFetch}
                  ocultarOrientacion={true}
                  Persona={Persona}
                />
              }
            />
          )}
          <div className="sm:col-span-10 col-span-12">
            <div className="col-span-12">
              {isSubmitting && <Spinner />}
              {hasError && <Alert type="error" mensaje={mensaje} />}
              {success && <Alert type="success" mensaje={mensaje} />}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
            <ButtonSiavi
              type="button"
              onClick={() => {
                handleSubmit();
              }}
              disabled={isSubmitting}
              text="Actualizar"
            />
          </div>
        </div>
      </div>
    </form>
  );
};
