import { useState, useEffect } from "react";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { Formik } from "formik";
import { getPreguntasUser, comprobarPreguntas } from "./services";
import Alert from "../../../components/common/Alert";
import Label from "../../../components/common/Label";
import Spinner from "../../../components/common/Spinner";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  respuesta1: Yup.string().required('respuesta 1 es requerida'),
  respuesta2: Yup.string().required('respuesta 2 es requerida'),
});

export const PreguntasSecretas = (props) => {
  const [preguntasUser, setPreguntasUser] = useState([]);

  useEffect(() => {
    getPreguntasUser(props.user).then(async (data) => {
      await setPreguntasUser(data?.data?.results);
    });
  }, [props.user]);

  const [hasError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [cargando, setCargando] = useState(false);

  return (
    <Formik
      initialValues={{
        pregunta1: 0,
        respuesta1: "",
        pregunta2: 0,
        respuesta2: "",
        username: props.user,
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        values.pregunta1 = preguntasUser?.[0].pregunta;
        values.pregunta2 = preguntasUser?.[1].pregunta;
        setCargando(true);
        comprobarPreguntas(values)
          .then((data) => {
            if(data.status === 200){
              setCargando(false);
              setSuccess(true);
              setMensaje("Preguntas comprobadas con éxito..");
              localStorage.setItem("pw", data.data.currentPassword);
              localStorage.setItem("username",values.username);
                
              setTimeout(() => {
                window.location.reload();
              }, 1800);
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400) {
              setCargando(false);
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
              setTimeout(() => {
                setError(false);
              }, 3000);
            }
            if (error.response.status >= 500) {
              setCargando(false);
              setError(true);
              setSuccess(false);
              setMensaje("Ocurrio un error interno comuniquese con el administrador");
              setTimeout(() => {
                setError(false);
              }, 3000);
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {
            (preguntasUser.length > 0) ? 
            <div className="pt-6 pb-3">
              <div className="mx-5">
                <div className="py-4 max-w-lg">
                  <div className="py-3 ">
                    <Label text={preguntasUser?.[0]?.pregunta_detalle?.pregunta} />
                    <div className="mt-1 mb-4">
                      <InputSiavi
                        name="respuesta1"
                        value={values.respuesta1}
                        onChange={handleChange}
                        validacion={true}
                      />
                    </div>
                    <div>
                      <Label text={preguntasUser?.[1]?.pregunta_detalle?.pregunta} />
                      <div className="mt-1 mb-4">
                        <InputSiavi
                          name="respuesta2"
                          value={values.respuesta2}
                          onChange={handleChange}
                          validacion={true}
                        />
                      </div>
                    </div>
                    <div className="mt-5 mb-2">
                      <ButtonSiavi
                        type="button"
                        text="Comprobar"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </div>
                    {cargando && <Spinner />}
                    {hasError && <Alert type="error" mensaje={mensaje} />}
                    {isSuccess && <Alert type="success" mensaje={mensaje} />}
                  </div>
                </div>
              </div>
            </div> :
            <div className="flex justify-center mt-5 flex-col items-center">
              <h1 className="mb-5">Aun no tienes preguntas de seguridad.</h1>
              <ButtonSiavi
                type="button"
                text="Cerrar"
                onClick={() => {
                  window.location = "/";
                }}
              />
            </div>
          }
        </form>
      )}
    </Formik>
  );
};


