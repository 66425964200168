import { useEffect, useState } from "react";
import { getHistorial } from "../services";
import TableSiavi from "../../../../../components/common/TableSiavi";
import moment from "moment";

const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";

export const HistorialMV = ({ token, medidaVictimologica }) => {
  const [state, setState] = useState({
    historial: [],
  });

  useEffect(() => {
    getHistorial(token, medidaVictimologica.id, true).then((data) => {
      let resultados =  data.results;

      resultados.sort(function (a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      setState({
        ...state,
        historial: resultados
      });
    });
  }, []);

  return (
    <TableSiavi
      headers={["id", "No. Caso", "Estado", "Fecha modificación", "Comentario","Fecha Registro"]}
      body={state.historial?.map((item, index) => (
        <tr key={index}>
          <td className={tdClass}>{item?.id}</td>
          <td className={tdClass}>{item?.medida_victimologica?.caso_persona?.caso?.numero_caso}</td>
          <td className={tdClass}>{item?.status?.nombre}</td>
          <td className={tdClass}>
            {moment(item?.fecha_modificacion).format("DD-MM-YYYY")}
          </td>
          <td className={tdClass}>{item?.comentario}</td>
          <td className={tdClass}>
            { item?.fecha_registro ? moment(item?.fecha_registro).format("DD-MM-YYYY HH:mm:ss") : ''}
          </td>
        </tr>
      ))}
    />
  );
};
