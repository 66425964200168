
const {
    REACT_APP_API_URL
} = process.env;

export const getTipoSms = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/registro/tiposmsvictima/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postTipoSms = async ({
    nombre,
    mensaje,
    estado,
    para_victima,
    para_profesional,
    por_caso,
    por_servicio,
    por_accion_seguimiento,
    referencia,
    editable
},token) => {
    try {
        let body = {
            nombre,
            mensaje,
            estado,
            para_victima,
            para_profesional,
            por_caso,
            por_servicio,
            por_accion_seguimiento,
            referencia,
            editable
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/tiposmsvictima/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putTipoSms = async (id, {
    nombre,
    mensaje,
    estado,
    para_victima,
    para_profesional,
    por_caso,
    por_servicio,
    por_accion_seguimiento,
    referencia,
    editable
},token) => {
    try {
        let body = {
            nombre,
            mensaje,
            estado,
            para_victima,
            para_profesional,
            por_caso,
            por_servicio,
            por_accion_seguimiento,
            referencia,
            editable
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/tiposmsvictima/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteTipoSms = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/tiposmsvictima/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAcciones = async (token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=1000`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};