const {
    REACT_APP_BITACORA_URL
} = process.env;

export const bloqueoFecha = () => {
    const fechaActual = new Date();
    let dia = "0";
    let mes = "0";

    if (fechaActual.getDate() >= 1 && fechaActual.getDate() < 10) {
        dia = "0" + fechaActual.getDate();
    } else {
        dia = fechaActual.getDate();
    }

    if (fechaActual.getMonth() >= 0 && fechaActual.getMonth() < 9) {
        mes = "0" + (fechaActual.getMonth() + 1);
    } else {
        mes = (fechaActual.getMonth() + 1);
    }

    let maximo = `${fechaActual.getFullYear()}-${mes}-${dia}`

    return maximo;
}

export const bloqueoFechaHora = () => {
    const fechaActual = new Date();
    let dia = "0";
    let mes = "0";
    let hora = "0";
    let minutos = "0";
    let segundos = "0";

    if (fechaActual.getDate() >= 1 && fechaActual.getDate() < 10) {
        dia = "0" + fechaActual.getDate();
    } else {
        dia = fechaActual.getDate();
    }

    if (fechaActual.getMonth() >= 0 && fechaActual.getMonth() < 9) {
        mes = "0" + (fechaActual.getMonth() + 1);
    } else {
        mes = (fechaActual.getMonth() + 1);
    }

    if (fechaActual.getHours() >= 0 && fechaActual.getHours() < 9) {
        hora = "0" + (fechaActual.getHours() + 1);
    } else {
        hora = (fechaActual.getHours() + 1);
    }

    if (fechaActual.getMinutes() >= 0 && fechaActual.getMinutes() < 9) {
        minutos = "0" + (fechaActual.getMinutes() + 1);
    } else {
        minutos = (fechaActual.getMinutes() + 1);
    }

    if (fechaActual.getSeconds() >= 0 && fechaActual.getSeconds() < 9) {
        segundos = "0" + (fechaActual.getSeconds() + 1);
    } else {
        segundos = (fechaActual.getSeconds() + 1);
    }

    let maximo = `${fechaActual.getFullYear()}-${mes}-${dia}T${hora}:${minutos}:${segundos}`

    return maximo;
}

export const bloqueoFechaMayor = () => {
    const fechaActual = new Date();
    let dia = "0";
    let mes = "0";

    if (fechaActual.getDate() >= 1 && fechaActual.getDate() < 10) {
        dia = "0" + fechaActual.getDate();
    } else {
        dia = fechaActual.getDate();
    }

    if (fechaActual.getMonth() >= 0 && fechaActual.getMonth() < 9) {
        mes = "0" + (fechaActual.getMonth() + 1);
    } else {
        mes = (fechaActual.getMonth() + 1);
    }

    let maximo = `${fechaActual.getFullYear()}-${mes}-${dia}`

    return maximo;
}


export const saveFavoritos = async (value, idUser) => {
    try {

        var raw = JSON.stringify({
            userId: idUser,
            favoritos: value
        });

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${REACT_APP_BITACORA_URL}/v1/favorito`, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    } catch (e) {
        console.log('error', e);
    }
}


export const getFavoritos = async (idUser) => {
    try {

        let favoritos = await fetch(`${REACT_APP_BITACORA_URL}/v1/favorito/${idUser}`)
            .then(response => response.json());

        return favoritos;

    } catch (e) {
        console.log('error', e);
    }
}


export const getPermisos = async (idRol) => {
    try {

        let permisos = await fetch(`${REACT_APP_BITACORA_URL}/v1/permiso/${idRol}`)
            .then(response => response.json());

        return permisos;

    } catch (e) {
        console.log('error', e);
    }
}