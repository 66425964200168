import { React, useState, useEffect } from "react";
import { getDireccionesVictima, getTelefonosVictima } from "./services";
import { MapIcon, PhoneIcon, UserGroupIcon } from "@heroicons/react/outline";
import { GestionDireccion } from "../../../../../components/gestionDireccion/gestionDireccion";
import { GestionTelefono } from "../../../../../components/gestionTelefono/gestionTelefono";
import { GestionRedesSociales } from "../../../../../components/gestionRedesSociales/gestionRedesSociales";
import { useSelector } from "react-redux";
import Label from "../../../../../components/common/Label";
import Divider from "../../../../../components/common/Divider";
import ModalAmplio from "../../../../../components/common/ModalAmplio";

export const DatosDomicilio = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  const useFetchDireccionPrincipal = (token) => {
    const [state, setState] = useState({
      direcciones: [],
    });

    useEffect(() => {
      getDireccionesVictima(props?.persona?.id,'', token).then((direcciones) => {
        setState({
          direcciones: direcciones,
        });
      });
    }, [token]);

    return state;
  };

  const useFetchTelefonoPrincipal = (token) => {
    const [state, setState] = useState({
      telefonos: [],
    });

    useEffect(() => {
      getTelefonosVictima(props?.persona?.id,'', token).then((telefonos) => {
        setState({
          telefonos: telefonos,
        });
      });
    }, [token]);

    return state;
  };

  const { direcciones } = useFetchDireccionPrincipal(token);
  const { telefonos } = useFetchTelefonoPrincipal(token);
  const [gestionarDirecciones, setGestionarDirecciones] = useState(false);
  const [gestionarTelefonos, setGestionarTelefonos] = useState(false);
  const [gestionarRedesSociales, setGestionarRedesSociales] = useState(false);
  
  const classButton = `inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 
  font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none 
  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`;

  return (
    <div className="grid-cols-12 gap-2 items-end">
      <div className="sm:col-span-6 lg:col-span-6 xl:col-span-2 md:col-span-6 col-span-12">
        <div className="py-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div className="flex flex-row justify-between items-end mb-2">
            <Label text="Dirección Principal" />
            <button
              onClick={() => {
                setGestionarDirecciones(true);
              }}
              type="button"
              className={classButton}
            >
              Gestionar direcciones
            </button>
          </div>
          <Divider />
          {direcciones?.results
            ?.filter((x) => x.direccion_principal === true)
            .map((item) => {
              return (
                <h1 className="-mt-2 mb-2 text-xs text-maivi-primaryFont ">
                  {`${item.numero_vialidad} 
                  ${item.vialidad_detalle.nombre} 
                  ${item.nombre_vialidad} 
                  ${item.numero_casa} 
                  "${item.letra_casa}" 
                  ${item.grupo_habitacional_detalle.nombre} 
                  ${item.nombre_grupo} ZONA 
                  ${item.zona}, 
                  ${item.referencia_ubicacion}, 
                  ${item.municipio_detalle.nombre}, 
                  ${item.municipio_detalle.departamento_detalle.nombre}, 
                  ${item.municipio_detalle.departamento_detalle.pais_detalle.nombre}.
                  `}
                </h1>
              );
            })}
          <Divider />
        </div>
      </div>

      <div className="sm:col-span-6 lg:col-span-6 xl:col-span-2 md:col-span-6 col-span-12">
        <dd className="py-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2 border-b-2">
          <div className="flex flex-row justify-between items-end mb-2">
            <Label text="Teléfono Principal" />
            <button
              onClick={() => {
                setGestionarTelefonos(true);
              }}
              type="button"
              className={classButton}
            >
              Gestionar teléfonos
            </button>
          </div>
          <Divider />
          {telefonos?.results
            ?.filter((x) => x.telefono_principal === true)
            .map((item) => {
              return (
                <h1 className="mt-1 text-xs text-maivi-primaryFont">
                  {item.numero_telefono}
                </h1>
              );
            })}
        </dd>
      </div>

      <div className="sm:col-span-6 lg:col-span-6 xl:col-span-2 md:col-span-6 col-span-12">
        <dd className="py-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2 border-b-2">
          <div className="flex flex-row justify-between items-end mb-2">
            <Label text="Redes Sociales" />
            <button
              onClick={() => {
                setGestionarRedesSociales(true);
              }}
              type="button"
              className={classButton}
            >
              Gestionar redes sociales
            </button>
          </div>
        </dd>
      </div>
      {gestionarDirecciones && (
        <ModalAmplio
          title={`Gestionar Direcciones`}
          open={true}
          closeModal={() => {}}
          cerrar={() => {
            setGestionarDirecciones(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <MapIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={<GestionDireccion persona={props.persona} />}
        />
      )}
      {gestionarTelefonos && (
        <ModalAmplio
          title={`Gestionar Teléfonos`}
          open={true}
          closeModal={() => {}}
          cerrar={() => {
            setGestionarTelefonos(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <PhoneIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={<GestionTelefono persona={props.persona} />}
        />
      )}
      {gestionarRedesSociales && (
        <ModalAmplio
          title={`Gestionar Redes Sociales`}
          open={true}
          closeModal={() => {}}
          cerrar={() => {
            setGestionarRedesSociales(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <UserGroupIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={<GestionRedesSociales persona={props.persona} />}
        />
      )}
    </div>
  );
};
