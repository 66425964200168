import Spinner from "./Spinner"

export const ButtonSiavi = (props) => {
    return(
        props.cargando ? <Spinner/> :
        <button
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md 
            shadow-sm text-sm font-medium text-white bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${props.className}`}
            onClick={props.onClick}
            type={props.type}
            disabled={props.disabled || false}
        >
        {props.text}
      </button>
    )
}