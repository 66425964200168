import { React } from "react";
import { Narrativa } from "./narrativa";

export const Echos = (props) => {
  return (
    <div className="grid grid-cols-12 gap-5 mt-4">
      <div className="col-span-12 xl:col-span-12">
        <Narrativa 
          caso={props?.caso} 
        />
      </div>
    </div>
  );
};
