
const {
    REACT_APP_API_URL
} = process.env;

export const getEtapaProcesal = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/etapaprocesal/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEtapaProcesal = async ({nombre, descripcion, tipo_proceso, estado},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            tipo_proceso,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/etapaprocesal/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEtapaProcesal = async (id, {nombre,descripcion,tipo_proceso,estado},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            tipo_proceso,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/etapaprocesal/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteEtapaProcesal = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/etapaprocesal/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProcesoEtapaProcesal = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoProceso = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/estadoproceso/?page_size=1000&etapa_proceso=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
