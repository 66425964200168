import { ButtonSiavi } from "../../../../components/common/Button";
import { Formik } from "formik";
import { SelectSiavi } from "../../../../components/common/Select";
import { useState } from "react";
import toast from "react-hot-toast";

export const AnioHistorico = (props) => {
    let persona = props?.Persona?.id;
    const [anio, setAnio] = useState("");
    props.Persona.historica = true;
    let historica = props?.Persona?.historica;
    props.Persona.ano_historico = anio;
    let ano_historico = props?.Persona?.ano_historico;

    const selectanio = [
        {
            value: "2020",
            text: "2020"
        },
        {
            value: "2021",
            text: "2021"
        },
        {
            value: "2022",
            text: "2022"
        }
    ]

    return (
        <div>
            <Formik
                initialValues={{
                    persona: props?.Persona?.id,
                    ano_historico: "",
                    historica: true
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6 mt-5">
                            <SelectSiavi
                                label=""
                                name="ano_historico"
                                value={values.ano_historico}
                                onChange={(e) => {
                                    setFieldValue("ano_historico", e.target.value);
                                    setAnio(e.target.value);
                                }}
                                valueInit=""
                                selectInit="Seleccionar año"
                                list={
                                    selectanio.map((item) => {
                                        return (
                                            <option key={item.value} value={item.id}>
                                                {item.text}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                        <div className="flex flex-row justify-center mt-5">
                            <div className="w-5/12">

                                    <ButtonSiavi
                                        text="Agregar"
                                        onClick={() => {
                                            if(ano_historico === ""){
                                                toast.error("debe seleccionar un año");
                                            } else {
                                            window.location = `/funcionalidades-generales/crear-orientacion/${persona}?ano_historico=${ano_historico}&historica=${historica}`;
                                                
                                            }
                                        }}
                                    />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};
