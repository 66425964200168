/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { 
    getHistoricoFechas, 
    getHistoricoAsignaciones 
} from "./services";
import { clases } from "../../../../../../helpers/clases";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import Label from "../../../../../../components/common/Label";
import moment from "moment";

export const FormDetalle = (props) => {
    const [historicoFechas,setFechas] = useState([]);
    const [historicoAsignaciones,setHistorico] = useState([]);
    const [cargando,setCargando] = useState(true);
    const [cargando1,setCargando2] = useState(true);

    useEffect(()=>{
        getHistoricoFechas(props.registro.id,props.token).then((data) =>{
            setFechas(data);
            setCargando2(false);
        });
    },[]);

    useEffect(()=>{
        setCargando(true);
        getHistoricoAsignaciones(props.registro.id,props.token).then((data) =>{
            setHistorico(data);
            setCargando(false);
        });
    },[]);

    return (
      <div className="grid grid-cols-12 gap-2">
       <div className="col-span-6">
       <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha accion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.fecha_accion}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Tipo accion
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.accion_seguimiento.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Expediente
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.expediente.numero_expediente}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Persona
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.persona?.primer_nombre} {props?.registro?.persona?.primer_apellido}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Servicio
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.accion_seguimiento?.servicio?.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha finalizada
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.fecha_completado}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Modalidad
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.registro?.modalidad?.nombre}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
       <div className="col-span-6 border rounded-lg p-3">
        <Label text="Historial asignaciones" />
        <TableSiavi 
            cargando={cargando}
            headers={[
                'Fecha',
                'Profesional',
                'Puesto'
            ]}
            body={
                historicoAsignaciones?.results?.map((item,index) =>(
                    <tr className={clases.tr} key={"t1"+index}>
                    <td className={clases.td}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                    <td className={clases.td}>{item?.profesional_asignado?.first_name} {item?.profesional_asignado?.last_name}</td>
                    <td className={clases.td}>{item?.profesional_asignado?.tipo_puesto?.puesto}</td>
                </tr>
                ))
            }
        />
       </div>
       <div className="col-span-12 border rounded-lg p-3">
       <Label text="Historial cambio de fechas" />
        <TableSiavi
            cargando={cargando1}
            headers={[
                'Fecha',
                'Fecha hora planificación inicio',
                'Fecha hora planificación fin',
                'Observaciones'
            ]}
            body={
                historicoFechas?.results?.map((item,index) =>(
                <tr className={clases.tr} key={"t2"+index}>
                    <td className={clases.td}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                    <td className={clases.td}>{moment(item?.fecha_hora_planificada_inicio).format("DD-MM-YYYY hh:mm:ss")} </td>
                    <td className={clases.td}>{moment(item?.fecha_hora_planificada_final).format("DD-MM-YYYY hh:mm:ss")}</td>
                    <td className={clases.td}>{item?.observaciones}</td>
                </tr>
                ))
            }
        />
       </div>
      </div>
    );
};
