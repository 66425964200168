import * as HIcons from '@heroicons/react/solid'
import * as LIcons from "../../helpers/icons";
const classIcon = "xl:h-9 lg:h-8 md:h-7 h-12 text-maivi-secondaryLightBlue"

export const  DynamicIcon = (props) => {
  if (props.ubicacion === 'local') {
    const { ...icons } = LIcons
    const TheIcon = icons[props.icon]
    return <TheIcon className={classIcon} aria-hidden="true" />;
  }
  const { ...icons } = HIcons
  const TheIcon = icons[props.icon]
  return <TheIcon className={classIcon} aria-hidden="true" />;
}
 