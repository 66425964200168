import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postNivelRiesgo, putNivelRiesgo } from "./services";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string().max(300, 'máximo 300 caracteres')
});

export const Form = (props) => {
  const token = useSelector(
    (state) => state.loginReducer.data.token
  );

  return <div>
    <Formik
      initialValues={{
        nombre: (props.editar) ? props.value.nombre : "",
        descripcion: (props.editar) ? props.value.descripcion : "",
        prioridad: (props.editar) ? props.value.prioridad : "",
        estado: (props.editar) ? props.value.estado : true
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        if (props.editar) {
          await putNivelRiesgo(props.value.id, values, token).then((data) => {
            if (data?.message !== undefined) {
              toast.error(data.message)
            } else {
              props.setReFetch(true);
              toast.success('Registro actualizado con éxito...!')
            }
          });
        } else {
          await postNivelRiesgo(values, token).then((data) => {
            if (data?.message !== undefined) {
              toast.error(data.message)
            } else {
              props.setReFetch(true);
              toast.success('Registro creado con éxito...!')
            }
          });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mx-5">
            <div className="col-span-12">
              <InputSiavi
                type="text"
                name="nombre"
                id="nombre"
                value={values.nombre}
                onChange={handleChange}
                validacion={true}
                label="Nombre*"
              />
            </div>
            <div className="col-span-12 mt-4">
              <InputSiavi
                type="text"
                name="descripcion"
                id="descripcion"
                value={values.descripcion}
                onChange={handleChange}
                validacion={true}
                label="Descripción"
              />
            </div>
            <div className="col-span-12 mt-4">
              <InputSiavi
                type="number"
                name="prioridad"
                id="prioridad"
                value={values.prioridad}
                onChange={handleChange}
                validacion={true}
                label="Prioridad"
              />
            </div>
            <div className="col-span-12 mt-5">
              <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
              <Toggle enabled={values.estado} name="estado" />
            </div>
            <div className="py-3 text-right">
              <ButtonSiavi 
                type="submit"
                disabled={isSubmitting}
                text={(props.editar) ? 'Actualizar' : 'Guardar'}
              />
            </div>
          </div>
          {isSubmitting && <Spinner />}
        </form>
      )}
    </Formik>
  </div>
};
