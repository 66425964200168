import Label from "./Label";
import { ErrorMessage } from "formik";
export const InputSiavi = (props) => {
  return (
    <div>
      <Label text={props.label} />
      <input
        autoFocus={props.autoFocus || false}
        type={props.type}
        name={props.name}
        id={props.id}
        autoComplete="off"
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        required={props.required || false}
        disabled={props.disabled || false}
        placeholder={props.placeholder}
        max={props?.maximo}
        min={props?.minimo}
        pattern={props?.pattern}
        onBlur={props?.onBlur}
        className={`shadow-sm mt-1 py-2 px-3 text-xs border focus:outline-none focus:ring-2 
        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
        border-maivi-gray-10 rounded-md text-maivi-primaryFont ${props.className}`}
      />
      {props.validacion && (
        <ErrorMessage
          name={props.name}
          component="span"
          className="text-xs text-maivi-secondaryRed"
        />
      )}
    </div>
  );
};
