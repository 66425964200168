import axios from "axios"
//import moment from "moment";

const {
    REACT_APP_API_URL
} = process.env;

export const getEtapasProcesales = async(idProceso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/etapaprocesal/?page_size=1000&estado=true&tipo_proceso=${idProceso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postServicios = async(idOrientacion, idServicio, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            servicio: Number(idServicio)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionservicio/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpEtapaProcesal = async(idCaso,idExpediente,idTipoProceso,idEtapaProcesal, token) => {
    try {
        let body = {
            caso: idCaso,
            expediente: idExpediente,
            tipo_proceso: idTipoProceso,
            etapa_procesal: idEtapaProcesal,
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedienteetapaprocesal/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiligencias = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipodiligencias/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/modalidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDocumento = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipodocumentoderivacion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiligenciasCreadas = async(expediente,token,urlPage) => {
    try {
        let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?expediente=${expediente}&ordering=-id`
          : `${urlPage}&expediente=${expediente}&ordering=-id`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiligenciasUrgentes = async(expediente, urlPage,token) => {
    try {
        let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?expediente=${expediente}&urgente=true&ordering=-id`
          : `${urlPage}&expediente=${expediente}&urgente=true&ordering=-id`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postDiligencias = async(idCaso, idExpediente, idProceso, idDiligencia, values, token) => {
    try {
        let body = {
            comentarios: values.comentario,
            caso: idCaso,
            expediente: idExpediente,
            tipo_proceso: idProceso,
            tipo_diligencia: idDiligencia,
            urgente: values.urgente,
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDiligencias = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNotificaciones = async(idCaso, idExpediente, idProceso, idDiligencia, values, token) => {
    try {
        let body = {
            comentarios: values.comentario,
            caso: idCaso,
            expediente: idExpediente,
            tipo_proceso: idProceso,
            tipo_diligencia: idDiligencia,
            urgente: values.urgente,
            plazo: values.plazo,
            numero_documento:values.numero_documento,
            tipo_documento:values.tipo_documento,
            modalidad: values.modalidad
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postCausaPenal = async(values,idCaso,idExpediente,organo_jurisdiccional, token) => {
    try {
        let body = {
            numero_causa_penal: values.numero_causa_penal,
            fecha: null,
            caso: idCaso,
            expediente: idExpediente,
            organo_jurisdiccional: organo_jurisdiccional,
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientecausapenal/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCausasPenales = async(idExpediente,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientecausapenal/?expediente=${idExpediente}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpedienteProceso = async (values,idCaso, token) => {
    try {
        let body ={
            proceso_secundario: values.proceso_secundario,
            fecha: values.fecha,
            comentarios: values.comentarios,
            caso: idCaso,
            expediente: values.expediente,
            expediente_sindicado: values.expediente_sindicado,
            tipo_proceso: values.tipo_proceso,
            etapa_procesal: values.etapa_procesal,
            estado_proceso: values.estado_proceso,
            resultado_audiencia: (values.resultado_audiencia === "") ? null : values.resultado_audiencia,
            medidada_desjudializadora: (values.medida === "") ? null : values.medida,
            expediente_causa_penal:(values.causa_penal === "") ? null : values.causa_penal,
            tipo_apelacion_especial_casacion: (values.tipo_apelacion2 === "") ? null : values.tipo_apelacion2,
            seguimiento_caso_accion: (values.audiencia === "") ? null : values.audiencia
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedienteproceso/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAudiencias = async (urlPage,idExpediente, token) => {
    try {
        let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?expediente=${idExpediente}&es_audiencia=true`
        :`${urlPage}&expediente=${idExpediente}&es_audiencia=true`;

        let result = await fetch( urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getResultadosAudiencias = async (estado_proceso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/resultadoaudiencia/?estado_proceso=${estado_proceso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAudiencia = async (id, resultado_audiencia,accion_id,fecha_requerida, fecha_completada,profesional_completa,token) => {
    try {
        let body ={
            resultado_audiencia: resultado_audiencia,
            completada : true,
            accion_seguimiento : accion_id,
            fecha_requerido : fecha_requerida,
            fecha_completado : fecha_completada,
            profesional_realiza : profesional_completa
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSentencias = async(caso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expedientesentencia/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpediente = async (id, values, token) => {
    try {
        let body ={
            exp_sentencia: values?.sentencia,
            sentencia_externa: values?.sentencia_externa,
            exp_rep_digna : values?.reparacion_digna,
            rep_digna_externa: values?.reparacion_externa
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expediente/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReparaciones = async(caso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expedientereparaciondigna/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getQuerellantes = async (caso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersona/?caso=${caso}&querellante=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        
        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProcuraciones = async (urlPage,idExpediente, token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?expediente=${idExpediente}&accion_seguimiento__es_procuracion=true`
        : `${urlPage}&expediente=${idExpediente}&es_procuracion=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

