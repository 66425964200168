const {
    REACT_APP_API_URL
} = process.env;

export const getProfesionales = async (urlPage, search, order, filters, token) => {
    try {
        let urlFetch = urlPage === undefined
            ? `${REACT_APP_API_URL}/seguridad/registrar/?search=${search.value}&ordering=${order}&${filters}`
            : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const profesionales = async (urlPage, search, order, filters, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/seguridad/registrar/?ordering=${order}&${filters}`
                : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getTipoPuestos = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipopuesto/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedes = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProfesionales = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipoprofesional/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/atencion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesPro = async (idPro, urlPage, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?profesional_asignado=${idPro}&es_accion=true`
                : `${urlPage}&profesional_asignado=${idPro}&es_accion=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeguimientoPro = async (idPro, urlPage, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?profesional_asignado=${idPro}&es_seguimiento=true`
                : `${urlPage}&profesional_asignado=${idPro}&es_seguimiento=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAudienciaPro = async (idPro, urlPage, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?profesional_asignado=${idPro}&es_audiencia=true`
                : `${urlPage}&profesional_asignado=${idPro}&es_audiencia=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientePro = async (idPro, urlPage, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/expediente/?profesional_asignado=${idPro}`
                : `${urlPage}&profesional_asignado=${idPro}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReferenciaPro = async (idPro, urlPage, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/referencia/?profesional_asignado=${idPro}`
                : `${urlPage}&profesional_asignado=${idPro}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
