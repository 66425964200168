import { types } from "../types/types";
import { SelectedDefaultTabOrientacion } from "../actions/orientacionTab";
export const ChangeOrientacionTab = (state = { tabs: SelectedDefaultTabOrientacion() }, action) => {
    switch (action.type) {
        case types.ChangeOrientacionTab:
            return { tabs: action.payload }
        default:
            return state;
    }
}

