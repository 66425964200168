/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect,useState } from "react";
//import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    getExpedientes
} from "./services"
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";
//import toast from 'react-hot-toast';

export const ValidacionExpedientes = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const mensaje = "Esta persona tiene uno o mas expedientes asociados, si le registra una accion no quedará asociada al expediente. Esta seguro que desea continuar?";
    const [expedientes,setExpedientes] = useState([]);
    const [cargando,setCargando] = useState(true);

    useEffect(() => {
        getExpedientes(props.registro.id, token).then((data) => {
            if(data.count === 0){
                props.setAgregarAccion(true);
                props.setExpedientesPersona(false);
                setCargando(false);
            } else {
                setExpedientes(data);
                setCargando(false);
            }
        });
    }, []);

    return (
        <Formik
            onSubmit={async (values) => {
              
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {
                        (cargando === false && expedientes.count > 0) ?
                        <div className="mb-2">
                            <Alert type="warning" mensaje={mensaje} />
                        </div> : ""
                    }
                    {  (cargando === false && expedientes.count > 0) ?
                        <BotonesConfirmar 
                        aceptar={()=>{
                            props.setExpedientesPersona(false);
                            props.setAgregarAccion(true);
                        }}
                        cancelar={()=>{
                            props.setExpedientesPersona(false);
                        }} 
                        /> : ""
                    }
                  
                    {
                        cargando &&
                        <Spinner /> 
                    }
                </form>
            )}
        </Formik>
    );
};
