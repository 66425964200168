import React, { useEffect, useState } from "react";
import Toggle from "../../../../components/common/Toggle";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { XCircleIcon } from "@heroicons/react/outline";
import {
  getDeptosGestion,
  getInstitucionGestion,
  getMateriaGestion,
  getMunicipiosGestion,
  getServicios,
  postGestionInstitucionMateria,
  deleteGestionInstitucionMateria,
  getPoblaciones,
  postPoblaciones,
  deletePoblaciones,
  postGestionInstitucionServicios,
  deleteGestionInstitucionServicios,
} from "../services";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import FormEliminar from "../../common/FormEliminar";
import toast from "react-hot-toast";
import Return from "../../../../components/common/ButtonSalir"
import ModalGenerico from "../../../../components/common/Modal";

const useFetchServicios = (token) => {
  const [state, setState] = useState({
    servicios: [],
  });

  useEffect(() => {
    getServicios(token).then((servicios) => {
      setState({
        servicios: servicios,
      });
    });
  }, [token]);

  return state;
};

const useFetchMateria = (token) => {
  const [state, setState] = useState({
    materias: [],
  });

  useEffect(() => {
    getMateriaGestion(token).then((materias) => {
      setState({
        materias: materias,
      });
    });
  }, [token]);

  return state;
};

const useFetchDepartamento = (token) => {
  const [state, setState] = useState({
    departamentos: [],
  });

  useEffect(() => {
    getDeptosGestion(token).then((departamentos) => {
      setState({
        departamentos: departamentos,
      });
    });
  }, [token]);

  return state;
};

const useFetchMunicipio = (token) => {
  const [state, setState] = useState({
    municipios: [],
  });

  useEffect(() => {
    getMunicipiosGestion(token).then((municipios) => {
      setState({
        municipios: municipios,
      });
    });
  }, [token]);

  return state;
};

const useFechtInstitucion = (token) => {
  const [state, setState] = useState({
    instituciones: [],
  });

  useEffect(() => {
    getInstitucionGestion(token).then((instituciones) => {
      setState({
        instituciones: instituciones,
      });
    });
  }, [token]);

  return state;
};

const useFechtPoblaciones = (token) => {
  const [state, setState] = useState({
    poblaciones: [],
  });

  useEffect(() => {
    getPoblaciones(token).then((data) => {
      setState({
        poblaciones: data,
      });
    });
  }, [token]);

  return state;
};


export const Editar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje,
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { servicios } = useFetchServicios(token);
  const { materias } = useFetchMateria(token);
  const { departamentos } = useFetchDepartamento(token);
  const { municipios } = useFetchMunicipio(token);
  const { instituciones } = useFechtInstitucion(token);
  const { poblaciones } = useFechtPoblaciones(token);
  const [ serviciosAgregados, setServiciosAgregados] = useState(values.servicio);
  const [ materiasAgregadas, setMateriasAgregadas] = useState(values.materias);
  const [ poblacionesAgregadas, setPoblacionesAgregadas] = useState(
    values.poblaciones
  );
  const [ isEliminar, setEliminar ] = useState(false);
  const [ itemPoblacion, setItemPoblacion ] = useState({});
  const [ eliminarMateria, setEliminarMateria ] = useState(false);
  const [ itemMateria, setItemMateria ] = useState({});

  const [ eliminarServicio, setEliminarServicio ] = useState(false);
  const [ itemServicio, setItemServicio ] = useState({});

  const selectClass = `mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue
    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md`;
  const buttonAddClass = `inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont 
   hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue`;
  const buttonRemoveClass = `inline-flex items-center p-1 border border-transparent rounded-md 
   text-maivi-primaryFont hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue`;

   const tabs = [
    {
      name: "Servicios que brinda",
    },
    {
      name: "Materia Legal",
    },
    {
      name: "Población Atendida",
    }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const pages = [
    {
      name: "Directorio de derivación",
      href: "/catalogos/gestion-instituciones",
      current: false,
    },
    { name: "Editar", href: "#", current: true },
  ];

  const borrarPoblaciones = () => {
    deletePoblaciones(itemPoblacion.id, token);
    let old = poblacionesAgregadas.filter((x) => x.id !== itemPoblacion.id);
    setPoblacionesAgregadas([...old]);
  };

  const borrarMateria = () => {
    deleteGestionInstitucionMateria(itemMateria.id,token);
    let old = materiasAgregadas.filter((x) => x.id !== itemMateria.id);
    setMateriasAgregadas([...old]);
  };

  const borrarServicio = () => {
    deleteGestionInstitucionServicios(itemServicio.id,token);
    let old = serviciosAgregados.filter((x) => x.id !== itemServicio.id);
    setServiciosAgregados([...old]);
  };

  return (
    <form autoComplete="off">
      <div className="grid grid-cols-12 -mt-36 xl:mx-10 lg:mx-8 md:mx-6 sm:mx-1 mx-1">
        <div className="col-span-12 xl:col-span-12 lg:col-span-10 md:col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
          <div className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 mt-5">
            <BreadCrumbTwo navegacion={pages} />
           <div className="flex flex-row">
           <h1 className="mb-4 text-base text-maivi-primaryFont">
              Actualizar directorio de derivación
            </h1>
            <Return ruta="/catalogos/gestion-instituciones" />
           </div>
          </div>
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 xl:col-span-7 lg:col-span-12 md:col-span-12 border-2 rounded-md p-5">
              <div className="grid grid-cols-12 gap-3">
                {/* <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="nombre_fiscalia"
                      placeholder="fiscalía o institución que está creando"
                      onChange={handleChange}
                      value={values.nombre_fiscalia}
                      validacion={true}
                      label="*Nombre"
                    />
                </div> */}
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                  <Label text="*Institución" />
                    <select
                      name="institucion"
                      value={values.institucion}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="" disabled>Elegir institución</option>
                      {instituciones?.results?.map((item) => {
                        return (
                          <option key={item.nombre} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      name="institucion"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                </div>
                <div className="col-span-12 xl:col-span-8 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      name="nombre_fiscalia"
                      placeholder="Oficina / Fiscalía / Instancia"
                      value={values.nombre_fiscalia}
                      onChange={handleChange}
                      validacion={true}
                      label="*Oficina / Fiscalía / Instancia"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      name="telefono"
                      value={values.telefono}
                      onChange={handleChange}
                      validacion={true}
                      label="*Teléfono"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      name="contacto_principal"
                      type="text"
                      value={values.contacto_principal}
                      onChange={handleChange}
                      validacion={true}
                      label="Contacto principal"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      name="otros_contactos"
                      type="text"
                      value={values.otros_contactos}
                      onChange={handleChange}
                      validacion={true}
                      label="Otros contactos"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      name="email_contacto"
                      type="email"
                      value={values.email_contacto}
                      onChange={handleChange}
                      validacion={true}
                      label="Correo Electrónico"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="direccion"
                      value={values.direccion}
                      onChange={handleChange}
                      validacion={true}
                      label="*Dirección"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                  <Label text="*Departamento" />
                    <select
                      name="departamento"
                      value={values.departamento}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="">Elegir departamento</option>
                      {departamentos?.results?.map((item) => {
                        return (
                          <option key={item.id + item.nombre} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      name="departamento"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                </div>
              {
                (values.departamento !== "") ?
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                <Label text="*Municipio" />
                  <select
                    name="municipio"
                    value={values.municipio}
                    onChange={handleChange}
                    className={selectClass}
                  >
                    <option value="" disabled>Elegir municipio</option>
                    {municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                      return (
                        <option key={item.nombre + item.id} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="municipio"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
              </div>: ""
              }
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="url"
                      value={values.url}
                      onChange={handleChange}
                      label="Página WEB"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="horario"
                      value={values.horario}
                      onChange={handleChange}
                      validacion={true}
                      label="Horario de atención"
                    />
                </div>
                {/* <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="jurisdiccion"
                      value={values.jurisdiccion}
                      onChange={handleChange}
                      validacion={true}
                      label="Jurisdicción"
                    />
                </div> */}
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="latitud"
                      value={values.latitud}
                      onChange={handleChange}
                      validacion={true}
                      label="Latitud"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="longitud"
                      value={values.longitud}
                      onChange={handleChange}
                      validacion={true}
                      label="Longitud"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="url_maps"
                      value={values.url_maps}
                      onChange={handleChange}
                      label="URL Mapa"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
                  <Label text="Observaciones" />
                    <TextAreaSiavi
                      name="observaciones"
                      type="text"
                      rows={2}
                      columns={50}
                      value={values.observaciones}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="observaciones"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6 flex flex-col justify-center items-center ">
                  <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                  <Toggle enabled={values.estado} name="estado" />
                </div>
                <div className=" xl:col-span-4 lg:col-span-6 md:col-span-6 flex items-end">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                    text="Actualizar"
                  />
                </div>
                <div className="mt-6 col-span-12">
                  {hasError && <Alert type="error" mensaje={mensaje} />}
                  {success && <Alert type="success" mensaje={mensaje} />}
                </div>
                <div className="col-span-12 flex flex-row items-center justify-center">
                  {isSubmitting && <Spinner />}
                </div>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-5 lg:col-span-12 md:col-span-12 border-2 rounded-md p-5">
              <Tab.Group>
              <Tab.List className="grid grid-cols-12 bg-blue-900/20 rounded-md bg-blue-50 p-1">
                {tabs.map((item) => {
                  return (
                    <Tab
                      key={item.name}
                      className={({ selected }) =>
                        classNames(
                          "col-span-4 py-2 w-full text-sm leading-5 font-medium text-maivi-primaryFont rounded-lg",
                          "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                          selected
                            ? "bg-white shadow px-2"
                            : "text-blue-100 hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue  px-2"
                        )
                      }
                    >
                      {item.name}
                    </Tab>
                  );
                })}
              </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Servicios
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
                          <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
                            <thead className="bg-maivi-gray-5">
                              <tr>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Servicio
                                </th>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Agregar
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                              {servicios?.results?.map((item) => (
                                <tr
                                  key={item.id + item.nombre}
                                  id={item.nombre}
                                  className="hover:bg-maivi-gray-5"
                                >
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      {item.nombre}
                                    </div>
                                  </td>
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      <button
                                        type="button"
                                        onClick={async () => {
                                          await postGestionInstitucionServicios(item.id, values.id, token)
                                          .then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                              let element = document.getElementById(item.nombre);
                                              let servicio = data.data;
                                              element.classList.add("bg-green-50");
                                              setServiciosAgregados([
                                                ...serviciosAgregados,
                                                servicio,
                                              ]);
                                              toast.success("Servicio agregado con éxito..!");
                                            } else {
                                              toast.success("Servicio agregado con éxito..!");
                                            }
                                          })
                                          .catch(function (error) {
                                            if (
                                              error.response.status >= 400 &&
                                              error.response.status <= 450
                                            ) {
                                              toast.error(error.response.data.message);
                                            } else if (error.response.status >= 500) {
                                              toast.error("Error interno comuniquese con el administrador");
                                            }
                                          });
                                        }}
                                        className={buttonAddClass}
                                      >
                                        Agregar
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Servicios agregados
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-60 overflow-y-auto">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <table className="min-w-full divide-y divide-maivi-gray-10">
                              <thead className="bg-maivi-gray-5">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Servicio
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Borrar
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                                {serviciosAgregados?.map((item) => (
                                  <tr key={item.id} className="bg-green-50">
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        {item.servicio_detalle.nombre}
                                      </div>
                                    </td>
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setItemServicio(item);
                                            setEliminarServicio(true);
                                          }}
                                          className={buttonRemoveClass}
                                        >
                                          Borrar
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Materias
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
                          <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
                            <thead className="bg-maivi-gray-5">
                              <tr>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Materia
                                </th>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Agregar
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                              {materias?.results?.map((item) => (
                                <tr
                                  key={item.materia}
                                  id={item.materia}
                                  className="hover:bg-maivi-gray-5"
                                >
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      {item.materia}
                                    </div>
                                  </td>
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      <button
                                        type="button"
                                        onClick={async () => {
                                          await postGestionInstitucionMateria(item.id, values.id, token)
                                          .then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                              let element = document.getElementById(item.materia);
                                              let materia = data.data;
    
                                              element.classList.add("bg-green-50");
                                              setMateriasAgregadas([
                                                ...materiasAgregadas,
                                                materia,
                                              ]);
                                              toast.success("Materia agregada con éxito..!");
                                            } else {
                                              toast.success("Materia agregada con éxito..!");
                                            }
                                          })
                                          .catch(function (error) {
                                            if (
                                              error.response.status >= 400 &&
                                              error.response.status <= 450
                                            ) {
                                              toast.error(error.response.data.message);
                                            } else if (error.response.status >= 500) {
                                              toast.error("Error interno comuniquese con el administrador");
                                            }
                                          });
                                        }}
                                        className={buttonAddClass}
                                      >
                                        Agregar
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Materias agregadas
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-60 overflow-y-auto">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <table className="min-w-full divide-y divide-maivi-gray-10">
                              <thead className="bg-maivi-gray-5">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Materia
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Borrar
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                                {materiasAgregadas?.map((item) => (
                                  <tr
                                    key={item.materia + item.id}
                                    className="bg-green-50"
                                  >
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        {item.materia_detalle.materia}
                                      </div>
                                    </td>
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setItemMateria(item);
                                            setEliminarMateria(true);
                                          }}
                                          className={buttonRemoveClass}
                                        >
                                          Borrar
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Población
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
                          <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
                            <thead className="bg-maivi-gray-5">
                              <tr>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Nombre
                                </th>
                                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                                  Agregar
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                              {poblaciones.results?.map((item) => (
                                <tr
                                  key={item.nombre}
                                  id={item.nombre}
                                  className="hover:bg-maivi-gray-5"
                                >
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      {item.nombre}
                                    </div>
                                  </td>
                                  <td className="px-6 whitespace-nowrap">
                                    <div className="text-xs text-maivi-primaryFont">
                                      <button
                                        type="button"
                                        onClick={async () => {
                                          await postPoblaciones(item.id, values.id, token)
                                          .then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                              let element = document.getElementById(item.nombre);
                                              let poblacion = data.data;
                                              element.classList.add("bg-green-50");
                                              setPoblacionesAgregadas([
                                                ...poblacionesAgregadas,
                                                poblacion,
                                              ]);

                                              toast.success("Poblacion agregada con éxito..!");
                                            } else {
                                              toast.success("Poblacion agregada con éxito..!");
                                            }
                                          })
                                          .catch(function (error) {
                                            if (
                                              error.response.status >= 400 &&
                                              error.response.status <= 450
                                            ) {
                                              toast.error(error.response.data.message);
                                            } else if (error.response.status >= 500) {
                                              toast.error("Error interno comuniquese con el administrador");
                                            }
                                          });
                                        }}
                                        className={buttonAddClass}
                                      >
                                        Agregar
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-3 col-span-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                        <div className="p-2">
                          <h3 className="text-sm leading-2 font-medium text-gray-900">
                            Poblaciones agregadas
                          </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-60 overflow-y-auto">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <table className="min-w-full divide-y divide-maivi-gray-10">
                              <thead className="bg-maivi-gray-5">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Población
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                                  >
                                    Borrar
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                                {poblacionesAgregadas?.map((item) => (
                                  <tr
                                    key={
                                      item.poblacion_detalle.nombre + item.id
                                    }
                                    className="bg-green-50"
                                  >
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        {item.poblacion_detalle.nombre}
                                      </div>
                                    </td>
                                    <td className="px-6 whitespace-nowrap">
                                      <div className="text-xs text-maivi-primaryFont">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setItemPoblacion(item);
                                            setEliminar(true);
                                          }}
                                          className={buttonRemoveClass}
                                        >
                                          Borrar
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
              {isEliminar && (
                <ModalGenerico
                  title={`¿Esta seguro de eliminar el registro ${itemPoblacion.poblacion_detalle.nombre}?`}
                  open={isEliminar}
                  closeModal={() => {}}
                  cerrar={() => {
                    setEliminar(false);
                  }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                    </div>
                  }
                  children={
                    <FormEliminar
                      aceptar={() => {
                        borrarPoblaciones();
                        setEliminar(false);
                      }}
                      cancelar={() => {
                        setEliminar(false);
                      }}
                    />
                  }
                />
              )}
              {eliminarMateria && (
                <ModalGenerico
                  title={`¿Esta seguro de eliminar el registro ${itemMateria.materia_detalle.materia}?`}
                  open={eliminarMateria}
                  closeModal={() => {}}
                  cerrar={() => {
                    setEliminarMateria(false);
                  }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                    </div>
                  }
                  children={
                    <FormEliminar
                      aceptar={() => {
                        borrarMateria();
                        setEliminarMateria(false);
                      }}
                      cancelar={() => {
                        setEliminarMateria(false);
                      }}
                    />
                  }
                />
              )}
               {eliminarServicio && (
                <ModalGenerico
                  title={`¿Esta seguro de eliminar el registro ${itemServicio.servicio_detalle.nombre}?`}
                  open={eliminarServicio}
                  closeModal={() => {}}
                  cerrar={() => {
                    setEliminarServicio(false);
                  }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                    </div>
                  }
                  children={
                    <FormEliminar
                      aceptar={() => {
                        borrarServicio();
                        setEliminarServicio(false);
                      }}
                      cancelar={() => {
                        setEliminarServicio(false);
                      }}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
