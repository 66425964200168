
const {
    REACT_APP_API_URL
} = process.env;

export const getDeptos = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/departamento/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPaisesDepto = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/pais/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postDepto = async ({nombre, codigo, pais, estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            pais,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDepto = async (id, {nombre,codigo,pais,estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            pais,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDepto = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};