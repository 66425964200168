import { React, useEffect, useState } from "react";
import {
  getPersonas,
  getSindicados,
  getCasosPersona,
  getExpedientesSindicado,
  getCasos
} from "./services";
import { useSelector } from "react-redux";
import { store } from "../app/redux/store/store";
import { ChangeSearch } from "../app/redux/actions/search";
import { clases } from "../helpers/clases";
import { EyeIcon } from "@heroicons/react/solid";
import ModalAmplio from "../components/common/ModalAmplio";
import Spinner from "../components/common/Spinner";
import Alert from "../components/common/Alert"
const logo = process.env.PUBLIC_URL + "/images/logo-siavi-background.png";
const busqueda = process.env.PUBLIC_URL + "/images/icons/search.svg";

export const Home = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const tipoFiltro = useSelector((state) => state.TipoFiltro.tipo);
  const [cargando, setCargando] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const [isBusqueda, setBusqueda] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [listado, setListado] = useState([]);
  const [casos, setCasos] = useState([]);
  const [nombre, setNombre] = useState("");
  const [isSindicado, setIsSindicado] = useState(false);
  const [personasEncontradas, setPersonasEncontradas] = useState(false);
  const [casosEncontrados, setCasosEncontrados] = useState(false);

  const fetchGetPersonas = () => {
    if (search.fetch && search.value.length >= search.min) {
      busquedas();
    }
  };

  const busquedas = async () => {
    if(tipoFiltro.caso === true) {
      setPersonasEncontradas(false);
      setTableLoading(true);
      await getCasos(search, token).then(async (casos) => {
        if (casos?.count > 0) {
          setCasosEncontrados(true);
        } else {
          setCasosEncontrados(false);
        }
        setCasos(casos);
        setTableLoading(false);
        setBusqueda(true);
        let value = {
          value: search.value,
          fetch: false,
          clean: false,
        };
        store.dispatch(ChangeSearch({ ...search, ...value }));
      });
    }
   

    if(tipoFiltro.persona === true){
      setCasosEncontrados(false);
      await getPersonas(search, token).then(async (data) => {
        setTableLoading(true);
  
        await getSindicados(search, token).then((data2) => {
          if (data2?.count > 0 || data?.count > 0) {
            setPersonasEncontradas(true);
          } else {
            setPersonasEncontradas(false);
          }
  
          setTableLoading(false);
          let busquedas = [...data?.results, ...data2?.results];
          setPersonas(busquedas);
        });
      }).then(() => {
          setBusqueda(true);
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  //useEffect(fetchVisitas, [urlFetch, token]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchGetPersonas, [search]);

  return !isBusqueda ? (
    <div className="min-h-screen flex justify-center items-center  opacity-25 -mt-10">
      <img className="w-10/12 lg:w-6/12 md:w-6/12" src={logo} alt="logo" />
    </div>
  ) : (personasEncontradas === false && casosEncontrados === false) ? (
    <div className="flex flex-col justify-center items-center mt-20">
      <h1 className="text-3xl font-extrabold text-maivi-primaryLightBlue tracking-tight">
        No se encontraron datos
      </h1>
      <br />
      <img
        src={busqueda}
        alt="triangle with all three sides equal"
        height="240"
        width="240"
      />
    </div>
  ) : (
    <div className="p-2 xl:p-10 bg-white">
      {
        personasEncontradas &&
        <div>
          <h1 className="text-xl font-extrabold text-maivi-primaryLightBlue tracking-tight">
            Busqueda general de personas y sindicados
          </h1>
          <div className="py-2 inline-block w-full">
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
              <table className="table w-full border ">
                <thead className="bg-maivi-gray-5 rounded-md">
                  <tr>
                    <th className={clases.th}>Nombres</th>
                    <th className={clases.th}>Pais</th>
                    <th className={clases.th}>Tipo documento</th>
                    <th className={clases.th}>No. documento</th>
                    <th className={clases.th}>Fecha nacimiento</th>
                    <th className={clases.th}>Sexo</th>
                    <th className={clases.th}>Tipo</th>
                    <th className={clases.th}>Ver</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                  {personas?.map((item, index) => (
                    <tr
                      key={item.id}
                      className="hover:bg-maivi-gray-5 cursor-pointer"
                    >
                      <td className={clases.td}>
                        {`${item?.primer_nombre} ${item?.segundo_nombre} ${item?.primer_apellido} ${item?.segundo_apellido}`}
                      </td>
                      <td className={clases.td}>{item?.pais_detalle?.nombre}</td>
                      <td className={clases.td}>
                        {item?.tipo_documento_detalle?.nombre}
                      </td>
                      <td className={clases.td}>{item?.no_documento}</td>
                      <td className={clases.td}>{item?.fecha_nacimiento}</td>
                      <td className={clases.td}>{item?.sexo}</td>
                      <td className={clases.td}>
                        {item?.no_documento === undefined ? "SINDICADO" : "PERSONA"}
                      </td>
                      {item?.no_documento === undefined ? (
                        <button
                          type="button"
                          className={`hover:bg-maivi-primaryLightBlue inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                          onClick={async () => {
                            setIsSindicado(true);
                            setAgregar(true);
                            setCargando(true);
                            setNombre(
                              `${item?.primer_nombre} ${item?.primer_apellido}`
                            );
                            await getExpedientesSindicado(item.id, token).then(
                              (data) => {
                                setListado(data);
                                setCargando(false);
                              }
                            );
                          }}
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={`hover:bg-maivi-primaryLightBlue inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                          onClick={async () => {
                            setAgregar(true);
                            setCargando(true);
                            setNombre(
                              `${item?.primer_nombre} ${item?.primer_apellido}`
                            );
                            await getCasosPersona(item.id, token).then((data) => {
                              setListado(data);
                              setCargando(false);
                            });
                          }}
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {tableLoading && (
                <div className="mt-10 mb-10">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      }

      {
        casosEncontrados &&
        <div>
          <h1 className="text-xl font-extrabold text-maivi-primaryLightBlue tracking-tight mt-5">
            Busqueda general de expedientes
          </h1>
          <div className="py-2 inline-block w-full">
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
              <table className="table w-full border ">
                <thead className="bg-maivi-gray-5 rounded-md">
                  <tr>
                    <th className={clases.th}>Expediente</th>
                    <th className={clases.th}>Estado</th>
                    <th className={clases.th}>Fecha</th>
                    <th className={clases.th}>Sede</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                  {casos?.results?.map((item) => (
                    <tr
                      key={item.id}
                      className="hover:bg-maivi-gray-5 cursor-pointer"
                      onClick={()=>{
                        window.location =`/funcionalidades-generales/editar-caso/${item.id}`
                      }}
                    >
                      <td className={clases.td}>
                        {item?.numero_caso}
                      </td>
                      <td className={clases.td}>
                        {item?.estatus?.descripcion}
                      </td>
                      <td className={clases.td}>
                        {item?.fecha}
                      </td>
                      <td className={clases.td}>
                        {item?.sede_creacion?.sede}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {tableLoading && (
                <div className="mt-10 mb-10">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      }

      {agregar && (
        <ModalAmplio
          title={`Expedientes relacionados con ${nombre}`}
          open={agregar}
          closeModal={() => {
            setIsSindicado(false);
          }}
          cerrar={() => {
            setAgregar(false);
            setIsSindicado(false);
          }}
          children={
            <div className="mt-3">
              {cargando ? (
                <div className="py-5">
                  <Spinner />
                </div>
              ) : isSindicado ? (
                <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
                  <thead className="bg-maivi-gray-5">
                    <tr>
                      <th className={clases.th}>Expediente</th>
                      <th className={clases.th}>Caso</th>
                    </tr>
                  </thead>
                  <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                    {listado?.results?.map((item) => (
                      <tr key={item.id} className="hover:bg-maivi-gray-5">
                        <td className={clases.td}>{item?.expediente}</td>
                        <td className={clases.td}>{item?.caso}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
               (listado?.results?.length > 0) ?
                <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
                <thead className="bg-maivi-gray-5">
                  <tr>
                    <th className={clases.th}>Expediente</th>
                    <th className={clases.th}>Estado</th>
                    <th className={clases.th}>Fecha</th>
                    <th className={clases.th}>Tipo Persona</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                  {listado?.results?.map((item) => (
                    <tr key={item?.caso?.numero_caso} className="hover:bg-maivi-gray-5 cursor-pointer" onClick={()=>{
                      window.location =`/funcionalidades-generales/editar-caso/${item?.caso?.id}`
                    }} >
                      <td className={clases.td}>{item?.caso?.numero_caso}</td>
                      <td className={clases.td}>{item?.caso?.estatus?.descripcion}</td>
                      <td className={clases.td}>{item?.fecha_creacion}</td>
                      <td className={clases.td}>
                        {item?.tipo_persona?.nombre}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>: <Alert type="success" mensaje="No existen casos relacionados con esta persona." />
               
              )}
            </div>
          }
        />
      )}
    </div>
  );
};
