import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { useSelector } from "react-redux";
import { getTemporalidades } from "../services";
import Spinner from "../../../../../components/common/Spinner";
import Divider from "../../../../../components/common/Divider";
import CustomTable from "../../../../../components/common/Table";
import TableSiavi from "../../../../../components/common/TableSiavi";

const tableColumns = [
  {
    title: "Día",
    value: "dia",
    tipo: "text",
  },
  {
    title: "Mes",
    value: "mes",
    tipo: "text",
  },
  {
    title: "Año",
    value: "anio",
    tipo: "text",
  },
  {
    title: "Edad",
    value: "edad",
    tipo: "text",
  },
  {
    title: "Aldea/Caserio",
    value: "aldea_caserio",
    tipo: "text",
  },
  {
    title: "Dirección",
    value: "direccion",
    tipo: "text",
  },
  {
    title: "Zona",
    value: "zona",
    tipo: "text",
  },
  {
    title: "Lugar",
    value: "tipo_lugar",
    tipo: "text",
  },
  {
    title: "Departamento",
    value: "departamento.nombre",
    tipo: "text",
  },
  {
    title: "Municipio",
    value: "municipio.nombre",
    tipo: "text",
  },
];

const validaciones = Yup.object().shape({
  edad: Yup.number().required("edad es requerida").max(100, "Máximo 100 años"),
  lugar: Yup.string().required("tipo lugar es requerido"),
});

export const Detalle = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const useFetchTemporalidades = (token, reFetch) => {
    useEffect(() => {
      getTemporalidades(props.idCaso, token).then((data) => {
        setDataTable(data);
        setTableLoading(false);

      });
    }, [token, reFetch]);
  };

  useFetchTemporalidades(token);

  const headersTableSindicados =[
    "Sindicado",
    "Delito"
  ]

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <div className="flex flex-row justify-between ">
                  <h1 className="mb-1 text-base text-maivi-primaryFont">
                    Narrativa
                  </h1>
                </div>
                <div className="grid grid-cols-12 items-end justify-end gap-2">
                  <div className="col-span-12">
                    <TextAreaSiavi
                      rows={10}
                      name="narrativa"
                      value={props.narrativa.narrativa}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <h1 className="mb-1 text-maivi-primaryFont">
                  Sindicados y delitos asociados
                </h1>
                <TableSiavi 
                  headers={headersTableSindicados}
                  body={
                    props.narrativa?.sindicados_delitos?.map((item,index) =>(
                      <tr key={item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_nombre + index} className="hover:bg-maivi-gray-5 cursor-pointer">
                          <td className={props.tdClass}>
                            {item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_nombre} {item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_apellido}
                          </td>
                          <td className={props.tdClass}>{item?.exp_sindicado_delito?.delito?.nombre}</td>
                        </tr>
                    ))
                  }
                />
                <Divider />
              
              </div>
              <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
                <h1 className="mb-1 text-maivi-primaryFont">Temporalidades</h1>
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                  <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    permiso={"exphechoslugartemporalidad"}
                    showEliminar={false}
                    showEditar={false}
                    showVer={false}
                  />
                  {tableLoading && (
                    <div className="mt-10 mb-10">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
