import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  postExpReparacionDigna,
  postExpReparacionDignaDetalle,
  getReparacionesDignas,
  getExpedienteReparacionDigna,
  getExpReparacionDignaDetalle,
  deleteExpReparacionDignaDetalle,
  deleteExpReparacionDigna,
  putExpReparacionDignaDetalle
} from "./services";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { PencilIcon, TrashIcon,DocumentAddIcon } from "@heroicons/react/solid";
import { SelectSiavi } from "../../../../../components/common/Select";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import Divider from "../../../../../components/common/Divider";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";
import NumberFormat from 'react-number-format';

const validaciones = Yup.object().shape({
  reparacion_digna: Yup.number().required("reparación digna es requerida"),
});

const tdClass = "px-6 py-1 row-td text-maivi-primaryFont text-xs";

export const ReparacionDigna = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idExpedienteProceso = props?.registro?.id;
  const idExpediente = props?.registro?.expediente?.id;
  const numero_expediente = props?.registro?.expediente?.numero_expediente;
  const idCaso = props?.registro?.caso;
  const [cargando, setCargando] = useState(true);
  const [cargandoDetalle, setCargandoDetalle] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [detalleReparaciones, setDetalleReparaciones] = useState([]);
  const [reparaciones,setReparaciones] = useState([]);
  const [expReparaciones,setExpReparaciones] = useState([]);
  const [idReparacionDigna, setIdReparacionDigna] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [crearReparacion, setCrearReparacion] = useState(false);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [mostrarMonto, setMostrarMonto] = useState(true);

  useEffect(() => {
    getReparacionesDignas(token).then((data) => {
      setReparaciones(data);
    });
  }, [token]);

  useEffect(() => {
    getExpedienteReparacionDigna(idCaso, token).then((data) => {
      setExpReparaciones(data);
      setCargando(false);
    });
  }, [token, idCaso, setCargando, refetch]);

  return (
    <div>
      <Formik
        initialValues={{
          monto: "",
          fecha: "",
          especificaciones: "",
          reparacion_digna: "",
          idRepacionDignaDetalle: 0,
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (isEdit) {
            await putExpReparacionDignaDetalle(
              values,
              idCaso,
              idReparacionDigna,
              token
            ).then((data) => {
              if (data?.status === 200 || data?.status === 201) {
                getExpReparacionDignaDetalle(idReparacionDigna, token).then(
                  (data) => {
                    setDetalleReparaciones(data?.results);
                    resetForm({});
                  }
                );
                toast.success("Registro actualizado con éxito..!");
                setRefetch(true);
                setRefetch(false);
                setNuevoRegistro(false);
                setEdit(false);
              } 
            }).catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
          } else {
            await postExpReparacionDignaDetalle(
              values,
              idCaso,
              idReparacionDigna,
              token
            ).then(async (data) => {
              if (data?.status === 200 || data?.status === 201) {
                getExpReparacionDignaDetalle(idReparacionDigna, token).then(
                  (data) => {
                    setDetalleReparaciones(data?.results);
                    resetForm({});
                  }
                );
                toast.success("Registro creado con éxito..!");
                resetForm({});
                setNuevoRegistro(false);
                setRefetch(true);
                setRefetch(false);
              } 
            }).catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-5 lg:col-span-5 border p-3 rounded-md shadow-sm hover:shadow-md mt-1">
                <h1 className="mb-1 text-maivi-primaryFont text-sm">Agregar reparación digna</h1>
                <Divider />
                <div className="grid grid-cols-12 gap-3 mb-2">
                  <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-5 sm:col-span-5">
                    <InputSiavi
                      type="date"
                      name="fecha"
                      value={values.fecha}
                      onChange={handleChange}
                      validacion={true}
                      label="Fecha"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 flex items-end">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {numero_expediente}
                    </span>
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-4 flex items-end">
                    <ButtonSiavi
                      type="button"
                      disabled={isSubmitting}
                      text="Crear"
                      onClick={async()=>{
                       if(values.fecha !== ""){
                        setCargando(true);
                        await postExpReparacionDigna(
                          values,
                          idCaso,
                          idExpediente,
                          idExpedienteProceso,
                          token
                        ).then(async (data) => {
                          if (data?.status === 200 || data?.status === 201) {
                              toast.success("Reparación digna creada con éxito..!");
                              setRefetch(true);
                              setRefetch(false);
                              setCargando(false);
                          } else {
                            toast.success("Reparación digna creada con éxito..!");
                          }
                        }).catch(function (error) {
                          if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                          ) {
                            setCargando(false);
                            toast.error(error.response.data.message);
                          } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                          }
                        });
                       } else {
                         setCrearReparacion(true);
                         setTimeout(() => {
                           setCrearReparacion(false);
                         }, 4000);
                       }
                      }}
                    />
                  </div>
                </div>
                {
                  crearReparacion && <div className="mb-1"><Alert type="error" mensaje="Fecha es requerida" /></div>
                }
                <TableSiavi 
                  cargando={cargando}
                  header={[
                    'Expediente',
                    'Fecha',
                    'Acciones'
                  ]}
                  body={
                    expReparaciones?.results?.map((item) => (
                      <tr
                        className="hover:bg-maivi-gray-5 cursor-pointer"
                        onClick={() => {
                          setCargandoDetalle(true);
                          getExpReparacionDignaDetalle(item.id, token).then(
                            (data) => {
                              setCargandoDetalle(false);
                              setDetalleReparaciones(data?.results);
                            }
                          );
                          setIdReparacionDigna(item.id);
                        }}
                      >
                        <td className={tdClass}>{item?.expediente?.numero_expediente}</td>
                        <td className={tdClass}>{item?.fecha}</td>
                        <td className={tdClass}>
                          <button
                            type="button"
                            onClick={async () => {
                              setCargando(true);
                              await deleteExpReparacionDigna(item.id, token)
                                .then((data) => {
                                  if (data?.status === 200 || data?.status === 201) {
                                    setCargando(false);
                                    toast.success("Registro eliminado con éxito...!");
                                    setRefetch(true);
                                    setRefetch(false);
                                  } 
                                })
                                .catch(function (error) {
                                  if (
                                    error.response.status === 400 ||
                                    error.response.status === 401
                                  ) {
                                    setCargando(false);
                                    toast.error(error.response.data.message);
                                  } else if (error.response.status >= 500) {
                                    toast.error(
                                      "Error interno comuniquese con el administrador"
                                    );
                                  }
                                });
                            }}
                            className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                            rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                          >
                            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setNuevoRegistro(true);
                              setIdReparacionDigna(item.id);
                              setEdit(false);
                            }}
                              className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                            >
                           <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                />
              </div>
              <div className="col-span-12 xl:col-span-7 lg:col-span-7 border p-3 rounded-md shadow-sm hover:shadow-md mt-1">
                <h1 className="mb-1 text-maivi-primaryFont text-sm">Detalle de reparación digna</h1>
                <Divider />
                {
                   nuevoRegistro && 
                   <div className="grid grid-cols-12 gap-3">
                   <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
                     <div className="mt-1">
                      <SelectSiavi 
                        label="Reparación digna"
                        validacion={true}
                        name="reparacion_digna"
                        value={values.reparacion_digna}
                        valueInit=""
                        selectInit="Elegir reparación digna"
                        onChange={(e) =>{
                          setFieldValue("reparacion_digna", e.target.value);
                          reparaciones?.results.forEach(element => {
                            if(Number(e.target.value) === element.id){
                                setMostrarMonto(element.monto_requerido)
                            }
                          });
                        }}
                        list={reparaciones?.results?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })}
                      />
                     </div>
                   </div>
                 {  mostrarMonto &&
                     <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
                     <Label text="Monto"/>
                      <NumberFormat 
                        name="monto"
                        thousandSeparator={true} 
                        prefix={'Q'}
                        value={values.monto}
                        onChange={handleChange}
                        allowNegative={false}
                        className="mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
                        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
                        sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
                      />
                   </div>
                 }
                   <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-8 sm:col-span-8">
                     <TextAreaSiavi
                       label="Especificaciones"
                       name="especificaciones"
                       value={values.especificaciones}
                       onChange={handleChange}
                     />
                   </div>
                   <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-4 flex items-end">
                     <ButtonSiavi
                       type="submit"
                       disabled={isSubmitting}
                       text="Guardar"
                       onClick={handleSubmit}
                     />
                   </div>
                 </div>
                }
                {
                  cargandoDetalle && <Spinner />
                }
                {
                 (detalleReparaciones.length === 0) ?
                 <div className="mt-2">
                    <Alert type="info" mensaje="No hay datos, seleccione una reparación digna para ver el detalle"/>
                 </div> :
                 <TableSiavi 
                   headers={[
                    'Reparación digna',
                    'Monto',
                    'Especificaciones',
                    'Acciones'
                   ]}
                   body={detalleReparaciones?.map((item) => (
                    <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                      <td className={tdClass}>
                        {item?.reparacion_digna?.nombre}
                      </td>
                      <td className={tdClass}><NumberFormat value={item?.monto} displayType={'text'} thousandSeparator={true} prefix={'Q'} /></td>
                      <td className={tdClass}>{item?.especificaciones}</td>
                      <td className={tdClass}>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue('monto', item?.monto);
                            setFieldValue('reparacion_digna', item?.reparacion_digna.id);
                            setFieldValue('especificaciones', item?.especificaciones);
                            setFieldValue('idRepacionDignaDetalle', item?.id);
                            setEdit(true);
                            setNuevoRegistro(true);
                          }}
                          className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                            rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                        >
                          <PencilIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                           setCargandoDetalle(true);
                            deleteExpReparacionDignaDetalle(item.id, token).then(() => {
                              getExpReparacionDignaDetalle(idReparacionDigna, token).then(
                                (data) => {
                                  setCargandoDetalle(false);
                                  setDetalleReparaciones(data?.results);
                                }
                              );
                              toast.success('registro eliminado con éxito');
                            });
                          }}
                          className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                            rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                        >
                          <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                        </button>
                      </td>
                    </tr>
                  ))}
                 />
               
               }
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
