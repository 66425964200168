import React, { useEffect, useState } from "react";
import { InputSiavi } from "../../../../components/common/InputText";
import { getServicioPropio, getClasificacionBiblio } from "../services";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../components/common/Select";
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Return from "../../../../components/common/ButtonSalir";
import Toggle from "../../../../components/common/Toggle";
import { TextAreaSiavi } from "../../../../components/common/TextArea";

export const Crear = ({
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
}) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [servicios, setServicios] = useState([]);
    const [clasificaciones, setClasificaciones] = useState([]);

    useEffect(() => {
        getServicioPropio(token).then((data) => {
            setServicios(data);
        });
    }, [token]);

    useEffect(() => {
        getClasificacionBiblio(token).then((data) => {
            setClasificaciones(data);
        });
    }, [token]);

    const pages = [
        {
            name: "Biblioteca",
            href: "/catalogos/biblioteca",
            current: false,
        },
        { name: "Crear", href: "#", current: true },
    ];

    return (
        <form autoComplete="off" >
            <div className="grid-cols-12 container mx-auto -mt-32 mb-10 justify-center">
                <div className="col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
                    <div
                        className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center
          lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 pt-5"
                    >
                        <BreadCrumbTwo navegacion={pages} />
                        <div className="flex flex-row">
                            <h1 className="text-lg text-maivi-primaryFont mr-2">
                                Crear
                            </h1>
                            <Return ruta="/catalogos/biblioteca" />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 xl:col-span-12 lg:col-span-12 border-2 rounded-md p-5">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <InputSiavi
                                        type="text"
                                        name="nombre_documento"
                                        id="nombre_documento"
                                        onChange={handleChange}
                                        value={values.nombre_documento}
                                        validacion={true}
                                        label="*Nombre Documento"
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <TextAreaSiavi
                                        label="*Palabras Clave"
                                        validacion={true}
                                        name="palabras_clave"
                                        type="text"
                                        rows={2}
                                        columns={50}
                                        value={values.palabras_clave}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Por Servicio" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_servicio} name="por_servicio" />
                                    </div>
                                </div>
                                {values.por_servicio && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 ">
                                        <SelectSiavi
                                            label="Servicio"
                                            name="servicio"
                                            value={values.servicio}
                                            onChange={handleChange}
                                            validacion={true}
                                            valueInit=""
                                            selectInit="Elegir servicio"
                                            list={servicios?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.id + "DP" + index} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                )}
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <SelectSiavi
                                        label="Clasificación"
                                        name="clasificacion"
                                        value={values.clasificacion}
                                        onChange={handleChange}
                                        validacion={true}
                                        valueInit=""
                                        selectInit="Elegir clasificación"
                                        list={clasificaciones?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                    <Toggle enabled={values.estado} name="estado" />
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2 sm:col-span-4 flex flex-col justify-center items-center mt-5">
                                    <ButtonSiavi
                                        type="button"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        text="Crear"
                                    />
                                </div>
                                {isSubmitting && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};