/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { SelectSiavi } from "../../../../components/common/Select";
import { InputSiavi } from "../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../components/common/Button";
import { clases } from "../../../../helpers/clases";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import { AddPersona } from "./persona";
import { UserAddIcon, FilterIcon, EyeIcon, PrinterIcon, UserIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./detalleRef";
import { getPersonasCasoDetails } from "../editar/services";
import {
    getInstituciones,
    getServiciosExternos,
    postReferencia,
    postReferenciaExterna,
    getReferencias,
    deleteReferencias
} from "./services";
import FiltrosGestionInstitucion from "./filtrosInstitucion";
import ModalGenerico from "../../../../components/common/Modal";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import Label from "../../../../components/common/Label";
import Select from "react-select";
import TableSiavi from "../../../../components/common/TableSiavi";
import Divider from "../../../../components/common/Divider";
import toast from "react-hot-toast";
import Spinner from "../../../../components/common/Spinner";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import PdfViewer from "../../../../components/common/PdfViewer";
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
    //persona: Yup.string().required('persona es requerida')
});

const headersReferenciaExterna = [
    "Servicio",
    "Area",
    "Especificacion",
    "Eliminar"
];

const headersReferencias = [
    "Codigo",
    "Fecha",
    "Institucion",
    "Persona",
    "Persona representada",
    "Profesional",
    "Acciones",
];

export const FormReferencia = (props) => {
    const [verDetalle, setDetalle] = useState(false);
    const [referenciaDetalle, setReferenciaDetalle] = useState({});
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user);
    const [instituciones, setInstituciones] = useState([]);
    const [serviciosExternos, setServiciosExternos] = useState([]);
    const [referencias, setReferencias] = useState([]);
    const [institucionSeleccionada, setInstitucionSeleccionada] = useState("");
    const [addServiciosExternos, setAddServiciosExternos] = useState([]);
    const [persona, setPersona] = useState(undefined);
    const [nuevaPersona, setNuevaPersona] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [registroEliminar, setRegistroEliminar] = useState({});
    const [filtrar, setFiltrar] = useState(false);
    const [filtros, setFiltros] = useState("");
    const [referenciaPdf, setReferenciaPdf] = useState(false);
    const [referenciaPdfData, setReferenciaPdfData] = useState({});
    const [editar, setEditar] = useState(false);
    const [registro,setRegistro] = useState({});

    const optionsInstituciones = [];
    const orientacion = props?.orientacion;

    useEffect(() => {
        if (filtros !== "") {
            getInstituciones(filtros, token).then((data) => {
                setInstituciones(data?.results);
            });
        }
    }, [filtros]);

    useEffect(() => {
        getServiciosExternos(token).then((data) => {
            setServiciosExternos(data?.results);
        });
    }, []);

    useEffect(() => {
        setCargando(true);
        getReferencias(orientacion.id, token).then((data) => {
            setReferencias(data?.results);
            setCargando(false);
        });
    }, [refetch]);

    instituciones?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item?.nombre_fiscalia,
        };
        optionsInstituciones.push(reg);
    });

    const changeSelectInstituciones = (e) => {
        setInstitucionSeleccionada(e.value);
    };

    return (
        <Formik
            initialValues={{
                tipo_referencia: false,
                referencia_trabajo_social: false,
                es_nna: false,
                persona: null,
                representante: orientacion.persona_detalle.id,
                institucion_refiere: "",
                caso: null,
                orientacion: orientacion.id,
                motivos_referencia: "",
                asistencia_atencion_especifica: "",
                seguimiento: false,
            }}
            validationSchema={validaciones}
            onSubmit={async (values, resetForm) => {
                if (values.es_nna === true && persona === undefined) {
                    toast.error("Debe agregar una persona")
                } else if (institucionSeleccionada === "") {
                    toast.error("Debe seleccionar una institución donde refiere")
                }

                else {
                    values.institucion_refiere = institucionSeleccionada;
                    if (values.es_nna === false) {
                        values.persona = orientacion.persona_detalle.id;
                        values.representante = null
                    }

                    else if (values.es_nna) {
                        values.persona = persona.id;
                        values.representante = orientacion.persona_detalle.id;
                    }

                    await postReferencia(values, token).then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            await addServiciosExternos.forEach(async (element) => {
                                await postReferenciaExterna(element, data?.data?.id, token);
                            });
                            setRefetch(true);
                            setRefetch(false);
                            setPersona(undefined);
                            toast.success("referencia agregada con éxito..!");
                            resetForm({});
                        }
                    }).catch(function (error) {
                        if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                        ) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                (nuevaPersona) ?
                    <AddPersona
                        setNuevaPersona={setNuevaPersona}
                        setPersona={setPersona}
                        editar={editar}
                        registro={registro}
                        setRefetch={setRefetch}
                    /> :
                    <div className="grid grid-cols-12 gap-2 mt-3">
                        <div className="col-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-3 hover:shadow-md ">
                            <div className="grid grid-cols-12 gap-2">
                                <div className="col-span-12 flex justify-between">
                                    <Label text={`Agregar referencia a: ${orientacion.persona_detalle.primer_nombre} ${orientacion.persona_detalle.segundo_nombre} ${orientacion.persona_detalle.primer_apellido} ${orientacion.persona_detalle.segundo_apellido}`} />

                                    <div className="flex">
                                        {
                                            (persona !== undefined) &&
                                            <Label text={`${persona?.primer_nombre} ${persona?.primer_apellido}`} />
                                        }
                                        <ButtonAdd
                                            className="-mt-2 -mb-1 ml-2"
                                            type="button"
                                            onClick={() => {
                                                setEditar(false);
                                                setNuevaPersona(true);
                                                setFieldValue('es_nna', true);
                                            }}
                                            icon={<UserAddIcon className=" -mr-1 h-5" />}
                                        />

                                    </div>

                                </div>
                                <div className="col-span-12">
                                    <Divider />
                                </div>
                                <div className="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-2 flex flex-col justify-end items-end">
                                    <ButtonAdd
                                        className="ml-2"
                                        text="Filtrar"
                                        type="button"
                                        onClick={() => {
                                            setFiltrar(true);
                                        }}
                                        icon={<FilterIcon className=" h-5" />}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-8">
                                    <Label text="Institución donde refiere" />
                                    <Select
                                        placeholder="Seleccionar"
                                        isMulti={false}
                                        isSearchable={true}
                                        name="institucion"
                                        className="basic-multi-select text-xs text-maivi-primaryFont"
                                        options={optionsInstituciones}
                                        onChange={changeSelectInstituciones}
                                        maxMenuHeight={150}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                                    <TextAreaSiavi
                                        rows={3}
                                        cols={3}
                                        label="Motivos referencia"
                                        name="motivos_referencia"
                                        value={values.motivos_referencia}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                                    <TextAreaSiavi
                                        rows={3}
                                        cols={3}
                                        label="Asistencia atencion especifica"
                                        name="asistencia_atencion_especifica"
                                        value={values.asistencia_atencion_especifica}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-4 xl:col-span-6 flex items-end">
                                    {
                                        (isSubmitting) ?
                                            <Spinner /> :
                                            <ButtonSiavi
                                                type="button"
                                                text="Guardar"
                                                onClick={handleSubmit}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-3 hover:shadow-md">
                            <div className="grid grid-cols-12 gap-2 mx-2 pt-1">
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 -mt-2">
                                    <SelectSiavi
                                        id="servicio_externo"
                                        label="Servicio externo"
                                        validacion={true}
                                        name="servicio_externo"
                                        value={values.servicio_externo}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir servicio externo"
                                        list={
                                            serviciosExternos.map((item, index) =>
                                                <option key={item?.id + index} value={item.id}>
                                                    {item?.nombre}
                                                </option>
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 -mt-2">
                                    <InputSiavi
                                        label="Area donde refiere"
                                        name="area_institucion"
                                        value={values.area_institucion}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className="col-span-12">
                                    <TextAreaSiavi
                                        rows={3}
                                        label="Especificaciones"
                                        name="especificaciones_externas"
                                        value={values.especificaciones_externas}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="xl:col-span-9 lg:col-span-8"></div>
                                <div className="col-span-4 xl:col-span-3 lg:col-span-4 flex items-end">
                                    <ButtonSiavi
                                        text="Agregar"
                                        onClick={() => {
                                            let combo = document.getElementById("servicio_externo");
                                            let text = combo.options[combo.selectedIndex].text;
                                            let value = combo.options[combo.selectedIndex].value;
                                            let registro = {
                                                servicio: text,
                                                valorServicio: value,
                                                area_institucion: values.area_institucion,
                                                especificaciones_externas: values.especificaciones_externas
                                            }
                                            setAddServiciosExternos([...addServiciosExternos, registro]);
                                        }}
                                    />
                                </div>
                                <div className="col-span-12">
                                    <TableSiavi
                                        headers={headersReferenciaExterna}
                                        body={
                                            addServiciosExternos.map((item) =>
                                                <tr className={clases.tr}>
                                                    <td className={clases.td}>{item.servicio}</td>
                                                    <td className={clases.td}>{item.area_institucion}</td>
                                                    <td className={clases.td}>{item.especificaciones_externas}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className={clases.trashIcon}
                                                            onClick={async () => {
                                                                let filter = addServiciosExternos.filter(x => x.valorServicio !== item.valorServicio);
                                                                setAddServiciosExternos([...filter]);
                                                            }}
                                                        >
                                                            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                            <span className="tooltiptext text-xs">Eliminar</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 p-4 border rounded-lg hover:shadow-md">
                            <Label text="REFERENCIAS CREADAS" />
                            <TableSiavi
                                cargando={cargando}
                                headers={headersReferencias}
                                body={
                                    referencias?.map((item) =>
                                        <tr className={clases.tr}>
                                            <td className={clases.td}>{item?.codigo_referencia}</td>
                                            <td className={clases.td}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                                            <td className={clases.td}>{item?.gestion_institucion?.nombre_fiscalia}</td>
                                            <td className={clases.td}>{item?.persona?.primer_nombre} {item?.persona?.segundo_nombre} {item?.persona?.primer_nombre} {item?.persona?.segundo_apellido}</td>
                                            <td className={clases.td}>{item?.representante?.primer_nombre} {item?.representante?.segundo_nombre} {item?.representante?.primer_nombre} {item?.representante?.segundo_apellido}</td>
                                            <td className={clases.td}>{item?.profesional_crea?.first_name} {item?.profesional_crea?.last_name}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setDetalle(true);
                                                        setReferenciaDetalle(item);
                                                    }}
                                                >
                                                    <EyeIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Ver detalle</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setNuevaPersona(true);
                                                        setRegistro(item.persona);
                                                        setEditar(true);
                                                    }}
                                                >
                                                    <PencilAltIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Editar persona</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setNuevaPersona(true);
                                                        setRegistro(item.representante);
                                                        setEditar(true);
                                                    }}
                                                >
                                                    <UserIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Editar representante</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        let personas = [];
                                                        console.log(item);
                                                        personas.push(item.persona.id);

                                                        if (item.representante !== null) {
                                                            personas.push(item.representante.id);
                                                        }


                                                        let reporte = "";
                                                        let codigoIso = "";

                                                        if (item.es_nna || item.representante !== null) {
                                                            reporte = "referencia-externa-nna";
                                                            codigoIso = "DTS-FOR-2";
                                                        } else {
                                                            reporte = "referencia-externa-adultos";
                                                            codigoIso = "DTS-FOR-3";
                                                        }

                                                        let tipo_victima = 'Directa';

                                                        await getPersonasCasoDetails(personas, token).then((data) => {
                                                            let persona = {};
                                                            let representante = {};
                                                            data?.personas.forEach(per => {
                                                                if (per.id === item.persona.id) {
                                                                    persona = per;
                                                                }
                                                                if (per.id === item?.representante?.id) {
                                                                    representante = per;
                                                                }
                                                            });
                                                            setReferenciaPdfData({
                                                                usuario: usuarioLogin.first_name + ' ' + usuarioLogin.last_name,
                                                                orientacion: true,
                                                                codigo_referencia: item.codigo_referencia,
                                                                reporte: reporte,
                                                                motivo: item.motivo_referencia,
                                                                asistencia: item.asistencia_atencion_especifica,
                                                                fecha: item.fecha,
                                                                sede: item?.sede?.sede,
                                                                persona: persona,
                                                                representante: representante,
                                                                expediente: item?.caso?.numero_caso,
                                                                gestion_institucion: item?.gestion_institucion,
                                                                tipo_victima: tipo_victima,
                                                                referencia_interna: item?.referencias_internas?.nombre || 'Otra',
                                                                referencia_ts: item.referencia_ts,
                                                                profesional_ts: item.profesional_crea_referencia_ts,
                                                                profesional: item.profesional_crea,
                                                                codigo_referencia_ts: item.codigo_referencia_ts,
                                                                fecha_asignacion: item.fecha_asignacion,
                                                                servicios_externos: item?.servicios_externos,
                                                                delitos: [],
                                                                codigoIso: codigoIso
                                                            })
                                                            setReferenciaPdf(true);
                                                        });

                                                    }}
                                                >
                                                    <PrinterIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Imprimir</span>
                                                </button>

                                                <button
                                                    type="button"
                                                    className={clases.trashIcon}
                                                    onClick={async () => {
                                                        setRegistroEliminar(item);
                                                        setBorrar(true);
                                                    }}
                                                >
                                                    <TrashIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
                                                    <span className="tooltiptext text-xs">Eliminar</span>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            />
                        </div>

                        {referenciaPdf && (
                            <ModalAmplio
                                title={`Imprimir Referencia`}
                                open={true}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setReferenciaPdf(false);
                                }}
                                icon={
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <PrinterIcon className="h-6 w-6 text-green-600" />
                                    </div>
                                }
                                children={
                                    <PdfViewer
                                        data={referenciaPdfData}
                                        reporte={referenciaPdfData?.reporte}
                                        style={{ height: "80vh" }}
                                    />
                                }
                            />
                        )}

                        {borrar && (
                            <ModalGenerico
                                title={`Desea eliminar el registro?`}
                                open={borrar}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setBorrar(false);
                                }}
                                children={
                                    <BotonesConfirmar
                                        aceptar={async () => {
                                            setCargando(true);
                                            await deleteReferencias(registroEliminar.id, token).then(async (data) => {
                                                if (data?.status >= 200 || data?.status <= 210) {
                                                    setRefetch(true);
                                                    toast.success("referencia eliminada con éxito.");
                                                    setRefetch(false);
                                                    setCargando(false);
                                                    setBorrar(false);
                                                }
                                            }).catch(function (error) {
                                                if (
                                                    error.response.status >= 400 &&
                                                    error.response.status <= 450
                                                ) {
                                                    toast.error(error.response.data.message);
                                                    setCargando(false);
                                                } else if (error.response.status >= 500) {
                                                    toast.error("Error interno comuniquese con el administrador");
                                                    setCargando(false);
                                                }
                                            });
                                        }}
                                        cancelar={() => {
                                            setBorrar(false);
                                        }}
                                    />
                                }
                            />
                        )}
                        {filtrar && (
                            <ModalGenerico
                                title={"Filtrar gestión institución"}
                                open={filtrar}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setFiltrar(false);
                                }}
                                children={
                                    <FiltrosGestionInstitucion setFiltrar={setFiltrar} setFiltros={setFiltros} />
                                }
                            />
                        )}
                        {verDetalle && (
                            <ModalGenerico
                                title={"Detalle de Referencia"}
                                open={verDetalle}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setDetalle(false);
                                }}
                                children={
                                    <FormDetalle detalle={referenciaDetalle} />
                                }
                            />
                        )}
                    </div>
            )}


        </Formik>
    );
};
