/* eslint-disable react-hooks/exhaustive-deps */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HomeIcon } from '@heroicons/react/outline'
import { useSelector } from "react-redux";
import { getSede } from "./services";
import { store } from "../../app/redux/store/store";
import { ChangeSede } from "../../app/redux/actions/sede";

export default function Sede(props) {
    //const [open, setOpen] = useState(props.open)
    const [sedesList, setSedes] = useState([])
    const [sedeSelect, setSedeSelect] = useState(0)
    const [sedeDefault, setSedeDefault] = useState(0)

    const classIcon = "xl:h-9 lg:h-8 md:h-7 h-12 text-maivi-secondaryLightBlue";
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const sedeIdUser = useSelector((state) => state.loginReducer?.data?.usuario?.user?.sede?.id);
    const otrasSedes = useSelector((state) => state.loginReducer?.data?.usuario?.user.otra_sede);
    const token = useSelector((state) => state.loginReducer?.data?.token);
    let sedesL = otrasSedes;
    const cancelButtonRef = useRef(null)

    useEffect(() => {
        if (sedeIdUser) {
            getSede(sedeIdUser, token).then(data => {
                setSedeDefault(data);
                setSedeSelect(data.id);
            })
        }
        setSedes(sedesL);
    }, [sedeIdUser, token, otrasSedes]);

    const cambiarSedeHandler = () => {
        store.dispatch(ChangeSede(sedeSelect));
        props.setSedeOpen(false);
    }

    const onChangeSelect = (e) => {
        setSedeSelect(e.target.value);
    }

    return (
        <Transition.Root appear show={(otrasSedes && sedeIdUser) ? props.sedeOpen : false} as={Fragment} >
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => { props.setSedeOpen(true) }} >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                                    <HomeIcon className={classIcon} />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Seleccionar Sede:
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div>
                                            <select
                                                className="mt-1 block w-full pl-3 pr-10 py-2 sm:text-sm rounded-md"
                                                onChange={onChangeSelect}
                                                defaultValue={sedeDefault.sede}
                                            >
                                                <option value="0" disabled="disabled">Seleccionar Sede</option>
                                                <option value={sedeDefault.id} selected>{sedeDefault.sede}</option>
                                                {
                                                    sedesList.map((sd, index) =>
                                                        <option key={'SD' + index} value={sd.sede.id}>
                                                            {sd.sede.sede}
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">

                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                    onClick={() => {
                                        cambiarSedeHandler()
                                    }}
                                    disabled={sedeSelect === 0}
                                >
                                    Asignar
                                </button>

                                {sedeId > 0 &&
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => props.setSedeOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancelar
                                    </button>
                                }
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
