/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getAudienciasTodas, getProfesionales, audienciasTodas, exportarData, exportarResumen } from "../audienciasPlanificadas/services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { DocumentTextIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { FormDetalle } from "../audiencias/Detalle";
import { TableHistorialAudiencias } from "../audienciasPlanificadas/historial";
import { useSelector } from "react-redux";
import {
    Resumeaudienciacambiofecha,
    Resumeaudienciarealizadasnoprogramadas,
    Resumeaudienciaresultadoaudiencia,
    Resumeaudienciasreasignadas,
    Resumeaudienciatipoproceso
} from "./columnas";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "../audienciasPlanificadas/filtros";
import ModalBig from "../../../components/common/ModalTwo";
import ExportButton from "../../../components/common/ExportButton";

const tableColumns = [
    { title: "Fecha Planficada", value: "fecha_hora_planificada_inicia", tipo: "datetime", ordenar: "fecha_hora_planificada_inicia" },
    { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
    {
        title: "Nombre Querellante",
        value: null,
        values: [
            "querellante.primer_nombre",
            "querellante.segundo_nombre",
            "querellante.primer_apellido",
            "querellante.segundo_apellido"
        ],
        tipo: "text"
    },
   
    { title: "Audiencia", value: "tipo_audiencia.nombre", tipo: "text" },
    {
        title: "Realizada",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    { title: "Resultado audiencia", value: "resultado_audiencia.nombre", tipo: "text" },
        {
        title: "Abogado",
        value: null,
        values: [
            "profesional_asignado.first_name",
            "profesional_asignado.last_name"
        ],
        tipo: "text",
        ordenar: "profesional_asignado.username"
    }
];

const useFetchProfesionales = (token) => {
    const [state, setState] = useState({
        profesionales: [],
    });

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setState({
                profesionales: data,
            });
        });
    }, [token]);

    return state;
};

export const TableAudienciasTodas = () => {
    const [verDetalle, setDetalle] = useState(false);
    const [audienciaDetalle, setAudienciaDetalle] = useState({});
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const sede = useSelector((state) => state.ChangeSede.sedeId);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState("");
    const [historial, setHistorial] = useState(false);
    const [servicio, setServicio] = useState("");
    const [order, setOrder] = useState("completada&-fecha_completado&-fecha_hora_planificada_inicia")
    const permiso = "accionesseguimientoscaso";



    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "audiencias";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });
        // retorno = true;  // mm comentar para que funcione
        return retorno;

    }




    const exportResumen = (url) => {
        setTableLoading(true);
        exportarResumen(url, token).then(async data => {
            let fileName = 'Resumen';
            let dataExport = data;
            let columns = [];

            if (url === 'resumeaudienciacambiofecha') {
                columns = Resumeaudienciacambiofecha;
                fileName = 'AudienciasCambioFecha';
            }

            if (url === 'resumeaudienciasreasignadas') {
                columns = Resumeaudienciasreasignadas;
                fileName = 'AudienciasReasignadas';
            }

            if (url === 'resumeaudienciarealizadasnoprogramadas') {
                columns = Resumeaudienciarealizadasnoprogramadas;
                fileName = 'AudienciasNoProgramadas';
            }

            if (url === 'resumeaudienciaresultadoaudiencia') {
                columns = Resumeaudienciaresultadoaudiencia;
                fileName = 'AudienciasResultado';
            }

            if (url === 'resumeaudienciatipoproceso') {
                columns = Resumeaudienciatipoproceso;
                fileName = 'AudienciasTipoProceso';
            }

            let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo: 'xls' });
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: raw,
                redirect: 'follow'
            };
            await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
                .then(response => response.blob())
                .then(blob => URL.createObjectURL(blob))
                .then(url => {
                    setTableLoading(false);
                    window.open(url, '_blank');
                })
                .catch(error => console.log('error', error));
        })
    }

    const exportData = (tipo) => {
        setTableLoading(true);
        exportarData(sede, filters, order, token).then(async (data) => {
            const fileName = 'audiencias-planificadas';
            let dataExport = data;

            let columns = [
                { name: 'Fecha planificada inicia', value: 'fecha_hora_planificada_inicia', tipo: 'fechahora' },
                { name: 'Tipo de audiencia', value: 'tipo_audiencia' },
                { name: 'Organo Jurisdisccional', value: 'gestion_institucion' },
                { name: 'Expediente', value: 'caso' },
                { name: 'Querellante', value: 'querellante' },
                { name: 'Titular del Expediente', value: 'profesional_crea' },
                { name: 'Profesional Asignado', value: 'profesional_asignado' }
            ];

            let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: raw,
                redirect: 'follow'
            };
            await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
                .then(response => response.blob())
                .then(blob => URL.createObjectURL(blob))
                .then(url => {
                    setTableLoading(false);
                    window.open(url, '_blank');
                })
                .catch(error => console.log('error', error));
        });
    }

    const fetchAudiencias = () => {
        setTableLoading(true);
        audienciasTodas(urlFetch, sede, filters, order, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    };

    const fetchGetAudiencias = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setTableLoading(true);
            getAudienciasTodas(urlFetch, search, sede, filters, order, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    const handleVer = (item) => {
        setAudienciaDetalle(item);
        setDetalle(true);
    };

    useEffect(fetchAudiencias, [urlFetch, filters, sede, order]);
    useEffect(fetchGetAudiencias, [urlFetch, search, filters, sede, order]);
    const { profesionales } = useFetchProfesionales(token);
    const optionsProfesionales = [];

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: `${item?.first_name} ${item?.last_name} - Asignaciones: ${item?.asignaciones}`,
        };

        if (servicio === "") {
            optionsProfesionales.push(reg);
        } else {
            item.servicios.forEach(element => {
                if (element?.servicio_detalle?.nombre === servicio) {
                    optionsProfesionales.push(reg);
                }
            });
        }
    });

    const pages = [
        {
            name: "Funcionalidades",
            href: "/funcionalidades-generales",
            current: false,
        },
        { name: "Audiencias", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
                <BreadCrumb navegacion={pages} />
                <div>
                    <ButtonAdd
                        className="ml-2"
                        text="Filtros"
                        type="button"
                        onClick={() => {
                            setOpenFilters(true);
                        }}
                        icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                    />
                    <ButtonAdd
                        className="ml-2"
                        text="Refrescar"
                        type="button"
                        onClick={() => {
                            setFilters("");
                        }}
                        icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                    />
                    {(CheckPermiso('Exportar') === true) &&
                        <ExportButton
                            handlePdf={() => {
                                exportData('pdf');
                            }}
                            show={'ambos'}
                            handleExcel={() => {
                                exportData('xls');
                            }}
                        />
                    }
                    {(CheckPermiso('Resumen') === true) &&
                        <ExportButton
                            title={'Resumen'}
                            handleOpciones={exportResumen}
                            opciones={[
                                { text: 'Audiencias Asignadas', value: 'resumeaudienciasreasignadas' },
                                { text: 'Audiencias Cambio Fecha', value: 'resumeaudienciacambiofecha' },
                                { text: 'Audiencias No Programadas', value: 'resumeaudienciarealizadasnoprogramadas' },
                                { text: 'Audiencias Resultado Audiencia', value: 'resumeaudienciaresultadoaudiencia' },
                                { text: 'Audiencias Tipo Proceso', value: 'resumeaudienciatipoproceso' }
                            ]}
                        />
                    }
                </div>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        fetchPage={setUrlFech}
                        handleVer={handleVer}
                        pagination={true}
                        permiso={permiso}
                        agregar={false}
                        showEliminar={false}
                        showEditar={false}
                        showVer={true}
                        order={order}
                        setOrder={setOrder}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
            {verDetalle && (
                <ModalBig
                    title={"Detalle de audiencia planificada"}
                    open={verDetalle}
                    closeModal={() => { }}
                    cerrar={() => {
                        setDetalle(false);
                    }}
                    children={
                        <FormDetalle registro={audienciaDetalle} token={token} />
                    }
                />
            )}
            {historial && (
                <ModalBig
                    title={"Historial de audiencias"}
                    open={historial}
                    closeModal={() => { }}
                    cerrar={() => {
                        setHistorial(false);
                    }}
                    icon={
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                            <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                        </div>
                    }
                    children={
                        <TableHistorialAudiencias />
                    }
                />
            )}
            {openFilters && (
                <ModalGenerico
                    title={`Aplicar filtros`}
                    open={openFilters}
                    closeModal={() => { }}
                    cerrar={() => {
                        setOpenFilters(false);
                    }}
                    children={
                        <Filtros
                            setFilters={setFilters}
                            setOpenFilters={setOpenFilters}
                            token={token}
                            setServicio={setServicio}
                        />
                    }
                    icon={
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <FilterIcon className="h-6 w-6 text-green-600" />
                        </div>
                    }
                />
            )}
        </div>
    );
};
