/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Victimario } from "./victimario";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { ExpedientsSindicado } from "./expedientesSindicado";
import { getParentesco, putExpedienteSindicado,getExpedientes } from "./services";
import { SelectSiavi } from "../../../../../components/common/Select";
import {
  postExpedientesSindicado,
  putSindicado,
  postSindicado,
  getConfiguracion
} from "./services";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  primer_nombre: Yup.string().when("conoce_al_sindicado",{
    is:true,
    then:Yup.string().required("primer nombre es requerido")
  }),

  primer_apellido: Yup.string().when("conoce_al_sindicado",{
    is:true,
    then:Yup.string().required("primer apellido es requerido")
  }),

  sexo: Yup.string().when("conoce_al_sindicado",{
    is:true,
    then:Yup.string().required("sexo es requerido")
  }),

  pais_origen: Yup.string().when("en_migracion", {
    is: true,
    then: Yup.string().required("pais origen es requerido")
  }).nullable(),
  pais_destino: Yup.string().when("en_migracion", {
    is: true,
    then: Yup.string().required("pais destino es requerido")
  }).nullable()
});


const handleChangeInput = (values) => {
  let fechaNac = new Date(values.fecha_nacimiento);
  let yearNacimiento = fechaNac.getFullYear();
  let mesNacimiento = fechaNac.getMonth();
  const d = new Date();
  let yearActual = d.getFullYear();
  let edad = yearActual - yearNacimiento;
  let m = d.getMonth() - mesNacimiento;

  if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
    edad--;
  }

  if (values.fecha_nacimiento) {
    values.edad = edad;
  }
};
export const FormVictimario = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [cargando, setCargando] = useState(false);
  const [parentescos, setParentescos] = useState([]);
  const [conf, setConfig] = useState([]);
  const [ expedientes,setExpedientes ] = useState([]);

  const idCaso = props?.caso.id;
  const exp = props?.caso?.expedientes;

  useEffect(() => {
    getParentesco(token).then((data) => {
      setParentescos(data);
    });
  }, [token]);

  useEffect(() => {
    getConfiguracion(token).then((data) => {
       setConfig(data);
    });
  }, [token]);

  useEffect(() => {
    getExpedientes(idCaso, token).then((data) => {
       setExpedientes(data?.results);
    });
  }, [token]);

  return (
    <Formik
      initialValues={{
        primer_nombre: "",
        segundo_nombre: "",
        otros_nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        apellido_casada: "",
        fecha_nacimiento: null,
        edad: 0,
        nacionalidad: "",
        pseudonimo: "",
        sexo: "",
        peligrocidad: "",
        pertenece_a_pandilla: false,
        nombre_pandilla: "",
        tiene_tatuajes: false,
        especificacion_tatuaje: "",
        ubicacion_tatuaje: "",
        uso_de_arma_de_fuego: false,
        altura: 0,
        peso: 0,
        complexion: "",
        color_forma_cabello: "",
        color_tez: "",
        tiene_cicatrices: false,
        ubicacion_cicatriz: "",
        tiene_barba: false,
        tiene_bigote: false,
        forma_de_cejas: "",
        color_ojos: "",
        usa_anteojos: false,
        forma_de_nariz: "",
        caracteristicas_dentadura: "",
        vestimenta: "",
        estado_civil: "",
        orientacion_sexual: "",
        departamento: "",
        municipio: "",
        lee_escribe: "",
        escolaridad: "",
        profesion: "",
        religion: "",
        origen_etnico: "",
        origen_etnico_maya:"",
        idioma: "",
        idioma_preferencia: "",
        situacion_laboral: "",
        identidad_genero: "",
        correo_electronico: "",
        en_migracion: false,
        pais:"",
        pais_origen: "",
        pais_destino: "",
        relacion_con_la_victima: "",
        referencia_ubicacion: "",
        observaciones: "",
        conoce_al_sindicado: false,
        tiene_identificacion: false,
        no_identificacion: "",
        tipo_identificacion: "",
        expedientes: expedientes,
        expedienteSeleccionado: "",
        actualizarSindicado: false,
        idSindicado: 0,
        idCaso: idCaso,
        refetch: false,
        token: token,
        modalExpediente: false,
        isCrear: true,
        busqueda: "",
        relacion: "",
        habilita_idioma_maya: conf.habilita_idioma_maya,
        habilita_origen_etnico: conf.habilita_origen_etnico,
        nacionalidad_default: conf.nacionalidad_default
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { setFieldValue }) => {
        values.sede = sede;
        if (values.actualizarSindicado) {
          await putSindicado(values, token).then(async (data) => {
            if (data?.status === 200 || data?.status === 201) {
              if(values?.actualizar_expediente){
                await putExpedienteSindicado(values,idCaso,token);
              }
              toast.success("sindicado actualizado con éxito..!");
            } else {
              setError(true);
              setSuccess(false);
            }
          })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                setError(true);
                setSuccess(false);
                setMensaje(error.response.data.message);
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                setError(true);
                setSuccess(false);
                toast.error("Error interno comuniquese con el administrador");
                setMensaje("Error interno comuniquese con el administrador");
              }
            });

          setTimeout(() => {
            setError(false);
          }, 4000);
        } else {
          await postSindicado(values, token)
            .then((data) => {
              if (data?.status === 200 || data?.status === 201) {
                toast.success("Sindicado creado con éxito..!");
                setFieldValue("idSindicado", data.data.id);
                setFieldValue("actualizarSindicado", true);
               
              } else {
                setError(true);
                setSuccess(false);
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                setError(true);
                setSuccess(false);
                setMensaje(error.response.data.message);
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                setError(true);
                setSuccess(false);
                toast.error("Error interno comuníquese con el administrador");
                setMensaje("Error interno comuníquese con el administrador");
              }
            });

            setTimeout(() => {
              setError(false);
            }, 4000);
        }
      }}
    >
      {(props) => (
        <div>
          <Victimario
            {...props}
            hasError={hasError}
            customChange={handleChangeInput}
            success={success}
            mensaje={mensaje}
            setError={setError}
            setMensaje={setMensaje}
            setSuccess={setSuccess}
            idCaso={idCaso}
            exp={exp}
            configuracion={conf}
          />
          {props.values.modalExpediente && (
            <ModalAmplio
              title={`Desea asignar al sindicado ${props.values.primer_nombre} ${props.values.primer_apellido} a un expediente.`}
              open={props.values.modalExpediente}
              closeModal={() => { }}
              cerrar={() => {
                props.setFieldValue("modalExpediente", false);
              }}
              children={
                <div className="h-80 pb-10">
                  <div className="grid grid-cols-12 gap-2 items-end mt-4">
                    <div className="col-span-4">
                      <SelectSiavi
                        label="Expedientes"
                        validacion={true}
                        name="expedienteSeleccionado"
                        value={props.values.expedienteSeleccionado}
                        onChange={props.handleChange}
                        valueInit=""
                        selectInit="Seleccionar proceso"
                        list={
                          expedientes?.filter(x => x.asitencia_legal).map((item) => {
                            return (
                              <option
                                key={item.numero_expediente}
                                value={item.id}
                              >
                                {`${item?.numero_expediente} ${item?.tipo_proceso?.tipo_proceso}`}
                              </option>
                            );
                          })
                        }
                    />
                    </div>
                    <div className="col-span-4">
                      <SelectSiavi
                        label="Relación con la víctima"
                        validacion={true}
                        name="relacion"
                        value={props.values.relacion}
                        onChange={props.handleChange}
                        valueInit=""
                        selectInit="Relación con víctima"
                        list={
                          parentescos?.results?.map((item) => {
                            return (
                              <option key={item.nombre} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                        }
                    />
                    </div>
                    <div className="col-span-4">
                      <ButtonSiavi
                        type="button"
                        onClick={async () => {
                          if(props.values.relacion === "" || props.values.expedienteSeleccionado === ""){
                            setError(true);
                            setMensaje("Relación y expediente son requeridos.")
                          } else {
                            if (props.values.actualizarSindicado) {
                              setCargando(true);
                              await postExpedientesSindicado(
                                idCaso,
                                props.values.expedienteSeleccionado,
                                props.values.idSindicado,
                                props.values.relacion,
                                token
                              ).then((data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                  setError(false);
                                  toast.success("expediente actualizado con éxito..!");
                                  props.setFieldValue("modalExpediente", false);
                                  setCargando(false);
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 1000);
                                } 
                              })
                                .catch(function (error) {
                                  if (
                                    error.response.status >= 400 &&
                                    error.response.status <= 450
                                  ) {
                                    setError(true);
                                    setSuccess(false);
                                    setMensaje(error.response.data.message);
                                    toast.error(error.response.data.message);
                                    setCargando(false);
                                  } else if (error.response.status >= 500) {
                                    setError(true);
                                    setSuccess(false);
                                    toast.error("Error interno comuniquese con el administrador");
                                    setMensaje("Error interno comuniquese con el administrador");
                                    setCargando(false);
                                  }
                                });
                            }
                          }
                          setTimeout(() => {
                            setError(false);
                          }, 4000);
                        }}
                        disabled={props.isSubmitting}
                        text="Agregar"
                      />
                    </div>
                  </div>
                  <ExpedientsSindicado
                    idSindicado={props.values.idSindicado}
                    idCaso={props.values.idCaso}
                    setFieldValue={props.setFieldValue}
                  />
                  <div className="col-span-12 mb-2">
                    {cargando && (
                      <div className="mt-5 mb-5">
                        <Spinner />
                      </div>
                    )}
                    {success && <Alert type="success" mensaje={mensaje} />}
                    {hasError && <Alert type="error" mensaje={mensaje} />}
                  </div>
                </div>
              }
            />
          )}
        </div>
      )}
    </Formik>
  );
};
