import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteAccionesRecursos,
    getAccionesAccionesRecursos,
    postAccionesRecursos,
    putAccionesRecursos,
    getRecursos,
    getRecursosDisponibilidad
} from "./services";
import Spinner from "../../../../../../components/common/Spinner";
import Label from "../../../../../../components/common/Label";
import Toggle from "../../../../../../components/common/Toggle";
import CustomTable from "../../../../../../components/common/Table";
import ModalGenerico from "../../../../../../components/common/Modal";
import FormEliminar from "../../../../../../components/common/FormEliminar";
import { SelectSiavi } from "../../../../../../components/common/Select";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Recurso", value: "recurso.nombre", tipo: "text" },
    { title: "Recurso Disponibilidad", value: "recurso_disponibilidad.descripcion", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

const validaciones = Yup.object().shape({
    recurso: Yup.string()
        .required("Recurso es requerido")
});

const useFetchRecurso = (token) => {
    const [state, setState] = useState({
        recursos: [],
    });

    useEffect(() => {
        getRecursos(token).then((recursos) => {
            setState({
                recursos: recursos,
            });
        });
    }, [token]);

    return state;
};

const useFetchRecursoDisponibilidad = (token) => {
    const [state, setState] = useState({
        recursos_disponibilidad: [],
    });

    useEffect(() => {
        getRecursosDisponibilidad(token).then((recursos_disponibilidad) => {
            setState({
                recursos_disponibilidad: recursos_disponibilidad,
            });
        });
    }, [token]);

    return state;
};

export const GestionAccionesRecursos = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAccionRecurso, setIdAccionRecurso] = useState("");
    const [accionRecurso, setAccionRecurso] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idAccion = props?.accion?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const { recursos } = useFetchRecurso(token);
    const { recursos_disponibilidad } = useFetchRecursoDisponibilidad(token);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "accionesrecursos";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdAccionRecurso(id);
        setEliminar(true);
    };

    const handleEditar = (gestionAccionRecurso) => {
        setEdit(true);
        setAccionRecurso(gestionAccionRecurso);
    };

    const fetchAccionesRecursos = () => {
        getAccionesAccionesRecursos(idAccion, token).then((accionesrecursos) => {
            setDataTable(accionesrecursos);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchAccionesRecursos, [token, idAccion, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    accion: props.accion.id,
                    recurso: isEdit ? accionRecurso?.recurso?.id : "",
                    recurso_disponibilidad: isEdit ? accionRecurso?.recurso_disponibilidad?.id : "",
                    profesional_autorizado: "",
                    fecha_requerido: isEdit ? accionRecurso?.fecha_requerido : "",
                    fecha_hora_inicio: isEdit ? accionRecurso?.fecha_hora_inicio : "",
                    fecha_hora_fin: isEdit ? accionRecurso?.fecha_hora_fin : "",
                    fecha_autorizado: "",
                    estado: isEdit ? accionRecurso?.estado : true,
                    requiere_autorizacion: isEdit ? accionRecurso?.requiere_autorizacion : false,
                    autorizado: false,
                    recuerso_compartido: isEdit ? accionRecurso?.recuerso_compartido : false,
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putAccionesRecursos(accionRecurso.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.recurso = "";
                                    values.recurso_disponibilidad = "";
                                    values.profesional_autorizado = "";
                                    values.fecha_requerido = "";
                                    values.fecha_hora_inicio = "";
                                    values.fecha_hora_fin = "";
                                    values.fecha_autorizado = "";
                                    values.estado = true;
                                    values.requiere_autorizacion = false;
                                    values.autorizado = false;
                                    values.recuerso_compartido = false;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postAccionesRecursos(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 xl:col-span-6">
                                <Label text="Recurso" />
                                <div className="mt-1">
                                    <SelectSiavi
                                        name="recurso"
                                        value={values.recurso}
                                        onChange={handleChange}
                                        initValue=""
                                        selectInit="Elegir recurso"
                                        list={
                                            recursos?.results?.filter(x => x.estado === true).map((item) => {
                                                return (
                                                    <option key={item.nombre + "R" + item.id} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })
                                        }

                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6">
                                <Label text="Recurso Disponibilidad" />
                                <div className="mt-1">
                                    <SelectSiavi
                                        name="recurso_disponibilidad"
                                        value={values.recurso_disponibilidad}
                                        onChange={handleChange}
                                        initValue=""
                                        selectInit="Elegir recurso disponibilidad"
                                        list={
                                            recursos_disponibilidad?.results?.map((item) => {
                                                return (
                                                    <option key={item.descripcion + "RD" + item.id} value={item.id}>
                                                        {item.descripcion}
                                                    </option>
                                                );
                                            })
                                        }

                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6">
                                <InputSiavi
                                    type="date"
                                    name="fecha_requerido"
                                    value={values.fecha_requerido}
                                    onChange={handleChange}
                                    validacion={false}
                                    disabled={(props?.accion?.autorizado === true) ? true : false}
                                    label="Fecha Requerido"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-6">
                                <InputSiavi
                                    type="date"
                                    name="fecha_hora_inicio"
                                    value={values.fecha_hora_inicio}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Hora Inicio"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-6">
                                <InputSiavi
                                    type="date"
                                    name="fecha_hora_fin"
                                    value={values.fecha_hora_fin}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Hora Fin"
                                />
                            </div>

                            <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text="Recurso Compartido" />
                                <Toggle enabled={values.recuerso_compartido} name="recuerso_compartido" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-5">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteAccionesRecursos(idAccionRecurso, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
