/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../../components/common/InputText";
import { getSedes } from "./services";
import { SelectSiavi } from "../../../../components/common/Select"
import Spinner from "../../../../components/common/Spinner";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [sedes, setSedes] = useState([]);
    let filtros = "";

    useEffect(() => {
        getSedes(token).then((data) => {
            setSedes(data?.results);
        })
    }, []);

    const [state, setState] = useState({
        sede: "",
        estado: "",
        denuncia: "",
        causa_penal: "",
        fecha_inicio:"",
        fecha_fin:"",
        caso_cerrado:""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const filtroestados = [
        {
            id: "true",
            value: "Activo"
        },
        {
            id: "false",
            value: "Inactivo"
        }
    ]

    return (
        <div className="grid grid-cols-12 mx-5">
            <div className="col-span-12 mt-3">
                <div className="mb-2">
                    <SelectSiavi
                        name="sede"
                        value={state.sede}
                        label="Sede"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir sede"
                        list={
                            sedes?.map((item, index) => {
                                return (
                                    <option key={item?.sede + index} value={item.id}>
                                        {item?.sede}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="estado"
                        value={state.estado}
                        label="Estado"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Seleccionar estado"
                        list={
                            filtroestados?.map((item, index) => {
                                return (
                                    <option key={item.value + index} value={item.id}>
                                        {item.value}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="caso_cerrado"
                        value={state.caso_cerrado}
                        label="Estado expediente"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Seleccionar estado"
                        list={
                           [
                            <option value="false">
                                Abierto
                            </option>,
                              <option value="true">
                              Cerrado
                          </option>,
                           ]
                        }
                    />
                </div>
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4 mx-1">
                <InputSiavi
                    name="denuncia"
                    value={state.denuncia}
                    onChange={onChange}
                    label="Numero denuncia"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4">
                <InputSiavi
                    name="causa_penal"
                    value={state.causa_penal}
                    onChange={onChange}
                    label="Causa penal"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4 mx-1">
                <InputSiavi
                    type="date"
                    name="fecha_inicio"
                    value={state.fecha_inicio}
                    onChange={onChange}
                    label="Fecha inicio"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_fin"
                    value={state.fecha_fin}
                    onChange={onChange}
                    label="Fecha fin"
                />
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `fecha__gte=${state.fecha_inicio}&fecha__lte=${state.fecha_fin}&caso_cerrado=${state.caso_cerrado}&sede_caso=${state.sede}&estado=${state.estado}&no_denuncia=${state.denuncia}&expedientescausapenalcaso__numero_causa_penal=${state.causa_penal}`;
                        props.setUrlFech(undefined);
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
