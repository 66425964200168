import axios from "axios";
const { REACT_APP_API_URL } = process.env;

export const getGestionaCriterioCierres = async (urlPage, search, filters, order, token) => {
    try {
        let urlFetch = urlPage === undefined
            ? `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/?search=${search.value}&${filters}&ordering=${order}`
            : `${urlPage}&search=${search.value}&${filters}&ordering=${order}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const gestionaCirterioCierres = async (urlPage, filters, order, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/?${filters}&ordering=${order}`
                : `${urlPage}&${filters}&ordering=${order}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const putGestionaCriterioCierre = async (id, data, token) => {
    try {
        let body = {
            gestiona_cierre: data.gestiona_cierre,
            fecha_gestiona: data.fecha_gestiona,
            cierre_autorizado: data.cierre_autorizado,
            observaciones: data.observaciones,
            expediente: data.expediente,
            caso: data.caso,
            motivo: data.motivo,
            profesional_solicita: data.profesional_solicita,
            profesional_gestiona: data.profesional_gestiona
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpCriterioCierreGestionar = async (idExpediente, idCierre, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientecriteriocierre/?expediente=${idExpediente}&gestionado=${idCierre}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};