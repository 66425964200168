import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getPersonaVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestionpersona/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPaisVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/pais/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipioVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEscolaridadVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/escolaridad/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrigenEtnicoVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/origenetnico/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomaVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idioma/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReligionVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/religion/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiscapacidadVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/discapacidad/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDocumentoVictimario = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipodocumento/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPersonaDiscapacidad = async (idPersona, idDiscapacidad, token) => {
    try {
        let body = {
            persona: idPersona,
            discapacidad: Number(idDiscapacidad)
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/personadiscapacidad/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDiscapacidades = async (id, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/personadiscapacidad/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSindicados = async (buscar, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/sindicado/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonas = async (buscar, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getParentesco = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/parentesco/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientes = async (idCaso,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expediente/?caso=${idCaso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpedientesSindicado = async (idCaso, idExpediente, idSindicado, relacion, token) => {
    try {
        let body = {
            caso: idCaso,
            expediente: (idExpediente === 0) ? null : idExpediente,
            sindicado: idSindicado,
            victima_sindicado: Number(relacion)
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientesindicado/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSindicado = async (values, token) => {
    try {
        let body = {
            sede: values?.sede,
            primer_nombre: values?.primer_nombre,
            segundo_nombre: values?.segundo_nombre,
            otros_nombres: values?.otros_nombres,
            primer_apellido: values?.primer_apellido,
            segundo_apellido: values?.segundo_apellido,
            apellido_casada: values?.apellido_casada,
            fecha_nacimiento: (values?.fecha_nacimiento === "") ? null : values?.fecha_nacimiento,
            edad: values?.edad,
            nacionalidad: values?.nacionalidad,
            pseudonimo: values?.pseudonimo,
            sexo: values?.sexo,
            pertenece_a_pandilla: values?.pertenece_a_pandilla,
           // nombre_pandilla: values?.nombre_pandilla,
            tiene_tatuajes: values?.tiene_tatuajes,
            especificacion_tatuaje: values?.especificacion_tatuaje,
            ubicacion_tatuaje: values?.ubicacion_tatuaje,
            uso_de_arma_de_fuego: values?.uso_de_arma_de_fuego,
            altura: values?.altura,
            peso: values?.peso,
            complexion: values?.complexion,
            color_forma_cabello: values?.color_forma_cabello,
            color_tez: values?.color_tez,
            tiene_cicatrices: values?.tiene_cicatrices,
            ubicacion_cicatriz: values?.ubicacion_cicatriz,
            tiene_barba: values?.tiene_barba,
            tiene_bigote: values?.tiene_bigote,
            forma_de_cejas: values?.forma_de_cejas,
            color_ojos: values?.color_ojos,
            usa_anteojos: values?.usa_anteojos,
            forma_de_nariz: values?.forma_de_nariz,
            caracteristicas_dentadura: values?.caracteristicas_dentadura,
            vestimenta: values?.vestimenta,
            estado_civil: (values?.estado_civil === "") ? null : values?.estado_civil,
            orientacion_sexual: (values?.orientacion_sexual === "") ? null : values?.orientacion_sexual,
            departamento: (values?.departamento === "") ? null : values?.departamento,
            municipio: (values?.municipio === "") ? null : values?.municipio,
            peligrocidad: (values?.peligrocidad === "") ? null : values?.peligrocidad,
            lee_escribe: (values?.lee_escribe === "") ? null : values?.lee_escribe,
            escolaridad: (values?.escolaridad === "") ? null : values?.escolaridad,
            profesion: (values?.profesion === "") ? null : values?.profesion,
            religion: (values?.religion === "") ? null : values?.religion,
            origen_etnico: (values?.origen_etnico === "") ? null : values?.origen_etnico,
            idioma: (values?.idioma === "") ? null : values?.idioma,
            idioma_preferencia: (values?.idioma_preferencia === "") ? null : Number(values?.idioma_preferencia),
            situacion_laboral: (values?.situacion_laboral === "") ? null : Number(values?.situacion_laboral),
            identidad_genero: (values?.identidad_genero === "") ? null : Number(values?.identidad_genero),
            correo_electronico: values?.correo_electronico,
            en_migracion: values?.en_migracion,
            pais: (values?.pais === "") ? null : values?.pais,
            pais_origen: (values?.pais_origen === "") ? null : values?.pais_origen,
            pais_destino: (values?.pais_destino === "") ? null : values?.pais_destino,
            relacion_con_la_victima: (values?.relacion_con_la_victima === "") ? null : values?.relacion_con_la_victima,
            referencia_ubicacion: values?.referencia_ubicacion,
            observaciones: values?.observaciones,
            conoce_al_sindicado: values?.conoce_al_sindicado,
            tiene_identificacion: values?.tiene_identificacion,
            no_identificacion: (values?.no_identificacion === "") ? null : values?.no_identificacion,
            tipo_identificacion: (values?.tipo_identificacion === "") ? null : values?.tipo_identificacion,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/sindicado/${values.idSindicado}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postSindicado = async (values, token) => {
    try {
       
        let body = {
            sede: values?.sede,
            primer_nombre: values?.primer_nombre,
            segundo_nombre: values?.segundo_nombre,
            otros_nombres: values?.otros_nombres,
            primer_apellido: values?.primer_apellido,
            segundo_apellido: values?.segundo_apellido,
            apellido_casada: values?.apellido_casada,
            fecha_nacimiento: (values?.fecha_nacimiento === "") ? null : values?.fecha_nacimiento, 
            edad: values?.edad,
            nacionalidad: values?.nacionalidad,
            pseudonimo: values?.pseudonimo,
            sexo: values?.sexo,
            pertenece_a_pandilla: values?.pertenece_a_pandilla,
            //nombre_pandilla: values?.nombre_pandilla,
            tiene_tatuajes: values?.tiene_tatuajes,
            especificacion_tatuaje: values?.especificacion_tatuaje,
            ubicacion_tatuaje: values?.ubicacion_tatuaje,
            uso_de_arma_de_fuego: values?.uso_de_arma_de_fuego,
            altura: values?.altura,
            peso: values?.peso,
            complexion: values?.complexion,
            color_forma_cabello: values?.color_forma_cabello,
            color_tez: values?.color_tez,
            tiene_cicatrices: values?.tiene_cicatrices,
            ubicacion_cicatriz: values?.ubicacion_cicatriz,
            tiene_barba: values?.tiene_barba,
            tiene_bigote: values?.tiene_bigote,
            forma_de_cejas: values?.forma_de_cejas,
            color_ojos: values?.color_ojos,
            usa_anteojos: values?.usa_anteojos,
            forma_de_nariz: values?.forma_de_nariz,
            caracteristicas_dentadura: values?.caracteristicas_dentadura,
            vestimenta: values?.vestimenta,
            estado_civil: (values?.estado_civil === "") ? null : values?.estado_civil,
            orientacion_sexual: (values?.orientacion_sexual === "") ? null : values?.orientacion_sexual,
            departamento: (values?.departamento === "") ? null : values?.departamento,
            municipio: (values?.municipio === "") ? null : values?.municipio,
            peligrocidad: (values?.peligrocidad === "") ? null : values?.peligrocidad,
            lee_escribe: (values?.lee_escribe === "") ? null : values?.lee_escribe,
            escolaridad: (values?.escolaridad === "") ? null : values?.escolaridad,
            profesion: (values?.profesion === "") ? null : values?.profesion,
            religion: (values?.religion === "") ? null : values?.religion,
            origen_etnico: (values?.origen_etnico === "") ? null : values?.origen_etnico,
            idioma: (values?.idioma === "") ? null : values?.idioma,
            idioma_preferencia: (values?.idioma_preferencia === "") ? null : Number(values?.idioma_preferencia),
            situacion_laboral: (values?.situacion_laboral === "") ? null : Number(values?.situacion_laboral),
            identidad_genero: (values?.identidad_genero === "") ? null : Number(values?.identidad_genero),
            correo_electronico: values?.correo_electronico,
            en_migracion: values?.en_migracion,
            pais: (values?.pais === "") ? null : values?.pais,
            pais_origen: (values?.pais_origen === "") ? null : values?.pais_origen,
            pais_destino: (values?.pais_destino === "") ? null : values?.pais_destino,
            relacion_con_la_victima: (values?.relacion_con_la_victima === "") ? null : values?.relacion_con_la_victima,
            referencia_ubicacion: values?.referencia_ubicacion,
            observaciones: values?.observaciones,
            conoce_al_sindicado: values?.conoce_al_sindicado,
            tiene_identificacion: values?.tiene_identificacion,
            no_identificacion: (values?.no_identificacion === "") ? null : values?.no_identificacion,
            tipo_identificacion: (values?.tipo_identificacion === "") ? null : values?.tipo_identificacion,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/sindicado/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientesSindicado = async (idSindicado, idCaso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesindicado/?sindicado=${idSindicado}&caso=${idCaso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionDireccionVictimario = async (data, token) => {

    try {
        let body = {
            zona: data.zona,
            nombre_grupo: data.nombre_grupo,
            numero_vialidad: data.numero_vialidad,
            nombre_vialidad: data.nombre_vialidad,
            numero_casa: data.numero_casa,
            letra_casa: data.letra_casa,
            referencia_ubicacion: data.referencia_ubicacion,
            otros: data.otros,
            sindicado: Number(data.sindicado),
            tipo_contacto: Number(data.tipo_contacto),
            municipio: Number(data.municipio),
            grupo_habitacional: Number(data.grupo_habitacional),
            vialidad: Number(data.vialidad),
            direccion_principal: data.direccion_principal,
            persona: null
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestiondireccion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionDireccionVictimario = async (id, data, token) => {
    try {
        let body = {
            zona: data.zona,
            nombre_grupo: data.nombre_grupo,
            numero_vialidad: data.numero_vialidad,
            nombre_vialidad: data.nombre_vialidad,
            numero_casa: data.numero_casa,
            letra_casa: data.letra_casa,
            referencia_ubicacion: data.referencia_ubicacion,
            otros: data.otros,
            sindicado: Number(data.sindicado),
            tipo_contacto: Number(data.tipo_contacto),
            municipio: Number(data.municipio),
            grupo_habitacional: Number(data.grupo_habitacional),
            vialidad: Number(data.vialidad),
            direccion_principal: data.direccion_principal,
            persona: null
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestiondireccion/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionTelefonoVictimario = async (data, token) => {

    try {
        let body = {
            numero_telefono: data.numero_telefono,
            tipo_contacto: Number(data.tipo_contacto),
            sindicado: Number(data.sindicado),
            telefono_principal: data.telefono_principal,
            enviar_sms : false,
            persona: null
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestiontelefono/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionTelefonoVictimario = async (id, data, token) => {
    try {
        let body = {
            numero_telefono: data.numero_telefono,
            tipo_contacto: Number(data.tipo_contacto),
            sindicado: Number(data.sindicado),
            telefono_principal: data.telefono_principal,
            persona: null
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestiontelefono/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const buscarSindicado = async (pais, tipo_documento, documento, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/sindicado/?pais=${pais}&tipo_documento=${tipo_documento}&search=${documento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpedienteSindicado = async (values,idCaso, token) => {
    try {
        let body = {
            caso: idCaso,
            expediente: values.id_expediente,
            sindicado: values.idSindicado,
            victima_sindicado: values.relacion_victima
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientesindicado/${values.id_expediente_sindicado}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionRSSindicado = async (data, token) => {
    try {
        let body = {
            usuario_red: data.usuario_red,
            link_perfil: data.link_perfil,
            red_social: Number(data.red_social),
            sindicado: Number(data.sindicado),
            estado: data.estado,
            persona: null
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestionredessociales/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionRSSindicado = async (id, data, token) => {
    try {
        let body = {
            usuario_red: data.usuario_red,
            link_perfil: data.link_perfil,
            red_social: Number(data.red_social),
            sindicado: Number(data.sindicado),
            estado: data.estado,
            persona: null
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionredessociales/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const getExpedientesSindicados = async (caso,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesindicado/?caso=${caso}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const deleteExpedientesSindicados = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientesindicado/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConfiguracion = async(token) => {
    const id = 1;
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/configuracion/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPeligrosidadSindicado = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/peligrosidadsindicado/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDelitosSindicado = async (sindicado,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expsindicadodelito/?estado=true&expediente_sindicado=${sindicado}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCambioEstado = async (id,data,token) => {
    try {
        let body = {
            estado: data.estado,
            caso: data.caso,
            sindicado: data.sindicado,
            usuario_ultimo_cambio_estado: data.usuario
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientesindicado/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};
