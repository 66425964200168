/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { FormReferencia } from "./referencia";
import { DocumentAddIcon, PaperClipIcon } from "@heroicons/react/solid";
import { clases } from "../../../../../../helpers/clases";
import { GestionArchivos } from "../../../../../../components/ArchivosSiavi/archivos";
import { ClipboardCheckIcon } from "@heroicons/react/outline";
import { AgregarFormCasoPersona } from "../casoPersona";
import { Acciones } from "../trabajo_social/Acciones";
import { FilePen, GearIcon } from "../../../../../../helpers/icons";
import { AgregarConsentimiento } from "./consentimiento";
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import ModalTwo from "../../../../../../components/common/ModalTwo";
import ModalBig from "../../../../../../components/common/ModalTwo";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import { useSelector } from "react-redux";
import moment from "moment";

import { ConsentimientoEditable } from "../trabajo_social/consentimiento_editable";

const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
const thClass = "px-3 py-1 text-left font-medium text-maivi-primaryFont text-xs";

const tableColumns = [
    "Persona",
    "Servicio",
    "Profesional",
    "Fecha",
    "Acciones"
];

export const Psicología = (props) => {
    const [addReferencia, setAddReferencia] = useState(false);
    const [expediente, setExpediente] = useState("");
    const [expedienteFull, setExpedienteFull] = useState("");
    const [orientacion, setOrientacion] = useState("");
    const [idServicio, setIdServicio] = useState("");
    const [archivos, setArchivos] = useState(false);
    const [casoPersona, setCasoPersona] = useState(false);
    const [idCasoPersona, setIdCasoPersona] = useState(false);
    const [consentimiento, setConsentimiento] = useState(false);
    const [registro, setRegistro] = useState({});
    const [acciones, setAcciones] = useState(false);
    const [consentimientoEditable, setConsentimientoEditable] = useState(false);
    const caso = props.caso
    const delitos = props.delitos;


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAV";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });

        //        retorno = true;  // mm comentar para que funcione
        //           retorno = true;  // mm comentar para que funcione
        return retorno;

    }


    return (
        <div className="mt-2 p-3 border rounded-lg">
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
            </div>
            <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 mt-1">
                    < TableSiavi
                        headers={tableColumns}
                        body={
                            caso?.expedientes?.map((item) =>
                                item.servicio.ps === true
                                    ?
                                    <tr className={clases.tr}>
                                        <td className={clases.td}>{item?.caso_persona?.persona?.primer_nombre} {item?.caso_persona?.persona?.segundo_nombre} {item?.caso_persona?.persona?.primer_apellido} {item?.caso_persona?.persona?.segundo_apellido}</td>
                                        <td className={clases.td}>{item?.servicio?.nombre}</td>
                                        <td className={clases.td}>{item?.profesional_asignado?.first_name} {item?.profesional_asignado?.last_name}</td>
                                        <td className={clases.td}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                                        <td>
                                            {(CheckPermiso('PSAgregarReferencia') === true) &&
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setExpediente(item.id);
                                                        setAddReferencia(true);
                                                        setIdServicio(item.servicio.id);
                                                    }}
                                                >
                                                    <DocumentAddIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Agregar referencia</span>
                                                </button>
                                            }
                                            {(CheckPermiso('PSAdjuntarDocumentos') === true) &&
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setExpediente(item.id);
                                                        setRegistro(item);
                                                        setArchivos(true);
                                                    }}
                                                >
                                                    <PaperClipIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Adjuntar documentos</span>
                                                </button>
                                            }
                                            {(CheckPermiso('PSInstrumentos') === true) &&

                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setExpediente(item.id);
                                                        setExpedienteFull(item);
                                                        setIdCasoPersona(item.caso_persona.id);
                                                        setOrientacion(item.orientacion);
                                                        setCasoPersona(true);
                                                        setIdServicio(item.servicio.id);
                                                    }}
                                                    className={clases.addIcon}>
                                                    <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    <span className="tooltiptext text-xs">Instrumentos</span>
                                                </button>
                                            }
                                            {(CheckPermiso('PSAcciones') === true) &&

                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setExpediente(item);
                                                        setAcciones(true);
                                                        setIdServicio(item.servicio.id);
                                                    }}
                                                    className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                                                    <GearIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    <span className="tooltiptext text-xs">Acciones</span>
                                                </button>
                                            }
                                            {(CheckPermiso('PSConsentimiento') === true) &&

                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setExpediente(item);
                                                        setConsentimiento(true);
                                                        setIdServicio(item.servicio.id);
                                                        setIdCasoPersona(item?.caso_persona?.id);
                                                    }}
                                                    className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                                                    <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    <span className="tooltiptext text-xs">Consentimiento</span>
                                                </button>
                                            }
                                            {(CheckPermiso('PSInstrumentosEditable') === true) &&

                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setExpediente(item);
                                                        setConsentimientoEditable(true);
                                                        setIdServicio(item.servicio.id);
                                                        setIdCasoPersona(item?.caso_persona?.id);
                                                    }}
                                                    className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                                                    <FilePen className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    <span className="tooltiptext text-xs">Instrumento editable</span>
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    :
                                    ''

                            )
                        }
                    />
                </div>
                {consentimientoEditable && (
                    <ModalTwo
                        title="Instrumento editable"
                        open={consentimientoEditable}
                        closeModal={() => { }}
                        cerrar={() => {
                            setConsentimientoEditable(false);
                        }}
                        children={
                            <ConsentimientoEditable
                                thClass={thClass}
                                tdClass={tdClass}
                                delitos={delitos}
                                idCasoPersona={idCasoPersona}
                                servicio={idServicio}
                                registro={expediente}
                            />
                        }
                    />
                )}

                {
                    addReferencia &&
                    <ModalBig
                        title="Agregar referencia"
                        open={true}
                        closeModal={() => { }}
                        children={
                            <FormReferencia
                                caso={caso}
                                expediente={expediente}
                                servicio={idServicio}
                                setMostrarReferencia={setAddReferencia}
                            />
                        }
                        cerrar={() => {
                            setAddReferencia(false);
                        }}
                    />
                }
                {archivos && (
                    <ModalAmplio
                        title={`Adjuntar archivos`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setArchivos(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <PaperClipIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        children={
                            <GestionArchivos
                                idRecurso={expediente}
                                recurso="EXPEDIENTEPSICOLOGICO"
                                claseDocumento="expedientepsicologico"
                                expediente={registro.id}
                                caso={registro.caso}
                            />
                        }
                    />
                )}
                {casoPersona && (
                    <ModalTwo
                        title="Formulario Caso Persona"
                        open={casoPersona}
                        closeModal={() => { }}
                        cerrar={() => {
                            setCasoPersona(false);
                        }}
                        children={
                            <AgregarFormCasoPersona
                                thClass={thClass}
                                tdClass={tdClass}
                                idCasoPersona={idCasoPersona}
                                idExpediente={expediente}
                                idOrientacion={orientacion}
                                servicio={idServicio}
                                expedienteFull={expedienteFull}
                                directores={false}
                            />
                        }
                    />
                )}
                {acciones && (
                    <ModalBig
                        title={`Agregar acciones`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAcciones(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <GearIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        children={
                            <Acciones infoExpediente={expediente} recurso="EXPEDIENTEPSICOLOGICO" claseDocto="expedientepsicologico" />
                        }
                    />
                )}
                {consentimiento && (
                    <ModalTwo
                        title="Consentimiento informado"
                        open={consentimiento}
                        closeModal={() => { }}
                        cerrar={() => {
                            setConsentimiento(false);
                        }}
                        children={
                            <AgregarConsentimiento
                                thClass={thClass}
                                tdClass={tdClass}
                                delitos={delitos}
                                idCasoPersona={idCasoPersona}
                                servicio={idServicio}
                                expediente={expediente}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};