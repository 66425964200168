
const {
    REACT_APP_API_URL
} = process.env;

export const getSeleccion = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/catalogoseleccion/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSeleccion = async ({ nombre, estado, encuestas }, token) => {
    try {
        let body = {
            nombre,
            estado,
            encuestas
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoseleccion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSeleccion = async (id, { nombre, estado, encuestas }, token) => {
    try {
        let body = {
            nombre,
            estado,
            encuestas
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoseleccion/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSeleccion = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoseleccion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSeleccionDetalle = async ({ valor, catalogo_seleccion, estado }, token) => {
    try {
        let body = {
            valor,
            catalogo_seleccion,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeleccionDetalle = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/?page_size=1000&catalogo_seleccion=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
