export const columsCasosList = [
  { name: "ID", value: "id" },
  { name: "Autoriza cierre", value: "autoriza_cierre" },
  { name: "Caso cerrado", value: "caso_cerrado" },
  { name: "Causas penales", value: "causas_penales" },
  { name: "Estado", value: "estado" },
  { name: "Estatus", value: "estatus" },
  { name: "Etapas procesales", value: "etapas_procesales" },
  { name: "Fecha", value: "fecha" },
  { name: "Fecha autoriza cierre", value: "fecha_autoriza_cierre" },
  { name: "Fecha cierre", value: "fecha_cierre" },
  { name: "Fecha creacion", value: "fecha_creacion" },
  { name: "Fecha denuncia", value: "fecha_denuncia" },
  { name: "Fiscal acargo", value: "fiscal_acargo" },
  { name: "Fiscalia actual", value: "fiscalia_actual" },
  { name: "Impugnaciones", value: "impugnaciones" },
  { name: "Litigio estrategico", value: "litigio_estrategico" },
  { name: "Medicina", value: "medicina" },
  { name: "Motivo cierre", value: "motivo_cierre" },
  { name: "No. denuncia", value: "no_denuncia" },
  { name: "Numero caso", value: "numero_caso" },
  { name: "Orientacion", value: "orientacion" },
  { name: "Psicologia", value: "psicologia" },
  { name: "Psiquiatria", value: "psiquiatria" },
  { name: "Querellantes", value: "querellantes" },
  { name: "Representante legal", value: "representante_legal" },
  { name: "Sede caso", value: "sede_caso" },
  { name: "Sede creacion", value: "sede_creacion" },
  { name: "Servicios", value: "servicios" },
  { name: "Servicios cantidad", value: "servicios_cantidad" },
  { name: "Sindicados conocidos", value: "sindicados_conocidos" },
  { name: "Total sindicados conocidos", value: "total_sindicados_conocidos" },
  { name: "Total sindicados desconocido", value: "total_sindicados_desconocido" },
  { name: "Total sindicados hombres", value: "total_sindicados_hombres" },
  { name: "Total sindicados mujeres", value: "total_sindicados_mujeres" },
  { name: "Trabajo social", value: "trabajo_social" },
  { name: "Usuario cierre", value: "usuario_cierre" },
  { name: "Usuario creacion", value: "usuario_creacion" },
  { name: "Victima", value: "victima" },
  { name: "Victimas", value: "victimas" },
  { name: "Victimas indirectas", value: "victimas_indirectas" }
];

export const columsExpedientesList = [
  { name: "Id Caso", value: "id" },
  { name: "Tipo de Delito", value: "delitos" },
  { name: "Número de Expediente IV", value: "numero_caso" },
  { name: "Número Orientacion", value: "orientacion" },
  { name: "Número de Denuncia MP", value: "no_denuncia" },
  { name: "Número de Causa del Juzgado", value: "numero_causa_juzgado" },
  { name: "Servicio", value: "servicio" },
  { name: "Tipo proceso", value: "tipo_proceso" },
  { name: "Etapa procesal", value: "etapa_procesal" },
  { name: "Sede", value: "sede_caso" },
  { name: "Fecha", value: "fecha", tipo: 'fecha' },
  { name: "Departamento hecho", value: "departamento_hecho" },
  { name: "Municipio hecho", value: "municipio_hecho" },
  { name: "Edad hecho", value: "edad_hecho" },
  { name: "Id Víctima", value: "victima_id" },
  { name: "Víctima", value: "victima" },
  { name: "Id Querellante", value: "querellantes" },
  { name: "Querellante", value: "querellante" },
  { name: "Sentencia", value: "sentencia" },
  { name: "Tipo Reparación", value: "reparacion_digna" },
  { name: "Evaluación diagnostica", value: "inicial" },
  { name: "Evaluación final de impacto", value: "final" },
  { name: "Profesional Orientacion", value: "profesional_crea" },
  { name: "Profesional Caso", value: "profesional_crea_caso" }
];
