/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { InputSiavi } from "../../../../../components/common/InputText";
import { SelectSiavi } from "../../../../../components/common/Select";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { XCircleIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { PaperClipIcon, TrashIcon, PencilAltIcon } from "@heroicons/react/outline";
import { bloqueoFecha } from "../../../../../helpers/utils";
import {
  getServicios,
  getInstituciones,
  getMotivosNoAdmisibilidad,
  postNoAdmisibilidad,
  deleteNoAdmisibilidad,
  getGestionInstituciones,
  getTipoPersonas,
  getDelitos,
  postDelitos,
  deleteDelitos,
  getProcesosOrientacion,
  getTipoDoctos,
  getTipoConsulta,
  postTipoConsulta,
  deleteTipoConsulta,
  getProfesionales,
  getOrientacionProfesionales,
  postProfesionalOrientacion,
  deleteProfesionalOrientacion,
  putProfesionalOrientacion
} from "../services";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import ButtonFiles from "../../../../../components/common/ButtonFiles";
import Divider from "../../../../../components/common/Divider";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import Label from "../../../../../components/common/Label";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import FormEliminar from "../../common/FormEliminar";

export const Orientacion = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  hasError,
  success,
  mensaje,
  idOrientacion,
  setFieldValue,
  serviciosBrindados,
  procesosBrindados,
  setError,
  setMensaje,
  orientacion
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const fechaActual = new Date();

  const [delitosAgregados, setDelitosAgregados] = useState(
    values.delitosAgregados
  );

  const [tipoConsultaAgregados, setTipoConsultaAgregados] = useState(
    values.tipoConsultaAgregados
  );

  const [motivosNoAdmisibilidad, setmotivosNoAdmisibilidad] = useState(
    values.motivosNoAdmisibilidad
  );

  const [servicios, setServicios] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [noAdmisibilidad, setNoAdmisibilidad] = useState([]);
  const [gestionInstituciones, setGestionInstituciones] = useState([]);
  const [tipoPersonas, setTipoPersonas] = useState([]);
  const [delitos, setDelitos] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [tipodoctos, setTipodoctos] = useState([]);
  const [tipoconsulta, setTipoConsulta] = useState([]);
  const [eliminar, setEliminar] = useState(false);
  const [nombreEliminar, setNombreEliminar] = useState(false);
  const [itemEliminar, setItemEliminar] = useState("");
  const [eliminarNoAdmisibilidad, setEliminarNoAdmisibilidad] = useState(false);
  const [eliminarDelito, setEliminarDelito] = useState(false);
  const [eliminarTipoConsulta, setEliminarTipoConsulta] = useState(false);
  const [eliminarProfesional, setEliminarProfesional] = useState(false);
  const [crear, setCrear] = useState(false);
  const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
  const [procesosSeleccionadas, setProcesosSelecionadas] = useState([]);
  const [archivos, setArchivos] = useState(false);
  const [bloquear, setBloquear] = useState(false);
  const [ultimoServicio, setUltimoServicio] = useState({});
  const [profesionales, setProfesionales] = useState([]);
  const [profesionalesOrientacion, setProfesionalesOrientacion] = useState([]);
  const [refetchProfesionales, setRefetchProfesionales] = useState(false);
  const [editar, setEditar] = useState(false);
  const animatedComponents = makeAnimated();
  const options = [];
  const optionsProcesos = [];

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, []);

  useEffect(() => {
    getInstituciones(token).then((data) => {
      setInstituciones(data);
    });
  }, []);

  useEffect(() => {
    getMotivosNoAdmisibilidad(token).then((data) => {
      setNoAdmisibilidad(data);
    });
  }, []);

  useEffect(() => {
    getGestionInstituciones(token).then((data) => {
      setGestionInstituciones(data);
    });
  }, []);

  useEffect(() => {
    getTipoPersonas(token).then((data) => {
      setTipoPersonas(data);
    });
  }, []);

  useEffect(() => {
    getDelitos(token).then((data) => {
      setDelitos(data);
    });
  }, []);

  useEffect(() => {
    if (ultimoServicio?.value !== undefined) {
      getProcesosOrientacion(ultimoServicio, token).then((data) => {
        setProcesos(data);
      });
    }
  }, [ultimoServicio]);

  useEffect(() => {
    getTipoDoctos(token).then((data) => {
      setTipodoctos(data);
    });
  }, []);

  useEffect(() => {
    getTipoConsulta(token).then((data) => {
      setTipoConsulta(data);
    });
  }, []);

  useEffect(() => {
    getProfesionales(sede, token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getOrientacionProfesionales(idOrientacion, token).then((data) => {
      setProfesionalesOrientacion(data);
    });
  }, [refetchProfesionales]);

  const changeSelectServicios = (e) => {
    setUltimoServicio(e[e.length - 1]);
    setServiciosSeleccionados(e);
  };

  const changeSelectProcesos = (e) => {
    setProcesosSelecionadas(e);
  };

  servicios?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    options.push(reg);
  });

  procesos?.results?.forEach((item) => {
    let reg = {
      value: item?.tipo_proceso?.id,
      label: item?.tipo_proceso?.tipo_proceso,
    };
    optionsProcesos.push(reg);
  });

  const deleteManys = async (item) => {
    if (eliminarNoAdmisibilidad) {
      deleteNoAdmisibilidad(item.id, token);
      let old = motivosNoAdmisibilidad.filter((x) => x.id !== item.id);
      setmotivosNoAdmisibilidad([...old]);
    } else if (eliminarDelito) {
      deleteDelitos(item.id, token);
      let old = delitosAgregados.filter((x) => x.id !== item.id);
      setDelitosAgregados([...old]);
    } else if (eliminarTipoConsulta) {
      deleteTipoConsulta(item.id, token);
      let old = tipoConsultaAgregados.filter((x) => x.id !== item.id);
      setTipoConsultaAgregados([...old]);
    } else if (eliminarProfesional) {
      await deleteProfesionalOrientacion(item.id, token).then((data) => {
        if (data.status >= 200 && data.status <= 210) {
          setRefetchProfesionales(true);
          setRefetchProfesionales(false);
        }
      }).catch(function (error) {
        if (error.response.status >= 400 && error.response.status <= 410) {
          toast.error(error.response.data.detail);
        } else if (error.response.status >= 500) {
          toast.error("Error interno comuniquese con el administrador");
        }
      });;
    }
  };

  const headersConsultas = [
    "Nombre",
    "Borrar"
  ];

  const headersMotivos = [
    "Motivo",
    "Borrar"
  ];

  const headers = [
    "Nombre",
  ];

  const buttonRemoveClass = `inline-flex items-center p-1 border border-transparent rounded-md
  text-maivi-primaryFont hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue`;

  return (
    <div>
      <fieldset disabled={(values.habilitarFormulario === true || values.habilitarFormulario === false) ? true : false}>
        <form
          autoComplete="off"
          onChange={customChange(values)}
          className="-mt-2"
        >
          <div className="flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-col mt-3 justify-start items-center">
            <div className="mb-1">
              <span className="text-maivi-primaryFont text-xs">PERSONA:</span>
              <span className="ml-2 text-maivi-primaryFont bg-green-200 p-1 rounded-lg text-sm">
                {values?.persona?.primer_nombre} {values?.persona?.primer_apellido}
              </span>
            </div>
            <div className="ml-10 mb-3 xl:mb-0 lg:mb-0 md:mb-0 flex flex-row items-center">
              <span className="text-maivi-primaryFont text-xs">
                COMO LE GUSTA QUE LE LLAMEN:
              </span>
              <InputSiavi
                name="como_le_gusta_que_le_llamen"
                value={values.como_le_gusta_que_le_llamen}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row ml-10 justify-center items-center">
              <span className="text-maivi-primaryFont text-xs">
                *TIPO PERSONA:
              </span>
              <SelectSiavi
                id="tipo_persona"
                validacion={true}
                name="tipo_persona"
                value={values.tipo_persona}
                valueInit=""
                selectInit="Tipo persona"
                onChange={(e) => {
                  setFieldValue('tipo_persona', e.target.value);
                  let combo = document.getElementById("tipo_persona");
                  let text = combo.options[combo.selectedIndex].text;
                  if (text === "NO APLICA") {
                    setBloquear(true);
                  } else {
                    setBloquear(false);
                  }
                }}
                list={
                  tipoPersonas?.results?.map((item, index) => {
                    return (
                      <option key={item.nombre + index} value={item.id} className="text-xs">
                        {item.nombre}
                      </option>
                    );
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 mt-3">
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <div className="flex flex-row justify-between">
                <h1 className="mb-1 text-base">Denuncia</h1>
                <div className="flex flex-row border border-maivi-primaryLightBlue rounded-md p-2 items-center -mt-1 mb-2">
                  <input
                    name="presentoDenuncia"
                    type="checkbox"
                    checked={values.presentoDenuncia}
                    onChange={handleChange}
                    className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                  />
                  <Label text="Presento denuncia" />
                </div>
              </div>
              <Divider />
              <div className="grid grid-cols-12 items-end gap-2">
                {values.presentoDenuncia && (
                  <div className="lg:col-span-12 xl:col-span-6 md:col-span-12 col-span-12">
                    <InputSiavi
                      label="Fecha denuncia"
                      type="date"
                      name="fechaDenuncia"
                      value={values.fechaDenuncia}
                      onChange={handleChange}
                      maximo={bloqueoFecha()}
                    />
                  </div>
                )}
                {values.presentoDenuncia && (
                  <div className="lg:col-span-12 xl:col-span-6 md:col-span-12 col-span-12">
                    <InputSiavi
                      label="No. Denuncia"
                      type="text"
                      name="numeroDenuncia"
                      value={values.numeroDenuncia}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {values.presentoDenuncia && (
                  <div className="lg:col-span-12 xl:col-span-6 md:col-span-12 col-span-12">
                    <SelectSiavi
                      label="Institución donde la presentó:"
                      name="institucionDenuncia"
                      value={values.institucionDenuncia}
                      onChange={handleChange}
                      valueInit=""
                      selectInit="Seleccionar"
                      list={
                        gestionInstituciones?.results?.filter(x => x.institucion_detalle.denuncia_valida === true).map((item, index) => {
                          return (
                            <option
                              key={item.nombre_fiscalia + index}
                              value={item.id}
                              className="text-xs"
                            >
                              {item.nombre_fiscalia}
                            </option>
                          );
                        })
                      }
                    />
                  </div>
                )}
              </div>

            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <h1 className="mb-1 text-base">Derivación</h1>
              <Divider />
              <div className="grid grid-cols-12 gap-2 items-end">
                <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
                  <SelectSiavi
                    label="Entidad que deriva"
                    name="entidad_deriva"
                    value={values.entidad_deriva}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Seleccionar"
                    list={
                      instituciones?.results?.map((item, index) => {
                        return (
                          <option
                            key={item.nombre + index}
                            value={item.id}
                            className="text-xs"
                          >
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
                  <SelectSiavi
                    label="Tipo Documento Derivación"
                    name="tipoDocto"
                    value={values.tipoDocto}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Seleccionar"
                    list={
                      tipodoctos?.results?.map((item) => {
                        return (
                          <option
                            key={item.nombre}
                            value={item.id}
                            className="text-xs"
                          >
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
                  <InputSiavi
                    label="No. de documento"
                    type="text"
                    name="numeroDocumento"
                    value={values.numeroDocumento}
                    onChange={handleChange}
                  />
                </div>
                <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
                  <InputSiavi
                    label="Fecha documento"
                    type="date"
                    name="fechaResolucion"
                    value={values.fechaResolucion}
                    onChange={handleChange}
                    maximo={`${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-${fechaActual.getDate()}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 mt-2">
            <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <h1 className="mb-1 text-base">Tipo de Orientación </h1>
              <div className="grid grid-cols-12 gap-2 items-start">
                <div className="lg:col-span-9 xl:col-span-8 sm:col-span-6 md:col-span-6 col-span-12 ">
                  <SelectSiavi
                    id="tipo_consulta"
                    name="tipo_consulta"
                    value={values.tipo_consulta}
                    onChange={handleChange}
                    initValue=""
                    selectInit="Seleccionar"
                    list={
                      tipoconsulta?.results?.map((item, index) => {
                        return (
                          <option
                            key={item.nombre + index}
                            value={item.id}
                            className="text-xs"
                          >
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-4 mt-1">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      let existe;
                      let combo = document.getElementById("tipo_consulta");
                      let selected = combo.options[combo.selectedIndex].text;
                      let selectedValue = combo.options[combo.selectedIndex].value;
                      tipoConsultaAgregados.forEach(element => {
                        if (element.tipo_consulta.nombre === selected) {
                          existe = true;
                        }
                      });
                      if (existe) {
                        toast.error('El registro seleccionado ya fue agregado.');
                      } else {
                        if (selected !== "Seleccionar") {
                          postTipoConsulta(idOrientacion, selectedValue, token).then(
                            (data) => {
                              let result = data;
                              setTipoConsultaAgregados([...tipoConsultaAgregados, result]);
                            }
                          );
                        }
                      }
                    }}
                    text="Agregar"
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-12 overflow-x-auto mt-2">
                <TableSiavi
                  headers={headersConsultas}
                  body={
                    tipoConsultaAgregados?.map((item, index) => (
                      <tr key={item?.tipo_consulta?.nombre + index}>
                        <td className="px-2 row-td">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.tipo_consulta.nombre}
                          </div>
                        </td>
                        <td className="px-2">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                setEliminar(true);
                                setItemEliminar(item);
                                setEliminarTipoConsulta(true);
                                setNombreEliminar(item.tipo_consulta.nombre);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                />
              </div>
            </div>

            <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <h1 className="mb-1 text-base">Delitos </h1>
              <div className="grid grid-cols-12 gap-2 items-start">
                <div className="lg:col-span-9 xl:col-span-8 sm:col-span-6 md:col-span-6 col-span-12 ">
                  <SelectSiavi
                    id="delitos"
                    name="delitos"
                    validacion={true}
                    value={values.delitos}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Seleccionar"
                    list={
                      delitos?.results?.map((item, index) => {
                        return (
                          <option
                            key={item.nombre + index}
                            value={item.id}
                            className="text-xs"
                          >
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-4 mt-1">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      let existe;
                      let combo = document.getElementById("delitos");
                      let selected = combo.options[combo.selectedIndex].text;
                      let selectedValue = combo.options[combo.selectedIndex].value;
                      delitosAgregados.forEach(element => {
                        if (element.delito.nombre === selected) {
                          existe = true;
                        }
                      });
                      if (existe) {
                        toast.error('El registro seleccionado ya fue agregado.');
                      } else {
                        if (selected !== "Seleccionar") {
                          postDelitos(idOrientacion, selectedValue, token).then(
                            (data) => {
                              let result = data;
                              setDelitosAgregados([...delitosAgregados, result]);
                            }
                          );
                        }
                      }
                    }}
                    text="Agregar"
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-12  overflow-x-auto mt-2">
                <TableSiavi
                  headers={headersConsultas}
                  body={
                    delitosAgregados?.map((item, index) => (
                      <tr key={item?.delito?.nombre + index}>
                        <td className="px-2 row-td">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.delito.nombre}
                          </div>
                        </td>
                        <td className="px-2">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                setEliminar(true);
                                setItemEliminar(item);
                                setEliminarDelito(true);
                                setNombreEliminar(item.delito.nombre);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                />
              </div>
            </div>

            <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <h1 className="mb-1 text-base">Observaciones</h1>
              <TextAreaSiavi
                cols={50}
                rows={3}
                value={values.observaciones}
                name="observaciones"
                onChange={handleChange}
              />
            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <h1 className="text-base items-start">Servicios brindados</h1>
              <TableSiavi
                empty="No existen servicios asociados"
                headers={headers}
                body={
                  serviciosBrindados?.map((item, index) => (
                    <tr
                      key={item.servicio_detalle.nombre + index}
                    >
                      <td className="px-6 whitespace-nowrap text-xs text-maivi-primaryFont py-1">
                        {item.servicio_detalle.nombre}
                      </td>

                    </tr>
                  ))
                }
              />
              <h1 className="mt-1 text-base items-start">Procesos</h1>
              <TableSiavi
                empty="No existen procesos asociados"
                headers={headers}
                body={
                  procesosBrindados?.map((item, index) => (
                    <tr
                      key={item.tipoproceso_detalle.tipo_proceso + index}
                    >
                      <td className="px-6 whitespace-nowrap text-xs text-maivi-primaryFont py-1">
                        {item.tipoproceso_detalle.tipo_proceso}
                      </td>

                    </tr>
                  ))
                }
              />
            </div>

            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <div className="grid grid-cols-12 items-end gap-2">
                <div className="col-span-12 flex flex-row justify-between ">
                  <div className="flex flex-row justify-center items-center">
                    <span>¿Es admisible?</span>
                    <div className={`flex flex-row  border border-maivi-primaryLightBlue rounded-md p-2 mx-2 ${(bloquear) ? 'bg-red-200' : ''}`}>
                      <input
                        disabled={bloquear}
                        name="casoAdmitido"
                        type="checkbox"
                        checked={values.casoAdmitido}
                        onChange={(e) => {
                          setFieldValue('casoAdmitido', e.target.checked);
                          if (e.target.checked === true) {
                            setFieldValue('casoNoAdmitido', false);
                            setFieldValue('valorCaso', true);
                          } else if (e.target.checked === false && values.casoNoAdmitido === false) {
                            setFieldValue('valorCaso', null);
                          }
                        }}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                      />
                      <Label text="SI" />
                    </div>
                    <div className="flex flex-row border border-maivi-primaryLightBlue rounded-md p-2 items-center">
                      <input
                        name="casoNoAdmitido"
                        type="checkbox"
                        checked={values.casoNoAdmitido}
                        onChange={(e) => {
                          setFieldValue('casoNoAdmitido', e.target.checked);
                          if (e.target.checked === true) {
                            setFieldValue('casoAdmitido', false);
                            setFieldValue('valorCaso', false);
                          } else if (e.target.checked === false && values.casoAdmitido === false) {
                            setFieldValue('valorCaso', null);
                          }
                        }}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                      />
                      <Label text="No" />
                    </div>
                  </div>
                  <ButtonFiles
                    type="button"
                    onClick={() => {
                      setArchivos(true);
                    }}
                  />
                </div>
                {
                  values.casoNoAdmitido &&
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-2">
                      <div className="col-span-8">
                        <SelectSiavi
                          id="motivoNoAdmisibilidad"
                          name="motivoNoAdmisibilidad"
                          value={values.motivoNoAdmisibilidad}
                          onChange={handleChange}
                          initValue=""
                          selectInit="Seleccionar"
                          list={
                            noAdmisibilidad?.results?.map((item, index) => {
                              return (
                                <option
                                  key={item.nombre + index}
                                  value={item.id}
                                  className="text-sm"
                                >
                                  {item.nombre}
                                </option>
                              );
                            })
                          }
                        />
                      </div>
                      <div className="col-span-4">
                        <ButtonSiavi
                          type="button"
                          onClick={() => {
                            let combo = document.getElementById(
                              "motivoNoAdmisibilidad"
                            );
                            let selected = combo.options[combo.selectedIndex].text;
                            let selectedValue =
                              combo.options[combo.selectedIndex].value;
                            if (selected !== "Seleccionar") {
                              postNoAdmisibilidad(
                                idOrientacion,
                                selectedValue,
                                token
                              ).then((data) => {
                                let result = data;
                                setmotivosNoAdmisibilidad([
                                  ...motivosNoAdmisibilidad,
                                  result,
                                ]);
                              });
                            }
                          }}
                          text="Agregar"
                        />
                      </div>
                    </div>
                  </div>
                }
                {
                  values.casoNoAdmitido &&
                  <div className="col-span-12 mb-4">
                    <TableSiavi
                      headers={headersMotivos}
                      body={
                        motivosNoAdmisibilidad?.map((item, index) => (
                          <tr
                            key={item?.noadmisibilidad_detalle.nombre + index}
                            className="bg-green-50"
                          >
                            <td className="px-6 whitespace-nowrap">
                              <div className="text-xs text-maivi-primaryFont">
                                {item?.noadmisibilidad_detalle?.nombre}
                              </div>
                            </td>
                            <td className="px-6 whitespace-nowrap">
                              <div className="text-xs text-maivi-primaryFont">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setEliminar(true);
                                    setItemEliminar(item);
                                    setEliminarNoAdmisibilidad(true);
                                    setNombreEliminar(item.noadmisibilidad_detalle.nombre);
                                  }}
                                  className={buttonRemoveClass}
                                >
                                  Borrar
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    />
                  </div>
                }
                <div className="col-span-12 ">
                  <TextAreaSiavi
                    label="Motivo Consulta"
                    cols={50}
                    rows={2}
                    value={values.comentarios}
                    name="comentarios"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      values.serviciosAgregados = serviciosSeleccionados;
                      values.procesosAgregados = procesosSeleccionadas;
                      values.motivosAdmisibilidad = [];
                      values.motivosNoAdmisibilidad = [];
                      values.delitosAgregados = delitosAgregados;
                      values.tipoConsultaAgregados = tipoConsultaAgregados;

                      if (values.casoAdmitido === true && values.presentoDenuncia === true) {
                        if (delitosAgregados.length > 0) {
                          setCrear(true);
                        } else {
                          toast.error('Delitos son requeridos');
                        }
                      } else if (values.casoAdmitido === false && motivosNoAdmisibilidad.length === 0) {
                        toast.error("Motivos de no admisibilidad son requeridos")
                      }
                      else {
                        handleSubmit();
                      }
                    }}
                    disabled={isSubmitting}
                    text="Actualizar"
                  />
                </div>
                <div className="col-span-12">
                  {isSubmitting && (
                    <div className="mt-5 mb-5">
                      <Spinner />
                    </div>
                  )}
                  {success && <Alert type="success" mensaje={mensaje} />}
                  {hasError && <Alert type="error" mensaje={mensaje} />}
                </div>
                {crear && (
                  <ModalGenerico
                    title={`Seleccione los servicios y los procesos`}
                    open={crear}
                    closeModal={() => { }}
                    cerrar={() => {
                      setCrear(false);
                    }}
                    children={
                      <div className="h-80 pb-10">
                        <div className="mt-5 mb-5">
                          <Label text="Servicios" />
                          <Select
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            isMulti
                            name="atenciones"
                            className="basic-multi-select mb-5 text-xs text-maivi-primaryFont"
                            classNamePrefix="seleccione"
                            options={options}
                            onChange={changeSelectServicios}
                            maxMenuHeight={140}
                          />
                          <Label text="Procesos" />
                          <Select
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            isMulti
                            name="servicios"
                            className="basic-multi-select text-xs text-maivi-primaryFont"
                            classNamePrefix="seleccione"
                            options={optionsProcesos}
                            onChange={changeSelectProcesos}
                            maxMenuHeight={140}
                          />
                        </div>
                        <ButtonSiavi
                          type="button"
                          onClick={() => {
                            values.serviciosAgregados = serviciosSeleccionados;
                            values.procesosAgregados = procesosSeleccionadas;
                            values.motivosAdmisibilidad = [];
                            values.motivosNoAdmisibilidad = [];
                            values.delitosAgregados = delitosAgregados;
                            values.tipoConsultaAgregados = tipoConsultaAgregados;

                            if (procesosSeleccionadas.length === 0) {
                              setError(true);
                              setMensaje("Procesos son requeridos");
                              setTimeout(() => {
                                setError(false);
                              }, 3000);
                            } else {
                              handleSubmit();
                            }
                          }}
                          disabled={isSubmitting}
                          text="Guardar y continuar"
                        />
                        <div className="col-span-12 mt-4">
                          {isSubmitting && (
                            <div className="mt-5 mb-5">
                              <Spinner />
                            </div>
                          )}
                          {success && <Alert type="success" mensaje={mensaje} />}
                          {hasError && <Alert type="error" mensaje={mensaje} />}
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
            {eliminar && (
              <ModalGenerico
                title={`¿Esta seguro de eliminar el registro ${nombreEliminar}?`}
                open={eliminar}
                closeModal={() => { }}
                cerrar={() => {
                  setEliminar(false);
                }}
                icon={
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                  </div>
                }
                children={
                  <FormEliminar
                    aceptar={() => {
                      deleteManys(itemEliminar);
                      setEliminar(false);
                    }}
                    cancelar={() => {
                      setEliminar(false);
                    }}
                  />
                }
              />
            )}
            {archivos && (
              <ModalAmplio
                title={`Adjuntar archivos`}
                open={true}
                closeModal={() => { }}
                cerrar={() => {
                  setArchivos(false);
                }}
                icon={
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <PaperClipIcon className="h-6 w-6 text-green-600" />
                  </div>
                }
                children={
                  <GestionArchivos
                    idRecurso={idOrientacion}
                    recurso="ORIENTACION"
                    claseDocumento="orientaciones"
                    caso={orientacion?.caso?.id}
                    expediente={null}
                  />
                }
              />
            )}
          </div>
        </form>
      </fieldset>

     <div className="grid grid-cols-12">
     <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
        <h1 className="mb-1 text-base">Profesional que acompaña </h1>
        <div className="grid grid-cols-12 gap-2 items-start">
          <div className="lg:col-span-9 xl:col-span-8 sm:col-span-6 md:col-span-6 col-span-12 ">
            <SelectSiavi
              id="profesional"
              name="profesional"
              validacion={true}
              value={values.profesional}
              onChange={handleChange}
              valueInit=""
              selectInit="Seleccionar"
              list={
                profesionales?.results?.map((item, index) => {
                  return (
                    <option
                      key={item.first_name + index}
                      value={item.id}
                      className="text-xs"
                    >
                      {item.first_name} {item.last_name}
                    </option>
                  );
                })
              }
            />
          </div>
          <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-4 mt-1">
            <ButtonSiavi
              type="button"
              onClick={async () => {
                let existe;
                let combo = document.getElementById("profesional");
                let selected = combo.options[combo.selectedIndex].text;
                let selectedValue = combo.options[combo.selectedIndex].value;

                if (editar) {
                  await putProfesionalOrientacion(itemEliminar.id, idOrientacion, selectedValue, token).then((data) => {
                    if (data.status >= 200 && data.status <= 210) {
                      setRefetchProfesionales(true);
                      setRefetchProfesionales(false);
                      toast.success('profesional actualizado con éxito.');
                    }
                  }).catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 410) {
                      toast.error(error.response.data.detail);
                    } else if (error.response.status >= 500) {
                      toast.error("Error interno comuniquese con el administrador");
                    }
                  });;
                } else {

                  delitosAgregados.forEach(element => {
                    if (element.delito.nombre === selected) {
                      existe = true;
                    }
                  });
                  if (existe) {
                    toast.error('El registro seleccionado ya fue agregado.');
                  } else {
                    if (selected !== "Seleccionar") {
                      postProfesionalOrientacion(idOrientacion, selectedValue, token).then((data) => {
                        setRefetchProfesionales(true);
                        setRefetchProfesionales(false);
                      }
                      );
                    }
                  }
                }
              }
              }
              text={(editar) ? 'Actualizar' : 'Agregar'}
            />
          </div>
        </div>
        <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-12  overflow-x-auto mt-2">
          <TableSiavi
            headers={headersConsultas}
            body={
              profesionalesOrientacion?.results?.map((item, index) => (
                <tr key={item?.delito?.nombre + index}>
                  <td className="px-2 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {item?.profesional_detalle.first_name} {item?.profesional_detalle.last_name}
                    </div>
                  </td>
                  <td className="px-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue('profesional', item.profesional);
                        setItemEliminar(item);
                        setEditar(true);
                      }}
                      className={`hover:bg-maivi-primaryBlue items-center p-1 border border-transparent 
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                    >
                      <PencilAltIcon className="h-6 w-6  text-maivi-primaryBlue hover:text-white" />
                      <span className="tooltiptext">Editar</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setEliminar(true);
                        setItemEliminar(item);
                        setEliminarProfesional(true);
                        setNombreEliminar(`${item?.profesional_detalle?.first_name} ${item?.profesional_detalle?.last_name}`);
                      }}
                      className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))
            }
          />
        </div>
      </div>
     </div>
    </div>

  );
};
