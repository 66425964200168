import moment from "moment";

export const FormDetalle = (props) => {
    return (
        <div className="bg-white border rounded-lg overflow-hidden sm:rounded-lg mt-4">
            <div className="border-gray-200 px-4 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Persona</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.persona?.primer_nombre} {props?.detalle?.persona?.segundo_nombre} {props?.detalle?.persona?.primer_apellido} {props?.detalle?.persona?.segundo_apellido}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Referencia</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.codigo_referencia === null
                      ? props?.detalle?.codigo_referencia_ts
                      : props?.detalle?.codigo_referencia}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Sede</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.codigo_referencia === null
                      ? props?.detalle?.caso?.sede_creacion?.sede
                      : props?.detalle?.sede?.sede}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Número Caso</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.caso?.numero_caso}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {moment(props?.detalle?.fecha).format("DD-MM-YYYY")}
                        </dd>
                    </div>
                  {
                    props?.detalle?.referencia_interna &&
                      <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Servicio interno</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                          {props?.detalle?.referencias_internas?.nombre}
                      </dd>
                  </div>
                  }
                   {
                        !props?.detalle?.referencia_interna &&
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Institución</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                         {props?.detalle?.gestion_institucion?.oficina_instancia}
                        </dd>
                    </div>
                    }
                    {
                        !props?.detalle?.referencia_interna &&
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Servicios externos</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                          {
                            props?.detalle?.servicios_externos?.map((item)=>(
                                <li>{item?.servicio_externo?.nombre}</li>
                            ))
                          } 
                          
                        </dd>
                    </div>
                    }
                   
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Motivo Referencia</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.motivo_referencia}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Asistencia Atención Específica</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.asistencia_atencion_especifica}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Usuario creación</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.codigo_referencia === null
                      ? `${props?.detalle?.profesional_crea_referencia_ts?.first_name} ${props?.detalle?.profesional_crea_referencia_ts?.last_name}`
                      : `${props?.detalle?.profesional_crea.first_name} ${props?.detalle?.profesional_crea.last_name}`}
                        </dd>
                    </div>
{/*                     <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Aceptado</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.aceptado === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Asistencia Legal</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.asitencia_legal === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Atención Victimológica</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.atencion_victimologica === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Seguimiento Legal</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.seguimiento_legal === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div> */}
                </dl>
            </div>
        </div>
    );
};
