
const {
    REACT_APP_API_URL
} = process.env;

export const getRecursos = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/recursos/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postRecursos = async (data, token) => {
    try {
        let body = {
            nombre: data.nombre,
            descripcion: data. descripcion,
            estado: data.estado,
            disponibilidad_por_dia: data.disponibilidad_por_dia,
            disponibilidad_por_hora: data.disponibilidad_por_hora,
            fecha_activado: (data.fecha_activado === "") ? null : data.fecha_activado,
            fecha_inactivo: (data.fecha_inactivo === "") ? null : data.fecha_inactivo,
            requiere_autorizacion: data.requiere_autorizacion,
            horario_inicio_disponibilidad: (data.horario_inicio_disponibilidad === "") ? null : data.horario_inicio_disponibilidad,
            horario_fin_disponibilidad: (data.horario_fin_disponibilidad === "") ? null : data.horario_fin_disponibilidad,
            profesional_responsable: (data.profesional_responsable === 0) ? null : data.profesional_responsable,
            profesional_autoriza: (data.profesional_autoriza === 0) ? null : data.profesional_autoriza,
            sede_recurso: (data.sede_recurso === 0) ? null : data.sede_recurso
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/recursos/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putRecursos = async (id, data, token) => {
    try {
        let body = {
            nombre: data.nombre,
            descripcion: data. descripcion,
            estado: data.estado,
            disponibilidad_por_dia: data.disponibilidad_por_dia,
            disponibilidad_por_hora: data.disponibilidad_por_hora,
            fecha_activado: (data.fecha_activado === "") ? null : data.fecha_activado,
            fecha_inactivo: (data.fecha_inactivo === "") ? null : data.fecha_inactivo,
            requiere_autorizacion: data.requiere_autorizacion,
            horario_inicio_disponibilidad: (data.horario_inicio_disponibilidad === "") ? null : data.horario_inicio_disponibilidad,
            horario_fin_disponibilidad: (data.horario_fin_disponibilidad === "") ? null : data.horario_fin_disponibilidad,
            profesional_responsable: (data.profesional_responsable === 0) ? null : data.profesional_responsable,
            profesional_autoriza: (data.profesional_autoriza === 0) ? null : data.profesional_autoriza,
            sede_recurso: (data.sede_recurso === 0) ? null : data.sede_recurso
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/recursos/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRecursos = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/recursos/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRecursosDisponibilidad = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/recursodisponibilidad/?page_size=1000&recurso=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};