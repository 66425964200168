/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { clases } from "../../../../../helpers/clases";
import { useSelector } from "react-redux";
import { ReasignarExpediente } from "./reasignar_expediente";
import {
  CheckCircleIcon,
  ClipboardCheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import { CambiarEstado } from "./cambiar_estado";
import { RefreshIcon, UserIcon } from "@heroicons/react/outline";
import {
  getTodosProfesionales,
  putExpediente,
  putReabrirExpediente,
} from "../services";
import { getExpedientes, getSedes } from "../generales/services";
import { AgregarFormCasoPersona } from "../atencion_victimologica/casoPersona";
import {
  getSindicadoDelitos,
  getExpedienteSindicados,
  getFullCasoDetails,
  getDiligenciasExpediente,
} from "../../../orientacion/editar/services";
import ModalSmall from "../../../../../components/common/Modal";
import ModalGenerico from "../../../../../components/common/ModalTwo";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import moment from "moment";
import Label from "../../../../../components/common/Label";
import ModalBig from "../../../../../components/common/ModalTwo";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import Spinner from "../../../../../components/common/Spinner";
import PdfViewer from "../../../../../components/common/PdfViewer";
import TableSiavi from "../../../../../components/common/TableSiavi";
import "moment/locale/es";
moment.locale("es");

const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
const thClass =
  "px-3 py-1 text-left font-medium text-maivi-primaryFont text-xs";

export const FormDireccion = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const [reasignar, setReasignar] = useState(false);
  const [expediente, setExpediente] = useState(undefined);
  const [cambiarEstado, setCambiarEstado] = useState(false);
  const [cambioSede, setCambioSede] = useState(false);
  const [reabrirExpediente, setReabrirExpediente] = useState(false);
  const [imprimirData, setImprimirData] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [profesionales, setProfesionales] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [profesional, setProfesional] = useState(null);
  const [casoPersona, setCasoPersona] = useState(false);
  const [expedienteFull, setExpedienteFull] = useState("");
  const [orientacion, setOrientacion] = useState("");
  const [idCasoPersona, setIdCasoPersona] = useState(false);
  const [sede, setSede] = useState(0);
  const [sedeName, setSedeName] = useState(0);
  const [servicio] = useState("");
  const [recargar, setRecargar] = useState(false);
  const [listExpedientes, setListExpedientes] = useState([]);
  const animatedComponents = makeAnimated();
  const optionsProfesionales = [];
  const optionsSedes = [];
  const caso = props.caso;
  moment.locale("es");

  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user
  );

  useEffect(() => {
    if (expediente !== undefined) {
      getTodosProfesionales(expediente, token).then((data) => {
        setProfesionales(data);
      });
    }
  }, [expediente, token]);

  useEffect(() => {
    getSedes(token).then((data) => {
      setSedes(data);
    });
  }, []);

  useEffect(() => {
    getExpedientes(caso.id, token).then((data) => {
      setListExpedientes(data.results);
    });
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
    };

    if (servicio === "") {
      optionsProfesionales.push(reg);
    } else {
      item.servicios.forEach((element) => {
        if (element?.servicio_detalle?.nombre === servicio) {
          optionsProfesionales.push(reg);
        }
      });
    }
  });

  sedes?.results
    ?.filter((x) => x.es_sede_seguimiento)
    .forEach((item) => {
      let reg = {
        value: item.id,
        label: item.sede,
      };

      optionsSedes.push(reg);
    });

  const changeSelectSedes = (e) => {
    setSede(e.value);
    setSedeName(e.label);
  };

  const CheckPermiso = (permiso) => {
    let retorno = false;

    for (const p of permisos) {
      if (p?.codename === permiso) {
        retorno = true;
      }
    }

    return retorno;
  };
  return (
    <form autoComplete="off">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 xl:col-span-12 lg:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
          <h1 className="text-maivi-primaryFont">Servicios brindados</h1>
          <TableSiavi
            headers={[
              "Servicio",
              "Profesional",
              "Sede",
              "Fecha asignación",
              "Estado",
              "Abierto",
              "Fecha cierre",
              "Acciones",
            ]}
            body={listExpedientes
              ?.filter(
                (x) =>
                  (x.seguimiento_legal === true &&
                    x.asitencia_legal === true) ||
                  (x.seguimiento_legal === false && x.asitencia_legal === false)
              )
              .map((item) => (
                <tr
                  key={item.id}
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <td className={clases.td}>{item?.servicio?.nombre}</td>
                  <td className={clases.td}>
                    {item?.profesional_asignado?.first_name}{" "}
                    {item?.profesional_asignado?.last_name}
                  </td>
                  <td className={clases.td}>
                    {item?.sede_expediente.sede                     }
                  </td>
                  <td className={clases.td}>
                    {item.fecha_asignacion
                      ? moment(item?.fecha_asignacion).format("DD-MM-YYYY")
                      : ""}
                  </td>
                  <td className={clases.td}>
                    {item?.estado === true ? "ACTIVO" : "INACTIVO"}
                  </td>
                  <td className={clases.td}>
                    {item?.expediente_cerrado === true ? "NO" : "SÍ"}
                  </td>
                  
                  <td className={clases.td}>
                    {item?.fecha_cierre === null
                      ? ""
                      : moment(item?.fecha_cierre).format("DD-MM-YYYY")}
                  </td>
                  <td className={clases.td}>
                    <button
                      type="button"
                      className={clases.addIcon}
                      onClick={() => {
                        setReasignar(true);
                        setExpediente(item);
                      }}
                    >
                      <UserIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Reasignar</span>
                    </button>
                    <button
                      type="button"
                      className={clases.addIcon}
                      onClick={() => {
                        setExpediente(item);
                        setCambiarEstado(true);
                      }}
                    >
                      <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Cambiar estado</span>
                    </button>
                    <button
                      type="button"
                      className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                      onClick={() => {
                        if (
                          CheckPermiso("can_change_sede_expediente") === true
                        ) {
                          setCambioSede(true);
                          setExpediente(item);
                        } else {
                          toast.error(
                            "No tiene permisos para realizar esta acción"
                          );
                        }
                      }}
                    >
                      <RefreshIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Cambiar sede</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setExpedienteFull(item);
                        setIdCasoPersona(item?.caso_persona?.id);
                        setOrientacion(item?.orientacion);
                        setCasoPersona(true);
                      }}
                      className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                    >
                      <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext text-xs">Instrumentos Directores</span>
                    </button>
                    <button
                      type="button"
                      className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                      onClick={() => {
                        if (CheckPermiso("can_reabrir_expediente") === true) {
                          if(item.expediente_cerrado === false){
                            toast.error('El expediente debe estar cerrado para poderlo reabrir')
                          } else {
                            setReabrirExpediente(true);
                            setExpediente(item);
                          }
                        } else {
                          toast.error(
                            "No tiene permisos para realizar esta acción"
                          );
                        }
                      }}
                    >
                      <CheckCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Reabrir expediente</span>
                    </button>
                  </td>
                </tr>
              ))}
          />
        </div>
      </div>
      {reabrirExpediente && (
        <ModalSmall
          title={`¿Está seguro que desea reabrir este expediente?`}
          open={reabrirExpediente}
          closeModal={() => {}}
          cerrar={() => {
            setReabrirExpediente(false);
          }}
          children={
            <BotonesConfirmar
              aceptar={async () => {
                setReabrirExpediente(false);
                let dateActual = new Date();
                expediente.fecha_activacion =
                  moment(dateActual).format("YYYY-MM-DD");
                expediente.expediente_cerrado = false;
                expediente.fecha_cierre = null;
                expediente.autoriza_cierre = null;
                await putReabrirExpediente(
                  expediente.id,
                  expediente.fecha_activacion,
                  expediente.expediente_cerrado,
                  expediente.fecha_cierre,
                  expediente.autoriza_cierre,
                  token
                )
                  .then(() => {
                    toast.success("El expediente fue reabierto con éxito...!");
                  })
                  .catch(function (error) {
                    if (
                      error.response.status >= 400 ||
                      error.response.status <= 410
                    ) {
                      toast.error(error.response.data.detail);
                    } else if (error.response.status >= 500) {
                      toast.error(
                        "Error interno comuniquese con el administrador"
                      );
                    }
                  });
              }}
              cancelar={() => {
                setReabrirExpediente(false);
              }}
            />
          }
        />
      )}
      {reasignar && (
        <ModalGenerico
          title="Reasignar expediente"
          open={true}
          closeModal={() => {}}
          cerrar={() => {
            if (recargar) {
              setRecargar(false);
              window.location.reload();
            } else {
              setReasignar(false);
            }
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <UserIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={
            <ReasignarExpediente
              expediente={expediente}
              token={token}
              setRecargar={setRecargar}
            />
          }
        />
      )}
      {cambiarEstado && (
        <ModalAmplio
          title={`Cambiar estado de expediente ${expediente?.servicio?.nombre}`}
          open={cambiarEstado}
          closeModal={() => {}}
          cerrar={() => {
            setCambiarEstado(false);
          }}
          children={<CambiarEstado expediente={expediente} token={token} />}
        />
      )}

      {cambioSede && (
        <ModalBig
          title={"Cambio de Sede"}
          open={cambioSede}
          closeModal={() => {}}
          cerrar={() => {
            if (recargar) {
              setRecargar(false);
              setImprimirData(null);
              window.location.reload();
            } else {
              setCambioSede(false);
            }
          }}
          children={
            <div className="grid grid-cols-12 gap-2 mb-10">
              <div className="col-span-5 border rounded-lg p-3">
                <Label text="Sede" />
                <div className="mt-1">
                  <Select
                    isDisabled={imprimirData == null ? false : true}
                    placeholder="Seleccionar sedes"
                    components={animatedComponents}
                    name="servicios"
                    className="basic-multi-select text-xs text-maivi-primaryFont mb-2"
                    classNamePrefix="seleccione"
                    options={optionsSedes}
                    onChange={changeSelectSedes}
                    maxMenuHeight={140}
                  />
                </div>

                {/* <Label text="Profesional" />
                <div className="mt-1">
                  <Select
                    isDisabled={imprimirData == null ? false : true}
                    placeholder="Seleccionar profesional"
                    components={animatedComponents}
                    name="servicios"
                    className="basic-multi-select text-xs text-maivi-primaryFont"
                    classNamePrefix="seleccione"
                    options={optionsProfesionales}
                    onChange={changeSelectProfesionales}
                    maxMenuHeight={140}
                  />
                </div> */}

                {!imprimirData && (
                  <BotonesConfirmar
                    aceptar={async () => {
                      setCargando(true);
                      await putExpediente(
                        expediente.id,
                        profesional,
                        sede,
                        token
                      )
                        .then(async (data) => {
                          if (data?.status >= 200 || data?.status <= 210) {
                            toast.success("reasignacion realizadas con éxito");
                            let profSend = {};
                            profesionales.results.forEach((item) => {
                              if (item.id === profesional) {
                                profSend = item;
                              }
                            });

                            let asignacionesSend = [];
                            await getSindicadoDelitos(
                              expediente.caso,
                              token
                            ).then(async (delitos) => {
                              await getExpedienteSindicados(
                                expediente.caso,
                                token
                              ).then(async (sindicado) => {
                                await getFullCasoDetails(
                                  expediente.caso,
                                  token
                                ).then(async (personasCaso) => {
                                  await getDiligenciasExpediente(
                                    expediente.id,
                                    token
                                  ).then((diligencias) => {
                                    asignacionesSend.push({
                                      ...expediente,
                                      delitos: delitos.results,
                                      sindicados: sindicado.results,
                                      personasCaso: personasCaso,
                                      diligencias: diligencias,
                                    });
                                    setCargando(false);
                                    setImprimirData({
                                      sede: sedeName,
                                      cambioSede: true,
                                      profesional: profSend,
                                      asignaciones: asignacionesSend,
                                      usuario: usuarioLogin,
                                    });
                                  });
                                });
                              });
                            });

                            setRecargar(true);
                          }
                        })
                        .catch(function (error) {
                          if (
                            error.response.status >= 400 ||
                            error.response.status <= 410
                          ) {
                            setCargando(false);
                            toast.error(error.response.data.detail);
                          } else if (error.response.status >= 500) {
                            setCargando(false);
                            toast.error(
                              "Error interno comuniquese con el administrador"
                            );
                          }
                        });
                    }}
                    cancelar={() => {
                      setCambioSede(false);
                    }}
                  />
                )}
                {cargando && <Spinner />}
              </div>
              <div className="col-span-7 border rounded-lg p-3">
                {cargando && <Spinner />}
                {imprimirData && (
                  <PdfViewer
                    data={imprimirData}
                    reporte={"asignaciones"}
                    style={{ height: "70vh" }}
                  />
                )}
              </div>
            </div>
          }
        />
      )}
      {casoPersona && (
        <ModalBig
          title="Instrumento"
          open={casoPersona}
          closeModal={() => {}}
          cerrar={() => {
            setCasoPersona(false);
          }}
          children={
            <AgregarFormCasoPersona
              thClass={thClass}
              tdClass={tdClass}
              idCasoPersona={idCasoPersona}
              idExpediente={expediente}
              idOrientacion={orientacion}
              servicio={expedienteFull?.servicio?.id}
              expedienteFull={expedienteFull}
              directores={true}
            />
          }
        />
      )}
    </form>
  );
};
