import { React, useState } from "react";
import { InputSiavi } from "../../../components/common/InputText";
import { getPersonas } from "../orientacion/editar/victimas/services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Spinner from "../../../components/common/Spinner";
import Alert from "../../../components/common/Alert";
import Divider from "../../../components/common/Divider";
import { AddPersona } from "../orientacion/listar/persona";
import ButtonAdd from "../../../components/common/ButtonAdd";
import { UserAddIcon } from "@heroicons/react/outline";

const thClass = "px-3 py-1 text-left font-medium text-maivi-primaryFont text-xs";

export const BuscarPersona = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [cargando, setCargando] = useState(false);
    const [personas, setPersonas] = useState([]);
    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState(false);
    const [nuevaPersona, setNuevaPersona] = useState(false);
    const [setRefetch] = useState(false);
    const [registro] = useState({});
    const [setPersona] = useState(undefined);
    const [editar, setEditar] = useState(false);

    return (
        (nuevaPersona) ?
            <AddPersona
                setNuevaPersona={setNuevaPersona}
                setPersona={setPersona}
                editar={editar}
                registro={registro}
                setRefetch={setRefetch}
            /> :
            <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
                <div className="flex flex-row justify-between">
                    <h1 className="mb-1 text-maivi-primaryFont text-sm">
                        Buscar Representante Legal
                    </h1>
                    <ButtonAdd
                        className="-mt-2 -mb-1 ml-2"
                        type="button"
                        onClick={() => {
                            setEditar(false);
                            setNuevaPersona(true);
                            props.setFieldValue('es_nna', true);
                        }}
                        icon={<UserAddIcon className=" -mr-1 h-5" />}
                    />
                </div>
                <Divider />
                <div className="grid grid-cols-12 items-end justify-end gap-2 -mt-3">
                    <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6">
                        <InputSiavi
                            type="text"
                            name="busqueda"
                            value={props.values.busqueda}
                            onChange={props.handleChange}
                            placeholder="Buscar Representante Legal"
                            className="uppercase"
                        />
                    </div>
                    <div className="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-3">
                        <ButtonSiavi
                            type="button"
                            text="Buscar"
                            onClick={async () => {
                                if (props.values.busqueda.length > 2) {
                                    setCargando(true);
                                    await getPersonas(props.values.busqueda, token).then(
                                        (data) => {
                                            setPersonas(data.results);
                                            if (data.results.length === 0) {
                                                setError(true);
                                                setMensaje("No se encontró ningún registro");
                                            }
                                        }
                                    );
                                    setCargando(false);
                                } else {
                                    if (props.values.busqueda === "") {
                                        setError(true);
                                        setMensaje("El campo busqueda no puede estar vacio");
                                    } else {
                                        setError(true);
                                        setMensaje(
                                            "Debe ingresar una busqueda con un minimo de 3 caracteres"
                                        );
                                    }
                                }

                                setTimeout(() => {
                                    setError(false);
                                }, 5000);
                            }}
                        />
                    </div>
                    <div className="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-3">
                        <ButtonSiavi
                            type="button"
                            text="Limpiar"
                            onClick={async (e) => {
                                props.resetForm({});
                                setPersonas({});
                            }}
                        />
                    </div>
                </div>

                <div className="col-span-12 mb-5 overflow-x-auto mt-3 max-h-40">
                    {cargando && (
                        <div className="m-2">
                            <Spinner />
                        </div>
                    )}
                    {personas?.length > 0 ? (
                        <table className="table w-full overflow-x-auto ">
                            <thead className="bg-maivi-gray-5">
                                <tr>
                                    <th scope="col" className={thClass}>
                                        Nombre
                                    </th>
                                    <th scope="col" className={thClass}>
                                        Fecha nac.
                                    </th>
                                    <th scope="col" className={thClass}>
                                        Identificación
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                                {personas?.map((item) => (
                                    <tr
                                        id={item.id}
                                        key={item.id}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            let row;
                                            personas.forEach((element) => {
                                                let row = document.getElementById(element.id);
                                                row.classList.remove("bg-green-50");
                                            });
                                            row = document.getElementById(item.id);
                                            row.classList.add("bg-green-50");
                                            props.setFieldValue('representante_legal', item.id);
                                        }}
                                    >
                                        <td className="px-2 py-1 row-td">
                                            <div className="text-xs text-maivi-primaryFont">
                                                {item.primer_nombre} {item.segundo_nombre}{" "}
                                                {item.tercer_nombre} {item.primer_apellido}{" "}
                                                {item.segundo_apellido}
                                            </div>
                                        </td>
                                        <td className="px-2 row-td">
                                            <div className="text-xs text-maivi-primaryFont">
                                                {item.fecha_nacimiento}
                                            </div>
                                        </td>
                                        <td className="px-2 row-td">
                                            <div className="text-xs text-maivi-primaryFont">
                                                {item.no_documento}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        ""
                    )}
                    {error && <Alert type="error" mensaje={mensaje} />}
                </div>
            </div>
    );
};