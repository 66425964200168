import axios from "axios";

const { REACT_APP_API_URL } = process.env;
export const exportarData = async(filters, token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/referenciaslist/?${filters}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
}

export const getReferencias = async (urlPage, search,sede,filters,order, token) => {
  try {
  console.log(urlPage,filters)

    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/referencia/?search=${search.value}&${filters}&ordering=${order}`
        :`${urlPage}&search=${search.value}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const referencias = async (urlPage,search,filters,order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/referencia/?search=${search.value}&ordering=${order}&${filters}`
        : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getServicios = async(token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getTipoProcesos = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAtenciones = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/atencion/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const convertirReferencias = async (registros,token) => {
  try {
      let ids =[];

      registros.forEach(element => {
          ids.push(element.id);
      });

      let body ={
        solicitudes: ids
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/solicitudareferencia/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const asignarReferencias = async (registros,profesional,token) => {
  try {
      let ids =[];

      registros.forEach(element => {
          ids.push(element.id);
      });

      let body ={
        profesional: profesional.value,
        referencias: ids
      };

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionereferencias/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};