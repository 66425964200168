import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    getDisponibilidadRecursos,
} from "../recursoDisponibilidad/services";
import Spinner from "../../../components/common/Spinner";
import CustomTable from "../../../components/common/Table";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "date" },
    { title: "Hora", value: "hora", tipo: "text" },
    {
        title: "Disponible",
        value: "disponible",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    }
];

export const GestionesRecursosDisponibilidad = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idRecurso = props?.recurso?.id;
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);

    const fetchRecursoDisponibilidad = () => {
        setTableLoading(true);
        getDisponibilidadRecursos(idRecurso,urlFetch, token).then((recursos) => {
            setDataTable(recursos);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchRecursoDisponibilidad, [token, idRecurso,urlFetch,reFetch]);

    return (
        <div>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    pagination={true}
                    fetchPage={setUrlFech}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};
