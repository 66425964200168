import { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { StarIcon } from "@heroicons/react/solid";
import { DynamicIcon } from '../../components/common/DynamicIcon';
import { useSelector } from "react-redux";
import { InputSiavi } from "../../components/common/InputText";
import { store } from "../../app/redux/store/store";
import { ChangeFavoritos } from "../../app/redux/actions/favoritos";
import { saveFavoritos } from '../../helpers/utils';
import Divider from "../../components/common/Divider";

const sedes = [
  {
    name: "Sedes",
    href: "/catalogos/sedes",
    icon: 'HomeIcon',
    permiso: "sede"
  },
  {
    name: "Tipo sede",
    href: "/catalogos/tipo-sede",
    icon: 'HomeIcon',
    permiso: "tiposede"
  },
];

const generales = [
  {
    name: "Configuración General",
    href: "/catalogos/configuracion",
    icon: 'CogIcon',
    permiso: "configuracion"
  },
  {
    name: "Área",
    href: "/catalogos/area-sustantiva",
    icon: 'CheckCircleIcon',
    permiso: "area"
  },
  {
    name: "Subárea",
    href: "/catalogos/sub-area-sustantiva",
    icon: 'CheckCircleIcon',
    permiso: "subarea"
  },
  {
    name: "Servicio interno",
    href: "/catalogos/servicio-instituto",
    icon: 'LibraryIcon',
    permiso: "serviciopropio"
  },
  {
    name: "Tipo profesional",
    href: "/catalogos/tipo-profesional",
    icon: 'AcademicCapIcon',
    permiso: "tipoprofesional"
  },
  {
    name: "Tipo puesto",
    href: "/catalogos/tipo-puesto",
    icon: 'UserGroupIcon',
    permiso: "tipopuesto"
  },
  /*   {
      name: "Viabilidad",
      href: "/catalogos/viabilidad",
      icon: ('TrendingUpIcon',
      permiso: "viabilidad"
    }, */
  /*   {
      name: "Admisibilidad",
      href: "/catalogos/admisibilidad",
      icon: 'CheckCircleIcon',
      permiso: "admisibilidad"
    }, */
  {
    name: "No admisibilidad",
    href: "/catalogos/no-admisibilidad",
    icon: 'MinusCircleIcon',
    permiso: "noadmisibilidad"
  },
  /*   {
      name: "No viabilidad",
      href: "/catalogos/no-viabilidad",
      icon: ('TrendingDownIcon',
      permiso: "noviabilidad"
    }, */
  {
    name: "Tipo referencia",
    href: "/catalogos/tipo-referencia",
    icon: 'UserGroupIcon',
    permiso: "tiporeferencia"
  },
  {
    name: "Tipo contacto",
    href: "/catalogos/tipo-contacto",
    icon: 'UserIcon',
    permiso: "tipocontacto"
  },
  {
    name: "Tipo documento identificación",
    href: "/catalogos/tipo-documento",
    icon: 'CollectionIcon',
    permiso: "tipodocumento"
  },
  {
    name: "Atenciones",
    href: "/catalogos/atencion",
    icon: 'ThumbUpIcon',
    permiso: "atencion"
  },
  {
    name: "Tipo persona",
    href: "/catalogos/tipo-persona",
    icon: 'UserIcon',
    permiso: "tipopersona"
  },
  {
    name: "Status Persona",
    href: "/catalogos/status-persona",
    icon: 'RefreshIcon',
    permiso: "statuspersona"
  },
  {
    name: "Clase Documento",
    href: "/catalogos/clase-documento",
    icon: 'DocumentTextIcon',
    permiso: "clasedocumento"
  },
  {
    name: "Identidad Género",
    href: "/catalogos/identidad-genero",
    icon: 'UserGroupIcon',
    permiso: "identidadgenero"
  },
  {
    name: "Situación Laboral",
    href: "/catalogos/situacion-laboral",
    icon: 'BriefcaseIcon',
    permiso: "situacionlaboral"
  },
  {
    name: "Redes Sociales",
    href: "/catalogos/redes-sociales",
    icon: 'UserGroupIcon',
    permiso: "redessociales"
  },
  {
    name: "Motivo Visita",
    href: "/catalogos/motivo-visita",
    icon: 'IdentificationIcon',
    permiso: "motivovisita"
  },
  {
    name: "Tipo Mensaje",
    href: "/catalogos/tipo-mensaje",
    icon: 'ChatIcon',
    permiso: "tipomensaje"
  },
  /*   {
      name: "Tipo Pregunta",
      href: "/catalogos/tipo-pregunta",
      icon: 'QuestionMarkCircleIcon',
      permiso: "tipopregunta"
    },
    {
      name: "Selección Múltiple",
      href: "/catalogos/seleccion",
      icon: 'CheckCircleIcon',
      permiso: "catalogoseleccion"
    }, */
  {
    name: "Tipo Consulta",
    href: "/catalogos/tipo-consulta",
    icon: 'CollectionIcon',
    permiso: "tipoconsulta"
  },
  {
    name: "Tipo Documento Derivación",
    href: "/catalogos/tipo-docto-derivacion",
    icon: 'CollectionIcon',
    permiso: "tipodocumentoderivacion"
  },
  {
    name: "Peligrosidad Sindicado",
    href: "/catalogos/peligrosidad-sindicado",
    icon: 'ViewBoardsIcon',
    permiso: "peligrosidadsindicado"
  },
  {
    name: "Recursos",
    href: "/catalogos/recursos",
    icon: 'ScaleIcon',
    permiso: "recursos"
  },
  {
    name: "Modalidad",
    href: "/catalogos/modalidad",
    icon: 'DocumentTextIcon',
    permiso: "modalidad"
  },
  {
    name: "Motivo Cambio Planificación",
    href: "/catalogos/motivo-cambio-planificacion",
    icon: 'DocumentTextIcon',
    permiso: "motivocambioplanificacion"
  },
  {
    name: "Grupos",
    href: "/catalogos/grupos",
    icon: 'UserGroupIcon',
    permiso: "grupo"
  },
  {
    name: "Biblioteca",
    href: "/catalogos/biblioteca",
    icon: 'BookOpenIcon',
    permiso: "biblioteca"
  },
  {
    name: "Insumos",
    href: "/catalogos/insumos",
    icon: 'ClipboardListIcon',
    permiso: "insumos"
  }
  /*   {
      name: "Recurso Disponibilidad",
      href: "/catalogos/recurso-disponibilidad",
      icon: 'ScaleIcon',
      permiso: "recursodisponibilidad"
    } */
]

const geograficos = [
  {
    name: "Países",
    href: "/catalogos/paises",
    icon: 'FlagIcon',
    permiso: "pais"
  },
  {
    name: "Departamentos",
    href: "/catalogos/departamentos",
    icon: 'MapIcon',
    permiso: "departamento"
  },
  {
    name: "Municipios",
    href: "/catalogos/municipios",
    icon: 'LocationMarkerIcon',
    permiso: "municipio"
  },
  {
    name: "Vialidad",
    href: "/catalogos/vialidad",
    icon: 'ArrowCircleRightIcon',
    permiso: "vialidad"
  },
  {
    name: "Grupo habitacional",
    href: "/catalogos/grupo-habitacional",
    icon: 'HomeIcon',
    permiso: "grupohabitacional"
  },
]

const demograficos = [
  {
    name: "Escolaridad",
    href: "/catalogos/escolaridad",
    icon: 'AcademicCapIcon',
    permiso: "escolaridad"
  },
  {
    name: "Nacionalidad",
    href: "/catalogos/nacionalidad",
    icon: 'FlagIcon',
    permiso: "nacionalidad"
  },
  {
    name: "Estado civil",
    href: "/catalogos/estado-civil",
    icon: 'UserGroupIcon',
    permiso: "estadocivil"
  },
  {
    name: "Tipo Discapacidad",
    href: "/catalogos/discapacidad",
    icon: 'Wheelchair',
    ubicacion: 'local',
    permiso: "discapacidad"
  },
  {
    name: "Orientación sexual",
    href: "/catalogos/orientacion-sexual",
    icon: 'UserGroupIcon',
    permiso: "orientacionsexual"
  },
  {
    name: "Parentesco / relación con la víctima",
    href: "/catalogos/parentesco",
    icon: 'UserGroupIcon',
    permiso: "parentesco"
  },
  {
    name: "Profesión",
    href: "/catalogos/profesion",
    icon: 'AcademicCapIcon',
    permiso: "profesion"
  },
  {
    name: "Religión",
    href: "/catalogos/religiones",
    icon: 'LibraryIcon',
    permiso: "religion"
  },
  {
    name: "Atención diferenciada",
    href: "/catalogos/aptitud-atencion-diferenciada",
    icon: 'CheckCircleIcon',
    permiso: "aptitudatenciondiferenciada"
  },
]

const etnicos = [
  {
    name: "Idiomas",
    href: "/catalogos/idiomas",
    icon: 'FlagIcon',
    permiso: "idioma"
  },
  {
    name: "Origen étnico",
    href: "/catalogos/origen-etnico",
    icon: 'UserGroupIcon',
    permiso: "origenetnico"
  },
  {
    name: "Idioma maya",
    href: "/catalogos/idioma-maya",
    icon: 'SpeakerphoneIcon',
    permiso: "idiomamaya"
  },
];

const instituciones = [
  {
    name: "Servicios brindados por instituciones",
    href: "/catalogos/servicio",
    icon: 'CollectionIcon',
    permiso: "servicioexterno"
  },
  {
    name: "Población atendida",
    href: "/catalogos/poblacion",
    icon: 'UserGroupIcon',
    permiso: "poblacion"
  },
  {
    name: "Tipo institución",
    href: "/catalogos/tipo-institucion",
    icon: 'LibraryIcon',
    permiso: "tipoinstitucion"
  },
  {
    name: "Categoría institución",
    href: "/catalogos/categoria-institucion",
    icon: 'LibraryIcon',
    permiso: "categoriainstitucion"
  },
  {
    name: "Instituciones",
    href: "/catalogos/instituciones",
    icon: 'LibraryIcon',
    permiso: "institucion"
  },
  {
    name: "Directorio de derivación",
    href: "/catalogos/gestion-instituciones",
    icon: 'LibraryIcon',
    permiso: "gestioninstitucion"
  }
]

const legales = [
  {
    name: "Delito",
    href: "/catalogos/delito",
    icon: 'ViewBoardsIcon',
    permiso: "delito"
  },
  /*   {
      name: "Subcategoría delito",
      href: "/catalogos/subcategoria-delito",
      icon: ('EmojiSadIcon',
      permiso: "subcategoriadelito"
    }, */
  {
    name: "Etapa procesal",
    href: "/catalogos/etapa-procesal",
    icon: 'ScaleIcon',
    permiso: "etapaprocesal"
  },
  /*   {
      name: "Estado proceso",
      href: "/catalogos/estado-proceso",
      icon: ('RefreshIcon',
      permiso: "estadoproceso"
    }, */
  {
    name: "Materia legal",
    href: "/catalogos/materia",
    icon: 'ArchiveIcon',
    permiso: "materia"
  },
  {
    name: "Tipo proceso",
    href: "/catalogos/tipo-proceso",
    icon: 'RefreshIcon',
    permiso: "tipoproceso"
  },
  {
    name: "Situación jurídica",
    href: "/catalogos/situacion-juridica",
    icon: 'ScaleIcon',
    permiso: "situacionjuridica"
  },
  /*  {
     name: "Medida desjudicializadora",
     href: "/catalogos/medida-desjudicializadora",
     icon: ('ScaleIcon',
     permiso: "medidadesjudicializadora"
   },
   {
     name: "Resultado audiencia",
     href: "/catalogos/resultado-audiencia",
     icon: ('ScaleIcon',
     permiso: "resultadoaudiencia"
   }, */
  /*   {
      name: "Interponente",
      href: "/catalogos/interponente",
      icon: 'ScaleIcon',
      permiso: "interponente"
    },
    {
      name: "Motivo",
      href: "/catalogos/tipo-apelacion-especial-casacion",
      icon: 'ScaleIcon',
      permiso: "tipoapelacionespecialcasacion"
    }, */
  {
    name: "Reparación digna",
    href: "/catalogos/reparacion-digna",
    icon: 'ScaleIcon',
    permiso: "reparaciondigna"
  },
  {
    name: "Impugnaciones y Acciones Constitucionales",
    href: "/catalogos/tipo-proceso-secundario",
    icon: 'ScaleIcon',
    permiso: "tipoprocesosecundario"
  },
  {
    name: "Tipo Diligencias",
    href: "/catalogos/tipo-diligencias",
    icon: 'ScaleIcon',
    permiso: "tipodiligencias"
  },
  {
    name: "Seguimiento Legal",
    href: "/catalogos/seguimiento-legal",
    icon: 'ScaleIcon',
    permiso: "accionesseguimientos"
  },
  {
    name: "Medida Coerción",
    href: "/catalogos/coercion",
    icon: 'ScaleIcon',
    permiso: "catalogomedidacoercion"
  },
  {
    name: "Resolución Otorga Reparación Digna",
    href: "/catalogos/resolucion-otorga-reparacion-digna",
    icon: 'DocumentTextIcon',
    permiso: "catalogomedidacoercion"
  },
  {
    name: "Estatus Medida Reparación Digna",
    href: "/catalogos/status-medida-reparacion-digna",
    icon: 'DocumentTextIcon',
    permiso: "catalogomedidacoercion"
  },
  {
    name: "Razón No Solicitud Reparación Digna",
    href: "/catalogos/razon-no-solicitud-reparacion-digna",
    icon: 'DocumentTextIcon',
    permiso: "catalogomedidacoercion"
  },
  /*   {
      name: "Estado proceso secundario",
      href: "/catalogos/estado-proceso-secundario",
      icon: ('RefreshIcon',
      permiso: "estadoprocesosecundario"
    }, */
];

const victimologia = [
  {
    name: "Dominio de Recuperación de la Víctima",
    href: "/catalogos/dominio-recuperacion",
    icon: "HandHoldingHeart",
    ubicacion: 'local',
    permiso: "dominiorecuperacion"
  },
  {
    name: "Acciones Victimológicas",
    href: "/catalogos/accion-victimologica",
    icon: 'ScaleIcon',
    permiso: "accionesseguimientos"
  },
  {
    name: "Medida Victimológica",
    href: "/catalogos/medida-victimologica",
    icon: 'DocumentTextIcon',
    permiso: "catalogomedidacoercion"
  }
];

const caso = [
  {
    name: "Consentimientos, acuerdos y memoriales",
    href: "/catalogos/consentimiento-informado",
    icon: 'ClipboardCheckIcon',
    permiso: "consentimientoinformado"
  },
  {
    name: "Gestión de Riesgo",
    href: "/catalogos/nivel-riesgo",
    icon: 'CollectionIcon',
    permiso: "nivelriesgo"
  },
  /*   {
      name: "Tipo Riesgo",
      href: "/catalogos/tipo-riesgo",
      icon: ('CollectionIcon',
      permiso: "tiporiesgo"
    }, */
/*   {
    name: "Motivo cierre",
    href: "/catalogos/motivo-cierre",
    icon: 'XCircleIcon',
    permiso: "motivocierre"
  }, */
  {
    name: "Motivo Criterio cierre",
    href: "/catalogos/motivo-criterio-cierre",
    icon: 'XCircleIcon',
    permiso: "motivocriterioscierre"
  },
  {
    name: "Estados del Caso",
    href: "/catalogos/status",
    icon: 'RefreshIcon',
    permiso: "status"
  },
  {
    name: "Tipo SMS Víctima",
    href: "/catalogos/tipo-sms-victima",
    icon: 'ChatIcon',
    permiso: "tiposmsvictima"
  },
];

const formularios = [
  {
    name: "Instrumentos",
    href: "/catalogos/formulario",
    icon: 'DocumentTextIcon',
    permiso: "formularios"
  }
];

const encuestas = [
  {
    name: "Encuestas",
    href: "/catalogos/encuesta",
    icon: 'DocumentTextIcon',
    permiso: "formularios"
  },
  {
    name: "Tipo Valor Encuesta",
    href: "/catalogos/tipo-valor-encuesta",
    icon: 'DocumentTextIcon',
    permiso: "tipovalorencuesta"
  },
  {
    name: "Razón Exclusión",
    href: "/catalogos/razon-exclusion",
    icon: 'DocumentTextIcon',
    permiso: "razonexclusion"
  }
];

export const ListadoCatalogos = () => {
  const permisos = useSelector(state => state.loginReducer.data.usuario.user.permissions);
  const menuFavoritos = useSelector(state => state.ChangeFavoritos.menus ? state.ChangeFavoritos.menus : []);
  const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
  let history = useHistory();

  let busqueda = localStorage.getItem('searchCatalogo') || "";

  const permisoView = (recurso) => {

    let view = `view_${recurso}`;
    let edit = `change_${recurso}`;
    let del = `delete_${recurso}`;
    let create = `add_${recurso}`;

    let retorno = 0;
    permisos.forEach(permiso => {
      if (view === permiso.codename || edit === permiso.codename || del === permiso.codename || create === permiso.codename) {
        retorno++;
      }
    });

    return (retorno > 1 ? true : false);
  }

  const [search, setSearch] = useState(busqueda);

  const [favoritos, setFavoritos] = useState(menuFavoritos);

  function onChange(e) {
    localStorage.setItem('searchCatalogo', e.target.value);
    setSearch(e.target.value);
  }

  const listado = (listado, title) => {
    const showTitle = () => {
      let retorno = false;
      listado.forEach(list => {
        if (permisoView(list.permiso) === true && list.name.toLowerCase().includes(search.toLowerCase())) {
          retorno = true;
        }
      })
      return retorno;
    }

    const markFavorite = (item) => {
      let menu = { ...item };
      let data = menuFavoritos;
      let find = true;
      data.forEach((mn, index) => {
        if (item.href === mn.href) {
          data.splice(index, 1)
          find = false;
        }
      })
      if (find) {
        data.push(menu)
      }
      store.dispatch(ChangeFavoritos(data));
      saveFavoritos(data, idUser);
      setFavoritos(data);
      history.push("/catalogos");
    }

    const isFavorite = (item) => {
      let retorno = false;
      favoritos.forEach(mn => {
        if (item.href === mn.href) {
          retorno = true;
        }
      })

      return retorno;
    }

    return (
      <div>
        {showTitle() === true &&
          <h1 className="inline text-xl font-extrabold tracking-tight text-maivi-primaryLightBlue ">{title}</h1>
        }
        {showTitle() === true &&
          <Divider />
        }
        {showTitle() === true &&
          <ul className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-3 mt-3 mb-10 ">
            {listado.map((item, index) =>
              (permisoView(item.permiso) === true && item.name.toLowerCase().includes(search.toLowerCase())) &&
              <li key={`${index}-cat`} className="flex shadow-lg rounded-md h-20">
                <div onDoubleClick={() => markFavorite(item)} className="cursor-pointer flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-maivi-primaryLightBlue">
                  {isFavorite(item) === true && <StarIcon />}
                </div>
                <Link key={item.name} to={item.href} className="h-22 flex-1 flex flex-col items-center justify-between border border-maivi-primaryLightBlue bg-white rounded-r-md break-word hover:bg-maivi-secondaryBlueHover">
                  <div className="flex flex-col items-center my-auto">
                    <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                    <p className="xl:text-xs lg:text-sm md:text-sm sm:text-sm text-gray-900 text-center font-medium">
                      {item.name}
                    </p>
                  </div>
                </Link>

              </li>
            )}
          </ul>
        }
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="xl:col-span-10 lg:col-span:10 md:col-span-10"></div>
        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2">
          <InputSiavi placeholder="buscar" onChange={onChange} value={busqueda} />
        </div>
      </div>
      {listado(sedes, 'Sedes')}
      {listado(generales, 'Generales')}
      {listado(geograficos, 'Geográficos')}
      {listado(demograficos, 'Demográficos')}
      {listado(etnicos, 'Étnicos')}
      {listado(instituciones, 'Instituciones')}
      {listado(legales, 'Legales')}
      {listado(victimologia, 'Victimología')}
      {listado(caso, 'Caso')}
      {listado(formularios, 'Instrumentos')}
      {listado(encuestas, 'Encuestas')}
    </div>
  );
};
