/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
  getProfesionales, getSedes, getServicios
} from "./services";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
  let filtros = "";
  const [state, setState] = useState({
    servicio: "",
    sede: "",
    profesional_crea: "",
    profesional_crea_referencia_ts: "",
    fecha_desde: "",
    fecha_hasta: ""
  });

  const [profesionales, setProfesionales] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [sedes,setSedes] = useState({});
  const [proReferenciasTS, setProReferenciasTS] = useState({});

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProReferenciasTS(data);
    });
  }, []);

  useEffect(() => {
    getServicios(props.token).then((data) => {
      setServicios(data);
    });
  }, []);

  useEffect(() => {
    getSedes(props.token).then((data) => {
      setSedes(data);
    });
  }, []);

  return (
    <div className="mx-4">
      {/* <div className="col-span-12 mt-4">
        <SelectSiavi
          name="tipo_referencia"
          label="Tipo Referencia"
          defaultValue=""
          value={state.tipo_referencia}
          onChange={onChange}
          initValue=""
          selectInit="Elegir tipo referencia"
          list={
            [
              <option value="true">INTERNA</option>,
              <option value="false">EXTERNA</option>
            ]
          }
        />
      </div> */}
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="profesional_crea"
          label="Usuario Creación"
          defaultValue=""
          value={state.profesional_crea}
          onChange={onChange}
          initValue=""
          selectInit="Elegir usuario"
          list={
            profesionales?.results?.map((item, index) => {
              return <option key={item?.first_name + index} value={item?.id}>{item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="sede"
          label="Sede"
          defaultValue=""
          value={state.sede}
          onChange={onChange}
          initValue=""
          selectInit="Elegir sede"
          list={
            sedes?.results?.map((item, index) => {
              return <option key={item?.sede + index} value={item?.id}>{item?.sede}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="servicio"
          label="Servicios"
          defaultValue=""
          value={state.servicio}
          onChange={onChange}
          initValue=""
          selectInit="Elegir servicio"
          list={
            servicios?.results?.map((item, index) => {
              return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="profesional_crea_referencia_ts"
          label="Usuario Solicita"
          defaultValue=""
          value={state.profesional_crea_referencia_ts}
          onChange={onChange}
          initValue=""
          selectInit="Elegir usuario"
          list={
            proReferenciasTS?.results?.map((item, index) => {
              return <option key={item?.first_name + index} value={item?.id}>{item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha fin"
        />
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `${(state.sede !== "" ? '&sede=' : '')}${state.sede}${(state.servicio !== "" ? '&expediente__servicio=' : '')}${state.servicio}${(state.profesional_crea !== "") ? '&profesional_crea=' : ''}${state.profesional_crea}${(state.profesional_crea_referencia_ts !== "") ? '&profesional_crea_referencia_ts=' : ''}${state.profesional_crea_referencia_ts}${(state.fecha_desde !== "") ? '&fecha__gte=' : ''}${state.fecha_desde}${(state.fecha_hasta !== "") ? '&fecha__lte=' : ''}${state.fecha_hasta}`;
            props.setFilters(filtros);
            props.setOpenFilters(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
