import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { FormDetalle } from "./detalleEspacioSeguro";
import { clases } from "../../../helpers/clases";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { GestionesInsumos } from "./gestionInsumos";
import { BuscarPersona } from "./buscarPersona";
import { useSelector } from "react-redux";
import { DocumentTextIcon, XCircleIcon } from "@heroicons/react/outline";
import { bloqueoFecha } from "../../../helpers/utils";
import {
    deleteEspacioSeguro,
    getEspacioSeguroVisitas,
    postEspacioSeguro,
    putEspacioSeguro,
    getDelitos,
    actualizarVisita
} from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import Spinner from "../../../components/common/Spinner";
import ModalAmplio from "../../../components/common/ModalAmplio";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "date" },
    { title: "Edad", value: "edad", tipo: "text" },
    {
        title: "Es NNA",
        value: "es_nna",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Representante Legal",
        value: null,
        values: [
            "representante_legal.primer_nombre",
            "representante_legal.primer_apellido",
        ],
        tipo: "text"
    },
    { title: "Delito", value: "delito.nombre", tipo: "text" },
];

export const GestionesEspacioSeguro = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const profesional = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const idPersona = props?.registro?.persona?.id;
    const [verDetalle, setDetalle] = useState(false);
    const [espacioSeguroDetalle, setEspacioSeguroDetalle] = useState({});
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idEspacioSeguro, setIdEspacioSeguro] = useState("");
    const [espacioSeguro, setEspacioSeguro] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [registro, setRegistro] = useState("");
    const [insumos, setInsumos] = useState(false);
    const [delitos, setDelitos] = useState({});
    const [delitosAgregado, setDelitoAgregado] = useState(0);
    const [valor, setValor] = useState("");
    const animatedComponents = makeAnimated();
    const optionsDelitos = [];
    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "visitas";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso == permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });
        // retorno = true;  // mm comentar para que funcione
        return retorno;

    }

    const permiso = "espacioseguro";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    useEffect(() => {
        getDelitos(token).then((data) => {
            setDelitos(data)
        });
    }, [token]);

    delitos?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre,
        };
        optionsDelitos.push(reg);
    });

    const changeSelectDelitos = (e) => {
        setDelitoAgregado(e.value);
    };

    const handleVer = (item) => {
        setEspacioSeguroDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdEspacioSeguro(id);
        setEliminar(true);
    };

    const handleEditar = (gestionEspacioSeguro) => {
        setEdit(true);
        setEspacioSeguro(gestionEspacioSeguro);
        setValor(gestionEspacioSeguro?.delito?.nombre)
        setDelitoAgregado(gestionEspacioSeguro?.delito?.id);
    };

    const fetchEspacioSeguro = () => {
        getEspacioSeguroVisitas(idPersona, token).then((espacios) => {
            setDataTable(espacios);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchEspacioSeguro, [token, idPersona, reFetch]);

    const opcion = (registro) => {
        return (<span>
            {(CheckPermiso('registroinsumosespacioseguro') === true) &&    //mm permiso agregado        
                <button
                    type="button"
                    className={clases.addIcon2}
                    onClick={() => {
                        setInsumos(true);
                        setRegistro(registro);
                    }}
                >
                    <DocumentTextIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                    <span className="tooltiptext">Insumos</span>
                </button>
            }
        </span>)

    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    persona: idPersona,
                    fecha: isEdit ? espacioSeguro?.fecha : "",
                    edad: isEdit ? espacioSeguro?.edad : "",
                    es_nna: isEdit ? espacioSeguro?.es_nna : false,
                    observaciones: isEdit ? espacioSeguro?.observaciones : "",
                    representante_legal: isEdit ? espacioSeguro?.representante_legal?.id : "",
                    delito: isEdit ? espacioSeguro?.delito?.id : "",
                    sede: sedeId,
                    profesional: profesional,
                    caso: null,
                    busqueda: ""
                }}
                onSubmit={async (values, { resetForm }) => {
                    values.delito = delitosAgregado;
                    if (isEdit) {
                        await putEspacioSeguro(espacioSeguro.id, values, token).then(async (data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                                setReFetch(true);
                                toast.success('Registro actualizado con éxito...!');
                                resetForm({});
                                setValor(".")
                                props.setRefetch(true);
                                setTimeout(() => {
                                    setReFetch(false);
                                    props.setRefetch(false);
                                }, 400);
                                setEdit(false);
                            }
                        }).catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 450) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(error.response.data.message);
                            }
                        });
                    } else {
                        await postEspacioSeguro(values, token).then(async (data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                                await actualizarVisita(props.registro.id, props.registro, token).then((data) => {
                                    if (data?.status >= 200 && data?.status <= 210) {
                                        toast.success('Registro creado con éxito...!');
                                        resetForm({});
                                        setValor(".")
                                        setReFetch(true);
                                        props.setRefetch(true);
                                        setTimeout(() => {
                                            setReFetch(false);
                                            props.setRefetch(false);
                                        }, 400);
                                    }
                                }).catch(function (error) {
                                    if (error.response.status >= 400 && error.response.status <= 450) {
                                        toast.error(error.response.data.message);
                                    } else if (error.response.status >= 500) {
                                        toast.error(error.response.data.message);
                                    }
                                });
                            }
                        }).catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 450) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-2 items-end border rounded-md p-3">
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="date"
                                        name="fecha"
                                        id="fecha"
                                        value={values.fecha}
                                        onChange={handleChange}
                                        validacion={true}
                                        minimo={bloqueoFecha()}
                                        label="Fecha"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="number"
                                        name="edad"
                                        id="edad"
                                        value={values.edad}
                                        onChange={handleChange}
                                        label="Edad"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <div className="mt-1">
                                    <Label text="Delito" />
                                    <Select
                                        inputValue={valor}
                                        onInputChange={(e) => { setValor(e); }}
                                        isMulti={false}
                                        placeholder="Seleccionar"
                                        components={animatedComponents}
                                        name="delitos"
                                        className="basic-multi-select text-xs text-maivi-primaryFont"
                                        classNamePrefix="seleccione"
                                        options={optionsDelitos}
                                        onChange={changeSelectDelitos}
                                        maxMenuHeight={160}
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="observaciones"
                                        id="observaciones"
                                        value={values.observaciones}
                                        onChange={handleChange}
                                        label="Observaciones"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center">
                                <Label text="¿Tiene Representante Legal?" />
                                <Toggle
                                    enabled={values.es_nna}
                                    name="es_nna"
                                />
                            </div>
                            {values.es_nna && (
                                <div className="col-span-12 mt-2">
                                    <div className="mt-1">
                                        <BuscarPersona
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            resetForm={resetForm}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                </div>
                            )}
                            {values.es_nna && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4"></div>
                            )}
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3 sm:col-span-4 md:col-span-4">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-5">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    //showEliminar={true}
                    showEliminar={(CheckPermiso('eliminarespacioseguro') === true)}    //mm permiso agregado        
                    showVer={true}
                    //showEditar={true}
                    showEditar={(CheckPermiso('editarespacioseguro') === true)}    //mm permiso agregado        
                    addAccion={true}
                    opciones={opcion}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de Espacio Seguro"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalle detalle={espacioSeguroDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteEspacioSeguro(idEspacioSeguro, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
                {insumos && (
                    <ModalAmplio
                        title={`Insumos de Espacio Seguro seleccionado`}
                        open={insumos}
                        closeModal={() => { }}
                        cerrar={() => {
                            setInsumos(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionesInsumos registro={registro} />
                        }
                    />
                )}
            </div>
        </div>
    );
};
