/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { FormDetalle } from "./Detalle";
import { XCircleIcon, LibraryIcon, FilterIcon } from "@heroicons/react/outline";
import { deleteCatalogos, getGenericCatalogo, getGenericCatalogoSearch } from "../../../helpers/catalogos";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../common/FormEliminar";
import toast from 'react-hot-toast';
import Filtros from "./filtros";
import ButtonRefresh from "../common/ButtonRefresh";

const tableColumns = [
  { title: "id", value: "id", tipo: "text", ordenar: "id" },
  { title: "Institución", value: "institucion_detalle.nombre", tipo: "text" },
  { title: "Oficina / Fiscalía / Instancia", value: "nombre_fiscalia", tipo: "text", ordenar: "nombre_fiscalia" },
  { title: "Departamento", value: "departamento_detalle.nombre", tipo: "text" },
  { title: "Municipio", value: "municipio_detalle.nombre", tipo: "text" },
  { title: "Teléfono", value: "telefono", tipo: "text" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
  },
];

export const TableGestionInstituciones = () => {
  const query = new URLSearchParams(window.location.search);
  const institucion = query.get("institucion");
  const [isEliminar, setEliminar] = useState(false);
  const [verDetalle, setDetalle] = useState(false);
  const [gestionInstitucionDetalle, setGestionInstitucionDetalle] = useState(
    {}
  );
  const [idGestionInstitucion, setIdGestionInstitucion] = useState("");
  const [nombreGestionInstitucion, setNombreGestionInstitucion] = useState("");
  const [isloading, setLoading] = useState(false);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [reFetch, setReFetch] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState(institucion === null ? "" : `departamento=&municipio=&estado=&institucion=${institucion}`);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "gestioninstitucion";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  useEffect(() => {
    setTableLoading(true);
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
    setReFetch(false);
    setEliminar(false);
    setLoading(false);
    setInterval(() => {
      setTableLoading(false)
    }, 2000);
  }, [urlFetch, token, reFetch, filters]);
  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
  }, [order, reFetch, filters]);
  useEffect(() => {
    getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order, filters);
  }, [search, reFetch, filters]);

  const handleVer = (item) => {
    setGestionInstitucionDetalle(item);
    setDetalle(true);
  };

  const handleEliminar = (id, nombre,registro) => {
    setIdGestionInstitucion(id);
    setNombreGestionInstitucion(registro?.nombre_fiscalia);
    setEliminar(true);
  };

  const linkEditar = `/catalogos/gestion-instituciones/editar/`;

  const pages = [
    { name: "Catálogos", href: "/catalogos", current: false },
    { name: "Directorio de Derivación", href: "#", current: true },
  ];
  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <Link to="/catalogos/gestion-instituciones/crear" type="button">
              <ButtonAdd text="Crear" type="button" />
            </Link>
            <ButtonAdd
              className="ml-2"
              text="Filtros"
              type="button"
              onClick={() => {
                setOpenFilters(true);
              }}
              icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
            />
            <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />
          </div>
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            handleEliminar={handleEliminar}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            link={true}
            linkTo={linkEditar}
            showVer={true}
            setOrder={setOrder}
            order={order}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle directorio de derivación"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <LibraryIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle
                  detalle={gestionInstitucionDetalle}
                  cancelar={setEliminar}
                />
              }
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${nombreGestionInstitucion}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteCatalogos(idGestionInstitucion, 'gestioninstitucion', token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false);
                        toast.error(data.message)
                      } else {
                        setReFetch(true);
                        setLoading(false);
                        toast.success('Registro eliminado con éxito...!')
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => { }}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={<Filtros setFilters={setFilters} setOpenFilters={setOpenFilters} />}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
