import { React, useState } from "react";
import { Formik } from "formik";
import { EstadoProceso } from "./estadoProceso";
import { useSelector } from "react-redux";
import {
  postExpedienteProceso,
  postExpedienteSentencia,
} from "./services";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  expediente: Yup.string().required("expediente es requerido"),
  estado_proceso: Yup.string().required("estado del proceso es requerido"),
  fecha: Yup.string().required("fecha es requerida"),
  numero_sentencia: Yup.string().when("requiere_sentencia", {
    is: true,
    then: Yup.string().required("numero sentencia es requerido"),
  }),
  fecha_sentencia: Yup.string().when("requiere_sentencia", {
    is: true,
    then: Yup.string().required("fecha sentencia es requerida"),
  }),
  tipo_proceso_secundario: Yup.string().when("proceso_secundario", {
    is: true,
    then: Yup.string().required("tipo proceso es requerido"),
  }),
  estado_proceso_secundario: Yup.string().when("proceso_secundario", {
    is: true,
    then: Yup.string().required("estado proceso es requerido"),
  }),
});

export const FormLitigioEstrategico = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idCaso = props.caso.id;
  const caso = props.caso;
  const [idExpedienteProceso,setIdExpedienteProceso]= useState(0);

  return (
    <Formik
      initialValues={{
        numero_sentencia: "",
        fecha_sentencia: "",
        reparacion_digna_via_penal: false,

        expediente: "",
        expediente_sindicado: "",
        etapa_procesal: "",
        resultado_audiencia: "",
        tipo_proceso: "",
        estado_proceso: "",
        medida: "",
        proceso_secundario: false,
        fecha: "",
        comentarios: "",

        tipo_proceso_secundario: "",
        estado_proceso_secundario: "",
        tipo_apelacion: null,
        amparo_provisional: false,
        fecha_proceso_secundario: "",
        comentarios_secundarios: "",
        causa_penal:"",
        causa_penal_proceso_secundario:"",
        tipo_apelacion2:"",
        audiencia:"",
        audiencia_id:null
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        await postExpedienteProceso(values, idCaso, token)
          .then(async (data) => {
            let expedienteProceso;
            if (data?.status === 200 || data?.status === 201) {
              expedienteProceso = data.data.id;
              setIdExpedienteProceso(data.data.id);
              if(values.requiere_sentencia){
                await postExpedienteSentencia(values,idCaso,expedienteProceso,token);
              }
              toast.success("Proceso creado con éxito..!");
            } 
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });

          resetForm({})
      }}
    >
      {(props) => (
        <EstadoProceso
          {...props}
          caso={caso}
          expedienteProceso={idExpedienteProceso}
        />
      )}
    </Formik>
  );
};
