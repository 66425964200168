import { ButtonSiavi } from "../../../../components/common/Button";
import { ButtonSiaviCancelar } from "../../../../components/common/ButtonCancelar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import { SelectSiavi } from "../../../../components/common/Select"
import {
  getMotivoVisitaOrientacion,
  postMotivoVisitaOrientacion,
  getUsuarios
} from "./services";
import Label from "../../../../components/common/Label";
import Spinner from "../../../../components/common/Spinner";
import toast from 'react-hot-toast';
import Toggle from "../../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  motivo_visita: Yup.string()
    .required("Motivo Visita es requerido")
});

const useFetchMotivoVisita = (token) => {
  const [state, setState] = useState({
    motivos: [],
  });

  useEffect(() => {
    getMotivoVisitaOrientacion(token).then((motivos) => {
      setState({
        motivos: motivos,
      });
    });
  }, [token]);

  return state;
};

const useFetcUsuarios = (token) => {
  const [state, setState] = useState({
    users: [],
  });

  useEffect(() => {
    getUsuarios(token).then((users) => {
      setState({
        users: users,
      });
    });
  }, [token]);

  return state;
};

export const OrientacionesPersona = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [pro, setPro] = useState(false);
  const { motivos } = useFetchMotivoVisita(token);
  const { users } = useFetcUsuarios(token);

  return (
    <div>
      <Formik
        initialValues={{
          persona: props?.Persona?.id,
          atendido: null,
          sede: sede,
          motivo_visita: "",
          profesional_al_que_visita: "",
          denuncia: false,
          observaciones: ""
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if(pro === true && values.profesional_al_que_visita === "" ){
            toast.error("Profesional al que visita es requerido")
          }else{
            await postMotivoVisitaOrientacion(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Motivo de Visita creado con éxito...!')
                props.cancelar();
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5 py-4">
              <SelectSiavi
                name="motivo_visita"
                value={values.motivo_visita}
                label="Motivo de Visita"
                validacion={true}
                onChange={(e) => {
                  setFieldValue('motivo_visita', e.target.value)
                  motivos.results.forEach(element => {
                    if (element.id === Number(e.target.value)) {
                      setPro(element?.requiere_profesional);
                    }
                  });
                }}
                valueInit=""
                selectInit="Elegir motivo de visita"
                list={
                  motivos?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })
                }
              />
              {!props.isFirst && pro && (
                <div className="mt-4">
                  <SelectSiavi
                    name="profesional_al_que_visita"
                    value={values.profesional_al_que_visita}
                    label="Profesional al que visita"
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir profesional" z
                    list={
                      users?.results?.map((item) => {
                        return (
                          <option key={item?.username} value={item.id}>
                            {item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}
                          </option>
                        );
                      })
                    }
                  />
                </div>)}
              {!props.isFirst && (
                <div className="mt-4">
                  <Label text="Denuncia" />
                  <div className="mt-1">
                    <Toggle enabled={values.denuncia} name="denuncia" />
                  </div>
                </div>)}
              <div className="mt-4">
                <Label text="Observaciones" />
                <div className="mt-1">
                  <TextAreaSiavi
                    name="observaciones"
                    type="text"
                    rows={2}
                    columns={50}
                    value={values.observaciones}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            {/* {props.cargando ? (
       <div className="py-5">
          <Spinner />
       </div>
      ) : (
        <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
          <thead className="bg-maivi-gray-5">
            <tr>
              <th className={th}>Nombre</th>
              <th className={th}>Fecha</th>
              <th className={th}>Editar</th>
            </tr>
          </thead>
          <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
            {props.orientaciones.results
              ?.filter((x) => x.caso_admitido === null)
              .map((item) => (
                <tr key={item.id} className="hover:bg-maivi-gray-5">
                  <td className="px-6 whitespace-nowrap">
                    <div className="text-xs text-maivi-primaryFont">
                      {`${item.persona_detalle.primer_nombre} ${item.persona_detalle.primer_apellido}`}
                    </div>
                  </td>
                  <td className="px-6 whitespace-nowrap">
                    <div className="text-xs text-maivi-primaryFont">
                      {item.fecha}
                    </div>
                  </td>
                  <td className="px-6 whitespace-nowrap">
                    <div className="text-xs text-maivi-primaryFont">
                      <Link
                        to={`/funcionalidades-generales/editar-orientacion/${item.id}`}
                      >
                        <button
                          type="button"
                          onClick={() => {}}
                          className={buttonAddClass}
                        >
                          Editar
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
 */}
            <div className="flex flex-row justify-center">
              <div className="w-5/12 mx-1">
                <ButtonSiaviCancelar text="Cancelar" onClick={props.cancelar} />
              </div>
              {/* {
               (props.ocultarOrientacion) ? "" :
               <div className="w-5/12">
               <Link to={`/funcionalidades-generales/crear-orientacion/${props?.Persona?.id}`}>
                 <ButtonSiavi
                   text="Crear orientación"
                   onClick={() => {
                     store.dispatch(saveOrientacion({}));
                   }}
                 />
               </Link>
             </div>
             } */}
              <div className="w-5/12 mx-1">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={"Agregar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
