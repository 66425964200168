/* eslint-disable jsx-a11y/iframe-has-title */
import Label from "../../../components/common/Label";

export const FormDetalle = (props) => {
    return (
      <div className="border p-3 rounded-lg">
        <center>
        <Label text="Pregunta" />
        <Label text={props.detalle.pregunta}/>
        <br />
        <Label text="Respuesta" />
        <Label text={props.detalle.respuesta}/>
        </center>
        <br />
        <video src={props?.detalle?.url} controls>

        </video>
      </div>
    );
  };
  