/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clases } from "../../../../../../helpers/clases";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { FormDetalle } from "./Detalle";
import { Gestionar } from "./gestionar";
import { audiencias } from "./services";
import TableGeneric from "../../../../../../components/common/TableGeneric";
import ModalGenerico from "../../../../../../components/common/Modal";
import ModalBig from "../../../../../../components/common/ModalTwo";
import Spinner from "../../../../../../components/common/Spinner";

const columns = [
  { title: "No.", value: "id", tipo: "text"},
  { title: "Expediente", value: "expediente.numero_expediente", tipo: "text"},
  
  { title: "Fecha plan.", value: "fecha_hora_planificada_inicia", tipo: "datetime", ordenar: "fecha_hora_planificada_inicia" },
  { title: "Tipo audiencia", value: "tipo_audiencia.nombre", tipo: "text" },
  { title: "Juzgado", value: "gestion_institucion.nombre_fiscalia", tipo: "text" },
  { title: "Resultado Audiencia", value: "resultado_audiencia.nombre", tipo: "text" },
  
//  { title: "Telefono", value: "expediente.caso_persona.persona.telefono.numero_telefono", tipo: "text" },
/*{
  title: "Querellante",
  value: null,
  values: [
    "querellante.primer_nombre",
    "querellante.segundo_nombre",
    "querellante.primer_apellido",
    "querellante.segundo_apellido"
  ],
  tipo: "text"
},
*/
{
    title: "Abogado",
    value: null,
    values: [
      "profesional_asignado.first_name",
      "profesional_asignado.last_name"
    ],
    tipo: "text",
    ordenar: "profesional_asignado"
  }
];

export const TableAudiencias = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filters] = useState("");
  const [order, setOrder] = useState("-fecha_accion");
  const [endpoint] = useState(`accionesseguimientoscaso?caso=${props.caso.id}`);
  const [detalle,setDetalle] = useState(false);
  const [registro,setRegistro] = useState({});
  const [gestionar,setGestionar] = useState(false);
  const permiso = "accionesseguimientoscaso";

  

const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


const permisoView = "ExpedientesAL";  //mm permiso padre

//mm procedimiento de validacion de permisos
const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
        if (menu?.permiso === permisoView) {
            menu?.opciones?.forEach(op => {
                if (op.permiso === permiso) {
                    retorno = op.ver;
                }
            })
        }
    });
    
    //        retorno = true;  // mm comentar para que funcione
    //        retorno = true;  // mm comentar para que funcione
    return retorno;

}



  useEffect(()=>{
    setTableLoading(true);
    audiencias(urlFetch, props?.caso?.id, token).then((data) => {

      data?.results?.forEach(element => {
        if(element.es_vencida){
          element.fondo = 'bg-red-50'
        } else if(element.es_por_vencer){
          element.fondo = 'bg-yellow-50'
        } else if(element.completada){
          element.fondo = ''
        }
      });
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, filters, sede, order,endpoint]);

  const handleVer =(registro)=>{
    setRegistro(registro);
    setDetalle(true);
  }

  const opcion = (registro) => {
    return(
      <button
      type="button"
      onClick={() => {
          setRegistro(registro);
          setGestionar(true);
      }}
      className={clases.addIcon}
    >
      <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
      <span className="tooltiptext">Gestionar</span>
    </button>
    )
  } 

  return (
    <div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <TableGeneric
            dataTable={dataTable}
            tableColumns={columns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={true}
            order={order}
            setOrder={setOrder}
            handleVer={handleVer}
            addAccion={false}
            opcion1={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
       {detalle && (
        <ModalBig
          title={`Detalle de acción`}
          open={detalle}
          closeModal={() => { }}
          cerrar={() => {
            setDetalle(false);
          }}
          children={
           <FormDetalle registro={registro} token={token} />
          }
        />
      )}
       {gestionar && (
        <ModalGenerico
          title={`Gestionar`}
          open={gestionar}
          closeModal={() => { }}
          cerrar={() => {
            setGestionar(false);
          }}
          children={
           <Gestionar 
            registro={registro} 
            setGestionar={setGestionar}
           />
          }
        />
      )}
    </div>
  );
};
