/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { Formik } from "formik";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { InputSiavi } from "../../../../../components/common/InputText";
import { SelectSiavi } from "../../../../../components/common/Select";
import { clases } from "../../../../../helpers/clases";
import { PaperClipIcon } from "@heroicons/react/solid";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { FormDetalleDiligenciaUrgente } from "./detalle_diligencia_urgente";
import {
  postNotificaciones,
  getDiligencias,
  getModalidades,
  getTipoDocumento,
  getDiligenciasCreadas,
  deleteDiligencias,
} from "./services";
import {
  TrashIcon,
  EyeIcon,
  //PencilAltIcon
} from "@heroicons/react/solid";
import CustomTable from "../../../../../components/common/Table";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import Label from "../../../../../components/common/Label";
import toast from "react-hot-toast";
import * as Yup from "yup";

const tableColumns = [
  { title: "Id", value: "id", tipo: "text"},
  { title: "Fecha", value: "fecha_creacion", tipo: "date"},
  { title: "Tipo", value: "tipo_diligencia.nombre", tipo: "text" },
  { title: "No. documento", value: "numero_documento", tipo: "text" },
  {
    title: "Urgente",
    value: "urgente",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Usuario creación",
    value: null,
    values: ["usuario_creacion.first_name", "usuario_creacion.last_name"],
    tipo: "text",
  },
];

const validaciones = Yup.object().shape({
  comentario: Yup.string()
    .max(300, "maximo 300 caracteres")
    .required("comentario es requerido"),
  diligencia: Yup.string().required("tipo diligencia es requerido"),
});

const classIconDelete = `hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`;

const classIconEdit = `hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`;

export const Diligencias = (props) => {
  const token = useSelector((state) => state?.loginReducer?.data?.token);
  const [cargando, setCargando] = useState(false);
  const idCaso = props?.expediente?.caso;
  const idExpediente = props?.expediente?.id;
  const idTipoProceso = props?.expediente?.tipo_proceso?.id;
  const [diligencias, setDiligencias] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [diligenciasCreadas, setDiligenciasCreadas] = useState([]);
  const [archivos, setArchivos] = useState(false);
  const [cargandoDiligencias, setCargandoDiligencias] = useState(false);
  const [registro, setRegistro] = useState({});
  const [eliminar, setEliminar] = useState(false);
  const [refetch, setReFetch] = useState(false);
  const [ver, setVer] = useState(false);
  const [id, setId] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [urlFetch, setUrlFech] = useState(undefined);

  const permisosRegistro = useSelector((state) =>
    state.ChangePermisos?.menus?.registros?.menus
      ? state.ChangePermisos?.menus?.registros?.menus
      : []
  ); //mm buscar el registro
  const permisoView = "ExpedientesAL"; //mm permiso padre

  useEffect(() => {
    getDiligenciasCreadas(idExpediente, token, urlFetch).then((data) => {
      setDataTable(data);
    });
  }, [urlFetch, token]);

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach((menu) => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach((op) => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        });
      }
    });

    return retorno;
  };

  useEffect(() => {
    getDiligencias(token).then((data) => {
      setDiligencias(data);
    });
  }, []);

  useEffect(() => {
    getModalidades(token).then((data) => {
      setModalidades(data);
    });
  }, []);

  useEffect(() => {
    getTipoDocumento(token).then((data) => {
      setTipoDocumentos(data);
    });
  }, []);

  useEffect(() => {
    setCargandoDiligencias(true);
    getDiligenciasCreadas(idExpediente, token).then((data) => {
      setDiligenciasCreadas(data);
      setCargandoDiligencias(false);
    });
  }, [refetch]);

  const headersTable = [
    "id",
    "Fecha",
    "Tipo",
    "Número Documento",
    "Urgente",
    "Usuario Crea",
    "Acciones",
  ];

  const opciones = (item) => {
    return (
      <span>
        {CheckPermiso("NotificacionesAdjuntarDocumentos") === true && (
          <button
            type="button"
            onClick={() => {
              setId(item.id);
              setRegistro(item);
              setArchivos(true);
            }}
            className="hover:bg-maivi-primaryLightBlue tooltip inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
          >
            <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
            <span className="tooltiptext">Adjuntar archivos</span>
          </button>
        )}
        {CheckPermiso("EliminarNotificaciones") === true && (
          <button
            type="button"
            onClick={async () => {
              setEliminar(true);
              setRegistro(item);
            }}
            className={classIconDelete}
          >
            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
          </button>
        )}
        <button
          type="button"
          onClick={async () => {
            setVer(true);
            setRegistro(item);
          }}
          className={classIconEdit}
        >
          <EyeIcon className="h-6 w-6  text-maivi-primaryFont hover:text-white" />
        </button>
      </span>
    );
  };
  return (
    <Formik
      initialValues={{
        comentario: "",
        urgente: true,
        plazo: "",
        modalidad: "",
        tipo_documento: "",
        numero_documento: "",
        diligencia: "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        setCargando(true);
        await postNotificaciones(
          idCaso,
          idExpediente,
          idTipoProceso,
          values.diligencia,
          values,
          token
        )
          .then((data) => {
            if (data?.status >= 200 || data?.status <= 210) {
              toast.success("diligencia agregada con éxito..!");
              setCargando(false);
              props.setDilegencia(false);
              setReFetch(true);
              setReFetch(false);
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              toast.error(error.response.data.message);
              setCargando(false);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
              setCargando(false);
            }
          });
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-3 border rounded-lg p-4">
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <SelectSiavi
                label="Tipo diligencia"
                validacion={true}
                name="diligencia"
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar tipo diligencia"
                list={diligencias?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              />
            </div>

            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                label="plazo"
                name="plazo"
                onChange={handleChange}
                type="date"
                className="mb-2"
              />
            </div>
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <SelectSiavi
                label="Modalidad"
                name="modalidad"
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar modalidad"
                list={modalidades?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              />
            </div>
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <SelectSiavi
                label="Tipo documento"
                name="tipo_documento"
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar tipo documento"
                list={tipoDocumentos?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              />
            </div>
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                label="Número de documento"
                name="numero_documento"
                onChange={handleChange}
                className="mb-2"
              />
            </div>
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <div className="col-span-6 mt-5">
                <Label text="Urgente" />
                <Toggle enabled={values.urgente} name="urgente" />
              </div>
            </div>
            <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 sm:col-span-6">
              <TextAreaSiavi
                label="Comentario"
                validacion={true}
                name="comentario"
                onChange={handleChange}
                rows={2}
                cols={10}
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6 flex items-end">
              {cargando ? (
                <div className="p-2">
                  <Spinner />
                </div>
              ) : (
                CheckPermiso("GrabarNotificacion") === true && (
                  <div className="mt-3 mb-2">
                    <ButtonSiavi
                      type="button"
                      text="Agregar"
                      onClick={handleSubmit}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div className="mt-3 p-3 border rounded-lg">
            <Label text="Diligencias creadas" />
            <CustomTable
              dataTable={dataTable}
              tableColumns={tableColumns}
              fetchPage={setUrlFech}
              pagination={true}
              addAccion={true}
              opciones={opciones}
            />
          </div>
          {archivos && (
            <ModalAmplio
              title={`Adjuntar archivos`}
              open={true}
              closeModal={() => {}}
              cerrar={() => {
                setArchivos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PaperClipIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <GestionArchivos
                  idRecurso={id}
                  recurso="DILIGENCIAS"
                  claseDocumento="diligencias"
                  caso={idCaso}
                  expediente={idExpediente}
                />
              }
            />
          )}
          {eliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro?`}
              open={true}
              closeModal={() => {}}
              cerrar={() => {
                setEliminar(false);
              }}
              children={
                <BotonesConfirmar
                  aceptar={async () => {
                    await deleteDiligencias(registro.id, token)
                      .then((data) => {
                        if (data?.status >= 200 || data?.status <= 210) {
                          toast.success("Registro eliminado con éxito...!");
                          setReFetch(true);
                          setReFetch(false);
                          setEliminar(false);
                        }
                      })
                      .catch(function (error) {
                        if (
                          error.response.status === 400 ||
                          error.response.status === 401
                        ) {
                          toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                          toast.error(
                            "Error interno comuniquese con el administrador"
                          );
                        }
                      });
                  }}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}

          {ver && (
            <ModalGenerico
              title={`Detalle`}
              open={true}
              closeModal={() => {}}
              cerrar={() => {
                setVer(false);
              }}
              children={
                <FormDetalleDiligenciaUrgente
                  registro={registro}
                  notificacion={true}
                />
              }
            />
          )}
        </form>
      )}
    </Formik>
  );
};
