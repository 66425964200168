import { React, useEffect, useState } from "react";
import { UserIcon } from "@heroicons/react/outline";
import { XCircleIcon, FilterIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./Detalle";
import { useSelector } from "react-redux";
import { deleteRegistro } from "../../../../helpers/catalogos";
import { orientaciones, getOrientaciones, exportarData, exportarResumen } from "./services";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { FormReferencia } from "./referencia";
import { useHistory } from "react-router-dom";
import ModalGenerico from "../../../../components/common/Modal";
import ModalBig from "../../../../components/common/ModalTwo";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import FormEliminar from "../../../../components/common/FormEliminar";
import CustomTable from "../../../../components/common/Table";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import ButtonRefresh from "../../../catalogos/common/ButtonRefresh";
import toast from "react-hot-toast";
import Filtros from "./filtros";
import ExportButton from "../../../../components/common/ExportButton";
import { clases } from "../../../../helpers/clases";
import { CambiarEstado } from "./cambiar_estado";

const tableColumns = [
  { title: "Orientación", value: "id", tipo: "text", ordenar: "id" },
  { title: "Fecha", value: "fecha", tipo: "date", ordenar: "fecha" },
  {
    title: "Nombre",
    value: null,
    values: [
      "persona_detalle.primer_nombre",
      "persona_detalle.segundo_nombre",
      "persona_detalle.primer_apellido",
      "persona_detalle.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Expediente", value: "caso_detalle.numero_caso", tipo: "text" },
  {
    title: "Caso Admitido",
    value: "caso_admitido",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Profesional",
    value: null,
    values: [
      "profesional.first_name",
      "profesional.last_name",
    ],
    tipo: "text"
  },
];

export const TableOrientacionesHistoricas = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const usuario = useSelector((state) => state.loginReducer?.data?.usuario?.user);
  const idUser = useSelector((state) => state.loginReducer?.data?.usuario?.user?.id);
  const [isEliminar, setEliminar] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [verDetalle, setDetalle] = useState(false);
  const [orientacionDetalle, setOrientacionDetalle] = useState({});
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const [order, setOrder] = useState("-fecha");
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [addReferencia, setAddReferencia] = useState(false);
  const [orientacion, setOrientacion] = useState({});
  const [registro, setRegistro] = useState("");
  const [cambiarEstado, setCambiarEstado] = useState(false);
  let history = useHistory();

  
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "historicos";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    // retorno = false;  // mm comentar para que funcione
    return retorno;

  }


  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "orientacion";
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      // permisoAgregar = true;
    }
  });

  const exportResumen = (url) => {
    setTableLoading(true);
    exportarResumen(url, filters, token).then(async data => {
      let fileName = 'Resumen';
      let dataExport = data;
      let columns = [];

      if (url === 'resumenorienacion') {
        columns = [
          { name: 'Sede', value: 'sede' },
          { name: 'Total Admisible', value: 'total_admisible' },
          { name: 'Total No Admisible', value: 'total_no_admisible' },
        ];
        fileName = 'resumenorienacion';
      }

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo: 'xls' });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    })
  }

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, token).then(async (data) => {
      const fileName = 'orientaciones';
      let dataExport = data;
      let columns = [
        { name: "ID", value: "id" },
        { name: "Acompañamiento", value: "acompanamientos" },
        { name: "Admisibilidad", value: "admisibilidad" },
        { name: "Año historico", value: "ano_historico" },
        { name: "Atenciones", value: "atenciones" },
        { name: "Caso Admitido", value: "caso_admitido" },
        { name: "Comentarios", value: "comentarios" },
        { name: "Como le gusta que le llamen", value: "como_le_gusta_que_le_llamen" },
        { name: "Consultas", value: "consultas" },
        { name: "Delitos", value: "delitos" },
        { name: "Denuncia", value: "denuncia" },
        { name: "Descripcion motivo no admisibilidad", value: "descripcion_motivo_no_admisibilidad" },
        { name: "Referencia", value: "doc_referencia" },
        { name: "Donde denuncia", value: "donde_denuncia" },
        { name: "Entidad que deriva", value: "entidad_deriva" },
        { name: "Es admisible", value: "es_admisible" },
        { name: "Estado", value: "estado" },
        { name: "Fecha", value: "fecha" },
        { name: "Fecha denuncia", value: "fecha_denuncia" },
        { name: "Fecha referencia", value: "fecha_referencia" },
        { name: "Historia", value: "historica" },
        { name: "IV historico", value: "iv_historico" },
        { name: "No admisibilidad", value: "no_admisibilidad" },
        { name: "No. denuncia", value: "no_denuncia" },
        { name: "Observaciones", value: "observaciones" },
        { name: "Persona", value: "persona" },
        { name: "Procesos", value: "procesos" },
        { name: "Profesional", value: "profesional" },
        { name: "Sede", value: "sede" },
        { name: "Servicios", value: "servicios" },
        { name: "Tipo documento derivación", value: "tipo_documento_derivacion" },
        { name: "Tipo persona", value: "tipo_persona" },
      ];

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  const fetchOrientaciones = () => {
    setTableLoading(true);
    orientaciones(idUser,urlFetch, order, search, filters, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetOrientaciones = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getOrientaciones(idUser,urlFetch, search, order, filters, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchOrientaciones, [urlFetch, order, filters, reFetch, token]);
  useEffect(fetchGetOrientaciones, [urlFetch, token, order, search, filters]);

  const handleVer = (item) => {
    setOrientacionDetalle(item);
    setDetalle(true);
  };

  const handleAgregar = (item) => {
    if (item.caso_admitido === false) {
      setOrientacion(item);
      setAddReferencia(true);
    } else {
      toast.error("Solo se puede agregar una referencia externa a una orientacion que no fue admitida")
    }
  };

  const handleEditar = (registro) => {
    let existe = (usuario?.is_superuser) ? true : false;
    //let existe = false;

    registro?.profesionales?.forEach(element => {
      if (element === usuario?.id) {
        existe = true;
      }
    });

    if (registro?.profesional?.id !== usuario?.id && existe === false) {
      toast.error("No tiene acceso para editar esta orientación")
    } else {
      history.push(`/funcionalidades-generales/editar-orientacion/${registro.id}?admitido=${registro?.caso_admitido}`);
    }
  };

  const handleEliminar = (id) => {
    setId(id);
    setEliminar(true);
  };

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Mis expedientes históricos", href: "#", current: true },
  ];

  const opcion = (registro) => {
    return (
      <button
        type="button"
        className={clases.trashIcon}
        onClick={() => {
          setCambiarEstado(true);
          setRegistro(registro);
        }}
      >
        <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
        <span className="tooltiptext">cambiar estado</span>
      </button>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div className="justify-end">
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />

{(CheckPermiso('exportarinformacion') === true) &&    //mm permiso agregado        
          <ExportButton
            handlePdf={() => {
              exportData('pdf');
            }}
            show={'xls'}
            handleExcel={() => {
              exportData('xls');
            }}
          />
          }
          {(CheckPermiso('exportarresumen') === true) &&    //mm permiso agregado        
          <ExportButton
            title={'Resumen'}
            handleOpciones={exportResumen}
            opciones={[
              { text: 'Resumen de Orientaciones', value: 'resumenorienacion' }
            ]}
          />
          }
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showEliminar={(CheckPermiso('eliminarregistro') === true)}
            handleEliminar={handleEliminar}
            //showEditar={true}
            showEditar={(CheckPermiso('editarregistro') === true)}   //mm permiso agregado        
            handleEditar={handleEditar}
            setOrder={setOrder}
            order={order}
            handleAgregar={handleAgregar}
           //addAccion={true}
           addAccion={(CheckPermiso('inactivarregistro') === true)}   //mm permiso agregado        
           opciones={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle de orientación"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle
                  detalle={orientacionDetalle}
                  cancelar={setEliminar}
                />
              }
            />
          )}
          {addReferencia && (
            <ModalBig
              title={"Agregar referencia"}
              open={addReferencia}
              closeModal={() => { }}
              cerrar={() => {
                setAddReferencia(false);
              }}

              children={
                <FormReferencia orientacion={orientacion} />
              }
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar la orientación número ${id}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteRegistro(id, permiso, token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false);
                        setEliminar(false);
                        setId("");
                        setReFetch(true);
                        toast.error(data.message)
                      } else {
                        toast.success("Registro eliminado con éxito...!");
                        setLoading(false);
                        setEliminar(false);
                        setId("");
                        setReFetch(true);
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                    setId("");
                  }}
                />
              }
            />
          )}
          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => { }}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={<Filtros setFilters={setFilters} setOpenFilters={setOpenFilters} />}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
          {cambiarEstado && (
            <ModalGenerico
              title={"Cambiar el estado de la orientacion"}
              open={cambiarEstado}
              closeModal={() => { }}
              cerrar={() => {
                setCambiarEstado(false);
              }}
              children={
                <CambiarEstado
                  registro={registro}
                  setCambiarEstado={setCambiarEstado}
                  setReFetch={setReFetch}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
