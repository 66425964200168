/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import { useSelector } from "react-redux";
import { ClipboardCheckIcon, DocumentIcon, EyeIcon, FilterIcon, PlusCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import { ChangeSearch } from '../../../../app/redux/actions/search';
import { store } from '../../../../app/redux/store/store';
import { clases } from "../../../../helpers/clases";
import { Acciones } from "../acciones/acciones";
import { BuscarExpedientes } from "./buscar_expediente";
import { Seguimientos } from "../seguimientos/Seguimientos";
import { FormDetalle } from "./detalleRecurso";
import toast, { ErrorIcon } from "react-hot-toast";
import {
  recursos,
  getRecursos,
  actualizarRecurso,
  actualizarAccion,
} from "./services";
import Alert from "../../../../components/common/Alert";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";
import CustomTable from "../../../../components/common/Table";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import ModalGenerico from "../../../../components/common/Modal";
import Filtros from "./filtros";
import ModalBig from "../../../../components/common/ModalTwo";
import moment from "moment";
import ModalAmplio from "../../../../components/common/ModalAmplio";

const tableColumns = [
  { title: "Reserva", value: "id", tipo: "text", ordenar: "id" },
  { title: "Sede", value: "recurso.sede_recurso.sede", tipo: "text", ordenar: "recurso__sede_recurso__sede" },
  { title: "Recurso", value: "recurso.nombre", tipo: "text", ordenar: "recurso__nombre" },
  { title: "Fecha", value: "fecha", tipo: "date", ordenar: "fecha" },
  { title: "Hora", value: "hora", tipo: "text", ordenar: "hora" },
  {
    title: "Disponible",
    value: "disponible",
    tipo: "boolean",
    text: { true: "Sí", false: "No", null: "No" },
  },
  { title: "Acción", value: "accion_recurso.id", tipo: "text" },
  { title: "Tipo", value: "accion_recurso.tipo", tipo: "text" },
  { title: "Profesional Asignado", value: "accion_recurso.profesional_asignado", tipo: "text" },
  //{ title: "t", value: "accion_recurso.accion_seguimiento", tipo: "text" },

  {
    title: "Completado", value: "accion_recurso.completada",
    tipo: "boolean",
    text: { true: "Sí", false: "No", null: "No" },
  },

];


export const TableRecursos = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.loginReducer.data.usuario.user.sede.id);
  const usuario = useSelector((state) => state.loginReducer.data.usuario.user);
  const search = useSelector((state) => state.inputChange.search)
  const [order, setOrder] = useState("fecha");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filtrar, setFiltrar] = useState(false);
  const [filtros, setFiltros] = useState(`fecha_gte=${moment().format('YYYY-MM-DD')}&fecha__lte=${moment().add(30, "days").format("YYYY-MM-DD")}&recurso__sede_recurso=${sede}&disponible=true`);
  const [registro, setRegistro] = useState({});
  const [agendarAccion, setAgendarAccion] = useState(false);
  const [modalAcciones, setModalAcciones] = useState(false);
  const [verDetalle, setVerDetalle] = useState(false);
  const [solicitarExpediente, setSolicitarExpediente] = useState(false);
  const [caso, setCaso] = useState({});
  const [expediente, setExpediente] = useState({});
  const [tipo, setTipo] = useState("");
  const [cancelarRecurso, setCancelarRecurso] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "recursodisponibilidad";



  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "ReservasDeEspaciosFisicos";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }






  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  useEffect(() => {
    setTableLoading(true);
    recursos(urlFetch, search, order, filtros, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, search, order, filtros, refetch, token]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getRecursos(urlFetch, search, order, filtros, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, order, filtros, token]);


  const linkEditar = `/funcionalidades-generales/actualizar/`;
  const pages = [
    { name: "Funcionalidades", href: "/funcionalidades-generales", current: false },
    { name: "Reservas de espacios físicos", href: "#", current: true },
  ];

  const opcion = (registro) => {
    return (
      <div>
        {(CheckPermiso('AgendarAcciones') === true) &&
          <button
            type="button"
            className={clases.addIcon2}
            onClick={() => {
              setTipo("acción");
              setRegistro(registro);
              setAgendarAccion(true);
            }}
          >
            <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
            <span className="tooltiptext"> Agendar acción </span>
          </button>
        }
        {(CheckPermiso('AgendarSeguimientos') === true) &&
          <button
            type="button"
            className={clases.addIcon2}
            onClick={() => {
              setTipo("seguimiento");
              setRegistro(registro);
              setAgendarAccion(true);
            }}
          >
            <DocumentIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
            <span className="tooltiptext"> Agendar seguimiento </span>
          </button>
        }
        <button
          type="button"
          className={clases.addIcon2}
          onClick={() => {
            setRegistro(registro);
            setVerDetalle(true);
          }}
        >
          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
          <span className="tooltiptext">Ver Detalle</span>
        </button>
        {(CheckPermiso('CancelarReservacion') === true) &&
          <button
            type="button"
            className={clases.trashIcon}
            onClick={() => {
              //if ((registro?.accion_recurso?.profesional_asignado === `${usuario?.first_name} ${usuario?.last_name}`) || registro?.accion_recurso?.profesional_crea === `${usuario?.first_name} ${usuario?.last_name}`) {
              setRegistro(registro);
              setCancelarRecurso(true);
              //} else {
              //  toast.error("No puede cancelar esta reservación, porque usted no la realizó.")
            }
            }
          //}
          >
            <ErrorIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
            <span className="tooltiptext"> Cancelar reservación  </span>
          </button>
        }
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <ButtonAdd
              className="ml-2"
              text="Refrescar"
              type="button"
              onClick={() => {
                setFiltros("")
              }}
              icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
            />
            <ButtonAdd
              className="ml-2"
              text="Filtrar"
              type="button"
              onClick={() => {
                setFiltrar(true)
              }}
              icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
            />
          </div>
        )}

      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            link={true}
            linkTo={linkEditar}
            agregar={false}
            showEditar={false}
            showVer={false}
            showEliminar={false}
            setOrder={setOrder}
            order={order}
            addAccion={true}
            opciones={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
        {filtrar && (
          <ModalGenerico
            title={`Aplicar filtros`}
            open={filtrar}
            closeModal={() => { }}
            cerrar={() => {
              setFiltrar(false);
            }}
            children={
              <Filtros
                setFiltrar={setFiltrar}
                setFiltros={setFiltros}
                setUrlFech={setUrlFech}
              />
            }
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <FilterIcon className="h-6 w-6 text-green-600" />
              </div>
            }
          />
        )}
        {agendarAccion && (
          <ModalGenerico
            title={`Agendar ${tipo}`}
            open={agendarAccion}
            closeModal={() => { }}
            cerrar={() => {
              setAgendarAccion(false);
            }}
            children={
              <div>
                {
                  registro.disponible ?
                    '' :
                    <Alert type="warning" mensaje="¿Este recurso ya está reservado, y estará reservándolo para dos acciones diferentes, desea continuar?" />
                }
                <BotonesConfirmar
                  aceptar={() => {
                    if ((registro?.accion_recurso?.compartir_recurso === false || registro?.accion_recurso?.compartir_recurso === undefined) && (!registro.disponible)) {
                      toast.error("No se permite compartir recurso.")
                    } else {
                      setAgendarAccion(false);
                      setSolicitarExpediente(true);
                    }
                  }}
                  cancelar={() => {
                    setAgendarAccion(false);
                  }}
                />
              </div>
            }
          />
        )}

        {solicitarExpediente && (
          <ModalGenerico
            title={`Buscar y seleccionar expediente`}
            open={solicitarExpediente}
            closeModal={() => { }}
            cerrar={() => {
              setSolicitarExpediente(false);
            }}
            children={
              <BuscarExpedientes
                setModalAcciones={setModalAcciones}
                setSolicitarExpediente={setSolicitarExpediente}
                setCaso={setCaso}
                setExpediente={setExpediente}
                tipo={tipo}
              />
            }
          />
        )}

        {modalAcciones && (
          <ModalBig
            title={`Agendar ${tipo}`}
            open={modalAcciones}
            closeModal={() => { }}
            cerrar={() => {
              setModalAcciones(false);
              setRefetch(true);
              setTimeout(() => {
                setRefetch(false);
              }, 400);
            }}
            children={
              tipo === "acción" ?
                <Acciones
                  registro={registro}
                  caso={caso}
                  expediente={expediente}
                /> :
                <Seguimientos
                  registro={registro}
                  caso={caso}
                  expediente={expediente}
                />
            }
          />
        )}

        {cancelarRecurso && (
          <ModalGenerico
            title={`Cancelar recurso`}
            open={cancelarRecurso}
            closeModal={() => { }}
            cerrar={() => {
              setCancelarRecurso(false);
            }}
            children={
              <div>
                <Alert type="warning" mensaje="Esto cancelará la acción planificada, ¿Está seguro que desea continuar?" />
                {/*({ values, handleChange, isSubmitting, handleSubmit }) => (
                    <TextAreaSiavi
                      label="Observaciones"
                      name="observaciones_fecha"
                      value={values.observaciones_fecha}

                      onChange={handleChange}
                     className="mb-4"
                    />
                 )
                */}
                <BotonesConfirmar
                  aceptar={async () => {
                    if (registro?.accion_recurso?.completada === false && registro.disponible === false && (registro?.accion_recurso?.fecha_completado === "None" || registro?.accion_recurso?.fecha_completado === null)) {
                      registro.disponible = true;
                      await actualizarRecurso(registro.id, registro, token)
                        .then((data) => {
                          if (data?.status >= 200 && data?.status <= 210) {
                            //                            let accion = registro.accion_recurso.id;
                            //                           accion.estado = false;

                            //toast.success(registro.accion_recurso.id +"" registro.accion_recurso.accion_seguimiento);
                            //  getAcciones(registro.accion_recurso.id, token)

                            toast.success("recurso actualizado con éxito");

                            //actualizarAccion(registro.accion_recurso.id, registro.accion_recurso.tipo,token).then((data) => {
                            actualizarAccion(registro.accion_recurso.id, registro.accion_recurso.accion_seguimiento, usuario.id, moment(), token).then((data) => {
                              setRefetch(true);
                              setTimeout(() => {
                                setRefetch(false);
                              }, 300);
                              toast.success("acción actualizada con éxito");
                              setCancelarRecurso(false);
                            })
                          }
                        }).catch(function (error) {
                          if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.message);
                          } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                          }
                        });
                    } else {
                      toast.error("Esta acción ya fue completada o el recurso esta disponible")
                    }
                  }}
                  cancelar={() => {
                    setCancelarRecurso(false);
                  }}
                />
              </div>
            }
          />
        )}
        {verDetalle && (
          <ModalAmplio
            title={"Detalle del Recurso"}
            open={verDetalle}
            closeModal={() => { }}
            cerrar={() => {
              setVerDetalle(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
              </div>
            }
            children={
              <FormDetalle detalle={registro} />
            }
          />
        )}
      </div>
    </div>
  );
};
