import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    deleteGestionRS,
    getGestionRS,
    getRedesSociales,
    postGestionRS,
    putGestionRS,
} from "../../modulos/funcionalidades-generales/orientacion/editar/victima/services";
import {
    postGestionRSSindicado,
    putGestionRSSindicado
} from "../../modulos/funcionalidades-generales/orientacion/editar/sindicados/services";
import Spinner from "../common/Spinner";
import { InputSiavi } from "../common/InputText";
import { ButtonSiavi } from "../common/Button";
import Label from "../common/Label";
import Toggle from "../common/Toggle";
import CustomTable from "../common/Table";
import { useSelector } from "react-redux";
import ModalGenerico from "../common/Modal";
import { XCircleIcon } from "@heroicons/react/outline";
import FormEliminar from "../common/FormEliminar";
import Alert from "../common/Alert";
const tableColumns = [
    {
        title: "Red Social",
        value: "red_social.nombre",
        tipo: "text",
    },
    {
        title: "Usuario Red",
        value: "usuario_red",
        tipo: "text",
    },
    {
        title: "Link Perfil",
        value: "link_perfil",
        tipo: "text",
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
];

const validaciones = Yup.object().shape({
    red_social: Yup.string()
        .required("Red Social es requerido")
        .max(100, "Máximo 100 caracteres"),
/*     usuario_red: Yup.string()
        .required("Usuario de Red es requerido")
        .max(100, "Máximo 100 caracteres"),
    link_perfil: Yup.string()
        .required("Link de Perfil es requerido")
        .max(100, "Máximo 100 caracteres"), */
});

const useFetchRedSocial = (token) => {
    const [state, setState] = useState({
        redes_sociales: [],
    });

    useEffect(() => {
        getRedesSociales(token).then((redes_sociales) => {
            setState({
                redes_sociales: redes_sociales,
            });
        });
    }, [token]);

    return state;
};

export const GestionRedesSociales = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idRS, setIdRS] = useState("");
    const [rs, setRS] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const { redes_sociales } = useFetchRedSocial(token);
    let idPersona;
    let idSindicado;
    if (props.persona) {
        idPersona = props?.persona?.id;
        idSindicado = '';
    } else {
        idSindicado = props?.sindicado?.idSindicado;
        idPersona = '';
    }
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "gestionredessociales";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdRS(id);
        setEliminar(true);
    };

    const handleEditar = (gestionRS) => {
        setEdit(true);
        setRS(gestionRS);
    };

    const fetchRS = () => {
        getGestionRS(idPersona, idSindicado, token).then((redes_sociales) => {
            setDataTable(redes_sociales);
            setTableLoading(false);
            setReFetch(false);
        });
    }

    useEffect(fetchRS, [token, idPersona, idSindicado, reFetch]);

    const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
    focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
    sm:text-sm border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont`;

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    persona: props?.persona?.id ? props?.persona?.id : null,
                    sindicado: props?.sindicado?.idSindicado ? props?.sindicado?.idSindicado : null,
                    usuario_red: isEdit ? rs?.usuario_red : "",
                    red_social: isEdit ? rs?.red_social?.id : "",
                    link_perfil: isEdit ? rs?.link_perfil : "",
                    estado: isEdit ? rs?.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (values.sindicado === null) {
                        if (isEdit) {
                            await putGestionRS(rs.id, values, token)
                                .then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                        setSuccess(true);
                                        setError(false);
                                        setMensaje("Red social actualizada exitosamente");
                                        setReFetch(true);
                                        setEdit(false);
                                        values.usuario_red = "";
                                        values.red_social = "";
                                        values.link_perfil = "";
                                        values.estado = false;
                                        setTimeout(() => {
                                            setSuccess(false);
                                        }, 3000);
                                    } else {
                                        setError(true);
                                        setSuccess(false);
                                        setTimeout(() => {
                                            setError(false);
                                        }, 3000);
                                    }
                                })
                                .catch(function (error) {
                                    if (
                                        error.response.status === 400 ||
                                        error.response.status === 401
                                    ) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje(error.response.data.message);
                                        setTimeout(() => {
                                            setError(false);
                                        }, 3000);
                                    } else if (error.response.status >= 500) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje("Error interno comuniquese con el administrador");
                                    }
                                });
                        } else {
                            await postGestionRS(values, token)
                                .then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                        setSuccess(true);
                                        setError(false);
                                        setMensaje("Red Social agregada exitosamente");
                                        setReFetch(true);
                                        resetForm({});
                                        setTimeout(() => {
                                            setSuccess(false);
                                        }, 3000);
                                    } else {
                                        setError(true);
                                        setSuccess(false);
                                    }
                                })
                                .catch(function (error) {
                                    if (
                                        error.response.status === 400 ||
                                        error.response.status === 401
                                    ) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje(error.response.data.message);
                                        setTimeout(() => {
                                            setError(false);
                                        }, 3000);
                                    } else if (error.response.status >= 500) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje("Error interno comuniquese con el administrador");
                                    }
                                });
                        }
                    } else {
                        if (isEdit) {
                            await putGestionRSSindicado(rs.id, values, token)
                                .then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                        setSuccess(true);
                                        setError(false);
                                        setMensaje("Red Social actualizada exitosamente");
                                        setReFetch(true);
                                        setEdit(false);
                                        values.usuario_red = "";
                                        values.red_social = "";
                                        values.link_perfil = "";
                                        values.estado = false;
                                        setTimeout(() => {
                                            setSuccess(false);
                                        }, 3000);
                                    } else {
                                        setError(true);
                                        setSuccess(false);
                                    }
                                })
                                .catch(function (error) {
                                    if (
                                        error.response.status === 400 ||
                                        error.response.status === 401
                                    ) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje(error.response.data.message);
                                        setTimeout(() => {
                                            setError(false);
                                        }, 3000);
                                    } else if (error.response.status >= 500) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje("Error interno comuniquese con el administrador");
                                    }
                                });
                        } else {
                            await postGestionRSSindicado(values, token)
                                .then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                        setSuccess(true);
                                        setError(false);
                                        setMensaje("Red Social agregada exitosamente");
                                        setReFetch(true);
                                        resetForm({});
                                        setTimeout(() => {
                                            setSuccess(false);
                                        }, 3000);
                                    } else {
                                        setError(true);
                                        setSuccess(false);
                                    }
                                })
                                .catch(function (error) {
                                    if (
                                        error.response.status === 400 ||
                                        error.response.status === 401
                                    ) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje(error.response.data.message);
                                        setTimeout(() => {
                                            setError(false);
                                        }, 3000);
                                    } else if (error.response.status >= 500) {
                                        setError(true);
                                        setSuccess(false);
                                        setMensaje("Error interno comuniquese con el administrador");
                                    }
                                });
                        }
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-6 mt-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <Label text="Red Social" />
                                <div className="mt-1">
                                    <select
                                        name="red_social"
                                        value={values.red_social}
                                        onChange={handleChange}
                                        className={selectClass}
                                    >
                                        <option value="" disabled>
                                            Elegir Red Social
                                        </option>
                                        {redes_sociales?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ErrorMessage
                                        name="red_social"
                                        component="span"
                                        className="font-medium text-xs text-maivi-secondaryRed"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="usuario_red"
                                    value={values.usuario_red}
                                    onChange={handleChange}
                                    label="Usuario de Red"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="link_perfil"
                                    value={values.link_perfil}
                                    onChange={handleChange}
                                    label="Link de Perfil"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}

            </Formik>
            <div className="mb-4 mt-3">
                {
                    success && <Alert type="success" mensaje={mensaje} />
                }
                {
                    error && <Alert type="error" mensaje={mensaje} />
                }
            </div>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteGestionRS(idRS, token).then(() => {
                                        setEliminar(false);
                                        setLoading(false);
                                        setReFetch(true);
                                        setMensaje('Registro eliminado exitosamente.');
                                        setSuccess(true);
                                        setTimeout(() => {
                                            setSuccess(false);
                                        }, 3000);
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
