import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { postServicioInstituto, putServicioInstituto, getSubAreaSustantiva } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../components/common/Select";
import Label from "../../../components/common/Label";
import toast from "react-hot-toast";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(100, "Máximo 100 caracteres"),
  descripcion: Yup.string()
    .max(300, "Máximo 300 caracteres"),
  codigo_expediente: Yup.string().required("codigo de expediente es requerido").max(2, "máximo 2 caracteres")
});

const useFetchSubAreaSustantiva = (token) => {
  const [state, setState] = useState({
    sub_area: [],
  });

  useEffect(() => {
    getSubAreaSustantiva(token).then((sub_area) => {
      setState({
        sub_area: sub_area,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { sub_area } = useFetchSubAreaSustantiva(token);
  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          codigo_expediente: props.editar ? props.value.codigo_expediente : "",
          sub_area: props.editar ? props?.value?.sub_area?.id : "",
          estado: props.editar ? props.value.estado : true,
          asitencia_legal: props.editar ? props.value.asitencia_legal : false,
          atencion_victimologica: props.editar ? props.value.atencion_victimologica : false,
          ts: props.editar ? props.value.ts : false,
          ps: props.editar ? props.value.ps : false,
          md: props.editar ? props.value.md : false,
          pq: props.editar ? props.value.pq : false,
          litigio_estrategico: props.editar ? props.value.litigio_estrategico : false,
          impugnaciones: props.editar ? props.value.impugnaciones : false,
          tiene_seguimiento_legal: props.editar ? props.value.tiene_seguimiento_legal : false,
          modifica_solo_profesional_asignado: props.editar ? props.value.modifica_solo_profesional_asignado : false
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putServicioInstituto(props.value.id, values, token).then(
              (data) => {
                if (data?.message !== undefined) {
                  toast.error(data.message);
                } else {
                  props.setReFetch(true);
                  toast.success("Registro actualizado con éxito...!");
                }
              }
            );
          } else {
            await postServicioInstituto(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre*"
                />
              </div>
              <div className="col-span-6 mt-4">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción"
                />
              </div>
              <div className="col-span-6 mt-4">
                <InputSiavi
                  type="text"
                  name="codigo_expediente"
                  value={values.codigo_expediente}
                  onChange={handleChange}
                  validacion={true}
                  label="Código expediente*"
                />
              </div>
              <div className="col-span-6 mt-4">
                <SelectSiavi
                  label="Subárea sustantiva"
                  id="sub_area"
                  name="sub_area"
                  value={values.sub_area}
                  onChange={handleChange}
                  valueInit=""
                  selectInit="Elegir sub área sustantiva"
                  list={
                    sub_area?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })
                  }
                />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
                <Label text="Asistencia legal" />
                <Toggle enabled={values.asitencia_legal} name="asitencia_legal" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Litigio Estratégico" />
                  <Toggle enabled={values.litigio_estrategico} name="litigio_estrategico" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Impugnaciones" />
                  <Toggle enabled={values.impugnaciones} name="impugnaciones" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="At. victimológica" />
                  <Toggle enabled={values.atencion_victimologica} name="atencion_victimologica" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Trabajo social" />
                  <Toggle enabled={values.ts} name="ts" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Psicología" />
                  <Toggle enabled={values.ps} name="ps" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Medicina" />
                  <Toggle enabled={values.md} name="md" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Psiquiatría" />
                  <Toggle enabled={values.pq} name="pq" />
              </div>
              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="¿Tiene Seguimiento Legal?" />
                  <Toggle enabled={values.tiene_seguimiento_legal} name="tiene_seguimiento_legal" />
              </div>

              <div className="border rounded-md hover:bg-gray-50 flex flex-row justify-between p-2 mt-2">
              <Label text="Modifica solo profesional asignado" />
                  <Toggle enabled={values.modifica_solo_profesional_asignado} name="modifica_solo_profesional_asignado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
