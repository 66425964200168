import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Registrar } from "./Registrar";
import { useSelector } from "react-redux";
import { store } from '../../../../app/redux/store/store';
import { savePersona } from '../../../../app/redux/actions/formRegisterPersona';
import { postPersona } from "./services";

const validaciones = Yup.object().shape({
  noDocumento: Yup.string().max(20, 'máximo 20 caracteres'),
  nacionalidad: Yup.string().required("nacionalidad es requerida"),
  pais: Yup.string()
    .required("pais es requerido"),
  primerNombre: Yup.string()
    .required("primer nombre es requerido")
    .max(30, "máximo 30 caracteres"),
  segundoNombre: Yup.string()
    .max(30, "máximo 30 caracteres"),
  primerApellido: Yup.string()
    .required("primer apellido es requerido")
    .max(30, "máximo 30 caracteres"),
  apellidoCasada: Yup.string().max(30, 'máximo 30 caracteres'),
  fechaNacimiento: Yup.string().required("fecha nacimiento es requerida"),
  sexo: Yup.string().required("sexo es requerido"),
  //alias: Yup.string().required("este campo es requerido").max(30, 'máximo 30 caracteres')
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  let fechaNac = new Date(values.fechaNacimiento);
  let yearNacimiento = fechaNac.getFullYear();
  let mesNacimiento = fechaNac.getMonth();
  const d = new Date();
  let yearActual = d.getFullYear();
  let edad = yearActual - yearNacimiento;
  let m = d.getMonth() - mesNacimiento;

  if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
    edad--;
  }

  if (values.fechaNacimiento) {
    values.edad = edad;
  }

  store.dispatch(savePersona(values));
};

export const FormRegistrarHistorico = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");

  return (
    <Formik
      initialValues={{
        tipoDocumento: "",
        noDocumento: "",
        pais: "",
        primeraVisita: true,
        sin_documento: false,
        primerNombre: "",
        segundoNombre: "",
        tercerNombre: "",
        primerApellido: "",
        segundoApellido: "",
        apellidoCasada: "",
        fechaNacimiento: "",
        edad: 0,
        sexo: "",
        nacionalidad: "",
        alias: "",
        sede: null
      }}

      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        await postPersona(values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status === 201) {
              setSuccess(true);
              setError(false);
              resetForm({})
              setMensaje("Registro creado éxitosamente");
              store.dispatch(savePersona(values));
              setTimeout(() => {
                window.location = `/funcionalidades-generales/actualizar/${data?.data?.id}`
              }, 3000);
            } else {
              setError(true);
              setSuccess(false);
            }
          })
          .catch(function (error) {
            if (error.response.status === 400 || error.response.status === 401) {
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              setMensaje("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {(props) => (
        <Registrar
          {...props}
          customSwitchChange={customSwitchChange}
          customChange={handleChangeInput}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
        />
      )}
    </Formik>
  );
};
