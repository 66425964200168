import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getFormulario = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtencion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getArea = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/area/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdioma = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idioma/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomaMaya = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idiomamaya/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAgrupacionPreguntas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/agrupacionpreguntas/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormulario = async (data, token) => {
    let areaFormulario;
    let fechaAutoriza;
    let fechaInactiva;
    let servicioFormulario;
    let atencionFormulario;
    let idiomaFormulario;
    let idiomaMayaFormulario;
    let usuarioCreacion;
    let usuarioAutoriza;
    let usuarioInactiva;

    if(Number(data.area) === 0 ){
        areaFormulario = null;
    }else{
        areaFormulario = Number(data.area);
    }

    if(data.fecha_autoriza === "" ){
        fechaAutoriza = null;
    }else{
        fechaAutoriza = data.fecha_autoriza;
    }

    if(data.fecha_inactivacion === "" ){
        fechaInactiva = null;
    }else{
        fechaInactiva = data.fecha_inactivacion;
    }

    if(Number(data.servicio) === 0 ){
        servicioFormulario = null;
    }else{
        servicioFormulario = Number(data.servicio);
    }

    if(Number(data.atencion) === 0 ){
        atencionFormulario = null;
    }else{
        atencionFormulario = Number(data.atencion);
    }

    if(Number(data.idioma) === 0 ){
        idiomaFormulario = null;
    }else{
        idiomaFormulario = Number(data.idioma);
    }

    if(Number(data.idioma_maya) === 0 ){
        idiomaMayaFormulario = null;
    }else{
        idiomaMayaFormulario = Number(data.idioma_maya);
    }

    if(Number(data.usuario_creacion) === 0 ){
        usuarioCreacion = null;
    }else{
        usuarioCreacion = Number(data.usuario_creacion);
    }

    if(Number(data.usuario_autoriza) === 0 ){
        usuarioAutoriza = null;
    }else{
        usuarioAutoriza = Number(data.usuario_autoriza);
    }

    if(Number(data.usuario_inactivacion) === 0 ){
        usuarioInactiva = null;
    }else{
        usuarioInactiva = Number(data.usuario_inactivacion);
    }

    try {
        let body = {
            nombre: data.nombre,
            descripcion: data.descripcion,
            estado: data.estado,
            por_servicio: data.por_servicio,
            por_atencion: data.por_atencion,
            fecha_creacion: data.fecha_creacion,
            fecha_autoriza: fechaAutoriza,
            fecha_inactivacion: fechaInactiva,
            encuesta: data.encuesta,
            profesional: data.profesional,
            usuario_victima: data.usuario_victima,
            por_area: data.por_area,
            por_orientacion: data.por_orientacion,
            autorizado: data.autorizado,
            servicio: servicioFormulario,
            atencion: atencionFormulario,
            idioma: idiomaFormulario,
            idioma_maya: idiomaMayaFormulario,
            usuario_creacion: usuarioCreacion,
            usuario_autoriza: usuarioAutoriza,
            usuario_inactivacion: usuarioInactiva,
            area: areaFormulario,
            informacion_contacto: data.informacion_contacto,
            informacion_solicitante: data.informacion_solicitante,
            directores: data.directores,
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/formularios/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormulario = async (id, data, token) => {
    let areaFormulario;
    let fechaAutoriza;
    let fechaInactiva;
    let servicioFormulario;
    let atencionFormulario;
    let idiomaFormulario;
    let idiomaMayaFormulario;
    let usuarioCreacion;
    let usuarioAutoriza;
    let usuarioInactiva;

    if(Number(data.area) === 0 ){
        areaFormulario = null;
    }else{
        areaFormulario = Number(data.area);
    }

    if(data.fecha_autoriza === "" ){
        fechaAutoriza = null;
    }else{
        fechaAutoriza = data.fecha_autoriza;
    }

    if(data.fecha_inactivacion === "" ){
        fechaInactiva = null;
    }else{
        fechaInactiva = data.fecha_inactivacion;
    }

    if(Number(data.servicio) === 0 ){
        servicioFormulario = null;
    }else{
        servicioFormulario = Number(data.servicio);
    }

    if(Number(data.atencion) === 0 ){
        atencionFormulario = null;
    }else{
        atencionFormulario = Number(data.atencion);
    }

    if(Number(data.idioma) === 0 ){
        idiomaFormulario = null;
    }else{
        idiomaFormulario = Number(data.idioma);
    }

    if(Number(data.idioma_maya) === 0 ){
        idiomaMayaFormulario = null;
    }else{
        idiomaMayaFormulario = Number(data.idioma_maya);
    }

    if(Number(data.usuario_creacion) === 0 ){
        usuarioCreacion = null;
    }else{
        usuarioCreacion = Number(data.usuario_creacion);
    }

    if(Number(data.usuario_autoriza) === 0 ){
        usuarioAutoriza = null;
    }else{
        usuarioAutoriza = Number(data.usuario_autoriza);
    }

    if(Number(data.usuario_inactivacion) === 0 ){
        usuarioInactiva = null;
    }else{
        usuarioInactiva = Number(data.usuario_inactivacion);
    }

    try {
        let body = {
            nombre: data.nombre,
            descripcion: data.descripcion,
            estado: data.estado,
            por_servicio: data.por_servicio,
            por_atencion: data.por_atencion,
            fecha_creacion: data.fecha_creacion,
            fecha_autoriza: fechaAutoriza,
            fecha_inactivacion: fechaInactiva,
            encuesta: data.encuesta,
            profesional: data.profesional,
            usuario_victima: data.usuario_victima,
            por_area: data.por_area,
            por_orientacion: data.por_orientacion,
            autorizado: data.autorizado,
            servicio: servicioFormulario,
            atencion: atencionFormulario,
            idioma: idiomaFormulario,
            idioma_maya: idiomaMayaFormulario,
            usuario_creacion: usuarioCreacion,
            usuario_autoriza: usuarioAutoriza,
            usuario_inactivacion: usuarioInactiva,
            area: areaFormulario,
            informacion_contacto: data?.informacion_contacto,
            informacion_solicitante: data?.informacion_solicitante,
            directores:data?.directores,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/formularios/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormulario = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormulariosPreguntas = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/formulariospreguntas/?page_size=1000&formulario=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormulariosPreguntas = async ({ pregunta, hint, formulario, estado, obligatorio, tipo_pregunta, catalogo_seleccion, agrupacion, ordenamiento }, token) => {
    try {
        let body = {
            pregunta,
            hint,
            formulario,
            estado,
            obligatorio,
            tipo_pregunta,
            catalogo_seleccion,
            agrupacion,
            ordenamiento
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormulariosPreguntas = async (id, { pregunta, hint, formulario, estado, obligatorio, tipo_pregunta, catalogo_seleccion, agrupacion, ordenamiento }, token) => {
    try {
        let body = {
            pregunta,
            hint,
            formulario,
            estado,
            obligatorio,
            tipo_pregunta,
            catalogo_seleccion,
            agrupacion,
            ordenamiento
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormulariosPreguntas = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormulariosPreguntasFormulario = async (id,urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/catalogos/formulariospreguntas/?formulario=${id}`
        : `${urlPage}&formulario=${id}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoPregunta = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipopregunta/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeleccion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoseleccion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};