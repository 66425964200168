import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { Editar } from "./editar";
import { putGestionInstitucion } from "../services";
import { useParams } from "react-router-dom";
import { getGestionInstitucion } from "../services";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from "yup";

// Validation with Yup
const validaciones = Yup.object().shape({
  nombre_fiscalia: Yup.string().required("nombre fiscalia es requerida"),
  //   .max(70, "máximo 70 caracteres"),
  institucion: Yup.string().required("institución es requerida"),
  departamento: Yup.string().required("departamento es requerido"),
  municipio: Yup.string().required("municipio es requerida"),
  email_contacto: Yup.string().max(100, "máximo 100 caracteres"),
  telefono: Yup.string()
    .required("teléfono es requerido")
    .max(20, "máximo 20 caracteres"),
  direccion: Yup.string()
    .required("dirección es requerido")
    .max(100, "máximo 100 caracteres"),
  horario: Yup.string().max(30, "máximo 30 caracteres"),
  //jurisdiccion: Yup.string().max(70, "máximo 70 caracteres"),
  latitud: Yup.string().max(30, "máximo 30 caracteres"),
  longitud: Yup.string().max(30, "máximo 30 caracteres"),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormEditar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);

  const { id } = useParams();
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [gestionInstitucion, setGestionInstitucion] = useState({});
  const [loading, setLoanding] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const history = useHistory();
  useEffect(() => {
    const datos = async () => {
      await getGestionInstitucion(id, token).then((data) => {
        setGestionInstitucion(data);
        setLoanding(false);
      });
    };
    datos();
  }, [id, token]);

  return loading ? (
    <div></div>
  ) : (
    <Formik
      initialValues={{
        id: id,
        nombre_fiscalia: gestionInstitucion?.nombre_fiscalia || "",
        oficina_instancia: gestionInstitucion?.oficina_instancia || "",
        departamento: gestionInstitucion?.departamento || "",
        municipio: gestionInstitucion?.municipio || "",
        email_contacto: gestionInstitucion?.email_contacto || "",
        telefono: gestionInstitucion?.telefono || "",
        direccion: gestionInstitucion?.direccion || "",
        horario: gestionInstitucion?.horario || "",
        jurisdiccion: gestionInstitucion?.jurisdiccion || "",
        latitud: gestionInstitucion?.latitud || "",
        longitud: gestionInstitucion?.longitud || "",
        url_maps: gestionInstitucion?.url_maps || "",
        url: gestionInstitucion?.url || "",
        estado: gestionInstitucion?.estado || true,
        servicio: gestionInstitucion?.gestionservicios_detalle || [],
        materias: gestionInstitucion?.gestionmateria_detalle || [],
        poblaciones: gestionInstitucion?.gestionpoblacion_detalle || [],
        institucion: gestionInstitucion?.institucion || "",
        otros_contactos: gestionInstitucion?.otros_contactos || "",
        observaciones: gestionInstitucion?.observaciones || "",
        contacto_principal: gestionInstitucion?.contacto_principal || "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await putGestionInstitucion(id, values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status === 201) {
              setSuccess(true);
              setError(false);
              setMensaje('Registro actualizado con éxito...!');
              toast.success('Registro actualizado con éxito...!');
              setTimeout(() => {
                history.push('/catalogos/gestion-instituciones');
              }, 1800);
            } else {
              setError(true);
              setSuccess(false);
            }
          })
          .catch(function (error) {
            if (error.response.status === 400 || error.response.status === 401) {
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              setMensaje('Error interno comuniquese con el administrador');
              toast.error("Error interno comuniquese con el administrador")
            }
          });
      }}
    >
      {(props) => (
        <Editar
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
        />
      )}
    </Formik>
  );
};
