import { React, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postDocumento, putDocumento } from "./services";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Clase Documento es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string()
    .required("Descripción es requerida")
    .max(255, "Máximo 100 caracteres"),
  recurso: Yup.string().required("Recurso es requerido"),
});

const extensiones = [
  { value: ".pdf", nombre: "PDF" },
  { value: ".docx", nombre: "DOCX" },
  { value: ".xlsx", nombre: "XLSX" },
  { value: ".pptx", nombre: "PPTX" },
  { value: ".jpg", nombre: "JPG" },
  { value: ".jpeg", nombre: "JPEG" },
  { value: ".png", nombre: "PNG" },
  { value: ".mp3", nombre: "MP3" },
  { value: ".mp4", nombre: "MP4" },
  { value: ".mkv", nombre: "MKV" },
];

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const animatedComponents = makeAnimated();


  const optionsExtensiones = [];
  const optionsExtensionesSelected = [];

  const changeSelectExtensiones = (e) => {
    setExtensionesSeleccionadas(e);
  }; 

  extensiones?.forEach((item) => {
    
    let reg = {
      value: item.value,
      label: item.nombre,
    };

    if (props.editar) {
      let extPermitidas = props.value.extension_permitida.split(',');
      extPermitidas.forEach(ex => {
        if (ex.trim().toLowerCase() === item.value.trim().toLowerCase()) {
          optionsExtensionesSelected.push(reg);
        }
      })
    }
    optionsExtensiones.push(reg);
  });

  const [extensionesSeleccionadas, setExtensionesSeleccionadas] = useState(optionsExtensionesSelected);

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          requerido: props.editar ? props.value.requerido : false,
          multiples_archivos: props.editar
            ? props.value.multiples_archivos
            : false,
          recurso: props.editar ? props.value.recurso.toLowerCase() : "",
          extension_permitida: props.editar
            ? props.value.extension_permitida
            : [],
          estado: props.editar ? props.value.estado : true,
          descripcion_recurso: props.editar ? props.value.descripcion_recurso : ""
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putDocumento(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro actualizado con éxito...!");
              }
            });
          } else {
            await postDocumento(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit,setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5 py-4">
              <div className="">
                <InputSiavi
                  type="text"
                  name="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Clase Documento*"
                />
              </div>
              <div className="mt-4">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción*"
                />
              </div>
              <div className="mt-4">
                <Label text="Recurso*" />
                <select
                  id="recurso"
                  name="recurso"
                  value={values.recurso}
                  onChange={(e)=>{
                    let select = document.getElementById("recurso");
                    let texto = select.options[select.selectedIndex].text;
                    setFieldValue("recurso",e.target.value);
                    setFieldValue("descripcion_recurso", texto);
                  }}
                  className={selectClass}
                >
                  <option value="">Seleccionar</option>
                  <option value="gestionpersona">Gestión Persona</option>
                  <option value="orientacion">Orientación</option>
                  <option value="caso">Caso</option>
                  <option value="expedienteproceso">Expediente proceso</option>
                  <option value="consentimientoinformado">Consentimiento Informado</option>
                  <option value="diligencias">Diligencias /Notificaciones Legales</option>
                  <option value="legalprimerainstancia">Expediente Legal</option>
                  <option value="expedientetrabajosocial">Expediente trabajo social</option>
                  <option value="expedientemedico">Expediente médico</option>
                  <option value="expedientepsiquiatrico">Expediente psiquiátrico</option>
                  <option value="expedientepsicologico">Expediente psicológico</option>
                  <option value="referencias">Referencias</option>
                  <option value="nombramientos">Nombramientos</option>
                  <option value="biblioteca">Biblioteca</option>
                  <option value="reparaciondigna">Reparaciones dignas</option>
                  <option value="medidasvictimologicas">Medidas victimológicas</option>
                  <option value="expectativareparaciondigna">Expectativa reparación digna</option>
                </select>
                <ErrorMessage
                  name="recurso"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-12 mt-4">
                  <Label text="Extensiones Permitidas*" />
                  <Select
                    defaultValue={optionsExtensionesSelected}
                    isMulti
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    name="extension_permitida"
                    className="basic-multi-select text-sm"
                    classNamePrefix="seleccione"
                    options={optionsExtensiones}
                    onChange={changeSelectExtensiones}
                    maxMenuHeight={160}
                  />
              </div>
            <div className="flex flex-row items-center justify-between">
            <div className="col-span-6 mt-5">
                <Label text="¿Requerido?" />
                <Toggle enabled={values.requerido} name="requerido" />
              </div>
              <div className="col-span-6 mt-5">
                <Label text="Múltiples Archivos?" />
                <Toggle
                  enabled={values.multiples_archivos}
                  name="multiples_archivos"
                />
              </div>
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado ? "Activo" : "Inactivo"}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
            </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                  onClick={() => {
                    values.extension_permitida = extensionesSeleccionadas;
                    handleSubmit();
                  }}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};