/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { TextAreaSiavi } from "../../../components/common/TextArea";
import { ButtonSiavi } from "../../../components/common/Button";
import { bloqueoFecha } from "../../../helpers/utils";
import { clases } from "../../../helpers/clases";
import { CheckCircleIcon } from "@heroicons/react/solid";
import {
    putGestionaCriterioCierre,
    getExpCriterioCierreGestionar
} from "./services";
import Spinner from "../../../components/common/Spinner";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../catalogos/common/FormEliminar";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import toast from "react-hot-toast";
import TableSiavi from "../../../components/common/TableSiavi";

export const Gestionar = (props) => {
    console.log(props)
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const cierre = props?.cierre;
    const [isMensaje, setMensaje] = useState(false);




    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "GestionarCierreExpedientes";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });
        // retorno = true;  // mm comentar para que funcione
        return retorno;

    }

    const [cierres, setCierres] = useState({});

    useEffect(() => {
        getExpCriterioCierreGestionar(props?.cierre?.expediente?.id, props?.cierre?.id, token).then((data) => {
            setCierres(data)
        });
    }, []);

    return (
        <div>
            <div className="bg-white border overflow-hidden sm:rounded-lg mb-2">
                <div className="border-gray-200 px-4 py-5 sm:p-0 mb-2">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-xs font-medium text-gray-500">Motivo cierre</dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.motivo?.nombre}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-xs font-medium text-gray-500">Fecha gestión</dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.fecha_gestiona}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-xs font-medium text-gray-500">Número de expediente</dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.expediente?.numero_expediente}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-xs font-medium text-gray-500">Nombre persona</dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.expediente?.caso_persona?.persona?.primer_nombre}  {cierre?.expediente?.caso_persona?.persona?.segundo_nombre}  {cierre?.expediente?.caso_persona?.persona?.primer_apellido}  {cierre?.expediente?.caso_persona?.persona?.segundo_apellido}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <TableSiavi
                headers={[
                    'Criterio', 'Se cumple criterio', 'Resultado'
                ]}
                body={cierres?.results?.map((item) => (
                    <tr key={item?.expediente?.numero_expediente} className="hover:bg-maivi-gray-5" >
                        <td className={clases.td}>{item?.criterio?.nombre}</td>
                        <td className={clases.td}>{item?.se_cumple_criterio === true ? "Sí" : "No"}</td>
                        <td className={clases.td}>{item?.resultado}</td>
                    </tr>
                ))}
            />
            <Formik
                initialValues={{
                    cierre_autorizado: cierre.cierre_autorizado,
                    gestiona_cierre: cierre?.gestiona_cierre
                }}
                onSubmit={async (values) => {
                    values.fecha_gestiona = bloqueoFecha();
                    values.expediente = cierre?.expediente?.id;
                    values.caso = cierre?.caso?.id;
                    values.profesional_solicita = cierre?.profesional_solicita?.id;
                    values.motivo = cierre?.motivo?.id;
                    values.profesional_gestiona = idUser;
                    await putGestionaCriterioCierre(
                        cierre.id,
                        values,
                        token
                    ).then(() => {
                        props.setReFetch(true);
                        props.setAgregar(false);
                        toast.success("Gestión de Expediente actualizado con éxito...!");
                        props.setReFetch(false);
                    }).catch(function (error) {
                        if (error.response.status >= 400 || error.response.status <= 410) {
                          toast.error(error.response.data.detail);
                        } else if (error.response.status >= 500) {
                          toast.error('Error interno comuniquese con el administrador');
                        }
                      });
                }}
            >
                {({ values, handleSubmit, isSubmitting, handleChange, setFieldValue }) => (
                    <form>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 mt-5">
                                <TextAreaSiavi
                                    label="Observaciones en la Gestión"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-3 mt-5">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                {(CheckPermiso('AutorizarCierre') === true) &&
                                    <ButtonSiavi
                                        text="Autorizar"
                                        type="button"
                                        onClick={() => {
                                            setMensaje(true);
                                            setFieldValue('gestiona_cierre', true);
                                            setFieldValue('cierre_autorizado', true);
                                            setFieldValue('profesional_gestiona', idUser);
                                        }}

                                    />
                                }
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                {(CheckPermiso('RechazarCierre') === true) &&
                                    <ButtonSiavi
                                        text="Rechazar"
                                        type="button"
                                        onClick={() => {
                                            setFieldValue('gestiona_cierre', true);
                                            setFieldValue('cierre_autorizado', false);
                                            setFieldValue('profesional_gestiona', idUser);
                                            handleSubmit();
                                        }}

                                    />
                                }
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium 
                rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    onClick={() => {
                                        props.setAgregar(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                        {
                            isMensaje && (
                                <ModalGenerico
                                    title={`¿Una vez autorizado no podrá editarlo nuevamente desea continuar?`}
                                    open={isMensaje}
                                    closeModal={() => { }}
                                    cerrar={() => {
                                        setMensaje(false);
                                    }}
                                    icon={
                                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                            <CheckCircleIcon className="h-6 w-6 text-maivi-secondaryLightGreen" />
                                        </div>
                                    }
                                    children={
                                        <FormEliminar
                                            aceptar={() => {
                                                setMensaje(false);
                                                handleSubmit();
                                            }}
                                            cancelar={() => {
                                                setMensaje(false);
                                            }}
                                        />
                                    }
                                />
                            )}
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
