import { React } from "react";
import { Formik } from "formik";
import { postTipoProceso, putTipoProceso } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  tipo_proceso: Yup.string()
    .required("Tipo Proceso es requerido")
    .max(100, "Máximo 100 caracteres"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  return (
    <div>
      <Formik
        initialValues={{
          tipo_proceso: props.editar ? props.value.tipo_proceso : "",
          estado: props.editar ? props.value.estado : true,
          requiere_sentencia_previa: props.editar ? props.value.requiere_sentencia_previa : false,
          requiere_reparacion_digna_previa: props.editar ? props.value.requiere_reparacion_digna_previa : false,
          unico_por_caso: props.editar ? props.value.unico_por_caso : false,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putTipoProceso(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro actualizado con éxito...!");
              }
            });
          } else {
            await postTipoProceso(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5">
              <div className="col-span-6">
                <Label text="" />
                <InputSiavi
                  type="text"
                  name="tipo_proceso"
                  id="tipo_proceso"
                  value={values.tipo_proceso}
                  onChange={handleChange}
                  validacion={true}
                  label="Tipo Proceso*"
                />
              </div>
              <div className="col-span-6 mt-3">
                <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="col-span-6 mt-3">
                <Label text="Requiere Sentencia Previa" />
                <Toggle enabled={values.requiere_sentencia_previa} name="requiere_sentencia_previa" />
              </div>
              <div className="col-span-6 mt-3">
                <Label text="Requiere Reparación Digna Previa" />
                <Toggle enabled={values.requiere_reparacion_digna_previa} name="requiere_reparacion_digna_previa" />
              </div>
              <div className="col-span-6 mt-3">
                <Label text="Único por caso" />
                <Toggle enabled={values.unico_por_caso} name="unico_por_caso" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
