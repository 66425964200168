import { React, useEffect, useState } from "react";
import { ScaleIcon, XCircleIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/outline";
import { deleteCatalogos, getGenericCatalogo, getGenericCatalogoSearch } from "../../../helpers/catalogos";
import { GestionesEstadoProceso } from "./gestionEstadoProceso";
import { FormDetalle } from "./Detalle";
import { Form } from "./Form";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import FormEliminar from "../common/FormEliminar";
import CustomTable from "../../../components/common/Table";
import toast from 'react-hot-toast';
import Filtros from "./filtros";
import ButtonRefresh from "../common/ButtonRefresh";
import ModalTwo from "../../../components/common/ModalTwo";

const tableColumns = [
  { title: "id", value: "id", tipo: "text", ordenar: "id" },
  { title: "Etapa procesal", value: "nombre", tipo: "text", ordenar: "nombre" },
  { title: "Descripción", value: "descripcion", tipo: "text" },
  { title: "Tipo proceso", value: "tipo_proceso_detalle.tipo_proceso", tipo: "text", ordenar: "tipo_proceso__nombre" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
    ordenar: "estado"
  },
];

export const TableEtapaProcesal = () => {
  const [verDetalle, setDetalle] = useState(false);
  const [agregar, setAgregar] = useState(false);
  //const [estadoProceso, setEstadoProceso] = useState([]);
  const [etapaProcesalDetalle, setEtapaProcesalDetalle] = useState({});
  const [open, setOpen] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [etapaProcesal, setEtapaProcesal] = useState("");
  const [idEtapaProcesal, setIdEtapaProcesal] = useState("");
  const [nombreEtapaProcesal, setNombreEtapaProcesal] = useState("");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [reFetch, setReFetch] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "etapaprocesal";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
    setReFetch(false);
    setOpen(false);
    setEliminar(false);
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFetch, reFetch, filters]);
  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, reFetch, filters]);
  useEffect(() => {
    getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, reFetch, filters]);

  // const idEstadoProceso = (item) => {
  //   getEstadoProceso(item.id, token).then((data) => {
  //     //setEstadoProceso(data);
  //   });
  // };

  const handleAgregar = (item) => {
    //idEstadoProceso(item);
    setIdEtapaProcesal(item.id);
    setEtapaProcesal(item);
    setAgregar(true);
  };

  const handleVer = (item) => {
    setEtapaProcesalDetalle(item);
    setDetalle(true);
  };

  const handleEliminar = (id, nombre) => {
    setIdEtapaProcesal(id);
    setNombreEtapaProcesal(nombre);
    setEliminar(true);
  };

  const handleEditar = (tipo) => {
    setEdit(true);
    setEtapaProcesal(tipo);
    setOpen(true);
  };

  const pages = [
    { name: "Catálogos", href: "/catalogos", current: false },
    { name: "Etapa procesal", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <ButtonAdd
              text="Crear"
              type="button"
              onClick={() => {
                setEdit(false);
                setOpen(true);
              }}
            />
            <ButtonAdd
              className="ml-2"
              text="Filtros"
              type="button"
              onClick={() => {
                setOpenFilters(true);
              }}
              icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
            />
            <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />
          </div>
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            handleEditar={handleEditar}
            handleEliminar={handleEliminar}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showVer={true}
            setOrder={setOrder}
            order={order}
            agregar={true}
            handleAgregar={handleAgregar}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle de etapa procesal"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle detalle={etapaProcesalDetalle} cancelar={setEliminar} />
              }
            />
          )}
          {open && (
            <ModalGenerico
              title={
                isEdit ? "Actualizar etapa procesal" : "Agregar etapa procesal"
              }
              open={open}
              closeModal={() => { }}
              cerrar={() => {
                setOpen(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <DocumentAddIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={<Form value={etapaProcesal} setReFetch={setReFetch} editar={isEdit} />}
            />
          )}
          {agregar && (
            <ModalTwo
              title={`Gestión de Estado Proceso de ${etapaProcesal.nombre}`}
              open={agregar}
              closeModal={() => { }}
              cerrar={() => {
                setAgregar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <RefreshIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <GestionesEstadoProceso etapa={etapaProcesal} />
              }
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${nombreEtapaProcesal}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteCatalogos(idEtapaProcesal, 'etapaprocesal', token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false);
                        toast.error(data.message)
                      } else {
                        setReFetch(true);
                        setLoading(false);
                        toast.success('Registro eliminado con éxito...!')
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => { }}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={<Filtros setFilters={setFilters} setOpenFilters={setOpenFilters} />}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
