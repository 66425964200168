import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import { ButtonSiavi } from "../../../../components/common/Button";
import {  getProfesionales,getAcciones } from "../../services";
import { useSelector } from "react-redux";
import { RenderJasper } from "../../RenderJasper";
import Select from "react-select";
import makeAnimated from 'react-select/animated';


const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

export const Parametros = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const usuario = useSelector((state) => (state.loginReducer.data.usuario.user.first_name+" "+state.loginReducer.data.usuario.user.last_name));
  const animatedComponents = makeAnimated();
  const [sede, setSede] = useState(null);

  const [profesion, setProfesion] = useState(null);
  const [profesionList, setProfesionList] = useState([]);


  const [profesional, setProfesional] = useState(null);
  const [profesionalList, setProfesionalList] = useState([]);
  
  const [servicio, setServicio] = useState(null);
  const [accion, setAccion] = useState(null);
  const [expediente, setExpediente] = useState(null);
  
  let data =[]
  let data2 =[]

  const useFetchProfesionales = () => {
    const [state, setState] = useState({
      profesionales: [],
    });
    
    useEffect(() => {
      getProfesionales(token).then((data) => {
        setState({
          profesionales: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchAcciones = () => {
    const [state, setState] = useState({
      acciones: [],
    });
    
    useEffect(() => {
      getAcciones(token).then((data) => {
        setState({
          acciones: data,
        });
      });
    }, []);

    return state;
  };

  const { profesionales } = useFetchProfesionales();
  const { acciones} = useFetchAcciones();

  {profesionales?.results?.map((item) => {
    if(item?.tipo_puesto?.sub_area_detalle?.area==1)
    {
      data.push({sedeId:item?.sede?.id
        ,sede:item?.sede?.sede
        ,profesionalId:item?.id
        ,profesional:item.first_name+' '+item.last_name
        ,tipoProfesionalId:item?.tipo_puesto?.id
        ,profesion:item?.tipo_puesto?.puesto
        })
    }
  })
//console.log(data)
}

  {acciones?.results?.map((item) => {
    if(item?.servicio?.atencion_victimologica && item?.estado)
    {
      data2.push({servicioId:item?.servicio?.id
                  ,servicio:item?.servicio?.nombre
                  ,accionId:item?.id
                  ,accion:item.nombre})
    }
  })
//console.log(acciones)
}

var groupBy = (arr, prop,prop2) =>{ 
  return arr.reduce((objs, obj) => {
      const sedeId = obj[prop];
      const tipoProfesionalId = obj[prop2];      
          let fi = objs.findIndex(x => x.sedeId == sedeId);          
          if (fi>=0) { 
            let fo = objs[fi].profesion.findIndex(x => x.tipoProfesionalId == tipoProfesionalId)
            if (fo>=0) {
              objs[fi].profesion[fo].profesional = [...objs[fi].profesion[fo].profesional, {profesionalId:obj.profesionalId,profesional:obj.profesional}];              
            }
            else { 
              objs[fi].profesion.push({
                tipoProfesionalId:tipoProfesionalId,
                            profesion:obj.profesion
                            ,profesional:[{profesionalId:obj.profesionalId,profesional:obj.profesional}]
            })              
            }
          }
          else { 
            objs.push({
              sedeId: sedeId,
              sede:obj.sede,
              profesion:[{tipoProfesionalId:tipoProfesionalId,
                          profesion:obj.profesion
                          ,profesional:[{profesionalId:obj.profesionalId,profesional:obj.profesional}]}]
          })
          }     
      return objs;
  }, []);
}

var groupBy2 = (arr, prop) =>{ 
  return arr.reduce((objs, obj) => {
      const servicioId = obj[prop];
      if (servicioId) {
          let fi = objs.findIndex(x => x.servicioId == servicioId);
          if (fi>=0) {
              objs[fi].acciones = [...objs[fi].acciones, {accionId:obj.accionId,accion:obj.accion}];
          } else {
              objs.push({
                  servicioId: servicioId,
                  servicio:obj.servicio,
                  acciones: [{accionId:obj.accionId,accion:obj.accion}]
              })
          }
      }
      return objs;
  }, []);
}

 
  // handle change event of the sede dropdown
  const handleSedeChange = (obj) => {
    setSede(obj);
    if(obj)
    setProfesionList(obj.profesion);
    setProfesion(null);
    setProfesional(null);
  };

  // handle change event of the sede dropdown
  const handleProfesionChange = (obj) => {
    setProfesion(obj);
    setProfesionalList(obj.profesional);
    setProfesional(null);
  };

   // handle change event of the servicio dropdown
   const handleServicioChange = (obj) => {
    setServicio(obj);
    if(obj)
    setAccion(null);
  };
  
  // handle change event of the profesional dropdown
  const handleProfesionalChange = (obj) => {
    setProfesional(obj);
  };

  // handle change event of the accion dropdown
  const handleExpedienteChange = (obj) => {
    setExpediente(obj);
    //console.log(expediente)
  };

//console.log(groupBy(data,'sedeId','tipoProfesionalId'))
    const pages = [
    { name: "Reportes", href: "/reportes", current: false },
    { name: "Referencias DSV", href: "#", current: true },  ];

  return (
    <form autoComplete="off">
      <div className="lg:grid-cols-2 gap-12 sm:grid sm:grid-cols-1 sm:gap-12 md:grid lg:flex -mt-24 mb-10 justify-center items-start">
        <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
          <div className="flex justify-center col-span-12">

          </div>
          <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-8">
            <BreadCrumbTwo navegacion={pages} />
          </div>

          <h1 className="mb-1 text-base">Reporte de Referencias</h1>
          <h2 className="block text-sm font-normal">Referencias asignadas y pendientes de asignar de la Dirección de Servicios Victimológicos</h2>
          <Divider />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">

          <div className="grid grid-cols-1  lg:grid-cols-4 gap-4 lg:gap-8">
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha inicio:" />
              <InputSiavi
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                onChange={handleChange}
                value={values.fechaInicio}
              />
              <ErrorMessage
                name="fechaInicio"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha Fin:" />
              <InputSiavi
                type="date"
                name="fechaFin"
                id="fechaFin"
                onChange={handleChange}
                value={values.fechaFin}
              />
              <ErrorMessage
                name="fechaFin"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
           
            <div className="sm:col-span-4 col-span-12">
              <Label text="Sede" />
              <Select
              isClearable
          placeholder="Seleccione una sede"
          value={sede}
          options={groupBy(data,'sedeId','tipoProfesionalId')}
          onChange={handleSedeChange}
          getOptionLabel={x => x.sede}
          getOptionValue={x => x.sedeId}
          defaultValue={"0" || 'Todas'}
        />
        <br />
        <Label text="Profesión" />
        <Select
          placeholder="Todos"
          value={profesion}
          options={profesionList}
          onChange={handleProfesionChange}
          getOptionLabel={x => x.profesion}
          getOptionValue={x => x.tipoProfesionalId}
          defaultValue={"0" || 'Todos'}
        />
        <br />
        <Label text="Profesional" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Todos"
          value={profesional}
          options={profesionalList}
          onChange={handleProfesionalChange}
          getOptionLabel={x => x.profesional}
          getOptionValue={x => x.profesionalId}
          defaultValue={"0" || 'Todos'}
        />

              <ErrorMessage
                name="sede"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>

            <div className="sm:col-span-4 col-span-12">
              <Label text="Servicio" />
              <Select
              isClearable
          placeholder="Seleccione un servicio"
          value={servicio}
          options={groupBy2(data2,'servicioId')}
          onChange={handleServicioChange}
          getOptionLabel={x => x.servicio}
          getOptionValue={x => x.servicioId}
          defaultValue={"0" || 'Todas'}
        />
        <br />
              <ErrorMessage
                name="servicio"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>

            <div className="sm:col-span-4 col-span-12">
              <Label text="Tipo de Referencia" />
              <Select
              isClearable
          placeholder="Todos"
          value={expediente}
          options={[{id:0,valor:"INTERNA"},{id:1,valor:"EXTERNA"}]}
          onChange={handleExpedienteChange}
          getOptionLabel={x => x.valor}
          getOptionValue={x => x.id}
          defaultValue={"0" || 'Todas'}
        />
        <br />
              <ErrorMessage
                name="expedientes"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>

            <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
                text="Generar Reporte"
              />
            </div>

          </div>


            <div className="grid grid-cols-1 col-span-3">
              <div className="col-span-12">
                {isSubmitting && (
                  <Spinner />
                )}
                {hasError && (
                  <Alert
                    type="error"
                    mensaje={mensaje}
                  />
                )}
                {success && (
                  <><Alert
                  type="success"
                  mensaje={mensaje} /><RenderJasper
                  fechaInicio={values.fechaInicio}
                  fechaFin={values.fechaFin}
                  sedes={sede==null?0:sede.sedeId}
                  profesion={profesion==null?0:profesion.tipoProfesionalId}
                  profesionales={profesional==null?0:profesional}
                  servicios={servicio==null?0:servicio.servicioId}
                  acciones={accion==null?0:accion}
                  usuario={usuario}
                  expediente={expediente}
                  reporte={"/DSV/Referencia"} />
                    </>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  );
};
