import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from '@heroicons/react/outline'

export default function ModalBig(props) {
  const [open] = useState(props.open);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment} >
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden 
            transform transition-all align-middle  ${props.fullWidth ? 'w-11/12' : 'w-9/12'}`}>
              <div className={`sm:block absolute top-0 right-0 pt-4 pr-4 ${props.ocultarClose ? 'invisible' : ''}`}>
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                  onClick={props.cerrar}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                {props.icon &&   
                  props.icon}
              
                <Dialog.Title
                  as="h3"
                  className="text-lg text-center font-medium text-gray-900"
                >
                  <span className="text-maivi-primaryFont font-semibold">{props.title}</span>
                </Dialog.Title>
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
