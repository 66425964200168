/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonSiavi } from "../../../../components/common/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    getExpedientes,
    getOrientacionesValidacion,
    putPersona
} from "./services"
import Label from "../../../../components/common/Label";
import Spinner from "../../../../components/common/Spinner";
import Toggle from "../../../../components/common/Toggle";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import Alert from "../../../../components/common/Alert";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";

const validaciones = Yup.object().shape({

});

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [orientaciones, setOrientaciones] = useState([]);
    const [expedientes, setExpedientes] = useState([]);
    const [cargando1, setCargando1] = useState(true);
    const [cargando2, setCargando2] = useState(true);
    const [alerta, setAlerta] = useState(false);

    useEffect(() => {
        getExpedientes(props.registro.id, token).then((data) => {
            setExpedientes(data);
            setCargando1(false);
        });
    }, []);

    useEffect(() => {
        getOrientacionesValidacion(props.registro.id, token).then((data) => {
            setOrientaciones(data);
            setCargando2(false);
        });
    }, [])

    return (
        <Formik
            initialValues={{
                estado: props?.registro?.estado,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                if ((expedientes.count > 0 || orientaciones.count > 0) && values.estado === false) {
                    setAlerta(true);
                } else {
                    props.registro.estado = values.estado;
                    props.registro.usuario_modificacion = usuario;
                    await putPersona(props.registro.id, props.registro, token)
                        .then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                                toast.success("registro actualizado con éxito");
                                props.setReFetch(true);
                                setTimeout(() => {
                                    props.setReFetch(false);
                                }, 400);
                                props.setCambiarEstado(false);
                            }
                        })
                        .catch(function (error) {
                            if (error.response.status === 400 || error.response.status === 401) {

                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error("Error interno comuniquese con el administrador");
                            }
                        });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 flex flex-col items-center justify-center">
                        <Label text={`${values.estado ? 'Activa' : 'Inactiva'}`} />
                        <Toggle enabled={values.estado} name="estado" />
                    </div>
                    {
                        alerta && <Alert type="warning" mensaje="¿No se puede desactivar esa persona porque esta siendo utilizada en un expediente / orientacion activa desea continuar?" />
                    }
                    {
                        alerta &&
                        <BotonesConfirmar
                            aceptar={async() => {
                                props.registro.estado = values.estado;
                                props.registro.usuario_modificacion = usuario;
                                
                                await putPersona(props.registro.id, props.registro, token)
                                .then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                        toast.success("registro actualizado con éxito");
                                        props.setReFetch(true);
                                        setTimeout(() => {
                                            props.setReFetch(false);
                                        }, 400);
                                        props.setCambiarEstado(false);
                                    }
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400 || error.response.status === 401) {
        
                                        toast.error(error.response.data.message);
                                    } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                    }
                                });
                            }}
                            cancelar={() => {
                                props.setCambiarEstado();
                            }}
                        />
                    }
                    {
                        (cargando1 && cargando2) ?
                            <Spinner /> :
                            <div className="flex flex-row justify-center">
                                {!alerta && 
                                <ButtonSiavi
                                type="button"
                                disabled={isSubmitting}
                                text={"Actualizar"}
                                onClick={handleSubmit}
                            />
                                }
                            </div>
                    }
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};
