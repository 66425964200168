import { React, useState, useEffect } from "react";
import { Formik } from "formik";
/* import { InputSiavi } from "../../../components/common/InputText"; */
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteGrupoProfesional,
    getGrupoPro,
    postGrupoProfesional,
    putGrupoProfesional,
    getProfesionales
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import { SelectSiavi } from "../../../components/common/Select";
import toast from 'react-hot-toast';
/* import * as Yup from "yup"; */

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { 
        title: "Profesional", 
        value: null, 
        values: [
          "profesional.first_name", 
          "profesional.last_name"
        ], 
        tipo: "text" 
      },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
    {
        title: "Es Autorizador",
        value: "es_autorizador",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Es Apoyo",
        value: "es_apoyo",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
];

/* const validaciones = Yup.object().shape({
    valor: Yup.string()
        .required("Valor es requerido")
        .max(100, "Máximo 100 caracteres")
}); */

export const GestionesGruposProfesionales = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idGrupoPro, setIdGrupoPro] = useState("");
    const [grupoPro, setGrupoPro] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idGrupo = props?.grupo?.id;
    const multidisciplinario = props?.grupo?.es_multidisciplinario;
    const idServicio = (multidisciplinario === false) ? props?.grupo?.servicio?.id : '';
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);

    const useFetchProfesional = (token) => {
        const [state, setState] = useState({
            profesionales: [],
        });
    
        useEffect(() => {
            getProfesionales(idServicio,token).then((profesionales) => {
                setState({
                    profesionales: profesionales,
                });
            });
        }, [token]);
    
        return state;
    };

    const { profesionales } = useFetchProfesional(token);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "grupoprofesional";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdGrupoPro(id);
        setEliminar(true);
    };

    const handleEditar = (gestionGrupoPro) => {
        setEdit(true);
        setGrupoPro(gestionGrupoPro);
    };

    const fetchGruposPro = () => {
        getGrupoPro(idGrupo, token).then((grupos_profesionales) => {
            setDataTable(grupos_profesionales);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchGruposPro, [token, idGrupo, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    grupo: props.grupo.id,
                    profesional: isEdit ? grupoPro?.profesional : "",
                    estado: isEdit ? grupoPro?.estado : true,
                    es_autorizador: isEdit ? grupoPro?.es_autorizador : false,
                    es_apoyo: isEdit ? grupoPro?.es_apoyo : false,
                    usuario_creacion: idUser
                }}
                /* validationSchema={validaciones} */
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putGrupoProfesional(grupoPro.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    /* values.profesional = "",
                                        values.estado = true,
                                        values.es_autorizador = false,
                                        values.es_apoyo = false, */
                                        setEdit(false);
                                }
                            })
                    } else {
                        await postGrupoProfesional(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Profesional"
                                    name="profesional"
                                    value={values.profesional}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir profesional"
                                    list={
                                        profesionales?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="¿Es Autorizador?" />
                                <Toggle enabled={values.es_autorizador} name="es_autorizador" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="¿Es Apoyo?" />
                                <Toggle enabled={values.es_apoyo} name="es_apoyo" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteGrupoProfesional(idGrupoPro, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
