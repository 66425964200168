
const {
    REACT_APP_API_URL
} = process.env;

export const getSeleccionDetalle = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSeleccionDetalle = async (data, token) => {
    try {
        let body = {
            catalogo_seleccion: data.catalogo_seleccion,
            valor: data.valor,
            representacion_emoji: data.representacion_emoji,
            representacion: data.representacion,
            text_mostrado: data.text_mostrado,
            tipo_valor: (Number(data.tipo_valor) === 0) ? null : Number(data.tipo_valor),
            estado: data.estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSeleccionDetalle = async (id, data, token) => {
    try {
        let body = {
            catalogo_seleccion: data.catalogo_seleccion,
            valor: data.valor,
            representacion_emoji: data.representacion_emoji,
            representacion: data.representacion,
            text_mostrado: data.text_mostrado,
            tipo_valor: (Number(data.tipo_valor) === 0) ? null : Number(data.tipo_valor),
            estado: data.estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSeleccionDetalle = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeleccion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoseleccion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeleccionSeleccionDetalle = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogoselecciondetalle/?catalogo_seleccion=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoValorEncuesta = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipovalorencuesta/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};