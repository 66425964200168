

const { REACT_APP_API_URL } = process.env;


export const exportarData = async(path, filters, token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/${path}/?${filters}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
}

export const getCasos = async (urlPage,filters, search,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/caso/?search=${search.value}&ordering=${order}&${filters}`
        : `${urlPage}&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const casos = async (urlPage,order,filters,search, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/caso/?ordering=${order}&${filters}`
        : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getExpedienteCausapenal = async (idCaso,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expedientecausapenal/?caso=${idCaso}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSindicados = async (idCaso,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesindicado/?caso=${idCaso}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getPersonas = async (idCaso,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};
