/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FilterIcon } from "@heroicons/react/outline";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { getInstituciones } from "../../../orientacion/listar/services";
import { InputSiavi } from "../../../../../components/common/InputText";
import { putCasoFiscalia } from "./services";
import FiltrosGestionInstitucion from "./filtrosInstitucion";
import ButtonAdd from "../../../../../components/common/ButtonAdd";
import Spinner from "../../../../../components/common/Spinner";
import ModalGenerico from "../../../../../components/common/Modal";
import Label from "../../../../../components/common/Label";
import Select from "react-select";
import toast from 'react-hot-toast';

export const ActualizarFiscalia = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [filtrar, setFiltrar] = useState(false);
    const [filtros, setFiltros] = useState("");
    const [fiscal_acargo, setFiscal_acargo] = useState(props?.caso?.fiscal_acargo);
    const [instituciones, setInstituciones] = useState([]);
    const [institucionSeleccionada, setInstitucionSeleccionada] = useState(props.caso?.fiscalia_actual?.id);
    const [cargando, setCargando] = useState(false);
    const optionsInstituciones = [];

    useEffect(() => {
        if (filtros !== "") {
            getInstituciones(filtros, token).then((data) => {
                setInstituciones(data?.results);
            });
        }
    }, [filtros]);

    if(filtros === ""){
        let reg = {}
        reg = {
            value: props.caso?.fiscalia_actual?.id,
            label: props.caso?.fiscalia_actual?.nombre_fiscalia,
        };

        optionsInstituciones.push(reg);
    } else {
        instituciones?.forEach((item) => {
            let reg ={};
            reg = {
                value: item.id,
                label: item?.nombre_fiscalia,
            };
            optionsInstituciones.push(reg);
        });
    }

    const changeSelectInstituciones = (e) => {
        setInstitucionSeleccionada(e.value);
    };

    return (
        <div>
            <div className="grid grid-cols-12 gap-3 items-end p-3 mb-20">
                <div className="col-span-6 xl:col-span-3 lg:col-span-2 md:col-span-2 flex flex-col justify-end items-end">
                    <ButtonAdd
                        className="ml-2"
                        text="Filtrar"
                        type="button"
                        onClick={() => {
                            setFiltrar(true);
                        }}
                        icon={<FilterIcon className=" h-5" />}
                    />
                </div>
                <div className="col-span-12 xl:col-span-9 lg:col-span-8 md:col-span-8">
                    <Label text="Fiscalia" />
                    <Select
                        defaultValue={optionsInstituciones[0]}
                        placeholder="Seleccionar"
                        isMulti={false}
                        isSearchable={true}
                        name="institucion"
                        className="basic-multi-select text-xs text-maivi-primaryFont"
                        options={optionsInstituciones}
                        onChange={changeSelectInstituciones}
                        maxMenuHeight={150}
                    />
                </div>
                <div className="col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 mb-2">
                    <InputSiavi
                        name="fiscal_acargo"
                        label="Fiscal a cargo"
                        validacion={false}
                        value={fiscal_acargo}
                        onChange={(e) => {
                            setFiscal_acargo(e.target.value);
                        }}
                    />
                </div>
                <div className="col-span-12">
                    <ButtonSiavi
                        type="button"
                        text="Actualizar"
                        onClick={async () => {
                            if (institucionSeleccionada === null) {
                                toast.error("debe filtrar y seleccionar una fiscalia");
                            } else {
                                setCargando(true);
                                await putCasoFiscalia(institucionSeleccionada, fiscal_acargo, props.caso.id, token).then(async (data) => {
                                    if (data?.status >= 200 && data?.status <= 210) {
                                        toast.success("fiscalia actualizada con éxito..!");
                                        setCargando(false);
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 300);
                                    }
                                }).catch(function (error) {
                                    if (
                                        error.response.status >= 400 &&
                                        error.response.status <= 450
                                    ) {
                                        toast.error(error.response.data.message);
                                        setCargando(false);
                                    } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                        setCargando(false);
                                    }
                                });
                            }
                        }}
                    />
                </div>
            </div>
            {
                cargando && <Spinner />
            }
            {filtrar && (
                <ModalGenerico
                    title={`Filtrar fiscalía`}
                    open={true}
                    closeModal={() => { }}
                    cerrar={() => {
                        setFiltrar(false);
                    }}
                    children={
                        <FiltrosGestionInstitucion
                            setFiltros={setFiltros}
                            setFiltrar={setFiltrar}
                        />
                    }
                />
            )}
        </div>
    );
};
