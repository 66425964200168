import { React } from "react";
import Label from "../../../../../components/common/Label";
import { InputSiavi } from "../../../../../components/common/InputText";
import Toggle from "../../../../../components/common/Toggle";

export const RasgosFisicos = (props) => {
  return (
    <div className="grid grid-cols-12 gap-3">
     
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Tiene Tatuajes" />
        <Toggle enabled={props.values.tiene_tatuajes} name="tiene_tatuajes" />
      </div>
      {props.values.tiene_tatuajes === true && (
        <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
          <InputSiavi
            type="text"
            name="especificacion_tatuaje"
            value={props.values.especificacion_tatuaje}
            onChange={props.handleChange}
            validacion={true}
            label="Especificación Tatuaje"
          />
        </div>
      )}
      {props.values.tiene_tatuajes === true && (
        <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
          <InputSiavi
            type="text"
            name="ubicacion_tatuaje"
            value={props.values.ubicacion_tatuaje}
            onChange={props.handleChange}
            validacion={true}
            label="Ubicación Tatuaje"
          />
        </div>
      )}
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="number"
          name="altura"
          value={props.values.altura}
          onChange={props.handleChange}
          validacion={true}
          label="Altura (cms)"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="peso"
          value={props.values.peso}
          onChange={props.handleChange}
          validacion={true}
          label="Peso (lbs)"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="complexion"
          value={props.values.complexion}
          onChange={props.handleChange}
          validacion={true}
          label="Complexión"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="color_forma_cabello"
          value={props.values.color_forma_cabello}
          onChange={props.handleChange}
          validacion={true}
          label="Color y Forma de Cabello"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="color_tez"
          value={props.values.color_tez}
          onChange={props.handleChange}
          validacion={true}
          label="Color de tez"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Tiene Cicatrices" />
        <Toggle
          enabled={props.values.tiene_cicatrices}
          name="tiene_cicatrices"
        />
      </div>
      {props.values.tiene_cicatrices === true && (
        <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
          <InputSiavi
            type="text"
            name="ubicacion_cicatriz"
            value={props.values.ubicacion_cicatriz}
            onChange={props.handleChange}
            validacion={true}
            label="Ubicación Cicatriz"
          />
        </div>
      )}
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Tiene Barba" />
        <Toggle enabled={props.values.tiene_barba} name="tiene_barba" />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Tiene Bigote" />
        <Toggle enabled={props.values.tiene_bigote} name="tiene_bigote" />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Usa anteojos" />
        <Toggle enabled={props.values.usa_anteojos} name="usa_anteojos" />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="forma_de_cejas"
          value={props.values.forma_de_cejas}
          onChange={props.handleChange}
          validacion={true}
          label="Forma de cejas"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="color_ojos"
          value={props.values.color_ojos}
          onChange={props.handleChange}
          validacion={true}
          label="Color de ojos"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="forma_de_nariz"
          value={props.values.forma_de_nariz}
          onChange={props.handleChange}
          validacion={true}
          label="Forma de nariz"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="caracteristicas_dentadura"
          value={props.values.caracteristicas_dentadura}
          onChange={props.handleChange}
          validacion={true}
          label="Características dentadura"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="vestimenta"
          value={props.values.vestimenta}
          onChange={props.handleChange}
          validacion={true}
          label="Vestimenta"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <Label text="Referencia ubicación" />
        <InputSiavi
          type="text"
          name="referencia_ubicacion"
          value={props.values.referencia_ubicacion}
          onChange={props.handleChange}
        />
      </div>
    </div>
  );
};
