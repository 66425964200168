/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { Orientacion } from "./orientacion";
import { useSelector } from "react-redux";
import { store } from '../../../../../app/redux/store/store';
import { saveOrientacion } from '../../../../../app/redux/actions/formOrientacion';
import { useParams } from "react-router-dom";
import { getPersona } from "../../editar/services";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { ChangeOrientacionTab } from "../../../../../app/redux/actions/orientacionTab";
import {
  postOrientacion,
  getProcesos,
  getServicios,
  postProfesionalOrientacion
} from "../services";
import toast from 'react-hot-toast';
import ModalGenerico from "../../../../../components/common/Modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import Label from "../../../../../components/common/Label";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  tipo_persona: Yup.string().required('tipo persona es requerido'),
  fechaDenuncia: Yup.string().when('presentoDenuncia', {
    is: true,
    then: Yup.string().required('fecha denuncia es requerida')
  }),
  numeroDenuncia: Yup.string().when('presentoDenuncia', {
    is: true,
    then: Yup.string().required('numero denuncia es requerido')
  }),
  institucionDenuncia: Yup.number().when('presentoDenuncia', {
    is: true,
    then: Yup.number().required('institucion es requerida').min(1, "institucion es requerida")
  }),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  store.dispatch(saveOrientacion(values));
};

export const FormOrientacion = () => {
  const { id } = useParams();
  const query = new URLSearchParams(window.location.search);
  const anio = query.get("ano_historico");
  const historica = query.get("historica");
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const token = useSelector((state) => state.loginReducer.data.token);
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [cargando, setCargando] = useState(true);
  const [persona, setPersona] = useState([]);
  const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
  const [procesosSeleccionadas, setProcesosSelecionadas] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [crear, setCrear] = useState(false);
  const [ultimoServicio, setUltimoServicio] = useState(null);
  const [asistenciaLegal, setAsistenciaLegal] = useState(false);
  const [guardando,setGuardando] = useState(false);
  const options = [];
  const optionsProcesos = [];
  const animatedComponents = makeAnimated();

  useEffect(() => {
    getPersona(token, id).then((data) => {
      setPersona(data);
      setCargando(false);
    });
  }, []);

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, []);

  useEffect(() => {
    if (ultimoServicio !== null) {
      getProcesos(ultimoServicio, token).then((data) => {
        setProcesos(data);
      });
    }
  }, [ultimoServicio]);

  servicios?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    options.push(reg);
  });

  procesos?.results?.forEach((item) => {
    let reg = {
      value: item?.tipo_proceso?.id,
      label: item?.tipo_proceso?.tipo_proceso,
    };
    optionsProcesos.push(reg);
  });

  const changeSelectServicios = (e) => {
    setUltimoServicio(e[e.length - 1]);
    setServiciosSeleccionados(e);

    servicios?.results?.forEach(element => {
      if (element.id === Number(e[e.length - 1]?.value)) {
        if (element.asitencia_legal) {
          setAsistenciaLegal(true);
        }
      }
    });
  };

  const changeSelectProcesos = (e) => {
    setProcesosSelecionadas(e);
    setAsistenciaLegal(false);
  };

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element, index) => {
      if (index === indexTab) {
        element.active = true;
      } else {
        element.active = false;
      }
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };

  return (!cargando) ?
    <Formik
      initialValues={{
        idPersona: id,
        presentoDenuncia: false,
        tipoDocto: "",
        numeroDenuncia: "",
        fechaDenuncia: "",
        institucionDenuncia: 0,
        numeroDocumento: "",
        entidad_deriva: "",
        fechaResolucion: "",
        casoAdmitido: false,
        casoNoAdmitido: false,
        valorCaso: null,
        ano_historico: (anio === null) ? "" : anio,
        historica: (historica === null) ? false : historica,
        fecha: (historica === null) ? false : "",
        isAdmisible: "1",
        motivoAdmisibilidad: "",
        motivoNoAdmisibilidad: "",
        isViable: "1",
        motivoViabilidad: "",
        motivoNoViabilidad: "",
        observaciones: "",
        sede: sede,
        profesional: "",
        profesional_acompania: "",
        comentarios: "",
        iv_historico: "",
        servicios: "",
        otros: "",
        delitos: "",
        tipo_consultas: "",
        serviciosAgregados: [],
        delitosAgregados: [],
        motivosAdmisibilidad: [],
        motivosNoAdmisibilidad: [],
        motivosViabilidad: [],
        motivosNoViabilidad: [],
        profesionales: [],
        alias: persona?.como_le_gusta_que_le_llamen,
        nombre: `${persona?.primer_nombre} ${persona?.primer_apellido}`,
        tipo_persona: "",
        descripcion_motivo_no_admisibilidad: "",
        querellante: false,
        victima: false,
        como_le_gusta_que_le_llamen: "",
        nombre_tipo_persona: ""
      }}

      validationSchema={validaciones}
      onSubmit={async (values) => {
        if (values.casoAdmitido === true && values.presentoDenuncia === false) {
          setError(true);
          setSuccess(false);
          setMensaje("Debe seleccionar la opcion de presento denuncia");
          setTimeout(() => {
            setError(false);
          }, 4000);
        }

        else if (values.casoAdmitido === false || values.casoAdmitido === null) {
          values.serviciosAgregados = [];
          values.procesosAgregados = [];
          setCargando(true);
          await postOrientacion(values, token)
            .then(async(data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                setSuccess(true);
                setError(false);
                setCargando(false);
                setMensaje("registro creado éxitosamente");

                if(values.profesional_acompania !== null){
                  await postProfesionalOrientacion(data.data.id,values.profesional_acompania,token);
                }

                toast.success('Registro creado con éxito...!');
                setTimeout(() => {
                  window.location = `/funcionalidades-generales/editar-orientacion/${data.data.id}?admitido=${data.data.caso_admitido}`
                }, 400);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                setError(true);
                setSuccess(false);
                setMensaje(error.response.data.message);
                setCargando(false);
                toast.error(error.response.data.message);
                setTimeout(() => {
                  setError(false);
                }, 4000);
              } else if (error.response.status >= 500) {
                setError(true);
                setSuccess(false);
                setCargando(false);
                setTimeout(() => {
                  setError(false);
                }, 4000);
                setMensaje("Error interno comuniquese con el administrador");
              }
            });
        }

        else {
          setCrear(true);
        }
      }}
    >
      {(props) => (
        <div>
          <Orientacion
            {...props}
            customSwitchChange={customSwitchChange}
            customChange={handleChangeInput}
            hasError={hasError}
            success={success}
            mensaje={mensaje}
            historica={historica}
            anio={anio}
          />
          {crear && (
            <ModalGenerico
              title={`Seleccione los servicios y los procesos`}
              open={crear}
              closeModal={() => { }}
              cerrar={() => {
                setCrear(false);
              }}
              children={
                <div className="h-80 pb-10">
                  <div className="mt-5 mb-5">
                    <Label text="Servicios" />
                    <Select
                      placeholder="Seleccionar"
                      components={animatedComponents}
                      isMulti
                      name="atenciones"
                      className="basic-multi-select mb-5 text-xs text-maivi-primaryFont"
                      classNamePrefix="seleccione"
                      options={options}
                      onChange={changeSelectServicios}
                      maxMenuHeight={140}
                    />
                    <Label text="Procesos" />
                    <Select
                      placeholder="Seleccionar"
                      components={animatedComponents}
                      isMulti
                      name="servicios"
                      className="basic-multi-select text-xs text-maivi-primaryFont"
                      classNamePrefix="seleccione"
                      options={optionsProcesos}
                      onChange={changeSelectProcesos}
                      maxMenuHeight={140}
                    />
                  </div>
                  {
                    guardando ? 
                    <Spinner /> :
                    <ButtonSiavi
                      type="button"
                      onClick={async () => {
                        props.values.serviciosAgregados = serviciosSeleccionados;
                        props.values.procesosAgregados = procesosSeleccionadas;
                        if (asistenciaLegal) {
                          setError(true);
                          setMensaje("Procesos son requeridos");
                          setTimeout(() => {
                            setError(false);
                          }, 3000);
                        } else {
                          let flagTrabajoSocial = false;
                          serviciosSeleccionados.forEach(element => {
                            servicios?.results?.forEach(element2 => {
                              if(element.value === element2.id){
                                if(element2.ts === true){
                                  flagTrabajoSocial = true;
                                }
                              }
                            });
                          });

                          if(flagTrabajoSocial){
                            setGuardando(true);
                            await postOrientacion(props.values, token)
                              .then(async(data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                  setSuccess(true);
                                  setError(false);
                                  if (props.values.nombre_tipo_persona === "VICTIMA") {
                                    changeTab(1);
                                  } else {
                                    changeTab(2);
                                  }

                                  if(props.values.profesional_acompania !== null){
                                    await postProfesionalOrientacion(data.data.id, props.values.profesional_acompania, token);
                                  }

                                  setMensaje("registro creado éxitosamente");
                                  toast.success('Registro creado con éxito...!');

                                  setTimeout(() => {
                                    window.location = `/funcionalidades-generales/editar-orientacion/${data.data.id}?admitido=${data.data.caso_admitido}`
                                  }, 600);
                                }
                              })
                              .catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                  setError(true);
                                  setSuccess(false);
                                  setMensaje(error.response.data.message);
                                  toast.error(error.response.data.message);
                                  setGuardando(false);
                                  setTimeout(() => {
                                    setError(false);
                                  }, 4000);
                                } else if (error.response.status >= 500) {
                                  setError(true);
                                  setSuccess(false);
                                  setMensaje("Error interno comuniquese con el administrador");
                                  setGuardando(false);
                                  setTimeout(() => {
                                    setError(false);
                                  }, 4000);
                                }
                              });
                          } else {
                            setError(true);
                            setMensaje("Es necesario agregar el Servicio de trabajo social");
                            setTimeout(() => {
                              setError(false);
                            }, 3000);
                          }
                        }
                      }}
                      disabled={props.isSubmitting}
                      text="Guardar y continuar"
                    />
                  }
               
                  <div className="col-span-12 mt-4">
                    {success && <Alert type="success" mensaje={mensaje} />}
                    {hasError && <Alert type="error" mensaje={mensaje} />}
                  </div>
                </div>
              }
            />
          )}
        </div>
      )}
    </Formik>
    :
    <Spinner />

};
