/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getExpedienteCausapenal, getSindicados, getPersonas} from "./services";
import { clases } from "../../../../helpers/clases";
import TableSiavi from "../../../../components/common/TableSiavi";
import Label from "../../../../components/common/Label";
import Divider from "../../../../components/common/Divider";

export const FormDetalle = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idCaso = props.caso.id;
    const [causas, setCausas] = useState([]);
    const [sindicados, setSindicados] = useState([]);
    const [personas,setPersonas] = useState({});
    const [cargando, setCargando] = useState(false);
    const [cargandoCausas, setCargandoCausas] = useState(false);
    const [cargandoPersonas, setCargandoPersonas] = useState(false);

    useEffect(() => {
        setCargando(true);
        getExpedienteCausapenal(idCaso, token).then((data) => {
            setCausas(data);
            setCargando(false);
        });
    }, []);

    useEffect(() => {
        setCargandoCausas(true);
        getSindicados(idCaso, token).then((data) => {
            setSindicados(data);
            setCargandoCausas(false);
        });
    }, []);

    useEffect(() => {
        setCargandoCausas(true);
        getSindicados(idCaso, token).then((data) => {
            setSindicados(data);
            setCargandoCausas(false);
        });
    }, []);

    useEffect(() => {
        setCargandoPersonas(true);
        getPersonas(idCaso, token).then((data) => {
            setCargandoPersonas(false);
            setPersonas(data);
        });
    }, []);

    const headersTableSindicados = [
        "Sindicado",
        "Identificacion"
    ];

    const headersTableCausas = [
        "No. Causa penal",
        "Organo jurisdiccional"
    ];

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="cols-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-2">
                <div className=" border-gray-200 p-0">
                    <div className="sm:divide-y sm:divide-gray-200 ">
                        <div className="py-2 grid grid-cols-12 gap-2 px-3 hover:bg-gray-100">
                            <div className="col-span-5 text-sm font-medium text-maivi-primaryFont">
                                Número expediente:
                            </div>
                            <div className="col-span-7 text-sm text-gray-900 ">
                                {props?.caso?.numero_caso}
                            </div>
                        </div>
                    </div>
                    <div className="sm:divide-y sm:divide-gray-200 ">
                        <div className="py-2 grid grid-cols-12 gap-2 px-3 hover:bg-gray-100">
                            <div className="col-span-5 text-sm font-medium text-maivi-primaryFont">
                                Víctima:
                            </div>
                            <div className="col-span-7 text-sm text-gray-900 ">
                                {props?.caso?.victima?.primer_nombre} {props?.caso?.victima?.segundo_nombre} {props?.caso?.victima?.primer_apellido} {props?.caso?.victima?.segundo_apellido}
                            </div>
                        </div>
                    </div>
                    <div className="sm:divide-y sm:divide-gray-200 ">
                        <div className="py-2 grid grid-cols-12 gap-2 px-3 hover:bg-gray-100">
                            <div className="col-span-5 text-sm font-medium text-maivi-primaryFont">
                                No. denuncia:
                            </div>
                            <div className="col-span-7 text-sm text-gray-900 ">
                                {props?.caso?.no_denuncia}
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <Label text="Personas" />
                <TableSiavi
                    cargando={cargandoPersonas}
                    headers={[
                        'Nombre',
                        'Querellante',
                        'Rep. Legal',
                        'Víctima',
                        'Víctima indirecta'
                    ]}
                    body={
                        personas?.results?.map((item, index) => (
                            <tr className={clases.tr} key={item?.persona?.primer_nombre + index}>
                                <td className={clases.td}>{item?.persona?.primer_nombre} {item?.persona?.segundo_nombre} {item?.persona?.primer_apellido} {item?.persona?.segundo_apellido}</td>
                                <td className={clases.td}>{item?.querellante ? 'Si': 'No'}</td>
                                <td className={clases.td}>{item?.representante_legal ? 'Si': 'No'}</td>
                                <td className={clases.td}>{item?.victima ? 'Si': 'No'}</td>
                                <td className={clases.td}>{item?.es_victima_indirecta ? 'Si': 'No'}</td>
                            </tr>
                        ))
                    }
                />
            </div>
            <div className="cols-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-2">
                <Label text="Causas penales" />
                <TableSiavi
                    cargando={cargandoCausas}
                    headers={headersTableCausas}
                    body={
                        causas?.results?.map((item, index) => (
                            <tr className={clases.tr} key={index}>
                                <td className={clases.td}>{item?.numero_causa_penal}</td>
                                <td className={clases.td}>{item?.organo_jurisdiccional?.nombre_fiscalia}</td>
                            </tr>
                        ))
                    }
                />

                <Divider />
                <Label text="Sindicados" />
                <TableSiavi
                    cargando={cargando}
                    headers={headersTableSindicados}
                    body={
                        sindicados?.results?.map((item, index) => (
                            <tr className={clases.tr} key={item?.sindicado?.primer_nombre + index}>
                                <td className={clases.td}>{item?.sindicado?.primer_nombre} {item?.sindicado?.segundo_nombre} {item?.sindicado?.primer_apellido} {item?.sindicado?.segundo_apellido}</td>
                                <td className={clases.td}>{item?.no_identificacion}</td>
                            </tr>
                        ))
                    }
                />
            </div>
        </div>
    );
};
