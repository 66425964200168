import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteSignosVitales,
    getSignosDiagnostico,
    postSignosVitales,
    putSignosVitales
} from "./services";
import Spinner from "../../../../../../components/common/Spinner";
import CustomTable from "../../../../../../components/common/Table";
import ModalGenerico from "../../../../../../components/common/Modal";
import FormEliminar from "../../../../../../components/common/FormEliminar";
import toast from 'react-hot-toast';

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "text" },
    { title: "Presión Arterial", value: "presion_arterial", tipo: "text" },
    { title: "Frecuencia Cardíaca", value: "frecuencia_cardiaca", tipo: "text" },
    { title: "Frecuencia Respiratoria", value: "frecuencia_respiratoria", tipo: "text" },
    { title: "Temperatura", value: "temperatura", tipo: "text" },
    { title: "Peso", value: "peso", tipo: "text" },
    { title: "Talla", value: "talla", tipo: "text" },
    { title: "GMT", value: "gmt", tipo: "text" },
    { title: "CPO2", value: "cpo2", tipo: "text" },
];

export const GestionSignosVitales = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idSignosVitales, setIdSignosVitales] = useState("");
    const [signosVitales, setSignosVitales] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idDiagnostico = props?.diagnostico?.id;
    const idExpediente = props?.idExpediente?.id;
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "expedientesignosvitales";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdSignosVitales(id);
        setEliminar(true);
    };

    const handleEditar = (gestionSignosVitales) => {
        setEdit(true);
        setSignosVitales(gestionSignosVitales);
    };

    const fetchSignosVitales = () => {
        getSignosDiagnostico(idDiagnostico, token).then((signosvitales) => {
            setDataTable(signosvitales);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchSignosVitales, [token, idDiagnostico, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    expediente_diagnostico: props.diagnostico.id,
                    profesional: idUser,
                    expediente: idExpediente,
                    caso: props.idExpediente.caso,
                    fecha: isEdit ? signosVitales?.fecha : "",
                    presion_arterial: isEdit ? signosVitales?.presion_arterial : "",
                    frecuencia_cardiaca: isEdit ? signosVitales?.frecuencia_cardiaca : "",
                    pulso: isEdit ? signosVitales?.pulso : "",
                    frecuencia_respiratoria: isEdit ? signosVitales?.frecuencia_respiratoria : "",
                    temperatura: isEdit ? signosVitales?.temperatura : "",
                    peso: isEdit ? signosVitales?.peso : "",
                    talla: isEdit ? signosVitales?.talla : "",
                    gmt: isEdit ? signosVitales?.gmt : "",
                    cpo2: isEdit ? signosVitales?.cpo2 : ""
                }}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putSignosVitales(signosVitales.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.fecha = "";
                                    values.presion_arterial = "";
                                    values.frecuencia_cardiaca = "";
                                    values.pulso = "";
                                    values.frecuencia_respiratoria = "";
                                    values.temperatura = "";
                                    values.peso = "";
                                    values.talla = "";
                                    values.gmt = "";
                                    values.cpo2 = "";
                                    setEdit(false);
                                    resetForm({});
                                }
                            })
                    } else {
                        await postSignosVitales(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 border rounded-md hover:shadow-lg p-3 items-end">
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="date"
                                    name="fecha"
                                    value={values.fecha}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="presion_arterial"
                                    value={values.presion_arterial}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Presión Arterial"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="frecuencia_cardiaca"
                                    value={values.frecuencia_cardiaca}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Frecuencia Cardíaca"
                                />
                            </div>
{/*                             <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="pulso"
                                    value={values.pulso}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Pulso"
                                />
                            </div> */}
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="frecuencia_respiratoria"
                                    value={values.frecuencia_respiratoria}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Frecuencia Respiratoria"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="temperatura"
                                    value={values.temperatura}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Temperatura"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="peso"
                                    value={values.peso}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Peso"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="talla"
                                    value={values.talla}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Talla"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="gmt"
                                    value={values.gmt}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="GMT"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="cpo2"
                                    value={values.cpo2}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="CPO2"
                                />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 sm:col-span-4 lg:col-span-2 md:col-span-2">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteSignosVitales(idSignosVitales, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};