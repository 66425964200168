/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { profesionales, getProfesionales } from "./services";
import { FilterIcon, UserIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./detalle";
import { useSelector } from "react-redux";
import { RefreshIcon } from "@heroicons/react/outline";
import { ChangeSearch } from '../../../app/redux/actions/search';
import { store } from '../../../app/redux/store/store';
import CustomTable from "../../../components/common/Table";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import ModalTwo from "../../../components/common/ModalTwo";
import Filtros from "./filtros";
import ModalGenerico from "../../../components/common/Modal";

const tableColumns = [
    { title: "Tipo Profesión", value: "tipo_profesional.profesion", tipo: "text", ordenar: "tipo_profesional__profesion" },
    { title: "Nombre", value: null, values: ["first_name", "last_name"], tipo: "text", ordenar: "first_name" },
    { title: "Tipo Puesto", value: "tipo_puesto.puesto", tipo: "text", ordenar: "tipo_puesto__puesto" },
    { title: "Sede Principal", value: "sede.sede", tipo: "text", ordenar: "sede__sede" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

export const TableProfesionales = () => {
    const query = new URLSearchParams(window.location.search);
    const value_route = query.get("value");
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search)
    const [profesional, setProfesional] = useState("");
    const [verDetalle, setDetalle] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState("");
    const [tipo] = useState(()=>{
        if(value_route === "asistencia_legal"){
            return "&profesionalservicios__servicio__asitencia_legal=true";
        } else if(value_route === "atencion_victimologica"){
            return "&profesionalservicios__servicio__atencion_victimologica=true";
        } else {
            return ""
        }
    });
    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "gestionpersona";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const fetchProfesionales = () => {
        setTableLoading(true);
        profesionales(urlFetch, search, order, filters+tipo, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    };

    const fetchGetProfesionales = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setUrlFech(undefined);
            setTableLoading(true);
            getProfesionales(urlFetch, search, order, filters+tipo, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchProfesionales, [urlFetch, order, filters, token]);
    useEffect(fetchGetProfesionales, [urlFetch, search, order, filters, token]);

    const handleVer = (item) => {
        setDetalle(true);
        setProfesional(item);
    };

    const pages = [
        { name: "Funcionalidades", href: "/funcionalidades-generales", current: false },
        { name: "Profesionales", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
                <BreadCrumb navegacion={pages} />
                {permisoAgregar && (
                    <div>
                        <ButtonAdd
                            className="ml-2"
                            text="Filtros"
                            type="button"
                            onClick={() => {
                                setOpenFilters(true);
                            }}
                            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                        />
                        <ButtonAdd
                            className="ml-2"
                            text="Refrescar"
                            type="button"
                            onClick={() => {
                                window.location.reload();
                            }}
                            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                        />
                    </div>
                )}
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showEditar={false}
                        showEliminar={false}
                        showVer={true}
                        order={order}
                        setOrder={setOrder}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {verDetalle && (
                        <ModalTwo
                            title={"Detalle de profesional"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <FormDetalle Profesional={profesional} />
                            }
                        />
                    )}
                    {openFilters && (
                        <ModalGenerico
                            title={`Aplicar filtros`}
                            open={openFilters}
                            closeModal={() => { }}
                            cerrar={() => {
                                setOpenFilters(false);
                            }}
                            children={
                                <Filtros
                                    setFilters={setFilters}
                                    setOpenFilters={setOpenFilters}
                                    token={token}
                                />
                            }
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <FilterIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
