import { React, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { resetPassword } from "./services";
import Alert from "../../../components/common/Alert";
import storage from "redux-persist/lib/storage";

const validaciones = Yup.object().shape({
  actual: Yup.string().required("Contraseña actual es requerida"),
  nueva: Yup.string().required("Nueva contraseña es requerida"),
  confirmar: Yup.string().required("Confirmar contraseña es requerida"),
});

export const CambiarPassword = () => {
  const loginState = useSelector((state) => state?.loginReducer?.data);
  const user = loginState?.usuario?.user?.username;
  const [isSuccess, setSuccess] = useState(false);
  const [hasError, setError] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  return (
    <div>
      <Formik
        initialValues={{
          actual: "",
          nueva: "",
          confirmar: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (values.nueva !== values.confirmar) {
            setError(true);
            setMensajeError("Las contraseñas nuevas no coinciden");
          } else {
            resetPassword(user, values.actual, values.nueva)
              .then((data) => {
                if (data.status === 200) {
                  setSuccess(true);
                  setError(false);
                  setTimeout(() => {
                    storage.removeItem("persist:root");
                    storage.removeItem("token");
                    window.location = "/";
                  }, 2000);
                }
              })
              .catch(function (error) {
                if (error.response.status === 400) {
                  setSuccess(false);
                  setError(true);
                  setMensajeError(error.response.data.message);
                }

                if (error.response.status === 404) {
                  setSuccess(false);
                  setError(true);
                  setMensajeError(error.response.data.message);
                }

                if (error.response.status === 403) {
                  setSuccess(false);
                  setError(true);
                  setMensajeError(error.response.data.message);
                }
              });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6 mt-4">
                <Label text="Contraseña actual" />
                <InputSiavi
                  type="password"
                  name="actual"
                  value={values.actual}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="actual"
                  component="span"
                  className="text-sm text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Nueva contraseña" />
                <InputSiavi
                  type="password"
                  name="nueva"
                  value={values.nueva}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="nueva"
                  component="span"
                  className="text-sm text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Confirmar nueva contraseña" />
                <InputSiavi
                  type="password"
                  name="confirmar"
                  value={values.confirmar}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="confirmar"
                  component="span"
                  className="text-sm text-maivi-secondaryRed"
                />
              </div>
              <div className="mt-2 ">
                {isSuccess && (
                  <Alert
                    type="success"
                    mensaje="Contraseña actualizada éxitosamente"
                  />
                )}
                {hasError && <Alert type="error" mensaje={mensajeError} />}
                {isSubmitting && <Spinner />}
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text="Cambiar"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
