import axios from 'axios';


const {
    REACT_APP_API_URL,
    REACT_APP_JASPER_REPORT,
    REACT_APP_JASPER_USUARIO,
    REACT_APP_JASPER_PASSWORD
} = process.env;


export const getServerInfo = async (data, token) => {
    try {
        const regex =/rest_v2(\/*\b\w*)*/i;
        const SERVER_IFO=REACT_APP_JASPER_REPORT.replace(regex,'rest_v2/serverInfo')

        let bodyResult = axios({
            method: 'GET',
            url: `${SERVER_IFO}`,
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export const descargarExcel = async (reporte,props) => {

    let parametros = {
        usuario:props.usuario,
        fechaInicio: props.fechaInicio,
        fechaFin: props.fechaFin,
        //profesionales: props.profesionales,
        //acciones:props.acciones,
        servicios:props.servicios,
        //numerocaso:props.numerocaso,
        asignacion:props.asignacion,
        profesion:props.profesion
    }

    let params = new URLSearchParams(parametros)

    //filtros personalizados
if(props.expediente!=null)
{
    params.append('expediente',props.expediente.id)
}

if(props.numerocaso!=0)
    {
        params.append('numerocaso',props.numerocaso)
    }


    //agregar filtros multiselección
    
    if(props.acciones!=0)
    {
        props.acciones?.map((item) => {
            params.append('acciones', item.accionId);
           // console.log(item)
          })
    }
    
    if(Array.isArray(props.profesionales))
    {
        props.profesionales?.map((item) => {
            params.append('profesionales', item.profesionalId);
          //  console.log(item)
          })
    }
    else if(props.profesionales!=0)
    {
        params.append('profesionales',props.profesionales)
    }
    
    if(props.motivoVisita!=0)
    {
        params.append('motivoVisita',props.motivoVisita)
    }
    
    
    if(Array.isArray(props.sedes))
    {
        props.sedes?.map((item) => {
            params.append('sedes', item.sedeId);
          //  console.log(item)
          })
    }
    else
    {
        params.append('sedes',props.sedes)
    }

    if(Array.isArray(props.delitos))
{
    props.delitos?.map((item) => {
        params.append('delitos', item.id);
      //  console.log(item)
      })
}
else if(props.delitos!=0)
{
    params.append('delitos',props.id)
}

if(Array.isArray(props.edades))
{
    props.edades?.map((item) => {
        params.append('edades', item.id);
      //  console.log(item)
      })
}
else if(props.edades!=0)
{
    params.append('edades',props.id)
}

if(Array.isArray(props.departamentos))
{
    props.departamentos?.map((item) => {
        params.append('departamentos', item.departamentoId);
      //  console.log(item)
      })
}
else  
{
    params.append('departamentos',props.departamentos)
}

if(Array.isArray(props.municipios))
{
    props.municipios?.map((item) => {
        params.append('municipios', item.municipioId);
      //  console.log(item)
      })
}
else if(props.municipios!=0)
{
    params.append('municipios',props.municipios)
}

if(Array.isArray(props.medidas))
{
    props.medidas?.map((item) => {
        params.append('medidas', item.id);
      //  console.log(item)
      })
}
else if(props.medidas!=0)
{
    params.append('medidas',props.medidas)
}

if(Array.isArray(props.instituciones))
{
    props.instituciones?.map((item) => {
        params.append('instituciones', item.id);
      //  console.log(item)
      })
}
else if(props.instituciones!=0)
{
    params.append('instituciones',props.id)
}


    axios({
        url: `${REACT_APP_JASPER_REPORT}${reporte}.xls`, //your url
        method: 'GET',
        params:params,
        auth:{
            username:REACT_APP_JASPER_USUARIO,
            password:REACT_APP_JASPER_PASSWORD},
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `Reporte.xls`); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
};




export const getSedes = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getProfesionales = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getServicios = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true&atencion_victimologica=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getAcciones = async (token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=1000`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivoVisita = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/motivovisita/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDelitos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/delito/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
