/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { SelectSiavi } from "../../../../components/common/Select";
import {
  getProfesionales,
  getTipoDiligencias
} from "./services";
import Select from "react-select";
import Spinner from "../../../../components/common/Spinner";
import makeAnimated from "react-select/animated";
import Label from "../../../../components/common/Label";

export default function Filtros(props) {
  const optionsProfesionales = [];
  const diligencias = [];
  const animatedComponents = makeAnimated();
  const [profesionalSelect, setProfesional] = useState("");
  const [diligencia, setDiligencia] = useState("");
  const [profesionales, setProfesionales] = useState([]);
  const [tipodiligencias, setTipodiligencias] = useState([]);

  let filtros = "";
  const [state, setState] = useState({
    urgente: "",
    enterado: "",
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getTipoDiligencias(props.token).then((data) => {
      setTipodiligencias(data);
    });
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
  });

  const changeSelectProfesionales = (e) => {
    setProfesional(e.value);
  };

  tipodiligencias?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item?.nombre,
    };
    diligencias.push(reg);
  });

  const changeSelectDiligencia = (e) => {
    setDiligencia(e.value);
  };

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
      <div className="col-span-12 mt-4">
        <Label text="Profesional asignado" />
        <Select
          isMulti={false}
          placeholder="Seleccionar profesional"
          components={animatedComponents}
          name="servicios"
          className="basic-multi-select text-xs text-maivi-primaryBlue w-full mb-4"
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesionales}
          maxMenuHeight={140}
        />
      </div>
      <div className="col-span-12 mt-4">
        <Label text="Tipo diligencia" />
        <Select
          isMulti={false}
          placeholder="Seleccionar tipo de diligencia"
          components={animatedComponents}
          name="diligencias"
          className="basic-multi-select text-xs text-maivi-primaryBlue w-full mb-4"
          classNamePrefix="seleccione"
          options={diligencias}
          onChange={changeSelectDiligencia}
          maxMenuHeight={140}
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="urgente"
          onChange={onChange}
          value={state.urgente}
          valueInit=""
          selectInit="Elegir"
          label="Urgente"
          list={[
            <option value="true">Si</option>,
            <option value="false">No</option>
          ]}
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="enterado"
          onChange={onChange}
          value={state.enterado}
          valueInit=""
          selectInit="Elegir"
          label="Enterado"
          list={[
            <option value="true">Si</option>,
            <option value="false">No</option>
          ]}
        />
      </div>

      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `tipo_diligencia=${diligencia}&expediente__profesional_asignado=${profesionalSelect}&enterado=${state.enterado}&urgente=${state.urgente}`;
            props.setFiltros(filtros);
            props.setOpenFiltros(false);
            props.setUrlFech(undefined)
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
