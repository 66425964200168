/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../components/common/Select";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import { FormDetalleAccion } from "../../casos/editar/atencion_victimologica/trabajo_social/detalleAccion";
import { GestionAccionesRecursos } from "../../casos/editar/atencion_victimologica/trabajo_social/gestionAccionRecursos";
import { GestionNombramiento } from "../../casos/editar/atencion_victimologica/trabajo_social/gestionNombramiento";
import { GearIcon } from "../../../../helpers/icons";
//import { bloqueoFecha } from "../../../../helpers/utils";
import {
    deleteAcciones,
    postAcciones,
    putAcciones,
    getDeptosGestion,
    getMunicipiosGestion,
    getModalidades,
    getInstitucion,
    //getProfesionales,
    //getReferenciaCreada,
    getCatalogoSeguimientos,
    getSeguimientosAcciones,
} from "../../casos/editar/atencion_victimologica/trabajo_social/services";
import { 
    //getAcciones,
    //getCatalogoAccionesSeguimientos,
    getProfesionales,
    actualizarRecurso
} from "./services";
//../../../casos/editar/atencion_victimologica/trabajo_social/services
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import Toggle from "../../../../components/common/Toggle";
import CustomTable from "../../../../components/common/Table";
import ModalGenerico from "../../../../components/common/Modal";
import FormEliminar from "../../../../components/common/FormEliminar";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const tableColumns = [
    { title: "Tipo seguimiento", value:"accion_seguimiento.nombre", tipo: "text"},
    { title: "Seguimiento", value: "seguimiento", tipo: "text" },
    {
        title: "Completada",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

const validaciones = Yup.object().shape({
    seguimiento: Yup.string()
        .required("Seguimiento es requerido")
});

export const Seguimientos = (props) => {
     //const caso = props?.registro?.caso?.id;
    //const servicio = null; //props.infoExpediente.servicio.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const [verDetalle, setDetalle] = useState(false);
    const [accionesDetalle, setAccionesDetalle] = useState({});
    const idExpediente = props?.expediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAcciones, setIdAcciones] = useState("");
    const [acciones, setAcciones] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [deptos, setDeptos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [catalogoAS, setCatalogoAS] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [profesionalesAsigna, setProfesionalesAsigna] = useState([]);
    //const [referencia, setReferencia] = useState([]);
    const [setNombreDepartamento] = useState("");
    const [agregar, setAgregar] = useState(false);
    const [agregarNombramiento, setAgregarNombramiento] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    //const [habilitaPlanificacion, setHabilitaPlanificacion] = useState(false);
    //const [accionSolicitada,setAccionSolicitada] = useState(false);

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data)
        });
    }, [token]);

    useEffect(() => {
        getCatalogoSeguimientos(token).then((data) => {
            setCatalogoAS(data);
        });
    }, [token]);

    useEffect(() => {
        getInstitucion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    useEffect(() => {
        getProfesionales(props?.expediente?.servicio?.id,token).then((data) => {
            setProfesionalesAsigna(data)
        });
    }, [token]);

    // useEffect(() => {
    //     getReferenciaCreada(caso, servicio, token).then((data) => {
    //         setReferencia(data)
    //     });
    // }, [token, caso, servicio]);

    useEffect(() => {
        getSeguimientosAcciones(urlFetch,idExpediente, token).then((acciones) => {
            setDataTable(acciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, reFetch,urlFetch]);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "accionesseguimientoscaso";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleAgregar = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregar(true);
        } else {
            toast.error("No es posible asignar recurso, porque ya está completado el seguimiento")
        }
    };

    const handleAgregarNombramiento = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregarNombramiento(true);
        } else {
            toast.error("No es posible agregar nombramientos, porque ya está completado el seguimiento")
        }
    };

    const handleVer = (item) => {
        setAccionesDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdAcciones(id);
        setEliminar(true);
    };

    const handleEditar = (acciones) => {
        if (acciones.completada === false) {
            setEdit(true);
            setAcciones(acciones);
        } else {
            toast.error("No es posible editar, porque ya está completado el seguimiento")
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    seguimiento: isEdit ? acciones?.seguimiento : "",
                    es_nna: isEdit ? acciones?.es_nna : false,
                    tipo_audiencia: isEdit ? acciones?.tipo_audiencia?.id : "",
                    fecha_accion: isEdit ? acciones?.fecha_accion : "",
                    fecha_asigna: isEdit ? acciones?.fecha_asigna : "",
                    requiere_autorizacion: false,
                    fecha_autoriza: isEdit ? acciones?.fecha_autoriza : "",
                    autorizado: isEdit ? acciones?.autorizado : null,
                    fecha_completado: isEdit ? acciones?.fecha_completado : "",
                    estado: isEdit ? acciones?.estado : true,
                    completada: isEdit ? acciones?.completada : false,
                    es_accion: false,
                    es_seguimiento: true,
                    requiere_nombramiento: false,
                    observaciones: isEdit ? acciones?.observaciones : "",
                    detalle_lugar: isEdit ? acciones?.detalle_lugar : "",
                    especificaciones_modalidad: isEdit ? acciones?.especificaciones_modalidad : "",
                    tipo: isEdit ? acciones?.tipo : "",
                    caso: props?.caso?.id,
                    expediente: idExpediente,
                    persona: props?.expediente?.caso_persona?.persona?.id,
                    victima: props?.caso?.victima?.id,
                    querellante: (props?.expediente?.caso_persona?.querellante === true) ? props?.expediente?.caso_persona?.persona.id : null,
                    modalidad: isEdit ? acciones?.modalidad?.id : "",
                    //referencia: referencia?.results?.id,
                    departamento: isEdit ? acciones?.departamento?.id : "",
                    municipio: isEdit ? acciones?.municipio?.id : "",
                    gestion_institucion: isEdit ? acciones?.gestion_institucion?.id : "",
                    accion_seguimiento: isEdit ? acciones?.accion_seguimiento?.id : "",
                    profesional_asignado: isEdit ? acciones?.profesional_asignado?.id : "",
                    profesional_autoriza: isEdit ? acciones?.profesional_autoriza?.id : "",
                    profesional_realiza: isEdit ? acciones?.profesional_realiza?.id : "",
                    nombramiento: null,
                    profesional_crea: idUser,
                    sede_accion: sedeId,
                    fecha_hora_planificada_inicia: isEdit ? acciones?.fecha_hora_planificada_inicia : `${props.registro.fecha}T${(props.registro.hora === null)  ? '00:00' : props.registro.hora}`,
                    fecha_hora_planificada_fin: isEdit ? acciones?.fecha_hora_planificada_fin : "",
                    accion_planificada: isEdit ? acciones?.accion_planificada : true,
                    asignar_accion: isEdit ? acciones?.asignar_accion : false,
                    habilita_planificacion: false,
                    seguimiento_accion_solicitada: null,
                    fecha_requerido: isEdit ? acciones?.fecha_requerido : "",
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                            await putAcciones(acciones.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({})
                                    setEdit(false);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });

                    } else {
                        await postAcciones(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    props.registro.accion = data.data.id;
                                    actualizarRecurso(props.registro.id, props.registro, token).then(() =>{
                                        setReFetch(true);
                                        toast.success('Registro creado con éxito...!');
                                        resetForm({});
                                    });
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo Seguimiento"
                                    name="accion_seguimiento"
                                    value={values.accion_seguimiento}
                                    onChange={(e) => {
                                        setFieldValue('accion_seguimiento', e.target.value);
                                        
                                        // catalogoAS?.results?.filter(x => x.habilita_planificacion)?.forEach(element => {
                                        //     if (element.id === Number(e.target.value)) {
                                        //         if (element.habilita_planificacion) {
                                        //             setHabilitaPlanificacion(true);
                                        //         } else {
                                        //             setHabilitaPlanificacion(false);
                                        //         }
                                        //     }
                                        // });
                                    }}
                                    valueInit=""
                                    selectInit="Elegir tipo seguimiento"
                                    list={
                                        catalogoAS?.results?.filter(x => x.es_audiencia === false)?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Seguimiento"
                                    validacion={true}
                                    name="seguimiento"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.seguimiento}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* {(habilitaPlanificacion) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Actividad planificada" />
                                    <Toggle enabled={values.accion_planificada} name="accion_planificada" />
                                </div>
                            )} */}
                            {/* {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_hora_planificada_inicia"
                                        id="fecha_hora_planificada_inicia"
                                        value={values.fecha_hora_planificada_inicia}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={bloqueoFecha()}
                                        label="Fecha Planificada"
                                    />
                                </div>)} */}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Asignar" />
                                    <Toggle enabled={values.asignar_accion} name="asignar_accion" />
                                </div>)}
                            {/* {(!values.asignar_accion) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_accion"
                                        id="fecha_accion"
                                        value={values.fecha_accion}
                                        onChange={handleChange}
                                        validacion={false}
                                        maximo={bloqueoFecha()}
                                        label="Fecha Seguimiento"
                                    />
                                </div>)} */}
                            {values.asignar_accion && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Profesional Asignado"
                                        name="profesional_asignado"
                                        value={values.profesional_asignado}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir profesional"
                                        list={
                                            profesionalesAsigna?.results?.map((item) => {
                                                return (
                                                    <option key={item.first_name} value={item.id}>
                                                        {item.first_name} {item.last_name}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>)}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    list={
                                        modalidades?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="especificaciones_modalidad"
                                    id="especificaciones_modalidad"
                                    value={values.especificaciones_modalidad}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Especificación Modalidad"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Departamento"
                                    name="departamento"
                                    value={values.departamento}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                        let combo = document.getElementById("departamento");
                                        let selected = combo?.options[combo.selectedIndex].text;
                                        setNombreDepartamento(selected);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item,index) => {
                                            return (
                                                <option key={item.nombre+index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Municipio"
                                    name="municipio"
                                    value={values.municipio}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir municipio"
                                    list={
                                        municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                                            return (
                                                <option key={item.nombre + "mn" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Institución"
                                    name="gestion_institucion"
                                    value={values.gestion_institucion}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir institución"
                                    list={
                                        instituciones?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="detalle_lugar"
                                    id="detalle_lugar"
                                    value={values.detalle_lugar}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Detalle Lugar"
                                />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                    agregar={true}
                    handleAgregar={handleAgregar}
                    agregar1="Asignar Recurso"
                    agregarSegundo={true}
                    handleSegundoAgregar={handleAgregarNombramiento}
                    agregar2="Nombramiento"
                    pagination={true}
                    fetchPage={setUrlFech}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de seguimiento"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleAccion detalle={accionesDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Acciones Recursos de ${acciones.seguimiento}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionAccionesRecursos accion={acciones} />
                        }
                    />
                )}
                {agregarNombramiento && (
                    <ModalAmplio
                        title={`Gestión de Nombramiento de ${acciones.seguimiento}`}
                        open={agregarNombramiento}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarNombramiento(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionNombramiento accion={acciones} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteAcciones(idAcciones, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
