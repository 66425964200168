import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { Editar } from "./editar";
import { putFormulario } from "../services";
import { useParams } from "react-router-dom";
import { getFormulario } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import ModalGenerico from "../../../../components/common/Modal";
import FormEliminar from "../../common/FormEliminar";
import * as Yup from "yup";

// Validation with Yup
const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(100, "máximo 100 caracteres"),
    descripcion: Yup.string()
        .required("descripción es requerida")
        .max(100, "máximo 100 caracteres"),
    idioma: Yup.string().required("Idioma es requerido")
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const FormEditar = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { id } = useParams();
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [formulario, setFormulario] = useState({});
    const [loading, setLoanding] = useState(true);
    const [valorAutorizado, setValorAutorizado] = useState(false);
    const [valorEstado, setValorEstado] = useState(false);

    const customChange = (values) => {
        if (values.autorizado === true && values.valorAutorizado === undefined) {
            setValorAutorizado(true)
        } else {
            setValorAutorizado(false)
        }
        if (values.estado === false && values.valorEstado === undefined) {
            setValorEstado(true)
        } else {
            setValorEstado(false)
        }
    };

    useEffect(() => {
        const datos = async () => {
            await getFormulario(id, token).then((data) => {
                setFormulario(data);
                setLoanding(false);
            });
        };
        datos();
    }, [id, token]);

    return loading ? (
        <div></div>
    ) : (
        <Formik
            initialValues={{
                id: id,
                nombre: formulario?.nombre || "",
                descripcion: formulario?.descripcion || "",
                estado: formulario?.estado || true,
                por_servicio: formulario?.por_servicio || false,
                por_atencion: formulario?.por_atencion || false,
                fecha_creacion: formulario?.fecha_creacion || "",
                fecha_autoriza: formulario?.fecha_autoriza || "",
                fecha_inactivacion: formulario?.fecha_inactivacion || "",
                encuesta: true,
                profesional: true,
                usuario_victima: false,
                por_area: false,
                por_orientacion: false,
                autorizado: formulario?.autorizado || false,
                servicio: formulario?.servicio?.id || "",
                atencion: formulario?.atencion?.id || "",
                idioma: formulario?.idioma?.id || "",
                idioma_maya: formulario?.idioma_maya?.id || "",
                usuario_creacion: formulario?.usuario_creacion?.id || "",
                usuario_autoriza: formulario?.usuario_autoriza?.id || "",
                usuario_inactivacion: formulario?.usuario_inactivacion?.id || "",
                area: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putFormulario(id, values, token).then((data) => {
                    if (data?.status === 200) {
                        setSuccess(true);
                        setError(false);
                        setMensaje('Registro actualizado con éxito...!');
                        toast.success('Registro actualizado con éxito...!');
                    } else {
                        setError(true);
                        setSuccess(false);
                    }
                }).catch(function (error) {
                    if (error.response.status === 400 || error.response.status === 401) {
                        setError(true);
                        setSuccess(false);
                        setMensaje(error.response.data.message);
                        toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                        setError(true);
                        setSuccess(false);
                        setMensaje(error.response.data.message);
                        toast.error("Error interno comuniquese con el administrador")
                    }
                });
            }}
        >
            {(props) => (
                <div>
                    <Editar
                        {...props}
                        customSwitchChange={customSwitchChange}
                        customChange={customChange}
                        hasError={hasError}
                        success={success}
                        mensaje={mensaje}
                    />
                    {valorAutorizado && (
                        <ModalGenerico
                            title={"Una vez autorizado no podrá editarlo nuevamente, ¿Desea continuar?"}
                            open={valorAutorizado}
                            closeModal={() => { }}
                            cerrar={() => {
                                setValorAutorizado(false)
                            }}
                            children={
                                <FormEliminar
                                    aceptar={() => {
                                        props.values.autorizado = true;
                                        props.values.valorAutorizado = true;
                                        setValorAutorizado(false)
                                    }}
                                    cancelar={() => {
                                        props.values.autorizado = false;
                                        props.values.valorAutorizado = false;
                                        setValorAutorizado(false);
                                    }}
                                />

                            }
                        />
                    )}
                    {valorEstado && (
                        <ModalGenerico
                            title={"Una vez inactivado no podrá activarlo nuevamente, ¿Desea continuar?"}
                            open={valorEstado}
                            closeModal={() => { }}
                            cerrar={() => {
                                setValorEstado(false)
                            }}
                            children={
                                <FormEliminar
                                    aceptar={() => {
                                        props.values.estado = false;
                                        props.values.valorEstado = false;
                                        setValorEstado(false)
                                    }}
                                    cancelar={() => {
                                        props.values.estado = true;
                                        props.values.valorEstado = true;
                                        setValorEstado(false);
                                    }}
                                />

                            }
                        />
                    )}
                </div>

            )}
        </Formik>
    );
};
