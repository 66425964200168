import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getAccionVictimologica, getRecursos } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchAccion = (token) => {
        const [state, setState] = useState({
            accion: [],
        });

        useEffect(() => {
            getAccionVictimologica(token).then((accion) => {
                setState({
                    accion: accion,
                });
            });
        }, [token]);

        return state;
    };

    const useFetchSeguimiento = (token) => {
        const [state, setState] = useState({
            seguimiento: [],
        });

        useEffect(() => {
            getRecursos(token).then((seguimiento) => {
                setState({
                    seguimiento: seguimiento,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        accion: "",
        seguimiento: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const { accion } = useFetchAccion(token);
    const { seguimiento } = useFetchSeguimiento(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Acción Victimológica" />
                    <div className="mt-1">
                        <select
                            id="accion"
                            name="accion"
                            value={state.accion}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir acción victimológica</option>
                            {accion?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Seguimiento Legal" />
                    <div className="mt-1">
                        <select
                            id="seguimiento"
                            name="seguimiento"
                            value={state.seguimiento}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir seguimiento legal</option>
                            {seguimiento?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `accion_recurso=${state.accion}&recurso=${state.seguimiento}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
