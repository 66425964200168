import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postGrupo, putGrupo, getServicios } from "./services";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';
import Toggle from "../../../components/common/Toggle";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(100, "Máximo 100 caracteres")
});

const useFetchServicios = (token) => {
    const [state, setState] = useState({
        servicios: [],
    });

    useEffect(() => {
        getServicios(token).then((servicios) => {
            setState({
                servicios: servicios,
            });
        });
    }, [token]);

    return state;
};

export const FormGrupo = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
    const { servicios } = useFetchServicios(token);
    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    descripcion: props.editar ? props.value.descripcion : "",
                    estado: props.editar ? props.value.estado : true,
                    es_multidisciplinario: props.editar ? props.value.es_multidisciplinario : false,
                    es_mesa_trabajo: props.editar ? props.value.es_mesa_trabajo : false,
                    es_grupo_autorizacion: props.editar ? props.value.es_grupo_autorizacion : false,
                    servicio: props.editar ? props?.value?.servicio?.id : "",
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putGrupo(props.value.id, values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro actualizado con éxito...!')
                            }
                        });
                    } else {
                        await postGrupo(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro creado con éxito...!')
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Nombre*"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="¿Es Multidisciplinario?" />
                                <Toggle enabled={values.es_multidisciplinario} name="es_multidisciplinario" />
                            </div>
                            <div className="col-span-6 mt-5">
                                <InputSiavi
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    value={values.descripcion}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Descripción"
                                />
                            </div>
                            {!values.es_multidisciplinario && (
                                <div className="col-span-6 mt-5">
                                    <Label text="Servicio" />
                                    <div className="mt-1">
                                        <select
                                            id="servicio"
                                            name="servicio"
                                            autoComplete="off"
                                            value={values.servicio}
                                            onChange={handleChange}
                                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                        >
                                            <option value="">Elegir servicio</option>
                                            {servicios?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="col-span-6 mt-5">
                                <Label text="¿Es Mesa de Trabajo?" />
                                <Toggle enabled={values.es_mesa_trabajo} name="es_mesa_trabajo" />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="¿Es Grupo Autorización?" />
                                <Toggle enabled={values.es_grupo_autorizacion} name="es_grupo_autorizacion" />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
