import {PaperClipIcon } from '@heroicons/react/solid'

export default function ButtonFiles(props) {
    return (
        <button
          type={props.type}
          className={`h-8 justify-center inline-flex items-center px-4 py-1 border border-transparent shadow-xl text-sm font-medium 
          rounded-md text-white bg-maivi-primaryBlue hover:bg-maivi-secondaryLightBlue focus:outline-none 
          focus:ring-2 focus:ring-offset-2 focus:bg-maivi-primaryBlue ${props.className}`}
          onClick={props.onClick}
        >
          {props.text}
          {(props.icon) ? props.icon :<PaperClipIcon className="h-5"/>}
        </button>

    )
  }