import { React, useEffect, useState } from "react";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { getMedidasVictimologicas, postMedidaVictimologica } from "../services";
import { SelectSiavi } from "../../../../../components/common/Select";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
  servicio: Yup.string().required("servicio es requerido"),
  medida_victimologica: Yup.string().required(
    "medida victimológica es requerida"
  ),
  fecha: Yup.string().required("fecha es requerida"),
  especificaciones: Yup.string().required("especificaciones son requeridas"),
});

export const MedidaVictimologica = ({ token, servicios, persona,closeModal,setDate }) => {
  const [state, setState] = useState({
    medidasVictimologicas: [],
  });

  useEffect(() => {
    getMedidasVictimologicas(token).then((data) => {
      setState({ ...state, medidasVictimologicas: data?.results });
    });
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          servicio: "",
          medida_victimologica: "",
          fecha: moment().format("YYYY-MM-DD"),
          especificaciones: "",
          caso_persona: persona.id,
          status: 1,
          estado: true,
          expediente: null
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          const expediente = servicios.filter(x => x.servicio.id === Number(values.servicio))
          values.expediente = expediente[0].id;
          await postMedidaVictimologica(values, token).then((data) => {
            toast.success("Registro creado con éxito...!");
            closeModal(false);
            setDate(new Date)
          }).catch(function (error) {
            if (error.response.status === 400 || error.response.status === 401) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5">
              <div className="col-span-12 mt-3">
                <SelectSiavi
                  label="Servicio Interno"
                  valueInit=""
                  selectInit="Elegir servicio"
                  value={values.servicio}
                  onChange={handleChange}
                  name="servicio"
                  validacion={true}
                  list={servicios?.filter((x) => x.atencion_victimologica === true && x?.caso_persona.persona.id === persona.persona.id)
                    .map((item) => {
                      return (
                        <option key={item.servicio.nombre} value={item.servicio.id}>
                          {item.servicio.nombre}
                        </option>
                      );
                    })}
                />
              </div>

              {values.servicio && (
                <div className="col-span-12 mt-3">
                  <SelectSiavi
                    label="Medida Victimológica"
                    valueInit=""
                    selectInit="Elegir medida victimológica"
                    value={values.medida_victimologica}
                    onChange={handleChange}
                    name="medida_victimologica"
                    validacion={true}
                    list={state.medidasVictimologicas
                      ?.filter(
                        (x) =>
                          x?.servicio_propio?.id === Number(values.servicio)
                      )
                      .map((item) => {
                        return (
                          <option key={item.nombre} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })}
                  />
                </div>
              )}

              {values.medida_victimologica && (
                <div className="col-span-6 mt-4">
                  <InputSiavi
                    type="date"
                    name="fecha"
                    id="fecha"
                    maximo={moment().format("YYYY-MM-DD")}
                    value={values.fecha}
                    onChange={handleChange}
                    validacion={true}
                    label="Fecha"
                  />
                </div>
              )}
              {values.medida_victimologica && (
                <div className="col-span-6 mt-3">
                  <TextAreaSiavi
                    label="Especificaciones"
                    name="especificaciones"
                    id="descripcion"
                    value={values.especificaciones}
                    onChange={handleChange}
                    validacion={true}
                    rows={5}
                    cols={5}
                  />
                </div>
              )}

              <div className="py-3 text-right">
                <ButtonSiavi
                  cargando={isSubmitting}
                  type="submit"
                  disabled={isSubmitting}
                  text="Guardar"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
