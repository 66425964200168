import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { getCheckStatus } from "../helpers/auth";
import { store } from "../app/redux/store/store";
import { checkStatus } from "../app/redux/actions/auth/checkStatus";
import { useSelector } from "react-redux";
import { saveLogin } from "./redux/actions/login";
import { ChangeSearch } from "../app/redux/actions/search";
import { ChangePermisos } from "../app/redux/actions/permisos";
import { getPermisos } from "../helpers/utils";

const PrivateRoute = ({ component: Component, ...opciones }) => {
  const sesionState = useSelector((state) => state?.statusReducer?.data);
  const isOk = sesionState?.status === true ? true : false;
  const token = useSelector((state) => state?.loginReducer?.data?.token);
  const loginData = useSelector((state) => state?.loginReducer);
  
  const authCheck = async (token) => {
    let estado = await getCheckStatus(token);
    store.dispatch(checkStatus(estado));
    let permisos = await getPermisos(loginData?.data?.usuario?.user?.rol_vista);
    
    store.dispatch(
        ChangeSearch({
          fetch: false,
          clean: false,
          min: 3,
          value: "",
        })
      );

    if (estado?.status === false) {
      store.dispatch(saveLogin({}));
      window.location = "/";
    } else {

      store.dispatch(ChangePermisos(permisos?.data)); 

      store.dispatch(saveLogin({ ...loginData.data, token: estado.newToken }));
    }
  };

  useEffect(() => {
    authCheck(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Component]);

  return (
    <Route {...opciones}>{isOk ? <Component /> : <Redirect to="/" />}</Route>
  );
};

export default PrivateRoute;
