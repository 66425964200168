/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { audiencias, exportarData, getAudiencias } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { clases } from "../../../helpers/clases";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { FormDetalle } from "./Detalle";
import { Gestionar } from "./gestionar";
import TableGeneric from "../../../components/common/TableGeneric";
import ModalGenerico from "../../../components/common/Modal";
import ModalBig from "../../../components/common/ModalTwo";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import ExportButton from "../../../components/common/ExportButton";

const tableColumns = [
  // { title: "Tipo acción", value: "accion_seguimiento.nombre", tipo: "text" },
  // { title: "Fecha planificada inicial", value: "fecha_hora_planificada_inicia", tipo: "text" },
  // { title: "Fecha completada", value: "fecha_completado", tipo: "text" },
  // { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  // {
  //   title: "Nombre",
  //   value: null,
  //   values:[
  //     "persona.primer_nombre",
  //     "persona.segundo_nombre",
  //     "persona.primer_apellido",
  //     "persona.segundo_apellido"
  //   ],
  //   tipo: "text"
  // },
  // { title: "Servicio", value: "accion_seguimiento.servicio.nombre", tipo: "text" },
  // {
  //   title:"Profesional realiza",
  //   value:null,
  //   values:[
  //     "profesional_realiza.first_name",
  //     "profesional_realiza.last_name"
  //   ],
  //   tipo:"text"
  // }

  { title: "Fecha planficada", value: "fecha_hora_planificada_inicia", tipo: "datetime", ordenar: "fecha_hora_planificada_inicia" },
  { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  {
    title: "Nombre Querellante",
    value: null,
    values: [
      "querellante.primer_nombre",
      "querellante.segundo_nombre",
      "querellante.primer_apellido",
      "querellante.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Teléfono", value: "expediente.caso_persona.persona.telefono.numero_telefono", tipo: "text" },
  { title: "Audiencia", value: "tipo_audiencia.nombre", tipo: "text" },
  { title: "Juzgado", value: "gestion_institucion.nombre_fiscalia", tipo: "text" },
  {
    title: "Abogado",
    value: null,
    values: [
      "profesional_asignado.first_name",
      "profesional_asignado.last_name"
    ],
    tipo: "text",
    ordenar: "profesional_asignado"
  }
];

export const TableAudiencias = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [order, setOrder] = useState("-fecha_hora_planificada_inicia&expediente");
  const [endpoint, setEndpoint] = useState("accionesseguimientoscaso");
  const [detalle, setDetalle] = useState(false);
  const [registro, setRegistro] = useState({});
  const [gestionar, setGestionar] = useState(false);
  const permiso = "accionesseguimientoscaso";




  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "audienciasprogramadas";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, order, token).then(async (data) => {
      const fileName = 'audiencias-planificadas';
      let dataExport = data;
      let columns = [
        { name: 'Fecha planificada inicia', value: 'fecha_hora_planificada_inicia' },
        { name: 'Tipo de audiencia', value: 'tipo_audiencia' },
        { name: 'Gestion Institucion', value: 'gestion_institucion' },
        { name: 'Expediente', value: 'expediente' },
        { name: 'Querellante', value: 'querellante' },
        { name: 'Completada', value: 'completada' },
        { name: 'Profesional Asignado', value: 'profesional_asignado' }
      ];

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  useEffect(() => {
    setTableLoading(true);
    audiencias(idUser, urlFetch, endpoint, filters, order, token).then((data) => {
      data?.results?.forEach(element => {
        if (element.es_vencida) {
          element.fondo = 'bg-red-50'
        } else if (element.es_por_vencer) {
          element.fondo = 'bg-yellow-50'
        } else if (element.completada) {
          element.fondo = ''
        }
      });
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, filters, sede, order, endpoint]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getAudiencias(idUser, urlFetch, search, endpoint, filters, order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, filters, sede, order, endpoint]);

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: `Mis Audiencias`, href: "#", current: true },
  ];

  const handleVer = (registro) => {
    setRegistro(registro);
    setDetalle(true);
  }

  const opcion = (registro) => {
    return (
      <button
        type="button"
        onClick={() => {
          setRegistro(registro);
          setGestionar(true);
        }}
        className={clases.addIcon}
      >
        <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
        <span className="tooltiptext">Gestionar</span>
      </button>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        {!props.ocultar &&
          <BreadCrumb navegacion={pages} />
        }
        <div>
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
              setEndpoint("accionesseguimientoscaso");
            }}
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
          />
          {(CheckPermiso('Exportar') === true) &&
            <ExportButton
              handlePdf={() => {
                exportData('pdf');
              }}
              show={'ambos'}
              handleExcel={() => {
                exportData('xls');
              }}
            />
          }
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <TableGeneric
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={true}
            order={order}
            setOrder={setOrder}
            handleVer={handleVer}
            //addAccion={false}
            addAccion={(CheckPermiso('gestionaraudiencias') === true)}
            opcion1={opcion}
          />
          {tableLoading && ( 
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
      {detalle && (
        <ModalBig
          title={`Detalle`}
          open={detalle}
          closeModal={() => { }}
          cerrar={() => {
            setDetalle(false);
          }}
          children={
            <FormDetalle registro={registro} token={token} />
          }
        />
      )}
      {gestionar && (
        <ModalGenerico
          title={`Gestionar`}
          open={gestionar}
          closeModal={() => { }}
          cerrar={() => {
            setGestionar(false);
          }}
          children={
            <Gestionar
              registro={registro}
              setGestionar={setGestionar}
            />
          }
        />
      )}
    </div>
  );
};
