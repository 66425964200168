import { useState, useEffect } from "react";
import { SelectSiavi } from "../../../../../components/common/Select";
import {
  getDetalleReparacionDigna,
  getRazonesNoSolicitud,
  putDetalleReparacionDigna,
  getReparacionDignaSindicadoDelito,
  getSindicados,
} from "./services";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";
import { InputSiavi } from "../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import Alert from "../../../../../components/common/Alert";
import Toggle from "../../../../../components/common/Toggle";
import toast from "react-hot-toast";
import Spinner from "../../../../../components/common/Spinner";
import ModalGenerico from "../../../../../components/common/Modal";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import moment from "moment";
import TableReparacionDigna from "../../../../../components/common/TableReparacionDigna";
import NumberFormat from "react-number-format";

const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";
const classAcciones = `hover:bg-maivi-primaryLightBlue inline-flex items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`;
const inactivarButton = `hover:bg-red-300 inline-flex items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-red-500 tooltip`;

export const DetalleRD = ({
  caso,
  token,
  reparacionesDignas,
  usuario,
  showModal,
}) => {
  const [idReparacionDigna, setIdReparacionDigna] = useState(null);
  const [listado, setListado] = useState([]);
  const [razonesNoSolicitud, setRazonesNoSolicitud] = useState([]);
  const [sindicados, setSindicados] = useState([]);
  const [position, setPosition] = useState(false);

  const [state, setState] = useState({
    showAlert: false,
    cargando: false,
    cargandoTable: false,
    cargandoGuardarTodo: false,
    cargandoRegistro: false,
    cargandoInactivar: false,
    mostrarAlertaSindicado: false,
    mostrarTablaDetalle: true,
    guardarCambios: false,
    mensaje: "",
    buscarInstitucion: "",
    inactivar: false,
    registroSeleccionado: {},
    hasCambios: false,
    validacionMedidaSolicitada: false,
    mensajeMedidaSolicitada: "",
    nameHeaders: [
      "Persona",
      "Medida de reparación digna",
      "Medida Solicitada",
      "Razón no solicitud",
      "Medida otorgada",
      "Especificaciones",
      "Monto",
      "Ejecuta institución",
      "Institución",
      "Ejecuta sindicado",
      "Sindicado",
      "Acciones",
    ],
  });

  useEffect(async () => {
    if (idReparacionDigna !== null) {
      setState({ ...state, cargandoTable: true });

      await getReparacionDignaSindicadoDelito(token, idReparacionDigna).then(
        async (dataSin) => {
          if (dataSin.count === 0) {
            setState({
              ...state,
              mostrarAlertaSindicado: true,
              mostrarTablaDetalle: false,
            });
          } else {
            await getDetalleReparacionDigna(token, idReparacionDigna).then(
              async (data) => {
                for (const item of data.results) {
                  item.razon_no_solicitud_id = item?.razon_no_solicitud?.id;
                  item.institucion_cumplimiento_id = item?.institucion_cumplimiento?.id;
                  item.sindicado_cumplimiento_id = item?.sindicado_cumplimiento?.id;
                }

                setListado(data.results);
                setState({
                  ...state,
                  cargandoTable: false,
                  mostrarAlertaSindicado: false,
                  mostrarTablaDetalle: true,
                });

                let list = [];

                await getSindicados(token, caso.id).then((rs) => {
                  for (const item of dataSin.results) {
                    for (const row of rs.results) {
                      if (
                        item?.exp_sindicado_delito?.expediente_sindicado
                          ?.sindicado?.id === row?.sindicado?.id
                      ) {
                        if (list.length === 0) {
                          list.push(row);
                        }
                        for (const l of list) {
                          if (l.sindicado.id !== row.sindicado.id) {
                            list.push(row);
                          }
                        }
                      }
                    }
                  }

                  setSindicados(list);
                });
              }
            );
          }
        }
      );
    }
  }, [idReparacionDigna]);

  useEffect(() => {
    getRazonesNoSolicitud(token).then((data) => {
      setRazonesNoSolicitud(data.results);
    });
  }, []);

  const guardarTodo = async (listado) => {
    for (const item of listado) {
      setState({ ...state, cargandoGuardarTodo: true });

      if (
        item.reparacion_digna?.monto_requerido &&
        item.medida_solicitada &&
        item.medida_otorgada === true &&
        !item.monto
      ) {
        toast.error("Monto es requerido");
        setState({ ...state, cargandoGuardarTodo: false });
      } else if (item.ejecucion_institucion && !item.institucion) {
        toast.error("Institución es requerida");
        setState({ ...state, cargandoGuardarTodo: false });
      } else if (item.ejecuta_sindicado && !item.sindicado_cumplimiento_id) {
        toast.error("Sindicado es requerido");
        setState({ ...state, cargandoGuardarTodo: false });
      } else {
        setState({ ...state, cargandoGuardarTodo: true });
        let spin = document.getElementById("sp-" + item.id);
        let btn = document.getElementById("btn-acciones-" + item.id);

        spin.classList.remove("invisible");
        spin.classList.add("ml-10");
        btn.classList.add("invisible");

        let body = {
          id: item.id,
          medida_solicitada: item.medida_solicitada,
          razon_no_solicitud: item.razon_no_solicitud_id || null,
          monto: item?.monto,
          medida_otorgada: item.medida_otorgada,
          ejecucion_institucion: item.ejecucion_institucion,
          institucion_cumplimiento: item.institucion_cumplimiento_id || null,
          ejecuta_sindicado: item.ejecuta_sindicado,
          sindicado_cumplimiento: item.ejecuta_sindicado
            ? item.sindicado_cumplimiento_id
            : null,
          caso: item.caso.id,
          expediente_reparacion_digna: idReparacionDigna,
          reparacion_digna: item.reparacion_digna.id,
          especificaciones: item.especificaciones,
          institucion: item.institucion,
        };

        await putDetalleReparacionDigna(body, token)
          .then(() => {
            toast.success("Registro actualizado con éxito.");
            spin.classList.add("invisible");
            spin.classList.remove("ml-10");
            btn.classList.remove("invisible");
          })
          .catch(function (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }

            spin.classList.add("invisible");
            spin.classList.remove("ml-10");
            btn.classList.remove("invisible");
          });
      }
    }

    setState({ ...state, cargandoGuardarTodo: false, guardarCambios: false });
    showModal(false);
  };

  const guardar = async (values) => {
    if (
      values.reparacion_digna?.monto_requerido &&
      values.medida_solicitada &&
      values.medida_otorgada === true &&
      !values.monto
    ) {
      toast.error("Monto es requerido");
    } else if (values.ejecucion_institucion && !values.institucion) {
      toast.error("Institución es requerida");
    } else if (values.ejecuta_sindicado && !values.sindicado_cumplimiento_id) {
      toast.error("Sindicado es requerido");
    } else {
      let spin = document.getElementById("sp-" + values.id);
      let btn = document.getElementById("btn-acciones-" + values.id);

      spin.classList.remove("invisible");
      spin.classList.add("ml-10");
      btn.classList.add("invisible");

      let body = {
        id: values.id,
        medida_solicitada: values.medida_solicitada,
        razon_no_solicitud: values.razon_no_solicitud_id || null,
        monto: values?.monto,
        medida_otorgada: values.medida_otorgada,
        ejecucion_institucion: values.ejecucion_institucion,
        institucion_cumplimiento: values.institucion_cumplimiento_id || null,
        ejecuta_sindicado: values.ejecuta_sindicado,
        sindicado_cumplimiento: values.ejecuta_sindicado
          ? values.sindicado_cumplimiento_id
          : null,
        caso: values.caso.id,
        expediente_reparacion_digna: idReparacionDigna,
        reparacion_digna: values.reparacion_digna.id,
        especificaciones: values.especificaciones,
        institucion: values.institucion,
      };

      await putDetalleReparacionDigna(body, token)
        .then(() => {
          toast.success("Registro actualizado con éxito.");
          spin.classList.add("invisible");
          spin.classList.remove("ml-10");
          btn.classList.remove("invisible");
        })
        .catch(function (error) {
          if (error.response.status === 400 || error.response.status === 401) {
            toast.error(error.response.data.message);
          } else if (error.response.status >= 500) {
            toast.error("Error interno comuniquese con el administrador");
          }

          spin.classList.add("invisible");
          spin.classList.remove("ml-10");
          btn.classList.remove("invisible");
        });
    }
  };

  const inactivarRegistro = async (values) => {
    setState({
      ...state,
      cargandoInactivar: true,
    });

    let body = {
      id: values.id,
      medida_solicitada: values.medida_solicitada,
      razon_no_solicitud: values.razon_no_solicitud_id || null,
      monto: values?.monto,
      medida_otorgada: values.medida_otorgada,
      ejecucion_institucion: values.ejecucion_institucion,
      institucion_cumplimiento: values.institucion_cumplimiento_id || null,
      ejecuta_sindicado: values.ejecuta_sindicado,
      sindicado_cumplimiento: values.sindicado_cumplimiento_id || null,
      caso: values.caso.id,
      expediente_reparacion_digna: idReparacionDigna,
      reparacion_digna: values.reparacion_digna.id,
      usuario_inactivacion: usuario,
      estado: false,
      fecha_inactivacion: moment(),
    };

    await putDetalleReparacionDigna(body, token)
      .then(() => {
        toast.success("Registro inactivado con éxito.");
        listado[position].estado = false;
        setListado(listado);
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status >= 500) {
          toast.error("Error interno comuniquese con el administrador");
        }
      });

    setState({
      ...state,
      cargandoInactivar: false,
      inactivar: false,
    });
  };

  return (
    <div className="grid grid-cols-12 gap-3 mt-2">
      <div className="col-span-12 flex justify-between">
        <span></span>
        <XIcon
          className="h-6 w-6 text-red-500 hover:text-white -mt-20 hover:bg-red-500 rounded-md cursor-pointer"
          onClick={() => {
            setState({
              ...state,
              validacionMedidaSolicitada: false,
              guardarCambios: true
            })
          }}
        />
      </div>
      <div className="col-span-12 border rounded-md p-2">
        <div className="w-full mb-1 flex items-end">
          <div className="w-72">
            <SelectSiavi
              label="Fecha reparación digna"
              initValue=""
              selectInit="Elegir reparación digna"
              name="reparacion_digna"
              className="w-72 mb-2"
              onChange={(e) => {
                setIdReparacionDigna(Number(e.target.value));
                setState({
                  ...state,
                  showAlert: false,
                });
                setState({ ...state, hasCambios: false });
              }}
              list={reparacionesDignas?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {moment(item.fecha).format("DD-MM-YYYY")}
                  </option>
                );
              })}
            />
          </div>
        </div>
        {state.showAlert && (
          <div className="mb-2">
            <Alert type="error" mensaje={state.mensaje} />
          </div>
        )}
        {state.mostrarAlertaSindicado && (
          <Alert
            type="warning"
            mensaje="Primero debe de registrar al menos un síndicado a la reparación digna"
          />
        )}

        {state.mostrarTablaDetalle && (
          <div className="max-h-96 w-full overflow-x-hidden overflow-y-auto">
            <TableReparacionDigna
              cargando={state.cargandoTable}
              headers={state.nameHeaders}
              body={listado?.map((row, index) => (
                <tr
                  key={index}
                  className={` ${row.estado === false ? "bg-red-50" : ""} ${
                    row.estado === false
                      ? "hover:bg-red-100"
                      : "hover:bg-maivi-gray-5"
                  } cursor-pointer`}
                >
                  <td className={tdClass}>{row.persona_c}</td>
                  <td className={tdClass}>{row?.reparacion_digna?.nombre}</td>
                  <td className={tdClass}>
                    <Toggle
                      enabled={row?.medida_solicitada}
                      checked={row?.medida_solicitada}
                      name={index + `medida_solicitada`}
                      onChange={() => {
                        listado[index].medida_solicitada =
                          !row?.medida_solicitada;
                        setListado(listado);
                        setState({ ...state, hasCambios: true });
                      }}
                    />
                    <label className="text-sm font-semibold ml-2">
                      {row.medida_solicitada ? "SI" : "NO"}
                    </label>
                  </td>
                  <td className={tdClass}>
                    {row.medida_solicitada === false && (
                      <SelectSiavi
                        initValue=""
                        selectInit="Elegir razón"
                        name="razon_no_solicitud"
                        className="w-72 mb-2"
                        value={row?.razon_no_solicitud?.id}
                        onChange={(e) => {
                          listado[index].razon_no_solicitud = Number(e.target.value);
                          listado[index].razon_no_solicitud_id = Number(
                            e.target.value
                          );

                          setListado(listado);
                          setState({ ...state, hasCambios: true });
                        }}
                        list={razonesNoSolicitud?.map((item) => {
                          return (
                            <option key={item.nombre} value={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })}
                      />
                    )}
                  </td>
                  <td className={tdClass}>
                      <>
                        <Toggle
                          enabled={row?.medida_otorgada}
                          checked={row?.medida_otorgada}
                          name={index + `medida_otorgada`}
                          onChange={() => {
                            listado[index].medida_otorgada =
                              !row?.medida_otorgada;
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                        />
                        <label className="text-sm font-semibold ml-2">
                          {row.medida_otorgada ? "SI" : "NO"}
                        </label>
                      </>
                  
                  </td>
                  <td className={tdClass}>
                    {row.medida_otorgada === true && (
                      <TextAreaSiavi
                        rows={2}
                        cols={2}
                        name="especificaciones"
                        type="text"
                        value={row?.especificaciones}
                        onChange={(e) => {
                          listado[index].especificaciones = e.target.value;
                          setListado(listado);
                          setState({ ...state, hasCambios: true });
                        }}
                      />
                    )}
                  </td>
                  <td className={tdClass}>
                    {row.reparacion_digna?.monto_requerido &&
                      row.medida_solicitada &&
                      row.medida_otorgada === true && (
                        <NumberFormat
                          name="monto"
                          thousandSeparator={true}
                          prefix={"Q"}
                          value={row.monto}
                          onChange={(e) => {
                            listado[index].monto = Number(
                              e.target.value
                                .replace("Q", "")
                                .replace(",", "")
                                .replace(",", "")
                            );
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                          allowNegative={false}
                          className="mt-1 h-8 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
                        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
                        sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
                        />
                      )}
                  </td>
                  <td className={tdClass}>
                    {row.medida_otorgada === true && (
                      <>
                        <Toggle
                          enabled={row?.ejecucion_institucion}
                          checked={row?.ejecucion_institucion}
                          name={index + `ejecuta_institucion`}
                          onChange={() => {
                            listado[index].ejecucion_institucion =
                              !row?.ejecucion_institucion;
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                        />
                        <label className="text-sm font-semibold ml-2">
                          {row.ejecucion_institucion ? "SI" : "NO"}
                        </label>
                      </>
                    )}
                  </td>
                  <td className={tdClass}>
                    {row?.ejecucion_institucion && row.medida_otorgada === true && (
                        <InputSiavi
                          value={row.institucion}
                          type="text"
                          onChange={(e) => {
                            listado[index].institucion = e.target.value;
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                        />
                      )}
                  </td>
                  <td className={tdClass}>
                    {row.medida_otorgada === true && (
                      <>
                        <Toggle
                          enabled={row?.ejecuta_sindicado}
                          checked={row?.ejecuta_sindicado}
                          name={index + `ejecuta_sindicado`}
                          onChange={() => {
                            listado[index].ejecuta_sindicado =
                              !row?.ejecuta_sindicado;
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                        />
                        <label className="text-sm font-semibold ml-2">
                          {row.ejecuta_sindicado ? "SI" : "NO"}
                        </label>
                      </>
                    )}
                  </td>
                  <td className={tdClass}>
                    {row?.ejecuta_sindicado && row.medida_otorgada === true && (
                        <SelectSiavi
                          initValue=""
                          selectInit="Sindicado"
                          value={row.sindicado_cumplimiento?.id}
                          name="sindicado_cumplimiento"
                          className=""
                          onChange={(e) => {
                            listado[index].sindicado_cumplimiento_id = Number(
                              e.target.value
                            );
                            setListado(listado);
                            setState({ ...state, hasCambios: true });
                          }}
                          list={sindicados?.map((item, index) => {
                            return (
                              <option
                                key={item.sindicado?.primer_nombre + index}
                                value={item?.id}
                              >
                                {item?.sindicado?.primer_nombre +
                                  " " +
                                  item?.sindicado.primer_apellido}
                              </option>
                            );
                          })}
                        />
                      )}
                  </td>
                  <td className={tdClass}>
                    <div className="flex flex-row">
                      <span id={"sp-" + row.id} className="invisible mt-1">
                        <Spinner size={25} />
                      </span>
                      <div id={"btn-acciones-" + row.id} className="flex">
                        <button
                          id={"btn-" + row.id}
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            if (
                              row.medida_solicitada === false &&
                              row.razon_no_solicitud_id === undefined
                            ) {
                              toast.error(
                                "Debe de elegir una razón de no solicitud"
                              );
                            } else {
                              guardar(row);
                            }
                          }}
                        >
                          <CheckCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          <span className="tooltiptext">Guardar</span>
                        </button>
                        <button
                          type="button"
                          className={inactivarButton}
                          onClick={() => {
                            setState({
                              ...state,
                              registroSeleccionado: row,
                              inactivar: true,
                            });

                            setPosition(index);
                          }}
                        >
                          <XIcon className="h-6 w-6 text-red-500 hover:text-white" />
                          <span className="tooltiptext">Inactivar</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
        )}

        {state.inactivar && (
          <ModalGenerico
            title={`Esta seguro que quiere inactivar el registro ${state.registroSeleccionado?.reparacion_digna?.nombre} perteneciente a ${state.registroSeleccionado.persona_c}`}
            open={state.inactivar}
            closeModal={() => {}}
            cerrar={() => {
              setState({ ...state, inactivar: false });
            }}
            children={
              <BotonesConfirmar
                cancelar={() => {
                  setState({ ...state, inactivar: false });
                }}
                aceptar={() => {
                  inactivarRegistro(state.registroSeleccionado);
                }}
                isLoading={state.cargandoInactivar}
              />
            }
          />
        )}

        {state.guardarCambios && (
          <ModalGenerico
            title="¿Desea guardar los cambios realizados?"
            open={state.guardarCambios}
            closeModal={() => {}}
            cerrar={() => {
              setState({ ...state, guardarCambios: false });
            }}
            children={
              <div>
                {state.validacionMedidaSolicitada ? (
                  <p className="text-maivi-primaryFont font-bold">
                    {state.mensajeMedidaSolicitada}
                  </p>
                ) : (
                  <BotonesConfirmar
                    cancelar={() => {
                      setState({ ...state, guardarCambios: false });
                      showModal(false);
                    }}
                    aceptar={async () => {
                      let contador = 0;
                          for (const item of listado) {
                            if (
                              item.medida_solicitada === false &&
                              (item.razon_no_solicitud_id === undefined || item.razon_no_solicitud_id === null 
                                || item.razon_no_solicitud_id === '' || isNaN(item.razon_no_solicitud_id))
                            ) {
                              contador++;
                            }
                          }

                          if (contador > 0) {
                            setState({
                              ...state,
                              guardarCambios: true,
                              validacionMedidaSolicitada: contador > 0 ? true : false,
                              hasCambios: false,
                              mensajeMedidaSolicitada:
                                contador > 0
                                  ? `${contador > 1 ? "Existen" : "Existe"} ${contador} ${
                                      contador > 1 ? "registros" : "registro"
                                    } que no ${
                                      contador > 1 ? "tienen" : "tiene"
                                    } razón no solicitud, primero debe de completar los campos para poder guardar y salir.`
                                  : "",
                            });
                          } else {
                            await guardarTodo(listado);
                            showModal(false);
                          }



                    }}
                    isLoading={state.cargandoGuardarTodo}
                  />
                )}
              </div>
            }
          />
        )}
        <hr className="mt-1" />

        <div className="mt-2 flex flex-row items-end justify-between">
          <span></span>
          <div className="flex flew-row w-36">
            <ButtonSiavi
              cargando={state.cargandoGuardarTodo}
              text="Guardar y salir"
              onClick={() => {
                let contador = 0;
                for (const item of listado) {
                  if (
                    item.medida_solicitada === false &&
                    (item.razon_no_solicitud_id === undefined || item.razon_no_solicitud_id === null || 
                      item.razon_no_solicitud_id === '' || isNaN(item.razon_no_solicitud_id))
                  ) {
                    contador++;
                  }
                }

                if (contador > 0) {
                  setState({
                    ...state,
                    guardarCambios: true,
                    validacionMedidaSolicitada: contador > 0 ? true : false,
                    hasCambios: false,
                    mensajeMedidaSolicitada:
                      contador > 0
                        ? `${contador > 1 ? "Existen" : "Existe"} ${contador} ${
                            contador > 1 ? "registros" : "registro"
                          } que no ${
                            contador > 1 ? "tienen" : "tiene"
                          } razón no solicitud, primero debe de completar los campos para poder guardar y salir.`
                        : "",
                  });
                } else {
                  guardarTodo(listado);
                }
              }}
            />
          </div>
        </div>
        <div className="mb-16"></div>
      </div>
    </div>
  );
};
