import { React, useEffect, useState } from "react";
import { personas, getPersonas,deletePersona } from "./services";
import { UserIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/outline";
import { FormDetalle } from "../../registrarPersona/listar/Detalle";
import { OrientacionesPersona } from "./orientaciones";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { RefreshIcon } from "@heroicons/react/outline";
import { store } from '../../../../app/redux/store/store';
import { ChangeSearch } from '../../../../app/redux/actions/search';
import CustomTable from "../../../../components/common/Table";
import toast from 'react-hot-toast';
import ModalGenerico from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import FormEliminar from "../../../../components/common/FormEliminar";
import ModalTwo from "../../../../components/common/ModalTwo";

const tableColumns = [
  { title: "Tipo Doc.", value: "tipo_documento_detalle.nombre", tipo: "text" },
  { title: "Identificación", value: "no_documento", tipo: "text" },
  { title: "País", value: "pais_detalle.nombre", tipo: "text" },
  { title: "Nombre", value: null, values: ["primer_nombre", "segundo_nombre", "primer_apellido", "segundo_apellido"], tipo: "text" },
  { title: "Fecha de Nacimiento", value: "fecha_nacimiento", tipo: "date" },
];

export const TableHistorico = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search)
  const [isEliminar, setEliminar] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [persona, setPersona] = useState("");
  const [id, setId] = useState("");
  const [verDetalle, setDetalle] = useState(false);
  const [userDetalle, setUserDetalle] = useState({});
  const [agregar, setAgregar] = useState(false);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [orientaciones] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const [registro, setRegistro] = useState("");
  const [isPrimera] = useState(false);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "gestionpersona";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });



  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "creacionhistoricos";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const fetchPersonas = () => {   
    setTableLoading(true);
    personas(urlFetch,search, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetPersonas = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getPersonas(urlFetch, search, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchPersonas, [urlFetch,reFetch, token]);
    useEffect(fetchGetPersonas, [urlFetch, search, token]);

  const handleVer = (item) => {
    setUserDetalle(item);
    setDetalle(true);
    setPersona(item);
  };

  const handleAgregar = (item) => {
    setCargando(true);
    setPersona(item);
    setAgregar(true);
  };

  const handleEliminar = (id, nombre, registro) => {
    setId(id);
    setRegistro(registro);
    setEliminar(true);
  };

  const linkEditar = `/funcionalidades-generales/actualizar-historico/`;
  const pages = [
    { name: "Funcionalidades", href: "/funcionalidades-generales", current: false },
    { name: "Creación de casos históricos", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <Link to="/funcionalidades-generales/registrar-historico" type="button">
            {(CheckPermiso('crearpersona') === true) &&    //mm permiso agregado       
              <ButtonAdd text="Crear Víctima" type="button" /> }
            </Link>
            <ButtonAdd
              className="ml-2"
              text="Refrescar"
              type="button"
              onClick={() => {
                window.location.reload();
              }}
              icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
            />
          </div>
        )}

      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            //link={true}   //mmazariegos
            link={false}
            linkTo={linkEditar}
            //handleEliminar={handleEliminar}   //mmazariegos
            //agregar={true}
            agregar={(CheckPermiso('crearhistorico') === true) }   //mm permiso agregado       }
            handleAgregar={handleAgregar}
          />
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${registro.primer_nombre} ${registro.primer_apellido}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => { setEliminar(false) }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={async () => {
                    setLoading(true);
                    await deletePersona(id, token)
                      .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                          toast.success('Registro eliminado con éxito...!');
                          setLoading(false);
                          setEliminar(false);
                          setReFetch(true);
                        } 
                      })
                      .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                          setEliminar(false);
                          setLoading(false);
                          toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                          toast.error('Error interno comuniquese con el administrador')
                        }
                      });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalTwo
              title={"Detalle de persona"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle detalle={userDetalle} cancelar={setEliminar} Persona={persona} />
              }
            />
          )}
          {agregar && (
            <ModalGenerico
              title={"¿Desea agregar la persona al listado de visitas?"}
              open={agregar}
              closeModal={() => { }}
              cerrar={() => {
                setAgregar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <OrientacionesPersona orientaciones={orientaciones} Persona={persona} cancelar={() => { setAgregar(false) }} setReFetch={setReFetch} cargando={cargando} isFirst={isPrimera} />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
