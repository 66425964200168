import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getPaisVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/pais/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDocumento = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipodocumento/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNacionalidadVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/nacionalidad/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoCivilVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/estadocivil/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrientacionSexualVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/orientacionsexual/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProfesionalVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipoprofesional/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProfesion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/profesion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtencionesDiferenciadas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/aptitudatenciondiferenciada/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAtenciondiferenciada = async (idPersona, idAtencion, token) => {
    try {
        let body = {
            persona: idPersona,
            atencion: Number(idAtencion)
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/personaatenciondiferenciada/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAtencionDiferenciada = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/personaatenciondiferenciada/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomaMaya = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idiomamaya/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipioVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEscolaridadVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/escolaridad/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrigenEtnicoVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/origenetnico/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomaVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idioma/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSituacionLaboralVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/situacionlaboral/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdentidadGeneroVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/identidadgenero/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReligionVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/religion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiscapacidadVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/discapacidad/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiscapacidadesPersona = async (idPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/personadiscapacidad/?persona=${idPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstitucionVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getParentesco = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/parentesco/?page_size=3000&estado=true&aplica_sindicados=false`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoContactoVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipocontacto/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGrupoHabitacionalVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/grupohabitacional/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getVialidadVictima = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/vialidad/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postGestionDireccion = async (data, token) => {
    try {
        let body = {
            zona: data.zona,
            nombre_grupo: data.nombre_grupo,
            numero_vialidad: data.numero_vialidad,
            nombre_vialidad: data.nombre_vialidad,
            numero_casa: data.numero_casa,
            letra_casa: data.letra_casa,
            referencia_ubicacion: data.referencia_ubicacion,
            otros: data.otros,
            persona: Number(data.persona),
            tipo_contacto: Number(data.tipo_contacto),
            municipio: Number(data.municipio),
            grupo_habitacional: Number(data.grupo_habitacional) === 0 ? null : Number(data.grupo_habitacional),
            vialidad: Number(data.vialidad) === 0 ? null : Number(data.vialidad),
            direccion_principal: data.direccion_principal
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestiondireccion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionDireccion = async (id, data, token) => {
    try {
        let body = {
            zona: data.zona,
            nombre_grupo: data.nombre_grupo,
            numero_vialidad: data.numero_vialidad,
            nombre_vialidad: data.nombre_vialidad,
            numero_casa: data.numero_casa,
            letra_casa: data.letra_casa,
            referencia_ubicacion: data.referencia_ubicacion,
            otros: data.otros,
            persona: Number(data.persona),
            tipo_contacto: Number(data.tipo_contacto),
            municipio: Number(data.municipio),
            grupo_habitacional: Number(data.grupo_habitacional) === 0 ? null : Number(data.grupo_habitacional),
            vialidad: Number(data.vialidad) === 0 ? null : Number(data.vialidad),
            direccion_principal: data.direccion_principal
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestiondireccion/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionDireccion = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestiondireccion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDireccionesVictima = async (idPersona,idSindicado, token) => {
    let url = (idPersona === "") ?  `registro/gestiondireccion/?page_size=10&sindicado__id=${idSindicado}` : 
    `registro/gestiondireccion/?page_size=10&persona__id=${idPersona}`;

    try {
        let result = await fetch(`${REACT_APP_API_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPersona = async (id, data, token) => {
    try {
        let body = {
            sede: data?.sede,
            lee_escribe: data?.lee_escribe,
            idioma_preferencia: Number(data?.idioma_preferencia),
            situacion_laboral: (data?.situacion_laboral === "") ? null : Number(data?.situacion_laboral),
            identidad_genero: (data?.identidad_genero === "") ? null : Number(data?.identidad_genero),
            require_atencion_diferenciada: data?.require_atencion_diferenciada,
            en_migracion: data?.en_migracion,
            pais_origen: (data?.pais_origen === "") ? null : Number(data?.pais_origen),
            pais_destino: (data?.pais_destino === "") ? null : Number(data?.pais_destino),
            no_documento: data?.noDocumento,
            primera_visita: data?.primeraVisita,
            primer_nombre: data?.primerNombre,
            segundo_nombre: data?.segundoNombre,
            segundo_apellido: data?.segundoApellido,
            primer_apellido: data?.primerApellido,
            apellido_casada: data?.apellidoCasada,
            fecha_nacimiento: data?.fechaNacimiento,
            sexo: data?.sexo,
            como_le_gusta_que_le_llamen: data?.alias,
            correo_electronico: data?.correo_electronico,
            estado_civil: Number(data?.estado_civil),
            orientacion_sexual: (data?.orientacion_sexual === "") ? null : Number(data?.orientacion_sexual),
            escolaridad: (data?.escolaridad === "") ? null : Number(data?.escolaridad),
            profesion: (data?.profesion === "") ? null : Number(data?.profesion),
            origen_etnico: (data?.origen_etnico === "") ? null : Number(data?.origen_etnico),
            origen_etnico_maya: (data?.origen_etnico_maya === "") ? null :data?.origen_etnico_maya,
            idioma: Number(data?.idioma),
            idioma_maya: (data?.idioma_maya === "") ? null : Number(data?.idioma_maya),
            religion: (data?.religion === "") ? null : Number(data?.religion),
            departamento: Number(data?.departamento),
            municipio: Number(data?.municipio),
            tipo_documento: Number(data?.tipoDocumento),
            pais: Number(data?.pais),
            nacionalidad: Number(data?.nacionalidad),
            relacion_con_victimario: Number(data?.relacion_con_victimario)
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPersona = async (data, token) => {
    try {
        let idsDiscapacidades = [];
        let idsAtenciones = [];

        data.discapacidades.forEach(element => {
            idsDiscapacidades.push(element.id);
        });

        data.atenciones.forEach(element => {
            idsAtenciones.push(element.id);
        });

        let body = {
            sede: data?.sede,
            discapacidades: idsDiscapacidades,
            atencion_diferenciada: idsAtenciones,
            lee_escribe: data.lee_escribe,
            idioma_preferencia: Number(data.idioma_preferencia),
            situacion_laboral: (data.situacion_laboral === "") ? null : Number(data.situacion_laboral),
            identidad_genero: (data.identidad_genero === "") ? null : Number(data.identidad_genero),
            require_atencion_diferenciada: data.require_atencion_diferenciada,
            en_migracion: data.en_migracion,
            pais_origen: (data.pais_origen === "") ? null : Number(data.pais_origen),
            pais_destino: (data.pais_destino === "") ? null : Number(data.pais_destino),
            no_documento: data.noDocumento,
            primera_visita: data.primeraVisita,
            primer_nombre: data.primerNombre,
            segundo_nombre: data.segundoNombre,
            segundo_apellido: data.segundoApellido,
            primer_apellido: data.primerApellido,
            apellido_casada: data.apellidoCasada,
            fecha_nacimiento: data.fechaNacimiento,
            sexo: data.sexo,
            como_le_gusta_que_le_llamen: data.alias,
            correo_electronico: data.correo_electronico,
            estado_civil: Number(data.estado_civil),
            orientacion_sexual: (data.orientacion_sexual === "") ? null : Number(data.orientacion_sexual),
            escolaridad: (data.escolaridad === "") ? null : Number(data.escolaridad),
            profesion: (data.profesion === "") ? null : Number(data.profesion),
            origen_etnico: (data.origen_etnico === "") ? null : Number(data.origen_etnico),
            origen_etnico_maya: (data?.origen_etnico_maya === "") ? null :data?.origen_etnico_maya,
            idioma: Number(data.idioma),
            idioma_maya: (data.idioma_maya === "") ? null : Number(data.idioma_maya),
            religion: (data.religion === "") ? null : Number(data.religion),
            departamento: Number(data.departamento),
            municipio: Number(data.municipio),
            tipo_documento: Number(data.tipoDocumento),
            pais: Number(data.pais),
            nacionalidad: Number(data.nacionalidad),
            relacion_con_victimario: Number(data.relacion_con_victimario)
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionTelefono = async (data, token) => {

    try {
        let body = {
            codigo_area: data.codigo_area,
            numero_telefono: data.numero_telefono,
            tipo_contacto: Number(data.tipo_contacto),
            persona: Number(data.persona),
            telefono_principal: data.telefono_principal,
            enviar_sms: data.enviar_sms,
            sindicado: null
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestiontelefono/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionTelefono = async (id, data, token) => {
    try {
        let body = {
            codigo_area: data.codigo_area,
            numero_telefono: data.numero_telefono,
            tipo_contacto: Number(data.tipo_contacto),
            persona: Number(data.persona),
            telefono_principal: data.telefono_principal,
            enviar_sms: data.enviar_sms
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestiontelefono/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPersonaDisc = async (idPersona, idDiscapacidad, token) => {
    try {
        let body = {
            persona: idPersona,
            discapacidad: Number(idDiscapacidad)
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/personadiscapacidad/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const postPersonaDiscapacidad = async (idPersona, idDiscapacidad, token) => {
    try {
        let body = {
            persona: idPersona,
            discapacidad: Number(idDiscapacidad)
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/personadiscapacidad/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDiscapacidades = async (id, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/personadiscapacidad/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionTelefono = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestiontelefono/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTelefonosVictima = async (idPersona,idSindicado, token) => {
    let url = (idPersona === "") ?  `registro/gestiontelefono/?page_size=10&sindicado__id=${idSindicado}` : 
    `registro/gestiontelefono/?page_size=10&persona__id=${idPersona}`;
    try {
        let result = await fetch(`${REACT_APP_API_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRedesSocialesVictima = async (idPersona, idSindicado, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionredessociales/?page_size=3000&persona__id=${idPersona}&sindicado__id=${idSindicado}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersona = async (idPersona,idCaso, values, token) => {
    try {
        let body ={
            status_caso_persona: true,
            tipo_persona: 1,
            caso: idCaso,
            persona: idPersona,
            remplazar_querellante: false,
            es_victima_directa: false,
            querellante: values.querellante,
            parentesco: null,
            victima: true
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersona/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
}; 

export const getPersonas = async (buscar, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSindicados = async (buscar, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/busquedasindicado/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const buscarPersona = async (pais, tipo_documento, documento, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?pais=${pais}&tipo_documento=${tipo_documento}&search=${documento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionRS = async (idPersona, idSindicado, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionredessociales/?page_size=3000&persona__id=${idPersona}&sindicado__id=${idSindicado}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionRS = async (data, token) => {

    try {
        let body = {
            usuario_red: data.usuario_red,
            link_perfil: data.link_perfil,
            red_social: Number(data.red_social),
            persona: Number(data.persona),
            estado: data.estado,
            sindicado: null
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestionredessociales/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionRS = async (id, data, token) => {
    try {
        let body = {
            usuario_red: data.usuario_red,
            link_perfil: data.link_perfil,
            red_social: Number(data.red_social),
            persona: Number(data.persona),
            estado: data.estado
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionredessociales/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionRS = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionredessociales/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRedesSociales = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/redessociales/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

