/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { DocumentTextIcon, XCircleIcon } from "@heroicons/react/outline";
import { TextAreaSiavi } from "../../../../../../components/common/TextArea";
import { FormDetalleDiagnostico } from "./detalleDiagnostico";
import { GestionSignosVitales } from "./gestionSignosVitales";
import { GestionEvolucion } from "./gestionEvolucion";
import {
    deleteDiagnosticoMedico,
    postDiagnosticoMedico,
    putDiagnosticoMedico,
    getDiagnosticoMedico
} from "./services";
import ModalBig from "../../../../../../components/common/ModalTwo";
import Spinner from "../../../../../../components/common/Spinner";
import CustomTable from "../../../../../../components/common/Table";
import ModalGenerico from "../../../../../../components/common/Modal";
import FormEliminar from "../../../../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import moment from "moment";
import * as Yup from "yup";
import { InputSiavi } from "../../../../../../components/common/InputText";

const tableColumns = [
    { title: "Motivo Consulta", value: "motivo_consulta", tipo: "text" },
    { title: "Plan Educacional", value: "plan_educacional", tipo: "text" },
    { title: "Diagnóstico Final", value: "diagnostico_final", tipo: "text" },
];

const validaciones = Yup.object().shape({
    motivo_consulta: Yup.string()
        .required("Motivo Consulta es requerido"),
    impresion_diagnostico_inicial: Yup.string()
        .required("Impresión Diagnóstico Inicial es requerido"),
});

export const DiagnosticoMedico = (props) => {
    const caso = props.infoExpediente.caso;
    const idExpediente = props?.infoExpediente?.id;
    const servicio = props.infoExpediente.servicio.id;
    const persona = props.infoExpediente.caso_persona.persona.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const [verDetalle, setDetalle] = useState(false);
    const [diagnosticoDetalle, setDiagnosticoDetalle] = useState({});
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idDiagnostico, setIdDiagnostico] = useState("");
    const [diagnostico, setDiagnostico] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [agregar, setAgregar] = useState(false);
    const [cargando,setCargando] = useState(false);
    const [agregarEvolucion, setAgregarEvolucion] = useState(false);
    let date = Date.now();

    useEffect(() => {
        getDiagnosticoMedico(idExpediente, token).then((diagnosticos) => {
            setDataTable(diagnosticos);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, reFetch]);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "expedientediagnosticomedico";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleAgregar = (item) => {
        setIdDiagnostico(item.id);
        setDiagnostico(item);
        setAgregar(true);
    };

    const handleAgregarEvolucion = (item) => {
        setIdDiagnostico(item.id);
        setDiagnostico(item);
        setAgregarEvolucion(true);
    };

    const handleVer = (item) => {
        setDiagnosticoDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdDiagnostico(id);
        setEliminar(true);
    };

    const handleEditar = (diagnosticos) => {
        setEdit(true);
        setDiagnostico(diagnosticos);
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    motivo_consulta: isEdit ? diagnostico?.motivo_consulta : "",
                    impresion_diagnostico_inicial: isEdit ? diagnostico?.impresion_diagnostico_inicial : "",
                    plan_educacional: isEdit ? diagnostico?.plan_educacional : "",
                    diagnostico_final: isEdit ? diagnostico?.diagnostico_final : "",
                    conclusiones: isEdit ? diagnostico?.conclusiones : "",
                    observaciones: isEdit ? diagnostico?.observaciones : "",
                    fecha_ultima_modificacion: moment(date).format('YYYY-MM-DD'),
                    usuario_ultima_modificacion: idUser,
                    caso: caso,
                    expediente: idExpediente,
                    servicio: servicio,
                    persona: persona,

                    antecedentes_familiares: isEdit ? diagnostico?.antecedentes_familiares: "",
                    antecedentes_personales: isEdit ? diagnostico?.antecedentes_personales: "",
                    antecedentes_go:isEdit ? diagnostico?.antecedentes_familiares: "",
                    fur: isEdit ? diagnostico?.FUR: null,
                    g: isEdit ? diagnostico?.G: null,
                    p:isEdit ? diagnostico?.P: null,
                    c:isEdit ? diagnostico?.C: null,
                    a8:isEdit ? diagnostico?.A8: null,
                    hv:isEdit ? diagnostico?.HV: null,
                    hm:isEdit ? diagnostico?.HM: null,
                    fecha_papanicolaou:isEdit ? diagnostico?.fecha_papanicolaou: null,
                    papanicolaou:isEdit ? diagnostico?.papanicolaou: "",
                    revision_sistemas:isEdit ? diagnostico?.revision_sistemas: "",
                    examen_fisico:isEdit ? diagnostico?.examen_fisico: "",
                    examen_perinatal:isEdit ? diagnostico?.examen_perinatal: "",
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        setCargando(true);
                        await putDiagnosticoMedico(diagnostico.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({});
                                    setEdit(false);
                                    setCargando(false);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                    setCargando(false);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                    setCargando(false);
                                }
                            });
                    } else {
                        setCargando(true);
                        await postDiagnosticoMedico(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro creado con éxito...!');
                                    resetForm({});
                                    setCargando(false);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                    setCargando(false);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                    setCargando(false);
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Motivo Consulta"
                                    validacion={true}
                                    name="motivo_consulta"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.motivo_consulta}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Historia clínica"
                                    validacion={true}
                                    name="impresion_diagnostico_inicial"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.impresion_diagnostico_inicial}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Tratamiento y plan educacional"
                                    validacion={true}
                                    name="plan_educacional"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.plan_educacional}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Antecedentes familiares"
                                    validacion={true}
                                    name="antecedentes_familiares"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.antecedentes_familiares}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Antecedentes personales"
                                    validacion={true}
                                    name="antecedentes_personales"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.antecedentes_personales}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Antecedentes G/O"
                                    validacion={true}
                                    name="antecedentes_go"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.antecedentes_go}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="FUR"
                                    name="fur"
                                    type="date"
                                    value={values.fur}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="G"
                                    name="g"
                                    type="number"
                                    value={values.g}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="P"
                                    name="p"
                                    type="number"
                                    value={values.p}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="C"
                                    name="c"
                                    type="number"
                                    value={values.c}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="AB"
                                    name="a8"
                                    type="number"
                                    value={values.a8}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="HV"
                                    name="hv"
                                    type="number"
                                    value={values.hv}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="HM"
                                    name="hm"
                                    type="number"
                                    value={values.hm}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
                                <InputSiavi
                                    label="Fecha papanicolaou"
                                    name="fecha_papanicolaou"
                                    type="date"
                                    value={values.fecha_papanicolaou}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <InputSiavi
                                    label="Papanicolaou"
                                    name="papanicolaou"
                                    type="text"
                                    value={values.papanicolaou}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Revisión sistemas"
                                    validacion={true}
                                    name="revision_sistemas"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.revision_sistemas}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Exámen físico"
                                    validacion={true}
                                    name="examen_fisico"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.examen_fisico}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Exámen perinatal"
                                    validacion={true}
                                    name="examen_perinatal"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.examen_perinatal}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Diagnóstico"
                                    validacion={true}
                                    name="diagnostico_final"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.diagnostico_final}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Observaciones"
                                    validacion={true}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 sm:col-span-4 md:col-span-2">
                                    {
                                        cargando ?
                                        <Spinner />
                                        :
                                        <ButtonSiavi
                                        type="submit"
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                    }
                                   
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                    agregar={true}
                    handleAgregar={handleAgregar}
                    agregar1="Signos Vitales"
                    agregarSegundo={true}
                    handleSegundoAgregar={handleAgregarEvolucion}
                    agregar2="Evolución Médica"
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de diagnóstico médico"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleDiagnostico detalle={diagnosticoDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalBig
                        title={`Signos Vitales de ${diagnostico.motivo_consulta}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionSignosVitales diagnostico={diagnostico} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {agregarEvolucion && (
                    <ModalBig
                        title={`Evolución Médica de ${diagnostico.motivo_consulta}`}
                        open={agregarEvolucion}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarEvolucion(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionEvolucion diagnostico={diagnostico} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteDiagnosticoMedico(idDiagnostico, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
