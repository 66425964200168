
const {
    REACT_APP_API_URL
} = process.env;

export const getAreaSustantiva = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/area/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAreaSustantiva = async ({nombre,estado,es_areasustantiva,codigo},token) => {
    try {
        let body = {
            nombre,
            estado,
            codigo,
            es_areasustantiva
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/area/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAreaSustantiva = async (id, {nombre,estado,es_areasustantiva,codigo},token) => {
    try {
        let body = {
            nombre,
            estado,
            codigo,
            es_areasustantiva
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/area/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAreaSustantiva = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/area/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};