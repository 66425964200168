

const { REACT_APP_API_URL } = process.env;

export const getPersonas = async (search, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/gestionpersona/?search=${search.value}&page_size=20`

    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSindicados = async (search,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/sindicado/?search=${search.value}&page_size=20`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getCasosPersona = async (idPersona,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/casopersona/?persona=${idPersona}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getExpedientesSindicado = async (idSindicado,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesindicado/?sindicado=${idSindicado}&page_size=20`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getCasos = async (busqueda,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/caso/?search=${busqueda.value}&search_fields=numero_caso`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getExpedientePersona = async (idPersona,urlPage,token) => {
  try {
    let urlFetch = urlPage === undefined
    ? `${REACT_APP_API_URL}/registro/expediente/?caso_persona__persona=${idPersona}`
    : `${urlPage}&caso_persona__persona=${idPersona}`;

      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAccionesPersona = async (idPersona,urlPage,token) => {
  try {
    let urlFetch = urlPage === undefined
    ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?persona=${idPersona}&caso__isnull=true&expediente__isnull=true`
    : `${urlPage}&persona=${idPersona}&caso__isnull=true&expediente__isnull=true`;

      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};