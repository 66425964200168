/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";


const {
    REACT_APP_JASPER_REPORT,
    REACT_APP_JASPER_USUARIO,
    REACT_APP_JASPER_PASSWORD
} = process.env;



export  function RenderJasper(props) {
    const [urlPDF, setUrlPDF] = useState("");

    let parametros = {
        usuario:props.usuario,
        fechaInicio: props.fechaInicio,
        fechaFin: props.fechaFin,
        //profesionales: props.profesionales,
        //acciones:props.acciones,
        servicios:props.servicios,
        // numerocaso:props.numerocaso,
        asignacion:props.asignacion,
        profesion:props.profesion,
        
    }

    let params = new URLSearchParams(parametros)

//filtros personalizados
if(props.expediente!=null)
{
    params.append('expediente',props.expediente.id)
}
if(props.meta!=null)
{
    params.append('meta',props.meta)
}
if(props.meta2!=null)
{
    params.append('meta2',props.meta2)
}

//agregar filtros multiselección

if(props.acciones!=0)
{
    props.acciones?.map((item) => {
        params.append('acciones', item.accionId);
       // console.log(item)
      })
}

if(Array.isArray(props.profesionales))
{
    props.profesionales?.map((item) => {
        params.append('profesionales', item.profesionalId);
      //  console.log(item)
      })
}
else if(props.profesionales!=0)
{
    params.append('profesionales',props.profesionales)
}

if(Array.isArray(props.numerocaso))
{
    props.numerocaso?.map((item) => {
        params.append('numerocaso', item.numero_caso);
       // console.log(item.numero_caso)
      })
}
else if(props.numerocaso!=0)
{
    
    params.append('numerocaso',props.numerocaso)
}

if(props.motivoVisita!=0)
{
    params.append('motivoVisita',props.motivoVisita)
}


if(Array.isArray(props.sedes))
{
    props.sedes?.map((item) => {
        params.append('sedes', item.sedeId);
      //  console.log(item)
      })
}
else
{
    params.append('sedes',props.sedes)
}

if(Array.isArray(props.delitos))
{
    props.delitos?.map((item) => {
        params.append('delitos', item.id);
      //  console.log(item)
      })
}
else if(props.delitos!=0)
{
    params.append('delitos',props.id)
}

if(Array.isArray(props.edades))
{
    props.edades?.map((item) => {
        params.append('edades', item.id);
      //  console.log(item)
      })
}
else if(props.edades!=0)
{
    params.append('edades',props.id)
}

if(Array.isArray(props.departamentos))
{
    props.departamentos?.map((item) => {
        params.append('departamentos', item.departamentoId);
      //  console.log(item)
      })
}
else  
{
    params.append('departamentos',props.departamentos)
}

if(Array.isArray(props.municipios))
{
    props.municipios?.map((item) => {
        params.append('municipios', item.municipioId);
      //  console.log(item)
      })
}
else if(props.municipios!=0)
{
    params.append('municipios',props.municipios)
}

if(Array.isArray(props.medidas))
{
    props.medidas?.map((item) => {
        params.append('medidas', item.id);
      //  console.log(item)
      })
}
else if(props.medidas!=0)
{
    params.append('medidas',props.medidas)
}

if(Array.isArray(props.instituciones))
{
    props.instituciones?.map((item) => {
        params.append('instituciones', item.id);
      //  console.log(item)
      })
}
else if(props.instituciones!=0)
{
    params.append('instituciones',props.id)
}



    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Basic ' + btoa(REACT_APP_JASPER_USUARIO + ":" + REACT_APP_JASPER_PASSWORD ));
        var requestOptions = {
            method: 'GET',
            params:params,
            headers: myHeaders,
            redirect: 'follow'
        };
             fetch(`${REACT_APP_JASPER_REPORT}${props.reporte}.pdf?`+ params, requestOptions)
             .then(response => {
                if (response.ok) {
                 return response.blob()
                }
                throw new Error('El reporte no soporta los parámetros ingresados    ');
              })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                setUrlPDF(url);
            })
            .catch(error => console.log( error));
        
       
    }, [props.data]);



    return (
        <div>
            {urlPDF !== "" &&
                <embed
                    src={`${urlPDF}#page=1&zoom=50`}
                    className={props.className}
                    width={props.width ? props.width : '100%'}
                    height={props.height ? props.height : '650px'}
                    style={props.style}
                    type="application/pdf"
                />
            }
        </div>
    );
}
