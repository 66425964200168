
const {
    REACT_APP_API_URL
} = process.env;

export const getAptitudAtencionDife = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/aptitudatenciondiferenciada/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAptitudAtencionDife = async ({nombre, descripcion, estado},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/aptitudatenciondiferenciada/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAptitudAtencionDife = async (id, {nombre,descripcion,estado},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/aptitudatenciondiferenciada/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAptitudAtencionDife = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/aptitudatenciondiferenciada/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};