const {
    REACT_APP_API_URL
} = process.env;

export const getEspacioSeguro = async (urlPage, search, order, filters, token) => {
    try {
        let urlFetch = urlPage === undefined
            ? `${REACT_APP_API_URL}/registro/espacioseguro/?search=${search.value}&ordering=${order}&${filters}`
            : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const espacioSeguro = async (urlPage, search, order, filters, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/espacioseguro/?ordering=${order}&${filters}`
                : `${urlPage}&search=${search.value}&ordering=${order}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getProfesionales = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };
  
  export const getDelitos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/delito/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };
  