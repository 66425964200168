
const {
    REACT_APP_API_URL
} = process.env;

export const getResultadoAudiencia = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/resultadoaudiencia/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postResultadoAudiencia = async ({ nombre, descripcion, estado_proceso, estado }, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado_proceso,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/resultadoaudiencia/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putResultadoAudiencia = async (id, { nombre, descripcion, estado_proceso, estado }, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado_proceso,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/resultadoaudiencia/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteResultadoAudiencia = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/resultadoaudiencia/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoProceso = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/estadoproceso/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoProcesoResultadoAudiencia = async (id,urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/catalogos/resultadoaudiencia/?estado_proceso=${id}` :
            `${urlPage}&estado_proceso=${id}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};