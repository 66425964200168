import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    getServicios,
    postMotivoCriterioCierre,
    putMotivoCriterioCierre,
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
    descripcion: Yup.string()
        .required("Descripción es requerida")
        .max(255, "Máximo 255 caracteres")
});

const useFetchServicio = (token) => {
    const [state, setState] = useState({
        servicios: [],
    });

    useEffect(() => {
        getServicios(token).then((servicios) => {
            setState({
                servicios: servicios,
            });
        });
    }, [token]);

    return state;
};

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const { servicios } = useFetchServicio(token);

    return (
        <div>
            <Formik
                initialValues={{
                    descripcion: props.editar ? props.value.descripcion : "",
                    por_caso: props.editar
                        ? props.value.por_caso
                        : false,
                    servicio: props.editar ? props.value.servicio.id : "",
                    usuario_creacion: idUser,
                    estado: props.editar ? props.value.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putMotivoCriterioCierre(props.value.id, values, token).then(
                            (data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    props.setReFetch(true);
                                    toast.success("Registro actualizado con éxito...!");
                                }
                            }
                        );
                    } else {
                        await postMotivoCriterioCierre(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message);
                            } else {
                                props.setReFetch(true);
                                toast.success("Registro creado con éxito...!");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6 mt-5">
                                <Label text="" />
                                <InputSiavi
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    value={values.descripcion}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Descripción*"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="¿Por Caso?" />
                                <Toggle
                                    enabled={values.por_caso}
                                    name="por_caso"
                                />
                            </div>
                            {!values.por_caso && (
                                <div className="col-span-6 mt-4">
                                    <Label text="Servicio*" />
                                    <div className="mt-1">
                                        <select
                                            name="servicio"
                                            value={values.servicio}
                                            onChange={handleChange}
                                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                        >
                                            <option value="" disabled>
                                                Elegir servicio
                                            </option>
                                            {servicios?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="col-span-6 mt-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
