import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getFormularioCasoPersona = async (idCasoPersona,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/formulariocasopersona/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormularioCasoPersona = async (data,idCasoPersona,idOrientacion,idExpediente,idSede,token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormularioCasoPersona = async (id,data,idCasoPersona,idOrientacion,idExpediente,idSede,token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormularioCasoPersona = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/modalidad/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntas = async (idFormulario,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/?formulario=${idFormulario}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/?page_size=200&consentimiento_informado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasoPersonaConsentimientos = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersonaconsentimiento/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${data.idPlan}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCasoPersonaConsentimientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};