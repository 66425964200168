import { React, useState } from "react";
import { useSelector } from "react-redux";
import { EditarNarrativa } from "./editar_narrativa";
import { Detalle } from "./detalleNarrativa";
import { Temporalidad } from "./temporalidad";
import { deleteNarrativa } from "../services";
import { TrashIcon, PencilAltIcon, ClockIcon } from "@heroicons/react/solid";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalTwo from "../../../../../components/common/ModalTwo";
import toast from "react-hot-toast";
import Spinner from "../../../../../components/common/Spinner";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";

export const MostrarNarrativa = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [editar, setEditar] = useState(false);
    const [verDetalle, setVerDetalle] = useState(false);
    const [temporalidad, setTemporalidad] = useState(false);
    const headerEditar = [
        "Sindicado",
        "Delito",
    ];


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "Orientacion";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso == permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });
        //    retorno = true;  // mm comentar para que funcione
        return retorno;

    }

    const classAcciones = "hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white focus:ring-maivi-primaryLightBlue tooltip"

    const acciones =
        <div className="flex flex-row border rounded-md hover:shadow-md p-1">
            {(CheckPermiso('OHAgregaTemporalidad') === true) &&    //mm permiso agregado        
                <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                        setTemporalidad(true);
                    }}
                >
                    <ClockIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext text-xs">Agregar lugar y tiempo</span>
                </button>
            }
            {(CheckPermiso('OHEditar') === true) &&    //mm permiso agregado        
                <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                        props.values.narrativaEditar = props?.itemNarrativa?.narrativa;
                        setEditar(true);
                    }}
                >
                    <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext text-xs">Editar</span>
                </button>
            }
            {(CheckPermiso('OHEliminar') === true) &&    //mm permiso agregado        
                <button
                    type="button"
                    className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                    onClick={async () => {
                        await deleteNarrativa(props.itemNarrativa.id, token)
                            .then((data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                    toast.success("Registro eliminado con éxito...!");
                                    props.setRefeth(true);
                                    props.setRefeth(false);
                                }
                            })
                            .catch(function (error) {
                                if (
                                    error.response.status === 400 ||
                                    error.response.status === 401
                                ) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error(
                                        "Error interno comuniquese con el administrador"
                                    );
                                }
                            });
                    }}
                >
                    <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                    <span className="tooltiptext text-xs">Eliminar</span>
                </button>
            }
        </div>

    return (
        <div>
            {(props.cargandoNarrativas) ? <Spinner /> :
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 xl:col-span-6 lg:col-span-6 border p-3 rounded-md hover:shadow-md">
                        <div className="flex flex-row justify-between items-end">
                            <h1 className="text-base text-maivi-primaryBlue">Hechos</h1>
                            <div className="flex justify-end">
                                {acciones}
                            </div>
                        </div>
                        <TextAreaSiavi
                            cols={7}
                            rows={10}
                            disabled={true}
                            value={props?.itemNarrativa?.narrativa}
                            className="mb-2"
                        />

                    </div>
                    <div className="col-span-12 xl:col-span-6 lg:col-span-6 p-2 border rounded-md hover:shadow-md">
                        <TableSiavi
                            headers={headerEditar}
                            body={
                                props?.itemNarrativa?.sindicados_delitos?.map((item, index) => (
                                    <tr className="hover:bg-maivi-gray-5 cursor-pointer" key={`${index}-ex`}>
                                        <td className={`px-2 py-1 row-td text-xs ${(item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? "text-red-600" : "text-maivi-primaryFont"}`} >
                                            {
                                                `${(item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado no individualizado' :
                                                    `${item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_nombre} 
                                             ${item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_apellido}`}`
                                            }
                                        </td>
                                        <td className={props.tdClass}>{item?.exp_sindicado_delito?.delito?.nombre}</td>
                                    </tr>
                                ))
                            }
                        />
                    </div>
                    {editar && (
                        <ModalTwo
                            title={`Editar narrativa`}
                            open={editar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setEditar(false);
                            }}
                            children={
                                <EditarNarrativa
                                    setRefeth={props.setRefeth}
                                    idCaso={props.idCaso}
                                    itemNarrativa={props.itemNarrativa}
                                    tdClass={props.tdClass}
                                    setEditar={setEditar}
                                    selectClass={props.selectClass}
                                    values={props.values}
                                    handleChange={props.handleChange}
                                    delitosFiltrar={props.delitosFiltrar}
                                />
                            }
                        />
                    )}
                    {verDetalle && (
                        <ModalTwo
                            title={`Ver narrativa`}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setVerDetalle(false);
                            }}
                            children={
                                <Detalle
                                    token={token}
                                    idCaso={props.idCaso}
                                    narrativa={props.itemNarrativa}
                                    thClass={props.thClass}
                                    tdClass={props.tdClass}
                                />
                            }
                        />
                    )}
                    {temporalidad && (
                        <ModalTwo
                            title={`Agregar Lugar y Tiempo`}
                            open={temporalidad}
                            closeModal={() => { }}
                            cerrar={() => {
                                setTemporalidad(false);
                            }}
                            children={
                                <Temporalidad
                                    token={token}
                                    idCaso={props.idCaso}
                                    idExpediente={props.itemNarrativa.expediente.id}
                                    idHecho={props.itemNarrativa.id}
                                    thClass={props.thClass}
                                    tdClass={props.tdClass}
                                    setTemporalidad={setTemporalidad}
                                />
                            }
                        />
                    )}
                </div>
            }

        </div>
    );
};
