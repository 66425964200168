import { React} from "react";
import { Formik } from "formik";
import { Generales }  from "./generales";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  tipo_persona: Yup.string().required('tipo persona es requerido'),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormGenerales = (props) => {
  const caso = props.caso;
  const orientacion = props.orientacion;

  return (
    <Formik
      initialValues={{
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
       
      }
     }
    >
      {(props) => (
        <Generales
          {...props}
          customSwitchChange={customSwitchChange}
          caso={caso}
          orientacion={orientacion}
        />
      )}
    </Formik>
  );
};
