import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { getCategoriaInstitucion, getTipoInstitucion, postInstitucion, putInstitucion } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Institución es requerido")
    .max(255, "Máximo 100 caracteres"),
});

const useFetchTipoInstitucion = (token) => {
  const [state, setState] = useState({
    tipos: [],
  });

  useEffect(() => {
    getTipoInstitucion(token).then((tipos) => {
      setState({
        tipos: tipos,
      });
    });
  }, [token]);

  return state;
};

const useFetchCategoriaInstitucion = (token) => {
  const [state, setState] = useState({
    categorias: [],
  });

  useEffect(() => {
    getCategoriaInstitucion(token).then((categorias) => {
      setState({
        categorias: categorias,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { tipos } = useFetchTipoInstitucion(token);
  const { categorias } = useFetchCategoriaInstitucion(token);
  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          estado: props.editar ? props.value.estado : true,
          tiene_materia: props.editar? props.value.tiene_materia: false,
          denuncia_valida: props.editar? props.value.denuncia_valida: false,
          tipo_institucion: props.editar ? props.value.tipo_institucion.id : [],
          categoria_institucion: props.editar ? props.value.categoria_institucion.id : []
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putInstitucion(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              }else{
                props.setReFetch(true);
                toast.success('Registro actualizado con éxito...!')
              }
            });
          } else {
            await postInstitucion(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              }else{
                props.setReFetch(true);
                toast.success('Registro creado con éxito...!')
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="*Institución"
                />
              </div>
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="col-span-6 mt-5">
                <Label text="Tiene materia" />
                <Toggle enabled={values.tiene_materia} name="tiene_materia" />
              </div>
              <div className="col-span-6 mt-5">
                <Label text="Denuncia válida" />
                <Toggle enabled={values.denuncia_valida} name="denuncia_valida" />
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Tipo Institución" />
                <div className="mt-1">
                  <select
                    id="tipo_institucion"
                    name="tipo_institucion"
                    autoComplete="off"
                    value={values.tipo_institucion}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="" disabled>Elegir tipo institución</option>
                    {tipos?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Categoría Institución" />
                <div className="mt-1">
                  <select
                    id="categoria_institucion"
                    name="categoria_institucion"
                    autoComplete="off"
                    value={values.categoria_institucion}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="" disabled>Elegir categoría institución</option>
                    {categorias?.results?.map((item) => {
                      return (
                        <option key={item.descripcion} value={item.id}>
                          {item.descripcion}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
