/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
//import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
  getProfesionales,
  getInstitucionGestion
} from "./services";
import Select from "react-select";
import Spinner from "../../../components/common/Spinner";
import makeAnimated from "react-select/animated";
import Label from "../../../components/common/Label";

export default function Filtros(props) {
  const optionsProfesionales = [];
  const fiscalias = [];
  const animatedComponents = makeAnimated();
  const [profesionalSelect, setProfesional] = useState("");
  const [fiscalia, setFiscalia] = useState("");
  const [profesionales, setProfesionales] = useState([]);
  const [instituciones, setInstituciones] = useState([]);

  let filtros = "";
  const [state, setState] = useState({
    profesional: "",
    gestion_institucion: "",
    completada: "",
    fecha_desde: "",
    fecha_hasta: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getInstitucionGestion(props.token).then((data) => {
      setInstituciones(data);
    });
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
        value: item.id,
        label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
});

const changeSelectProfesionales = (e) => {
    setProfesional(e.value);
};

instituciones?.results?.forEach((item) => {
  let reg = {
      value: item.id,
      label: item?.nombre_fiscalia,
  };
  fiscalias.push(reg);
});

const changeSelectFiscalia = (e) => {
  setFiscalia(e.value);
};

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
         <div className="col-span-12 mt-4">
         <Label text="Profesional asignado"/>
         <Select
          isMulti={false}
          placeholder="Seleccionar profesional"
          components={animatedComponents}
          name="servicios"
          className="basic-multi-select text-xs text-maivi-primaryBlue w-full mb-4"
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesionales}
          maxMenuHeight={140}
      />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha Planificada inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha Planificada fin"
        />
      </div>
      <div className="col-span-12 mt-4">
       <Label text="Órgano Jurisdiccional"/>
         <Select
          isMulti={false}
          placeholder="Seleccionar"
          components={animatedComponents}
          name="servicios"
          className="basic-multi-select text-xs text-maivi-primaryBlue w-full mb-4"
          classNamePrefix="seleccione"
          options={fiscalias}
          onChange={changeSelectFiscalia}
          maxMenuHeight={140}
      />
      </div>
      {/* <div className="mt-1">
        <SelectSiavi 
         label="Completada"
         name="completada"
         value={state.completada}
         onChange={onChange}
         valueInit=""
         selectInit="Elegir"
         list={
            [
              <option value="true">Sí</option>,
              <option value="false">No</option>
            ]
         }
        />
      </div> */}
   
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `gestion_institucion=${fiscalia}&profesional_asignado=${profesionalSelect}&fecha_hora_planificada_inicia__gte=${state.fecha_desde}&fecha_hora_planificada_inicia__lte=${state.fecha_hasta}`;
            props.setFilters(filtros);
            props.setOpenFilters(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
