import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getTipoProcesoEtapaProcesal } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchTipoProceso = (token) => {
        const [state, setState] = useState({
            tipo_procesos: [],
        });

        useEffect(() => {
            getTipoProcesoEtapaProcesal(token).then((tipo_procesos) => {
                setState({
                    tipo_procesos: tipo_procesos,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        estado: "",
        tipo_proceso: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }
    const { tipo_procesos } = useFetchTipoProceso(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Tipo Proceso" />
                    <div className="mt-1">
                        <select
                            id="tipo_proceso"
                            name="tipo_proceso"
                            value={state.tipo_proceso}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir tipo proceso</option>
                            {tipo_procesos?.results?.map((item) => {
                                return (
                                    <option key={item.tipo_proceso} value={item.id}>
                                        {item.tipo_proceso}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `tipo_proceso=${state.tipo_proceso}&estado=${state.estado}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
