import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import {
    putGestionaCriterioCierre
} from "./services";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import toast from "react-hot-toast";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextAreaSiavi } from "../../../components/common/TextArea";
import { bloqueoFecha } from "../../../helpers/utils";

export const Gestionar = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const cierre = props?.cierre;

    return (
        <div>
            <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-4">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Motivo Cierre</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.motivo?.nombre}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Criterio Cierre del Motivo</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.motivo?.motivo_criterios_cierre_detalle?.descripcion}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Fecha Gestión</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.fecha_gestiona}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Número de Expediente</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.expediente?.numero_expediente}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Observaciones Actuales</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {cierre?.observaciones}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <Formik
                initialValues={{
                    cierre_autorizado: cierre.cierre_autorizado,
                    gestiona_cierre: cierre?.gestiona_cierre
                }}
                onSubmit={async (values) => {
                    values.gestiona_cierre = values.gestiona_cierre;
                    values.fecha_gestiona = bloqueoFecha();
                    values.cierre_autorizado = values.cierre_autorizado;
                    values.observaciones = values.observaciones;
                    values.expediente = cierre?.expediente?.id;
                    values.caso = cierre?.caso?.id;
                    values.profesional_solicita = cierre?.profesional_solicita?.id;
                    values.motivo = cierre?.motivo;
                    values.profesional_gestiona = values.profesional_gestiona?.id;
                    await putGestionaCriterioCierre(
                        cierre.id,
                        values,
                        token
                    ).then((data) => {
                        if (data?.message !== undefined) {
                            toast.error(data.message);
                        } else {
                            props.setReFetch(true);
                            props.setAgregar(false);
                            toast.success("Gestión de Expediente actualizado con éxito...!");
                            props.setReFetch(false);
                        }
                    });
                }}
            >
                {({ values, handleSubmit, isSubmitting, handleChange }) => (
                    <form>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 mt-5">
                                <TextAreaSiavi
                                    label="Observaciones en la Gestión"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-3 mt-5">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        values.gestiona_cierre = true;
                                        values.cierre_autorizado = true;
                                        values.profesional_gestiona = idUser;
                                        handleSubmit();
                                    }}
                                >
                                    Autorizar
                                </button>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        values.gestiona_cierre = true;
                                        values.cierre_autorizado = false;
                                        values.profesional_gestiona = idUser;
                                        handleSubmit();
                                    }}
                                >
                                    Rechazar
                                </button>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    onClick={() => {
                                        props.setAgregar(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                        {
                            values.gestiona_cierre &&
                            <div className="mt-4">
                                <Alert type="warning" mensaje="¿Una vez autorizado no podrá editarlo nuevamente desea continuar? " />
                            </div>
                        }
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
