import { React, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";

export default function Filtros(props) {
    let filtros = "";

    const [state, setState] = useState({
        estado: "",
        disponibilidad_por_dia: "",
        disponibilidad_por_hora: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                    <Label text="Disponibilidad por día" />
                    <div className="mt-1">
                        <select
                            name="disponibilidad_por_dia"
                            value={state.disponibilidad_por_dia}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir disponibilidad por día</option>
                            <option value="true">Sí</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <Label text="Disponibilidad por hora" />
                    <div className="mt-1">
                        <select
                            name="disponibilidad_por_hora"
                            value={state.disponibilidad_por_hora}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir disponibilidad por hora</option>
                            <option value="true">Sí</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `estado=${state.estado}&disponibilidad_por_dia=${state.disponibilidad_por_dia}&disponibilidad_por_hora=${state.disponibilidad_por_hora}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
