import React from "react";
import PrivateRoute from "./PrivateRoute";
import NotFound from '../pages/notFound';
import { Route, Switch, withRouter } from "react-router-dom";
import { Home } from "../pages/home";

import { Perfil } from '../modulos/seguridad/perfil';
import { CatalogoPreguntas } from '../modulos/seguridad/preguntas';
import { MantenimientoUsuarios } from '../modulos/seguridad/mantenimiento-usuarios';
import { RegistrarUsuario } from '../modulos/seguridad/mantenimiento-usuarios/registrar';
import { ActualizarUsuario } from '../modulos/seguridad/mantenimiento-usuarios/editar';
import { CatalogoRoles } from '../modulos/seguridad/roles';
import { EditarRoles } from '../modulos/seguridad/roles/editar/index';
import { CrearRoles } from '../modulos/seguridad/roles/crear';
import { ModuloSeguridad } from '../modulos/seguridad/';
import { RolesVista } from "../modulos/seguridad/roles_view";
import { Bitacora } from "../modulos/seguridad/bitacora";

import { CatalogoTipoProfesionales } from '../modulos/catalogos/tipoProfesional';
import { CatalogoTipoPuesto } from '../modulos/catalogos/tipoPuesto';
import { CatalogoSedes } from '../modulos/catalogos/sedes';
import { CrearSedes } from "../modulos/catalogos/sedes/crear";
import { ActualizarSede } from "../modulos/catalogos/sedes/editar";
import { Catalogos } from '../modulos/catalogos';

import { CatalogoPaises } from "../modulos/catalogos/paises";
import { CatalogoDepartamentos } from "../modulos/catalogos/departamentos";
import { CatalogoMunicipios } from "../modulos/catalogos/municipios";
import { CatalogoTipoSedes } from "../modulos/catalogos/tipoSede";
import { CatalogoCoberturaSedes } from "../modulos/catalogos/coberturaSede";
import { CatalogoCategoriaInstitucion } from "../modulos/catalogos/categoriaInstitucion";
import { CatalogoTipoReferencia } from "../modulos/catalogos/tipoReferencia";
import { CatalogoServicioInstitucion } from "../modulos/catalogos/servicioInstitucion";
import { CatalogoAreaSustantiva } from "../modulos/catalogos/areaSustantiva";
import { CatalogoSubAreaSustantiva } from "../modulos/catalogos/subAreaSustantiva";
import { CatalogoOrientacionSexual } from "../modulos/catalogos/orientacionSexual";
import { CatalogoMateria } from "../modulos/catalogos/materia";
import { CatalogoIdiomas } from "../modulos/catalogos/idiomas";
import { CatalogoReligiones } from "../modulos/catalogos/religiones";
import { CatalogoEscolaridad } from "../modulos/catalogos/escolaridad";
import { CatalogoParentesco } from "../modulos/catalogos/parentesco";
import { CatalogoEstadoCivil } from "../modulos/catalogos/estadoCivil";
import { CatalogoOrigenEtnico } from "../modulos/catalogos/origenEtnico";
import { CatalogoAptitudAtencionDiferenciada } from "../modulos/catalogos/aptitudAtencionDiferenciada";
import { CatalogoInstitucion } from "../modulos/catalogos/instituciones";
import { CatalogoTipoInstitucion } from "../modulos/catalogos/tipoInstitucion";
import { CatalogoGestionInstitucion } from "../modulos/catalogos/gestionInstitucion";
import { CrearGestionInstitucion } from "../modulos/catalogos/gestionInstitucion/crear";
import { ActualizarGestionInstitucion } from "../modulos/catalogos/gestionInstitucion/editar";
import { CatalogoServicioInstituto } from "../modulos/catalogos/servicioInstituto";
import { CatalogoIdiomaMaya } from "../modulos/catalogos/idiomasmayas";
import { CatalogoNacionalidad } from "../modulos/catalogos/nacionalidad";
import { CatalogoTipoDocumento } from "../modulos/catalogos/tipoDocumento";
import { CatalogoProfesion } from "../modulos/catalogos/profesion";
import { CatalogoEstadoProceso } from "../modulos/catalogos/estadoProceso";
import { CatalogoDelito } from "../modulos/catalogos/delitos";
import { CatalogoSubcategoriaDelito } from "../modulos/catalogos/subcategoriaDelitos";
import { CatalogoTipoAcompanamiento } from "../modulos/catalogos/tipoAcompanamiento";
import { CatalogoNoAdmisibilidad } from "../modulos/catalogos/noAdmisibilidad";
import { CatalogoAdmisibilidad } from "../modulos/catalogos/admisibilidad";
import { CatalogoViabilidad } from "../modulos/catalogos/viabilidad";
import { CatalogoNoViabilidad } from "../modulos/catalogos/noViabilidad";
import { CatalogoDiscapacidad } from "../modulos/catalogos/discapacidad";
import { CatalogoTipoContacto } from "../modulos/catalogos/tipoContacto";
import { CatalogoGrupoHabitacional } from "../modulos/catalogos/grupoHabitacional";
import { CatalogoVialidad } from "../modulos/catalogos/vialidad";
import { CatalogoEtapaProcesal } from "../modulos/catalogos/etapaProcesal";
import { CatalogoAtenciones } from "../modulos/catalogos/atenciones";
import { CatalogoPoblacion } from "../modulos/catalogos/poblacion";
import { CatalogoTipoProceso } from "../modulos/catalogos/tipoProceso";
import { CatalogoStatus } from "../modulos/catalogos/status";
import { CatalogoSituacionJuridica } from "../modulos/catalogos/situacionJuridica";
import { CatalogoTipoPersona } from "../modulos/catalogos/tipoPersona";
import { CatalogoMedidaDesjudicializadora } from "../modulos/catalogos/medidaDesjudicializadora";
import { CatalogoResultadoAudiencia } from "../modulos/catalogos/resultadoAudiencia";
import { CatalogoInterponente } from "../modulos/catalogos/interponente";
import { CatalogoTipoApelacionEspecial } from "../modulos/catalogos/tipoApelacionEspecial";
import { CatalogoNivelRiesgo } from "../modulos/catalogos/nivelRiesgo";
import { CatalogoTipoRiesgo } from "../modulos/catalogos/tipoRiesgo";
import { CatalogoStatusPersona } from "../modulos/catalogos/statusPersona";
import { CatalogoConsentimientoInformado } from "../modulos/catalogos/consentimientoInformado";
import { CatalogoReparacionDigna } from "../modulos/catalogos/reparacionDigna";
import { CatalogoTipoProcesoSecundario } from "../modulos/catalogos/tipoProcesoSecundario";
import { CatalogoEstadoProcesoSecundario } from "../modulos/catalogos/estadoProcesoSecundario";
import { CatalogoSubdominioRecuperacion } from "../modulos/catalogos/subdominioRecuperacion";
import { CatalogoDominioRecuperacion } from "../modulos/catalogos/dominioRecuperacion";
import { CatalogoClaseDocumento } from "../modulos/catalogos/documento";
import { CatalogoIdentidadGenero } from "../modulos/catalogos/identidadGenero";
import { CatalogoSituacionLaboral } from "../modulos/catalogos/situacionLaboral";
import { CatalogoRedesSociales } from "../modulos/catalogos/redesSociales";
import { CatalogoMotivoVisita } from "../modulos/catalogos/motivoVisita";
import { CatalogoTipoMensaje } from "../modulos/catalogos/tipoMensaje";
import { CatalogoTipoPregunta } from "../modulos/catalogos/tipoPregunta";
import { CatalogoFormularios } from "../modulos/catalogos/formulario";
import { CrearFormulario } from "../modulos/catalogos/formulario/crear";
import { ActualizarFormulario } from "../modulos/catalogos/formulario/editar";
import { CatalogoSeleccion } from "../modulos/catalogos/catalogoSeleccion";
import { CatalogoTipoConsulta } from "../modulos/catalogos/tipoConsulta";
import { CatalogoTipoDoctoDerivacion } from "../modulos/catalogos/tipoDocumentoDerivacion";
import { CatalogoPeligrosidadSindicado } from "../modulos/catalogos/peligrosidadSindicado";
import { CatalogoTipoDiligencias } from "../modulos/catalogos/tipoDiligencias";
import { CatalogoModalidad } from "../modulos/catalogos/modalidad";
import { CatalogoTipoValorEncuesta } from "../modulos/catalogos/tipoValorEncuesta";
import { CatalogoAccionVictimologica } from "../modulos/catalogos/accionVictimologica";
import { CatalogoRecursoDisponibilidad } from "../modulos/catalogos/recursoDisponibilidad";
import { CatalogoRazonExclusion } from "../modulos/catalogos/razonExclusion";
import { CatalogoRecursos } from "../modulos/catalogos/recursos";
import { ActualizarConfiguracionGeneral } from "../modulos/catalogos/configuracionGeneral/Actualizar";
import { CrearEncuesta } from "../modulos/catalogos/encuesta/crear";
import { ActualizarEncuesta } from "../modulos/catalogos/encuesta/editar";
import { CatalogosEncuestas } from "../modulos/catalogos/encuesta";
import { CatalogosSeguimientoLegal } from "../modulos/catalogos/seguimientoLegalAV";
import { CatalogoSeleccionEncuesta } from "../modulos/catalogos/catalogoSeleccionEncuesta";
import { CatalogoAgrupacionPreguntas } from "../modulos/catalogos/agrupacionPreguntas";
import { CatalogoMedidaCoercion } from "../modulos/catalogos/medidaCoercion";
import { CatalogoMotivoCambioPlanificacion } from "../modulos/catalogos/motivoCambioPlanificacion";
import { CatalogoGrupos } from "../modulos/catalogos/grupos";
import { CatalogoMotivoCriterioCierre } from "../modulos/catalogos/motivoCriterioCierre";
import { CatalogoMotivoCierre } from "../modulos/catalogos/motivoCierre";
import { CatalogoCriterioCierre } from "../modulos/catalogos/criterioCierre";
import { CatalogoTipoSms } from "../modulos/catalogos/tipoSms";
import { CatalogoBiblioteca } from "../modulos/catalogos/biblioteca";
import { CrearBiblioteca } from "../modulos/catalogos/biblioteca/crear";
import { ActualizarBiblioteca } from "../modulos/catalogos/biblioteca/editar";
import { CatalogoClasificacionBiblioteca } from "../modulos/catalogos/clasificacionBiblioteca";
import { CatalogoInsumo } from "../modulos/catalogos/insumos";
import { CatalogoResolucionOtorgaReparacionDigna } from "../modulos/catalogos/resolucionOtorgaRD";
import { CatalogoStatusMedidaReparacionDigna } from "../modulos/catalogos/statusMedidaRD";
import { CatalogoRazonNoSolicitudReparacionDigna } from "../modulos/catalogos/razonNoSolicitudRD";
import { CatalogoMedidaVictimologica } from "../modulos/catalogos/medidaVictimologica";

import { ModuloFuncionalides } from "../modulos/funcionalidades-generales/";
import { RegistrarPersona } from "../modulos/funcionalidades-generales/registrarPersona/registrar";
import { ActualizarPersona } from "../modulos/funcionalidades-generales/registrarPersona/actualizar";
import { ListadoPersonas } from "../modulos/funcionalidades-generales/registrarPersona/listar";
import { CrearOrientacion } from "../modulos/funcionalidades-generales/orientacion/crear";
import { EditarOrientacion } from "../modulos/funcionalidades-generales/orientacion/editar";
import { ListadoOrientaciones } from "../modulos/funcionalidades-generales/orientacion/listar";
import { Visitas } from "../modulos/funcionalidades-generales/visitas";
import { Casos } from "../modulos/funcionalidades-generales/casos/listar";
import { EditarCaso } from "../modulos/funcionalidades-generales/casos/editar";
import { Expedientes } from "../modulos/funcionalidades-generales/expedientes";
import { ListadoHistoricos } from "../modulos/funcionalidades-generales/casosHistoricos/listar";
import { RegistrarHistorico } from "../modulos/funcionalidades-generales/casosHistoricos/registrar";
import { ActualizarHistorico } from "../modulos/funcionalidades-generales/casosHistoricos/actualizar";
import { Referencias } from "../modulos/funcionalidades-generales/referencias";
import { ReferenciasTrabajoSocial } from "../modulos/funcionalidades-generales/referenciasTrabajoSocial";
import { RegistroNotificacionesLegales } from "../modulos/funcionalidades-generales/notificacionesLegales/listar";
import { ListadoProfesionales } from "../modulos/funcionalidades-generales/profesionales";
import { RegistroEspacioSeguro } from "../modulos/funcionalidades-generales/espacioSeguro";


import { BotonReferencias } from "../modulos/funcionalidades-generales/botonReferencias";
import { ReasignarExpedientes } from "../modulos/funcionalidades-generales/reasignacionExpedientes";
import { RegistroNombramientos } from "../modulos/funcionalidades-generales/nombramientos";
import { Acciones } from "../modulos/funcionalidades-generales/acciones";
import { AtencionAcciones } from "../modulos/funcionalidades-generales/atencionAcciones";
import { Seguimientos } from "../modulos/funcionalidades-generales/seguimientos";
import { Audiencias } from "../modulos/funcionalidades-generales/audiencias";
import { AudienciasPlanificadasTodas } from "../modulos/funcionalidades-generales/audienciasTodas";
import { RegistroGestionCriterioCierre } from "../modulos/funcionalidades-generales/cierreExpedientes";
import { RegistroGestionCriterioCierreCaso } from "../modulos/funcionalidades-generales/cierreCasos";
import { Procuraciones } from "../modulos/funcionalidades-generales/procuracionesProgramadas";
import { ListadoRecursos } from "../modulos/funcionalidades-generales/recursos/listar";
import { ListadoOrientacionesHistoricas } from "../modulos/funcionalidades-generales/expedientes_historicos/listar"

import { ModuloMisRegistros } from "../modulos/mis-registros";
import { MisExpedientes } from "../modulos/mis-registros/mis-expedientes";
import { MisRegistrosNotificacionesLegales } from "../modulos/mis-registros/notificacionesLegales/listar";
import { Notificaciones } from "../modulos/mis-registros/notificaciones";
import { MisRegistrosMisReferencias } from "../modulos/mis-registros/mis-referencias/listar";
import { MisAcciones } from "../modulos/mis-registros/acciones";
import { MisAudienciasPlanificadas } from "../modulos/mis-registros/mis-audiencias";
import { ModuloFavoritos } from "../modulos/favoritos";

import { ModuloAtencionVictimologica } from "../modulos/atencion_victimologica";

import { ModuloAsistenciaLegal } from "../modulos/asistencia_legal";
import { RegistroBiblioteca } from "../modulos/funcionalidades-generales/biblioteca";
import { ReasignarExpedientesAsistenciaLegal } from "../modulos/asistencia_legal/reasignacionExpedientes";
import { ReasignarExpedientesAtencion } from "../modulos/atencion_victimologica/reasignacionExpedientes";
import { RegistroGestionCriterioCierreAsistencia } from "../modulos/asistencia_legal/cierreExpedientes";
import { RegistroGestionCriterioCierreAtencion } from "../modulos/atencion_victimologica/cierreExpedientes";

import { TablePreguntasFrecuentes } from "../modulos/ayuda/preguntas";
import { MisSeguimientos } from "../modulos/mis-registros/seguimientos";
import { MisProcuraciones } from "../modulos/mis-registros/procuracionesProgramadas";
import { MisAudiencias } from "../modulos/mis-registros/audiencias";
import { MisListadoOrientacionesHistoricas } from "../modulos/mis-registros/expedientes_historicos/listar";
import { MisAtencionAcciones } from "../modulos/mis-registros/atencionAcciones";
import { ModuloReportes } from "../modulos/reportes";
import { DALReporteSedes} from "../modulos/reportes/dal/sedes"
import { DALReporteProfesional } from "../modulos/reportes/dal/profesional";
import { DALReporteAudiencias } from "../modulos/reportes/dal/audiencias";
import { DALReporteAudienciasCaso  } from "../modulos/reportes/dal/audienciascaso/listar";
import { DALReporteSentencia } from "../modulos/reportes/dal/sentencias";
import { DSVReporteAccionesVictimologicas } from "../modulos/reportes/dsv/accionesVictimologicas";
import { DSVReporteAccionesPorArea } from "../modulos/reportes/dsv/accionesPorArea";
import { DSVReporteProfesionales } from "../modulos/reportes/dsv/profesionales";
import { DSVReporteInformacionVictimologica } from "../modulos/reportes/dsv/InformacionVictimologica";
import { DSVReporteVisitas } from "../modulos/reportes/dsv/visitas";
import { DALReporteDesjudicializadora } from "../modulos/reportes/dal/desjudicializadora";
import { DALReporteMetas } from "../modulos/reportes/dal/metas";
import { DSVReporteExpedientesPorMesa } from "../modulos/reportes/dsv/expedientesPorMesa";
import { DSVReporteInformes } from "../modulos/reportes/dsv/informes";
import { DALReporteReparacion } from "../modulos/reportes/dal/reparacion";
import { DALReporteCasos } from "../modulos/reportes/dal/casos";
import { DALReporteImpugnacion } from "../modulos/reportes/dal/impugnacion";
import { DALReporteIndicadores } from "../modulos/reportes/dal/indicadores";
import { DSVReporteExpedientes } from "../modulos/reportes/dsv/expedientesPendientes";
import { DSVReporteReferencias } from "../modulos/reportes/dsv/referencias";
import { DSVReportePlanificacion } from "../modulos/reportes/dsv/planificacion";
import { DSVReporteAtencionesVictimologicas } from "../modulos/reportes/dsv/atencionesVictimologicas";
import { DSVReporteMetasPsicologia } from "../modulos/reportes/dsv/metasPsicologia";
import { DSVReporteMetasTrabajo } from "../modulos/reportes/dsv/metasTrabajo";
import { DSVReporteMetasMedicina } from "../modulos/reportes/dsv/metasMedicina";
import { DSVReportePlanTratamiento } from "../modulos/reportes/dsv/planTratamiento";
import { DSVReporteMatrizBeneficiarios } from "../modulos/reportes/dsv/matrizBeneficiarios";
import { DSVReporteCierreCasos } from "../modulos/reportes/dsv/cierreCasos";
import { DSVReporteEvaluacionDiagnostica } from "../modulos/reportes/dsv/evaluacionDiagnostica";
import { DALReporteMetasDAL } from "../modulos/reportes/dal/metasDAL";
import { DALReporteFichaMedicion } from "../modulos/reportes/dal/FichaMedicion";
import { DALReporteDatosSociodemograficos } from "../modulos/reportes/dal/datos";
import { DALReporteIndicadoresOrientacionesCasos } from "../modulos/reportes/dal/IndicadoresOrientacionesCasos";
import { DALReporteIndicadoresSentencias } from "../modulos/reportes/dal/IndicadoresSentencias";
import { DALReporteIndicadoresReparaciones } from "../modulos/reportes/dal/IndicadoresReparaciones";
import { DALReporteListadoExpedientes } from "../modulos/reportes/dal/ListadoExpedientes";
class AppRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path={`/`}
          exact
          component={Home}
        />
        <PrivateRoute
          path={`/perfil-usuario`}
          exact
          component={Perfil}
        />
        {/***************** modulo de catalogos *******************/}
        <PrivateRoute
          path={`/catalogos`}
          exact
          component={Catalogos}
        />
        <PrivateRoute
          path={`/catalogos/tipo-sms-victima`}
          exact
          component={CatalogoTipoSms}
        />
        <PrivateRoute
          path={`/catalogos/tipo-profesional`}
          exact
          component={CatalogoTipoProfesionales}
        />
        <PrivateRoute
          path={`/catalogos/tipo-puesto`}
          exact
          component={CatalogoTipoPuesto}
        />
        <PrivateRoute
          path={`/catalogos/sedes`}
          exact
          component={CatalogoSedes}
        />
        <PrivateRoute
          path={`/catalogos/sedes/crear`}
          exact
          component={CrearSedes}
        />
        <PrivateRoute
          path={`/catalogos/sedes/editar/:id`}
          exact
          component={ActualizarSede}
        />
        <PrivateRoute
          path={`/catalogos/paises`}
          exact
          component={CatalogoPaises}
        />
        <PrivateRoute
          path={`/catalogos/departamentos`}
          exact
          component={CatalogoDepartamentos}
        />
        <PrivateRoute
          path={`/catalogos/municipios`}
          exact
          component={CatalogoMunicipios}
        />
        <PrivateRoute
          path={`/catalogos/tipo-sede`}
          exact
          component={CatalogoTipoSedes}
        />
        <PrivateRoute
          path={`/catalogos/cobertura-sede`}
          exact
          component={CatalogoCoberturaSedes}
        />
        <PrivateRoute
          path={`/catalogos/categoria-institucion`}
          exact
          component={CatalogoCategoriaInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/tipo-referencia`}
          exact
          component={CatalogoTipoReferencia}
        />
        <PrivateRoute
          path={`/catalogos/servicio`}
          exact
          component={CatalogoServicioInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/area-sustantiva`}
          exact
          component={CatalogoAreaSustantiva}
        />
        <PrivateRoute
          path={`/catalogos/sub-area-sustantiva`}
          exact
          component={CatalogoSubAreaSustantiva}
        />
        <PrivateRoute
          path={`/catalogos/orientacion-sexual`}
          exact
          component={CatalogoOrientacionSexual}
        />
        <PrivateRoute
          path={`/catalogos/materia`}
          exact
          component={CatalogoMateria}
        />
        <PrivateRoute
          path={`/catalogos/idiomas`}
          exact
          component={CatalogoIdiomas}
        />
        <PrivateRoute
          path={`/catalogos/religiones`}
          exact
          component={CatalogoReligiones}
        />
        <PrivateRoute
          path={`/catalogos/escolaridad`}
          exact
          component={CatalogoEscolaridad}
        />
        <PrivateRoute
          path={`/catalogos/parentesco`}
          exact
          component={CatalogoParentesco}
        />
        <PrivateRoute
          path={`/catalogos/estado-civil`}
          exact
          component={CatalogoEstadoCivil}
        />
        <PrivateRoute
          path={`/catalogos/origen-etnico`}
          exact
          component={CatalogoOrigenEtnico}
        />
        <PrivateRoute
          path={`/catalogos/aptitud-atencion-diferenciada`}
          exact
          component={CatalogoAptitudAtencionDiferenciada}
        />
        <PrivateRoute
          path={`/catalogos/instituciones`}
          exact
          component={CatalogoInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/tipo-institucion`}
          exact
          component={CatalogoTipoInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/gestion-instituciones`}
          exact
          component={CatalogoGestionInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/gestion-instituciones/crear`}
          exact
          component={CrearGestionInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/gestion-instituciones/editar/:id`}
          exact
          component={ActualizarGestionInstitucion}
        />
        <PrivateRoute
          path={`/catalogos/servicio-instituto`}
          exact
          component={CatalogoServicioInstituto}
        />
        <PrivateRoute
          path={`/catalogos/idioma-maya`}
          exact
          component={CatalogoIdiomaMaya}
        />
        <PrivateRoute
          path={`/catalogos/nacionalidad`}
          exact
          component={CatalogoNacionalidad}
        />
        <PrivateRoute
          path={`/catalogos/tipo-documento`}
          exact
          component={CatalogoTipoDocumento}
        />
        <PrivateRoute
          path={`/catalogos/profesion`}
          exact
          component={CatalogoProfesion}
        />
        <PrivateRoute
          path={`/catalogos/estado-proceso`}
          exact
          component={CatalogoEstadoProceso}
        />
        <PrivateRoute
          path={`/catalogos/delito`}
          exact
          component={CatalogoDelito}
        />
        <PrivateRoute
          path={`/catalogos/subcategoria-delito`}
          exact
          component={CatalogoSubcategoriaDelito}
        />
        <PrivateRoute
          path={`/catalogos/tipo-acompanamiento`}
          exact
          component={CatalogoTipoAcompanamiento}
        />
        <PrivateRoute
          path={`/catalogos/no-admisibilidad`}
          exact
          component={CatalogoNoAdmisibilidad}
        />
        <PrivateRoute
          path={`/catalogos/admisibilidad`}
          exact
          component={CatalogoAdmisibilidad}
        />
        <PrivateRoute
          path={`/catalogos/viabilidad`}
          exact
          component={CatalogoViabilidad}
        />
        <PrivateRoute
          path={`/catalogos/no-viabilidad`}
          exact
          component={CatalogoNoViabilidad}
        />
        <PrivateRoute
          path={`/catalogos/discapacidad`}
          exact
          component={CatalogoDiscapacidad}
        />
        <PrivateRoute
          path={`/catalogos/tipo-contacto`}
          exact
          component={CatalogoTipoContacto}
        />
        <PrivateRoute
          path={`/catalogos/grupo-habitacional`}
          exact
          component={CatalogoGrupoHabitacional}
        />
        <PrivateRoute
          path={`/catalogos/vialidad`}
          exact
          component={CatalogoVialidad}
        />
        <PrivateRoute
          path={`/catalogos/etapa-procesal`}
          exact
          component={CatalogoEtapaProcesal}
        />
        <PrivateRoute
          path={`/catalogos/atencion`}
          exact
          component={CatalogoAtenciones}
        />
        <PrivateRoute
          path={`/catalogos/poblacion`}
          exact
          component={CatalogoPoblacion}
        />
        <PrivateRoute
          path={`/catalogos/tipo-proceso`}
          exact
          component={CatalogoTipoProceso}
        />
        <PrivateRoute
          path={`/catalogos/status`}
          exact
          component={CatalogoStatus}
        />
        <PrivateRoute
          path={`/catalogos/situacion-juridica`}
          exact
          component={CatalogoSituacionJuridica}
        />
        <PrivateRoute
          path={`/catalogos/tipo-persona`}
          exact
          component={CatalogoTipoPersona}
        />
        <PrivateRoute
          path={`/catalogos/motivo-cierre`}
          exact
          component={CatalogoMotivoCierre}
        />
        <PrivateRoute
          path={`/catalogos/criterio-cierre`}
          exact
          component={CatalogoCriterioCierre}
        />
        <PrivateRoute
          path={`/catalogos/motivo-criterio-cierre`}
          exact
          component={CatalogoMotivoCriterioCierre}
        />
        <PrivateRoute
          path={`/catalogos/medida-desjudicializadora`}
          exact
          component={CatalogoMedidaDesjudicializadora}
        />
        <PrivateRoute
          path={`/catalogos/resultado-audiencia`}
          exact
          component={CatalogoResultadoAudiencia}
        />
        <PrivateRoute
          path={`/catalogos/interponente`}
          exact
          component={CatalogoInterponente}
        />
        <PrivateRoute
          path={`/catalogos/tipo-apelacion-especial-casacion`}
          exact
          component={CatalogoTipoApelacionEspecial}
        />
        <PrivateRoute
          path={`/catalogos/nivel-riesgo`}
          exact
          component={CatalogoNivelRiesgo}
        />
        <PrivateRoute
          path={`/catalogos/tipo-riesgo`}
          exact
          component={CatalogoTipoRiesgo}
        />
        <PrivateRoute
          path={`/catalogos/status-persona`}
          exact
          component={CatalogoStatusPersona}
        />
        <PrivateRoute
          path={`/catalogos/configuracion`}
          exact
          component={ActualizarConfiguracionGeneral}
        />
        <PrivateRoute
          path={`/catalogos/consentimiento-informado`}
          exact
          component={CatalogoConsentimientoInformado}
        />
        <PrivateRoute
          path={`/catalogos/reparacion-digna`}
          exact
          component={CatalogoReparacionDigna}
        />
        <PrivateRoute
          path={`/catalogos/tipo-proceso-secundario`}
          exact
          component={CatalogoTipoProcesoSecundario}
        />
        <PrivateRoute
          path={`/catalogos/estado-proceso-secundario`}
          exact
          component={CatalogoEstadoProcesoSecundario}
        />
        <PrivateRoute
          path={`/catalogos/subdominio-recuperacion`}
          exact
          component={CatalogoSubdominioRecuperacion}
        />
        <PrivateRoute
          path={`/catalogos/dominio-recuperacion`}
          exact
          component={CatalogoDominioRecuperacion}
        />
        <PrivateRoute
          path={`/catalogos/clase-documento`}
          exact
          component={CatalogoClaseDocumento}
        />
        <PrivateRoute
          path={`/catalogos/identidad-genero`}
          exact
          component={CatalogoIdentidadGenero}
        />
        <PrivateRoute
          path={`/catalogos/situacion-laboral`}
          exact
          component={CatalogoSituacionLaboral}
        />
        <PrivateRoute
          path={`/catalogos/redes-sociales`}
          exact
          component={CatalogoRedesSociales}
        />
        <PrivateRoute
          path={`/catalogos/motivo-visita`}
          exact
          component={CatalogoMotivoVisita}
        />
        <PrivateRoute
          path={`/catalogos/tipo-mensaje`}
          exact
          component={CatalogoTipoMensaje}
        />
        <PrivateRoute
          path={`/catalogos/tipo-pregunta`}
          exact
          component={CatalogoTipoPregunta}
        />
        <PrivateRoute
          path={`/catalogos/clasificacion-biblioteca`}
          exact
          component={CatalogoClasificacionBiblioteca}
        />
        <PrivateRoute
          path={`/catalogos/formulario`}
          exact
          component={CatalogoFormularios}
        />
        <PrivateRoute
          path={`/catalogos/formulario/crear`}
          exact
          component={CrearFormulario}
        />
        <PrivateRoute
          path={`/catalogos/formulario/editar/:id`}
          exact
          component={ActualizarFormulario}
        />
        <PrivateRoute
          path={`/catalogos/biblioteca`}
          exact
          component={CatalogoBiblioteca}
        />
        <PrivateRoute
          path={`/catalogos/biblioteca/crear`}
          exact
          component={CrearBiblioteca}
        />
        <PrivateRoute
          path={`/catalogos/biblioteca/editar/:id`}
          exact
          component={ActualizarBiblioteca}
        />
        <PrivateRoute
          path={`/catalogos/seleccion`}
          exact
          component={CatalogoSeleccion}
        />
        <PrivateRoute
          path={`/catalogos/seleccion-encuesta`}
          exact
          component={CatalogoSeleccionEncuesta}
        />
        <PrivateRoute
          path={`/catalogos/tipo-consulta`}
          exact
          component={CatalogoTipoConsulta}
        />
        <PrivateRoute
          path={`/catalogos/tipo-docto-derivacion`}
          exact
          component={CatalogoTipoDoctoDerivacion}
        />
        <PrivateRoute
          path={`/catalogos/peligrosidad-sindicado`}
          exact
          component={CatalogoPeligrosidadSindicado}
        />
        <PrivateRoute
          path={`/catalogos/tipo-diligencias`}
          exact
          component={CatalogoTipoDiligencias}
        />
        <PrivateRoute
          path={`/catalogos/modalidad`}
          exact
          component={CatalogoModalidad}
        />
        <PrivateRoute
          path={`/catalogos/tipo-valor-encuesta`}
          exact
          component={CatalogoTipoValorEncuesta}
        />
        <PrivateRoute
          path={`/catalogos/accion-victimologica`}
          exact
          component={CatalogoAccionVictimologica}
        />
        <PrivateRoute
          path={`/catalogos/seguimiento-legal`}
          exact
          component={CatalogosSeguimientoLegal}
        />
        <PrivateRoute
          path={`/catalogos/recursos`}
          exact
          component={CatalogoRecursos}
        />
        <PrivateRoute
          path={`/catalogos/coercion`}
          exact
          component={CatalogoMedidaCoercion}
        />
        <PrivateRoute
          path={`/catalogos/grupos`}
          exact
          component={CatalogoGrupos}
        />
        <PrivateRoute
          path={`/catalogos/motivo-cambio-planificacion`}
          exact
          component={CatalogoMotivoCambioPlanificacion}
        />
        <PrivateRoute
          path={`/catalogos/recurso-disponibilidad`}
          exact
          component={CatalogoRecursoDisponibilidad}
        />
        <PrivateRoute
          path={`/catalogos/razon-exclusion`}
          exact
          component={CatalogoRazonExclusion}
        />
        <PrivateRoute
          path={`/catalogos/agrupacion-preguntas`}
          exact
          component={CatalogoAgrupacionPreguntas}
        />
        <PrivateRoute
          path={`/catalogos/encuesta`}
          exact
          component={CatalogosEncuestas}
        />
        <PrivateRoute
          path={`/catalogos/encuesta/crear`}
          exact
          component={CrearEncuesta}
        />
        <PrivateRoute
          path={`/catalogos/encuesta/editar/:id`}
          exact
          component={ActualizarEncuesta}
        />
        <PrivateRoute
          path={`/catalogos/insumos`}
          exact
          component={CatalogoInsumo}
        />
        <PrivateRoute
          path={`/catalogos/resolucion-otorga-reparacion-digna`}
          exact
          component={CatalogoResolucionOtorgaReparacionDigna}
        />
        <PrivateRoute
          path={`/catalogos/status-medida-reparacion-digna`}
          exact
          component={CatalogoStatusMedidaReparacionDigna}
        />
        <PrivateRoute
          path={`/catalogos/razon-no-solicitud-reparacion-digna`}
          exact
          component={CatalogoRazonNoSolicitudReparacionDigna}
        />
        <PrivateRoute
          path={`/catalogos/medida-victimologica`}
          exact
          component={CatalogoMedidaVictimologica}
        />



        {/***************** modulo de seguridad *******************/}
        <PrivateRoute
          path={`/seguridad`}
          exact
          component={ModuloSeguridad}
        />
        <PrivateRoute
          path={`/seguridad/usuarios`}
          exact
          component={MantenimientoUsuarios}
        />
        <PrivateRoute
          path={`/seguridad/usuarios/editar/:id`}
          exact
          component={ActualizarUsuario}
        />
        <PrivateRoute
          path={`/seguridad/usuarios/registrar`}
          exact
          component={RegistrarUsuario}
        />
        <PrivateRoute
          path={`/seguridad/roles`}
          exact
          component={CatalogoRoles}
        />
        <PrivateRoute
          path={`/seguridad/roles-vista`}
          exact
          component={RolesVista}
        />
        <PrivateRoute
          path={`/seguridad/roles/crear`}
          exact
          component={CrearRoles}
        />
        <PrivateRoute
          path={`/seguridad/roles/editar/:id`}
          exact
          component={EditarRoles}
        />
        <PrivateRoute
          path={`/seguridad/bitacora`}
          exact
          component={Bitacora}
        />
        <PrivateRoute
          path={`/seguridad/preguntas`}
          exact
          component={CatalogoPreguntas}
        />
        {/***************** modulo de funcionalidades generales *******************/}
        <PrivateRoute
          path={`/funcionalidades-generales/`}
          exact
          component={ModuloFuncionalides}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/registrar`}
          exact
          component={RegistrarPersona}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/actualizar/:id`}
          exact
          component={ActualizarPersona}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/listar`}
          exact
          component={ListadoPersonas}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/listar-orientacion`}
          exact
          component={ListadoOrientaciones}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/crear-orientacion/:id`}
          exact
          component={CrearOrientacion}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/editar-orientacion/:id`}
          exact
          component={EditarOrientacion}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/visitas`}
          exact
          component={Visitas}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/casos`}
          exact
          component={Casos}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/editar-caso/:id`}
          exact
          component={EditarCaso}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/expedientes`}
          exact
          component={Expedientes}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/reasignar-expedientes`}
          exact
          component={ReasignarExpedientes}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/registrar-historico`}
          exact
          component={RegistrarHistorico}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/actualizar-historico/:id`}
          exact
          component={ActualizarHistorico}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/listar-historico`}
          exact
          component={ListadoHistoricos}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/expedientes-historicos`}
          exact
          component={ListadoOrientacionesHistoricas}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/referencias`}
          exact
          component={Referencias}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/boton-referencias`}
          exact
          component={BotonReferencias}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/registro-nombramientos`}
          exact
          component={RegistroNombramientos}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/referencias-trabajo-social`}
          exact
          component={ReferenciasTrabajoSocial}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/notificaciones-legales`}
          exact
          component={RegistroNotificacionesLegales}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/acciones`}
          exact
          component={Acciones}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/atencion-acciones`}
          exact
          component={AtencionAcciones}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/seguimientos`}
          exact
          component={Seguimientos}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/audiencias-planificadas`}
          exact
          component={Audiencias}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/procuraciones-programadas`}
          exact
          component={Procuraciones}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/audiencias`}
          exact
          component={AudienciasPlanificadasTodas}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/gestion-cierre-expediente`}
          exact
          component={RegistroGestionCriterioCierre}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/gestion-cierre-caso`}
          exact
          component={RegistroGestionCriterioCierreCaso}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/recursos`}
          exact
          component={ListadoRecursos}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/biblioteca`}
          exact
          component={RegistroBiblioteca}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/profesionales`}
          exact
          component={ListadoProfesionales}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/espacio-seguro`}
          exact
          component={RegistroEspacioSeguro}
        />

        {/***************** modulo de mis registros *******************/}
        <PrivateRoute
          path={`/mis-registros`}
          exact
          component={ModuloMisRegistros}
        />
        <PrivateRoute
          path={`/mis-expedientes`}
          exact
          component={MisExpedientes}
        />
        <PrivateRoute
          path={`/mis-notificaciones-legales`}
          exact
          component={MisRegistrosNotificacionesLegales}
        />
        <PrivateRoute
          path={`/mis-referencias`}
          exact
          component={MisRegistrosMisReferencias}
        />
        <PrivateRoute
          path={`/mis-acciones`}
          exact
          component={MisAcciones}
        />
        <PrivateRoute
          path={`/mis-seguimientos`}
          exact
          component={MisSeguimientos}
        />
        <PrivateRoute
          path={`/mis-procuraciones`}
          exact
          component={MisProcuraciones}
        />
        <PrivateRoute
          path={`/mis-audiencias`}
          exact
          component={MisAudiencias}
        />
        <PrivateRoute
          path={`/mis-audiencias-planificadas`}
          exact
          component={MisAudienciasPlanificadas}
        />
        <PrivateRoute
          path={`/mis-expedientes-historicos`}
          exact
          component={MisListadoOrientacionesHistoricas}
        />
        <PrivateRoute
          path={`/mis-atenciones-acciones`}
          exact
          component={MisAtencionAcciones}
        />
        <PrivateRoute
          path={`/notificaciones`}
          exact
          component={Notificaciones}
        />

        {/***************** modulo de atencion victimologica *******************/}
        <PrivateRoute
          path={`/atencion-victimologica`}
          exact
          component={ModuloAtencionVictimologica}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/reasignar-expedientes-atencion`}
          exact
          component={ReasignarExpedientesAtencion}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/gestion-cierre-expediente-atencion`}
          exact
          component={RegistroGestionCriterioCierreAtencion}
        />

        {/***************** modulo de asistencia legal *******************/}
        <PrivateRoute
          path={`/asistencia-legal`}
          exact
          component={ModuloAsistenciaLegal}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/reasignar-expedientes-asistencia-legal`}
          exact
          component={ReasignarExpedientesAsistenciaLegal}
        />
        <PrivateRoute
          path={`/funcionalidades-generales/gestion-cierre-expediente-asistencia-legal`}
          exact
          component={RegistroGestionCriterioCierreAsistencia}
        />
        <PrivateRoute
          path={`/favoritos`}
          exact
          component={ModuloFavoritos}
        />

        {/***************** modulo de ayuda *******************/}
        <PrivateRoute
          path={`/ayuda`}
          exact
          component={TablePreguntasFrecuentes}
        />

        {/***************** modulo de reportes DAL*******************/}
        <PrivateRoute
          path={`/reportes`}
          exact
          component={ModuloReportes}
        />
        <PrivateRoute
          path={`/reportes/dal/sedes`}
          exact
          component={DALReporteSedes}
        />
        <PrivateRoute
          path={`/reportes/dal/profesional`}
          exact
          component={DALReporteProfesional}
        />
        <PrivateRoute
          path={`/reportes/dal/audiencias`}
          exact
          component={DALReporteAudiencias}
        />
        <PrivateRoute
          path={`/reportes/dal/audienciascaso`}
          exact
          component={DALReporteAudienciasCaso}
        />
        <PrivateRoute
          path={`/reportes/dal/sentencias`}
          exact
          component={DALReporteSentencia}
        />
        <PrivateRoute
          path={`/reportes/dal/desjudicializadora`}
          exact
          component={DALReporteDesjudicializadora}
        />
        <PrivateRoute
          path={`/reportes/dal/datos`}
          exact
          component={DALReporteDatosSociodemograficos}
        />
        <PrivateRoute
          path={`/reportes/dal/metas`}
          exact
          component={DALReporteMetas}
        />
        <PrivateRoute
          path={`/reportes/dal/metasDAL`}
          exact
          component={DALReporteMetasDAL}
        />
        <PrivateRoute
          path={`/reportes/dal/fichamedicion`}
          exact
          component={DALReporteFichaMedicion}
        />
        <PrivateRoute
          path={`/reportes/dal/reparacion`}
          exact
          component={DALReporteReparacion}
        />
        <PrivateRoute
          path={`/reportes/dal/casos`}
          exact
          component={DALReporteCasos}
        />
        <PrivateRoute
          path={`/reportes/dal/impugnaciones`}
          exact
          component={DALReporteImpugnacion}
        />
        <PrivateRoute
          path={`/reportes/dal/indicadores`}
          exact
          component={DALReporteIndicadores}
        />
        <PrivateRoute
          path={`/reportes/dal/indicadoresorientacionescasos`}
          exact
          component={DALReporteIndicadoresOrientacionesCasos}
        />
        <PrivateRoute
          path={`/reportes/dal/indicadoressentencias`}
          exact
          component={DALReporteIndicadoresSentencias}
        />
        <PrivateRoute
          path={`/reportes/dal/indicadoresreparaciones`}
          exact
          component={DALReporteIndicadoresReparaciones}
        />
        <PrivateRoute
          path={`/reportes/dal/listadoexpedientes`}
          exact
          component={DALReporteListadoExpedientes}
        />

        {/***************** modulo de reportes DSV*******************/}
        <PrivateRoute
          path={`/reportes/dsv/acciones-victimologicas`}
          exact
          component={DSVReporteAccionesVictimologicas}
        />
        <PrivateRoute
          path={`/reportes/dsv/acciones-por-area`}
          exact
          component={DSVReporteAccionesPorArea}
        />
        <PrivateRoute
          path={`/reportes/dsv/profesionales`}
          exact
          component={DSVReporteProfesionales}
        />
         <PrivateRoute
          path={`/reportes/dsv/informacion-victimologica`}
          exact
          component={DSVReporteInformacionVictimologica}
        />
        <PrivateRoute
          path={`/reportes/dsv/visitas`}
          exact
          component={DSVReporteVisitas}
        />
        <PrivateRoute
          path={`/reportes/dsv/expedientes`}
          exact
          component={DSVReporteExpedientesPorMesa}
        />
        <PrivateRoute
          path={`/reportes/dsv/informes`}
          exact
          component={DSVReporteInformes}
        />
        <PrivateRoute
          path={`/reportes/dsv/expedientesPendientes`}
          exact
          component={DSVReporteExpedientes}
        />
        <PrivateRoute
          path={`/reportes/dsv/referencias`}
          exact
          component={DSVReporteReferencias}
        />
        <PrivateRoute
          path={`/reportes/dsv/planificacion`}
          exact
          component={DSVReportePlanificacion}
        />
        {/* Quinta entrega  */ }
        <PrivateRoute
          path={`/reportes/dsv/atenciones`}
          exact
          component={DSVReporteAtencionesVictimologicas}
        />
        <PrivateRoute
          path={`/reportes/dsv/metaspsicologia`}
          exact
          component={DSVReporteMetasPsicologia}
        />
        <PrivateRoute
          path={`/reportes/dsv/metastrabajo`}
          exact
          component={DSVReporteMetasTrabajo}
        />
        <PrivateRoute
          path={`/reportes/dsv/metasmedicina`}
          exact
          component={DSVReporteMetasMedicina}
        />
        <PrivateRoute
          path={`/reportes/dsv/plantratamiento`}
          exact
          component={DSVReportePlanTratamiento}
        />
        <PrivateRoute
          path={`/reportes/dsv/matrizBeneficiarios`}
          exact
          component={DSVReporteMatrizBeneficiarios}
        />
        <PrivateRoute
          path={`/reportes/dsv/cierrecasos`}
          exact
          component={DSVReporteCierreCasos}
        />
        <PrivateRoute
        path={`/reportes/dsv/evaluaciondiagnostica`}
        exact
        component={DSVReporteEvaluacionDiagnostica}
      />
        <Route
          path="*"
          component={NotFound}
        />

      </Switch>
    );
  }
}

export default withRouter(AppRouter);
