/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getNombramientos, nombramientos } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { ClipboardCheckIcon, FilterIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import { Gestionar } from "./gestionar";
import toast from "react-hot-toast";
import PdfViewer from "../../../components/common/PdfViewer";
import { PrinterIcon } from "@heroicons/react/solid";
import ModalAmplio from "../../../components/common/ModalAmplio";

const tableColumns = [
  { title: "id", value: "id", tipo: "text" },
  {
    title: "Profesional solicita",
    value: null,
    values: [
      "usuario_requiere_nombramiento.first_name",
      "usuario_requiere_nombramiento.last_name",
    ],
    tipo: "text",
    ordenar: "usuario_requiere_nombramiento__first_name"
  },
  { title: "Caso", value: "caso.numero_caso", tipo: "text", ordenar: "caso" },
  {
    title: "Víctima",
    value: null,
    values: [
      "caso.victima.primer_nombre",
      "caso.victima.segundo_nombre",
      "caso.victima.primer_apellido",
      "caso.victima.segundo_apellido"
    ],
    tipo: "text"
  },
  {
    title: "Servicio",
    value: "servicio.nombre",
    tipo: "text"
  },
  { title: "Destino", value: "lugar_destino", tipo: "text" },
  { title: "Fecha Solicitud", value: "fecha", tipo: "date", ordenar: "fecha" },
  {
    title: "Gestionado",
    value: "autorizado",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
    ordenar: "autorizado"
  },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Autorizado", false: "Rechazado" },
    ordenar: "estado"
  }
];

export const TableNombramientos = () => {
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [openFilters, setOpenFilters] = useState(false);
  const [nombramiento, setNombramiento] = useState("");
  const [gestionar, setGestionar] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [filters, setFilters] = useState("");
  const [order, setOrder] = useState("-fecha_creacion");
  const [imprimirNombramiento, setImprimirNombramiento] = useState(false);
  const [nombramientoPdfData, setNombramientoPdfData] = useState(false);
  const permiso = "nombramientos";

  useEffect(() => {
    setTableLoading(true);
    nombramientos(urlFetch, filters, order, token).then((data) => {
      setDataTable(data);
      setReFetch(false);
      setTableLoading(false);
    });
  }, [urlFetch, filters, order, reFetch]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getNombramientos(urlFetch, search, filters, order, token)
        .then((data) => {
          setDataTable(data);
          setReFetch(false);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, filters, order, reFetch]);

  const handleGestionar = (item) => {
    if (item.autorizado === false) {
      setNombramiento(item);
      setGestionar(true);
    } else {
      toast.error("No se puede gestionar el registro, porque ya está autorizado")
    }
  };

  let autorizadoValor;

  dataTable?.results?.map((item) => {
    autorizadoValor = item.autorizado;
    if (autorizadoValor === true) {
      item.mostrar = false
    } else {
      item.mostrar = true
    }
  })

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Nombramientos", href: "#", current: true },
  ];

  const printPdf = (item) => {
    setNombramientoPdfData({ nombramiento: item});
    setImprimirNombramiento(true);
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div>
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showEliminar={false}
            showEditar={false}
            showVer={false}
            showPrint={true}
            handlePrint={printPdf}
            order={order}
            setOrder={setOrder}
            agregar={true}
            agregar1="Gestionar"
            handleAgregar={handleGestionar}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
      {gestionar && (
        <ModalGenerico
          title={"Autorizar nombramiento"}
          open={gestionar}
          closeModal={() => { }}
          cerrar={() => {
            setGestionar(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <Gestionar nombramiento={nombramiento} setGestionar={setGestionar} setReFetch={setReFetch} />
          }
        />
      )}
      {imprimirNombramiento &&
        <ModalAmplio
          title={`Imprimir Nombramiento`}
          open={true}
          closeModal={() => { }}
          cerrar={() => {
            setImprimirNombramiento(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <PrinterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={
            <PdfViewer
              data={nombramientoPdfData}
              reporte={'nombramiento'}
              style={{ height: "80vh" }}
            />
          }
        />
      }
    </div>
  );
};
