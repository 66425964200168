import axios from "axios";
const { REACT_APP_API_URL } = process.env;

export const getNombramientos = async (urlPage, search, filters, order, token) => {
  try {
    let urlFetch = urlPage === undefined
      ? `${REACT_APP_API_URL}/registro/nombramientos/?search=${search.value}&${filters}&ordering=${order}`
      : `${urlPage}&search=${search.value}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const nombramientos = async (urlPage, filters, order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/nombramientos/?${filters}&ordering=${order}`
        : `${urlPage}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getServicios = async (token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getDeptosGestion = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getMunicipiosGestion = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const putNombramientos = async (id, data, token) => {
  try {
    let body = {
      lugar_destino: data.lugar_destino,
      fecha: (data.fecha === "") ? null : data.fecha,
      fecha_hora_salida: (data.fecha_hora_salida === "") ? null : data.fecha_hora_salida,
      fecha_hora_retorno: (data.fecha_hora_retorno === "") ? null : data.fecha_hora_retorno,
      fecha_autorizado: (data.fecha_autorizado === "") ? null : data.fecha_autorizado,
      autorizado: data.autorizado,
      estado: data.estado,
      diligencia_realizar: data.diligencia_realizar,
      observaciones: data.observaciones,
      usuario_requiere_nombramiento: (data.usuario_requiere_nombramiento === "") ? null : data.usuario_requiere_nombramiento,
      caso: (data.caso === 0) ? null : data.caso,
      expediente: (data.expediente === 0) ? null : data.expediente,
      departamento: (data.departamento === "") ? null : data.departamento,
      municipio: (data.municipio === "") ? null : data.municipio,
      usuario_autorizado: (data.usuario_autorizado === "") ? null : data.usuario_autorizado,
      usuario_creacion: ""
    }

    let bodyResult = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/registro/nombramientos/${id}/`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return bodyResult;

  } catch (e) {
    console.log('error', e);
  }
};
