/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { getReparaciones,putExpediente } from "./services";
import { SelectSiavi } from "../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../components/common/Button";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    reparacion_digna: Yup.string().required("reparacion digna es requerida"),
});

export const ListadoReparaciones = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [reparaciones, setReparaciones] = useState([]);

    useEffect(() => {
        getReparaciones(props.registro.caso, token).then((data) => {
            setReparaciones(data)
        });
    }, []);

    return (
        <Formik
            initialValues={{
                reparacion_digna: "",
                reparacion_externa: false,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putExpediente(props.registro.id, values, token)
                    .then(async (data) => {
                        if (data?.status >= 200 || data?.status <= 210) {
                            toast.success("registro agregado con éxito..!");
                            props.setAddSentencia(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {({ values, handleSubmit,setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="border-gray-200 px-4 py-5 sm:p-0 mx-4">
                        <SelectSiavi
                            label="Seleccionar reparación digna"
                            validacion={true}
                            value={values.reparacion_digna}
                            name="reparacion_digna"
                            valueInit=""
                            selectInit="Seleccionar reparación digna"
                            onChange={(e)=>{
                                setFieldValue('reparacion_digna',e.target.value);

                                reparaciones?.results?.forEach(element => {
                                    if(element.id === Number(e.target.value)){
                                        setFieldValue('reparacion_externa',element?.rep_digna_externa);
                                    }
                                });
                            }}
                            list={
                                reparaciones?.results?.map((item, index) => {
                                    return (
                                        <option key={item?.fecha + index} value={item?.id}>
                                            {item?.fecha}
                                        </option>
                                    );
                                })
                            }
                        />
                        <div className="flex flex-row mt-3">
                            <ButtonSiavi
                                className="mr-2"
                                text="Guardar"
                                type="button"
                                onClick={handleSubmit}
                            />
                            <ButtonSiavi
                                className="bg-maivi-primaryLightBlue"
                                text="Nueva reparación digna"
                                type="button"
                                onClick={() => {
                                    props.setAgregarReparacion(true);
                                    props.setAddReparacion(false);
                                }}
                            />

                        </div>
                        {isSubmitting && <Spinner />}
                    </div>
                </form>
            )}
        </Formik>
    )



};
