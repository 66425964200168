import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getExpedientesSindicado } from "./services";
import TableSiavi from "../../../../../components/common/TableSiavi";
import moment from "moment";

export const ExpedientsSindicado = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargando, setCargando] = useState(false);
  const [expedientes, setExpedientes] = useState([]);

  const fetchExpedientes = () => {
    setCargando(true);
    getExpedientesSindicado(props.idSindicado, props.idCaso, token).then((data) => {
      setExpedientes(data);
      setCargando(false);
    });
  };

  useEffect(fetchExpedientes, [props.idCaso, props.idSindicado, token]);

  return (
    <div className="grid grid-cols-12 gap-6 items-end mt-3">
      <div className="col-span-12 mb-5 overflow-x-auto">
        <TableSiavi 
          cargando={cargando}
          headers={[
            'Nombre',
            'Identificacion',
            'Expediente',
            'Fecha'
          ]}
          body= {expedientes?.results?.map((item) => (
            <tr key={item.id} className="cursor-pointer ">
              <td className="px-2 py-2 row-td">
                <div className="text-xs text-maivi-primaryFont">
                  {item?.sindicado?.primer_nombre}{" "}
                  {item?.sindicado.segundo_nombre}{" "}
                  {item?.sindicado?.primer_apellido}{" "}
                  {item?.sindicado?.segundo_apellido}
                </div>
              </td>
              <td className="px-2 row-td">
                <div className="text-xs text-maivi-primaryFont">
                  {item?.sindicado?.no_identificacion}
                </div>
              </td>
              <td className="px-2 row-td">
                <div className="text-xs text-maivi-primaryFont">
                  {item?.expediente?.numero_expediente}
                </div>
              </td>
              <td className="px-2 row-td">
                <div className="text-xs text-maivi-primaryFont">
                  {moment(item?.fecha_creacion).format("DD-MM-YYYY")}
                </div>
              </td>
            </tr>
          ))}
        />
      </div>
    </div>
  );
};
