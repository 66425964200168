/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../../components/common/InputText";
import { getCatalogoRecursos,getSedes } from "./services";
import { SelectSiavi } from "../../../../components/common/Select"
import Spinner from "../../../../components/common/Spinner";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [recursos, setRecursos] = useState([]);
    const [sedes, setSedes] = useState([]);
    let filtros = "";

    useEffect(() => {
        getCatalogoRecursos(token).then((data) => {
            setRecursos(data);
        })
    }, []);

    useEffect(() => {
        getSedes(token).then((data) => {
            setSedes(data);
        })
    }, []);

    const [state, setState] = useState({
        recurso: "",
        disponible: "",
        sede:"",
        fecha_desde: "",
        fecha_hasta: "",
        hora_desde: "",
        hora_hasta: "",
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="grid grid-cols-12 mx-5">
            <div className="col-span-12 mt-3">
                <div className="mb-2">
                    <SelectSiavi
                        name="recurso"
                        value={state.recurso}
                        label="Recurso"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir recurso"
                        list={
                            recursos?.results?.map((item, index) => {
                                return (
                                    <option key={item?.nombre + index} value={item.id}>
                                        {item?.nombre}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="sede"
                        value={state.sede}
                        label="Sede"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir sede"
                        list={
                            sedes?.results?.map((item, index) => {
                                return (
                                    <option key={index} value={item?.id}>
                                        {item?.sede}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="disponible"
                        value={state.disponible}
                        label="Disponible"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Seleccionar disponibildad"
                        list={[
                            <option value="true">Disponible</option>,
                            <option value="false">No disponible</option>
                        ]}
                    />
                </div>
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-2 mx-1">
                <InputSiavi
                    type="time"
                    name="hora_desde"
                    value={state.hora_desde}
                    onChange={onChange}
                    label="Hora inicio"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-2 mx-1">
                <InputSiavi
                    type="time"
                    name="hora_hasta"
                    value={state.hora_hasta}
                    onChange={onChange}
                    label="Hora fin"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-2 mx-1">
                <InputSiavi
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha inicio"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-2">
                <InputSiavi
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha fin"
                />
            </div>
            <div className="col-span-12 mt-2">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        props.setUrlFech(undefined);
                        filtros = `recurso=${state.recurso}&fecha__gte=${state.fecha_desde}&fecha__lte=${state.fecha_hasta}&disponible=${state.disponible}&recurso__sede_recurso=${state.sede}&hora__gte=${state.hora_desde}&hora__lte=${state.hora_hasta}`;
                        props.setFiltros(filtros);
                        props.setFiltrar(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
