import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postTipoPregunta, putTipoPregunta } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Tipo Pregunta es requerido")
    .max(100, "Máximo 100 caracteres"),
  descripcion: Yup.string()
    .required("Descripción es requerida")
    .max(300, "Máximo 300 caracteres"),
});

const selectClass = `shadow-sm py-2 px-3 border focus:outline-none focus:ring-2
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full
border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont text-xs`;

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  let numerico = false;
  let linea_texto = false;
  let texto_extenso = false;
  let archivo_adjunto = false;
  let seleccion_simple = false;
  let fecha = false;

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          numerico: props.editar ? props.value.numerico : false,
          linea_texto: props.editar ? props.value.linea_texto : false,
          texto_extenso: props.editar ? props.value.texto_extenso : false,
          archivo_adjunto: props.editar ? props.value.archivo_adjunto : false,
          seleccion_simple: props.editar ? props.value.seleccion_simple : false,
          estado: props.editar ? props.value.estado : true,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putTipoPregunta(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro actualizado con éxito...!");
              }
            });
          } else {
            await postTipoPregunta(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="*Tipo Pregunta"
                />
              </div>
              <div className="col-span-6 mt-5 mb-5">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="*Descripción"
                />
              </div>
              <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
                <Label text="Seleccionar opción para tipo pregunta" />
                <select
                  name="opcion"
                  onChange={(e) => {
                    setFieldValue("opcion", e.target.value)
                    if (e.target.value === "numerico") {
                      numerico = true;
                      linea_texto = false;
                      texto_extenso = false;
                      archivo_adjunto = false;
                      seleccion_simple = false;
                      fecha = false;
                    } else if (e.target.value === "linea_texto") {
                      numerico = false;
                      linea_texto = true;
                      texto_extenso = false;
                      archivo_adjunto = false;
                      seleccion_simple = false;
                      fecha = false;
                    } else if (e.target.value === "texto_extenso") {
                      numerico = false;
                      linea_texto = false;
                      texto_extenso = true;
                      archivo_adjunto = false;
                      seleccion_simple = false;
                      fecha = false;
                    } else if (e.target.value === "archivo_adjunto") {
                      numerico = false;
                      linea_texto = false;
                      texto_extenso = false;
                      archivo_adjunto = true;
                      seleccion_simple = false;
                      fecha = false;
                    } else if (e.target.value === "seleccion_simple") {
                      numerico = false;
                      linea_texto = false;
                      texto_extenso = false;
                      archivo_adjunto = false;
                      seleccion_simple = true;
                      fecha = false;
                    }
                    else if (e.target.value === "fecha") {
                      numerico = false;
                      linea_texto = false;
                      texto_extenso = false;
                      archivo_adjunto = false;
                      seleccion_simple = false;
                      fecha = true;
                    }
                  }}
                  className={selectClass}
                >
                  <option value="">Seleccionar</option>
                  <option value="linea_texto">Línea Texto</option>
                  <option value="numerico">Númerico</option>
                  <option value="fecha">Fecha</option>
                  <option value="texto_extenso">Texto Extenso</option>
                  <option value="archivo_adjunto">Archivo Adjunto</option>
                  <option value="seleccion_simple">Selección Múltiple</option>
                </select>
              </div>
              <div className="col-span-6 mt-5 flex flex-col">
                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              {/* </div> */}
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                  onClick={() => {
                    values.numerico = numerico;
                    values.linea_texto = linea_texto;
                    values.texto_extenso = texto_extenso;
                    values.archivo_adjunto = archivo_adjunto;
                    values.seleccion_simple = seleccion_simple;
                    values.fecha = fecha;
                  }}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
