import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    getMotivoCierre,
    postCriterioCierre,
    putCriterioCierre,
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Motivo de Cierre es requerido")
        .max(255, "Máximo 100 caracteres"),
    motivo_cierre: Yup.string()
        .required("Motivo Cierre es requerido")
        .max(255, "Máximo 100 caracteres"),
});

const useFetchMotivoCierre = (token) => {
    const [state, setState] = useState({
        motivoscierres: [],
    });

    useEffect(() => {
        getMotivoCierre(token).then((motivoscierres) => {
            setState({
                motivoscierres: motivoscierres,
            });
        });
    }, [token]);

    return state;
};

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { motivoscierres } = useFetchMotivoCierre(token);

    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    motivo_cierre: props.editar ? props.value.motivo_cierre : "",
                    estado: props.editar ? props.value.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putCriterioCierre(props.value.id, values, token).then(
                            (data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    props.setReFetch(true);
                                    toast.success("Registro actualizado con éxito...!");
                                }
                            }
                        );
                    } else {
                        await postCriterioCierre(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message);
                            } else {
                                props.setReFetch(true);
                                toast.success("Registro creado con éxito...!");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Nombre*"
                                />
                            </div>
                            <div className="col-span-6 mt-4">
                                <Label text="Motivo Cierre*" />
                                <div className="mt-1">
                                    <select
                                        id="motivo_cierre"
                                        name="motivo_cierre"
                                        autoComplete="off"
                                        value={values.motivo_cierre}
                                        onChange={handleChange}
                                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                    >
                                        <option value="" disabled>
                                            Elegir motivo cierre
                                        </option>
                                        {motivoscierres?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ErrorMessage
                                        name="motivo_cierre"
                                        component="span"
                                        className="text-sm text-maivi-secondaryRed"
                                    />
                                </div>
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
