/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { TrashIcon, PencilAltIcon, PrinterIcon, PaperClipIcon } from "@heroicons/react/solid";
import { GestionArchivos } from "../../../../../../components/ArchivosSiavi/archivos";
import { SelectSiavi } from "../../../../../../components/common/Select";
import {
    getConsentimientos,
    getConsentimientosEditables,
    postConsentimientoEditable,
    putConsentimientoEditable,
    deleteConsentimientoEditable,
    getPersonas
} from "./services";
import Toggle from "../../../../../../components/common/Toggle";
import Spinner from "../../../../../../components/common/Spinner";
import PdfViewer from "../../../../../../components/common/PdfViewer";
import ModalGenerico from "../../../../../../components/common/Modal";
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import BotonesConfirmar from "../../../../../../components/common/ButtonsConfirmacion";
import Label from "../../../../../../components/common/Label";
import toast from "react-hot-toast";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from "moment";
import * as Yup from "yup";
import { getExpedienteFull } from "../../services";

const validaciones = Yup.object().shape({
    consentimiento_informado: Yup.string().required("tipo consentimiento es requerido")
});

export const ConsentimientoEditable = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user.username);
    const [refetch, setRefetch] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [consentimientos, setConsentimientos] = useState({});
    const [consentimientosEditables, setConsentimientosEditabes] = useState({});
    const [dataPreview, setDataPreview] = useState(false);
    const [editar, setEditar] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [id, setId] = useState(0);
    const [registro, setRegistro] = useState({});
    const [archivos, setArchivos] = useState(false);
    const [registroConsentimiento, setRegistroConsentimiento] = useState({});
    const [validarEdicion, setValidarEdicion] = useState(false);
    const [personas, setPersonas] = useState({});
    const [editorState, setEditorState] = useState(() => {
        return EditorState.createEmpty()
    });


    
    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro

    const permisoView = "ExpedientesAV";  //mm permiso padre
  
    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
      let retorno = false;
      permisosRegistro?.forEach(menu => {
        if (menu?.permiso === permisoView) {
          menu?.opciones?.forEach(op => {
            if (op.permiso === permiso) {
              retorno = op.ver;
            }
          })
        }
      });
  
      //        retorno = false;  // mm comentar para que funcione
  //               retorno = true;  // mm comentar para que funcione
      return retorno;
  
    }
  

    useEffect(() => {
        getConsentimientosEditables(props.registro.id, token).then((data) => {
            setConsentimientosEditabes(data);
        });
    }, [token, refetch]);

    useEffect(() => {
        getConsentimientos(token).then((data) => {
            setConsentimientos(data);
        });
    }, [token]);

    useEffect(() => {
        getPersonas(props.registro.caso, token).then((data) => {
            setPersonas(data);
        });
    }, [])

    const actualizarConsentimiento = async (values, token) => {
        await putConsentimientoEditable(values, token).then((data) => {
            if (data?.status === 200 || data?.status === 201) {
                setCargando(false);
                toast.success("Registro actualizado con éxito...!");
                setRefetch(true);
                setRefetch(false);
                setEditar(false);
                setValidarEdicion(false);
            }
        })
            .catch(function (error) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401
                ) {
                    setCargando(false);
                    toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                    toast.error(
                        "Error interno comuniquese con el administrador"
                    );
                }
            });
    }

    return (
        <Formik
            initialValues={{
                idConsentimiento: "",
                titulo: "",
                consentimiento_informado: "",
                servicio: props.servicio,
                observaciones: "",
                caso: props.registro.caso,
                expediente: props.registro.id,
                consentimiento: "",
                fecha_ultima_modificacion: moment().format("YYYY-MM-DD"),
                editable: true,
                estado: true,
                es_nna: false,
                persona_representante: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values, { resetForm }) => {
                if (editar) {
                    if (values.editable) {
                        setValidarEdicion(true);
                    } else {
                        toast.error("Este registro ya no puede actualizarse.")
                    }

                } else {
                    setCargando(true);
                    try {

                        console.log(props.registro);

                        const numero_caso = props.registro?.caso_persona?.caso?.numero_caso;
                        let primer_nombre = props.registro?.caso_persona?.persona?.primer_nombre;
                        let segundo_nombre = props.registro?.caso_persona?.persona?.segundo_nombre;
                        let primer_apellido = props.registro?.caso_persona?.persona?.primer_apellido;
                        let segundo_apellido = props.registro?.caso_persona?.persona?.segundo_apellido;
                        const persona = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`;
                        const identificacion_persona = props.registro?.caso_persona?.persona?.no_documento;
                        let tipo_persona = "";
                        console.log(registroConsentimiento);
                        let data = registroConsentimiento?.consentimiento;
                        let es_nna = registroConsentimiento?.es_nna;
                        let delitos = '';

                        if (props.delitos) {
                            props.delitos.forEach(delito => {
                                delitos = delitos + ' ' + delito.nombre;
                            });
                        }

                        try {
                            let expediente = props.registro;
                            const servicio_expediente = expediente?.servicio?.nombre;
                            const numero_denuncia = expediente?.caso_persona?.caso?.no_denuncia;
                            const organo_jurisdiccional = '';
                            const causa_penal = '';
                            const profesional_asignado = '';

                            data = data.replace('{profesional_asignado}', profesional_asignado);
                            data = data.replace('{numero_denuncia}', numero_denuncia);
                            data = data.replace('{organo_jurisdiccional}', organo_jurisdiccional);
                            data = data.replace('{servicio_expediente}', servicio_expediente);
                            data = data.replace('{causa_penal}', causa_penal);

                        } catch (error) {
                            console.log(error);
                        }

                        data = data.replace('{numero_caso}', numero_caso);
                        data = data.replace('{delitos}', delitos);

                        if (es_nna === false) {
                            tipo_persona = 'Querellante'
                            data = data.replace('{tipo_persona}', tipo_persona);
                            data = data.replace('{persona}', persona);
                            data = data.replace('{identificacion_persona}', identificacion_persona);
                        } else if (es_nna === true) {

                            data = data.replace('{nombre_victima}', persona);
                            data = data.replace('{nombre_victima}', persona);
                            data = data.replace('{identificacion_victima}', identificacion_persona);

                            if (registroConsentimiento.caso_persona.querellante) {
                                data = data.replace('{tipo_persona}', 'Querellante');
                                data = data.replace('{persona}', persona);
                                data = data.replace('{identificacion_persona}', identificacion_persona);
                            } else {
                                let primer_nombre = registroConsentimiento.representante?.primer_nombre;
                                let segundo_nombre = registroConsentimiento.representante?.segundo_nombre;
                                let primer_apellido = registroConsentimiento.representante?.primer_apellido;
                                let segundo_apellido = registroConsentimiento.representante?.segundo_apellido;
                                const persona2 = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`;
                                const identificacion_persona2 = registroConsentimiento.representante?.no_documento;

                                data = data.replace('{tipo_persona}', 'Querellante');
                                data = data.replace('{persona}', persona2);
                                data = data.replace('{identificacion_persona}', identificacion_persona2);
                            }

                        }

                        values.consentimiento = data;

                    } catch (error) { console.log(error); }

                    values.titulo = registroConsentimiento.titulo;

                    values.es_nna = registroConsentimiento.es_nna;
                    values.estado = registro.estado;

                    await postConsentimientoEditable(values, token).then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setCargando(false);
                            toast.success("Registro creado con éxito...!");
                            setRefetch(true);
                            setRefetch(false);
                            resetForm({});
                        }
                    })
                        .catch(function (error) {
                            if (
                                error.response.status === 400 ||
                                error.response.status === 401
                            ) {
                                setCargando(false);
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(
                                    "Error interno comuniquese con el administrador"
                                );
                            }
                        });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="grid grid-cols-12 gap-2 mb-2">
                            <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-5 border p-3 hover:shadow-md rounded-md">
                                <div className="col-span-12 xl:col-span-6 lg:col-span-4 md:col-span-6">
                                    <SelectSiavi
                                        label="Tipo consentimiento"
                                        name="consentimiento_informado"
                                        value={values.consentimiento_informado}
                                        valueInit=""
                                        validacion={true}
                                        selectInit="Elegir consentimiento"
                                        onChange={(e) => {
                                            setFieldValue('consentimiento_informado', e.target.value);

                                            consentimientos?.results.forEach(element => {
                                                if (element.id === Number(e.target.value)) {
                                                    console.log(element);
                                                    setRegistroConsentimiento(element)
                                                };
                                            });
                                        }}
                                        list={consentimientos?.results?.filter((x) =>
                                            (props.servicio === 'litigio_estrategico') ? x.servicio.litigio_estrategico === true :
                                                (props.servicio === 'impugnaciones') ? x.servicio.impugnaciones === true :
                                                    x?.servicio?.id === props.servicio).map((item) => {
                                                        return (
                                                            <option key={item.nombre + item.id} value={item.id}>
                                                                {item.nombre}
                                                            </option>
                                                        );
                                                    })}
                                    />
                                </div>
                                <div className="col-span-12 mt-2 mb-2">
                                    <InputSiavi
                                        label="Observaciones"
                                        name="observaciones"
                                        value={values.observaciones}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-12 flex items-end ">
                                {(CheckPermiso('GuardarConsentimientoEditable') === true) && 
                                    <ButtonSiavi
                                        text={(editar) ? 'Actualizar' : 'Guardar'}
                                        onClick={handleSubmit}
                                    />
                                                }
                                </div>
                                <div className="mt-2">
                                    <TableSiavi
                                        headers={[
                                            'Nombre',
                                            'Acciones'
                                        ]}
                                        body={consentimientosEditables?.results?.map((item, index) => (
                                            <tr key={index + item.id} className="hover:bg-maivi-gray-5 cursor-pointer">
                                                <td className={props.tdClass}>
                                                    {`${item?.titulo}  `}
                                                </td>
                                                <td className={props.tdClass}>
                                                {(CheckPermiso('EditarConsentimientoEditable') === true) && 
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            console.log(item);
                                                            setFieldValue('consentimiento_informado', item?.consentimiento_informado);
                                                            setFieldValue('titulo', item?.titulo);
                                                            setFieldValue('estado', item?.estado);
                                                            setFieldValue('es_nna', item?.es_nna);
                                                            setFieldValue('consentimiento', item?.consentimiento);
                                                            setFieldValue('idConsentimiento', item?.id);
                                                            if (item.consentimiento) {
                                                                const blocksFromHtml = htmlToDraft(item.consentimiento);
                                                                const { contentBlocks, entityMap } = blocksFromHtml;
                                                                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                                                                await setEditorState(EditorState.createWithContent(contentState));
                                                            }

                                                            setEditar(true);
                                                            setDataPreview("");
                                                        }}
                                                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                    >
                                                        <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                        }
                                        {(CheckPermiso('EliminarConsentimientoEditable') === true) && 
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setBorrar(true);
                                                            setId(item.id);
                                                        }}
                                                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                    >
                                                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                    </button>
                                        }
                                                    {(CheckPermiso('ImprimirConsentimientoEditable') === true) && 
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            try {
                                                                setEditar(false);
                                                                setDataPreview({
                                                                    html: item.consentimiento,
                                                                    fecha_creacion: item?.fecha_creacion,
                                                                    usuario: usuarioLogin
                                                                })

                                                            } catch (error) {
                                                                setDataPreview("")
                                                            }
                                                        }}
                                                        className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                                                    >
                                                        <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                        }
                                        {(CheckPermiso('AdjuntarConsentimientoEditable') === true) && 
                                         <button
                                                        type="button"
                                                        onClick={() => {
                                                            setId(item.id);
                                                            setRegistro(item);
                                                            setArchivos(true);
                                                        }}
                                                        className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                                                    >
                                                        <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                        }
                                                </td>
                                            </tr>
                                        ))}
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-7 lg:col-span-7 md:col-span-7 border p-2 hover:shadow-md rounded-md">
                                {
                                    editar &&
                                    <div className="grid grid-cols-12 gap-2 items-end">
                                        <div className="col-span-6">
                                            <InputSiavi
                                                name="titulo"
                                                value={values.titulo}
                                                onChange={handleChange}
                                                label="Titulo"
                                            />
                                        </div>
                                        <div className="col-span-12 xl:col-span-3 lg:col-span-3 sm:col-span-3 md:col-span-4 text-center">
                                            <Label text="Estado" />
                                            <Toggle enabled={values.estado} name="estado" />
                                        </div>
                                        <div className="col-span-12 xl:col-span-3 lg:col-span-3 sm:col-span-3 md:col-span-4 text-center">
                                            <Label text="Es NNA" />
                                            <Toggle enabled={values.es_nna} name="es_nna" />
                                        </div>
                                        {
                                            (values.es_nna) &&
                                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-4">
                                                <SelectSiavi
                                                    label="Representante"
                                                    name="persona_representante"
                                                    value={values.persona_representante}
                                                    valueInit=""
                                                    selectInit="Seleccionar representante"
                                                    onChange={handleChange}
                                                    list={personas?.results?.filter(x => x.querellante || x.representante_legal).map((item, index) => {
                                                        return <option key={"p" + index} value={item?.persona?.id}>{item?.persona?.primer_nombre}  {item?.persona?.segundo_nombre} {item?.persona?.primer_apellido} {item?.persona?.segundo_apellido}</option>
                                                    })}
                                                />
                                            </div>

                                        }
                                        <div className="col-span-12">
                                            <Editor
                                                editorState={editorState}
                                                readOnly={!values.editable}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                editorStyle={{ height: "500px", border: "1px solid #ededed", padding: "5px" }}
                                                onEditorStateChange={async (state) => {
                                                    await setEditorState(state);
                                                    const data = convertToRaw(editorState.getCurrentContent());
                                                    setFieldValue('consentimiento', draftToHtml(data));
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                {dataPreview &&
                                    <PdfViewer
                                        reporte={"consentimiento-persona"}
                                        data={dataPreview}
                                        className="alto-pdf"
                                    />
                                }
                            </div>
                        </div>
                        {
                            cargando && <Spinner />
                        }
                    </div>
                    {isSubmitting && <Spinner />}
                    {borrar && (
                        <ModalGenerico
                            title="Esta seguro que desea eliminar el registro?"
                            open={borrar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setBorrar(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    aceptar={async () => {
                                        setCargando(true);
                                        await deleteConsentimientoEditable(id, token).then((data) => {
                                            if (data?.status >= 200 && data?.status <= 210) {
                                                setCargando(false);
                                                toast.success("Registro borrado con éxito...!");
                                                setRefetch(true);
                                                setRefetch(false);
                                                setBorrar(false);
                                            }
                                        })
                                            .catch(function (error) {
                                                if (
                                                    error.response.status === 400 ||
                                                    error.response.status === 401
                                                ) {
                                                    setCargando(false);
                                                    toast.error(error.response.data.message);
                                                } else if (error.response.status >= 500) {
                                                    toast.error(
                                                        "Error interno comuniquese con el administrador"
                                                    );
                                                }
                                            });
                                    }}
                                    cancelar={() => {
                                        setBorrar(false);
                                    }}
                                />
                            }
                        />
                    )}

                    {validarEdicion && (
                        <ModalGenerico
                            title="¿Desea continuar editandolo posteriormente?"
                            open={validarEdicion}
                            closeModal={() => { }}
                            cerrar={() => {
                                setValidarEdicion(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    aceptar={async () => {
                                        setFieldValue("editable", true);
                                        actualizarConsentimiento(values, token);
                                    }}
                                    cancelar={() => {
                                        setFieldValue("editable", false);
                                        actualizarConsentimiento(values, token);
                                    }}
                                />
                            }
                        />
                    )}

                    {archivos && (
                        <ModalAmplio
                            title={`Adjuntar archivos`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setArchivos(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <PaperClipIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                            children={
                                <GestionArchivos
                                    idRecurso={id}
                                    recurso="CONSENTIMIENTOINFORMADO"
                                    claseDocumento="consentimientoinformado"
                                    caso={registro?.caso_persona?.caso?.id}
                                    expediente={null}
                                />
                            }
                        />
                    )}
                </form>
            )}
        </Formik>
    );
};
