import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Actualizar } from "./Actualizar";
import { useSelector } from "react-redux";
import { getConfiguracion, putConfiguracion } from "./services";

const validaciones = Yup.object().shape({
    nombre_institucion: Yup.string()
        .required("Nombre Institución es requerido"),
    nit: Yup.string()
        .required("NIT es requerido"),
    direccion: Yup.string().required("Dirección es requerida"),
    pais: Yup.string()
        .required("País es requerido"),
    idioma_preferente: Yup.string()
        .required("Idioma Preferente es requerido"),
    moneda: Yup.string()
        .required("Moneda es requerida"),
    prefijo_telefono: Yup.string().required("Prefijo Teléfono es requerido"),
    intentos_contrasena: Yup.string().required("Intentos Contraseña es requerido"),
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const FormActualizar = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [loading, setLoading] = useState(true);
    const [configuracion, setConfiguracion] = useState({});

    useEffect(() => {
        const datos = async () => {
            await getConfiguracion(token).then((data) => {
                setConfiguracion(data.data);
                setLoading(false);
            });
        };
        datos();
    }, [token]);

    return loading ? <div></div> :
        <Formik
            initialValues={{
                nombre_institucion: configuracion?.nombre_institucion || "",
                nit: configuracion?.nit || "",
                direccion: configuracion?.direccion || "",
                pais: configuracion?.pais?.id,
                habilita_idioma_maya: configuracion?.habilita_idioma_maya?.id,
                habilita_origen_etnico: configuracion?.habilita_origen_etnico?.id,
                nacionalidad_default: configuracion?.nacionalidad_default?.id,
                notificaciones_activas: configuracion?.notificaciones_activas || false,
                mensajes_autocuidado: configuracion?.mensajes_autocuidado || false,
                idioma_preferente: configuracion?.idioma_preferente || "",
                moneda: configuracion?.moneda || "",
                prefijo_telefono: configuracion?.prefijo_telefono || "",
                intentos_contrasena: configuracion?.intentos_contrasena || "",
                dias_atiende_visita: configuracion?.dias_atiende_visita || "",
                dias_registro_acciones: configuracion?.dias_registro_acciones || "",
                dias_finalizo_acciones: configuracion?.dias_finalizo_acciones || "",
                victimaxcaso: configuracion?.victimaxcaso || false,
                comunicacion_victima: configuracion?.comunicacion_victima || false,
                caso_sin_orientacion: configuracion?.caso_sin_orientacion || false
            }}

            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putConfiguracion(values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje("Configuración Actualizada éxitosamente");
                        } else {
                            setError(true);
                            setSuccess(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            setMensaje("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {(props) => (
                <Actualizar
                    {...props}
                    customSwitchChange={customSwitchChange}
                    hasError={hasError}
                    success={success}
                    mensaje={mensaje}
                />
            )}
        </Formik>
};
