/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import FormEliminar from "../common/FormEliminar";
import { useSelector } from "react-redux";
import { Form } from "./Form";
import CustomTable from "../../../components/common/Table";
import { deleteCatalogos, getGenericCatalogo, getGenericCatalogoSearch} from "../../../helpers/catalogos";
import toast from 'react-hot-toast';

const tableColumns = [
  { title: "id", value: "id", tipo: "text",ordenar:"id" },
  { title: "Cobertura", value: "cobertura", tipo: "text", ordenar:"cobertura" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
  },
];

export const TableCoberturaSede = () => {
  const [open, setOpen] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [coberturaSede, setCoberturaSede] = useState("");
  const [idCoberturaSede, setIdCoberturaSede] = useState("");
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [reFetch, setReFetch] = useState(false);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "coberturasede";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order);
    setReFetch(false);
    setOpen(false);
    setEliminar(false);
    setLoading(false);
  }, [urlFetch, token, reFetch]);
  useEffect(() => {
    getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order);
  }, [order]);
  useEffect(()=>{
    getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order);
  }, [search]);

  const handleEliminar = (id) => {
    setIdCoberturaSede(id);
    setEliminar(true);
  };

  const handleEditar = (tipo) => {
    setEdit(true);
    setCoberturaSede(tipo);
    setOpen(true);
  };

  const pages = [
    { name: "Catálogos", href: "/catalogos", current: false },
    { name: "Cobertura sedes", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <ButtonAdd
            text="Crear"
            type="button"
            onClick={() => {
              setEdit(false);
              setOpen(true);
            }}
          />
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleEditar={handleEditar}
            handleEliminar={handleEliminar}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showVer={false}
            setOrder={setOrder}
            order={order}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {open && (
            <ModalGenerico
              title={
                isEdit ? "Actualizar cobertura sede" : "Agregar cobertura sede"
              }
              open={open}
              closeModal={() => {}}
              cerrar={() => {
                setOpen(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <DocumentAddIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={<Form value={coberturaSede} setReFetch={setReFetch} editar={isEdit} />}
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro?`}
              open={isEliminar}
              closeModal={() => {}}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteCatalogos(idCoberturaSede, 'coberturasede', token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false); 
                        toast.error(data.message)
                      }else{
                        setReFetch(true);
                        setLoading(false);
                        toast.success('Registro eliminado con éxito...!')
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
