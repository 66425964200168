import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { postEstadoProceso, putEstadoProceso, getEtapaProcesalEstadoProceso } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../components/common/Select";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string().max(300, 'máximo 300 caracteres'),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [etapa_proceso, setEtapaProceso] = useState({});

  useEffect(() => {
    getEtapaProcesalEstadoProceso(token).then((data) => {
      setEtapaProceso(data)
    });
  }, [token]);

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          etapa_proceso: props.editar ? props.value.etapa_proceso : "",
          habilita_proceso_secundario: props.editar ? props.value.habilita_proceso_secundario : false,
          habilita_medida_desjudicializadora: props.editar ? props.value.habilita_medida_desjudicializadora : false,
          habilita_sentencia: props.editar ? props.value.habilita_sentencia : false,
          es_audiencia: props.editar ? props.value.es_audiencia : false,
          estado: props.editar ? props.value.estado : true,
          habilita_motivo: props.editar ? props.value.habilita_motivo : false,
          habilita_interponente: props.editar ? props.value.habilita_interponente : false,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putEstadoProceso(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro actualizado con éxito...!')
              }
            });
          } else {
            await postEstadoProceso(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro creado con éxito...!')
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="*Nombre"
                />
              </div>
              <div className="col-span-6 mt-3">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción"
                />
              </div>
              <div className="col-span-6 mt-3">
                <SelectSiavi
                  label="Etapa procesal"
                  id="etapa_proceso"
                  name="etapa_proceso"
                  value={values.etapa_proceso}
                  onChange={handleChange}
                  valueInit=""
                  selectInit=""
                  list={
                    etapa_proceso?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })
                  }
                />
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-6 mt-2">
                  <Label text="Habilita Proceso Secundario" />
                  <Toggle enabled={values.habilita_proceso_secundario} name="habilita_proceso_secundario" />
                </div>
                <div className="col-span-6 mt-2">
                  <Label text="Habilita Medida Desjudicializadora" />
                  <Toggle enabled={values.habilita_medida_desjudicializadora} name="habilita_medida_desjudicializadora" />
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-6 mt-2">
                  <Label text="Habilita Sentencia" />
                  <Toggle enabled={values.habilita_sentencia} name="habilita_sentencia" />
                </div>
                <div className="col-span-6 mt-2">
                  <Label text="Es Audiencia" />
                  <Toggle enabled={values.es_audiencia} name="es_audiencia" />
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-6 mt-2">
                  <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                  <Toggle enabled={values.estado} name="estado" />
                </div>
                <div className="col-span-6 mt-2">
                  <Label text="Habilita motivo" />
                  <Toggle enabled={values.habilita_motivo} name="habilita_motivo" />
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-6 mt-2">
                  <Label text="Habilita interponente" />
                  <Toggle enabled={values.habilita_interponente} name="habilita_interponente" />
                </div>
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
