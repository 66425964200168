
import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;


export const exportarResumen = async (url, filters, token) => {
    try {
      let urlFetch = `${REACT_APP_API_URL}/registro/${url}?${filters}` ;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((data) => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
  };


export const exportarData = async(filters, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/orientacion-list/?${filters}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }).then(data => data.json());

        return result;
      } catch (e) {
        console.log("error", e);
      }
}

export const deleteOrientaciones = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacion/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrientaciones = async (urlPage, search,order,filters, token) => {
    try {
      let urlFetch = urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/orientacion/?historica=true&search=${search.value}&${filters}&ordering=${order}`
          : `${urlPage}&historica=true&ordering=${order}&${filters}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
  };

  export const orientaciones = async (urlPage, order,search,filters, token) => {
    try {
      let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/orientacion/?historica=true&ordering=${order}&${filters}`
          : `${urlPage}&historica=true&ordering=${order}&${filters}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((data) => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
  };


export const getProfesionales = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getPersonas = async (idCaso,token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getInstituciones = async (filtros,token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true${filtros}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getServiciosInternos = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const getServiciosExternos = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getReferencias = async (orientacion,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/referencia/?orientacion=${orientacion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

export const postReferencia = async(values, token) => {
  try {
      let body = {
          solicitud_referencia: false,
          referencia_interna: values.tipo_referencia,
          descripcion_referencia: "",
          seguimiento_completado: false,
          institucion: null,//(values.institucion_refiere === "") ? null : values.institucion_refiere,
          gestion_institucion: (values.institucion_refiere === "") ? null : values.institucion_refiere,
          persona: values.persona,
          representante:values.representante,
          caso: values.caso,
          orientacion: values.orientacion,
          motivo_referencia:values.motivos_referencia,
          asistencia_atencion_especifica: values.asistencia_atencion_especifica,
          seguimiento:false
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/referencia/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postReferenciaInterna = async(data, idReferencia, token) => {
  try {
      let body = {
          especificaciones: data.especificaciones,
          requiere_accion: false,
          referencia: idReferencia,
          servicio_interno: data.valorServicio,
          atencion: null,
          expediente: null,
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/referenciaservicioatencionesinternas/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postReferenciaExterna = async(data, idReferencia, token) => {
  try {
      let body = {
          area_institucion_refiere: data.area_institucion,
          especificaciones: data.especificaciones_externas,
          referencia_medica: false,
          impresion_clinica: "",
          resumen_clinico: "",
          presion_arterial: "",
          temperatura: "",
          frecuencia_cardiaca: "",
          tratamiento_empleado: "",
          condiciones_de_traslado: "",
          nombre_persona_recibe: "",
          fecha_recepcion: null,
          frecuencia_respiratoria: "",
          referencia: idReferencia,
          servicio_externo: data.valorServicio
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/referenciaservicioexterno/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postPersona = async (data,token) => {
    let numeroDocto;
    let tipoDocto;
    if(data.noDocumento === "" && Number(data.tipoDocumento) === 0){
        numeroDocto = null;
        tipoDocto = null;
    }else{
        numeroDocto = data.noDocumento;
        tipoDocto = Number(data.tipoDocumento);
    }
    try {
        let body = {
            no_documento: numeroDocto,
            primera_visita: data.primeraVisita,
            sin_documento: data.sin_documento,
            primer_nombre: data.primerNombre,
            segundo_nombre: data.segundoNombre,
            segundo_apellido: data.segundoApellido,
            otros_nombres: data.tercerNombre,
            primer_apellido: data.primerApellido,
            apellido_casada: data.apellidoCasada,
            fecha_nacimiento: data.fechaNacimiento,
            sexo: data.sexo,
            como_le_gusta_que_le_llamen: data.alias,
            tipo_documento: tipoDocto,
            pais: Number(data.pais),
            nacionalidad: Number(data.nacionalidad),
            sede: Number(data.sede)
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/registro/gestionpersonaregistro/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPersona = async (id,data,token) => {
    let numeroDocto;
    let tipoDocto;
    if(data.noDocumento === "" && Number(data.tipoDocumento) === 0){
        numeroDocto = null;
        tipoDocto = null;
    }else{
        numeroDocto = data.noDocumento;
        tipoDocto = Number(data.tipoDocumento);
    }
    try {
        let body = {
            no_documento: numeroDocto,
            primera_visita: data.primeraVisita,
            sin_documento: data.sin_documento,
            primer_nombre: data.primerNombre,
            segundo_nombre: data.segundoNombre,
            segundo_apellido: data.segundoApellido,
            otros_nombres: data.tercerNombre,
            primer_apellido: data.primerApellido,
            apellido_casada: data.apellidoCasada,
            fecha_nacimiento: data.fechaNacimiento,
            sexo: data.sexo,
            como_le_gusta_que_le_llamen: data.alias,
            tipo_documento: tipoDocto,
            pais: Number(data.pais),
            nacionalidad: Number(data.nacionalidad),

            estado_civil: (data.estado_civil === "") ? null : data.estado_civil,
            idioma: (data.idioma === "") ? null : data.idioma,
            religion: (data.religion === "") ? null : data.religion,
            escolaridad: (data.escolaridad === "") ? null : data.escolaridad,
            profesion: (data.profesion === "") ? null : data.profesion
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionpersonaregistro/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDocumentos = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/tipodocumento/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPaises = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/pais/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNacionalidades = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/nacionalidad/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const buscarPersona = async (pais,tipo_documento,documento,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?pais=${pais}&tipo_documento=${tipo_documento}&search=${documento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteReferencias = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/referencia/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipiosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getInstitucionGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPoblaciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/poblacion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEstadoOrientacion = async (id,data,token) => {

    try {
        let body = {
            estado: data.estado,
            usuario_ultimo_cambio_estado: data.usuario
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/orientacion/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};
