import { React, useEffect, useState } from "react";
import { PrinterIcon, UserIcon } from "@heroicons/react/outline";
import { XCircleIcon, FilterIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./Detalle";
import { useSelector } from "react-redux";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { FormReferencia } from "./referencia";
import { useHistory } from "react-router-dom";
import { ChangeOrientacionTab } from "../../../../app/redux/actions/orientacionTab";
import { clases } from "../../../../helpers/clases";
import { CambiarEstado } from "./cambiar_estado";
import { deleteRegistro } from "../../../../helpers/catalogos";
import {
  orientaciones,
  getOrientaciones,
  exportarData,
  exportarResumen,
  postValidarFicha,
  getPersonasCasoDetails,
  getExpedienteSindicados,
  getExpedienteSindicadoDelitos,
  getCasoEvaluacionRiesgo,
  getCasoHechos,
  getCasoHechosTemporalidad,
  getDiligenciasExpediente,
  getCausasPenales
} from "./services";
import ModalGenerico from "../../../../components/common/Modal";
import ModalBig from "../../../../components/common/ModalTwo";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import FormEliminar from "../../../../components/common/FormEliminar";
import CustomTable from "../../../../components/common/Table";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import ButtonRefresh from "../../../catalogos/common/ButtonRefresh";
import toast from "react-hot-toast";
import Filtros from "./filtros";
import ExportButton from "../../../../components/common/ExportButton";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import PdfViewer from "../../../../components/common/PdfViewer";

const tableColumns = [
  { title: "Orientación", value: "id", tipo: "text", ordenar: "id" },
  { title: "Fecha", value: "fecha", tipo: "date", ordenar: "fecha" },
  {
    title: "Nombre",
    value: null,
    values: [
      "persona_detalle.primer_nombre",
      "persona_detalle.segundo_nombre",
      "persona_detalle.primer_apellido",
      "persona_detalle.segundo_apellido",
    ],
    tipo: "text",
  },
  {
    title: "Caso Admitido",
    value: "caso_admitido",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Profesional",
    value: null,
    values: ["profesional.first_name", "profesional.last_name"],
    tipo: "text",
  },
];

export const TableOrientaciones = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const usuario = useSelector(
    (state) => state.loginReducer?.data?.usuario?.user
  );
  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user.username
  );
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const [isEliminar, setEliminar] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [verDetalle, setDetalle] = useState(false);
  const [orientacionDetalle, setOrientacionDetalle] = useState({});
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const [order, setOrder] = useState("-fecha");
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [addReferencia, setAddReferencia] = useState(false);
  const [registro, setRegistro] = useState("");
  const [cambiarEstado, setCambiarEstado] = useState(false);
  const [orientacion, setOrientacion] = useState({});
  const [ficha, setFicha] = useState(false);
  const [fichaData, setFichaData] = useState({});
  const [cargando, setCargando] = useState(false);
  const casoAdmitido = false;

  let history = useHistory();
  const permisosRegistro = useSelector((state) =>
    state.ChangePermisos?.menus?.registros?.menus
      ? state.ChangePermisos?.menus?.registros?.menus
      : []
  ); //mm buscar el registro

  const permisoView = "Orientacion"; //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach((menu) => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach((op) => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        });
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;
  };

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "orientacion";
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      // permisoAgregar = true;
    }
  });

  const exportResumen = (url) => {
    setTableLoading(true);
    exportarResumen(url, filters, token).then(async (data) => {
      let fileName = "Resumen";
      let dataExport = data;
      let columns = [];

      if (url === "resumenorienacion") {
        columns = [
          { name: "Sede", value: "sede" },
          { name: "Total Admisible", value: "total_admisible" },
          { name: "Total No Admisible", value: "total_no_admisible" },
        ];
        fileName = "resumenorienacion";
      }

      let raw = JSON.stringify({
        columns,
        data: dataExport,
        fileName,
        tipo: "xls",
      });
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };
      await fetch(
        `${process.env.REACT_APP_URL_REPORTES}/export-file`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          setTableLoading(false);
          window.open(url, "_blank");
        })
        .catch((error) => console.log("error", error));
    });
  };

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, token).then(async (data) => {
      const fileName = "orientaciones";
      let dataExport = data;
      let columns = [
        { name: "ID", value: "id" },
        { name: "Sede", value: "sede" },
        { name: "Fecha de Orientación", value: "fecha", tipo: "fecha" },
        { name: "Caso Admitido", value: "caso_admitido", tipo: "bool" },
        { name: "Persona", value: "persona" },
        { name: "Tipo persona", value: "tipo_persona" },
        { name: "Delitos", value: "delitos" },
        { name: "No. denuncia", value: "no_denuncia" },
        { name: "Fecha denuncia", value: "fecha_denuncia", tipo: "fecha" },
        { name: "Donde denuncia", value: "donde_denuncia" },
        { name: "Procesos", value: "procesos" },
        { name: "Profesional", value: "profesional" },
        { name: "Acompañamiento", value: "acompanamientos" },
        { name: "Tipo Servicios", value: "servicios" },
        { name: "Histórico", value: "historica", tipo: "bool" },
        { name: "IV histórico", value: "iv_historico" },
        { name: "Año histórico", value: "ano_historico" },
        { name: "Consultas", value: "consultas" },
        { name: "Denuncia", value: "denuncia", tipo: "bool" },
        { name: "Entidad que deriva", value: "entidad_deriva" },
        {
          name: "Tipo documento derivación",
          value: "tipo_documento_derivacion",
        },
        { name: "Fecha derivación", value: "fecha_referencia", tipo: "fecha" },
        { name: "No. Referencia", value: "doc_referencia" },
        { name: "Estado", value: "estado", tipo: "bool-estado" },
        { name: "Admisibilidad", value: "admisibilidad" },
        { name: "Atenciones", value: "atenciones" },
        { name: "Comentarios", value: "comentarios" },
        {
          name: "¿Cómo le gusta que le llamen?",
          value: "como_le_gusta_que_le_llamen",
        },
        {
          name: "Descripción motivo no admisibilidad",
          value: "descripcion_motivo_no_admisibilidad",
        },
        { name: "Es admisible", value: "es_admisible", tipo: "bool" },
        { name: "No admisibilidad", value: "no_admisibilidad" },
        { name: "Observaciones", value: "observaciones" },
      ];

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };
      await fetch(
        `${process.env.REACT_APP_URL_REPORTES}/export-file`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          setTableLoading(false);
          window.open(url, "_blank");
        })
        .catch((error) => console.log("error", error));
    });
  };

  const fetchOrientaciones = () => {
    setTableLoading(true);
    orientaciones(urlFetch, order, search, filters, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetOrientaciones = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getOrientaciones(urlFetch, search, order, filters, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchOrientaciones, [urlFetch, order, filters, reFetch, token]);
  useEffect(fetchGetOrientaciones, [urlFetch, token, order, search, filters]);

  const handleVer = (item) => {
    setOrientacionDetalle(item);
    setDetalle(true);
  };

  const handleAgregar = (item) => {
    if (item.caso_admitido === false) {
      setOrientacion(item);
      setAddReferencia(true);
    } else {
      toast.error(
        "Solo se puede agregar una referencia externa a una orientacion que no fue admitida"
      );
    }
  };

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element, index) => {
      element.active = false;
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };

  const handleEditar = (registro) => {
    let existe = usuario?.is_superuser ? true : false;

    registro?.profesionales?.forEach((element) => {
      if (element === usuario?.id) {
        existe = true;
      }
    });

    // if (registro?.profesional?.id !== usuario?.id && existe === false) {
    //   toast.error("No tiene acceso para editar esta orientación");
    // } else {
    //   changeTab(0);
    //   history.push(
    //     `/funcionalidades-generales/editar-orientacion/${registro.id}?admitido=${registro?.caso_admitido}`
    //   );
    // }

    changeTab(0);
    history.push(
      `/funcionalidades-generales/editar-orientacion/${registro.id}?admitido=${registro?.caso_admitido}`
    );
  };

  const handleEliminar = (id) => {
    setId(id);
    setEliminar(true);
  };

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Orientación Legal-Victimológica", href: "#", current: true },
  ];

  const opcion = (registro) => {
    return (
      <span>
        {CheckPermiso("CambioEstadoOrientaciones") === true && ( //mm permiso agregado
          <button
            type="button"
            className={clases.trashIcon}
            onClick={() => {
              setCambiarEstado(true);
              setRegistro(registro);
            }}
          >
            <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
            <span className="tooltiptext">cambiar estado</span>
          </button>
        )}
        {registro.caso_admitido ? (
          <button></button>
        ) : (
          <button type="button" className={clases.addIcon2} onClick={() => {
            console.log(registro)
            setOrientacion(registro);
            printFicha();
          }}>
            <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
            <span className="tooltiptext">Imprimir</span>
          </button>
        )}
      </span>
    );
  };

  const printFicha = async () => {
    if (casoAdmitido === false || casoAdmitido === 'false') {
      setCargando(true);
      return await getPersonasCasoDetails([orientacion.persona], token).then(async (data) => {
        setFicha(true);
        let persona_detalle = data.personas[0];
        let orientacionSend = { ...orientacion, persona_detalle: persona_detalle};
        setFichaData({
          reporte: 'ficha-tecnica-no-admisible',
          orientacion: orientacionSend,
          usuario: usuarioLogin,
          codigoIso: "DAL-DSV-FOR-4"
        });

        setCargando(false);
      });
    }
    let personas = [];

    orientacion.caso.personas.forEach((p) => {
      personas.push(p.persona.id);
    });

    await postValidarFicha(orientacion.id, token).then(async (data) => {
      if (data?.status >= 200 && data?.status <= 210) {
        setCargando(true);
        setFicha(true);
        await getPersonasCasoDetails(personas, token).then(async (data) => {
          await getExpedienteSindicados(orientacion.caso.id, token).then(
            async (sindicadosList) => {
              await getExpedienteSindicadoDelitos(orientacion.caso.id, token).then(
                async (delitosSindicados) => {
                  await getCasoEvaluacionRiesgo(orientacion.caso.id, token).then(
                    async (evaluacionRiesgos) => {
                      await getCasoHechos(orientacion.caso.id, token).then(
                        async (casoHechos) => {
                          await getCasoHechosTemporalidad(
                            orientacion.caso.id,
                            token
                          ).then(async(casoHechosTemp) => {

                            let expedientes = orientacion.caso.expedientes;
                            let diligenciasUrgentes = [];

                            await expedientes.forEach(async (exp, index) => {
                              await getDiligenciasExpediente(exp.id, token).then(async diligencias => {

                                diligencias.results.forEach(dil => {
                                  diligenciasUrgentes.push(dil);
                                })
                                await getCausasPenales(orientacion.caso.id, token).then( causaPenal => {
                                  if (index === (expedientes.length - 1)) {
                                    setFichaData({
                                      reporte: 'ficha-tecnica',
                                      orientacion: orientacion,
                                      causaPenal: causaPenal.results,
                                      personas: data.personas,
                                      sindicados: sindicadosList?.results,
                                      delitos: delitosSindicados?.results,
                                      riesgos: evaluacionRiesgos?.results,
                                      hechos: casoHechos?.results,
                                      hechosTemp: casoHechosTemp?.results,
                                      usuario: usuarioLogin,
                                      diligencias: diligenciasUrgentes,
                                      codigoIso: "DAL-DSV-FOR-3"
                                    });
                                    setCargando(false);

                                  }
                                })
                              });
                            });
                          });
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        });
      }
    }).catch(function (error) {
      if (
        error.response.status >= 400 &&
        error.response.status <= 450
      ) {
        toast.error(error.response.data.message);
      } else if (error.response.status >= 500) {
        toast.error("Error interno comuniquese con el administrador");
      }
    });
  };


  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div className="justify-end">
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />
          {CheckPermiso("ExportarOrientaciones") === true && ( //mm permiso agregado
            <ExportButton
              handlePdf={() => {
                exportData("pdf");
              }}
              show={"xls"}
              handleExcel={() => {
                exportData("xls");
              }}
            />
          )}
          {CheckPermiso("ExportarResumenOrientaciones") === true && ( //mm permiso agregado
            <ExportButton
              title={"Resumen"}
              handleOpciones={exportResumen}
              opciones={[
                {
                  text: "Resumen de Orientaciones",
                  value: "resumenorienacion",
                },
              ]}
            />
          )}
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            handleEliminar={handleEliminar}
            showEditar={true}
            handleEditar={handleEditar}
            setOrder={setOrder}
            order={order}
            //agregar={true}
            agregar={CheckPermiso("OrientacionesReferencia") === true} //mm permiso agregado
            agregar1="Crear referencia"
            handleAgregar={handleAgregar}
            addAccion={true}
            opciones={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle de orientación"}
              open={verDetalle}
              closeModal={() => {}}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle
                  detalle={orientacionDetalle}
                  cancelar={setEliminar}
                />
              }
            />
          )}
          {addReferencia && (
            <ModalBig
              title={"Agregar referencia"}
              open={addReferencia}
              closeModal={() => {}}
              cerrar={() => {
                setAddReferencia(false);
              }}
              children={<FormReferencia orientacion={orientacion} />}
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar la orientación número ${id}?`}
              open={isEliminar}
              closeModal={() => {}}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteRegistro(id, permiso, token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false);
                        setEliminar(false);
                        setId("");
                        setReFetch(true);
                        toast.error(data.message);
                      } else {
                        toast.success("Registro eliminado con éxito...!");
                        setLoading(false);
                        setEliminar(false);
                        setId("");
                        setReFetch(true);
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                    setId("");
                  }}
                />
              }
            />
          )}
          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => {}}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={
                <Filtros
                  setFilters={setFilters}
                  setOpenFilters={setOpenFilters}
                  setUrlFech={setUrlFech}
                />
              }
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
          {cambiarEstado && (
            <ModalGenerico
              title={"Cambiar el estado de la orientacion"}
              open={cambiarEstado}
              closeModal={() => {}}
              cerrar={() => {
                setCambiarEstado(false);
              }}
              children={
                <CambiarEstado
                  registro={registro}
                  setCambiarEstado={setCambiarEstado}
                  setReFetch={setReFetch}
                />
              }
            />
          )}
                  {ficha && (
          <ModalAmplio
            title={`Ficha técnica`}
            open={ficha}
            closeModal={() => { }}
            cerrar={() => {
              setFicha(false);
            }}
            children={
              cargando ?
                <Spinner /> : <PdfViewer
                  data={fichaData}
                  reporte={fichaData.reporte}
                  style={{ height: "80vh" }}
                />
            }
          />
        )}
        </div>
      </div>
    </div>
  );
};
