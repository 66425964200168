import { types } from "../types/types";

export const ChangeCasoTab = (value) => {
  return {
    type: types.ChangeCasoTab,
    payload: value,
  };
};

export const SelectedTabCaso = () => {
    let tabs = [
      { index: 0, active: true, block: false, name:"Generales" },
      { index: 2, active: false, block: false, name:"Víctimas" },
      { index: 3, active: false, block: false, name:"Querellantes / representante legal" },
      { index: 1, active: false, block: false, name:"Asistencia legal" },
      { index: 4, active: false, block: false, name:"Atención victimológica" },
      { index: 5, active: false, block: false, name:"Riesgos" },
      { index: 5, active: false, block: false, name:"Reparación digna" },
      { index: 6, active: false, block: false, name:"Evaluación diagnóstica" },
      { index: 7, active: false, block: false, name:"Dirección" },]
  return tabs;
};
