import { HomeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

export default function BreadCrumbTwo(props) {
  return (
    <nav className="flex">
      <ol  className="bg-maivi-gray-5 rounded-lg px-6 flex ">
        <li className="flex">
          <div className="flex items-center">
            <Link to={"/"} className="text-maivi-primaryFont hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {props.navegacion.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-8 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={page.href}
                className="ml-4 text-sm font-medium text-maivi-primaryFont hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}