/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState,useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import { getModalidades,getAccionesSeguimiento } from "./services";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
  let filtros = "";
  const [modalidades, setModalidades] = useState([]);
  const [accionesSeguimiento, setAccionesSeguimiento] = useState([]);
  const [state, setState] = useState({
    fecha_desde:"",
    fecha_hasta: "",
    accion_seguimiento:"",
    modalidad:""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(()=>{
    getModalidades(props.token).then((data)=>{
      setModalidades(data);
    })
  },[]);

  useEffect(()=>{
    getAccionesSeguimiento(props.token).then((data)=>{
      setAccionesSeguimiento(data);
    })
  },[])

  return (
    <div className="mx-4">
        <div className="col-span-12 mt-1">
         <SelectSiavi 
           name="accion_seguimiento"
           label="Tipo"
           value={state.accion_seguimiento}
           onChange={onChange}
           initValue=""
           selectInit="Elegir tipo"
           list={
             accionesSeguimiento?.results?.map((item,index) =>{
               return <option key={item.nombre + index} value={item.id}>{item?.atencion?.nombre} - {item.nombre}</option>
             })
           }
         />
     </div>
       <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="modalidad"
           label="Modalidad"
           value={state.modalidad}
           onChange={onChange}
           initValue=""
           selectInit="Elegir modalidad"
           list={
             modalidades?.results?.map((item,index) =>{
               return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
             })
           }
         />
     </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha fin"
        />
      </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `&${state.modalidad === "" ? "" : `modalidad=${state.modalidad}`}&${state.accion_seguimiento === "" ? "" : `accion_seguimiento=${state.accion_seguimiento}`}&${state.fecha_desde === "" ? "" : `fecha_hora_planificada_inicia__gte=${state.fecha_desde}`}&${state.fecha_hasta === "" ? "" : `fecha_hora_planificada_fin__lte=${state.fecha_hasta}`}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
      </div>
  );
}
