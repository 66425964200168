/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../../components/common/TextArea";
import { SelectSiavi } from "../../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { clases } from "../../../../../../helpers/clases";
import { EyeIcon, PencilAltIcon, TrashIcon, PrinterIcon } from "@heroicons/react/outline";
import { FormDetalleSeguimiento } from "./detalleSeguimiento";
import { getExpedienteSindicadoDelitos } from "../../../../orientacion/editar/services";
import { bloqueoFecha } from "../../../../../../helpers/utils";
import {
    postSeguimiento,
    getProfesionales,
    getSeguimientos,
    getModalidades,
    deleteSeguimientos,
    putSeguimiento
} from "./services";
import SelectAC from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../../../../../components/common/Spinner";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import toast from 'react-hot-toast';
import ModalGenerico from "../../../../../../components/common/Modal";
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import PdfViewer from "../../../../../../components/common/PdfViewer";
import Label from "../../../../../../components/common/Label";
import moment from "moment";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    fecha_seguimiento: Yup.string().required("fecha es requerida"),
    modalidad: Yup.string().required("modalidad es requerida")
});

export const Seguimiento = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [profesionales, setProfesionales] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [seguimientos, setSeguimientos] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [verDetalle, setDetalle] = useState(false);
    const [seguimientoDetalle, setSeguimientoDetalle] = useState({});
    const [editar, setEditar] = useState(false);
    const [seguimientoPdf, setSeguimientoPdf] = useState(false);
    const [seguimientoPdfData, setSeguimientoPdfData] = useState({});
    const [profesionalSelect, setProfesional] = useState(undefined);
    const [valor,setValor] = useState("");
    const animatedComponents = makeAnimated();
    const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user.username);
    const optionsProfesionales = [];

    useEffect(() => {
        getProfesionales(props?.referencia?.expediente?.servicio, token).then((data) => {
            setProfesionales(data);
        });
    }, []);

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
        };
        optionsProfesionales.push(reg);
    });

    const changeSelectProfesionales = (e) => {
        //setValor(e.label);
        setProfesional(e.value);
    };

    useEffect(() => {
        setCargando(true);
        getSeguimientos(props.referencia.id, token).then((data) => {
            setSeguimientos(data);
            setCargando(false);
        });
    }, [token, refetch]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data);
        });
    }, [token, refetch]);

    const headersTable = [
        "Fecha seguimiento",
        "Profesional Seguimiento",
        "Profesional Crea",
        "Profesional Externo",
        "Objetivo seguimiento",
        "Acciones"
    ];

    return (
        <Formik
            enableReinitialize
            initialValues={{
                seguimiento_a_victima: props.seguimientoVictima,
                fecha_seguimiento: "",
                objetivo_seguimiento: "",
                retroalimentacion_externa: "",
                estado_atencion: "",
                acciones_coordinadas_seguimiento: "",
                comentarios_profesional: "",
                referencia: props?.referencia?.id,
                profesional_seguimiento: "",
                nombre_profesional_seguimiento : "",
                modalidad: "",
                especificaciones_modalidad: "",
                profesional_externo: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values, { resetForm }) => {
                if (editar) {
                    values.profesional_seguimiento = profesionalSelect;
                    await putSeguimiento(values.id, values, token)
                        .then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                                toast.success("Registro actualizado con éxito...!");
                                setRefetch(true);
                                setRefetch(false);
                                resetForm({});
                                setEditar(false);
                                setValor(".");
                                setProfesional(undefined);
                            }
                        })
                        .catch(function (error) {
                            if (
                                error.response.status === 400 ||
                                error.response.status === 401
                            ) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(
                                    "Error interno comuniquese con el administrador"
                                );
                            }
                        });
                } else {
                    values.profesional_seguimiento = profesionalSelect;
                    await postSeguimiento(values, token).then(async (data) => {
                        if (data?.status >= 200 || data?.status <= 210) {
                            toast.success('Seguimiento guardado con éxito.');
                            setRefetch(true);
                            setRefetch(false);
                            resetForm({});
                            setValor(null);
                            setProfesional(undefined);
                        }
                    }).catch(function (error) {
                        if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                        ) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-3 border rounded-lg p-3 items-end">
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                            <InputSiavi
                                type="date"
                                name="fecha_seguimiento"
                                value={values.fecha_seguimiento}
                                onChange={handleChange}
                                maximo={bloqueoFecha()}
                                label="Fecha seguimiento"
                                validacion={true}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                            <SelectSiavi
                                label="Modalidad"
                                name="modalidad"
                                value={values.modalidad}
                                validacion={true}
                                valueInit=""
                                selectInit="Elegir modalidad"
                                onChange={handleChange}
                                list={
                                    modalidades?.results?.map((item, index) => {
                                        return <option key={item.nombre + index} value={item.id}>{item?.nombre}</option>
                                    })
                                }
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                            <InputSiavi
                                label="Especificaciones modalidad"
                                name="especificaciones_modalidad"
                                value={values.especificaciones_modalidad}
                                onChange={handleChange}
                            />
                        </div>
                        {
                            (props.referencia.referencia_interna) ? "" :
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                                    <InputSiavi
                                        label="Profesional externo"
                                        value={values.profesional_externo}
                                        name="profesional_externo"
                                        onChange={handleChange}
                                    />
                                </div>
                        }
                        {
                            (props?.referencia?.referencia_interna)
                                ?
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                                    <Label text="Profesional que da seguimiento en el instituto (IV)" />
                                    <SelectAC
                                        inputValue={valor}
                                        onInputChange={(e)=>{
                                            setValor(e.label)
                                        }}
                                        placeholder="Seleccionar profesional"
                                        components={animatedComponents}
                                        className="basic-multi-select text-xs text-maivi-primaryBlue w-full"
                                        options={optionsProfesionales}
                                        onChange={changeSelectProfesionales}
                                        maxMenuHeight={240}
                                    />
                                </div>
                                :
                                ""
                        }
                        {
                            (props.referencia.referencia_interna) ? "" :
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                                    <Label text='Institución donde refiere:' className='mb-3' />
                                    <Label text={props?.referencia?.gestion_institucion?.nombre_fiscalia} />
                                </div>
                        }
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 ">
                            <TextAreaSiavi
                                label="Objetivo del seguimiento"
                                value={values.objetivo_seguimiento}
                                name="objetivo_seguimiento"
                                rows={2}
                                cols={2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 ">
                            <TextAreaSiavi
                                label="Información proporcionada por la o él profesional sobre el plan de atención"
                                name="retroalimentacion_externa"
                                value={values.retroalimentacion_externa}
                                rows={2}
                                cols={2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 ">
                            <TextAreaSiavi
                                label="Estado del proceso de Atención"
                                name="estado_atencion"
                                value={values.estado_atencion}
                                rows={2}
                                cols={2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 ">
                            <TextAreaSiavi
                                label="Acciones coordinadas con la o él profesional para el avance del proceso"
                                name="acciones_coordinadas_seguimiento"
                                value={values.acciones_coordinadas_seguimiento}
                                rows={2}
                                cols={2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 ">
                            <TextAreaSiavi
                                label="Observaciones"
                                name="comentarios_profesional"
                                value={values.comentarios_profesional}
                                rows={2}
                                cols={2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2">
                            <ButtonSiavi
                                type="submit"
                                disabled={isSubmitting}
                                text={editar ? "Actualizar" : "Guardar"}
                            />
                        </div>
                        {isSubmitting && <Spinner />}
                    </div>
                    <div className="grid grid-cols-12 border rounded-lg p-3 mt-2">
                        <div className="col-span-12">
                            <TableSiavi
                                cargando={cargando}
                                headers={headersTable}
                                body={
                                    seguimientos?.results?.map((item, index) =>
                                        <tr className={clases.tr} key={index + item.id}>
                                            <td className={clases.td}>{moment(item.fecha_seguimiento).format("DD-MM-YYYY")}</td>
                                            <td className={clases.td}>{item.profesional_seguimiento?.first_name} {item.profesional_seguimiento?.last_name}</td>
                                            <td className={clases.td}>{item?.referencia?.profesional_crea?.first_name} {item?.referencia?.profesional_crea?.last_name}</td>
                                            <td className={clases.td}>{item?.profesional_externo}</td>
                                            <td className={clases.td}>{item?.objetivo_seguimiento}</td>
                                            <td className={clases.td}>
                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        setDetalle(true);
                                                        setSeguimientoDetalle(item);
                                                    }}
                                                >
                                                    <EyeIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Ver detalle</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setFieldValue('fecha_seguimiento', item.fecha_seguimiento);
                                                        setFieldValue('objetivo_seguimiento', item?.objetivo_seguimiento);
                                                        setFieldValue('especificaciones_modalidad', item.especificaciones_modalidad);
                                                        setFieldValue('retroalimentacion_externa', item?.retroalimentacion_externa);
                                                        setFieldValue('estado_atencion', item?.estado_atencion);
                                                        setFieldValue('acciones_coordinadas_seguimiento', item.acciones_coordinadas_seguimiento);
                                                        setFieldValue('comentarios_profesional', item.comentarios_profesional);
                                                        setFieldValue('profesional_seguimiento', item.profesional_seguimiento.id);
                                                        setFieldValue('modalidad', item.modalidad.id);
                                                        setFieldValue('profesional_externo', item.profesional_externo);
                                                        setFieldValue('seguimiento_a_victima', item.seguimiento_a_victima);
                                                        setFieldValue('referencia', item.referencia.id);
                                                        setFieldValue('id', item.id);
                                                        setProfesional(item.profesional_seguimiento.id);
                                                        setFieldValue('nombre_profesional_seguimiento',`${item?.profesional_seguimiento?.first_name} ${item?.profesional_seguimiento?.last_name}`)
                                                        setValor(`${item?.profesional_seguimiento?.first_name} ${item?.profesional_seguimiento?.last_name}`)
                                                        setEditar(true);
                                                    }}
                                                    className={clases.addIcon}
                                                >
                                                    <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    <span className="tooltiptext text-xs">Editar</span>
                                                </button>

                                                <button
                                                    type="button"
                                                    className={clases.addIcon}
                                                    onClick={async () => {
                                                        await getExpedienteSindicadoDelitos(item.referencia.caso.id, token).then(async (dataSindicadoDelitos) => {
                                                            setSeguimientoPdfData({
                                                                ...item,
                                                                delitos: dataSindicadoDelitos.results,
                                                                usuario: usuarioLogin,
                                                                codigoIso: "DTS-FOR-12"
                                                            })
                                                            setSeguimientoPdf(true);
                                                        })

                                                    }}
                                                >
                                                    <PrinterIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                                                    <span className="tooltiptext text-xs">Imprimir</span>
                                                </button>

                                                <button
                                                    type="button"
                                                    onClick={async () => {
                                                        await deleteSeguimientos(item.id, token)
                                                            .then((data) => {
                                                                if (data?.status === 200 || data?.status <= 210) {
                                                                    toast.success("Registro eliminado con éxito...!");
                                                                    setRefetch(true);
                                                                    setRefetch(false);
                                                                }
                                                            })
                                                            .catch(function (error) {
                                                                if (
                                                                    error.response.status === 400 ||
                                                                    error.response.status === 401
                                                                ) {
                                                                    toast.error(error.response.data.message);
                                                                } else if (error.response.status >= 500) {
                                                                    toast.error(
                                                                        "Error interno comuniquese con el administrador"
                                                                    );
                                                                }
                                                            });
                                                    }}
                                                    className={clases.addIcon}
                                                >
                                                    <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                    <span className="tooltiptext text-xs">Eliminar</span>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            />
                        </div>
                    </div>


                    {seguimientoPdf && (
                        <ModalAmplio
                            title={`Imprimir Seguimiento`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setSeguimientoPdf(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <PrinterIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                            children={
                                <PdfViewer
                                    data={seguimientoPdfData}
                                    reporte={'seguimiento-victima-IE'}
                                    style={{ height: "80vh" }}
                                />
                            }
                        />
                    )}

                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de Seguimiento"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            children={
                                <FormDetalleSeguimiento detalle={seguimientoDetalle} />
                            }
                        />
                    )}
                </form>
            )}
        </Formik>
    );
};