import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { SelectSiavi } from "../../../components/common/Select";
import {
  postConsentimientoInformado,
  putConsentimientoInformado,
  getServicios,
  getAtenciones,
  getIdiomas,
  getIdiomasMayas,
  getConfiguraciones
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import toast from "react-hot-toast";
import Label from "../../../components/common/Label";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import * as Yup from "yup";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string().max(300, "máximo 300 caracteres"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [servicios, setServicios] = useState([]);
  const [atenciones, setAtenciones] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [idiomasmayas, setIdiomasMayas] = useState([]);
  const [config, setConfig] = useState({});
  const [mostrarIdiomaMaya, setMostrarIdiomaMaya] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    if (props.value.consentimiento && props.editar) {
      const blocksFromHtml = htmlToDraft(props.value.consentimiento);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(contentState);
    }

    return EditorState.createEmpty()
  });

  useEffect(() => {
    getIdiomasMayas(token).then((data) => {
      setIdiomasMayas(data)
    });
  }, [token]);

  useEffect(() => {
    getIdiomas(token).then((data) => {
      setIdiomas(data);
    });
  }, [token]);

  useEffect(() => {
    getAtenciones(token).then((data) => {
      setAtenciones(data);
    });
  }, [token]);

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, [token]);

  useEffect(() => {
    getConfiguraciones(token).then((data) => {
      setConfig(data);
    });
  }, [token]);


  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          titulo: props.editar ? props.value.titulo : "",
          descripcion: props.editar ? props.value.descripcion : "",
          consentimiento: props.editar ? props.value.consentimiento : "",
          fecha_autorizacion: null,
          fecha_inactivacion: null,
          servicio: props.editar ? props.value.servicio?.id : "",
          atencion: props.editar ? props.value.atencion?.id : "",
          idioma: props.editar ? props.value.idioma.id : "",
          idioma_maya: props.editar ? props.value.idioma_maya?.id : "",
          usuario_creacion: null,
          usuario_autoriza: null,
          usuario_inactivacion: null,
          estado: props.editar ? props.value.estado : true,
          unificado: props.editar ? props.value.unificado : true,
          es_nna: props.editar ? props.value.es_nna : false,
          autorizado: props.editar ? props.value.autorizado : false,
          replicable_editable : props.editar ? props.value.replicable_editable : false
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putConsentimientoInformado(
              props.value.id,
              values,
              token
            ).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro actualizado con éxito...!");
              }
            });
          } else {
            await postConsentimientoInformado(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 xl:col-span-12 lg:col-span-12">
                <div className="grid grid-cols-12 gap-3 border rounded-lg p-3">
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                    <InputSiavi
                      type="text"
                      name="nombre"
                      value={values.nombre}
                      onChange={handleChange}
                      validacion={true}
                      label="Nombre*"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                    <InputSiavi
                      type="text"
                      name="titulo"
                      value={values.titulo}
                      onChange={handleChange}
                      validacion={true}
                      label="Título*"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                    <InputSiavi
                      type="text"
                      name="descripcion"
                      value={values.descripcion}
                      onChange={handleChange}
                      validacion={true}
                      label="Descripción"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    <Label text="Unificado" />
                    <Toggle enabled={values.unificado} name="unificado" />
                  </div>
                  {
                    (values.unificado) ? "" :
                      <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 ">
                        <SelectSiavi
                          label="Servicio"
                          name="servicio"
                          value={values.servicio}
                          onChange={handleChange}
                          valueInit=""
                          selectInit="Elegir servicio"
                          list={
                            servicios?.results?.map((item) => {
                              return (
                                <option key={item.nombre} value={item.id}>
                                  {item.nombre}
                                </option>
                              );
                            })
                          }
                        />
                      </div>
                  }
                  {
                    (values.unificado) ? "" :
                      <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 ">
                        <SelectSiavi
                          label="Atencion"
                          name="atencion"
                          value={values.atencion}
                          onChange={handleChange}
                          valueInit=""
                          selectInit="Elegir atencion"
                          list={
                            atenciones?.results?.map((item) => {
                              return (
                                <option key={item.nombre} value={item.id}>
                                  {item.nombre}
                                </option>
                              );
                            })
                          }
                        />
                      </div>
                  }
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 ">
                    <SelectSiavi
                      label="Idioma"
                      id="idioma"
                      name="idioma"
                      value={values.idioma}
                      onChange={(e) => {
                        setFieldValue("idioma", e.target.value);
                        let combo = document.getElementById("idioma");
                        let text = combo.options[combo.selectedIndex].text;
                        if (text === config.habilita_idioma_maya.nombre.trim()) {
                          setMostrarIdiomaMaya(true);
                        } else {
                          setMostrarIdiomaMaya(false);
                        }
                      }}
                      valueInit=""
                      selectInit="Elegir idioma"
                      list={
                        idiomas?.results?.map((item) => {
                          return (
                            <option key={item.nombre} value={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })
                      }
                    />
                  </div>
                  {
                    mostrarIdiomaMaya &&
                    <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 ">
                      <SelectSiavi
                        label="Idioma maya"
                        name="idioma_maya"
                        value={values.idioma_maya}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir idioma maya"
                        list={
                          idiomasmayas?.results?.map((item) => {
                            return (
                              <option key={item.nombre} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                        }
                      />
                    </div>
                  }
                  {
                    (values.unificado) ? "" :
                      <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                        <Label text="Por atencion" />
                        <Toggle enabled={values.por_atencion} name="por_atencion" />
                      </div>
                  }
                  {
                    (values.unificado) ? "" :
                      <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                        <Label text="Por servicio" />
                        <Toggle enabled={values.por_servicio} name="por_servicio" />
                      </div>
                  }
                  {/* {props.editar && (
                    <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                      <Label
                        text={`${values.estado ? "Activo" : "Inactivo"}`}
                      />
                      <Toggle enabled={values.estado} name="estado" />
                    </div>)} */}

                  <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    <Label text="Es NNA" />
                    <Toggle enabled={values.es_nna} name="es_nna" />
                  </div>
                  <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    <Label text="Editar replicado" />
                    <Toggle enabled={values.replicable_editable} name="replicable_editable" />
                  </div>
                  <div className="col-span-12 xl:col-span-2 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    {
                      !values.autorizado &&
                      <ButtonSiavi
                        type="submit"
                        disabled={isSubmitting}
                        text={props.editar ? "Actualizar" : "Guardar"}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="col-span-12 border p-3 rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100 rounded-lg ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan={3}>Campos Utilizables</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{nombre_victima}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{identificacion_victima}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{delitos}"}</td>
                    </tr>
                    <tr>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{numero_caso}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{persona}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{tipo_persona}"}</td>
                    </tr>
                    <tr>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{identificacion_persona}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{profesional_asignado}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{servicio_expediente}"}</td>
                    </tr>
                    <tr>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{numero_denuncia}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{organo_jurisdiccional}"}</td>
                      <td className="px-1 py-1 whitespace-nowrap text-sm">{"{causa_penal}"}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="col-span-6 mt-5 ">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorStyle={{ height: "950px", border: "1px solid #ededed", padding: "5px" }}
                    onEditorStateChange={async (state) => {
                      await setEditorState(state);
                      const data = convertToRaw(editorState.getCurrentContent());
                      setFieldValue('consentimiento', draftToHtml(data));
                    }}
                  />
                </div>
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
