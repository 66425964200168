import { useState } from 'react'
import Divider from '../../../components/common/Divider';
import { postPermisos } from './services';
import ButtonAdd from "../../../components/common/ButtonAdd";
import toast from 'react-hot-toast';
import { PencilAltIcon, ArrowLeftIcon } from "@heroicons/react/solid";

const estructura = {
  menus: {
    text: 'MENUS',
    menus: [
      {
        text: 'Catálogos',
        permiso: 'catalogos',
        ver: false
      },
      {
        text: 'Reportes',
        permiso: 'reportes',
        ver: false
      },
      {
        text: 'Seguridad',
        permiso: 'seguridar',
        ver: false
      },
      {
        text: 'Registro',
        permiso: 'Registro',
        ver: false
      },
      {
        text: 'Asistencia Legal',
        permiso: 'AsistenciaLegal',
        ver: false
      },
      {
        text: 'Atención Victimológica',
        permiso: 'AtencionVictimologica',
        ver: false
      },
      {
        text: 'Mis Registros',
        permiso: 'MisRegistros',
        ver: false
      },
      {
        text: 'Favoritos',
        permiso: 'Favoritos',
        ver: false
      },
      {
        text: 'Ayuda',
        permiso: 'Ayuda',
        ver: false
      }
    ]
  },

  seguridad: {
    text: 'SEGURIDAD',
    menus: [
      {
        text: 'Usuarios',
        permiso: 'usuarios',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'Roles',
        permiso: 'roles',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'Bitacora',
        permiso: 'bitacora',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'Preguntas',
        permiso: 'preguntas',
        ver: false,
        editar: false,
        eliminar: false
      }
    ]
  },

  reportes: {
    text: 'REPORTES',
    menus: [
      {
        text: 'DAL Rp. Sedes',
        permiso: 'dal.sedes',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Profesional',
        permiso: 'dal.profesional',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Audiencia',
        permiso: 'dal.audiencia',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Sentencias',
        permiso: 'dal.sentencia',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Desjudicializadora',
        permiso: 'dal.desjudicializadora',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Datos Sociodemográficos',
        permiso: 'dal.datos',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Metas',
        permiso: 'dal.metas',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Rendimiento Laboral',
        permiso: 'dal.rendimiento',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Ficha Medición Laboral',
        permiso: 'dal.ficha',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Reparaciones Dignas',
        permiso: 'dal.reparaciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Casos Admisibles',
        permiso: 'dal.casos',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Impugnaciones',
        permiso: 'dal.impugnaciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Indicadores',
        permiso: 'dal.indicadores',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Indicadores Orientaciones Casos',
        permiso: 'dal.indicadorOrientaciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Indicadores Sentencias',
        permiso: 'dal.indicadorSentencias',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Indicadores Reparaciones Dignas',
        permiso: 'dal.indicadorReparaciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DAL Rp. Listado de Expedientes',
        permiso: 'dal.expedientes',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Profesionales',
        permiso: 'dsv.profesional',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Total de Acciones Victimológicas',
        permiso: 'dsv.acciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Acciones por Área',
        permiso: 'dsv.accionesPorArea',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Información Victimológica',
        permiso: 'dsv.informacionVictimologica',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Visitas',
        permiso: 'dsv.visitas',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Expedientes por mesa',
        permiso: 'dsv.expedientes',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Informes Victimológicos',
        permiso: 'dsv.informes',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Expedientes Asignados',
        permiso: 'dsv.expedientesAsignados',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Referencias',
        permiso: 'dsv.referencias',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Planificación Semanal',
        permiso: 'dsv.planificacion',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Atenciones Victimológicas',
        permiso: 'dsv.atenciones',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Metas Psicología',
        permiso: 'dsv.metasPsicologia',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Metas Trabajo Social',
        permiso: 'dsv.metasTrabajoSocial',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Metas Medicina',
        permiso: 'dsv.metasMedicina',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Riesgos y Plan de Tratamiento',
        permiso: 'dsv.riesgo',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Matriz de Beneficiarios',
        permiso: 'dsv.matriz',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Cierre de casos',
        permiso: 'dsv.cierre',
        ver: false,
        editar: false,
        eliminar: false
      },
      {
        text: 'DSV Rp. Evolución de Vulnerabilidad',
        permiso: 'dsv.evolucion',
        ver: false,
        editar: false,
        eliminar: false
      }
    ]
  },

  registros: {
    text: 'REGISTROS',
    menus: [
      {
        text: 'Víctimas',
        permiso: 'victimas',
        ver: false,
        opciones: [       //mm agregar opciones
          {
            text: 'Crear víctimas',
            permiso: 'CrearVictimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar víctimas',
            permiso: 'ExpVictimasExcel',
            ver: false,
            editar: false,
          },
          {
            text: 'Agregar Visita a víctimas',
            permiso: 'Victimas.AgregaVisita',
            ver: false,
            editar: false,
          },
          {
            text: 'Agregar acción',
            permiso: 'Victima.AgregarAccion',
            ver: false,
            editar: false,
          },
          {
            text: 'Inactivar víctimas',
            permiso: 'InactivarVictimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar víctimas',
            permiso: 'EditarVictimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Eliminar víctimas',
            permiso: 'EliminarVictimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar: Agregar Teléfono',
            permiso: 'agregarTelefono',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar: Agregar Visita',
            permiso: 'MotivoVisita',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar: Crear Orientación',
            permiso: 'CrearOrientacion',
            ver: false,
            editar: false,
          },
        ]
      },
      {
        text: 'Orientación',
        permiso: 'Orientacion',
        ver: false,
        opciones: [
          {
            text: 'Exportar Orientaciones',
            permiso: 'ExportarOrientaciones',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar Resumen Orientaciones',
            permiso: 'ExportarResumenOrientaciones',
            ver: false,
            editar: false,
          },
          {
            text: 'Crear Referencia Externa',
            permiso: 'OrientacionesReferencia',
            ver: false,
            editar: false,
          },
          {
            text: 'Cambiar Estado Orientaciones',
            permiso: 'CambioEstadoOrientaciones',
            ver: false,
            editar: false,
          },
          //pestañas
          {
            text: 'Generales',
            permiso: 'Generales',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas',
            permiso: 'OVictimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellante',
            permiso: 'OQuerellante',
            ver: false,
            editar: false
          },
          {
            text: 'Sindicado',
            permiso: 'OSindicado',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos/Delitos',
            permiso: 'OProcesosDelitos',
            ver: false,
            editar: false
          },
          {
            text: 'Hechos',
            permiso: 'OHechos',
            ver: false,
            editar: false
          },
          {
            text: 'Riesgos',
            permiso: 'ORiesgos',
            ver: false,
            editar: false
          },
          {
            text: 'Evaluación Diagnóstica',
            permiso: 'oevaluaciondiagnostica',
            ver: false,
            editar: false
          },
          {
            text: 'Dirección',
            permiso: 'direccion',
            ver: false,
            editar: false
          },
          //Victimas
          {
            text: 'Víctimas - Agregar Servicios Legales',
            permiso: 'OVServicioslegales',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas - Agregar referencia interna',
            permiso: 'OVRefInterna',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas - Cambiar Estado',
            permiso: 'OVCambioEstado',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctima - Eliminar',
            permiso: 'OVEliminar',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas - Convertir en Querellante',
            permiso: 'OVConvertirQuerellante',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctima - Editar',
            permiso: 'OVEditar',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas - Crear Víctima Nueva',
            permiso: 'OVVictimaNueva',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctima - Agregar Víctima',
            permiso: 'OVAgregarVictima',
            ver: false,
            editar: false,
          },
          //querellantes
          {
            text: 'Querellantes - Cambiar Estado',
            permiso: 'OQCambioEstado',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellantes - Eliminar',
            permiso: 'OQEliminar',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellantes - Convertir en Víctima',
            permiso: 'OQConvertirVictima',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellante - Editar',
            permiso: 'OQEditar',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellante - Crear Víctima Nueva',
            permiso: 'OQQuerellanteNuevo',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellante - Agregar Víctima',
            permiso: 'OQAgregarQuerellante',
            ver: false,
            editar: false,
          },
          //Sindicado
          {
            text: 'Sindicados - Cambiar Estado',
            permiso: 'OSindicadosCambioEstado',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Eliminar',
            permiso: 'OSindicadosEliminar',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Editar',
            permiso: 'OSindicadoEditar',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Agregar Situación jurídica',
            permiso: 'OSindicadoSituacionjuridica',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Medida de coerción',
            permiso: 'OSindicadoMedidaCoercion',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Agregar Nuevo',
            permiso: 'OSindicadoAgregarNuevo',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Actualizar / Crear',
            permiso: 'OSindicadoActualizaCrea',
            ver: false,
            editar: false,
          },
          {
            text: 'Sindicados - Asignar Expediente',
            permiso: 'OSindicadoAsignarExpediente',
            ver: false,
            editar: false,
          },
          //Procesos Delitos
          {
            text: 'Procesos Delitos - Agregar Delitos',
            permiso: 'OPDAgregarDelitos',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos Delitos - Editar Delitos',
            permiso: 'OPDEditarDelitos',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos Delitos - Eliminar Delitos',
            permiso: 'OPDEliminarDelitos',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos Delitos - Agregar Etapa Procesal',
            permiso: 'OPDAgregarEtapaProcesal',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos Delitos - Causa Penal',
            permiso: 'OPDCausaPenal',
            ver: false,
            editar: false,
          },
          {
            text: 'Procesos Delitos - Diligencia Urgente',
            permiso: 'OPDDiligenciaUrgente',
            ver: false,
            editar: false,
          },
          //Hechos
          {
            text: 'Hechos - Crear',
            permiso: 'OHCrear',
            ver: false,
            editar: false,
          },
          {
            text: 'Hechos - Agregar Temporalidad',
            permiso: 'OHAgregaTemporalidad',
            ver: false,
            editar: false,
          },
          {
            text: 'Hechos - Editar',
            permiso: 'OHEditar',
            ver: false,
            editar: false,
          },
          {
            text: 'Hechos - Eliminar',
            permiso: 'OHEliminar',
            ver: false,
            editar: false,
          },
          {
            text: 'Hechos - Editar Temporalidad',
            permiso: 'OHEditarTemporalidad',
            ver: false,
            editar: false,
          },
          {
            text: 'Hechos - Eliminar Temporalidad',
            permiso: 'OHEliminaTemporalidad',
            ver: false,
            editar: false,
          },
          //Riesgos
          {
            text: 'Riesgos - Agregar',
            permiso: 'OAgregariesgo',
            ver: false,
            editar: false,
          },
          //Ev. Diagnostica Inicial
          {
            text: 'Ev. Diagnóstica Inicial - Guardar',
            permiso: 'OEDIGuardar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Editar',
            permiso: 'OEDIEditar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Plan Tratamiento Preliminar',
            permiso: 'OEDIPlanTratamiento',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Imprimir',
            permiso: 'OEDIImprimir',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Editar Plan Tratamiento Preliminar',
            permiso: 'OEDIEditarPlanTratamiento',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Eliminar Plan Tratamiento Preliminar',
            permiso: 'OEDIEliminarPlanTratamiento',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Diagnóstica Inicial - Agregar Plan Tratamiento Preliminar',
            permiso: 'OEDIAgregarPlanTratamiento',
            ver: false,
            editar: false,
          },
          {
            text: 'Ev. Final Impacto - Agregar Evaluación Final de Impacto',
            permiso: 'OEDIEvaluacionImpacto',
            ver: false,
            editar: false,
          },
          //RD
          {
            text: 'Registro de Expectativa Reparación Digna',
            permiso: 'OQExpectativaRD',
            ver: false,
            editar: false,
          }

        ]
      },
      {
        text: 'Visitas',
        permiso: 'visitas',
        ver: false,
        opciones: [
          {
            text: 'Crear Orientación',
            permiso: 'visitaorientacion',
            ver: false,
            editar: false,
          },
          {
            text: 'Registrar en Espacio Seguro',
            permiso: 'visitaespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Dar Seguimiento a Expediente',
            permiso: 'visitaseguimientocaso',
            ver: false,
            editar: false,
          },
          // detalle espacio seguro
          {
            text: 'Editar Registro Espacio Seguro',
            permiso: 'editarespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Eliminar Registro Espacio Seguro',
            permiso: 'eliminarespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Registro de Insumos en Espacio Seguro',
            permiso: 'registroinsumosespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Agregar Insumos en Espacio Seguro',
            permiso: 'agregarinsumosespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Eliminar Insumos en Espacio Seguro',
            permiso: 'eliminarinsumosespacioseguro',
            ver: false,
            editar: false,
          },
          {
            text: 'Actualizar Insumos en Espacio Seguro',
            permiso: 'actualizarinsumosespacioseguro',
            ver: false,
            editar: false,
          },
        ]
      },
      {
        text: 'Expedientes',
        ver: false,
        opciones: [

          {
            text: 'Generales',
            permiso: 'Generales',
            ver: false,
            editar: false,
          },
          {
            text: 'Víctimas',
            permiso: 'Victimas',
            ver: false,
            editar: false,
          },
          {
            text: 'Querellante',
            permiso: 'Querellante',
            ver: false,
            editar: false
          },
          {
            text: 'Asistencia Legal',
            permiso: 'AsistenciaLegal',
            ver: false,
            editar: false,
          },
          {
            text: 'Atención Victimológica',
            permiso: 'atencionvictimologica',
            ver: false,
            editar: false
          },
          {
            text: 'Riesgos',
            permiso: 'Riesgos',
            ver: false,
            editar: false
          },
          {
            text: 'Evaluación Diagnóstica',
            permiso: 'evaluaciondiagnostica',
            ver: false,
            editar: false
          },
          {
            text: 'Dirección',
            permiso: 'direccion',
            ver: false,
            editar: false
          },
          {
            text: 'Exportar Expedientes',
            permiso: 'ExportarExpedientes',
            ver: false,
            editar: false
          },
          {
            text: 'Editar Expedientes',
            permiso: 'EditarExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Cambio sede expedientes',
            permiso: 'CambioSedeExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Cambio sede caso',
            permiso: 'CambioSedeCaso',
            ver: false,
            editar: false
          },
          {
            text: 'Reabrir expediente',
            permiso: 'ReabrirExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Expedientes',
            permiso: 'EliminarExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Expedientes',
            permiso: 'EliminarExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Registro Medidas Victimológicas',
            permiso: 'RDMedidasVictimologicas',
            ver: false,
            editar: false
          },
          {
            text: 'Impresión de  Medidas Reparación Digna',
            permiso: 'RDImprimirMedidasRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Ver Detalles de Medidas de Reparación Digna',
            permiso: 'RDVerdetalleRD',
            ver: false,
            editar: false
          },
          {
            text: 'Registrar Seguimientos a Reparación Digna',
            permiso: 'RDIngresoSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Registrar Acciones Victimológicas a Reparación Digna',
            permiso: 'RDIngresoAcciones',
            ver: false,
            editar: false
          },
          {
            text: 'Cambiar estado a Medidas de Reparación Digna',
            permiso: 'RDCambiarEstado',
            ver: false,
            editar: false
          },
          {
            text: 'Adjuntar archivos a Medidas de Reparación Digna',
            permiso: 'RDArchivosAdjuntos',
            ver: false,
            editar: false
          }
        ]
      },
      {
        text: 'Expediente - Asistencia Legal',
        permiso: 'ExpedientesAL',
        ver: false,
        opciones: [
          {
            text: 'Agregar Estado de Proceso',
            permiso: 'EstadoProceso',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Etapa Procesal',
            permiso: 'EtapaProcesal',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Causa Penal',
            permiso: 'CausaPenal',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Diligencia Urgente',
            permiso: 'DiligenciaUrgente',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Notificaciones',
            permiso: 'AgregarNotificacion',
            ver: false,
            editar: false
          },
          {
            text: 'Adjuntar Documentos',
            permiso: 'AdjuntarArchivos',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Seguimientos',
            permiso: 'AgregarSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Agendar Audiencias',
            permiso: 'AgendarAudiencias',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Procuraciones',
            permiso: 'AgregarProcuraciones',
            ver: false,
            editar: false
          },
          {
            text: 'Impugnaciones Agregar Sentencia Previa',
            permiso: 'ImpAgregarSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Impugnaciones Agregar Reparación Digna',
            permiso: 'ImpReparacionDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Instrumentos Editables',
            permiso: 'AgregarInstrumentosEditables',
            ver: false,
            editar: false
          },
          {
            text: 'Impugnaciones Estado Proceso Agregar Adjuntos',
            permiso: 'ImpEstadoAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'Estado Proceso Agregar Sentencia',
            permiso: 'AgregarSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Estado Proceso Agregar Rep. Digna',
            permiso: 'AgregarRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Estado Proceso Acción Constitucional',
            permiso: 'AgregarAConstitucional',
            ver: false,
            editar: false
          },
          {
            text: 'Estado Proceso Adjuntar Docs.',
            permiso: 'EPAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Acción Constitucional',
            permiso: 'GuardarACI',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Sentencia',
            permiso: 'GrabarSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Sentencia',
            permiso: 'BorrarSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Agregar Detalle de  Sentencia',
            permiso: 'AgregarDetalleSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Detalle de  Sentencia',
            permiso: 'GuardarDetalleSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Detalle Sentencia',
            permiso: 'EliminarDetalleSentencia',
            ver: false,
            editar: false
          },
          {
            text: 'Grabar Reparación Digna',
            permiso: 'GrabarRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Reparación Digna',
            permiso: 'EliminarRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Detalle Reparación Digna',
            permiso: 'AgregarDetalleRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Detalle Reparación Digna',
            permiso: 'GuardarDetRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Detalle Reparación Digna',
            permiso: 'EliminarDetRepDigna',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Procuraciones',
            permiso: 'GuardarProcuraciones',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Procuraciones',
            permiso: 'EliminarProcuraciones',
            ver: false,
            editar: false
          },
          {
            text: 'Editar Procuraciones',
            permiso: 'EditarProcuraciones',
            ver: false,
            editar: false
          },
          {
            text: 'Enviar SMS en Procuraciones',
            permiso: 'SMSProcuraciones',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Seguimientos',
            permiso: 'GuardarSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Seguimientos',
            permiso: 'EliminarSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Editar Seguimientos',
            permiso: 'EditarSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Enviar SMS en Seguimientos',
            permiso: 'SMSSeguimientos',
            ver: false,
            editar: false
          },
          {
            text: 'Grabar Causa Penal',
            permiso: 'AgregarCausaPenal',
            ver: false,
            editar: false
          },
          {
            text: 'Grabar Diligencia Urgentes',
            permiso: 'GrabarDiligencia',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Diligencia Urgentes',
            permiso: 'EliminarDiligencia',
            ver: false,
            editar: false
          },
          {
            text: 'Grabar Notificaciones',
            permiso: 'GrabarNotificacion',
            ver: false,
            editar: false
          },
          {
            text: 'Notificaciones - Adjuntar Documentos',
            permiso: 'NotificacionesAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Notificaciones',
            permiso: 'EliminarNotificaciones',
            ver: false,
            editar: false
          },
          {
            text: 'Grabar Causa Penal',
            permiso: 'EPGrabarCausaPenal',
            ver: false,
            editar: false
          },
          {
            text: 'Registrar Resultado Audiencia',
            permiso: 'ResultadoAudiencia',
            ver: false,
            editar: false
          },
          {
            text: 'Audiencia - Envio de SMS',
            permiso: 'AudienciaSMS',
            ver: false,
            editar: false
          },
          {
            text: 'Guardar Audiencia',
            permiso: 'GuardarAudiencia',
            ver: false,
            editar: false
          },
          {
            text: 'Eliminar Audiencia',
            permiso: 'EliminarAudiencia',
            ver: false,
            editar: false
          },
          {
            text: 'Editar Audiencia',
            permiso: 'EditarAudiencia',
            ver: false,
            editar: false
          },
          {
            text: 'Generales - Agregar Nombramiento',
            permiso: 'AgregarNombramiento',
            ver: false,
            editar: false
          },
          {
            text: 'Generales - Imprimir Carátula',
            permiso: 'ImprimirCaratula',
            ver: false,
            editar: false
          },
          {
            text: 'Servicios - Enviar SMS',
            permiso: 'ServiciosSMS',
            ver: false,
            editar: false
          },
          {
            text: 'Servicios - Adjuntar Archivos',
            permiso: 'ServiciosAdjuntos',
            ver: false,
            editar: false
          },
          {
            text: 'Cerrar Expediente',
            permiso: 'CerrarExpediente',
            ver: false,
            editar: false
          },
          {
            text: 'Generales Adjuntar Documentos',
            permiso: 'GeneralesAdjuntar',
            ver: false,
            editar: false
          },
          {
            text: 'Cambiar Estado Caso',
            permiso: 'CambiarEstado',
            ver: false,
            editar: false
          },
          {
            text: 'Reabrir Caso',
            permiso: 'ReabrirCaso',
            ver: false,
            editar: false
          },
          {
            text: 'Actualizar Fiscalia',
            permiso: 'ActualizarFiscalia',
            ver: false,
            editar: false
          },
          {
            text: 'Notificaciones Internas',
            permiso: 'NotificacionesInternas',
            ver: false,
            editar: false
          },
          {
            text: 'Cambiar Sede Caso',
            permiso: 'CambiarSedeCaso',
            ver: false,
            editar: false
          },
          {
            text: 'Generales - Enviar SMS',
            permiso: 'GeneralesSMS',
            ver: false,
            editar: false
          },
          {
            text: 'AL Seguimientos - Gestionar',
            permiso: 'GestionarSeguimientos',
            ver: false,
            editar: false
          },

        ]
      },


      {
        text: 'Expediente - Atención Victimológica',
        permiso: 'ExpedientesAV',
        ver: false,
        editar: false,
        opciones: [
          {
            text: 'Trabajo Social',
            permiso: 'TS',
            ver: false,
            editar: false
          },
          {
            text: 'Psicología',
            permiso: 'PS',
            ver: false,
            editar: false
          },
          {
            text: 'Medicina',
            permiso: 'MD',
            ver: false,
            editar: false
          },
          {
            text: 'Psiquiatría',
            permiso: 'PQ',
            ver: false,
            editar: false
          },
          {
            text: 'Acciones Victimológicas',
            permiso: 'AccionesV',
            ver: false,
            editar: false
          },
          {
            text: 'Atenciones Complementarias',
            permiso: 'AtencionesComp',
            ver: false,
            editar: false
          },
          //Trabajo Social
          {
            text: 'TS - Evaluación Diagnóstica',
            permiso: 'TSEvaluacionDiagnostica',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Agregar Referencia',
            permiso: 'TSAgregarReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Adjuntar Documentos',
            permiso: 'TSAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Instrumentos',
            permiso: 'TSInstrumentos',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Acciones',
            permiso: 'TSAcciones',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Consentimiento',
            permiso: 'TSConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Instrumentos Editables',
            permiso: 'TSInstrumentosEditable',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Crea Referencias',
            permiso: 'TSCrearReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Editar Referencias',
            permiso: 'TSEditarReferencias',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Referencias Adjuntar Documentos',
            permiso: 'TSReferenciasDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Referencias Dar Seguimiento',
            permiso: 'TSReferenciaSeguimiento',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Imprimir Referencia',
            permiso: 'TSImprimirReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'TS - Inactivar Referencia',
            permiso: 'TSInactivarReferencia',
            ver: false,
            editar: false
          },


          //psicologia
          {
            text: 'PS - Agregar Referencia',
            permiso: 'PSAgregarReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'PS - Adjuntar Documentos',
            permiso: 'PSAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'PS - Instrumentos',
            permiso: 'PSInstrumentos',
            ver: false,
            editar: false
          },
          {
            text: 'PS - Acciones',
            permiso: 'PSAcciones',
            ver: false,
            editar: false
          },
          {
            text: 'PS - Consentimiento',
            permiso: 'PSConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'PS - Instrumentos Editables',
            permiso: 'PSInstrumentosEditable',
            ver: false,
            editar: false
          },
          //medicina
          {
            text: 'MED - Agregar Referencia',
            permiso: 'MDAgregarReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Adjuntar Documentos',
            permiso: 'MDAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Instrumentos',
            permiso: 'MDInstrumentos',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Acciones',
            permiso: 'MDAcciones',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Consentimiento',
            permiso: 'MDConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Instrumentos Editables',
            permiso: 'MDInstrumentosEditable',
            ver: false,
            editar: false
          },
          {
            text: 'MED - Evaluación Médica',
            permiso: 'MDEvaluacionMedica',
            ver: false,
            editar: false
          },


          //psiquiatria
          {
            text: 'PSQ - Agregar Referencia',
            permiso: 'PQAgregarReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Adjuntar Documentos',
            permiso: 'PQAdjuntarDocumentos',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Instrumentos',
            permiso: 'PQInstrumentos',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Acciones',
            permiso: 'PQAcciones',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Consentimiento',
            permiso: 'PQConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Instrumentos Editables',
            permiso: 'PQInstrumentosEditable',
            ver: false,
            editar: false
          },
          {
            text: 'PSQ - Diagnóstico Médico',
            permiso: 'PQDiagnosticoMedico',
            ver: false,
            editar: false
          },

          //acciones complementarias
          {
            text: 'At. Complementarias - Gestionar Acciones',
            permiso: 'ACGestionar',
            ver: false,
            editar: false
          },
          {
            text: 'At. Complementarias - Agregar Acciones',
            permiso: 'ACAcciones',
            ver: false,
            editar: false
          },


          {
            text: 'At. Complementarias - Eliminar Acciones',
            permiso: 'ACEliminarAcciones',
            ver: false,
            editar: false
          },


          //acciones victimológicas

          {
            text: 'Acciones - Gestionar Acciones',
            permiso: 'GestionarAcciones',
            ver: false,
            editar: false
          },

          //instrumentos

          {
            text: 'Instrumento - Guardar',
            permiso: 'GuardarInstrumento',
            ver: false,
            editar: false
          },
          {
            text: 'Instrumento - Eliminar',
            permiso: 'EliminarInstrumento',
            ver: false,
            editar: false
          },
          {
            text: 'Instrumento - Editar',
            permiso: 'EditarInstrumento',
            ver: false,
            editar: false
          },
          {
            text: 'Instrumento - Imprimir',
            permiso: 'ImprimirInstrumento',
            ver: false,
            editar: false
          },
          {
            text: 'Instrumento - Guardar Detalle',
            permiso: 'GuardarDetalleInstrumento',
            ver: false,
            editar: false
          },

          //Consentimiento

          {
            text: 'AV. Consentimiento - Guardar',
            permiso: 'GuardarConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento - Editar',
            permiso: 'EditarConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento - Eliminar',
            permiso: 'EliminarConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento - Imprimir',
            permiso: 'ImprimirConsentimiento',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento - Adjuntar Documento',
            permiso: 'AdjuntarAConsentimiento',
            ver: false,
            editar: false
          },

          //Consentimiento Editable

          {
            text: 'AV. Consentimiento Editable - Guardar',
            permiso: 'GuardarConsentimientoEditable',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento Editable - Editar',
            permiso: 'EditarConsentimientoEditable',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento Editable - Eliminar',
            permiso: 'EliminarConsentimientoEditable',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento Editable - Imprimir',
            permiso: 'ImprimirConsentimientoEditable',
            ver: false,
            editar: false
          },
          {
            text: 'AV. Consentimiento Editable - Adjuntar Documento',
            permiso: 'AdjuntarConsentimientoEditable',
            ver: false,
            editar: false
          },
          //referencias otras areas

          {
            text: 'Victimología - Crea Referencias',
            permiso: 'VMCrearReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'Victimología - Editar Referencias',
            permiso: 'VMEditarReferencias',
            ver: false,
            editar: false
          },
          {
            text: 'Victimología - Imprimir Referencia',
            permiso: 'VMImprimirReferencia',
            ver: false,
            editar: false
          },
          {
            text: 'Victimología - Inactivar Referencia',
            permiso: 'VMInactivarReferencia',
            ver: false,
            editar: false
          },
        ]
      },

      {
        text: 'Asignación de expedientes por área',
        permiso: 'AsignacionDeExpedientesPorArea',
        ver: false,
        opciones: [
          {
            text: 'Asignar',
            permiso: 'AsignarExpediente',
            ver: false,
            editar: false,
          }
        ]
      },

      {
        text: 'Expedientes por Área',
        permiso: 'ExpedientesArea',
        ver: false,
        opciones: [
          {
            text: 'Reasignar Expediente',
            permiso: 'ReasignarExpediente',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar a Excel',
            permiso: 'ExportarExpedientes',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar Expediente',
            permiso: 'EditarExpediente',
            ver: false,
            editar: false,
          },
          {
            text: 'Reimprimir Asignación',
            permiso: 'ImprimirAsignacion',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Creación Casos Históricos',
        permiso: 'creacionhistoricos',
        ver: false,
        opciones: [
          {
            text: 'Crear caso histórico',
            permiso: 'crearhistorico',
            ver: false,
            editar: false,
          },
          {
            text: 'Crear Víctima',
            permiso: 'crearpersona',
            ver: false,
            editar: false,
          },
        ]
      },

      {
        text: 'Históricos',
        permiso: 'historicos',
        ver: false,
        opciones: [
          {
            text: 'Exportar Información',
            permiso: 'exportarinformacion',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar Resumen',
            permiso: 'exportarresumen',
            ver: false,
            editar: false,
          },
          {
            text: 'Eliminar Registro',
            permiso: 'eliminarregistro',
            ver: false,
            editar: false,
          },
          {
            text: 'Editar Registro',
            permiso: 'editarregistro',
            ver: false,
            editar: false,
          },
          {
            text: 'Inactivar Registro',
            permiso: 'inactivarregistro',
            ver: false,
            editar: false,
          },
        ]
      },
      {
        text: 'Referencias a trabajo social',
        permiso: 'Referenciasatrabajosocial',
        ver: false,
        opciones: [
          {
            text: 'Convertir Referencias',
            permiso: 'convertirreferencias',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Asignación de referencias',
        permiso: 'Asignacióndereferencias',
        ver: false,
        opciones: [
          {
            text: 'Asignar Referencias',
            permiso: 'asignarreferencias',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Referencias',
        permiso: 'Referencias',
        ver: false,
        opciones: [
          {
            text: 'Exportar Referencias',
            permiso: 'exportarreferencias',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar Resumen',
            permiso: 'exportarresumen',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Notificaciones Legales',
        permiso: 'NotificacionesLegales',
        ver: false
      },
      {
        text: 'Acciones victimológicas',
        permiso: 'AccionesVictimol0gicas',
        ver: false,
        opciones: [
          {
            text: 'Exportar Acciones',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Acciones Vencidas',
            permiso: 'VerAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Acciones Vencidas',
            permiso: 'GestionarAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Acciones Planificadas',
            permiso: 'VerAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Acciones Planificadas',
            permiso: 'GestionarAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Acciones Completadas',
            permiso: 'VerAccionesCompletadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Acciones por Autorizar',
            permiso: 'VerAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Acciones por Autorizar',
            permiso: 'GestionarAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Acciones por Asignar',
            permiso: 'VerAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Acciones por Asignar',
            permiso: 'GestionarAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Inactivar Acciones',
            permiso: 'Inactivaracciones',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Seguimientos legales',
        permiso: 'Seguimientoslegales',
        ver: false,
        opciones: [
          {
            text: 'Exportar Seguimientos',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Seguimientos Vencidos',
            permiso: 'VerAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Seguimientos Vencidos',
            permiso: 'GestionarAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Seguimientos Planificados',
            permiso: 'VerAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Seguimientos Planificados',
            permiso: 'GestionarAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Seguimientos Completados',
            permiso: 'VerAccionesCompletadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Seguimientos por Autorizar',
            permiso: 'VerAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Seguimientos por Autorizar',
            permiso: 'GestionarAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Seguimientos por Asignar',
            permiso: 'VerAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Seguimientos por Asignar',
            permiso: 'GestionarAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Inactivar Seguimientos',
            permiso: 'Inactivaracciones',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Audiencias Programadas',
        permiso: 'audienciasprogramadas',
        ver: false,
        opciones: [
          {
            text: 'Exportar Audiencias Programadas',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Audiencias Programadas',
            permiso: 'gestionaraudiencias',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Procuraciones programadas',
        permiso: 'Procuracionesprogramadas',
        ver: false,
        opciones: [
          {
            text: 'Exportar Procuraciones ',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Procuraciones ',
            permiso: 'Gestionar',
            ver: false,
            editar: false,
          },
        ]
      },
      {
        text: 'Audiencias',
        permiso: 'audiencias',
        ver: false,
        opciones: [
          {
            text: 'Exportar Audiencias ',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Exportar Resumen de Audiencias ',
            permiso: 'Resumen',
            ver: false,
            editar: false,
          },
        ]
      },
      {
        text: 'Gestionar cierre expedientes',
        permiso: 'GestionarCierreExpedientes',
        ver: false,
        opciones: [
          {
            text: 'Gestionar Cierre',
            permiso: 'GestionarCierre',
            ver: false,
            editar: false,
          },
          {
            text: 'Autorizar Cierre',
            permiso: 'AutorizarCierre',
            ver: false,
            editar: false,
          },
          {
            text: 'Rechazar Cierre',
            permiso: 'RechazarCierre',
            ver: false,
            editar: false,
          },

        ]
      },
      {
        text: 'Reservas de espacios físicos',
        permiso: 'ReservasDeEspaciosFisicos',
        ver: false,
        opciones: [
          {
            text: 'Agendar Acciones',
            permiso: 'AgendarAcciones',
            ver: false,
            editar: false,
          },
          {
            text: 'Agendar Seguimientos',
            permiso: 'AgendarSeguimientos',
            ver: false,
            editar: false,
          },
          {
            text: 'Cancelar Reservación',
            permiso: 'CancelarReservacion',
            ver: false,
            editar: false,
          }
        ]
      },
      {
        text: 'Biblioteca',
        permiso: 'Biblioteca',
        ver: false
      },
      {
        text: 'Profesionales',
        permiso: 'Profesionales',
        ver: false,
        opciones: []
      },
      {
        text: 'Espacio seguro',
        permiso: 'EspacioSeguro',
        ver: false,
        opciones: []
      },
      {
        text: 'Atenciones complementarias',
        permiso: 'AtencionesComplementarias',
        ver: false,
        opciones: [
          {
            text: 'Exportar Atenciones Complementarias',
            permiso: 'Exportar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Atenciones Complementarias Vencidas',
            permiso: 'VerAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Atenciones Complementarias Vencidas',
            permiso: 'GestionarAccionesVencidas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Atenciones Complementarias Planificadas',
            permiso: 'VerAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Atenciones Complementarias Planificadas',
            permiso: 'GestionarAccionesPlanificadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Atenciones Complementarias Completadas',
            permiso: 'VerAccionesCompletadas',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Atenciones Complementarias por Autorizar',
            permiso: 'VerAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Atenciones Complementarias por Autorizar',
            permiso: 'GestionarAccionesporAutorizar',
            ver: false,
            editar: false,
          },
          {
            text: 'Ver Atenciones Complementarias por Asignar',
            permiso: 'VerAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Gestionar Atenciones Complementarias por Asignar',
            permiso: 'GestionarAccionesporAsignar',
            ver: false,
            editar: false,
          },
          {
            text: 'Inactivar Atenciones Complementarias',
            permiso: 'Inactivaracciones',
            ver: false,
            editar: false,
          }
        ]
      }

    ]
  },

  asistencialegal: {
    text: 'ASISTENCIA LEGAL',
    menus: [
      {
        text: 'Asignacion de expedientes por área',
        permiso: 'AsignacionExpedientesArea',
        ver: false
      },
      {
        text: 'Reasignación de expedientes por área',
        permiso: 'ReasignacionExpedientesArea',
        ver: false
      },
      {
        text: 'AL Notificaciones Legales',
        permiso: 'ALNotificacionesLegales',
        ver: false
      },
      {
        text: 'AL Seguimientos legales',
        permiso: 'ALSeguimientosLegales',
        ver: false
      },
      {
        text: 'AL Audiencias programadas',
        permiso: 'ALAudienciasProgramadas',
        ver: false
      },
      {
        text: 'AL Audiencias',
        permiso: 'ALAudiencias',
        ver: false
      },
      {
        text: 'AL Gestionar cierre expedientes',
        permiso: 'ALGestionarCierreExpedientes',
        ver: false
      },
      {
        text: 'AL Procuraciones programadas',
        permiso: 'ALProcuracionesProgramadas',
        ver: false
      },
      {
        text: 'AL Biblioteca',
        permiso: 'ALBiblioteca',
        ver: false
      },
      {
        text: 'AL Profesionales',
        permiso: 'ALProfesionales',
        ver: false
      }
    ]
  },

  atencionvictimologica: {
    text: 'ATENCION VICTIMOLOGICA',
    menus: [
      {
        text: 'AV Asignacion de expedientes por area',
        permiso: 'AVAsignacionExpedientesArea',
        ver: false
      },
      {
        text: 'AV Reasignacion Expedientes por Area',
        permiso: 'AVReasignacionExpedientesArea',
        ver: false
      },
      {
        text: 'AV Referencias a trabajo social',
        permiso: 'AVReferenciasTrabajoSocial',
        ver: false
      },
      {
        text: 'AV Asignación de referencias',
        permiso: 'AsignacionReferencias',
        ver: false
      },
      {
        text: 'AV Referencias',
        permiso: 'AVReferencias',
        ver: false
      },
      {
        text: 'AV Acciones Victimologicas',
        permiso: 'AVAccionesVictimologicas',
        ver: false
      },
      {
        text: 'AV Gestionar cierre expedientes',
        permiso: 'AVGestionarCierreExpedientes',
        ver: false
      },
      {
        text: 'AV Biblioteca',
        permiso: 'AVBiblioteca',
        ver: false
      },
      {
        text: 'AV Profesionales',
        permiso: 'AVProfesionales',
        ver: false
      }
    ]
  },

  misregistros: {
    text: 'MIS REGISTROS',
    menus: [
      {
        text: 'Mis expedientes',
        permiso: 'MisExpedientes',
        ver: false
      },
      {
        text: 'Notificaciones Legales',
        permiso: 'MisNotificacionesLegales',
        ver: false
      },
      {
        text: 'Mis Referencias',
        permiso: 'MisReferencias',
        ver: false
      },
      {
        text: 'Seguimientos Legales',
        permiso: 'MisSeguimientosLegales',
        ver: false,
        opciones: []
      },
      {
        text: 'Acciones Victimológicas',
        permiso: 'MisAccionesVictimológicas',
        ver: false,
        opciones: []
      },
      {
        text: 'Procuraciones programadas',
        permiso: 'MisProcuracionesProgramadas',
        ver: false
      },
      {
        text: 'Audiencias',
        permiso: 'MisAudiencias',
        ver: false
      },
      {
        text: 'Audiencias programadas',
        permiso: 'MisAudienciasProgramadas',
        ver: false
      },
      {
        text: 'Expedientes históricos',
        permiso: 'MisExpedientesHistoricos',
        ver: false
      },
      {
        text: 'Atenciones complementarias',
        permiso: 'MisAtencionesComplementarias',
        ver: false,
        opciones: []
      }
    ]
  }
};

export const FormDetalle = (props) => {
  const [data, setData] = useState(props.detalle.data !== null ? () => {
    let dataConvertida = props.detalle.data;
    estructura.registros.menus.forEach(mn => {
      let find = true;
      dataConvertida.registros.menus.forEach((dt, index) => {
        if (dt.text === mn.text) {
          find = false;
          let menuTmp = {
            ...mn,
            ...dt,
          };
          if (mn.opciones) {
            mn.opciones.forEach(mnOp => {
              let findOp = true;
              dt.opciones.forEach((dtOp, index) => {
                if (mnOp.text === dtOp.text) {
                  findOp = false;
                  dt.opciones[index] = {
                    ...mnOp,
                    ...dtOp
                  }
                }
              })

              if (findOp) {
                dt.opciones.push(mnOp);
              }
            });

            menuTmp = {
              ...mn,
              ...dt,
            }
          }

          dataConvertida.registros.menus[index] = menuTmp;
        }
      })

      if (find) {
        dataConvertida.registros.menus.push(mn);
      }
    });

    estructura.seguridad.menus.forEach(mn => {
      dataConvertida.seguridad.menus.forEach((dt, index) => {
        if (dt.text === mn.text) {
          dataConvertida.seguridad.menus[index] = {
            ...mn,
            ...dt,
          }
        }
      })
    });

    if (dataConvertida?.asistencialegal) {
      estructura.asistencialegal.menus.forEach(mn => {
        dataConvertida.asistencialegal.menus.forEach((dt, index) => {
          if (dt.text === mn.text) {
            dataConvertida.asistencialegal.menus[index] = {
              ...mn,
              ...dt,
            }
          }
        })
      });
    } else {
      dataConvertida = { ...dataConvertida, asistencialegal: estructura.asistencialegal }
    }

    if (dataConvertida?.atencionvictimologica) {
      estructura.atencionvictimologica.menus.forEach(mn => {
        dataConvertida.atencionvictimologica.menus.forEach((dt, index) => {
          if (dt.text === mn.text) {
            dataConvertida.atencionvictimologica.menus[index] = {
              ...mn,
              ...dt,
            }
          }
        })
      });
    } else {
      dataConvertida = { ...dataConvertida, atencionvictimologica: estructura.atencionvictimologica }
    }

    if (dataConvertida?.menus) {
      estructura.menus.menus.forEach(mn => {
        dataConvertida.menus.menus.forEach((dt, index) => {
          if (dt.text === mn.text) {
            dataConvertida.menus.menus[index] = {
              ...mn,
              ...dt,
            }
          }
        })
      });
    } else {
      dataConvertida = { ...dataConvertida, menus: estructura.menus }
    }

    if (dataConvertida?.reportes) {
      estructura.reportes.menus.forEach(mn => {
        dataConvertida.reportes.menus.forEach((dt, index) => {
          if (dt.text === mn.text) {
            dataConvertida.reportes.menus[index] = {
              ...mn,
              ...dt,
            }
          }
        })
      });
    } else {
      dataConvertida = { ...dataConvertida, reportes: estructura.reportes }
    }


    if (dataConvertida?.misregistros) {
      estructura.misregistros.menus.forEach(mn => {
        dataConvertida.misregistros.menus.forEach((dt, index) => {
          if (dt.text === mn.text) {
            dataConvertida.misregistros.menus[index] = {
              ...mn,
              ...dt,
            }
          }
        })
      });
    } else {
      dataConvertida = { ...dataConvertida, misregistros: estructura.misregistros }
    }

    return dataConvertida;
  } : estructura)

  const [opcionesRegistro, setOpcionesRegistro] = useState([])
  const [opcionesRegistroShow, setOpcionesRegistroShow] = useState(false)

  const [opcionesAtencionVictimologica, setOpcionesAtencionVictimologica] = useState([])
  const [opcionesAtencionVictimologicaShow, setOpcionesAtencionVictimologicaShow] = useState(false)

  const [opcionesAsistenciaLegal, setOpcionesAsistenciaLegal] = useState([])
  const [opcionesAsistenciaLegalShow, setOpcionesAsistenciaLegalShow] = useState(false)

  const [opcionesMisRegistros, setOpcionesMisRegistros] = useState([])
  const [opcionesMisRegistrosShow, setOpcionesMisRegistrosShow] = useState(false)

  const saveMenus = () => {
    postPermisos(props.id, data).then(data => {
      console.log(data);
      props.cancelar(false);
      toast.success(props.detalle.data !== null ? 'Registro actualizado con éxito...!' : 'Registro creado con éxito...!')
    })
  }

  const changeMenu = (value, menu, index, opcion) => {
    let dataTemp = data;
    if (menu === 'registros') {
      if (opcion === 'ver') {
        dataTemp.registros.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.registros.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.registros.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }
    else if (menu === 'seguridad') {
      if (opcion === 'ver') {
        dataTemp.seguridad.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.seguridad.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.seguridad.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }
    else if (menu === 'asistencialegal') {
      if (opcion === 'ver') {
        dataTemp.asistencialegal.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.asistencialegal.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.asistencialegal.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }
    else if (menu === 'atencionvictimologica') {
      if (opcion === 'ver') {
        dataTemp.atencionvictimologica.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.atencionvictimologica.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.atencionvictimologica.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }

    else if (menu === 'reportes') {
      if (opcion === 'ver') {
        dataTemp.reportes.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.reportes.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.reportes.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }

    else if (menu === 'misregistros') {
      if (opcion === 'ver') {
        dataTemp.misregistros.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.misregistros.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.misregistros.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }

    else if (menu === 'menus') {
      if (opcion === 'ver') {
        dataTemp.menus.menus[index].ver = value;
      } else if (opcion === 'editar') {
        dataTemp.menus.menus[index].editar = value;
      } else if (opcion === 'eliminar') {
        dataTemp.menus.menus[index].eliminar = value;
      }
      setData(dataTemp);
    }
  }

  const editRegistroOp = (mn, index) => {
    setOpcionesRegistro({
      menu: mn,
      index: index,
      opciones: mn.opciones
    })
    setOpcionesRegistroShow(true);
  }

  const changeMenuOpRegistro = (checked, index) => {
    let tmp = opcionesRegistro;
    tmp.opciones[index].ver = checked;
    tmp.menu.opciones = tmp.opciones;
    setOpcionesRegistro(tmp);

    let dataTemp = data;
    data.registros.menus[tmp.index] = tmp.menu;
    setData(dataTemp);
  }

  const editAtencionVOp = (mn, index) => {
    setOpcionesAtencionVictimologica({
      menu: mn,
      index: index,
      opciones: mn.opciones
    })
    setOpcionesAtencionVictimologicaShow(true);
  }

  const changeMenuOpAtencionV = (checked, index) => {
    let tmp = opcionesAtencionVictimologica;
    tmp.opciones[index].ver = checked;
    tmp.menu.opciones = tmp.opciones;
    setOpcionesAtencionVictimologica(tmp);

    let dataTemp = data;
    data.atencionvictimologica.menus[tmp.index] = tmp.menu;
    setData(dataTemp);
  }

  const editAsistenciaLOp = (mn, index) => {
    setOpcionesAsistenciaLegal({
      menu: mn,
      index: index,
      opciones: mn.opciones
    })
    setOpcionesAsistenciaLegalShow(true);
  }

  const changeMenuOpAsistenciaL = (checked, index) => {
    let tmp = opcionesAsistenciaLegal;
    tmp.opciones[index].ver = checked;
    tmp.menu.opciones = tmp.opciones;
    setOpcionesAsistenciaLegal(tmp);

    let dataTemp = data;
    data.asistencialegal.menus[tmp.index] = tmp.menu;
    setData(dataTemp);
  }

  const editMisRegistrosOp = (mn, index) => {
    setOpcionesMisRegistros({
      menu: mn,
      index: index,
      opciones: mn.opciones
    })
    setOpcionesMisRegistrosShow(true);
  }

  const changeMenuOpMisRegistros = (checked, index) => {
    let tmp = opcionesMisRegistros;
    tmp.opciones[index].ver = checked;
    tmp.menu.opciones = tmp.opciones;
    setOpcionesMisRegistros(tmp);

    let dataTemp = data;
    data.misregistros.menus[tmp.index] = tmp.menu;
    setData(dataTemp);
  }

  return (
    <div className=" mt-4">
      {data?.registros?.menus &&
        <div className=" border-gray-200 p-0" >

          {!opcionesRegistroShow &&
            <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
              <p className="text-sm font-bold text-maivi-secondaryLightBlue">{data?.registros?.text}</p>
              <Divider />
              <ul className="divide-y divide-gray-200 -mt-4">
                {data?.registros?.menus && data.registros.menus.map((mn, index) =>
                  <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                    <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "480px" }}>{mn.text}</p>
                    <div className="flex items-center mx-6">
                      <input
                        type="checkbox"
                        defaultChecked={mn.ver}
                        onChange={(e) => changeMenu(e.target.checked, 'registros', index, 'ver')}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                      />
                      <label className="text-sm font-medium text-gray-500">
                        Ver
                      </label>
                    </div>
                    <div className="flex items-center mx-6 ml-3">
                      <input
                        type="checkbox"
                        onChange={(e) => changeMenu(e.target.checked, 'registros', index, 'editar')}
                        defaultChecked={mn.editar}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                      />
                      <label className="text-sm font-medium text-gray-500">
                        Editar
                      </label>
                    </div>
                    <div className="flex items-center mx-6 ml-3">
                      <input
                        type="checkbox"
                        onChange={(e) => changeMenu(e.target.checked, 'registros', index, 'eliminar')}
                        defaultChecked={mn.eliminar}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                      />
                      <label className="text-sm font-medium text-gray-500">
                        Eliminar
                      </label>
                    </div>
                    <div className="flex items-center mx-6 ml-3" width="50">
                      {mn?.opciones &&
                        <PencilAltIcon onClick={() => editRegistroOp(mn, index)} className="h-6 w-6 text-maivi-primaryLightBlue" />
                      }
                    </div>
                  </li>
                )}
              </ul>
            </div>
          }

          {opcionesRegistroShow &&
            <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
              <ArrowLeftIcon style={{ width: '25px', cursor: 'pointer' }} onClick={() => { setOpcionesRegistroShow(false) }} />
              <p className="text-sm font-bold text-maivi-secondaryLightBlue">{opcionesRegistro?.menu?.text}</p>
              <Divider />
              <ul className="divide-y divide-gray-200 -mt-4">
                {opcionesRegistro?.opciones && opcionesRegistro?.opciones.map((mn, index) =>
                  <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                    <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "480px" }}>{mn.text}</p>
                    <div className="flex items-center mx-6">
                      <input
                        type="checkbox"
                        defaultChecked={mn.ver}
                        onChange={(e) => changeMenuOpRegistro(e.target.checked, index)}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                      />
                      <label className="text-sm font-medium text-gray-500">
                        Ver
                      </label>
                    </div>
                    <div className="flex items-center mx-6 ml-3">
                      <input
                        type="checkbox"
                        onChange={(e) => changeMenuOpRegistro(e.target.checked, 'seguridad', index, 'editar')}
                        defaultChecked={mn.editar}
                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                      />
                      <label className="text-sm font-medium text-gray-500">
                        Editar
                      </label>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          }

          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-bold text-maivi-secondaryLightBlue mt-1">{data?.seguridad?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.seguridad?.menus && data.seguridad.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'seguridad', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'seguridad', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'seguridad', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-bold text-maivi-secondaryLightBlue mt-2">{data?.asistencialegal?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.asistencialegal?.menus && data.asistencialegal.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'asistencialegal', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'asistencialegal', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'asistencialegal', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-bold text-maivi-secondaryLightBlue 2">{data?.atencionvictimologica?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.atencionvictimologica?.menus && data.atencionvictimologica.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'atencionvictimologica', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'atencionvictimologica', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'atencionvictimologica', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>


          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-bold text-maivi-secondaryLightBlue mt-2">{data?.reportes?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.reportes?.menus && data.reportes.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'reportes', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'reportes', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'reportes', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>




          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-medium text-maivi-primaryFont mt-2">{data?.misregistros?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.misregistros?.menus && data.misregistros.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'misregistros', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'misregistros', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'misregistros', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="mx-3 hover:bg-green-50 p-3 rounded-md border mb-3 hover:border-black">
            <p className="text-sm font-medium text-maivi-primaryFont mt-2">{data?.menus?.text}</p>
            <Divider />
            <ul className="divide-y -mt-4">
              {data?.menus?.menus && data.menus.menus.map((mn, index) =>
                <li key={`${index}-mn`} className="py-2 flex hover:bg-gray-100 cursor-pointer px-2">
                  <p className="text-sm font-medium text-maivi-primaryFont" style={{ width: "380px" }}>{mn.text}</p>
                  <div className="flex items-center mx-6">
                    <input
                      type="checkbox"
                      defaultChecked={mn.ver}
                      onChange={(e) => changeMenu(e.target.checked, 'menus', index, 'ver')}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Ver
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'menus', index, 'editar')}
                      defaultChecked={mn.editar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Editar
                    </label>
                  </div>
                  <div className="flex items-center mx-6 ml-3">
                    <input
                      type="checkbox"
                      onChange={(e) => changeMenu(e.target.checked, 'menus', index, 'eliminar')}
                      defaultChecked={mn.eliminar}
                      className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2 cursor-pointer"
                    />
                    <label className="text-sm font-medium text-gray-500">
                      Eliminar
                    </label>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      }
      <Divider />

      <ButtonAdd
        text={`${(props.detalle.data !== null) ? "Actualizar" : "Crear"}`}
        type="button"
        onClick={() => {
          saveMenus(false);
        }}
      />
    </div>
  );
};