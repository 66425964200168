import Label from "./Label";
import { ErrorMessage } from "formik";

export const SelectSiavi = (props) => {
  return (
    <div>
      <Label text={props.label} />
      <select
        autoFocus={props.autoFocus || false}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        disabled={props.disabled || false}
        onBlur={props?.onBlur}
        className={`mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
        text-xs border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont ${props.className}`}
      >
      <option value={props.valueInit}>{props.selectInit}</option>  
      {props.list}
      </select>
      
      {props.validacion && (
        <ErrorMessage
          name={props.name}
          component="span"
          className="text-xs text-maivi-secondaryRed"
        />
      )}
    </div>
  );
};
