/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TrashIcon, PaperClipIcon, EyeIcon } from "@heroicons/react/solid";
import { ClipboardListIcon, PrinterIcon } from "@heroicons/react/outline";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { SelectSiavi } from "../../../../../components/common/Select";
import { getExpedienteSindicadoDelitos, getPersonasCasoDetails } from "../../../orientacion/editar/services";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import {
    getFormularios,
    getModalidades,
    getFormularioCasoPersona,
    postFormularioCasoPersona,
    putFormularioCasoPersona,
    deleteFormularioCasoPersona,
    getCasoPersonaRespuestas,
    postInstrumentos,
    getPreguntas,
    getInstituciones
} from "../services";
import FormularioGenerico from "../../../../../components/common/FormularioGenerico";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Spinner from "../../../../../components/common/Spinner";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import PdfViewer from "../../../../../components/common/PdfViewer";
import * as Yup from "yup";
import { clases } from "../../../../../helpers/clases";

const validaciones = Yup.object().shape({
    formulario: Yup.string().required("Instrumento es requerido")
});

export const AgregarFormCasoPersona = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuarioLogin = useSelector(
        (state) => state.loginReducer.data.usuario.user.username
    );
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const expediente = props.idExpediente;
    const orientacion = props.idOrientacion;
    const expedienteFull = props.expedienteFull;
    const [refetch, setRefetch] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [loading, setLoading] = useState(false);
    const [instrumentos, setInstrumentos] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [casoPersonaFormularios, setFormularios] = useState([]);
    const [formulario, setFormulario] = useState({})
    const [editar, setEditar] = useState(false);
    const [verdetalle, setVerdetalle] = useState(true);
    const [borrar, setBorrar] = useState(false);
    const [id, setId] = useState(0);
    const [archivos, setArchivos] = useState(false);
    const [listadoPreguntas, setListadoPreguntas] = useState([]);
    const [editarFormulario, setEditarFormulario] = useState(false);
    const [imprimirData, setImprimirData] = useState({});
    const [instituciones, setInstituciones] = useState([]);
    const [confirmar, setConfirmar] = useState(false);
    const [esEditable, setEsEditable] = useState(true);
    const [registroFormulario, setRegistroFormulario] = useState(false);


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAV";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });

        //        retorno = false;  // mm comentar para que funcione
        //               retorno = true;  // mm comentar para que funcione
        return retorno;

    }



    useEffect(() => {
        getFormularios(props?.servicio, props?.directores, token).then((data) => {
            setInstrumentos(data);
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data);
        });
    }, [token]);

    useEffect(() => {
        getInstituciones(token).then((data) => {
            setInstituciones(data);
        });
    }, [token]);

    useEffect(() => {
        getFormularioCasoPersona(props.idCasoPersona, props.idExpediente, token).then((data) => {
        //getFormularioCasoPersona(props.idCasoPersona, token).then((data) => {
            setFormularios(data);
            
        });
    }, [token, refetch]);

    useEffect(async () => {
        getPreguntasFill(formulario);
    }, [token, refetch, formulario]);

    const getPreguntasFill = async (formulario) => {
        if (formulario.id !== undefined) {
            setLoading(true);
            let respuestas = [];
            let listado = [];

            await getCasoPersonaRespuestas(formulario?.id, token).then((data) => {
                respuestas = data?.results;
            });

            await getPreguntas(formulario?.formulario?.id, token).then((data) => {
                data?.results?.forEach((item) => {
                    if (respuestas.length > 0) {
                        setEditarFormulario(true);
                        respuestas.forEach(element => {
                            if (element?.pregunta?.id === item?.id) {
                                listado.push({
                                    agrupacion: item?.agrupacion,
                                    catalogo_seleccion: item?.catalogo_seleccion,
                                    hint: item?.hint,
                                    obligatorio: item?.obligatorio,
                                    ordenamiento: item?.ordenamiento,
                                    pregunta: item?.pregunta,
                                    id_pregunta: item?.id,
                                    tipo_pregunta: item?.tipo_pregunta,
                                    catalogo_seleccion_id: item?.catalogo_seleccion?.id || null,
                                    valor_seleccion: element?.valor_seleccion,
                                    respuesta_corta: element?.respuesta_corta,
                                    texto_extenso: element?.texto_extenso,
                                    formulario_caso_persona: formulario.id,
                                    editada: true,
                                    id: element?.id
                                });
                            }
                        });
                    } else {
                        setEditarFormulario(false);
                        listado.push({
                            agrupacion: item?.agrupacion,
                            catalogo_seleccion: item?.catalogo_seleccion,
                            hint: item?.hint,
                            obligatorio: item?.obligatorio,
                            ordenamiento: item?.ordenamiento,
                            pregunta: item?.pregunta,
                            id_pregunta: item?.id,
                            tipo_pregunta: item?.tipo_pregunta,
                            catalogo_seleccion_id: item?.catalogo_seleccion?.id || null,
                            valor_seleccion: null,
                            respuesta_corta: null,
                            texto_extenso: null,
                            formulario_caso_persona: formulario.id,
                            editada: false,
                            id: null
                        });
                    }
                });
                setLoading(false);
                setListadoPreguntas([]);
                setListadoPreguntas(listado);
            });

            return listado;
        }
    }

    return (
        <Formik
            initialValues={{
                nombre_solicita: "",
                fecha_solicitud: "",
                institucion_solicita: "",
                formulario: "",
                atencion: "",
                fecha_formulario: "",
                observaciones: "",
                modalidad: "",
                editable: true,
                sede: sedeId,
                expediente: expediente,
                orientacion: orientacion,
                caso_persona: props.idCasoPersona
            }}
            validationSchema={validaciones}
            onSubmit={async (values, { resetForm }) => {
                if (editar) {
                    await putFormularioCasoPersona(formulario.id, values, props.idCasoPersona, orientacion, expediente, sedeId, token).then((data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            setCargando(false);
                            toast.success("Registro actualizado con éxito...!");
                            setRefetch(true);
                            setRefetch(false);
                            setEditar(false);
                            resetForm({});
                        }
                    })
                        .catch(function (error) {
                            if (
                                error.response.status >= 400 &&
                                error.response.status <= 410
                            ) {
                                setCargando(false);
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(
                                    "Error interno comuniquese con el administrador"
                                );
                            }
                        });
                } else {
                    setCargando(true);
                    await postFormularioCasoPersona(values, props.idCasoPersona, orientacion, expediente, sedeId, token).then((data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            setCargando(false);
                            toast.success("Registro creado con éxito...!");
                            setRefetch(true);
                            setRefetch(false);
                            resetForm({});
                        }
                    })
                        .catch(function (error) {
                            if (
                                error.response.status >= 400 &&
                                error.response.status <= 410
                            ) {
                                setCargando(false);
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error(
                                    "Error interno comuniquese con el administrador"
                                );
                            }
                        });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit} className="">
                    <div className="grid grid-cols-12 gap-2 mb-2 mt-2">
                        <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 border p-3 hover:shadow-md rounded-md">
                            <div className="grid grid-cols-12 gap-3 items-end mb-2">
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <SelectSiavi
                                        label="Instrumento"
                                        validacion={true}
                                        name="formulario"
                                        value={values.formulario}
                                        onChange={(e) => {
                                            setFieldValue('formulario', e.target.value);
                                            instrumentos?.results?.forEach(element => {
                                                if (element.id === Number(e.target.value)) {
                                                    setRegistroFormulario(element.informacion_solicitante);
                                                }
                                            });

                                            if (e.target.value === null) {
                                                setRegistroFormulario(false);
                                            }
                                        }}
                                        valueInit={null}
                                        selectInit="Elegir instrumento"
                                        list={
                                            instrumentos?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre + item.id} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <InputSiavi
                                        label="Fecha Instrumento"
                                        type="date"
                                        name="fecha_formulario"
                                        value={values.fecha_formulario}
                                        onChange={handleChange}
                                    />
                                </div>
                                {
                                    registroFormulario &&
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <InputSiavi
                                            label="Nombre solicita"
                                            type="text"
                                            name="nombre_solicita"
                                            value={values.nombre_solicita}
                                            onChange={handleChange}
                                        />
                                    </div>
                                }
                                {
                                    registroFormulario &&
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <SelectSiavi
                                            label="Institucion solicita"
                                            validacion={true}
                                            name="institucion_solicita"
                                            value={values.institucion_solicita}
                                            onChange={handleChange}
                                            valueInit={null}
                                            selectInit="Elegir institucion"
                                            list={
                                                instituciones?.results?.map((item, index) => {
                                                    return (
                                                        <option key={item.nombre + index} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                    </div>
                                }
                                {
                                    registroFormulario &&
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <InputSiavi
                                            label="Fecha solicitud"
                                            type="date"
                                            name="fecha_solicitud"
                                            value={values.fecha_solicitud}
                                            onChange={handleChange}
                                        />
                                    </div>
                                }


                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <SelectSiavi
                                        label="Modalidad"
                                        validacion={true}
                                        valueInit=""
                                        selectInit="Elegir modalidad"
                                        name="modalidad"
                                        value={values.modalidad}
                                        onChange={handleChange}
                                        list={
                                            modalidades?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-span-12 ">
                                    <TextAreaSiavi
                                        label="Observaciones"
                                        name="observaciones"
                                        value={values.observaciones}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    {(CheckPermiso('GuardarInstrumento') === true) && (verdetalle) &&
                                        <ButtonSiavi
                                            type="button"
                                            text={(editar) ? 'Actualizar' : 'Guardar'}
                                            onClick={() => {
                                                if (esEditable) {
                                                    handleSubmit();
                                                } else {
                                                    toast.error("Este formulario ya no se puede actualizar por fue guardado como no editable");
                                                }
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            <TableSiavi
                                className="mt-2"
                                pagination={true}
                                headers={[
                                    "No.",
                                    "Instrumento",
                                    "Editable",
                                    "Fecha Formulario",
                                    "Acciones"

                                ]}
                                body={
                                    casoPersonaFormularios?.results?.map((item) => (
                                        <tr key={item?.formulario?.descripcion + item.id} className="hover:bg-maivi-gray-5 cursor-pointer">
                                            <td className={props.tdClass}>
                                                {item?.id
                                                }
                                            </td>
                                            <td className={props.tdClass}>
                                                {item?.formulario?.descripcion}
                                            </td>
                                            <td className={clases.td}>
                                                <span className={`${clases?.estado} ${(item.editable === true || item.editable === null) ? clases.estadoActivo : clases.estadoInactivo}`}>
                                                    {(item?.editable === true || item?.editable === null)
                                                        ? 'Si'
                                                        : 'No'}
                                                </span>
                                            </td>
                                            <td className={clases.td}>
                                                {(item?.fecha_formulario)}
                                            </td>                                                    
                                            <td className={props.tdClass}>
                                                {
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            values.nombre_solicita = "";
                                                            values.institucion_solicita = "";

                                                            setEditar(false);
                                                            setVerdetalle(false);
                                                            setFormulario(item);
                                                            setFieldValue("nombre_solicita", (item?.nombre_solicita === null) ? "" : item?.nombre_solicita);
                                                            setFieldValue("fecha_solicitud", (item?.fecha_solicitud === null) ? "" : item?.fecha_solicitud);
                                                            setFieldValue("institucion_solicita", (item?.institucion_solicita === null) ? "" : item?.institucion_solicita);
                                                            setFieldValue("formulario", item?.formulario?.id);
                                                            setFieldValue("atencion", item?.atencion?.id);
                                                            setFieldValue("fecha_formulario", item?.fecha_formulario);
                                                            setFieldValue("observaciones", item?.observaciones);
                                                            setFieldValue("modalidad", item?.modalidad);

                                                            if (item?.editable) {
                                                                setEsEditable(true);
                                                            } else {
                                                                setEsEditable(false);
                                                            }
                                                        }}
                                                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                                                    >
                                                        <span className="tooltiptext text-xs">Editar</span>
                                                        <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                                }
                                                {(CheckPermiso('EliminarInstrumento') === true) &&
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setBorrar(true);
                                                            setId(item.id);
                                                        }}
                                                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                                                    >
                                                        <span className="tooltiptext text-xs">Eliminar</span>
                                                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                    </button>
                                                }
                                                {(CheckPermiso('EditarInstrumento') === true) &&
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            values.nombre_solicita = "";
                                                            values.institucion_solicita = "";

                                                            setEditar(true);
                                                            setFormulario(item);
                                                            setVerdetalle(true);
                                                            setFieldValue("nombre_solicita", (item?.nombre_solicita === null) ? "" : item?.nombre_solicita);
                                                            setFieldValue("fecha_solicitud", (item?.fecha_solicitud === null) ? "" : item?.fecha_solicitud);
                                                            setFieldValue("institucion_solicita", (item?.institucion_solicita === null) ? "" : item?.institucion_solicita);
                                                            setFieldValue("formulario", item?.formulario?.id);
                                                            setFieldValue("atencion", item?.atencion?.id);
                                                            setFieldValue("fecha_formulario", item?.fecha_formulario);
                                                            setFieldValue("observaciones", item?.observaciones);
                                                            setFieldValue("modalidad", item?.modalidad);

                                                            if (item?.editable) {
                                                                setEsEditable(true);
                                                            } else {
                                                                setEsEditable(false);
                                                            }
                                                            setImprimirData({});

                                                        }}
                                                        className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip "
                                                    >
                                                        <span className="tooltiptext text-xs">Instrumento</span>
                                                        <ClipboardListIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                                }
                                                {(CheckPermiso('ImprimirInstrumento') === true) &&
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            setFormulario({});
                                                            let caso = expedienteFull?.caso_persona?.caso;
                                                           // toast.success(expedienteFull?.caso_persona?.caso?.id);
                                           
                                                            await getPreguntasFill(item).then(async preguntas => {
                                                                await getPersonasCasoDetails([item.caso_persona.persona.id], token).then(async personas => {
                                                                    await getExpedienteSindicadoDelitos(caso?.id, token).then(delitos => {

                                                                        let questions = preguntas.sort(function (a, b) {
                                                                            if (a.ordenamiento > b.ordenamiento) {
                                                                                return 1;
                                                                            }
                                                                            if (a.ordenamiento < b.ordenamiento) {
                                                                                return -1;
                                                                            }
                                                                            return 0;
                                                                        })
                                                                        setImprimirData({
                                                                            id: item.id,
                                                                            formulario: item,
                                                                            preguntas: questions,
                                                                            persona: personas.personas[0],
                                                                            titulo: item?.formulario?.nombre,
                                                                            sede: item?.caso_persona?.caso?.sede_creacion?.sede,
                                                                            fecha: item.formulario.fecha_creacion,
                                                                            servicio: item?.formulario?.servicio?.nombre,
                                                                            numero_caso: caso?.numero_caso,
                                                                            usuario: usuarioLogin,
                                                                            delitos: delitos.results
                                                                        });
                                                                    })
                                                                })
                                                            })
                                                        }}
                                                        className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip "
                                                    >
                                                        <span className="tooltiptext text-xs">Imprimir</span>
                                                        <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            />
                        </div>
                        {formulario?.id !== undefined &&
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6
                                border p-10 hover:shadow-md rounded-md bg-blue-50">
                                <h1 className="text-center text-maivi-primaryFont mb-2">{formulario?.formulario?.descripcion}</h1>
                                <FormularioGenerico
                                    listadoPreguntas={listadoPreguntas}
                                    setListadoPreguntas={setListadoPreguntas}
                                />
                                {
                                    (formulario.id !== undefined) &&
                                    (CheckPermiso('GuardarDetalleInstrumento') === true) && (verdetalle) &&
                                    <ButtonSiavi
                                        type="button"
                                        text={'Guardar'}
                                        onClick={async () => {
                                            if (esEditable) {
                                                setConfirmar(true);
                                            } else {
                                                toast.error("Este formulario ya no se puede actualizar por fue guardado como no editable");
                                            }
                                        }}
                                    />
                                }
                                <div className="mt-3">
                                    {
                                        loading && <Spinner />
                                    }
                                </div>
                            </div>
                        }

                        {imprimirData?.id !== undefined &&
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6
                                border p-2 hover:shadow-md rounded-md bg-blue-50">
                                <PdfViewer
                                    data={imprimirData}
                                    reporte={"formulario-dinamico"}
                                    style={{ height: "70vh" }}
                                />
                            </div>
                        }
                    </div>
                    {
                        cargando && <Spinner />
                    }
                    {isSubmitting && <Spinner />}
                    {borrar && (
                        <ModalGenerico
                            title="Esta seguro que desea eliminar el registro?"
                            open={borrar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setBorrar(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    aceptar={async () => {
                                        setCargando(true);
                                        await deleteFormularioCasoPersona(id, token).then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                                setCargando(false);
                                                toast.success("Registro borrado con éxito...!");
                                                setRefetch(true);
                                                setRefetch(false);
                                            }
                                        })
                                            .catch(function (error) {
                                                if (
                                                    error.response.status === 400 ||
                                                    error.response.status === 401
                                                ) {
                                                    setCargando(false);
                                                    toast.error(error.response.data.message);
                                                } else if (error.response.status >= 500) {
                                                    toast.error(
                                                        "Error interno comuniquese con el administrador"
                                                    );
                                                }
                                            });
                                    }}
                                    cancelar={() => {
                                        setBorrar(false);
                                    }}
                                />
                            }
                        />
                    )}
                    {archivos && (
                        <ModalAmplio
                            title={`Adjuntar archivos`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setArchivos(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <PaperClipIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                            children={
                                <GestionArchivos
                                    idRecurso={id}
                                    recurso="FORMULARIOCASOPERSONA"
                                    claseDocumento="formulariocasopersona"
                                />
                            }
                        />
                    )}

                    {confirmar && (
                        <ModalGenerico
                            title={`desea modificar este informe posteriormente?`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setConfirmar(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    isloading={loading}
                                    aceptar={async () => {
                                        setLoading(true);

                                        await postInstrumentos(editarFormulario, formulario, listadoPreguntas, token).then((data) => {
                                            if (data.status >= 200 && data.status <= 210) {
                                                setLoading(false);
                                                setEditar(true);
                                                setConfirmar(false);
                                                getPreguntasFill(formulario);
                                                toast.success("Formulario guardado con éxito");
                                            }
                                        }).catch(function (error) {
                                            if (
                                                error?.response?.status >= 400 &&
                                                error?.response?.status <= 450
                                            ) {
                                                toast.error(error.response.data.message);
                                                setLoading(false);
                                            } else if (error?.response?.status >= 500) {
                                                setLoading(false);
                                                toast.error("Error interno comuniquese con el administrador");
                                            }
                                        });

                                    }}
                                    cancelar={async () => {
                                        setEsEditable(false);
                                        setLoading(true);
                                        values.editable = false;
                                        await postInstrumentos(editarFormulario, formulario, listadoPreguntas, token).then(async (data) => {
                                            if (data.status >= 200 && data.status <= 210) {
                                                await putFormularioCasoPersona(formulario.id, values, props.idCasoPersona, orientacion, expediente, sedeId, token).then((data) => {
                                                    if (data?.status >= 200 && data?.status <= 210) {
                                                        setCargando(false);
                                                        toast.success("Registro actualizado con éxito...!");
                                                    }
                                                });

                                                setLoading(false);
                                                toast.success("Formulario guardado con éxito");
                                                setConfirmar(false);
                                            }
                                        }).catch(function (error) {
                                            if (
                                                error?.response?.status >= 400 &&
                                                error?.response?.status <= 450
                                            ) {
                                                toast.error(error.response.data.message);
                                                setLoading(false);
                                            } else if (error?.response?.status >= 500) {
                                                setLoading(false);
                                                toast.error("Error interno comuniquese con el administrador");
                                            }
                                        });
                                    }}
                                />
                            }
                        />
                    )}
                </form>
            )}
        </Formik>
    );
};
