import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    getDiscapacidadVictima,
    deleteDiscapacidades,
    getDiscapacidadesPersona,
    postPersonaDisc
} from "../../orientacion/editar/victima/services";
import { SelectSiavi } from "../../../../components/common/Select";
import { clases } from "../../../../helpers/clases";
import { TrashIcon } from "@heroicons/react/outline";
import TableSiavi from "../../../../components/common/TableSiavi";
import ModalGenerico from "../../../../components/common/Modal";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";


export const Discapacidades = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [discapacidades,setDiscapacidades] = useState([]);
    const [discapacidadesPersona,setDiscapacidadesPersona] = useState([]);
    const [eliminar,setEliminar] = useState(false);
    const [registro,setRegistro] = useState({});
    const [cargando,setCargando] = useState(false);
    const [refetch,setRefetch] = useState(false);

    useEffect(() => {
        getDiscapacidadVictima(token).then((data) => {
            setDiscapacidades(data)
        });
    }, [token]);

    useEffect(() => {
        setCargando(true);
        getDiscapacidadesPersona(props?.values?.idPersona, token).then((data) => {
            setCargando(false);
            setDiscapacidadesPersona(data)
        });
    }, [token,props?.values?.idPersona,eliminar,refetch]);

    return (
        <Formik
            initialValues={{
               discapacidad:""
            }}
            validationSchema={null}
            onSubmit={async (values) => {
                setCargando(true);
                await postPersonaDisc(props?.values?.idPersona, values.discapacidad, token)
                .then((data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                        toast.success("registro agregado con éxito")
                        setCargando(false);
                        setRefetch(true);
                        setRefetch(false);
                    }
                })
                .catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 450) {
                        setCargando(false);
                    } else if (error.response.status >= 500) {
                        setCargando(false);
                    }
                });
            }}
        >
            {({ values, handleChange, handleSubmit }) => (
                <div className="grid grid-cols-12 gap-3">
                    <div className="lg:col-span-9 xl:col-span-8 sm:col-span-6 md:col-span-6 col-span-12 ">
                        <SelectSiavi
                            name="discapacidad"
                            value={values.discapacidad}
                            valueInit=""
                            selectInit="Seleccionar discapacidad"
                            onChange={handleChange}
                            list={
                                discapacidades?.results?.map((item) => {
                                    return (
                                        <option key={item.nombre + "os"} value={item.id}>
                                            {item.nombre}
                                        </option>
                                    );
                                })
                            }
                        />
                    </div>
                    <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-4 mt-1">
                        <ButtonSiavi
                            type="button"
                            onClick={handleSubmit}
                            text="Agregar"
                        />
                    </div>
                    <div className="col-span-12">
                        <TableSiavi
                            cargando={cargando}
                            headers={[
                                'Discapacidad',
                                'Borrar'
                            ]}
                            body={
                                discapacidadesPersona?.results?.map((item,index) =>
                                <tr className={clases.tr} key={item?.discapacidad?.nombre + index}>
                                    <td className={clases.td}>{item?.discapacidad?.nombre}</td>
                                    <td>
                                        <button
                                            type="button"
                                            className={clases.trashIcon}
                                            onClick={async () => {
                                                setRegistro(item);
                                                setEliminar(true);
                                            }}
                                        >
                                            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                            <span className="tooltiptext text-xs">Eliminar</span>
                                        </button>
                                    </td>
                                </tr>
                                )
                            }
                        />
                    </div>
                    {eliminar && (
                        <ModalGenerico
                        title={`Esta seguro de eliminar el registro`}
                        open={true}
                        closeModal={() => {}}
                        children={
                           <BotonesConfirmar 
                            isloading={cargando}
                            aceptar={async()=>{
                                setCargando(true);
                                await deleteDiscapacidades(registro.id, token)
                                .then((data) => {
                                    if (data?.status >= 200 && data?.status <= 210) {
                                        toast.success("registro eliminado con éxito.");
                                        setEliminar(false);
                                    }
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400 || error.response.status === 401) {
                                        toast.error("No se pudo eliminar el registro");
                                        setCargando(false);
                                    } else if (error.response.status >= 500) {
                                        toast.error("Ocurrio un error inter no comuniquese con el administrador");
                                        setCargando(false);
                                    }
                                });
                            }}
                            cancelar={()=>{
                                setEliminar(false);
                            }}
                           />
                        }
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        />
                    )}
                </div>
            )}
        </Formik>
      
    );
};
