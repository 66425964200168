import { types } from "../types/types";

export const TipoFiltro = (value) => {
  return {
    type: types.TipoFiltro,
    payload: value,
  };
};

export const SelectTipoFiltro = () => {
  let tipo = {
      persona: true,
      caso: false
  };
  return tipo;
};
