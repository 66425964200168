import TableSiavi from "../../../../../components/common/TableSiavi";
import Alert from "../../../../../components/common/Alert";
import { useState, useEffect } from "react";
import { SelectSiavi } from "../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { getSindicadoDelitos } from "../services";
import {
  getReparacionDignaSindicadoDelito,
  reparacionDignaSindicadoDelito,
} from "./services";
import moment from "moment";
import toast from "react-hot-toast";
import { ButtonSiaviCancelar } from "../../../../../components/common/ButtonCancelar";

const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";

export const SindicadosRD = ({ caso, token, reparacionesDignas,showModal,registro }) => {
  const [dateFetch, setDate] = useState(moment());
  const [sindicados, setSindicados] = useState([]);
  const [idReparacionDigna, setIdReparacionDigna] = useState(null);
  const [sindicadosDelitos, setSindicadosDelitos] = useState([]);
  const [listadoSindicadosRD, setListadoSindicadosRD] = useState([]);
  const [cargandoButton, setCargandoButton] = useState(false);
  const [state, setState] = useState({
    showAlert: false,
    cargando: false,
    cargandoTable: false,
    mensaje: "",
  });

  useEffect(() => {
    getSindicadoDelitos(caso.id, token).then((data) => {
      setSindicadosDelitos(data.results);
    });
  }, []);

  useEffect(() => {
    if (idReparacionDigna !== null) {
      setState({ ...state, cargandoTable: true });
      getReparacionDignaSindicadoDelito(token, idReparacionDigna).then(
        (data) => {
          setListadoSindicadosRD(data.results);
          setState({ ...state, cargandoTable: false });
        }
      );
    }
  }, [dateFetch, idReparacionDigna]);

  const guardarSindicados = async () => {
    if (sindicados.length === 0) {
      setState({
        ...state,
        showAlert: true,
        mensaje: "Debe seleccionar al menos un sindicado",
      });
    } else if (idReparacionDigna === null) {
      setState({
        ...state,
        showAlert: true,
        mensaje: "Selecione una reparación digna",
      });
    } else {
      setCargandoButton(true);

      for (const item of sindicados) {
        let body = {
          reparacion_digna: idReparacionDigna,
          exp_sindicado_delito: item,
        };

        await reparacionDignaSindicadoDelito(body, token)
          .then(() => {
            toast.success('Sindicado agregado con exito.')
          })
          .catch(function (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }

      setCargandoButton(false);

      for(const item of sindicadosDelitos){
        let element = document.getElementById(item?.expediente_sindicado.sindicado.primer_nombre);
        element.checked = false;
      }

      setSindicados([]);
      setDate(moment());
    }
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 mt-2">
        <div className="col-span-6 border rounded-md p-2">
          <div className="w-full mb-1 flex items-end">
            <div className="w-72">
              <SelectSiavi
                label="Fecha de la reparación digna"
                initValue=""
                selectInit="Elegir reparación digna"
                name="reparacion_digna"
                className="w-72 mb-2"
                onChange={(e) => {
                  setIdReparacionDigna(Number(e.target.value));
                  setState({
                    ...state,
                    showAlert: false,
                  });
                }}
                list={reparacionesDignas?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {moment(item.fecha).format('DD-MM-YYYY')}
                    </option>
                  );
                })}
              />
            </div>
            <div className="w-32 mb-2 ml-3">
              <ButtonSiavi
                text="Agregar"
                cargando={cargandoButton}
                onClick={() => {
                  guardarSindicados();
                }}
              />
            </div>
          </div>
          {state.showAlert && (
            <div className="mb-2">
              <Alert type="error" mensaje={state.mensaje} />
            </div>
          )}
          <TableSiavi
            headers={["", "Sindicado", "Delito"]}
            body={sindicadosDelitos?.filter(x => x.expediente_sindicado.sindicado.conoce_al_sindicado === true 
              && registro.expediente.id === x.expediente_sindicado.expediente).map((sin,index) => (
              <tr
                key={sin?.expediente_sindicado.sindicado.primer_nombre + index}
                className="hover:bg-maivi-gray-5 cursor-pointer"
              >
                <td className={tdClass}>
                  <input
                    id={sin?.expediente_sindicado.sindicado.primer_nombre}
                    name="checkTable"
                    type="checkbox"
                    className="bg-maivi-primaryLightBlue h-4 w-4 rounded mr-2"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setState({
                          ...state,
                          showAlert: false,
                        });

                        setSindicados([...sindicados, sin.id]);
                      } else {
                        let sindicadosElegidos = sindicados.filter(
                          (x) => x !== sin.id
                        );
                        setSindicados(sindicadosElegidos);
                      }
                    }}
                  />
                </td>
                <td className={tdClass}>
                  {sin?.expediente_sindicado?.sindicado?.primer_nombre +
                    " " +
                    sin?.expediente_sindicado?.sindicado?.segundo_nombre +
                    " " +
                    sin?.expediente_sindicado?.sindicado?.primer_apellido +
                    " " +
                    sin?.expediente_sindicado?.sindicado?.segundo_apellido}
                </td>
                <td className={tdClass}>{sin?.delito?.nombre}</td>
              </tr>
            ))}
          />
        </div>
        <div className="col-span-6 rounded-md border p-3">
          <span className="text-maivi-primaryFont ">
            Sindicados agregados a reparación digna
          </span>
          <TableSiavi
            cargando={state.cargandoTable}
            headers={["Sindicado","Delito"]}
            body={listadoSindicadosRD?.map((sin,index) => (
              <tr
                key={sin?.exp_sindicado_delito?.id + index}
                className="hover:bg-maivi-gray-5 cursor-pointer"
              >
                <td className={tdClass}>
                  {sin?.exp_sindicado_delito?.expediente_sindicado?.sindicado
                    ?.primer_nombre +
                    " " +
                    sin?.exp_sindicado_delito?.expediente_sindicado?.sindicado
                      ?.segundo_nombre +
                    " " +
                    sin?.exp_sindicado_delito?.expediente_sindicado?.sindicado
                      ?.primer_apellido +
                    " " +
                    sin?.exp_sindicado_delito?.expediente_sindicado?.sindicado
                      ?.segundo_apellido}
                </td>
                <td className={tdClass}>{sin?.exp_sindicado_delito?.delito?.nombre}</td>
              </tr>
            ))}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row items-end justify-between">
        <span></span>
        <div className="w-32">
          <ButtonSiaviCancelar
            className="mr-1"
            text="Salir"
            onClick={() => {
              showModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
