import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { putEvaluacionDiagnosticaDetalle } from "./services";
import { ButtonSiavi } from "../../../../../components/common/Button";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
  //edad: Yup.number().required("edad es requerida").max(100, "Máximo 100 años"),
});

export const DetalleEvaluacion = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [detalleEvaluacion, setDetalleEvaluacion] = useState([]);
  const [nombreDominio, setNombreDominio] = useState("");
  const [editar, setEditar] = useState(false);
  const [idDominio, setIdDominio] = useState(null);

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const Escalas = () => {
    return (
      <div className="flex flex-row -mt-3 mb-2 justify-end items-end">
        <span className="text-sm px-2.5 py-0.5 rounded-full font-medium bg-red-100 text-red-800">
          1. Muy vulnerable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
          2. Vulnerable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  bg-blue-100 text-blue-800">
          3. Estable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-green-100 text-green-800">
          4. Muy Estable
        </span>
      </div>
    );
  };

  const promedio = (evaluacion) => {
    let total = 0;

    evaluacion?.detalles?.forEach(element2 => {
      total += element2.resultado_inicial;
    });

    return (total / evaluacion?.detalles?.length).toFixed(2);

  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{}}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => { }}
      >
        {({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md mt-4">
                <div className="flex flex-row justify-between ">
                  <h1 className="mb-1 text-base text-maivi-primaryFont">
                    Evaluaciones
                  </h1>
                </div>
                <table className="table w-full border">
                  <thead className="bg-maivi-gray-5 rounded-md">
                    <tr>
                      <th className={props.thClass}>Nombre</th>
                      <th className={props.thClass}>Promedio</th>
                      <th className={props.thClass}>Fecha</th>
                    </tr>
                  </thead>
                  <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                    {props.evaluaciones.evaluaciones?.map((item) => (
                      <tr
                        key={item.id}
                        className="hover:bg-maivi-gray-5 cursor-pointer"
                        onClick={() => {
                          setDetalleEvaluacion(item.detalles);
                          setNombreDominio(item?.dominio?.nombre);
                          setIdDominio(item?.dominio.id);
                        }}
                      >
                        <td className={props.tdClass}>
                          {item?.dominio?.nombre}
                        </td>
                        <td className={props.tdClass}>
                          {promedio(item)}
                        </td>
                        <td className={props.tdClass}>
                          {item?.fecha_creacion}
                        </td>
                        {/* <td className={props.tdClass}>{item?.observaciones}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md mt-1">
                <h1 className="mb-1 text-maivi-primaryFont">
                  {`Evaluaciones dominio ${nombreDominio}`}
                </h1>
                <div className="flex flex-row mt-3">
                  {editar && <Escalas />}
                </div>
                <table className="table w-full border mb-2">
                  <thead className="bg-maivi-gray-5 rounded-md">
                    <tr>
                      <th className={props.thClass}>Descripción</th>
                      <th className={props.thClass}>Puntuación</th>
                    </tr>
                  </thead>
                  <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                    {detalleEvaluacion?.map((item) => (
                      <tr
                        key={item.id}
                        className="hover:bg-maivi-gray-5 cursor-pointer"
                      >
                        <td className={props.tdClass}>
                          {item?.subdominio?.descripcion}
                        </td>
                        <td className={props.tdClass}>
                          {editar === true ? (
                            <select
                              name={`select-${item.subdominio.nombre}`}
                              onChange={(e) => {
                                putEvaluacionDiagnosticaDetalle(
                                  item.id,
                                  item.evaluacion,
                                  idDominio,
                                  item.subdominio.id,
                                  e.target.value,
                                  token
                                )
                                  .then(async (data) => {
                                    if (
                                      data?.status === 200 ||
                                      data?.status === 201
                                    ) {
                                      toast.success(
                                        "Evaluacion actualizada con éxito."
                                      );
                                    } else {
                                      toast.success(
                                        "Evaluacion actualizada con éxito."
                                      );
                                    }
                                  })
                                  .catch(function (error) {
                                    if (
                                      error.response.status >= 400 &&
                                      error.response.status <= 450
                                    ) {
                                      toast.error(error.response.data.message);
                                    } else if (error.response.status >= 500) {
                                      toast.error(
                                        "Error interno comuniquese con el administrador"
                                      );
                                    }
                                  });
                              }}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          ) : (
                            item?.resultado_inicial
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {(CheckPermiso('OEDIEditar') === true) &&    //mm permiso agregado        
                  <ButtonSiavi
                    text={editar ? 'Cancelar' : 'Editar'}
                    onClick={() => {
                      setEditar(!editar);
                    }}
                  />
                }
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div className="flex flex-row justify-start items-stretch">
        <h1 className="mt-2 text-xs text-maivi-primaryFont">
          Realizado por: {props.evaluaciones.usuario.first_name} {props.evaluaciones.usuario.last_name}
        </h1>
      </div>
    </div>
  );
};
