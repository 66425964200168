/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { FormDetalle } from "./Detalle";
import { GestionesFormulariosPreguntas } from "./gestionFormulariosPreguntas";
import { Autorizar } from "./autorizar";
import { 
    deleteCatalogos, 
    getGenericCatalogo, 
    getGenericCatalogoSearch 
} from "../../../helpers/catalogos";
import { 
    XCircleIcon, 
    DocumentTextIcon, 
    FilterIcon, 
    QuestionMarkCircleIcon, 
    CheckCircleIcon, 
    ClipboardCheckIcon 
} from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../common/FormEliminar";
import CustomTable from "../../../components/common/Table";
import toast from 'react-hot-toast';
import Filtros from "./filtros";
import ButtonRefresh from "../common/ButtonRefresh";
import ModalAmplio from "../../../components/common/ModalAmplio";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { title: "Nombre", value: "nombre", tipo: "text", ordenar: "nombre" },
    { title: "Servicio", value: "servicio.nombre", tipo: "text" },
    {
        title: "Autorizado",
        value: "autorizado",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

// const useFetchFormularios = (token) => {
//     const [state, setState] = useState({
//         formulariosgenerales: [],
//     });

//     useEffect(() => {
//         getFormularios(token).then((formulariosgenerales) => {
//             setState({
//                 formulariosgenerales: formulariosgenerales,
//             });
//         });
//     }, [token]);

//     return state;
// };

export const TableEncuestas = () => {
    const [isEliminar, setEliminar] = useState(false);
    const [verDetalle, setDetalle] = useState(false);
    const [agregar, setAgregar] = useState(false);
    //const [formsPreguntas, setFormsPreguntas] = useState([]);
    const [formularios, setFormularios] = useState("");
    const [formularioDetalle, setFormularioDetalle] = useState({});
    const [idFormulario, setIdFormulario] = useState("");
    const [nombreFormulario, setNombreFormulario] = useState("");
    const [isloading, setLoading] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const [reFetch, setReFetch] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState("encuesta=true");
    const [gestionar, setGestionar] = useState(false);
    //const { formulariosgenerales } = useFetchFormularios(token);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "formularios";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    useEffect(() => {
        getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
        setReFetch(false);
        setEliminar(false);
        setLoading(false);
    }, [urlFetch, token, reFetch, filters]);
    useEffect(() => {
        getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters);
    }, [order, reFetch, filters]);
    useEffect(() => {
        getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order, filters);
    }, [search, reFetch, filters]);

    const formulariosPreguntas = (item) => {
        // getFormulariosPreguntas(item.id, token).then((data) => {
        //     setFormsPreguntas(data);
        // });
    };

    const handleAgregar = (item) => {
        autorizadoValor = item.autorizado;
        estadoValor = item.estado;
        if (autorizadoValor === true && estadoValor === false) {
            toast.error("Ya no es posible agregar mas preguntas")
        } else {
            formulariosPreguntas(item);
            setIdFormulario(item.id);
            setFormularios(item);
            setAgregar(true);
        }
    };

    const handleVer = (item) => {
        setFormularioDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id, nombre) => {
        setIdFormulario(id);
        setNombreFormulario(nombre);
        setEliminar(true);
    };

    const handleGestionar = (item) => {
        setFormularios(item);
        setGestionar(true);
    };

    let autorizadoValor;
    let estadoValor;

    const linkEditar = `/catalogos/encuesta/editar/`;

    const pages = [
        { name: "Catálogos", href: "/catalogos", current: false },
        { name: "Encuestas", href: "#", current: true },
    ];
    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
                <BreadCrumb navegacion={pages} />
                {permisoAgregar && (
                    <div>
                        <Link to="/catalogos/encuesta/crear" type="button">
                            <ButtonAdd text="Crear" type="button" />
                        </Link>
                        <ButtonAdd
                            className="ml-2"
                            text="Filtros"
                            type="button"
                            onClick={() => {
                                setOpenFilters(true);
                            }}
                            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                        />
                        <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />
                    </div>
                )}
            </div>
            <div className="flex justify-end">
            <Link to={`/catalogos/tipo-pregunta/?encuestas=${true}`}>
                <ButtonAdd
                    text="Tipo Pregunta"
                    type="button"
                    onClick={() => {
                    }}
                    icon={<QuestionMarkCircleIcon className="ml-3 -mr-1 h-5" />}
                />
                </Link>
                <Link to={`/catalogos/seleccion-encuesta/`}>
                <ButtonAdd
                    className="ml-2"
                    text="Selección Múltiple"
                    type="button"
                    onClick={() => {
                    }}
                    icon={<CheckCircleIcon className="ml-3 -mr-1 h-5" />}
                />
                </Link>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        handleEliminar={handleEliminar}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        link={true}
                        linkTo={linkEditar}
                        showVer={true}
                        setOrder={setOrder}
                        order={order}
                        showEditar={true}
                        agregar={true}
                        handleAgregar={handleAgregar}
                        ocultarAcciones={true}
                        agregar1="Formularios Preguntas"
                        agregarSegundo={true}
                        agregar2="Gestionar"
                        handleSegundoAgregar={handleGestionar}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {gestionar && (
                        <ModalGenerico
                            title={"Autorizar encuesta"}
                            open={gestionar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setGestionar(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <Autorizar instrumento={formularios} setGestionar={setGestionar} setReFetch={setReFetch} />
                            }
                        />
                    )}
                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de encuesta"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <FormDetalle detalle={formularioDetalle} cancelar={setEliminar} />
                            }
                        />
                    )}
                    {agregar && (
                        <ModalAmplio
                            title={`Gestión de Preguntas de ${formularios.nombre}`}
                            open={agregar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setAgregar(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <GestionesFormulariosPreguntas formularios={formularios} />
                            }
                        />
                    )}
                    {isEliminar && (
                        <ModalGenerico
                            title={`¿Esta seguro de eliminar el registro ${nombreFormulario}?`}
                            open={isEliminar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setEliminar(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                    <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                                </div>
                            }
                            children={
                                <FormEliminar
                                    aceptar={() => {
                                        setLoading(true);
                                        deleteCatalogos(idFormulario, permiso, token).then((data) => {
                                            if (data?.message !== undefined) {
                                                setLoading(false);
                                                toast.error(data.message)
                                            } else {
                                                setReFetch(true);
                                                setLoading(false);
                                                toast.success('Registro eliminado con éxito...!')
                                            }
                                        });
                                    }}
                                    isloading={isloading}
                                    cancelar={() => {
                                        setEliminar(false);
                                    }}
                                />
                            }
                        />
                    )}
                    {openFilters && (
                        <ModalGenerico
                            title={`Aplicar filtros`}
                            open={openFilters}
                            closeModal={() => { }}
                            cerrar={() => {
                                setOpenFilters(false);
                            }}
                            children={<Filtros setFilters={setFilters} setOpenFilters={setOpenFilters} />}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <FilterIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
