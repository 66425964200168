import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { DynamicIcon } from "../../components/common/DynamicIcon";


import Divider from "../../components/common/Divider";

const dal = [
  {
    permiso: "dal.sedes",
    name: "Sedes DAL",
    href: "reportes/dal/sedes",
    icon: 'HomeIcon',
  },
  {
    permiso: "dal.profesional",
    name: "Profesionales DAL",
    href: "reportes/dal/profesional",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dal.audiencia",
    name: "Audiencias DAL",
    href: "reportes/dal/audiencias",
    icon: 'ScaleIcon',
  },
  {
    permiso: "dal.audiencia",
    name: "Audiencias Específicas",
    href: "reportes/dal/audienciascaso",
    icon: 'ScaleIcon',
  },
  {
    permiso: "dal.sentencia",
    name: "Sentencias DAL",
    href: "reportes/dal/sentencias",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.desjudicializadora",
    name: "Desjudicializadora DAL",
    href: "reportes/dal/desjudicializadora",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.datos",
    name: "Datos Sociodemográficos DAL",
    href: "reportes/dal/datos",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.rendimiento",
    name: "Rendimiento Laboral DAL",
    href: "reportes/dal/metas",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.metas",
    name: "Metas DAL",
    href: "reportes/dal/metasDAL",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.ficha",
    name: "Ficha Medición Laboral",
    href: "reportes/dal/fichamedicion",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.reparaciones",
    name: "Reparaciones Dignas DAL",
    href: "reportes/dal/reparacion",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.casos",
    name: "Casos Admisibles DAL",
    href: "reportes/dal/casos",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.impugnaciones",
    name: "Impugnaciones DAL",
    href: "reportes/dal/impugnaciones",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.indicadores",
    name: "Indicadores DAL",
    href: "reportes/dal/indicadores",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.indicadorOrientaciones",
    name: "Indicadores Orientaciones Casos",
    href: "reportes/dal/indicadoresorientacionescasos",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.indicadorSentencias",
    name: "Indicadores Sentencias",
    href: "reportes/dal/indicadoressentencias",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.indicadorReparaciones",
    name: "Indicadores Reparaciones",
    href: "reportes/dal/indicadoresreparaciones",
    icon: 'LibraryIcon',
  },
  {
    permiso: "dal.expedientes",
    name: "Listado de expedientes",
    href: "reportes/dal/listadoexpedientes",
    icon: 'LibraryIcon',
  }
];

const dsv = [
  {
    permiso: "dsv.profesional",
    name: "Profesionales DSV",
    href: "reportes/dsv/profesionales",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.acciones",
    name: "Acciones Victimológicas DSV",
    href: "reportes/dsv/acciones-victimologicas",
    icon: 'HeartIcon',
  },
  {
    permiso: "dsv.accionesPorArea",
    name: "Acciones por Área DSV",
    href: "reportes/dsv/acciones-por-area",
    icon: 'IdentificationIcon',
  },
  {
    permiso: "dsv.informacionVictimologica",
    name: "Información Victimológica",
    href: "reportes/dsv/informacion-victimologica",
    icon: 'IdentificationIcon',
  },
  {
    permiso: "dsv.visitas",
    name: "Visitas",
    href: "reportes/dsv/visitas",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.expedientes",
    name: "Expedientes por mesa",
    href: "reportes/dsv/expedientes",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.informes",
    name: "Informes Victimológicos",
    href: "reportes/dsv/informes",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.expedientesAsignados",
    name: "Expedientes Asignados",
    href: "reportes/dsv/expedientesPendientes",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.referencias",
    name: "Referencias",
    href: "reportes/dsv/referencias",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.planificacion",
    name: "Planificación Semanal",
    href: "reportes/dsv/planificacion",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.atenciones",
    name: "Atenciones victimológicas",
    href: "reportes/dsv/atenciones",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.metasPsicologia",
    name: "Metas Psicología",
    href: "reportes/dsv/metaspsicologia",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.metasTrabajoSocial",
    name: "Metas Trabajo Social",
    href: "reportes/dsv/metastrabajo",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.metasMedicina",
    name: "Metas Medicina",
    href: "reportes/dsv/metasmedicina",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.riesgo",
    name: "Riesgo y Plan de Tratamiento",
    href: "reportes/dsv/plantratamiento",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.matriz",
    name: "Matriz de Beneficiarios",
    href: "reportes/dsv/matrizbeneficiarios",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.cierre",
    name: "Cierre Casos",
    href: "reportes/dsv/cierrecasos",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "dsv.evolucion",
    name: "Evolución de Vulnerabilidad",
    href: "reportes/dsv/evaluaciondiagnostica",
    icon: 'UserGroupIcon',
  }
];




export const ListadoReporte = () => {


  
  const permisos = useSelector(state => state.ChangePermisos?.menus?.reportes?.menus ? state.ChangePermisos?.menus?.reportes?.menus: []);


  const checkPermiso = (item) => {
    let retorno = false;
   
    permisos.forEach(p => {
      //console.log(p)
      if(p.permiso === item.permiso){
        retorno = p.ver;
      }
    })

    //retorno = true;
    return retorno;
  }




 
  
  const listado = (listado, title) => {
    

  
    return (
      <div>
        
          <h1 className="inline text-xl font-extrabold tracking-tight text-maivi-primaryLightBlue ">{title}</h1>
        
        
          <Divider />
        

           <div>
           <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 m-0">
             {listado.map((item, index) =>
             checkPermiso(item) &&
               <li key={`${index}-seg`} className="flex shadow-lg rounded-md h-20">
                 <Link key={item.name} to={item.href} className="h-22 flex-1 flex flex-col items-center justify-between border border-maivi-primaryLightBlue bg-white rounded-r-md break-word hover:bg-maivi-secondaryBlueHover">
                   <div className="flex flex-col items-center my-auto">
                   <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                     <p className="xl:text-xs lg:text-sm md:text-sm sm:text-sm text-gray-900 text-center font-medium">
                       {item.name}
                     </p>
                   </div>
                 </Link>
     
               </li>
             )}
           </ul>
         </div>
      </div>
    );
  }

  return (
    <div>
      {listado(dal, 'Dirección de Asistencia Legal')}
      {listado(dsv, 'Dirección de Servicios Victimológicos')}
    </div>
  );
};
