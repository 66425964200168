
const {
    REACT_APP_API_URL
} = process.env;

export const getDiligenciasUrgentes = async (urlPage, search,sede,profesional,order, token) => {
    try {
      let urlFetch = urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?search=${search.value}&expediente__sede_expediente=${sede}&expediente__profesional_asignado=${profesional}&ordering=-expediente__fecha_asignacion,${order}`
          :`${urlPage}&search=${search.value}&expediente__sede_expediente=${sede}&expediente__profesional_asignado=${profesional}&ordering=-expediente__fecha_asignacion,${order}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());
  
      return result;
    } catch (e) {
      console.log("error", e);
    }
  };

  export const diligenciasUrgentes = async (urlPage,sede,profesional, token) => {
    try {
      let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?expediente__sede_expediente=${sede}&expediente__profesional_asignado=${profesional}`
          : `${urlPage}&expediente__sede_expediente=${sede}&expediente__profesional_asignado=${profesional}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((data) => data.json());
  
      return result;
    } catch (e) {
      console.log("error", e);
    }
  };