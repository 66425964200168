import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteMotivoCierre,
    getMotivosCriterioCierre,
    postMotivoCierre,
    putMotivoCierre
} from "../motivoCierre/services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { FormDetalle } from "../motivoCierre/Detalle";
import { getCriterioCierre } from "./services";
import { GestionesCriterioCierre } from "./gestionCriterioCierre";
import ModalAmplio from "../../../components/common/ModalAmplio";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Motivo Cierre", value: "nombre", tipo: "text" },
    { title: "Descripción", value: "descripcion", tipo: "text" },
    {
        title: "¿Requiere Autorización?",
        value: "require_autorizacion",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(100, "Máximo 100 caracteres"),
    descripcion: Yup.string()
        .required("Descripción es requerida")
        .max(100, "Máximo 100 caracteres")
});

export const GestionesMotivoCierre = (props) => {
    const [verDetalle, setDetalle] = useState(false);
    const [motivoCierreDetalle, setMotivoCierreDetalle] = useState({});
    const [agregar, setAgregar] = useState(false);
    const [criterioCierre, setCriterioCierre] = useState([]);
    const [motivoCierre, setMotivoCierre] = useState("");
    const [idMotivoCierre, setIdMotivoCierre] = useState("");
    const [nombreMotivoCierre, setNombreMotivoCierre] = useState("");
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idMotivoCriterioCierre = props?.motivoCriterioCierre?.id;
    const [reFetch, setReFetch] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [isEdit, setEdit] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "motivocierre";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const idCriterioCierre = (item) => {
        getCriterioCierre(item.id, token).then((data) => {
            setCriterioCierre(data);
        });
    };

    const handleAgregar = (item) => {
        idCriterioCierre(item);
        setIdMotivoCierre(item.id);
        setMotivoCierre(item);
        setAgregar(true);
    };

    const handleVer = (item) => {
        setMotivoCierreDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id, nombre) => {
        setIdMotivoCierre(id);
        setNombreMotivoCierre(nombre);
        setEliminar(true);
    };

    const handleEditar = (tipo) => {
        setEdit(true);
        setMotivoCierre(tipo);
    };

    const fetchMotivoCierre = () => {
        getMotivosCriterioCierre(idMotivoCriterioCierre, token).then((motivos) => {
            setDataTable(motivos);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchMotivoCierre, [token, idMotivoCriterioCierre, urlFetch, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    motivo_criterios_cierre: props.motivoCriterioCierre.id,
                    nombre: isEdit ? motivoCierre?.nombre : "",
                    descripcion: isEdit ? motivoCierre?.descripcion : "",
                    estado: isEdit ? motivoCierre?.estado : true,
                    require_autorizacion: isEdit ? motivoCierre?.require_autorizacion : false
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putMotivoCierre(motivoCierre.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.nombre = "";
                                    values.descripcion = "";
                                    values.estado = true;
                                    values.require_autorizacion = false;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postMotivoCierre(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 xl:col-span-12 lg:col-span-12 border p-3 rounded-md">
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-12 xl:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            value={values.nombre}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Motivo de Cierre*"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="descripcion"
                                            id="descripcion"
                                            value={values.descripcion}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Descripción*"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                        <Label text="¿Requiere Autorización?" />
                                        <Toggle enabled={values?.require_autorizacion} name="require_autorizacion" />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                        <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                        <Toggle enabled={values.estado} name="estado" />
                                    </div>
                                    {permisoAgregar && (
                                        <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-5">
                                            <ButtonSiavi
                                                type="submit"
                                                disabled={isSubmitting}
                                                text={isEdit ? "Actualizar" : "Guardar"}
                                                onClick={handleSubmit}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEditar={handleEditar}
                    handleEliminar={handleEliminar}
                    fetchPage={setUrlFech}
                    pagination={true}
                    permiso={permiso}
                    showVer={true}
                    agregar={true}
                    agregar1="Criterio Cierre"
                    handleAgregar={handleAgregar}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de motivo cierre"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalle detalle={motivoCierreDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Criterio Cierre de ${motivoCierre.nombre}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionesCriterioCierre motivoCierre={motivoCierre} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar ${nombreMotivoCierre}?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteMotivoCierre(idMotivoCierre, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
