const {
    REACT_APP_API_URL
} = process.env;

export const get = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/medidavictimologica/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const post = async ({
    nombre,
    estado,
    servicio_interno
},token) => {
    try {
        let body = {
            nombre,
            estado,
            servicio_propio:servicio_interno
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/medidavictimologica/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const put = async (id, {
    nombre,
    estado,
    servicio_interno
},token) => {
    try {
        let body = {
            nombre,
            estado,
            servicio_propio:servicio_interno
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/medidavictimologica/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRegistro = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/medidavictimologica/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getServicioInterno = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};