/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getHistorialSindicado } from "../services";
import TableSiavi from "../../../../../components/common/TableSiavi";

export const Historial = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idExpedienteSindicado = props.sindicadoDelito.id;
    const [historial,setHistorial] = useState([]);
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        setCargando(true);
        getHistorialSindicado(idExpedienteSindicado, token).then((data) => {
            setHistorial(data)
            setCargando(false);
        });
    }, []);

    return  <div className="border-gray-200 px-4 py-5 sm:p-0">
    <TableSiavi 
        cargando={cargando}
        headers={[
            'Fecha',
            'Delito',
            'Estado'
        ]}
        body= {historial?.results?.map((item) => (
            <tr
                key={item.id}
                className="hover:bg-maivi-gray-5 cursor-pointer"
            >
                <td className={props.tdClass}>
                    {item?.fecha}
                </td>
                <td className={props.tdClass}>{item?.delito?.nombre}</td>
                <td className={props.tdClass}>{(item?.estado) ? 'Activo' : 'Inactivo'}</td>
            </tr>
        ))}
    />
</div>
};
