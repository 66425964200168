
import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const putCasoFiscalia = async (fiscalia, fiscal_acargo, id, token) => {
    try {
        let body = {
            fiscalia_actual: fiscalia,
            fiscal_acargo: fiscal_acargo
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/caso/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMensaje = async (bandeja, profesional, token) => {
    try {
        let body = {
            leido: false,
            fecha_leido: null,
            enviado_al_correo: false,
            bandeja_profesionales: true,
            correo_destino: "",
            respondido: false,
            bandeja: bandeja,
            profesional_destino: profesional,
            diligencia: null
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/bandejaprofesionaldestino/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postBandejaUsuario = async (caso, mensaje, usuario, token) => {
    try {
        let body = {
            bandeja: null,
            titulo: "Mensaje a profesional",
            mensaje: mensaje,
            tipo_accion: null,
            tipo_seguimiento: null,
            requiere_respuesta: true,
            notificacion_legal: false,
            profesional_origen: usuario,
            caso: caso,
            //persona: null,
            expediente: null,
            tipo_mensaje: null,
            atencion: null,
            referencia: null
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/bandejausuario/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getMensajes = async (caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/bandejausuario/?page_size=50&caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionaCriterioCierre = async (data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            gestiona_cierre: data.gestiona_cierre,
            cierre_autorizado: data.cierre_autorizado,
            motivo: data.motivo,
            expediente: data.expediente,
            caso: data.caso,
            profesional_solicita: data.profesional_solicita
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpCriterioCierre = async (data, token) => {
    try {
        let body = {
            se_cumple_criterio: data.se_cumple_criterio,
            resultado: data.resultado,
            comentarios: data.comentarios,
            fecha: data.fecha,
            fecha_gestionado: data.fecha_gestionado,
            caso: data.caso,
            expediente: data.expediente,
            tipo_proceso: data.tipo_proceso,
            criterio: data.criterio,
            usuario_gestionado: data.usuario_gestionado,
            profesional_crea: data.profesional_crea,
            gestionado: data.gestionado
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientecriteriocierre/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpCriterioCierre = async (id, data, token) => {
    try {
        let body = {
            se_cumple_criterio: data.se_cumple_criterio,
            resultado: data.resultado,
            comentarios: data.comentarios,
            fecha: data.fecha,
            fecha_gestionado: data.fecha_gestionado,
            caso: data.caso,
            expediente: data.expediente,
            tipo_proceso: data.tipo_proceso,
            criterio: data.criterio,
            usuario_gestionado: data.usuario_gestionado,
            profesional_crea: data.profesional_crea,
            gestionado: data.gestionado
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientecriteriocierre/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivoCierre = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/motivocierre/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCriterioCierre = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/criterioscierre/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionaCriterioCierre = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionaexpedientecierre/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteExpCriterioCierre = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientecriteriocierre/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpCriterioCierre = async (idExpediente, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientecriteriocierre/?expediente=${idExpediente}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpCriterioCierreGestion = async (idGestion, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientecriteriocierre/?gestionado=${idGestion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionaCriterioCierre = async (idExpediente, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/?expediente=${idExpediente}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionaCriterioCierreCaso = async (idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/gestionaexpedientecierre/?caso=${idCaso}&expediente__isnull=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDelitosCaso = async (caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadodelito/?page_size=50&caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedes = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/sedes/?page_size=200&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSedeCaso = async (sede, id, token) => {
    try {
        let body = {
            sede_caso: sede,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/caso/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCausasPenales = async (caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientecausapenal/?caso=${caso}&ordering=-id`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoSMSVictima = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/tiposmsvictima/?page_size=1000&estado=true&por_caso=true&para_victima=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientes = async(idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expediente/?caso=${idCaso}&page_size=20`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};