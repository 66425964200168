/* eslint-disable array-callback-return */
import { React, useState, useEffect } from "react";
import { TratamientoPreliminar } from "./tratamientoPreliminar";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { DetalleEvaluacion } from "./detalle";
import { AddTratamientoPreliminar } from "./addPlanTratamiento";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { EyeIcon, DocumentAddIcon, PrinterIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { getPersonasCaso } from "../services";
import { SelectSiavi } from "../../../../../components/common/Select";
import {
  getDominios,
  getSubdominios,
  getEvaluaciones,
  postEvaluacionDiagnostico,
  postEvaluacionDiagnosticoDominio,
  postEvaluacionDiagnosticaDetalle,
  postPlanTratamientoPreliminar,
  getServicios
} from "./services";
import ModalTwo from "../../../../../components/common/ModalTwo";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import toast from "react-hot-toast";
import ModalGenerico from "../../../../../components/common/Modal";
import Divider from "../../../../../components/common/Divider";
import Alert from "../../../../../components/common/Alert";
import Spinner from "../../../../../components/common/Spinner";
import PdfViewer from "../../../../../components/common/PdfViewer";

const tdClass = "px-6 py-1 row-td text-maivi-primaryFont text-xs";
const thClass = "px-6 py-1 text-left font-medium text-maivi-primaryFont text-sm";

export const Evaluacion = ({
  values,
  handleChange,
  caso,
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idCaso = caso.id;
  const [refetch, setRefetch] = useState(false);
  const [idCasoPersona, setIdCasoPersona] = useState(1);
  const [dominioEvaluacion, setDominioEvaluacion] = useState([]);
  const [subDominioEvaluacion, setSubDominioEvaluacion] = useState([]);
  const [personasCaso, setPersonasCaso] = useState([]);
  const [subDominios, setSubdominios] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [hasPlanes, setHasPlanes] = useState(false);
  const [servicios, setServicios] = useState([]);
  const [error, setError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [modalDetalle, setModalDetalle] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargandoEvaluaciones, setCargandoEvaluaciones] = useState(false);
  const [registroDetalle, setRegistroDetalle] = useState(null);
  const [guardar, setGuardar] = useState(false);
  const [alertaTratamiento, setAlertaTratamiento] = useState(false);
  const [addPlanTratamiento, setAddPlanTratamiento] = useState(false);
  const [idEvaluacion, setIdEvaluacion] = useState(false);
  const [imprimir, setImprimir] = useState(false);
  const [imprimirData, setImprimirData] = useState(false);

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  useEffect(() => {
    getPersonasCaso(idCaso, token).then((data) => {
      setPersonasCaso(data);
    });
  }, [idCaso, token, refetch]);

  useEffect(() => {
    getDominios(token).then((data) => {
      setDominioEvaluacion(data.results);
    });
  }, [token, setDominioEvaluacion]);

  useEffect(() => {
    getSubdominios(token).then((data) => {
      setSubdominios(data);
    });
  }, [token]);

  useEffect(() => {
    setCargandoEvaluaciones(true);
    getEvaluaciones(idCaso, token).then((data) => {
      data?.results?.forEach(element => {
        if (element.tiene_planes === false) {
          setHasPlanes(false);
        }
      });
      setEvaluaciones(data);
      setCargandoEvaluaciones(false);
    });
  }, [token, idCaso, refetch, addPlanTratamiento]);

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, [token]);

  const Escalas = () => {
    return (
      <div className="flex flex-row -mt-3 mb-2 justify-end items-end">
        <span className="text-sm px-2.5 py-0.5 rounded-full font-medium bg-red-100 text-red-800">
          1. Muy vulnerable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
          2. Vulnerable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  bg-blue-100 text-blue-800">
          3. Estable
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-green-100 text-green-800">
          4. Muy Estable
        </span>
      </div>
    );
  };

  const Notas = () => {
    return (
      <div className="flex flex-col mt-2 mb-2 justify-center items-center">
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  text-blue-800">
          *Para niños menores de 12 años, por favor califique al cuidador del
          menor de edad
        </span>
        <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  text-blue-800">
          +Para niños menores de 3 años, por favor califique solamente cuidador
          del menor de edad
        </span>
      </div>
    );
  };

  const puntuaciones = [
    {
      value: 1,
      title: "1",
      color: "bg-red-100 text-red-800 px-1.5 rounded-full",
    },
    {
      value: 2,
      title: "2",
      color: "bg-yellow-100 text-yellow-800 px-1.5 rounded-full",
    },
    {
      value: 3,
      title: "3",
      color: "bg-blue-100 text-blue-800 px-1.5 rounded-full",
    },
    {
      value: 4,
      title: "4",
      color: "bg-green-100 text-green-800 px-1.5 rounded-full",
    },
  ];

  const [state, setState] = useState({
    persona: null,
  });

  function onChange(value, dominio) {
    let newDom = {
      ...dominio,
      observaciones: value,
    };
    let temp = [];

    dominioEvaluacion.forEach((dom) => {
      if (dom.id === dominio.id) {
        temp.push(newDom);
      } else {
        temp.push(dom);
      }
    });

    setDominioEvaluacion(temp);
  }

  function onChangePersona(e) {
    let hasEvaluacion = false;

    evaluaciones?.results?.forEach((element) => {
      if (element?.caso_persona?.id === Number(e.target.value)) {
        hasEvaluacion = true;
      }
    });

    if (hasEvaluacion) {
      setError(true);
      setMensaje("La persona elegida ya tiene una evaluación");
      setState({
        ...state,
        persona: null,
      });
      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
      setIdCasoPersona(e.target.value);
    }
  }

  dominioEvaluacion.forEach((dom) => {
    subDominios?.results?.forEach((sub) => {
      if (dom.id === sub.dominio.id) {
        let bandera = true;

        subDominioEvaluacion.forEach((sub2) => {
          if (sub.id === sub2.id && sub.dominio.id === sub2.dominio) {
            bandera = false;
          }
        });
        if (bandera) {
          subDominioEvaluacion.push({
            id: sub.id,
            descripcion: sub.descripcion,
            dominio: sub.dominio.id,
            puntuacion: 0,
          });
        }
      }
    });
  });

  function onChangeChecks(value, sub) {
    sub.puntuacion = parseInt(value);
    let temp = [];

    subDominioEvaluacion.forEach((sub2) => {
      if (sub.id === sub2.id && sub.dominio === sub2.dominio) {
        temp.push(sub);
      } else {
        temp.push(sub2);
      }
    });
    setSubDominioEvaluacion(temp);
  }

  const Resultado = (idDominio) => {
    let total = 0;
    let contador = 0;
    let titulo = <div></div>;
    let resultado = 0;

    subDominioEvaluacion.forEach((element) => {
      if (element.dominio === idDominio) {
        total += Number(element.puntuacion);
        contador++;
      }
    });

    resultado = (total / contador).toFixed(1);
    if (resultado >= 1 && resultado < 2) {
      titulo = <span className="text-sm px-2.5 py-0.5 rounded-full font-medium bg-red-100 text-red-800">
        {`Muy vulnerable ${resultado}`}
      </span>
    } else if (resultado >= 2 && resultado < 3) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
        {`Vulnerable ${resultado}`}
      </span>
    } else if (resultado >= 3 && resultado < 4) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  bg-blue-100 text-blue-800">
        {`Estable ${resultado}`}
      </span>
    } else if (resultado >= 4) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-green-100 text-green-800">
        {`Muy Estable ${resultado}`}
      </span>
    }

    return <td className={`${tdClass} text-right`}> {titulo}</td>;
  };

  const ResultadoFinal = () => {
    let total = 0;
    let total2 = 0;
    let sumatoria = [];
    let largo = 0;

    dominioEvaluacion.forEach(element => {
      subDominioEvaluacion.forEach(element2 => {
        if (element.id === element2.dominio) {
          total2 += Number(element2.puntuacion);
          largo++;
        }
      });
      sumatoria.push(total2 / largo);
      total2 = 0;
      largo = 0;
    });

    sumatoria.forEach(element => {
      total += element
    });

    let resultado = promedioFinal((total / dominioEvaluacion.length).toFixed(1));
    return resultado;
  };

  const promedioGeneral = (evaluacion) => {
    let promedio = 0;
    let arreglo = [];
    let total = 0;

    evaluacion?.evaluaciones?.forEach(element => {
      element?.detalles?.forEach(element2 => {
        total += element2.resultado_inicial;
      });
      arreglo.push((total) / element?.detalles.length);
      total = 0;
    });

    arreglo.forEach(element => {
      promedio += element;
    });

    return (promedio / evaluacion.evaluaciones.length).toFixed(2);
  }

  const promedioFinal = (resultado) => {
    let titulo;
    if (resultado >= 1 && resultado < 2) {
      titulo = <span className="text-sm px-2.5 py-0.5 rounded-full font-medium bg-red-100 text-red-800">
        {`Muy vulnerable ${resultado}`}
      </span>
    } else if (resultado >= 2 && resultado < 3) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
        {`Vulnerable ${resultado}`}
      </span>
    } else if (resultado >= 3 && resultado < 4) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium  bg-blue-100 text-blue-800">
        {`Estable ${resultado}`}
      </span>
    } else if (resultado >= 4) {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-green-100 text-green-800">
        {`Muy Estable ${resultado}`}
      </span>
    } else {
      titulo = <span className="ml-2 text-sm px-2.5 py-0.5 rounded-full font-medium bg-green-100 text-green-800">
        {0}
      </span>
    }
    return titulo;
  }

  const compareDominio = (a, b) => {
    if (a.dominio.id < b.dominio.id) {
      return -1;
    }
    if (a.dominio.id > b.dominio.id) {
      return 1;
    }
    return 0;
  }

  const compareSubDominio = (a, b) => {
    if (a.subdominio.descripcion.substring(0, 1) < b.subdominio.descripcion.substring(0, 1)) {
      return -1;
    }
    if (a.subdominio.descripcion.substring(0, 1) > b.subdominio.descripcion.substring(0, 1)) {
      return 1;
    }
    return 0;
  }

  return (
    <form autoComplete="off">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
          <div className="flex flex-col xl:flex-row lg:flex-row  justify-between ">
            <h1 className="mb-4 xl:mb-1 lg:mb-1 md:mb-1">Evaluaciones</h1>
            <SelectSiavi
              className="-mt-2 mb-2"
              name="persona"
              value={state.persona}
              valueInit={null}
              selectInit="Elegir persona"
              onChange={(e) => {
                onChangePersona(e);
              }}
              list={
                personasCaso?.results?.filter(x => (x.es_victima_indirecta === true || x.victima === true) && x?.persona?.victima_fallecida === false).map((item, index) => {
                  return (
                    <option key={`${item?.persona.primer_nombre}-${item?.tipo_persona?.nombre}-${index}`} value={item.id}>
                      {item?.persona.primer_nombre} {item?.persona.segundo_nombre}{" "}
                      {item?.persona.primer_apellido}{" "}
                      {item?.persona.segundo_apellido}{" "}
                      {item?.tipo_persona?.nombre}
                    </option>
                  );
                })
              }
            />
          </div>
          {
            (cargandoEvaluaciones) ? <Spinner /> :
              <table className="table w-full border">
                <thead className="bg-maivi-gray-5 rounded-md">
                  <tr>
                    <th className={thClass}>Víctima</th>
                    <th className={thClass}>Nombre</th>
                    <th className={thClass}>Fecha</th>
                    <th className={thClass}>Tipo</th>
                    <th className={thClass}>Promedio</th>
                    {
                      (!hasPlanes) ? <th></th> : <th className={thClass}>Agregar plan</th>
                    }
                    <th className={thClass}>Acciones</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                  {evaluaciones.results?.map((item, index) => (
                    <tr key={`evaluaciones-${index}`} className="hover:bg-maivi-gray-5 cursor-pointer">
                      <td className={tdClass}>
                        {item?.caso_persona?.es_victima_indirecta ? "Indirecta" : item?.caso_persona?.victima ? "Directa" : ""}
                      </td>
                      <td
                        className={tdClass}
                      >{`${item?.caso_persona?.persona?.primer_nombre} ${item?.caso_persona?.persona?.segundo_nombre} ${item?.caso_persona?.persona?.primer_apellido} ${item?.caso_persona?.persona?.segundo_apellido}`}</td>
                      <td className={tdClass}>
                        {item?.fecha_creacion}
                      </td>
                      <td className={tdClass}>
                        {item?.tipo}
                      </td>
                      <td className={tdClass}>
                        {
                          promedioGeneral(item)
                        }
                      </td>
                      {
                        (item.tiene_planes === false) && (item.tipo === "INICIAL") ?
                          <td className={tdClass}><span className="text-red-500">***Por favor registre plan de tratamiento.***</span></td> : <td className={tdClass}></td>
                      }
                      <td className={tdClass}>
                        <button
                          type="button"
                          className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent
                      rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip"
                          onClick={() => {
                            setRegistroDetalle(item);
                            setModalDetalle(true);
                          }}
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          <span className="tooltiptext">Detalle</span>
                        </button>
                        {(CheckPermiso('OEDIPlanTratamiento') === true) && (item.tipo === "INICIAL") &&     //mm permiso agregado        
                          <button
                            type="button"
                            className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent
                      rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip"
                            onClick={() => {
                              setIdEvaluacion(item.id);
                              setAddPlanTratamiento(true);
                            }}
                          >
                            <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                            <span className="tooltiptext">plan de tratamiento</span>
                          </button>
                        }
                        {(CheckPermiso('OEDIImprimir') === true) &&    //mm permiso agregado        
                          <button
                            type="button"
                            onClick={() => {
                              setImprimir(true);
                              let tempItem = { ...item, codigoIso: "DAL-DSV-FOR-10" };
                              tempItem.evaluaciones = tempItem.evaluaciones.sort(compareDominio);

                              tempItem.evaluaciones.forEach((ev, index) => {
                                tempItem.evaluaciones[index].detalles = ev.detalles.sort(compareSubDominio)
                              });
                              setImprimirData(tempItem)
                            }}
                            className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent
                      rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip"
                          >
                            <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                            <span className="tooltiptext">imprimir</span>
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }

        </div>
        {state.persona === "Elegir persona" || state.persona === null
          ? ""
          : dominioEvaluacion?.map((dominio, indexDom) => (
            <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
              <div className="flex flex-row justify-between mt-2">
                <h1 className="text-sm text-maivi-primaryFont">
                  DOMINIO {dominio.nombre}
                </h1>
                <Escalas />
              </div>
              <table className="table w-full border mb-3">
                <thead className="bg-maivi-gray-5 rounded-md">
                  <tr>
                    <th className={thClass}>Subdominio</th>
                    <th className={thClass}>Puntuación</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                  {subDominioEvaluacion?.map((sub, indexSub) => {
                    if (sub.dominio === dominio.id) {
                      return (
                        <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                          <td className={tdClass}>{sub.descripcion}</td>
                          <td className={tdClass}>
                            <tr align="right" className="hover:bg-maivi-gray-5 cursor-pointer flex justify-end">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {puntuaciones.map((item) => (
                                  <div
                                    key={item.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={item.value}
                                      type="radio"
                                      name={`${indexDom}-${indexSub}-puntuacion`}
                                      onChange={(e) => {
                                        onChangeChecks(e.target.value, sub);
                                      }}
                                      value={item.value}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
                                    />
                                    <label
                                      className={`ml-1 text-sm font-medium ${item.color} `}
                                    >
                                      {item.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </tr>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              <TextAreaSiavi
                label="Observaciones"
                value={dominio.observaciones}
                name={`observacion-${dominio.id}`}
                onChange={(e) => onChange(e.target.value, dominio)}
                placeholder="observaciones"
              />
              <Notas />
            </div>
          ))}

        {state.persona === "Elegir persona" || state.persona === null ? (
          ""
        ) : (
          <div className="col-span-12 xl:col-span-8 lg:col-span-8 border p-3 rounded-md shadow-sm hover:shadow-md">
            <h1 className="mb-4 xl:mb-1 lg:mb-1 md:mb-1 text-maivi-primaryFont">Resultado</h1>
            <Divider />
            <table className="table w-full border">
              <thead className="bg-maivi-gray-5 rounded-md">
                <tr>
                  <th className={thClass}>Descripción</th>
                  <th className={`${thClass} text-right`}>
                    Promedio
                  </th>
                </tr>
              </thead>
              <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                {dominioEvaluacion?.map((item, index) => (
                  <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                    <td className={tdClass}>{item.nombre}</td>
                    {Resultado(item.id)}
                  </tr>
                ))}

                <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                  <td className={`${tdClass} font-bold`}>PROMEDIO GENERAL</td>
                  <td className={`${tdClass} text-right`}>
                    <ResultadoFinal />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {
          (state.persona === null) ? "" :
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 border p-3 rounded-md shadow-sm hover:shadow-md">
              {error && (
                <div className="mb-2">
                  <Alert type="error" mensaje={mensaje} />
                </div>
              )}
              {cargando && (
                <div className="mb-2">
                  <Spinner />
                </div>
              )}
              <div className="mb-2">
                <TextAreaSiavi
                  name="observaciones_generales"
                  placeholder="observaciones"
                  onChange={handleChange}
                  value={values.observaciones_generales}
                />
              </div>
              {(CheckPermiso('OEDIGuardar') === true) &&    //mm permiso agregado       
                <ButtonSiavi
                  type="button"
                  onClick={async () => {
                    let faltaPuntuacion = false;
                    subDominioEvaluacion.forEach((element) => {
                      if (element.puntuacion === 0) {
                        faltaPuntuacion = true;
                      }
                    });

                    if (faltaPuntuacion) {
                      setError(true);
                      setMensaje(
                        "Debe de calificar todas las preguntas de todos los dominios"
                      );
                      setTimeout(() => {
                        setError(false);
                      }, 5000);
                    } else {
                      //setGuardar(true);
                      /*                 if (values.servicio === null || values.plan === "") {
                                        setAlertaTratamiento(true);
                                        setTimeout(() => {
                                          setAlertaTratamiento(false);
                                        }, 4000);
                                      } else { */
                      setCargando(true);
                      await postEvaluacionDiagnostico(idCasoPersona, idCaso, values.observaciones_generales, token)
                        .then(async (data) => {
                          let idEvaluacion;
                          let idEvaluacionDiagnostica;
                          if (data?.status === 200 || data?.status === 201) {
                            idEvaluacion = data.data.id;
                            dominioEvaluacion.forEach(async (d) => {
                              await postEvaluacionDiagnosticoDominio(
                                idEvaluacion,
                                d.id,
                                d.observaciones,
                                token
                              ).then((data) => {
                                setRefetch(true);
                                idEvaluacionDiagnostica = data.data.id;

                                subDominioEvaluacion.forEach(async (s) => {
                                  if (s.dominio === d.id) {
                                    await postEvaluacionDiagnosticaDetalle(
                                      s.puntuacion,
                                      idEvaluacionDiagnostica,
                                      s.dominio,
                                      s.id,
                                      token
                                    );
                                  }
                                });
                                setRefetch(false);
                                setState({
                                  ...state,
                                  persona: null,
                                });

                                values.observaciones_generales = "";

                              });
                            });

                            //await postPlanTratamientoPreliminar(values, idEvaluacion, token);
                            toast.success("Evaluaciones creadas con éxito..!");

                            setCargando(false);
                            setRefetch(true);
                            setGuardar(false);
                          } else {
                            toast.success("Evaluaciones creadas con éxito..!");
                          }
                        })
                        .catch(function (error) {
                          if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                          ) {
                            setCargando(false);
                            toast.error(error.response.data.message);
                          } else if (error.response.status >= 500) {
                            setCargando(false);
                            toast.error(
                              "Error interno comuniquese con el administrador"
                            );
                          }
                        });
                      //}
                    }
                  }}
                  text="Guardar"
                />
              }
            </div>
        }


      </div>
      {
        modalDetalle &&
        <ModalTwo
          title={`Detalle de evaluación`}
          open={modalDetalle}
          closeModal={() => { }}
          cerrar={() => {
            setModalDetalle(false);
          }}
          children={<DetalleEvaluacion evaluaciones={registroDetalle} thclassName={thClass} tdclassName={tdClass} />}
        />
      }
      {
        guardar &&
        <ModalGenerico
          title={`Plan tratamiento preliminar`}
          open={guardar}
          closeModal={() => { }}
          cerrar={() => {
            setGuardar(false);
          }}
          children={
            <div>
              <TratamientoPreliminar values={values} handleChange={handleChange} servicios={servicios?.results} />
              {
                alertaTratamiento && <div className=" mx-auto px-4 sm:px-6 md:px-8 mb-2">
                  <Alert type="error" mensaje="servicio y plan son requeridos" />
                </div>
              }
              <div className=" mx-auto px-4 sm:px-6 md:px-8">
          
                  <ButtonSiavi
                    type="submit"
                    text="Guardar"
                    onClick={async () => {
                      if (values.servicio === null || values.plan === "") {
                        setAlertaTratamiento(true);
                        setTimeout(() => {
                          setAlertaTratamiento(false);
                        }, 4000);
                      } else {
                        setCargando(true);
                        await postEvaluacionDiagnostico(idCasoPersona, idCaso, values.observaciones_generales, token)
                          .then(async (data) => {
                            let idEvaluacion;
                            let idEvaluacionDiagnostica;
                            if (data?.status === 200 || data?.status === 201) {
                              idEvaluacion = data.data.id;
                              dominioEvaluacion.forEach(async (d) => {
                                await postEvaluacionDiagnosticoDominio(
                                  idEvaluacion,
                                  d.id,
                                  d.observaciones,
                                  token
                                ).then((data) => {
                                  setRefetch(true);
                                  idEvaluacionDiagnostica = data.data.id;

                                  subDominioEvaluacion.forEach(async (s) => {
                                    if (s.dominio === d.id) {
                                      await postEvaluacionDiagnosticaDetalle(
                                        s.puntuacion,
                                        idEvaluacionDiagnostica,
                                        s.dominio,
                                        s.id,
                                        token
                                      );
                                    }
                                  });
                                  setRefetch(false);
                                  setState({
                                    ...state,
                                    persona: null,
                                  });

                                  values.observaciones_generales = "";

                                });
                              });

                              await postPlanTratamientoPreliminar(values, idEvaluacion, token);
                              toast.success("Evaluaciones creadas con éxito..!");

                              setCargando(false);
                              setRefetch(true);
                              setGuardar(false);
                            } else {
                              toast.success("Evaluaciones creadas con éxito..!");
                            }
                          })
                          .catch(function (error) {
                            if (
                              error.response.status >= 400 &&
                              error.response.status <= 450
                            ) {
                              setCargando(false);
                              toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                              setCargando(false);
                              toast.error(
                                "Error interno comuniquese con el administrador"
                              );
                            }
                          });
                      }
                    }}
                  />
                
              </div>
            </div>
          }
        />
      }

      {
        addPlanTratamiento &&
        <ModalAmplio
          title={`Agregar plan de tratamiento`}
          open={addPlanTratamiento}
          closeModal={() => { }}
          cerrar={() => {
            setAddPlanTratamiento(false);
          }}
          children={
            <AddTratamientoPreliminar
              values={values}
              handleChange={handleChange}
              servicios={servicios?.results}
              thClass={thClass}
              tdClass={tdClass}
              idEvaluacion={idEvaluacion}
            />
          }
        />
      }

      {
        imprimir &&
        <ModalAmplio
          title={`Imprimir`}
          open={imprimir}
          closeModal={() => { }}
          cerrar={() => {
            setImprimir(false);
          }}
          children={
            <PdfViewer
              data={imprimirData}
              reporte={"evaluacion-diagnostica"}
              style={{ height: "80vh" }}
            />
          }
        />
      }
    </form>
  );
};
