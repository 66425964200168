/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { SelectSiavi } from "../../../../../../components/common/Select";
import { InputSiavi } from "../../../../../../components/common/InputText";
import Spinner from "../../../../../../components/common/Spinner";

export default function Filtros(props) {
  let filtros = "";
  const [state, setState] = useState({
    fecha_desde:"",
    fecha_hasta: "",
    accion_seguimiento:""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="mx-4">
        <div className="col-span-12 mt-1">
         <SelectSiavi 
           name="accion_seguimiento"
           label="Tipo"
           value={state.accion_seguimiento}
           onChange={onChange}
           initValue=""
           selectInit="Elegir tipo"
           list={
             props.acciones?.map((item,index) =>{
               return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
             })
           }
         />
     </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha fin"
        />
      </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `${state.accion_seguimiento === "" ? "" : `accion_seguimiento=${state.accion_seguimiento}`}&${state.fecha_desde === "" ? "" : `fecha_accion__gte=${state.fecha_desde}`}&${state.fecha_hasta === "" ? "" : `fecha_accion__lte=${state.fecha_hasta}`}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
      </div>
  );
}
