/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";

const {
    REACT_APP_URL_REPORTES
} = process.env;

export default function PdfViewer(props) {
    const [urlPDF, setUrlPDF] = useState("");

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(props.data);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${REACT_APP_URL_REPORTES}/${props.reporte}`, requestOptions)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                setUrlPDF(url);
            })
            .catch(error => console.log('error', error));
    }, [props.data]);


    return (
        <div>
            {urlPDF !== "" &&
                <embed
                    src={`${urlPDF}#page=1&zoom=60`}
                    className={props.className}
                    width={props.width ? props.width : '100%'}
                    style={props.style}
                    type="application/pdf"
                />
            }
        </div>
    );
}
