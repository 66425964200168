import { React } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { InputSiavi } from "../common/InputText";
import { ButtonSiavi } from "../common/Button";
import { validarCodigo } from "./services";
import Spinner from "../common/Spinner";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  codigo: Yup.string()
    .required("codigo es requerido")
});

export const ValidarCodigo = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          codigo: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          await validarCodigo(props.registro.id, values, token)
            .then((data) => {
              if (data?.status === 200 || data?.status === 201) {
                toast.success("codigo validado con exito");
                props?.setGestionar(false);
                props?.setReFetch(true);
                setTimeout(() => {
                  props?.setReFetch(false);
                }, 300);
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 410
              ) {
                toast.error(error.response.data[0]);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <InputSiavi
              label="Codigo enviado"
              placeholder="ingrese el codigo enviado"
              name="codigo"
              value={values.codigo}
              onChange={handleChange}
              validacion={true}
            />
            <ButtonSiavi
              className="mt-3"
              type="button"
              text="Validar"
              onClick={handleSubmit}
            />
            {
              isSubmitting &&
              <div className="mt-4">
                <Spinner />
              </div>
            }
          </form>
        )}
      </Formik>
    </div>
  );
};
