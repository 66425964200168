/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
  getModalidades,
  getAccionesSeguimiento,
  getProfesionales 
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import makeAnimated from "react-select/animated";
import Select from "react-select";

export default function Filtros(props) {
  let filtros = "";
  const optionsProfesionales = [];
  const [modalidades, setModalidades] = useState([]);
  const [accionesSeguimiento, setAccionesSeguimiento] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const animatedComponents = makeAnimated();

  const [state, setState] = useState({
    profesional_completado: "",
    profesional_asignado: "",
    fecha_completada_inicia: "",
    fecha_completada_fin: "",
    fecha_planificada_inicia: "",
    fecha_planificada_fin: "",
    accion_seguimiento: "",
    modalidad: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getModalidades(props.token).then((data) => {
      setModalidades(data);
    })
  }, []);

  useEffect(() => {
    getAccionesSeguimiento(props.token).then((data) => {
      setAccionesSeguimiento(data);
    })
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item.first_name} ${item.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
  });

  const changeSelectProfesionalCompletado = (e) => {
    setState({
      ...state,
      profesional_completado: e.value,
    });
  };

  const changeSelectProfesionalAsignado = (e) => {
    setState({
      ...state,
      profesional_asignado: e.value,
    });
  };

  return (
    <div className="mx-4">
      <div className="col-span-12 mt-1">
        <Label text="Profesional completa" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesionalCompletado}
        />
      </div>
      <div className="col-span-12 mt-1">
        <Label text="Profesional asignado" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesionalAsignado}
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="accion_seguimiento"
          label="Tipo acción seguimiento"
          value={state.accion_seguimiento}
          onChange={onChange}
          initValue=""
          selectInit="Elegir acción seguimiento"
          list={
            accionesSeguimiento?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-3 mb-2">
        <SelectSiavi
          name="modalidad"
          label="Modalidad"
          value={state.modalidad}
          onChange={onChange}
          initValue=""
          selectInit="Elegir modalidad"
          list={
            modalidades?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
            })
          }
        />
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <Label text="Fecha completada" />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_completada_inicia"
            value={state.fecha_completada_inicia}
            onChange={onChange}
            label="Inicio"
          />
        </div>
        <div className="col-span-6 mb-3">
          <InputSiavi
            type="date"
            name="fecha_completada_fin"
            value={state.fecha_completada_fin}
            onChange={onChange}
            label="Fin"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <Label text="Fecha planificada" />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="datetime-local"
            name="fecha_planificada_inicia"
            value={state.fecha_planificada_inicia}
            onChange={onChange}
            label="Inicio"
          />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="datetime-local"
            name="fecha_planificada_fin"
            value={state.fecha_planificada_fin}
            onChange={onChange}
            label="Fin"
          />
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `&profesional_asignado=${state.profesional_asignado}&profesional_autoriza=${state.profesional_completado}&modalidad=${state.modalidad}&accion_seguimiento=${state.accion_seguimiento}&fecha_hora_planificada_inicia__gte=${state.fecha_planificada_inicia}&fecha_hora_planificada_inicia__lte=${state.fecha_planificada_fin}&fecha_completado__gte=${state.fecha_completada_inicia}&fecha_completado__lte=${state.fecha_completada_fin}`;
            props.setUrlFech(undefined);
            props.setFiltros(filtros);
            props.setFiltrar(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}


//http://api.app.institutodelavictima.gob.gt/dev/server/api/registro/accionesseguimientoscaso/?expediente=&es_accion=unknown&es_seguimiento=unknown&nombramiento=&es_audiencia=unknown&completada=unknown&fecha_completado=&tipo_audiencia=&accion_seguimiento=&modalidad=&estado=unknown&sede_accion=&caso=&persona=&fecha_hora_planificada_inicia=&fecha_hora_planificada_fin=&fecha_hora_inicia=&fecha_hora_fin=&profesional_crea=&profesional_autoriza=&profesional_asignado=74&gestion_institucion=&requiere_asignacion=unknown&gestionar=unknown&accion_seguimiento__es_procuracion=unknown&recurso_disponibilidad=&recurso=&accion_seguimiento__atencion=&accion_seguimiento__accion=unknown&fecha_completado__gte=&fecha_completado__lte=&fecha_accion__gte=&fecha_accion__lte=&fecha_hora_planificada_inicia__lte=&fecha_hora_planificada_inicia__gte=&fecha_hora_planificada_fin__lte=&fecha_hora_planificada_fin__gte=&fecha_hora_inicia__lte=&fecha_hora_inicia__gte=&fecha_hora_fin__lte=&fecha_hora_fin__gte=&profesional_asignado__isnull=unknown&accion_seguimiento__isnull=unknown&expediente__isnull=unknown&caso__isnull=unknown&accion_seguimiento_atencion__isnull=unknown&accion_seguimiento_accion__isnull=unknown

