/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { store } from "../../../../../app/redux/store/store";
import { ChangeOrientacionTab } from "../../../../../app/redux/actions/orientacionTab";
import { InputSiavi } from "../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { SelectSiavi } from "../../../../../components/common/Select";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { useSelector } from "react-redux";
import { ErrorMessage } from "formik";
import {
  getInstituciones,
  getMotivosNoAdmisibilidad,
  getMotivosAdmisibilidad,
  getGestionInstituciones,
  getTipoPersonas,
  getDelitos,
  getTipoDoctos,
  getTipoConsulta,
  getProfesionales
} from "../services";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Divider from "../../../../../components/common/Divider";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import Label from "../../../../../components/common/Label";
import ModalGenerico from "../../../../../components/common/Modal";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";

export const Orientacion = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  hasError,
  success,
  mensaje,
  setFieldValue,
  historica,
  anio
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const animatedComponents = makeAnimated();
  const fechaActual = new Date();
  const [motivosNoAdmisibilidad, setmotivosNoAdmisibilidad] = useState([]);
  const [delitosAgregados, setDelitosAgregados] = useState([]);
  const [tipoConsultaAgregados, setTipoConsultaAgregados] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [noAdmisibilidad, setNoAdmisibilidad] = useState([]);
  const [admisibilidad, setAdmisibilidad] = useState([]);
  const [gestionInstituciones, setGestionInstituciones] = useState([]);
  const [tipoPersonas, setTipoPersonas] = useState([]);
  const [delitos, setDelitos] = useState([]);
  const [pregunta, setPregunta] = useState(false);
  const [bloquear, setBloquear] = useState(false);
  const [tipodoctos, setTipodoctos] = useState([]);
  const [tipoconsulta, setTipoconsulta] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const [profesionalSelecciondo, setProfesionalSeleccionado] = useState(null);
  const [validacionProfesional, setValidacionProfesional] = useState(false);
  const optionsAdmisibiliad = [];
  const optionsNoAdmisibilidad = [];
  const optionsDelitos = [];
  const optionsConsultas = [];
  const optionsInstituciones = [];
  const optiosnProfesionales = [];

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element, index) => {
        element.active = false;
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };


  useEffect(() => {
    changeTab(0);
    getInstituciones(token).then((data) => {
      setInstituciones(data);
    });
  }, []);

  useEffect(() => {
    getGestionInstituciones(token).then((data) => {
      setGestionInstituciones(data);
    });
  }, []);

  useEffect(() => {
    getMotivosNoAdmisibilidad(token).then((data) => {
      setNoAdmisibilidad(data);
    });
  }, []);

  useEffect(() => {
    getMotivosAdmisibilidad(token).then((data) => {
      setAdmisibilidad(data);
    });
  }, []);

  useEffect(() => {
    getTipoPersonas(token).then((data) => {
      setTipoPersonas(data);
    });
  }, []);

  useEffect(() => {
    getDelitos(token).then((data) => {
      setDelitos(data);
    });
  }, []);

  useEffect(() => {
    getTipoDoctos(token).then((data) => {
      setTipodoctos(data);
    });
  }, []);

  useEffect(() => {
    getTipoConsulta(token).then((data) => {
      setTipoconsulta(data);
    });
  }, []);

  useEffect(() => {
    getProfesionales(sede, token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  const changeSelectNoAdmisibilidad = (e) => {
    setmotivosNoAdmisibilidad(e);
  };

  const changeSelectDelitos = (e) => {
    setDelitosAgregados(e);
  };

  const changeSelectTipoConsulta = (e) => {
    setTipoConsultaAgregados(e);
  };

  const changeSelectInstitucion = (e) => {
    setFieldValue('institucionDenuncia', e.value);
  };

  const changeSelectProfesional = (e) => {
    setProfesionalSeleccionado(e.value);
  };

  admisibilidad?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsAdmisibiliad.push(reg);
  });

  noAdmisibilidad?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsNoAdmisibilidad.push(reg);
  });

  delitos?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsDelitos.push(reg);
  });

  tipoconsulta?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsConsultas.push(reg);
  });

  gestionInstituciones?.results?.filter(x => x.institucion_detalle.denuncia_valida === true).forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre_fiscalia,
    };
    optionsInstituciones.push(reg);
  });

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name}`,
    };
    optiosnProfesionales.push(reg);
  });

  return (
    <form
      autoComplete="off"
      onChange={customChange(values)}
      className="-mt-2 mb-20"
    >
      <div className="flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-col mt-5 justify-start items-center">
        <div className="mb-3 xl:mb-0 lg:mb-0 md:mb-0">
          <span className="text-maivi-primaryFont text-sm">PERSONA:</span>
          <span className="ml-2 text-maivi-primaryFont bg-green-200 p-1 rounded-lg text-sm">
            {values.nombre}
          </span>
        </div>
        <div className="ml-10 mb-3 xl:mb-0 lg:mb-0 md:mb-0 flex flex-row items-center">
          <span className="text-maivi-primaryFont text-xs">
            COMO LE GUSTA QUE LE LLAMEN:
          </span>
          <InputSiavi
            name="como_le_gusta_que_le_llamen"
            value={values.como_le_gusta_que_le_llamen}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-row ml-10 justify-center items-center">
          <span className="text-maivi-primaryFont text-xs">TIPO PERSONA:</span>
          <div className="flex flex-col">
            <SelectSiavi
              validacion={true}
              id="tipo_persona"
              name="tipo_persona"
              value={values.tipo_persona}
              valueInit=""
              selectInit="Tipo persona"
              onChange={(e) => {
                setFieldValue('tipo_persona', e.target.value);
                let combo = document.getElementById("tipo_persona");
                let text = combo.options[combo.selectedIndex].text;
                setFieldValue('nombre_tipo_persona', text);

                if (text === "VICTIMA") {
                  setFieldValue("victima", true);
                  setPregunta(true);
                  setBloquear(false);
                } else if (text === "QUERELLANTE") {
                  setFieldValue("querellante", true);
                }
                else if (text === "NO APLICA") {
                  setBloquear(true);
                } else {
                  setBloquear(false);
                }
              }}
              list={
                tipoPersonas?.results?.map((item) => {
                  return (
                    <option key={item.nombre} value={item.id} className="text-sm">
                      {item.nombre}
                    </option>
                  );
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-3">
        <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <div className="flex flex-row justify-between">
            <h1 className="mb-1 text-base">Denuncia</h1>
            <div className="flex flex-row border border-maivi-primaryLightBlue rounded-md p-2 items-center -mt-1 mb-2">
              <input
                name="presentoDenuncia"
                type="checkbox"
                checked={values.presentoDenuncia}
                onChange={handleChange}
                className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
              />
              <Label text="Presento denuncia" />
            </div>
          </div>
          <Divider />
          <div className="grid grid-cols-12 items-end gap-2">
            {values.presentoDenuncia && (
              <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 ">
                <InputSiavi
                  type="date"
                  name="fechaDenuncia"
                  value={values.fechaDenuncia}
                  onChange={handleChange}
                  maximo={bloqueoFecha()}
                  validacion={true}
                  label="Fecha denuncia"
                />
              </div>
            )}
            {values.presentoDenuncia && (
              <div className="lg:col-span-12 xl:col-span-6 md:col-span-12 col-span-12">
                <InputSiavi
                  type="text"
                  name="numeroDenuncia"
                  value={values.numeroDenuncia}
                  onChange={handleChange}
                  validacion={true}
                  label="No. Denuncia"
                />
              </div>
            )}
            {values.presentoDenuncia && (
              <div className="lg:col-span-12 xl:col-span-6 md:col-span-12 col-span-12">
                <Label text="Institución donde la presento" />
                <Select
                  placeholder="Seleccionar"
                  isMulti={false}
                  isSearchable={true}
                  name="institucionDenuncia"
                  className="basic-multi-select text-xs text-maivi-primaryFont"
                  options={optionsInstituciones}
                  onChange={changeSelectInstitucion}
                />
                <ErrorMessage
                  name="institucionDenuncia"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed ml-2"
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <h1 className="mb-1 text-base">Derivación</h1>
          <Divider />
          <div className="grid grid-cols-12 gap-2 items-end">
            <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
              <SelectSiavi
                label="Entidad que deriva"
                name="entidad_deriva"
                value={values.entidad_deriva}
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar"
                list={
                  instituciones?.results?.map((item) => {
                    return (
                      <option
                        key={item.nombre}
                        value={item.id}
                      >
                        {item.nombre}
                      </option>
                    );
                  })
                }
              />
            </div>
            <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
              <SelectSiavi
                label="Tipo documento derivación"
                name="tipoDocto"
                value={values.tipoDocto}
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar"
                list={
                  tipodoctos?.results?.map((item) => {
                    return (
                      <option
                        key={item.nombre}
                        value={item.id}
                      >
                        {item.nombre}
                      </option>
                    );
                  })
                }
              />
            </div>
            <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
              <InputSiavi
                type="text"
                name="numeroDocumento"
                value={values.numeroDocumento}
                onChange={handleChange}
                label="No. documento"
              />
            </div>
            <div className="lg:col-span-12 xl:col-span-6 sm:col-span-12 md:col-span-12 col-span-12">
              <InputSiavi
                type="date"
                name="fechaResolucion"
                value={values.fechaResolucion}
                onChange={handleChange}
                label="Fecha documento"
                maximo={`${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-${fechaActual.getDate()}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-12 xl:col-span-5 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <h1 className="mb-1 text-sm text-maivi-primaryFont">Tipo de orientación</h1>
          <Select
            isMulti
            placeholder="Seleccionar"
            components={animatedComponents}
            name="tipo_consultas"
            className="basic-multi-select text-xs text-maivi-primaryFont"
            classNamePrefix="seleccione"
            options={optionsConsultas}
            onChange={changeSelectTipoConsulta}
          />
          <h1 className="mb-1 text-sm text-maivi-primaryFont mt-2">Delitos</h1>
          <Select
            isMulti
            placeholder="Seleccionar"
            components={animatedComponents}
            name="delitos"
            className="basic-multi-select text-xs text-maivi-primaryFont"
            classNamePrefix="seleccione"
            options={optionsDelitos}
            onChange={changeSelectDelitos}
          />
          <h1 className="mb-1 text-sm text-maivi-primaryFont mt-2">Observaciones</h1>
          <TextAreaSiavi
            placeholder="observaciones generales"
            cols={50}
            rows={2}
            value={values.observaciones}
            name="observaciones"
            onChange={handleChange}
          />
        </div>
        <div className="col-span-12 xl:col-span-5 lg:col-span-6 md:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <div className="grid grid-cols-12 items-end gap-3">
            <div className="col-span-12 flex flex-row justify-start items-center">
              <span>¿Es admisible?</span>
              <div className={`flex flex-row  border border-maivi-primaryLightBlue rounded-md p-2 mx-2 ${(bloquear) ? 'bg-red-200' : ''}`} >
                <input
                  disabled={bloquear}
                  name="casoAdmitido"
                  type="checkbox"
                  checked={values.casoAdmitido}
                  onChange={(e) => {
                    setFieldValue('casoAdmitido', e.target.checked);
                    if (e.target.checked === true) {
                      setFieldValue('casoNoAdmitido', false);
                      setFieldValue('valorCaso', true);
                    } else if (e.target.checked === false && values.casoNoAdmitido === false) {
                      setFieldValue('valorCaso', null);
                    }
                  }}
                  className={`bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2`}
                />
                <Label text="SI" />
              </div>
              <div className="flex flex-row border border-maivi-primaryLightBlue rounded-md p-2 items-center">
                <input
                  name="casoNoAdmitido"
                  type="checkbox"
                  checked={values.casoNoAdmitido}
                  onChange={(e) => {
                    setFieldValue('casoNoAdmitido', e.target.checked);
                    if (e.target.checked === true) {
                      setFieldValue('casoAdmitido', false);
                      setFieldValue('valorCaso', false);
                    } else if (e.target.checked === false && values.casoAdmitido === false) {
                      setFieldValue('valorCaso', null);
                    }
                  }}
                  className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                />
                <Label text="No" />
              </div>
            </div>
            <div className="col-span-12">
              <Label
                text="Motivo no admisibilidad"
                className={values.casoNoAdmitido === false ? "hidden" : ""}
              />
              <Select
                placeholder="Seleccionar"
                components={animatedComponents}
                isMulti
                name="noadmisibilidad"
                className={`basic-multi-select text-xs text-maivi-primaryFont ${values.casoNoAdmitido === false ? "hidden" : ""
                  }`}
                classNamePrefix="seleccione"
                options={optionsNoAdmisibilidad}
                onChange={changeSelectNoAdmisibilidad}
              />
            </div>
            <div className="col-span-12">
              <TextAreaSiavi
                label="Motivo Consulta"
                cols={50}
                rows={2}
                value={values.comentarios}
                name="comentarios"
                onChange={handleChange}
              />
            </div>
            <div className="col-span-12">
              <Label text="Profesional que acompaña la orientación" />
              <Select
                isMulti={false}
                placeholder="Seleccionar"
                components={animatedComponents}
                name="profesional"
                className="basic-multi-select text-xs text-maivi-primaryFont"
                classNamePrefix="seleccione"
                options={optiosnProfesionales}
                onChange={changeSelectProfesional}
              />
            </div>
            {historica === null
              ?
              ""
              :
              <div className="col-span-12">
                <InputSiavi
                  type="date"
                  name="fecha"
                  minimo={anio + `-01-01`}
                  maximo={anio + `-12-31`}
                  value={values.fecha}
                  onChange={handleChange}
                  label="Fecha Expediente*"
                />
              </div>
            }
            {historica === null
              ?
              ""
              :
              <div className="col-span-12">
                <InputSiavi
                  type="text"
                  name="iv_historico"
                  value={values.iv_historico}
                  onChange={handleChange}
                  label="Histórico IV*"
                />
              </div>
            }
            <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6"></div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
              {
                isSubmitting ?
                <Spinner /> :
                <ButtonSiavi
                type="button"
                onClick={() => {
                  if (profesionalSelecciondo === null) {
                    setValidacionProfesional(true);
                  } else {
                    values.motivosAdmisibilidad = [];
                    values.motivosNoAdmisibilidad = motivosNoAdmisibilidad;
                    values.delitosAgregados = delitosAgregados;
                    values.tipoConsultaAgregados = tipoConsultaAgregados;
                    values.profesional_acompania = profesionalSelecciondo;
                    if (values.casoAdmitido && delitosAgregados.length === 0) {
                      toast.error("Delitos son requeridos ");
                    } else if (values.casoNoAdmitido === true && motivosNoAdmisibilidad.length === 0) {
                      toast.error("Motivos de no admisibilidad son requeridos");
                    } else if (values.fecha === undefined && historica !== null) {
                      toast.error("Fecha Expediente es requerida");
                    } else if (values.iv_historico === "" && historica !== null)
                      toast.error("Histórico es requerido");
                    else {
                      handleSubmit();
                    }
                  }
                }}
                disabled={isSubmitting}
                text="Guardar y continuar"
              />
              }
             
            </div>
            <div className="col-span-12">
              {/* {isSubmitting && (
                <div className="mt-5 mb-5">
                  <Spinner />
                </div>
              )} */}
              {success && <Alert type="success" mensaje={mensaje} />}
              {hasError && <Alert type="error" mensaje={mensaje} />}
            </div>
          </div>
        </div>
        {pregunta && (
          <ModalGenerico
            title={`¿La víctima tambien es el querellante?`}
            open={pregunta}
            closeModal={() => { }}
            cerrar={() => {
              setPregunta(false);
            }}
            children={
              <BotonesConfirmar
                aceptar={() => {
                  setFieldValue('querellante', true);
                  setPregunta(false);
                }}
                cancelar={() => {
                  setPregunta(false);
                }}
              />
            }
          />
        )}
        {validacionProfesional && (
          <ModalGenerico
            title={`No seleccionó a ningun profesional que lo acompaña en esta orientación. ¿Desea Continuar?`}
            open={validacionProfesional}
            closeModal={() => { }}
            cerrar={() => {
              setValidacionProfesional(false);
            }}
            children={
              <BotonesConfirmar
                aceptar={() => {
                  setValidacionProfesional(false);
                  values.motivosAdmisibilidad = [];
                  values.motivosNoAdmisibilidad = motivosNoAdmisibilidad;
                  values.delitosAgregados = delitosAgregados;
                  values.tipoConsultaAgregados = tipoConsultaAgregados;
                  values.profesional_acompania = profesionalSelecciondo;
                  if (values.casoAdmitido && delitosAgregados.length === 0) {
                    toast.error("Delitos son requeridos ");
                  } else if (values.casoNoAdmitido === true && motivosNoAdmisibilidad.length === 0) {
                    toast.error("Motivos de no admisibilidad son requeridos");
                  }

                  else {
                    handleSubmit();
                  }
                }}
                cancelar={() => {
                  setValidacionProfesional(false);
                }}
              />
            }
          />
        )}
      </div>
    </form>
  );
};
