import { React, useState } from "react";
import { InputSiavi } from "../../../../../components/common/InputText";
import { getSindicados,getPersonas } from "./services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import moment from "moment";

const thClass = "px-2 py-1 text-left font-medium text-maivi-primaryFont tracking-wider text-xs";

export const BuscarSindicado = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargando, setCargando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(false);
  const [personas, setPersonas] = useState([]);
 
  return (
    <div className="grid grid-cols-12 gap-2 items-end -mt-3">
      <div className="col-span-12 xl:col-span-5 lg:col-span-8 md:col-span-8 sm:col-span-8">
        <InputSiavi
          type="text"
          name="busqueda"
          value={props.values.busqueda}
          onChange={props.handleChange}
          placeholder="buscar sindicado"
          className="uppercase"
        />
      </div>
      <div className="col-span-6 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2">
        <ButtonSiavi
          type="button"
          text="Buscar"
          onClick={async () => {
            if (props.values.busqueda.length > 2) {
              setCargando(true);
              await getSindicados(props.values.busqueda, token).then(async (data) => {
                setPersonas([]);
                await getPersonas(props.values.busqueda, token).then((data2) =>{
                  if (data.results.length === 0 && data2.results.length === 0) {
                    setError(true);
                    setMensaje("No se encontró ningún registro");
                  } else {
                    let busquedas = [...data.results, ...data2.results]
                    setPersonas(busquedas);
                  }
                })
                
              });
              setCargando(false);
            } else {
              if (props.values.busqueda === "") {
                setError(true);
                setMensaje("El campo búsqueda no puede estar vacío");
              } else {
                setError(true);
                setMensaje(
                  "Debe ingresar una búsqueda con un mínimo de 3 caracteres"
                );
              }
            }

            setTimeout(() => {
              setError(false);
            }, 4000);
          }}
        />
      </div>
      <div className="col-span-6 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2">
        <ButtonSiavi
          type="button"
          text="Limpiar"
          onClick={async () => {
            setPersonas([]);
            props.resetForm({});
            props.setNuevo(false);
          }}
        />
      </div>
      <div className="col-span-12 mb-5 overflow-x-auto max-h-40 mt-1">
        {cargando && (
          <div className="m-2">
            <Spinner />
          </div>
        )}
        {personas?.length > 0 ? (
          <table className="table w-full overflow-x-auto">
            <thead className="bg-maivi-gray-5">
              <tr>
                <th scope="col" className={thClass}>
                  Tipo
                </th>
                <th scope="col" className={thClass}>
                  Nombre
                </th>
                <th scope="col" className={thClass}>
                  Pseudónimo
                </th>
                <th scope="col" className={thClass}>
                  Fecha nacimimiento
                </th>
                <th scope="col" className={thClass}>
                  Identificación
                </th>
              </tr>
            </thead>
            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
              {personas?.map((item) => (
                <tr
                  id={item.primer_nombre+item.fecha_nacimiento+item.id}
                  key={item.primer_nombre + item.fecha_nacimiento + item.id}
                  className="cursor-pointer "
                  onClick={()=>{
                    let row;
                    personas.forEach((element) => {
                      let row = document.getElementById(element.primer_nombre+element.fecha_nacimiento+element.id);
                      row.classList.remove("bg-green-50");
                    });
                    row = document.getElementById(item.primer_nombre+item.fecha_nacimiento+item.id);
                    row.classList.add("bg-green-50");
                    props.setNuevo(true);

                    if(item?.no_documento === undefined){
                      props.setFieldValue('actualizarSindicado',true);

                      props.setFieldValue('primer_nombre',item?.primer_nombre);
                      props.setFieldValue('segundo_nombre',item.segundo_nombre);
                      props.setFieldValue('otros_nombres',item.otros_nombres);
                      props.setFieldValue('primer_apellido',item.primer_apellido);
                      props.setFieldValue('segundo_apellido',item.segundo_apellido);
                      props.setFieldValue('apellido_casada',item.apellido_casada);
                      props.setFieldValue('fecha_nacimiento',item.fecha_nacimiento);
                      props.setFieldValue('nacionalidad',item?.nacionalidad?.id);
                      props.setFieldValue('edad',item?.edad);
                      props.setFieldValue('pseudonimo',item?.pseudonimo);
                      props.setFieldValue('sexo',item?.sexo);

                      props.setFieldValue('pertenece_a_pandilla',item?.pertenece_a_pandilla);
                      props.setFieldValue('nombre_pandilla',item?.nombre_pandilla);
                      props.setFieldValue('tiene_tatuajes',item?.tiene_tatuajes);
                      props.setFieldValue('especificacion_tatuaje',item?.especificacion_tatuaje);
                      props.setFieldValue('uso_de_arma_de_fuego',item?.uso_de_arma_de_fuego);
                      props.setFieldValue('altura',item?.altura);
                      props.setFieldValue('peso',item?.peso);
                      props.setFieldValue('complexion',item?.complexion);
                      props.setFieldValue('color_forma_cabello',item?.color_forma_cabello);
                      props.setFieldValue('color_tez',item?.color_tez);
                      props.setFieldValue('tiene_cicatrices',item?.tiene_cicatrices);
                      props.setFieldValue('tiene_barba',item?.tiene_barba);
                      props.setFieldValue('tiene_bigote',item?.tiene_bigote);
                      props.setFieldValue('forma_de_cejas',item?.forma_de_cejas);
                      props.setFieldValue('color_ojos',item?.color_ojos);
                      props.setFieldValue('usa_anteojos',item?.usa_anteojos);
                      props.setFieldValue('forma_de_nariz',item?.forma_de_nariz);
                      props.setFieldValue('caracteristicas_dentadura',item?.caracteristicas_dentadura);
                      props.setFieldValue('vestimenta',item?.vestimenta);
  
                      props.setFieldValue('estado_civil',item?.estado_civil?.id);
                      props.setFieldValue('orientacion_sexual',item?.orientacion_sexual?.id);
                      props.setFieldValue('identidad_genero',item?.identidad_genero?.id);
                      props.setFieldValue('departamento',item?.departamento?.id);
                      props.setFieldValue('municipio',item?.municipio?.id);
                      props.setFieldValue('lee_escribe',item?.lee_escribe);
                      props.setFieldValue('escolaridad',item?.escolaridad?.id);
                      props.setFieldValue('profesion',item?.profesion?.id);
                      props.setFieldValue("situacion_laboral", item?.situacion_laboral?.id);
                      props.setFieldValue('religion',item?.religion?.id);
                      props.setFieldValue('origen_etnico',item?.origen_etnico?.id);
                      props.setFieldValue('idioma',item?.idioma?.id);
                      props.setFieldValue('otro_idioma',item?.otro_idioma);
                      props.setFieldValue('en_migracion',item?.en_migracion);
                      props.setFieldValue('pais', item?.pais?.id);
                      props.setFieldValue('pais_origen', item?.pais_origen?.id);
                      props.setFieldValue('pais_destino',item?.pais_destino?.id);
                      props.setFieldValue('relacion_con_la_victima',item?.relacion_con_la_victima?.id);
                      props.setFieldValue('referencia_ubicacion',item?.referencia_ubicacion);
                      props.setFieldValue('observaciones',item?.observaciones);
                      props.setFieldValue('correo_electronico',item?.correo_electronico)
  
                      props.setFieldValue('conoce_al_sindicado',item?.conoce_al_sindicado);
                      props.setFieldValue('tiene_identificacion',item?.tiene_identificacion);
                      props.setFieldValue('no_identificacion',item?.no_identificacion);
                      props.setFieldValue('tipo_identificacion',item?.tipo_identificacion?.id);
                      props.setFieldValue('idSindicado',item?.id);

                    } else {
                      props.setFieldValue('actualizarSindicado',false);

                      props.setFieldValue('conoce_al_sindicado', true);
                      props.setFieldValue('tiene_identificacion', true);
                      props.setFieldValue('no_identificacion',item?.no_documento);
                      props.setFieldValue('tipo_identificacion',item?.tipo_documento);
                      props.setFieldValue('pais', item?.pais?.id)

                      props.setFieldValue('primer_nombre',item?.primer_nombre);
                      props.setFieldValue('segundo_nombre',item?.segundo_nombre);
                      props.setFieldValue('tercer_nombre',item?.tercer_nombre);
                      props.setFieldValue('primer_apellido',item?.primer_apellido);
                      props.setFieldValue('segundo_apellido',item?.segundo_apellido);
                      props.setFieldValue('fecha_nacimiento',item?.fecha_nacimiento);
                      props.setFieldValue('nacionalidad',item?.nacionalidad);
                      props.setFieldValue('edad',item?.edad);
                      props.setFieldValue('pseudonimo',item?.como_le_gusta_que_le_llamen);
                      props.setFieldValue('sexo',item?.sexo);

                      props.setFieldValue("nacionalidad", item?.nacionalidad || "");
                      props.setFieldValue("estado_civil", item?.estado_civil || "");
                      props.setFieldValue("departamento", item?.departamento || "");
                      props.setFieldValue("municipio", item?.municipio || "");
                      props.setFieldValue("idioma", item?.idioma || "");
                      props.setFieldValue("identidad_genero", item?.identidad_genero || "");
                      props.setFieldValue("idioma_preferencia", item?.idioma_preferencia?.id || "");
                      props.setFieldValue("situacion_laboral", item?.situacion_laboral || "");
                      props.setFieldValue("orientacion_sexual", item?.orientacion_sexual || "");
                      props.setFieldValue("escolaridad", item?.escolaridad || "");
                      props.setFieldValue("profesion", item?.profesion || "");
                      props.setFieldValue("religion", item?.religion || "");
                      props.setFieldValue("origen_etnico", item?.origen_etnico?.id || "");
                      props.setFieldValue("otro_idioma", item?.otro_idioma || "") ;
                      props.setFieldValue("idioma_maya", item?.idioma_maya || "");
                      props.setFieldValue("origen_etnico_maya", item?.origen_etnico_maya || "");
                      props.setFieldValue("en_migracion", item?.en_migracion || false);
                      props.setFieldValue("pais_origen", item?.pais_origen || "");
                      props.setFieldValue("pais_destino", item?.pais_destino || "");
                      props.setFieldValue("relacion_con_victimario", item?.relacion_con_victimario || "");
                    }
                  }}
                >
                   <td className="px-2 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {(item?.no_documento === undefined) ? 'Sindicado' : 'Persona'}
                    </div>
                  </td>
                  <td className="px-2 py-1 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {item.primer_nombre} {item.segundo_nombre}{" "}
                      {item.tercer_nombre} {item.primer_apellido}{" "}
                      {item.segundo_apellido}
                    </div>
                  </td>
                  <td className="px-2 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {item.pseudonimo}
                    </div>
                  </td>
                  <td className="px-2 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {item.fecha_nacimiento ? moment(item.fecha_nacimiento).format("DD-MM-YYYY") : ""}
                    </div>
                  </td>
                  <td className="px-2 row-td">
                    <div className="text-xs text-maivi-primaryFont">
                      {(item?.no_documento === undefined) ? item.no_identificacion : item.no_documento}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : ""}
        {error && <Alert type="error" mensaje={mensaje} />}
      </div>
    </div>
  );
};
