import { React, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";

export default function Filtros(props) {
  let filtros = "";

  const [state, setState] = useState({
    estado: "",
    asitencia_legal: "",
    atencion_victimologica: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
      <div className=" max-w-lg">
        <div className="col-span-12 mt-4">
          <Label text="Estado" />
          <div className="mt-1">
            <select
              name="estado"
              value={state.estado}
              onChange={onChange}
              className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
            >
              <option value="">Elegir estado</option>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <Label text="Asistencia Legal" />
          <div className="mt-1">
            <select
              name="asitencia_legal"
              value={state.asitencia_legal}
              onChange={onChange}
              className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
            >
              <option value="">Elegir asistencia legal</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <Label text="Atención victimológica" />
          <div className="mt-1">
            <select
              name="atencion_victimologica"
              value={state.atencion_victimologica}
              onChange={onChange}
              className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
            >
              <option value="">Elegir atención victimológica</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `estado=${state.estado}&asitencia_legal=${state.asitencia_legal}&atencion_victimologica=${state.atencion_victimologica}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
      </div>
    </div>
  );
}
