/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { DocumentAddIcon } from "@heroicons/react/solid";
import {
  getPersonasCaso,
  getNivelRiesgos,
  getTipoRiesgos,
  getEvaluaciones,
  getTipoRiesgosPersona
} from "../services";
import { useSelector } from "react-redux";
import { Evaluacion } from "./evaluacion";
import ModalGenerico from "../../../../../components/common/Modal";
import TableSiavi from "../../../../../components/common/TableSiavi";

export const Riesgos = ({
  values,
  customChange,
  caso,
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idCaso = caso.id;
  const [cargando, setCargado] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [modal, setModal] = useState(false);
  const [idPersona, setIdPersona] = useState(0);
  const [personasCaso, setPersonasCaso] = useState({});
  const [nivelesRiesgos, setNivelesRiesgos] = useState({});
  const [tipoRiesgos, setTipoRiesgos] = useState({});
  const [evaluaciones, setEvaluaciones] = useState(idCaso, token, refetch);
  const [riesgosPersonas, setRiesgosPersonas] = useState([]);
  const [nombrePersona, setNombrePersona] = useState("");
  const [nombrePersonaModal, setNombrePersonaModal] = useState("");

  const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro
  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  useEffect(() => {
    getTipoRiesgos(token).then((data) => {
      setTipoRiesgos(data)
    });
  }, []);

  useEffect(() => {
    getNivelRiesgos(token).then((data) => {
      setNivelesRiesgos(data);
    });
  }, [token]);

  useEffect(() => {
    setCargado(true);
    getPersonasCaso(idCaso, token).then((data) => {
      setPersonasCaso(data)
      setCargado(false);
    });
  }, [idCaso, token]);

  useEffect(() => {
    getEvaluaciones(idCaso, token).then((data) => {
      setEvaluaciones(data);
    });
  }, [idCaso, token, refetch]);

  return (
    <form autoComplete="off" onChange={customChange(values)}>
      <div className="grid grid-cols-12 gap-3 mt-2">
        <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <div className="flex flex-row justify-between">
            <h1 className="mb-1 text-sm">Personas asociadas al caso</h1>
          </div>
          <TableSiavi
            cargando={cargando}
            headers={[
              'Nombre',
              'No. Identificación',
              'Fecha nacimiento',
              'Evaluación'
            ]}
            body={personasCaso?.results?.filter(x => (x?.victima || x?.es_victima_indirecta) && x?.persona?.victima_fallecida === false ).map((item) => (
              <tr
                key={
                  item?.persona.primer_nombre +
                  item?.persona?.fecha_nacimiento
                }
                className="hover:bg-maivi-gray-5 cursor-pointer"
              >
                <td className={tdClass}>
                  {item?.persona?.primer_nombre}{" "}
                  {item?.persona?.segundo_nombre}{" "}
                  {item?.persona?.primer_apellido}{" "}
                  {item?.persona?.segundo_apellido}
                </td>
                <td className={tdClass}>{item?.persona?.no_documento}</td>
                <td className={tdClass}>
                  {item?.persona.fecha_nacimiento}
                </td>
                <td className={tdClass}>
                {(CheckPermiso('OAgregariesgo') === true) &&    //mm permiso agregado        
                  <button
                    type="button"
                    onClick={() => {
                      setNombrePersonaModal(`${item?.persona?.primer_nombre} ${item?.persona?.primer_apellido}`)
                      setIdPersona(item.persona.id);
                      setModal(true);
                    }}
                    className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                      rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                  >
                    <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                  </button>
            }
                </td>
              </tr>
            ))}
          />
        </div>
        <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
          <h1 className="mb-1 text-sm">Evaluaciones de riesgo</h1>
          <TableSiavi
            cargando={cargando}
            headers={[
              'Nombre',
              'Identificación',
              'Nivel riesgo'
            ]}
            body={evaluaciones?.results?.map((item) => (
              <tr
                key={'ev' + item?.persona?.primer_nombre + item.id}
                className="hover:bg-maivi-gray-5 cursor-pointer"
                onClick={() => {
                  setNombrePersona(`${item?.persona?.primer_nombre} ${item?.persona?.primer_apellido}`)
                  getTipoRiesgosPersona(item.id, token).then((data) => {
                    setRiesgosPersonas(data);
                  })
                }}
              >
                <td className={tdClass}>
                  {item?.persona?.primer_nombre}{" "}
                  {item?.persona?.segundo_nombre}{" "}
                  {item?.persona?.primer_apellido}{" "}
                  {item?.persona?.segundo_apellido}
                </td>
                <td className={tdClass}>{item?.persona?.no_documento}</td>
                <td className={tdClass}>{item?.nivel_riesgo?.nombre}</td>
              </tr>
            ))}
          />
        </div>
        <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
          <div className="flex flex-row justify-between">
            <h1 className="mb-1 text-sm">Tipos de riesgos</h1>
            <h1 className="mb-1 text-sm">{nombrePersona}</h1>
          </div>
          <TableSiavi
            headers={[
              'Tipo riesgo',
              'Descripción'
            ]}
            body={riesgosPersonas?.results?.map((item) => (
              <tr
                key={'rp' + item.id}
                className="hover:bg-maivi-gray-5 cursor-pointer"
              >

                <td className={tdClass}>{item?.tipo_riesgo?.nombre}</td>
                <td className={tdClass}>{item?.tipo_riesgo?.descripcion}</td>
              </tr>
            ))}
          />
        </div>
      </div>
      {modal && (
        <ModalGenerico
          title={`Agregar evaluación de riesgos a ${nombrePersonaModal}`}
          open={modal}
          closeModal={() => { }}
          cerrar={() => {
            setRefetch(false);
            setModal(false);
          }}
          children={
            <Evaluacion
              nivelesRiesgos={nivelesRiesgos}
              tipoRiesgos={tipoRiesgos}
              idCaso={idCaso}
              idPersona={idPersona}
              token={token}
              setRefetch={setRefetch}
              cerrarModal={() => {
                setModal(false)
              }}
            />
          }
        />
      )}
    </form>
  );
};
