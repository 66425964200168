/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { clases } from "../../../../../../helpers/clases";
import { getPersonasCaso, getAtenciones, accionesAtenciones } from "./services";
import { useSelector } from "react-redux";
import { Acciones } from "./acciones";
import { SelectSiavi } from "../../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { GearIcon } from "../../../../../../helpers/icons";
import ModalBig from "../../../../../../components/common/ModalTwo";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import moment from "moment";
import ModalGenerico from "../../../../../../components/common/Modal";
import toast from "react-hot-toast";
import Filtros from "../../../../atencionAcciones/filtros";
import { Gestionar } from "../../../../atencionAcciones/gestionar";
import { FormDetalle } from "../../../../atencionAcciones/Detalle";
import ButtonAdd from "../../../../../../components/common/ButtonAdd";
import { FilterIcon, PlusCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import TableGeneric from "../../../../../../components/common/TableGeneric";
import Spinner from "../../../../../../components/common/Spinner";

const columns = [
    { title: "No.", value: "id", tipo: "text" },
    { title: "Atención", value: "accion_seguimiento.atencion.nombre", tipo: "text" },
    
    { title: "Tipo acción", value: "accion_seguimiento.nombre", tipo: "text" },
    //{ title: "Expediente", value: "caso.numero_caso", tipo: "text" },
    {
        title: "Nombre Víctima",
        value: null,
        values: [
            "persona.primer_nombre",
            "persona.segundo_nombre",
            "persona.primer_apellido",
            "persona.segundo_apellido"
        ],
        tipo: "text"
    },
    {
        title: "Profesional Asignado",
        value: null,
        values: [
            "profesional_asignado.first_name",
            "profesional_asignado.last_name"
        ],
        tipo: "text"
    },
    { title: "Fecha planificada", value: "fecha_hora_planificada_inicia", tipo: "datetime" },
    { title: "Fecha completada", value: "fecha_completado", tipo: "date" },
    
];

export const AtencionesComplementarias = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const sede = useSelector((state) => state.ChangeSede.sedeId);
    const caso = props.caso
    const [acciones, setAcciones] = useState(false);
    const [personas, setPersonas] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [registroAtenciones, setRegistroAtenciones] = useState({});
    const [atenciones, setAtenciones] = useState([]);
    const [seleccionarAtencion, setSeleccionarAtencion] = useState(false)
    const [atencion, setAtencion] = useState("");
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("-fecha_accion");
    const [endpoint] = useState(`accionesseguimientoscaso?caso=${props.caso.id}`);
    const [detalle, setDetalle] = useState(false);
    const [registro, setRegistro] = useState({});
    const [gestionar, setGestionar] = useState(false);
    const [filtros, setFiltros] = useState("");
    const [filtrar, setFiltrar] = useState(false);
    const [refetch,setRefetch] = useState(false);

    const permiso = "accionesseguimientoscaso";


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAV";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });

        //        retorno = true;  // mm comentar para que funcione
        //           retorno = true;  // mm comentar para que funcione
        return retorno;

    }

    useEffect(() => {
        setTableLoading(true);
        accionesAtenciones(urlFetch, props?.caso?.id, filtros, token).then((data) => {

            data?.results?.forEach(element => {
                if (element.es_vencida) {
                    element.fondo = 'bg-red-50'
                } else if (element.es_por_vencer) {
                    element.fondo = 'bg-yellow-50'
                } else if (element.completada) {
                    element.fondo = ''
                }
            });
            setDataTable(data);
            setTableLoading(false);
        });
    }, [urlFetch, filtros, refetch, sede, order, endpoint]);

    const handleVer = (registro) => {
        setRegistroAtenciones(registro);
        setDetalle(true);
    }

    const opcion = (registro) => {
        return (
            CheckPermiso('ACGestionar') === true &&
            <button
                type="button"
                onClick={() => {
                    setRegistroAtenciones(registro);
                    setGestionar(true);
                }}
                className={clases.addIcon}
            >
                <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                <span className="tooltiptext">Gestionar</span>
            </button>
        
        )
    }

    useEffect(() => {
        setCargando(true);
        getPersonasCaso(caso.id, token).then((data) => {
            setPersonas(data);
            setCargando(false);
        });
    }, [token]);

    useEffect(() => {
        getAtenciones(token).then((data) => {
            setAtenciones(data);
        });
    }, [token]);

    return (
        <div className="mt-2 p-3 border rounded-lg">
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
            </div>
            <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 mt-1">
                    <TableSiavi
                        cargando={cargando}
                        headers={[
                            'Nombre',
                            'No. Identificación',
                            'Fecha nacimiento',
                            'Relación con víctima',
                            'Es víctima',
                            'Es víctima indirecta',
                            'Es querellante',
                            'Es representante legal',
                            'Acciones'
                        ]}
                        body={personas?.results?.filter(x => (x.status_caso_persona === true)).map((item, index) => (
                            <tr
                                key={`${item?.persona?.no_documento}-${index}`}
                                className={`hover:bg-maivi-gray-5 cursor-pointer ${item.victima ? "bg-blue-50" : ""}`}
                            >
                                <td className={clases.td}>
                                    {item?.persona?.primer_nombre} {item?.persona?.segundo_nombre}{" "}
                                    {item?.persona?.primer_apellido}{" "}
                                    {item?.persona?.segundo_apellido}
                                </td>
                                <td className={clases.td}>{item?.persona?.no_documento}</td>
                                <td className={clases.td}>{(moment(item?.persona?.fecha_nacimiento).format("DD-MM-YYYY"))}</td>
                                <td className={clases.td}>{item?.parentesco?.nombre}</td>
                                <td className={clases.td}>
                                    {item?.victima ? (
                                        <label
                                            className={`${item.victima
                                                ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                                                : ""
                                                }`}
                                        >
                                            Si
                                        </label>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td className={clases.td}>
                                    {item?.es_victima_indirecta ? (
                                        <label
                                            className={`${item.es_victima_indirecta
                                                ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                                                : ""
                                                }`}
                                        >
                                            Si
                                        </label>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td className={clases.td}>
                                    {item?.querellante ? (
                                        <label
                                            className={`${item?.querellante
                                                ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                                                : ""
                                                }`}
                                        >
                                            Si
                                        </label>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td className={clases.td}>
                                    {item?.representante_legal ? (
                                        <label
                                            className={`${item?.representante_legal
                                                ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                                                : ""
                                                }`}
                                        >
                                            Si
                                        </label>
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td className={`${clases.td} flex flex-row`}>
                                {(CheckPermiso('ACAcciones') === true) &&
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setRegistro(item);
                                            setSeleccionarAtencion(true);
                                        }}
                                        className={clases.addIcon}>
                                        <GearIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext">Agregar acción</span>
                                    </button>
                        }
                                </td>
                            </tr>
                        ))}
                    />

                </div>
                {seleccionarAtencion && (
                    <ModalGenerico
                        title={`Seleccionar atencion`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setSeleccionarAtencion(false);
                        }}
                        children={
                            <div >
                                <SelectSiavi
                                    label="Selecionar atencion"
                                    name="atencion"
                                    valueInit=""
                                    selectInit="Seleccionar atencion"
                                    className="mb-2"
                                    onChange={(e) => {
                                        setAtencion(e.target.value);
                                    }}
                                    list={
                                        atenciones?.results?.map((item) => {
                                            return (
                                                <option key={item?.nombre} value={item.id}>{item?.nombre}</option>
                                            )
                                        })
                                    }
                                />
                                <ButtonSiavi
                                    type="button"
                                    text="Continuar"
                                    onClick={() => {
                                        if (atencion === "") {
                                            toast.error("seleccione una atencion")
                                        } else {
                                            setAcciones(true);
                                        }
                                    }}
                                />
                            </div>
                        }
                    />
                )}
                {acciones && (
                    <ModalBig
                        title={`Agregar acciones`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAcciones(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <GearIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        children={
                            <Acciones registro={registro} atencion={atencion} />
                        }
                    />
                )}
            </div>
            <div>
                <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
                    <span></span>
                    <div>
                        <ButtonAdd
                            className="ml-2"
                            text="Filtros"
                            type="button"
                            onClick={() => {
                                setFiltrar(true);
                            }}
                            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                        />
                        <ButtonAdd
                            className="ml-2"
                            text="Refrescar"
                            type="button"
                            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                            onClick={() => {
                                setFiltros("")
                            }}
                        />
                    </div>
                </div>
                <div className="py-2 inline-block w-full">
                    <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                        <TableGeneric
                            dataTable={dataTable}
                            tableColumns={columns}
                            fetchPage={setUrlFech}
                            pagination={true}
                            permiso={permiso}
                            agregar={false}
                            showEliminar={false}
                            showEditar={false}
                            showVer={true}
                            order={order}
                            setOrder={setOrder}
                            handleVer={handleVer}
                            addAccion={true}
                            opcion1={opcion}
                        />
                        {tableLoading && (
                            <div className="mt-10 mb-10">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
                {detalle && (
                    <ModalBig
                        title={`Detalle de atención complementaria`}
                        open={detalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        children={
                            <FormDetalle registro={registroAtenciones} token={token} />
                        }
                    />
                )}
                {gestionar && (
                    <ModalGenerico
                        title={`Gestionar`}
                        open={gestionar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setGestionar(false);
                        }}
                        children={
                            <Gestionar
                                registro={registroAtenciones}
                                setGestionar={setGestionar}
                                setRefetch={setRefetch}
                            />
                        }
                    />
                )}
                {filtrar && (
                    <ModalGenerico
                        title={`Filtros`}
                        open={filtrar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setFiltrar(false);
                        }}
                        children={
                            <Filtros
                                setOpenFilters={setFiltrar}
                                setFilters={setFiltros}
                                setUrlFech={setUrlFech}
                                token={token}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};