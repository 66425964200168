import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { ButtonSiavi } from "../common/Button";
import { useSelector } from "react-redux";
import { PhoneIcon, XCircleIcon } from "@heroicons/react/outline";
import {
  deleteGestionTelefono,
  getTelefonosVictima,
  getTipoContactoVictima,
  postGestionTelefono,
  putGestionTelefono,
} from "../../modulos/funcionalidades-generales/orientacion/editar/victima/services";
import {
  postGestionTelefonoVictimario,
  putGestionTelefonoVictimario
} from "../../modulos/funcionalidades-generales/orientacion/editar/sindicados/services";
import { getPaises } from "./services";
import { SelectSiavi } from "../common/Select";
import { ValidarCodigo } from "./validar_codigo";
import Spinner from "../common/Spinner";
import Label from "../common/Label";
import Toggle from "../common/Toggle";
import CustomTable from "../common/Table";
import ModalGenerico from "../common/Modal";
import FormEliminar from "../common/FormEliminar";
import Alert from "../common/Alert";
import NumberFormat from 'react-number-format';
import * as Yup from "yup";

const tableColumns = [
  {
    title: "Tipo Contacto",
    value: "tipo_contacto_detalle.nombre",
    tipo: "text",
  },
  {
    title: "Codigo area",
    value: "codigo_area",
    tipo: "text",
  },
  {
    title: "Telefono",
    value: "numero_telefono",
    tipo: "text",
  },
  {
    title: "Principal",
    value: "telefono_principal",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
  {
    title: "Enviar sms",
    value: "enviar_sms",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
  {
    title: "Tel. confirmado",
    value: "telefono_confirmado",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
];

const validaciones = Yup.object().shape({
  tipo_contacto: Yup.string()
    .required("Tipo Contacto es requerido")
    .max(100, "Máximo 100 caracteres"),
  codigo_area: Yup.string()
    .required("codigo area es requerido"),
  numero_telefono: Yup.string().required('telefono es requerido').min(8, 'minimo 8 caracteres'),
});

const useFetchTipoContacto = (token) => {
  const [state, setState] = useState({
    tipoContacto: [],
  });

  useEffect(() => {
    getTipoContactoVictima(token).then((tipoContacto) => {
      setState({
        tipoContacto: tipoContacto,
      });
    });
  }, [token]);

  return state;
};

const useFetchPaises = (token) => {
  const [state, setState] = useState({
    paises: [],
  });

  useEffect(() => {
    getPaises(token).then((data) => {
      setState({
        paises: data,
      });
    });
  }, [token]);

  return state;
};

export const GestionTelefono = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [isloading, setLoading] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [idTelefono, setIdTelefono] = useState("");
  const [telefono, setTelefono] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const { tipoContacto } = useFetchTipoContacto(token);
  const { paises } = useFetchPaises(token);
  const [codigoArea, setCodigoArea] = useState(false);
  const [idPais, setIdPais] = useState(0);
  const [gestionar, setGestionar] = useState(false);
  const [registro,setRegistro] = useState(false);

  let idPersona;
  let idSindicado;
  if (props.persona) {
    idPersona = props?.persona?.id;
    idSindicado = '';
  } else {
    idSindicado = props?.sindicado?.idSindicado;
    idPersona = '';
  }
  const [reFetch, setReFetch] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "gestiontelefono";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  const handleEliminar = (id) => {
    setIdTelefono(id);
    setEliminar(true);
  };

  const handleEditar = (gestionTelefono) => {
    setEdit(true);
    setTelefono(gestionTelefono);
    paises.results.forEach(element => {
      if (element.codigo_area === gestionTelefono.codigo_area) {
        setIdPais(element.id);
        setCodigoArea(element.codigo_area);
      }
    });
  };

  const handleGestionar = (registro) => {
    setGestionar(true);
    setRegistro(registro)
  };

  const fetchTelefonos = () => {
    getTelefonosVictima(idPersona, idSindicado, token).then((telefonos) => {
      setDataTable(telefonos);
      setTableLoading(false);
      setReFetch(false);
    });
  };

  useEffect(fetchTelefonos, [token, idPersona, idSindicado, reFetch]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          persona: props?.persona?.id ? props?.persona?.id : null,
          sindicado: props?.sindicado?.idSindicado ? props?.sindicado?.idSindicado : null,
          numero_telefono: isEdit ? telefono?.numero_telefono : "",
          tipo_contacto: isEdit ? telefono?.tipo_contacto : "",
          telefono_principal: isEdit ? telefono?.telefono_principal : true,
          enviar_sms: isEdit ? telefono?.enviar_sms : false,
          codigo_area: isEdit ? idPais : ""
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (values.sindicado === null) {
            if (isEdit) {
              await putGestionTelefono(telefono.id, values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Teléfono actualizado exitosamente");
                    setReFetch(true);
                    values.numero_telefono = "";
                    values.tipo_contacto = "";
                    values.telefono_principal = false;
                    setEdit(false);
                  } 
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            } else {
              await postGestionTelefono(values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Teléfono agregado exitosamente");
                    setReFetch(true);
                    resetForm({});
                    if(values.enviar_sms){
                      setGestionar(true);
                      setRegistro(data.data);
                    }
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            }
          } else {
            if (isEdit) {
              await putGestionTelefonoVictimario(telefono.id, values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Teléfono actualizado exitosamente");
                    setReFetch(true);
                    values.numero_telefono = "";
                    values.tipo_contacto = "";
                    values.telefono_principal = false;
                    setEdit(false);
                  } 
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            } else {
              await postGestionTelefonoVictimario(values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Teléfono agregado exitosamente");
                    setReFetch(true);
                    resetForm({});
                  } 
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            }
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-auto grid grid-cols-12 gap-3 items-end border p-3 rounded-lg hover:shadow-md">
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 mt-2">
                <SelectSiavi 
                  label="*Tipo Contacto"
                  name="tipo_contacto"
                  value={values.tipo_contacto}
                  onChange={handleChange}
                  valueInit=""
                  selectInit="Elegir tipo contacto"
                  validacion={true}
                  list=  {tipoContacto?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 mt-2">
                <SelectSiavi 
                  label="Codigo de area"
                  name="codigo_area"
                  value={values.codigo_area}
                  valueInit=""
                  selectInit="Elegir codigo area"
                  validacion={true}
                  disabled={(telefono?.telefono_confirmado) ? true : false}
                  onChange={(e) => {
                    setFieldValue('codigo_area', e.target.value);
                    paises.results.forEach(element => {
                      if (element.id === Number(e.target.value)) {
                        setCodigoArea(element.codigo_area);
                      }
                    });
                  }}
                  list= {paises?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item?.codigo_area} - {item.nombre}
                      </option>
                    );
                  })}
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 mt-2">
                <Label text="*Número de Teléfono" />
                <NumberFormat
                  name="numero_telefono"
                  value={values.numero_telefono}
                  onChange={handleChange}
                  allowNegative={false}
                  disabled={(telefono?.telefono_confirmado) ? true : false}
                  className="mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
                    focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
                    sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
                />
                <ErrorMessage
                  name="numero_telefono"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center mt-2">
                <Label text="Teléfono Principal" />
                <Toggle
                  enabled={values.telefono_principal}
                  name="telefono_principal"
                />
              </div>
             {
                (!props?.es_sindicado) &&
               <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center mt-2">
               <Label text="Enviar SMS" />
               <Toggle
                 enabled={values.enviar_sms}
                 name="enviar_sms"
               />
             </div>
             }
              {permisoAgregar && (
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                  <ButtonSiavi
                    type="submit"
                    disabled={isSubmitting}
                    text={isEdit ? "Actualizar" : "Guardar"}
                    onClick={() => {
                      values.codigo_area = codigoArea;
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
      <div className="mb-4 mt-3">
        {success && <Alert type="success" mensaje={mensaje} />}
        {error && <Alert type="error" mensaje={mensaje} />}
      </div>
      <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleEliminar={handleEliminar}
          handleEditar={handleEditar}
          permiso={permiso}
          showEliminar={true}
          showVer={false}
          showEditar={true}
          agregar={true}
          agregar1="Validar"
          handleAgregar={handleGestionar}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {isEliminar && (
          <ModalGenerico
            title={`¿Esta seguro de eliminar el registro?`}
            open={isEliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
              </div>
            }
            children={
              <FormEliminar
                aceptar={() => {
                  setLoading(true);
                  deleteGestionTelefono(idTelefono, token).then(() => {
                    setEliminar(false);
                    setLoading(false);
                    setReFetch(true);
                  });
                }}
                isloading={isloading}
                cancelar={() => {
                  setEliminar(false);
                }}
              />
            }
          />
        )}
        {gestionar && (
          <ModalGenerico
            title={"Validar numero de teléfono"}
            open={gestionar}
            closeModal={() => { }}
            cerrar={() => {
              setGestionar(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <PhoneIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
              </div>
            }
            children={
             <ValidarCodigo 
              registro={registro}
              setGestionar={setGestionar}
              setReFetch={setReFetch}
             />
            }
          />
        )}
      </div>
    </div>
  );
};
