import moment from "moment";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const FormDetalle = (props) => {
    console.log(props)

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-6 ">
                <div className="bg-white border rounded-md hover:shadow-lg">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Nombre
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.recurso?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.recurso?.sede_recurso?.sede}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Id
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.recurso?.id}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Fecha Disponibilidad
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.fecha}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Hora Disponibilidad
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.hora}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Disponible
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.disponible === true ? (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            Sí
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                            No
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-span-12 xl:col-span-7 lg:col-span-7 md:col-span-6 p-4 border rounded-lg hover:shadow-lg">
                Detalle de Acción del Recurso
                <div className="bg-white border rounded-md hover:shadow-lg">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Tipo
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.accion_seguimiento?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    No. Caso
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.caso}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    No. Expediente
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.expediente}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">Fecha creación</div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {moment(props?.detalle?.accion_recurso?.fecha_creacion).format("DD-MM-YYYY")}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Modalidad
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.modalidad}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Persona
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.persona}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">Profesional Asignado</div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.profesional_asignado}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Sede Acción
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.sede_accion}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Seguimiento
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.seguimiento}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Municipio
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.municipio}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Departamento
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.accion_recurso?.departamento}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Fecha Completado
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.fecha_completado === null ? '' : moment(props?.detalle?.fecha_completado).format("DD-MM-YYYY")}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Completado
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.accion_recurso?.completada === true ? (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            Sí
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                            No
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
