
const {
    REACT_APP_API_URL
} = process.env;

export const getEstadoProceso = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/estadoproceso/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEstadoProceso = async ({
    nombre, 
    descripcion, 
    etapa_proceso, 
    habilita_proceso_secundario, 
    habilita_medida_desjudicializadora, 
    habilita_sentencia, 
    es_audiencia, 
    estado,
    habilita_motivo,
    habilita_interponente
},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            etapa_proceso,
            habilita_proceso_secundario,
            habilita_medida_desjudicializadora,
            habilita_sentencia,
            es_audiencia,
            estado,
            habilita_motivo,
            habilita_interponente
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/estadoproceso/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEstadoProceso = async (id, {
    nombre,
    descripcion,
    etapa_proceso, 
    habilita_proceso_secundario, 
    habilita_medida_desjudicializadora, 
    habilita_sentencia, 
    es_audiencia, estado,
    habilita_motivo,
    habilita_interponente
},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            etapa_proceso,
            habilita_proceso_secundario,
            habilita_medida_desjudicializadora,
            habilita_sentencia,
            es_audiencia,
            estado,
            habilita_motivo,
            habilita_interponente
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/estadoproceso/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteEstadoProceso = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/estadoproceso/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEtapaProcesalEstadoProceso = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/etapaprocesal/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoProcesoEtapa = async (id,urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ?
        `${REACT_APP_API_URL}/catalogos/estadoproceso/?etapa_proceso=${id}` :
        `${urlPage}&etapa_proceso=${id}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getResultadoAudiencia = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/resultadoaudiencia/?estado_proceso=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMedidaDesjudicializadora = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/medidadesjudicializadora/?estado_proceso=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};