const {
    REACT_APP_BITACORA_URL,
    REACT_APP_API_URL
} = process.env;

export const getBitacora1 = async (url) => {
    try {

        let body = {
            page: 1,
            limit: 10,
            query: {
                tabla: "registro_casohistoricoestado",
                fecha: { $gte: '2022-06-13T00:00:00.884181', $lte: '2022-06-13T23:59:59.884181' }
            },
            // sort: {
            //     createdAt: -1 ,
            // }
        }
        let urlFetch = url || `${REACT_APP_BITACORA_URL}/v1/bitacora/custom-query`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const bitacoraExport = async (
    fecha_inicio,
    fecha_fin,
    tabla,
    modulo,
    transaccion,
    profesional,
    buscar,
    token
    ) => {
    try {
        let urlFetch =`${REACT_APP_BITACORA_URL}/v1/bitacora/custom-export`
        let body = {
            query: {},
            buscar: buscar
        }

        if (tabla !== "") {
            body.query.tabla = tabla
        }

        if (fecha_inicio !== "" && fecha_fin !== "") {
            body.query.fecha = { $gte: `${fecha_inicio}T00:00:00`, $lte: `${fecha_fin}T23:59:59` }
        }

        if(modulo !== ""){
            body.query.modulo = modulo;
        }

        if(transaccion !== ""){
            body.query.transaccion = transaccion;
        }

        if(profesional !== ""){
            body.query.usuario = profesional;
        }
        let result = await fetch(urlFetch, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(body)
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const bitacora = async (
    page,
    fecha_inicio,
    fecha_fin,
    tabla,
    modulo,
    transaccion,
    profesional,
    order,
    buscar,
    token) => {
    try {
        let urlFetch =`${REACT_APP_BITACORA_URL}/v1/bitacora/custom-query`
        let body = {
            page: page,
            limit: 10,
            query: {},
            sort:{},
            buscar: buscar
        }

        if (tabla !== "") {
            body.query.tabla = tabla
        }

        if (fecha_inicio !== "" && fecha_fin !== "") {
            body.query.fecha = { $gte: `${fecha_inicio}T00:00:00`, $lte: `${fecha_fin}T23:59:59` }
        }

        if(modulo !== ""){
            body.query.modulo = modulo;
        }

        if(transaccion !== ""){
            body.query.transaccion = transaccion;
        }

        if(profesional !== ""){
            body.query.usuario = profesional;
        }

        if(order !== ""){
            let campo = order.split("-");

            if(campo[0] === ""){
                body.sort[campo[1]] = 1
            } else {
                body.sort[campo[0]] = -1
            }
        }

        let result = await fetch(urlFetch, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(body)
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getBitacora = async (urlPage, search, order, filters, token) => {
    try {
        let urlFetch = urlPage === undefined
            ? `${REACT_APP_BITACORA_URL}/v1/bitacora/custom-query`
            : `${urlPage}&ordering=${order}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getProfesionales = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

export const getTablas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/listtables/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };