import ReactJson from 'react-json-view'

export const FormDetalle = (props) => {


  return (
    <div className="bg-white border rounded-lg hover:shadow-lg overflow-hidden sm:rounded-lg mt-4">
      <div className="px-4 py-5 sm:p-0 overflow-auto">
        <dl className=" flex flex-row justify-center">
          <div className="py-4 sm:py-3 sm:px-6" >
          <ReactJson src={props.detalle.datosAnteriores} name="Datos anteriores" collapsed={false}/>
          </div>
         
          <div className="py-4 sm:py-3 sm:px-6">
            <ReactJson src={props.detalle.datosActuales} name="Datos Actuales" collapsed={false}/>
          </div>
        </dl>
      </div>
    </div>
  );
};
