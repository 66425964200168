import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Crear } from "./Crear";
import { postGestionInstitucion } from "../services";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

// Validation with Yup
const validaciones = Yup.object().shape({
  nombre_fiscalia: Yup.string()
    .required("nombre fiscalia es requerida"),
  // oficina_instancia: Yup.string()
  //   .required("oficina instancia es requerida")
  //   .max(70, "máximo 70 caracteres"),
  institucion: Yup.string().required("institución es requerida"),
  departamento: Yup.string().required("departamento es requerido"),
  municipio: Yup.string().required("municipio es requerida"),
  email_contacto: Yup.string().max(100, "máximo 100 caracteres").email('correo no valido').required("correo electronico es requerido"),
  telefono: Yup.string()
    .required("teléfono es requerido")
    .max(20, "máximo 20 caracteres"),
  direccion: Yup.string()
    .required("dirección es requerido")
    .max(100, "máximo 100 caracteres"),
  horario: Yup.string().max(30, "máximo 30 caracteres"),
  //jurisdiccion: Yup.string().max(70, "máximo 70 caracteres"),
  latitud: Yup.string().max(30, "máximo 30 caracteres"),
  longitud: Yup.string().max(30, "máximo 30 caracteres"),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
};

export const FormCrear = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  //const [mensaje, setMensaje] = useState("");
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        nombre_fiscalia: "",
        oficina_instancia: "",
        materia: "",
        departamento: "",
        municipio: "",
        email_contacto: "",
        otros_contactos:"",
        observaciones:"",
        contacto_principal:"",
        telefono: "",
        direccion: "",
        horario: "",
        jurisdiccion: "",
        latitud: "",
        longitud: "",
        url_maps: "",
        url: "",
        estado: true,
        serviciosAgregados: [],
        materiasAgregadas: [],
        poblacionesAgregadas: [],
        institucion: "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await postGestionInstitucion(values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status === 201 ) {
              setSuccess(true);
              setError(false);
              toast.success('Registro creado con éxito...!')
              setTimeout(() => {
                history.push('/catalogos/gestion-instituciones');
              }, 1800);
            } else {
              setError(true);
              setSuccess(false);
            }
          })
          .catch(function (error) {
            if (error.response.status === 400 || error.response.status === 401) {
              setError(true);
              setSuccess(false);
              toast.error(error.response.data.message)
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              toast.error("Error interno comuniquese con el administrador")
            }
          });
      }}
    >
      {(props) => (
        <Crear
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          //mensaje={mensaje}
          customChange={handleChangeInput}
        />
      )}
    </Formik>
  );
};
