import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postRazonExclusion, putRazonExclusion } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(255, "Máximo 100 caracteres"),
    descripcion: Yup.string().max(300, 'máximo 300 caracteres')
});

export const Form = (props) => {
    const token = useSelector(
        (state) => state.loginReducer.data.token
    );

    return <div>
        <Formik
            initialValues={{
                nombre: (props.editar) ? props.value.nombre : "",
                descripcion: (props.editar) ? props.value.descripcion : "",
                estado: (props.editar) ? props.value.estado : true
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                if (props.editar) {
                    await putRazonExclusion(props.value.id, values, token).then((data) => {
                        if (data?.message !== undefined) {
                            toast.error(data.message)
                        } else {
                            props.setReFetch(true);
                            toast.success('Registro actualizado con éxito...!')
                        }
                    });
                } else {
                    await postRazonExclusion(values, token).then((data) => {
                        if (data?.message !== undefined) {
                            toast.error(data.message)
                        } else {
                            props.setReFetch(true);
                            toast.success('Registro creado con éxito...!')
                        }
                    });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="py-4 max-w-lg">
                                <div className="col-span-6">
                                    <InputSiavi
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        value={values.nombre}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Nombre*"
                                    />
                                </div>
                                <div className="col-span-6 mt-4">
                                    <InputSiavi
                                        type="text"
                                        name="descripcion"
                                        id="descripcion"
                                        value={values.descripcion}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Descripción"
                                    />
                                </div>
                                <div className="col-span-6 mt-5">
                                    <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                    <Toggle enabled={values.estado} name="estado" />
                                </div>
                                <div className="py-3 text-right">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm 
                    text-sm font-medium rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    >
                                        {(props.editar) ? 'Actualizar' : 'Guardar'}
                                    </button>
                                </div>
                            </div>
                            {isSubmitting && <Spinner />}
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    </div>
};
