import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getAudiencias = async (urlPage, search,sede,filters,order,user, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&profesional_asignado=${user}&ordering=${order}&${filters}`
        :`${urlPage}&search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&profesional_asignado=${user}&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const audiencias = async (urlPage,sede,filters,order,user, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&profesional_asignado=${user}&${filters}&ordering=${order}`
        : `${urlPage}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&profesional_asignado=${user}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getInstitucionGestion = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&institucion__tiene_materia=true`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const postAsignaciones = async (profesional, expedientes,token) => {
  try {
      let ArregloExpedientes = [];
      expedientes.forEach(element => {
        ArregloExpedientes.push(element.id);
      });

      let body ={
        profesional: profesional.value, 
        expedientes:ArregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};