/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import {
  getExpedientes,
  getProfesionales,
  postAsignaciones,
  expedientes,
  //exportarData
} from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { DocumentAddIcon, FilterIcon, RefreshIcon, ViewGridAddIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import {
  getSindicadoDelitos,
  getExpedienteSindicados,
  getFullCasoDetails,
  getDiligenciasExpediente
} from "../orientacion/editar/services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../components/common/Label";
import ModalGenerico from "../../../components/common/Modal";
import ModalBig from "../../../components/common/ModalTwo";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import toast from "react-hot-toast";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import PdfViewer from "../../../components/common/PdfViewer";

const tableColumns = [
  { title: "Fecha", value: "fecha", tipo: "date", ordenar: "fecha" },
  { title: "Sede creación", value: "sede_expediente.sede", tipo: "text", ordenar: "sede_expediente__nombre" },
  { title: "Expediente", value: "caso_persona.caso.numero_caso", tipo: "text" },
  { title: "Servicio", value: "servicio.nombre", tipo: "text", ordenar: "servicio__nombre" },
  { title: "Identificación", value: "caso_persona.persona.no_documento", tipo: "text" },
  {
    title: "Nombre",
    value: null,
    values: [
      "caso_persona.persona.primer_nombre",
      "caso_persona.persona.segundo_nombre",
      "caso_persona.persona.primer_apellido",
      "caso_persona.persona.segundo_apellido"
    ],
    tipo: "text"
  },
  {
    title: "Usuario",
    value: null,
    values: [
      "usuario_creacion.first_name",
      "usuario_creacion.last_name",
    ],
    tipo: "text",
    ordenar: "usuario_creacion__first_name"
  },
];

const useFetchProfesionales = (token) => {
  const [state, setState] = useState({
    profesionales: [],
  });

  useEffect(() => {
    getProfesionales(token).then((data) => {
      setState({
        profesionales: data,
      });
    });
  }, [token]);

  return state;
};

export const TableExpedientes = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [registros, setRegistros] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [profesional, setProfesional] = useState(0);
  const [servicio, setServicio] = useState("");
  const [order, setOrder] = useState("-fecha")
  const [imprimirData, setImprimirData] = useState(null);
  const permiso = "expediente";
  const animatedComponents = makeAnimated();
  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user
  );

  const query = new URLSearchParams(window.location.search);
  const value_route = query.get("value");

  // const exportData = (tipo) => {
  //   setTableLoading(true);
  //   exportarData(filters, token).then(async (data) => {
  //     const fileName = 'expedientes por area';
  //     let dataExport = data;
  //     let columns = [
  //       { name: "ID", value: "id" },
  //       { name: "Aceptado", value: "aceptado" },
  //       { name: "Asistencia legal", value: "asitencia_legal" },
  //       { name: "Atencion", value: "atencion" },
  //       { name: "Atencion victimologica", value: "atencion_victimologica" },
  //       { name: "Autoriza cierre", value: "autoriza_cierre" },
  //       { name: "Caso", value: "caso" },
  //       { name: "Correlativo", value: "correlativo" },
  //       { name: "Delitos", value: "delitos" },
  //       { name: "Estado", value: "estado" },
  //       { name: "Estatus", value: "estatus" },
  //       { name: "Expediente reparacion digna", value: "exp_rep_digna" },
  //       { name: "Expediente sentencia", value: "exp_sentencia" },
  //       { name: "Expediente cerrado", value: "expediente_cerrado" },
  //       { name: "Fecha", value: "fecha" },
  //       { name: "Fecha aceptado", value: "fecha_aceptado" },
  //       { name: "Fecha activacion", value: "fecha_activacion" },
  //       { name: "Fecha asignacion", value: "fecha_asignacion" },
  //       { name: "Fecha autoriza", value: "fecha_autoriza" },
  //       { name: "Fecha cierre", value: "fecha_cierre" },
  //       { name: "Fecha creacion", value: "fecha_creacion" },
  //       { name: "Motivo cierre", value: "motivo_cierre" },
  //       { name: "Numero expediente", value: "numero_expediente" },
  //       { name: "Orientacion", value: "orientacion" },
  //       { name: "Persona", value: "persona" },
  //       { name: "Profesional asignado", value: "profesional_asignado" },
  //       { name: "Reparacion digna externa", value: "rep_digna_externa" },
  //       { name: "Sede expediente", value: "sede_expediente" },
  //       { name: "Seguimiento legal", value: "seguimiento_legal" },
  //       { name: "Sentencia externa", value: "sentencia_externa" },
  //       { name: "Servicio", value: "servicio" },
  //       { name: "Sindicados", value: "sindicados" },
  //       { name: "Tipo persona", value: "tipo_persona" },
  //       { name: "Tipo proceso", value: "tipo_proceso" },
  //       { name: "Usuario cierre", value: "usuario_cierre" },
  //       { name: "Usuario creacion", value: "usuario_creacion" }
  //     ];

  //     let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
  //     let requestOptions = {
  //       method: 'POST',
  //       headers: { "Content-Type": "application/json" },
  //       body: raw,
  //       redirect: 'follow'
  //     };
  //     await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
  //       .then(response => response.blob())
  //       .then(blob => URL.createObjectURL(blob))
  //       .then(url => {
  //         setTableLoading(false);
  //         window.open(url, '_blank');
  //       })
  //       .catch(error => console.log('error', error));

  //   });
  // }

  
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "AsignacionDeExpedientesPorArea";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const fetchExpedientes = () => {
    setTableLoading(true);
    expedientes(urlFetch, sede, filters, order,value_route, search, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetExpedientes = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getExpedientes(urlFetch, search, sede, filters, order,"", token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  useEffect(fetchExpedientes, [urlFetch, filters, sede, order]);
  useEffect(fetchGetExpedientes, [urlFetch, search, filters, sede, order]);
  const { profesionales } = useFetchProfesionales(token);
  const optionsProfesionales = [];

  const registrosSeleccionados = (items) => {
    setRegistros(items);
  };

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
    };

    if (servicio === "") {
      optionsProfesionales.push(reg);
    } else {
      item.servicios.forEach(element => {
        if (element?.servicio_detalle?.nombre === servicio) {
          optionsProfesionales.push(reg);
        }
      });
    }
  });

  const changeSelectProfesionales = (e) => {
    setProfesional(e);
  };

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Asignación de expedientes por área ", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div>
        {(CheckPermiso('AsignarExpediente') === true) &&    //mm permiso agregado        
          <ButtonAdd
            className="ml-2"
            text="Asignar"
            type="button"
            onClick={() => {
              if (registros.length === 0) {
                toast.error('Debe de seleccionar al menos un expediente.')
              }
              else {
                let servicioDiferente = false;
                let sedeDiferente = false;
                let servicio = registros[0].servicio.nombre;
                let sede = registros[0].sede_expediente.sede;

                registros.forEach(element => {
                  if (servicio !== element.servicio.nombre) {
                    servicioDiferente = true;
                  }
                  if (sede !== element.sede_expediente.sede) {
                    sedeDiferente = true;
                  }
                });

                if (servicioDiferente || sedeDiferente) {
                  toast.error("Debe elegir servicios iguales, y que pertenezcan a la misma sede para realizar la asignacion");
                } else {
                  setAgregar(true);
                }
              }
            }}
            icon={<ViewGridAddIcon className="ml-3 -mr-1 h-5" />}
          />
}
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
              setUrlFech(undefined);
              setFilters("");
            }}
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
          />
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={false}
            select={true}
            registrosSeleccionados={registrosSeleccionados}
            order={order}
            setOrder={setOrder}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {agregar && (
        <ModalBig
          title={"Asignar expedientes"}
          open={agregar}
          closeModal={() => {
            setAgregar(false);
            setImprimirData(null);
            window.location.reload();
          }}
          cerrar={() => {
            setAgregar(false);
            setImprimirData(null);
            window.location.reload();
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentAddIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <div className="grid grid-cols-12 gap-2 mb-10">
              <div className="col-span-5 border rounded-lg p-3">
                <Label text="Profesional" />
                <div className="mt-1" style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    isDisabled={imprimirData == null ? false : true}
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    name="servicios"
                    className="basic-multi-select text-sm w-full"
                    classNamePrefix="seleccione"
                    options={optionsProfesionales}
                    onChange={changeSelectProfesionales}
                    maxMenuHeight={140}
                  />
                  {/* <div style={{ width: "50px", display: 'flex', justifyContent: 'center' }}>
                    <PrinterIcon className="h-6 w-6 text-maivi-primaryFont cursor-pointer" onClick={async () => { }} />
                  </div> */}

                </div>
                {!imprimirData &&
                  <BotonesConfirmar
                    isLoading={cargando}
                    aceptar={async () => {
                      setCargando(true);
                      await postAsignaciones(profesional, registros, token)
                        .then(async (data) => {
                          if (data?.status >= 200 || data?.status <= 210) {
                            toast.success('Asignaciones realizadas con éxito');

                            let profSend = {};
                            profesionales.results.forEach(item => {
                              if (item.id === profesional.value) {
                                profSend = item;
                              }
                            })

                            let asignacionesSend = [];
                            await registros.forEach(async asig => {
                              await getSindicadoDelitos(asig.caso, token).then(async (delitos) => {
                                await getExpedienteSindicados(asig.caso, token).then(async sindicado => {
                                  await getFullCasoDetails(asig.caso, token).then(async personasCaso => {
                                    await getDiligenciasExpediente(asig.id, token).then(diligencias => {

                                      let etapaProcesal = "";

                                      personasCaso.expedientes.forEach(exp => {
                                        if (exp.ultima_etapa_procesal.etapa_procesal) {
                                          etapaProcesal += ` ${exp.ultima_etapa_procesal.etapa_procesal.nombre} ${etapaProcesal !== ""? ',': ''} `;
                                        }
                                      })

                                      asignacionesSend.push({
                                        ...asig,
                                        etapaProcesal: etapaProcesal,
                                        persona: asig.caso_persona.persona,
                                        delitos: delitos.results,
                                        sindicados: sindicado.results,
                                        personasCaso: personasCaso,
                                        diligencias: diligencias
                                      })
                                      if (asignacionesSend.length === registros.length) {
                                        setCargando(false);
                                        setImprimirData({
                                          profesional: profSend,
                                          asignaciones: asignacionesSend,
                                          usuario: usuarioLogin
                                        })
                                      }
                                    });
                                  });
                                })
                              })
                            })
                          }
                        })
                        .catch(function (error) {
                          if (error.response.status >= 400 || error.response.status <= 410) {
                            setCargando(false);
                            toast.error(error.response.data.message);
                          } else if (error.response.status >= 500) {
                            setCargando(false);
                            toast.error('Error interno comuniquese con el administrador');
                          }
                        });
                    }}
                    cancelar={() => {
                      setAgregar(false);
                      setImprimirData(null);
                      window.location.reload();
                    }}
                  />
                }
                {cargando && <Spinner />}
              </div>
              <div className="col-span-7 border rounded-lg p-3">
                {cargando && <Spinner />}
                {imprimirData &&
                  <PdfViewer
                    data={imprimirData}
                    reporte={"asignaciones"}
                    style={{ height: "70vh" }}
                  />
                }
              </div>
            </div>
          }
        />
      )}
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
              setServicio={setServicio}
              setUrlFech={setUrlFech}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
    </div>
  );
};
