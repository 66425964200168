import { React, useEffect, useState } from "react";
import { getRoles, deleteRol } from "./services";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/common/Table";
import { LockClosedIcon, XCircleIcon } from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../common/FormEliminar";
import { FormDetalle } from "./Detalle";
import toast from 'react-hot-toast';

const tableColumns = [{ title: "Nombre", value: "name", tipo: "text" }];

export const TableRoles = () => {
  const [isEliminar, setEliminar] = useState(false);
  const [verDetalle, setDetalle] = useState(false);
  const [RolesDetalle, setRolesDetalle] = useState(
    {}
  );
  const [idRoles, setIdRoles] = useState("");
  const [nombreRoles, setNombreRoles] = useState("");
  const [isloading, setLoading] = useState(false);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const token = useSelector((state) => state.loginReducer.data.token);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "group";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  const handleVer = (item) => {
    setRolesDetalle(item);
    setDetalle(true);
  };

  const handleEliminar = (id, nombre) => {
    setIdRoles(id);
    setNombreRoles(nombre);
    setEliminar(true);
  };

  const fetchRoles = () => {
    getRoles(urlFetch, token).then((roles) => {
      setDataTable(roles);
      setTableLoading(false);
    });
  };

  useEffect(fetchRoles, [urlFetch, token,reFetch]);

  const linkEditar = `/seguridad/roles/editar/`;

  const pages = [
    { name: "Seguridad", href: "/seguridad", current: false },
    { name: "Roles", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <Link to="/seguridad/roles/crear" type="button">
            <ButtonAdd text="Crear" type="button" />
          </Link>
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            handleEliminar={handleEliminar}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            link={true}
            linkTo={linkEditar}
            showVer={true}
            showEliminar={true}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle de Roles"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <LockClosedIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle
                  detalle={RolesDetalle}
                  cancelar={setEliminar}
                />
              }
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${nombreRoles}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteRol(idRoles, token).then(() => {
                      setLoading(false);
                      setReFetch(true);
                      setEliminar(false);
                      toast.success('Registro eliminado con éxito...!');
                      setReFetch(false);
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
