import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteCriterioCierre,
    getMotivoCierreCriterioCierre,
    postCriterioCierre,
    putCriterioCierre
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { FormDetalleCriterioCierre } from "./detalleCriterioCierre";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Criterio Cierre", value: "nombre", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
});

export const GestionesCriterioCierre = (props) => {
    const [verDetalle, setDetalle] = useState(false);
    const [criterioCierreDetalle, setCriterioCierreDetalle] = useState({});
    const [criterioCierre, setCriterioCierre] = useState("");
    const [idCriterioCierre, setIdCriterioCierre] = useState("");
    const [nombreCriterioCierre, setNombreCriterioCierre] = useState("");
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idMotivoCierre = props?.motivoCierre?.id;
    const [reFetch, setReFetch] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [isEdit, setEdit] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "criterioscierre";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleVer = (item) => {
        setCriterioCierreDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id, nombre) => {
        setIdCriterioCierre(id);
        setNombreCriterioCierre(nombre);
        setEliminar(true);
    };

    const handleEditar = (tipo) => {
        setEdit(true);
        setCriterioCierre(tipo);
    };

    const fetchCriterioCierre = () => {
        getMotivoCierreCriterioCierre(idMotivoCierre, token).then((motivos) => {
            setDataTable(motivos);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchCriterioCierre, [token, idMotivoCierre, urlFetch, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    motivo_cierre: props.motivoCierre.id,
                    nombre: isEdit ? criterioCierre?.nombre : "",
                    estado: isEdit ? criterioCierre?.estado : true
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putCriterioCierre(criterioCierre.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.nombre = "";
                                    values.estado = true;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postCriterioCierre(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 xl:col-span-12 lg:col-span-12 border p-3 rounded-md">
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-12 xl:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            value={values.nombre}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Criterio de Cierre*"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                        <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                        <Toggle enabled={values.estado} name="estado" />
                                    </div>
                                    {permisoAgregar && (
                                        <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-5">
                                            <ButtonSiavi
                                                type="submit"
                                                disabled={isSubmitting}
                                                text={isEdit ? "Actualizar" : "Guardar"}
                                                onClick={handleSubmit}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEditar={handleEditar}
                    handleEliminar={handleEliminar}
                    fetchPage={setUrlFech}
                    pagination={true}
                    permiso={permiso}
                    showVer={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de criterio cierre"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleCriterioCierre detalle={criterioCierreDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar ${nombreCriterioCierre}?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteCriterioCierre(idCriterioCierre, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
