import { React } from "react";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import { Tab } from "@headlessui/react";
import { FormOrientacion } from "./orientacion/form";

export const Crear = () => {
  const pages = [
    {
      name: "Personas",
      href: "/funcionalidades-generales/listar",
      current: false,
    },
    { name: "Orientación Legal-Victimológica", href: "#", current: true },
  ];

  const tabs = [
    {
      name: "Admisibilidad",
      disabled: false,
    },
    {
      name: "Víctimas",
      disabled: true,
    },
    {
      name: "Querellantes",
      disabled: true,
    },
    {
      name: "Sindicados",
      disabled: true,
    },
    {
      name: "Procesos/Delitos",
      disabled: true,
    },
    {
      name: "Hechos",
      disabled: true,
    },
    {
      name: "Riesgos",
      disabled: true,
    },
    {
      name: "Evaluación diagnóstica",
      disabled: true,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="container mx-auto -mt-32 mb-10">
      <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
        <div
          className="flex flex-col justify-center items-center md:justify-between lg:justify-between 
        lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-2"
        >
          <BreadCrumbTwo navegacion={pages} />
          <h1 className="text-sm text-maivi-primaryFont lg:text-lg mb-2">
            Registro de datos generales
          </h1>
        </div>
        <Tab.Group>
        <Tab.List className="grid grid-cols-3 xl:grid-cols-10 lg:grid-cols-5 md:grid-cols-4 rounded-xl bg-blue-50 p-1">
            {tabs.map((item) => {
              return (
                <Tab
                  key={item.name}
                  disabled={item.disabled}
                  className={({ selected }) =>
                    classNames(
                      "w-full text-xs leading-5 font-medium text-maivi-primaryFont rounded-lg py-2",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                      selected
                        ? "bg-white shadow px-2"
                        : "text-maivi-primaryFont-100 hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue  px-2"
                    )
                  }
                >
                  {item.name}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <FormOrientacion />
            </Tab.Panel>
            <Tab.Panel>
              <h1 className="text-2xl m-10">Persona</h1>
            </Tab.Panel>
            <Tab.Panel></Tab.Panel>
            <Tab.Panel>
              <h1 className="text-2xl m-10">Querellante</h1>
            </Tab.Panel>
            <Tab.Panel></Tab.Panel>
            <Tab.Panel>
              <h1 className="text-2xl m-10">Hechos</h1>
            </Tab.Panel>
            <Tab.Panel></Tab.Panel>
            <Tab.Panel>
              <h1 className="text-2xl m-10">Estado del proceso</h1>
            </Tab.Panel>
            <Tab.Panel></Tab.Panel>
            <Tab.Panel>
              <h1 className="text-2xl m-10">Impugnación</h1>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
