import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Actualizar } from "./Actualizar";
import { useSelector } from "react-redux";
import { store } from '../../../../app/redux/store/store';
import { savePersona } from '../../../../app/redux/actions/formRegisterPersona';
import { getPersona,putPersona } from "./services";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const validaciones = Yup.object().shape({
  noDocumento: Yup.string().max(20,'máximo 20 caracteres'),
  nacionalidad: Yup.string().required("nacionalidad es requerida"),
  pais: Yup.string()
     .required("pais es requerido"),
  primerNombre: Yup.string()
    .required("primer nombre es requerido")
    .max(30, "máximo 30 caracteres"),
  segundoNombre: Yup.string()
    .max(30, "máximo 30 caracteres"),
  primerApellido: Yup.string()
    .required("primer apellido es requerido")
    .max(30, "máximo 30 caracteres"),
  apellidoCasada:Yup.string().max(30,'máximo 30 caracteres'),
  fechaNacimiento: Yup.string().required("fecha nacimiento es requerida"),
  sexo: Yup.string().required("sexo es requerido"),
  //alias:Yup.string().required("este campo es requerido").max(30,'máximo 30 caracteres')
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  let fechaNac = new Date(values.fechaNacimiento);
  let yearNacimiento = fechaNac.getFullYear();
  let mesNacimiento = fechaNac.getMonth();
  const d = new Date();
  let yearActual = d.getFullYear();
  let edad = yearActual - yearNacimiento;
  let m = d.getMonth() - mesNacimiento;

  if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
    edad--;
  }

  if (values.fechaNacimiento) {
    values.edad = edad;
  }

  store.dispatch(savePersona(values));
};

export const FormActualizar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { id } = useParams();
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ mensaje, setMensaje] = useState("");
  const [ loading, setLoading] = useState(true);
  const [ persona, setPersona ] = useState({});
  const history = useHistory();

  useEffect(() => {
    const datos = async () => {
      await getPersona(id,token).then((data) => {
        setPersona(data.data);
        setLoading(false);
      });
    };
    datos();
  }, [id,token]);

  return loading ? <div></div>:
  <Formik
  initialValues={{
    id: persona?.id,
    tipoDocumento: persona?.tipo_documento || "" ,
    noDocumento: persona?.no_documento || "",
    pais: persona?.pais,
    primeraVisita: persona?.primera_visita || false,
    primerNombre: persona?.primer_nombre || "",
    segundoNombre: persona?.segundo_nombre || "",
    tercer_nombre: persona?.otros_nombres || "",
    primerApellido: persona?.primer_apellido || "",
    segundoApellido: persona?.segundo_apellido || "",
    apellidoCasada: persona?.apellido_casada || "",
    fechaNacimiento: persona?.fecha_nacimiento || "",
    edad: persona?.edad || 0,
    sexo: persona?.sexo || "",
    nacionalidad: persona?.nacionalidad || "",
    alias: persona?.como_le_gusta_que_le_llamen,
    sede: persona?.sede?.sede,
    sin_documento: persona?.sin_documento || false
  }}
  
  validationSchema={validaciones}
  onSubmit={async (values) => {
    await putPersona(id,values, token)
    .then((data) => {
      if (data?.status === 200 || data?.status === 201 ) {
        setSuccess(true);
        setError(false);
        setMensaje("registro actualizado éxitosamente");
        setTimeout(() => {
          history.push('/funcionalidades-generales/listar');
        }, 2000);
      }
    })
    .catch(function (error) {
      if (error.response.status === 400 || error.response.status === 401) {
        setError(true);
        setSuccess(false);
        setMensaje(error.response.data.message);
      } else if (error.response.status >= 500) {
        setError(true);
        setSuccess(false);
        setMensaje("Error interno comuniquese con el administrador");
      }
    });
  }}
>
  {(props) => (
    <Actualizar
      {...props}
      customSwitchChange={customSwitchChange}
      customChange={handleChangeInput}
      hasError={hasError}
      success={success}
      mensaje={mensaje}
      actualizar={true}
      idPersona={id}
    />
  )}
</Formik>
};
