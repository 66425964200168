/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { Formik } from "formik";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { SelectSiavi } from "../../../../../components/common/Select";
import { FormDetalleDiligenciaUrgente } from "./detalle_diligencia_urgente";
import { TrashIcon, EyeIcon } from "@heroicons/react/solid";
import {
  postDiligencias,
  getDiligencias,
  getDiligenciasUrgentes,
  deleteDiligencias,
} from "./services";
import ModalGenerico from "../../../../../components/common/Modal";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import CustomTable from "../../../../../components/common/Table";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  comentario: Yup.string()
    .max(300, "maximo 300 caracteres")
    .required("comentario es requerido"),
  diligencia: Yup.string().required("tipo diligencia es requerido"),
});

const classIconEdit = `hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
 rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`;

const classIconDelete = `hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`;

const tableColumns = [
  { title: "Id", value: "id", tipo: "text" },
  { title: "Fecha", value: "fecha_creacion", tipo: "date" },
  { title: "Tipo", value: "tipo_diligencia.nombre", tipo: "text" },
  {
    title: "Urgente",
    value: "urgente",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Usuario creación",
    value: null,
    values: ["usuario_creacion.first_name", "usuario_creacion.last_name"],
    tipo: "text",
  },
];

export const DiligenciasUrgentes = (props) => {
  const token = useSelector((state) => state?.loginReducer?.data?.token);
  const [cargando, setCargando] = useState(false);
  const idCaso = props?.expediente?.caso;
  const idExpediente = props?.expediente?.id;
  const idTipoProceso = props?.expediente?.tipo_proceso?.id;
  const [diligencias, setDiligencias] = useState([]);
  const [diligenciasCreadas, setDiligenciasCreadas] = useState(false);
  const [refetch, setReFetch] = useState(false);
  const [cargandoDiligencias, setCargandoDiligencias] = useState(false);
  const [registro, setRegistro] = useState({});
  const [eliminar, setEliminar] = useState(false);
  const [ver, setVer] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [urlFetch, setUrlFech] = useState(undefined);

  const permisosRegistro = useSelector((state) =>
    state.ChangePermisos?.menus?.registros?.menus
      ? state.ChangePermisos?.menus?.registros?.menus
      : []
  ); //mm buscar el registro
  const permisoView = "ExpedientesAL"; //mm permiso padre

  const opciones = (item) => {
    return (
      <span>
        {CheckPermiso("EliminarDiligencia") === true && (
          <button
            type="button"
            onClick={async () => {
              setEliminar(true);
              setRegistro(item);
            }}
            className={classIconDelete}
          >
            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
          </button>
        )}
        <button
          type="button"
          onClick={async () => {
            setVer(true);
            setRegistro(item);
          }}
          className={classIconEdit}
        >
          <EyeIcon className="h-6 w-6  text-maivi-primaryFont hover:text-white" />
        </button>
      </span>
    );
  };

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach((menu) => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach((op) => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        });
      }
    });
    //retorno = true;  // mm comentar para que funcione
    return retorno;
  };

  useEffect(() => {
    getDiligencias(token).then((data) => {
      setDiligencias(data);
    });
  }, []);

  useEffect(() => {
    setCargandoDiligencias(true);
    getDiligenciasUrgentes(idExpediente, urlFetch, token).then((data) => {
      setDiligenciasCreadas(data);
      setCargandoDiligencias(false);
      setDataTable(data);
    });
  }, [refetch, urlFetch]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comentario: "",
        urgente: true,
        diligencia: "",
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        setCargando(true);
        console.log(props.expediente);
        await postDiligencias(
          idCaso,
          idExpediente,
          idTipoProceso,
          values.diligencia,
          values,
          token
        )
          .then((data) => {
            if (data?.status === 200 || data?.status === 201) {
              toast.success("diligencia agregada con éxito..!");
              setCargando(false);
              setReFetch(true);
              setReFetch(false);
            }
          })
          .catch(function (error) {
            if (
              error?.response?.status >= 400 &&
              error?.response?.status <= 450
            ) {
              toast.error(error?.response?.data?.message);
              setCargando(false);
            } else if (error?.response?.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
              setCargando(false);
            }
          });
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-3 border p-3 rounded-lg">
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6 items-end">
              <SelectSiavi
                label="Tipo diligencia"
                validacion={true}
                name="diligencia"
                onChange={handleChange}
                valueInit=""
                selectInit="Seleccionar tipo diligencia"
                list={diligencias?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              />
            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-4 sm:col-span-6">
              <TextAreaSiavi
                label="Comentario"
                validacion={true}
                name="comentario"
                onChange={handleChange}
                rows={2}
                cols={10}
              />
            </div>
            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6 flex items-end">
              {!cargando ? (
                CheckPermiso("GrabarDiligencia") === true && (
                  <ButtonSiavi
                    type="button"
                    text="Agregar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <div className="p-2">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
          <div className="mt-3 p-3 border rounded-lg">
            <Label text="Diligencias creadas" />
            <CustomTable
              dataTable={dataTable}
              tableColumns={tableColumns}
              fetchPage={setUrlFech}
              pagination={true}
              addAccion={true}
              showVer={true}
              opciones={opciones}
            />
          </div>
          {eliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro?`}
              open={true}
              closeModal={() => {}}
              cerrar={() => {
                setEliminar(false);
              }}
              children={
                <BotonesConfirmar
                  aceptar={async () => {
                    await deleteDiligencias(registro.id, token)
                      .then((data) => {
                        if (data?.status >= 200 || data?.status <= 210) {
                          toast.success("Registro eliminado con éxito...!");
                          setReFetch(true);
                          setReFetch(false);
                          setEliminar(false);
                        }
                      })
                      .catch(function (error) {
                        if (
                          error.response.status === 400 ||
                          error.response.status === 401
                        ) {
                          toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                          toast.error(
                            "Error interno comuniquese con el administrador"
                          );
                        }
                      });
                  }}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {ver && (
            <ModalGenerico
              title={`Detalle`}
              open={true}
              closeModal={() => {}}
              cerrar={() => {
                setVer(false);
              }}
              children={<FormDetalleDiligenciaUrgente registro={registro} />}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
