/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    putCasoPersonaQuerellante,
    getExpedientes
} from "./services"
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import Alert from "../../../../../components/common/Alert";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import toast from 'react-hot-toast';

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [mensaje, setMensaje] = useState("");
    const [mensaje2,setMensaje2] = useState("");
    const [expedientes, setExpedientes] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [confirmar, setConfirmar] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alert2,setAlert2] = useState(false);

    useEffect(() => {
        getExpedientes(props.registro.persona.id, token).then((data) => {
            setExpedientes(data);
            setCargando(false);
        });
    }, []);

    const validarQuerellantes = (values) => {
        let contador = props.personas.filter(x => (x.querellante || x.representante_legal) && x.status_caso_persona === true).length;
        
        if (contador === 1) {
            setAlert(true);
            setMensaje("Debe agregar otro querellante antes de cambiar el estado del querellante actual");
        } else if(props.registro.victima && contador > 1){
            setMensaje2("¿La victima directa dejara de ser querellante, desea continuar?");
            setAlert2(true);
            setConfirmar(true);
        }
        
        else {
            actualizarQuerellante(values);
        }
    }

    const actualizarQuerellante = async (values) => {
        setCargando(true)
        await putCasoPersonaQuerellante(props.registro.id, values, token)
            .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    toast.success("registro actualizado con éxito");
                    props.setReFetch(true);
                    setTimeout(() => {
                        props.setReFetch(false);
                    }, 400);
                    props.setCambiarEstado(false);
                    setCargando(false);
                }
            })
            .catch(function (error) {
                if (error.response.status === 400 || error.response.status === 401) {
                    toast.error(error.response.data.message);
                    setCargando(false);
                } else if (error.response.status >= 500) {
                    setCargando(false);
                    toast.error("Error interno comuniquese con el administrador");
                }
            });
    }

    return (
        <Formik
            initialValues={{
                estado: props?.registro?.status_caso_persona || false,
                representante_legal: props.registro.representante_legal,
                querellante: props.registro.querellante,
                usuario: usuario
            }}
            onSubmit={async (values) => {

                if (expedientes.count > 0) {
                    setMensaje("No es posible inactivarlo porque tiene expedientes y caso asociados.");
                    setAlert(true);
                } else {

                }

            }}
        >
            {({ values, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 mb-3">
                        <div className="col-span-4 items-center justify-center flex flex-col">
                            <Label text={`Representante legal`} />
                            <Toggle enabled={values.representante_legal} name="representante_legal" />
                        </div>
                        <div className="col-span-4 items-center justify-center flex flex-col">
                            <Label text={`Querellante`} />
                            <Toggle enabled={values.querellante} name="querellante" />
                        </div>
                        <div className="col-span-4 items-center justify-center flex flex-col">
                            <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                            <Toggle enabled={values.estado} name="estado" />
                        </div>
                    </div>
                    {
                        alert &&
                        <div className="mb-2">
                            <Alert type="warning" mensaje={mensaje} />
                        </div>
                    }
                    {
                        alert2 &&
                        <div className="mb-2">
                            <Alert type="warning" mensaje={mensaje2} />
                        </div>
                    }
                    {
                        confirmar &&
                        <BotonesConfirmar
                            aceptar={() => {
                                actualizarQuerellante(values);
                            }}
                            cancelar={() => {
                                props.setCambiarEstado(false);
                            }}
                        />
                    }
                    {
                        (!cargando) ?
                        !confirmar &&
                        <ButtonSiavi
                        type="button"
                        disabled={isSubmitting}
                        text={"Actualizar"}
                        onClick={() => {
                            if(values.estado === false) {
                                console.log(0)
                                console.log(props.registro)
                                if(values.representante_legal || values.querellante) {
                                    setMensaje("Primero debe deshabilitar las opciones de querellante y representante legal")
                                    setAlert(true);
                                    console.log(1)
                                } else if (props?.registro?.victima){
                                    setMensaje("No se puede desahabilitar porque es una victima directa")
                                    setAlert(true);
                                    console.log(2)
                                } else if(props?.registro?.es_victima_indirecta){
                                    setMensaje("Debe hacer este proceso desde la ventana de víctima")
                                    setAlert(true);
                                    console.log(2)
                                }
                            } else if(values.estado === true){
                                if(values.querellante || values.representante_legal){
                                    validarQuerellantes(values);
                                }
                                if (values.querellante === false || values.representante_legal === false) {
                                    validarQuerellantes(values);
                                }  
                            }
                        }}
                    />   
                        :
                            <Spinner />
                    }
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};
