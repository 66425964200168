/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { espacioSeguro, getEspacioSeguro } from "./services";
import { FormDetalle } from "./detalle";
import { useSelector } from "react-redux";
import { ChangeSearch } from '../../../app/redux/actions/search';
import { store } from '../../../app/redux/store/store';
import CustomTable from "../../../components/common/Table";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ModalGenerico from "../../../components/common/Modal";
import { DocumentTextIcon } from "@heroicons/react/outline";
import ButtonAdd from "../../../components/common/ButtonAdd";
import { FilterIcon, RefreshIcon } from "@heroicons/react/solid";
import Filtros from "./filtros";

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { title: "Fecha", value: "fecha", tipo: "date" },
    {
        title: "Persona",
        value: null,
        values: [
            "persona.primer_nombre",
            "persona.primer_apellido",
        ],
        tipo: "text"
    },
    {
        title: "Es NNA",
        value: "es_nna",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Representante legal",
        value: null,
        values: [
            "representante_legal.primer_nombre",
            "representante_legal.primer_apellido",
        ],
        tipo: "text"
    },
    {
        title: "Profesional",
        value: null,
        values: [
            "profesional.first_name",
            "profesional.last_name",
        ],
        tipo: "text"
    },
    { title: "Delito", value: "delito.nombre", tipo: "text" },
];

export const TableEspacioSeguro = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search)
    const [espacio, setEspacio] = useState("");
    const [verDetalle, setDetalle] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("-id");
    const [filtrar,setFiltrar] = useState(false)
    const [filtros,setFiltros] = useState("");

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "gestionpersona";

    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
        }
    });

    const fetchEspacioSeguro = () => {
        setTableLoading(true);
        espacioSeguro(urlFetch, search, order, filtros, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    };

    const fetchGetEspacioSeguro = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setUrlFech(undefined);
            setTableLoading(true);
            getEspacioSeguro(urlFetch, search, order, filtros, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchEspacioSeguro, [urlFetch, order, filtros, token]);
    useEffect(fetchGetEspacioSeguro, [urlFetch, search, order, filtros, token]);

    const handleVer = (item) => {
        setDetalle(true);
        setEspacio(item);
    };

    const pages = [
        { name: "Funcionalidades", href: "/funcionalidades-generales", current: false },
        { name: "Espacio seguro", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
                <BreadCrumb navegacion={pages} />
                <div>
                    <ButtonAdd
                        className="ml-2"
                        text="Filtrar"
                        type="button"
                        onClick={() => {
                            setFiltrar(true);
                        }}
                        icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                    />
                     <ButtonAdd
                        className="ml-2"
                        text="Refrescar"
                        type="button"
                        onClick={() => {
                            setFiltros("");
                        }}
                        icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                    />
                </div>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showEditar={false}
                        showEliminar={false}
                        showVer={true}
                        order={order}
                        setOrder={setOrder}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de Espacio Seguro"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <FormDetalle detalle={espacio} />
                            }
                        />
                    )}
                    {filtrar && (
                        <ModalGenerico
                        title={`Aplicar filtros`}
                        open={filtrar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setFiltrar(false);
                        }}
                        children={
                            <Filtros
                                setFiltros={setFiltros}
                                setFiltrar={setFiltrar}
                                setUrlFech={setUrlFech}
                                token={token}
                            />
                        }
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <FilterIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
