import ReactJson from 'react-json-view'

export const FormDetalle = (props) => {
    return (
      <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-4">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.name}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Permisos</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ReactJson src={props.detalle.permissions} name="Permisos" collapsed={true}/>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };
  