import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getTipoSMSVictima = async (filtros,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/tiposmsvictima/?page_size=1000&estado=true&${filtros}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMensajes = async (filtro,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/historicosmsvictima/?page_size=100&${filtro}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTelefonos = async (persona,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/gestiontelefono/?page_size=10&persona__id=${persona}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSms = async (data,token) => {
    try {
        let body ={
            numero_destino: data.numero_destino,
            codigo_area: data.codigo_area,
            mensaje: data.mensaje,
            mensaje_enviado: data.mensaje_enviado,
            para_victima: data.para_victima,
            por_caso: data.por_caso,
            por_expediente: data?.por_expediente,
            para_profesional: data?.para_profesional,
            tipo_mensaje: (data?.tipo_mensaje === "") ? null : data?.tipo_mensaje,
            caso_persona: data?.caso_persona,
            caso: data?.caso,
            expediente: data.expediente,
            servicio: null,
            profesional_destino: null,
            usuario_envia: data.usuario_envia
        }
        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/historicosmsvictima/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };
