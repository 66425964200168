import moment from "moment";

export const FormDetalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Recursos
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Descripción
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.descripcion}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">Estado</div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.estado === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Disponibilidad por dia
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.disponibilidad_por_dia === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Disponibilidad por hora
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.disponibilidad_por_hora === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Requiere autorización
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.requiere_autorizacion === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Fecha activado
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {moment(props.detalle.fecha_activado).format("DD-MM-YYYY")}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Fecha inactivo
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {moment(props.detalle.fecha_inactivo).format("DD-MM-YYYY")}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Horario inicio disponibilidad
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.horario_inicio_disponibilidad}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Horario fin disponibilidad
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.horario_fin_disponibilidad}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Profesional responsable
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.profesional_responsable.username}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Profesional autoriza
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.profesional_autoriza?.username}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Sede recurso
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.sede_recurso.sede}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};