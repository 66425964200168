
const {
    REACT_APP_API_URL
} = process.env;


export const getCheckStatus = async (token) => {
    try {
        let bodyResult = await fetch(`${REACT_APP_API_URL}/seguridad/validar-session/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
        
        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export const authorizationSession = async (token) => {
    try {
        let bodyResult = await fetch(`${REACT_APP_API_URL}/seguridad/validar-session/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
        
        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};
