import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { DocumentAddIcon } from "@heroicons/react/solid";
import Alert from "./Alert";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
const { REACT_APP_API_URL } = process.env;

export const SiaviFile = (props) => {
  const token = useSelector((state) => state.loginReducer?.data?.token);
  const [cargando, setcargando] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      setcargando(true);
      setError(false);
      setSuccess(false);

      let myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var formdata = new FormData();
      formdata.append("nombre_documento", props.nombre_documento);
      formdata.append("clase_documento", props.clase_documento);
      formdata.append("recurso_id", props.recurso_id);
      if(props?.expediente !== null){
        formdata.append("expediente",props?.expediente);
      }

      if(props?.caso !== null){
        formdata.append("caso", props?.caso);
      }
      
      formdata.append("path", acceptedFiles[0]);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      };

      fetch(`${REACT_APP_API_URL}/catalogos/colecciondocumentos/`, requestOptions)
        .then(data => data.json())
        .then((result) => {
          if(result?.id){
            setError(false);
            setSuccess(true);
            setcargando(false);
            setMensaje("Archivo guardado con exito");
            toast.success('Archivo guardado con exito');
            props.refetch(true);
            props.refetch(false);
            props.reset();
            setTimeout(() => {
              setSuccess(false);
            }, 5000);
          } else {
            setcargando(false);
            setSuccess(false);
            setError(true);
            setMensaje(result?.message);
          }
        })
        .catch(error => {
          setError(true);
          setcargando(false);
          setMensaje("Ha ocurrido un error interno");
        });
    },
    [props, token]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.extension_permitida,
    multiple: false,
    onDrop,
  });

  return (
    <section className="container border p-2 rounded-md shadow-sm hover:shadow-md w-full hover:bg-blue-100">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        <div className="flex flex-row justify-center items-center">
          <DocumentAddIcon className="h-12 w-12 mr-3 text-maivi-secondaryLightBlue" />
          <p className="text-maivi-primaryFont text-xs">
            Arrastre y suelte el archivo aquí, o haga clic para
            seleccionar, capacidad maxima de archivos 5MB
          </p>
        </div>
        {cargando && <Spinner />}
        {error && <div className="mt-1"><Alert type="error" mensaje={mensaje} /></div>}
        {success && <div className="mt-1"><Alert type="success" mensaje={mensaje} /></div>}
      </div>
    </section>
  );
};
