import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon, PencilAltIcon, TrashIcon, EyeIcon, PaperClipIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../components/common/Select";
import { FormDetalleNombramiento } from "../../../../../modulos/funcionalidades-generales/casos/editar/generales/DetalleNombramiento";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { clases } from "../../../../../helpers/clases";
import {
    deleteNombramientos,
    getAccionesNombramientos,
    postNombramientos,
    putNombramientos,
    getDeptosGestion,
    getMunicipiosGestion,
    getProNombramiento
} from "../../../../../modulos/funcionalidades-generales/casos/editar/services";
import Spinner from "../../../../../components/common/Spinner";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import FormEliminar from "../../../../../components/common/FormEliminar";
import TableSiavi from "../../../../../components/common/TableSiavi";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const classIcon = `hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`;

const validaciones = Yup.object().shape({
    lugar_destino: Yup.string()
        .required("Lugar Destino es requerido")
        .max(150, "Máximo 150 caracteres"),
    fecha: Yup.string()
        .required("Fecha Solicitud es requerido"),
    fecha_hora_salida: Yup.string()
        .required("Fecha Salida es requerido"),
    fecha_hora_retorno: Yup.string()
        .required("Fecha Retorno es requerido"),
    departamento: Yup.string()
        .required("Departamento es requerido"),
    municipio: Yup.string()
        .required("Municipio es requerido"),
    diligencia_realizar: Yup.string()
        .required("Actividades/Diligencias a realizar es requerido"),
    usuario_requiere_nombramiento: Yup.string()
        .required("Profesional requiere nombramiento es requerido")

});

export const GestionNombramiento = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    /*     const sedeId = useSelector((state) => state.ChangeSede.sedeId); */
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
    const [verDetalle, setDetalle] = useState(false);
    const [nombramientoDetalle, setNombramientoDetalle] = useState({});
    const idAccion = props?.accion?.id;
    //const idExpediente = props?.idExpediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idNombramientos, setIdNombramientos] = useState("");
    const [nombramientos, setNombramientos] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [deptos, setDeptos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [usuarioReqNombramiento, setUsuarioReqNombramiento] = useState([]);
    /*     const [setUsuarioAutoriza] = useState([]); */
    const [setNombreDepartamento] = useState([]);
    const [archivos, setArchivos] = useState(false);

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getProNombramiento(token).then((data) => {
            setUsuarioReqNombramiento(data)
        });
    }, [token]);

    useEffect(() => {
        getAccionesNombramientos(idAccion, token).then((nombramientos) => {
            setDataTable(nombramientos);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idAccion, reFetch]);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "nombramientos";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    lugar_destino: isEdit ? nombramientos?.lugar_destino : "",
                    fecha: isEdit ? nombramientos?.fecha : "",
                    fecha_hora_salida: isEdit ? nombramientos?.fecha_hora_salida : "",
                    fecha_hora_retorno: isEdit ? nombramientos?.fecha_hora_retorno : "",
                    fecha_autorizado: isEdit ? nombramientos?.fecha_autorizado : "",
                    autorizado: isEdit ? nombramientos?.autorizado : false,
                    estado: null,
                    observaciones: isEdit ? nombramientos?.observaciones : "",
                    diligencia_realizar: isEdit ? nombramientos?.diligencia_realizar : "",
                    caso: props.idExpediente.caso,
                    expediente: props.idExpediente.id,
                    departamento: isEdit ? nombramientos?.departamento?.id : 0,
                    municipio: isEdit ? nombramientos?.municipio?.id : 0,
                    usuario_requiere_nombramiento: isEdit ? nombramientos?.usuario_requiere_nombramiento?.id : 0,
                    usuario_autorizado: isEdit ? nombramientos?.usuario_autorizado?.id : 0,
                    usuario_creacion: idUser,
                    accion_seguimiento: idAccion
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putNombramientos(nombramientos.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    setReFetch(false);
                                    toast.success('Registro actualizado con éxito...!');
                                    setEdit(false);
                                    resetForm({});
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    } else {
                        await postNombramientos(values, token).then((data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                                setReFetch(true);
                                setReFetch(false);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        }).catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 450) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error("Error interno comuniquese con el administrador");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha"
                                    id="fecha"
                                    value={values.fecha}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Fecha solicitud"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="*Profesional que requiere nombramiento"
                                    name="usuario_requiere_nombramiento"
                                    value={values.usuario_requiere_nombramiento}
                                    onChange={handleChange}
                                    validacion={true}
                                    valueInit=""
                                    selectInit="Elegir usuario"
                                    list={
                                        usuarioReqNombramiento?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name + "pro" + item.id} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="lugar_destino"
                                    id="lugar_destino"
                                    value={values.lugar_destino}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Lugar Destino"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="*Departamento"
                                    name="departamento"
                                    validacion={true}
                                    value={values.departamento}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                        let combo = document.getElementById("departamento");
                                        let selected = combo?.options[combo.selectedIndex].text;
                                        setNombreDepartamento(selected);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre + "dp" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="*Municipio"
                                    name="municipio"
                                    validacion={true}
                                    value={values.municipio}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir municipio"
                                    list={
                                        municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                                            return (
                                                <option key={item.nombre + "mn" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="datetime-local"
                                    name="fecha_hora_salida"
                                    id="fecha_hora_salida"
                                    value={values.fecha_hora_salida}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Fecha Hora Salida"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="datetime-local"
                                    name="fecha_hora_retorno"
                                    id="fecha_hora_retorno"
                                    value={values.fecha_hora_retorno}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Fecha Hora Retorno"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Actividades / Diligencia a realizar"
                                    validacion={true}
                                    name="diligencia_realizar"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.diligencia_realizar}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_autorizado"
                                    id="fecha_autorizado"
                                    value={values.fecha_autorizado}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Autorización"
                                />
                            </div> */}
                            {/*                             <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    onChange={(e) => {
                                        setFieldValue("modalidad", e.target.value);
                                        modalidades.results.forEach(element => {
                                            if (element.id === Number(e.target.value)) {
                                                setIsSeleccion(element?.seleccion_simple);
                                            }
                                        });
                                    }}
                                    list={
                                        tipo_preguntas?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Usuario Autoriza"
                                    name="usuario_autorizado"
                                    value={values.usuario_autorizado}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir usuario"
                                    list={
                                        usuarioAutoriza?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Observaciones"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text={`${values.estado ? 'Autorizado' : 'Rechazado'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Autorizar" />
                                <Toggle enabled={values.autorizado} name="autorizado" />
                            </div> */}
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <TableSiavi
                    cargando={tableLoading}
                    headers={[
                        'Fecha Requerida',
                        'Profesional',
                        'Departamento',
                        'Municipio',
                        'Gestionado',
                        'Estado',
                        'Acciones'
                    ]}
                    body={
                        dataTable?.results?.map((item, index) =>
                            <tr key={item.id + index} className="cursor-pointer hover:bg-gray-100">
                                <td className={clases.td}>{item?.fecha}</td>
                                <td className={clases.td}>{item?.usuario_requiere_nombramiento?.first_name} {item?.usuario_requiere_nombramiento?.last_name}</td>
                                <td className={clases.td}>{item?.departamento?.nombre}</td>
                                <td className={clases.td}>{item?.municipio?.nombre}</td>
                                <td className={clases.td}>{(item?.autorizado) ? 'Si' : 'No'}</td>
                                <td className={clases.td}>{(item?.estado) ? 'Autorizado' : (item?.estado === false) ? 'Rechazado' : 'Pendiente'}</td>
                                <td className={clases.td}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setNombramientoDetalle(item);
                                            setDetalle(true);
                                        }}
                                        className={classIcon}>
                                        <EyeIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext text-xs">Ver detalle</span>
                                    </button>
                                    {!item.autorizado && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setEdit(true);
                                                setNombramientos(item);
                                            }}
                                            className={classIcon}>
                                            <PencilAltIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                                            <span className="tooltiptext text-xs">Editar</span>
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setArchivos(true);
                                            setNombramientos(item);
                                        }}
                                        className={classIcon}>
                                        <PaperClipIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext text-xs">Adjuntar documentos</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setIdNombramientos(item.id);
                                            setEliminar(true);
                                        }}
                                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                                        rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-secondaryRed tooltip`}>
                                        <TrashIcon className="h-5 w-5 text-maivi-secondaryRed hover:text-white" />
                                        <span className="tooltiptext text-xs">Eliminar</span>
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                />


                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de nombramiento"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        children={
                            <FormDetalleNombramiento detalle={nombramientoDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteNombramientos(idNombramientos, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}

                {archivos && (
                    <ModalAmplio
                        title={`Adjuntar archivos`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setArchivos(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <PaperClipIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        children={
                            <GestionArchivos
                                idRecurso={nombramientos.id}
                                recurso="NOMBRAMIENTOS"
                                claseDocumento="nombramientos"
                                expediente={nombramientos?.expediente}
                                caso={nombramientos?.caso?.id}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};