import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import AppRouter from "../../app/router";
import storage from "redux-persist/lib/storage";
import {
  BellIcon,
  FolderIcon,
  MenuAlt2Icon,
  XIcon,
  UserIcon,
  LockClosedIcon,
  ClipboardListIcon,
  ViewListIcon,
  FilterIcon,
  ExclamationIcon,
  StarIcon,
  MenuIcon
} from "@heroicons/react/outline";
import { HandHoldingHeart } from "../../helpers/icons";
import { PresentationChartBarIcon, QuestionMarkCircleIcon, ScaleIcon, SearchIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from '../../app/redux/store/store';
import { ChangeSearch } from '../../app/redux/actions/search';
import { TipoFiltro } from '../../app/redux/actions/tipoFiltro';
import { getSede } from "./services";
import Sedes from "./Sedes";

const navigation = [
  // {
  //   permiso: "todos",
  //   name: "Dashboard",
  //   href: "/",
  //   icon: HomeIcon,
  //   current: true
  // },
  {
    permiso: "catalogos",
    name: "Catálogos",
    href: "/catalogos",
    icon: FolderIcon,
    current: false
  },
  {
    permiso: "reportes",
    name: "Reportes",
    href: "/reportes",
    icon: PresentationChartBarIcon,
    current: false
  },
  {
    permiso: "seguridar",
    name: "Seguridad",
    href: "/seguridad",
    icon: LockClosedIcon,
    current: false,
  },
  {
    permiso: "Registro",
    name: "Registro",
    href: "/funcionalidades-generales",
    icon: ClipboardListIcon,
    current: false,
  },
  {
    permiso: "AsistenciaLegal",
    name: "Asistencia legal",
    href: "/asistencia-legal",
    icon: ScaleIcon,
    current: false,
  },
  {
    permiso: "AtencionVictimologica",
    name: "Atención victimológica",
    href: "/atencion-victimologica",
    icon: HandHoldingHeart,
    current: false,
  },
  {
    permiso: "MisRegistros",
    name: "Mis registros",
    href: "/mis-registros",
    icon: ViewListIcon,
    current: false,
  },
  {
    permiso: "Favoritos",
    name: "Favoritos",
    href: "/favoritos",
    icon: StarIcon,
    current: false,
  },
  {
    permiso: "Ayuda",
    name: "Ayuda",
    href: "/ayuda",
    icon: QuestionMarkCircleIcon,
    current: false,
  },
];
const userNavigation = [{ name: "Tu perfil", href: "/perfil-usuario" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const logo = "https://d2lwggyn64d2dw.cloudfront.net/SIAVI-2022/Vertical/SIAVI-Vertical-blanco.png";
const logoIjm = process.env.PUBLIC_URL + "/images/logo-ijm-blanco.png";
const logoSiaviWhite = process.env.PUBLIC_URL + "/images/logo-siavi-white.png";
const AmbientePruebas = process.env.REACT_APP_DEV_MODE;

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sedeData, setSedeData] = useState({});
  const [sedeOpen, setSedeOpen] = useState(false);

  const searchText = useSelector((state) => state.inputChange.search);
  const token = useSelector((state) => state.loginReducer.data.token);
  const user = useSelector((state) => state.loginReducer.data?.usuario);
  const sedeId = useSelector((state) => state.ChangeSede.sedeId);
  /*const permisos = useSelector(state => state?.loginReducer?.data?.usuario?.user?.permissions);
  const permisosRegistros = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);
  const permisosSeguridad = useSelector(state => state.ChangePermisos?.menus?.seguridad?.menus ? state.ChangePermisos?.menus?.seguridad?.menus : []);
  const permisosAL = useSelector(state => state.ChangePermisos?.menus?.asistencialegal?.menus ? state.ChangePermisos?.menus?.asistencialegal?.menus : []);
  const permisosAV = useSelector(state => state.ChangePermisos?.menus?.atencionvictimologica?.menus ? state.ChangePermisos?.menus?.atencionvictimologica?.menus : []);
*/

  const permisosAll = useSelector(state => state.ChangePermisos?.menus?.menus?.menus ? state.ChangePermisos?.menus?.menus?.menus : []);
  const bandeja = useSelector(state => state?.statusReducer?.data?.bandeja);

  useEffect(() => {
    if (sedeId > 0) {
      getSede(sedeId, token).then(data => {
        setSedeData(data);
        setSedeOpen(false);
      })
    } else {
      setSedeOpen(true)
    }
  }, [sedeId, token]);


  const cerrarSesion = () => {
    storage.removeItem("persist:root");
    storage.removeItem("token");
    window.location = "/";
  };

  const KeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      if (searchText.value === '') {
        store.dispatch(ChangeSearch({ ...searchText, fetch: false, clean: true }));
      }
      else {
        store.dispatch(ChangeSearch({ ...searchText, fetch: true, clean: false }));
      }
    }
  }

  const ChangeHandler = (e) => {
    let value = {
      value: e.target.value,
      min: 3,
      fetch: false,
      clean: false
    };

    store.dispatch(ChangeSearch(value));
  }


  const permisoView = (item) => {

    /*  let recurso = item.permiso;

      let edit = `change_`;
      let del = `delete_`;
      let create = `add_`;

      if (item.permiso === 'todos') {
        return true;
      }

      if (item.permiso === 'registros') {
        let ret = false;
        permisosRegistros.forEach(p => { if (p.ver) { ret = true; } })
        return ret;
      }

      if (item.permiso === 'seguridad') {
        let ret = false;
        permisosSeguridad.forEach(p => { if (p.ver) { ret = true; } })
        return ret;
      }

      if (item.permiso === 'asistencialegal') {
        let ret = false;
        permisosAL.forEach(p => { if (p.ver) { ret = true; } })
        return ret;
      }

      if (item.permiso === 'atencionvictimologica') {
        let ret = false;
        permisosAV.forEach(p => { if (p.ver) { ret = true; } })
        return ret;
      }


      permisos?.forEach(permiso => {
        if (permiso?.codename.includes(recurso)) {
          if (permiso?.codename.includes(edit) || permiso?.codename.includes(del) || permiso?.codename.includes(create)) {
            retorno = true;
          }
        }
      });
  */

      let retorno = false;
    permisosAll.forEach(perm => {
      if (perm.ver === true && perm.permiso === item.permiso) {
        retorno = true;
      }
    });
    return retorno;
  }

  return (
    <div className={`h-screen flex overflow-hidden bg-maivi-gray-5 font-maiviFutura`}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-maivi-gray-50 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 background-siavi">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-maivi-gray-5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-maivi-gray-5" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="h-auto w-7/12 mt-5 ml-14"
                  src={logo}
                  alt={"Instituto de la Víctima"}
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto flex flex-col justify-between">
                <nav className="px-2 space-y-1">

                  {(AmbientePruebas === true || AmbientePruebas === 'true') &&
                    <Link
                      to={"/"}
                      style={{ background: "green" }}
                      className={"border border-yellow-400 text-maivi-gray-5 group flex items-center px-2 py-2 text-base font-medium rounded-md"}
                    >
                      <ExclamationIcon className="mr-4 flex-shrink-0 h-6 w-6 text-white" />
                      Ambiente de Pruebas
                    </Link>
                  }


                  {navigation.map((item) =>
                    permisoView(item) === true &&
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-maivi-secondaryPurple text-maivi-gray-5"
                          : "text-maivi-gray-10 hover:bg-maivi-primaryLightBlue",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-white" />
                      {item.name}
                    </Link>
                  )}
                </nav>
                <div className="flex justify-center">
                  <img src={logoSiaviWhite} alt="img" className="w-6/12" />
                  <img src={logoIjm} alt="img" className="w-5/12" />
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      <div className={`${open ? 'flex':'hidden'} background-siavi`}>
        <div className="flex flex-col w-64 ">
          <div className="flex flex-col flex-grow pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0">
              <img
                className="h-auto w-10/12 mt-5 mr-5 ml-5"
                src={logo}
                alt={"Instituto de la Víctima"}
              />
            </div>
            <div className="mt-5 flex-1 flex flex-col justify-between">
              <nav className="flex-1 px-2 space-y-1 ">
                {(AmbientePruebas === true || AmbientePruebas === 'true') &&
                  <Link
                    to={"/"}
                    style={{ background: "green" }}
                    className={"border border-yellow-400 text-maivi-gray-5 group flex items-center px-2 py-2 text-base font-medium rounded-md"}
                  >
                    <ExclamationIcon className="mr-4 flex-shrink-0 h-6 w-6 text-white" />
                    Ambiente de Pruebas
                  </Link>
                }

                {navigation.map((item) =>
                  permisoView(item) === true &&
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      "text-maivi-gray-10 hover:bg-maivi-primaryLightBlue",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" />
                    {item.name}
                  </Link>
                )}
              </nav>
              <div className="flex justify-center mx-5">
                <img src={logoSiaviWhite} alt="img" className="w-7/12" />
                <img src={logoIjm} alt="img" className="w-5/12" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-maivi-secondaryWhite shadow">
          <button
            type="button"
            className="px-4 border-r border-maivi-gray-10 text-maivi-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-maivi-secondaryBlue md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
            <div className="mr-2 inset-y-0 left-0 flex items-center cursor-pointer" onClick={()=>{
                    setOpen(!open);
                  }}>
                  <MenuIcon className="h-8 w-8 text-maivi-primaryLightBlue" />
                </div>
              <div className="relative w-full text-maivi-gray-25 focus-within:text-maivi-primaryLightBlue">
              
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchIcon className="h-8 w-8 " />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full pl-8 pr-3 py-2 text-maivi-primaryFont
                  placeholder-maivi-gray-50 focus:outline-none focus:placeholder-maivi-primaryLightBlue text-lg ml-4"
                  placeholder="Buscar"
                  type="search"
                  onChange={ChangeHandler}
                  onKeyDown={KeyDownHandler}
                  name="search"
                  autoComplete={"Off"}
                  value={searchText.value}
                />
              </div>
            </div>
            <div className=" flex items-center md:ml-6">
              <button
                type="button"
                className="p-1 px-3 mr-4 text-maivi-gray-25  hover:text-maivi-gray-50 focus:outline-none focus:ring-2"
                onClick={() => setSedeOpen(true)}
              >
                <span>{sedeData && sedeData.sede}</span>
              </button>

              <Menu as="div" className="relative">
                <Menu.Button className="max-w-xs bg-maivi-gray-5 flex items-center text-sm rounded-full
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue">
                  <FilterIcon className="h-8 w-8 rounded-full p-1 text-maivi-primaryBlue" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-maivi-gray-5 ring-1 ring-maivi-gray-50 ring-opacity-5 focus:outline-none">
                    <Menu.Item key="personas">
                      {({ active }) => (
                        <Link
                          to={"/"}
                          className={classNames(
                            active ? "bg-maivi-gray-10" : "",
                            "block px-4 py-2 text-sm text-maivi-gray-50"
                          )}
                          onClick={() => {
                            let filtro = {
                              persona: true,
                              caso: false
                            }
                            store.dispatch(TipoFiltro(filtro));
                          }}
                        >
                          Personas
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item key="casos">
                      {({ active }) => (
                        <Link
                          to={"/"}
                          className={classNames(
                            active ? "bg-maivi-gray-10" : "",
                            "block px-4 py-2 text-sm text-maivi-gray-50"
                          )}
                          onClick={() => {
                            let filtro = {
                              persona: false,
                              caso: true
                            }
                            store.dispatch(TipoFiltro(filtro));
                          }}
                        >
                          Expedientes
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu as="div" className="ml-3 relative">
                <Menu.Button className="max-w-xs bg-maivi-gray-5 hover:bg-maivi-primaryLightBlue flex items-center text-sm rounded-full
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue">
                  <Link to={"/notificaciones"} className="flex flex-row items-end ">

                    <span className="sr-only">Notificaciones</span>
                    <BellIcon className="h-8 w-8 rounded-full p-1 -mr-3 text-maivi-primaryBlue hover:text-white" />
                    {
                      (bandeja > 0) &&
                      <span className="pt-0.2 px-1 text-white text-xs border rounded-lg bg-red-500">{bandeja}</span>
                    }
                  </Link>

                </Menu.Button>
              </Menu>
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-maivi-gray-5 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue">
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className="h-8 w-8 rounded-full p-1 text-maivi-primaryBlue" />
                    <span className="text-maivi-primaryBlue text-xs">{user?.user?.first_name} {user?.user?.last_name}</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-maivi-gray-5 ring-1 ring-maivi-gray-50 ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? "bg-maivi-gray-10" : "",
                              "block px-4 py-2 text-sm text-maivi-primaryFont"
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item key="notificaciones">
                      {({ active }) => (
                        <Link
                          to={"/notificaciones"}
                          className={classNames(
                            active ? "bg-maivi-gray-10" : "",
                            "block px-4 py-2 text-sm text-maivi-primaryFont"
                          )}
                        >
                          Notificaciones
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item key="cerrar-sesion">
                      {({ active }) => (
                        <Link
                          to={"/"}
                          className={classNames(
                            active ? "bg-maivi-gray-10" : "",
                            "block px-4 py-2 text-sm text-maivi-primaryFont"
                          )}
                          onClick={cerrarSesion}
                        >
                          Cerrar sesión
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>

            </div>
          </div>
        </div>
        <AppRouter />
      </div>
      {sedeOpen &&
        <Sedes sedeOpen={sedeOpen} setSedeOpen={setSedeOpen} />
      }
    </div>
  );
};

export default Dashboard;
