import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { TextAreaSiavi } from "../../../../components/common/TextArea";
import {
  getDeptosGestion,
  getInstitucionGestion,
  getMateriaGestion,
  getMunicipiosGestion,
  getServicios,
  getPoblaciones,
} from "../services";
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Return from "../../../../components/common/ButtonSalir";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Toggle from "../../../../components/common/Toggle";

const useFetchServicios = (token) => {
  const [state, setState] = useState({
    servicios: [],
  });

  useEffect(() => {
    getServicios(token).then((servicios) => {
      setState({
        servicios: servicios,
      });
    });
  }, [token]);

  return state;
};

const useFetchMateria = (token) => {
  const [state, setState] = useState({
    materias: [],
  });

  useEffect(() => {
    getMateriaGestion(token).then((materias) => {
      setState({
        materias: materias,
      });
    });
  }, [token]);

  return state;
};

const useFetchDepartamento = (token) => {
  const [state, setState] = useState({
    departamentos: [],
  });

  useEffect(() => {
    getDeptosGestion(token).then((departamentos) => {
      setState({
        departamentos: departamentos,
      });
    });
  }, [token]);

  return state;
};

const useFetchMunicipio = (token) => {
  const [state, setState] = useState({
    municipios: [],
  });

  useEffect(() => {
    getMunicipiosGestion(token).then((municipios) => {
      setState({
        municipios: municipios,
      });
    });
  }, [token]);

  return state;
};

const useFechtInstitucion = (token) => {
  const [state, setState] = useState({
    instituciones: [],
  });

  useEffect(() => {
    getInstitucionGestion(token).then((instituciones) => {
      setState({
        instituciones: instituciones,
      });
    });
  }, [token]);

  return state;
};

const useFetchPoblaciones = (token) => {
  const [state, setState] = useState({
    poblaciones: [],
  });

  useEffect(() => {
    getPoblaciones(token).then((data) => {
      setState({
        poblaciones: data,
      });
    });
  }, [token]);

  return state;
};

export const Crear = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  const { servicios } = useFetchServicios(token);
  const { materias } = useFetchMateria(token);
  const { departamentos } = useFetchDepartamento(token);
  const { municipios } = useFetchMunicipio(token);
  const { instituciones } = useFechtInstitucion(token);
  const { poblaciones } = useFetchPoblaciones(token);

  const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
  const [materiasSeleccionadas, setMateriasSeleccionadas] = useState([]);
  const [poblacionesSeleccionadas, setPoblacionesSeleccionadas] = useState([]);
  const [tieneMateria, setTieneMateria] = useState(false);
  const animatedComponents = makeAnimated();

  const optionsServicios = [];
  const optionsMaterias = [];
  const optionsPoblaciones = [];

  const changeSelectServicios = (e) => {
    setServiciosSeleccionados(e);
  };

  const changeSelectMaterias = (e) => {
    setMateriasSeleccionadas(e);
  };

  const changeSelectPoblaciones = (e) => {
    setPoblacionesSeleccionadas(e);
  };

  servicios?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsServicios.push(reg);
  });

  materias?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.materia,
    };
    optionsMaterias.push(reg);
  });

  poblaciones?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsPoblaciones.push(reg);
  });

  const selectClass = `mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue
   focus:border-maivi-secondaryBlue block w-full text-xs border-maivi-gray-10 rounded-md`;

  const pages = [
    {
      name: "Directorio de Derivación",
      href: "/catalogos/gestion-instituciones",
      current: false,
    },
    { name: "Crear", href: "#", current: true },
  ];

  return (
    <form autoComplete="off" onChange={customChange(values)}>
      <div className="grid grid-cols-12 -mt-32 mb-10 justify-center">
        <div className="xl:col-span-1"></div>
        <div className="col-span-12 xl:col-span-10 lg:col-span-10 md:col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
          <div className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 mt-5">
            <BreadCrumbTwo navegacion={pages} />
            <div className="flex flex-row">
              <h1 className="mb-4 text-base text-maivi-primaryFont">
                Crear directorio de derivación
              </h1>
              <Return ruta="/catalogos/gestion-instituciones" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3 border-2 rounded-md p-3">
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <Label text="*Institución" />
              <div className="">
                <select
                  id="institucion"
                  name="institucion"
                  value={values.institucion}
                  onChange={handleChange}
                  onBlur={() => {
                    let combo = document.getElementById("institucion");
                    let selectedValue = Number(combo.options[combo.selectedIndex].value);
                    instituciones?.results.forEach((item) => {
                      if (item.id === selectedValue) {
                        setTieneMateria(item.tiene_materia)
                      }
                    })
                  }}
                  className={selectClass}
                >
                  <option value="" disabled>Elegir institución</option>
                  {instituciones?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  name="institucion"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-8 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="nombre_fiscalia"
                placeholder="Oficina / Fiscalía / Instancia"
                onChange={handleChange}
                value={values.nombre_fiscalia}
                validacion={true}
                label="*Oficina / Fiscalía / Instancia"
              />
            </div>
            {/* <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <div className="">
                  <InputSiavi
                    type="text"
                    name="oficina_instancia"
                    placeholder="Oficina o Instancia que está atendiendo"
                    value={values.oficina_instancia}
                    onChange={handleChange}
                    validacion={true}
                    label="*Oficina/Instancia"
                  />
                </div>
              </div> */}
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                name="telefono"
                type="text"
                value={values.telefono}
                onChange={handleChange}
                validacion={true}
                label="*Teléfono"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                name="contacto_principal"
                type="text"
                value={values.contacto_principal}
                onChange={handleChange}
                validacion={true}
                label="Contacto principal"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                name="otros_contactos"
                type="text"
                value={values.otros_contactos}
                onChange={handleChange}
                validacion={true}
                label="Otros contactos"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                name="email_contacto"
                type="email"
                value={values.email_contacto}
                onChange={handleChange}
                validacion={true}
                label="Correo Electrónico"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="direccion"
                value={values.direccion}
                onChange={handleChange}
                validacion={true}
                label="*Dirección"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <Label text="*Departamento" />
              <select
                name="departamento"
                value={values.departamento}
                onChange={handleChange}
                className={selectClass}
              >
                <option value="">Elegir departamento</option>
                {departamentos?.results?.map((item) => {
                  return (
                    <option key={item.nombre + item.id} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
              <ErrorMessage
                name="departamento"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            {(values.departamento !== "") ?
              <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
                <Label text="*Municipio" />
                <select
                  name="municipio"
                  value={values.municipio}
                  onChange={handleChange}
                  className={selectClass}
                >
                  <option value="" disabled>Elegir municipio</option>
                  {municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                    return (
                      <option key={item.nombre + item.id} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  name="municipio"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div> : ""
            }
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="url"
                id="url"
                value={values.url}
                onChange={handleChange}
                label="Página WEB"
              />
            </div>
            <div className="col-span-12 xl:col-span-2 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="horario"
                id="horario"
                value={values.horario}
                onChange={handleChange}
                validacion={true}
                label="Horario de atención"
              />
            </div>
            {/* <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
                  <InputSiavi
                    type="text"
                    name="jurisdiccion"
                    value={values.jurisdiccion}
                    onChange={handleChange}
                    validacion={true}
                    label="Jurisdicción"
                  />
              </div> */}
            <div className="col-span-12 xl:col-span-2 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="latitud"
                value={values.latitud}
                onChange={handleChange}
                validacion={true}
                label="Latitud"
              />
            </div>
            <div className="col-span-12 xl:col-span-2 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <InputSiavi
                type="text"
                name="longitud"
                value={values.longitud}
                onChange={handleChange}
                validacion={true}
                label="Longitud"
              />
            </div>
            <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <Label text="URL Mapa" />
              <div className="">
                <InputSiavi
                  type="text"
                  name="url_maps"
                  id="url_maps"
                  value={values.url_maps}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-span-12 xl:col-span-5 lg:col-span-4 md:col-span-6 sm:col-span-6">
              <TextAreaSiavi
                label="Observaciones"
                validacion={true}
                name="observaciones"
                type="text"
                rows={2}
                columns={50}
                value={values.observaciones}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-12 xl:col-span-2 lg:col-span-4 md:col-span-6 sm:col-span-6 flex flex-col justify-center items-center">
              <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
              <div className="">
                <Toggle enabled={values.estado} name="estado" />
              </div>
            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
              <h1 className="mb-1 text-sm text-maivi-primaryFont">Servicios brindados por instituciones</h1>
              <Select
                isMulti
                placeholder="Seleccionar"
                components={animatedComponents}
                name="servicios"
                className="basic-multi-select text-xs text-maivi-primaryFont"
                classNamePrefix="seleccione"
                options={optionsServicios}
                onChange={changeSelectServicios}
              />
            </div>
            {tieneMateria &&
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 ">
                <h1 className="mb-1 text-sm text-maivi-primaryFont">Materia Legal</h1>
                <Select
                  isMulti
                  placeholder="Seleccionar"
                  components={animatedComponents}
                  name="materias"
                  className="basic-multi-select text-xs text-maivi-primaryFont"
                  classNamePrefix="seleccione"
                  options={optionsMaterias}
                  onChange={changeSelectMaterias}
                />
              </div>
            }
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 ">
              <h1 className="mb-1 text-sm text-maivi-primaryFont">Población atendida</h1>
              <Select
                isMulti
                placeholder="Seleccionar"
                components={animatedComponents}
                name="poblaciones"
                className="basic-multi-select text-xs text-maivi-primaryFont"
                classNamePrefix="seleccione"
                options={optionsPoblaciones}
                onChange={changeSelectPoblaciones}
              />
            </div>
            <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-6 items-end flex">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  values.serviciosAgregados = serviciosSeleccionados;
                  values.materiasAgregadas = materiasSeleccionadas;
                  values.poblacionesAgregadas = poblacionesSeleccionadas;
                  handleSubmit();
                }}
                disabled={isSubmitting}
                text="Crear"
              />
            </div>
            {isSubmitting && <Spinner />}
          </div>
        </div>
        <div className="xl:col-span-1"></div>
      </div>
    </form>
  );
};
