import Spinner from "./Spinner";

export default function BotonesConfirmar(props) {
  return (
    <div className="py-3 text-center">
      <button
        className="inline-flex w-5/12 m-1 justify-center py-2 border border-transparent shadow-sm text-sm font-medium 
            rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
            focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
        onClick={props.cancelar}
      >
        No
      </button>
     {
      props?.isLoading ? 
      <Spinner /> :
      <button
      className="inline-flex w-5/12 m-1  justify-center py-2 border border-transparent shadow-sm text-sm font-medium 
          rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
          focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
      onClick={props.aceptar}
    >
      Sí
    </button>
     }
      <br />
      {
        (props.isloading) && <Spinner />
      }
    </div>
  );
}
