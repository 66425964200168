import { formReducer } from "./formReducer";
import { loginReducer } from "./login";
import { statusReducer } from "./auth/statusReducer";
import { formPersonaReducer } from "./formRegisterPersona";
import { inputChange } from "./search";
import { ChangeSede } from "./sede";
import { formOrientacion } from "./orientacionReducer";
import { ChangeOrientacionTab } from "./orientacionTab";
import { ChangeCasoTab } from "./casoTabs";
import { TipoFiltro } from "./tipoFiltro";
import { ChangeFavoritos } from "./favoritos";
import { ChangePermisos } from "./permisos";
let index = {
    formReducer,
    loginReducer,
    statusReducer,
    formPersonaReducer,
    inputChange,
    ChangeSede,
    formOrientacion,
    ChangeOrientacionTab,
    ChangeCasoTab,
    TipoFiltro,
    ChangeFavoritos,
    ChangePermisos
};
export default index;
