import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { buscarPersona } from "./services";
import { useSelector } from "react-redux";
import {
  getPaisVictimario,
  getTipoDocumentoVictimario,
} from "../sindicados/services";
import Label from "../../../../../components/common/Label";
import ModalGenerico from "../../../../../components/common/Modal";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import Toggle from "../../../../../components/common/Toggle";

const useFetchPais = (token) => {
  const [state, setState] = useState({
    paises: [],
  });

  useEffect(() => {
    getPaisVictimario(token).then((paises) => {
      setState({
        paises: paises,
      });
    });
  }, [token]);

  return state;
};

const useFetchTipoDocumento = (token) => {
  const [state, setState] = useState({
    tipo_identificacion: [],
  });

  useEffect(() => {
    getTipoDocumentoVictimario(token).then((tipo_identificacion) => {
      setState({
        tipo_identificacion: tipo_identificacion,
      });
    });
  }, [token]);

  return state;
};

export const Identificacion = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { paises } = useFetchPais(token);
  const { tipo_identificacion } = useFetchTipoDocumento(token);
  const [personaExiste, setPersonaExiste] = useState(false);
  const [persona, setPersona] = useState({});
  const [state, setState] = useState({
    pais: "",
    no_documento: "",
    tipo_documento: "",
  });

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function busqueda() {
    if (state.pais !== "" && state.no_documento !== "" && state.tipo_documento !== "") {
      buscarPersona(state.pais, state.tipo_documento, state.no_documento, token).then((data) => {
        if (data.count > 0) {
          setPersonaExiste(true);
          setPersona(data.results[0]);
        }
      });
    }
  }

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="sm:col-span-6 xl:col-span-2 col-span-12 flex flex-col justify-center items-center">
        <Label text="Victima fallecida" />
        <Toggle
          enabled={props.values.victima_fallecida}
          name="victima_fallecida"
        />
      </div>
      <div className="sm:col-span-6 xl:col-span-2 col-span-12 flex flex-col justify-center items-center">
        <Label text="Sin documento" />
        <Toggle
          enabled={props.values.sin_documento}
          name="sin_documento"
        />
      </div>
      {
        (props.values.sin_documento) ? "" :
        <div className="sm:col-span-6 xl:col-span-3 col-span-12 ">
        <Label text="Tipo documento" />
        <select
          name="tipo_documento"
          value={props.values?.tipo_documento}
          className={props.selectClass}
          onChange={(e) => {
            props.setFieldValue('tipo_documento', e.target.value);
            handleInputChange(e);
          }}
          onBlur={() => {
            busqueda();
          }}
        >
          <option value="" >
            Elegir documento
          </option>
          {tipo_identificacion?.results?.map((item) => {
            return (
              <option key={item.nombre + "P"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="tipo_documento"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div> 
      }
      {
        (props.values.sin_documento) ? "" :
        <div className="sm:col-span-6 xl:col-span-2 lg:col-span-2 col-span-12 ">
        <InputSiavi
          type="text"
          name="no_documento"
          value={props.values?.no_documento}
          onChange={(e) => {
            props.setFieldValue('no_documento', e.target.value);
            handleInputChange(e);
          }}
          label="No. documento"
          validacion={true}
          onBlur={() => {
            busqueda();
          }}
        />
      </div>
      }
      <div className="sm:col-span-6 xl:col-span-3 col-span-12 ">
        <Label text="País" />
        <select
          name="pais"
          value={props.values?.pais}
          className={props.selectClass}
          onChange={(e) => {
            props.setFieldValue('pais', e.target.value);
            handleInputChange(e);
          }}
          onBlur={() => {
            busqueda();
          }}
        >
          <option value="" >
            Elegir País
          </option>
          {paises?.results?.map((item) => {
            return (
              <option key={item.nombre + "P"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="pais"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>

      {personaExiste && (
        <ModalGenerico
          title={`Ya existe una persona registrada con esos datos, ¿desea consultarlo?`}
          open={personaExiste}
          closeModal={() => { }}
          cerrar={() => {
            setPersonaExiste(false);
          }}
          children={
            <div className="py-3 text-center">
              <BotonesConfirmar
                aceptar={() => {
                  setPersonaExiste(false);
                  props.setFieldValue("primer_nombre", persona?.primer_nombre);
                  props.setFieldValue("segundo_nombre", persona?.segundo_nombre);
                  props.setFieldValue("tercer_nombre", persona?.tercer_nombre);
                  props.setFieldValue("primer_apellido", persona?.primer_apellido);
                  props.setFieldValue("segundo_apellido", persona?.segundo_apellido);
                  props.setFieldValue("fecha_nacimiento", persona?.fecha_nacimiento);
                  props.setFieldValue("edad", persona?.edad);
                  props.setFieldValue("nacionalidad", persona?.nacionalidad);
                  props.setFieldValue("pseudonimo", persona?.como_le_gusta_que_le_llamen);

                  props.setFieldValue("sexo", persona?.sexo);
                  props.setFieldValue("estado_civil", persona?.estado_civil);
                  props.setFieldValue("orientacion_sexual", persona?.orientacion_sexual);
                  props.setFieldValue("departamento", persona?.departamento);
                  props.setFieldValue("municipio", persona?.municipio);
                  props.setFieldValue("lee_escribe", persona?.lee_escribe);
                  props.setFieldValue("escolaridad", persona?.escolaridad);
                  props.setFieldValue("profesion", persona?.profesion);
                  props.setFieldValue("origen_etnico", persona?.origen_etnico);
                  props.setFieldValue("idioma", persona?.idioma);
                  props.setFieldValue("idioma_maya", persona?.idioma_maya);
                  props.setFieldValue("religion", persona?.religion);
                  props.setFieldValue("relacion_con_la_victima", persona?.relacion_con_victimario);
                  props.setFieldValue("persona", persona?.id);
                  props.setFieldValue("idPersona", persona?.id);
                  props.setFieldValue("editarPersona", true);
                  props.setFieldValue("nueva_persona",false);
                }}
                cancelar={() => {
                  setPersonaExiste(false);
                  props.resetForm({});
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};
