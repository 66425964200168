import { useState } from "react";
import { PrinterIcon, TrashIcon } from "@heroicons/react/solid";
import { EyeIcon } from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { SelectorIcon } from "@heroicons/react/solid";
import { PencilAltIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";
import moment from "moment";
import { XCircleIcon } from "@heroicons/react/outline";

const {
    REACT_APP_BITACORA_URL
} = process.env;

const TableGeneric = (props) => {
    const btnOpCls =
        "inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue";
    const thTableCls =
        "px-2 py-2 text-left text-sm font-medium text-maivi-primaryFont tracking-wider";
    const spanBool =
        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full ";
    const spanGreen = "bg-green-100 text-green-800";
    const spanRed = "bg-red-100 text-red-800";

    const permisos = useSelector(state => state.loginReducer.data.usuario.user.permissions);
    let permisoEliminar = false;
    let permisoEditar = false;
    let permisoVer = false;
    const [registrosSeleccionados, setRegistrosSeleccionados] = useState([])

    permisos.forEach(permiso => {
        let editar = `change_${props.permiso}`;
        let eliminar = `delete_${props.permiso}`;
        let keyView = `view_${props.permiso}`;

        if (editar === permiso.codename) {
            permisoEditar = true;
        }
        if (eliminar === permiso.codename) {
            permisoEliminar = true;
        }
        if (keyView === permiso.codename) {
            permisoVer = true;
        }
    });

    let next = props.dataTable?.next;
    let previous = props.dataTable?.previous;
    let count = props.dataTable?.count;

    if (props.permiso === 'bitacora') {
        if (props.dataTable.page === 1) {
            previous = null;
            next = `${REACT_APP_BITACORA_URL}/v1/bitacora?page=${props.dataTable.page + 1}`;
            count = props.dataTable.total;
        } else if (props.dataTable.page === props.dataTable.pages) {
            previous = `${REACT_APP_BITACORA_URL}/v1/bitacora?page=${props.dataTable.page - 1}`;;
            next = null;
            count = props.dataTable.total;
        }
        else {
            previous = `${REACT_APP_BITACORA_URL}/v1/bitacora?page=${props.dataTable.page - 1}`;;
            next = `${REACT_APP_BITACORA_URL}/v1/bitacora?page=${props.dataTable.page + 1}`;
            count = props.dataTable.total;
        }
    }

    const setOrder = (order) => {
        if (order === props.order) {
            props.setOrder(`-${order}`);
        }
        else if (`-${order}` === props.order) {
            props.setOrder(`${order}`);
        } else {
            props.setOrder(`${order}`);
        }
    }

    const compomentTD = (index, data, column) => {

        const dataValue = () => {
            if (column.value !== null) {
                let val = column.value.split('.')
                let retVal = data;
                let firt = 0;

                val.forEach(ele => {
                    retVal = firt === 0 ? (data && data[ele]) : (retVal && retVal[ele]);
                    firt = 1;
                });
                return (retVal?.length >= 40) ? retVal.substring(0, 40) + '...' : retVal;
            }

            if (column.values !== undefined) {
                let valRetorno = '';

                column.values.forEach(value => {
                    let val = value.split('.');
                    let retVal = data;
                    let firt = 0;

                    val.forEach(ele => {
                        retVal = firt === 0 ? (data && data[ele]) : (retVal && retVal[ele]);
                        firt = 1;
                    });
                    let valNew = (retVal?.length >= 40) ? retVal.substring(0, 40) + '...' : retVal;
                    if (retVal !== null) {
                        valRetorno = `${valRetorno} ${valNew}`;
                    }
                })
                return valRetorno;
            }
        }

        if(column.tipo === "datetime"){
            return (
                <td key={`${index}-${dataValue()}-TD`} className="px-4 py-2 whitespace-nowrap text-xs text-maivi-primaryFont">
                   <span>{(dataValue() === undefined || dataValue() === null) ? '' : moment(dataValue()).format("DD-MM-YYYY HH:mm:ss") }</span>
                </td>
            ); 
        }

        if(column.tipo === "date"){
            return (
                <td key={`${index}-${dataValue()}-TD`} className="px-4 py-2 whitespace-nowrap text-xs text-maivi-primaryFont">
                   <span>{(dataValue() === undefined || dataValue() === null) ? '' : moment(dataValue()).format("DD-MM-YYYY") }</span>
                </td>
            ); 
        } else {
            return (
                <td key={`${index}-${dataValue()}-TD`} className="px-4 py-2 whitespace-nowrap text-xs text-maivi-primaryFont">
                    {column.tipo === "boolean" ? (
                        <span
                            className={`${spanBool} ${dataValue() ? spanGreen : spanRed
                                }`}
                        >
                            {column.text[dataValue()]}
                        </span>
                    ) : (
                        dataValue()
                    )}
                </td>
            );
        }
    };

    const customSort = (th) => {
        if (th.ordenar === props.order) {
            return <ChevronUpIcon className="h-6 w-6 text-maivi-primaryFont hover:text-maivi-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
        }
        if (`-${th.ordenar}` === props.order) {
            return <ChevronDownIcon className="h-6 w-6 text-maivi-primaryFont hover:text-maivi-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
        }

        return <SelectorIcon className="h-6 w-6 text-maivi-primaryFont hover:text-maivi-primaryLightBlue cursor-pointer" onClick={() => setOrder(th.ordenar)} />
    }

    return (
        <>
            <table className="min-w-full divide-y divide-maivi-gray-10">
                <thead className="bg-maivi-gray-5">
                    <tr>
                        {props?.select &&
                            <th scope="col" className={thTableCls}>
                                Seleccionar
                            </th>
                        }
                        {props.tableColumns.map((th) => (
                            <th key={`TH${th.title}`} scope="col" className={`${thTableCls}`}>
                                <span className="flex">
                                    {th.title}
                                    {th.ordenar !== undefined &&
                                        customSort(th)
                                    }
                                </span>
                            </th>
                        ))}
                        <th scope="col" className={thTableCls}>
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                    {props?.dataTable.results?.map((registro, index) => (
                        <tr key={`${index}-TR-${registro.id}`} className={`hover:bg-maivi-gray-10 ${registro?.fondo}`}>
                            {props?.select &&
                                <td className="px-6 py-2 whitespace-nowrap text-xs text-maivi-primaryFont">
                                    <input
                                        name="checkTable"
                                        type="checkbox"
                                        className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                                        onChange={(e) => {
                                            if (e.target.checked === true) {
                                                setRegistrosSeleccionados([
                                                    ...registrosSeleccionados,
                                                    registro,
                                                ]);
                                            } else {
                                                let registros = registrosSeleccionados.filter(
                                                    (x) => x.id !== registro.id
                                                );
                                                setRegistrosSeleccionados(registros);
                                            }

                                        }}
                                        onBlur={() => {
                                            props.registrosSeleccionados(registrosSeleccionados);
                                        }}
                                    />
                                </td>
                            }
                            {props.tableColumns.map((td, index) => compomentTD(index, registro, td))}
                            <td className="px-3 py-1.2 whitespace-nowrap text-xs text-maivi-secondaryRed">
                                {(permisoVer && props.showVer !== false) &&
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.handleVer(registro);
                                        }}
                                        className={`hover:bg-maivi-primaryLightBlue ${btnOpCls} tooltip`}
                                    >
                                        <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext">Ver detalle</span>
                                    </button>
                                }
                                  {(permisoVer && props.showCancelar === true) &&
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.handleCancelar(registro);
                                        }}
                                        className={`hover:bg-red-300 ${btnOpCls} tooltip`}
                                    >
                                        <XCircleIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                        <span className="tooltiptext">Cancelar</span>
                                    </button>
                                }
                                {
                                    (permisoEditar === true && props.showEditar !== false) &&
                                    <button
                                        type="button"
                                        className={`hover:bg-maivi-primaryLightBlue ${btnOpCls} tooltip`}
                                        onClick={() => {
                                            props.handleEditar(registro);
                                        }}
                                    >
                                        <PencilAltIcon className="h-6 w-6 text-maivi-primaryLightBlue hover:text-white" />
                                        <span className="tooltiptext">Editar</span>
                                    </button>
                                }
                                {
                                    props.addAccion &&
                                    props.opcion1(registro)
                                }
                                 {props.showPrint === true &&
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.handlePrint(registro);
                                        }}
                                        className={`hover:bg-maivi-primaryLightBlue ${btnOpCls} tooltip`}
                                    >
                                        <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext">Imprimir</span>
                                    </button>
                                }

                                {(permisoEliminar && props.showEliminar !== false) &&
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.handleEliminar(registro);
                                        }}
                                        className={`hover:bg-maivi-secondaryRed ${btnOpCls} tooltip`}
                                    >
                                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                        <span className="tooltiptext">Eliminar</span>
                                    </button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {props.pagination && (
                <Pagination
                    next={next}
                    prev={previous}
                    count={count}
                    fetchPage={props.fetchPage}
                />
            )}
        </>
    );
};

export default TableGeneric;
