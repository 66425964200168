import { React, useEffect, useState } from "react";
import { InputSiavi } from "../../../../../components/common/InputText";
import { getPaisVictimario, getTipoDocumentoVictimario, buscarSindicado } from "./services";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../../components/common/Select";
import Toggle from "../../../../../components/common/Toggle";
import ModalGenerico from "../../../../../components/common/Modal";
import Label from "../../../../../components/common/Label";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";

const useFetchPais = (token) => {
  const [state, setState] = useState({
    paises: [],
  });

  useEffect(() => {
    getPaisVictimario(token).then((paises) => {
      setState({
        paises: paises,
      });
    });
  }, [token]);

  return state;
};

const useFetchTipoDocumento = (token) => {
  const [state, setState] = useState({
    tipo_identificacion: [],
  });

  useEffect(() => {
    getTipoDocumentoVictimario(token).then((tipo_identificacion) => {
      setState({
        tipo_identificacion: tipo_identificacion,
      });
    });
  }, [token]);

  return state;
};

export const IdentificacionVictimario = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { paises } = useFetchPais(token);
  const { tipo_identificacion } = useFetchTipoDocumento(token);

  const [personaExiste, setPersonaExiste] = useState(false);
  const [persona, setPersona] = useState({});
  const [state, setState] = useState({
    pais: "",
    tipo_identificacion: "",
    no_identificacion: "",
  });

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function busqueda() {
    if (
      state.pais !== "" &&
      state.no_identificacion !== "" &&
      state.tipo_identificacion !== ""
    ) {
      buscarSindicado(
        state.pais,
        state.tipo_identificacion,
        state.no_identificacion,
        token
      ).then((data) => {
        if (data.count > 0) {
          setPersonaExiste(true);
          props.setFieldValue('actualizar_expediente', true);
          setPersona(data.results[0]);
        }
      });
    }
  }

  return (
    <div>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 xl:col-span-2 lg:col-span-6 md:col-span-6  mb-2 flex flex-col items-center justify-center">
          <Label text={(props.values.conoce_al_sindicado) ? "Sindicado conocido" : "Sindicado desconocido"} />
          <Toggle enabled={props.values.conoce_al_sindicado} name="conoce_al_sindicado" />
        </div>
        {
          props.values.conoce_al_sindicado &&
          <div className="col-span-12 xl:col-span-2 lg:col-span-6 md:col-span-6  mb-2 flex flex-col items-center justify-center">
            <Label text="Tiene Identificación" />
            <Toggle enabled={props.values.tiene_identificacion} name="tiene_identificacion" />
          </div>
        }
        {
          (props.values.tiene_identificacion && props.values.conoce_al_sindicado) &&
          <div className="sm:col-span-6 xl:col-span-2 col-span-12 ">
            <SelectSiavi
                label="Tipo documento"
                validacion={true}
                name="tipo_identificacion"
                value={props.values.tipo_identificacion}
                onChange={(e) => {
                  props.setFieldValue("tipo_identificacion", e.target.value);
                  handleInputChange(e);
                }}
                onBlur={() => {
                  busqueda();
                }}
                valueInit=""
                selectInit="Elegir Tipo Identificación"
                list={
                  tipo_identificacion?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })
                }
            />
          </div>
        }
        {(props.values.tiene_identificacion && props.values.conoce_al_sindicado) &&
          <div className="sm:col-span-6 xl:col-span-2 col-span-12 ">
            <InputSiavi
              label="No. documento"
              validacion={true}
              type="text"
              name="no_identificacion"
              value={props.values.no_identificacion}
              onChange={(e) => {
                props.setFieldValue("no_identificacion", e.target.value);
                handleInputChange(e);
              }}
              onBlur={() => {
                busqueda();
              }}
            />
          </div>
        }
        {
          (props.values.tiene_identificacion && props.values.conoce_al_sindicado) &&
          <div className="sm:col-span-6 xl:col-span-2 col-span-12 ">
            <SelectSiavi
                label="País"
                validacion={true}
                name="pais"
                value={props.values.pais}
                onChange={(e) => {
                  props.setFieldValue("pais", e.target.value);
                  handleInputChange(e);
                }}
                onBlur={() => {
                  busqueda();
                }}
                valueInit=""
                selectInit="Elegir País"
                list={
                  paises?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })
                }
            />
          </div>
        }
      </div>
      {personaExiste && (
        <ModalGenerico
          title={`Ya existe una persona registrada con esos datos, ¿desea consultarlo?`}
          open={personaExiste}
          closeModal={() => { }}
          cerrar={() => {
            setPersonaExiste(false);
          }}
          children={
            <div className="py-3 text-center">
              <BotonesConfirmar 
                aceptar={()=>{
                  setPersonaExiste(false);
                  props.setFieldValue('primer_nombre', persona?.primer_nombre);
                  props.setFieldValue('segundo_nombre', persona.segundo_nombre);
                  props.setFieldValue('tercer_nombre', persona.tercer_nombre);
                  props.setFieldValue('primer_apellido', persona.primer_apellido);
                  props.setFieldValue('segundo_apellido', persona.segundo_apellido);
                  props.setFieldValue('fecha_nacimiento', persona.fecha_nacimiento);
                  props.setFieldValue('nacionalidad', persona?.nacionalidad?.id);
                  props.setFieldValue('edad', persona?.edad);
                  props.setFieldValue('pseudonimo', persona?.pseudonimo);
                  props.setFieldValue('sexo', persona?.sexo);
                  props.setFieldValue('pertenece_a_pandilla', persona?.pertenece_a_pandilla);
                  props.setFieldValue('nombre_pandilla', persona?.nombre_pandilla);
                  props.setFieldValue('tiene_tatuajes', persona?.tiene_tatuajes);
                  props.setFieldValue('especificacion_tatuaje', persona?.especificacion_tatuaje);
                  props.setFieldValue('uso_de_arma_de_fuego', persona?.uso_de_arma_de_fuego);
                  props.setFieldValue('altura', persona?.altura);
                  props.setFieldValue('peso', persona?.peso);
                  props.setFieldValue('complexion', persona?.complexion);
                  props.setFieldValue('color_forma_cabello', persona?.color_forma_cabello);
                  props.setFieldValue('color_tez', persona?.color_tez);
                  props.setFieldValue('tiene_cicatrices', persona?.tiene_cicatrices);
                  props.setFieldValue('tiene_barba', persona?.tiene_barba);
                  props.setFieldValue('tiene_bigote', persona?.tiene_bigote);
                  props.setFieldValue('forma_de_cejas', persona?.forma_de_cejas);
                  props.setFieldValue('color_ojos', persona?.color_ojos);
                  props.setFieldValue('usa_anteojos', persona?.usa_anteojos);
                  props.setFieldValue('forma_de_nariz', persona?.forma_de_nariz);
                  props.setFieldValue('caracteristicas_dentadura', persona?.caracteristicas_dentadura);
                  props.setFieldValue('vestimenta', persona?.vestimenta);

                  props.setFieldValue('estado_civil', persona?.estado_civil?.id);
                  props.setFieldValue('orientacion_sexual', persona?.orientacion_sexual?.id);
                  props.setFieldValue('departamento', persona?.departamento?.id);
                  props.setFieldValue('municipio', persona?.municipio?.id);
                  props.setFieldValue('lee_escribe', persona?.lee_escribe);
                  props.setFieldValue('escolaridad', persona?.escolaridad?.id);
                  props.setFieldValue('profesion', persona?.profesion?.id);
                  props.setFieldValue('religion', persona?.religion?.id);
                  props.setFieldValue('origen_etnico', persona?.origen_etnico?.id);
                  props.setFieldValue('idioma', persona?.idioma?.id);
                  props.setFieldValue('otro_idioma', persona?.otro_idioma);
                  props.setFieldValue('en_migracion', persona?.en_migracion);
                  props.setFieldValue('pais', persona?.pais?.id);
                  props.setFieldValue('pais_origen', persona?.pais_origen?.id);
                  props.setFieldValue('pais_destino', persona?.pais_destino?.id);
                  props.setFieldValue('relacion_con_la_victima', persona?.relacion_con_la_victima?.id);
                  props.setFieldValue('referencia_ubicacion', persona?.referencia_ubicacion);
                  props.setFieldValue('observaciones', persona?.observaciones);

                  props.setFieldValue('conoce_al_sindicado', persona?.conoce_al_sindicado);
                  props.setFieldValue('tiene_identificacion', persona?.tiene_identificacion);
                  props.setFieldValue('no_identificacion', persona?.no_identificacion);
                  props.setFieldValue('tipo_identificacion', persona?.tipo_identificacion?.id);
                  props.setFieldValue('idSindicado', persona?.id);
                  
                }}
                cancelar={()=>{
                  setPersonaExiste(false);
                  props.resetForm({});
              
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};
