/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { personas, getPersonas, deletePersona, exportarData } from "./services";
import { FilterIcon, UserIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./Detalle";
import { OrientacionesPersona } from "./orientaciones";
import { useSelector } from "react-redux";
import { RefreshIcon } from "@heroicons/react/outline";
import { ChangeSearch } from '../../../../app/redux/actions/search';
import { CambiarEstado } from "./inactivar_persona";
import { GearIcon } from "../../../../helpers/icons";
import { Acciones } from "./acciones/acciones";
import { ValidacionExpedientes } from "./validacion_expedientes";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { store } from '../../../../app/redux/store/store';
import { clases } from "../../../../helpers/clases";
import CustomTable from "../../../../components/common/Table";
import toast from 'react-hot-toast';
import ModalGenerico from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import FormEliminar from "../../../../components/common/FormEliminar";
import ModalTwo from "../../../../components/common/ModalTwo";
import ExportButton from "../../../../components/common/ExportButton";
import Filtros from "./filtros";

const tableColumns = [
  { title: "Tipo Documento", value: "tipo_documento_detalle.nombre", tipo: "text" },
  { title: "Identificacion", value: "no_documento", tipo: "text" },
  { title: "Pais", value: "pais_detalle.nombre", tipo: "text" },
  { title: "Nombre", value: null, values: ["primer_nombre", "segundo_nombre", "primer_apellido", "segundo_apellido"], tipo: "text" },
  { title: "Fecha de Nacimiento", value: "fecha_nacimiento", tipo: "date" },
];

export const TablePersonas = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search)
  const [isEliminar, setEliminar] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [persona, setPersona] = useState("");
  const [id, setId] = useState("");
  const [verDetalle, setDetalle] = useState(false);
  const [userDetalle, setUserDetalle] = useState({});
  const [agregar, setAgregar] = useState(false);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [orientaciones] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const [registro, setRegistro] = useState("");
  const [cambiarEstado, setCambiarEstado] = useState(false);
  const [agregarAccion, setAgregarAccion] = useState(false);
  const [validarExpedientes, setValidarExpediente] = useState(false);
  const [expedientesPersona, setExpedientesPersona] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [isPrimera] = useState(false);
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "victimas";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "gestionpersona";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, token).then(async (data) => {
      const fileName = 'victimas';
      let dataExport = data;

      let columns = [
        { name: "ID", value: "id" },
        { name: "Primer nombre", value: "primer_nombre" },
        { name: "Segundo nombre", value: "segundo_nombre" },
        { name: "Otros nombres", value: "otros_nombres" },
        { name: "Primer apellido", value: "primer_apellido" },
        { name: "Segundo apellido", value: "segundo_apellido" },
        { name: "Apellido casada", value: "apellido_casada" },
        { name: "Departamento de residencia", value: "departamento" },
        { name: "Municipio de residencia", value: "municipio" },
        { name: "Estado civil", value: "estado_civil" },
        { name: "Nacionalidad", value: "nacionalidad" },
        { name: "Idioma", value: "idioma" },
        { name: "Religión", value: "religion" },
        { name: "Fecha nacimiento", value: "fecha_nacimiento", tipo: 'fecha' },
        { name: "Sexo", value: "sexo" },
        { name: "Orientación sexual", value: "orientacion_sexual" },
        { name: "Identidad género", value: "identidad_genero" },
        { name: "Escolaridad", value: "escolaridad" },
        { name: "Profesión u oficio", value: "profesion" },
        { name: "Tipo de dispacidad", value: "discapacidad" },
        { name: "Origen étnico", value: "origen_etnico" },
        { name: "País origen", value: "pais_origen" },
        { name: "Querellante", value: "querellante", tipo: 'bool' },
        { name: "Víctima", value: "victima", tipo: "bool" },
        /*
                { name: "Como le gusta que le llamen", value: "como_le_gusta_que_le_llamen" },
                { name: "Correo electronico", value: "correo_electronico" },
                { name: "Edad", value: "edad" },
                { name: "En migracion", value: "en_migracion" },
                { name: "Fecha creacion", value: "fecha_creacion" },
                { name: "Fecha primera visita", value: "fecha_primera_visita" },
                { name: "Idioma maya", value: "idioma_maya" },
                { name: "Idioma preferencia", value: "idioma_preferencia" },
                { name: "Lee escribe", value: "lee_escribe" },
                { name: "No. documento", value: "no_documento" },
                { name: "Origen etnico maya", value: "origen_etnico_maya" },
                { name: "Otros idioms", value: "otros_idioms" },
                { name: "Pais", value: "pais" },
                { name: "Pais destino", value: "pais_destino" },
                { name: "Primera visita", value: "primera_visita" },
                { name: "Relacion con victimario", value: "relacion_con_victimario" },
                { name: "Require atencion diferenciada", value: "require_atencion_diferenciada" },
                { name: "Sede", value: "sede" },
                { name: "Sin documento", value: "sin_documento" },
                { name: "Situacion laboral", value: "situacion_laboral" },
                { name: "Telefono", value: "telefono" },
                { name: "Tipo documento", value: "tipo_documento" },
                { name: "Victima fallecida", value: "victima_fallecida" }
                */

      ];
      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  const fetchPersonas = () => {
    setTableLoading(true);
    personas(urlFetch, search, filters, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetPersonas = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getPersonas(urlFetch, search, filters, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  useEffect(fetchPersonas, [urlFetch, reFetch, filters, token]);
  useEffect(fetchGetPersonas, [urlFetch, search, token, filters]);

  const handleVer = (item) => {
    setUserDetalle(item);
    setDetalle(true);
    setPersona(item);
  };

  const handleAgregar = (item) => {
    setCargando(true);
    setPersona(item);
    setAgregar(true);
  };

  const handleEliminar = (id, nombre, registro) => {
    setId(id);
    setRegistro(registro);
    setEliminar(true);
  };

  const linkEditar = `/funcionalidades-generales/actualizar/`;
  const pages = [
    { name: "Funcionalidades", href: "/funcionalidades-generales", current: false },
    { name: "Victimas", href: "#", current: true },
  ];

  const opcion = (registro) => {
    return (
      <span>
        {(CheckPermiso('Victima.AgregarAccion') === true) &&    //mm permiso agregado        
          <button
            type="button"
            className={clases.addIcon2}
            onClick={() => {
              setRegistro(registro);
              setExpedientesPersona(true);
            }}
          >

            <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue hover:text-white" />
            <span className="tooltiptext">Agregar acción</span>
          </button>
        }
        {(CheckPermiso('InactivarVictimas') === true) &&    //mm permiso agregado
          <button
            type="button"
            className={clases.trashIcon}
            onClick={() => {
              setCambiarEstado(true);
              setRegistro(registro);
            }}
          >
            <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
            <span className="tooltiptext">Inactivar persona</span>
          </button>
        }
      </span>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <div>
            <Link to="/funcionalidades-generales/registrar" type="button">
              {(CheckPermiso('CrearVictimas') === true) &&    //mm permiso agregado
                <ButtonAdd text="Crear" type="button" />
              }
            </Link>
            <ButtonAdd
              className="ml-2"
              text="Refrescar"
              type="button"
              onClick={() => {
                window.location.reload();
              }}
              icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
            />
            <ButtonAdd
              className="ml-2"
              text="Filtrar"
              type="button"
              onClick={() => {
                setOpenFilters(true);
              }}
              icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
            />
            {(CheckPermiso('ExpVictimasExcel') === true) &&    //mm permiso agregado
              <ExportButton
                handlePdf={() => {
                  exportData('pdf');
                }}
                show={'xls'}
                handleExcel={() => {
                  exportData('xls');
                }}
              />
            }   {/* //mm cerrar permiso */}
          </div>
        )}
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            handleVer={handleVer}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showEliminar={(CheckPermiso('EliminarVictimas') === true) }
            showEditar={(CheckPermiso('EditarVictimas') === true)}
            link= {true}
            linkTo=  {linkEditar}            
            handleEliminar= {handleEliminar}
            agregar= {CheckPermiso('Victimas.AgregaVisita')}   //mm permiso agregado
            handleAgregar={handleAgregar}
            addAccion={true}
            opciones={opcion}
          />
          
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${registro.primer_nombre} ${registro.primer_apellido}?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => { setEliminar(false) }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={async () => {
                    setLoading(true);
                    await deletePersona(id, token)
                      .then((data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                          toast.success('Registro eliminado con éxito...!');
                          setLoading(false);
                          setEliminar(false);
                          setReFetch(true);
                        }
                      })
                      .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                          setEliminar(false);
                          setLoading(false);
                          toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                          setEliminar(false);
                          setLoading(false);
                          toast.error('Error interno comuniquese con el administrador')
                        }
                      });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalTwo
              title={"Detalle de persona"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <FormDetalle detalle={userDetalle} cancelar={setEliminar} Persona={persona} />
              }
            />
          )}
          {agregar && (
            <ModalGenerico
              title={"¿Desea agregar la persona al listado de visitas?"}
              open={agregar}
              closeModal={() => { }}
              cerrar={() => {
                setAgregar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                </div>
              }
              children={
                <OrientacionesPersona
                  orientaciones={orientaciones}
                  Persona={persona}
                  cancelar={() => { setAgregar(false) }}
                  setReFetch={setReFetch}
                  cargando={cargando}
                  isFirst={isPrimera}
                />
              }
            />
          )}
          {cambiarEstado && (
            <ModalGenerico
              title={"¿Desea cambiar el estado de la persona?"}
              open={cambiarEstado}
              closeModal={() => { }}
              cerrar={() => {
                setCambiarEstado(false);
              }}
              children={
                <CambiarEstado
                  registro={registro}
                  setCambiarEstado={setCambiarEstado}
                  setReFetch={setReFetch}
                />
              }
            />
          )}

          {validarExpedientes && (
            <ModalGenerico
              title={"¿Desea cambiar el estado de la persona?"}
              open={validarExpedientes}
              closeModal={() => { }}
              cerrar={() => {
                setValidarExpediente(false);
              }}
              children={
                <CambiarEstado
                  registro={registro}
                  setCambiarEstado={setCambiarEstado}
                  setReFetch={setReFetch}
                />
              }
            />
          )}


          {expedientesPersona && (
            <ModalGenerico
              title={"¿Crear accion?"}
              open={expedientesPersona}
              closeModal={() => { }}
              cerrar={() => {
                setExpedientesPersona(false);
              }}
              children={
                <ValidacionExpedientes
                  registro={registro}
                  setExpedientesPersona={setExpedientesPersona}
                  setAgregarAccion={setAgregarAccion}
                />
              }
            />
          )}

          {agregarAccion && (
            <ModalTwo
              title={"Agregar acción"}
              open={agregarAccion}
              closeModal={() => { }}
              cerrar={() => {
                setAgregarAccion(false);
              }}
              children={
                <Acciones
                  registro={registro}
                  caso={{}}
                  expediente={{}}
                />
              }
            />
          )}

          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => { }}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={
                <Filtros
                  setFilters={setFilters}
                  setOpenFilters={setOpenFilters}
                  setUrlFech={setUrlFech}
                />
              }
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
