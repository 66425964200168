import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const login = async (loginId, password) => {
    try {
        let body = {
            loginId: loginId,
            password: password
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/seguridad/login/`,
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const resetPassword = async (loginId, password, newPassword) => {
    try {
        let body = {
            loginId: loginId,
            currentPassword: password,
            password: newPassword
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/seguridad/cambiar-contrasena/`,
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const sendPassword = async (loginId) => {
    let body = {
        loginId: loginId,
    }

    try {
        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/seguridad/olvide-contrasena/`,
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntas = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/preguntas/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const savePreguntas = async (pregunta, respuesta, token) => {
    let body = {
        pregunta: pregunta,
        respuesta: respuesta
    }

    try {
        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/catalogos/preguntauser/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntasUser = async (username) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/preguntauser/?user__username=${username}`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const comprobarPreguntas = async (data) => {
    let body = {
        pregunta1: data.pregunta1,
        respuesta1: data.respuesta1,
        pregunta2: data.pregunta2,
        respuesta2: data.respuesta2,
        username: data.username
    }

    try {
        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/seguridad/preguntasecreta/`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export default login;