import { React } from "react";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { InputSiavi } from "../../../../../components/common/InputText";
import Label from "../../../../../components/common/Label";

const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
sm:text-sm  rounded-md bg-white text-maivi-primaryFont`;

export const TratamientoPreliminar = (props) => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="col-span-6 mt-5">
          <Label text="Plan" />
          <InputSiavi
            name="plan"
            value={props.values.plan}
            onChange={props.handleChange}
          />
        </div>
        <div className="col-span-6 mb-4 mt-3">
          <Label text="Servicio" />
          <select
            name="servicio"
            value={props.values.servicio}
            onChange={props.handleChange}
            className={selectClass}
          >
            <option value={null}>Elegir servicio</option>
            {props?.servicios?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-span-6 mt-3">
          <Label text="Comentarios" />
          <TextAreaSiavi
            row={2}
            cols={40}
            name="comentario_plan_tratamiento"
            value={props.values.comentario_plan_tratamiento}
            onChange={props.handleChange}
          />
        </div>
      </div>
    </div>
  );
};
