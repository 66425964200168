/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getExpedientesSindicados, deleteExpedientesSindicados } from "./services";
import { getMedidaCoercion, getSituacionesJuridicas } from "../services";
import { SituacionJuridica } from "./situacionJuridica";
import { MedidaCoercion } from "./medidaCoercion";
import { clases } from "../../../../../helpers/clases";
import { CambiarEstado } from "./cambiar_estado"
import {
  PencilAltIcon,
  TrashIcon,
  ScaleIcon,
  ViewBoardsIcon,
  XCircleIcon
} from "@heroicons/react/outline";
import ModalGenerico from "../../../../../components/common/Modal";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import TableSiavi from "../../../../../components/common/TableSiavi";


export const Sindicados = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cargando, setCargando] = useState(false);
  const [addSituacionJuridica, setAddSituacionJuridica] = useState(false);
  const [addMedidaCoercion, setAddMedidaCoercion] = useState(false);
  const [situacionesJuridicas, setSituacionesJuridicas] = useState([]);
  const [medidasCoerciones, setMedidasCoerciones] = useState([]);
  const [idExpediente, setIdExpediente] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [borrar, setBorrar] = useState(false);
  const [sindicados, setSindicados] = useState([]);
  const [id, setId] = useState(0);
  const [nombre, setNombre] = useState("");
  const [sindicadoConocido, setSindicadoConocido] = useState(false);
  const [registro, setRegistro] = useState("");
  const [cambiarEstado, setCambiarEstado] = useState(false);

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //  retorno = true;  // mm comentar para que funcione
    return retorno;

  }



  useEffect(() => {
    setCargando(true);
    getExpedientesSindicados(props.idCaso, token).then((data) => {
      setSindicados(data);
      setCargando(false);
    });
  }, [token, refetch]);

  useEffect(() => {
    getSituacionesJuridicas(token).then((data) => {
      setSituacionesJuridicas(data);
    });
  }, [])

  useEffect(() => {
    getMedidaCoercion(token).then((data) => {
      setMedidasCoerciones(data);
    });
  }, [])


  return (
    <div>
      <TableSiavi
        cargando={cargando}
        headers={[
          'Nombre',
          'Relación con la víctima',
          'Proceso',
          'Situación jurídica',
          'Medida de coerción',
          'Acciones'
        ]}
        body={
          props?.exp?.map((exp) =>
            sindicados?.results?.map((item, index) => {
              if (exp?.id === item?.expediente) {
                return <tr
                  key={item?.sindicado?.primer_nombre + index}
                  className={`cursor-pointer hover:bg-gray-50 ${(item?.situacion_juridica?.situacion_juridica?.nombre === undefined) ? 'bg-yellow-50' : ''}`}
                >
                  <td
                    className={`${(item?.sindicado?.conoce_al_sindicado === false) ? 'text-xs italic' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                  >
                    {`${(item?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado No Individualizado' : item?.sindicado?.primer_nombre + ' ' + item.sindicado.primer_apellido}`} { }

                  </td>
                  <td
                    className={`${(item?.sindicado?.conoce_al_sindicado === false) ? 'text-xs italic' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                  >
                    {`${(item?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado No Individualizado' : item?.victima_sindicado?.nombre}`} { }

                  </td>
                  <td
                    className={`${(item?.sindicado?.conoce_al_sindicado === false) ? 'text-xs italic' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                  >
                    {`${(item?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado No Individualizado' : exp?.tipo_proceso?.tipo_proceso}`} { }

                  </td>
                  <td
                    className={`${(item?.situacion_juridica?.situacion_juridica?.nombre === undefined) ? 'text-xs text-maivi-secondaryRed' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                  >
                    {`${(item?.situacion_juridica?.situacion_juridica?.nombre === undefined) ? '**registre situación juridica**' : item?.situacion_juridica?.situacion_juridica?.nombre}`} { }
                  </td>
                  <td
                    className={`${(item?.exp_medida_coercion?.medida_coercion?.nombre === undefined) ? 'text-xs text-maivi-secondaryRed' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                  >
                    {`${(item?.exp_medida_coercion?.medida_coercion?.nombre === undefined) ? '**registre medida de coerción**' : item?.exp_medida_coercion?.medida_coercion?.nombre}`} { }
                  </td>
                  <td className={clases.td}>
                    {(CheckPermiso('OSindicadoEditar') === true) &&    //mm permiso agregado

                      <button
                        type="button"
                        onClick={() => {
                          if (item?.no_documento === undefined) {
                            props.setFieldValue('actualizarSindicado', true);
                            props.setFieldValue('primer_nombre', item?.sindicado?.primer_nombre);
                            props.setFieldValue('segundo_nombre', item?.sindicado.segundo_nombre);
                            props.setFieldValue('tercer_nombre', item?.sindicado.tercer_nombre);
                            props.setFieldValue('primer_apellido', item?.sindicado.primer_apellido);
                            props.setFieldValue('segundo_apellido', item?.sindicado.segundo_apellido);
                            props.setFieldValue('fecha_nacimiento', item?.sindicado.fecha_nacimiento);
                            props.setFieldValue('nacionalidad', item?.sindicado?.nacionalidad?.id);
                            props.setFieldValue('edad', item?.sindicado?.edad);
                            props.setFieldValue('pseudonimo', item?.sindicado?.pseudonimo);
                            props.setFieldValue('sexo', item?.sindicado?.sexo);

                            props.setFieldValue('pertenece_a_pandilla', item?.sindicado?.pertenece_a_pandilla);
                            props.setFieldValue('nombre_pandilla', item?.sindicado?.nombre_pandilla);
                            props.setFieldValue('tiene_tatuajes', item?.sindicado?.tiene_tatuajes);
                            props.setFieldValue('especificacion_tatuaje', item?.sindicado?.especificacion_tatuaje);
                            props.setFieldValue('uso_de_arma_de_fuego', item?.sindicado?.uso_de_arma_de_fuego);
                            props.setFieldValue('altura', item?.sindicado?.altura);
                            props.setFieldValue('peso', item?.sindicado?.peso);
                            props.setFieldValue('complexion', item?.sindicado?.complexion);
                            props.setFieldValue('color_forma_cabello', item?.sindicado?.color_forma_cabello);
                            props.setFieldValue('color_tez', item?.sindicado?.color_tez);
                            props.setFieldValue('tiene_cicatrices', item?.sindicado?.tiene_cicatrices);
                            props.setFieldValue('tiene_barba', item?.sindicado?.tiene_barba);
                            props.setFieldValue('tiene_bigote', item?.sindicado?.tiene_bigote);
                            props.setFieldValue('forma_de_cejas', item?.sindicado?.forma_de_cejas);
                            props.setFieldValue('color_ojos', item?.sindicado?.color_ojos);
                            props.setFieldValue('usa_anteojos', item?.sindicado?.usa_anteojos);
                            props.setFieldValue('forma_de_nariz', item?.sindicado?.forma_de_nariz);
                            props.setFieldValue('caracteristicas_dentadura', item?.sindicado?.caracteristicas_dentadura);
                            props.setFieldValue('vestimenta', item?.sindicado?.vestimenta);

                            props.setFieldValue('estado_civil', item?.sindicado?.estado_civil?.id);
                            props.setFieldValue('orientacion_sexual', item?.sindicado?.orientacion_sexual?.id);
                            props.setFieldValue('identidad_genero', item?.sindicado?.identidad_genero?.id);
                            props.setFieldValue('departamento', item?.sindicado?.departamento?.id);
                            props.setFieldValue('municipio', item?.sindicado?.municipio?.id);
                            props.setFieldValue('lee_escribe', item?.sindicado?.lee_escribe);
                            props.setFieldValue('escolaridad', item?.sindicado?.escolaridad?.id);
                            props.setFieldValue('profesion', item?.sindicado?.profesion?.id);
                            props.setFieldValue("situacion_laboral", item?.sindicado?.situacion_laboral?.id);
                            props.setFieldValue('religion', item?.sindicado?.religion?.id);
                            props.setFieldValue('origen_etnico', item?.sindicado?.origen_etnico?.id);
                            props.setFieldValue('idioma', item?.sindicado?.idioma?.id);
                            props.setFieldValue('otro_idioma', item?.sindicado?.otro_idioma);
                            props.setFieldValue('en_migracion', item?.sindicado?.en_migracion);
                            props.setFieldValue('pais', item?.pais?.id);
                            props.setFieldValue('pais_origen', item?.sindicado?.pais_origen?.id);
                            props.setFieldValue('pais_destino', item?.sindicado?.pais_destino?.id);
                            props.setFieldValue('relacion_con_la_victima', item?.sindicado?.relacion_con_la_victima?.id);
                            props.setFieldValue('referencia_ubicacion', item?.sindicado?.referencia_ubicacion);
                            props.setFieldValue('observaciones', item?.sindicado?.observaciones);
                            props.setFieldValue('correo_electronico', item?.sindicado?.correo_electronico)

                            props.setFieldValue('conoce_al_sindicado', item?.sindicado?.conoce_al_sindicado);
                            props.setFieldValue('tiene_identificacion', item?.sindicado?.tiene_identificacion);
                            props.setFieldValue('no_identificacion', item?.sindicado?.no_identificacion);
                            props.setFieldValue('tipo_identificacion', item?.sindicado?.tipo_identificacion?.id);
                            props.setFieldValue('idSindicado', item?.sindicado?.id);

                          } else {
                            props.setFieldValue('actualizarSindicado', false);
                            props.setFieldValue('conoce_al_sindicado', true);
                            props.setFieldValue('tiene_identificacion', true);
                            props.setFieldValue('no_identificacion', item?.no_documento);
                            props.setFieldValue('tipo_identificacion', item?.tipo_documento);
                            props.setFieldValue('pais', item?.pais)

                            props.setFieldValue('primer_nombre', item?.primer_nombre);
                            props.setFieldValue('segundo_nombre', item?.segundo_nombre);
                            props.setFieldValue('tercer_nombre', item?.tercer_nombre);
                            props.setFieldValue('primer_apellido', item?.primer_apellido);
                            props.setFieldValue('segundo_apellido', item?.segundo_apellido);
                            props.setFieldValue('fecha_nacimiento', item?.fecha_nacimiento);
                            props.setFieldValue('nacionalidad', item?.nacionalidad);
                            props.setFieldValue('edad', item?.edad);
                            props.setFieldValue('pseudonimo', item?.como_le_gusta_que_le_llamen);
                            props.setFieldValue('sexo', item?.sexo);

                            props.setFieldValue("nacionalidad", item?.nacionalidad || "");
                            props.setFieldValue("estado_civil", item?.estado_civil || "");
                            props.setFieldValue("departamento", item?.departamento || "");
                            props.setFieldValue("municipio", item?.municipio || "");
                            props.setFieldValue("idioma", item?.idioma || "");
                            props.setFieldValue("identidad_genero", item?.identidad_genero || "");
                            props.setFieldValue("idioma_preferencia", item?.idioma_preferencia?.id || "");
                            props.setFieldValue("situacion_laboral", item?.situacion_laboral || "");
                            props.setFieldValue("orientacion_sexual", item?.orientacion_sexual || "");
                            props.setFieldValue("escolaridad", item?.escolaridad || "");
                            props.setFieldValue("profesion", item?.profesion || "");
                            props.setFieldValue("religion", item?.religion || "");
                            props.setFieldValue("origen_etnico", item?.origen_etnico?.id || "");
                            props.setFieldValue("otro_idioma", item?.otro_idioma || "");
                            props.setFieldValue("idioma_maya", item?.idioma_maya || "");
                            props.setFieldValue("en_migracion", item?.en_migracion || false);
                            props.setFieldValue("pais_origen", item?.pais_origen || "");
                            props.setFieldValue("pais_destino", item?.pais_destino || "");
                            props.setFieldValue("relacion_con_victimario", item?.relacion_con_victimario || "");
                          }
                          props.setFieldValue("id_expediente_sindicado", item?.id);
                          props.setFieldValue("id_expediente", item?.expediente);
                          props.setFieldValue("relacion_victima", item?.victima_sindicado?.id);
                          props.setNuevo(true);
                        }}
                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                        <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">Editar</span>
                      </button>
                    }
                    {(CheckPermiso('OSindicadoSituacionjuridica') === true) &&    //mm permiso agregado
                    <button
                      type="button"
                      onClick={() => {
                        setId(item.id);
                        setNombre(`${item?.sindicado?.primer_nombre} ${item?.sindicado?.primer_apellido}`)
                        setIdExpediente(item?.id);
                        props.setFieldValue('primer_nombre', item?.sindicado?.primer_nombre);
                        props.setFieldValue('primer_apellido', item?.sindicado?.primer_apellido);
                        setAddSituacionJuridica(true);
                        if (item?.sindicado.conoce_al_sindicado) {
                          setSindicadoConocido(true);
                        } else {
                          setSindicadoConocido(false);
                        }
                      }}
                      className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                      <ScaleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Agregar situación jurídica</span>
                    </button>
              }
              {(CheckPermiso('OSindicadoMedidaCoercion') === true) &&    //mm permiso agregado
                    <button
                      type="button"
                      onClick={() => {
                        setId(item.id);
                        setNombre(`${item?.sindicado?.primer_nombre} ${item?.sindicado?.primer_apellido}`)
                        setIdExpediente(item?.id);
                        props.setFieldValue('primer_nombre', item?.sindicado?.primer_nombre);
                        props.setFieldValue('primer_apellido', item?.sindicado?.primer_apellido);
                        setAddMedidaCoercion(true);
                        if (item?.sindicado.conoce_al_sindicado) {
                          setSindicadoConocido(true);
                        } else {
                          setSindicadoConocido(false);
                        }
                      }}
                      className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                              rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                      <ViewBoardsIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Agregar medida coerción</span>
                    </button>
              }
                    {(CheckPermiso('OSindicadosCambioEstado') === true) &&    //mm permiso agregado
                      <button
                        type="button"
                        className={clases.trashIcon}
                        onClick={() => {
                          setCambiarEstado(true);
                          setRegistro(item);
                        }}
                      >
                        <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
                        <span className="tooltiptext">Cambiar estado</span>
                      </button>
                    }
                    {(CheckPermiso('OSindicadosEliminar') === true) &&    //mm permiso agregado

                      <button
                        type="button"
                        onClick={async () => {
                          setId(item.id);
                          setNombre(`${item?.sindicado?.primer_nombre} ${item?.sindicado?.primer_apellido}`);
                          setBorrar(true);
                        }}
                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md 
                  text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                        <span className="tooltiptext">Eliminar</span>
                      </button>
                    }
                  </td>
                </tr>
              }
            }))}
      />
      {addSituacionJuridica && (
        <ModalAmplio
          title={`Agregar situación jurídica del sindicado ${nombre}`}
          open={addSituacionJuridica}
          closeModal={() => { }}
          cerrar={() => {
            setAddSituacionJuridica(false);
          }}
          children={
            <SituacionJuridica
              situacionesJuridicas={situacionesJuridicas}
              token={token}
              idExpediente={idExpediente}
              setRefetch={setRefetch}
              refetch={refetch}
              sindicadoConocido={sindicadoConocido}
              cerrar={() => {
                setAddSituacionJuridica(false);
              }}
            />
          }
        />
      )}
      {addMedidaCoercion && (
        <ModalAmplio
          title={`Agregar medida coerción del sindicado ${nombre}`}
          open={addMedidaCoercion}
          closeModal={() => { }}
          cerrar={() => {
            setAddMedidaCoercion(false);
          }}
          children={
            <MedidaCoercion
              medidasCoerciones={medidasCoerciones}
              token={token}
              idExpediente={idExpediente}
              setRefetch={setRefetch}
              refetch={refetch}
              sindicadoConocido={sindicadoConocido}
              cerrar={() => {
                setAddMedidaCoercion(false);
              }}
            />
          }
        />
      )}
      {borrar && (
        <ModalGenerico
          title={`Esta seguro que desea eliminar el registro de ${nombre}`}
          open={borrar}
          closeModal={() => { }}
          cerrar={() => {
            setBorrar(false);
          }}
          children={
            <div>
              <BotonesConfirmar
                aceptar={async () => {
                  await deleteExpedientesSindicados(id, token)
                    .then((data) => {
                      if (data?.status >= 200 || data?.status <= 210) {
                        toast.success("Registro eliminado con éxito.");
                        props.cerrar();
                        setRefetch(true);
                        setRefetch(false);
                        setBorrar(false);
                      }
                    })
                    .catch(function (error) {
                      if (error.response.status === 400 || error.response.status === 401) {
                        toast.error(error.response.data.message);
                      } else if (error.response.status >= 500) {
                        toast.error(
                          "Error interno comuniquese con el administrador"
                        );
                      }
                      setBorrar(false);
                    });
                }}
                cancelar={() => {
                  setBorrar(false);
                }}
              />
            </div>
          }
        />
      )}

      {cambiarEstado && (
        <ModalGenerico
          title={"¿Desea cambiar el estado del sindicado?"}
          open={cambiarEstado}
          closeModal={() => { }}
          cerrar={() => {
            setCambiarEstado(false);
          }}
          children={
            <CambiarEstado
              registro={registro}
              setCambiarEstado={setCambiarEstado}
              setReFetch={setRefetch}
            />
          }
        />
      )}
    </div>
  );
};
