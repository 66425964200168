import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postRol, getPermisos } from "../services";
import { InputSiavi } from "../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { CheckCircleIcon } from "@heroicons/react/outline";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import toast from 'react-hot-toast';
import ButtonAdd from "../../../../components/common/ButtonAdd";
import Return from "../../../../components/common/ButtonSalir";

const validaciones = Yup.object().shape({
  name: Yup.string()
    .required("Nombre es requerido")
    .max(100, "Máximo 100 caracteres"),
});

const pages = [
  {
    name: "Roles",
    href: "/seguridad/roles",
    current: false,
  },
  { name: "Crear", href: "#", current: true },
];

export const FormCrearRol = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [permisosAgregados, setPermisosAgregados] = useState([]);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [hasError, setError] = useState(false);
  const history = useHistory();
  const [filters, setFilters] = useState("");
  const [buscar, setBuscar] = useState("");

  const useFetchPermisos = (token) => {
    const [state, setState] = useState({
      permisos: [],
    });

    useEffect(() => {
      getPermisos(filters, token).then((permisos) => {
        setState({
          permisos: permisos,
        });
      });
    }, [token]);

    return state;
  };

  const { permisos } = useFetchPermisos(token);

  /*   permisos?.results?.map((p) => {
      setPermisosFiltrados(p)
    }) */

 const filtroAgregados  = (item) => {
   let retorno = true;

  permisosAgregados.forEach(perm => {
    if (perm.id === item.id) {
      retorno = false;
    }
  });
  return retorno;
 }


  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        if (permisosAgregados.length > 0) {
          let permisosSend =[];
          permisosAgregados.forEach(perm => permisosSend.push(perm.id));
          await postRol(values.name, permisosSend, token)
            .then((data) => {
              if (data?.status === 200 || data?.status === 201) {
                setError(false);
                setSuccess(true);
                setMensaje("Rol creado éxitosamente");
                toast.success('Registro creado con éxito...!');
                setTimeout(() => {
                  history.push('/seguridad/roles');
                }, 1500);
              } else {
                setError(true);
                setSuccess(false);
              }
            })
            .catch(function (error) {
              if (
                error.response.status === 400 ||
                error.response.status === 401
              ) {
                setError(true);
                setSuccess(false);
                setMensaje(error.response.data.message);
              } else if (error.response.status >= 500) {
                setError(true);
                setSuccess(false);
                setMensaje("Error interno comuniquese con el administrador");
              }
            });
        } else {
          setError(true);
          setSuccess(false);
          setMensaje("Debe de agregar permisos para crear el rol");
        }

      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 -mt-32 mb-10 justify-center  ">
            <div className="xl:col-span-1"></div>
            <div className="mt-8 col-span-12 xl:col-span-10 lg:col-span-10 md:col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
              <div className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 mt-5">
                <BreadCrumbTwo navegacion={pages} />
                <h1 className="text-lg text-maivi-primaryFont mt-4 xl:mt-0 lg:mt-0 md:mt-0">
                  Crear rol
                  <Return ruta={"/seguridad/roles/"} />
                </h1>
              </div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 border-2 rounded-md p-5">
                  <div className="mb-1 flex items-end">
                    <h3 className="text-lg leading-6 font-medium text-maivi-primaryFont mr-5">
                      Permisos
                    </h3>
                    <div className="mt-1">
                      <select
                        name="codename"
                        value={filters}
                        onChange={(e) => setFilters(e.target.value)}
                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                      >
                        <option value="">Todos</option>
                        <option value="add_">Agregar</option>
                        <option value="change_">Actualizar</option>
                        <option value="delete_">Eliminar</option>
                        <option value="view_">Ver</option>
                      </select>
                    </div>
                    <ButtonAdd
                      className="ml-2"
                      type="button"
                      onClick={() => {
                        if (filters !== '') {
                          let tempPermisos = permisosAgregados;
                          permisos.results.forEach(perm => {
                            if (perm.codename.includes(filters)) {
                              let notExist = true;
                              permisosAgregados.forEach(permAdd => {
                                if (permAdd.id === perm.id) {
                                  notExist = false;
                                }
                              })
                              if (notExist) {
                                tempPermisos.push(perm)
                              }
                            }
                          });
                          setPermisosAgregados(tempPermisos);
                          setFilters("");
                        }
                      }}
                      text="Agregar Todos"
                      icon={<CheckCircleIcon className="ml-3 -mr-1 h-5" />}
                    />
                  </div>
                  <div className="mt-1 mb-1">
                    <div className="grid grid-cols-6 gap-6 m-5">
                      <div className="sm:col-span-1 col-span-2 mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Buscar:
                        </label>
                      </div>
                      <div className="sm:col-span-5 col-span-6">
                        <InputSiavi
                          type="text"
                          name="buscar"
                          value={buscar}
                          onChange={(e) => setBuscar(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-96 overflow-y-auto">
                    <table className="min-w-full divide-y divide-maivi-gray-10 ">
                      <thead className="bg-maivi-gray-5">
                        <tr>
                          <th className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider">
                            id
                          </th>
                          <th className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider">
                            Permiso
                          </th>
                          <th className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider">
                            Agregar
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                        {permisos?.results?.map((item) =>
                          (item.codename.includes(filters) && filtroAgregados(item) && item.name.includes(buscar))&&
                          <tr
                            key={`${item.id}-noadd`}
                            id={item.codename}
                            className="hover:bg-maivi-gray-5"
                          >
                            <td className="px-6 py-1">
                              <div className="text-sm text-maivi-primaryFont row-td">
                                {item.id}
                              </div>
                            </td>
                            <td className="px-6 py-1">
                              <div className="text-sm text-maivi-primaryFont row-td">
                                {
                                  item.name
                                    .replace('Can view', 'Puede ver')
                                    .replace('Can delete', 'Puede eliminar')
                                    .replace('Can add', 'Puede crear')
                                    .replace('Can change', 'Puede actualizar')
                                }
                              </div>
                            </td>
                            <td className="px-6 py-1">
                              <div className="text-sm text-maivi-primaryFont row-td">
                                <button
                                  type="button"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      item.codename
                                    );
                                    element.classList.add("bg-green-50");
                                    setPermisosAgregados([
                                      ...permisosAgregados,
                                      {
                                        id: item.id,
                                        name: item.name,
                                        codename: item.codename,
                                      },
                                    ]);
                                  }}
                                  className="inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue"
                                >
                                  Agregar
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 border-2 rounded-md p-5">
                  <div className="bg-white  sm:rounded-lg">

                    <div className="mr-4 ml-4">
                      {hasError && <Alert type="error" mensaje={mensaje} />}
                      {success && (
                        <Alert
                          mensaje="Rol creado éxitosamente"
                          type={"success"}
                        />
                      )}
                    </div>
                    {isSubmitting && <Spinner />}
                    <div className="grid grid-cols-6 gap-6 m-5">
                      <div className="sm:col-span-3 col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Nombre*
                        </label>
                        <div className="mt-1">
                          <InputSiavi
                            autoFocus
                            type="text"
                            name="name"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="font-medium text-xs text-maivi-secondaryRed"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 col-span-6">
                        <div className="mt-6">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            className="w-full py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                          >
                            Crear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-80 overflow-y-auto">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <table className="min-w-full divide-y divide-maivi-gray-10">
                          <thead className="bg-maivi-gray-5">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider"
                              >
                                id
                              </th>
                              <th
                                scope="col"
                                className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider"
                              >
                                Permiso
                              </th>
                              <th
                                scope="col"
                                className="px-6 text-left text-md font-medium text-maivi-primaryFont tracking-wider"
                              >
                                Borrar
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                            {permisosAgregados?.map((item, index) => (
                              <tr key={`${item.name}-${index}`} className="bg-green-50">
                                <td className="px-6 whitespace-nowrap">
                                  <div className="text-sm text-maivi-primaryFont">
                                    {item.id}
                                  </div>
                                </td>
                                <td className="px-6 whitespace-nowrap">
                                  <div className="text-sm text-maivi-primaryFont">
                                    {item.name}
                                  </div>
                                </td>
                                <td className="px-6 whitespace-nowrap">
                                  <div className="text-sm text-maivi-primaryFont">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        let element = document.getElementById(
                                          item.codename
                                        );
                                        element.classList.remove("bg-green-50");
                                        let old = permisosAgregados?.filter(
                                          (x) => x.id !== item.id
                                        );

                                        setPermisosAgregados([...old]);
                                      }}
                                      className="inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont
                                     hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue"
                                    >
                                      Borrar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-1"></div>
          </div>
        </form>
      )}
    </Formik>
  );
};
