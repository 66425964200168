import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Crear } from "./Crear";
import { postFormulario } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import ModalGenerico from "../../../../components/common/Modal";
import FormEliminar from "../../common/FormEliminar";

// Validation with Yup
const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(100, "máximo 100 caracteres"),
    descripcion: Yup.string()
        .required("descripción es requerida")
        .max(100, "máximo 100 caracteres"),
    idioma: Yup.string().required("Idioma es requerido")
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const FormCrear = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [valorAutorizado, setValorAutorizado] = useState(false);
    const [valorEstado, setValorEstado] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const history = useHistory();

    const customChange = (values) => {
        if (values.autorizado === true && values.valorAutorizado === undefined) {
            setValorAutorizado(true)
        } else {
            setValorAutorizado(false)
        }
        if (values.estado === false && values.valorEstado === undefined) {
            setValorEstado(true)
        } else {
            setValorEstado(false)
        }
    };

    return (
        <Formik
            initialValues={{
                nombre: "",
                descripcion: "",
                estado: true,
                por_servicio: false,
                por_atencion: false,
                fecha_creacion: "",
                fecha_autoriza: "",
                fecha_inactivacion: "",
                encuesta: true,
                profesional: true,
                usuario_victima: false,
                por_area: false,
                por_orientacion: false,
                autorizado: false,
                servicio: "",
                atencion: "",
                idioma: "",
                idioma_maya: "",
                usuario_creacion: "",
                usuario_autoriza: "",
                usuario_inactivacion: "",
                area: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await postFormulario(values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje('Registro creado con éxito...!');
                            toast.success('Registro creado con éxito...!')
                            history.push('/catalogos/formulario');
                        } else {
                            setError(true);
                            setSuccess(false);
                        }
                    })
                    .catch(function (error) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401
                        ) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {(props) => (
                <div>
                    <Crear
                        {...props}
                        customSwitchChange={customSwitchChange}
                        customChange={customChange}
                        hasError={hasError}
                        success={success}
                        mensaje={mensaje}
                    />
                    {valorAutorizado && (
                        <ModalGenerico
                            title={"Una vez autorizado no podrá editarlo nuevamente, ¿Desea continuar?"}
                            open={valorAutorizado}
                            closeModal={() => { }}
                            cerrar={() => {
                                setValorAutorizado(false)
                            }}
                            children={
                                <FormEliminar
                                    aceptar={() => {
                                        props.values.autorizado = true;
                                        props.values.valorAutorizado = true;
                                        setValorAutorizado(false)
                                    }}
                                    cancelar={() => {
                                        props.values.autorizado = false;
                                        props.values.valorAutorizado = false;
                                        setValorAutorizado(false);
                                    }}
                                />

                            }
                        />
                    )}
                    {valorEstado && (
                        <ModalGenerico
                            title={"Una vez inactivado no podrá activarlo nuevamente, ¿Desea continuar?"}
                            open={valorEstado}
                            closeModal={() => { }}
                            cerrar={() => {
                                setValorEstado(false)
                            }}
                            children={
                                <FormEliminar
                                    aceptar={() => {
                                        props.values.estado = false;
                                        props.values.valorEstado = false;
                                        setValorEstado(false)
                                    }}
                                    cancelar={() => {
                                        props.values.estado = true;
                                        props.values.valorEstado = true;
                                        setValorEstado(false);
                                    }}
                                />

                            }
                        />
                    )}
                </div>

            )}
        </Formik>
    );
};
