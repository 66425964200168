import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import Return from "../../../../components/common/ButtonSalir";
import { ButtonSiavi } from "../../../../components/common/Button";
import {
  getNacionalidades,
  getPaises,
  getTipoDocumentos,
  buscarPersona,
} from "./services";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../../components/common/Modal";
import { Link } from "react-router-dom";
import Toggle from "../../../../components/common/Toggle";
const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

export const Registrar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  hasError,
  success,
  mensaje,
  setFieldValue,
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  values.sede = sede;

  const useFetchDocumentos = () => {
    const [state, setState] = useState({
      tipoDocumentos: [],
    });

    useEffect(() => {
      getTipoDocumentos(token).then((data) => {
        setState({
          tipoDocumentos: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchPaises = () => {
    const [state, setState] = useState({
      paises: [],
    });

    useEffect(() => {
      getPaises(token).then((data) => {
        setState({
          paises: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchNacionalidad = () => {
    const [state, setState] = useState({
      nacionalidades: [],
    });

    useEffect(() => {
      getNacionalidades(token).then((data) => {
        setState({
          nacionalidades: data,
        });
      });
    }, []);

    return state;
  };

  const { tipoDocumentos } = useFetchDocumentos();
  const { paises } = useFetchPaises();
  const { nacionalidades } = useFetchNacionalidad();

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales/listar-historico",
      current: false,
    },
    { name: "Registrar", href: "#", current: true },
  ];

  const [personaExiste, setPersonaExiste] = useState(false);
  const [persona, setPersona] = useState({});
  const [state, setState] = useState({
    pais: "",
    tipoDocumento: "",
    noDocumento: "",
  });

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function busqueda() {
    if (
      state.pais !== "" &&
      state.noDocumento !== "" &&
      state.tipoDocumento !== ""
    ) {
      buscarPersona(
        state.pais,
        state.tipoDocumento,
        state.noDocumento,
        token
      ).then((data) => {
        if (data.count > 0) {
          setPersonaExiste(true);
          setPersona(data.results[0]);
        }
      });
    }
  }

  return (
    <form autoComplete="off" onChange={customChange(values)}>
      <div className="lg:mx-6 xl:mx-40 -mt-32 mb-10 justify-center items-start shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
        <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-8">
          <BreadCrumbTwo navegacion={pages} />
          <div className="mt-5 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  flex flex-row justify-center items-center">
            <h1 className="mr-5 text-base xl:text-lg lg:text-base text-maivi-primaryFont">
              Registro de datos generales
            </h1>
            <Return ruta="/funcionalidades-generales/listar-historico" />
          </div>
        </div>
        <h1 className="mb-1 text-base">Identificación</h1>
        <Divider />
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12">
            <Label text="Sin Documento" />
            <Toggle enabled={values.sin_documento} name="sin_documento" />
          </div>
          {!values.sin_documento && (
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
              <Label text="Tipo de documento" />
              <select
                name="tipoDocumento"
                value={values.tipoDocumento}
                onChange={(e) => {
                  setFieldValue("tipoDocumento", e.target.value);
                  handleInputChange(e);
                }}
                onBlur={() => {
                  busqueda();
                }}
                className={selectClass}
              >
                <option value="">Seleccionar</option>
                {tipoDocumentos?.data?.results?.map((item) => {
                  return (
                    <option
                      key={item.nombre}
                      value={item.id}
                      className="text-sm"
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
            </div>)}
          {!values.sin_documento && (
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
              <InputSiavi
                type="text"
                name="noDocumento"
                onChange={(e) => {
                  setFieldValue("noDocumento", e.target.value);
                  handleInputChange(e);
                }}
                onBlur={() => {
                  busqueda();
                }}
                value={values.noDocumento}
                label="No. documento"
              />
            </div>)}
          <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
            <Label text="Pais" />
            <select
              name="pais"
              value={values.pais}
              onChange={(e) => {
                setFieldValue("pais", e.target.value);
                handleInputChange(e);
              }}
              onBlur={() => {
                busqueda();
              }}
              className={selectClass}
            >
              <option value="">Seleccionar</option>
              {paises?.data?.results?.map((item) => {
                return (
                  <option
                    key={item.nombre}
                    value={item.id}
                    className="text-sm"
                  >
                    {item.nombre}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="pais"
              component="span"
              className="font-medium text-xs text-maivi-secondaryRed"
            />
          </div>
        </div>
        <h1 className="mt-5 mb-1 text-base">Datos generales</h1>
        <Divider />
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="primerNombre"
              value={values.primerNombre}
              onChange={handleChange}
              validacion={true}
              label="Primer nombre"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="segundoNombre"
              value={values.segundoNombre}
              onChange={handleChange}
              validacion={true}
              label="Segundo nombre"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="tercerNombre"
              value={values.tercerNombre}
              onChange={handleChange}
              validacion={true}
              label="Otros nombres"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              name="primerApellido"
              type="text"
              value={values.primerApellido}
              onChange={handleChange}
              validacion={true}
              label="Primer apellido"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              name="segundoApellido"
              type="text"
              value={values.segundoApellido}
              onChange={handleChange}
              validacion={true}
              label="Segundo apellido"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="text"
              name="apellidoCasada"
              value={values.apellidoCasada}
              onChange={handleChange}
              validacion={true}
              label="Apellido de casada"
            />
          </div>

          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="date"
              name="fechaNacimiento"
              value={values.fechaNacimiento}
              onChange={handleChange}
              validacion={true}
              label="fecha nacimiento"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <InputSiavi
              type="number"
              name="edad"
              value={values.edad}
              onChange={handleChange}
              validacion={true}
              label="Edad"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <Label text="Sexo" />
            <select
              name="sexo"
              value={values.sexo}
              onChange={handleChange}
              className={selectClass}
            >
              <option value="">Seleccionar</option>
              <option value="HOMBRE">HOMBRE</option>
              <option value="MUJER">MUJER</option>
            </select>
            <ErrorMessage
              name="sexo"
              component="span"
              className="font-medium text-xs text-maivi-secondaryRed"
            />
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6">
            <Label text="Nacionalidad" />
            <select
              name="nacionalidad"
              value={values.nacionalidad}
              onChange={handleChange}
              className={selectClass}
            >
              <option value="">Seleccionar</option>
              {nacionalidades?.data?.results?.map((item) => {
                return (
                  <option
                    key={item.nombre}
                    value={item.id}
                    className="text-sm"
                  >
                    {item.nombre}
                  </option>
                );
              })}
            </select>
            <ErrorMessage
              name="nacionalidad"
              component="span"
              className="font-medium text-xs text-maivi-secondaryRed"
            />
          </div>
          {/* <div className="col-span-12 xl:col-span-3">
            <InputSiavi
              type="text"
              name="alias"
              value={values.alias}
              onChange={handleChange}
              validacion={true}
              label="¿Cómo le gusta que le llamen?"
            />
          </div> */}
          <div className="sm:col-span-6 col-span-12">
            <InputSiavi
              type="hidden"
              name="sede"
              value={values.sede}
              onChange={handleChange}
            />
          </div>
          <div className="sm:col-span-10 col-span-12">
            <div className="col-span-12">
              {isSubmitting && <Spinner />}
              {hasError && <Alert type="error" mensaje={mensaje} />}
              {success && <Alert type="success" mensaje={mensaje} />}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
            <ButtonSiavi
              type="button"
              onClick={() => {
                handleSubmit();
              }}
              disabled={isSubmitting}
              text="Guardar"
            />
          </div>

        </div>
      </div>
      {personaExiste && (
        <ModalGenerico
          title={`Ya existe una persona registrada con esos datos, ¿desea consultarlo?`}
          open={personaExiste}
          closeModal={() => { }}
          cerrar={() => {
            setPersonaExiste(false);
          }}
          children={
            <div className="py-3 text-center">
              <button
                className="inline-flex w-5/12 m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
              rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                onClick={() => {
                  setPersonaExiste(false);
                  setFieldValue('tipoDocumento', '');
                  setFieldValue('noDocumento', '');
                  setFieldValue('pais', '');
                  setFieldValue('primeraVisita', true);
                  setFieldValue('primerNombre', '');
                  setFieldValue('segundoNombre', '');
                  setFieldValue('tercerNombre', '');
                  setFieldValue('primerApellido', '');
                  setFieldValue('segundoApellido', '');
                  setFieldValue('fechaNacimiento', '');
                  setFieldValue('sexo', '');
                  setFieldValue('nacionalidad', '');
                  setFieldValue('alias', '');
                }}
              >
                No
              </button>
              <Link to={`/funcionalidades-generales/actualizar/${persona.id}`}>
                <button
                  className="inline-flex w-5/12 m-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
              rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                >
                  Si
                </button>
              </Link>
            </div>
          }
        />
      )}
    </form>
  );
};
