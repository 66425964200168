/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/common/Table";
import { getHistorialAudiencia } from "./services";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha-Hora Planificada Inicio", value: "fecha_hora_planificada_inicio", tipo: "text" },
    { title: "Fecha-Hora Planificada Fin", value: "fecha_hora_planificada_fin", tipo: "text" },
    { title: "Observaciones", value: "observaciones", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "text" },
    { title: "Tipo de Audiencia", value: "caso_seguimiento_accion.tipo_audiencia.nombre", tipo: "text" },
    { title: "No. Expediente", value: "caso_seguimiento_accion.expediente.numero_expediente", tipo: "text" },
    { title: "Motivo Cambio Planificación", value: "motivos_cambio_planificacion.nombre", tipo: "text" },
    {
        title: "Usuario Planifica",
        value: null,
        values: [
            "usuario_planifica.first_name",
            "usuario_planifica.last_name"
        ],
        tipo: "text"
    }
];

export const TableHistorialAudiencias = () => {
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const token = useSelector((state) => state.loginReducer.data.token);
    const [reFetch, setReFetch] = useState(false);
    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "casoseguimientoaccionhistoricoplanificacion";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const fetchHistorial = () => {
        getHistorialAudiencia(urlFetch,token).then((historial) => {
          setDataTable(historial);
          setTableLoading(false);
          setReFetch(false);
        });
      }
    
      useEffect(fetchHistorial, [token, urlFetch, reFetch]);

    return (
        <div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={false}
                        showEliminar={false}
                        showEditar={false}
                        setOrder={setOrder}
                        order={order}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
