/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState,useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { 
  //getServicios,
  //getSedes,
  getTipoProcesos,
  getProfesionales
} from "./services";
import { SelectSiavi } from "../../../components/common/Select";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
  const filtroSedes = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.otra_sede);
  const filtroServicios = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.servicios);

  let filtros = "";
  const [state, setState] = useState({
    profesional:"",
    servicio: "",
    proceso:"",
    sede:""
  });

  const [ profesionales, setProfesionales ] = useState([]);
  const [ procesos,setProcesos ] = useState([]);
  //const [ sedes,setSedes ] = useState([]);

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
     setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getTipoProcesos(props.token).then((data) => {
     setProcesos(data);
    });
  }, []);

  // useEffect(() => {
  //   getSedes(props.token).then((data) => {
  //    setSedes(data);
  //   });
  // }, []);

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
       <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="profesional"
           label="Usuario creación"
           value={state.profesional}
           onChange={onChange}
           initValue=""
           selectInit="Elegir usuario creación"
           list={
             profesionales?.results?.map((item,index) =>{
               return <option key={item.first_name + index} value={item.id}>{item.first_name} {item.last_name}</option>
             })
           }
         />
     </div>
       {
         (filtroSedes.length > 0) ?
         <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="sede"
           label="Sede"
           defaultValue=""
           value={state.sede}
           onChange={onChange}
           initValue=""
           selectInit="Elegir sede"
           list={
             filtroSedes?.map((item,index) =>{
               return <option key={item.sede.id + index} value={item.sede.id}>{item.sede.sede}</option>
             })
           }
         />
     </div>: ""
       }
       {
         (filtroServicios.length > 0) ?
         <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="servicio"
           label="Servicio"
           defaultValue=""
           value={state.servicio}
           onChange={onChange}
           initValue=""
           selectInit="Elegir Servicio"
           list={
             filtroServicios?.map((item,index) =>{
               return <option key={item.servicio_detalle?.id + index} value={item?.servicio_detalle?.id}>{item.servicio_detalle?.nombre}</option>
             })
           }
         />
     </div> : ""
       }
        <div className="col-span-12 mt-4">
            <SelectSiavi 
              label="Tipo proceso"
              name="proceso"
              value={state.proceso}
              onChange={onChange}
              initValue=""
              selectInit="Elegir tipo proceso"
              list={
                procesos?.results?.map((item) =>{
                  return <option key={item.tipo_proceso} value={item.id}>{item.tipo_proceso}</option>
                })
              }
            />
        </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `tipo_proceso=${state.proceso}&servicio=${state.servicio}&sede_expediente=${state.sede}&usuario_creacion=${state.profesional}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
              props?.setUrlFech(undefined);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
      </div>
  );
}
