import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { 
    getDeptosGestion, 
    getMunicipiosGestion, 
    getInstitucionGestion,
    //getServicios,
    //getPoblaciones
} from "../../../orientacion/listar/services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { SelectSiavi } from "../../../../../components/common/Select";

export default function FiltrosGestionInstitucion(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const [state, setState] = useState({
        estado: true,
        institucion: "",
        departamento: "",
        municipio: "",
        servicio:"",
        poblacion:""
    });

    const  [ instituciones, setInstituciones] = useState([]);
    const [ departamentos,setDepartamentos ] = useState([]);
    const [ municipios, setMunicipios ] = useState([]);
    //const [ servicios, setServicios] = useState([]);
    //const [ poblaciones, setPoblaciones] = useState(token);

    const animatedComponents = makeAnimated();
    const optionsInstituciones = [];
    const optionsDepartamentos = [];
    const optionsMunicipios = [];
    //const optionsServicios = [];
    //const optionsPoblacion = [];

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDepartamentos(data)
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getInstitucionGestion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    // useEffect(() => {
    //     getServicios(token).then((data) => {
    //        setServicios(data);
    //     });
    // }, [token]);

    // useEffect(() => {
    //     getPoblaciones(token).then((data) => {
    //         setPoblaciones(data);
    //     });
    // }, [token]);

    const changeSelectInstitucion = (e) => {
        setState({
            ...state,
            institucion: e.value,
        });
    };

    const changeSelectDepartamento = (e) => {
        setState({
            ...state,
            departamento: e.target.value,
        });
    };

    const changeSelectMunicipio = (e) => {
        setState({
            ...state,
            municipio: e.target.value,
        });
    };

    // const changeSelectServicio = (e) => {
    //     setState({
    //         ...state,
    //         servicio: e.value,
    //     });
    // };

    // const changeSelectPoblacion = (e) => {
    //     setState({
    //         ...state,
    //         poblacion: e.value,
    //     });
    // };

    instituciones?.results?.filter(x => x?.denuncia_valida)?.forEach((item) => {
        let reg = {
          value: item.id,
          label: item.nombre,
        };
        optionsInstituciones.push(reg);
    });

    departamentos?.results?.forEach((item) => {
        let reg = {
          value: item.id,
          label: item.nombre,
        };
        optionsDepartamentos.push(reg);
    });

    municipios?.results?.forEach((item) => {
        let reg = {
          value: item.id,
          label: item.nombre,
        };
        optionsMunicipios.push(reg);
    });

    // servicios?.results?.forEach((item) => {
    //     let reg = {
    //       value: item.id,
    //       label: item.nombre,
    //     };
    //     optionsServicios.push(reg);
    // });

    // poblaciones?.results?.forEach((item) => {
    //     let reg = {
    //       value: item.id,
    //       label: item.nombre,
    //     };
    //     optionsPoblacion.push(reg);
    // });

    return (
        <div className="mx-5 mb-14">
                <div className="col-span-12 mt-3 ">
                  <Label text="Institución" />
                    <div className="mt-1">
                        <Select
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            name="institucion"
                            className={`basic-multi-select text-xs text-maivi-primaryFont`}
                            classNamePrefix="seleccione"
                            options={optionsInstituciones}
                            onChange={changeSelectInstitucion}
                        /> 
                    </div>
                </div>
                <div className="col-span-12 mt-3">
                    <SelectSiavi 
                        label="Departamento"
                        name="departamento"
                        valueInit=""
                        selectInit="Seleccionar"
                        onChange={changeSelectDepartamento}
                        list={
                            departamentos?.results?.map((item,index) => {
                                return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
                            })
                        }
                    />
                </div>
                <div className="col-span-12 mt-3">
                    <SelectSiavi 
                        label="Municipio"
                        name="municipio"
                        valueInit=""
                        selectInit="Seleccionar municipio"
                        onChange={changeSelectMunicipio}
                        list={
                            municipios?.results?.filter(x => x.departamento === Number(state.departamento)).map((item,index) => {
                                return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
                            })
                        }
                    />
                </div>
                {/* <div className="col-span-12 mt-3">
                <Label text="Servicio" />
                    <div className="mt-1">
                        <Select
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            name="servicio"
                            className={`basic-multi-select text-xs text-maivi-primaryFont`}
                            classNamePrefix="seleccione"
                            options={optionsServicios}
                            onChange={changeSelectServicio}
                            maxMenuHeight={150}
                        /> 
                    </div>
                </div> */}
                {/* <div className="col-span-12 mt-3">
                <Label text="Poblaciones" />
                    <div className="mt-1">
                    <Select
                            placeholder="Seleccionar"
                            components={animatedComponents}
                            name="poblacion"
                            className={`basic-multi-select text-xs text-maivi-primaryFont`}
                            classNamePrefix="seleccione"
                            options={optionsPoblacion}
                            onChange={changeSelectPoblacion}
                            maxMenuHeight={120}
                        /> 
                    </div>
                </div> */}
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `${(state.departamento !== "" ? '&departamento=' : '')}${state.departamento}${(state.municipio !== "") ? '&municipio=': ''}${state.municipio}${(state.institucion !== "") ? '&institucion=' : ''}${state.institucion}${(state.poblacion !== "") ? '&poblaciones=' : ''}${state.poblacion}${(state.servicio !== '') ?'&servicio=':''}${state.servicio}`;
                            props.setFiltros(filtros);
                            props.setFiltrar(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
