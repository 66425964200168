/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import { getModalidades, getAccionesSeguimiento, getProfesionales } from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import makeAnimated from "react-select/animated";
import Select from "react-select";


export default function Filtros(props) {
  let filtros = "";
  const animatedComponents = makeAnimated();
  const optionsProfesionales = [];
  const [modalidades, setModalidades] = useState([]);
  const [accionesSeguimiento, setAccionesSeguimiento] = useState([]);
  const [profesionales, setProfesionales] = useState({});
  const [state, setState] = useState({
    fecha_planificada_inicio: "",
    fecha_planificada_fin: "",
    fecha_completada_inicio: "",
    fecha_completada_fin: "",
    profesional_asignado: "",
    profesional_completado: "",
    accion_seguimiento: "",
    modalidad: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getModalidades(props.token).then((data) => {
      setModalidades(data);
    })
  }, []);

  useEffect(() => {
    getAccionesSeguimiento(props.token).then((data) => {
      setAccionesSeguimiento(data);
    })
  }, []);

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item.first_name} ${item.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
  });

  const changeSelectProfesional = (e) => {
    setState({
      ...state,
      profesional_asignado: e.value,
    });
  };

  const changeSelectProfesionalCompletado = (e) => {
    setState({
      ...state,
      profesional_completado: e.value,
    });
  };

  return (
    <div className="mx-4">
      <div className="col-span-12 mt-1">
        <Label text="Profesional asignado" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesional}
        />
      </div>
      <div className="col-span-12 mt-1">
        <Label text="Profesional completado" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesionalCompletado}
        />
      </div>
      <div className="col-span-12 mt-1">
        <SelectSiavi
          name="accion_seguimiento"
          label="Tipo"
          value={state.accion_seguimiento}
          onChange={onChange}
          initValue=""
          selectInit="Elegir tipo"
          list={
            accionesSeguimiento?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item?.atencion?.nombre} - {item.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="modalidad"
          label="Modalidad"
          value={state.modalidad}
          onChange={onChange}
          initValue=""
          selectInit="Elegir modalidad"
          list={
            modalidades?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
            })
          }
        />
      </div>
      <div className="grid grid-cols-12 gap-2 mb-4 mt-4">
        <div className="col-span-12">
          <Label text="Fecha planificada" />
          <hr />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_planificada_inicio"
            value={state.fecha_planificada_inicio}
            onChange={onChange}
            label="Inicio"
          />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_planificada_fin"
            value={state.fecha_planificada_fin}
            onChange={onChange}
            label="Fin"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <Label text="Fecha completada" />
          <hr />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_completada_inicio"
            value={state.fecha_completada_inicio}
            onChange={onChange}
            label="Inicio"
          />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_completada_fin"
            value={state.fecha_completada_fin}
            onChange={onChange}
            label="Fin"
          />
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `profesional_autoriza=${state.profesional_completado}&profesional_asignado=${state.profesional_asignado}&fecha_hora_planificada_inicia__gte=${state.fecha_planificada_inicio}&fecha_hora_planificada_inicia__lte=${state.fecha_planificada_fin}&fecha_completado__gte=${state.fecha_completada_inicio}&fecha_completado__lte=${state.fecha_completada_fin}&modalidad=${state.modalidad}&accion_seguimiento=${state.accion_seguimiento}`;
            props?.setFilters(filtros);
            props?.setOpenFilters(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
