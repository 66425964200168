/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    getDelitosSindicado,
    putCambioEstado
} from "./services"
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import toast from 'react-hot-toast';
import Alert from "../../../../../components/common/Alert";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [mensaje, setMensaje] = useState("");
    const [delitos, setDelitos] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getDelitosSindicado(props.registro.id, token).then((data) => {
            setDelitos(data);
            setCargando(false);
        });
    }, []);

    const cambioEstado = async (values) => {
        setCargando(true);
        await putCambioEstado(props.registro.id, values, token)
            .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    toast.success("registro actualizado con éxito");
                    props.setReFetch(true);
                    setTimeout(() => {
                        props.setReFetch(false);
                    }, 400);
                    setCargando(false);
                    props.setCambiarEstado(false);
                }
            })
            .catch(function (error) {
                if (error.response.status === 400 || error.response.status === 401) {
                    toast.error(error.response.data.message);
                    setCargando(false);
                } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                    setCargando(false);
                }
            });
    }

    return (
        <Formik
            initialValues={{
                estado: props?.registro?.estado || false,
                caso: props?.registro?.caso,
                sindicado: props?.registro?.sindicado?.id,
                //fecha: props?.registro?.fecha,
                usuario: usuario
            }}
            onSubmit={async (values) => {

                if (delitos.count > 0) {
                    setMensaje("Este sindicado tiene delitos y/o hechos asociados, desea continuar?")
                    setAlert(true);
                } else {
                    cambioEstado(values);
                }

            }}
        >
            {({ values, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 flex flex-col items-center justify-center">
                        <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                        <Toggle enabled={values.estado} name="estado" />
                    </div>
                    {
                        alert &&
                        <div className="mb-2">
                            <Alert type="warning" mensaje={mensaje} />
                        </div>
                    }
                    {alert &&
                        <BotonesConfirmar
                            aceptar={() => {
                                cambioEstado(values);
                            }}
                            cancelar={() => {
                                props.setCambiarEstado(false)
                            }}
                        />
                    }
                    {
                        (!cargando) ?
                            !alert &&
                            <ButtonSiavi
                                type="button"
                                disabled={isSubmitting}
                                text={"Actualizar"}
                                onClick={handleSubmit}
                            />
                            :
                            <Spinner />
                    }
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};
