import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  postAcciones,
  putAcciones,
  getServicioPropio,
  getAtencion,
  getAccionesParaSeg,
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SelectSiavi } from "../../../components/common/Select";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(255, "Máximo 100 caracteres"),
});

const useFetchServicioPropio = (token) => {
  const [state, setState] = useState({
    servicio_propio: [],
  });

  useEffect(() => {
    getServicioPropio(token).then((servicio_propio) => {
      setState({
        servicio_propio: servicio_propio,
      });
    });
  }, [token]);

  return state;
};

const useFetchAtencion = (token) => {
  const [state, setState] = useState({
    atencion: [],
  });

  useEffect(() => {
    getAtencion(token).then((atencion) => {
      setState({
        atencion: atencion,
      });
    });
  }, [token]);

  return state;
};

const useFetchAccionesParaSeg = (token) => {
  const [state, setState] = useState({
    acciones: [],
  });

  useEffect(() => {
    getAccionesParaSeg(token).then((acciones) => {
      setState({
        acciones: acciones,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { servicio_propio } = useFetchServicioPropio(token);
  const { atencion } = useFetchAtencion(token);
  const { acciones } = useFetchAccionesParaSeg(token);
  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          servicio: props.editar ? props?.value?.servicio?.id : "",
          atencion: props.editar ? props?.value?.atencion?.id : "",
          estado: props.editar ? props.value.estado : true,
          accion: true,
          seguimiento: false,
          aplica_meta: props.editar ? props.value.aplica_meta : false,
          requiere_autorizacion: props.editar
            ? props.value.requiere_autorizacion
            : false,
          es_audiencia: false,
          habilita_planificacion: props.editar
            ? props.value.habilita_planificacion
            : false,
          solicita_accion: props.editar ? props.value.solicita_accion : false,
          seguimiento_accion_solicitada: props.editar
            ? props.value.seguimiento_accion_solicitada
            : null,
            rep_digna: props.editar ? props.value.rep_digna: false,
            medida_victimologica: props.editar ? props.value.medida_victimologica: false
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putAcciones(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro actualizado con éxito...!");
              }
            });
          } else {
            await postAcciones(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="*Nombre"
                />
              </div>
              <div className="col-span-6 mt-5">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción"
                />
              </div>
              <Label text="Servicio" />
              <div className="mt-1">
                <select
                  id="servicio"
                  name="servicio"
                  value={values.servicio}
                  onChange={handleChange}
                  className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                >
                  <option value="">Elegir servicio</option>
                  {servicio_propio?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Label text="Atención" />
              <div className="mt-1">
                <select
                  id="atencion"
                  name="atencion"
                  value={values.atencion}
                  onChange={handleChange}
                  className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                >
                  <option value="">Elegir atención</option>
                  {atencion?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                </select>
              </div>
              {values.solicita_accion && (
                <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 mt-5 ">
                  <SelectSiavi
                    label="Seguimiento Acción Solicitada"
                    name="seguimiento_accion_solicitada"
                    value={values.seguimiento_accion_solicitada}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir seguimiento acción solicitada"
                    list={acciones?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre +
                            " - " +
                            (item?.servicio?.nombre === undefined
                              ? " "
                              : item?.servicio?.nombre) +
                            " - " +
                            (item?.atencion?.nombre === undefined
                              ? " "
                              : item?.atencion?.nombre)}
                        </option>
                      );
                    })}
                  />
                </div>
              )}
              <div className="col-span-12 mt-5 flex justify-between border rounded-md p-2 hover:bg-gray-50 cursor-pointer">
                <Label text={`${values.estado ? "Activo" : "Inactivo"}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="col-span-12 mt-5 flex justify-between border rounded-md p-2 hover:bg-gray-50 cursor-pointer">
                <Label text="¿Aplica Meta?" />
                <Toggle enabled={values.aplica_meta} name="aplica_meta" />
              </div>
              <div className="col-span-12 mt-5 flex justify-between border rounded-md p-2 hover:bg-gray-50 cursor-pointer">
                <Label text="¿Requiere Autorización?" />
                <Toggle
                  enabled={values.requiere_autorizacion}
                  name="requiere_autorizacion"
                />
              </div>
              <div className="col-span-12 mt-5 flex justify-between border rounded-md p-2 hover:bg-gray-50 cursor-pointer">
                <Label text="Habilita Planificación" />
                <Toggle
                  enabled={values.habilita_planificacion}
                  name="habilita_planificacion"
                />
              </div>
              <div className="col-span-12 mt-5 flex justify-between border rounded-md p-2 hover:bg-gray-50 cursor-pointer">
                <Label text="Solicita Acción" />
                <Toggle
                  enabled={values.solicita_accion}
                  name="solicita_accion"
                />
              </div>

              <div className="col-span-12 mt-2 flex justify-between items-center border p-2 rounded-md hover:bg-gray-50">
                <Label text="Reparación Digna" />
                <Toggle enabled={values.rep_digna} name="rep_digna" />
              </div>
              <div className="col-span-12 mt-2 flex justify-between items-center border p-2 rounded-md hover:bg-gray-50">
                <Label text="Medida Victimológica" />
                <Toggle
                  enabled={values.medida_victimologica}
                  name="medida_victimologica"
                />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
