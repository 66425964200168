import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Crear } from "./Crear";
import { postSedes } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

// Validation with Yup
const validaciones = Yup.object().shape({
  codigo: Yup.string().max(10, "máximo 10 caracteres"),
  sede: Yup.string()
    .required("Sede es requerida")
    .max(100, "máximo 100 caracteres"),
  direccion: Yup.string().required("direccion es requerida").max(200, "máximo 200 caracteres"),
  telefono: Yup.string().max(20, "Maximo 20 caracteres"),
  descripcion: Yup.string()
    .required("descripción es requerida")
    .max(100, "máximo 100 caracteres"),
  municipio: Yup.string().required("municipio es requerido"),
  departamento: Yup.string().required("departamento es requerido"),
  tipo_sede: Yup.string().required("sede es requerida"),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormCrear = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const history = useHistory(); 
  return (
    <Formik
      initialValues={{
        sede: "",
        direccion: "",
        departamento: "",
        municipio: "",
        telefono: "",
        codigo: "",
        descripcion: "",
        hacen_turnos: true,
        tipo_sede: "",
        estado: true,
        cobertura: [],
        es_sede_seguimiento: true,
        sede_seguimiento: ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await postSedes(values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status===201) {
              setSuccess(true);
              setError(false);
              setMensaje('Registro creado con éxito...!');
              toast.success('Registro creado con éxito...!')
              history.push('/catalogos/sedes');
            } else {
              setError(true);
              setSuccess(false);
            }
          })
          .catch(function (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {(props) => (
        <Crear
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
        />
      )}
    </Formik>
  );
};
