import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getVisitas = async (urlPage, search, sede, order, filters, token) => {
  try {
    let urlFetch = urlPage === undefined
      ? `${REACT_APP_API_URL}/registro/visitasedeorientacion/?sede=${sede}&search=${search.value}&ordering=${order}&${filters}`
      : `${urlPage}&sede=${sede}&ordering=${order}&search=${search.value}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const visitas = async (urlPage, filters, search, idSede, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/visitasedeorientacion/?sede=${idSede}&${filters}`
        : `${urlPage}&sede=${idSede}&search=${search.value}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getMotivosVisitas = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/motivovisita/?page_size=3000&estado=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};


export const getUsuarios = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const postEspacioSeguro = async ({ fecha, edad, es_nna, observaciones, persona, representante_legal, caso, delito, sede, profesional }, token) => {
  try {
    let body = {
      fecha,
      edad,
      es_nna,
      observaciones,
      persona,
      representante_legal,
      caso,
      delito,
      sede,
      profesional
    }

    let bodyResult = axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/registro/espacioseguro/`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return bodyResult;

  } catch (e) {
    console.log('error', e);
  }
};

export const putEspacioSeguro = async (id, { fecha, edad, es_nna, observaciones, persona, representante_legal, caso, delito, sede, profesional }, token) => {

  try {
    let body = {
      fecha,
      edad,
      es_nna,
      observaciones,
      persona,
      representante_legal,
      caso,
      delito,
      sede,
      profesional
    }

    let bodyResult = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/registro/espacioseguro/${id}/`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return bodyResult;

  } catch (e) {
    console.log('error', e);
  }
};

export const deleteEspacioSeguro = async (id, token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/registro/espacioseguro/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getEspacioSeguroVisitas = async (idPersona, token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/registro/espacioseguro/?persona=${idPersona}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getDelitos = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/delito/?page_size=3000&estado=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getInsumosEspacioSeguro = async (idEspacioSeguro, token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/registro/espacioseguroinsumos/?espacio_seguro=${idEspacioSeguro}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getInsumos = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/insumos/?page_size=3000&estado=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const postEspacioSeguroInsumo = async ({ cantidad, espacio_seguro, estado, alimenta, profesional_crea }, token) => {
  try {
    let body = {
      cantidad,
      espacio_seguro,
      estado,
      alimenta,
      profesional_crea
    }

    let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/espacioseguroinsumos/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }).then(data => data.json());

    return Bodyresult;

  } catch (e) {
    console.log('error', e);
  }
};

export const putEspacioSeguroInsumo = async (id, { cantidad, espacio_seguro, estado, alimenta, profesional_crea }, token) => {
  try {
    let body = {
      cantidad,
      espacio_seguro,
      estado,
      alimenta,
      profesional_crea
    }

    let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/espacioseguroinsumos/${id}/`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }).then(data => data.json());

    return Bodyresult;

  } catch (e) {
    console.log('error', e);
  }
};

export const deleteEspacioSeguroInsumo = async (id, token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/registro/espacioseguroinsumos/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const putVisita = async (id, data, token) => {

  let body = {
    persona: data.persona.id,
    atendido: true,
    sede: data.sede.id,
    motivo_visita: (Number(data.motivo_visita) === 0) ? null : Number(data.motivo_visita),
    profesional_al_que_visita: (Number(data.profesional_al_que_visita) === 0) ? null : Number(data.profesional_al_que_visita),
    denuncia: data.denuncia,
    presento_denuncia: data.presento_denuncia,
    observaciones: data.observaciones
  }

  try {

    let bodyResult = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/registro/visitasedeorientacion/${id}/`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return bodyResult;

  } catch (e) {
    console.log('error', e);
  }
};

export const getConfiguracion = async (id, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/catalogos/configuracion/${id}/`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const actualizarVisita = async (id, data, token) => {
  let body = {
    persona: data.persona.id,
    atendido: true,
    sede: data.sede.id,
    motivo_visita: data?.motivo_visita?.id,
    denuncia: data?.denuncia,
    presento_denuncia: data?.presento_denuncia,
    observaciones: data.observaciones
  }

  try {

    let bodyResult = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/registro/visitasedeorientacion/${id}/`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return bodyResult;

  } catch (e) {
    console.log('error', e);
  }
};