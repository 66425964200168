/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getGestionaCriterioCierres, gestionaCirterioCierres } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { ClipboardCheckIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { Gestionar } from "./gestionar";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import toast from "react-hot-toast";
import Filtros from "./filtros";
import ButtonAdd from "../../../components/common/ButtonAdd";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "date" },
    { title: "Sede", value: "caso.sede_creacion.sede", tipo: "text" },
    { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
    {
        title: "Nombre Víctima",
        value: null,
        values: [
            "caso.victima.primer_nombre",
            "caso.victima.segundo_nombre",
            "caso.victima.primer_apellido",
            "caso.victima.segundo_apellido",
        ],
        tipo: "text"
    },
    { title: "Servicio", value: "expediente.servicio_propio.nombre", tipo: "text" },
    { title: "Tipo Proceso", value: "expediente.tipo_proceso.tipo_proceso", tipo: "text" },
    {
        title: "Profesional solicita",
        value: null,
        values: [
            "profesional_solicita.first_name",
            "profesional_solicita.last_name",
        ],
        tipo: "text"
    },
    {
        title: "Gestionado",
        value: "gestiona_cierre",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
        ordenar: "gestiona_cierre"
    },
    {
        title: "Autorizado",
        value: "cierre_autorizado",
        tipo: "boolean",
        text: { true: "Sí", false: "No", null: "" }
    },
    {
        title: "Profesional gestiona",
        value: null,
        values: [
            "profesional_gestiona.first_name",
            "profesional_gestiona.last_name",
        ],
        tipo: "text"
    },
    { title: "Fecha Gestiona", value: "fecha_gestiona", tipo: "date" }
];

export const TableGestionaCriterioCierre = () => {
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const [cierre, setCierre] = useState("");
    const [agregar, setAgregar] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const [filters, setFilters] = useState("");
    const [order, setOrder] = useState("-id");
    const [openFilters, setOpenFilters] = useState(false);

    const permiso = "expedientecriteriocierre";




    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "GestionarCierreExpedientes";  //mm permiso padre
  
    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
      let retorno = false;
      permisosRegistro?.forEach(menu => {
        if (menu?.permiso === permisoView) {
          menu?.opciones?.forEach(op => {
            if (op.permiso === permiso) {
              retorno = op.ver;
            }
          })
        }
      });
      // retorno = true;  // mm comentar para que funcione
      return retorno;
  
    }
  


    useEffect(() => {
        setTableLoading(true);
        gestionaCirterioCierres(urlFetch, filters, order, token).then((data) => {
            setDataTable(data);
            setReFetch(false);
            setTableLoading(false);
        });
    }, [urlFetch, filters, order, reFetch]);

    useEffect(() => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setTableLoading(true);
            getGestionaCriterioCierres(urlFetch, search, filters, order, token)
                .then((data) => {
                    setDataTable(data);
                    setReFetch(false);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    }, [urlFetch, search, filters, order, reFetch]);

    const handleAgregar = (item) => {
        if (item.gestiona_cierre === false) {
            console.log(item)
            setCierre(item);
            setAgregar(true);
        } else {
            toast.error("No se puede gestionar el registro, porque ya está gestionado")
        }
    };

    const pages = [
        {
            name: "Funcionalidades",
            href: "/funcionalidades-generales",
            current: false,
        },
        { name: "Gestionar cierre expedientes", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
                <BreadCrumb navegacion={pages} />
                <div>
                    <ButtonAdd
                        className="ml-2"
                        text="Filtros"
                        type="button"
                        onClick={() => {
                            setOpenFilters(true);
                        }}
                        icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                    />
                    <ButtonAdd
                        className="ml-2"
                        text="Refrescar"
                        type="button"
                        onClick={() => {
                            setFilters("");
                        }}
                        icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                    />
                </div>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showEliminar={false}
                        showEditar={false}
                        showVer={false}
                        showPrint={false}
                        order={order}
                        setOrder={setOrder}
                        //agregar={true}
                        agregar={(CheckPermiso('GestionarCierre') === true)}
                        agregar1="Gestionar"
                        handleAgregar={handleAgregar}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
            {agregar && (
                <ModalGenerico
                    title={"Autorizar cierre del expediente"}
                    open={agregar}
                    closeModal={() => { }}
                    cerrar={() => {
                        setAgregar(false);
                    }}
                    icon={
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                            <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                        </div>
                    }
                    children={
                        <Gestionar cierre={cierre} setAgregar={setAgregar} setReFetch={setReFetch} />
                    }
                />
            )}
            {openFilters && (
                <ModalGenerico
                    title={`Aplicar filtros`}
                    open={openFilters}
                    closeModal={() => { }}
                    cerrar={() => {
                        setOpenFilters(false);
                    }}
                    children={
                        <Filtros
                            setFilters={setFilters}
                            setOpenFilters={setOpenFilters}
                            token={token}
                        />
                    }
                    icon={
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <FilterIcon className="h-6 w-6 text-green-600" />
                        </div>
                    }
                />
            )}
        </div>
    );
};
