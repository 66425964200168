import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getReferencias = async (urlPage, search,sede,filters,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/referencia/?search=${search.value}&${filters}&referencia_ts=true&asignado=false&estado=true&solicitud_referencia=true&ordering=${order}`
        :`${urlPage}&search=${search.value}&${filters}&referencia_ts=true&asignado=false&estado=true&solicitud_referencia=true&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const referencias = async (urlPage,filters,order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/referencia/?${filters}&referencia_ts=true&asignado=false&estado=true&solicitud_referencia=true&ordering=${order}`
        : `${urlPage}&${filters}&referencia_ts=true&asignado=false&estado=true&solicitud_referencia=true&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getServicios = async(token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getTipoProcesos = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAtenciones = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/atencion/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const convertirReferencias = async (registros,token) => {
  try {
      let ids =[];

      registros.forEach(element => {
          ids.push(element.id);
      });
      
      let body ={
        solicitudes: ids
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/solicitudareferencia/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};