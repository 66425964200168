/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import {
    getEtapasProcesales,
    postExpEtapaProcesal
} from "./services";
import { Formik } from "formik";
import Spinner from "../../../../../components/common/Spinner";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    especificaciones: Yup.string().max(300, "maximo 300 caracteres"),
});

export const EtapaProcesal = (props) => {
    const token = useSelector((state) => state?.loginReducer?.data?.token);
    const animatedComponents = makeAnimated();
    const [cargando, setCargando] = useState(false);
    const [etapaSeleccionada, setEtapaSeleccionada] = useState([]);
    const [ etapas,setEtapas ] = useState([]);
    const optionsEtapas = [];
    const idCaso = props?.expediente?.caso;
    const idProceso = props?.expediente?.tipo_proceso.id;
    const idExpediente = props?.expediente?.id;
    const idTipoProceso = props?.expediente?.tipo_proceso?.id;

    useEffect(() => {
        getEtapasProcesales(idProceso,token).then((data)=>{
          setEtapas(data);
        })
    }, []);
    const changeSelectEtapas = (e) => {
        setEtapaSeleccionada(e);
    };

    etapas?.results?.forEach((item) => {
        let reg = {
          value: item.id,
          label: item.nombre,
        };
        optionsEtapas.push(reg);
      });

    return (
        <Formik
            initialValues={{
                
            }}
            validationSchema={validaciones}
            onSubmit={async () => {
                setCargando(true);
                await postExpEtapaProcesal(
                    idCaso,
                    idExpediente,
                    idTipoProceso,
                    etapaSeleccionada.value,
                    token
                ).then((data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                        toast.success("Etapa agregada con éxito...");
                        props.reFetch(true);
                        props.setEtapa(false);
                        setCargando(false);
                        props.reFetch(false);
                    } else {
                        toast.success("Etapa agregada con éxito..!");
                    }
                })
                    .catch(function (error) {
                        if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                        ) {
                            toast.error(error.response.data.message);
                            setCargando(false);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                            setCargando(false);
                        }
                    });
            }}
        >
            {({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Select
                        isMulti={false}
                        placeholder="Seleccionar etapa"
                        components={animatedComponents}
                        name="etapas"
                        className="basic-multi-select text-xs"
                        classNamePrefix="seleccione"
                        options={optionsEtapas}
                        onChange={changeSelectEtapas}
                        maxMenuHeight={120}
                    />
                    {
                        (cargando) ?
                            <div className="p-2"><Spinner /></div> :
                            <div className="mt-3 mb-16">
                                <ButtonSiavi
                                    type="button"
                                    text="Agregar"
                                    onClick={handleSubmit}
                                />
                            </div>
                    }
                </form>
            )}
        </Formik>
    );
};
