/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import {
  getProfesionales,
  getServicios,
  getDeptosGestion,
  getMunicipiosGestion
} from "./services";
import { SelectSiavi } from "../../../components/common/Select";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import Label from "../../../components/common/Label";

export default function Filtros(props) {
  let filtros = "";
  const [state, setState] = useState({
    profesional_solicita: "",
    servicios: "",
    departamentos: "",
    municipios: "",
    fecha_desde: "",
    fecha_hasta: "",
    autorizado: "",
    estado: ""
  });

  const [profesionales, setProfesionales] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getServicios(props.token).then((data) => {
      setServicios(data);
    });
  }, []);

  useEffect(() => {
    getDeptosGestion(props.token).then((data) => {
      setDepartamentos(data);
    });
  }, []);

  useEffect(() => {
    getMunicipiosGestion(props.token).then((data) => {
      setMunicipios(data);
    });
  }, []);

  return (
    <div className="mx-4">
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="profesional_solicita"
          label="Profesional Solicita Nombramiento"
          defaultValue=""
          value={state.profesional_solicita}
          onChange={onChange}
          initValue=""
          selectInit="Elegir profesional"
          list={
            profesionales?.results?.map((item, index) => {
              return <option key={item?.first_name + index} value={item?.id}>{item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="servicios"
          label="Servicios"
          defaultValue=""
          value={state.servicios}
          onChange={onChange}
          initValue=""
          selectInit="Elegir servicio"
          list={
            servicios?.results?.map((item, index) => {
              return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="departamentos"
          label="Departamento"
          defaultValue=""
          value={state.departamentos}
          onChange={onChange}
          initValue=""
          selectInit="Elegir departamento"
          list={
            departamentos?.results?.map((item, index) => {
              return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="municipios"
          label="Municipios"
          defaultValue=""
          value={state.municipios}
          onChange={onChange}
          initValue=""
          selectInit="Elegir municipio"
          list={
            municipios?.results?.map((item, index) => {
              return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha fin"
        />
      </div>
      <div className="col-span-12 mt-4">
        <Label text="Estado" />
        <div className="mt-1">
          <select
            name="estado"
            value={state.estado}
            onChange={onChange}
            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
          >
            <option value="">Elegir estado</option>
            <option value="null">Por definir</option>
            <option value="true">Autorizado</option>
            <option value="false">Rechazado</option>
          </select>
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <Label text="Gestionado" />
        <div className="mt-1">
          <select
            name="autorizado"
            value={state.autorizado}
            onChange={onChange}
            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
          >
            <option value="">Elegir gestionado</option>
            <option value="true">Sí</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `${(state.profesional_solicita !== "" ? '&usuario_requiere_nombramiento=' : '')}${state.profesional_solicita}${(state.servicios !== "") ? '&expediente__servicio=' : ''}${state.servicios}${(state.departamentos !== "") ? '&departamento=' : ''}${state.departamentos}${(state.municipios !== "") ? '&municipio=' : ''}${state.municipios}${(state.fecha_desde !== "") ? '&fecha__gte=' : ''}${state.fecha_desde}${(state.fecha_hasta !== "") ? '&fecha__lte=' : ''}${state.fecha_hasta}${(state.autorizado !== "") ? '&autorizado=' : ''}${state.autorizado}${(state.estado !== "") ? '&estado=' : ''}${state.estado}`;
            props.setFilters(filtros);
            props.setOpenFilters(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
