import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { TextAreaSiavi } from "../../../../../../components/common/TextArea";
import { clases } from "../../../../../../helpers/clases";
import {
    deleteEvolucionMedica,
    getEvolucionDiagnostico,
    postEvolucionMedica,
    putEvolucionMedica
} from "./services";
import Spinner from "../../../../../../components/common/Spinner";
import ModalGenerico from "../../../../../../components/common/Modal";
import FormEliminar from "../../../../../../components/common/FormEliminar";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const classIcon = `hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`;

const validaciones = Yup.object().shape({
    historia_clinica_evolucion: Yup.string()
        .required("Historia Clínica Evolución es requerida"),
});

export const GestionEvolucion = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const idDiagnostico = props?.diagnostico?.id;
    const idExpediente = props?.idExpediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idEvolucion, setIdEvolucion] = useState("");
    const [evolucion, setEvolucion] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        getEvolucionDiagnostico(idDiagnostico, token).then((evoluciones) => {
            setDataTable(evoluciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idDiagnostico, reFetch]);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "expedienteevolucionmedica";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    fecha: isEdit ? evolucion?.fecha : "",
                    numero_cita: isEdit ? evolucion?.numero_cita : "",
                    historia_clinica_evolucion: isEdit ? evolucion?.historia_clinica_evolucion : "",
                    laboratorios: isEdit ? evolucion?.laboratorios : "",
                    tratamiento: isEdit ? evolucion?.tratamiento : "",
                    observaciones: isEdit ? evolucion?.observaciones : "",
                    expediente_diagnostico: props.diagnostico.id,
                    profesional: idUser,
                    expediente: idExpediente,
                    caso: props.idExpediente.caso,
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putEvolucionMedica(evolucion.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    setReFetch(false);
                                    toast.success('Registro actualizado con éxito...!');
                                    setEdit(false);
                                    resetForm({});
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    } else {
                        await postEvolucionMedica(values, token).then((data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                                setReFetch(true);
                                setReFetch(false);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        }).catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 450) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error("Error interno comuniquese con el administrador");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2 hover:shadow-lg">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="date"
                                    name="fecha"
                                    id="fecha"
                                    value={values.fecha}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="numero_cita"
                                    value={values.numero_cita}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Número Cita"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <TextAreaSiavi
                                    label="*Evolución"
                                    validacion={true}
                                    name="historia_clinica_evolucion"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.historia_clinica_evolucion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <TextAreaSiavi
                                    label="Laboratorios"
                                    validacion={false}
                                    name="laboratorios"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.laboratorios}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <TextAreaSiavi
                                    label="Tratamiento"
                                    validacion={false}
                                    name="tratamiento"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.tratamiento}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6">
                                <TextAreaSiavi
                                    label="Observaciones"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 sm:col-span-4 md:col-span-2">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <TableSiavi
                    cargando={tableLoading}
                    headers={[
                        'Fecha',
                        'Número Cita',
                        'Historia Clínica Evolución',
                        'Laboratorios',
                        'Tratamiento',
                        'Observaciones',
                        'Acciones'
                    ]}
                    body={
                        dataTable?.results?.map((item, index) =>
                            <tr key={item.id + index} className="cursor-pointer hover:bg-gray-100">
                                <td className={clases.td}>{item?.fecha}</td>
                                <td className={clases.td}>{item?.numero_cita}</td>
                                <td className={clases.td}>{item?.historia_clinica_evolucion}</td>
                                <td className={clases.td}>{item?.laboratorios}</td>
                                <td className={clases.td}>{item?.tratamiento}</td>
                                <td className={clases.td}>{item?.observaciones}</td>
                                <td className={clases.td}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEdit(true);
                                            setEvolucion(item);
                                        }}
                                        className={classIcon}>
                                        <PencilAltIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                                        <span className="tooltiptext text-xs">Editar</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setIdEvolucion(item.id);
                                            setEliminar(true);
                                        }}
                                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                                        rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-secondaryRed tooltip`}>
                                        <TrashIcon className="h-5 w-5 text-maivi-secondaryRed hover:text-white" />
                                        <span className="tooltiptext text-xs">Eliminar</span>
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                />
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteEvolucionMedica(idEvolucion, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};