import { RefreshIcon } from "@heroicons/react/outline";
import ButtonAdd from "../../../components/common/ButtonAdd";

export default function ButtonRefresh(props) {
    return (
        <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
                props?.setReFetch(true);
                props?.setFilters(false);
            }}
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
        />
    );
};
