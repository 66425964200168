import { React } from "react";
import { Formik } from "formik";
import { Evaluacion } from "./evaluacion";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  tipo_persona:Yup.string().required('tipo persona es requerido'),
  status_persona:Yup.string().required('estatus persona es requerido')
});

export const FormEvaluacion = (props) => {
  const caso = props.caso;

  return (
    <Formik
      initialValues={{
        idPersona: null,
        observaciones_generales:"",
        servicio:null,
        plan: "",
        comentario_plan_tratamiento:""
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
       
      }}
    >
      {(props) => (
        <Evaluacion
          {...props}
          caso={caso}
        />
      )}
    </Formik>
  );
};
