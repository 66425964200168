import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getServicioPropio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getClasificacionBiblio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/clasificacionbiblioteca/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postBiblioteca = async (data, token) => {

    let clasificacionBiblio;
    let servicioBiblio;

    if (Number(data.clasificacion) === 0) {
        clasificacionBiblio = null;
    } else {
        clasificacionBiblio = Number(data.clasificacion);
    }

    if (Number(data.servicio) === 0) {
        servicioBiblio = null;
    } else {
        servicioBiblio = Number(data.servicio);
    }

    try {
        let body = {
            nombre_documento: data.nombre_documento,
            palabras_clave: data.palabras_clave,
            estado: data.estado,
            por_servicio: data.por_servicio,
            fecha_creacion: data.fecha_creacion,
            clasificacion: clasificacionBiblio,
            servicio: servicioBiblio
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/biblioteca/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putBiblioteca = async (id, data, token) => {

    let clasificacionBiblio;
    let servicioBiblio;

    if (Number(data.clasificacion) === 0) {
        clasificacionBiblio = null;
    } else {
        clasificacionBiblio = Number(data.clasificacion);
    }

    if (Number(data.servicio) === 0) {
        servicioBiblio = null;
    } else {
        servicioBiblio = Number(data.servicio);
    }

    try {
        let body = {
            nombre_documento: data.nombre_documento,
            palabras_clave: data.palabras_clave,
            estado: data.estado,
            por_servicio: data.por_servicio,
            fecha_creacion: data.fecha_creacion,
            clasificacion: clasificacionBiblio,
            servicio: servicioBiblio
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/biblioteca/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getBiblioteca = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/biblioteca/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};