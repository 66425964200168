import axios from "axios"
import moment from "moment";

const {
    REACT_APP_API_URL
} = process.env;

export const getServicios = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/institucion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionInstituciones = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getMotivosNoAdmisibilidad = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/noadmisibilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getMotivosAdmisibilidad = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/admisibilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDelitos = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/delito/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProcesos = async (idServicio,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/serviciopropiotipoproceso/?page_size=1000&servicio_interno=${idServicio.value}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getViabilidad = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/viabilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNoViabilidad = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/noviabilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getSedes = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersona = async (token,id) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/gestionpersona/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoPersonas = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipopersona/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getProfesiones = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoprofesional/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postOrientacion = async (values,token) => {
    let idsServicios =[];
    let idsProcesos =[];
    let idsDelitos = [];
    let idsTipoConsulta = [];
    let motivosAdmisibilidad =[];
    let motivosNoAdmisibilidad = [];
    let viabilidad =[];
    let noViabilidad = [];
    let profesionales = [];

    values.serviciosAgregados.forEach((item) =>{
        idsServicios.push(item.value);
    });

    values.procesosAgregados.forEach((item) =>{
        idsProcesos.push(item.value);
    });

    values.delitosAgregados.forEach((item) =>{
        idsDelitos.push(item.value);
    });

    values.tipoConsultaAgregados.forEach((item) =>{
        idsTipoConsulta.push(item.value);
    });

    values.motivosAdmisibilidad.forEach((item) =>{
        motivosAdmisibilidad.push(item.value);
    });

    values.motivosNoAdmisibilidad.forEach((item) =>{
        motivosNoAdmisibilidad.push(item.value);
    });

    values.motivosViabilidad.forEach((item) =>{
        viabilidad.push(Number(item.id));
    });

    values.motivosNoViabilidad.forEach((item) =>{
        noViabilidad.push(Number(item.id));
    });

    values.profesionales.forEach((item) =>{
        profesionales.push(Number(item.id));
    });

    try {
        let date = Date.now();
        let body = {
            no_denuncia: (values.numeroDenuncia ==="") ? null : values.numeroDenuncia,
            donde_denuncia: (values.institucionDenuncia === 0) ? null : values.institucionDenuncia,
            fecha_denuncia: (values.fechaDenuncia === "") ? null : values.fechaDenuncia,
            fecha_referencia : (values.fechaResolucion === "") ? null : values.fechaResolucion,
            fecha : (values.fecha === false) ? moment(date).format('YYYY-MM-DD') : values.fecha,
            acompanamientos: values.otros,
            denuncia: values.presentoDenuncia,
            es_admisible: (values.isAdmisible === "1") ? true : false,
            es_viable: (values.isViable === "1") ? true : false,
            historica: values.historica,
            ano_historico: (values.ano_historico === "") ? null : values.ano_historico,
            iv_historico: (values.iv_historico === "") ? null : values.iv_historico,
            caso_admitido: values.valorCaso,
            doc_referencia: values.numeroDocumento,
            tipo_documento_derivacion:(values.tipoDocto === "") ? null : Number(values.tipoDocto),
            comentarios: values.comentarios,
            observaciones:values.observaciones,
            persona: values.idPersona,
            entidad_deriva: (values.entidad_deriva === "") ? null : Number(values.entidad_deriva),
            sede: Number(values.sede),
            profesional: Number(values.profesional),
            servicios: idsServicios,
            tipo_procesos: idsProcesos,
            delitos: idsDelitos,
            tipo_consultas: idsTipoConsulta,
            motivo_admisibilidad : motivosAdmisibilidad,
            motivo_no_admisibilidad : motivosNoAdmisibilidad,
            tipo_persona:values.tipo_persona,
            descripcion_motivo_no_admisibilidad: values.descripcion_motivo_no_admisibilidad,
            querellante: values.querellante,
            victima: values.victima,
            como_le_gusta_que_le_llamen:values.como_le_gusta_que_le_llamen
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/orientacion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersona = async(idPersona, idCaso, token) => {
    try {
        let body = {
            status_caso_persona: true,
            caso: idCaso,
            persona: idPersona,
            parentesco:null,
            es_victima_indirecta: false,
            querellante: true,
            victima: true,
            remplazar_querellante:false,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersona/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postProfesionalOrientacion = async(orientacion, profesional, token) => {
    try {
        let body = {
            orientacion,
            profesional,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/orientacionprofesional/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDoctos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipodocumentoderivacion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoConsulta = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipoconsulta/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (sede,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&tipo_puesto__sub_area__area__es_areasustantiva=true&sedesusuario__sede=${sede}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
