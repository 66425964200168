/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
    getProfesionales,
    getDiligenciasFiltro
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";

export default function Filtros(props) {
    let filtros = "";
    const [state, setState] = useState({
        enviado_por: "",
        diligencias: "",
        fecha_desde: "",
        fecha_hasta: "",
        leido: ""
    });

    const [enviado_por, setEnviadoPor] = useState([]);
    const [diligencias, setDiligencias] = useState([]);

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        getProfesionales(props.token).then((data) => {
            setEnviadoPor(data);
        });
    }, []);

    useEffect(() => {
        getDiligenciasFiltro(props.token).then((data) => {
            setDiligencias(data);
        });
    }, []);

    return (
        <div className="mx-4">
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="enviado_por"
                    label="Enviado Por"
                    defaultValue=""
                    value={state.enviado_por}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir profesional"
                    list={
                        enviado_por?.results?.map((item, index) => {
                            return <option key={item?.first_name + index} value={item?.id}>{item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="diligencias"
                    label="Tipo"
                    defaultValue=""
                    value={state.diligencias}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir tipo"
                    list={
                        diligencias?.results?.map((item, index) => {
                            return <option key={item?.comentarios + index} value={item?.id}>{item?.comentarios}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha inicio"
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha fin"
                />
            </div>
            <div className="col-span-12 mt-4">
                <Label text="Leído" />
                <div className="mt-1">
                    <select
                        name="leido"
                        value={state.leido}
                        onChange={onChange}
                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                    >
                        <option value="">Elegir</option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `bandeja__profesional_origen=${state.enviado_por}&tipo_mensaje=${state.diligencias}&bandeja__fecha_enviado__gte=${state.fecha_desde}&bandeja__fecha_enviado__lte=${state.fecha_hasta}&leido=${state.leido}`;
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
