import { React} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Asistencia } from "./asistencia";

const validaciones = Yup.object().shape({
  tipo_persona: Yup.string().required('tipo persona es requerido'),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormAsistencia = (props) => {
  const caso = props.caso;

  return (
    <Formik
      initialValues={{
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
       
      }
     }
    >
      {(props) => (
        <Asistencia
          {...props}
          customSwitchChange={customSwitchChange}
          caso={caso}
        />
      )}
    </Formik>
  );
};
