const {
    REACT_APP_API_URL
} = process.env;

export const getCoercion = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/catalogomedidacoercion/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCoercion = async ({
    nombre,
    estado
},token) => {
    try {
        let body = {
            nombre,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogomedidacoercion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCoercion = async (id, {
    nombre,
    estado
},token) => {
    try {
        let body = {
            nombre,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/catalogomedidacoercion/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCoercion = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/catalogomedidacoercion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};