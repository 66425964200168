
const {
    REACT_APP_API_URL
} = process.env;

export const getMunicipios = async (urlPage,token, search) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/municipio/?search=${search}` : `${urlPage}&search=${search}` ;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptoMunicipio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMunicipio = async ({nombre, codigo, codigo_ine, departamento, estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            codigo_ine,
            departamento,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putMunicipio = async (id, {nombre,codigo,codigo_ine,departamento,estado},token) => {
    try {
        let body = {
            nombre,
            codigo,
            codigo_ine,
            departamento,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteMunicipio = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};