export default function NotFound() {
    return (
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-maivi-primaryLightBlue sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-maivi-primaryLightBlue tracking-tight sm:text-5xl">Pagina no encontrada</h1>
                <p className="mt-1 text-base text-gray-500">la ruta ingresada no existe</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }