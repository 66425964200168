import axios from "axios"
import moment from "moment";

const {
    REACT_APP_API_URL
} = process.env;

export const getServicios = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonasCaso = async (idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const postServicios = async(idOrientacion, idServicio, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            servicio: Number(idServicio)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionservicio/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteServicios = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionservicio/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};



export const getInstituciones = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/institucion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getMotivosNoAdmisibilidad = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/noadmisibilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNoAdmisibilidad = async(idOrientacion, idNoAdmisibilidad, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            no_admisibilidad: Number(idNoAdmisibilidad)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionnoadmisibilidad/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNoAdmisibilidad = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionnoadmisibilidad/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivosAdmisibilidad = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/admisibilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAdmisibilidad = async(idOrientacion, idAdmisibilidad, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            admisibilidad: Number(idAdmisibilidad)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionadmisibilidad/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAdmisibilidad = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionadmisibilidad/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDelitos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/delito/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientes = async(idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expediente/?caso_persona__caso=${idCaso}&page_size=20`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postDelitos = async(idOrientacion, idDelito, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            delito: Number(idDelito)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientaciondelito/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};



export const deleteDelitos = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientaciondelito/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getViabilidad = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/viabilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postViabilidad = async(idOrientacion, idViabilidad, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            viabilidad: Number(idViabilidad)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionviabilidad/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteViabilidad = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionviabilidad/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getNoViabilidad = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/noviabilidad/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNoViabilidad = async(idOrientacion, idNoViabilidad, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            no_viabilidad: Number(idNoViabilidad)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionnoviabilidad/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNoViabilidad = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacionnoviabilidad/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};



export const getSedes = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersona = async(token, id) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/gestionpersona/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrientacion = async(token, id) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacion/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getProfesiones = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipoprofesional/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoPersonas = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipopersona/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getStatusPersona = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/statuspersona/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProcesos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProcesosOrientacion = async (idServicio,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/serviciopropiotipoproceso/?page_size=1000&servicio_interno=${idServicio.value}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putOrientacion = async(id, values, token) => {
    let idsServicios = [];
    let idsProcesos = [];

    values.serviciosAgregados.forEach((item) => {
        idsServicios.push(item.value);
    });

    values.procesosAgregados.forEach((item) => {
        idsProcesos.push(item.value);
    });

    try {
        let body = {
            no_denuncia: (values.numeroDenuncia === "") ? null : values.numeroDenuncia,
            donde_denuncia: (values.institucionDenuncia === "0") ? null : values.institucionDenuncia,
            fecha_denuncia: (values.fechaDenuncia === "") ? null : values.fechaDenuncia,
            fecha_referencia : (values.fechaResolucion === "") ? null : values.fechaResolucion,
            acompanamientos: values.otros,
            denuncia: values.presentoDenuncia,
            es_admisible: (values.isAdmisible === "1") ? true : false,
            es_viable: (values.isViable === "1") ? true : false,
            caso_admitido: values.valorCaso,
            doc_referencia: values.numeroDocumento,
            comentarios: values.comentarios,
            observaciones: values.observaciones,
            persona: values.idPersona,
            entidad_deriva: (values.entidad_deriva === null) ? null : Number(values.entidad_deriva),
            sede: Number(values.sede),
            profesional: Number(values.profesional),
            servicios: idsServicios,
            tipo_procesos: idsProcesos,
            descripcion_motivo_no_admisibilidad: values.descripcion_motivo_no_admisibilidad,
            como_le_gusta_que_le_llamen: values.como_le_gusta_que_le_llamen,
            querellante: values.querellante
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/orientacion/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionInstituciones = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=300&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getExpsindicadosituacionjuridica = async(idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadosituacionjuridica/?expediente_sindicado__caso=${idCaso}&page_size=100`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSituacionesJuridicas = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/situacionjuridica/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMedidaCoercion = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/catalogomedidacoercion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSituacionJuridica = async(values, idExpediente, token) => {
    try {
        let body = {
            fecha: values.fecha,
            comentarios: values.comentarios,
            situacion_juridica: values.situacionJuridica,
            expediente_sindicado: idExpediente,
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expsindicadosituacionjuridica/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSituacionJuridicaHistorico = async (caso,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/expsindicadosituacionjuridica/?page_size=50&expediente_sindicado=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

export const getMedidaCoercionHistorico = async (caso,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/expedientesindicadomedidacoercion/?page_size=50&expediente_sindicado=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

export const postExpMedidaCoercion = async(values, idExpediente, token) => {
    try {
        let body = {
            fecha: values.fecha,
            comentarios: values.comentarios,
            medida_coercion: values.medidaCoercion,
            expediente_sindicado: idExpediente,
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientesindicadomedidacoercion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSindicadoDelito = async(idCaso, expedienteSindicado, delito, token) => {
    try {
        const fecha = new Date();

        let body = {
            fecha: moment(fecha).format('YYYY-MM-DD'),
            caso: idCaso,
            expediente_sindicado: expedienteSindicado,
            delito: delito
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expsindicadodelito/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSindicadoDelito = async(id,idCaso, expedienteSindicado, delito,estado, token) => {
    try {
        const fecha = new Date();

        let body = {
            fecha: moment(fecha).format('YYYY-MM-DD'),
            caso: idCaso,
            expediente_sindicado: expedienteSindicado,
            delito: delito,
            estado: estado
        };

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expsindicadodelito/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getSindicadoDelitos = async(idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadodelito/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postNarrativa = async(narrativa, idCaso, idExpediente, token) => {
    try {
        let date = Date.now()

        let body = {
            fecha: moment(date).format('YYYY-MM-DD'),
            narrativa: narrativa,
            caso: idCaso,
            expediente: idExpediente
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientehechos/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const putNarrativa = async(id, narrativa, idCaso, idExpediente, token) => {
    try {
        let date = Date.now()

        let body = {
            fecha: moment(date).format('YYYY-MM-DD'),
            narrativa: narrativa,
            caso: idCaso,
            expediente: idExpediente
        };

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientehechos/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNarrativa = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientehechos/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNarrativas = async(idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientehechos/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteSindicadoDelito = async(idCaso,idExpediente, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadodelito/?caso=${idCaso}&expediente_sindicado__expediente__tipo_proceso=${idExpediente}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postNarrativaSindicados = async(idCaso, idSindicadoDelito, idEcho, token) => {
    try {
        let body = {
            caso: idCaso,
            exp_sindicado_delito: idSindicadoDelito,
            hechos: idEcho
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/exphechosindicadodelito/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNarrativaSindicados = async(id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/exphechosindicadodelito/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};



export const postTemporalidad = async(values, idCaso, idExpediente, idHecho, token) => {
    try {
        let body = {
            aldea_caserio: values.aldea,
            zona: (values.zona === "") ? null : values.zona,
            tipo_lugar: values.lugar,
            nombre_lugar: values.direccion,
            direccion: values.direccion,
            anio: (values.year === "") ? null : values.year,
            mes: (values.mes === "") ? null : values.mes,
            dia: (values.dia === "") ? null : values.dia,
            edad: (values.edad === "") ? null : values.edad,
            observaciones: values.observaciones,
            caso: idCaso,
            expediente: idExpediente,
            hechos: idHecho,
            departamento: values.departamento,
            municipio: values.municipio
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTemporalidades = async(idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTemporalidad = async(idHecho, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/?hechos=${idHecho}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putTemporalidad = async(id, values, idCaso, idExpediente, idHecho, token) => {
    try {
        let body = {
            aldea_caserio: values.aldea,
            zona: (values.zona === "") ? null : values.zona,
            tipo_lugar: values.lugar,
            nombre_lugar: values.direccion,
            direccion: values.direccion,
            anio: (values.year === "") ? null : values.year,
            mes: (values.mes === "") ? null : values.mes,
            dia: (values.dia === "") ? null : values.dia,
            edad: (values.edad === "") ? null : values.edad,
            observaciones: values.observaciones,
            caso: idCaso,
            expediente: idExpediente,
            hechos: idHecho,
            departamento: values.departamento,
            municipio: values.municipio
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteTemporalidad = async(id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteExpsindicadosituacionjuridica = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadosituacionjuridica/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSindicadoDelitos = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadodelito/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaQuerellante = async(values, idCaso, token) => {
    try {
        let body = {
            status_caso_persona: true,
            caso: idCaso,
            persona: values.persona,
            parentesco: (values.relacion === "") ? null : Number(values.relacion),
            es_victima_indirecta: values.es_victima_directa,
            querellante: values.querellante,
            representante_legal:values.representante_legal,
            remplazar_querellante: values.remplazar_querellante
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersona/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaVictima = async(values, idCaso, token) => {
    try {
        let body = {
            status_caso_persona: true,
            //tipo_persona: values.tipo_persona,
            caso: idCaso,
            persona: values.persona,
            parentesco: values.relacion,
            es_victima_indirecta: (values.es_victima === "0") ? true : false,
            querellante: values.querellante,
            victima: (values.es_victima === "1") ? true : false,
            remplazar_querellante: values.remplazar_querellante,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersona/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaQuerellante = async(values, idCaso, idCasoPersona, token) => {
    try {
        let body = {
            status_caso_persona: values.status_caso_persona,
            caso: idCaso,
            persona: values.persona,
            parentesco: (values.relacion === "") ? null : values.relacion,
            es_victima_indirecta: values.es_victima_directa,
            querellante: values.querellante,
            representante_legal:values.representante_legal,
            victima: values.victima,
            remplazar_querellante: values.remplazar_querellante
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersona/${idCasoPersona}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const putQuerellanteVictima = async(values, idCaso, idCasoPersona, token) => {
    try {
        let body = {
            status_caso_persona: values.status_caso_persona,
            caso: idCaso,
            persona: values.persona,
            parentesco: (values.relacion === "") ? null : values.relacion,
            querellante: values.querellante,
            representante_legal:values.representante_legal,
            victima: values.es_victima,
            es_victima_indirecta: values.es_victima_indirecta,
            remplazar_querellante: values.remplazar_querellante
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersona/${idCasoPersona}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaVictima = async(values, idCaso, idCasoPersona, token) => {
    try {
        let body = {
            status_caso_persona: values.status_caso_persona,
            //tipo_persona: values.tipo_persona,
            caso: idCaso,
            persona: values.persona,
            parentesco: (values.relacion === "") ? null : values.relacion,
            es_victima_indirecta: (values.es_victima === "0") ? true : false,
            querellante: values.querellante,
            victima : (values.es_victima === "1") ? true : false,
            remplazar_querellante: values.remplazar_querellante
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersona/${idCasoPersona}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putVictimaQuerellante = async(values, idCaso, idCasoPersona, token) => {
    try {
        let body = {
            status_caso_persona: values.status_caso_persona,
            caso: idCaso,
            persona: values.persona,
            parentesco: (values.relacion === "") ? null : values.relacion,
            es_victima_indirecta: values.es_victima_indirecta,
            querellante: values.querellante,
            victima : values.victima,
            remplazar_querellante: values.remplazar_querellante
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersona/${idCasoPersona}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasoHechos = async(caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientehechos/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const getCasoHechosTemporalidad = async(caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/exphechoslugartemporalidad/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const getCasoEvaluacionRiesgo = async(caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casoevaluacionriesgo/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}


export const getExpedienteSindicadoDelitos = async(caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expsindicadodelito/?caso=${caso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const getExpedienteSindicados = async(caso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientesindicado/?caso=${caso}&page_size=20`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getDiligenciasExpediente = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?expediente=${id}&urgente=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getFullCasoDetails = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/caso/${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonasCasoDetails = async(ids, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/consulta-personas/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({personas: ids})
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNivelRiesgos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/nivelriesgo/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoRiesgos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tiporiesgo/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionRiesgo = async(values, token) => {
    try {
        let idsRiesgos = [];

        values.tipo_riesgos.forEach(element => {
            idsRiesgos.push(element.value);
        });
        let body = {
            tipos_riesgo: idsRiesgos,
            especificaciones: values.especificaciones,
            caso: values.idCaso,
            persona: values.idPersona,
            nivel_riesgo: values.nivel_riesgo,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casoevaluacionriesgo/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEvaluaciones = async(idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casoevaluacionriesgo/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoRiesgosPersona = async(idEvaluacion, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casoevaluaciontiporiesgo/?evaluacion=${idEvaluacion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getParentesco = async(token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/parentesco/?page_size=3000&estado=true&aplica_sindicados=false`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDoctos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipodocumentoderivacion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoConsulta = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/tipoconsulta/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postTipoConsulta = async(idOrientacion, idTipoConsulta, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            tipo_consulta: Number(idTipoConsulta)
        };

        let urlFetch = `${REACT_APP_API_URL}/registro/orientaciontipoconsulta/`;
        let result = await fetch(urlFetch, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteTipoConsulta = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientaciontipoconsulta/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConfiguracion = async(token) => {
    const id = 1;
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/configuracion/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getHistorialSindicado = async(idExpedienteSindicado,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/histexpedientedelitossindicado/?exp_delito_sindicado=${idExpedienteSindicado}&ordering=-id`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postProcesoOrientacion = async(idOrientacion,proceso,servicio, token) => {
    try {
        let body = {
            orientacion: idOrientacion,
            tipo_proceso: proceso,
            servicio_propio:servicio
        };

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/orientaciontipoproceso/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getProfesionales = async (sede,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&tipo_puesto__sub_area__area__es_areasustantiva=true&sedesusuario__sede=${sede}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

  export const getOrientacionProfesionales = async (orientacion,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/orientacionprofesional/?orientacion=${orientacion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

export const postProfesionalOrientacion = async(orientacion, profesional, token) => {
    try {
        let body = {
            orientacion,
            profesional,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/orientacionprofesional/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putProfesionalOrientacion = async(id,orientacion, profesional, token) => {
    try {
        let body = {
            orientacion,
            profesional,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/orientacionprofesional/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteProfesionalOrientacion = async(id, token) => {
    try {
        let result = axios({
            method: 'DELETE',
            url: `${REACT_APP_API_URL}/registro/orientacionprofesional/${id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postValidarFicha = async(orientacion, token) => {
    try {
        let body = {
            orientacion,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/validar-caso-orientacion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
