import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { StarIcon } from "@heroicons/react/solid";
import { DynamicIcon } from '../../components/common/DynamicIcon';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../app/redux/store/store";
import { ChangeFavoritos } from "../../app/redux/actions/favoritos";
import { saveFavoritos } from '../../helpers/utils';

const menu = [
  {
    permiso: "victimas",
    name: "Víctimas",
    href: "/funcionalidades-generales/listar",
    icon: 'UserGroupIcon'
  },
  {
    permiso: "Orientacion",
    name: "Orientación legal-victimológica",
    href: "/funcionalidades-generales/listar-orientacion",
    icon: 'UserCircleIcon'
  },
  {
    permiso: "visitas",
    name: "Visitas",
    href: "/funcionalidades-generales/visitas",
    icon: 'ViewListIcon'
  },
  {
    permiso: "Expedientes",
    name: "Expedientes",
    href: "/funcionalidades-generales/casos",
    icon: 'FolderOpenIcon'
  },
  {
    permiso: "AsignacionDeExpedientesPorArea",
    name: "Asignaciones de expedientes por área",
    href: "/funcionalidades-generales/expedientes",
    icon: 'DocumentTextIcon'
  },
  {
    permiso: "ExpedientesArea",
    name: "Expedientes por área",
    href: "/funcionalidades-generales/reasignar-expedientes",
    icon: 'DocumentTextIcon'
  },
  {
    permiso: "creacionhistoricos",
    name: "Creación de casos históricos",
    href: "/funcionalidades-generales/listar-historico",
    icon: 'TimeLine',
    ubicacion: "local"
  },
  {
    permiso: "historicos",
    name: "Expedientes históricos",
    href: "/funcionalidades-generales/expedientes-historicos",
    icon: 'TimeLine',
    ubicacion: "local"
  },
  {
    permiso: "Referenciasatrabajosocial",
    name: "Referencias a trabajo social",
    href: "/funcionalidades-generales/referencias-trabajo-social",
    icon: 'TrabajoSocial',
    ubicacion: 'local'
  },
  {
    permiso: "Asignacióndereferencias",
    name: "Asignación de referencias",
    href: "/funcionalidades-generales/referencias",
    icon: 'UserGroupIcon'
  },
  {
    permiso: "Referencias",
    name: "Referencias",
    href: "/funcionalidades-generales/boton-referencias",
    icon: 'UserGroupIcon'
  },
  {
    permiso: "NotificacionesLegales",
    name: "Notificaciones Legales",
    href: "/funcionalidades-generales/notificaciones-legales",
    icon: 'FolderOpenIcon'
  },
     {
      permiso: "nombramientos",
      name: "Nombramientos",
      href: "/funcionalidades-generales/registro-nombramientos",
      icon: 'DocumentTextIcon'
    }, 
  {
    permiso: "AccionesVictimol0gicas",
    name: "Acciones Victimológicas",
    href: "/funcionalidades-generales/acciones",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "Seguimientoslegales",
    name: "Seguimientos legales",
    href: "/funcionalidades-generales/seguimientos",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "audienciasprogramadas",
    name: "Audiencias programadas",
    href: "/funcionalidades-generales/audiencias-planificadas",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "Procuracionesprogramadas",
    name: "Procuraciones programadas",
    href: "/funcionalidades-generales/procuraciones-programadas",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "audiencias",
    name: "Audiencias",
    href: "/funcionalidades-generales/audiencias",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "GestionarCierreExpedientes",
    name: "Gestionar Cierre Expediente por Área",
    href: "/funcionalidades-generales/gestion-cierre-expediente",
    icon: 'ClipboardCheckIcon'
  },
  /*   {
      permiso: "victimas",
      name: "Gestionar Cierre de Expediente",
      href: "/funcionalidades-generales/gestion-cierre-caso",
      icon: 'ClipboardCheckIcon'
    }, */
  {
    permiso: "ReservasDeEspaciosFisicos",
    name: "Reservas de espacios físicos",
    href: "/funcionalidades-generales/recursos",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "Biblioteca",
    name: "Biblioteca",
    href: "/funcionalidades-generales/biblioteca",
    icon: 'BookOpenIcon'
  },
  {
    permiso: "Profesionales",
    name: "Profesionales",
    href: "/funcionalidades-generales/profesionales",
    icon: 'UserGroupIcon'
  },
  {
    permiso: "EspacioSeguro",
    name: "Espacio Seguro",
    href: "/funcionalidades-generales/espacio-seguro",
    icon: 'DocumentTextIcon'
  },
  {
    permiso: "AtencionesComplementarias",
    name: "Atenciones Complementarias",
    href: "/funcionalidades-generales/atencion-acciones",
    icon: 'ClipboardCheckIcon'
  }
];

export const Menu = () => {
  const permisos = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);
  const menuFavoritos = useSelector(state => state.ChangeFavoritos.menus ? state.ChangeFavoritos.menus : []);
  const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
  let history = useHistory();
  const [favoritos, setFavoritos] = useState(menuFavoritos);

  const markFavorite = (item) => {
    let menu = { ...item };
    let data = menuFavoritos;
    let find = true;
    data.forEach((mn, index) => {
      if (item.href === mn.href) {
        data.splice(index, 1)
        find = false;
      }
    })
    if (find) {
      data.push(menu)
    }
    store.dispatch(ChangeFavoritos(data));
    saveFavoritos(data, idUser);
    setFavoritos(data);
    history.push("/funcionalidades-generales");
  }

  const isFavorite = (item) => {
    let retorno = false;
    favoritos.forEach(mn => {
      if (item.href === mn.href) {
        retorno = true;
      }
    })

    return retorno;
  }

  const checkPermiso = (item) => {
    let retorno = false;

    permisos.forEach(p => {
      if (p.permiso === item.permiso || p.text === item.permiso) {
        retorno = p.ver;
      }
    })

    //retorno = true;
    return retorno;
  }

  return (
    <div>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 m-0">
        {menu.map((item, index) =>
          checkPermiso(item) &&
          <li key={`${index}-fun`} className="flex shadow-lg rounded-md h-20">
            <div onDoubleClick={() => markFavorite(item)} className="cursor-pointer flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-maivi-primaryLightBlue">
              {isFavorite(item) === true && <StarIcon />}
            </div>
            <Link key={item.name} to={item.href} className="h-22 flex-1 flex flex-col items-center justify-between border border-maivi-primaryLightBlue bg-white rounded-r-md break-word hover:bg-maivi-secondaryBlueHover">
              <div className="flex flex-col items-center my-auto">
                <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                <p className="text-xs text-gray-900 text-center font-medium">
                  {item.name}
                </p>
              </div>
            </Link>

          </li>
        )}
      </ul>
    </div>
  );
};
