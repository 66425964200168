import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getExpedientes = async (urlPage, search,sede,profesional,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/expediente/?search=${search.value}&estado=true&profesional_asignado=${profesional}&ordering=-fecha_asignacion,${order}`
        :`${urlPage}&search=${search.value}&estado=true&profesional_asignado=${profesional}&ordering=-fecha_asignacion,${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const expedientes = async (urlPage,sede,profesional, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/expediente/?estado=true&profesional_asignado=${profesional}&ordering=-fecha_asignacion`
        : `${urlPage}&estado=true&profesional_asignado=${profesional}&ordering=-fecha_asignacion`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const postAsignaciones = async (expedientes,token) => {
  try {
      let arregloExpedientes =[];

      expedientes.forEach(element => {
          arregloExpedientes.push(element.id);
      });

      let body ={
        aceptado: true, 
        expedientes:arregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/aceptarasignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};