
export const types = {
    save: 'Save',
    login:'Login',
    checkStatus:'CheckStatus',
    savePersona: 'SavePersona',
    cleanPersona:'CleanPersona',
    ChangeSearch:'ChangeSearch',
    ChangeSede:'ChangeSede',
    saveOrientacion:'SaveOrientacion',
    ChangeOrientacionTab:'ChangeOrientacionTab',
    ChangeCasoTab:'ChangeCasoTab',
    TipoFiltro:'TipoFiltro',
    Favoritos:'Favoritos',
    Permisos:'Permisos'
}
