import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getSubAreaSustantivaTipoPuesto } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchSubArea = (token) => {
        const [state, setState] = useState({
            sub_area: [],
        });

        useEffect(() => {
            getSubAreaSustantivaTipoPuesto(token).then((sub_area) => {
                setState({
                    sub_area: sub_area,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        estado: "",
        sub_area: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }
    const { sub_area } = useFetchSubArea(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Subárea" />
                    <div className="mt-1">
                        <select
                            id="sub_area"
                            name="sub_area"
                            value={state.sub_area}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir subárea</option>
                            {sub_area?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `sub_area=${state.sub_area}&estado=${state.estado}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
