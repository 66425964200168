import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getMotivoCriterioCierre,
  postMotivoCierre,
  putMotivoCierre,
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Motivo de Cierre es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string()
    .required("Descripción es requerida")
    .max(255, "Máximo 100 caracteres"),
  motivo_criterios_cierre: Yup.string()
    .required("Motivo Criterio Cierre es requerido")
    .max(255, "Máximo 100 caracteres"),
});

const useFetchMotivoCriterioCierre = (token) => {
  const [state, setState] = useState({
    motivoscriterioscierres: [],
  });

  useEffect(() => {
    getMotivoCriterioCierre(token).then((motivoscriterioscierres) => {
      setState({
        motivoscriterioscierres: motivoscriterioscierres,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { motivoscriterioscierres } = useFetchMotivoCriterioCierre(token);

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          require_autorizacion: false,
          motivo_criterios_cierre: props.editar ? props.value.motivo_criterios_cierre : "",
          estado: props.editar ? props.value.estado : true,
          area: null
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putMotivoCierre(props.value.id, values, token).then(
              (data) => {
                if (data?.message !== undefined) {
                  toast.error(data.message);
                } else {
                  props.setReFetch(true);
                  toast.success("Registro actualizado con éxito...!");
                }
              }
            );
          } else {
            await postMotivoCierre(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message);
              } else {
                props.setReFetch(true);
                toast.success("Registro creado con éxito...!");
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre*"
                />
              </div>
              <div className="col-span-6 mt-5">
                <Label text="" />
                <InputSiavi
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción*"
                />
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Motivo Criterio Cierre*" />
                <div className="mt-1">
                  <select
                    id="motivo_criterios_cierre"
                    name="motivo_criterios_cierre"
                    autoComplete="off"
                    value={values.motivo_criterios_cierre}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="" disabled>
                      Elegir motivo criterio cierre
                    </option>
                    {motivoscriterioscierres?.results?.map((item) => {
                      return (
                        <option key={item.descripcion} value={item.id}>
                          {item.descripcion}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="motivo_criterios_cierre"
                    component="span"
                    className="text-sm text-maivi-secondaryRed"
                  />
                </div>
              </div>
            {/* <div className="col-span-6 mt-5">
                <Label text="¿Requiere Autorización?" />
                <Toggle
                  enabled={values.require_autorizacion}
                  name="require_autorizacion"
                /> 
              </div> */}
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
