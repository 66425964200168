
const {
    REACT_APP_API_URL
} = process.env;

export const getMotivoCriterioCierre = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/motivocriterioscierre/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMotivoCriterioCierre = async ({ descripcion, servicio, por_caso, estado, usuario_creacion }, token) => {
    try {
        let body = {
            descripcion,
            servicio,
            por_caso,
            estado,
            usuario_creacion
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/motivocriterioscierre/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putMotivoCriterioCierre = async (id, { descripcion, servicio, por_caso, estado, usuario_creacion }, token) => {
    try {
        let body = {
            descripcion,
            servicio,
            por_caso,
            estado,
            usuario_creacion
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/motivocriterioscierre/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteMotivoCriterioCierre = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/motivocriterioscierre/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivoCierre = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/motivocierre/?page_size=1000&motivo_criterios_cierre=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCriterioCierre = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/criterioscierre/?page_size=1000&motivo_cierre=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivoCierreCriterioCierre = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/criterioscierre/?motivo_cierre=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCriterioCierre = async ({ nombre, motivo_cierre, estado }, token) => {
    try {
        let body = {
            nombre,
            motivo_cierre,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/criterioscierre/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCriterioCierre = async (id, { nombre, motivo_cierre, estado }, token) => {
    try {
        let body = {
            nombre,
            motivo_cierre,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/criterioscierre/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCriterioCierre = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/criterioscierre/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};