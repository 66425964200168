import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { FormDetalle } from "../estadoProceso/Detalle";
import { GestionesResultadoAudiencia } from "./gestionResultadoAudiencia";
import { GestionesMedidaDesjudicializadora } from "./gestionMedidaDesju";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { RefreshIcon, ScaleIcon, XCircleIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import {
    deleteEstadoProceso,
    getEstadoProcesoEtapa,
    postEstadoProceso,
    putEstadoProceso,
    getResultadoAudiencia,
    getMedidaDesjudicializadora
} from "../estadoProceso/services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import ModalAmplio from "../../../components/common/ModalAmplio";
import * as Yup from "yup";

const tableColumns = [
    { title: "Proceso", value: "nombre", tipo: "text" },
    {
        title: "Proceso Secundario",
        value: "habilita_proceso_secundario",
        tipo: "boolean",
        text: { true: "Sí", false: "No" }
    },
    {
        title: "Habilita Rep. Digna",
        value: "habilita_medida_desjudicializadora",
        tipo: "boolean",
        text: { true: "Sí", false: "No" }
    },
    {
        title: "Habilita Sentencia",
        value: "habilita_sentencia",
        tipo: "boolean",
        text: { true: "Sí", false: "No" }
    },
    {
        title: "Es audiencia",
        value: "es_audiencia",
        tipo: "boolean",
        text: { true: "Sí", false: "No" }
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
        ordenar: "estado"
    },
];

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Estado Proceso es requerido")
        .max(100, "Máximo 100 caracteres")
});

export const GestionesEstadoProceso = (props) => {
    const [verDetalle, setDetalle] = useState(false);
    const [estadoProcesoDetalle, setEstadoProcesoDetalle] = useState({});
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idEstadoProceso, setIdEstadoProceso] = useState("");
    const [estadoProceso, setEstadoProceso] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idEtapaProcesal = props?.etapa?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [agregarAudiencia, setAgregarAudiencia] = useState(false);
    const [agregarMedida, setAgregarMedida] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "estadoproceso";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const idResultadoAudiencia = (item) => {
        getResultadoAudiencia(item.id, token).then((data) => {
            //setResultadoAudiencia(data);
        });
    };

    const handleAgregar = (item) => {
        idResultadoAudiencia(item);
        setIdEstadoProceso(item.id);
        setEstadoProceso(item);
        setAgregarAudiencia(true);
    };

    const idMedidaDesju = (item) => {
        getMedidaDesjudicializadora(item.id, token).then((data) => {
            //setMedidaDes(data);
        });
    };

    const handleSegundoAgregar = (item) => {
        idMedidaDesju(item);
        setIdEstadoProceso(item.id);
        setEstadoProceso(item);
        setAgregarMedida(true);
    };

    const handleVer = (item) => {
        setEstadoProcesoDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdEstadoProceso(id);
        setEliminar(true);
    };

    const handleEditar = (gestionEstadoProceso) => {
        setEdit(true);
        setEstadoProceso(gestionEstadoProceso);
    };

    const fetchEstadoProceso = () => {
        getEstadoProcesoEtapa(idEtapaProcesal,urlFetch, token).then((estadoProceso) => {
            setDataTable(estadoProceso);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchEstadoProceso, [token, idEtapaProcesal, reFetch,urlFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    etapa_proceso: props.etapa.id,
                    nombre: isEdit ? estadoProceso?.nombre : "",
                    descripcion: isEdit ? estadoProceso?.descripcion : "",
                    estado: isEdit ? estadoProceso?.estado : true,
                    habilita_proceso_secundario: isEdit ? estadoProceso?.habilita_proceso_secundario : false,
                    habilita_medida_desjudicializadora: isEdit ? estadoProceso?.habilita_medida_desjudicializadora : false,
                    habilita_sentencia: isEdit ? estadoProceso?.habilita_sentencia : false,
                    habilita_motivo: isEdit ? estadoProceso?.habilita_motivo : false,
                    habilita_interponente: isEdit ? estadoProceso?.habilita_interponente : false,
                    es_audiencia: isEdit ? estadoProceso?.es_audiencia : false
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putEstadoProceso(estadoProceso.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.nombre = "";
                                    values.descripcion = "";
                                    values.estado = true;
                                    values.habilita_proceso_secundario = false;
                                    values.habilita_medida_desjudicializadora = false;
                                    values.habilita_sentencia = false;
                                    values.es_audiencia = false;
                                    values.habilita_motivo = false;
                                    values.habilita_interponente = false;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postEstadoProceso(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-6 gap-3 border p-3 mb-2 rounded-lg hover:shadow-md">
                            <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        value={values.nombre}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="*Nombre"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="descripcion"
                                        id="descripcion"
                                        value={values.descripcion}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Descripción"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Habilita Proceso Secundario" />
                                <Toggle enabled={values.habilita_proceso_secundario} name="habilita_proceso_secundario" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Habilita Reparación Digna" />
                                <Toggle enabled={values.habilita_medida_desjudicializadora} name="habilita_medida_desjudicializadora" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Habilita Sentencia" />
                                <Toggle enabled={values.habilita_sentencia} name="habilita_sentencia" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Es Audiencia" />
                                <Toggle enabled={values.es_audiencia} name="es_audiencia" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Habilita Motivo" />
                                <Toggle enabled={values.habilita_motivo} name="habilita_motivo" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text="Habilita Interponente" />
                                <Toggle enabled={values.habilita_interponente} name="habilita_interponente" />
                            </div>
                            <div className="col-span-12 xl:col-span-2 lg:col-span-6 sm:col-span-6 md:col-span-6 flex flex-col items-center justify-center ">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 lg:col-span-4 sm:col-span-6 md:col-span-4 ">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 overflow-x-auto mt-3">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                    agregar={true}
                    handleAgregar={handleAgregar}
                    //agregarSegundo={true}
                    handleSegundoAgregar={handleSegundoAgregar}
                    agregar1="resultado audiencia"
                    //agregar2="medida desjudicializadora"
                    pagination={true}
                    fetchPage={setUrlFech}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de estado proceso"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <RefreshIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalle detalle={estadoProcesoDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregarAudiencia && (
                    <ModalAmplio
                        title={`Gestión de Resultado Audiencia de ${estadoProceso.nombre}`}
                        open={agregarAudiencia}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarAudiencia(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionesResultadoAudiencia estadoProceso={estadoProceso} />
                        }
                    />
                )}
                {agregarMedida && (
                    <ModalAmplio
                        title={`Gestión de Medida Desjudicializadora de ${estadoProceso.nombre}`}
                        open={agregarMedida}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarMedida(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionesMedidaDesjudicializadora estadoProceso={estadoProceso} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteEstadoProceso(idEstadoProceso, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
