import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getTipoSedes, getDeptosSedes, getMunicipiosSedes } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchTipoSede = (token) => {
        const [state, setState] = useState({
            tiposedes: [],
        });

        useEffect(() => {
            getTipoSedes(token).then((tiposedes) => {
                setState({
                    tiposedes: tiposedes,
                });
            });
        }, [token]);

        return state;
    };

    const useFetchDepto = (token) => {
        const [state, setState] = useState({
            departamentos: [],
        });

        useEffect(() => {
            getDeptosSedes(token).then((departamentos) => {
                setState({
                    departamentos: departamentos,
                });
            });
        }, [token]);

        return state;
    };

    const useFetchMuni = (token) => {
        const [state, setState] = useState({
            municipios: [],
        });

        useEffect(() => {
            getMunicipiosSedes(token).then((municipios) => {
                setState({
                    municipios: municipios,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        estado: "",
        tipo_sede: "",
        departamento: "",
        municipio: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const { tiposedes } = useFetchTipoSede(token);
    const { departamentos } = useFetchDepto(token);
    const { municipios } = useFetchMuni(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Tipo Sede" />
                    <div className="mt-1">
                        <select
                            id="tipo_sede"
                            name="tipo_sede"
                            value={state.tipo_sede}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir tipo sede</option>
                            {tiposedes?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Departamento" />
                    <div className="mt-1">
                        <select
                            id="departamento"
                            name="departamento"
                            value={state.departamento}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir departamento</option>
                            {departamentos?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Municipio" />
                    <div className="mt-1">
                        <select
                            id="municipio"
                            name="municipio"
                            value={state.municipio}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir municipio</option>
                            {municipios?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `tipo_sede=${state.tipo_sede}&departamento=${state.departamento}&municipio=${state.municipio}&estado=${state.estado}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
