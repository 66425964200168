/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getCasos, casos, exportarData } from "./services";
import { useSelector } from "react-redux";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { FormDetalle } from "./detalle";
import { FilterIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/solid";
import CustomTable from "../../../../components/common/Table";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import ModalGenerico from "../../../../components/common/Modal";
import ModalBig from "../../../../components/common/ModalTwo";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import ExportButton from "../../../../components/common/ExportButton";
import { columsCasosList, columsExpedientesList } from "./columnas";

const tableColumns = [
  { title: "Fecha", value: "fecha_creacion", tipo: "date", ordenar: "fecha" },
  {
    title: "Sede",
    value: "sede_creacion.sede",
    tipo: "text",
    ordenar: "sede"
  },
  { title: "No. Expediente", value: "numero_caso", tipo: "text" },
  { title: "Víctima", value: null, values: ["victima.primer_nombre", "victima.segundo_nombre", "victima.primer_apellido", "victima.segundo_apellido"], tipo: "text" },
  { title: "Denuncia", value: "no_denuncia", tipo: "text" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo", null: "" }
  },
  {
    title: "Expediente cerrado",
    value: "caso_cerrado",
    tipo: "boolean",
    text: { true: "Si", false: "No", null: "" }
  },
];

export const TableCasos = () => {
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [verDetalle, setDetalle] = useState(false)
  const [tableLoading, setTableLoading] = useState(true);
  const [caso, setCaso] = useState({});
  const [openFilters, setOpenFilters] = useState(false);
  const [order, setOrder] = useState("-fecha");
  const [filters, setFilters] = useState("");
  const [id] = useState("");
  const permiso = "caso";
  const linkEditar = `/funcionalidades-generales/editar-caso/${id}`;

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(tipo, filters, token).then(async (data) => {
      const fileName = 'expedientes';
      let dataExport = data;
      let columns = [];

      if (tipo === 'caso-list') {
        columns = columsCasosList;
      }
      if (tipo === 'expedientereportelist') {
        columns = columsExpedientesList;
      }

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo: 'xls' });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  const fetchCasos = () => {
    setTableLoading(true);
    casos(urlFetch, order, filters, search, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetCasos = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);

      setTableLoading(true);
      getCasos(urlFetch, filters, search, order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  const handleVer = (item) => {
    setCaso(item);
    setDetalle(true);
  };

  useEffect(fetchCasos, [urlFetch, order, token, filters]);
  useEffect(fetchGetCasos, [urlFetch, token, order, search]);

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Expedientes", href: "#", current: true },
  ];

  const permisoView = "Expedientes";

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.text === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
//retorno=true 
    return retorno;
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div className="justify-end">
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => { setFilters("") }}
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
          />
          {(CheckPermiso('ExportarExpedientes') === true) &&
            <ExportButton
              handleOpciones={exportData}
              opciones={[
                { value: 'expedientereportelist', text: 'Resumen de Expedientes' },
                { value: 'caso-list', text: 'Listado de Expedientes' }
              ]}
            />
          }
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            //showEliminar={true}
            showEliminar={(CheckPermiso('EliminarExpediente') === true) }
            //showEditar={true}
            showEditar={(CheckPermiso('EditarExpediente') === true)}
            //showVer={true}
            linkTo={linkEditar}
            link={true}
            order={order}
            setOrder={setOrder}
            handleVer={handleVer}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
          {verDetalle && (
            <ModalBig
              title={"Detalle"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              children={
                <FormDetalle
                  caso={caso}
                />
              }
            />
          )}
          {openFilters && (
            <ModalGenerico
              title={`Aplicar filtros`}
              open={openFilters}
              closeModal={() => { }}
              cerrar={() => {
                setOpenFilters(false);
              }}
              children={
                <Filtros
                  setFilters={setFilters}
                  setOpenFilters={setOpenFilters}
                  setUrlFech={setUrlFech}
                />
              }
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <FilterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
