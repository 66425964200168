import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import {
    deleteEspacioSeguroInsumo,
    getInsumosEspacioSeguro,
    postEspacioSeguroInsumo,
    putEspacioSeguroInsumo,
    getInsumos
} from "./services";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import { DocumentTextIcon, XCircleIcon } from "@heroicons/react/outline";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import { FormDetalle } from "./detalleEspacioSeguroInsumo";
import { SelectSiavi } from "../../../components/common/Select";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Cantidad", value: "cantidad", tipo: "text" },
    { title: "Insumo", value: "alimenta.nombre", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

export const GestionesInsumos = (props) => {
    console.log(props)
    const [verDetalle, setDetalle] = useState(false);
    const [espacioSeguroInsumosDetalle, setEspacioSeguroInsumosDetalle] = useState({});
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idEspacioSeguroInsumos, setIdEspacioSeguroInsumos] = useState("");
    const [espacioSeguroInsumos, setEspacioSeguroInsumos] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const profesional = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const idEspacioSeguro = props?.registro?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "visitas";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso == permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });
        // retorno = true;  // mm comentar para que funcione
        return retorno;

    }



    const permiso = "espacioseguroinsumos";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const useFetchInsumos = (token) => {
        const [state, setState] = useState({
            insumos: [],
        });

        useEffect(() => {
            getInsumos(token).then((insumos) => {
                setState({
                    insumos: insumos,
                });
            });
        }, [token]);

        return state;
    };

    const handleVer = (item) => {
        setEspacioSeguroInsumosDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdEspacioSeguroInsumos(id);
        setEliminar(true);
    };

    const handleEditar = (gestionEspacioSeguroInsumos) => {
        setEdit(true);
        setEspacioSeguroInsumos(gestionEspacioSeguroInsumos);
    };

    const fetchEspacioSeguroInsumos = () => {
        getInsumosEspacioSeguro(idEspacioSeguro, token).then((espacios) => {
            setDataTable(espacios);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchEspacioSeguroInsumos, [token, idEspacioSeguro, reFetch]);

    const { insumos } = useFetchInsumos(token);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    espacio_seguro: idEspacioSeguro,
                    cantidad: isEdit ? espacioSeguroInsumos?.cantidad : "",
                    estado: isEdit ? espacioSeguroInsumos?.estado : true,
                    alimenta: isEdit ? espacioSeguroInsumos?.alimenta?.id : "",
                    profesional_crea: profesional
                }}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putEspacioSeguroInsumo(espacioSeguroInsumos.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.cantidad = "";
                                    values.alimenta = "";
                                    values.estado = false;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postEspacioSeguroInsumo(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-full mx-auto grid grid-cols-12 gap-6 items-end">
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="cantidad"
                                        id="cantidad"
                                        value={values.cantidad}
                                        onChange={handleChange}
                                        label="Cantidad"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2 text-center">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <SelectSiavi
                                        name="alimenta"
                                        label="Insumo"
                                        value={values.alimenta}
                                        onChange={handleChange}
                                        initValue=""
                                        selectInit="Elegir insumo"
                                        list={
                                            insumos?.results?.map((item, index) => {
                                                return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                    {(CheckPermiso('agregarinsumosespacioseguro') === true) &&    //mm permiso agregado      
                                        <ButtonSiavi
                                            type="submit"
                                            disabled={isSubmitting}
                                            text={isEdit ? "Actualizar" : "Guardar"}
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-5">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    //showEliminar={true}
                    showEliminar={(CheckPermiso('eliminarinsumosespacioseguro') === true)}   
                    showVer={true}
                    //showEditar={true}
                    showEditar={(CheckPermiso('actualizarinsumosespacioseguro') === true)}   
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de espacio seguro insumos"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <DocumentTextIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalle detalle={espacioSeguroInsumosDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteEspacioSeguroInsumo(idEspacioSeguroInsumos, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};