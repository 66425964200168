
const {
    REACT_APP_API_URL
} = process.env;

export const getDocumento = async (urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/clasedocumento/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postDocumento = async ({ nombre, descripcion,descripcion_recurso, requerido, recurso, extension_permitida, multiples_archivos, estado }, token) => {
    try {
        let extensiones ="";
        extension_permitida.forEach(element => {
            extensiones += `${element.value},`
        });

        let body = {
            nombre,
            descripcion,
            descripcion_recurso,
            requerido,
            recurso,
            extension_permitida: extensiones,
            multiples_archivos,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/clasedocumento/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDocumento = async (id, { nombre, descripcion,descripcion_recurso, requerido, recurso, extension_permitida, multiples_archivos, estado }, token) => {
    try {
        let extensiones ="";
        extension_permitida.forEach(element => {
            extensiones += `${element.value},`
        });

        let body = {
            nombre,
            descripcion,
            descripcion_recurso,
            requerido,
            recurso,
            extension_permitida:extensiones,
            multiples_archivos,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/clasedocumento/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDocumento = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/clasedocumento/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};