/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from "@headlessui/react";
import { getExpedientePersona, getAccionesPersona } from "../../../../pages/services";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCasosPersonas } from "./services";
import Spinner from "../../../../components/common/Spinner";
import CustomTable from "../../../../components/common/Table";
import TableGeneric from "../../../../components/common/TableGeneric";
import moment from "moment";

const tableColumns = [
  { title: "Tipo", value: "accion_seguimiento.nombre", tipo: "text" },
  { title: "Descripcion", value: "accion_seguimiento.descripcion", tipo: "text" },
  { title: "Fecha creacion", value: "fecha_creacion", tipo: "date" },
  { title: "Profesional asignado", value: null, values: ["profesional_asignado.first_name", "profesional_asignado.last_name"], tipo: "text" },
];

const tableColumnsExpedientes = [
  { title: "Expediente", value: "numero_expediente", tipo: "text" },
  { title: "Servicio", value: "servicio.nombre", tipo: "text" },
  {
    title: "Estado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
  },
  { title: "Fecha creacion", value: "fecha_creacion", tipo: "date" },
  { title: "Profesional asignado", value: null, values: ["profesional_asignado.first_name", "profesional_asignado.last_name"], tipo: "text" },
];

const tableColumnsCasosPersonas = [
  { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  {
    title: "Querellante",
    value: "querellante",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
  {
    title: "Rep. Legal",
    value: "representante_legal",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
  {
    title: "Víctima",
    value: "victima",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
  {
    title: "Víctima indirecta",
    value: "es_victima_indirecta",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const FormDetalle = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [expedientes, setExpedientes] = useState({});
  const [acciones, setAcciones] = useState({});
  const [urlFetch, setUrlFech] = useState(undefined);
  const [urlFetchCasosPersona, setUrlFechCasosPersona] = useState(undefined);
  const [urlFetchExpedientes, setUrlFechExpedientes] = useState(undefined);
  const [cargando,setCargando] = useState(false);
  const [cargandoAcciones,setCargandoAcciones] = useState(false);
  const [casosPersonas,setCasosPersonas] = useState({})
  const [cargandoCasosPersonas,setCargandoCasosPersonas] = useState(false);

  useEffect(() => {
    setCargando(true);
    getExpedientePersona(props?.Persona?.id,urlFetchExpedientes, token).then((data) => {
      setExpedientes(data);
      setCargando(false);
    });
  }, [token, urlFetchExpedientes]);

  useEffect(() => {
    setCargandoAcciones(true);
    getAccionesPersona(props?.Persona?.id, urlFetch, token).then((data) => {
      setAcciones(data);
      setCargandoAcciones(false);
    });
  }, [urlFetch, token]);

  useEffect(() => {
    setCargandoCasosPersonas(true);
    getCasosPersonas(props?.Persona?.id, urlFetchCasosPersona, token).then((data) => {
      setCasosPersonas(data);
      setCargandoCasosPersonas(false);
    });
  }, [urlFetchCasosPersona, token]);

  const handleVer = (registro) => {
    window.location = `/funcionalidades-generales/editar-caso/${registro.caso}`
  }

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 ">
        <div className="bg-white border rounded-md hover:shadow-lg">
          <div className=" border-gray-200 p-0">
            <div className="sm:divide-y sm:divide-gray-200 ">
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Nombre
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {`${props.detalle.primer_nombre} ${props.detalle.segundo_nombre} ${props.detalle.primer_apellido} ${props.detalle.segundo_apellido}`}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Sexo
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props.detalle.sexo}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">Fecha nacimiento</div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {moment(props.detalle.fecha_nacimiento).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Edad
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props.detalle.edad}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Como le gusta que le llamen
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props.detalle.como_le_gusta_que_le_llamen}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">Tipo documento</div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props?.detalle?.tipo_documento_detalle?.nombre}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  No. documento
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props.detalle.no_documento}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Pais
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props?.detalle?.pais_detalle?.nombre}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Nacionalidad
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props?.detalle?.nacionalidad_detalle?.nombre}
                </div>
              </div>
              <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                <div className="text-xs font-medium text-gray-500">
                  Primera visita
                </div>
                <div className="text-xs text-gray-900 sm:col-span-2">
                  {props.detalle.primera_visita === true ? (
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      Sí
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      No
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 p-3 border rounded-lg hover:shadow-lg">
        <Tab.Group>
          <Tab.List className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 bg-blue-50 rounded-md p-1">
            <Tab
              key="casos"
              disabled={false}
              className={({ selected }) =>
                classNames(
                  "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                  selected
                    ? "bg-white shadow"
                    : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                )
              }
            >
              Expedientes
            </Tab>
            <Tab
              key="acciones"
              disabled={false}
              className={({ selected }) =>
                classNames(
                  "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                  selected
                    ? "bg-white shadow"
                    : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                )
              }
            >
              Acciones
            </Tab>
            <Tab
              key="caso-personas"
              disabled={false}
              className={({ selected }) =>
                classNames(
                  "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                  selected
                    ? "bg-white shadow"
                    : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                )
              }
            >
              Expedientes persona
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-2">
                <TableGeneric
                  dataTable={expedientes}
                  tableColumns={tableColumnsExpedientes}
                  fetchPage={setUrlFechExpedientes}
                  pagination={true}
                  permiso={"expediente"}
                  showEliminar={false}
                  showEditar={false}
                  showVer={true}
                  handleVer={handleVer}
                  hideAcciones={false}
                />
                <div className="mt-2">
                  {
                    cargando &&
                    <Spinner />
                  }
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-2">
                <CustomTable
                  dataTable={acciones}
                  tableColumns={tableColumns}
                  fetchPage={setUrlFech}
                  pagination={true}
                  permiso={"expediente"}
                  showEliminar={false}
                  showEditar={false}
                  showVer={false}
                  hideAcciones={true}
                />
                   <div className="mt-2">
                  {
                    cargandoAcciones &&
                    <Spinner />
                  }
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-2">
                <CustomTable
                  dataTable={casosPersonas}
                  tableColumns={tableColumnsCasosPersonas}
                  fetchPage={setUrlFechCasosPersona}
                  pagination={true}
                  permiso={"expediente"}
                  showEliminar={false}
                  showEditar={false}
                  showVer={false}
                  hideAcciones={true}
                />
                   <div className="mt-2">
                  {
                    cargandoCasosPersonas &&
                    <Spinner />
                  }
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}