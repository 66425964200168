
const {
    REACT_APP_API_URL
} = process.env;

export const postTipoProceso = async ({ tipo_proceso, estado, requiere_sentencia_previa, requiere_reparacion_digna_previa,unico_por_caso }, token) => {
    try {
        let body = {
            tipo_proceso,
            estado,
            requiere_sentencia_previa,
            requiere_reparacion_digna_previa,
            unico_por_caso
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/tipoproceso/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putTipoProceso = async (id, { tipo_proceso, estado, requiere_sentencia_previa, requiere_reparacion_digna_previa,unico_por_caso }, token) => {
    try {
        let body = {
            tipo_proceso,
            estado,
            requiere_sentencia_previa,
            requiere_reparacion_digna_previa,
            unico_por_caso
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/tipoproceso/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};