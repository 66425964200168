export const columReferencias = [
  { name: "Id", value: "id" },
  { name: "Asignado", value: "asignado" },
  { name: "Asistencia atencion especifica", value: "asistencia_atencion_especifica" },
  { name: "Caso", value: "caso" },
  { name: "Codigo referencia", value: "codigo_referencia" },
  { name: "Codigo referencia ts", value: "codigo_referencia_ts" },
  { name: "Codigo solicitud referencia", value: "codigo_solicitud_referencia" },
  { name: "Descripcion referencia", value: "descripcion_referencia" },
  { name: "Es nna", value: "es_nna" },
  { name: "Estado", value: "estado" },
  { name: "Expediente", value: "expediente" },
  { name: "Expediente ts", value: "expediente_ts" },
  { name: "Fecha", value: "fecha", tipo: 'fecha' },
  { name: "Fecha asignacion", value: "fecha_asignacion", tipo: 'fecha' },
  { name: "Fecha creacion", value: "fecha_creacion", tipo: "fecha" },
  { name: "Fecha creacion referencia ts", value: "fecha_creacion_referencia_ts", tipo: 'fecha' },
  { name: "Firma profesional", value: "firma_profesional" },
  { name: "Firma profesional referencia ts", value: "firma_profesional_referencia_ts" },
  { name: "Gestion institucion", value: "gestion_institucion" },
  { name: "Id referencia ayuda", value: "id_referencia_ayuda" },
  { name: "Institucion", value: "institucion" },
  { name: "Institucion refiere", value: "institucion_refiere" },
  { name: "Motivo referencia", value: "motivo_referencia" },
  { name: "Orientacion", value: "orientacion" },
  { name: "Persona", value: "persona" },
  { name: "Profesional asignado", value: "profesional_asignado" },
  { name: "Profesional crea", value: "profesional_crea" },
  { name: "Profesional crea referencia ts", value: "profesional_crea_referencia_ts" },
  { name: "Referencia ayudagt", value: "referencia_ayudagt" },
  { name: "Referencia interna", value: "referencia_interna" },
  { name: "Referencia ts", value: "referencia_ts" },
  { name: "Representante", value: "representante" },
  { name: "Requiere accion", value: "requiere_accion" },
  { name: "Sede", value: "sede" },
  { name: "Seguimiento", value: "seguimiento" },
  { name: "Seguimiento completado", value: "seguimiento_completado" },
  { name: "Solicitud referencia", value: "solicitud_referencia" },
  { name: "Tipo referencia", value: "tipo_referencia" }
];

export const referenciainternalist = [
  { name: 'ID', value: 'id' },
  { name: 'Acepta referencia', value: 'acepta_referencia' },
  { name: 'Atencion', value: 'atencion' },
  { name: 'Codigo referencia', value: 'codigo_referencia' },
  { name: 'Especificaciones', value: 'especificaciones' },
  { name: 'Expediente', value: 'expediente' },
  { name: 'Fecha asignado', value: 'fecha_asignado' },
  { name: 'Fecha creacion', value: 'fecha_creacion' },
  { name: 'Fecha profesional recibe', value: 'fecha_profesional_recibe' },
  { name: 'Firma profesional recibe', value: 'firma_profesional_recibe' },
  { name: 'Persona', value: 'persona' },
  { name: 'Profesional asignado', value: 'profesional_asignado' },
  { name: 'Profesional crea', value: 'profesional_crea' },
  { name: 'Referencia', value: 'referencia' },
  { name: 'Referencia interna', value: 'referencia_interna' },
  { name: 'Requiere accion', value: 'requiere_accion' },
  { name: 'Servicio interno', value: 'servicio_interno' },
  { name: 'Usuario asigna', value: 'usuario_asigna' },
];

export const referenciaexternalist = [
  { name: 'ID', value: 'id' },
  { name: 'Area institucion refiere', value: 'area_institucion_refiere' },
  { name: 'Codigo referencia', value: 'codigo_referencia' },
  { name: 'Condiciones de traslado', value: 'condiciones_de_traslado' },
  { name: 'Especificaciones', value: 'especificaciones' },
  { name: 'Expediente', value: 'expediente' },
  { name: 'Fecha creacion', value: 'fecha_creacion' },
  { name: 'Fecha recepcion', value: 'fecha_recepcion' },
  { name: 'Frecuencia cardiaca', value: 'frecuencia_cardiaca' },
  { name: 'Frecuencia respiratoria', value: 'frecuencia_respiratoria' },
  { name: 'Impresion clinica', value: 'impresion_clinica' },
  { name: 'Nombre persona recibe', value: 'nombre_persona_recibe' },
  { name: 'Persona', value: 'persona' },
  { name: 'Presion arterial', value: 'presion_arterial' },
  { name: 'Profesional asignado', value: 'profesional_asignado' },
  { name: 'Profesional crea', value: 'profesional_crea' },
  { name: 'Referencia', value: 'referencia' },
  { name: 'Referencia interna', value: 'referencia_interna' },
  { name: 'Referencia medica', value: 'referencia_medica' },
  { name: 'Resumen clinico', value: 'resumen_clinico' },
  { name: 'Servicio externo', value: 'servicio_externo' },
  { name: 'Temperatura', value: 'temperatura' },
  { name: 'Tratamiento empleado', value: 'tratamiento_empleado' },
];

export const seguimientoreferenciaexternalist = [
  { name: 'ID', value: 'id' },
  { name: 'Acciones coordinadas seguimiento', value: 'acciones_coordinadas_seguimiento' },
  { name: 'Acciones inmediatas', value: 'acciones_inmediatas' },
  { name: 'Comentarios profesional', value: 'comentarios_profesional' },
  { name: 'Especificaciones modalidad', value: 'especificaciones_modalidad' },
  { name: 'Estado atencion', value: 'estado_atencion' },
  { name: 'Fecha creacion', value: 'fecha_creacion' },
  { name: 'Fecha seguimiento', value: 'fecha_seguimiento' },
  { name: 'Modalidad', value: 'modalidad' },
  { name: 'Objetivo seguimiento', value: 'objetivo_seguimiento' },
  { name: 'Profesional crea', value: 'profesional_crea' },
  { name: 'Profesional externo', value: 'profesional_externo' },
  { name: 'Profesional seguimiento', value: 'profesional_seguimiento' },
  { name: 'Referencia', value: 'referencia' },
  { name: 'Retroalimentacion externa', value: 'retroalimentacion_externa' },
  { name: 'Seguimiento a victima', value: 'seguimiento_a_victima' },
];