import { React, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { getProfesionales,postAsignaciones } from "../services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import * as Yup from "yup";
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
    profesional: Yup.string()
        .required("profesional es requerido")
});

const useFetchProfesionales = (token) => {
    const [state, setState] = useState({
        profesionales: [],
    });

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setState({
                profesionales: data,
            });
        });
    }, [token]);

    return state;
};


export const ReasignarExpediente = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { profesionales } = useFetchProfesionales(token);

    return (
        <div>
            <Formik
                initialValues={{
                    profesional: "",
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    let expedientes =[
                        props.expediente.id
                    ]
                  await postAsignaciones(values.profesional, expedientes, token)
                  .then((data) => {
                    if (data?.status === 200 || data?.status === 201 ) {
                      toast.success('reasignacion realizada con éxito');
                      setTimeout(() => {
                        window.location.reload();
                      }, 1700);
                      
                    } else {
                      toast.success('reasignacion realizadas con éxito');
                    }
                  })
                  .catch(function (error) {
                    if (error.response.status === 400 || error.response.status === 401) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                        toast.error('Error interno comuniquese con el administrador');
                    }
                  });
                }}>
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mx-5">
                            <Label text="Profesional" />
                            <div className="mt-1">
                                <select
                                    name="profesional"
                                    value={values.profesional}
                                    onChange={handleChange}
                                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                                        focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md">
                                    <option value="">Elegir profesional</option>
                                    {
                                        profesionales?.results?.map((item) => {
                                            return <option key={item?.first_name + item.id} value={item?.id}>{`${item?.first_name} ${item?.last_name} - Asignaciones: ${item?.asignaciones}`}</option>
                                        })
                                    }
                                </select>
                                <ErrorMessage
                                    name="profesional"
                                    component="span"
                                    className="text-xs text-maivi-secondaryRed"
                                />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text="Reasignar"
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
