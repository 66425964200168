
import { React } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { getPreguntas, savePreguntas } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import Label from "../../../components/common/Label";
import Spinner from "../../../components/common/Spinner";
import Alert from "../../../components/common/Alert";

const validaciones = Yup.object().shape({
  pregunta1: Yup.string().required("pregunta 1 es requerida"),
  pregunta2: Yup.string().required('pregunta 2 es requerida'),
  respuesta1: Yup.string().required('respuesta 1 es requerida'),
  respuesta2: Yup.string().required('respuesta 2 es requerida'),
});

export const Preguntas = (props) => {
  const [preguntas, setPreguntas] = useState([]);
  const [hasError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showPreguntas, setShowPreguntas] = useState(false);
  const [cargando, setCargando] = useState(false);

  return (
      <Formik
        initialValues={{
          pregunta1: "",
          pregunta2: "",
          respuesta1: "",
          respuesta2: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (values.pregunta1 === values.pregunta2) {
            setError(true);
            setMensaje('debe seleccionar preguntas diferentes');
            setTimeout(() => {
              setError(false);
            }, 4000);
          } else {
            setCargando(true);
            savePreguntas(
              values.pregunta1,
              values.respuesta1,
              props.token
            ).then(() => {
              savePreguntas(
                values.pregunta2,
                values.respuesta2,
                props.token
              ).then((data) => {
                if (data.status >= 200 && data.status <= 250) {
                  setCargando(false);
                  setError(false);
                  setSuccess(true);
                  setMensaje('Preguntas guardadas con éxito')
                  setTimeout(() => {
                    window.location = '/'
                  }, 2800);
                }
              }).catch(function (error) {
                if (error.response.status >= 400 && error.response.status <= 450) {
                  setCargando(false);
                  setError(true);
                  setMensaje(error.response.data.message);
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                } else if (error.response.status >= 500) {
                  setCargando(false);
                  setError(true);
                  setMensaje("Error interno comuniquese con el administrador");
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                }
              });
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {showPreguntas ? (
              <div className="mx-6 py-6">
                <div className="col-span-6">
                  <Label text="Pregunta 1" />
                  <select
                    name="pregunta1"
                    value={values.pregunta1}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="">Elegir pregunta 1</option>
                    {preguntas?.results?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.pregunta}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="pregunta1"
                    component="span"
                    className="text-xs text-maivi-secondaryRed"
                  />
                </div>
                <div className="mt-3">
                  <InputSiavi
                    type="text"
                    name="respuesta1"
                    value={values.respuesta1}
                    onChange={handleChange}
                    validacion={true}
                    label="Respuesta 1"
                  />
                </div>
                <div className="col-span-6 mt-4">
                  <Label text="Pregunta 2" />
                  <select
                    name="pregunta2"
                    value={values.pregunta2}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="">Elegir pregunta 2</option>
                    {preguntas?.results?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.pregunta}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="pregunta2"
                    component="span"
                    className="text-xs text-maivi-secondaryRed"
                  />
                </div>
                <div className="mt-3">
                  <InputSiavi
                    type="text"
                    name="respuesta2"
                    value={values.respuesta2}
                    onChange={handleChange}
                    validacion={true}
                    label="Respuesta 2"
                  />
                </div>
                <div className="py-3 text-right">
                  <ButtonSiavi
                    type="submit"
                    onClick={handleSubmit}
                    text="Guardar"
                  />
                </div>
                {isSuccess && <Alert type="success" mensaje={mensaje} />}
                {hasError && <Alert type="error" mensaje={mensaje} />}
                {cargando && <Spinner />}
              </div>
            ) : (
              <div className="py-3 text-center">
                <BotonesConfirmar 
                  aceptar={()=>{
                    getPreguntas(props.token).then((data) => {
                      setPreguntas(data.data);
                      setShowPreguntas(true);
                    });
                  }}
                  cancelar={()=>{
                    window.location = "/";
                  }}
                />
              </div>
            )}

          </form>
        )}
      </Formik>
  );
};