import React, { useEffect, useState } from "react";
import { InputSiavi } from "../../../../components/common/InputText";
import { getServicio, getAtencion, getIdioma, getIdiomaMaya } from "../services";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../components/common/Select";
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import Toggle from "../../../../components/common/Toggle";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Return from "../../../../components/common/ButtonSalir";

export const Editar = ({
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    customChange,
}) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [servicios, setServicios] = useState([]);
    const [atenciones, setAtenciones] = useState([]);
    const [idiomas, setIdiomas] = useState([]);
    const [idiomasmayas, setIdiomasmayas] = useState([]);
    const [activaIdiomaMaya, setActivaIdiomaMaya] = useState(false);
    const pages = [
        {
            name: "Instrumentos",
            href: "/catalogos/formulario",
            current: false,
        },
        { name: "Actualizar", href: "#", current: true },
    ];

    useEffect(() => {
        getServicio(token).then((data) => {
            setServicios(data)
        });
    }, [token]);

    useEffect(() => {
        getAtencion(token).then((data) => {
            setAtenciones(data)
        });
    }, [token]);

    useEffect(() => {
        getIdioma(token).then((data) => {
            setIdiomas(data)
        });
    }, [token]);

    useEffect(() => {
        getIdiomaMaya(token).then((data) => {
            setIdiomasmayas(data)
        });
    }, [token]);


    return (
        <form autoComplete="off" onChange={customChange(values)}>
            <div className="grid-cols-12 container mx-auto -mt-32 mb-10 justify-center">
                <div className="col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
                    <div className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center
                    lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 pt-5"
                    >
                        <BreadCrumbTwo navegacion={pages} />
                        <div className="flex flex-row">
                            <h1 className="text-lg text-maivi-primaryFont mr-2">
                                Editar
                            </h1>
                            <Return ruta="/catalogos/formulario" />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12  xl:col-span-12 lg:col-span-12 border-2 rounded-md p-5">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <InputSiavi
                                        type="text"
                                        name="nombre"
                                        onChange={handleChange}
                                        value={values.nombre}
                                        validacion={true}
                                        label="*Nombre"
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <InputSiavi
                                        type="text"
                                        name="descripcion"
                                        value={values.descripcion}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="*Descripción"
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Por Servicio" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_servicio} name="por_servicio" />
                                    </div>
                                </div>
                                {values.por_servicio && (
                                    <div className="col-span-12 xl:col-span-3 lg:col-span-3 ">
                                        <SelectSiavi
                                            label="Servicio"
                                            name="servicio"
                                            value={values.servicio}
                                            onChange={handleChange}
                                            valueInit=""
                                            selectInit="Elegir servicio"
                                            list={servicios?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.id + "DP" + index} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                )}
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Por Atención" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_atencion} name="por_atencion" />
                                    </div>
                                </div>

                                {values.por_atencion && (
                                    <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                        <SelectSiavi
                                            label="Atención"
                                            name="atencion"
                                            value={values.atencion}
                                            onChange={handleChange}
                                            valueInit=""
                                            selectInit="Elegir atención"
                                            list={atenciones?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                )}

                                <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                    <SelectSiavi
                                        label="*Idioma"
                                        name="idioma"
                                        value={values.idioma}
                                        valueInit=""
                                        selectInit="Elegir Idioma"
                                        validacion={true}
                                        onChange={(e) => {
                                            setFieldValue("idioma", e.target.value);
                                            if (e.target.selectedOptions[0].innerText === "MAYA") {
                                                setActivaIdiomaMaya(true)
                                            } else {
                                                setActivaIdiomaMaya(false)
                                            }
                                        }}
                                        list={idiomas?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    />
                                </div>

                                {activaIdiomaMaya && (
                                    <div className="col-span-12 xl:col-span-3 lg:col-span-3">
                                        <SelectSiavi
                                            label="Idioma Maya"
                                            name="idioma_maya"
                                            value={values.idioma_maya}
                                            onChange={handleChange}
                                            valueInit=""
                                            selectInit="Elegir idioma maya"
                                            list={idiomasmayas?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                )}

                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Información contacto" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.informacion_contacto} name="informacion_contacto" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Información solicitante" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.informacion_solicitante} name="informacion_solicitante" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center">
                                    <Label text="Directores" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.directores} name="directores" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 flex flex-col justify-center items-center mt-5">
                                    <ButtonSiavi
                                        type="button"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        text="Actualizar"
                                    />
                                </div>
                                {isSubmitting && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
