/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../components/common/Select";
import { GearIcon } from "../../../../../helpers/icons";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { FormDetalleAccion } from "../atencion_victimologica/trabajo_social/detalleAccion";
import { GestionAccionesRecursos } from "../atencion_victimologica/trabajo_social/gestionAccionRecursos";
import {
    deleteAcciones,
    postAcciones,
    putAcciones,
    //getEstadoProceso,
    getDeptosGestion,
    getMunicipiosGestion,
    getModalidades,
    getInstitucionGestion,
    //getProfesionales,
    getReferenciaCreada,
    getCatalogoAccionesSeguimientos,
    //getAccionesRecursos,
    getAccionesNombramientos
} from "../atencion_victimologica/trabajo_social/services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import CustomTable from "../../../../../components/common/Table";
import ModalGenerico from "../../../../../components/common/Modal";
import FormEliminar from "../../../../../components/common/FormEliminar";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Acción", value: "seguimiento", tipo: "text" },
    {
        title: "Profesional",
        value: null,
        values: [
            "profesional_crea.first_name",
            "profesional_crea.last_name"
        ],
        tipo: "text"
    },
    {
        title: "Completada",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

/* const tipos = [
    { value: "ACCION", text: "ACCIÓN" },
    { value: "SEGUIMIENTO", text: "SEGUIMIENTO" }
]; */

const validaciones = Yup.object().shape({
    seguimiento: Yup.string()
        .required("Acción es requerido")
});

export const GestionAcciones = (props) => {
    const caso = props.infoExpediente;
    const servicio = props.infoExpediente.servicio.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
    const [verDetalle, setDetalle] = useState(false);
    const [accionesDetalle, setAccionesDetalle] = useState({});
    const idExpediente = props?.infoExpediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAcciones, setIdAcciones] = useState("");
    const [acciones, setAcciones] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    //const [tipo_audiencias, setTipoAudiencias] = useState([]);
    const [deptos, setDeptos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [catalogoAS, setCatalogoAS] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    //const [profesionalesAsigna, setProfesionalesAsigna] = useState([]);
    //const [profesionalesRealiza, setProfesionalesRealiza] = useState([]);
    //const [profesionalesAutoriza, setProfesionalesAutoriza] = useState([]);
    const [referencia, setReferencia] = useState([]);
    const [setNombreDepartamento] = useState("");
    const [agregar, setAgregar] = useState(false);
    const [preguntaCompletado, setPreguntaCompletado] = useState(false);
    const [accionSolicitada, setAccionSolicitada] = useState(false);
    //const [accionesRecursos, setAccionesRecursos] = useState([]);

    // useEffect(() => {
    //     getEstadoProceso(token).then((data) => {
    //         setTipoAudiencias(data);
    //     });
    // }, [token]);

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data)
        });
    }, [token]);

    useEffect(() => {
        getCatalogoAccionesSeguimientos(token).then((data) => {
            setCatalogoAS(data)
        });
    }, [token]);

    useEffect(() => {
        getInstitucionGestion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    // useEffect(() => {
    //     getProfesionales(token).then((data) => {
    //         setProfesionalesAsigna(data)
    //     });
    // }, [token]);

    // useEffect(() => {
    //     getProfesionales(token).then((data) => {
    //         setProfesionalesRealiza(data)
    //     });
    // }, [token]);

    // useEffect(() => {
    //     getProfesionales(token).then((data) => {
    //         setProfesionalesAutoriza(data)
    //     });
    // }, [token]);

    useEffect(() => {
        getReferenciaCreada(caso, servicio, token).then((data) => {
            setReferencia(data)
        });
    }, [token, caso, servicio]);

    useEffect(() => {
        getAccionesNombramientos(idExpediente, props.nombramiento.id, token).then((acciones) => {
            setDataTable(acciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, reFetch]);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "accionesseguimientoscaso";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const idAccionesRecursos = (item) => {
        // getAccionesRecursos(item.id, token).then((data) => {
        //     setAccionesRecursos(data);
        // });
    };

    const handleAgregar = (item) => {
        if (item.completada === false) {
            idAccionesRecursos(item);
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregar(true);
        } else {
            toast.error("No es posible asignar recurso, porque ya está completado la acción")
        }
    };

    const handleVer = (item) => {
        setAccionesDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdAcciones(id);
        setEliminar(true);
    };

    const handleEditar = (acciones) => {
        if (acciones.completada === false) {
            setEdit(true);
            setAcciones(acciones);
        } else {
            toast.error("No es posible editar, porque ya está completado la acción")
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    seguimiento: isEdit ? acciones?.seguimiento : "",
                    es_nna: isEdit ? acciones?.es_nna : false,
                    tipo_audiencia: isEdit ? acciones?.tipo_audiencia?.id : "",
                    fecha_accion: isEdit ? acciones?.fecha_accion : "",
                    fecha_asigna: isEdit ? acciones?.fecha_asigna : "",
                    requiere_autorizacion: false,
                    fecha_autoriza: isEdit ? acciones?.fecha_autoriza : "",
                    autorizado: isEdit ? acciones?.autorizado : false,
                    fecha_completado: isEdit ? acciones?.fecha_completado : "",
                    estado: isEdit ? acciones?.estado : true,
                    completada: isEdit ? acciones?.completada : false,
                    es_accion: true,
                    es_seguimiento: false,
                    requiere_nombramiento: false,
                    observaciones: isEdit ? acciones?.observaciones : "",
                    detalle_lugar: isEdit ? acciones?.detalle_lugar : "",
                    especificaciones_modalidad: isEdit ? acciones?.especificaciones_modalidad : "",
                    tipo: isEdit ? acciones?.tipo : "",
                    caso: props.infoExpediente.caso,
                    expediente: idExpediente,
                    persona: props.infoExpediente.caso_persona.persona.id,
                    victima: (props.infoExpediente.caso_persona.victima === true) ? props.infoExpediente.caso_persona.persona.id : null,
                    querellante: (props.infoExpediente.caso_persona.querellante === true) ? props.infoExpediente.caso_persona.persona.id : null,
                    modalidad: isEdit ? acciones?.modalidad?.id : "",
                    referencia: referencia?.results?.id,
                    departamento: isEdit ? acciones?.departamento?.id : "",
                    municipio: isEdit ? acciones?.municipio?.id : "",
                    gestion_institucion: isEdit ? acciones?.gestion_institucion?.id : "",
                    accion_seguimiento: isEdit ? acciones?.accion_seguimiento?.id : "",
                    profesional_asignado: isEdit ? acciones?.profesional_asignado?.id : "",
                    profesional_autoriza: isEdit ? acciones?.profesional_autoriza?.id : "",
                    profesional_realiza: isEdit ? acciones?.profesional_realiza?.id : "",
                    nombramiento: props.nombramiento.id,
                    profesional_crea: idUser,
                    sede_accion: sedeId
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putAcciones(acciones.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({});
                                    values.seguimiento = "";
                                    values.tipo_audiencia = "";
                                    values.tipo = "";
                                    values.fecha_accion = "";
                                    values.fecha_asigna = "";
                                    values.fecha_autoriza = "";
                                    values.fecha_completado = "";
                                    values.observaciones = "";
                                    values.especificaciones_modalidad = "";
                                    values.modalidad = "";
                                    values.detalle_lugar = "";
                                    values.accion_seguimiento = "";
                                    values.departamento = "";
                                    values.municipio = "";
                                    values.gestion_institucion = "";
                                    values.profesional_asignado = "";
                                    values.profesional_autoriza = "";
                                    values.profesional_realiza = "";
                                    values.estado = true;
                                    values.es_nna = false;
                                    values.autorizado = false;
                                    values.completada = false;
                                    values.es_accion = true;
                                    values.es_seguimiento = false;
                                    values.institucion = "";
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postAcciones(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro creado con éxito...!');
                                    resetForm({});
                                    values.institucion = "";
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo Seguimiento"
                                    name="accion_seguimiento"
                                    value={values.accion_seguimiento}
                                    onChange={(e) => {
                                        setFieldValue('accion_seguimiento', e.target.value);
                                        catalogoAS?.results.forEach(element => {
                                            if (element.id === Number(e.target.value)) {
                                                if (element.solicita_accion) {
                                                    setAccionSolicitada(true);
                                                } else {
                                                    setAccionSolicitada(false);
                                                }
                                            }
                                        });
                                    }}
                                    valueInit=""
                                    selectInit="Elegir tipo acción/seguimiento"
                                    list={
                                        catalogoAS?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Seguimiento"
                                    validacion={true}
                                    name="seguimiento"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.seguimiento}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_accion"
                                    id="fecha_accion"
                                    value={values.fecha_accion}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Acción"
                                />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_asigna"
                                    id="fecha_asigna"
                                    value={values.fecha_asigna}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Asignación"
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_autoriza"
                                    id="fecha_autoriza"
                                    value={values.fecha_autoriza}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Autorización"
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_completado"
                                    id="fecha_completado"
                                    value={values.fecha_completado}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Fecha Completado"
                                />
                            </div> */}
                            {/*                             <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    onChange={(e) => {
                                        setFieldValue("modalidad", e.target.value);
                                        modalidades.results.forEach(element => {
                                            if (element.id === Number(e.target.value)) {
                                                setIsSeleccion(element?.seleccion_simple);
                                            }
                                        });
                                    }}
                                    list={
                                        tipo_preguntas?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo Audiencia"
                                    name="tipo_audiencia"
                                    value={values.tipo_audiencia}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir tipo audiencia"
                                    list={
                                        tipo_audiencias?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo"
                                    name="tipo"
                                    value={values.tipo}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir tipo"
                                    list={
                                        tipos?.map((item) => {
                                            return (
                                                <option key={item.text} value={item.value}>
                                                    {item.text}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div> */}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    list={
                                        modalidades?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="especificaciones_modalidad"
                                    id="especificaciones_modalidad"
                                    value={values.especificaciones_modalidad}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Especificación Modalidad"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Departamento"
                                    name="departamento"
                                    value={values.departamento}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                        let combo = document.getElementById("departamento");
                                        let selected = combo?.options[combo.selectedIndex].text;
                                        setNombreDepartamento(selected);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Municipio"
                                    name="municipio"
                                    value={values.municipio}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir municipio"
                                    list={
                                        municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                                            return (
                                                <option key={item.nombre + "mn" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Institución"
                                    name="gestion_institucion"
                                    value={values.gestion_institucion}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir institución"
                                    list={
                                        instituciones?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="detalle_lugar"
                                    id="detalle_lugar"
                                    value={values.detalle_lugar}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Detalle Lugar"
                                />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Profesional Asignado"
                                    name="profesional_asignado"
                                    value={values.profesional_asignado}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir profesional"
                                    list={
                                        profesionalesAsigna?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Profesional Autoriza"
                                    name="profesional_autoriza"
                                    value={values.profesional_autoriza}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir profesional"
                                    list={
                                        profesionalesAutoriza?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Profesional Realiza"
                                    name="profesional_realiza"
                                    value={values.profesional_realiza}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir profesional"
                                    list={
                                        profesionalesRealiza?.results?.map((item) => {
                                            return (
                                                <option key={item.first_name} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="Observaciones"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div> */}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Es NNA" />
                                <Toggle enabled={values.es_nna} name="es_nna" />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Autorizar" />
                                <Toggle enabled={values.autorizado} name="autorizado" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Completada" />
                                <Toggle enabled={values.completada} name="completada" />
                            </div> */}
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                    <ButtonSiavi
                                        type="button"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            if (accionSolicitada === true) {
                                                handleSubmit();
                                                //values.institucion = institucionesAgregados.value;
                                            } else {
                                                setPreguntaCompletado(true);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                        {preguntaCompletado && (
                            <ModalGenerico
                                title={`¿Desea marcarlo como completado?`}
                                open={preguntaCompletado}
                                closeModal={() => { }}
                                cerrar={() => { setPreguntaCompletado(false) }}
                                icon={
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                        <GearIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                                    </div>
                                }
                                children={
                                    <FormEliminar
                                        aceptar={async () => {
                                            setPreguntaCompletado(false);
                                            handleSubmit();
                                            //values.institucion = institucionesAgregados.value;
                                            values.fecha_completado = values.fecha_accion;
                                            values.completada = true;
                                            values.profesional_realiza = values.profesional_asignado;
                                        }}
                                        isloading={isloading}
                                        cancelar={() => {
                                            toast.success("No será tomada en cuenta hasta que sea completada");
                                            setPreguntaCompletado(false);
                                            handleSubmit();
                                            //values.institucion = institucionesAgregados.value;
                                        }}
                                    />
                                }
                            />
                        )
                        }
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                    agregar={true}
                    handleAgregar={handleAgregar}
                    pagination={true}
                    agregar1="Acciones Recursos"
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de acciones"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleAccion detalle={accionesDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Acciones Recursos de ${acciones.seguimiento}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionAccionesRecursos accion={acciones} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteAcciones(idAcciones, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
