import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getUsuarios = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/seguridad/registrar/` : urlPage;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPermisos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/permisos/?page_size=5000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedesUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoPuestoUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipopuesto/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRolesVista = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/rolvista/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postProfesionalServicio = async (idUser,idServicio,token) => {
    
    try {
        let body = {
         profesional: idUser,
         servicio: idServicio
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/profesionalservicio/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteProfesionalServicio = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/seguridad/profesionalservicio/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProfesionalUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipoprofesional/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRolesUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/roles/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUser = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postUsuario = async (data,token) => {
    data.groups.forEach(element => {
        let arregloIds=[];
        element.permissions.forEach(element2 => {
            arregloIds.push(element2.id);
        });
        element.permissions = arregloIds;
    });

    try {
        let body = {
            is_superuser: data.superUsuario,
            username: data.username,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            is_staff: data.isStaff,
            is_active: data.activo,
            numero_colegiado: data.numeroColegiado,
            telefono: data.telefono,
            estado: data.estado,
            habilitado_para_turno: data.habilitadoTurno,
            usuario_interno: data.usuarioInterno,
            sede: Number(data.sede),
            tipo_puesto: Number(data.tipoPuesto),
            tipo_profesional: Number(data.tipoProfesion),
            otra_sede : data.otra_sede,
            groups: data.groups,
            atenciones: data.atenciones,
            user_permissions: data.user_permissions,
            servicios: data.servicios,
            codigo_empleado: Number(data.codigo_empleado),
            rol_vista: data.rol_vista,
            enviar_sms: data.enviar_sms
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/registrar/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postProfesionalAtencion = async (idUser,idAtencion,token) => {
    
    try {
        let body = {
         profesional: idUser,
         atencion: idAtencion
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/profesionalatencion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteProfesionalAtencion = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/seguridad/profesionalatencion/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postUsuarioPermiso = async (idUser,idPermiso,token) => {
    
    try {
        let body = {
            usuario: idUser,
            permission: idPermiso
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/usuariopermiso/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteUsuarioPermiso = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/seguridad/usuariopermiso/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postRolesUsuario = async (idUser,idRol,token) => {
    
    try {
        let body = {
            usuario: idUser,
            group: idRol
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/usuariorol/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRolesUsuario = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/seguridad/usuariorol/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSedesUsuario = async (idUser,idSede,token) => {
    
    try {
        let body = {
            usuario: idUser,
            sede: idSede
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/usuariosede/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSedesUsuario = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/seguridad/usuariosede/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putUsuario = async (id,data,token) => {
    try {
        let body = {
            is_superuser: data.superUsuario,
            username: data.username,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            is_staff: data.isStaff,
            is_active: data.activo,
            numero_colegiado: data.numeroColegiado,
            telefono: data.telefono,
            estado: data.estado,
            habilitado_para_turno: data.habilitadoTurno,
            usuario_interno: data.usuarioInterno,
            sede: Number(data.sede),
            tipo_puesto: Number(data.tipoPuesto),
            tipo_profesional: Number(data.tipoProfesion),
            otra_sede : data.otra_sede,
            groups: data.groups,
            user_permissions: data.user_permissions,
            codigo_empleado: Number(data.codigo_empleado),
            rol_vista: data.rol_vista,
            codigo_area : data.codigo_area,
            codigo_confirmacion : data.codigo_confirmacion || null
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/seguridad/registrar/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCodigo = async (id,data,token) => {
    try {
        let body = {
            usuario_interno : data.usuario_interno,
            codigo_confirmacion : data.codigo_confirmacion || null,
            email: data.email,
            username: data.username,
            codigo_area : data.codigo_area,
            telefono : data.telefono,
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/seguridad/registrar/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUsuario = async (usuario,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?search=${usuario}&search_fields==username`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const resetPassword = async (usuario,token) => {
    try {
        let body = {
            loginId: usuario
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/olvide-contrasena/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const reenviarCodigo = async (id,data,token) => {
    try {
        let body = {
            usuario_interno : data.usuario_interno,
            //codigo_confirmacion : data.codigo_confirmacion || null,
            email: data.email,
            username: data.username,
            enviar_sms: true,
            telefono: data.telefono.toString(),
            codigo_area : data.codigo_area
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/seguridad/registrar/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};