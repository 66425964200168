import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postTipoDiligencias, putTipoDiligencias } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Tipo Diligencia es requerida")
        .max(100, "Máximo 100 caracteres"),
});

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    estado: props.editar ? props.value.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putTipoDiligencias(props.value.id, values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro actualizado con éxito...!')
                            }
                        });
                    } else {
                        await postTipoDiligencias(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro creado con éxito...!')
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6">
                                <InputSiavi
                                    autoFocus
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Tipo Diligencia"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
