import { types } from "../types/types";
import { SelectedTabCaso } from "../actions/casoTabs";
export const ChangeCasoTab = (state = { tabs: SelectedTabCaso() }, action) => {
    switch (action.type) {
        case types.ChangeCasoTab:
            return { tabs: action.payload }
        default:
            return state;
    }
}
