import { React, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { postEvaluacionRiesgo } from "../services";
import Spinner from "../../../../../components/common/Spinner";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../../../components/common/Label";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  //nivel_riesgo: Yup.string().required("nivel de riesgo es requerida"),
  especificaciones: Yup.string().max(300, "maximo 300 caracteres"),
});
// const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
// focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
// sm:text-sm  rounded-md bg-white text-maivi-primaryFont`;

export const Evaluacion = (props) => {
  const [nivelRiesgo,setNivelRiesgo] =useState(0);
  const [idNivelriesgo,setIdNivelRiesgo] = useState(false);
  const animatedComponents = makeAnimated();
  const [tiposRiesgosSeleccionandos, setTiposRiesgosSeleccionandos] = useState(
    []
  );
  const options = [];

  props?.tipoRiesgos?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    options.push(reg);
  });

  const changeSelectTipoRiesgos = (e) => {
    let arreglo = [];
    let menores =[];
    setTiposRiesgosSeleccionandos(e);

    e.forEach(element => {
      props?.tipoRiesgos?.results?.forEach(element2 => {
        if(element.value === element2.id){
          arreglo.push(element2);
        }
      });
    });

    arreglo.forEach(element => {
        menores.push(element?.nivel_riesgo?.prioridad)
    });

    setNivelRiesgo(Math.min(...menores));

    arreglo.forEach(element =>{
      if(element?.nivel_riesgo?.prioridad === (Math.min(...menores))){
        setIdNivelRiesgo(element.nivel_riesgo.id);
      }
    })
  };

  const NivelRiesgo = (nivel) => {
    let tituloNivel

    if (nivel === 1 ) {
      tituloNivel = <span className="text-sm px-2.5 py-1 rounded-full font-medium bg-red-100 text-red-800">
        NIVEL DE RIESGO ALTO
      </span>
    } else if (nivel === 2) {
      tituloNivel = <span className="ml-2 text-sm px-2.5 py-1 rounded-full font-medium bg-yellow-100 text-yellow-800">
        NIVEL DE RIESGO MEDIO
      </span>
    } else if (nivel === 3) {
      tituloNivel = <span className="ml-2 text-sm px-2.5 py-1 rounded-full font-medium  bg-green-100 text-green-800">
        NIVEL DE RIESGO BAJO
      </span>
    } 

    return tituloNivel;
  };

  return (
    <div>
      <Formik
        initialValues={{
          especificaciones: "",
          nivel_riesgo: 0,
          tipo_riesgos: [],
          idCaso: props.idCaso,
          idPersona: props.idPersona,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          values.nivel_riesgo = idNivelriesgo;
          await postEvaluacionRiesgo(values, props.token)
            .then((data) => {
              if (data?.status === 200 || data?.status === 201) {
                toast.success("Evaluación agregada con éxito..!");
                props.setRefetch(true);
                props.cerrarModal(true);
              } else {
                props.cerrarModal(true);
                toast.success("Evaluación agregada con éxito..!");
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5 py-3">
            <div>
                <Label text="Tipos de riesgo" />
                <Select
                  placeholder="Seleccionar"
                  components={animatedComponents}
                  isMulti
                  name="tipo_riesgo"
                  className="basic-multi-select mb-2 text-xs text-maivi-primaryFont"
                  classNamePrefix="seleccione"
                  options={options}
                  onChange={changeSelectTipoRiesgos}
                  maxMenuHeight={140}
                />
              </div>
              {/* <div className="col-span-6 mb-4">
                <Label text="Nivel de riesgo" />
                <select
                  name="nivel_riesgo"
                  value={values.nivel_riesgo}
                  onChange={handleChange}
                  className={selectClass}
                >
                  <option value="">Nivel de riesgo</option>
                  {props.nivelesRiesgos?.results?.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  name="nivel_riesgo"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div> */}
              <div className="col-span-6 mt-2">
                <Label text="Comentarios del profesional" />
                <TextAreaSiavi
                  row={2}
                  cols={40}
                  name="especificaciones"
                  value={values.especificaciones}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="especificaciones"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
              {
                NivelRiesgo(nivelRiesgo)
              }
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text="Guardar"
                  onClick={() => {
                    values.tipo_riesgos = tiposRiesgosSeleccionandos;
                    handleSubmit();
                  }}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
