import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getRoles = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/seguridad/roles/?page_size=10` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPermisos = async (filters, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/permisos/?page_size=5000&${filters}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRol = async (id,token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/roles/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const postRol = async (name, permissions,token) => {
    try {
        let body = {
            name: name,
            permissions:permissions
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/roles/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putRol = async (name, permissions,id,token) => {
    let permisos = [];
    permissions.forEach(element => {
        permisos.push(element.id)
    });

    try {
        let body = {
            name: name,
            permissions:permisos
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/roles/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRol = async (id,token) => {
    try {
    
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/roles/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};
