/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { SelectSiavi } from "../../../components/common/Select";
import { getProfesionales, getTablas } from "./services";
import { useSelector } from "react-redux";
import Select from "react-select";
import Label from "../../../components/common/Label";
import Spinner from "../../../components/common/Spinner";
import makeAnimated from "react-select/animated";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [profesionales, setProfesionales] = useState([]);
    const [tablas,setTablas] = useState([]);
    const animatedComponents = makeAnimated();
    const optionsProfesionales = [];
    const optionsTablas = [];

    const [state, setState] = useState({
        fecha_inicio: "",
        fecha_fin: "",
        tabla: "",
        modulo: "",
        transaccion: "",
        profesional: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setProfesionales(data);
        })
    }, []);

    useEffect(() => {
        getTablas(token).then((data) => {
            setTablas(data);
        })
    }, []);

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.username,
            label: `${item.first_name} ${item.last_name}`,
        };
        optionsProfesionales.push(reg);
    });

    tablas?.tables?.forEach((item) => {
        let reg = {
            value: item.table_name,
            label: item.name,
        };
        optionsTablas.push(reg);
    });

    const changeSelectProfesional = (e) => {
        setState({
            ...state,
            profesional: e.value,
        });
    };

    const changeSelectTablas = (e) => {
        setState({
            ...state,
            tabla: e.value,
        });
    };

    return (
        <div className="mx-5">
          
            <SelectSiavi
                label="Modulo"
                name="modulo"
                value={state.modulo}
                onChange={onChange}
                valueInit=""
                className="mb-3"
                selectInit="Seleccionar modulo"
                list={[
                    <option value="seguridad">Seguridad</option>,
                    <option value="catalogos">Catalogos</option>,
                    <option value="registro">Registro</option>,
                    <option value="legal">Legal</option>,
                    <option value="evaluacion_victima">Evaluacion victima</option>,
                ]}
            />
            <div className="col-span-12 mt-1 mb-3">
                <Label text="Tabla" />
                <Select
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    name="tabla"
                    className={`basic-multi-select text-xs text-maivi-primaryBlue`}
                    classNamePrefix="seleccione"
                    options={optionsTablas}
                    onChange={changeSelectTablas}
                />
            </div>
            <div className="col-span-12 mt-1 mb-3">
                <Label text="Profesional" />
                <Select
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    name="profesional"
                    className={`basic-multi-select text-xs text-maivi-primaryBlue`}
                    classNamePrefix="seleccione"
                    options={optionsProfesionales}
                    onChange={changeSelectProfesional}
                />
            </div>
            <SelectSiavi
                label="Transaccion"
                name="transaccion"
                value={state.transaccion}
                onChange={onChange}
                valueInit=""
                selectInit="Seleccionar transaccion"
                className="mb-3"
                list={[
                    <option value="Crear">Crear</option>,
                    <option value="Actualizar">Actualizar</option>,
                    <option value="Eliminar">Eliminar</option>,
                ]}
            />
            <InputSiavi
                type="date"
                name="fecha_inicio"
                value={state.fecha_inicio}
                onChange={onChange}
                label="Fecha inicio"
                className="mb-3"
            />
            <InputSiavi
                type="date"
                name="fecha_fin"
                value={state.fecha_fin}
                onChange={onChange}
                label="Fecha fin"
                className="mb-3"
            />
            <ButtonSiavi
                text="Buscar"
                className="mb-6"
                onClick={() => {
                    props.setFechaInicio(state.fecha_inicio);
                    props.setFechaFin(state.fecha_fin);
                    props.setTabla(state.tabla);
                    props.setModulo(state.modulo);
                    props.setTransaccion(state.transaccion);
                    props.setProfesional(state.profesional);
                    props.filtrar(false);
                }}
            />
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
