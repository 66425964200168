/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { clases } from "../../../../../helpers/clases";
import {
    postBandejaUsuario,
    postMensaje,
    getMensajes
} from "./services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Select from "react-select";
import toast from 'react-hot-toast';
import TableSiavi from "../../../../../components/common/TableSiavi";
import moment from "moment";

export const EnviarMensajes = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.id);
    const [profesionalesSeleccionados, setProfesionalesSeleccionado] = useState([]);
    const [mensajes,setMensajes] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [cargandoMensajes,setCargandoMensajes] = useState(false);
    const optionsProfesionales = [];

    useEffect(() => {
        setCargandoMensajes(true);
        getMensajes(props.caso.id,token).then((data) => {
            setMensajes(data?.results);
            setCargandoMensajes(false);
        });
    }, []);

    props.caso?.expedientes?.forEach((item,index) => {
        let existe = false;

        let reg = {
            value: item.profesional_asignado?.id,
            label: `${item?.profesional_asignado?.first_name} ${item?.profesional_asignado?.last_name} -  ${item?.profesional_asignado?.tipo_puesto?.puesto}`,
        };

        if(index === 0){
            optionsProfesionales.push(reg);
        }

        optionsProfesionales.forEach(element => {
            if(element.value === item?.profesional_asignado?.id){
               existe = true;
            }
        });

        if(!existe && item?.profesional_asignado !== null){
            optionsProfesionales.push(reg);
        }
        
        existe = false;
    });

    const changeSelectProfesionales = (e) => {
        setProfesionalesSeleccionado(e);
    };

    const [state, setState] = useState({
        mensaje: "",
    });

    function handleInputChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="grid grid-cols-12 gap-3 p-3 ">
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-5 border p-3 rounded-lg hover:shadow-md">
                <div className="grid grid-cols-12 gap-3 ">
                    <div className="col-span-12">
                        <Label text="Profesionales destino" />
                        <Select
                            placeholder="Seleccionar"
                            isMulti={true}
                            isSearchable={true}
                            name="institucion"
                            className="basic-multi-select text-xs text-maivi-primaryFont"
                            options={optionsProfesionales}
                            onChange={changeSelectProfesionales}
                            maxMenuHeight={150}
                        />
                    </div>
                    <div className="col-span-12">
                        <TextAreaSiavi
                            label="Mensaje"
                            name="mensaje"
                            onChange={handleInputChange}
                            rows={8}
                            cols={2}
                        />
                    </div>
                    <div className="col-span-12">
                        <ButtonSiavi
                            type="button"
                            text="Enviar mensaje"
                            onClick={async () => {
                                if (profesionalesSeleccionados.length === 0) {
                                    toast.error("debe seleccionar al menos un profesional");
                                } else if (state.mensaje === "") {
                                    toast.error("Mensaje es requerido");
                                } else {
                                    setCargando(true);
                                    await postBandejaUsuario(props.caso.id, state.mensaje, usuario, token).then(async (data) => {
                                        if (data?.status >= 200 && data?.status <= 210) {
                                            await profesionalesSeleccionados.forEach(element => {
                                                postMensaje(data.data.id, element.value, token).then(() => {
                                                });
                                            });

                                            setCargando(false);
                                            toast.success("Mensaje enviado con éxito..");
                                            setProfesionalesSeleccionado([]);
                                            setTimeout(() => {
                                                props.setMensajes(false)
                                            }, 1500);
                                        }
                                    }).catch(function (error) {
                                        if (
                                            error.response.status >= 400 &&
                                            error.response.status <= 450
                                        ) {
                                            toast.error(error.response.data.message);
                                            setCargando(false);
                                        } else if (error.response.status >= 500) {
                                            toast.error("Error interno comuniquese con el administrador");
                                            setCargando(false);
                                        }
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-7 border p-3 rounded-lg hover:shadow-md " >
                <div className="grid grid-cols-12 gap-3 ">
                   <div className="col-span-12 overflow-y-auto overflow-x-auto h-72 ">
                        <TableSiavi
                            cargando={cargandoMensajes}
                            headers={[
                                'Origen',
                                'Mensaje',
                                'Fecha'
                            ]}
                            body={
                                mensajes.map((item, index) =>
                                    <tr key={index} className="cursor-pointer hover:bg-gray-100">
                                        <td className={clases.td}>{item?.profesional_origen?.first_name} {item?.profesional_origen?.last_name} </td>
                                        <td className={clases.td}>{item?.mensaje}</td>
                                        <td className={clases.td}>{moment(item?.fecha_enviado).format("DD-MM-YYYY HH:mm:ss")}</td>
                                    </tr>
                                )
                            }
                        />
                   </div>
                </div>
            </div>
            {
                cargando && <Spinner />
            }
        </div>

    );
};
