import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Orientacion }  from "./orientacion";
import { useSelector } from "react-redux";
import { putOrientacion } from "../services";
import { store } from '../../../../../app/redux/store/store';
import { saveOrientacion } from '../../../../../app/redux/actions/formOrientacion';
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
  tipo_persona: Yup.string().required('Tipo Persona es requerido'),
  //motivoAdmisibilidad: Yup.string().required('Motivo Admisibilidad es requerido'),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  store.dispatch(saveOrientacion(values));
};

export const FormOrientacion = (props) => {
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const token = useSelector((state) => state.loginReducer.data.token);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const id = props.orientacion?.id;
  const servicios = props?.orientacion?.orientacionservicios_detalle;
  const procesos = props?.orientacion?.orientaciontipoprocesos_detalle;
  const orientacion = props?.orientacion;
  
  return (
    <Formik
      initialValues={{
        idPersona: props.orientacion?.persona_detalle?.id,
        presentoDenuncia: props?.orientacion?.denuncia,
        tipoDocto: props?.orientacion?.tipo_documento_derivacion?.id,
        numeroDenuncia: props?.orientacion?.no_denuncia,
        fechaDenuncia: props?.orientacion.fecha_denuncia,
        institucionDenuncia: props?.orientacion.donde_denuncia,
        numeroDocumento: props?.orientacion.doc_referencia,
        entidad_deriva: props?.orientacion.entidad_deriva,
        fechaResolucion: props?.orientacion.fecha_referencia,
        casoAdmitido: (props?.orientacion.caso_admitido === null) ? null : props?.orientacion.caso_admitido,
        casoNoAdmitido:(props?.orientacion.caso_admitido === null) ? null : !props?.orientacion.caso_admitido,
        valorCaso: props?.orientacion.caso_admitido,
        habilitarFormulario: props?.orientacion.caso_admitido,
        isAdmisible: (props?.orientacion.es_admisible === true) ?  "1" :"0",
        isViable: (props?.orientacion.es_viable === true) ?  "1" :"0",
        observaciones: props?.orientacion.observaciones,
        sede: sede,
        comentarios: props?.orientacion?.comentarios,
        otros: props?.orientacion.acompanamientos,
        delitosAgregados: props.orientacion.orientaciondelitos_detalle,
        tipoConsultaAgregados: props.orientacion.orientaciontipoconsulta_detalle,
        serviciosAgregados : props?.orientacion.orientacionservicios_detalle,
        motivosAdmisibilidad: props?.orientacion.orientacionadmisibilidad_detalle,
        motivosNoAdmisibilidad: props?.orientacion.orientacionnoadmisibilidad_detalle,
        motivosViabilidad: props?.orientacion.orientacionviabilidad_detalle,
        motivosNoViabilidad: props?.orientacion.orientacionnoviabilidad_detalle,
        persona: props?.orientacion.persona_detalle,
        tipo_persona: props?.orientacion?.tipo_persona?.id,
        descripcion_motivo_no_admisibilidad: props?.orientacion?.descripcion_motivo_no_admisibilidad,
        como_le_gusta_que_le_llamen: props?.orientacion?.como_le_gusta_que_le_llamen,
        profesional:"",
        querellante: props?.orientacion?.querellante
      }}
      
      validationSchema={validaciones}
      onSubmit={async (values) => {
        if(values.presentoDenuncia === true && ( values.fechaDenuncia === null || values.numeroDenuncia === null || values.institucionDenuncia === "0")){
            setError(true);
            setSuccess(false);
            setMensaje("fecha denuncia, numero denuncia, e institución donde presento la denuncia son requeridos.");
            setTimeout(() => {
              setError(false);
              setMensaje("");
            }, 4000);
        }  else if(values.casoAdmitido === true && values.presentoDenuncia === false){
          setError(true);
          setSuccess(false);
          setMensaje("Debe seleccionar la opcion de presento denuncia");
          setTimeout(() => {
            setError(false);
          }, 4000);
        }
        
        else {
          await putOrientacion(id,values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status === 201 ) {
              setMensaje("Registro actualizado con éxito...!")
              setSuccess(true);
              setError(false);
              values.serviciosAgregados = [];
              values.motivosAdmisibilidad = [];
              values.motivosNoAdmisibilidad = [];
              values.delitosAgregados = [];
              toast.success('Registro actualizado con éxito...!');
              setTimeout(() => {
                 window.location = `/funcionalidades-generales/editar-orientacion/${id}?admitido=${data.data.caso_admitido}`
              }, 500);
            } else {
              setError(true);
              setSuccess(false);
            }
          })
          .catch(function (error) {
            if (error.response.status >= 400 && error.response.status <= 450) {
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
              toast.error(error.response.data.message);
              setTimeout(() => {
                setError(false);
              }, 4000);
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              toast.error("Error interno comuniquese con el administrador");
              setMensaje("Error interno comuniquese con el administrador");
              setTimeout(() => {
                setError(false);
              }, 4000);
            }
          });
        }
      }
     }
    >
      {(props) => (
        <Orientacion
          {...props}
          customSwitchChange={customSwitchChange}
          customChange={handleChangeInput}
          hasError={hasError}
          setError={setError}
          success={success}
          mensaje={mensaje}
          setMensaje={setMensaje}
          idOrientacion={id}
          serviciosBrindados={servicios}
          procesosBrindados= {procesos}
          orientacion={orientacion}
        />
      )}
    </Formik>
  );
};
