import { React, useState } from "react";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { useSelector } from "react-redux";
import { putNarrativa, postNarrativaSindicados, deleteNarrativaSindicados } from "../services";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TrashIcon } from "@heroicons/react/solid";
import Spinner from "../../../../../components/common/Spinner";
import TableSiavi from "../../../../../components/common/TableSiavi";
import toast from "react-hot-toast";

export const EditarNarrativa = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const headerEditar = [
        "Sindicado",
        "Delito",
        "Acciones"
    ];

    return (
        <div>
            <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 p-2 border rounded-md hover:shadow-md">
                    <TextAreaSiavi
                        rows={7}
                        cols={30}
                        validacion={true}
                        name="narrativaEditar"
                        onChange={props?.handleChange}
                        value={props?.values?.narrativaEditar}
                        placeholder="narrativa de hechos"
                        className="mb-2"
                    />
                    {
                        (loadingUpdate) ? <Spinner /> :
                            <ButtonSiavi
                                text="Actualizar"
                                onClick={async () => {
                                    setLoadingUpdate(true);
                                    await putNarrativa(props.itemNarrativa.id, props.values.narrativaEditar, props.itemNarrativa?.caso, props.itemNarrativa?.expediente?.id, token)
                                        .then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                                toast.success("Registro actualizado con éxito...!");
                                                props.setRefeth(true);
                                                props.setRefeth(false);
                                                props.setEditar(false);
                                                setLoadingUpdate(false);
                                            }
                                        })
                                        .catch(function (error) {
                                            if (
                                                error.response.status === 400 ||
                                                error.response.status === 401
                                            ) {
                                                setLoadingUpdate(false);
                                                toast.error(error.response.data.message);
                                            } else if (error.response.status >= 500) {
                                                setLoadingUpdate(false);
                                                toast.error(
                                                    "Error interno comuniquese con el administrador"
                                                );
                                            }
                                        });
                                }}
                            />

                    }

                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 p-2 border rounded-md hover:shadow-md">
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-8 sm:col-span-6 mt-2">
                            <select
                                name="expSindicadoDelito"
                                value={props.values.expSindicadoDelito}
                                className={`${props.selectClass} w-full`}
                                onChange={props.handleChange}
                            >
                                <option value="">Seleccione expediente</option>
                                {props?.delitosFiltrar?.map((item, index) => (
                                    <option
                                        key={item?.expediente_sindicado?.sindicado?.primer_nombre + index}
                                        value={item.id}
                                    >
                                        {item?.expediente_sindicado?.sindicado?.primer_nombre} {item?.expediente_sindicado?.sindicado?.primer_apellido} - {item?.delito?.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6 ">
                            <ButtonSiavi
                                text="Agregar"
                                onClick={() => {
                                    setLoading(true);
                                    postNarrativaSindicados(
                                        props.idCaso,
                                        props.values.expSindicadoDelito,
                                        props.itemNarrativa.id,
                                        token
                                    ).then(() => {
                                        setLoading(false);
                                        props.setRefeth(true);
                                        props.setRefeth(false);
                                        toast.success('Sindicado agregado con éxito');
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 2000);
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {loading && <div className="m-2"> <Spinner /></div>}
                    <TableSiavi
                        headers={headerEditar}
                        body={
                            props?.itemNarrativa?.sindicados_delitos?.map((item, index) => (
                                <tr className="hover:bg-maivi-gray-5 cursor-pointer" key={`${index}-ex`}>
                                    <td className={props.tdClass} >
                                        {item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_nombre} {item?.exp_sindicado_delito?.expediente_sindicado?.sindicado?.primer_apellido}
                                    </td>
                                    <td className={props.tdClass}>{item?.exp_sindicado_delito?.delito?.nombre}</td>
                                    <td className={props.tdClass}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setLoading(true);
                                                deleteNarrativaSindicados(item.id, token).then(() => {
                                                    setLoading(false);
                                                    toast.success('registro eliminado con éxito');
                                                    setTimeout(() => {
                                                        window.location.reload();
                                                    }, 2000);
                                                });
                                            }}
                                            className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                                                        rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                        >
                                            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    />
                </div>
            </div>
            {loading && <Spinner />}
           
        </div>
    );
};
