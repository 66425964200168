/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState,useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { 
  //getServicios,
  getSedes,
  //getAtenciones,
  getProfesionales
} from "./services";
import { SelectSiavi } from "../../../components/common/Select";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
  let filtros = "";
  const [state, setState] = useState({
    servicio: "",
    proceso:"",
    sede:"",
    profesional:"",
    referencia_ts:"",
    atencion:""
  });

  //const [ servicios,setServicios ] = useState([]);
  //const [ atenciones,setAtenciones ] = useState([]);
  const [ sedes,setSedes ] = useState([]);
  const [ profesionales,setProfesionales ] = useState([]);

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  // useEffect(() => {
  //   getAtenciones(props.token).then((data) => {
  //    setAtenciones(data);
  //   });
  // }, []);

  // useEffect(() => {
  //   getServicios(props.token).then((data) => {
  //    setServicios(data);
  //   });
  // }, []);

  useEffect(() => {
    getSedes(props.token).then((data) => {
     setSedes(data);
    });
  }, []);

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
     setProfesionales(data);
    });
  }, []);

  return (
    <div className="mx-4">
         <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="sede"
           label="Sede"
           defaultValue=""
           value={state.sede}
           onChange={onChange}
           initValue=""
           selectInit="Elegir sede"
           list={
             sedes?.results?.map((item,index) =>{
               return <option key={item.sede + index} value={item.id}>{item.sede}</option>
             })
           }
         />
     </div>
     <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="profesional"
           label="Profesional"
           defaultValue=""
           value={state.profesional}
           onChange={onChange}
           initValue=""
           selectInit="Elegir profesional"
           list={
             profesionales?.results?.map((item,index) =>{
               return <option key={item?.first_name + index} value={item?.id}>{item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}</option>
             })
           }
         />
     </div>
         {/* <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="servicio"
           label="Servicio"
           defaultValue=""
           value={state.servicio}
           onChange={onChange}
           initValue=""
           selectInit="Elegir Servicio"
           list={
             servicios?.results?.map((item,index) =>{
               return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
             })
           }
         />
     </div> 
     <div className="col-span-12 mt-4">
         <SelectSiavi 
           name="atencion"
           label="Atenciones"
           defaultValue=""
           value={state.atencion}
           onChange={onChange}
           initValue=""
           selectInit="Elegir atencion"
           list={
             atenciones?.results?.map((item,index) =>{
               return <option key={item?.nombre + index} value={item?.id}>{item?.nombre}</option>
             })
           }
         />
     </div>  */}
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `${(state.sede !== "" ? '&sede=' : '')}${state.sede}${(state.profesional !== "") ? '&profesional_crea_referencia_ts=': ''}${state.profesional}${(state.servicio !== "") ? '&expediente__servicio=' : ''}${state.servicio}${(state.referencia_ts !== "") ? '&referencia_ts=' : ''}${state.referencia_ts}${(state.atencion !== "")? '&referencias_internas__atencion=': ''}${state.atencion}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
