import { useState } from "react";
export default function ItemsNarrativas(props) {
    const [selected, setSelected] = useState(0);
    return (
      <div className={`flex items-center justify-between`}>
            <div className="inline-flex rounded-md shadow-sm -space-x-px">
                {
                    props?.narrativas?.results?.map((item,index) =>(
                        <span key={index+item.id} className={`z-10 cursor-pointer ${(selected === index) ?'bg-blue-100':''} border-blue-300 text-maivi-primaryFont 
                            relative items-center px-3 py-1 border text-sm`} onClick={()=>{
                                props.setItemNarrativa(item);
                                props.values.narrativaEditar = item.narrativa;
                                setSelected(index)
                            }}>
                            {index+1}
                        </span>
                    ))
                }
            </div>
      </div>
    )
}

//bg-indigo-50