import { Tab } from "@headlessui/react";
import { getExpedientePro, getAccionesPro, getSeguimientoPro, getAudienciaPro, getReferenciaPro } from "./services";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/common/Table";
import Spinner from "../../../components/common/Spinner";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const tableColumnsExpedientes = [
    { title: "Expediente", value: "caso_persona.caso.numero_caso", tipo: "text" },
    { title: "Víctima", value: null, values: ["caso_persona.caso.victima.primer_nombre", "caso_persona.caso.victima.primer_apellido"], tipo: "text" },
    { title: "Servicio", value: "servicio.nombre", tipo: "text" },
    {
        title: "Estado",
        value: "caso_persona.status_caso_persona",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
    { title: "Fecha", value: "fecha_creacion", tipo: "date" }
];

const tableColumnsAcciones = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Tipo", value: "tipo", tipo: "text" },
    { title: "Descripción", value: "seguimiento", tipo: "text" },
    { title: "Fecha", value: "fecha_accion", tipo: "date" },
    { title: "Víctima", value: null, values: ["caso.victima.primer_nombre", "caso.victima.primer_apellido"], tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const tableColumnsSeguimientos = [
    { title: "Tipo", value: "tipo", tipo: "text" },
    { title: "Descripción", value: "seguimiento", tipo: "text" },
    { title: "Fecha", value: "fecha_accion", tipo: "date" },
    { title: "Víctima", value: null, values: ["caso.victima.primer_nombre", "caso.victima.primer_apellido"], tipo: "text" },
];

const tableColumnsAudiencias = [
    { title: "Tipo", value: "tipo", tipo: "text" },
    { title: "Descripción", value: "seguimiento", tipo: "text" },
    { title: "Fecha", value: "fecha_accion", tipo: "date" },
    { title: "Víctima", value: null, values: ["caso.victima.primer_nombre", "caso.victima.primer_apellido"], tipo: "text" },
];

const tableColumnsReferencias = [
    { title: "Código", value: "codigo_referencia", tipo: "text" },
    { title: "Motivo", value: "motivo_referencia", tipo: "text" },
    { title: "Fecha", value: "fecha", tipo: "date" },
    { title: "No. Expediente", value: "expediente.numero_expediente", tipo: "text" },
];

export const FormDetalle = (props) => {

    const token = useSelector((state) => state.loginReducer.data.token);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [urlFetchAcciones, setUrlFechAcciones] = useState(undefined);
    const [urlFetchSeguimientos, setUrlFechSeguimientos] = useState(undefined);
    const [urlFetchAudiencias, setUrlFechAudiencias] = useState(undefined);
    const [urlFetchReferencias, setUrlFechReferencias] = useState(undefined);
    const [tableLoading, setTableLoading] = useState(true);
    const [dataTableExpedientes, setDataTableExpedientes] = useState([]);
    const [dataTableAcciones, setDataTableAcciones] = useState([]);
    const [dataTableSeguimientos, setDataTableSeguimientos] = useState([]);
    const [dataTableAudiencias, setDataTableAudiencias] = useState([]);
    const [dataTableReferencias, setDataTableReferencias] = useState([]);

    const fetchExpedientes = () => {
        setTableLoading(true);
        getExpedientePro(props?.Profesional?.id, urlFetch, token).then((data) => {
            setDataTableExpedientes(data);
            setTableLoading(false);
        });
    };

    useEffect(fetchExpedientes, [props?.Profesional?.id, urlFetch, token]);

    const fetchAcciones = () => {
        setTableLoading(true);
        getAccionesPro(props?.Profesional?.id, urlFetchAcciones, token).then((data) => {
            setDataTableAcciones(data);
            setTableLoading(false);
        });
    };

    useEffect(fetchAcciones, [props?.Profesional?.id, urlFetchAcciones, token]);

    const fetchSeguimientos = () => {
        setTableLoading(true);
        getSeguimientoPro(props?.Profesional?.id, urlFetchSeguimientos, token).then((data) => {
            setDataTableSeguimientos(data);
            setTableLoading(false);
        });
    };

    useEffect(fetchSeguimientos, [props?.Profesional?.id, urlFetchSeguimientos, token]);

    const fetchAudiencias = () => {
        setTableLoading(true);
        getAudienciaPro(props?.Profesional?.id, urlFetchAudiencias, token).then((data) => {
            setDataTableAudiencias(data);
            setTableLoading(false);
        });
    };

    useEffect(fetchAudiencias, [props?.Profesional?.id, urlFetchAudiencias, token]);

    const fetchReferencias = () => {
        setTableLoading(true);
        getReferenciaPro(props?.Profesional?.id, urlFetchReferencias, token).then((data) => {
            setDataTableReferencias(data);
            setTableLoading(false);
        });
    };

    useEffect(fetchReferencias, [props?.Profesional?.id, urlFetchReferencias, token]);

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-6 ">
                <div className="bg-white border rounded-md hover:shadow-lg">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Nombre
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {`${props?.Profesional?.first_name} ${props?.Profesional?.last_name}`}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Tipo Profesión
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.tipo_profesional?.profesion}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Tipo Puesto
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.tipo_puesto?.puesto}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Usuario
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.username}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.sede?.sede}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Teléfono de Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.sede?.telefono}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Dirección de Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.sede?.direccion}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Código de Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.sede?.codigo}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Descripción de Sede
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.Profesional?.sede?.descripcion}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Estado
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.Profesional.estado === true ? (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            Activo
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                            Inactivo
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-span-12 xl:col-span-7 lg:col-span-7 md:col-span-6 p-3 border rounded-lg hover:shadow-lg">
                <Tab.Group>
                    <Tab.List className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 bg-blue-50 rounded-md p-1">
                        <Tab
                            key="expedientes"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Expedientes
                        </Tab>
                        <Tab
                            key="acciones"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Acciones
                        </Tab>
                        <Tab
                            key="seguimientos"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Seguimientos
                        </Tab>
                        <Tab
                            key="audiencias"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Audiencias
                        </Tab>
                        <Tab
                            key="referencias"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Referencias
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <CustomTable
                                dataTable={dataTableExpedientes}
                                tableColumns={tableColumnsExpedientes}
                                fetchPage={setUrlFech}
                                pagination={true}
                                permiso={'expediente'}
                                showEditar={false}
                                showEliminar={false}
                                showVer={false}
                            />
                            {tableLoading && (
                                <div className="mt-10 mb-10">
                                    <Spinner />
                                </div>
                            )}
                        </Tab.Panel>
                        <Tab.Panel>
                            <CustomTable
                                dataTable={dataTableAcciones}
                                tableColumns={tableColumnsAcciones}
                                fetchPage={setUrlFechAcciones}
                                pagination={true}
                                permiso={'accionesseguimientoscaso'}
                                showEditar={false}
                                showEliminar={false}
                                showVer={false}
                            />
                            {tableLoading && (
                                <div className="mt-10 mb-10">
                                    <Spinner />
                                </div>
                            )}
                        </Tab.Panel>
                        <Tab.Panel>
                            <CustomTable
                                dataTable={dataTableSeguimientos}
                                tableColumns={tableColumnsSeguimientos}
                                fetchPage={setUrlFechSeguimientos}
                                pagination={true}
                                permiso={'accionesseguimientoscaso'}
                                showEditar={false}
                                showEliminar={false}
                                showVer={false}
                            />
                            {tableLoading && (
                                <div className="mt-10 mb-10">
                                    <Spinner />
                                </div>
                            )}
                        </Tab.Panel>
                        <Tab.Panel>
                            <CustomTable
                                dataTable={dataTableAudiencias}
                                tableColumns={tableColumnsAudiencias}
                                fetchPage={setUrlFechAudiencias}
                                pagination={true}
                                permiso={'accionesseguimientoscaso'}
                                showEditar={false}
                                showEliminar={false}
                                showVer={false}
                            />
                            {tableLoading && (
                                <div className="mt-10 mb-10">
                                    <Spinner />
                                </div>
                            )}
                        </Tab.Panel>
                        <Tab.Panel>
                            <CustomTable
                                dataTable={dataTableReferencias}
                                tableColumns={tableColumnsReferencias}
                                fetchPage={setUrlFechReferencias}
                                pagination={true}
                                permiso={'referencia'}
                                showEditar={false}
                                showEliminar={false}
                                showVer={false}
                            />
                            {tableLoading && (
                                <div className="mt-10 mb-10">
                                    <Spinner />
                                </div>
                            )}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    );
}