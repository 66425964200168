import { React } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { putDetalleReparacionDigna } from "../services";
import { SelectSiavi } from "../../../../../components/common/Select";
import { Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { InputSiavi } from "../../../../../components/common/InputText";
import moment from "moment";

const validaciones = Yup.object().shape({
  status_medidarepdigna: Yup.string().required("estado es requerido"),
  fecha_modificacion: Yup.string().required("fecha es requerida"),
  comentario_status: Yup.string().required('comentario es requerido')
});

export const ModificarStatusRD = ({
  token,
  statusMedidas,
  reparacionDigna,
  state,
  setState,
  setDate,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          id: reparacionDigna.id,
          status_medidarepdigna: reparacionDigna?.status_medidarepdigna?.id,
          medida_solicitada: reparacionDigna.medida_solicitada,
          razon_no_solicitud: reparacionDigna.razon_no_solicitud_id || null,
          monto: reparacionDigna?.monto,
          medida_otorgada: reparacionDigna.medida_otorgada,
          ejecucion_institucion: reparacionDigna.ejecucion_institucion,
          institucion_cumplimiento:
            reparacionDigna.institucion_cumplimiento_id || null,
          ejecuta_sindicado: reparacionDigna.ejecuta_sindicado,
          sindicado_cumplimiento:
            reparacionDigna.sindicado_cumplimiento_id || null,
          caso: reparacionDigna.caso.id,
          expediente_reparacion_digna: reparacionDigna.expediente_reparacion_digna.id,
          reparacion_digna: reparacionDigna.reparacion_digna.id,
          comentario_status: "",
          fecha_modificacion: moment().format("YYYY-MM-DD")
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          await putDetalleReparacionDigna(values, token)
            .then(() => {
              toast.success("Registro actualizado con éxito...!");
              setState({
                ...state,
                cambiarStatusRD: false,
              });

              setDate(new Date());
            })
            .catch(function (error) {
              if (
                error.response.status === 400 ||
                error.response.status === 401
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5">
              <div className="col-span-12 mt-3">
                <SelectSiavi
                  label="Estado"
                  valueInit=""
                  selectInit="Elegir estado"
                  value={values.status_medidarepdigna}
                  onChange={handleChange}
                  name="status_medidarepdigna"
                  validacion={true}
                  list={statusMedidas.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                />
              </div>
              <div className="mt-3">
                  <InputSiavi
                    type="date"
                    name="fecha_modificacion"
                    id="fecha_modificacion"
                    maximo={moment().format("YYYY-MM-DD")}
                    value={values.fecha_modificacion}
                    onChange={handleChange}
                    validacion={true}
                    label="Fecha modificación"
                  />
                </div>
              <div className="mt-3">
                <TextAreaSiavi 
                  value={values.comentario_status}
                  label="Comentario cambio de estado"
                  name="comentario_status"
                  validacion={true}
                  onChange={handleChange}
                  rows={8}
                  cols={8}
                />
              </div>

              <div className="py-3 text-right">
                <ButtonSiavi
                  cargando={isSubmitting}
                  type="submit"
                  disabled={isSubmitting}
                  text="Guardar"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
