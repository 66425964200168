import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    deleteMedida,
    getEstadoProcesoMedida,
    postMedida,
    putMedida,
} from "../medidaDesjudicializadora/services";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import { ScaleIcon, XCircleIcon } from "@heroicons/react/outline";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import { FormDetalle } from "../medidaDesjudicializadora/Detalle";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Medida Desjudicializadora", value: "nombre", tipo: "text", ordenar: "nombre" },
    { title: "Descripción", value: "descripcion", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Medida Desjudicializadora es requerido")
        .max(100, "Máximo 100 caracteres")
});

export const GestionesMedidaDesjudicializadora = (props) => {
    const [verDetalle, setDetalle] = useState(false);
    const [meididaDetalle, setMedidaDetalle] = useState({});
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idMedida, setIdMedida] = useState("");
    const [medida, setMedida] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idEstadoProceso = props?.estadoProceso?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "medidadesjudicializadora";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleVer = (item) => {
        setMedidaDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdMedida(id);
        setEliminar(true);
    };

    const handleEditar = (gestionMedida) => {
        setEdit(true);
        setMedida(gestionMedida);
    };

    const fetchMedida = () => {
        getEstadoProcesoMedida(idEstadoProceso, token).then((medida) => {
            setDataTable(medida);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchMedida, [token, idEstadoProceso, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    estado_proceso: props.estadoProceso.id,
                    nombre: isEdit ? medida?.nombre : "",
                    descripcion: isEdit ? medida?.descripcion : "",
                    estado: isEdit ? medida?.estado : true
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putMedida(medida.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.nombre = "";
                                    values.descripcion = "";
                                    values.estado = true;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postMedida(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-full mx-auto grid grid-cols-12 gap-6 items-end">
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        value={values.nombre}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Nombre*"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="descripcion"
                                        id="descripcion"
                                        value={values.descripcion}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Descripción*"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 text-center mb-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-5">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleVer={handleVer}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={"Detalle de medida desjudicializadora"}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalle detalle={meididaDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteMedida(idMedida, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
