import { React, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";
import { store } from "../../../../../app/redux/store/store";
import { ChangeOrientacionTab } from "../../../../../app/redux/actions/orientacionTab";
import { TrabajoSocial } from "./trabajo_social";
import { Medicina } from "./medicina";
import { Psicología } from "./psicologia";
import { Psiquiatria } from "./psiquiatria";
import { TableAcciones } from "./acciones/acciones";
import { AtencionesComplementarias } from "./atenciones_complementarias";

export const AtencionVictimologica = (props) => {
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const query = new URLSearchParams(window.location.search);
  const casoAdmitido = query.get("admitido");
  const caso = props.caso;
  const delitos = props.delitos;


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "ExpedientesAV";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });

        //retorno = true;  // mm comentar para que funcione
            retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  const tabs = [
    { name: "Trabajo social" },
    { name: "Psicología" },
    { name: "Medicina" },
    { name: "Psiquiatría" },
    { name: "Acciones victimológicas" },
    { name: "Atenciones complementarias" },
    //{ name: "prueba" },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const defaultTab = OrientacionTab.tabs;
    if (casoAdmitido === "null" || casoAdmitido === "false") {
      defaultTab.forEach((element) => {
        if (element.index !== 0) {
          element.block = true;
        }
      });
    } else {
      defaultTab.forEach((element) => {
        if (element.index !== 0) {
          element.block = false;
        }
      });
    }
    store.dispatch(ChangeOrientacionTab(defaultTab));
  }, [casoAdmitido, OrientacionTab.tabs]);

  const getIndexTab = () => {
    let pocision = 0;

    OrientacionTab.tabs.forEach((element, index) => {
      if (element.active) {
        pocision = index;
      }
    });

    return pocision;
  };

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element, index) => {
      if (index === indexTab) {
        element.active = true;
      } else {
        element.active = false;
      }
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };

  return  <Tab.Group defaultIndex={getIndexTab()} onChange={changeTab} >
    <Tab.List className="grid grid-cols-3 xl:grid-cols-8 lg:grid-cols-5 md:grid-cols-4 bg-blue-50 rounded-md p-1 mt-2">
      {tabs.map((item) => {
        //mm
        let permiso = ''
        if (item.index === 0) { permiso = 'TrabajoSocial'; }
        if (item.index === 1) { permiso = 'PSicologia'; }
        if (item.index === 2) { permiso = 'Medicina'; }
        if (item.index === 3) { permiso = 'Psiquiatria'; }
        if (item.index === 4) { permiso = 'AccionesV'; }
        if (item.index === 5) { permiso = 'AtencionesComp'; }

        if (!CheckPermiso(permiso)) { return ''; }

        //mm
        return (
          <Tab
            key={item.name}
            disabled={item.block}
            className={({ selected }) =>
              classNames(
                "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                selected
                  ? "bg-white shadow"
                  : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
              )
            }
          >
            {item.name}
          </Tab>
        );
      })}
    </Tab.List>
    <Tab.Panels>
        <Tab.Panel>
          <TrabajoSocial caso={caso} delitos={delitos} />
        </Tab.Panel>
      <Tab.Panel>
        <Psicología caso={caso} delitos={delitos} />
      </Tab.Panel>
      <Tab.Panel>
        <Medicina caso={caso} delitos={delitos} />
      </Tab.Panel>
      <Tab.Panel>
        <Psiquiatria caso={caso} delitos={delitos} />
      </Tab.Panel>
      <Tab.Panel className="mt-2">
        <TableAcciones caso={caso} />
      </Tab.Panel>
      <Tab.Panel className="mt-2">
        <AtencionesComplementarias caso={caso} />
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
};
