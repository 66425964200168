import moment from "moment";

export const DetalleVM = ({ detalle }) => {
  const row = (titulo, valor) => {
    return (
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <div className="text-xs font-medium text-gray-500">{titulo}</div>
        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
          {valor}
        </div>
      </div>
    );
  };

  const rowBoolean = (titulo, valor) => {
    return (
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <div className="text-xs font-medium text-gray-500">{titulo}</div>
        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
          {valor === true ? (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Sí
            </span>
          ) : (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              No
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white overflow-hidiven border rounded-lg mt-4">
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="sm:divide-y sm:divide-gray-200">
          {row("ID", detalle?.id)}
          {row("Medida Victimológica", detalle?.medida_victimologica.nombre)}
          {row("Activo", detalle?.status?.nombre)}
          {row("Comentario estado", detalle?.comentario_estado)}
          {row("Especificaciones", detalle?.especificaciones)}
          {rowBoolean("Estado",detalle.estado)}
          {row("Fecha medida", moment( detalle?.fecha).format('DD-MM-YYYY'))}
          {row("Fecha modificación", moment(detalle?.fecha_modificacion).format('DD-MM-YYYY'))}
          {row("Caso", detalle?.caso_persona?.caso?.numero_caso)}
        </div>
      </div>
    </div>
  );
};
