import NumberFormat from "react-number-format";

export const DetalleRD = ({ detalle }) => {
  const row = (titulo, valor, monto) => {
    return (
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <div className="text-xs font-medium text-gray-500">{titulo}</div>
        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
          {monto ? (
            <NumberFormat
              name="monto"
              disabled
              thousandSeparator={true}
              prefix={"Q"}
              value={valor}
              allowNegative={false}
              className="mt-1 h-8 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
              focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
              sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
                  />
          ) : (
            valor
          )}
        </div>
      </div>
    );
  };

  const rowBoolean = (titulo, valor) => {
    return (
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <div className="text-xs font-medium text-gray-500">{titulo}</div>
        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
          {valor === true ? (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Sí
            </span>
          ) : (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              No
            </span>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white overflow-hidiven border rounded-lg mt-4">
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="sm:divide-y sm:divide-gray-200">
          {row("Persona", detalle?.persona_c)}
          {row("Medida de reparación digna", detalle?.reparacion_digna?.nombre)}
          {rowBoolean("Medida solicitada", detalle.medida_solicitada)}
          {row("Razón no solicitud", detalle?.razon_no_solicitud?.nombre)}
          {rowBoolean("Medida otorgada", detalle.medida_otorgada)}
          {row("Especificaciones", detalle?.especificaciones)}
          {row("Monto", detalle?.monto,true)}
          {rowBoolean("Ejecuta institución", detalle.ejecucion_institucion)}
          {row("Institución", detalle?.institucion)}
          {rowBoolean("Ejecuta sindicado", detalle.ejecuta_sindicado)}
          {row(
            "Sindicado",
            detalle.sindicado_cumplimiento ? `${detalle?.sindicado_cumplimiento?.sindicado?.primer_nombre} ${detalle?.sindicado_cumplimiento?.sindicado?.segundo_nombre} ${detalle?.sindicado_cumplimiento?.sindicado?.primer_apellido} ${detalle?.sindicado_cumplimiento?.sindicado?.segundo_apellido}` :''
          )}
        </div>
      </div>
    </div>
  );
};
