/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDelitos, getExpedientes } from "../services";
import { ButtonSiavi } from "../../../../../components/common/Button";
/* import { FormDetalle } from "./detalle"; */
import { Historial } from "./historial";
import { Actualizar } from "./actualizar";
import { EtapaProcesal } from "./etapa_procesal";
import { DiligenciasUrgentes } from "./diligencias_urgentes";
import { Diligencias } from "./diligencias";
import { CausaPenal } from "./causa_penal";
import { Seguimientos } from "./Seguimientos";
import { AgendaDeAudiencias } from "./Audiencias";
import { GearIcon, AudienciaIcon } from "../../../../../helpers/icons";
import {
  ScaleIcon,
  XCircleIcon,
  LibraryIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/outline";
import {
  postSindicadoDelito,
  getSindicadoDelitos,
  deleteSindicadoDelitos,
} from "../services";
import {
  TrashIcon,
  ClipboardListIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import FormEliminar from "../../../../catalogos/common/FormEliminar";
import toast from "react-hot-toast";
import Alert from "../../../../../components/common/Alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import Spinner from "../../../../../components/common/Spinner";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalBig from "../../../../../components/common/ModalTwo";

const tdClass = "px-2 py-0.5 row-td text-maivi-primaryFont text-xs";
const thClass = "px-2 py-1 text-left font-medium text-maivi-primaryFont tracking-wider text-xs";

export const Procesos = (props) => {
  const idCaso = props?.caso?.id;
  const token = useSelector((state) => state.loginReducer.data.token);
  const [delitos, setDelitos] = useState({});
  const [delitosSindicado, setDelitosSindicado] = useState([]);
  const [delitosAgregados, setDelitosAgregados] = useState([]);
  const [nombreSindicado, setNombreSindicado] = useState("");
  const [apellidoSindicado, setApellidoSindicado] = useState("");
  const [idDelitosSindicados, setIdDelitosSindicados] = useState("");
  /*   const [verDetalleDelito, setDetalleDelito] = useState(false); */
  /*   const [sindicadoDelitoDetalle, setDelitoJuridicaDetalle] = useState({}); */
  const [verHistorial, setVerHistorial] = useState(false);
  const [isEliminarDelito, setEliminarDelito] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [reFetchExpedientes, setRefetchExpedientes] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [sindicadosElegidos, setSindicadosElegidos] = useState([]);
  const [alertaSindicado, setAlerta] = useState(false);
  const [sindicadoDelito, setSindicadoDelito] = useState(false);
  const [editar, setEditar] = useState(false);
  const [cargandoExpedientes, setCargandoExpedientes] = useState(false);
  const [addEtapa, setEtapa] = useState(false);
  const [expediente, setExpediente] = useState({});
  const [diligencia, setDilegencia] = useState(false);
  const [causaPenal, setCausaPenal] = useState(false);
  const [addNotificacion, setAddNotificacion] = useState(false);
  const optionsDelitos = [];
  const optionsDelitosComplete = [];
  const animatedComponents = makeAnimated();
  const [expedientes, setExpedientes] = useState([]);
  const [seguimientos, setSeguimientos] = useState(false);
  const [audiencias, setAudiencias] = useState(false);
  const [alertaDelito, setAlertaDelito] = useState(false);
  const classAcciones = `hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md 
        text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`;

  useEffect(() => {
    getDelitos(token).then((data) => {
      setDelitos(data)
    });
  }, []);

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //    retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  useEffect(() => {
    setCargando(true);
    getSindicadoDelitos(idCaso, token).then((data) => {
      setDelitosSindicado(data);

      let contador1 = props?.orientacion?.orientaciondelitos_detalle.length;
      let contador = 0;

      props?.orientacion?.orientaciondelitos_detalle?.forEach(element => {
        data?.results?.forEach((item) => {
          if (item?.delito?.id === element?.delito?.id) {
            contador = contador + 1;
          }
        });
      });

      if (contador1 === contador) {
        setAlertaDelito(false)
      } else {
        setAlertaDelito(true)
      }

      setCargando(false)
    });
  }, [token, idCaso, reFetch,]);

  useEffect(() => {
    setCargandoExpedientes(true);
    getExpedientes(idCaso, token).then((data) => {
      if (props?.impugnaciones) {
        setExpedientes(data?.results?.filter(x => x?.servicio?.impugnaciones && x?.asitencia_legal === true));
      } else if (props?.litigio_estrategico) {
        setExpedientes(data?.results?.filter(x => x?.servicio?.litigio_estrategico && x?.asitencia_legal === true));
      } else {
        setExpedientes(data?.results?.filter(x => x?.asitencia_legal === true));
      }
      setCargandoExpedientes(false)
    });
  }, [token, idCaso, reFetchExpedientes, props.impugnaciones]);

  const changeSelectDelitos = (e) => {
    setDelitosAgregados(e);
  };

  // delitosSindicado?.results?.forEach(element => {
  //   props?.orientacion?.orientaciondelitos_detalle?.forEach((item) => {
  //     if(element?.delito?.id === item?.delito?.id){
  //       contador++;
  //     }
  //   });
  // });

  props?.orientacion?.orientaciondelitos_detalle?.forEach((item) => {
    let reg = {
      value: item.delito.id,
      label: item.delito.nombre,
    };
    optionsDelitos.push(reg);
  });

  delitos?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsDelitosComplete.push(reg);
  });

  /*   const handleVerDelito = (item) => {
      setDelitoJuridicaDetalle(item);
      setDetalleDelito(true);
    }; */

  const handleEliminarDelitos = (id, nombre, apellido) => {
    setIdDelitosSindicados(id);
    setNombreSindicado(nombre);
    setApellidoSindicado(apellido);
    setEliminarDelito(true);
  };

  const headersTableDelitos = [
    'Nombre',
    'Proceso',
    'Estado',
    'Delito',
    'Fecha',
    'Acciones'
  ];

  const headersSindicados = [
    'Seleccionar',
    'Proceso',
    'Sindicado'
  ];

  const headersEtapas = [
    'Proceso',
    'Expediente',
    'Etapa procesal',
    'Acciones',
  ]

  return (
    <div className="grid grid-cols-12 gap-2 mt-2">
      {
        (props.ocultar_secciones) ? "" :
          <div className="col-span-12 xl:col-span-5 border p-3 rounded-md shadow-sm hover:shadow-md">
            <h1 className="mb-1 ">Sindicados y tipo de procesos</h1>
            <TableSiavi
              headers={headersSindicados}
              body={
                props?.caso.expedientes &&
                props?.caso?.expedientes?.map((exp) =>
                  exp.sindicados.map((sin) => (
                    <tr
                      key={`${sin?.sindicado.primer_nombre + exp.tipo_proceso?.tipo_proceso + sin.id}`}
                      className="hover:bg-maivi-gray-5 cursor-pointer"
                    >
                      <td className={tdClass}>
                        <input
                          name="checkTable"
                          type="checkbox"
                          className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setSindicadosElegidos([
                                ...sindicadosElegidos,
                                sin.id,
                              ]);
                            } else {
                              let sindicados = sindicadosElegidos.filter(
                                (x) => x !== sin.id
                              );
                              setSindicadosElegidos(sindicados);
                            }
                          }}
                        />
                      </td>
                      <td className={tdClass}>{exp?.tipo_proceso?.tipo_proceso}</td>
                      <td
                        className={`${(sin?.sindicado?.conoce_al_sindicado === false) ? 'text-sm italic' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                      >
                        {`${(sin?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado No Individualizado' : sin?.sindicado?.primer_nombre + ' ' + sin.sindicado.primer_apellido}`} { }

                      </td>
                    </tr>
                  ))
                )
              }
            />
          </div>
      }

      {
        (props.ocultar_secciones) ? "" :
          <div className="col-span-12 xl:col-span-7 border p-3 rounded-md shadow-sm hover:shadow-md">
            <h1 className="mb-1 ">Asociar delitos a sindicados</h1>
            <div className="grid gap-3 grid-cols-12">
              <div className="col-span-4">
                <Select
                  isMulti={false}
                  placeholder="Seleccionar"
                  components={animatedComponents}
                  name="delitos"
                  className="basic-multi-select text-xs text-maivi-primaryFont"
                  classNamePrefix="seleccione"
                  options={(props.es_caso) ? optionsDelitosComplete : optionsDelitos}
                  onChange={changeSelectDelitos}
                />
              </div>
              <div className="col-span-2 xl:col-span-3 lg:col-span-3 md:col-span-2">
                {(CheckPermiso('OPDAgregarDelitos') === true) &&    //mm permiso agregado        
                  <ButtonSiavi
                    text="Agregar"
                    onClick={() => {
                      if (
                        sindicadosElegidos.length === 0 ||
                        delitosAgregados?.value === ""
                      ) {
                        setAlerta(true);
                        setTimeout(() => {
                          setAlerta(false);
                        }, 4000);
                      } else {
                        setCargando(true);
                        sindicadosElegidos.forEach(async (item) => {
                          await postSindicadoDelito(
                            idCaso,
                            item,
                            delitosAgregados.value,
                            token
                          ).then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                              toast.success("delitos agregados con éxito..!");
                              setReFetch(true);
                              setReFetch(false);
                              setCargando(false);
                            } else {
                              toast.success("delitos agregados con éxito..!");
                            }
                          })
                            .catch(function (error) {
                              if (
                                error.response.status >= 400 &&
                                error.response.status <= 450
                              ) {
                                toast.error(error.response.data.message);
                              } else if (error.response.status >= 500) {
                                toast.error(
                                  "Error interno comuniquese con el administrador"
                                );
                              }
                            });
                        });
                      }
                    }}
                  />
                }
              </div>
              {alertaSindicado && (
                <div className="col-span-12">
                  <Alert
                    type="error"
                    mensaje="debe seleccionar al menos un delito y un sindicado"
                  />
                </div>
              )}
              {(alertaDelito && props?.es_caso === false) && (
                <div className="col-span-12 mb-2">
                  <Alert
                    type="error"
                    mensaje="Delitos pendientes de asociar"
                  />
                </div>
              )}
            </div>
            {cargando ? (
              <Spinner />
            ) : (
              <TableSiavi
                className="mt-2"
                headers={headersTableDelitos}
                body={
                  props?.caso?.expedientes?.map((exp) =>
                    delitosSindicado?.results?.map((item, index) => {
                      if (exp?.id === item?.expediente_sindicado?.expediente) {
                        return <tr
                          key={"sin" + index}
                          className="hover:bg-maivi-gray-5 cursor-pointer"
                        >
                          <td className={`px-2 py-1 row-td text-xs ${(item?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? "text-red-600" : "text-maivi-primaryFont"}`}>
                            {
                              `${(item?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado no individualizado' :
                                `${item?.expediente_sindicado?.sindicado?.primer_nombre} 
                               ${item?.expediente_sindicado?.sindicado?.primer_apellido}`}`
                            }
                          </td>
                          <td className={tdClass}>{exp?.tipo_proceso?.tipo_proceso}</td>
                          <td className={tdClass}>{(item.estado === true) ? 'ACTIVO' : 'INACTIVO'}</td>
                          <td className={tdClass}>{item?.delito?.nombre}</td>
                          <td className={tdClass}>{item.fecha}</td>
                          <td className={tdClass}>
                            <button
                              type="button"
                              className={classAcciones}
                              onClick={() => {
                                setVerHistorial(true);
                                setSindicadoDelito(item);
                              }}
                            >
                              <ClipboardListIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                              <span className="tooltiptext">Ver historial</span>
                            </button>

                            {(CheckPermiso('OPDEditarDelitos') === true) &&    //mm permiso agregado        
                              <button
                                type="button"
                                className={classAcciones}
                                onClick={() => {
                                  setEditar(true);
                                  setSindicadoDelito(item);
                                }}
                              >
                                <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                <span className="tooltiptext">Editar</span>
                              </button>
                            }
                            {/* <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            handleVerDelito(item?.expediente_sindicado);
                          }}
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          <span className="tooltiptext">Ver detalle</span>
                        </button> */}
                            {(CheckPermiso('OPDEliminarDelitos') === true) &&    //mm permiso agregado        
                              <button
                                type="button"
                                onClick={() => {
                                  let nombreCampo;
                                  let apellidoCampo;
                                  nombreCampo =
                                    item?.expediente_sindicado?.sindicado?.primer_nombre;
                                  apellidoCampo =
                                    item?.expediente_sindicado?.sindicado
                                      ?.primer_apellido;
                                  handleEliminarDelitos(
                                    item?.id,
                                    nombreCampo,
                                    apellidoCampo,
                                    item?.expediente_sindicado
                                  );
                                }}
                                className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                       rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                              >
                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                <span className="tooltiptext">Eliminar</span>
                              </button>
                            }
                          </td>
                        </tr>
                      }
                    })
                  )
                }
              />
            )}
          </div>
      }

      <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
        <h1 className="mb-1 ">Etapas procesales</h1>
        <TableSiavi
          headers={headersEtapas}
          cargando={cargandoExpedientes}
          body={expedientes?.map((item) => {
            return <tr
              key={item.id}
              className="hover:bg-maivi-gray-5 cursor-pointer"
            >
              <td className={tdClass}>{item?.tipo_proceso?.tipo_proceso}</td>
              <td className={tdClass}>{item?.numero_expediente}</td>
              <td className={tdClass}>{item?.ultima_etapa_procesal?.etapa_procesal?.nombre}</td>
              <td className={tdClass}>
                {(CheckPermiso('OPDAgregarEtapaProcesal') === true) &&    //mm permiso agregado        
                  <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                      setExpediente(item);
                      setEtapa(true);
                    }}
                  >
                    <ScaleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext">Agregar etapa procesal</span>
                  </button>
                }
                {(CheckPermiso('OPDCausaPenal') === true) &&    //mm permiso agregado        
                  <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                      setExpediente(item);
                      setCausaPenal(true);
                    }}
                  >
                    <LibraryIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext">Agregar causa penal</span>
                  </button>
                }
                {(CheckPermiso('OPDDiligenciaUrgente') === true) &&    //mm permiso agregado        
                  <button
                    type="button"
                    onClick={() => {
                      setExpediente(item);
                      setDilegencia(true);
                    }}
                    className={`hover:bg-yellow-500 items-center p-1 border border-transparent 
                     rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}
                  >
                    <ShieldExclamationIcon className="h-6 w-6 text-yellow-400 hover:text-white" />
                    <span className="tooltiptext">Agregar diligencia urgente</span>
                  </button>
                }
              </td>
            </tr>
          })}
        />
      </div>
      {isEliminarDelito && (
        <ModalGenerico
          title={`¿Esta seguro de eliminar el registro ${nombreSindicado} ${apellidoSindicado}?`}
          open={isEliminarDelito}
          closeModal={() => { }}
          cerrar={() => {
            setEliminarDelito(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
            </div>
          }
          children={
            <FormEliminar
              aceptar={() => {
                setLoading(true);
                deleteSindicadoDelitos(idDelitosSindicados, token).then(
                  (data) => {
                    if (data?.message !== undefined) {
                      setLoading(false);
                      toast.error(data.message);
                      setEliminarDelito(false);
                    } else {
                      setReFetch(true);
                      setReFetch(false)
                      setLoading(false);
                      setEliminarDelito(false);
                      toast.success("Registro eliminado con éxito...!");
                    }
                  }
                );
              }}
              isloading={isloading}
              cancelar={() => {
                setEliminarDelito(false);
              }}
            />
          }
        />
      )}

      {seguimientos && (
        <ModalBig
          title={`Agregar Seguimientos`}
          open={true}
          closeModal={() => { }}
          cerrar={() => {
            setSeguimientos(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <GearIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={
            <Seguimientos infoExpediente={expediente} />
          }
        />
      )}

      {audiencias && (
        <ModalBig
          title={`Agregar agenda de audiencias`}
          open={true}
          closeModal={() => { }}
          cerrar={() => {
            setAudiencias(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <AudienciaIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={
            <AgendaDeAudiencias infoExpediente={expediente} />
          }
        />
      )}
      {/* 
      {verDetalleDelito && (
        <ModalGenerico
          title={"Detalle de delitos sindicados"}
          open={verDetalleDelito}
          closeModal={() => { }}
          cerrar={() => {
            setDetalleDelito(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <FormDetalle
              detalle={sindicadoDelitoDetalle}
              cancelar={setEliminarDelito}
            />
          }
        />
      )} */}

      {editar && (
        <ModalGenerico
          title={"Actualizar"}
          open={editar}
          closeModal={() => { }}
          cerrar={() => {
            setEditar(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <PencilAltIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <Actualizar
              delitos={optionsDelitos}
              sindicadoDelito={sindicadoDelito}
              reFetch={setReFetch}
              setEditar={setEditar}
            />
          }
        />
      )}

      {verHistorial && (
        <ModalGenerico
          title={"Historial delitos sindicados"}
          open={verHistorial}
          closeModal={() => { }}
          cerrar={() => {
            setVerHistorial(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ClipboardListIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <Historial
              sindicadoDelito={sindicadoDelito}
              tdClass={tdClass}
              thClass={thClass}
            />
          }
        />
      )}

      {addEtapa && (
        <ModalGenerico
          title={"Agregar etapa procesal"}
          open={addEtapa}
          closeModal={() => { }}
          cerrar={() => {
            setEtapa(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <EtapaProcesal
              idCaso={idCaso}
              expediente={expediente}
              tdClass={tdClass}
              thClass={thClass}
              setEtapa={setEtapa}
              reFetch={setRefetchExpedientes}
            />
          }
        />
      )}

      {diligencia && (
        <ModalAmplio
          title={"Agregar diligencia urgente"}
          open={diligencia}
          closeModal={() => { }}
          cerrar={() => {
            setDilegencia(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
              <ShieldExclamationIcon className="h-6 w-6 text-yellow-300" />
            </div>
          }
          children={
            <DiligenciasUrgentes
              idCaso={idCaso}
              expediente={expediente}
              tdClass={tdClass}
              thClass={thClass}
              setDilegencia={setDilegencia}
            />
          }
        />
      )}

      {addNotificacion && (
        <ModalAmplio
          title={"Agregar notificacion"}
          open={addNotificacion}
          closeModal={() => { }}
          cerrar={() => {
            setAddNotificacion(false);
          }}

          children={
            <Diligencias
              idCaso={idCaso}
              expediente={expediente}
              tdClass={tdClass}
              thClass={thClass}
              setDilegencia={setDilegencia}
            />
          }
        />
      )}

      {causaPenal && (
        <ModalGenerico
          title={"Agregar causa penal"}
          open={causaPenal}
          closeModal={() => { }}
          cerrar={() => {
            setCausaPenal(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <LibraryIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>

          }
          children={
            <CausaPenal
              idCaso={idCaso}
              expediente={expediente}
              tdClass={tdClass}
              thClass={thClass}
              setCausaPenal={setCausaPenal}
            />
          }
        />
      )}
    </div>
  );
};
