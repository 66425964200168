import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getServicios } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchServicio = (token) => {
        const [state, setState] = useState({
            servicios: [],
        });

        useEffect(() => {
            getServicios(token).then((servicios) => {
                setState({
                    servicios: servicios,
                });
            });
        }, [token]);

        return state;
    };

    const [state, setState] = useState({
        estado: "",
        servicio: "",
        por_caso: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const { servicios } = useFetchServicio(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Por Caso" />
                    <div className="mt-1">
                        <select
                            name="por_caso"
                            value={state.por_caso}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir por caso</option>
                            <option value="true">Sí</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <Label text="Servicio" />
                    <div className="mt-1">
                        <select
                            id="servicio"
                            name="servicio"
                            value={state.servicio}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir servicio</option>
                            {servicios?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `estado=${state.estado}&por_caso=${state.por_caso}&servicio=${state.servicio}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
