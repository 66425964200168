import { React } from "react";
import { Formik } from "formik";
import { postPregunta, putPregunta } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  pregunta: Yup.string()
    .required("pregunta es requerida"),
  respuesta: Yup.string()
    .required("respuesta es requerido"),
  
});


export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  return (
      <Formik
        initialValues={{
          pregunta: props.editar ? props.value.pregunta : "",
          respuesta: props.editar ? props.value.respuesta : "",
          palabras_clave: props.editar ? props.value.palabras_clave : "",
          estado: props.editar ? props.value.estado : true,
          url: props.editar ? props.value.url : "",
        }}
        validationSchema={validaciones}
        validateOnMount={true}
        onSubmit={async (values) => {
          if (props.editar) {
            await putPregunta(props.value.id, values, token).then((data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                  toast.success('pregunta actualizada con éxito');
                  props.setReFetch(true);
              }
          })
              .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 410) {
                      toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                      toast.error('Error interno comuniquese con el administrador');
                  }
              });

          
          } else {
            await postPregunta(values, token).then((data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                  toast.success('pregunta creada con éxito');
                  props.setReFetch(true);
              }
            })
              .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 410) {
                      toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                      toast.error('Error interno comuniquese con el administrador');
                  }
              });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              <div className="mb-2">
                <InputSiavi
                  type="text"
                  name="pregunta"
                  value={values.pregunta}
                  onChange={handleChange}
                  validacion={true}
                  label="Pregunta*"
                />
              </div>
              <div className="mb-2">
                <InputSiavi
                  type="text"
                  name="respuesta"
                  value={values.respuesta}
                  onChange={handleChange}
                  validacion={true}
                  label="Respuesta*"
                />
              </div>
              <div className="mb-2">
                <InputSiavi
                  type="text"
                  name="palabras_clave"
                  value={values.palabras_clave}
                  onChange={handleChange}
                  validacion={true}
                  label="Palabras clave"
                />
              </div>
              <div className="mb-2">
                <InputSiavi
                  type="text"
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  validacion={true}
                  label="Url video"
                />
              </div>
              <div className="col-span-6 mt-3">
                <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
  );
};
