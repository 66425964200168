/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import {
    getProfesionales
} from "../reasignacionExpedientes/services";
import { SelectSiavi } from "../../../components/common/Select";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";

export default function Filtros(props) {
    const filtroSedes = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.otra_sede);
    const filtroServicios = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.servicios);

    let filtros = "";
    const [state, setState] = useState({
        profesional_solicita: "",
        servicio: "",
        sede: "",
        gestiona_cierre: "",
        cierre_autorizado: "",
        fecha_desde: "",
        fecha_hasta: "",
        fecha_gestiona_desde: "",
        fecha_gestiona_hasta: ""
    });

    const [profesionales, setProfesionales] = useState([]);

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        getProfesionales(props.token).then((data) => {
            setProfesionales(data);
        });
    }, []);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            {
                (filtroSedes.length > 0) ?
                    <div className="col-span-12 mt-4">
                        <SelectSiavi
                            name="sede"
                            label="Sede"
                            defaultValue=""
                            value={state.sede}
                            onChange={onChange}
                            initValue=""
                            selectInit="Elegir sede"
                            list={
                                filtroSedes?.map((item, index) => {
                                    return <option key={item.sede.id + index} value={item.sede.id}>{item.sede.sede}</option>
                                })
                            }
                        />
                    </div> : ""
            }
            {
                (filtroServicios.length > 0) ?
                    <div className="col-span-12 mt-4">
                        <SelectSiavi
                            name="servicio"
                            label="Servicio"
                            defaultValue=""
                            value={state.servicio}
                            onChange={onChange}
                            initValue=""
                            selectInit="Elegir Servicio"
                            list={
                                filtroServicios?.map((item, index) => {
                                    return <option key={item.servicio_detalle?.id + index} value={item?.servicio_detalle?.id}>{item.servicio_detalle?.nombre}</option>
                                })
                            }
                        />
                    </div> : ""
            }
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="profesional_solicita"
                    label="Profesional solicita"
                    value={state.profesional_solicita}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir profesional solicita"
                    list={
                        profesionales?.results?.map((item, index) => {
                            return <option key={item.first_name + index} value={item.id}>{item.first_name} {item.last_name}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    label="Gestionado"
                    name="gestiona_cierre"
                    value={state.gestiona_cierre}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir gestionado"
                    list={[
                        <option value="true">Sí</option>,
                        <option value="false">No</option>
                    ]
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    label="Autorizado"
                    name="cierre_autorizado"
                    value={state.cierre_autorizado}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir autorizado"
                    list={[
                        <option value="true">Sí</option>,
                        <option value="false">No</option>
                    ]
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha solicita inicial"
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha solicita final"
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_gestiona_desde"
                    value={state.fecha_gestiona_desde}
                    onChange={onChange}
                    label="Fecha cierre inicial"
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_gestiona_hasta"
                    value={state.fecha_gestiona_hasta}
                    onChange={onChange}
                    label="Fecha cierre final"
                />
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `gestiona_cierre=${state.gestiona_cierre}&cierre_autorizado=${state.cierre_autorizado}&expediente__servicio=${state.servicio}&caso__sede_caso=${state.sede}&profesional_solicita=${state.profesional_solicita}&fecha__gte=${state.fecha_desde}&fecha__lte=${state.fecha_hasta}&fecha_gestiona__gte=${state.fecha_gestiona_desde}&fecha_gestiona__lte=${state.fecha_gestiona_hasta}`;
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
