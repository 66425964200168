import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../components/common/Select";
import {
  getEscolaridadVictima,
  getIdiomaVictima,
  getReligionVictima,
  getTipoProfesion,
  getEstadoCivilVictima,
} from "../editar/victima/services";
import { GestionDireccion } from "../../../../components/gestionDireccion/gestionDireccion";
import { GestionTelefono } from "../../../../components/gestionTelefono/gestionTelefono";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import { Discapacidades } from "./discapacidades";
import ModalGenerico from "../../../../components/common/Modal";

const classButton = `inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 
font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none 
focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full`;

export const DatosDemograficos = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [escolaridad, setEscolaridades] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [religiones, setReligiones] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [estadocivil, setEstadocivil] = useState([]);
  const [gestionarDirecciones, setGestionarDirecciones] = useState(false);
  const [gestionarTelefonos, setGestionarTelefonos] = useState(false);
  const [discapacidad, setDiscapacidad] = useState(false);

  useEffect(() => {
    getEscolaridadVictima(token).then((data) => {
      setEscolaridades(data)
    });
  }, [token]);

  useEffect(() => {
    getIdiomaVictima(token).then((data) => {
      setIdiomas(data)
    });
  }, [token]);

  useEffect(() => {
    getReligionVictima(token).then((data) => {
      setReligiones(data)
    });
  }, [token]);

  useEffect(() => {
    getTipoProfesion(token).then((data) => {
      setProfesiones(data)
    });
  }, [token]);

  useEffect(() => {
    getEstadoCivilVictima(token).then((data) => {
      setEstadocivil(data)
    });
  }, [token]);

  let values ={
    editarPersona : true,
    idPersona: props?.registro?.id,
    discapacidades: props?.registro?.discapacidades
  }

  return (
    <div className="grid grid-cols-12 gap-3 items-end">
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Estado civil"
          name="estado_civil"
          value={props.values?.estado_civil}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir estado civil"
          list={estadocivil?.results?.map((item) => {
            return (
              <option key={item.nombre + "ec"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Idioma"
          name="idioma"
          value={props.values?.idioma}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir idioma"
          list={idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Religión"
          name="religion"
          value={props.values?.religion}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir religión"
          list={religiones?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Escolaridad"
          name="escolaridad"
          value={props.values?.escolaridad}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir escolaridad"
          list={escolaridad?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Profesión/oficio"
          name="profesion"
          value={props.values?.profesion}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir tipo profesion"
          list={profesiones?.results?.map((item) => {
            return (
              <option key={item.nombre + "tp"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
     {props.editar && 
       <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
       <button
         onClick={() => {setGestionarDirecciones(true)}}
         type="button"
         className={classButton}
       >
         Gestionar direcciones
       </button>
     </div>
     }
     {
      props.editar &&
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
      <button
        onClick={() => {setGestionarTelefonos(true)}}
        type="button"
        className={classButton}
      >
        Gestionar telefonos
      </button>
    </div>
     }
     {
      props.editar && 
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
      <button
        onClick={() => {setDiscapacidad(true)}}
        type="button"
        className={classButton}
      >
        Discapacidades
      </button>
    </div>
     }
     
    
      {gestionarDirecciones && (
        <ModalAmplio
          title={`Gestionar Direcciones`}
          open={true}
          closeModal={() => {}}
          children={<GestionDireccion persona={props.registro} />}
          cerrar={() => {
            setGestionarDirecciones(false);
          }}
        />
      )}
      {gestionarTelefonos && (
        <ModalAmplio
          title={`Gestionar Teléfonos`}
          open={true}
          closeModal={() => {}}
          children={<GestionTelefono persona={props.registro} />}
          cerrar={() => {
            setGestionarTelefonos(false);
          }}
        />
      )}
       {discapacidad && (
        <ModalGenerico
          title={`Discapacidades`}
          open={true}
          closeModal={() => {}}
          children={
            <Discapacidades 
              persona={props.registro} 
              values={values} 
              setDiscapacidad={setDiscapacidad} 
            />
          }
          cerrar={() => {
            setDiscapacidad(false);
          }}
        />
      )}
    </div>
  );
};
