/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { 
  getTipoProcesos,
  postAddTipoProceso,
  getProcesos,
  deleteProceso
} from "./services";
import { clases } from "../../../helpers/clases";
import { TrashIcon } from "@heroicons/react/solid";
import ModalGenerico from "../../../components/common/Modal";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import Spinner from "../../../components/common/Spinner";
import TableSiavi from "../../../components/common/TableSiavi";
import toast from "react-hot-toast";

export default function TiposProcesos(props) {
  const [ procesos,setProcesos] = useState([]);
  const [ tiposProcesos,setTipoProcesos ] = useState([]);
  const [ cargando,setCargando ] = useState(false);
  const [ cargandoProcesos, setCargandoProcesos ] = useState(false);
  const [ borrarProceso,setBorrarProceso ] = useState(false);
  const [ item,setItem ] = useState(false);
  const [ reFetch,setReFetch ] = useState(false);
  const [state, setState] = useState({
    tipo_proceso: "",
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getTipoProcesos(props.token).then((data) => {
        setProcesos(data);
    });
  }, []);

  useEffect(() => {
    setCargandoProcesos(true);
    getProcesos(props?.registro?.id, props.token).then((data) => {
        setTipoProcesos(data);
        setCargandoProcesos(false);
    });
  }, [reFetch]);

  const headers= [
    "Proceso",
    "Eliminar"
  ]

  return (
    <div className="mx-2">
         <div className="col-span-12">
            <SelectSiavi 
              label="Tipo proceso"
              name="tipo_proceso"
              value={state.tipo_proceso}
              onChange={onChange}
              initValue=""
              selectInit="Elegir tipo proceso"
              list={
                procesos?.results?.map((item) =>{
                  return <option key={item.tipo_proceso} value={item.id}>{item.tipo_proceso}</option>
                })
              }
            />
        </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Guardar"
            className="mb-2"
            onClick={async() => {
                setCargando(true);
                await postAddTipoProceso(props.registro.id, state.tipo_proceso, props.token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201 ) {
                    setCargando(false);
                    setReFetch(true);
                    toast.success("Proceso agregado con éxito");
                    setReFetch(false);
                  } 
                })
                .catch(function (error) {
                  if (error.response.status === 400 || error.response.status === 401) {
                    setCargando(false);
                    toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                      setCargando(false);
                    toast.error("Error interno comuniquese con el administrador")
                  }
                });
            }}
          />
          { 
            cargando && <Spinner />
          }
        </div>
        <TableSiavi 
          headers={headers}
          cargando={cargandoProcesos}
          body={
            tiposProcesos?.results?.map((item,index) =>(
              <tr className={clases.tr}>
                <td className={clases.td}>{item?.tipo_proceso?.tipo_proceso}</td>
                <td className={clases.td}>
                <button
                      type="button"
                      onClick={async () => {
                        setBorrarProceso(true);
                        setItem(item);
                      }}
                      className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md 
                    text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                      <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                </td>
              </tr>
            ))
          }
        />
        {props.isloading === true ? <Spinner /> : ""}
      {borrarProceso && (
            <ModalGenerico
              title={"Esta seguro de eliminar el registro"}
              open={borrarProceso}
              closeModal={() => { }}
              cerrar={() => {
                setBorrarProceso(false);
              }}
              children={
                <BotonesConfirmar 
                  aceptar={async ()=>{
                    await deleteProceso(item.id, props.token)
                    .then((data) => {
                      if (data?.status >= 200 || data?.status <= 220 ) {
                        setReFetch(true);
                        toast.success("Proceso borrado con éxito");
                        setReFetch(false);
                        setBorrarProceso(false);
                      } 
                    })
                    .catch(function (error) {
                      if (error.response.status === 400 || error.response.status === 401) {
                        toast.error(error.response.data.message);
                      } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador")
                      }
                    });
                  }}
                  cancelar={()=>{
                    setBorrarProceso(false);
                  }}
                />
              }
            />
          )}
    </div>
  );
}
