import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Riesgos } from "./riesgos";

const validaciones = Yup.object().shape({
  // tipoDocumento: Yup.string()
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  //store.dispatch(savePersona(values));
};

export const FormRiesgos = (props) => {
  const caso = props?.caso;
  return (
    <Formik
      initialValues={{}}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {}}
    >
      {(props) => (
        <Riesgos
          {...props}
          customSwitchChange={customSwitchChange}
          customChange={handleChangeInput}
          caso={caso}
        />
      )}
    </Formik>
  );
};
