import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { putBiblioteca } from "../services";
import { getBiblioteca } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Editar } from "./editar";
import { useParams } from "react-router-dom";

// Validation with Yup
const validaciones = Yup.object().shape({
    nombre_documento: Yup.string()
        .required("Nombre Documento es requerido")
        .max(100, "máximo 100 caracteres"),
    palabras_clave: Yup.string()
        .required("Palabras Clave es requerido")
        .max(100, "máximo 100 caracteres")
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const FormEditar = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { id } = useParams();
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const history = useHistory();
    const [biblioteca, setBiblioteca] = useState({});
    const [loading, setLoanding] = useState(true);

    useEffect(() => {
        const datos = async () => {
            await getBiblioteca(id, token).then((data) => {
                setBiblioteca(data);
                setLoanding(false);
            });
        };
        datos();
    }, [id, token]);

    return loading ? (
        <div></div>
    ) : (
        <Formik
            initialValues={{
                id: biblioteca?.id,
                nombre_documento: biblioteca?.nombre_documento || "",
                palabras_clave: biblioteca?.palabras_clave || "",
                estado: biblioteca?.estado || true,
                por_servicio: biblioteca?.por_servicio || false,
                fecha_creacion: biblioteca?.fecha_creacion || "",
                clasificacion: biblioteca?.clasificacion?.id || "",
                servicio: biblioteca?.servicio?.id || ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putBiblioteca(id,values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje('Registro actualizado con éxito...!');
                            toast.success('Registro actualizado con éxito...!')
                            history.push('/catalogos/biblioteca');
                        } else {
                            setError(true);
                            setSuccess(false);
                        }
                    })
                    .catch(function (error) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401
                        ) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {(props) => (
                <div>
                    <Editar
                        {...props}
                        customSwitchChange={customSwitchChange}
                        hasError={hasError}
                        success={success}
                        mensaje={mensaje}
                    />
                </div>

            )}
        </Formik>
    );
};
