/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { postCambiarEstadoCaso,getHistorialEstadosCaso} from "../services";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { clases } from "../../../../../helpers/clases";
import Toggle from "../../../../../components/common/Toggle";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";
import TableSiavi from "../../../../../components/common/TableSiavi";
import moment from "moment";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    motivo_cambio: Yup.string().required("motivo es requerido")
});

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [historial,setHistorial] = useState([]);
    const [cargando,setCargando] = useState(false);
    const [refetch,setReFetch] = useState(false);

    useEffect(()=>{
        setCargando(true);
        getHistorialEstadosCaso(props?.caso?.id,token).then((data)=>{
            setHistorial(data);
            setCargando(false);
        });
    },[refetch])

    return (
        <Formik
            initialValues={{
                estado: props?.caso?.estado,
                motivo_cambio: "",
                caso: props?.caso?.id,
            }}
            validationSchema={validaciones}
            onSubmit={async (values,{resetForm}) => {
                await postCambiarEstadoCaso(values, token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success('registro guardado con éxito');
                            setReFetch(true);
                            setReFetch(false);
                            resetForm({});
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error('Error interno comuniquese con el administrador');
                        }
                    });
            }}>
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="grid grid-cols-12 gap-2 mb-10">
                    <div className="col-span-12 border rounded-lg p-3 hover:shadow-lg">
                        <Label text="Estado" />
                        <Toggle
                            enabled={values.estado}
                            name="estado"
                        />
                        <TextAreaSiavi
                            label="Motivo"
                            name="motivo_cambio"
                            value={values.motivo_cambio}
                            rows={5}
                            onChange={handleChange}
                            validacion={true}
                        />
                        <div className="grid grid-cols-12 gap-2 mt-2">
                            <div className="xl:col-span-9 lg:col-span-9 md:col-span-9 sm:col-span-6">

                            </div>
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6 ">
                                {
                                    (isSubmitting) ?<Spinner /> :
                                    <ButtonSiavi
                                    type="button"
                                    text="Guardar"
                                    onClick={handleSubmit}
                                />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 border rounded-lg p-3 hover:shadow-lg">
                        <TableSiavi
                            cargando={cargando}
                            headers={[
                                'Motivo',
                                'Estado',
                                'Fecha'
                            ]}
                            body={
                                historial?.results?.map((item,index) => (
                                    <tr key={item?.fecha + index} className={clases.tr}>
                                        <td className={clases.td}>{item?.motivo_cambio}</td>
                                        <td className={clases.td}>{(item?.estado_nuevo) ? 'Activo': 'Inactivo'}</td>
                                        <td className={clases.td}>{(moment(item?.fecha).format("DD-MM-YYYY"))}</td>
                                    </tr>
                                ))
                            }
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};
