export const FormDetalleDiligenciaUrgente = (props) => {
    return (
        <div className="rounded-md border">
                <div className="sm:divide-y sm:divide-gray-200">
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Tipo diligencia</div>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {`${props?.registro.tipo_diligencia?.nombre}`}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Comentario</div>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.registro?.comentarios}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Urgente</div>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {(props.registro.urgente) ? 'Si' : 'No'}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Fecha</div>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.registro?.fecha_creacion}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Usuario creacion</div>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.registro?.usuario_creacion?.first_name} {props?.registro?.usuario_creacion?.last_name}
                        </dd>
                    </div>
                    { (props.notificacion) &&
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                           <div className="text-sm font-medium text-gray-500">No. documento</div>
                           <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                               {props?.registro?.numero_documento}
                           </dd>
                       </div>
                    }
                     {
                         (props.notificacion) &&
                        <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                           <div className="text-sm font-medium text-gray-500">Plazo</div>
                           <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                               {props?.registro?.plazo} 
                           </dd>
                       </div>
                    }
                </div>
        </div>
    );
};
