import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import {
  getPermisos,
  getAtenciones,
  getRolesUsuarios,
  getSedesUsuarios,
  getTipoProfesionalUsuarios,
  getTipoPuestoUsuarios,
  getUsuario,
  getServicios,
  getRolesVista
} from "../services";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import Return from "../../../../components/common/ButtonSalir";
import Divider from "../../../../components/common/Divider";
import ModalGenerico from "../../../../components/common/Modal";
import Toggle from "../../../../components/common/Toggle";
import ButtonsConfirmacion from "../../../../components/common/ButtonsConfirmacion";
import { SelectSiavi } from "../../../../components/common/Select";

const menu = [
  {
    name: "Sedes"
  },
  {
    name: "Roles"
  },
  {
    name: "Permisos"
  },
  {
    name: "Atenciones"
  },
  {
    name:"Servicios"
  }
]


export const Registrar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje,
  setFieldValue,
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [sedes, setSedes] = useState({});
  const [puestos, setPuestos] = useState({});
  const [profesionales, setProfesionales] = useState({});
  const [permisos, setPermisos] = useState({});
  const [roles, setRoles] = useState({});
  const [rolesVista, setRolesVista] = useState({});
  const [atenciones, setAtenciones] = useState({});
  const [servicios, setServicios] = useState({});

  const [sedesAgregadas, setSedesAgregadas] = useState([]);
  const [rolesAgregados, setRolesAgregados] = useState([]);
  const [atencionesAgregadas, setAtencionesAgregadas] = useState([]);
  const [serviciosAgregados, setServiciosAgregados] = useState([]);
  const [permisosAgregados, setPermisosAgregados] = useState(values.user_permissions);
  const [usuarioExiste, setUsuarioExiste] = useState(false);
  const [usuario, setUsuario] = useState({});

  const buttonAddClass =
    "inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue";
  const buttonRemoveClass =
    "inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue";

    useEffect(() => {
      getSedesUsuarios(token).then((data) => {
        setSedes(data)
      });
    }, [token]);
  
    useEffect(() => {
      getTipoPuestoUsuarios(token).then((data) => {
        setPuestos(data)
      });
    }, [token]);
  
    useEffect(() => {
      getTipoProfesionalUsuarios(token).then((data) => {
        setProfesionales(data)
      });
    }, [token]);
  
    useEffect(() => {
      getRolesUsuarios(token).then((data) => {
        setRoles(data)
      });
    }, [token]);
  
    useEffect(() => {
      getPermisos(token).then((data) => {
        setPermisos(data)
      });
    }, [token]);
  
    useEffect(() => {
      getAtenciones(token).then((data) => {
        setAtenciones(data);
      });
    }, [token]);
  
    useEffect(() => {
      getServicios(token).then((data) => {
        setServicios(data);
      });
    }, [token]);
  
    useEffect(() => {
      getRolesVista(token).then((data) => {
        setRolesVista(data)
      });
    }, [token]);

    
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const componentTable = (titulo, titulo2, body) => {
    return <div className="sm:col-span-3 col-span-6">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
        <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
          {
            (titulo2 === "") ? "" :
              <h3 className="text-sm leading-2 font-medium text-gray-900 p-1 ml-3">
                {titulo2}
              </h3>
          }
          <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
            <thead className="bg-maivi-gray-5">
              <tr>
                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                  {titulo}
                </th>
                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                  Agregar
                </th>
              </tr>
            </thead>
            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
              {body}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }

  return (
    <form autoComplete="off">
      <div className="lg:grid-cols-2 sm:grid md:grid lg:flex sm:grid-cols-1 sm:gap-3 justify-center items-start -mt-32">
        <div className="shadow-xl sm:rounded-md p-6 bg-maivi-secondaryWhite mb-5">
          <div className="flex flex-row justify-between">
            <h1>Crear usuario</h1>
            <Return ruta="/seguridad/usuarios/" />
          </div>
          <Divider />
          <div className="grid grid-cols-6 gap-4">
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="username"
                value={values.username}
                onChange={handleChange}
                label="*Usuario"
                validacion={true}
                onBlur={() => {
                  if (values.username.length > 2) {
                    getUsuario(values.username, token).then((data) => {
                      if (data?.count > 0) {
                        setUsuarioExiste(true);
                        setUsuario(data?.results[0]);
                      }
                    });
                  }
                }}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                label="*Nombre"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="lastName"
                id="lastName"
                value={values.lastName}
                onChange={handleChange}
                label="*Apellido"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="codigo_area"
                value={values.codigo_area}
                onChange={handleChange}
                label="*Codigo area"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="telefono"
                value={values.telefono}
                onChange={handleChange}
                label="*Teléfono"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                label="*Correo Electrónico"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-3 col-span-6">
              <InputSiavi
                type="text"
                name="codigo_empleado"
                value={values.codigo_empleado}
                onChange={handleChange}
                label="Código empleado"
                validacion={true}
              />
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2  col-span-6 flex text-center flex-col">
              <Label text="Usuario interno" />
              <div className="mt-1">
                <Toggle
                  enabled={values.usuarioInterno}
                  name="usuarioInterno"
                />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Inactivo/Activo" />
              <div className="mt-1">
                <Toggle enabled={values.activo} name="activo" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6  flex text-center flex-col">
              <Label text="Bloqueado/desbloqueado" />
              <div className="mt-1">
                <Toggle enabled={values.estado} name="estado" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Personal contratado" />
              <div className="mt-1">
                <Toggle enabled={values.isStaff} name="isStaff" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Super usuario" />
              <div className="mt-1">
                <Toggle enabled={values.superUsuario} name="superUsuario" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Habilitado para turno" />
              <div className="mt-1">
                <Toggle
                  enabled={values.habilitadoTurno}
                  name="habilitadoTurno"
                />
                <ErrorMessage
                  name="habilitadoTurno"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
            </div>
            {values.usuarioInterno ? (
              <React.Fragment>
                <div className="sm:col-span-3 col-span-6 ">
                <SelectSiavi
                    name="sede"
                    value={values.sede}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir sede"
                    validacion={true}
                    label="Sede principal"
                    list={sedes?.results?.map((item) => {
                      return (
                        <option key={item.sede} value={item.id}>
                          {item.sede}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                <SelectSiavi
                    name="tipoPuesto"
                    value={values.tipoPuesto}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir puesto"
                    validacion={true}
                    label="Tipo de puesto"
                    list={puestos?.results?.map((item) => {
                      return (
                        <option key={item.puesto} value={item.id}>
                          {item.puesto}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                <SelectSiavi
                    name="tipoProfesion"
                    value={values.tipoProfesion}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir profesión"
                    validacion={true}
                    label="Tipo de profesión"
                    list={profesionales?.results?.map((item) => {
                      return (
                        <option key={item.profesion} value={item.id}>
                          {item.profesion}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <InputSiavi
                    name="numeroColegiado"
                    value={values.numeroColegiado}
                    onChange={handleChange}
                    label="Número de colegiado"
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                <SelectSiavi
                    name="rol_vista"
                    value={values.rol_vista}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir rol vista"
                    validacion={true}
                    label="Rol vista"
                    list={rolesVista?.results?.map((item) => {
                      return (
                        <option
                          key={item.nombre}
                          value={item.id}
                        >
                          {item?.nombre}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6 flex items-end">
            <ButtonSiavi
              type="button"
              onClick={() => {
                values.otra_sede = [];
                values.atenciones =[];
                values.servicios =[];
                
                sedesAgregadas.forEach(sede => {
                  values.otra_sede.push(sede.id);
                });
                atencionesAgregadas.forEach(a => {
                  values.atenciones.push(a.id);
                });
                serviciosAgregados.forEach(a => {
                  values.servicios.push(a.id);
                });
                values.groups = rolesAgregados;
                values.user_permissions = permisosAgregados;
                handleSubmit();
              }}
              disabled={isSubmitting}
              text="Crear"
            />
          </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
          <div className="mt-6">
            {hasError && <Alert type="error" mensaje={mensaje} />}
            {success && <Alert type="success" mensaje={mensaje} />}
          </div>
          {isSubmitting && <Spinner />}
        </div>
        {!values.usuarioInterno ? (
          ""
        ) : (
          <div className="shadow-xl sm:rounded-md px-2 py-2 bg-maivi-secondaryWhite">
            <Tab.Group>
              <Tab.List className="grid grid-cols-5 gap-6 rounded-md bg-blue-50 p-1">
                {menu.map((item) => {
                  return (
                    <Tab
                      key={item.name}
                      disabled={item.block}
                      className={({ selected }) =>
                        classNames(
                          "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                          "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                          selected
                            ? "bg-white shadow"
                            : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                        )
                      }
                    >
                      {item.name}
                    </Tab>
                  );
                })}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  {componentTable("Sedes", "",
                    sedes?.results?.map((item) => (
                      <tr
                        key={item.id + item.sede}
                        id={item.sede}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.sede}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let element = document.getElementById(
                                  item.sede
                                );
                                element.classList.add("bg-green-50");
                                setSedesAgregadas([
                                  ...sedesAgregadas,
                                  item,
                                ]);
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {componentTable("Sede", "Sedes agregadas",
                    sedesAgregadas?.map((item) => (
                      <tr
                        key={item.id + item.sede}
                        className="bg-green-50"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.sede}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let old = sedesAgregadas.filter(
                                  (x) => x.id !== item.id
                                );
                                setSedesAgregadas([...old]);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  {componentTable("Rol", "",
                    roles?.results?.map((item) => (
                      <tr
                        key={item.id + item.name}
                        id={item.name}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.name}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let element = document.getElementById(
                                  item.name
                                );
                                element.classList.add("bg-green-50");
                                setRolesAgregados([
                                  ...rolesAgregados,
                                  item,
                                ]);
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {componentTable("Rol", "Roles agregados",
                    rolesAgregados?.map((item) => (
                      <tr key={item.id} className="bg-green-50">
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.name}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let old = rolesAgregados.filter(
                                  (x) => x.id !== item.id
                                );
                                setRolesAgregados([...old]);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  {componentTable("Permisos", "",
                    permisos?.results?.map((item) => (
                      <tr
                        key={item.id + item.name}
                        id={item.id}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.name}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let element = document.getElementById(
                                  item.id
                                );
                                element.classList.add("bg-green-50");
                                setPermisosAgregados([
                                  ...permisosAgregados,
                                  item,
                                ]);
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {componentTable("Permiso", "Permisos agregados",
                    permisosAgregados?.map((item) => (
                      <tr
                        key={item.id + item.name}
                        className="bg-green-50"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.name}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let old = permisosAgregados.filter(
                                  (x) => x.id !== item.id
                                );
                                setPermisosAgregados([...old]);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  {componentTable("Atenciones", "",
                    atenciones?.results?.map((item) => (
                      <tr
                        key={item.id + item.nombre}
                        id={item.nombre}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.nombre}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let element = document.getElementById(
                                  item.nombre
                                );
                                element.classList.add("bg-green-50");
                              
                                setAtencionesAgregadas([
                                  ...atencionesAgregadas,
                                  item,
                                ]);
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {componentTable("Atenciones", "Atenciones agregadas",
                    atencionesAgregadas?.map((item) => (
                      <tr
                        key={item.nombre + item.id}
                        className="bg-green-50"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.nombre}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let old = atencionesAgregadas.filter(
                                  (x) => x.id !== item.id
                                );
                                setAtencionesAgregadas([...old]);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  {componentTable("Servicio", "",
                    servicios?.results?.map((item) => (
                      <tr
                        key={item.id + item.nombre}
                        id={item.nombre}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.nombre}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let element = document.getElementById(
                                  item.nombre
                                );
                                element.classList.add("bg-green-50");
                              
                                setServiciosAgregados([
                                  ...serviciosAgregados,
                                  item,
                                ]);
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {componentTable("Servicio", "Servicios agregadas",
                    serviciosAgregados?.map((item) => (
                      <tr
                        key={item.nombre + item.id}
                        className="bg-green-50"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            {item.nombre}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-primaryFont">
                            <button
                              type="button"
                              onClick={() => {
                                let old = serviciosAgregados.filter(
                                  (x) => x.id !== item.id
                                );
                                setServiciosAgregados([...old]);
                              }}
                              className={buttonRemoveClass}
                            >
                              Borrar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
        {usuarioExiste && (
          <ModalGenerico
            title={`Ya se encuentra registrado ese nombre de usuario, ¿desea consultarlo?`}
            open={usuarioExiste}
            closeModal={() => { }}
            cerrar={() => {
              setUsuarioExiste(false);
              setFieldValue("username", "")
            }}
            children={
              <div className="py-3 text-center">
                <ButtonsConfirmacion
                  aceptar={() => {
                    window.location = `/seguridad/usuarios/editar/${usuario.id}`;
                  }}
                  cancelar={() => {
                    setUsuarioExiste(false);
                    setFieldValue("username", "");
                  }}
                />
              </div>
            }
          />
        )}


      </div>
    </form>
  );
};
