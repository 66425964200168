
const {
    REACT_APP_API_URL
} = process.env;

export const diligenciasUrgentes = async (urlPage,search,order,filtros, token) => {
    try {

        let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/?search=${search.value}&ordering=${order}&${filtros}`
          : `${urlPage}&search=${search.value}&ordering=${order}&${filtros}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getProfesionales = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getTipoDiligencias = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipodiligencias/?estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpediente = async (expediente,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expediente/${expediente}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};