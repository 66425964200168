import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
    getTipoProcesoSecundarioEstadoProcesoSecundario,
    postEstadoProcesoSecundario,
    putEstadoProcesoSecundario,
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Motivo de Cierre es requerido")
        .max(255, "Máximo 100 caracteres"),
    descripcion: Yup.string()
        .required("Descripción es requerida")
        .max(255, "Máximo 100 caracteres"),
    proceso_secundario: Yup.string()
        .required("Tipo Proceso Secundario es requerida")
        .max(255, "Máximo 100 caracteres"),
});

const useFetchProcesoSecundario = (token) => {
    const [state, setState] = useState({
        proceso_secundario: [],
    });

    useEffect(() => {
        getTipoProcesoSecundarioEstadoProcesoSecundario(token).then((proceso_secundario) => {
            setState({
                proceso_secundario: proceso_secundario,
            });
        });
    }, [token]);

    return state;
};

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { proceso_secundario } = useFetchProcesoSecundario(token);

    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    descripcion: props.editar ? props.value.descripcion : "",
                    habilita_apelacion_especial: props.editar
                        ? props.value.habilita_apelacion_especial
                        : false,
                    proceso_secundario: props.editar ? props.value.proceso_secundario : "",
                    estado: props.editar ? props.value.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putEstadoProcesoSecundario(props.value.id, values, token).then(
                            (data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    props.setReFetch(true);
                                    toast.success("Registro actualizado con éxito...!");
                                }
                            }
                        );
                    } else {
                        await postEstadoProcesoSecundario(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message);
                            } else {
                                props.setReFetch(true);
                                toast.success("Registro creado con éxito...!");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Estado Proceso Secundario"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="" />
                                <InputSiavi
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    value={values.descripcion}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Descripción"
                                />
                            </div>
                            <div className="col-span-6 mt-4">
                                <Label text="*Tipo Proceso Secundario" />
                                <div className="mt-1">
                                    <select
                                        name="proceso_secundario"
                                        value={values.proceso_secundario}
                                        onChange={handleChange}
                                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                    >
                                        <option value="">
                                            Elegir proceso secundario
                                        </option>
                                        {proceso_secundario?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ErrorMessage
                                        name="proceso_secundario"
                                        component="span"
                                        className="text-sm text-maivi-secondaryRed"
                                    />
                                </div>
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="Habilita apelación especial" />
                                <Toggle
                                    enabled={values.habilita_apelacion_especial}
                                    name="habilita_apelacion_especial"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
