export const clases = {
    tr: "hover:bg-gray-100 cursor-pointer",
    th: "px-2 py-2 text-left font-medium text-maivi-primaryFont tracking-wider text-xs",
    td: "px-2 py-2 row-td text-maivi-primaryFont text-xs",
    select: "mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full sm:text-sm border-maivi-gray-10 rounded bg-white text-maivi-primaryFont",
    trashIcon: "hover:bg-red-400 items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white focus:ring-maivi-primaryLightBlue tooltip",
    addIcon: "hover:bg-maivi-primaryBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white focus:ring-maivi-primaryLightBlue tooltip",
    addIcon2: "hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white focus:ring-maivi-primaryLightBlue tooltip",
    estado:"px-2 inline-flex text-xs leading-5 font-semibold rounded-full ",
    estadoActivo:"bg-green-100 text-green-800",
    estadoInactivo:"bg-red-100 text-red-800"
}