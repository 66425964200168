import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getFormularioCasoPersona = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/formulariocasopersona/?${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormularioCasoPersona = async (data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormularioCasoPersona = async (id, data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormularioCasoPersona = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/modalidad/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/?page_size=200&consentimiento_informado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasoPersonaConsentimientos = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersonaconsentimiento/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${data.idPlan}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCasoPersonaConsentimientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiagnosticoPS = async (idExpediente, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientediagnosticops/?expediente=${idExpediente}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postDiagnosticoPS = async (data, token) => {
    try {
        let body = {
            historia_caso: data.historia_caso,
            observacion_clinica: data.observacion_clinica,
            actividades_realizadas: data.actividades_realizadas,
            seguimiento: data.seguimiento,
            metodologia_intervencion: data.metodologia_intervencion,
            indicadores_recuperacion: data.indicadores_recuperacion,
            conclusiones: data.conclusiones,
            fecha_ultima_modificacion: data.fecha_ultima_modificacion,
            usuario_ultima_modificacion: data.usuario_ultima_modificacion,
            caso: data.caso,
            expediente: data.expediente,
            servicio: data.servicio,
            persona: data.persona
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientediagnosticops/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDiagnosticoPS = async (id, data, token) => {
    try {
        let body = {
            historia_caso: data.historia_caso,
            observacion_clinica: data.observacion_clinica,
            actividades_realizadas: data.actividades_realizadas,
            seguimiento: data.seguimiento,
            metodologia_intervencion: data.metodologia_intervencion,
            indicadores_recuperacion: data.indicadores_recuperacion,
            conclusiones: data.conclusiones,
            fecha_ultima_modificacion: data.fecha_ultima_modificacion,
            usuario_ultima_modificacion: data.usuario_ultima_modificacion,
            caso: data.caso,
            expediente: data.expediente,
            servicio: data.servicio,
            persona: data.persona
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientediagnosticops/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDiagnosticoPS = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientediagnosticops/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEvolucionPS = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedienteevolucionpsicologica/?expediente_diagnostico=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvolucionPS = async (data, token) => {
    try {
        let body = {
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            fecha: data.fecha,
            numero_sesion: data.numero_sesion,
            temas_relevantes_abordados: data.temas_relevantes_abordados,
            avances: data.avances,
            observaciones: data.observaciones
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/expedienteevolucionpsicologica/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEvolucionPS = async (id, data, token) => {
    try {
        let body = {
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            fecha: data.fecha,
            numero_sesion: data.numero_sesion,
            temas_relevantes_abordados: data.temas_relevantes_abordados,
            avances: data.avances,
            observaciones: data.observaciones
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/expedienteevolucionpsicologica/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteEvolucionPS = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedienteevolucionpsicologica/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIndicadoresAvance = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedienteindicadresavanceps/?expediente_diagnostico=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postIndicadoresAvance = async (data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            indicador_positivo: data.indicador_positivo,
            indicador_negativo: data.indicador_negativo,
            observaciones: data.observaciones,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedienteindicadresavanceps/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putIndicadoresAvance = async (id, data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            indicador_positivo: data.indicador_positivo,
            indicador_negativo: data.indicador_negativo,
            observaciones: data.observaciones,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedienteindicadresavanceps/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteIndicadoresAvance = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedienteindicadresavanceps/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMedidasReparacion = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientemedidasreparacionps/?expediente_diagnostico=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMedidasReparacion = async (data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            victima_salud_mental: data.victima_salud_mental,
            proyecto_vida: data.proyecto_vida,
            colateral_salud_mental: data.colateral_salud_mental,
            colateral_proyecto_vida: data.colateral_proyecto_vida,
            psicosocial: data.psicosocial,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientemedidasreparacionps/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putMedidasReparacion = async (id, data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            victima_salud_mental: data.victima_salud_mental,
            proyecto_vida: data.proyecto_vida,
            colateral_salud_mental: data.colateral_salud_mental,
            colateral_proyecto_vida: data.colateral_proyecto_vida,
            psicosocial: data.psicosocial,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientemedidasreparacionps/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteMedidasReparacion = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientemedidasreparacionps/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
