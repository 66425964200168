import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { putAcciones, getProfesionales } from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../../components/common/Spinner";
import toast from 'react-hot-toast';
//import moment from "moment";

export const Gestiones = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const profesional = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const animatedComponents = makeAnimated();
    const [profesionales, setProfesionales] = useState([]);
    const [cargando, setCargando] = useState(false);
    const optionsProfesionales = [];
    const [accion, setAccion] = useState("");
    const [profesionalSelect, setProfesional] = useState(0);

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setProfesionales(data);
        });
    }, [token]);

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
        };
        optionsProfesionales.push(reg);
    });

    const changeSelectProfesionales = (e) => {
        setProfesional(e.value);
    };


    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                let body = {};
                if (accion === "aceptar") {
                    body = {
                        autorizado: true,
                        completado: true,
                        gestionado: true,
                        profesional_realiza: profesional,
                        profesional_autoriza: profesional,
                        accion_seguimiento : props?.registro?.accion_seguimiento?.id,
                        fecha_requerido : props?.registro?.fecha_requerido,
                    }
                } else {
                    body = {
                        autorizado: false,
                        estado: false,
                        profesional_autoriza: profesional,
                        accion_seguimiento : props?.registro?.accion_seguimiento?.id,
                        fecha_requerido : props?.registro?.fecha_requerido,
                    }
                }

              
                    await putAcciones(props.registro.id, body, token).then((data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success('accion completada con éxito');
                            props.setGestionar(false);
                            props.setRefetch(true);
                            setTimeout(() => {
                                props.setRefetch(false);
                            }, 300);
                        }
                    })
                        .catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 410) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error('Error interno comuniquese con el administrador');
                            }
                        });
              
            }}
        >
            {({ isSubmitting, handleSubmit }) => (
                <div className="mx-5 pb-20">
                    {
                        !props.reasignar &&
                        <div className="flex flex-row">
                            <ButtonSiavi
                                type="submit"
                                disabled={isSubmitting}
                                text={"Autorizar"}
                                onClick={() => {
                                    setAccion("aceptar")
                                    handleSubmit();
                                }}
                            />
                            <ButtonSiavi
                                type="submit"
                                disabled={isSubmitting}
                                text="Rechazar"
                                className="mx-2"
                                onClick={() => {
                                    setAccion("rechazar");
                                    handleSubmit();
                                }}
                            />
                            <ButtonSiavi
                                type="submit"
                                disabled={isSubmitting}
                                text="Cancelar"
                                onClick={() => {
                                    props.setGestionar(false);
                                }}
                            />
                        </div>
                    }
                    {
                        props.reasignar &&
                        <div>
                            <Select
                                isMulti={false}
                                placeholder="Seleccionar"
                                components={animatedComponents}
                                name="servicios"
                                className="basic-multi-select text-xs text-maivi-primaryBlue w-full mb-4"
                                classNamePrefix="seleccione"
                                options={optionsProfesionales}
                                onChange={changeSelectProfesionales}
                                maxMenuHeight={140}
                            />
                            <ButtonSiavi
                                type="submit"
                                disabled={isSubmitting}
                                text="Reasignar"
                                onClick={async () => {
                                    let body = {};
                                    body = {
                                        profesional_asignado: profesionalSelect,
                                        accion_seguimiento : props?.registro?.accion_seguimiento?.id,
                                        fecha_requerido : props?.registro?.fecha_requerido,
                                    }
                                    if(props.registro.es_vencida === null || props.registro.es_vencida === true){
                                        toast.error("No se puede reasignar una accion vencida");
                                    } else {
                                        setCargando(true);
                                        await putAcciones(props.registro.id, body, token).then((data) => {
                                            if (data?.status >= 200 && data?.status <= 210) {
                                                toast.success('accion completada con éxito');
                                                props.setGestionar(false);
                                                props.setRefetch(true);
                                                setCargando(false);
                                                setTimeout(() => {
                                                    props.setRefetch(false);
                                                }, 300);
                                            }
                                        })
                                            .catch(function (error) {
                                                if (error.response.status >= 400 && error.response.status <= 410) {
                                                    toast.error(error.response.data.message);
                                                    setCargando(false);
                                                } else if (error.response.status >= 500) {
                                                    setCargando(false);
                                                    toast.error('Error interno comuniquese con el administrador');
                                                }
                                            });
                                    }
                               
                                }}
                            />
                          {
                            cargando &&
                               <div className="mt-2">
                               <Spinner />
                           </div>
                          }
                        </div>
                    }
                    <div className="mt-3 mb-3">
                        {isSubmitting && <Spinner />}
                    </div>
                </div>
            )}
        </Formik>
    );
};
