import { types } from "../types/types"

export const savePersona = (data) => {
    return {
        type: types.savePersona,
        payload: data
    }
}

export const cleanPersona = () => {
    return {
        type: types.cleanPersona,
        payload: ''
    }
}