import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getGestionInstituciones = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/gestioninstitucion/` : urlPage;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMateriaGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/materia/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPoblaciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/poblacion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipiosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstitucionGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionInstitucion = async (id,token ) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postGestionInstitucionServicios = async (idServicio,idGestion,token ) => {
    try {
        let body = {
            institucion : idGestion,
            servicio: idServicio
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/gestioninstitucionservicio/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        
        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionInstitucionServicios = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucionservicio/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionInstitucionMateria = async (idMateria,idGestion,token ) => {
    try {
        let body = {
            materia : idMateria,
            gestion_institucion: idGestion
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/gestioninstitucionmateria/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPoblaciones = async (idPoblacion,idGestion,token ) => {
    try {
        let body = {
            poblacion : idPoblacion,
            gestion_institucion: idGestion
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/gestioninstitucionpoblacion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deletePoblaciones = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucionpoblacion/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGestionInstitucionMateria = async (id,token ) => {
    try {
        let body = {
            id : id,
        }

        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucionmateria/${id}/`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGestionInstitucion = async (data,token) => {

    let idsServicios =[];
    let idsMaterias =[];
    let idsPoblaciones =[];

    data.serviciosAgregados?.forEach((item) =>{
        idsServicios.push(item.value);
    });

    data.materiasAgregadas?.forEach((item) =>{
        idsMaterias.push(item.value);
    });

    data.poblacionesAgregadas?.forEach((item) =>{
        idsPoblaciones.push(item.value);
    });

    try {
        let body = {
            nombre_fiscalia: (data.nombre_fiscalia === "") ? null : data.nombre_fiscalia,
            oficina_instancia: (data.oficina_instancia === "") ? null : data.oficina_instancia,
            departamento: Number(data.departamento),
            municipio: Number(data.municipio),
            email_contacto: (data.email_contacto === "") ? null : data.email_contacto,
            telefono: (data.telefono === "") ? null : data.telefono,
            direccion: (data.direccion === "") ? null : data.direccion,
            horario: (data.horario) === "" ? null : data.horario,
            jurisdiccion: (data.jurisdiccion === "") ? null : data.jurisdiccion,
            latitud: (data.latitud === "") ? null : data.latitud,
            longitud:( data.longitud === "") ? null : data.longitud,
            url_maps: (data.url_maps === "") ? null : data.url_maps,
            url: (data.url === "") ? null : data.url,
            estado: data.estado,
            contacto_principal: (data.contacto_principal === "") ? null : data.contacto_principal,
            otros_contactos: (data.otros_contactos === "") ? null : data.otros_contactos,
            observaciones:data.observaciones,
            institucion: Number(data.institucion),
            servicios: idsServicios,
            materias: idsMaterias,
            poblaciones: idsPoblaciones,
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/gestioninstitucion/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGestionInstitucion = async (id,data,token) => {
    try {
        let body = {
            nombre_fiscalia: (data.nombre_fiscalia === "") ? null : data.nombre_fiscalia,
            oficina_instancia: (data.oficina_instancia === "") ? null : data.oficina_instancia,
            departamento: Number(data.departamento),
            municipio: Number(data.municipio),
            email_contacto: (data.email_contacto === "") ? null : data.email_contacto,
            telefono: (data.telefono === "") ? null : data.telefono,
            direccion: (data.direccion === "") ? null : data.direccion,
            horario: (data.horario) === "" ? null : data.horario,
            jurisdiccion: (data.jurisdiccion === "") ? null : data.jurisdiccion,
            latitud: (data.latitud === "") ? null : data.latitud,
            longitud:( data.longitud === "") ? null : data.longitud,
            url_maps: (data.url_maps === "") ? null : data.url_maps,
            url: (data.url === "") ? null : data.url,
            estado: data.estado,
            contacto_principal: (data.contacto_principal === "") ? null : data.contacto_principal,
            otros_contactos: (data.otros_contactos === "") ? null : data.otros_contactos,
            observaciones:data.observaciones,
            institucion: Number(data.institucion),
        }

        let Bodyresult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/gestioninstitucion/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};
