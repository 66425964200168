import axios from "axios"

const {
    REACT_APP_API_URL
} = process.env;

export const getPersonas = async (idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServiciosInternos = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true&atencion_victimologica=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServiciosExternos = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async (filtros, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&${filtros}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postReferencia = async (values, token) => {
    try {
        let body = {
            es_nna: values.es_nna,
            solicitud_referencia: values.solicitud_referencia,
            referencia_interna: (values.tipo_referencia === "true") ? true : false,
            descripcion_referencia: "",
            seguimiento_completado: false,
            gestion_institucion: (values.institucion_refiere === "") ? null : values.institucion_refiere,
            persona: (values.persona === "") ? null : values.persona,
            representante: (values.representante === "") ? null : values.representante,
            caso: values.caso,
            orientacion: values.orientacion,
            motivo_referencia: values.motivos_referencia,
            asistencia_atencion_especifica: values.asistencia_atencion_especifica,
            expediente: (values.expediente === "") ? null : values.expediente,
            referencia_ts: values.referencia_trabajo_social,
            expediente_ts: (values.expediente_ts === "") ? null : values.expediente_ts,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/referencia/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putReferencia = async (id, values, token) => {
    try {
        let body = {
            es_nna: values.es_nna,
            solicitud_referencia: values.solicitud_referencia,
            referencia_interna: (values.tipo_referencia === "true") ? true : false,
            descripcion_referencia: "",
            seguimiento_completado: false,
            gestion_institucion: (values.institucion_refiere === "") ? null : values.institucion_refiere,
            persona: (values.persona === "") ? null : values.persona,
            representante: (values.representante === "") ? null : values.representante,
            caso: values.caso,
            orientacion: values.orientacion,
            motivo_referencia: values.motivos_referencia,
            asistencia_atencion_especifica: values.asistencia_atencion_especifica,
            expediente: (values.expediente === "") ? null : values.expediente,
            referencia_ts: values.referencia_trabajo_social,
            expediente_ts: (values.expediente_ts === "") ? null : values.expediente_ts,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/referencia/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEstadoReferencia = async (id, token) => {
    try {
        let body = {
            estado: false,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/referencia/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReferenciaCreada = async (idCaso, idExpediente, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/referencia/?caso=${idCaso}&expediente__servicio=${idExpediente}&ordering=-fecha`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postReferenciaInterna = async (data, idReferencia, token) => {
    try {
        let body = {
            especificaciones: data.especificaciones,
            requiere_accion: false,
            referencia: idReferencia,
            servicio_interno: (data.valorServicio === "") ? null : data.valorServicio,
            atencion: (data.valorAtencion === "") ? null : data.valorAtencion,
            expediente: null,
            profesional_asignado: (data.profesional_asignado === "") ? null : data.profesional_asignado
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/referenciaservicioatencionesinternas/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postReferenciaExterna = async (data, idReferencia, token) => {
    try {
        let body = {
            area_institucion_refiere: data.area_institucion,
            especificaciones: data.especificaciones_externas,
            referencia_medica: data.referencia_medica,
            impresion_clinica: data.impresion_clinica,
            resumen_clinico: data.resumen_clinico,
            presion_arterial: data.presion_arterial,
            temperatura: data.temperatura,
            frecuencia_cardiaca: data.frecuencia_cardiaca,
            tratamiento_empleado: data.tratamiento_empleado,
            condiciones_de_traslado: data.condiciones_de_traslado,
            nombre_persona_recibe: "",
            fecha_recepcion: null,
            frecuencia_respiratoria: data.frecuencia_respiratoria,
            referencia: idReferencia,
            servicio_externo: data.valorServicio
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/referenciaservicioexterno/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReferencias = async (idCaso, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/referencia/?caso=${idCaso}&ordering=-fecha`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipiosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getInstitucionGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&institucion__tiene_materia=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstitucion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPoblaciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/poblacion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSeguimiento = async (values, token) => {
    try {
        let body = {
            seguimiento_a_victima: values.seguimiento_a_victima,
            objetivo_seguimiento: values.objetivo_seguimiento,
            retroalimentacion_externa: values.retroalimentacion_externa,
            estado_atencion: values.estado_atencion,
            acciones_coordinadas_seguimiento: values.acciones_coordinadas_seguimiento,
            acciones_inmediatas: values.acciones_inmediatas,
            comentarios_profesional: values.comentarios_profesional,
            referencia: values.referencia,
            fecha_seguimiento: values.fecha_seguimiento,
            especificaciones_modalidad: values.especificaciones_modalidad,
            // acciones_inmediatas: values.acciones_inmediatas,
            profesional_externo: values.profesional_externo,
            profesional_seguimiento: (values.profesional_seguimiento === "") ? null : values.profesional_seguimiento,
            modalidad: (values.modalidad === "") ? null : values.modalidad
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/seguimientoreferenciaexterna/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSeguimiento = async (id, values, token) => {
    try {
        let body = {
            seguimiento_a_victima: values.seguimiento_a_victima,
            objetivo_seguimiento: values.objetivo_seguimiento,
            retroalimentacion_externa: values.retroalimentacion_externa,
            estado_atencion: values.estado_atencion,
            acciones_coordinadas_seguimiento: values.acciones_coordinadas_seguimiento,
            acciones_inmediatas: values.acciones_inmediatas,
            comentarios_profesional: values.comentarios_profesional,
            referencia: values.referencia,
            fecha_seguimiento: values.fecha_seguimiento,
            especificaciones_modalidad: values.especificaciones_modalidad,
            // acciones_inmediatas: values.acciones_inmediatas,
            profesional_externo: values.profesional_externo,
            profesional_seguimiento: (values.profesional_seguimiento === "") ? null : values.profesional_seguimiento,
            modalidad: (values.modalidad === "") ? null : values.modalidad
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/seguimientoreferenciaexterna/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getProfesionales = async (servicio, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeguimientos = async (referencia, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/seguimientoreferenciaexterna/?page_size=1000&referencia=${referencia}&seguimiento_a_victima=false`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeguimientosVictima = async (referencia, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/seguimientoreferenciaexterna/?page_size=1000&referencia=${referencia}&seguimiento_a_victima=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/modalidad/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoAccionesSeguimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&accion=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoSeguimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&seguimiento=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoSeguimientosAudiencia = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&accion=false&seguimiento=true&es_audiencia=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntas = async (idFormulario, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/?formulario=${idFormulario}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadoProceso = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/estadoproceso/?page_size=200&estado=true&es_audiencia=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAcciones = async (urlPage,idExpediente, filters,  token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?expediente=${idExpediente}&es_accion=true&es_seguimiento=false&ordering=-id&${filters}`
        : `${urlPage}&expediente=${idExpediente}&es_accion=true&es_seguimiento=false&${filters}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesNombramientos = async (idExpediente, idNombramiento, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?expediente=${idExpediente}&es_accion=true&es_seguimiento=false&nombramiento=${idNombramiento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSeguimientosAcciones = async (urlPage,idExpediente, token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?expediente=${idExpediente}&es_accion=false&es_seguimiento=true`
        : `${urlPage}&expediente=${idExpediente}&es_accion=false&es_seguimiento=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAcciones = async (data, token) => {
    try {
        let body = {
            seguimiento: data.seguimiento,
            es_nna: data.es_nna,
            tipo_audiencia: (data.tipo_audiencia === "") ? null : data.tipo_audiencia,
            fecha_accion: (data.fecha_accion === "") ? null : data.fecha_accion,
            fecha_asigna: (data.fecha_asigna === "") ? null : data.fecha_asigna,
            fecha_hora_planificada_inicia: (data.fecha_hora_planificada_inicia === "") ? null : data.fecha_hora_planificada_inicia,
            fecha_hora_planificada_fin: (data.fecha_hora_planificada_fin === "") ? null : data.fecha_hora_planificada_fin,
            requiere_autorizacion: data.requiere_autorizacion || false,
            fecha_autoriza: (data.fecha_autoriza === "") ? null : data.fecha_autoriza,
            autorizado: data.autorizado,
            fecha_completado: (data.fecha_completado === "") ? null : data.fecha_completado,
            estado: data.estado,
            accion_planificada: data.accion_planificada,
            asignar_accion: data.asignar_accion,
            es_accion: data.es_accion,
            es_seguimiento: data.es_seguimiento,
            es_audiencia: data.es_audiencia,
            completada: data.completada,
            requiere_nombramiento: data.requiere_nombramiento,
            observaciones: (data.observaciones === "") ? null : data.observaciones,
            detalle_lugar: data.detalle_lugar,
            especificaciones_modalidad: data.especificaciones_modalidad,
            tipo: (data.tipo === "") ? null : data.tipo,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            persona: (data.persona === 0) ? null : data.persona,
            victima: data.victima,
            querellante: data.querellante,
            modalidad: (data.modalidad === "") ? null : data.modalidad,
            referencia: (data.referencia === "") ? null : data.referencia,
            departamento: (data.departamento === "") ? null : data.departamento,
            municipio: (data.municipio === "") ? null : data.municipio,
            accion_seguimiento: (data.accion_seguimiento === "") ? null : data.accion_seguimiento,
            gestion_institucion: (data.gestion_institucion === "") ? null : data.gestion_institucion,
            institucion: (data.institucion === "") ? null : data.institucion,
            profesional_asignado: (data.profesional_asignado === "") ? null : data.profesional_asignado,
            profesional_autoriza: (data.profesional_autoriza === "") ? null : data.profesional_autoriza,
            profesional_realiza: (data.profesional_realiza === "") ? null : data.profesional_realiza,
            nombramiento: data.nombramiento,
            profesional_crea: data.profesional_crea,
            sede_accion: data.sede_accion,
            accion_servicio_solicitado: data?.seguimiento_accion_solicitada,
            fecha_requerido : (data.fecha_requerido === "") ? null : data.fecha_requerido,
            requiere_recurso: data?.requiere_recurso,
            recurso: data?.recurso,
            recurso_disponibilidad : data?.recurso_disponibilidad,
            gestionar : data?.gestionar || false
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAcciones = async (id, data, token) => {
    try {
        let body = {
            seguimiento: data.seguimiento,
            es_nna: data.es_nna,
            tipo_audiencia: (data?.tipo_audiencia === "") ? null : data?.tipo_audiencia,
            fecha_accion: (data?.fecha_accion === "") ? null : data?.fecha_accion,
            fecha_asigna: (data?.fecha_asigna === "") ? null : data?.fecha_asigna,
            fecha_hora_planificada_inicia: (data?.fecha_hora_planificada_inicia === "") ? null : data?.fecha_hora_planificada_inicia,
            fecha_hora_planificada_fin: (data?.fecha_hora_planificada_fin === "") ? null : data?.fecha_hora_planificada_fin,
            requiere_autorizacion: false,
            fecha_autoriza: (data?.fecha_autoriza === "") ? null : data?.fecha_autoriza,
            autorizado: data?.autorizado,
            fecha_completado: (data?.fecha_completado === "") ? null : data?.fecha_completado,
            estado: data?.estado,
            accion_planificada: data?.accion_planificada,
            asignar_accion: data?.asignar_accion,
            es_accion: data?.es_accion,
            es_seguimiento: data?.es_seguimiento,
            es_audiencia: data?.es_audiencia,
            completada: data?.completada,
            requiere_nombramiento: data?.requiere_nombramiento,
            observaciones: (data.observaciones === "") ? null : data.observaciones,
            detalle_lugar: data.detalle_lugar,
            especificaciones_modalidad: data.especificaciones_modalidad,
            tipo: (data.tipo === "") ? null : data.tipo,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            persona: (data.persona === 0) ? null : data.persona,
            victima: data.victima,
            querellante: data.querellante,
            modalidad: (data.modalidad === "") ? null : data.modalidad,
            referencia: (data.referencia === "") ? null : data.referencia,
            departamento: (data.departamento === "") ? null : data.departamento,
            municipio: (data.municipio === "") ? null : data.municipio,
            accion_seguimiento: (data.accion_seguimiento === "") ? null : data.accion_seguimiento,
            gestion_institucion: (data.gestion_institucion === "") ? null : data.gestion_institucion,
            profesional_asignado: (data.profesional_asignado === "") ? null : data.profesional_asignado,
            profesional_autoriza: (data.profesional_autoriza === "") ? null : data.profesional_autoriza,
            profesional_realiza: (data.profesional_realiza === "") ? null : data.profesional_realiza,
            nombramiento: data.nombramiento,
            profesional_crea: data.profesional_crea,
            sede_accion: data.sede_accion,
            accion_servicio_solicitado: data.seguimiento_accion_solicitada,
            fecha_requerido : (data.fecha_requerido === "") ? null : data.fecha_requerido,
            requiere_recurso: data?.requiere_recurso,
            recurso: data?.recurso,
            recurso_disponibilidad : data?.recurso_disponibilidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAcciones = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSeguimientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/seguimientoreferenciaexterna/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionalesAtenciones = async (atencion, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalatenciones__atencion=${atencion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAccionesRecursos = async (data, token) => {
    try {
        let body = {
            recurso: data.recurso,
            accion: data.accion,
            recurso_disponibilidad: (Number(data.recurso_disponibilidad) === 0) ? null : Number(data.recurso_disponibilidad),
            profesional_autorizado: (Number(data.profesional_autorizado) === 0) ? null : Number(data.profesional_autorizado),
            fecha_requerido: (data.fecha_requerido === "") ? null : data.fecha_requerido,
            fecha_hora_inicio: (data.fecha_hora_inicio === "") ? null : data.fecha_hora_inicio,
            fecha_hora_fin: (data.fecha_hora_fin === "") ? null : data.fecha_hora_fin,
            fecha_autorizado: (data.fecha_autorizado === "") ? null : data.fecha_autorizado,
            estado: data.estado,
            requiere_autorizacion: data.requiere_autorizacion,
            autorizado: data.autorizado,
            recuerso_compartido: data.recuerso_compartido,
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/accionesrecursos/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAccionesRecursos = async (id, data, token) => {
    try {
        let body = {
            recurso: data.recurso,
            accion: data.accion,
            recurso_disponibilidad: (Number(data.recurso_disponibilidad) === 0) ? null : Number(data.recurso_disponibilidad),
            profesional_autorizado: (Number(data.profesional_autorizado) === 0) ? null : Number(data.profesional_autorizado),
            fecha_requerido: (data.fecha_requerido === "") ? null : data.fecha_requerido,
            fecha_hora_inicio: (data.fecha_hora_inicio === "") ? null : data.fecha_hora_inicio,
            fecha_hora_fin: (data.fecha_hora_fin === "") ? null : data.fecha_hora_fin,
            fecha_autorizado: (data.fecha_autorizado === "") ? null : data.fecha_autorizado,
            estado: data.estado,
            requiere_autorizacion: data.requiere_autorizacion,
            autorizado: data.autorizado,
            recuerso_compartido: data.recuerso_compartido,
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/accionesrecursos/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAccionesRecursos = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/accionesrecursos/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesAccionesRecursos = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/accionesrecursos/?accion=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRecursos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/recursos/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRecursosDisponibilidad = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/recursodisponibilidad/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesRecursos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/accionesrecursos/?page_size=1000&accion=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNombramientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/nombramientos/?page_size=1000&accion_seguimiento=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/?page_size=200&consentimiento_informado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getCasoPersonaConsentimientos = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersonaconsentimiento/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientosEditables = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedienteconsentimientoeditable/?expediente=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export const postConsentimientoEditable = async (data, token) => {
    try {
        let body = {
            titulo: data.titulo ,
            consentimiento: data.consentimiento,
            editable: data.editable,
            es_nna: data.es_nna,
            estado: data.estado,
            caso: data.caso,
            expediente: data.expediente,
            consentimiento_informado: data.consentimiento_informado,
            persona_representante: data.persona_representante,
            fecha_ultima_modificacion: data.fecha_ultima_modificacion,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedienteconsentimientoeditable/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putConsentimientoEditable = async (data, token) => {
    try {
        let body = {
            titulo: data.titulo ,
            consentimiento: data.consentimiento,
            editable: data.editable,
            es_nna: data.es_nna,
            estado: data.estado,
            caso: data.caso,
            expediente: data.expediente,
            consentimiento_informado: data.consentimiento_informado,
            persona_representante: data.persona_representante,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedienteconsentimientoeditable/${data.idConsentimiento}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${data.idPlan}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCasoPersonaConsentimientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteConsentimientoEditable = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedienteconsentimientoeditable/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};