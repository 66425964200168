/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getHistoricoFechas, getHistoricoAsignaciones } from "./services";
import { clases } from "../../../helpers/clases";
import TableSiavi from "../../../components/common/TableSiavi";
import Label from "../../../components/common/Label";

export const FormDetalle = (props) => {
    console.log(props)
    const [historicoFechas, setFechas] = useState([]);
    const [historicoAsignaciones, setHistorico] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [cargando1, setCargando2] = useState(true);

    useEffect(() => {
        getHistoricoFechas(props.registro.id, props.token).then((data) => {
            setFechas(data);
            setCargando2(false);
        });
    }, []);

    useEffect(() => {
        setCargando(true);
        getHistoricoAsignaciones(props.registro.id, props.token).then((data) => {
            setHistorico(data);
            setCargando(false);
        });
    }, []);

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-6">
                <div className="bg-white border rounded-md">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Fecha - Hora Planificada
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.fecha_hora_planificada_inicia}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Juzgado
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.gestion_institucion?.institucion?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Tipo Proceso
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.expediente?.tipo_proceso?.tipo_proceso}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Etapa Procesal - Estado Proceso
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.tipo_audiencia?.etapa_proceso_detalle?.nombre} - {props?.registro?.tipo_audiencia?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Expediente
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.expediente.numero_expediente}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Víctima
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.victima?.primer_nombre} {props?.registro?.victima?.primer_apellido}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Querellante
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.querellante?.primer_nombre} {props?.registro?.querellante?.primer_apellido}
                                </div>
                            </div>
{/*                             <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">
                                    Teléfono Querellante
                                </div>
                                <div className="text-sm text-gray-900 sm:col-span-2">
                                    {props?.registro?.querellante?.telefono?.numero_telefono}
                                </div>
                            </div> */}
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">Causas Penales</div>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {props?.registro?.causa_penal?.numero_causa_penal}
                                </dd>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">Denuncia</div>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {props?.registro?.caso?.no_denuncia}
                                </dd>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <dt className="text-sm font-medium text-gray-500">Completada</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {props?.registro?.completada === true ? (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                            Sí
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                                            No
                                        </span>
                                    )}
                                </dd>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">Profesional Asignado</div>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {`${props?.registro?.profesional_asignado?.first_name} ${props?.registro?.profesional_asignado?.last_name}`}
                                </dd>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">Profesional Seguimiento</div>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {`${props?.registro?.profesional_crea?.first_name} ${props?.registro?.profesional_crea?.last_name}`}
                                </dd>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-sm font-medium text-gray-500">Asignado por</div>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {(props?.registro?.profesional_asigna === null) ? "" : `${props?.registro?.profesional_asigna?.first_name} ${props?.registro?.profesional_asigna?.last_name}`}
                                </dd>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-6 border rounded-lg p-3">
                <Label text="Historial asignaciones" />
                <TableSiavi
                    cargando={cargando}
                    headers={[
                        'Fecha',
                        'Profesional',
                        'Puesto'
                    ]}
                    body={
                        historicoAsignaciones?.results?.map((item, index) => (
                            <tr className={clases.tr} key={"t1" + index}>
                                <td className={clases.td}>{item?.fecha}</td>
                                <td className={clases.td}>{item?.profesional_asignado?.first_name} {item?.profesional_asignado?.last_name}</td>
                                <td className={clases.td}>{item?.profesional_asignado?.tipo_puesto?.puesto}</td>
                            </tr>
                        ))
                    }
                />
            </div>
            <div className="col-span-12 border rounded-lg p-3">
                <Label text="Historial cambio de fechas" />
                <TableSiavi
                    cargando={cargando1}
                    headers={[
                        'Fecha',
                        'Fecha hora planificación inicio',
                        'Fecha hora planificación fin',
                        'Observaciones'
                    ]}
                    body={
                        historicoFechas?.results?.map((item, index) => (
                            <tr className={clases.tr} key={"t2" + index}>
                                <td className={clases.td}>{item?.fecha}</td>
                                <td className={clases.td}>{item?.fecha_hora_planificada_inicio} </td>
                                <td className={clases.td}>{item?.fecha_hora_planificada_inicio}</td>
                                <td className={clases.td}>{item?.observaciones}</td>
                            </tr>
                        ))
                    }
                />
            </div>
        </div>
    );
};
