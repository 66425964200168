import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const resetPassword = async (loginId, password, newPassword) => {
    try {
        let body = {
            loginId: loginId,
            password: newPassword,
            currentPassword: password
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/seguridad/cambiar-contrasena/`,
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export const getPreguntas = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/preguntas/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const updatePreguntas = async (id,pregunta, respuesta, token) => {
    let body = {
        pregunta: pregunta,
        respuesta: respuesta
    }

    try {
        let bodyResult = axios({
            method: 'put',
            url: `${REACT_APP_API_URL}/catalogos/preguntauser/${id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: body
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntasUser = async (username) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/preguntauser/?user__username=${username}`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};