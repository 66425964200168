/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import Spinner from "../../../../../components/common/Spinner";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../../../components/common/Label";
import { useSelector } from "react-redux";
import {
    getServicios,
    getProcesosOrientacion,
    postProcesoOrientacion
} from "../services";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";

export const AgregarServicios = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [ultimoServicio, setUltimoServicio] = useState({});
    const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
    const [procesosSeleccionadas, setProcesosSelecionadas] = useState([]);
    const [procesos, setProcesos] = useState([]);
    const animatedComponents = makeAnimated();
    const options = [];
    const optionsProcesos = [];

    useEffect(() => {
        getServicios(token).then((data) => {
            setServicios(data);
        });
    }, []);

    useEffect(() => {
        if (ultimoServicio?.value !== undefined) {
            getProcesosOrientacion(ultimoServicio, token).then((data) => {
                setProcesos(data);
            });
        }
    }, [ultimoServicio]);

    const changeSelectServicios = (e) => {
        setUltimoServicio(e);
        setServiciosSeleccionados(e);
    };

    const changeSelectProcesos = (e) => {
        setProcesosSelecionadas(e);
    };

    servicios?.results?.filter(x => x.asitencia_legal === true)?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre,
        };
        options.push(reg);
    });

    if (props?.persona?.victima) {
        procesos?.results?.forEach((item) => {
            let reg = {
                value: item?.tipo_proceso?.id,
                label: item?.tipo_proceso?.tipo_proceso,
            };
            optionsProcesos.push(reg);
        });
    } else {
        procesos?.results?.forEach((item) => {
            let reg = {
                value: item?.tipo_proceso?.id,
                label: item?.tipo_proceso?.tipo_proceso,
            };
            optionsProcesos.push(reg);
        });
    }

    return (
        <div className="h-80 pb-10">
            <div className="mt-5 mb-5">
                <Label text="Servicios" />
                <Select
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    isMulti={false}
                    name="atenciones"
                    className="basic-multi-select mb-5 text-xs text-maivi-primaryFont"
                    classNamePrefix="seleccione"
                    options={options}
                    onChange={changeSelectServicios}
                    maxMenuHeight={140}
                />
                <Label text="Procesos" />
                <Select
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    isMulti={false}
                    name="servicios"
                    className="basic-multi-select text-xs text-maivi-primaryFont"
                    classNamePrefix="seleccione"
                    options={optionsProcesos}
                    onChange={changeSelectProcesos}
                    maxMenuHeight={140}
                />
            </div>
            <ButtonSiavi
                type="button"
                text="Guardar"
                onClick={async () => {
                    if (serviciosSeleccionados.length === 0 || procesosSeleccionadas.length === 0) {
                        setError(true);
                        setMensaje("Servicios y procesos son requeridos");
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    } else {
                        setCargando(true);
                        await postProcesoOrientacion(props.orientacion, procesosSeleccionadas.value, serviciosSeleccionados.value, token)
                            .then((data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                    toast.success("Servicios y procesos agregados con éxito");
                                    setCargando(false);
                                    props.setAgregarServicios(false);
                                }
                            })
                            .catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                    setCargando(false);
                                } else if (error.response.status >= 500) {
                                    setCargando(false);
                                    toast.error("Error interno comuniquese con el administrador");
                                    setMensaje("Error interno comuniquese con el administrador");
                                }
                            });
                    }
                }}
            />
            <div className="col-span-12 mt-4">
                {cargando && (
                    <div className="mt-5 mb-5">
                        <Spinner />
                    </div>
                )}
                {error &&
                    <div className="mt-5 mb-5">
                        <Alert type="error" mensaje={mensaje} />
                    </div>
                }
            </div>
        </div>
    );
};



