import { React, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";

export default function Filtros(props) {
  let filtros = "";

  const [state, setState] = useState({
    requiere_sentencia_previa: "",
    requiere_reparacion_digna_previa: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
      <div className=" max-w-lg">
        <div className="col-span-12 mt-4">
          <Label text="Requiere Sentencia Previa" />
          <div className="mt-1">
            <select
              name="requiere_sentencia_previa"
              value={state.requiere_sentencia_previa}
              onChange={onChange}
              className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
            >
              <option value="">Elegir requiere sentencia previa</option>
              <option value="true">Sí</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <Label text="Requiere Reparación Digna Previa" />
          <div className="mt-1">
            <select
              name="requiere_reparacion_digna_previa"
              value={state.requiere_reparacion_digna_previa}
              onChange={onChange}
              className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
            >
              <option value="">Elegir requiere reparación digna previa</option>
              <option value="true">Sí</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <ButtonSiavi
            text="Buscar"
            onClick={() => {
              filtros = `requiere_sentencia_previa=${state.requiere_sentencia_previa}&requiere_reparacion_digna_previa=${state.requiere_reparacion_digna_previa}`;
              props.setFilters(filtros);
              props.setOpenFilters(false);
            }}
          />
        </div>
        {props.isloading === true ? <Spinner /> : ""}
      </div>
    </div>
  );
}
