/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { putSedeCaso } from "./services";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import ModalGenerico from "../../../../../components/common/Modal";
import Select from "react-select";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    // motivo_cambio: Yup.string().required("motivo es requerido")
});

export const CambiarSede = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [sedeSeleccionada, setSedeSeleccionada] = useState(0);
    const [confirmar, setConfirmar] = useState(false);
    const [cargando, setCargando] = useState(false);
    const optionsSedes = [];
    let listadoSedes = [];

    props?.caso?.expedientes?.filter(x => x?.sede_expediente?.es_sede_seguimiento)?.forEach((item, index) => {
        let existe = false;
        if (index === 0) {
            listadoSedes.push(item?.sede_expediente);
        }

        listadoSedes.forEach(element => {
            if (element?.id === item.sede_expediente.id) {
                existe = true;
            }
        });

        if (!existe) {
            listadoSedes.push(item.sede_expediente);
        }
    });

    listadoSedes.forEach(element => {
        let reg = {
            value: element?.id,
            label: element?.sede
        };
        optionsSedes.push(reg);
    });

    const changeSelectSede = (e) => {
        setSedeSeleccionada(e.value);
    };

    return (
        <Formik
            initialValues={{
                sede: "",
            }}
            validationSchema={validaciones}
            onSubmit={async (values, { resetForm }) => {
                setCargando(true);
                await putSedeCaso(sedeSeleccionada, props.caso.id, token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            setCargando(false);
                            toast.success('registro guardado con éxito');
                            resetForm({});
                            window.location.reload();
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.detail);
                        } else if (error.response.status >= 500) {
                            toast.error('Error interno comuniquese con el administrador');
                        }
                        setCargando(false);
                    });
            }}>
            {({ handleSubmit, isSubmitting }) => (
                <div className="grid grid-cols-12 gap-2 mb-10">
                    <div className="col-span-12 p-3 mb-10">
                        <Label text="Sede" />
                        <Select
                            placeholder="Seleccionar"
                            isMulti={false}
                            isSearchable={true}
                            name="institucion"
                            className="basic-multi-select text-xs text-maivi-primaryFont mb-2"
                            options={optionsSedes}
                            onChange={changeSelectSede}
                            maxMenuHeight={150}
                        />
                        {
                            (isSubmitting) ? <Spinner /> :
                                <ButtonSiavi
                                    type="button"
                                    text="Guardar"
                                    onClick={() => {
                                        if (sedeSeleccionada === 0) {
                                            toast.error("debe seleccionar una sede");
                                        } else {
                                            setConfirmar(true);
                                        }
                                    }}
                                />
                        }
                    </div>
                    {confirmar && (
                        <ModalGenerico
                            title="¿Esta seguro de cambiar la sede del expediente, se recomienda cambiar 
                            todos los servicios de sede previo a cambiar el expediente de sede?"
                            open={confirmar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setConfirmar(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    isloading={cargando}
                                    aceptar={handleSubmit}
                                    cancelar={() => {
                                        setConfirmar(false);
                                    }}
                                />
                            }
                        />
                    )}
                </div>
            )}
        </Formik>
    );
};
