import { React, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { FormVictimario } from "../../../orientacion/editar/sindicados/form";
import { FormEstadoProceso } from "../../../orientacion/editar/estadoProceso/form";
import { FormLitigioEstrategico } from "../../../orientacion/editar/litigio_estrategico/form";
import { Procesos } from "../../../orientacion/editar/procesos/procesos_delitos";
import { useSelector } from "react-redux";
import { Echos } from "../../../orientacion/editar/echos/echos";
//import { FormQuerellantes } from "../../../orientacion/editar/querellantes/form";
//import { FormEvaluacion } from "../../../orientacion/editar/evaluacion/form";
//import { FormRiesgos } from "../../../orientacion/editar/riesgos/form";
//import { FormVictimas } from "../../../orientacion/editar/victimas/form";
import { store } from "../../../../../app/redux/store/store";
import { ChangeOrientacionTab } from "../../../../../app/redux/actions/orientacionTab";
import { TableSeguimientos } from "./seguimientos/seguimientos";
import { TableAudiencias } from "./audiencias/audiencias";
import { TableProcuraciones } from "./procuraciones/procuraciones";

export const Asistencia = (props) => {
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const query = new URLSearchParams(window.location.search);
  const casoAdmitido = query.get("admitido");

    const tabs = [
        //{ name:"Víctimas" },
        //{ name:"Querellantes" },
        { name:"Sindicado" },
        { name:"Procesos/Delitos" },
        { name:"Hechos" },
        { name:"Litigio estratégico" },
        { name:"Impugnaciones"},
        { name:"Seguimientos"},
        { name:"Audiencias"},
        { name:"Procuraciones" },
        // { name:"Evaluación diagnóstica" },
    ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const defaultTab = OrientacionTab.tabs;
    if(casoAdmitido === "null" || casoAdmitido === "false"){
      defaultTab.forEach((element) => {
        if(element.index !== 0){
          element.block = true;
        }
      });
    } else {
      defaultTab.forEach((element) => {
        if(element.index !== 0){
          element.block = false;
        }
      });
    }
    store.dispatch(ChangeOrientacionTab(defaultTab));
  }, [casoAdmitido,OrientacionTab.tabs]);

  const getIndexTab = () => {
    let pocision = 0;
    
    OrientacionTab.tabs.forEach((element,index) => {
      if(element.active){
        pocision = index;
      }
    });

    return pocision;
  };

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element,index) => {
      if(index === indexTab){
        element.active = true;
      } else {
        element.active =false;
      }
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };

  return  <Tab.Group defaultIndex={getIndexTab()} onChange={changeTab} >
  <Tab.List className="grid grid-cols-3 xl:grid-cols-8 lg:grid-cols-5 md:grid-cols-4 bg-blue-50 rounded-md p-1 mt-2">
    {tabs.map((item) => {
      return (
        <Tab
          key={item.name}
          //disabled={item.block}
          className={({ selected }) =>
            classNames(
              "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
              "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
              selected
                ? "bg-white shadow"
                : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
            )
          }
        >
          {item.name}
        </Tab>
      );
    })}
  </Tab.List>
  <Tab.Panels>
    {/* <Tab.Panel>
     <FormVictimas caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
     <FormQuerellantes caso={props.caso} />
    </Tab.Panel> */}
    <Tab.Panel>
      <FormVictimario caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
      <Procesos caso={props.caso} es_caso={true}/>
    </Tab.Panel>
    <Tab.Panel>
      <Echos caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
      <FormLitigioEstrategico caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
      <FormEstadoProceso caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
      <TableSeguimientos caso={props.caso}/>
    </Tab.Panel>
    <Tab.Panel>
      <TableAudiencias caso={props.caso}/>
    </Tab.Panel>
    <Tab.Panel>
      <TableProcuraciones caso={props.caso}/>
    </Tab.Panel>
    {/* <Tab.Panel>
      <FormRiesgos caso={props.caso} />
    </Tab.Panel>
    <Tab.Panel>
      <FormEvaluacion caso={props.caso} />
    </Tab.Panel> */}
  </Tab.Panels>
</Tab.Group>
};
