import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postRecursoDisponibilidad, putRecursoDisponibilidad, getRecursos, getAccionVictimologica } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
    descripcion: Yup.string()
        .required("Descripción es requerido")
        .max(255, "Máximo 100 caracteres"),
    recurso: Yup.string()
        .required("Seguimiento Legal es requerido")
        .max(255, "Máximo 100 caracteres"),
    accion_recurso: Yup.string()
        .required("Acción Victimológica es requerido")
        .max(255, "Máximo 100 caracteres")
});

const useFetchAccion = (token) => {
    const [state, setState] = useState({
        acciones: [],
    });

    useEffect(() => {
        getAccionVictimologica(token).then((acciones) => {
            setState({
                acciones: acciones,
            });
        });
    }, [token]);

    return state;
};

const useFetchSeguimiento = (token) => {
    const [state, setState] = useState({
        seguimientos: [],
    });

    useEffect(() => {
        getRecursos(token).then((seguimientos) => {
            setState({
                seguimientos: seguimientos,
            });
        });
    }, [token]);

    return state;
};

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { acciones } = useFetchAccion(token);
    const { seguimientos } = useFetchSeguimiento(token);

    return (
        <div>
            <Formik
                initialValues={{
                    descripcion: props.editar ? props.value.descripcion : "",
                    recurso: props.editar ? props.value.recurso.id : "",
                    accion_recurso: props.editar ? props.value.accion_recurso.id : "",
                    disponible: props.editar ? props.value.disponible : false,
                    fecha: props.editar ? props.value.fecha : "",
                    hora: props.editar ? props.value.hora : ""
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putRecursoDisponibilidad(props.value.id, values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro actualizado con éxito...!')
                            }
                        });
                    } else {
                        await postRecursoDisponibilidad(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro creado con éxito...!')
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6 mt-5">
                                <InputSiavi
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    value={values.descripcion}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Descripción"
                                />
                            </div>
                            <Label text="*Seguimiento Legal" />
                            <div className="mt-1">
                                <select
                                    id="recurso"
                                    name="recurso"
                                    value={values.recurso}
                                    onChange={handleChange}
                                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                >
                                    <option value="" disabled>Elegir seguimiento legal</option>
                                    {seguimientos?.results?.map((item) => {
                                        return (
                                            <option key={item.nombre} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <Label text="*Acción Victimológica" />
                            <div className="mt-1">
                                <select
                                    id="accion_recurso"
                                    name="accion_recurso"
                                    value={values.accion_recurso}
                                    onChange={handleChange}
                                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                >
                                    <option value="" disabled>Elegir acción victimológica</option>
                                    {acciones?.results?.map((item) => {
                                        return (
                                            <option key={item.nombre} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-span-6 mt-5">
                                <InputSiavi
                                    type="date"
                                    name="fecha"
                                    id="fecha"
                                    value={values.fecha}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Fecha"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <InputSiavi
                                    type="time"
                                    name="hora"
                                    id="hora"
                                    value={values.hora}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Hora"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="Disponible" />
                                <Toggle enabled={values.disponible} name="disponible" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
