import { React, useState, useEffect } from "react";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { getServicioPropio, getClasificacionBiblio } from "./services";
import { useSelector } from "react-redux";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    let filtros = "";

    const useFetchServicioPropio = (token) => {
        const [state, setState] = useState({
            servicio_propio: [],
        });

        useEffect(() => {
            getServicioPropio(token).then((servicio_propio) => {
                setState({
                    servicio_propio: servicio_propio,
                });
            });
        }, [token]);

        return state;
    };

    const useFetchClasificacion = (token) => {
        const [state, setState] = useState({
            clasificacion: [],
        });

        useEffect(() => {
            getClasificacionBiblio(token).then((clasificacion) => {
                setState({
                    clasificacion: clasificacion,
                });
            });
        }, [token]);

        return state;
    };


    const [state, setState] = useState({
        estado: "",
        servicio_propio: "",
        clasificacion: ""
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }
    const { servicio_propio } = useFetchServicioPropio(token);
    const { clasificacion } = useFetchClasificacion(token);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className=" max-w-lg">
                <div className="col-span-12 mt-4">
                    <Label text="Clasificación" />
                    <div className="mt-1">
                        <select
                            id="clasificacion"
                            name="clasificacion"
                            value={state.clasificacion}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir clasificación</option>
                            {clasificacion?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Servicio" />
                    <div className="mt-1">
                        <select
                            id="servicio_propio"
                            name="servicio_propio"
                            value={state.servicio_propio}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="" disabled>Elegir servicio</option>
                            {servicio_propio?.results?.map((item) => {
                                return (
                                    <option key={item.nombre} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Label text="Estado" />
                    <div className="mt-1">
                        <select
                            name="estado"
                            value={state.estado}
                            onChange={onChange}
                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                        >
                            <option value="">Elegir estado</option>
                            <option value="true">Activo</option>
                            <option value="false">Inactivo</option>
                        </select>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <ButtonSiavi
                        text="Buscar"
                        onClick={() => {
                            filtros = `servicio=${state.servicio_propio}&estado=${state.estado}&clasificacion=${state.clasificacion}`;
                            props.setFilters(filtros);
                            props.setOpenFilters(false);
                        }}
                    />
                </div>
                {props.isloading === true ? <Spinner /> : ""}
            </div>
        </div>
    );
}
