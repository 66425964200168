
const {
    REACT_APP_API_URL
} = process.env;

export const getDominioRecuperacion = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/evaluacion-victima/dominiorecuperacion/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postDominioRecuperacion = async ({ nombre, descripcion, estado }, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/dominiorecuperacion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDominioRecuperacion = async (id, { nombre, descripcion, estado }, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/dominiorecuperacion/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDominioRecuperacion = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/dominiorecuperacion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSubdominioRecuperacion = async ({ nombre, descripcion, dominio, estado }, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            dominio,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/subdominiorecuperacion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSubdominios = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/evaluacion-victima/subdominiorecuperacion/?page_size=1000&dominio=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};