import Return from "../common/ButtonSalir";

const ContenedorForm = (props) => {
  return (
    <div className="min-h-screen bg-maivi-gray-5 pb-10 mb-10 overflow-y-auto">
      <div className="absolute w-full bg-warm-gray-50" />
      <div className="py-1 mx-0 lg:mx-1 xl:mx-1 ">
        <div className="flex flex-col items-center h-40 w-full background-siavi">
          {props.cerrar ? <Return ruta={props.cerrar} /> : ""}
        </div>
        <div className="bg-white shadow py-1 px-2">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ContenedorForm;
