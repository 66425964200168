import { InputSiavi } from "../../../components/common/InputText";
import Label from "../../../components/common/Label";

export const Reset = (props) =>{
    return(
        <div>
        <div className="mt-5">
          <Label text="Nueva contraseña"/>
          <div className="mt-1">
            <InputSiavi
              id="newPassword"
              name="newPassword"
              type="password"
              autoComplete="current-password"
              required
              onChange={props.onChange}
            />
          </div>
        </div>
        <div className="mt-5">
          <Label text="Confirmar contraseña" />
          <div className="mt-1">
            <InputSiavi
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              autoComplete="current-password"
              required
              onChange={props.onChange}
            />
          </div>
        </div>
      </div>
    );
}