import moment from "moment";

export const FormDetalleAccion = (props) => {

    return (
        <div className="bg-white border rounded-lg overflow-hidden sm:rounded-lg">
            <div className="border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">ID</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.id}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Tipo</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.accion_seguimiento?.nombre}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Seguimiento</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.seguimiento}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">No. Expediente</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.expediente?.numero_expediente}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Persona</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.persona?.primer_nombre} {props?.detalle?.persona?.primer_apellido}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Modalidad</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.modalidad?.nombre}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Especificaciones Modalidad</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.especificaciones_modalidad}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Profesional Asignado</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.profesional_asignado?.first_name} {props?.detalle?.profesional_asignado?.last_name}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha Acción</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {moment(props?.detalle?.fecha_accion).format("DD-MM-YYYY")}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Completada</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.completada === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha Completado</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.fecha_completado === null ? '' : moment(props?.detalle?.fecha_completado).format("DD-MM-YYYY")}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha Hora Planificada Inicia</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.fecha_hora_planificada_inicia}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Acción Planificada</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.accion_planificada === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Servicio</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.expediente?.servicio_propio?.nombre}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Atención</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.expediente?.atencion?.nombre}
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Institución</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.institucion?.nombre}
                        </dd>
                    </div>
                    { props.seguimiento && (
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">¿Es Audiencia?</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.caso?.es_audiencia === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                    )}
                </dl>
            </div>
        </div>
    );
};
