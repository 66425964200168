/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { clases } from "../../../../../helpers/clases";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { useSelector } from "react-redux";
import { ReasignarExpediente } from "./reasignar-expediente";
import { Nombramiento } from "./Nombramiento";
import { ActualizarFiscalia } from "./actualizar_fiscalia";
import { EnviarMensajes } from "./enviar_mensajes";
import { CambiarEstado } from "./cambiar_estado";
import {
  getDelitosCaso,
  getCausasPenales,
} from "./services";
import { RefreshIcon } from "@heroicons/react/solid";
import { CambiarSede } from "./cambiar_sede";
import { Cierre } from "./Cierre";
import { CierreCaso } from "./CierreCaso";
import { MensajeriaSMS } from "../../../../../components/EnvioMensajes";
import {
  LockOpenIcon,
  XCircleIcon,
  PaperClipIcon,
  UserIcon,
  ClipboardCheckIcon,
  PencilAltIcon,
  ChatIcon,
  PrinterIcon,
  MailIcon
} from "@heroicons/react/outline";
import {
  getExpedienteSindicadoDelitos,
  getExpedienteSindicados,
  getFullCasoDetails
} from "../../../orientacion/editar/services";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import ModalGenerico from "../../../../../components/common/Modal";
import Divider from "../../../../../components/common/Divider";
import PdfViewer from "../../../../../components/common/PdfViewer";
import ModalBig from "../../../../../components/common/ModalTwo";
import 'moment/locale/es';
import moment from 'moment';
import { Reabrir } from "./reabrir_caso";
import toast from "react-hot-toast";

moment.locale('es');

export const Generales = ({
  caso,
  orientacion
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const permisos = useSelector((state) => state.loginReducer.data.usuario.user.permissions);
  const [querellante, setQuerellante] = useState("");
  const [archivos, setArchivos] = useState(false);
  //const [documentoQuerellante, setDocumentoQuerellante] = useState("");
  const [reasignar, setReasignar] = useState(false);
  const [expediente, setExpediente] = useState({});
  const [nombramientos, setNombramientos] = useState(false);
  const [actualizarFiscalia, setActualizarFiscalia] = useState(false);
  const [cambiarEstado, setCambiarEstado] = useState(false);
  const [cambiarSede, setCambiarSede] = useState(false);
  const [mensajeSMS, setMensajeSMS] = useState(false);
  const [mensajes, setMensajes] = useState(false);
  const [delitos, setDelitos] = useState([]);
  const [causaPenal, setCausaPenal] = useState([]);
  const [imprimirData, setImprimirData] = useState(false);
  const [cierre, setCierre] = useState(false);
  const [cierreCaso, setCierreCaso] = useState(false);
  const [smsExpediente, setSmsExpediente] = useState(false);
  const [archivosExpedientes, setArchivosExpedientes] = useState(false);
  const [reabrir, setReabrir] = useState(false);
  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user
  );

  moment.locale("es");

  const headersTable = [
    "Persona",
    "Servicio",
    "Tipo proceso",
    "Profesional",
    "Fecha",
    "Acciones"
  ]

  const Info = (titulo, subtitulo, tercer_parametro) => {
    return (
      <div>
        <p className="text-xs  text-gray-900">{titulo}</p>
        <p className="text-xs font-medium text-gray-500">{subtitulo}</p>
        <p className="text-xs font-medium text-gray-500">{tercer_parametro}</p>
      </div>
    );
  };

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro
  const permisoView = "ExpedientesAL";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }



  const nombreQuerellante = () => {
    caso?.personas?.forEach(element => {
      if (element.querellante === true) {
        setQuerellante(`${element?.persona?.primer_nombre} ${element?.persona?.segundo_nombre} ${element?.persona?.primer_apellido} ${element?.persona?.segundo_apellido}`)
        //setDocumentoQuerellante(element?.persona?.no_documento)
        return false;
      }
    });
  }

  useEffect(nombreQuerellante);
  useEffect(() => {
    getDelitosCaso(caso.id, token).then((data) => {
      let delitos = [];
      data.results.forEach((element, index) => {
        let existe = false;
        if (index === 0) {
          delitos.push(element?.delito);
        }
        delitos.forEach(item => {
          if (item.id === element?.delito?.id) {
            existe = true;
          }
        });

        if (!existe) {
          delitos.push(element.delito);
        }
      });
      setDelitos(delitos);
    });
  }, [])

  useEffect(() => {
    getCausasPenales(caso.id, token).then((data) => {
      setCausaPenal(data.results[0])
    });
  }, []);

  const CheckPermisoUser = (permiso) => {
    let retorno = false;

    for(const p of permisos){
      if (p?.codename === permiso) {
        retorno = true
      }
    }

    return retorno;

}

  return (
    <form autoComplete="off">
      <div className="border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
        <h1 className="text-maivi-primaryFont">Datos generales</h1>
        <Divider />
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
            {Info("Número de expediente", `${caso?.numero_caso}, (Orientación: ${orientacion?.id})`)}
            {Info("", caso?.sede_caso?.sede)}
            <p className="text-xs">{(caso?.estado) ?
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Activo
              </span>
              :
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                Inactivo
              </span>
            }
              /
              {(caso?.caso_cerrado) ?
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                  Cerrado
                </span>
                :
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Abierto
                </span>
              }
            </p>
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
            <p className="text-xs  text-gray-900">Nombre de la víctima</p>
            <p className="text-xs font-medium text-gray-500">{caso?.victima?.primer_nombre} {caso?.victima?.segundo_nombre} {caso?.victima?.primer_apellido} {caso?.victima?.segundo_apellido}</p>
            {/* <p className="text-sm font-medium text-gray-500">Documento {caso?.victima?.no_documento}</p> */}
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
            {Info(
              "Nombre del querellante:",
              querellante,
              //`Documento: ${documentoQuerellante}`
            )}
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
            {Info("Creación:", `${moment(caso?.fecha_creacion).format('LL')}`)}
            {Info("", `${caso?.profesional_orientacion?.first_name} ${caso?.profesional_orientacion?.last_name} `)}
            {/* {Info("", `${moment(caso?.fecha_creacion).startOf('day').fromNow()}`)} */}
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
            {Info("Histórico / IV Histórico", (orientacion?.historica === true) ? `Sí / ${orientacion?.iv_historico}` : "No")}
            {Info("Fecha caso:", `${moment(caso?.fecha).format('LL')}`)}
          </div>
          <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4">
            <p className="text-xs text-gray-900">Delitos:</p>
            {
              delitos?.map((item, index) => {
                return <p key={item?.nombre + index} className="text-xs font-medium text-gray-500">{item?.nombre}</p>
              })
            }
          </div>
          <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4">
            {Info("Denuncia:", caso?.no_denuncia)}
          </div>
          <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4">
            <p className="text-xs">Fiscalía actual: <span className="text-gray-500">{caso?.fiscalia_actual?.nombre_fiscalia}</span></p>
            <p className="text-xs">Fiscal a cargo: <span className="text-gray-500">{caso?.fiscal_acargo}</span></p>
          </div>
          <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4">
            <p className="text-xs">Numero causa penal: <span className="text-gray-500">{causaPenal?.numero_causa_penal}</span></p>
            <p className="text-xs">Organo jurisdiccional: <span className="text-gray-500">{causaPenal?.organo_jurisdiccional?.nombre_fiscalia}</span></p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 xl:col-span-9 lg:col-span-9 md:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md mt-2 h-96 overflow-auto">
          <h1 className="text-maivi-primaryFont text-sm">Servicios brindados</h1>
          <TableSiavi
            headers={headersTable}
            empty="Aun no hay servicios agregados"
            body={
              caso?.expedientes.filter(x => (x.seguimiento_legal === true && x.asitencia_legal === true) || (x.seguimiento_legal === false && x.asitencia_legal === false)).map((item, index) =>
                <tr key={item?.caso_persona?.persona?.primer_nombre + index} className={`cursor-pointer hover:bg-gray-100 ${item.expediente_cerrado ? 'bg-red-200' : ''} `}>
                  <td className={clases.td}>{item?.caso_persona?.persona?.primer_nombre} {item?.caso_persona?.persona?.primer_apellido} {item?.caso_persona?.persona?.segundo_apellido}</td>
                  <td className={clases.td}>{item?.servicio?.nombre}</td>
                  <td className={clases.td}>{item?.tipo_proceso?.tipo_proceso}</td>
                  <td className={clases.td}>{item?.profesional_asignado?.first_name} {item?.profesional_asignado?.last_name}</td>
                  <td className={clases.td}>{(item?.fecha_asignacion) ? moment(item?.fecha_asignacion).format("DD-MM-YYYY") : ''}</td>
                  <td className={clases.td}>
                    {(CheckPermiso('AgregarNombramiento') === true) &&
                      <button
                        type="button"
                        onClick={() => {
                          setExpediente(item);
                          setNombramientos(true);
                        }}
                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                        <ClipboardCheckIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext text-xs">Nombramiento</span>
                      </button>
                    }
                    {(CheckPermiso('ImprimirCaratula') === true) &&
                      <button
                        type="button"
                        onClick={async () => {
                          let victimas = '', querellantes = '', numeroIV = '', numeroMP = '', causa = '', profesionalAsignado = '';
                          let delitos = '', sindicados = '', fiscalia = '', organoJUR = '', etapa = '', fechaAsignacion = '', reporte = '';
                          let sede = item.sede_expediente?.sede || '';
                          let usuario = usuarioLogin.username;
                          let etapaProcesal = item.ultima_etapa_procesal.etapa_procesal;
                          let victimaDirecta = item.caso_persona.victima ? 'Directa' : 'Colateral';

                          etapa = etapaProcesal === null ? '' : etapaProcesal.nombre;
                          fechaAsignacion = item.fecha_asignacion;
                          let profMD = "", profTS = "", profPS = "", profLG = "", profPQ = "";
                          let idPersona = item.caso_persona.persona.id;
                          caso?.expedientes.forEach(exp => {
                            let prof = exp?.profesional_asignado;

                            if (exp.servicio.md && idPersona === exp.caso_persona.persona.id) {
                              profMD = prof?.first_name + " " + prof?.last_name;
                            }
                            if (exp.servicio.ps && idPersona === exp.caso_persona.persona.id) {
                              profPS = prof?.first_name + " " + prof?.last_name;
                            }
                            if (exp.servicio.ts && idPersona === exp.caso_persona.persona.id) {
                              profTS = prof?.first_name + " " + prof?.last_name;
                            }
                            if (exp.servicio.asitencia_legal && idPersona === exp.caso_persona.persona.id) {
                              profLG = prof?.first_name + " " + prof?.last_name;
                            }
                            if (exp.servicio.pq && idPersona === exp.caso_persona.persona.id) {
                              profPQ = prof?.first_name + " " + prof?.last_name;
                            }

                          });

                          if (item.servicio.asitencia_legal) {
                            reporte = "caratula-legal-penal";
                          }
                          if (item.servicio.md) {
                            reporte = "caratula-medica";
                          }
                          if (item.servicio.impugnaciones) {
                            reporte = "caratula-legal-impugnaciones";
                          }
                          if (item.servicio.ps) {
                            reporte = "caratula-psicologia";
                          }
                          if (item.servicio.ts) {
                            reporte = "caratula-trabajo-social";
                          }

                          if (item.servicio.pq) {
                            reporte = "caratula-psiquiatria";
                          }

                          if (item.profesional_asignado) {
                            let prof = item.profesional_asignado;
                            profesionalAsignado = `${prof.first_name} ${prof.last_name}`;
                          }

                          await getExpedienteSindicados(item.caso, token).then(async (sindicos) => {
                            if (sindicos.results) {
                              sindicos.results.forEach(sin => {
                                let sindicado = sin.sindicado;
                                sindicados += ` ${sindicado.primer_nombre} ${sindicado.segundo_nombre} ${sindicado.primer_apellido} ${sindicado.segundo_apellido},`;
                              });
                            }

                            await getExpedienteSindicadoDelitos(item.caso, token).then(async (dataDelitos) => {
                              if (dataDelitos.results) {
                                let delitosA = [];
                                dataDelitos.results.forEach(del => {
                                  delitosA.push(del.delito.nombre);
                                });
                                let result = delitosA.filter((item, index) => {
                                  return delitosA.indexOf(item) === index;
                                })
                                result.forEach(del => { delitos += ` ${del},`; })
                              }
                              await getFullCasoDetails(item.caso, token).then((caso) => {
                                numeroIV = caso.numero_caso;
                                numeroMP = caso.no_denuncia;
                                organoJUR = causaPenal?.organo_jurisdiccional?.nombre_fiscalia || '';
                                causa = causaPenal?.numero_causa_penal || '';
                                if (caso.fiscalia_actual !== null) {
                                  fiscalia = `${caso.fiscalia_actual.institucion.nombre}, ${caso.fiscal_acargo}`;
                                }

                                let person = item.caso_persona.persona;
                                victimas += ` ${person.primer_nombre} ${person.segundo_nombre} ${person.primer_apellido} ${person.segundo_apellido}`;

                                caso.personas.forEach(per => {
                                  let person = per.persona;
                                  if (per.victima) {

                                  }
                                  if (per.querellante) {
                                    querellantes += ` ${person.primer_nombre} ${person.segundo_nombre} ${person.primer_apellido} ${person.segundo_apellido},`;
                                  }
                                });

                                setImprimirData({
                                  victimaDirecta: victimaDirecta,
                                  profMD: profMD,
                                  profTS: profTS,
                                  profPS: profPS,
                                  profLG: profLG,
                                  profPQ: profPQ,
                                  reporte: reporte,
                                  sede: sede,
                                  usuario: usuario,
                                  numeroIV: numeroIV,
                                  numeroMP: numeroMP,
                                  causa: causa,
                                  fiscalia: fiscalia,
                                  organoJUR: organoJUR,
                                  profesionalAsignado: profesionalAsignado,
                                  fechaAsignacion: fechaAsignacion,
                                  etapa: etapa,
                                  victimas: victimas.slice(0, -1),
                                  querellantes: querellantes.slice(0, -1),
                                  delitos: delitos.slice(0, -1),
                                  sindicados: sindicados.slice(0, -1)
                                })
                              });
                            });
                          });
                        }}
                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                        <PrinterIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext text-xs">Imprimir Carátula</span>
                      </button>
                    }
                    {(CheckPermiso('ServiciosSMS') === true) &&
                      <button
                        type="button"
                        onClick={() => {
                          setExpediente(item);
                          setSmsExpediente(true);
                        }}
                        className={clases.addIcon2}>
                        <ChatIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext text-xs">Enviar sms</span>
                      </button>
                    }
                    {(CheckPermiso('ServiciosAdjuntos') === true) &&
                      <button
                        type="button"
                        onClick={() => {
                          setExpediente(item);
                          setArchivosExpedientes(true);
                        }}
                        className={clases.addIcon2}>
                        <PaperClipIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext text-xs">ver archivos</span>
                      </button>
                    }
                    {(CheckPermiso('CerrarExpediente') === true) &&
                      <button
                        type="button"
                        onClick={() => {
                          if (item?.expediente_cerrado) {
                            toast.error("Este expediente ya fue cerrado");
                          }
                          else if (usuarioLogin.id === item?.profesional_asignado?.id) {
                            setExpediente(item);
                            setCierre(true);
                          } else {
                            setExpediente(item);
                            setCierre(true);
                            //toast.error("Solo el profesional asignado puede cerrar el expediente");
                          }
                        }}
                        className={clases.trashIcon}>
                        <XCircleIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext text-xs">Cierre</span>
                      </button>
                    }
                  </td>
                </tr>
              )
            }
          />
        </div>
        <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
          <h1 className="text-maivi-primaryFont text-sm">Realizar acciones</h1>
          <Divider />
          <div className="grid grid-cols-12 gap-2 items-start">
            {(CheckPermiso('GeneralesAdjuntar') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <PaperClipIcon
                    className="h-6 w-6 text-blue-600"
                    onClick={() => {
                      setArchivos(true);
                    }}
                  />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Adjuntar</h1>
              </div>
            }
            {(CheckPermiso('CambiarEstado') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer" onClick={() => {
                setCambiarEstado(true);
              }}>
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryMustard" />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Cambio de estado</h1>
              </div>
            }
            {/* <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
              <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <LockClosedIcon
                  className="h-6 w-6 text-maivi-secondaryDarkRed"
                  onClick={() => {
                    setCierreCaso(true);
                  }}
                />
              </div>
              <h1 className="text-maivi-primaryFont text-xs">Cierre general</h1>
            </div> */}
            {(CheckPermiso('ReabrirCaso') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <LockOpenIcon className="h-6 w-6 text-green-600" onClick={() => {
                    if (caso.caso_cerrado) {
                      setReabrir(true);
                    } else {
                      toast.error("El caso debe estar cerrado para poder reabrirlo")
                    }
                  }} />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Reabrir</h1>
              </div>
            }
            {(CheckPermiso('ActualizarFiscalia') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <PencilAltIcon
                    className="h-6 w-6 text-blue-600"
                    onClick={() => {
                      setActualizarFiscalia(true);
                    }}
                  />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Actualizar fiscalía</h1>
              </div>
            }
            {(CheckPermiso('NotificacionesInternas') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <MailIcon
                    className="h-6 w-6 text-blue-600"
                    onClick={() => {
                      setMensajes(true);
                    }}
                  />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Enviar notificaciones</h1>
              </div>
            }
            {(CheckPermiso('CambiarSedeCaso') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <RefreshIcon
                    className="h-6 w-6 text-green-600"
                    onClick={() => {
                      if(CheckPermisoUser('can_change_sede_caso') === true){
                        setCambiarSede(true);
                      } else {
                        toast.error('No tiene permisos para realizar esta acción')
                      }
                    }}
                  />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Cambiar sede</h1>
              </div>
            }
            {(CheckPermiso('GeneralesSMS') === true) &&
              <div className="col-span-6 xl:col-span-4 lg:col-span-4 flex flex-col items-center justify-center text-center cursor-pointer">
                <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                  <ChatIcon
                    className="h-6 w-6 text-maivi-secondaryMustard"
                    onClick={() => {
                      setMensajeSMS(true);
                    }}
                  />
                </div>
                <h1 className="text-maivi-primaryFont text-xs">Mensajería SMS</h1>
              </div>
            }
            </div>


          {imprimirData && (
            <ModalAmplio
              title={`Carátula`}
              open={true}
              closeModal={() => { setImprimirData(false); }}
              cerrar={() => {
                setImprimirData(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PrinterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <PdfViewer
                  data={imprimirData}
                  reporte={imprimirData?.reporte}
                  style={{ height: "80vh" }}
                />
              }
            />
          )}

          {archivos && (
            <ModalAmplio
              title={`Adjuntar archivos`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setArchivos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PaperClipIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <GestionArchivos
                  sin_filtro={true}
                  idRecurso={caso.id}
                  recurso="CASO"
                  claseDocumento="casos"
                  caso={caso.id}
                  expediente={null}
                  filtros={`caso=${caso.id}`}
                />
              }
            />
          )}

          {archivosExpedientes && (
            <ModalAmplio
              title={`ver archivos`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setArchivosExpedientes(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PaperClipIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <GestionArchivos
                  ocultar_formulario={true}
                  sin_filtro={true}
                  idRecurso={caso.id}
                  recurso="CASO"
                  claseDocumento="casos"
                  caso={caso.id}
                  expediente={expediente.id}
                  filtros={`expediente=${expediente.id}`}
                />
              }
            />
          )}
          {reasignar && (
            <ModalGenerico
              title="Reasignar expediente"
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setReasignar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UserIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <ReasignarExpediente
                  //expediente={expediente}
                  token={token}
                />}
            />
          )}
          {nombramientos && (
            <ModalBig
              title={`Agregar Nombramiento`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setNombramientos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ClipboardCheckIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <Nombramiento infoExpediente={expediente} />
              }
            />
          )}
          {actualizarFiscalia && (
            <ModalGenerico
              title={`Actualizar fiscalía`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setActualizarFiscalia(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <PencilAltIcon className="h-6 w-6 text-blue-600" />
                </div>
              }
              children={
                <ActualizarFiscalia caso={caso} />
              }
            />
          )}
          {mensajes && (
            <ModalBig
              title={`Enviar mensajes a profesionales`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setMensajes(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <ChatIcon className="h-6 w-6 text-blue-600" />
                </div>
              }
              children={
                <EnviarMensajes
                  caso={caso}
                  setMensajes={setMensajes}
                />
              }
            />
          )}
          {cambiarEstado && (
            <ModalAmplio
              title={`Cambiar estado ${caso.numero_caso}`}
              open={cambiarEstado}
              closeModal={() => { }}
              cerrar={() => {
                window.location.reload();
              }}
              children={
                <CambiarEstado
                  caso={caso}
                  token={token}
                />
              }
            />
          )}
          {reabrir && (
            <ModalGenerico
              title="Reabrir caso"
              open={reabrir}
              closeModal={() => { }}
              cerrar={() => {
                setReabrir(false)
              }}
              children={
                <Reabrir
                  caso={caso}
                />
              }
            />
          )}
          {mensajeSMS && (
            <ModalAmplio
              title="Mensajería SMS"
              open={mensajeSMS}
              closeModal={() => { }}
              cerrar={() => {
                setMensajeSMS(false);
              }}
              children={
                <MensajeriaSMS
                  editable={true}
                  por_caso={true}
                  registro={caso}
                  token={token}
                />
              }
            />
          )}
          {smsExpediente && (
            <ModalAmplio
              title="Mensajería SMS"
              open={smsExpediente}
              closeModal={() => { }}
              cerrar={() => {
                setSmsExpediente(false);
              }}
              children={
                <MensajeriaSMS
                  por_expediente={true}
                  registro={expediente}
                  token={token}
                />
              }
            />
          )}
          {cambiarSede && (
            <ModalGenerico
              title="Cambiar sede"
              open={cambiarSede}
              closeModal={() => { }}
              cerrar={() => {
                setCambiarSede(false);
              }}
              children={
                <CambiarSede
                  caso={caso}
                />
              }
            />
          )}
          {cierre && (
            <ModalBig
              title={`Gestión criterio cierre del expediente ${expediente?.servicio?.nombre}`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setCierre(false);
              }}
              children={
                <Cierre infoExpediente={expediente} />
              }
            />
          )}
          {cierreCaso && (
            <ModalBig
              title={`Gestión Criterio Cierre del Caso`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setCierreCaso(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ClipboardCheckIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <CierreCaso infoCaso={caso} />
              }
            />
          )}
        </div>
      </div>
    </form>
  );
};