/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import {
    getAtenciones,
    getServicios,
    getSedes,
    getTipoProfesionales,
    getTipoPuestos
} from "./services";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {

    let filtros = "";
    const [state, setState] = useState({
        tipoprofesional: "",
        atenciones: "",
        servicios: "",
        sedes: "",
        tipopuesto: "",
        estado: ""
    });

    const [tipoprofesional, setTipoProfesional] = useState([]);
    const [tipopuesto, setTipoPuesto] = useState([]);
    const [sedes, setSedes] = useState([]);
    const [atenciones, setAtenciones] = useState([]);
    const [servicios, setServicios] = useState([]);

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        getTipoProfesionales(props.token).then((data) => {
            setTipoProfesional(data);
        });
    }, []);

    useEffect(() => {
        getTipoPuestos(props.token).then((data) => {
            setTipoPuesto(data);
        });
    }, []);

    useEffect(() => {
        getSedes(props.token).then((data) => {
            setSedes(data);
        });
    }, []);

    useEffect(() => {
        getAtenciones(props.token).then((data) => {
            setAtenciones(data);
        });
    }, []);

    useEffect(() => {
        getServicios(props.token).then((data) => {
            setServicios(data);
        });
    }, []);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="tipoprofesional"
                    label="Tipo Profesional"
                    value={state.tipoprofesional}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir tipo profesional"
                    list={
                        tipoprofesional?.results?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.profesion}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="tipopuesto"
                    label="Tipo Puesto"
                    value={state.tipopuesto}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir tipo puesto"
                    list={
                        tipopuesto?.results?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.puesto}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="sedes"
                    label="Sede"
                    value={state.sedes}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir sede"
                    list={
                        sedes?.results?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.sede}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="atenciones"
                    label="Atención"
                    value={state.atenciones}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir atención"
                    list={
                        atenciones?.results?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.nombre}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="servicios"
                    label="Servicio"
                    value={state.servicios}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir servicio"
                    list={
                        servicios?.results?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.nombre}</option>
                        })
                    }
                />
            </div>
            <div className="mt-1">
                <SelectSiavi
                    label="Estado"
                    name="estado"
                    value={state.estado}
                    onChange={onChange}
                    valueInit=""
                    selectInit="Elegir"
                    list={
                        [
                            <option value="true">Activo</option>,
                            <option value="false">Inactivo</option>
                        ]
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `tipo_profesional=${state.tipoprofesional}&tipo_puesto=${state.tipopuesto}&sede=${state.sedes}&estado=${state.estado}&profesionalatenciones__atencion=${state.atenciones}&profesionalservicios__servicio=${state.servicios}`;
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}