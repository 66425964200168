/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
//import { bloqueoFecha } from "../../../../../helpers/utils";
import { useSelector } from "react-redux";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
//import { InputSiavi } from "../../../../../components/common/InputText";
import { SelectSiavi } from "../../../../../components/common/Select";
import { Formik } from "formik";
import {
    getMedidasDesjudicializadoras,
    getTipoApelaciones,
    getInterponentes,
    postExpedienteProceso
} from "../estadoProceso/services";
import { 
    getResultadosAudiencias,
    putAudiencia
} from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
    resultado_audiencia: Yup.string().required("resultado de la audiencia es requerido"),
    //fecha: Yup.string().required("fecha es requerida"),
});

export const EstadoProcesoAudiencias = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [interponentes, setInterponentes] = useState([]);
    const [apelaciones, setApelaciones] = useState([]);
    const [medidasDesjudicializadoras, setMedidasDesjudicializadoras] = useState([]);
    const [selectInterponentes, setSelectInterponentes] = useState([]);
    const [resultadosAudiencias,setResultadosAudiencias] = useState([]);
    const [sindicadoConocido] = useState(true);
    const animatedComponents = makeAnimated();
    const optionsInterponentes = [];

    console.log(props.registro);

    interponentes?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre,
        };
        optionsInterponentes.push(reg);
    });

    const changeInterponentes = (e) => {
        setSelectInterponentes(e);
    };

    useEffect(() => {
        getInterponentes(token).then((data) => {
            setInterponentes(data);
        });
    }, [token]);

    useEffect(() => {
        getTipoApelaciones(token).then((data) => {
            setApelaciones(data);
        });
    }, [token]);

    useEffect(() => {
        getMedidasDesjudicializadoras(token).then((data) => {
            setMedidasDesjudicializadoras(data);
        });
    }, [token]);

    useEffect(() => {
        getResultadosAudiencias(props?.registro?.tipo_audiencia?.id,token).then((data) => {
            setResultadosAudiencias(data);
        });
    }, [token]);

    console.log(props.registro);

    return (
        <Formik
            initialValues={{
                expediente: props?.registro?.expediente?.id,
                etapa_procesal: "",
                resultado_audiencia: "",
                tipo_proceso: props?.registro?.expediente?.tipo_proceso?.id,
                estado_proceso: props?.registro?.tipo_audiencia?.id,
                medida: "",
                proceso_secundario: false,
                fecha: moment(props?.registro?.fecha_hora_planificada_inicia).format("YYYY-MM-DD"),
                comentarios: "",
        
                tipo_apelacion: null,
                fecha_proceso_secundario: "",
                tipo_apelacion2:"",
                audiencia: props?.registro?.id,
                audiencia_id:null,
                caso: props?.registro?.caso?.id,
            }}
            validationSchema={validaciones}
            onSubmit={async (values,{resetForm}) => {

                await postExpedienteProceso(values, values.caso, token)
                .then(async (data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    putAudiencia(
                        props.registro.id,
                        values.resultado_audiencia,
                        props?.registro?.accion_seguimiento?.id,
                        props?.registro?.fecha_requerido,
                        moment(props?.registro?.fecha_hora_planificada_inicia).format("YYYY-MM-DD"),
                        props?.registro?.profesional_asignado?.id,
                        token).then(()=>{
                        toast.success("Proceso creado con éxito..!");
                        setTimeout(() => {
                            props.setAddEstadoProceso(false);
                        }, 500);
                    });
                  } 
                })
                .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 450) {
                    toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
      
                resetForm({})
            }}
        >
            {({ values, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <SelectSiavi 
                            label="Resultado audiencia"
                            name="resultado_audiencia"
                            value={values.resultado_audiencia}
                            onChange={handleChange}
                            valueInit=""
                            selectInit="Elegir resultado audiencia"
                            list={
                                resultadosAudiencias?.results?.map((item, index) => {
                                    return (
                                        <option key={item.nombre + index + 'mt'} value={item.id}>
                                            {item.nombre}
                                        </option>
                                    );
                                })
                            }
                        />
                    </div>
                    {
                        (values.habilita_motivo) &&
                        <div className="mb-3">
                            <SelectSiavi
                                label="Motivo"
                                name="tipo_apelacion2"
                                value={values.tipo_apelacion2}
                                onChange={handleChange}
                                valueInit=""
                                selectInit="Elegir Motivo"
                                list={
                                    apelaciones?.results?.map((item, index) => {
                                        return (
                                            <option key={item.nombre + index + 'mt'} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                    }
                    {values.habilita_interponente && (
                        <div className="mb-3">
                            <Label text="Interponente" />
                            <Select
                                placeholder="Seleccionar"
                                components={animatedComponents}
                                isMulti
                                name="interponente"
                                className="basic-multi-select text-xs text-maivi-primaryFont"
                                classNamePrefix="seleccione"
                                options={optionsInterponentes}
                                onChange={changeInterponentes}
                                maxMenuHeight={140}
                            />
                        </div>)}
                    {
                        values.medidaDesjudializadora &&
                        <div className="mb-3">
                            <SelectSiavi
                                label="Medida desjudicializadora"
                                name="medida"
                                value={values.medida}
                                onChange={handleChange}
                                valueInit=""
                                selectInit="Elegir medida desjudicializadora"
                                list={
                                    medidasDesjudicializadoras?.results?.map((item, index) => {
                                        return (
                                            <option key={item.nombre + index} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                    }
                    {/* <div className="mb-3">
                        <InputSiavi
                            label="Fecha"
                            name="fecha"
                            type="date"
                            onChange={handleChange}
                            value={values.fecha}
                            maximo={bloqueoFecha()}
                            validacion={true}
                        />
                    </div> */}
                    <div className="mb-3">
                        <TextAreaSiavi
                            label="Comentarios"
                            placeholder="comentarios"
                            name="comentarios"
                            value={values.comentarios}
                            onChange={handleChange}
                        />
                    </div>
                    {
                        !sindicadoConocido &&
                        <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                            <Alert type="warning" mensaje="No puede avanzar en el proceso penal si el sindicado no es individualizado" />
                        </div>
                    }
                    {
                        (isSubmitting) ? <Spinner /> :
                            sindicadoConocido &&
                            <ButtonSiavi
                                type="submit"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    values.interponentes = selectInterponentes;
                                    values.etapa_procesal = props.registro?.tipo_audiencia?.etapa_proceso;
                                    await handleSubmit();
                                }}
                                text="Guardar"
                            />
                    }
                </form>
            )}
        </Formik>
    );
};
