/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import {
    getConsentimientos,
    getCasoPersonaConsentimientos,
    postCasoPersonaConsentimientos,
    putCasoPersonaConsentimientos,
    deleteCasoPersonaConsentimientos
} from "./services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { TrashIcon, PencilAltIcon, PrinterIcon, PaperClipIcon } from "@heroicons/react/solid";
import { GestionArchivos } from "../../../../../../components/ArchivosSiavi/archivos";
import Label from "../../../../../../components/common/Label";
import Spinner from "../../../../../../components/common/Spinner";
import PdfViewer from "../../../../../../components/common/PdfViewer";
import ModalGenerico from "../../../../../../components/common/Modal";
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import BotonesConfirmar from "../../../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import * as Yup from "yup";

const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full
sm:text-sm  rounded-md bg-white text-maivi-primaryFont`;

const validaciones = Yup.object().shape({
    tipo_consentimiento: Yup.string().required("tipo consentimiento es requerido")
});

const useFetchConsentimientos = (token) => {
    const [state, setState] = useState({
        consentimientos: [],
    });

    useEffect(() => {
        getConsentimientos(token).then((data) => {
            setState({
                consentimientos: data,
            });
        });
    }, [token]);

    return state;
};

const useFetchCasoPersona = (idCasoPersona, token, refetch) => {
    const [state, setState] = useState({
        casoPersonaConsentimientos: [],
    });

    useEffect(() => {
        getCasoPersonaConsentimientos(idCasoPersona, token).then((data) => {
            setState({
                casoPersonaConsentimientos: data,
            });
        });
    }, [token, refetch]);

    return state;
};

export const AgregarConsentimiento = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user.username);
    const [refetch, setRefetch] = useState(false);
    const [cargando, setCargando] = useState(false);
    const { consentimientos } = useFetchConsentimientos(token);
    const { casoPersonaConsentimientos } = useFetchCasoPersona(props.idCasoPersona, token, refetch);
    const [dataPreview, setDataPreview] = useState(false);
    const [editar, setEditar] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [id, setId] = useState(0);
    const [registro, setRegistro] = useState({});
    const [archivos, setArchivos] = useState(false);


    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAV";  //mm permiso padre

    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
        let retorno = false;
        permisosRegistro?.forEach(menu => {
            if (menu?.permiso === permisoView) {
                menu?.opciones?.forEach(op => {
                    if (op.permiso === permiso) {
                        retorno = op.ver;
                    }
                })
            }
        });

        //        retorno = false;  // mm comentar para que funcione
        //               retorno = true;  // mm comentar para que funcione
        return retorno;

    }


    return (
        <div>
            <Formik
                initialValues={{
                    tipo_consentimiento: "",
                    servicio: props.servicio,
                    observaciones: "",
                    idPlan: 0
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (editar) {
                        await putCasoPersonaConsentimientos(values, props.idCasoPersona, token).then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                                setCargando(false);
                                toast.success("Registro actualizado con éxito...!");
                                setRefetch(true);
                                setRefetch(false);
                                setEditar(false);
                                resetForm({});
                            } else {
                                setRefetch(true);
                                setRefetch(false);
                                toast.success("Registro actualizado con éxito...!");
                            }
                        })
                            .catch(function (error) {
                                if (
                                    error.response.status === 400 ||
                                    error.response.status === 401
                                ) {
                                    setCargando(false);
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error(
                                        "Error interno comuniquese con el administrador"
                                    );
                                }
                            });
                    } else {
                        setCargando(true);
                        await postCasoPersonaConsentimientos(values, props.idCasoPersona, token).then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                                setCargando(false);
                                toast.success("Registro creado con éxito...!");
                                setRefetch(true);
                                setRefetch(false);
                                resetForm({});
                            } else {
                                setRefetch(true);
                                setRefetch(false);
                                toast.success("Registro creado con éxito...!");
                            }
                        })
                            .catch(function (error) {
                                if (
                                    error.response.status === 400 ||
                                    error.response.status === 401
                                ) {
                                    setCargando(false);
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error(
                                        "Error interno comuniquese con el administrador"
                                    );
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="grid grid-cols-12 gap-2 mb-2">
                                <div className="col-span-12 xl:col-span-5 lg:col-span-5 md:col-span-5 border p-3 hover:shadow-md rounded-md">
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-4 md:col-span-6">
                                        <Label text="Tipo consentimiento" />
                                        <select
                                            name="tipo_consentimiento"
                                            value={values.tipo_consentimiento}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value={null}>Elegir consentimiento</option>
                                            {consentimientos?.results?.filter((x) => x?.servicio?.id === props.servicio).map((item) => {
                                                return (
                                                    <option key={item.nombre + item.id} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <ErrorMessage
                                            name="tipo_consentimiento"
                                            component="span"
                                            className="text-xs text-maivi-secondaryRed"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-9 lg:col-span-9 md:col-span-8 mt-2 mb-2">
                                        <Label text="Observaciones" />
                                        <InputSiavi
                                            name="observaciones"
                                            value={values.observaciones}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 flex items-end ">
                                        {(CheckPermiso('GuardarConsentimiento') === true) &&
                                            <ButtonSiavi
                                                text={(editar) ? 'Actualizar' : 'Guardar'}
                                                onClick={handleSubmit}
                                            />
                                        }
                                    </div>
                                    <table className="table w-full border mt-3">
                                        <thead className="bg-maivi-gray-5 rounded-md">
                                            <tr>
                                                <th className={props.thClass}>Nombre</th>
                                                <th className={props.thClass}>Servicio</th>
                                                <th className={props.thClass}>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                                            {casoPersonaConsentimientos.results?.filter((x) => x?.servicio?.ps === true).map((item) => (
                                                <tr key={item?.servicio?.nombre + item.id} className="hover:bg-maivi-gray-5 cursor-pointer">
                                                    <td className={props.tdClass}>
                                                        {`${item?.caso_persona?.persona?.primer_nombre} ${item?.caso_persona?.persona?.primer_apellido}`}
                                                    </td>
                                                    <td className={props.tdClass}>
                                                        {item?.servicio?.nombre}
                                                    </td>
                                                    <td className={props.tdClass}>
                                                        {(CheckPermiso('EditarConsentimiento') === true) &&
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setFieldValue('tipo_consentimiento', item?.consentimiento?.id);
                                                                    setFieldValue('servicio', item?.servicio?.id);
                                                                    setFieldValue('idPlan', item.id);
                                                                    setFieldValue('observaciones', item?.observaciones);
                                                                    setEditar(true);
                                                                }}
                                                                className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                            >
                                                                <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                            </button>
                                                        }
                                                        {(CheckPermiso('EliminarConsentimiento') === true) &&
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setBorrar(true);
                                                                    setId(item.id);
                                                                }}
                                                                className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                            >
                                                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                            </button>
                                                        }
                                                        {(CheckPermiso('ImprimirConsentimiento') === true) &&
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    try {
                                                                        const numero_caso = item?.caso_persona?.caso?.numero_caso;
                                                                        let primer_nombre = item?.caso_persona?.persona?.primer_nombre;
                                                                        let segundo_nombre = item?.caso_persona?.persona?.segundo_nombre;
                                                                        let primer_apellido = item?.caso_persona?.persona?.primer_apellido;
                                                                        let segundo_apellido = item?.caso_persona?.persona?.segundo_apellido;
                                                                        const persona = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`;
                                                                        const identificacion_persona = item?.caso_persona?.persona?.no_documento;
                                                                        let tipo_persona = "";
                                                                        let data = item?.consentimiento?.consentimiento;
                                                                        let es_nna = item?.consentimiento?.es_nna;
                                                                        let delitos = '';

                                                                        if (props.delitos) {
                                                                            props.delitos.forEach(delito => {
                                                                                delitos = delitos + ' ' + delito.nombre;
                                                                            });
                                                                        }

                                                                        try {
                                                                            let expediente = props.expediente;
                                                                            const servicio_expediente = expediente?.servicio?.nombre;
                                                                            const numero_denuncia = expediente?.caso_persona?.caso?.no_denuncia;
                                                                            const organo_jurisdiccional = '';
                                                                            const causa_penal = '';
                                                                            const profesional_asignado = '';

                                                                            data = data.replace('{profesional_asignado}', profesional_asignado);
                                                                            data = data.replace('{numero_denuncia}', numero_denuncia);
                                                                            data = data.replace('{organo_jurisdiccional}', organo_jurisdiccional);
                                                                            data = data.replace('{servicio_expediente}', servicio_expediente);
                                                                            data = data.replace('{causa_penal}', causa_penal);

                                                                        } catch (error) {
                                                                            console.log(error);
                                                                        }

                                                                        data = data.replace('{numero_caso}', numero_caso);
                                                                        data = data.replace('{delitos}', delitos);

                                                                        if (es_nna === false) {
                                                                            tipo_persona = 'Querellante'
                                                                            data = data.replace('{tipo_persona}', tipo_persona);
                                                                            data = data.replace('{persona}', persona);
                                                                            data = data.replace('{identificacion_persona}', identificacion_persona);
                                                                        } else if (es_nna === true) {

                                                                            data = data.replace('{nombre_victima}', persona);
                                                                            data = data.replace('{nombre_victima}', persona);
                                                                            data = data.replace('{identificacion_victima}', identificacion_persona);

                                                                            if (item.caso_persona.querellante) {
                                                                                data = data.replace('{tipo_persona}', 'Querellante');
                                                                                data = data.replace('{persona}', persona);
                                                                                data = data.replace('{identificacion_persona}', identificacion_persona);
                                                                            } else {
                                                                                let primer_nombre = item.representante?.primer_nombre;
                                                                                let segundo_nombre = item.representante?.segundo_nombre;
                                                                                let primer_apellido = item.representante?.primer_apellido;
                                                                                let segundo_apellido = item.representante?.segundo_apellido;
                                                                                const persona2 = `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`;
                                                                                const identificacion_persona2 = item.representante?.no_documento;

                                                                                data = data.replace('{tipo_persona}', 'Querellante');
                                                                                data = data.replace('{persona}', persona2);
                                                                                data = data.replace('{identificacion_persona}', identificacion_persona2);
                                                                            }

                                                                        }

                                                                        setDataPreview({
                                                                            titulo: item?.consentimiento?.titulo,
                                                                            html: data,
                                                                            fecha_creacion: item?.fecha_creacion,
                                                                            usuario: usuarioLogin
                                                                        })

                                                                    } catch (error) {
                                                                        setDataPreview("")
                                                                        console.log(error);
                                                                    }
                                                                }}
                                                                className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                                                            >
                                                                <PrinterIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                            </button>
                                                        }

                                                        {(CheckPermiso('AdjuntarAConsentimiento') === true) &&
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setId(item.id);
                                                                    setRegistro(item);
                                                                    setArchivos(true);
                                                                }}
                                                                className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                                                            >
                                                                <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-span-12 xl:col-span-7 lg:col-span-7 md:col-span-7 border p-2 hover:shadow-md rounded-md">
                                    {dataPreview &&
                                        <PdfViewer
                                            reporte={"consentimiento-persona"}
                                            data={dataPreview}
                                            className="alto-pdf"
                                        />
                                    }
                                </div>
                            </div>
                            {
                                cargando && <Spinner />
                            }
                        </div>
                        {isSubmitting && <Spinner />}
                        {borrar && (
                            <ModalGenerico
                                title="Esta seguro que desea eliminar el registro?"
                                open={borrar}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setBorrar(false);
                                }}
                                children={
                                    <BotonesConfirmar
                                        aceptar={async () => {
                                            setCargando(true);
                                            await deleteCasoPersonaConsentimientos(id, token).then((data) => {
                                                if (data?.status === 200 || data?.status === 201) {
                                                    setCargando(false);
                                                    toast.success("Registro borrado con éxito...!");
                                                    setRefetch(true);
                                                    setRefetch(false);

                                                } else {
                                                    setCargando(false);
                                                    setRefetch(true);
                                                    setRefetch(false);
                                                    setBorrar(false);
                                                    toast.success("Registro borrado con éxito...!");
                                                }
                                            })
                                                .catch(function (error) {
                                                    if (
                                                        error.response.status === 400 ||
                                                        error.response.status === 401
                                                    ) {
                                                        setCargando(false);
                                                        toast.error(error.response.data.message);
                                                    } else if (error.response.status >= 500) {
                                                        toast.error(
                                                            "Error interno comuniquese con el administrador"
                                                        );
                                                    }
                                                });
                                        }}
                                        cancelar={() => {
                                            setBorrar(false);
                                        }}
                                    />
                                }
                            />
                        )}

                        {archivos && (
                            <ModalAmplio
                                title={`Adjuntar archivos`}
                                open={true}
                                closeModal={() => { }}
                                cerrar={() => {
                                    setArchivos(false);
                                }}
                                icon={
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <PaperClipIcon className="h-6 w-6 text-green-600" />
                                    </div>
                                }
                                children={
                                    <GestionArchivos
                                        idRecurso={id}
                                        recurso="CONSENTIMIENTOINFORMADO"
                                        claseDocumento="consentimientoinformado"
                                        caso={registro?.caso_persona?.caso?.id}
                                        expediente={null}
                                    />
                                }
                            />
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
};
