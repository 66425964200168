/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { acciones, exportarData, getAcciones, getResumen, putAcciones } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { FilterIcon, RefreshIcon, UserIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { clases } from "../../../helpers/clases";
import { FormDetalle } from "./Detalle";
import { Gestionar } from "./gestionar";
import { Gestiones } from "./gestiones";
import {
  ClockIcon,
  PlusCircleIcon,
  XCircleIcon,
  CheckIcon,
  LockClosedIcon
} from "@heroicons/react/solid";
import TableGeneric from "../../../components/common/TableGeneric";
import ModalGenerico from "../../../components/common/Modal";
import ModalBig from "../../../components/common/ModalTwo";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import moment from "moment";
import ExportButton from "../../../components/common/ExportButton";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";

const tableColumnCompletadas = [
  { title: "No.", value: "id", tipo: "text" },
  { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  {
    title: "Nombre Víctima",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Atención", value: "accion_seguimiento.atencion.nombre", tipo: "text" },
  { title: "Tipo acción", value: "accion_seguimiento.nombre", tipo: "text" },
  { title: "Fecha planificada", value: "fecha_hora_planificada_inicia", tipo: "datetime" },
  { title: "Fecha completada", value: "fecha_completado", tipo: "date" },
  {
    title: "Profesional realiza",
    value: null,
    values: [
      "profesional_realiza.first_name",
      "profesional_realiza.last_name"
    ],
    tipo: "text"
  }
];

const tableColumnPorVencer = [
  { title: "No.", value: "id", tipo: "text" },
  { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  {
    title: "Nombre Víctima",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Atención", value: "accion_seguimiento.atencion.nombre", tipo: "text" },
  { title: "Tipo acción", value: "accion_seguimiento.nombre", tipo: "text" },
  { title: "Fecha plan inicia", value: "fecha_hora_planificada_inicia", tipo: "datetime" },
  { title: "Fecha plan fin", value: "fecha_hora_planificada_fin", tipo: "datetime" },
  {
    title: "Profesional asignado",
    value: null,
    values: [
      "profesional_asignado.first_name",
      "profesional_asignado.last_name"
    ],
    tipo: "text"
  }
];

export const TableAtencionAcciones = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const user = useSelector((state) => state.loginReducer.data.usuario.user);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [order, setOrder] = useState("-completada&-fecha_completado&-fecha_hora_planificada_inicia");
  const [endpoint, setEndpoint] = useState("accionesseguimientoscaso");
  const [resumen, setResumen] = useState({});
  const [detalle, setDetalle] = useState(false);
  const [registro, setRegistro] = useState({});
  const [gestionar, setGestionar] = useState(false);
  const [tipo, setTipo] = useState("");
  const [addGestionar, setAddGestionar] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [gestiones, setGestiones] = useState(false);
  const [cancelar, setCancelar] = useState(false);
  const [cargando, setCargando] = useState(false);
  const permiso = "accionesseguimientoscaso";



  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "AtencionesComplementarias";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  const [columnas, setTableColumns] = useState({
    columns: tableColumnCompletadas
  });

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, token).then(async (data) => {
      const fileName = 'Atenciones Complementarias';

      let dataExport = data;
      let columns = [
        { name: "ID", value: "id" },
        { name: "Número de expediente IV - Víctima", value: "caso" },
        { name: "Tipo de acción victimológica", value: "accion_seguimiento" },
        { name: "Víctima/querellante", value: "victima" },
        { name: "Profesional asignado", value: "profesional_asignado" },
        { name: "Sede", value: "sede_accion" },
        { name: "Fecha de creación de acción victimológica", value: "fecha_creacion", tipo: 'fecha' },
        { name: "Fecha de cierre de acción victimológica", value: "fecha_completado", tipo: 'fecha' },
        { name: "Accion Victimológica completada", value: "completada", tipo: 'bool' },
        { name: "Fecha hora planificada inicia", value: "fecha_hora_planificada_inicia", tipo: 'fechahora' }

        /*{ name: "Accion planificada", value: "accion_planificada" },
        { name: "Accion servicio relacion", value: "accion_servicio_relacion" },
        { name: "Accion servicio solicitado", value: "accion_servicio_solicitado" },
        { name: "Asignar accion", value: "asignar_accion" },
        { name: "Autorizado", value: "autorizado" },
        { name: "Causa penal", value: "causa_penal" },
        { name: "Departamento", value: "departamento" },
        { name: "Detalle lugar", value: "detalle_lugar" },
        { name: "Es accion", value: "es_accion" },
        { name: "Es audiencia", value: "es_audiencia" },
        { name: "Es nna", value: "es_nna" },
        { name: "Es seguimiento", value: "es_seguimiento" },
        { name: "Especificaciones modalidad", value: "especificaciones_modalidad" },
        { name: "Estado", value: "estado" },
        { name: "Expediente", value: "expediente" },
        { name: "Fecha accion", value: "fecha_accion" },
        { name: "Fecha asigna", value: "fecha_asigna" },
        { name: "Fecha autoriza", value: "fecha_autoriza" },
        { name: "Fecha hora fin", value: "fecha_hora_fin" },
        { name: "Fecha hora inicia", value: "fecha_hora_inicia" },
        { name: "Fecha hora planificada fin", value: "fecha_hora_planificada_fin" },
        { name: "Fecha hora planificada inicia", value: "fecha_hora_planificada_inicia" },
        { name: "Fecha requerido", value: "fecha_requerido" },
        { name: "Gestion institucion", value: "gestion_institucion" },
        { name: "Gestionado", value: "gestionado" },
        { name: "Gestionar", value: "gestionar" },
        { name: "Habilita planificacion", value: "habilita_planificacion" },
        { name: "Modalidad", value: "modalidad" },
        { name: "Motivo cambio fecha", value: "motivo_cambio_fecha" },
        { name: "Municipio", value: "municipio" },
        { name: "Nombramiento", value: "nombramiento" },
        { name: "Observaciones", value: "observaciones" },
        { name: "Observaciones gestiona", value: "observaciones_gestiona" },
        { name: "Orientacion", value: "orientacion" },
        { name: "Persona", value: "persona" },
        { name: "Profesional asigna", value: "profesional_asigna" },
        { name: "Profesional autoriza", value: "profesional_autoriza" },
        { name: "Profesional crea", value: "profesional_crea" },
        { name: "Profesional realiza", value: "profesional_realiza" },
        { name: "Querellante", value: "querellante" },
        { name: "Referencia", value: "referencia" },
        { name: "Requiere asignacion", value: "requiere_asignacion" },
        { name: "Requiere autorizacion", value: "requiere_autorizacion" },
        { name: "Requiere nombramiento", value: "requiere_nombramiento" },
        { name: "Requiere recurso", value: "requiere_recurso" },
        { name: "Resultado audiencia", value: "resultado_audiencia" },
        { name: "Seguimiento", value: "seguimiento" },
        { name: "Tipo", value: "tipo" },
        { name: "Tipo audiencia", value: "tipo_audiencia" },*/
      ]
      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  useEffect(() => {
    setTableLoading(true);
    acciones(urlFetch, endpoint, filters, order, token).then((data) => {
      data?.results?.forEach(element => {
        if (element.es_vencida) {
          element.fondo = 'bg-red-50'
        } else if (element.es_por_vencer) {
          element.fondo = 'bg-yellow-50'
        } else if (element.completada) {
          element.fondo = ''
        }
      });
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, filters, sede, order, endpoint, refetch]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getAcciones(urlFetch, search, endpoint, filters, order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, filters, sede, order, endpoint]);

  useEffect(() => {
    getResumen('es_accion', token).then((data) => {
      setResumen(data);
    })
  }, []);

  const pages = [
    // {
    //   name: "Funcionalidades",
    //   href: "/funcionalidades-generales",
    //   current: false,
    // },
    { name: `Atenciones complementarias ${tipo}`, href: "#", current: true },
  ];

  const handleVer = (registro) => {
    setRegistro(registro);
    setDetalle(true);
  }

  const handleCancelar = (registro) => {
    if (registro?.estado && registro?.profesional_asignado?.id === user.id) {
      setRegistro(registro);
      setCancelar(true);
    } else {
      toast.error("El registro debe estar activo y debe de estar asignado a la persona que intenta hacer la cancelación")
    }
  }

  const opcion = (registro) => {
    return (
      <button
        type="button"
        onClick={() => {
          setRegistro(registro);
          setGestionar(true);
        }}
        className={clases.addIcon}
      >
        <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
        <span className="tooltiptext">Gestionar</span>
      </button>
    )
  }

  const contador =
    <div className="flex flex-row border rounded-md hover:shadow-md p-1">
      {(CheckPermiso('VerAccionesVencidas') === true) &&
        <button
          type="button"
          className="bg-red-400 items-center p-1 border border-transparent rounded-md tooltip mx-1"
          onClick={async () => {
            setGestiones(false);
            //         setAddGestionar(true);
            setAddGestionar(CheckPermiso('GestionarAccionesVencidas') === true);//mm permiso agregado        
            setTipo("vencidas");
            setUrlFech(undefined);
            setFilters("");
           setEndpoint("accionesseguimientoscasovencidas");
           setFilters(`&accion_seguimiento_atencionisnull=false`);
           setTableColumns({
              columns: tableColumnPorVencer
            });
          }}
        >
          <div className="flex flex-row justify-end items-center">
            <p className="text-base text-transparent">{resumen?.vencidas}</p>
            <p className="text-base text-white"></p>
            <XCircleIcon className="h-5 w-5 text-white" />
          </div>
          <span className="tooltiptext text-xs">Vencidas</span>
        </button>
      }
      {(CheckPermiso('VerAccionesPlanificadas') === true) &&
        <button
          type="button"
          className={`bg-yellow-300 items-center p-1 rounded-md tooltip mx-1`}
          onClick={() => {
            setGestiones(false);
            //         setAddGestionar(true);
            setAddGestionar(CheckPermiso('GestionarAccionesPlanificadas') === true);//mm permiso agregado        
            setTipo("planificadas");
            setUrlFech(undefined);
            setFilters("");
            setEndpoint("accionesseguimientoscasoporvencer");
            setTableColumns({
              columns: tableColumnPorVencer
            });
          }}
        >
          <div className="flex flex-row justify-end items-center">
            <p className="text-base text-transparent">{resumen?.por_vencer}</p>
            <ClockIcon className="h-5 w-5 text-white" />
          </div>
          <span className="tooltiptext text-xs">Planificadas</span>
        </button>
      }
      {(CheckPermiso('VerAccionesCompletadas') === true) &&
        <button
          type="button"
          className="bg-maivi-primaryBlue items-center p-1 border border-transparent rounded-md tooltip mx-1"
          onClick={() => {
            setGestiones(false);
            setAddGestionar(false);
            setTipo("completadas");
            setUrlFech(undefined);
            setEndpoint("accionesseguimientoscasocompletadas");
            setTableColumns({
              columns: tableColumnCompletadas
            });
          }}
        >
          <CheckIcon className="h-5 w-5 text-white" />
          <span className="tooltiptext text-xs">Completadas</span>
        </button>
      }
      {(CheckPermiso('VerAccionesporAutorizar') === true) &&
        <button
          type="button"
          className="bg-maivi-primaryBlue items-center p-1 border border-transparent rounded-md tooltip mx-1"
          onClick={() => {
            setGestiones(true);
            //       setAddGestionar(false);          
            setAddGestionar(CheckPermiso('GestionarAccionesporAutorizar') === true);//mm permiso agregado        
            setTipo("por autorizar");
            setUrlFech(undefined);
            setFilters("");
            setEndpoint("accionesseguimientoscaso");
            //setFilters(`&gestionar=true&fecha_hora_planificada_inicia__gte=${moment().format("YYYY-MM-DD")}`);
            setFilters(`&gestionar=true&estado=true&completada=false`);
            setTableColumns({
              columns: tableColumnCompletadas
            });
          }}
        >
          <div className="flex flex-row justify-end items-center">
          <p className="text-base bg-maivi-primaryBlue">{resumen?.por_autorizar}</p>
            <LockClosedIcon className="h-5 w-5 text-white" />
          </div>
          <span className="tooltiptext text-xs">por autorizar</span>
        </button>
      }
      {(CheckPermiso('VerAccionesporAsignar') === true) &&
        <button
          type="button"
          className="bg-maivi-primaryBlue items-center p-1 border border-transparent rounded-md tooltip mx-1"
          onClick={() => {
            setGestiones(true);
            //setAddGestionar(false);
            setAddGestionar(CheckPermiso('GestionarAccionesporAutorizar') === true);//mm permiso agregado        
            setTipo("por asignar");
            setUrlFech(undefined);
            //setFilters(`&profesional_asignado__isnull=true&requiere_asignacion=true&fecha_hora_planificada_inicia__gte=${moment().format("YYYY-MM-DD")}`);
            setFilters(`&profesional_asignado__isnull=true&completada=false&estado=true&requiere_asignacion=true`);
            setEndpoint("accionesseguimientoscaso");
            setTableColumns({
              columns: tableColumnCompletadas
            });
          }}
        >
          <div className="flex flex-row justify-end items-center">
            <p className="text-base bg-maivi-primaryBlue">{resumen?.pendientes_asignar}</p>
            <UserIcon className="h-5 w-5 text-white" />
          </div>
          <span className="tooltiptext text-xs">por asignar</span>
        </button>
      }
    </div>

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        {!props.ocultar &&
          <div className="hidden xl:flex">
            <BreadCrumb navegacion={pages} />

          </div>
        }
        {contador}
        <div>
          <ButtonAdd
            className="ml-1"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-1 -mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-1"
            text="Refrescar"
            type="button"
            onClick={() => {
              setTipo("");
              setAddGestionar(false);
              setEndpoint("accionesseguimientoscaso");
              setFilters("");
            }}
            icon={<RefreshIcon className="ml-1 -mr-1 h-5" />}
          />
          {(CheckPermiso('Exportar') === true) &&    //mm permiso agregado        
            <ExportButton
              handlePdf={() => {
                exportData('pdf');
              }}
              show={'xls'}
              handleExcel={() => {
                exportData('xls');
              }}
            />
          }
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <TableGeneric
            dataTable={dataTable}
            tableColumns={columnas.columns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={true}
            //           showCancelar={true}
            showCancelar={(CheckPermiso('Inactivaracciones') === true)}    //mm permiso agregado        
            order={order}
            setOrder={setOrder}
            handleVer={handleVer}
            handleCancelar={handleCancelar}
            addAccion={addGestionar}
            opcion1={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
      {detalle && (
        <ModalBig
          title={`Detalle`}
          open={detalle}
          closeModal={() => { }}
          cerrar={() => {
            setDetalle(false);
          }}
          children={
            <FormDetalle registro={registro} token={token} />
          }
        />
      )}
      {gestionar && (
        <ModalGenerico
          title={`Gestionar`}
          open={gestionar}
          closeModal={() => { }}
          cerrar={() => {
            setGestionar(false);
          }}
          children={
            (gestiones) ?
              <Gestiones
                registro={registro}
                setGestionar={setGestionar}
              /> :
              <Gestionar
                registro={registro}
                setGestionar={setGestionar}
              />
          }
        />
      )}

      {cancelar && (
        <ModalGenerico
          title={`Si cancela esta accion no se podra revertir, ¿Está seguro que quiere cancelar el registro?`}
          open={cancelar}
          closeModal={() => { }}
          cerrar={() => {
            setCancelar(false);
          }}
          children={
            <BotonesConfirmar
              isloading={cargando}
              aceptar={async () => {
                setCargando(true);
                let body = {
                  estado: false,
                  profesional_cambia_estado: user.id,
                  fecha_cambia_estado: moment(),
                  accion_seguimiento: registro?.accion_seguimiento?.id,
                  fecha_requerido: registro?.fecha_requerido,
                }

                await putAcciones(registro.id, body, token).then((data) => {
                  if (data?.status >= 200 && data?.status <= 210) {
                    toast.success('Seguimiento cancelado éxito');
                    setCargando(false);
                    setCancelar(false);
                    setRefetch(true);

                    setTimeout(() => {
                      setRefetch(false);
                    }, 300);
                  }
                })
                  .catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 410) {
                      toast.error(error.response.data.message);
                      setCargando(false);
                    } else if (error.response.status >= 500) {
                      toast.error('Error interno comuniquese con el administrador');
                      setCargando(false);
                    }
                  });
              }}
              cancelar={() => {
                setCancelar(false);
              }}
            />
          }
        />
      )}
    </div>
  );
};
