/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { getSentencias,putExpediente } from "./services";
import { SelectSiavi } from "../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../components/common/Button";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    sentencia: Yup.string().required("sentencia es requerida"),
});

export const ListadoSentencias = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [sentencias, setSentencias] = useState([]);

    useEffect(() => {
        getSentencias(props.registro.caso, token).then((data) => {
            setSentencias(data)
        });
    }, []);

    return (
        <Formik
            initialValues={{
                sentencia: "",
                sentencia_externa: false,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await putExpediente(props.registro.id, values, token)
                    .then(async (data) => {
                        if (data?.status >= 200 || data?.status <= 210) {
                            toast.success("Sentencia agregada con éxito..!");
                            props.setAddSentencia(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {({ values, handleSubmit,setFieldValue, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="border-gray-200 px-4 py-5 sm:p-0 mx-4">
                        <SelectSiavi
                            label="Seleccionar sentencia"
                            validacion={true}
                            value={values.sentencia}
                            name="sentencia"
                            valueInit=""
                            selectInit="Seleccionar sentencia"
                            onChange={(e)=>{
                                setFieldValue('sentencia',e.target.value);

                                sentencias?.results?.forEach(element => {
                                    if(element.id === Number(e.target.value)){
                                        setFieldValue('sentencia_externa',element?.sentencia_externa);
                                    }
                                });
                            }}
                            list={
                                sentencias?.results?.map((item, index) => {
                                    return (
                                        <option key={item.numero_sentencia + index} value={item.id}>
                                            {item.numero_sentencia}
                                        </option>
                                    );
                                })
                            }
                        />
                        <div className="flex flex-row mt-3">
                            <ButtonSiavi
                                className="mr-2"
                                text="Guardar"
                                type="button"
                                onClick={handleSubmit}
                            />
                            <ButtonSiavi
                                className="bg-maivi-primaryLightBlue"
                                text="Crear nueva sentencia"
                                type="button"
                                onClick={() => {
                                    props.setAgregarSentencia(true);
                                    props.setAddSentencia(false);
                                }}
                            />

                        </div>
                        {isSubmitting && <Spinner />}
                    </div>
                </form>
            )}
        </Formik>
    )
};
