import { useEffect, useState } from "react";
import {
  getDetalleReparacionDigna,
  getMedidasVictimologicasPersona,
  getStatusMedidas,
} from "../services";
import { GearIcon, TimeLine } from "../../../../../helpers/icons";
import { Acciones } from "../../../../funcionalidades-generales/casos/editar/atencion_victimologica/trabajo_social/Acciones";
import {
  EyeIcon,
  PaperClipIcon,
  PencilAltIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import { MedidaVictimologica } from "./medida_victimologica";
import { HistorialRD } from "./historial_reparaciones_dignas";
import { HistorialMV } from "./historial_medidas_victimologicas";
import { Tab } from "@headlessui/react";
import { Seguimientos } from "../../../orientacion/editar/procesos/Seguimientos";
import { ModificarStatusRD } from "./modificar_status_rd";
import { ModificarStatusMV } from "./modificar_status_mv";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalTwo from "../../../../../components/common/ModalTwo";
import moment from "moment";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import TableReparacionDigna from "../../../../../components/common/TableReparacionDigna";
import PdfViewer from "../../../../../components/common/PdfViewer";
import { DetalleRD } from "./detalleRD";
import { useSelector } from "react-redux";
import { getPersonasCasoDetails } from "../../../orientacion/listar/services";
import { DetalleVM } from "./detalleVM";
import { getReparacionDignaSindicadoDelito } from "../../../orientacion/editar/litigio_estrategico/services";

export const ReparacionesDignas = ({ caso, token }) => {
  const [dateFetch, setDate] = useState(new Date());
  const [imprimirData, setImprimirData] = useState(false);
  const [agregarMedidaVictimologica, setAgregarMedidaVictimologica] =
    useState(false);
  const [mostrarHistorialRD, setMostrarHistorialRD] = useState(false);
  const [mostrarHistorialVM, setMostrarHistorialVM] = useState(false);
  const [seguimientosRD, setSeguimientosRD] = useState(false);
  const [medidasVictimologicas, setMedidasVictimologicas] = useState([]);
  const [persona, setPersona] = useState({});
  const [detalleReparacionesDignas, setDetalleReparacionesDignas] = useState(
    []
  );

  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user.email
  );

  const [state, setState] = useState({
    cargandoReparacionesDignas: false,
    cargandoMedidasVictimologicas: false,
    agregarAccionRD: false,
    agregarAccionMV: false,
    serviciosInternos: [],
    nombrePersona: "",
    reparacionSeleccionada: {},
    medidaSeleccionada: {},
    cambiarStatusRD: false,
    cambiarStatusVM: false,
    statusMedidas: [],
    infoExpediente: {},
    gestionArchivosReparacionesDignas: false,
    gestionArchivosMedidasVictimologicas: false,
    urlReporte: "",
    tituloReporte: "",
    mostrarDetalle: false,
    mostrarDetalleMV: false,
    urlReporte: '',
    tituloReporte: '',
    mostrarDetalle: false,
  });


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro

  const permisoView = "Expedientes";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      //console.log("militza",menu)
      if (menu?.permiso === permisoView || menu?.text === permisoView) {
        //console.log("militza",menu)
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });

      //      retorno = true;  // mm comentar para que funcione
    //      retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  useEffect(async () => {
    if (persona?.id) {
      setState({
        ...state,
        cargandoReparacionesDignas: true,
      });

      await getDetalleReparacionDigna(token, persona.id).then((data) => {
        setDetalleReparacionesDignas(data?.results);
        setState({
          ...state,
          cargandoReparacionesDignas: false,
        });
      });
    }
  }, [persona, dateFetch]);

  useEffect(() => {
    getStatusMedidas(token).then((data) => {
      setState({
        ...state,
        statusMedidas: data?.results,
      });
    });
  }, []);

  useEffect(async () => {
    if (persona.id) {
      setState({ ...state, cargandoMedidasVictimologicas: true });
      await getMedidasVictimologicasPersona(token, persona?.id).then((data) => {
        setMedidasVictimologicas(data?.results);
        setState({ ...state, cargandoMedidasVictimologicas: false });
      });
    }
  }, [persona.id, dateFetch]);

  const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
  const classAcciones = `p-1 tooltip hover:bg-gray-200 rounded-md`;
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const ButtonIcon = ({ icon, tooltip, clase, clickFunction }) => {
    return (
      <button
        type="button"
        className={clase}
        onClick={() => {
          clickFunction();
        }}
      >
        {icon}
        <span className="tooltiptext">{tooltip}</span>
      </button>
    );
  };
  return (
    <div className="grid grid-cols-12 gap-3 mt-2">
      <div className="col-span-12 xl:col-span-12 border p-3 rounded-md hover:shadow-sm">
        <div className="flex flex-row justify-between">
          <h1 className="mb-1 text-sm text-maivi-primaryFont">
            Personas asociadas al caso
          </h1>
        </div>
        <TableSiavi
          headers={[
            "Nombre",
            "No. Identificación",
            "Fecha nacimiento",
            "Acciones",
          ]}
          body={caso?.personas?.map((item) => (
            <tr
              key={item?.persona.primer_nombre}
              className="hover:bg-maivi-gray-5 cursor-pointer"
            >
              <td
                className={tdClass}
                onClick={() => {
                  setState({
                    ...state,
                    nombrePersona: `${item?.persona?.primer_nombre} ${item?.persona?.segundo_nombre} ${item?.persona?.primer_apellido} ${item?.persona?.segundo_apellido}`,
                  });

                  setPersona(item);
                }}
              >
                {item?.persona?.primer_nombre} {item?.persona?.segundo_nombre}{" "}
                {item?.persona?.primer_apellido}{" "}
                {item?.persona?.segundo_apellido}
              </td>
              <td className={tdClass}>{item?.persona?.no_documento}</td>
              <td className={tdClass}>
                          {item?.persona?.fecha_nacimiento
                            ? moment(item?.persona?.fecha_nacimiento).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                        </td>
                       <td className={tdClass}>
                <ButtonIcon
                  icon={
                    <PrinterIcon className="h-6 w-6 text-maivi-primaryFont" />
                  }
                  tooltip="imprimir medidas de reparación digna"
                  clase={classAcciones}
                  clickFunction={async () => {
                    await getPersonasCasoDetails([item.persona.id], token).then(
                      async (personas) => {
                        await getDetalleReparacionDigna(token, item.id).then(
                          (data) => {
                            setDetalleReparacionesDignas(data?.results);
                            setImprimirData({
                              reporte: "detalle-reparaciones-dignas",
                              detalleReparacionesDignas: data?.results,
                              expediente: item,
                              persona: personas.personas[0],
                              usuario: usuarioLogin,
                            });
                          }
                        );

                        setState({
                          ...state,
                          tituloReporte: "Reporte medidas de reparación digna",
                        });
                      }
                    );
                  }}
                />
                <ButtonIcon
                  icon={<GearIcon className="h-6 w-6 text-green-600" />}
                  tooltip="Agregar medida victimológica"
                  clase={classAcciones}
                  clickFunction={() => {
                    setPersona(item);
                    setState({
                      ...state,
                      nombrePersona: `${item?.persona?.primer_nombre} ${item?.persona?.segundo_nombre} ${item?.persona?.primer_apellido} ${item?.persona?.segundo_apellido}`,
                    });
                    setAgregarMedidaVictimologica(true);
                  }}
                />
                <ButtonIcon
                  icon={<PrinterIcon className="h-6 w-6 text-green-600" />}
                  tooltip="imprimir medidas victimologicas"
                  clase={classAcciones}
                  clickFunction={async () => {
                    await getPersonasCasoDetails([item.persona.id], token).then(
                      async (personas) => {
                        await getMedidasVictimologicasPersona(
                          token,
                          item?.id
                        ).then((data) => {
                          setImprimirData({
                            reporte: "detalle-medidas-victimologicas",
                            medidasVictimologicas: data?.results,
                            expediente: item,
                            persona: personas.personas[0],
                            usuario: usuarioLogin,
                          });
                        });
                      }
                    );

                    setState({
                      ...state,
                      urlReporteRD: "detalle-medidas-victimologicas",
                    });
                  }}
                />
              </td>
            </tr>
          ))}
        />
      </div>

      <div className="col-span-12">
        <Tab.Group>
        {(CheckPermiso('RDMedidasVictimologicas') === true) &&
          <Tab.List className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 bg-blue-50 rounded-md p-1 mb-2">
            {[
              `Medidas de reparación digna para: ${state.nombrePersona}`
              , `Medidas victimológicas para: ${state.nombrePersona}`,
            ].map((item) => {
              return (
                <Tab
                  key={item}
                  className={({ selected }) =>
                    classNames(
                      "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                      selected
                        ? "bg-white shadow"
                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                    )
                  }
                  onClick={() => {}}
                >
                  {item}
                </Tab>
              );
            })}
          </Tab.List>}
          {(CheckPermiso('RDMedidasVictimologicas') === false) &&
          <Tab.List className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 bg-blue-50 rounded-md p-1 mb-2">
            {[
              `Medidas de reparación digna para: ${state.nombrePersona}`
              //, `Medidas victimológicas para: ${state.nombrePersona}`,
            ].map((item) => {
              return (
                <Tab
                  key={item}
                  className={({ selected }) =>
                    classNames(
                      "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                      selected
                        ? "bg-white shadow"
                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                    )
                  }
                  onClick={() => { }}
                >
                  {item}
                </Tab>
              );
            })}
          </Tab.List>}
          <Tab.Panels>
            <Tab.Panel>
              <div className="col-span-12 xl:col-span-6 rounded-md hover:shadow-sm max-h-96 overflow-y-auto">
                <TableReparacionDigna
                  cargando={state.cargandoReparacionesDignas}
                  headers={[
                    "Fecha Reparación Digna",
                    "Medida de reparación digna",
                    "Estado",
                    "Comentario estado",
                    "Fecha estado",
                    "Acciones",
                  ]}
                  body={detalleReparacionesDignas
                    ?.filter((x) => x.medida_otorgada === true)
                    .map((item, index) => (
                      <tr key={item.persona_c + index}>
                        <td className={tdClass}>
                          {moment(
                            item?.expediente_reparacion_digna?.fecha
                          ).format("DD-MM-YYYY")}
                        </td>
                        <td className={tdClass}>
                          {item?.reparacion_digna?.nombre}
                        </td>
                        <td className={tdClass}>
                          {item?.status_medidarepdigna?.nombre}
                        </td>
                        <td className={tdClass}>{item?.comentario_status}</td>
                        <td className={tdClass}>
                          {item.fecha_modificacion
                            ? moment(item?.fecha_modificacion).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                        </td>

                        <td className={tdClass}>
                          <button
                            type="button"
                            className={classAcciones}
                            onClick={() => {
                              setState({
                                ...state,
                                reparacionSeleccionada: item,
                                mostrarDetalle: true,
                              });
                            }}
                          >
                            <EyeIcon className="h-6 w-6 text-maivi-primaryBlue" />
                            <span className="tooltiptext">Ver detalle</span>
                          </button>
                          <button
                            type="button"
                            className={classAcciones}
                            onClick={() => {
                              let infoExpediente = {
                                caso: item?.expediente_reparacion_digna?.caso
                                  ?.id,
                                servicio: {
                                  id: item?.expediente_reparacion_digna
                                    ?.expediente?.servicio,
                                },
                                id: item?.expediente_reparacion_digna
                                  ?.expediente?.id,
                                caso_persona: {
                                  persona: {
                                    id: item?.expediente_reparacion_digna
                                      ?.expediente?.servicio,
                                  },
                                  id: item?.expediente_reparacion_digna
                                    ?.expediente?.id,
                                  caso_persona: {
                                    persona: {
                                      id: item?.expediente_reparacion_digna
                                        ?.expediente?.caso_persona?.persona?.id,
                                    },
                                  },
                                }};
                                
                                setSeguimientosRD(true);
                                setState({
                                  ...state,
                                  reparacionSeleccionada: item,
                                  infoExpediente: infoExpediente,
                                });
                              }}
                            >
                              <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                              <span className="tooltiptext">
                                Agregar seguimientos legales
                              </span>
                          </button>
                          
                          {(CheckPermiso('RDIngresoAcciones') === true) &&
                            <button
                              type="button"
                              className={classAcciones}
                              onClick={() => {
                                let infoExpediente = {
                                  caso: item?.expediente_reparacion_digna?.caso
                                    ?.id,
                                  servicio: {
                                    id: item?.expediente_reparacion_digna
                                      ?.expediente?.servicio,
                                  },
                                  id: item?.expediente_reparacion_digna
                                    ?.expediente?.id,
                                  caso_persona: {
                                    persona: {
                                      id: item?.expediente_reparacion_digna
                                        ?.expediente?.caso_persona?.persona?.id,
                                    },
                                  },
                                };
                                setState({
                                  ...state,
                                  reparacionSeleccionada: item,
                                  infoExpediente: infoExpediente,
                                  agregarAccionRD: true,
                                });
                              }}
                            >
                              <GearIcon className="h-6 w-6 text-maivi-primaryFont" />
                              <span className="tooltiptext">Agregar acciones victimológicas</span>
                            </button>
                          }
                          {(CheckPermiso('RDCambiarEstado') === true) &&
                            <button
                              type="button"
                              className={classAcciones}
                              onClick={() => {
                                let infoExpediente = {
                                  caso: item?.expediente_reparacion_digna?.caso
                                    ?.id,
                                  servicio: {
                                    id: item?.expediente_reparacion_digna
                                      ?.expediente?.servicio,
                                  },
                                  id: item?.expediente_reparacion_digna
                                    ?.expediente?.id,
                                  caso_persona: {
                                    persona: {
                                      id: item?.expediente_reparacion_digna
                                        ?.expediente?.caso_persona?.persona?.id,
                                    },
                                  },
                                };

                                setState({
                                  ...state,
                                  infoExpediente: infoExpediente,
                                  cambiarStatusRD: true,
                                  reparacionSeleccionada: item,
                                });
                              }}
                            >
                              <PencilAltIcon className="h-6 w-6 text-yellow-400" />
                              <span className="tooltiptext">
                                Actualizar estatus
                              </span>
                            </button>
                          }
                          {(CheckPermiso('RDVerdetalleRD') === true) &&
                            <button
                              type="button"
                              className={classAcciones}
                              onClick={() => {
                                setMostrarHistorialRD(true);
                                setState({
                                  ...state,
                                  reparacionSeleccionada: item,
                                });
                              }}
                            >
                              <TimeLine className="h-6 w-6 text-green-400" />
                              <span className="tooltiptext">Ver historial</span>
                            </button>
                          }
                          {(CheckPermiso('RDArchivosAdjuntos') === true) &&
                            <button
                              type="button"
                              className={classAcciones}
                              onClick={() => {
                                setState({
                                  ...state,
                                  reparacionSeleccionada: item,
                                  gestionArchivosReparacionesDignas: true,
                                });
                              }}
                            >
                              <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont" />
                              <span className="tooltiptext">
                                Adjuntar archivos
                              </span>
                            </button>
                          }
                        </td>
                      </tr>
                    ))}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="col-span-12 xl:col-span-6 rounded-md hover:shadow-sm max-h-96 overflow-y-auto">
                <TableReparacionDigna
                  cargando={state.cargandoMedidasVictimologicas}
                  headers={[
                    "Fecha medida",
                    "Medida victimológica",
                    "Estado",
                    "Comentario estado",
                    "Fecha estado",
                    "Acciones",
                  ]}
                  body={medidasVictimologicas?.map((item, index) => (
                    <tr key={index}>
                      <td className={tdClass}>
                        {moment(item?.fecha).format("DD-MM-YYYY")}
                      </td>
                      <td className={tdClass}>
                        {item?.medida_victimologica?.nombre}
                      </td>
                      <td className={tdClass}>{item?.status?.nombre}</td>
                      <td className={tdClass}>{item?.comentario_estado}</td>
                      <td className={tdClass}>
                       
                        {item.fecha_modificacion
                            ? moment(item?.fecha_modificacion).format(
                                "DD-MM-YYYY"
                              )
                            : ""}


                      </td>
                      <td className={tdClass}>
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setState({
                              ...state,
                              medidaSeleccionada: item,
                              mostrarDetalleMV: true,
                            });
                          }}
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryBlue" />
                          <span className="tooltiptext">Ver detalle</span>
                        </button>
                        {/* <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setState({
                              ...state,
                              medidaSeleccionada: item,
                              agregarAccionVM: true,
                            });
                          }}
                        >
                          <GearIcon className="h-6 w-6 text-maivi-primaryFont" />
                          <span className="tooltiptext">Agregar acciones victimológicas</span>
                        </button> */}
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setState({
                              ...state,
                              medidaSeleccionada: item,
                              cambiarStatusVM: true,
                            });
                          }}
                        >
                          <PencilAltIcon className="h-6 w-6 text-yellow-400" />
                          <span className="tooltiptext">Actualizar estatus</span>
                        </button>
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setMostrarHistorialVM(true);
                            setState({
                              ...state,
                              medidaSeleccionada: item,
                            });
                          }}
                        >
                          <TimeLine className="h-6 w-6 text-green-400" />
                          <span className="tooltiptext">Ver historial</span>
                        </button>
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setState({
                              ...state,
                              medidaSeleccionada: item,
                              gestionArchivosMedidasVictimologicas: true,
                            });
                          }}
                        >
                          <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont" />
                          <span className="tooltiptext">Adjuntar archivos</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      {state.agregarAccionRD && (
        <ModalTwo
          title={`Agregar acción a ${state.reparacionSeleccionada.reparacion_digna.nombre}`}
          open={state.agregarAccionRD}
          closeModal={() => {}}
          cerrar={() => {
            setState({ ...state, agregarAccionRD: false });
          }}
          children={
            <Acciones
              infoExpediente={state.infoExpediente}
              esReparacionDigna={true}
            />
          }
        />
      )}

      {state.agregarAccionVM && (
        <ModalTwo
          title={`Agregar acción a ${state.medidaSeleccionada?.medida_victimologica?.nombre}`}
          open={state.agregarAccionVM}
          closeModal={() => {}}
          cerrar={() => {
            setState({ ...state, agregarAccionVM: false });
          }}
          children={
            <Acciones
              infoExpediente={state.infoExpediente}
              esMedidaVictimologica={true}
            />
          }
        />
      )}

      {agregarMedidaVictimologica && (
        <ModalGenerico
          title={"Agregar medida victimológica"}
          open={agregarMedidaVictimologica}
          closeModal={() => {}}
          cerrar={() => {
            setAgregarMedidaVictimologica(false);
          }}
          children={
            <MedidaVictimologica
              token={token}
              servicios={caso.expedientes}
              persona={persona}
              closeModal={setAgregarMedidaVictimologica}
              setDate={setDate}
            />
          }
        />
      )}

      {mostrarHistorialRD && (
        <ModalTwo
          title={`Historial reparación digna ${state.reparacionSeleccionada.reparacion_digna.nombre}`}
          open={mostrarHistorialRD}
          closeModal={() => {}}
          cerrar={() => {
            setMostrarHistorialRD(false);
          }}
          children={
            <HistorialRD
              token={token}
              persona={persona}
              reparacionDigna={state.reparacionSeleccionada}
            />
          }
        />
      )}

      {mostrarHistorialVM && (
        <ModalTwo
          title={`Historial medida victimológica ${state.medidaSeleccionada?.medida_victimologica?.nombre}`}
          open={mostrarHistorialVM}
          closeModal={() => {}}
          cerrar={() => {
            setMostrarHistorialVM(false);
          }}
          children={
            <HistorialMV
              token={token}
              persona={persona}
              medidaVictimologica={state.medidaSeleccionada}
            />
          }
        />
      )}

      {seguimientosRD && (
        <ModalTwo
          title={`Seguimientos ${state.reparacionSeleccionada?.reparacion_digna?.nombre}`}
          open={seguimientosRD}
          closeModal={() => {}}
          cerrar={() => {
            setSeguimientosRD(false);
          }}
          children={
            <Seguimientos
              token={token}
              infoExpediente={state.infoExpediente}
              esReparacionDigna={true}
            />
          }
        />
      )}

      {state.cambiarStatusRD && (
        <ModalGenerico
          title={`Cambiar estado de ${state.reparacionSeleccionada?.reparacion_digna?.nombre}`}
          open={state.cambiarStatusRD}
          closeModal={() => {}}
          cerrar={() => {
            setState({
              ...state,
              cambiarStatusRD: false,
            });
          }}
          children={
            <ModificarStatusRD
              token={token}
              reparacionDigna={state.reparacionSeleccionada}
              statusMedidas={state.statusMedidas}
              state={state}
              setState={setState}
              setDate={setDate}
            />
          }
        />
      )}

      {state.cambiarStatusVM && (
        <ModalGenerico
          title={`Cambiar estado de ${state.medidaSeleccionada?.medida_victimologica?.nombre}`}
          open={state.cambiarStatusVM}
          closeModal={() => {}}
          cerrar={() => {
            setState({
              ...state,
              cambiarStatusVM: false,
            });
          }}
          children={
            <ModificarStatusMV
              token={token}
              medidaVictimologica={state.medidaSeleccionada}
              statusMedidas={state.statusMedidas}
              state={state}
              setState={setState}
              setDate={setDate}
            />
          }
        />
      )}

      {state.gestionArchivosReparacionesDignas && (
        <ModalAmplio
          title={`Adjuntar archivos`}
          open={state.gestionArchivosReparacionesDignas}
          closeModal={() => {}}
          cerrar={() => {
            setState({
              ...state,
              gestionArchivosReparacionesDignas: false,
            });
          }}
          children={
            <GestionArchivos
              idRecurso={state.reparacionSeleccionada.id}
              token={token}
              recurso="REPARACIONDIGNA"
              claseDocumento="reparaciondigna"
            />
          }
        />
      )}

      {state.gestionArchivosMedidasVictimologicas && (
        <ModalAmplio
          title={`Adjuntar archivos`}
          open={state.gestionArchivosMedidasVictimologicas}
          closeModal={() => {}}
          cerrar={() => {
            setState({
              ...state,
              gestionArchivosMedidasVictimologicas: false,
            });
          }}
          children={
            <GestionArchivos
              idRecurso={state.medidaSeleccionada.id}
              token={token}
              recurso="MEDIDASVICTIMOLOGICAS"
              claseDocumento="medidasvictimologicas"
            />
          }
        />
      )}

      {imprimirData && (
        <ModalAmplio
          title={state.tituloReporte}
          open={true}
          closeModal={() => {
            setImprimirData(false);
          }}
          cerrar={() => {
            setImprimirData(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <PrinterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
          children={
            <PdfViewer
              data={imprimirData}
              reporte={imprimirData.reporte}
              style={{ height: "80vh" }}
            />
          }
        />
      )}

      {state.mostrarDetalle && (
        <ModalGenerico
          title={`Detalle de ${state.reparacionSeleccionada?.reparacion_digna?.nombre}`}
          open={state.mostrarDetalle}
          closeModal={() => { }}
          cerrar={() => {
            setState({
              ...state,
              mostrarDetalle: false,
            });
          }}
          children={<DetalleRD detalle={state.reparacionSeleccionada} />}
        />
      )}

      {state.mostrarDetalleMV && (
        <ModalGenerico
          title={`Detalle de ${state.medidaSeleccionada?.medida_victimologica?.nombre}`}
          open={state.mostrarDetalleMV}
          closeModal={() => {}}
          cerrar={() => {
            setState({
              ...state,
              mostrarDetalleMV: false,
            });
          }}
          children={<DetalleVM detalle={state.medidaSeleccionada} />}
        />
      )}
    </div>
  );
};
