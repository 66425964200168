import { React } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import {
    putNombramientos,
} from "./services";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import toast from "react-hot-toast";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextAreaSiavi } from "../../../components/common/TextArea";

export const Gestionar = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
    const nombramiento = props?.nombramiento;

    return (
        <div>
            <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-4">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Lugar Destino</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {nombramiento?.lugar_destino}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Diligencia a Realizar</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {nombramiento?.diligencia_realizar}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Usuario Requiere Nombramiento</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {nombramiento?.usuario_requiere_nombramiento?.first_name} {nombramiento?.usuario_requiere_nombramiento?.last_name}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Fecha Hora Salida</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {nombramiento?.fecha_hora_salida}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Fecha Hora Retorno</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {nombramiento?.fecha_hora_retorno}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <Formik
                initialValues={{
                    autorizado: nombramiento.autorizado,
                    estado: nombramiento?.estado
                }}
                onSubmit={async (values) => {
                    values.lugar_destino = nombramiento?.lugar_destino;
                    values.fecha = nombramiento?.fecha;
                    values.fecha_hora_salida = nombramiento?.fecha_hora_salida;
                    values.fecha_hora_retorno = nombramiento?.fecha_hora_retorno;
                    values.fecha_autorizado = nombramiento?.fecha_autorizado;
                    values.observaciones = nombramiento?.observaciones;
                    values.diligencia_realizar = nombramiento?.diligencia_realizar;
                    values.caso = nombramiento?.caso?.id;
                    values.expediente = nombramiento?.expediente?.id;
                    values.departamento = nombramiento?.departamento?.id;
                    values.municipio = nombramiento?.municipio?.id;
                    values.usuario_requiere_nombramiento = nombramiento?.usuario_requiere_nombramiento?.id;
                    values.usuario_autorizado = nombramiento?.usuario_autorizado?.id;
                    await putNombramientos(
                        nombramiento.id,
                        values,
                        token
                    ).then((data) => {
                        if (data?.message !== undefined) {
                            toast.error(data.message);
                        } else {
                            props.setReFetch(true);
                            props.setGestionar(false);
                            toast.success("Registro actualizado con éxito...!");
                            props.setReFetch(false);
                        }
                    });
                }}
            >
                {({ values, handleSubmit, isSubmitting, handleChange }) => (
                    <form>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 mt-5">
                                <TextAreaSiavi
                                    label="Observaciones"
                                    validacion={false}
                                    name="observaciones"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.observaciones}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-3 mt-5">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        values.autorizado = true;
                                        values.estado = true;
                                        values.usuario_autorizado = idUser;
                                        values.fecha_autorizado = new Date().getDate();
                                        handleSubmit();
                                    }}
                                >
                                    Autorizar
                                </button>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        values.autorizado = true;
                                        values.estado = false;
                                        handleSubmit();
                                    }}
                                >
                                    Rechazar
                                </button>
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                <button
                                    className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                                    onClick={() => {
                                        props.setGestionar(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                        {
                            values.autorizado &&
                            <div className="mt-4">
                                <Alert type="warning" mensaje="¿Una vez autorizado no podrá editarlo nuevamente desea continuar? " />
                            </div>
                        }
                        {
                            values.es_autorizado &&
                            <div className="mt-4">
                                <Alert type="warning" mensaje="Este registro ya fue autorizado" />
                            </div>
                        }
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
