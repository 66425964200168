export const FormDetalle = (props) => {
  return (
    <div>
      <div className="bg-white border rounded-md overflow-hidden sm:rounded-lg mt-4">
        <div className=" border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Nombre</dt>
              <dd className=" text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {`${props.detalle.first_name} ${props.detalle.last_name}`}
              </dd>
            </div>
            {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Telefono</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.telefono}
              </dd>
            </div> */}
            {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Correo</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.email}
              </dd>
            </div> */}
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">
                Tipo Puesto
              </dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props?.detalle?.tipo_puesto?.puesto}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Sub área</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props?.detalle?.tipo_puesto?.sub_area_detalle?.nombre}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Área</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props?.detalle?.tipo_puesto?.sub_area_detalle?.detalle_area?.nombre}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Estado</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.is_active === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Activo
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Inactivo
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">Administrador</dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.is_superuser === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Si
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    No
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">
                Usuario interno
              </dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.usuario_interno === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Si
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    No
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">
                Desbloqueado
              </dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.estado === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Activo
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Inactivo
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-xs font-medium text-gray-500">
                Sede Principal
              </dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                {props.detalle.sede.sede}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
