/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { CheckCircleIcon, FilterIcon, PaperClipIcon, RefreshIcon, XCircleIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../../components/common/Select";
import { TextAreaSiavi } from "../../../../../../components/common/TextArea";
import { FormDetalleAccion } from "./detalleAccion";
import { GestionAccionesRecursos } from "./gestionAccionRecursos";
import { GestionNombramiento } from "./gestionNombramiento";
import { bloqueoFecha, bloqueoFechaHora } from "../../../../../../helpers/utils";
import { GearIcon } from "../../../../../../helpers/icons";
import {
    deleteAcciones,
    postAcciones,
    putAcciones,
    getAcciones,
    getDeptosGestion,
    getMunicipiosGestion,
    getModalidades,
    getInstitucion,
    getProfesionales,
    getReferenciaCreada,
    getCatalogoAccionesSeguimientos,
} from "./services";
import Spinner from "../../../../../../components/common/Spinner";
import Label from "../../../../../../components/common/Label";
import Toggle from "../../../../../../components/common/Toggle";
import CustomTable from "../../../../../../components/common/Table";
import ModalGenerico from "../../../../../../components/common/Modal";
import FormEliminar from "../../../../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import moment from "moment";
import * as Yup from "yup";
// import makeAnimated from "react-select/animated";
import Select from "react-select";
import ButtonAdd from "../../../../../../components/common/ButtonAdd";
import Filtros from "./filtrosAcciones";
import { GestionArchivos } from "../../../../../../components/ArchivosSiavi/archivos";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Tipo accion", value: "accion_seguimiento.nombre", tipo: "text" },
    { title: "Fecha completada", value: "fecha_completado", tipo: "date" },
    {
        title: "Profesional realiza",
        value: null,
        values: [
            "profesional_realiza.first_name",
            "profesional_realiza.last_name"
        ],
        tipo: "text"
    },
    {
        title: "Completada",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

const validaciones = Yup.object().shape({
    seguimiento: Yup.string()
        .required("Acción es requerido")
});

export const Acciones = (props) => {
    const caso = props.infoExpediente?.caso;
    const servicio = props.infoExpediente?.servicio?.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    // const animatedComponents = makeAnimated();
    const [verDetalle, setDetalle] = useState(false);
    const [accionesDetalle, setAccionesDetalle] = useState({});
    const idExpediente = props?.infoExpediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAcciones, setIdAcciones] = useState("");
    const [acciones, setAcciones] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [deptos, setDeptos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [catalogoAS, setCatalogoAS] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [institucionesAgregados, setInstitucionesAgregados] = useState((isEdit === true) ? acciones?.institucion : []);
    const [profesionalesAsigna, setProfesionalesAsigna] = useState([]);
    const [referencia, setReferencia] = useState([]);
    const [setNombreDepartamento] = useState("");
    const [agregar, setAgregar] = useState(false);
    const [agregarNombramiento, setAgregarNombramiento] = useState(false);
    const [habilitaPlanificacion, setHabilitaPlanificacion] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [accionSolicitada, setAccionSolicitada] = useState(false);
    const [preguntaCompletado, setPreguntaCompletado] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [archivos, setArchivos] = useState(false);
    const [recurso, setRecurso] = useState("");
    const [filters, setFilters] = useState("");
    const [valor,setValor] = useState("");
    const optionsInstituciones = [];

    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data)
        });
    }, [token]);

    useEffect(() => {
        getCatalogoAccionesSeguimientos(token).then((data) => {
            if(props.esReparacionDigna){
                const result = data?.results?.filter(x => x.rep_digna === true);
                setCatalogoAS(result)

            } else if(props.esMedidaVictimologica){
                const result = data?.results?.filter(x => x.medida_victimologica === true);
                setCatalogoAS(result)

            } else {
                const result = data?.results?.filter((x) => x?.servicio?.id === props.infoExpediente.servicio.id);
                setCatalogoAS(result)
            }         
        });
    }, [token]);

    useEffect(() => {
        getInstitucion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    useEffect(() => {
        getProfesionales(servicio, token).then((data) => {
            setProfesionalesAsigna(data)
        });
    }, [token]);

    useEffect(() => {
        getReferenciaCreada(caso, servicio, token).then((data) => {
            setReferencia(data)
        });
    }, [token, caso, servicio]);

    useEffect(() => {
        getAcciones(urlFetch, idExpediente, filters,  token).then((acciones) => {
            setDataTable(acciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, urlFetch, filters, reFetch]);

    const changeSelectInstituciones = (e) => {
        setValor(e);
        setInstitucionesAgregados(e);
    };

    instituciones?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre,
        };
        optionsInstituciones.push(reg);
    });

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "accionesseguimientoscaso";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleAgregar = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregar(true);
        } else {
            toast.error("No es posible asignar recurso, porque ya está completado la acción")
        }
    };

    const handleAgregarNombramiento = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregarNombramiento(true);
        } else {
            toast.error("No es posible agregar nombramientos, porque ya está completado la acción")
        }
    };

    const handleVer = (item) => {
        setAccionesDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdAcciones(id);
        setEliminar(true);
    };

    const handleEditar = (acciones) => {
        if (acciones.completada === false) {
            setEdit(true);
            setAcciones(acciones);
        } else {
            toast.error("No es posible editar, porque ya está completado la acción")
        }
    };

    const handleArchivos = (recurso) => {
        setArchivos(true);
        setRecurso(recurso);
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    seguimiento: isEdit ? acciones?.seguimiento : "",
                    es_nna: isEdit ? acciones?.es_nna : false,
                    tipo_audiencia: isEdit ? acciones?.tipo_audiencia?.id : "",
                    fecha_accion: isEdit ? acciones?.fecha_accion : "",
                    fecha_asigna: isEdit ? acciones?.fecha_asigna : "",
                    requiere_autorizacion: false,
                    fecha_autoriza: isEdit ? acciones?.fecha_autoriza : "",
                    autorizado: isEdit ? acciones?.autorizado : false,
                    fecha_completado: isEdit ? acciones?.fecha_completado : "",
                    estado: isEdit ? acciones?.estado : true,
                    completada: isEdit ? acciones?.completada : false,
                    es_accion: true,
                    es_seguimiento: false,
                    requiere_nombramiento: false,
                    observaciones: isEdit ? acciones?.observaciones : "",
                    detalle_lugar: isEdit ? acciones?.detalle_lugar : "",
                    especificaciones_modalidad: isEdit ? acciones?.especificaciones_modalidad : "",
                    tipo: isEdit ? acciones?.tipo : "",
                    caso: props.infoExpediente?.caso,
                    expediente: idExpediente,
                    persona: props.infoExpediente?.caso_persona?.persona.id,
                    victima: (props.infoExpediente?.caso_persona?.victima === true) ? props.infoExpediente?.caso_persona.persona?.id : null,
                    querellante: (props.infoExpediente?.caso_persona?.querellante === true) ? props.infoExpediente?.caso_persona?.persona?.id : null,
                    modalidad: isEdit ? acciones?.modalidad?.id : "",
                    referencia: referencia?.results?.id,
                    departamento: isEdit ? acciones?.departamento?.id : "",
                    municipio: isEdit ? acciones?.municipio?.id : "",
                    gestion_institucion: isEdit ? acciones?.gestion_institucion?.id : "",
                    institucion: isEdit ? acciones?.institucion?.id : "",
                    accion_seguimiento: isEdit ? acciones?.accion_seguimiento?.id : "",
                    profesional_asignado: isEdit ? acciones?.profesional_asignado?.id : "",
                    profesional_autoriza: isEdit ? acciones?.profesional_autoriza?.id : "",
                    profesional_realiza: isEdit ? acciones?.profesional_realiza?.id : "",
                    nombramiento: null,
                    profesional_crea: idUser,
                    sede_accion: sedeId,
                    fecha_hora_planificada_inicia: isEdit ? acciones?.fecha_hora_planificada_inicia : "",
                    fecha_hora_planificada_fin: isEdit ? acciones?.fecha_hora_planificada_fin : "",
                    accion_planificada: isEdit ? acciones?.accion_planificada : false,
                    asignar_accion: isEdit ? acciones?.asignar_accion : false,
                    habilita_planificacion: false,
                    seguimiento_accion_solicitada: null,
                    fecha_requerido: isEdit ? acciones?.fecha_requerido : "",
                    gestionar: false
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                            await putAcciones(acciones.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({});
                                    setEdit(false);
                                    setValor(null);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    } else {
                        await postAcciones(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro creado con éxito...!');
                                    resetForm({});
                                    setValor(null);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo acción"
                                    name="accion_seguimiento"
                                    value={values.accion_seguimiento}
                                    onChange={(e) => {
                                        setFieldValue('accion_seguimiento', e.target.value);
                                        catalogoAS?.forEach(element => {
                                            if (element.id === Number(e.target.value)) {
                                                if (element.habilita_planificacion) {
                                                    setHabilitaPlanificacion(true);
                                                    
                                                } else {
                                                    setHabilitaPlanificacion(false);
                                                    setFieldValue('accion_planificada',false);
                                                }

                                                if (element?.solicita_accion) {
                                                    setFieldValue('seguimiento_accion_solicitada', element?.seguimiento_accion_solicitada);
                                                    setFieldValue('fecha_accion', moment().format("YYYY-MM-DD"));
                                                    setFieldValue('requiere_autorizacion', element?.requiere_autorizacion);
                                                    setFieldValue('gestionar',true);
                                                    setFieldValue('profesional_realiza',idUser);
                                                    setAccionSolicitada(true);

                                                } else {
                                                    setFieldValue('seguimiento_accion_solicitada', null);
                                                    setFieldValue('fecha_accion', "");
                                                    setFieldValue('requiere_autorizacion', false);
                                                    setAccionSolicitada(false);
                                                }
                                            }
                                        });
                                    }}
                                    valueInit=""
                                    selectInit="Elegir tipo acción"
                                    list={
                                        catalogoAS?.map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Acción"
                                    validacion={true}
                                    name="seguimiento"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.seguimiento}
                                    onChange={handleChange}
                                />
                            </div>
                            {(habilitaPlanificacion && accionSolicitada === false) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Acción planificada" />
                                    <Toggle enabled={values.accion_planificada} name="accion_planificada" />
                                </div>
                            )}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="datetime-local"
                                        name="fecha_hora_planificada_inicia"
                                        id="fecha_hora_planificada_inicia"
                                        value={values.fecha_hora_planificada_inicia}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={bloqueoFechaHora()}
                                        disabled={isEdit ? true : false}
                                        label="Fecha hora planificada inicia"
                                    />
                                </div>)}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="datetime-local"
                                        name="fecha_hora_planificada_fin"
                                        disabled={isEdit ? true : false}
                                        id="fecha_hora_planificada_fin"
                                        value={values.fecha_hora_planificada_fin}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={values.fecha_hora_planificada_inicia}
                                        label="Fecha hora planificada fin"
                                    />
                                </div>)}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Asignar acción" />
                                    <Toggle enabled={values.asignar_accion} name="asignar_accion" />
                                </div>)}
                            {(!values.asignar_accion && accionSolicitada === false && values.accion_planificada === false) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_accion"
                                        id="fecha_accion"
                                        value={values.fecha_accion}
                                        onChange={handleChange}
                                        validacion={false}
                                        maximo={bloqueoFecha()}
                                        label="Fecha acción"
                                    />
                                </div>)}
                                {(accionSolicitada) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_requerido"
                                        value={values.fecha_requerido}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={bloqueoFecha()}
                                        disabled={(values.autorizado === true) ? true : false}
                                        label="Fecha requerida"
                                    />
                                </div>)}
                            {values.asignar_accion && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Profesional Asignado"
                                        name="profesional_asignado"
                                        value={values.profesional_asignado}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir profesional"
                                        list={
                                            profesionalesAsigna?.results?.map((item) => {
                                                return (
                                                    <option key={item.first_name} value={item.id}>
                                                        {item.first_name} {item.last_name}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>)}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    list={
                                        modalidades?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="especificaciones_modalidad"
                                    id="especificaciones_modalidad"
                                    value={values.especificaciones_modalidad}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Especificación modalidad"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Departamento"
                                    name="departamento"
                                    value={values.departamento}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                        let combo = document.getElementById("departamento");
                                        let selected = combo?.options[combo.selectedIndex].text;
                                        setNombreDepartamento(selected);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Municipio"
                                    name="municipio"
                                    value={values.municipio}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir municipio"
                                    list={
                                        municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                                            return (
                                                <option key={item.nombre + "mn" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <h1 className="mb-1 text-sm text-maivi-primaryFont mt-2">Institución</h1>
                                <Select
                                    defaultValue={valor}
                                    placeholder="Seleccionar"
                                    name="institucion"
                                    className="basic-multi-select text-xs text-maivi-primaryFont"
                                    options={optionsInstituciones}
                                    onChange={changeSelectInstituciones}
                                    isClearable={false}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="detalle_lugar"
                                    id="detalle_lugar"
                                    value={values.detalle_lugar}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Detalle lugar"
                                />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div> */}
                            {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Completada" />
                                <Toggle enabled={values.completada} name="completada" />
                            </div> */}
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 sm:col-span-3 md:col-span-3">
                                  {
                                     isSubmitting ? <Spinner /> :
                                     <ButtonSiavi
                                         type="button"
                                         disabled={isSubmitting}
                                         text={isEdit ? "Actualizar" : "Guardar"}
                                         onClick={() => {
                                             if (accionSolicitada === true || values.accion_planificada === true) {
                                                values.institucion = institucionesAgregados.value;
                                                handleSubmit();
                                             } else {
                                                 setPreguntaCompletado(true);
                                             }
                                         }}
                                     /> 
 
                                  }
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                        {(preguntaCompletado) && (
                            <ModalGenerico
                                title={`¿Desea marcarlo como completado?`}
                                open={preguntaCompletado}
                                closeModal={() => { }}
                                cerrar={() => { setPreguntaCompletado(false) }}
                                children={
                                    <FormEliminar
                                        aceptar={async () => {
                                            values.profesional_realiza = idUser;
                                            values.institucion = institucionesAgregados.value;
                                            values.fecha_completado = values.fecha_accion;
                                            values.profesional_realiza = values.profesional_asignado;
                                            values.completada = true;
                                            handleSubmit();
                                            setPreguntaCompletado(false);
                                        }}
                                        isloading={isloading}
                                        cancelar={() => {
                                            toast.success("No será tomada en cuenta hasta que sea completada");
                                            setPreguntaCompletado(false);
                                            values.institucion = institucionesAgregados.value;
                                            handleSubmit();

                                        }}
                                    />
                                }
                            />
                        )
                        }
                    </form>
                )}
            </Formik>
            <ButtonAdd
                className="ml-1 mb-2"
                text="Filtros"
                type="button"
                onClick={() => {
                    setOpenFilters(true);
                }}
                icon={<FilterIcon className="ml-1 -mr-1 h-5" />}
            />
            <ButtonAdd
                className="ml-1"
                text="Refrescar"
                type="button"
                onClick={() => {
                    setFilters("");
                    setReFetch(true);
                }}
                icon={<RefreshIcon className="ml-1 -mr-1 h-5" />}
            />
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={true}
                    showEditar={true}
                    agregar={false}
                    handleAgregar={handleAgregar}
                    agregar1="Asignar Recurso"
                    agregarSegundo={false}
                    handleSegundoAgregar={handleAgregarNombramiento}
                    agregar2="Nombramiento"
                    pagination={true}
                    fetchPage={setUrlFech}
                    showAdjunt={true}
                    handleArchivos={handleArchivos}
                    nameAdjunt="Adjuntar"
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={`Detalle de acción ${accionesDetalle.id}`}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleAccion detalle={accionesDetalle} cancelar={setEliminar} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Acciones Recursos de ${acciones.seguimiento}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionAccionesRecursos accion={acciones} />
                        }
                    />
                )}
                {agregarNombramiento && (
                    <ModalAmplio
                        title={`Gestión de Nombramiento de ${acciones.seguimiento}`}
                        open={agregarNombramiento}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarNombramiento(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionNombramiento accion={acciones} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {openFilters && (
                    <ModalGenerico
                        title={`Aplicar filtros`}
                        open={openFilters}
                        closeModal={() => { }}
                        cerrar={() => {
                            setOpenFilters(false);
                        }}
                        children={
                            <Filtros
                                setFilters={setFilters}
                                setOpenFilters={setOpenFilters}
                                token={token}
                                acciones={catalogoAS?.filter((x) => x?.servicio?.id === props.infoExpediente?.servicio?.id)}
                            />
                        }
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <FilterIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={async () => {
                                    setLoading(true);
                                    deleteAcciones(idAcciones, token).then((data) => {
                                        if (data?.status >= 200 && data?.status <= 210) {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!');
                                            
                                            setTimeout(() => {
                                                setReFetch(false);
                                            }, 300);
                                        }
                                    }).catch(function (error) {
                                        if (error.response.status >= 400 && error.response.status <= 450) {
                                            toast.error(error.response.data.message);
                                            setLoading(false);
                                        } else if (error.response.status >= 500) {
                                            setLoading(false);
                                            toast.error("Error interno comuniquese con el administrador");
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
                {archivos && (
                    <ModalAmplio
                        title={`Adjuntar archivos`}
                        open={true}
                        closeModal={() => { }}
                        cerrar={() => {
                            setArchivos(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <PaperClipIcon className="h-6 w-6 text-green-600" />
                            </div>
                        }
                        children={
                            <GestionArchivos
                                idRecurso={recurso.id}
                                recurso={props.recurso}
                                claseDocumento={props.claseDocto}
                                expediente={recurso?.expediente?.id}
                                caso={recurso?.expediente?.caso}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};

