import { useState } from "react";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import { LockOpenIcon } from "@heroicons/react/outline";
import { CambiarPassword } from "./cambiarContrasena";
import { CambiarPreguntas } from "./cambiarPreguntas";
export const Detalle = () => {
  const loginState = useSelector((state) => state?.loginReducer?.data);
  const token = useSelector((state) => state.loginReducer.data.token);
  const [cambiarPassword, setCambiarPassword] = useState(false);
  const [cambiarPreguntas, setCambiarPreguntas] = useState(false);
  const user = loginState?.usuario?.user?.username;
  const tienePreguntas = loginState?.usuario?.user?.tienepreguntas;

  const row = (titulo, valor) =>{
    return (
      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">
        {titulo}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {valor}
      </dd>
    </div>
    );
  }
  return (
    <div>
      <div className="flex flex-col items-center h-32 w-full lg:h-32 background-siavi">
        <div className="p-6 flex flex-row">
          <span className="inline-flex items-center justify-center h-14 w-14 ring-4 ring-white rounded-full bg-maivi-secondaryLightBlue">
            <span className="text-xl font-medium leading-none text-white">
              {loginState?.usuario?.user?.first_name.substring(0, 1)}
              {loginState?.usuario?.user?.last_name.substring(0, 1)}
            </span>
          </span>
          <div className="flex flex-col items-start  justify-center ml-5">
            <h1 className="lg:text-2xl sm:text-sm font-bold text-white truncate">
              {`${loginState?.usuario?.user?.first_name} ${loginState?.usuario?.user?.last_name} `}
            </h1>
            <span className="text-maivi-secondaryWhite">
              {loginState?.usuario?.user?.username}
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-2">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1"></div>
        </div>
        <div className="grid lg:grid-cols-2 gap-3 sm:grid-cols-1 sm:gap-5 ">
          <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg lg:mt-4 sm:mt-1">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Información general
              </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                {row('Email',loginState?.usuario?.user?.email)}
                {row('Teléfono',loginState?.usuario?.user?.telefono)}
                {row('Tipo profesión',loginState?.usuario?.user?.detalle_tipoprofesional?.profesion)}
                {row('Número colegiado',loginState?.usuario?.user?.numero_colegiado)}
                {row('Tipo puesto', loginState?.usuario?.user?.detalle_tipopuesto?.puesto)}
                {row(' Sub Área',  loginState?.usuario?.user?.subarea)}
                {row(' Área',  loginState?.usuario?.user?.area)}
              </dl>
            </div>
          </div>
          <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-4">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Estado usuario
              </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Estado</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {loginState?.usuario?.user?.is_active === true ? (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                        Activo
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                        Inactivo
                      </span>
                    )}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Administrador
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {loginState?.usuario?.user?.is_superuser === true ? (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                        Si
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                        No
                      </span>
                    )}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Usuario interno
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {loginState?.usuario?.user?.usuario_interno === true ? (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                        Si
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                        No
                      </span>
                    )}
                  </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                  <dt className="text-sm font-medium text-gray-500">
                    Cambiar preguntas
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {tienePreguntas &&
                       <button
                      onClick={() => {
                        setCambiarPreguntas(true);
                      }}
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Cambiar
                    </button>
                    }
                   
                  </dd>
                </div>
              
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                  <dt className="text-sm font-medium text-gray-500">
                    Resetear contraseña
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <button
                      onClick={() => {
                        setCambiarPassword(true);
                      }}
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Resetear
                    </button>
                  </dd>
                </div>
                {cambiarPreguntas && (
                  <ModalGenerico
                    title={`¿Desea cambiar sus preguntas de seguridad?`}
                    open={true}
                    closeModal={() => {}}
                    cerrar={() => {
                      setCambiarPreguntas(false);
                    }}
                    icon={
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <LockOpenIcon className="h-6 w-6 text-green-600" />
                      </div>
                    }
                    children={<CambiarPreguntas token={token} user={user} />}
                  />
                )}
                {cambiarPassword && (
                  <ModalGenerico
                    title={`Cambiar contraseña`}
                    open={true}
                    closeModal={() => {}}
                    cerrar={() => {
                      setCambiarPassword(false);
                    }}
                    icon={
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <LockOpenIcon className="h-6 w-6 text-green-600" />
                      </div>
                    }
                    children={<CambiarPassword />}
                  />
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
