import { XCircleIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

export default function Return(props) {
  return (
     <Link to={props.ruta}>
      <button
        type="button"
        className="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 
        hover:bg-red-200 focus:ring-offset-2 focus:ring-offset-red-500"
      >
        <XCircleIcon   className="h-6 w-8" aria-hidden="true" />
      </button>
     </Link>
  )
}