import { React } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import {
    putFormulario,
} from "./services";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import toast from "react-hot-toast";
import Label from "../../../components/common/Label";
import * as Yup from "yup";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const validaciones = Yup.object().shape({

});

export const Autorizar = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const instrumento = props?.instrumento;

    return (
        <div>
            <Formik
                initialValues={{
                    autorizado: instrumento.autorizado,
                    estado: instrumento.estado,
                    es_autorizado: instrumento.autorizado
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    values.nombre = instrumento.nombre;
                    values.descripcion = instrumento.descripcion;
                    values.unificado = instrumento.unificado;
                    values.instrumento = instrumento.instrumento;
                    values.fecha_autorizacion = instrumento.fecha_autorizacion;
                    values.fecha_inactivacion = instrumento.fecha_inactivacion;
                    values.servicio = instrumento?.servicio?.id;
                    values.atencion = instrumento?.atencion?.id;
                    values.idioma = instrumento?.idioma?.id;
                    values.idioma_maya = instrumento?.idioma_maya?.id;
                    values.profesional = instrumento?.profesional?.id;
                    values.por_atencion = instrumento.por_atencion;
                    values.por_servicio = instrumento.por_servicio;
                    values.usuario_victima = instrumento.usuario_victima;
                    values.por_area = instrumento.por_area;
                    values.por_orientacion = instrumento.por_orientacion;
                    values.usuario_creacion = instrumento?.usuario_creacion?.id;
                    values.usuario_autoriza = instrumento?.usuario_autoriza?.id;
                    values.usuario_inactivacion = instrumento?.usuario_inactivacion?.id;
                    values.area = instrumento?.area?.id;

                    await putFormulario(
                        instrumento.id,
                        values,
                        token
                    ).then((data) => {
                        if (data?.message !== undefined) {
                            toast.error(data.message);
                        } else {
                            props.setReFetch(true);
                            props.setGestionar(false);
                            toast.success("Registro actualizado con éxito...!");
                            props.setReFetch(false);
                        }
                    });
                }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <form>
                        <div className="grid grid-cols-12 gap-3">
                            {
                                !values.es_autorizado &&
                                <div className="col-span-12 xl:col-span-6 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Autorizar" />
                                    <Toggle enabled={values.autorizado} name="autorizado" />
                                </div>
                            }
                            <div className="col-span-12 xl:col-span-6 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                                <Label text="Activar" />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                        </div>
                        {
                            (values.autorizado && values.es_autorizado === false) &&
                            <div className="mt-4">
                                <Alert type="warning" mensaje="¿Una vez autorizado no podrá editarlo nuevamente desea continuar? " />
                            </div>
                        }
                        {
                            values.es_autorizado &&
                            <div className="mt-4">
                                <Alert type="warning" mensaje="Este registro ya fue autorizado" />
                            </div>
                        }
                        <BotonesConfirmar
                            aceptar={handleSubmit}
                            cancelar={() => {
                                console.log(props.instrumento);

                                props.setGestionar(false);
                            }}
                        />
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};
