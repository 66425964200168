
const {
    REACT_APP_API_URL
} = process.env;

export const getConsentimientoInformado = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/consentimientoinformado/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idioma/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomasMayas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/idiomamaya/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConfiguraciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/configuracion/1/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postConsentimientoInformado = async (data,token) => {
    let usuarioCreacion;
    let usuarioAutoriza;
    let usuarioInactivacion;
    if(Number(data.usuario_creacion) === 0 && Number(data.usuario_autoriza) === 0 && Number(data.usuario_inactivacion) === 0){
        usuarioCreacion = null;
        usuarioAutoriza = null;
        usuarioInactivacion = null;
    }else{
        usuarioCreacion = Number(data.usuario_creacion);
        usuarioAutoriza = Number(data.usuario_autoriza);
        usuarioInactivacion = Number(data.usuario_inactivacion);
    }
    try {
        let body = {
            nombre: data.nombre,
            descripcion: data.descripcion,
            unificado: data.unificado,
            estado: data.estado,
            consentimiento: data.consentimiento,
            fecha_autorizacion: data.fecha_autorizacion,
            fecha_inactivacion: data.fecha_inactivacion,
            servicio: (data.servicio === "") ? null :data.servicio,
            atencion: (data.atencion === "") ? null :data.atencion,
            idioma: (data.idioma ==="") ? null :data.idioma,
            idioma_maya: (data.idioma_maya === "") ? null :data.idioma_maya,
            usuario_creacion: usuarioCreacion,
            usuario_autoriza: usuarioAutoriza,
            usuario_inactivacion: usuarioInactivacion,
            es_nna: data.es_nna,
            por_atencion: data.por_atencion,
            por_servicio: data.por_servicio,
            titulo: data.titulo,
            replicable_editable : data.replicable_editable
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putConsentimientoInformado = async (id,data,token) => {

    try {
        let body = {
            nombre: data.nombre,
            descripcion: data.descripcion,
            unificado: data.unificado,
            estado: data.estado,
            consentimiento: data.consentimiento,
            fecha_autorizacion: data.fecha_autorizacion,
            fecha_inactivacion: data.fecha_inactivacion,
            servicio: (data.servicio === "") ? null : Number(data.servicio),
            atencion: (data.atencion === "") ? null : Number(data.atencion),
            idioma: (data.idioma ==="") ? null : Number(data.idioma),
            idioma_maya: (data.idioma_maya === "") ? null : Number(data.idioma_maya),
            autorizado : data?.autorizado,
            titulo : data?.titulo,
            // usuario_creacion: usuarioCreacion,
            // usuario_autoriza: usuarioAutoriza,
            // usuario_inactivacion: usuarioInactivacion,
            es_nna: data.es_nna,
            por_atencion: data.por_atencion,
            por_servicio: data.por_servicio,
            replicable_editable : data.replicable_editable
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteConsentimientoInformado = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};