import { React, useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import {
    putSindicadoDelito
} from "../services";
import { Formik } from "formik";
import Spinner from "../../../../../components/common/Spinner";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    especificaciones: Yup.string().max(300, "maximo 300 caracteres"),
});

const useFetchPocision = (listado,delito) => {
    const [state, setState] = useState({
        pocision: 0,
    });

    useEffect(() => {
        listado?.forEach((element,index) => {
            if(element.value === delito){
                setState({
                    pocision: index,
                });
            }
        });
        return state
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return state;
};

export const Actualizar = (props) => {
    const token = useSelector((state) => state?.loginReducer?.data?.token);
    const animatedComponents = makeAnimated();
    const [cargando, setCargando] = useState(false);
    const [delitosAgregados, setDelitosAgregados] = useState([]);
    const idCaso = props?.sindicadoDelito?.caso;
    const idExpedienteSindicadoDelito = props?.sindicadoDelito?.id;
    const idExpedienteSindicado = props?.sindicadoDelito?.expediente_sindicado?.id;
    const changeSelectDelitos = (e) => {
        setDelitosAgregados(e);
    };
    const delito = props?.sindicadoDelito?.delito?.id;
    const listadoDelitos = props?.delitos;

    const { pocision } = useFetchPocision(listadoDelitos, delito);
    
    // useEffect(() => {
    //     listadoDelitos.forEach((element,index) => {
    //         if(element.value === Number(delito)){
    //             console.log(element);
    //             console.log(delito);
    //             setPocision(index);
    //             console.log(index);
    //             //pocisionDelito = index;
    //         }
    //     });
    // },[]);

    //const [pocision, setPocision] = useState(pocisionDelito);


    return (
        <Formik
            initialValues={{
                estado: props.sindicadoDelito.estado,
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                setCargando(true);
                await putSindicadoDelito(
                    idExpedienteSindicadoDelito,
                    idCaso,
                    idExpedienteSindicado,
                    delitosAgregados.value,
                    values.estado,
                    token
                ).then((data) => {
                    if (data?.status === 200 || data?.status === 201) {
                        toast.success("sindicado actualizado con éxito..!");
                        props.reFetch(true);
                        props.reFetch(false);
                        setCargando(false);
                        props.setEditar(false);
                    } else {
                        toast.success("sindicado actualizado con éxito..!");
                    }
                })
                    .catch(function (error) {
                        if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                        ) {
                            toast.error(error.response.data.message);
                            setCargando(false);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                            setCargando(false);
                        }
                    });
            }}
        >
            {({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Select
                        defaultValue={props?.delitos[pocision]}
                        isMulti={false}
                        placeholder="Seleccionar"
                        components={animatedComponents}
                        name="delitos"
                        className="basic-multi-select text-xs"
                        classNamePrefix="seleccione"
                        options={props.delitos}
                        onChange={changeSelectDelitos}
                        maxMenuHeight={120}
                    />
                    <div className="mt-2">
                        <Label text="Estado" />
                        <Toggle enabled={values.estado} name="estado" />
                    </div>
                    {
                        (cargando) ?
                            <div className="p-2"><Spinner /></div> :
                            <div className="mt-3 mb-2">
                                <ButtonSiavi
                                    type="button"
                                    text="Actualizar"
                                    onClick={handleSubmit}
                                />
                            </div>
                    }
                </form>
            )}
        </Formik>
    );
};
