
const {
    REACT_APP_API_URL
} = process.env;


export const getTipoValorEncuesta = async (urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/tipovalorencuesta/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postTipoValorEncuesta = async ({ nombre, signo, estado }, token) => {
    try {
        let body = {
            nombre,
            signo,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/tipovalorencuesta/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putTipoValorEncuesta = async (id, { nombre, signo, estado }, token) => {
    try {
        let body = {
            nombre,
            signo,
            estado
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/tipovalorencuesta/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteTipoValorEncuesta = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipovalorencuesta/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};