import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

const idConf = 1;

export const getConfiguracion = async (token) => {
    try {
        let bodyResult = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/catalogos/configuracion/${idConf}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putConfiguracion = async (data, token) => {
    try {
        let body = {
            nombre_institucion: data.nombre_institucion,
            nit: data.nit,
            direccion: data.direccion,
            idioma_preferente: data.idioma_preferente,
            moneda: data.moneda,
            prefijo_telefono: data.prefijo_telefono,
            notificaciones_activas: data.notificaciones_activas,
            mensajes_autocuidado: data.mensajes_autocuidado,
            intentos_contrasena: data.intentos_contrasena,
            dias_atiende_visita: data.dias_atiende_visita,
            dias_registro_acciones: data.dias_registro_acciones,
            dias_finalizo_acciones: data.dias_finalizo_acciones,
            victimaxcaso: data.victimaxcaso,
            comunicacion_victima: data.comunicacion_victima,
            caso_sin_orientacion: data.caso_sin_orientacion,
            pais: Number(data.pais),
            habilita_idioma_maya: Number(data.habilita_idioma_maya),
            habilita_origen_etnico: Number(data.habilita_origen_etnico),
            nacionalidad_default: Number(data.nacionalidad_default)
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/configuracion/${idConf}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPaises = async (token) => {
    try {
        let bodyResult = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/catalogos/pais/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getIdiomaMaya = async (token) => {
    try {
        let bodyResult = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/catalogos/idioma/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrigenEtnico = async (token) => {
    try {
        let bodyResult = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/catalogos/origenetnico/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNacionalidad = async (token) => {
    try {
        let bodyResult = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/catalogos/nacionalidad/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};
