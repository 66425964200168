/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { getProfesionales, postAsignaciones } from "../services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { 
    getDiligenciasExpediente, 
    getExpedienteSindicados, 
    getFullCasoDetails, 
    getSindicadoDelitos 
} from "../../../orientacion/editar/services";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import PdfViewer from "../../../../../components/common/PdfViewer";
import Label from "../../../../../components/common/Label";
import makeAnimated from "react-select/animated";
import Select from "react-select";

export const ReasignarExpediente = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user);
    const [profesionales, setProfesionales] = useState(false);
    const [asignacionPdf, setAsignacionPdf] = useState(null);
    const [profesionalSeleccionado,setProfesionalSeleccionado] = useState(0);
    const animatedComponents = makeAnimated();
    const optionsProfesionales = [];

    useEffect(() => {
        getProfesionales(props.expediente, token).then((data) => {
            setProfesionales(data);
        });
    }, [token]);

    profesionales?.results?.forEach((item) => {
        let reg = {
          value: item.id,
          label: `${item.first_name} ${item.last_name} - ${item?.tipo_puesto?.puesto}`,
        };
        optionsProfesionales.push(reg);
      });
    
      const changeSelectProfesional = (e) => {
            setProfesionalSeleccionado(e.value)
      };

      
    return (
        <Formik
            initialValues={{
                profesional: "",
            }}
            onSubmit={async (values) => {
                values.profesional = profesionalSeleccionado;
                let expedientes = [
                    props.expediente.id
                ]
                await postAsignaciones(values.profesional, expedientes, token)
                    .then(async (data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            toast.success('reasignacion realizada con éxito');

                            let profSend = {};
                            profesionales.results.forEach(item => {
                              if (item.id === values.profesional) {
                                profSend = item;
                              }
                            })

                            let tempAsig = [];
                            await getSindicadoDelitos(props.expediente.caso, token).then(async (delitos) => {
                                await getExpedienteSindicados(props.expediente.caso, token).then(async sindicado => {
                                    await getFullCasoDetails(props.expediente.caso, token).then(async personasCaso => {
                                        await getDiligenciasExpediente(props.expediente.id, token).then(diligencias => {
                                            tempAsig.push({
                                                ...props.expediente,
                                                delitos: delitos.results,
                                                sindicados: sindicado.results,
                                                personasCaso: personasCaso,
                                                diligencias: diligencias.results
                                            })
                                            setAsignacionPdf({
                                                profesional: profSend,
                                                asignaciones: tempAsig,
                                                usuario: usuarioLogin
                                            })
                                        })
                                    });

                                })
                            })

                            props.setRecargar(true);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error('Error interno comuniquese con el administrador');
                        }
                    });
            }}>
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <div className="grid grid-cols-12 gap-2 mb-20">
                    <div className="col-span-4 border rounded-lg p-3">
                        <form onSubmit={handleSubmit}>
                            <div className="">
                                 <div className="col-span-12 mt-1">
                                        <Label text="Profesional" />
                                        <Select
                                        placeholder="Seleccionar"
                                        components={animatedComponents}
                                        name="profesional"
                                        className={`basic-multi-select text-xs text-maivi-primaryFont`}
                                        classNamePrefix="seleccione"
                                        options={optionsProfesionales}
                                        onChange={changeSelectProfesional}
                                        maxMenuHeight={140}
                                        />
                                    </div>
                                {!asignacionPdf &&
                                    <div className="py-3 text-right">
                                        <ButtonSiavi
                                            type="submit"
                                            disabled={isSubmitting}
                                            text="Reasignar"
                                        />
                                    </div>
                                }
                            </div>
                            {isSubmitting && <Spinner />}
                        </form>
                    </div>
                    <div className="col-span-8 border rounded-lg p-3">
                        {asignacionPdf &&
                            <PdfViewer
                                data={asignacionPdf}
                                reporte={"asignaciones"}
                                style={{ height: "70vh" }}
                            />
                        }
                    </div>
                </div>

            )}
        </Formik>
    );
};
