/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { ChatIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../components/common/Select";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { FormDetalleAccion } from "../../../casos/editar/atencion_victimologica/trabajo_social/detalleAccion";
import { GestionAccionesRecursos } from "../../../casos/editar/atencion_victimologica/trabajo_social/gestionAccionRecursos";
import { GestionNombramiento } from "./gestionNombramiento";
import { GearIcon } from "../../../../../helpers/icons";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { clases } from "../../../../../helpers/clases";
import { MensajeriaSMS } from "../../../../../components/EnvioMensajes";
import {
    deleteAcciones,
    postAcciones,
    putAcciones,
    getDeptosGestion,
    getMunicipiosGestion,
    getModalidades,
    getInstitucion,
    getProfesionales,
    getReferenciaCreada,
    getCatalogoSeguimientos,
    getSeguimientosAcciones,
} from "../../../casos/editar/atencion_victimologica/trabajo_social/services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import CustomTable from "../../../../../components/common/Table";
import ModalGenerico from "../../../../../components/common/Modal";
import FormEliminar from "../../../../../components/common/FormEliminar";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import toast from 'react-hot-toast';
import moment from "moment";
import * as Yup from "yup";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Tipo seguimiento", value: "accion_seguimiento.nombre", tipo: "text" },
    { title: "Seguimiento", value: "seguimiento", tipo: "text" },
    {
        title: "Profesional",
        value: null,
        values: [
            "profesional_realiza.first_name",
            "profesional_realiza.last_name"
        ],
        tipo: "text"
    },
    {
        title: "Autorizado",
        value: "autorizado",
        tipo: "boolean",
        text: { true: "Sí", false: "No", null: "" },
    },
    {
        title: "Completado",
        value: "completada",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    }
];

const validaciones = Yup.object().shape({
    seguimiento: Yup.string()
        .required("Seguimiento es requerido")
});

export const Seguimientos = (props) => {
    const caso = props.infoExpediente.caso;
    const servicio = props.infoExpediente.servicio.id;
    const token = useSelector((state) => state.loginReducer.data.token);
    const sedeId = useSelector((state) => state.ChangeSede.sedeId);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const procuraciones = props?.procuraciones;
    const animatedComponents = makeAnimated();
    const [verDetalle, setDetalle] = useState(false);
    const [accionesDetalle, setAccionesDetalle] = useState({});
    const idExpediente = props?.infoExpediente?.id;
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idAcciones, setIdAcciones] = useState("");
    const [acciones, setAcciones] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [deptos, setDeptos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [catalogoAS, setCatalogoAS] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [institucionesAgregados, setInstitucionesAgregados] = useState([]);
    const [profesionalesAsigna, setProfesionalesAsigna] = useState([]);
    const [referencia, setReferencia] = useState([]);
    const [setNombreDepartamento] = useState("");
    const [agregar, setAgregar] = useState(false);
    const [agregarNombramiento, setAgregarNombramiento] = useState(false);
    const [preguntaCompletado, setPreguntaCompletado] = useState(false);
    const [habilitaPlanificacion, setHabilitaPlanificacion] = useState(null);
    const [accionSolicitada, setAccionSolicitada] = useState(false);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [enviarSms,setEnviarSms] = useState(false);
    const optionsInstituciones = [];
    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro
    const permisoView = "ExpedientesAL";  //mm permiso padre
  
    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
      let retorno = false;
      permisosRegistro?.forEach(menu => {
        if (menu?.permiso === permisoView) {
          menu?.opciones?.forEach(op => {
            if (op.permiso === permiso) {
              retorno = op.ver;
            }
          })
        }
      });
     //  retorno = true;  // mm comentar para que funcione
      return retorno;
  
    }


    useEffect(() => {
        getDeptosGestion(token).then((data) => {
            setDeptos(data);
        });
    }, [token]);

    useEffect(() => {
        getMunicipiosGestion(token).then((data) => {
            setMunicipios(data)
        });
    }, [token]);

    useEffect(() => {
        getModalidades(token).then((data) => {
            setModalidades(data)
        });
    }, [token]);

    useEffect(() => {
        getCatalogoSeguimientos(token).then(async(data) => {
            setCatalogoAS(data);
        });
    }, [token]);

    useEffect(() => {
        getInstitucion(token).then((data) => {
            setInstituciones(data)
        });
    }, [token]);

    useEffect(() => {
        getProfesionales(servicio, token).then((data) => {
            setProfesionalesAsigna(data)
        });
    }, [token]);

    useEffect(() => {
        getReferenciaCreada(caso, servicio, token).then((data) => {
            setReferencia(data)
        });
    }, [token, caso, servicio]);

    useEffect(() => {
        getSeguimientosAcciones(urlFetch, idExpediente, token).then((acciones) => {
            setDataTable(acciones);
            setTableLoading(false);
            setReFetch(false);
        });
    }, [token, idExpediente, reFetch, urlFetch]);

    const changeSelectInstituciones = (e) => {
        setInstitucionesAgregados(e);
    };

    instituciones?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre,
        };
        optionsInstituciones.push(reg);
    });

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "accionesseguimientoscaso";
    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleAgregar = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregar(true);
        } else {
            toast.error("No es posible asignar recurso, porque ya está completado el seguimiento")
        }
    };

    const handleAgregarNombramiento = (item) => {
        if (item.completada === false) {
            setIdAcciones(item.id);
            setAcciones(item);
            setAgregarNombramiento(true);
        } else {
            toast.error("No es posible agregar nombramientos, porque ya está completado el seguimiento")
        }
    };

    const handleVer = (item) => {
        setAccionesDetalle(item);
        setDetalle(true);
    };

    const handleEliminar = (id) => {
        setIdAcciones(id);
        setEliminar(true);
    };

    const handleEditar = (acciones) => {
        if (acciones.autorizado === false || acciones.autorizado === null) {
            setEdit(true);
            setAcciones(acciones);
        } else {
            toast.error("No es posible editar, porque ya está autorizado el seguimiento")
        }
    };

    const opcion = (item) => {
        return(
          <button
            type="button"
            className={clases.addIcon}
            onClick={() => {
                setAcciones(item);
                setEnviarSms(true);
            }}
            >
          <ChatIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
          <span className="tooltiptext">enviar sms</span>
        </button>
        )
    } 

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    seguimiento: isEdit ? acciones?.seguimiento : "",
                    es_nna: isEdit ? acciones?.es_nna : false,
                    tipo_audiencia: isEdit ? acciones?.tipo_audiencia?.id : "",
                    fecha_accion: isEdit ? moment(acciones?.fecha_accion).format("YYYY-MM-DD") : "",
                    fecha_asigna: isEdit ? acciones?.fecha_asigna : "",
                    requiere_autorizacion: false,
                    fecha_autoriza: isEdit ? acciones?.fecha_autoriza : "",
                    autorizado: isEdit ? acciones?.autorizado : null,
                    fecha_completado: isEdit ? acciones?.fecha_completado : "",
                    estado: isEdit ? acciones?.estado : true,
                    completada: isEdit ? acciones?.completada : false,
                    es_accion: false,
                    es_seguimiento: true,
                    requiere_nombramiento: false,
                    observaciones: isEdit ? acciones?.observaciones : "",
                    detalle_lugar: isEdit ? acciones?.detalle_lugar : "",
                    especificaciones_modalidad: isEdit ? acciones?.especificaciones_modalidad : "",
                    tipo: isEdit ? acciones?.tipo : "",
                    caso: props.infoExpediente.caso,
                    expediente: idExpediente,
                    persona: props.infoExpediente.caso_persona.persona.id,
                    victima: (props.infoExpediente.caso_persona.victima === true) ? props.infoExpediente.caso_persona.persona.id : null,
                    querellante: (props.infoExpediente.caso_persona.querellante === true) ? props.infoExpediente.caso_persona.persona.id : null,
                    modalidad: isEdit ? acciones?.modalidad?.id : "",
                    referencia: referencia?.results?.id,
                    departamento: isEdit ? acciones?.departamento?.id : "",
                    municipio: isEdit ? acciones?.municipio?.id : "",
                    gestion_institucion: isEdit ? acciones?.gestion_institucion?.id : "",
                    institucion: isEdit ? acciones?.institucion?.id : "",
                    accion_seguimiento: isEdit ? acciones?.accion_seguimiento?.id : "",
                    profesional_asignado: isEdit ? acciones?.profesional_asignado?.id : "",
                    profesional_autoriza: isEdit ? acciones?.profesional_autoriza?.id : "",
                    profesional_realiza: isEdit ? acciones?.profesional_realiza?.id : "",
                    nombramiento: null,
                    profesional_crea: idUser,
                    sede_accion: sedeId,
                    fecha_hora_planificada_inicia: isEdit ? acciones?.fecha_hora_planificada_inicia : "",
                    fecha_hora_planificada_fin: isEdit ? acciones?.fecha_hora_planificada_fin : "",
                    accion_planificada: isEdit ? acciones?.accion_planificada : false,
                    asignar_accion: isEdit ? acciones?.asignar_accion : false,
                    habilita_planificacion: false,
                    seguimiento_accion_solicitada: null,
                    fecha_requerido: isEdit ? acciones?.fecha_requerido : "",
                    gestionar: isEdit ? acciones?.gestionar : false,
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                            await putAcciones(acciones.id, values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    resetForm({})
                                    setEdit(false);
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    } else {
                        await postAcciones(values, token)
                            .then((data) => {
                                if (data?.status >= 200 && data?.status <= 210) {
                                    setReFetch(true);
                                    toast.success('Registro creado con éxito...!');
                                    resetForm({});
                                }
                            }).catch(function (error) {
                                if (error.response.status >= 400 && error.response.status <= 450) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form >
                        <div className="grid grid-cols-12 gap-3 items-end border rounded-lg p-3 mb-2">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Tipo Seguimiento"
                                    name="accion_seguimiento"
                                    value={values.accion_seguimiento}
                                    onChange={(e) => {
                                        setFieldValue('accion_seguimiento', e.target.value);
                                        catalogoAS?.results?.forEach(element => {
                                            if (element.id === Number(e.target.value)) {
                                                if (element.habilita_planificacion) {
                                                    setHabilitaPlanificacion(true);
                                                    
                                                } else {
                                                    setHabilitaPlanificacion(false);
                                                    setFieldValue('accion_planificada',false);
                                                }

                                                if (element?.solicita_accion) {
                                                    setFieldValue('seguimiento_accion_solicitada', element?.seguimiento_accion_solicitada);
                                                    setFieldValue('fecha_accion', moment().format("YYYY-MM-DD"));
                                                    setFieldValue('requiere_autorizacion', element?.requiere_autorizacion);
                                                    setAccionSolicitada(true);

                                                } else {
                                                    setFieldValue('seguimiento_accion_solicitada', null);
                                                    setFieldValue('fecha_accion', "");
                                                    setFieldValue('requiere_autorizacion', false);
                                                    setAccionSolicitada(false);
                                                }
                                            }
                                        });
                                    }}
                                    valueInit=""
                                    selectInit="Elegir tipo seguimiento"
                                    list={
                                        props.esReparacionDigna ? 
                                        catalogoAS?.results?.filter(x => x.rep_digna).map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        }) :
                                        catalogoAS?.results?.filter(x => x.es_procuracion === procuraciones && x?.es_audiencia === false).map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-4">
                                <TextAreaSiavi
                                    label="*Seguimiento"
                                    validacion={true}
                                    name="seguimiento"
                                    type="text"
                                    rows={2}
                                    columns={50}
                                    value={values.seguimiento}
                                    onChange={handleChange}
                                />
                            </div>
                            {(habilitaPlanificacion) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Acción Planificada" />
                                    <Toggle enabled={values.accion_planificada} name="accion_planificada" />
                                </div>
                            )}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_hora_planificada_inicia"
                                        id="fecha_hora_planificada_inicia"
                                        value={values.fecha_hora_planificada_inicia}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={bloqueoFecha()}
                                        disabled={(isEdit === true) ? true : false}
                                        label="Fecha Planificada"
                                    />
                                </div>)}
                            {values.accion_planificada && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                    <Label text="Asignar" />
                                    <Toggle enabled={values.asignar_accion} name="asignar_accion" />
                                </div>)}
                            {(accionSolicitada === false && values?.accion_planificada === false ) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_accion"
                                        id="fecha_accion"
                                        value={values.fecha_accion}
                                        onChange={handleChange}
                                        validacion={false}
                                        maximo={bloqueoFecha()}
                                        label="Fecha seguimiento"
                                    />
                                </div>)}
                            {(accionSolicitada ) && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="date"
                                        name="fecha_requerido"
                                        value={values.fecha_requerido}
                                        onChange={handleChange}
                                        validacion={false}
                                        minimo={bloqueoFecha()}
                                        disabled={(values.autorizado === true) ? true : false}
                                        label="Fecha requerida"
                                    />
                                </div>)}
                            {values.asignar_accion && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Profesional Asignado"
                                        name="profesional_asignado"
                                        value={values.profesional_asignado}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Elegir profesional"
                                        list={
                                            profesionalesAsigna?.results?.map((item) => {
                                                return (
                                                    <option key={item.first_name} value={item.id}>
                                                        {item.first_name} {item.last_name}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>)}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Modalidad"
                                    name="modalidad"
                                    value={values.modalidad}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir modalidad"
                                    list={
                                        modalidades?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="especificaciones_modalidad"
                                    id="especificaciones_modalidad"
                                    value={values.especificaciones_modalidad}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Especificación Modalidad"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Departamento"
                                    name="departamento"
                                    value={values.departamento}
                                    onChange={(e) => {
                                        setFieldValue("departamento", e.target.value);
                                        let combo = document.getElementById("departamento");
                                        let selected = combo?.options[combo.selectedIndex].text;
                                        setNombreDepartamento(selected);
                                    }}
                                    valueInit=""
                                    selectInit="Elegir departamento"
                                    list={
                                        deptos?.results?.map((item, index) => {
                                            return (
                                                <option key={item.nombre + index} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="Municipio"
                                    name="municipio"
                                    value={values.municipio}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir municipio"
                                    list={
                                        municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                                            return (
                                                <option key={item.nombre + "mn" + item.id} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <h1 className="mb-1 text-sm text-maivi-primaryFont mt-2">Institución</h1>
                                <Select
                                    placeholder="Seleccionar"
                                    components={animatedComponents}
                                    name="institucion"
                                    className="basic-multi-select text-xs text-maivi-primaryFont"
                                    classNamePrefix="seleccione"
                                    options={optionsInstituciones}
                                    onChange={changeSelectInstituciones}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="detalle_lugar"
                                    id="detalle_lugar"
                                    value={values.detalle_lugar}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Detalle Lugar"
                                />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-2 lg:col-span-2 sm:col-span-3 md:col-span-3">
                                  {
                                     isSubmitting ? <Spinner /> :
                                     (CheckPermiso('GuardarSeguimientos') === true) &&
                                     <ButtonSiavi
                                         type="button"
                                         disabled={isSubmitting}
                                         text={isEdit ? "Actualizar" : "Guardar"}
                                         onClick={() => {
                                             if (accionSolicitada === true || values.accion_planificada === true) {
                                                values.institucion = institucionesAgregados.value;
                                                handleSubmit();
                                             } else {
                                                 setPreguntaCompletado(true);
                                             }
                                         }}
                                     /> 
 
                                  }
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                        {preguntaCompletado && (
                            <ModalGenerico
                                title={`¿Desea marcarlo como completado?`}
                                open={preguntaCompletado}
                                closeModal={() => { }}
                                cerrar={() => { setPreguntaCompletado(false) }}
                                children={
                                    <FormEliminar
                                        aceptar={async () => {
                                            setFieldValue('completada',true);
                                            setFieldValue('fecha_completado',values.fecha_accion);
                                            setFieldValue('profesional_realiza',idUser);
                                            setFieldValue('institucion',institucionesAgregados.value);

                                            handleSubmit();
                                            setPreguntaCompletado(false);
                                        }}
                                        isloading={isloading}
                                        cancelar={() => {
                                            values.institucion = institucionesAgregados.value;
                                            handleSubmit();
                                            toast.success("No será tomada en cuenta hasta que sea completada");
                                            setPreguntaCompletado(false);
                                        }}
                                    />
                                }
                            />
                        )
                        }
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    handleVer={handleVer}
                    permiso={permiso}
                    //showEliminar={true}
                    showEliminar={((CheckPermiso('EliminarSeguimientos') === true) )}
                    showVer={true}
                    //showEditar={true}
                    showEditar={((CheckPermiso('EditarSeguimientos') === true) )}
                    agregar={false}
                    handleAgregar={handleAgregar}
                    agregar1="Asignar Recurso"
                    agregarSegundo={false}
                    handleSegundoAgregar={handleAgregarNombramiento}
                    agregar2="Nombramiento"
                    pagination={true}
                    fetchPage={setUrlFech}
                    //addAccion={true}
                    addAccion={((CheckPermiso('SMSSeguimientos') === true) )}
                    opciones={opcion}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {verDetalle && (
                    <ModalGenerico
                        title={`Detalle de seguimiento ${accionesDetalle.id}`}
                        open={verDetalle}
                        closeModal={() => { }}
                        cerrar={() => {
                            setDetalle(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <GearIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <FormDetalleAccion detalle={accionesDetalle} cancelar={setEliminar} seguimiento={true} />
                        }
                    />
                )}
                {agregar && (
                    <ModalAmplio
                        title={`Gestión de Acciones Recursos de ${acciones.seguimiento}`}
                        open={agregar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionAccionesRecursos accion={acciones} />
                        }
                    />
                )}
                {agregarNombramiento && (
                    <ModalAmplio
                        title={`Gestión de Nombramiento de ${acciones.seguimiento}`}
                        open={agregarNombramiento}
                        closeModal={() => { }}
                        cerrar={() => {
                            setAgregarNombramiento(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <CheckCircleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <GestionNombramiento accion={acciones} idExpediente={props?.infoExpediente} />
                        }
                    />
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteAcciones(idAcciones, token).then((data) => {
                                        if (data?.status >= 200 && data?.status <= 210) {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!');
                                            
                                            setTimeout(() => {
                                                setReFetch(false);
                                            }, 300);
                                        }
                                    }).catch(function (error) {
                                        if (error.response.status >= 400 && error.response.status <= 450) {
                                            toast.error(error.response.data.message);
                                            setLoading(false);
                                        } else if (error.response.status >= 500) {
                                            setLoading(false);
                                            toast.error("Error interno comuniquese con el administrador");
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}

                    {enviarSms && (
                    <ModalAmplio
                        title={`Enviar sms`}
                        open={enviarSms}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEnviarSms(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ChatIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                            </div>
                        }
                        children={
                            <MensajeriaSMS 
                                por_accion={true}
                                registro={acciones}
                                token={token}
                                editable={true}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};