import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../../components/common/Select";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import {
    postExpCriterioCierre,
    getMotivoCierre,
    getCriterioCierre,
    postGestionaCriterioCierre,
    putExpCriterioCierre,
    deleteGestionaCriterioCierre,
    getExpCriterioCierre,
    getGestionaCriterioCierre,
    deleteExpCriterioCierre,
    getExpCriterioCierreGestion
} from "./services";
import Spinner from "../../../../../components/common/Spinner";
import toast from 'react-hot-toast';
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import moment from "moment";
import TableSiavi from "../../../../../components/common/TableSiavi";
import * as Yup from "yup";
import { clases } from "../../../../../helpers/clases";

const validaciones = Yup.object().shape({
     resultado: Yup.string()
         .required("Resultado es requerido")
    //     .max(150, "Máximo 150 caracteres"),
});

const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";

export const Cierre = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const gestionFecha = bloqueoFecha();
    const idExpediente = props?.infoExpediente?.id;
    const [cargando, setCargando] = useState(true);
    const [refetch, setRefetch] = useState(false);
    const [motivos, setMotivos] = useState([]);
    const [criterios, setCriterios] = useState([]);
    const [detalleCierres, setCierresDetalle] = useState([]);
    const [expCierre, setExpCierre] = useState({});
    const [idExpCriterioCierre, setIdExpCriterioCierre] = useState(0);
    const [edit, setEdit] = useState(false);
    const [nuevoRegistro, setNuevoRegistro] = useState(false);

    useEffect(() => {
        setCargando(true);
        getGestionaCriterioCierre(idExpediente, token).then((data) => {
            setExpCierre(data);
            setCargando(false);
        });
    }, [idExpediente, setCargando, refetch, token]);

    useEffect(() => {
        getMotivoCierre(token).then((data) => {
            setMotivos(data);
        });
    }, [token]);

    useEffect(() => {
        getCriterioCierre(token).then((data) => {
            setCriterios(data);
        });
    }, [token]);

    console.log(props?.infoExpediente)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                caso: props.infoExpediente.caso,
                expediente: idExpediente,
                tipo_proceso: props?.infoExpediente?.tipo_proceso?.id,
                resultado: "",
                comentarios: "",
                fecha: "",
                fecha_gestionado: gestionFecha,
                se_cumple_criterio: false,
                motivo: "",
                usuario_gestionado: idUser,
                profesional_crea: idUser,
                gestiona_cierre: false,
                cierre_autorizado: null,
                profesional_solicita: idUser
            }}
            validationSchema={validaciones}
            onSubmit={async (values, { resetForm }) => {
                if (edit) {
                    await putExpCriterioCierre(idExpCriterioCierre, values, token)
                        .then(async (data) => {
                            if (data?.status === 200 || data?.status === 201) {
                                getExpCriterioCierreGestion(idExpCriterioCierre, token).then(
                                    (data) => {
                                        setCierresDetalle(data?.results);
                                    }
                                );
                                toast.success("Cierre del Expediente actualizada con éxito..!");
                                setRefetch(true);
                                resetForm({});
                                setEdit(false);
                                setNuevoRegistro(false);
                                setRefetch(false);
                            }
                        })
                        .catch(function (error) {
                            if (
                                error.response.status >= 400 &&
                                error.response.status <= 450
                            ) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error("Error interno comuniquese con el administrador");
                            }
                        });

                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 xl:col-span-5 lg:col-span-5 border p-3 rounded-md shadow-sm hover:shadow-md">
                            <div className="grid grid-cols-12 gap-2 mb-2">
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <SelectSiavi
                                        label="Motivo Cierre"
                                        name="motivo"
                                        value={values.motivo}
                                        onChange={(e) => {
                                            setFieldValue("motivo", e.target.value);
                                        }}
                                        validacion={true}
                                        valueInit=""
                                        selectInit="Elegir motivo"
                                        list={
                                            motivos?.results?.filter(x => x?.motivo_criterios_cierre_detalle?.servicio?.id === props?.infoExpediente?.servicio?.id).map((item) => {
                                                return (
                                                    <option key={item.nombre + "mc" + item.id} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <InputSiavi
                                        type="date"
                                        name="fecha"
                                        id="fecha"
                                        value={values.fecha}
                                        validacion={true}
                                        onChange={handleChange}
                                        maximo={bloqueoFecha()}
                                        label="Fecha"
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6 flex items-end justify-end">
                                    <ButtonSiavi
                                        type="button"
                                        text="Crear gestión"
                                        onClick={async () => {
                                            setCargando(true);
                                            await postGestionaCriterioCierre(values, token)
                                                .then(async (data) => {
                                                    if (data?.status === 200 || data?.status === 201) {
                                                        toast.success("Gestión de cierre creada con éxito..!");
                                                        setRefetch(true);
                                                        await criterios?.results?.filter(x => x.motivo_cierre === Number(values.motivo)).forEach((item) => {
                                                            values.gestionado = data.data.id;
                                                            values.fecha = data.data.fecha;
                                                            values.expediente = data.data.expediente.id;
                                                            values.caso = data.data.caso.id;
                                                            values.tipo_proceso = data?.data?.expediente?.tipo_proceso?.id;
                                                            values.criterio = item.id;
                                                            postExpCriterioCierre(values, token)
                                                                .then((data) => {
                                                                    if (data?.status === 200 || data?.status === 201) {
                                                                        getExpCriterioCierreGestion(idExpCriterioCierre, token).then(
                                                                            (data) => {
                                                                                setCierresDetalle(data?.results);
                                                                            }
                                                                        );
                                                                        toast.success("Cierre del Expediente creada con éxito..!");
                                                                        setNuevoRegistro(false);
                                                                        setRefetch(true);
                                                                    } else {
                                                                        toast.success("Cierre del Expediente creada con éxito..!");
                                                                    }
                                                                })
                                                                .catch(function (error) {
                                                                    if (
                                                                        error.response.status >= 400 &&
                                                                        error.response.status <= 450
                                                                    ) {
                                                                        toast.error(error.response.data.message);
                                                                    } else if (error.response.status >= 500) {
                                                                        toast.error("Error interno comuniquese con el administrador");
                                                                    }
                                                                });
                                                        })
                                                        resetForm({});
                                                        setRefetch(false);
                                                        setCargando(false);
                                                    }
                                                })
                                                .catch(function (error) {
                                                    if (
                                                        error.response.status >= 400 &&
                                                        error.response.status <= 450
                                                    ) {
                                                        setCargando(false);
                                                        toast.error(error.response.data.message);
                                                    } else if (error.response.status >= 500) {
                                                        setCargando(false);
                                                        toast.error("Error interno comuniquese con el administrador");
                                                    }
                                                });
                                        }}
                                    />
                                </div>
                            </div>
                            <TableSiavi
                                cargando={cargando}
                                headers={[
                                    'Expediente',
                                    'Fecha',
                                    'Motivo de cierre',
                                    'Gestionado',
                                    'Acciones'
                                ]}
                                body={expCierre?.results?.map((item) => (
                                    <tr
                                        className="hover:bg-maivi-gray-5 cursor-pointer"
                                        onClick={() => {
                                            getExpCriterioCierreGestion(item.id, token).then(
                                                (data) => {
                                                    setCierresDetalle(data?.results);
                                                }
                                            );
                                            resetForm({});
                                            setEdit(false);
                                            setIdExpCriterioCierre(item.id);
                                        }}
                                    >
                                        <td className={tdClass}>{item?.expediente?.numero_expediente}</td>
                                        <td className={tdClass}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                                        <td className={tdClass}>{item?.motivo?.descripcion}</td>
                                        <td className={tdClass}>{(item?.gestiona_cierre) === true ? "Sí" : "No"}</td>
                                        <td className={tdClass}>
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    setCargando(true);
                                                    await deleteGestionaCriterioCierre(item.id, token)
                                                        .then((data) => {
                                                            if (data?.status === 200 || data?.status === 201) {
                                                                setCargando(false);
                                                                toast.success("Registro eliminado con éxito...!");
                                                                setRefetch(true);
                                                                setRefetch(false);
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            if (
                                                                error.response.status === 400 ||
                                                                error.response.status === 401
                                                            ) {
                                                                setCargando(false);
                                                                toast.error(error.response.data.message);
                                                            } else if (error.response.status >= 500) {
                                                                toast.error(
                                                                    "Error interno comuniquese con el administrador"
                                                                );
                                                            }
                                                        });
                                                }}
                                                className={clases.trashIcon}
                                            >
                                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            />

                        </div>
                        <div className="col-span-12 xl:col-span-7 lg:col-span-7 border p-5 rounded-md shadow-sm hover:shadow-md">
                            <Label text="Agregar detalle de cierre" />
                            {
                                nuevoRegistro &&
                                <div className="grid grid-cols-12 gap-3">
                                    <div className="col-span-6 flex flex-col justify-center items-center">
                                        <Label text="Se cumple criterio" />
                                        <Toggle enabled={values.se_cumple_criterio} name="se_cumple_criterio" />
                                    </div>
                                    <div className="col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="resultado"
                                            id="resultado"
                                            value={values.resultado}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="*Resultado"
                                        />
                                    </div>
                                    <div className="col-span-6 mt-5">
                                        <ButtonSiavi
                                            type="submit"
                                            disabled={isSubmitting}
                                            text={(edit) ? 'Actualizar' : 'Guardar'}
                                        />
                                    </div>
                                </div>
                            }
                            <TableSiavi
                                headers={[
                                    'Expediente',
                                    'Criterio',
                                    'Se cumple criterio',
                                    'Acciones'
                                ]}
                                body={detalleCierres?.map((item) => (
                                    <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                                        <td className={tdClass}>{item?.expediente?.numero_expediente}</td>
                                        <td className={tdClass}>{item?.criterio?.nombre}</td>
                                        <td className={tdClass}>{(item?.se_cumple_criterio === true) ? "Sí" : "No"}</td>
                                        <td className={tdClass}>
                                            {!item?.gestionado?.gestiona_cierre && (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setFieldValue('resultado', item?.resultado);
                                                        setFieldValue('comentarios', item?.comentarios);
                                                        setFieldValue('fecha_gestionado', item?.fecha_gestionado);
                                                        setFieldValue('fecha', item?.fecha_gestionado);
                                                        setFieldValue('se_cumple_criterio', item?.se_cumple_criterio);
                                                        setFieldValue('criterio', item.criterio.id);
                                                        setFieldValue('gestionado', item.gestionado.id);
                                                        setFieldValue('expediente', item?.expediente?.id);
                                                        setFieldValue('caso', item?.caso?.id);
                                                        setFieldValue('tipo_proceso', item?.tipo_proceso?.id);
                                                        setEdit(true);
                                                        setNuevoRegistro(true);
                                                        setIdExpCriterioCierre(item.id)
                                                    }}
                                                    className={clases.addIcon2}
                                                >
                                                    <PencilIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                </button>
                                            )}
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    deleteExpCriterioCierre(item.id, token).then(() => {
                                                        getExpCriterioCierre(idExpCriterioCierre, token).then((data) => {
                                                            setCierresDetalle(data?.results);
                                                        });
                                                        toast.success('registro eliminado con éxito');
                                                    });
                                                }}
                                                className={clases.trashIcon}
                                            >
                                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            />
                        </div>
                    </div>
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};