const {
    REACT_APP_API_URL
} = process.env;

export const getPersonasCaso = async (idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}


export const getAcciones = async (persona, urlPage, token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?persona=${persona}&es_accion=true`
        : `${urlPage}&persona=${persona}&es_accion=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?estado=true&page_size=300`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoAccionesSeguimientos = async (atencion,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&atencion=${atencion}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (servicio,atencion, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${servicio}&profesionalatenciones__atencion=${atencion}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const accionesAtenciones = async (urlPage,caso,filtros, token) => {
    try {
      let urlFetch = urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?caso=${caso}&es_accion=true&accion_seguimiento_atencion__isnull=false&${filtros}`
          :`${urlPage}&es_accion=true&accion_seguimiento_atencionisnull=false&caso=${caso}&${filtros}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());
  
      return result;
    } catch (e) {
      console.log("error", e);
    }
  };