import { React, useEffect, useState } from "react";
import { login, resetPassword } from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { store } from "../../../app/redux/store/store";
import { saveLogin } from "../../../app/redux/actions/login";
import { Reset } from "./reset";
import { Preguntas } from "./preguntas";
import { OlvidePassword } from "./olvidePassword";
import { authorizationSession } from "../../../helpers/auth";
import { checkStatus } from "../../../app/redux/actions/auth/checkStatus";
import { ChangeSede } from "../../../app/redux/actions/sede";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import ModalGenerico from "../../../components/common/Modal";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import { getFavoritos, getPermisos } from "../../../helpers/utils";
import { ChangeFavoritos } from "../../../app/redux/actions/favoritos";
import { ChangePermisos } from "../../../app/redux/actions/permisos";

const logo = "https://d2lwggyn64d2dw.cloudfront.net/SIAVI-2022/Vertical/SIAVI-Vertical-blanco.png";
const logoIjm = process.env.PUBLIC_URL + "/images/logo-ijm-blanco.png";
const logoSiaviWhite = process.env.PUBLIC_URL + "/images/logo-siavi-white.png";
const PageInConstrucction = process.env.REACT_APP_CONSTRUCCION;
const AmbientePruebas = process.env.REACT_APP_DEV_MODE;

export const Login = () => {
  const [title, setTitle] = useState("");
  const [isLoading, setCargando] = useState(false);
  const [isReset, setReset] = useState(false);
  const [hasError, setError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [olvidePassword, setOlvidePassword] = useState(false);
  const [addPreguntas, setPreguntas] = useState(false);
  const [bloq, setbloq] = useState(false);
  const [token, setToken] = useState("");
  //const query = new URLSearchParams(window.location.search);
  //const pw = query.get("pw");
  const pw = localStorage.getItem("pw");
  const username = localStorage.getItem("username");

  useEffect(
    () => {
      if (pw !== undefined && pw !== null) {
        setReset(true);
        setTitle("Cambiar contraseña");
        setbloq(true);
      }
    },
    [pw]
  );

  const [state, setState] = useState({
    username: (username !== null || username !== undefined) ? username : '',
    password: (pw !== null || pw !== undefined) ? pw : '',
    newPassword: "",
    confirmPassword: "",
    usuario: "",
  });

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    setCargando(true);

    if (isSuccess) {
      window.location = "/";
    }

    if (state.newPassword !== state.confirmPassword) {
      setMensaje("Contraseñas no coinciden");
      setCargando(false);
      return null;
    }

    if (!isReset) {
      await login(state.username, state.password)
        .then(async (data) => {
          if (data.status === 200) {
            localStorage.removeItem('pw');
            if (data?.data?.usuario?.user.tienepreguntas === false) {
              setToken(data.data.token);
              let estado = await authorizationSession(data?.data?.token);
              let favoritos = await getFavoritos(data.data.usuario.id);
              let permisos = await getPermisos(data.data.usuario.user.rol_vista);

              store.dispatch(checkStatus(estado));
              store.dispatch(saveLogin({ ...data.data, token: estado.newToken }));
              if (data?.data?.usuario?.user?.otra_sede.length < 1) {
                store.dispatch(ChangeSede(data?.data?.usuario?.user?.sede));
              }
              store.dispatch(ChangeFavoritos(favoritos?.data?.favoritos));
              store.dispatch(ChangePermisos(permisos?.data));
              setCargando(false);
              setPreguntas(true);
            } else {
              let estado = await authorizationSession(data?.data?.token);
              let favoritos = await getFavoritos(data.data.usuario.id);
              let permisos = await getPermisos(data.data.usuario.user.rol_vista);

              store.dispatch(saveLogin({ ...data.data, token: estado.newToken }));
              store.dispatch(checkStatus(estado));
              if (data?.data?.usuario?.user?.otra_sede.length < 1) {
                store.dispatch(ChangeSede(data?.data?.usuario?.user?.sede));
              }
              store.dispatch(ChangeFavoritos(favoritos?.data?.favoritos));
              store.dispatch(ChangePermisos(permisos?.data));
              window.location = "/";
            }
          }

          if (data.status === 203) {
            setTitle("Cambiar Contraseña");
            setCargando(false);
            setReset(true);
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setError(true);
            setCargando(false);
            setMensaje(error.response.data.message);
          }

          if (error.response.status === 404) {
            setError(true);
            setCargando(false);
            setMensaje(error.response.data.message);
          }

          if (error.response.status === 403) {
            setError(true);
            setCargando(false);
            setMensaje(error.response.data.message);
          }

          if (error.response.status === 423) {
            setError(true);
            setCargando(false);
            setTitle("Restablecer Contraseña");
            setMensaje(error.response.data.message);
            setReset(true);
          }

          if (error.response.status >= 500) {
            setError(true);
            setCargando(false);
            setMensaje("Ocurrio un error comuniquese con el administrador");
          }
        });
    } else {
      await resetPassword(state.username, state.password, state.newPassword)
        .then((data) => {
          if (data.status === 200) {
            localStorage.removeItem('pw');
            setSuccess(true);
            setError(false);
            setCargando(false);
            setMensaje(data.data.message);

            setTimeout(() => {
              login(username, state.newPassword).then(async (data) => {
                let estado = await authorizationSession(data?.data?.token);
                store.dispatch(saveLogin({ ...data.data, token: estado.newToken }));
                store.dispatch(checkStatus(estado));
                localStorage.removeItem('username');
                window.location = "/";
              });
            }, 1800);
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setError(true);
            setCargando(false);
            setReset(true);
            setMensaje(error.response.data.message);
          }

          if (error.response.status === 404) {
            setError(true);
            setCargando(false);
            setReset(true);
            setMensaje(error.response.data.message);
          }

          if (error.response.status === 403) {
            setError(true);
            setCargando(false);
            setReset(true);
            setMensaje(error.response.data.message);
          }
        });
    }
  };

  return (
    <div
      className={`min-h-screen flex flex-col justify-center sm:px-6 lg:px-8 rounded-md`}
      style={{
        backgroundImage: "url(https://d2lwggyn64d2dw.cloudfront.net/SIAVI-2022/SIAVI-fondo.png)",
        backgroundSize: "cover"
      }}
    >
      <div className="mx-auto sm:max-w-md flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="w-10/12 lg:w-full xl:w-full" />
        <h2 className="mt-3 text-center lg:text-base md:text-base sm:text-sm font-bold text-maivi-primaryLightBlue">
          {title}
        </h2>
        {(AmbientePruebas === true || AmbientePruebas === 'true') &&
          <Link
            to={"/"}
            style={{ background: "green" }}
            className={"border border-yellow-400 w-full text-maivi-gray-5 group flex justify-center px-2 py-2 text-base font-medium rounded-md"}
          >
            <ExclamationIcon className="mr-4 flex-shrink-0 h-6 w-6 text-white" />
            Usted se conectara al Ambiente de Pruebas
          </Link>
        }
      </div>
      {(PageInConstrucction === true || PageInConstrucction === 'true') ?
        <div className="mx-auto sm:max-w-md flex flex-col justify-center items-center">
          <img src="https://d2lwggyn64d2dw.cloudfront.net/construction.svg" alt="Construccion" style={{ height: "150px" }} />
        </div>
        :
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md m-5">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {olvidePassword === true ? (
              <OlvidePassword
                onChange={handleInputChange}
                mensaje={mensaje}
                error={hasError}
                cargando={isLoading}
                username={state.username}
              />
            ) : (
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleLogin}
              >
                <div>
                  {!isReset
                    ?
                    (
                      <div>
                        <Label text="Usuario" />
                        <div className="mt-1">
                          <InputSiavi
                            id="username"
                            name="username"
                            autoComplete="false"
                            required
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    )
                    : ""}
                  {
                    (pw === null || pw === undefined) ?
                      <div className="mt-5" >
                        <Label text="Contraseña actual" />
                        <div className="mt-1">
                          <InputSiavi
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            onChange={handleInputChange}
                            disabled={bloq}
                          />
                        </div>
                      </div> : ''
                  }
                  {isReset ? <Reset onChange={handleInputChange} /> : ""}
                </div>
                <div>
                  {isSuccess ? (
                    <ButtonSiavi type="submit" text="Reingresar" />
                  ) : (
                    <ButtonSiavi
                      type="submit"
                      text={isReset === true ? "Cambiar" : "Iniciar Sesión"}
                    />
                  )}
                </div>
                {isSuccess ? <Alert mensaje={mensaje} type="success" /> : ""}
                {hasError ? <Alert mensaje={mensaje} type="error" /> : ""}
                {isLoading === true ? <Spinner /> : ""}
                {addPreguntas && (
                  <ModalGenerico
                    title={`No has configurado tus preguntas de seguridad, ¿quieres hacerlo ahora?`}
                    open={addPreguntas}
                    closeModal={() => {
                    }}
                    cerrar={() => {
                      setPreguntas(false);
                    }}
                    children={<Preguntas token={token} />}
                  />
                )}
                {!isReset
                  ?
                  (<div className="flex items-center justify-between">
                    <div className="text-sm">
                      <label
                        className="font-medium text-maivi-primaryBlue hover:text-maivi-primaryLightBlue cursor-pointer"
                        onClick={() => {
                          setOlvidePassword(true);
                          setMensaje("");
                        }}
                      >
                        ¿Olvidé mi contraseña?
                      </label>
                    </div>
                  </div>)
                  :
                  ""}

              </form>
            )}
          </div>
        </div>
      }
      <div className="mx-auto sm:max-w-md flex flex-col justify-center items-center">
        <div className="flex flex-row items-center justify-center">
          <img src={logoSiaviWhite} alt="img" className="w-4/12" />
          <img src={logoIjm} alt="img" className="w-4/12" />
        </div>
      </div>
    </div>
  );
};
