import { React, useState } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../components/common/Button";
import { SearchCircleIcon } from "@heroicons/react/outline";
import { clases } from "../../../../helpers/clases";
import { getCasos, getExpedientes } from "./services";
import Spinner from "../../../../components/common/Spinner";
import TableSiavi from "../../../../components/common/TableSiavi";
import Label from "../../../../components/common/Label";
import Alert from "../../../../components/common/Alert";

export const BuscarExpedientes = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const profesional = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [casos, setCasos] = useState([]);
    const [caso, setCaso] = useState(undefined);
    const [expedientes, setExpedientes] = useState([]);
    const [expediente, setExpediente] = useState(undefined)
    const [cargando, setCargando] = useState(false);
    const [cargandoExpedientes, setCargandoExpedientes] = useState(false);
    const [mensaje, setMensaje] = useState(false);

    const buscarCaso = (busqueda) => {
        setCargando(true);
        getCasos(busqueda, token).then((data) => {
            setCasos(data);
            setCargando(false);
        });
    }

    const buscarExpedientes = (caso) => {
        setCargandoExpedientes(true);
        getExpedientes(caso, profesional, token).then((data) => {
            setExpedientes(data);
            setCargandoExpedientes(false);
        });
    }
    return <div>
        <Formik
            initialValues={{
                busqueda_caso: "",
            }}
            onSubmit={async () => {
                if (caso === undefined || expediente === undefined) {
                    setMensaje(true);
                    setTimeout(() => {
                        setMensaje(false)
                    }, 3000);
                } else {
                    props.setCaso(caso);
                    props.setExpediente(expediente)
                    props.setModalAcciones(true);
                    props.setSolicitarExpediente(false);
                }

            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-2 items-end">
                        <div className="col-span-11">
                            <InputSiavi
                                type="text"
                                name="busqueda_caso"
                                placeholder="buscar por victima o expediente"
                                value={values.busqueda_caso}
                                onChange={handleChange}
                                validacion={true}
                                label="Busqueda del caso"
                                className="mb-2"
                            />
                        </div>
                        <div className="col-span-1">
                            <button
                                type="button"
                                className={`${clases.addIcon2} mb-2`}
                                onClick={() => {
                                    buscarCaso(values.busqueda_caso);
                                }}
                            >
                                <SearchCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                <span className="tooltiptext">Buscar expediente</span>
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <Label text="Seleccionar expediente" />
                        <TableSiavi
                            cargando={cargando}
                            empty="No existen expedientes realice una busqueda"
                            headers={[
                                'Expediente',
                                'Victima'
                            ]}
                            body={
                                casos?.results?.map((item) => (
                                    <tr
                                        id={item.id}
                                        key={item.id} className="hover:bg-maivi-gray-5 cursor-pointer"
                                        onClick={() => {
                                            let row;
                                            casos?.results?.forEach((element) => {
                                                let row = document.getElementById(element.id);
                                                row.classList.remove("bg-green-50");
                                            });
                                            row = document.getElementById(item.id);
                                            row.classList.add("bg-green-50");
                                            setCaso(item);
                                            buscarExpedientes(item.id);
                                        }}
                                    >
                                        <td className={clases.td}>
                                            {item?.numero_caso}
                                        </td>
                                        <td className={clases.td}>
                                            {item?.victima?.primer_nombre} {item?.victima?.segundo_nombre} {item?.victima?.primer_apellido} {item?.victima?.segundo_apellido}
                                        </td>
                                    </tr>
                                ))
                            }
                        />
                    </div>

                    <div className="mb-3">
                        <Label text="Seleccionar servicio" />
                        <TableSiavi
                            cargando={cargandoExpedientes}
                            empty="No existen expedientes realice una busqueda"
                            headers={[
                                'Servicio',
                                'Persona'
                            ]}
                            body={
                                props.tipo === "acción" ?
                                    expedientes?.results?.filter(x => x?.servicio?.atencion_victimologica)?.map((item, index) => (
                                        <tr
                                            id={item?.servicio?.nombre + index}
                                            key={item.id} className="hover:bg-maivi-gray-5 cursor-pointer"
                                            onClick={() => {
                                                let row;
                                                expedientes?.results?.filter(x => x?.servicio?.atencion_victimologica)?.forEach((element, index) => {
                                                    let row = document.getElementById(element.servicio?.nombre + index);
                                                    row.classList.remove("bg-green-50");
                                                });
                                                row = document.getElementById(item.servicio?.nombre + index);
                                                row.classList.add("bg-green-50");
                                                setExpediente(item);
                                            }}
                                        >
                                            <td className={clases.td}>
                                                {item?.servicio?.nombre}
                                            </td>
                                            <td className={clases.td}>
                                                {item?.caso_persona?.persona?.primer_nombre} {item?.caso_persona?.persona?.segundo_nombre} {item?.caso_persona?.persona?.primer_apellido} {item?.caso_persona?.persona?.segundo_apellido}
                                            </td>
                                        </tr>
                                    )) :

                                    expedientes?.results?.filter(x => x?.servicio?.asitencia_legal)?.map((item, index) => (
                                        <tr
                                            id={item?.servicio?.nombre + index}
                                            key={item.id} className="hover:bg-maivi-gray-5 cursor-pointer"
                                            onClick={() => {
                                                let row;
                                                expedientes?.results?.filter(x => x?.servicio?.asitencia_legal)?.forEach((element, index) => {
                                                    let row = document.getElementById(element.servicio?.nombre + index);
                                                    row.classList.remove("bg-green-50");
                                                });
                                                row = document.getElementById(item.servicio?.nombre + index);
                                                row.classList.add("bg-green-50");
                                                setExpediente(item);
                                            }}
                                        >
                                            <td className={clases.td}>
                                                {item?.servicio?.nombre}
                                            </td>
                                            <td className={clases.td}>
                                                {item?.caso_persona?.persona?.primer_nombre} {item?.caso_persona?.persona?.segundo_nombre} {item?.caso_persona?.persona?.primer_apellido} {item?.caso_persona?.persona?.segundo_apellido}
                                            </td>
                                        </tr>
                                    ))
                            }
                        />
                    </div>
                    {
                        mensaje &&
                        <div>
                            <Alert type="warning" mensaje="Debe seleccionar un expediente y un servicio" />
                        </div>
                    }
                    <ButtonSiavi
                        className="mt-2"
                        text="Continuar"
                        type="submit"
                        onClick={handleSubmit}
                    />
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    </div>
};
