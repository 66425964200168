/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { SelectSiavi } from "../../../components/common/Select";
import {
  //getDelitos,
  getProfesionales 
} from "./services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import makeAnimated from "react-select/animated";
import Select from "react-select";

export default function Filtros(props) {
  let filtros = "";
  const optionsProfesionales = [];
  //const optionsDelitos = [];
  //const [delitos, setDelitos] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const animatedComponents = makeAnimated();

  const [state, setState] = useState({
    profesional: "",
    delito: "",
    estado:"",
    es_nna: "",
    fecha_inicio: "",
    fecha_fin: "",
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  // useEffect(() => {
  //   getDelitos(props.token).then((data) => {
  //     setDelitos(data);
  //   })
  // }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item.first_name} ${item.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
  });

  // delitos?.results?.forEach((item) => {
  //   let reg = {
  //     value: item.id,
  //     label: item?.nombre,
  //   };
  //   optionsDelitos.push(reg);
  // });

  // const changeSelectDelito = (e) => {
  //   setState({
  //     ...state,
  //     delito: e.value,
  //   });
  // };

  const changeSelectProfesional = (e) => {
    setState({
      ...state,
      profesional: e.value,
    });
  };

  return (
    <div className="mx-4">
      <div className="col-span-12 mt-1">
        <Label text="Profesional" />
        <Select
          placeholder="Seleccionar profesional"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesional}
        />
      </div>
      {/* <div className="col-span-12 mt-4">
      <Label text="Delito" />
      <Select
          placeholder="Seleccionar delito"
          components={animatedComponents}
          name="delitos"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsDelitos}
          onChange={changeSelectDelito}
        />
      </div> */}
      <div className="col-span-12 mt-3 mb-2">
        <SelectSiavi
          name="es_nna"
          label="Es NNA"
          value={state.es_nna}
          onChange={onChange}
          initValue=""
          selectInit="Elegir"
          list={
           [
            <option value="true">Si</option>,
            <option value="false">No</option>
           ]
          }
        />
      </div>
      {/* <div className="col-span-12 mt-3 mb-2">
        <SelectSiavi
          name="estdo"
          label="Estado"
          value={state.estado}
          onChange={onChange}
          initValue=""
          selectInit="Elegir estado"
          list={
           [
            <option value="true">Activo</option>,
            <option value="false">Inactivo</option>
           ]
          }
        />
      </div> */}
      <div className="grid grid-cols-12 gap-2 mt-3">
        <div className="col-span-12">
          <Label text="Fecha" />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_inicio"
            value={state.fecha_inicio}
            onChange={onChange}
            label="Inicio"
          />
        </div>
        <div className="col-span-6">
          <InputSiavi
            type="date"
            name="fecha_fin"
            value={state.fecha_fin}
            onChange={onChange}
            label="Fin"
          />
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `&profesional=${state.profesional}&es_nna=${state.es_nna}&fecha__gte=${state.fecha_inicio}&fecha_lte=${state.fecha_fin}`;
            props.setUrlFech(undefined);
            props.setFiltros(filtros);
            props.setFiltrar(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}