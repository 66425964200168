
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DynamicIcon } from '../../components/common/DynamicIcon';
 
export const Menu = () => {

  const menuFavoritos = useSelector(state => state.ChangeFavoritos.menus ? state.ChangeFavoritos.menus : []);

  return (
    <div>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 m-0">
        {menuFavoritos.map((item, index) =>
          <Link key={`${index}-fav`} to={item.href}>
            <li className="col-span-1 flex shadow-lg rounded-md h-22">
              <div className="flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-maivi-primaryLightBlue"></div>
              <div className="h-24 flex-1 flex flex-col items-center justify-between border-t border-r border-b border-maivi-primaryLightBlue bg-white rounded-r-md whitespace-pre-line break-word hover:bg-maivi-secondaryBlueHover">
                <div className="flex flex-col items-center text-sm truncate my-auto">
                  <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                  <p className="xl:text-lg lg:text-md md:text-md sm:text-smtext-gray-900 text-center font-medium hover:text-gray-600 whitespace-pre-line break-word">
                    {item.name}
                  </p>
                </div>
              </div>
            </li>
          </Link>
        )}
      </ul>
    </div>
  );
};
