import moment from "moment";

export const Detalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Número sentencia
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.registro?.numero_sentencia}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Fecha sentencia
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {moment(props.registro.fecha).format("DD-MM-YYYY")}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Juzgado que Dicta Sentencia
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.registro?.juzgado?.nombre_fiscalia} 
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Reparacion digna
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {(props.registro.reparacion_digna) ? 'Si' : 'No'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
