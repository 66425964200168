/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect,useState } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
    putCasoPersonaVictima,
    getExpedientes
} from "./services"
import Label from "../../../../../components/common/Label";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import toast from 'react-hot-toast';
import Alert from "../../../../../components/common/Alert";

export const CambiarEstado = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [mensaje,setMensaje] = useState("");
    const [expedientes,setExpedientes] = useState([]);
    const [cargando,setCargando] = useState(true);
    const [alert,setAlert] = useState(false)

    useEffect(() => {
        getExpedientes(props.registro.persona.id, token).then((data) => {
            setExpedientes(data);
            setCargando(false);
        });
    }, []);

    return (
        <Formik
            initialValues={{
                estado: props?.registro?.estado || false,
                usuario: usuario
            }}
            onSubmit={async (values) => {

                if(expedientes.count > 0) {
                    setMensaje("No es posible inactivarlo porque tiene expedientes y caso asociados.");
                    setAlert(true);
                } else {
                    await putCasoPersonaVictima(props.registro.id, values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            toast.success("registro actualizado con éxito");
                            props.setReFetch(true);
                            setTimeout(() => {
                                props.setReFetch(false);
                            }, 400);
                            props.setCambiarEstado(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
    
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
                }
              
            }}
        >
            {({ values, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3 flex flex-col items-center justify-center">
                        <Label text={`${values.estado ? 'Activa' : 'Inactiva'}`} />
                        <Toggle enabled={values.estado} name="estado" />
                    </div>
                    {
                        alert &&
                        <div className="mb-2">
                            <Alert type="warning" mensaje={mensaje} />
                        </div>
                    }
                    {
                        (!cargando) ? 
                        <ButtonSiavi
                                type="button"
                                disabled={isSubmitting}
                                text={"Actualizar"}
                                onClick={()=>{
                                    if(props.registro.victima){
                                        setMensaje("No es posible inactivarlo porque tiene expedientes y caso asociados.")
                                        setAlert(true);
                                        setTimeout(() => {
                                            setAlert(false);
                                        }, 4000);
                                    } else if(props.registro.es_victima_indirecta && props.registro.querellante){
                                        setMensaje("Debe hacer el proceso desde la opcion del querellante.")
                                        setAlert(true);
                                        setTimeout(() => {
                                            setAlert(false);
                                        }, 4000);
                                    }
                                    
                                    else {
                                        handleSubmit()
                                    }
                                }}
                            /> :
                        <Spinner /> 
                    }
                    {isSubmitting && <Spinner />}
                </form>
            )}
        </Formik>
    );
};
