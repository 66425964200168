/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { SelectSiavi } from "../../../../../../components/common/Select";
import { InputSiavi } from "../../../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { clases } from "../../../../../../helpers/clases";
import { EyeIcon, FilterIcon } from "@heroicons/react/outline";
import { TextAreaSiavi } from "../../../../../../components/common/TextArea";
import { GestionArchivos } from "../../../../../../components/ArchivosSiavi/archivos";
import { Seguimiento } from "./seguimiento";
import { SeguimientoVictima } from "./seguimiento_victima";
import { getExpedienteSindicadoDelitos, getPersonasCasoDetails } from "../../../../orientacion/editar/services";
import { FormDetalle } from "./Detalle";
import {
  TrashIcon,
  PaperClipIcon,
  ClipboardListIcon,
  PrinterIcon,
  UserIcon,
  PencilAltIcon,
  XCircleIcon
} from "@heroicons/react/solid";
import {
  getPersonas,
  getInstituciones,
  getServiciosInternos,
  getServiciosExternos,
  postReferencia,
  postReferenciaInterna,
  postReferenciaExterna,
  getAtenciones,
  getReferencias,
  getProfesionalesAtenciones,
  putReferencia,
  putEstadoReferencia
} from "./services";
import ModalAmplio from "../../../../../../components/common/ModalAmplio";
import BotonesConfirmar from "../../../../../../components/common/ButtonsConfirmacion";
import FiltrosGestionInstitucion from "../../../../orientacion/listar/filtrosInstitucion";
import ModalGenerico from "../../../../../../components/common/Modal";
import ButtonAdd from "../../../../../../components/common/ButtonAdd";
import Toggle from "../../../../../../components/common/Toggle";
import Label from "../../../../../../components/common/Label";
import Select from "react-select";
import TableSiavi from "../../../../../../components/common/TableSiavi";
import toast from "react-hot-toast";
import Spinner from "../../../../../../components/common/Spinner";
import Alert from "../../../../../../components/common/Alert";
import ModalBig from "../../../../../../components/common/ModalTwo";
import PdfViewer from "../../../../../../components/common/PdfViewer";
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
  tipo_referencia: Yup.string().required("tipo referencia es requerido"),
  persona: Yup.string().required("persona es requerida"),
});

const listReferencias = [
  {
    value: "true",
    label: "Referencia interna",
  },
  {
    value: "false",
    label: "Referencia externa",
  },
];

const headersReferenciaInterna = [
  "Servicio/atencion",
  //"Especificacion",
  "Eliminar"];

const headersReferenciaExterna = [
  "Servicio",
  "Area",
  //"Especificacion",
  "Eliminar",
];

const headersReferencias = [
  "Fecha",
  "Código",
  "Persona",
  "Servicio",
  "Usuario creación",
  "Estado",
  "Acciones",
];

export const FormReferencia = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const usuarioLogin = useSelector((state) => state.loginReducer.data.usuario.user);
  const [verDetalle, setDetalle] = useState(false);
  const [referenciaDetalle, setReferenciaDetalle] = useState({});
  const [personas, setPersonas] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [serviciosInternos, setServiciosInternos] = useState([]);
  const [serviciosExternos, setServiciosExternos] = useState([]);
  const [atenciones, setAtenciones] = useState([]);
  const [institucionSeleccionada, setInstitucionSeleccionada] = useState("");
  const [addServiciosInternos, setAddServiciosInternos] = useState([]);
  const [addServiciosExternos, setAddServiciosExternos] = useState([]);
  const [referencias, setReferencias] = useState([]);
  const [filtrar, setFiltrar] = useState(false);
  const [filtros, setFiltros] = useState("");
  const [refetch, setRefetch] = useState([]);
  const [cargandoReferencias, setCargandoReferencias] = useState(false);
  const [personaSeleccionada, setPersonaSeleccionada] = useState(0);
  const [archivos, setArchivos] = useState(false);
  const [idRegistro, setIdRegistro] = useState(0);
  const [addSeguimiento, setAddSeguimiento] = useState(false);
  const [seguimientoVictima, setSeguimientoVictima] = useState(false);
  const [referencia, setReferencia] = useState({});
  const [referenciaPdf, setReferenciaPdf] = useState(false);
  const [referenciaPdfData, setReferenciaPdfData] = useState({});
  const [profesionales, setProfesionales] = useState([]);
  const [editar, setEditar] = useState(false);
  const [inactivar, setInactivar] = useState(false);
  const [atencion, setAtencion] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [valor, setValor] = useState("");

  const optionsInstituciones = [];
  const caso = props?.caso;
  const expediente = props?.expediente;


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "ExpedientesAV";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });

    //        retorno = true;  // mm comentar para que funcione
    //           retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  useEffect(() => {
    getPersonas(caso.id, token).then((data) => {
      setPersonas(data?.results);
    });
  }, []);

  useEffect(() => {
    if (filtros !== "") {
      getInstituciones(filtros, token).then((data) => {
        setInstituciones(data?.results);
      });
    }
  }, [filtros]);

  useEffect(() => {
    getServiciosInternos(token).then((data) => {
      let servicios = [];
      let existe = false;
      data?.results?.forEach((element) => {
        caso?.expedientes?.filter((x) => x?.caso_persona?.persona?.id === Number(personaSeleccionada))?.forEach((element2) => {
          if (element.id === element2?.servicio?.id) {
            existe = true;
          }
        });

        if (!existe) {
          servicios.push(element);
        }

        existe = false;
      });
      setServiciosInternos(servicios);
    });
  }, [personaSeleccionada]);

  useEffect(() => {
    getAtenciones(token).then((data) => {
      setAtenciones(data?.results);
    });
  }, []);

  useEffect(() => {
    getServiciosExternos(token).then((data) => {
      setServiciosExternos(data?.results);
    });
  }, []);

  useEffect(() => {
    if (atencion !== null) {
      getProfesionalesAtenciones(atencion, token).then((data) => {
        setProfesionales(data?.results);
      });
    }
  }, [atencion]);

  useEffect(() => {
    setCargandoReferencias(true);
    getReferencias(caso.id, token).then((data) => {
      setReferencias(data);
      setCargandoReferencias(false);
    });
  }, [refetch]);

  instituciones?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item?.nombre_fiscalia,
    };
    optionsInstituciones.push(reg);
  });

  const changeSelectInstituciones = (e) => {
    setInstitucionSeleccionada(e.value);
  };

  return (
    <Formik
      initialValues={{
        tipo: false,
        atencion: "",
        tipo_referencia: "",
        referencia_trabajo_social: false,
        solicitud_referencia: false,
        es_nna: false,
        persona: "",
        representante: "",
        institucion_refiere: "",
        expediente: expediente,

        caso: caso.id,
        orientacion: caso.orientacion,
        motivos_referencia: "",
        asistencia_atencion_especifica: "",
        seguimiento: true,
        profesional: ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (editar) {
          await putReferencia(idRegistro, values, token)
            .then(async (data) => {
              if (data?.status === 200 || data?.status === 201) {
                toast.success("referencia actualizada con éxito..!");
                setRefetch(true);
                setRefetch(false);
                resetForm({});
                setEditar(false);
                setValor(null);
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });

        } else {
          if (institucionSeleccionada === "" && values.tipo_referencia === "false") {
            toast.error("Debe seleccionar una institución donde refiere");
          } else {
            values.institucion_refiere = institucionSeleccionada;

            if (addServiciosInternos.length === 0 && values.tipo_referencia === "true") {
              toast.error('Debe de agregar servicios o atenciones 1');
            } else if (addServiciosExternos.length === 0 && values.tipo_referencia === "false") {
              toast.error('Debe de agregar servicios o atenciones 2');
            } else {
              await postReferencia(values, token)
                .then(async (data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    await addServiciosInternos.forEach(async (element) => {
                      await postReferenciaInterna(element, data?.data?.id, token);
                    });

                    await addServiciosExternos.forEach(async (element) => {
                      await postReferenciaExterna(element, data?.data?.id, token);
                    });

                    toast.success("referencia agregada con éxito..!");
                    setRefetch(true);
                    setRefetch(false);
                    resetForm({});
                    setAddServiciosInternos([]);
                    setValor(null);
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status >= 400 &&
                    error.response.status <= 450
                  ) {
                    toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
            }
          }
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className="grid grid-cols-12 gap-2 mt-3">
          <div className="col-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-3 hover:shadow-md ">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 ">
                <SelectSiavi
                  name="tipo_referencia"
                  value={values.tipo_referencia}
                  label="Tipo referencia"
                  validacion={true}
                  onChange={handleChange}
                  valueInit=""
                  selectInit="Elegir referencia"
                  disabled={(editar) ? true : false}
                  list={listReferencias.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                />
              </div>
              <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2 flex flex-col justify-center items-center">
                <Label text="Es NNA?" />
                <Toggle enabled={values.es_nna} name="es_nna" disabled={(editar) ? true : false} onChange={() => { }} />
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <SelectSiavi
                  label="Persona"
                  validacion={true}
                  name="persona"
                  value={values.persona}
                  valueInit=""
                  selectInit="Elegir persona"
                  disabled={(editar) ? true : false}
                  onChange={(e) => {
                    setFieldValue("persona", e.target.value);
                    setPersonaSeleccionada(e.target.value);
                  }}
                  list={personas.filter(x => x?.persona?.victima_fallecida === false).map((item, index) => (
                    <option key={item?.id + index} value={item?.persona?.id}>
                      {item?.persona?.primer_nombre}{" "}
                      {item?.persona?.segundo_nombre}{" "}
                      {item?.persona?.primer_apellido}{" "}
                      {item?.persona?.segundo_apellido}
                    </option>
                  ))}
                />
              </div>
              {values.es_nna && (
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
                  <SelectSiavi
                    label="Representante"
                    validacion={true}
                    name="representante"
                    value={values.representante}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir representante"
                    disabled={(editar) ? true : false}
                    list={personas
                      ?.filter((x) => x.querellante)
                      .map((item, index) => (
                        <option
                          key={item.persona.id + index}
                          value={item?.persona?.id}
                        >
                          {item?.persona?.primer_nombre}{" "}
                          {item?.persona?.segundo_nombre}{" "}
                          {item?.persona?.primer_apellido}{" "}
                          {item?.persona?.segundo_apellido}
                        </option>
                      ))}
                  />
                </div>
              )}
              {values.tipo_referencia === "false" ? (
                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-2 xl:col-span-2 flex flex-col justify-end items-end">
                      <ButtonAdd
                        text="Filtrar"
                        type="button"
                        onClick={() => {
                          setFiltrar(true);
                        }}
                        icon={<FilterIcon className=" h-5" />}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-10 lg:col-span-10 md:col-span-10">
                      <Label text="Institucion donde refiere" />
                      <Select
                        inputValue={valor}
                        onInputChange={(e) => {
                          setValor(e.label)
                        }}
                        placeholder="Filtrar y seleccionar"
                        name="institucion"
                        className="basic-multi-select text-xs text-maivi-primaryBlue w-full"
                        options={optionsInstituciones}
                        onChange={changeSelectInstituciones}
                        maxMenuHeight={240}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
                <TextAreaSiavi
                  rows={2}
                  cols={2}
                  label="Motivos referencia"
                  name="motivos_referencia"
                  value={values.motivos_referencia}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6">
                <TextAreaSiavi
                  rows={2}
                  cols={2}
                  label="Asistencia atencion especifica"
                  name="asistencia_atencion_especifica"
                  value={values.asistencia_atencion_especifica}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-4 xl:col-span-6 flex items-end">
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  CheckPermiso('TSCrearReferencia') === true &&
                    
                  <ButtonSiavi
                    type="button"
                    text={(editar) ? 'Actualizar' : 'Guardar'}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-span-12 xl:col-span-6 lg:col-span-6 border rounded-lg p-3 hover:shadow-md">
            {
              (editar) ? "" :

                values.tipo_referencia === "" ? (
                  <Alert type="info" mensaje="Seleccione un tipo de referencia" />
                ) : values.tipo_referencia === "true" ? (
                  <div className="grid grid-cols-12 gap-2 mt-2 mx-2">
                    <div className="col-span-12 xl:col-span-4 flex flex-col justify-center items-center">
                      <Label text="Servicio/atencion" />
                      <Toggle
                        enabled={values.tipo}
                        name="tipo"
                        onChange={() => {
                          setAddServiciosExternos([]);
                          setAddServiciosInternos([]);
                        }}
                      />
                    </div>
                    {values.tipo === false && (
                      <div className="col-span-12 xl:col-span-8">
                        <SelectSiavi
                          id="servicio_interno"
                          label="Servicios internos"
                          validacion={true}
                          name="servicio_interno"
                          value={values.servicio_interno}
                          onChange={handleChange}
                          valueInit=""
                          selectInit="Elegir servicio interno"
                          list={serviciosInternos.map((item, index) => (
                            <option key={item?.id + index} value={item.id}>
                              {item?.nombre}
                            </option>
                          ))}
                        />
                      </div>
                    )}
                    {values.tipo && (
                      <div className="col-span-12 xl:col-span-8">
                        <SelectSiavi
                          id="atencion"
                          label="Atenciones"
                          validacion={true}
                          name="atencion"
                          value={values.atencion}
                          onChange={(e) => {
                            setFieldValue('atencion', e.target.value);
                            setAtencion(e.target.value);
                          }}
                          valueInit=""
                          selectInit="Elegir atencion"
                          list={atenciones?.map((item, index) => (
                            <option key={item?.id + index} value={item.id}>
                              {item?.nombre}
                            </option>
                          ))}
                        />
                      </div>
                    )}
                    {values.atencion && (
                      <div className="col-span-12">
                        <SelectSiavi
                          id="profesional"
                          label="Profesional asignado"
                          validacion={true}
                          name="profesional"
                          value={values.profesional}
                          onChange={handleChange}
                          valueInit=""
                          selectInit="Elegir profesional"
                          list={profesionales?.map((item, index) => (
                            <option key={item?.first_name + index} value={item?.id}>
                              {item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}
                            </option>
                          ))}
                        />
                      </div>
                    )}
                    {/* <div className="col-span-12 xl:col-span-12">
                    <TextAreaSiavi
                      rows={3}
                      label="Especificaciones"
                      name="especificaciones"
                      value={values.especificaciones}
                      onChange={handleChange}
                    />
                  </div> */}
                    <div className="xl:col-span-9 lg:col-span-8 md:col-span-7"></div>
                    <div className="col-span-4 xl:col-span-3 lg:col-span-4 md:col-span-5 flex items-end">
                      {addServiciosInternos.length === 0 && (
                        <ButtonSiavi
                          text="Agregar"
                          onClick={() => {
                            let text = "";
                            let value = "";

                            let value2 = "";
                            let text2 = "";
                            if (!values.tipo) {
                              let combo = document.getElementById("servicio_interno");
                              text = combo.options[combo.selectedIndex].text;
                              value = combo.options[combo.selectedIndex].value;
                            } else {
                              let combo2 = document.getElementById("atencion");
                              text2 = combo2.options[combo2.selectedIndex].text;
                              value2 = combo2.options[combo2.selectedIndex].value;
                            }

                            let registro = {
                              servicio: text,
                              atencion: text2,
                              valorServicio: value,
                              valorAtencion: value2,
                              especificaciones: values.especificaciones,
                              profesional_asignado: values.profesional,
                            };

                            if (text === "Elegir servicio interno") {
                              toast.error("Debe elegir un servicio interno");
                            } else {
                              setAddServiciosInternos([
                                ...addServiciosInternos,
                                registro,
                              ]);
                              values.servicio_interno = "";
                              values.especificaciones = "";
                              values.profesional = "";
                              values.atencion = "";
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="col-span-12">
                      <TableSiavi
                        headers={headersReferenciaInterna}
                        body={addServiciosInternos.map((item) => (
                          <tr className={clases.tr}>
                            <td className={clases.td}>{(item.servicio === "") ? item.atencion : item.servicio}</td>
                            {/* <td className={clases.td}>{item.especificaciones}</td> */}
                            <td>
                              <button
                                type="button"
                                className={clases.trashIcon}
                                onClick={async () => {
                                  let filter = addServiciosInternos.filter(
                                    (x) => x.valorServicio !== item.valorServicio
                                  );
                                  setAddServiciosInternos([...filter]);
                                }}
                              >
                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                <span className="tooltiptext text-xs">
                                  Eliminar
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-12 gap-2 mt-1 mx-2">
                    <div className="col-span-4 xl:col-span-6">
                      <SelectSiavi
                        id="servicio_externo"
                        label="Servicio externo"
                        validacion={true}
                        name="servicio_externo"
                        value={values.servicio_externo}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir servicio externo"
                        list={serviciosExternos.map((item, index) => (
                          <option key={item?.id + index} value={item.id}>
                            {item?.nombre}
                          </option>
                        ))}
                      />
                    </div>
                    <div className="col-span-4 xl:col-span-6">
                      <InputSiavi
                        label="Área o unidad a donde se refiere"
                        name="area_institucion"
                        value={values.area_institucion}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className="col-span-12 xl:col-span-12">
                    <TextAreaSiavi
                      rows={3}
                      label="Especificaciones"
                      name="especificaciones_externas"
                      value={values.especificaciones_externas}
                      onChange={handleChange}
                    />
                  </div> */}
                    <div className="xl:col-span-9 lg:col-span-8"></div>
                    <div className="col-span-4 xl:col-span-3 lg:col-span-4 flex items-end">
                      <ButtonSiavi
                        text="Agregar"
                        onClick={() => {
                          let combo = document.getElementById("servicio_externo");
                          let text = combo.options[combo.selectedIndex].text;
                          let value = combo.options[combo.selectedIndex].value;
                          let registro = {
                            servicio: text,
                            valorServicio: value,
                            area_institucion: values.area_institucion,
                            especificaciones_externas:
                              values.especificaciones_externas,
                          };
                          setAddServiciosExternos([
                            ...addServiciosExternos,
                            registro,
                          ]);
                        }}
                      />
                    </div>
                    <div className="col-span-12">
                      <TableSiavi
                        headers={headersReferenciaExterna}
                        body={addServiciosExternos.map((item) => (
                          <tr className={clases.tr}>
                            <td className={clases.td}>{item.servicio}</td>
                            <td className={clases.td}>{item.area_institucion}</td>
                            {/* <td className={clases.td}>
                            {item.especificaciones_externas}
                          </td> */}
                            <td>
                              <button
                                type="button"
                                className={clases.trashIcon}
                                onClick={async () => {
                                  let filter = addServiciosExternos.filter(
                                    (x) => x.valorServicio !== item.valorServicio
                                  );
                                  setAddServiciosExternos([...filter]);
                                }}
                              >
                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                <span className="tooltiptext text-xs">
                                  Eliminar
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      />
                    </div>
                  </div>
                )
            }
          </div>
          <div className="col-span-12 p-3 border rounded-lg hover:shadow-md h-64 overflow-y-auto">
            <Label text="Referencias creadas" />
            <TableSiavi
              cargando={cargandoReferencias}
              headers={headersReferencias}
              body={referencias?.results?.map((item) => (
                <tr className={clases.tr}>
                  <td className={clases.td}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                  <td className={clases.td}>
                    {item?.codigo_referencia === null
                      ? item?.codigo_referencia_ts
                      : item.codigo_referencia}
                  </td>
                  <td className={clases.td}>
                    <span className={`${clases.estado} ${(item.estado === true || item.estado === null) ? clases.estadoActivo : clases.estadoInactivo}`}>
                      {(item?.estado === true || item?.estado === null)
                        ? 'Activo'
                        : 'Inactivo'}
                    </span>
                  </td>
                  <td className={clases.td}>
                    {item?.persona?.primer_nombre}{" "}
                    {item?.persona?.segundo_nombre}{" "}
                    {item?.persona?.primer_apellido}{" "}
                    {item?.persona?.segundo_apellido}
                  </td>
                  <td className={clases.td}>
                    {item?.referencias_internas?.nombre}
                  </td>
                  <td className={clases.td}>
                    {item?.codigo_referencia === null
                      ? `${item?.profesional_crea_referencia_ts?.first_name} ${item?.profesional_crea_referencia_ts?.last_name}`
                      : `${item?.profesional_crea.first_name} ${item?.profesional_crea.last_name}`}
                  </td>
                  <td className={clases.td}>
                    <button
                      type="button"
                      className={clases.addIcon}
                      onClick={async () => {
                        setDetalle(true);
                        setReferenciaDetalle(item);
                      }}
                    >
                      <EyeIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                      <span className="tooltiptext text-xs">Ver detalle</span>
                    </button>
                    {(CheckPermiso('TSEditarReferencias') === true) &&
                      <button
                        type="button"
                        className={clases.addIcon}
                        onClick={async () => {
                          setEditar(true);
                          setIdRegistro(item.id);
                          setFieldValue('asistencia_atencion_especifica', item?.asistencia_atencion_especifica);
                          setFieldValue('motivos_referencia', item?.motivo_referencia);
                          setFieldValue('persona', item?.persona?.id);
                          setFieldValue('es_nna', item?.es_nna);
                          setFieldValue('representante', item?.representante);
                          setFieldValue('tipo_referencia', (item?.referencia_interna) ? 'true' : 'false');
                          setValor(item?.gestion_institucion?.nombre_fiscalia);
                        }}
                      >
                        <PencilAltIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                        <span className="tooltiptext text-xs">Editar</span>
                      </button>
                    }
                    {(CheckPermiso('TSReferenciasDocumentos') === true) &&
                      <button
                        type="button"
                        className={clases.addIcon}
                        onClick={async () => {
                          setIdRegistro(item.id);
                          setArchivos(true);
                        }}
                      >
                        <PaperClipIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                        <span className="tooltiptext text-xs">
                          Adjuntar documentos
                        </span>
                      </button>
                    }
                    {(CheckPermiso('TSReferenciaSeguimiento') === true) &&
                      <button
                        type="button"
                        className={clases.addIcon}
                        onClick={async () => {
                          setIdRegistro(item.id);
                          setAddSeguimiento(true);
                          setReferencia(item);
                        }}
                      >
                        <ClipboardListIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                        <span className="tooltiptext text-xs">
                          Agregar seguimiento
                        </span>
                      </button>
                    }
                    {(CheckPermiso('TSReferenciaSeguimiento') === true) &&
                      <button
                        type="button"
                        className={clases.addIcon}
                        onClick={async () => {
                          setIdRegistro(item.id);
                          setAddSeguimiento(true);
                          setReferencia(item);
                          setSeguimientoVictima(true);
                        }}
                      >
                        <UserIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                        <span className="tooltiptext text-xs">
                          Agregar seguimiento a víctima
                        </span>
                      </button>
                    }
                    {(CheckPermiso('TSImprimirReferencia') === true) &&
                      <button
                        type="button"
                        className={clases.addIcon}
                        onClick={async () => {
                          let personas = [];
                          personas.push(item.persona.id);

                          if (item.representante !== null) {
                            personas.push(item?.representante?.id);
                          }

                          let reporte = "";
                          let codigoIso = "";

                          if (item.referencia_interna) {
                            if (item.expediente.servicio_propio.md) {
                              reporte = "referencia-medica";
                            }
                            else if (item.es_nna) {
                              reporte = "referencia-interna-nna";
                              codigoIso = "DTS-FOR-4";
                            } else {
                              reporte = "referencia-interna-adultos";
                              codigoIso = "DTS-FOR-5";
                            }
                          } else {
                            if (item.expediente.servicio_propio.md) {
                              reporte = "referencia-medica";
                            }
                            else if (item.es_nna) {
                              reporte = "referencia-externa-nna";
                              codigoIso = "DTS-FOR-2";
                            } else {
                              reporte = "referencia-externa-adultos";
                              codigoIso = "DTS-FOR-3";
                            }
                          }

                          let tipo_victima = 'Directa';
                          caso.personas.forEach(per => {
                            if (per.persona.id === item.persona.id) {
                              if (per.es_victima_indirecta) {
                                tipo_victima = 'Indirecta';
                              }
                            }
                          });

                          await getExpedienteSindicadoDelitos(item.caso.id, token).then(async (dataSindicadoDelitos) => {
                            await getPersonasCasoDetails(personas, token).then((data) => {
                              let persona = {};
                              let representante = {};
                              data?.personas.forEach(per => {
                                if (per.id === item.persona.id) {
                                  persona = per;
                                }
                                if (per.id === item?.representante?.id) {
                                  representante = per;
                                }
                              });
                              setReferenciaPdfData({
                                usuario: usuarioLogin.first_name + ' ' + usuarioLogin.last_name,
                                codigo_referencia: item.codigo_referencia,
                                reporte: reporte,
                                motivo: item.motivo_referencia,
                                asistencia: item.asistencia_atencion_especifica,
                                fecha: item.fecha,
                                sede: item?.sede?.sede,
                                persona: persona,
                                representante: representante,
                                expediente: item?.caso?.numero_caso,
                                gestion_institucion: item?.gestion_institucion,
                                tipo_victima: tipo_victima,
                                referencia_interna: item?.referencias_internas?.nombre || 'Otra',
                                referencia_ts: item.referencia_ts,
                                profesional_ts: item.profesional_crea_referencia_ts,
                                profesional: item.profesional_crea,
                                codigo_referencia_ts: item.codigo_referencia_ts,
                                fecha_asignacion: item.fecha_asignacion,
                                servicios_externos: item?.servicios_externos,
                                delitos: dataSindicadoDelitos.results,
                                codigoIso: codigoIso
                              })
                              setReferenciaPdf(true);
                            });
                          })

                        }}
                      >
                        <PrinterIcon className="h-6 w-6 text-maivi-primaryBlue hover:text-white" />
                        <span className="tooltiptext text-xs">Imprimir</span>
                      </button>
                    }
                    {(CheckPermiso('TSInactivarReferencia') === true) &&
                      <button
                        type="button"
                        className={clases.trashIcon}
                        onClick={async () => {
                          setIdRegistro(item.id);
                          setReferencia(item);
                          setInactivar(true);
                        }}
                      >
                        <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
                        <span className="tooltiptext text-xs">
                          Inactivar referencia
                        </span>
                      </button>
                    }
                  </td>
                </tr>
              ))}
            />
          </div>
          {filtrar && (
            <ModalGenerico
              title={"Filtrar gestión institución"}
              open={filtrar}
              closeModal={() => { }}
              cerrar={() => {
                setFiltrar(false);
              }}
              children={
                <FiltrosGestionInstitucion
                  setFiltrar={setFiltrar}
                  setFiltros={setFiltros}
                />
              }
            />
          )}
          {archivos && (
            <ModalAmplio
              title={`Adjuntar archivos`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setArchivos(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PaperClipIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <GestionArchivos
                  idRecurso={idRegistro}
                  recurso="REFERENCIAS"
                  claseDocumento="referencias"
                  expediente={expediente}
                  caso={props?.caso?.id}
                />
              }
            />
          )}

          {referenciaPdf && (
            <ModalAmplio
              title={`Imprimir Referencia`}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setReferenciaPdf(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <PrinterIcon className="h-6 w-6 text-green-600" />
                </div>
              }
              children={
                <PdfViewer
                  data={referenciaPdfData}
                  reporte={referenciaPdfData?.reporte}
                  style={{ height: "80vh" }}
                />
              }
            />
          )}
          {addSeguimiento && (
            <ModalBig
              title={`Agregar seguimiento interno y/o externo ${(seguimientoVictima) ? 'a victima' : ''} `}
              open={true}
              closeModal={() => { }}
              cerrar={() => {
                setAddSeguimiento(false);
                setSeguimientoVictima(false);
              }}
              children={
                (seguimientoVictima) ? <SeguimientoVictima referencia={referencia} seguimientoVictima={true} />
                  : <Seguimiento referencia={referencia} seguimientoVictima={false} />
              }
            />
          )}
          {verDetalle && (
            <ModalGenerico
              title={"Detalle de Referencia"}
              open={verDetalle}
              closeModal={() => { }}
              cerrar={() => {
                setDetalle(false);
              }}
              children={
                <FormDetalle detalle={referenciaDetalle} />
              }
            />
          )}
          {inactivar && (
            <ModalGenerico
              title={"¿Esta seguro que desea inactivar la referencia?"}
              open={inactivar}
              closeModal={() => { }}
              cerrar={() => {
                setInactivar(false);
              }}
              children={
                <BotonesConfirmar
                  isloading={cargando}
                  aceptar={async () => {
                    setCargando(true);
                    await putEstadoReferencia(idRegistro, token)
                      .then(async (data) => {
                        if (data?.status === 200 || data?.status === 201) {
                          toast.success("referencia inactivada con éxito..!");
                          setRefetch(true);
                          setRefetch(false);
                          setInactivar(false);
                          setCargando(false);
                        }
                      })
                      .catch(function (error) {
                        if (
                          error.response.status >= 400 &&
                          error.response.status <= 450
                        ) {
                          toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                          toast.error("Error interno comuniquese con el administrador");
                        }
                      });
                  }}
                  cancelar={() => {
                    setInactivar(false);
                  }}
                />
              }
            />
          )}
        </div>
      )}
    </Formik>
  );
};
