export const FormDetalleSeguimiento = (props) => {
    return (
        <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg mt-4">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Retroalimentación Externa</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.retroalimentacion_externa}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Fecha Seguimiento</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.fecha_seguimiento}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Comentarios del Profesional</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.comentarios_profesional}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Especificaciones Modalidad</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.especificaciones_modalidad}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Objetivo Seguimiento</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.objetivo_seguimiento}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Estado Atención</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.estado_atencion}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Acciones Inmediatas</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.acciones_inmediatas}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Modalidad</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.modalidad?.nombre}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Acciones Coordinadas Seguimiento</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.acciones_coordinadas_seguimiento}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Profesional Externo</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.profesional_externo}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Referencia</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.referencia?.codigo_referencia_ts}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Profesional Seguimiento</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.profesional_seguimiento?.first_name} {props?.detalle?.profesional_seguimiento?.last_name}
                        </dd>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xs font-medium text-gray-500">Seguimiento a Víctima</dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.seguimiento_a_victima === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};
