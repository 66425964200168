export const FormDetalle = (props) => {
    return (
        <div className="border-gray-200 p-3 border rounded-lg">
          <div className="divide-y divide-gray-200">
            <div className="pb-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Nombre</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.nombre}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Descripción</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.descripcion}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Estado</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.estado === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    Activo
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                    Inactivo
                  </span>
                )}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Unificado</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.unificado === true ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    Sí
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                    No
                  </span>
                )}
              </div>
            </div>
            {/* <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Consentimiento</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.consentimiento}
              </div>
            </div> */}
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Servicio</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.servicio.nombre}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Atención</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.atencion.nombre}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Idioma</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.idioma.nombre}
              </div>
            </div>
            <div className="py-2 grid grid-cols-3 gap-2">
              <div className="text-sm font-medium text-gray-500">Idioma Maya</div>
              <div className="text-sm text-gray-900 sm:col-span-2">
                {props.detalle.idioma_maya.nombre}
              </div>
            </div>
          </div>
        </div>
    );
  };
  