import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { putRol, getRol, getPermisos } from "../services";
import { InputSiavi } from "../../../../components/common/InputText";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Return from "../../../../components/common/ButtonSalir";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import { CheckCircleIcon } from "@heroicons/react/outline";

const validaciones = Yup.object().shape({
  name: Yup.string()
    .required("Nombre es requerido")
    .max(100, "Máximo 100 caracteres"),
});

export const FormEditarRol = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { id } = useParams();
  const [permisosAgregados, setPermisosAgregados] = useState([]);
  const [loading, setLoanding] = useState(true);
  const [nombre, setNombre] = useState("");
  const [filters, setFilters] = useState("");
  const [buscar, setBuscar] = useState("");
  const [state, setState] = useState({
    permisos: [],
  });
  const history = useHistory();

  useEffect(() => {
    const datos = async () => {
      await getRol(id, token).then((data) => {
        setPermisosAgregados(data.permissions);
        setNombre(data?.name);
        setLoanding(false);
      });
    };
    datos();
  }, [id, token]);

  const useFetchPermisos = (token) => {
    useEffect(() => {
      getPermisos("", token).then((permisos) => {
        setState({
          permisos: permisos,
        });
      });
    }, [token]);

    return state;
  };

  const { permisos } = useFetchPermisos(token);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [hasError, setError] = useState(false);

  const pages = [
    {
      name: "Roles",
      href: "/seguridad/roles",
      current: false,
    },
    { name: "Editar", href: "#", current: true },
  ];

  const filtroAgregados = (item) => {
    let retorno = true;

    permisosAgregados.forEach(perm => {
      if (perm.id === item.id) {
        retorno = false;
      }
    });
    return retorno;
  }

  const updateRoles = async (values,permisos,id,token) => {
     putRol(values.name, permisos, id, token)
    .then((data) => {
      if (data.status >= 200 && data.status <= 210) {
        setError(false);
        setSuccess(true);
        setMensaje("Rol actualizado éxitosamente");
        toast.success('Registro actualizado con éxito...!');
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
        return data.json();
      }
      if (data.status === 400) {
        setError(true);
        setSuccess(false);
        setMensaje("No se pudo actualizar el rol");
        toast.error('No se pudo actualizar el rol')
        return data.json();
      }

      return data.json();
    })
    .then((data) => {
      if (data.id) {
        setError(false);
        setSuccess(true);
        setMensaje("Rol actualizado éxitosamente");
        return 0;
      }
    });
  }

  return loading ? (
    <div></div>
  ) : (
    <Formik
      initialValues={{
        name: nombre,
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await putRol(values.name, permisosAgregados, id, token)
          .then((data) => {
            if (data.status === 200) {
              setError(false);
              setSuccess(true);
              setMensaje("Rol actualizado éxitosamente");
              toast.success('Registro actualizado con éxito...!');
              setTimeout(() => {
                history.push('/seguridad/roles');
              }, 1500);
              return data.json();
            }
            if (data.status === 400) {
              setError(true);
              setSuccess(false);
              setMensaje("No se pudo actualizar el rol");
              toast.error('No se pudo actualizar el rol')
              return data.json();
            }

            return data.json();
          })
          .then((data) => {
            if (data.id) {
              setError(false);
              setSuccess(true);
              setMensaje("Rol actualizado éxitosamente");
              return 0;
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 -mt-32 mb-10 ">
            <div className="col-span-12 mx-1 xl:mx-10 lg:mx-10 md:mx-5 shadow-xl rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
              <div className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 mt-5">
                <BreadCrumbTwo navegacion={pages} />
                <h1 className="mb-4 text-lg text-maivi-primaryFont mt-4 xl:mt-0 lg:mt-0 md:mt-0">
                  Actualización de rol
                  <Return ruta={"/seguridad/roles/"} />
                </h1>
              </div>
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border-2 rounded-md p-3">
                  <div className="flex items-end">
                    <h3 className="text-lg leading-6 font-medium text-maivi-primaryFont mr-5">
                      Permisos
                    </h3>
                    <div className="">
                      <select
                        name="codename"
                        value={filters}
                        onChange={(e) => setFilters(e.target.value)}
                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
                    focus:border-maivi-secondaryBlue block w-full text-xs border-maivi-gray-10 rounded-md"
                      >
                        <option value="">Todos</option>
                        <option value="add_">Agregar</option>
                        <option value="change_">Actualizar</option>
                        <option value="delete_">Eliminar</option>
                        <option value="view_">Ver</option>
                      </select>
                    </div>
                    <ButtonAdd
                      className="ml-2"
                      type="button"
                      text="Agregar todos"
                      icon={<CheckCircleIcon className="ml-3 -mr-1 h-5" />}
                      onClick={() => {
                        if (filters !== '') {
                          let tempPermisos = permisosAgregados;
                          permisos.results.forEach(perm => {
                            if (perm.codename.includes(filters)) {
                              let notExist = true;
                              permisosAgregados.forEach(permAdd => {
                                if (permAdd.id === perm.id) {
                                  notExist = false;
                                }
                              })
                              if (notExist) {
                                tempPermisos.push(perm)
                              }
                            }
                          });
                          setPermisosAgregados(tempPermisos);
                          setFilters("");
                          updateRoles(values,tempPermisos,id,token);
                        }
                      }}
                    />
                  </div>
                    <div className="grid grid-cols-6 gap-2 p-2">
                      <div className="sm:col-span-1 col-span-2 mt-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Buscar:
                        </label>
                      </div>
                      <div className="sm:col-span-5 col-span-6">
                        <InputSiavi
                          type="text"
                          name="buscar"
                          value={buscar}
                          onChange={(e) => setBuscar(e.target.value)}
                        />
                      </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-96 overflow-y-auto">
                    <table className="min-w-full divide-y divide-maivi-gray-10 ">
                      <thead className="bg-maivi-gray-5">
                        <tr>
                          <th className="px-2 text-left text-sm font-medium text-maivi-primaryFont">
                            id
                          </th>
                          <th className="px-3 text-left text-sm font-medium text-maivi-primaryFont">
                            Permiso
                          </th>
                          <th className="px-3 text-left text-sm font-medium text-maivi-primaryFont">
                            Agregar
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                        {permisos?.results?.map((item) =>
                          (item.codename.includes(filters) && filtroAgregados(item) && item.name.includes(buscar)) &&
                          <tr
                            key={item.id}
                            id={item.codename}
                            className="hover:bg-maivi-gray-5"
                          >
                            <td className="px-2 py-1">
                              <div className="text-xs text-maivi-primaryFont row-tg">
                                {item.id}
                              </div>
                            </td>
                            <td className="px-2 py-1">
                              <div className="text-xs text-maivi-primaryFont row-td">
                                {item.name}
                              </div>
                            </td>
                            <td className="px-2 py-1">
                              <div className="text-xs text-maivi-primaryFont row-td">
                                <button
                                  type="button"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      item.codename
                                    );
                                    element.classList.add("bg-green-50");
                                    setPermisosAgregados([
                                      ...permisosAgregados,
                                      {
                                        id: item.id,
                                        name: item.name,
                                        codename: item.codename,
                                      },
                                    ]);

                                    let nuevo = [
                                      ...permisosAgregados,
                                      {
                                        id: item.id,
                                        name: item.name,
                                        codename: item.codename,
                                      }
                                    ];

                                    updateRoles(values,nuevo,id,token);
                                  }}
                                  className="inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue"
                                >
                                  Agregar
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-12 md:col-span-12 border-2 rounded-md p-5">
                  <div className="bg-white  sm:rounded-lg">
                    <div className="px-4 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-maivi-primaryFont">
                        {`Actualizar rol "${nombre}"`}
                      </h3>
                    </div>
                    <div className="mr-4 ml-4">
                      {hasError && <Alert type="error" mensaje={mensaje} />}
                      {success && (
                        <Alert
                          mensaje="Rol actualizado éxitosamente"
                          type={"success"}
                        />
                      )}
                    </div>
                    {isSubmitting && <Spinner />}
                    <div className="grid grid-cols-6 gap-2 m-2">
                      <div className="sm:col-span-3 col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Nombre*
                        </label>
                        <div className="mt-1">
                          <InputSiavi
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            validacion={true}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 col-span-6">
                        <div className="mt-6">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            className="w-full py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                          >
                            Actualizar
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-2 py-2 h-80 overflow-y-auto">
                        <table className="w-full divide-maivi-gray-10">
                          <thead className="bg-maivi-gray-5">
                            <tr>
                              <th
                                className="px-2 text-left text-sm font-medium text-maivi-primaryFont"
                              >
                                id
                              </th>
                              <th
                                className="px-2 text-left text-sm font-medium text-maivi-primaryFont"
                              >
                                Permiso
                              </th>
                              <th
                                className="px-2 text-left text-sm font-medium text-maivi-primaryFont"
                              >
                                Borrar
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                            {permisosAgregados?.map((item,index) => (
                              <tr key={item.name + index} className="bg-green-50">
                                <td className="px-2 whitespace-nowrap">
                                  <div className="text-xs text-maivi-primaryFont">
                                    {item.id}
                                  </div>
                                </td>
                                <td className="px-2 whitespace-nowrap">
                                  <div className="text-xs text-maivi-primaryFont">
                                    {item.name}
                                  </div>
                                </td>
                                <td className="px-2 whitespace-nowrap">
                                  <div className="text-xs text-maivi-primaryFont">
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        // let element = document.getElementById(
                                        //   item.codename
                                        // );
                                        // //element.classList.remove("bg-green-50");
                                        let old = permisosAgregados?.filter(
                                          (x) => x.id !== item.id
                                        );
                                        setPermisosAgregados([...old]);
                                        updateRoles(values,old,id,token);
                                      }}
                                      className="inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont
                                       hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue"
                                    >
                                      Borrar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
