import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {  Parametros } from "./Parametros";
import { useSelector } from "react-redux";
import {     getServerInfo } from "../../services";

const validaciones = Yup.object().shape({
    fechaInicio: Yup.date()
        .required("Fecha de Inicio del reporte es requerida"),
    fechaFin: Yup.date()
        .required("Fecha de Fin del reporte es requerida"),
    sedes: Yup.string().required("Seleccione una o varias sedes"),
    
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const IndicadoresReparaciones= () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [loading, setLoading] = useState(true);
    const [,setConfiguracion] =useState("");
    
    const fechaHoy = new Date();
    const fechaInicioMes = new Date(new Date().setDate(fechaHoy.getDate()-30));

    function formatofecha(fecha){
        let año = fecha.toLocaleString("default",{year:"numeric"});
        let mes = fecha.toLocaleString("default",{month:"2-digit"});
        let dia = fecha.toLocaleString("default",{day:"2-digit"});
        return año+'-'+mes+'-'+dia
    };

    useEffect(() => {
       
                setConfiguracion("");
                setLoading(false);
         
    }, [token]);

    return loading ? <div></div> :
        <Formik
            initialValues={{
                fechaInicio: formatofecha(fechaInicioMes) || "",
                fechaFin: formatofecha(fechaHoy) || "",
                sedes: "0" || "",
            }}

            validationSchema={validaciones}
            onSubmit={async (values) => {
                setMensaje("");setError(false);setSuccess(false);

                await getServerInfo(values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje("¡Reporte generado correctamente!");
                        } else {
                            setError(true);
                            setSuccess(false);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            setMensaje("Error interno comuniquese con el administrador");
                        }
                    });
            } }
        >


            {(props) => (
                <Parametros
                    {...props}
                    customSwitchChange={customSwitchChange}
                    hasError={hasError}
                    success={success}
                    mensaje={mensaje} />
            )}


        </Formik>

        
};
