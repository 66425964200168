import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const exportarData = async(filters, token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expediente-list/?${filters}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());

      return result;
    } catch (e) {
      console.log("error", e);
    }
}

export const getExpedientes = async (urlPage, search,sede,filters,order,value_route, token) => {
  try {
    let valor;
    if(value_route === null){
      valor="";
    } else if(value_route === "asistencia_legal"){
      valor="servicio__asitencia_legal=true";
    } else if( value_route === "atencion_victimologica"){
      valor="servicio__atencion_victimologica=true";
    }

    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/expediente/?search=${search.value}&asistencia_seguimiento=true&sede=${sede}&profesional_asignado__isnull=true&ordering=${order}&${filters}${`&${valor}`}`
        :`${urlPage}&search=${search.value}&sede=${sede}&asistencia_seguimiento=true&profesional_asignado__isnull=true&ordering=${order}&${filters}${`&${valor}`}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const expedientes = async (urlPage,sede,filters,order,value_route,search, token) => {
  try { 
    let valor;
    if(value_route === null){
      valor="";
    } else if(value_route === "asistencia_legal"){
      valor="servicio__asitencia_legal=true";
    } else if( value_route === "atencion_victimologica"){
      valor="servicio__atencion_victimologica=true";
    }

    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/expediente/?sede=${sede}&asistencia_seguimiento=true&profesional_asignado__isnull=true&${filters}&ordering=${order}${`&${valor}`}`
        : `${urlPage}&search=${search.value}&sede=${sede}&asistencia_seguimiento=true&profesional_asignado__isnull=true&${filters}&ordering=${order}${`&${valor}`}`;
       
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getServicios = async(token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getTipoProcesos = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};


export const postAsignaciones = async (profesional, expedientes,token) => {
  try {
      let ArregloExpedientes = [];
      expedientes.forEach(element => {
        ArregloExpedientes.push(element.id);
      });

      let body ={
        profesional: profesional.value,
        expedientes:ArregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};