import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Personas } from "./persona";
import { postCasoPersonaVictima, putCasoPersonaVictima, getConfiguracion } from "../services";
import { postPersona, putPersona } from "./services";
import toast from "react-hot-toast";
import * as Yup from "yup";

const useFetchConf = (token) => {
  const [state, setState] = useState({
    conf: [],
  });

  useEffect(() => {
    getConfiguracion(token).then((conf) => {
      setState({
        conf: conf,
      });
    });
  }, [token]);

  return state;
};

const validaciones = Yup.object().shape({
  primer_nombre: Yup.string().required("primer nombre es requerido"),
  primer_apellido: Yup.string().required("primer apellido es requerido"),
  sexo: Yup.string().required("sexo es requerido"),

  fecha_nacimiento: Yup.string().when("victima_fallecida",{
    is: true,
    then:Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("fecha de nacimiento es requerida")
  }),

  pais: Yup.string().when("victima_fallecida",{
    is: true,
    then:Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("pais es requerido")
  }),

  orientacion_sexual: Yup.string().nullable().when("victima_fallecida",{
    is: true,
    then:Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("orientacion sexual es requerida")
  }),
  
  identidad_genero: Yup.string().nullable().when("victima_fallecida",{
    is: true,
    then:Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("identidad de genero requerida")
  }),

  // departamento: Yup.string().nullable().when("victima_fallecida", {
  //   is: true,
  //   then:Yup.string().nullable(),
  //   otherwise: Yup.string().nullable().required("departamento es requerido")
  // }),

  // municipio: Yup.string().nullable().when("victima_fallecida", {
  //   is: true,
  //   then:Yup.string().nullable(),
  //   otherwise: Yup.string().nullable().required("municipio es requerido")
  // }),

  // origen_etnico: Yup.string().nullable().when("victima_fallecida",{
  //   is: true,
  //   then:Yup.string().nullable(),
  //   otherwise: Yup.string().nullable().required("origen etnico es requerido")
  // }),

  idioma: Yup.string().nullable().when("victima_fallecida",{
    is: true,
    then:Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("idioma es requerido")
  }),

  pais_origen: Yup.string().when("en_migracion",{
    is:true,
    then: Yup.string().required("pais origen es requerido")
  }).nullable(),

  pais_destino: Yup.string().when("en_migracion", {
    is: true,
    then: Yup.string().required("pais destino es requerido")
  }).nullable(),

  relacion: Yup.string().when("es_victima",{
    is: "0",
    then:Yup.string().required("relacion es requerida")
  }),

  // idioma_maya: Yup.string().nullable().when("habilita_idioma_maya", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("idioma maya es requerido")
  // }),

  // origen_etnico_maya: Yup.string().nullable().when("habilita_origen_etnico", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("origen etnico maya es requerido")
  // }),

  // departamento: Yup.string().nullable().when("nacionalidad_default", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("departamento es requerido")
  // }),

  // municipio: Yup.string().nullable().when("nacionalidad_default", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("municipio es requerido")
  // }),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  let fechaNac = new Date(values.fecha_nacimiento);
  let yearNacimiento = fechaNac.getFullYear();
  let mesNacimiento = fechaNac.getMonth();
  const d = new Date();
  let yearActual = d.getFullYear();
  let edad = yearActual - yearNacimiento;
  let m = d.getMonth() - mesNacimiento;

  if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
    edad--;
  }

  if (values.fecha_nacimiento) {
    values.edad = edad;
  }
};

export const FormVictimas = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { conf } = useFetchConf(token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const idCaso = props.caso.id;
  const caso = props.caso;
  const delitos = props.delitos;

  return (
    <Formik
      initialValues={{
        idPersona: 0,
        nueva_persona: false,
        tipo_documento: "",
        no_documento: "",
        pais: "",
        primer_nombre: "",
        segundo_nombre: "",
        otros_nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        apellido_casada: "",
        fecha_nacimiento: "",
        edad: 0,
        nacionalidad: "",
        pseudonimo: "",
        sexo: "",

        estado_civil: "",
        orientacion_sexual: "",
        identidad_genero: "",
        departamento: "",
        municipio: "",
        lee_escribe: "",
        escolaridad: "",
        profesion: "",
        religion: "",
        origen_etnico: "",
        origen_etnico_maya:"",
        idioma: "",
        idioma_maya: "",
        idioma_preferencia: "",
        otro_idioma: "",
        en_migracion: false,
        correo_electronico: "",

        relacion_con_la_victima: "",
        tipo_persona: "",
        status_caso_persona: true,
        querellante: false,
        victima: false,
        esVictima: false,
        es_victima: "0",
        es_victima_directa: false,
        remplazar_querellante: false,
        editarPersona: false,
        casoPersona: 0,
        persona: 0,
        busqueda: "",
        relacion: "",
        sede: sede,
        discapacidades: [],
        idsDiscapacidades: [],
        atenciones: [],
        idAtenciones: [],
        victima_fallecida: false,
        habilita_idioma_maya: conf.habilita_idioma_maya,
        habilita_origen_etnico: conf.habilita_origen_etnico,
        nacionalidad_default: conf.nacionalidad_default
      }}
      validateOnMount={true}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        values.sede = sede;
        if (values.nueva_persona) {
          await postPersona(values, token).then(async (data) => {
            if (data?.status === 200 || data?.status === 201) {
              let id = data.data.id;
              values.persona = id;
              await postCasoPersonaVictima(values, idCaso, token).then(data => {
                toast.success('Persona agregada con éxito..!');
                resetForm({});
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              });
            } else {
              toast.success("Persona agregada con éxito..!");
            }
          }).catch(function (error) {
            if (
              error.response.status >= 400 &&
              error.response.status <= 450
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });

        } else {
          await putPersona(values, token).then(async (data) => {
            if (data?.status === 200 || data?.status === 201) {
              await putCasoPersonaVictima(values, idCaso, values.casoPersona, token).then(async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  toast.success('Persona actualizada con éxito..!');
                  resetForm({});
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                } 
              }).catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
            }
          }).catch(function (error) {
            if (
              error.response.status >= 400 &&
              error.response.status <= 450
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
        }
      }}
    >
      {(props) => (
        <Personas
          {...props}
          customSwitchChange={customSwitchChange}
          customChange={handleChangeInput}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
          setError={setError}
          setMensaje={setMensaje}
          setSuccess={setSuccess}
          caso={caso}
          delitos={delitos}
          configuracion={conf}
        />
      )}
    </Formik>
  );
};
