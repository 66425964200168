
const {
    REACT_APP_API_URL
} = process.env;

export const getGrupos = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/grupos-profesionales/grupo/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGrupo = async ({ nombre, estado, descripcion, es_multidisciplinario, es_mesa_trabajo, es_grupo_autorizacion, servicio }, token) => {
    try {
        let body = {
            nombre,
            estado,
            descripcion,
            es_multidisciplinario,
            es_mesa_trabajo,
            es_grupo_autorizacion,
            servicio
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupo/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGrupo = async (id, { nombre, estado, descripcion, es_multidisciplinario, es_mesa_trabajo, es_grupo_autorizacion, servicio }, token) => {
    try {
        let body = {
            nombre,
            estado,
            descripcion,
            es_multidisciplinario,
            es_mesa_trabajo,
            es_grupo_autorizacion,
            servicio
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupo/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGrupo = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupo/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGrupoPro = async ({ es_autorizador, es_apoyo, estado, grupo, profesional, usuario_creacion }, token) => {
    try {
        let body = {
            es_autorizador,
            es_apoyo,
            estado,
            grupo,
            profesional,
            usuario_creacion
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupoprofesional/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGrupoPro = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/grupos-profesionales/grupoprofesional/?page_size=1000&grupo=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGrupoProfesional = async (data, token) => {
    try {
        let body = {
            grupo: (Number(data.grupo) === 0) ? null : Number(data.grupo),
            profesional: (Number(data.profesional) === 0) ? null : Number(data.profesional),
            estado: data.estado,
            es_autorizador: data.es_autorizador,
            es_apoyo: data.es_apoyo
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupoprofesional/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGrupoProfesional = async (id, data, token) => {
    try {
        let body = {
            grupo: (Number(data.grupo) === 0) ? null : Number(data.grupo),
            profesional: (Number(data.profesional) === 0) ? null : Number(data.profesional),
            estado: data.estado,
            es_autorizador: data.es_autorizador,
            es_apoyo: data.es_apoyo
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupoprofesional/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGrupoProfesional = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/grupoprofesional/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (idServicio,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${idServicio}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};