/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { useSelector } from "react-redux";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { InputSiavi } from "../../../../../components/common/InputText";
import { Sentencia } from "./sentencia";
import { ReparacionDigna } from "./reparacion";
import { Actualizar } from "./actualizar";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import { PaperClipIcon, PencilAltIcon, LibraryIcon, FolderAddIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../components/common/Select";
import { CausaPenal } from "./causa_penal";
import { Procesos } from "../procesos/procesos";
import {
  getSindicadoDelitos,
  getEtapasProcesales,
  getResultadosAudiencias,
  getMedidasDesjudicializadoras,
  getTiposProcesosSecundarios,
  getEstadosProcesosSecundarios,
  getEstadosProcesos,
  getTipoApelaciones,
  getInterponentes,
  getExpedienteProceso,
  getCausasPenales,
  postExpedienteProcesoSecundario,
  getProcesoSecundario
} from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../../../components/common/Label";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalTwo from "../../../../../components/common/ModalTwo";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";
import TableSiavi from "../../../../../components/common/TableSiavi";
import moment from "moment";
import { clases } from "../../../../../helpers/clases";

const selectClass = `shadow-sm py-2 px-3 border focus:outline-none focus:ring-2
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full
border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont text-xs`;
const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
const thClass = "px-3 py-1 text-left font-medium text-maivi-primaryFont text-sm";

export const EstadoProceso = ({
  isSubmitting,
  handleSubmit,
  values,
  handleChange,
  caso,
  setFieldValue,
  resetForm
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idCaso = caso.id;
  const expedientes = caso?.expedientes;
  const [cargando, setCargando] = useState(true);
  const [sindicados, setSindicados] = useState([]);
  const [expedientesProcesos, setExpedientesProcesos] = useState([]);
  const [registro, setRegistro] = useState({});
  const [etapasProcesales, setEtapasProcesales] = useState([]);
  const [audiencias, setAudiencias] = useState(false);
  const [interponentes, setInterponentes] = useState([]);
  const [apelaciones, setApelaciones] = useState([]);
  const [estadosProcesosSecundarios, setEstadosProcesosSecundarios] = useState([]);
  const [estadosProcesos, setEstadosProcesos] = useState([]);
  const [procesosSecundarios, setProcesosSecundarios] = useState([]);
  const [medidasDesjudicializadoras, setMedidasDesjudicializadoras] = useState([]);
  const [expSindicados] = useState([]);
  const [causasPenales, setCausasPenales] = useState([]);
  const animatedComponents = makeAnimated();
  const optionsInterponentes = [];
  const [selectInterponentes, setSelectInterponentes] = useState([]);
  const [agregarSentencia, setAgregarSentencia] = useState(false);
  const [agregarReparacion, setAgregarReparacion] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [idExpedienteProceso, setIdExpedienteProceso] = useState(false);
  const [motivo, setMotivo] = useState(false);
  const [sindicadoConocido] = useState(true);
  const [actualizar, setActualizar] = useState(false);
  const [addCausaPenal, setAddCausaPenal] = useState(false);
  const [fetchCausasPenales, setRefetchCausasPenales] = useState(false);
  const [addEstadoProceso, setAddEstadoProceso] = useState(false);
  const [registroEtapa, setRegistroEtapa] = useState({});
  const [procesoSecundario, setProcesosSecundario] = useState(false);
  const [expProcesosSecundarios, setExpProcesosSecundarios] = useState([]);
  const [refetch,setRefetch] = useState(false);
  const classAcciones = `hover:bg-maivi-primaryLightBlue inline-flex items-center p-1 border border-transparent 
  rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`;

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro
  const permisoView = "ExpedientesAL";  //mm permiso padre

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
   //  retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  interponentes?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre,
    };
    optionsInterponentes.push(reg);
  });

  const changeInterponentes = (e) => {
    setSelectInterponentes(e);
  };

  useEffect(() => {
    getInterponentes(token).then((data) => {
      setInterponentes(data);
    });
  }, [token]);

  useEffect(() => {
    getTipoApelaciones(token).then((data) => {
      setApelaciones(data);
    });
  }, [token]);

  useEffect(() => {
    getEstadosProcesosSecundarios(token).then((data) => {
      setEstadosProcesosSecundarios(data);
    });
  }, [token]);

  useEffect(() => {
    getEstadosProcesos(token).then((data) => {
      setEstadosProcesos(data);
    });
  }, [token]);

  useEffect(() => {
    getTiposProcesosSecundarios(token).then((data) => {
      setProcesosSecundarios(data);
    });
  }, [token]);

  useEffect(() => {
    getSindicadoDelitos(idCaso, token).then((data) => {
      setSindicados(data);
    });
  }, [idCaso, token]);

  useEffect(() => {
    getEtapasProcesales(token).then((data) => {
      setEtapasProcesales(data);
    });
  }, [token]);

  useEffect(() => {
    getResultadosAudiencias(token).then((data) => {
      setAudiencias(data);
    });
  }, [token]);

  useEffect(() => {
    getMedidasDesjudicializadoras(token).then((data) => {
      setMedidasDesjudicializadoras(data);
    });
  }, [token]);

  useEffect(() => {
    getCausasPenales(idCaso, token).then((data) => {
      setCausasPenales(data);
    });
  }, [fetchCausasPenales, token]);

  useEffect(() => {
    getExpedienteProceso(idCaso, token).then((data) => {
      setExpedientesProcesos(data);
      setCargando(false);
    });
  }, [token, idCaso, setCargando, isSubmitting]);

  useEffect(()=>{
    if (registro?.id !== null) {
      getProcesoSecundario(registro?.id, token).then((data) => {
        setExpProcesosSecundarios(data);
      });
    }
   
  },[registro,refetch]);

  return (
    <form autoComplete="off">
      <Procesos
        caso={caso}
        ocultar_secciones={true}
        impugnaciones={true}
        setAddEstadoProceso={setAddEstadoProceso}
        setRegistroEtapa={setRegistroEtapa}
      />
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
          <h1 className="mb-4 xl:mb-1 lg:mb-1 md:mb-1 ">Procesos</h1>
          <TableSiavi
            cargando={cargando}
            headers={[
              'Tipo de proceso',
              'Etapa procesal/proceso',
              'Resultado audiencia',
              'Fecha',
              'Acciones'
            ]}
            body={expedientesProcesos?.results?.filter(x => x?.expediente?.servicio_propio?.impugnaciones)?.map((item) => (
              <tr key={item?.estado_proceso?.nombre + item.id} className="hover:bg-maivi-gray-5 cursor-pointer">
                <td className={tdClass}>{item?.tipo_proceso?.tipo_proceso}</td>
                <td className={tdClass}>{item?.etapa_procesal?.nombre} - {item?.estado_proceso?.nombre}</td>
                <td className={tdClass}>{item?.resultado_audiencia?.nombre}</td>
                <td className={tdClass}>{moment(item?.fecha).format("DD-MM-YYYY")}</td>
                <td className={tdClass}>
                {//(CheckPermiso('XXX') === true) &&
                  <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                      setActualizar(true);
                      setRegistro(item);
                    }}
                  >
                    <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext">Ver Detalle</span>
                  </button>
            }
            {(false) &&
                  <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                      setRegistro(item);
                      setProcesosSecundario(true);
                    }}
                  >
                    <FolderAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext">Acción constitucional/impugnación </span>
                  </button>
            }
            {(CheckPermiso('ImpEstadoAdjuntarDocumentos') === true) &&
                  <button
                    type="button"
                    className={classAcciones}
                    onClick={() => {
                      setIdExpedienteProceso(item.id);
                      setRegistro(item);
                      setArchivos(true);
                    }}
                  >
                    <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                    <span className="tooltiptext">Adjuntar Documentos</span>
                  </button> 
            }
                </td>
              </tr>
            ))}
          />
        </div>

        {agregarSentencia && (
          <ModalTwo
            title={`Agregar detalle de sentencia`}
            open={agregarSentencia}
            closeModal={() => { }}
            cerrar={() => {
              setAgregarSentencia(false);
            }}
            children={<Sentencia registro={registro} sindicados={sindicados} />}
          />
        )}

        {agregarReparacion && (
          <ModalTwo
            title='Agregar reparación digna'
            open={agregarReparacion}
            closeModal={() => { }}
            cerrar={() => {
              setAgregarReparacion(false);
            }}
            children={<ReparacionDigna registro={registro} sindicados={sindicados} />}
          />
        )}

        {archivos && (
          <ModalAmplio
            title={`Adjuntar archivos`}
            open={true}
            closeModal={() => { }}
            cerrar={() => {
              setArchivos(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <PaperClipIcon className="h-6 w-6 text-green-600" />
              </div>
            }
            children={
              <GestionArchivos
                idRecurso={idExpedienteProceso}
                recurso="EXPEDIENTEPROCESO"
                claseDocumento="expedienteproceso"
                expediente={registro?.expediente?.id}
                caso={registro?.caso}
              />
            }
          />
        )}

        {addCausaPenal && (
          <ModalGenerico
            title={`Agregar causa penal`}
            open={addCausaPenal}
            closeModal={() => { }}
            cerrar={() => {
              setAddCausaPenal(false);
            }}
            children={
              <CausaPenal
                idCaso={idCaso}
                setRefetchCausasPenales={setRefetchCausasPenales}
                setAddCausaPenal={setAddCausaPenal}
                idExpediente={registroEtapa?.id}
              />
            }
          />
        )}

        {actualizar && (
          <ModalAmplio
            title={`Detalle`}
            open={true}
            closeModal={() => { }}
            cerrar={() => {
              setActualizar(false);
            }}
            children={
              <Actualizar
                selectClass={selectClass}
                registro={registro}
                expedientes={expedientes}
                expSindicados={expSindicados}
                etapasProcesales={etapasProcesales}
                estadosProcesos={estadosProcesos}
                audiencias={audiencias}
                medidasDesjudicializadoras={medidasDesjudicializadoras}
                apelaciones={apelaciones}
                estadosProcesosSecundarios={estadosProcesosSecundarios}
                procesosSecundarios={procesosSecundarios}
                bloqueoFecha={bloqueoFecha}
                thClass={thClass}
                tdClass={tdClass}
                classAcciones={classAcciones}
              />
            }
          />
        )}

        {procesoSecundario && (
          <ModalAmplio
            title={`Acción constitucional/impugnación`}
            open={true}
            closeModal={() => { }}
            cerrar={() => {
              setProcesosSecundario(false);
            }}
            children={
              <div className="grid grid-cols-12 items-end gap-3 border p-3 rounded-md shadow-sm hover:shadow-md">
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <SelectSiavi
                    label="Acción constitucional/Impugnación"
                    name="tipo_proceso_secundario"
                    value={values.tipo_proceso_secundario}
                    onChange={handleChange}
                    valueInit={null}
                    selectInit="Elegir"
                    list={
                      procesosSecundarios?.results?.map((item, index) => {
                        return (
                          <option key={item?.nombre + index + 'ps'} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <SelectSiavi
                    label="Estado proceso"
                    validacion={true}
                    name="estado_proceso_secundario"
                    value={values.estado_proceso_secundario}
                    valueInit={null}
                    selectInit="Elegir estado del proceso"
                    onChange={(e) => {
                      setFieldValue("estado_proceso_secundario", e.target.value);
                      estadosProcesosSecundarios?.results?.forEach(element => {
                        if (element.id === Number(e.target.value)) {
                          setMotivo(element.habilita_apelacion_especial);
                        }
                      });
                    }}
                    list={
                      estadosProcesosSecundarios?.results?.filter(x => x.proceso_secundario === Number(values.tipo_proceso_secundario)).map((item, index) => {
                        return (
                          <option key={item.nombre + index + 'eps'} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
                {motivo && (
                  <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                    <SelectSiavi
                      label="Motivo"
                      name="tipo_apelacion"
                      value={values.tipo_apelacion}
                      onChange={handleChange}
                      valueInit={null}
                      selectInit="Elegir Motivo"
                      list={
                        apelaciones?.results?.map((item, index) => {
                          return (
                            <option key={item.nombre + index + 'mt'} value={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })
                      }
                    />
                  </div>
                )}
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <Label text="Interponente" />
                  <Select
                    placeholder="Seleccionar"
                    components={animatedComponents}
                    isMulti
                    name="interponente"
                    className="basic-multi-select text-xs text-maivi-primaryFont"
                    classNamePrefix="seleccione"
                    options={optionsInterponentes}
                    onChange={changeInterponentes}
                    maxMenuHeight={140}
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <div className="flex flex-col justify-center items-center">
                    <Label text="Amparo provisional" />
                    <Toggle
                      enabled={values.amparo_provisional}
                      onChange={handleChange}
                      name="amparo_provisional"
                    />
                  </div>
                </div>
                {
                  (values.expediente) &&
                  <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-1 flex items-end">
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setAddCausaPenal(true);
                          }}
                        >
                          <LibraryIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          <span className="tooltiptext">Agregar causa penal</span>
                        </button>
                      </div>

                      <div className="col-span-11">
                        <SelectSiavi
                          label="Causa penal"
                          name="causa_penal_proceso_secundario"
                          className="w-full"
                          value={values.causa_penal_proceso_secundario}
                          onChange={handleChange}
                          valueInit=""
                          selectInit="Elegir causa penal"
                          list={
                            causasPenales?.results?.filter(x => x.expediente.id === Number(registroEtapa.id)).map((item, index) => {
                              return (
                                <option key={item?.numero_causa_penal + index + 'cp'} value={item?.id}>
                                  {item?.numero_causa_penal}
                                </option>
                              );
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    label="Fecha"
                    name="fecha_proceso_secundario"
                    type="date"
                    maximo={bloqueoFecha()}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-12 xl:col-span-8 lg:col-span-8">
                  <TextAreaSiavi
                  enabled={false}
                    label="Comentarios"
                    placeholder="comentarios"
                    name="comentarios_secundarios"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                  <ButtonSiavi
                    type="button"
                    text="Guardar..."
                    onClick={async () => {
                      values.interponentes = selectInterponentes;
                      await postExpedienteProcesoSecundario(
                        values,
                        registro.id,
                        token
                      ).then(async (data) => {
                        if (data?.status === 200 || data?.status === 201) {
                          toast.success("Registro creado con éxito..!");
                          setRefetch(true);
                          setRefetch(false);
                        }
                      })
                        .catch(function (error) {
                          if (error?.response?.status >= 400 && error?.response?.status <= 450) {
                            toast.error(error.response.data.message);
                          } else if (error?.response?.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                          }
                        });;
                    }}
                  />
                </div>
                <div className="col-span-12 hover:shadow-lg">
                  <TableSiavi
                    headers={[
                      "Tipo proceso",
                      "Estado proceso",
                      "Comentarios",
                      "Fecha creacion"
                    ]}
                    body={
                      expProcesosSecundarios?.results?.map((item) => (
                        <tr key={item?.interponente?.nombre} className="hover:bg-maivi-gray-5 cursor-pointer">
                          <td className={clases.td}>{item?.tipo_proceso_secundario?.nombre}</td>
                          <td className={clases.td}>{item?.estado_proceso_secundario?.nombre}</td>
                          <td className={clases.td}>{item?.comentarios}</td>
                          <td className={clases.td}>{moment(item?.fecha_creacion).format("DD-MM-YYYY")}</td>
                        </tr>
                      ))
                    }
                  />
                </div>
              </div>
            }
          />
        )}

        {addEstadoProceso && (
          <ModalGenerico
            title={`Agregar estado del proceso`}
            open={addEstadoProceso}
            closeModal={() => { }}
            cerrar={() => {
              setAddEstadoProceso(false);
              resetForm({})
            }}
            children={
              <div className="">
                <div className="">
                  <div className="mb-3">
                    <SelectSiavi
                      label="Estado proceso"
                      validacion={true}
                      name="estado_proceso"
                      valueInit=""
                      selectInit="Elegir estado proceso"
                      value={values.estado_proceso}
                      onChange={(e) => {
                        setFieldValue("estado_proceso", e.target.value);
                        estadosProcesos?.results?.forEach((element) => {
                          if (element.id === Number(e.target.value)) {
                            setFieldValue("medidaDesjudializadora", element.habilita_medida_desjudicializadora);
                          }

                          if (element.id === Number(e.target.value)) {
                            if (element?.habilita_motivo) {
                              setFieldValue("habilita_motivo", true);
                            } else {
                              setFieldValue("habilita_motivo", false)
                            }
                          }
                          if (element.id === Number(e.target.value)) {
                            if (element?.habilita_interponente) {
                              setFieldValue("habilita_interponente", true);
                            } else {
                              setFieldValue("habilita_interponente", false)
                            }
                          }
                        });
                      }}
                      list={
                        (values.audiencia_id === null) ?
                          estadosProcesos?.results?.filter(x => (x.etapa_proceso === Number(registroEtapa?.ultima_etapa_procesal?.etapa_procesal?.id))).map((item, index) => {
                            return (
                              <option key={item.nombre + index + 'e'} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          }) :
                          estadosProcesos?.results?.filter(x => x.id === values.audiencia_id).map((item, index) => {
                            return (
                              <option key={item.nombre + index + 'e'} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                      }
                    />
                  </div>
                  {
                    (values.habilita_motivo) &&
                    <div className="mb-3">
                      <SelectSiavi
                        label="Motivo"
                        name="tipo_apelacion2"
                        value={values.tipo_apelacion2}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir Motivo"
                        list={
                          apelaciones?.results?.map((item, index) => {
                            return (
                              <option key={item.nombre + index + 'mt'} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                        }
                      />
                    </div>
                  }
                  {values.habilita_interponente && (
                    <div className="mb-3">
                      <Label text="Interponente" />
                      <Select
                        placeholder="Seleccionar"
                        components={animatedComponents}
                        isMulti
                        name="interponente"
                        className="basic-multi-select text-xs text-maivi-primaryFont"
                        classNamePrefix="seleccione"
                        options={optionsInterponentes}
                        onChange={changeInterponentes}
                        maxMenuHeight={140}
                      />
                    </div>)}
                  {/* <div className="col-span-12 xl:col-span-3 lg:col-span-4">
                      <SelectSiavi
                        label="Resultado audiencia"
                        name="resultado_audiencia"
                        value={values.resultado_audiencia}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir resultado audiencia"
                        list={
                          audiencias?.results?.filter(x => x?.estado_proceso?.id === Number(values.estado_proceso)).map((item, index) => {
                            return (
                              <option key={item.nombre + index + 'a'} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                        }
                      />
                    </div> */}
                  {
                    (values.estado_proceso) &&
                    <div className="mb-3">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-1 flex items-end">
                          <button
                            type="button"
                            className={classAcciones}
                            onClick={() => {
                              setAddCausaPenal(true);
                            }}
                          >
                            <LibraryIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                            <span className="tooltiptext">Agregar causa penal</span>
                          </button>
                        </div>

                        <div className="col-span-11">
                          <SelectSiavi
                            label="Causa penal"
                            name="causa_penal"
                            className="w-full"
                            value={values.causa_penal}
                            onChange={handleChange}
                            valueInit=""
                            selectInit="Elegir causa penal"
                            list={
                              causasPenales?.results?.filter(x => x.expediente.id === Number(registroEtapa.id)).map((item, index) => {
                                return (
                                  <option key={item?.numero_causa_penal + index + 'c'} value={item?.id}>
                                    {item?.numero_causa_penal}
                                  </option>
                                );
                              })
                            }
                          />
                        </div>
                      </div>

                    </div>
                  }
                  {
                    values.medidaDesjudializadora &&
                    <div className="mb-3">
                      <SelectSiavi
                        label="Medida desjudicializadora"
                        name="medida"
                        value={values.medida}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir medida desjudicializadora"
                        list={
                          medidasDesjudicializadoras?.results?.map((item, index) => {
                            return (
                              <option key={item.nombre + index} value={item.id}>
                                {item.nombre}
                              </option>
                            );
                          })
                        }
                      />
                    </div>
                  }
                  {/* <div className="col-span-12 xl:col-span-3 lg:col-span-4">
                      <div className="flex flex-col justify-center items-center">
                        <Label text="Acción constitucional/impugnación" />
                        <Toggle
                          enabled={values.proceso_secundario}
                          name="proceso_secundario"
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                  <div className="mb-3">
                    <InputSiavi
                      label="Fecha"
                      name="fecha"
                      type="date"
                      onChange={handleChange}
                      value={values.fecha}
                      maximo={bloqueoFecha()}
                      validacion={true}
                    />
                  </div>
                  <div className="mb-3">
                    <TextAreaSiavi
                      label="Comentarios"
                      placeholder="comentarios"
                      name="comentarios"
                      value={values.comentarios}
                      onChange={handleChange}
                    />
                  </div>
                  {
                    !sindicadoConocido &&
                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                      <Alert type="warning" mensaje="No puede avanzar en el proceso penal si el sindicado no es individualizado" />
                    </div>
                  }

                </div>
                <div className="">
                  {
                    (isSubmitting) ? <Spinner /> :
                      sindicadoConocido &&
                      <ButtonSiavi
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault();
                          values.interponentes = selectInterponentes;
                          values.etapa_procesal = registroEtapa?.ultima_etapa_procesal?.etapa_procesal?.id;
                          values.expediente = registroEtapa?.id;
                          values.tipo_proceso = registroEtapa?.tipo_proceso?.id;
                          await handleSubmit();
                        }}
                        text="Guardar"
                      />
                  }
                </div>
              </div>
            }
          />
        )}
      </div>
    </form>
  );
};
