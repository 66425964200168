
const {
    REACT_APP_API_URL
} = process.env;

export const getNivelRiesgo = async (urlPage,token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/nivelriesgo/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNivelRiesgo = async ({nombre, descripcion, estado, prioridad},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado,
            prioridad
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/nivelriesgo/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putNivelRiesgo = async (id, {nombre,descripcion,estado, prioridad}, token) => {
    try {
        let body = {
            nombre,
            descripcion,
            estado,
            prioridad
        }

        console.log('NivelRiesgo',body);

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/nivelriesgo/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNivelRiesgo = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/nivelriesgo/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoRiesgos = async (id,token) => {
    try {
        let urlFetch =`${REACT_APP_API_URL}/catalogos/tiporiesgo/?page_size=1000&dominio=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};