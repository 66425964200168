/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { clases } from "../../../../../helpers/clases";
import {
    postCausaPenal,
    getInstituciones,
    getCausasPenales
} from "./services";

import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import TableSiavi from "../../../../../components/common/TableSiavi";
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
    numero_causa_penal: Yup.string().max(300, "maximo 300 caracteres").required("numero de causa es requerido"),
    //fecha: Yup.string().required("fecha es requerida"),
});

export const CausaPenal = (props) => {
    const token = useSelector((state) => state?.loginReducer?.data?.token);
    const animatedComponents = makeAnimated();
    const [cargando, setCargando] = useState(false);
    const [institucionSeleccionada, setInstitucionSeleccionada] = useState([]);
    const [ intituciones,setInstituciones ] = useState([]);
    const [ causasPenales,setCausas ] = useState([]);
    const idCaso = props?.expediente?.caso;
    const idExpediente = props?.expediente?.id;


    

    const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


    const permisoView = "ExpedientesAL";  //mm permiso padre
  
    //mm procedimiento de validacion de permisos
    const CheckPermiso = (permiso) => {
      let retorno = false;
      permisosRegistro?.forEach(menu => {
        if (menu?.permiso === permisoView) {
          menu?.opciones?.forEach(op => {
            if (op.permiso === permiso) {
              retorno = op.ver;
            }
          })
        }
      });
        //retorno = true;  // mm comentar para que funcione
      return retorno;
  
    }

    const changeSelectInstituciones = (e) => {
        setInstitucionSeleccionada(e);
    };

    useEffect(() => {
        getInstituciones(token).then((data) => {
           setInstituciones(data)
        })
    }, []);

    useEffect(() => {
        getCausasPenales(idExpediente,token).then((data) => {
           setCausas(data);
        })
    }, []);

    const optionsInstituciones = [];

    intituciones?.results?.filter(x => x?.institucion_detalle?.tiene_materia === true).forEach((item) => {
        let reg = {
            value: item.id,
            label: item.nombre_fiscalia,
        };
        optionsInstituciones.push(reg);
    });

    const headerTable = [
        "No. causa penal",
        "Órgando jurisdiccional",
        "Fecha"
    ]

    return (
        <Formik
            initialValues={{
                numero_causa_penal: "",
                fecha:""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                setCargando(true);
                await postCausaPenal(
                    values,
                    idCaso,
                    idExpediente,
                    institucionSeleccionada.value,
                    token
                ).then((data) => {
                    if (data?.status === 200 || data?.status === 201) {
                        toast.success("causa penal agregada con éxito..!");
                        setCargando(false);
                        props.setCausaPenal(false);
                    } else {
                        toast.success("causa penal agregada con éxito..!");
                    }
                }).catch(function (error) {
                    if (
                        error.response.status >= 400 &&
                        error.response.status <= 450
                    ) {
                        toast.error(error.response.data.message);
                        setCargando(false);
                    } else if (error.response.status >= 500) {
                        toast.error("Error interno comuniquese con el administrador");
                        setCargando(false);
                    }
                });
            }}
        >
            {({values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <InputSiavi
                            name="numero_causa_penal"
                            onChange={handleChange}
                            value={values.numero_causa_penal}
                            label="Número causa penal"
                            validacion={true}
                        />
                    </div>
                    <Label text="Órgano jurisdiccional"/>
                    <Select
                        isMulti={false}
                        placeholder="Seleccionar institución"
                        components={animatedComponents}
                        name="etapas"
                        className="basic-multi-select text-xs mb-2"
                        options={optionsInstituciones}
                        onChange={changeSelectInstituciones}
                        maxMenuHeight={120}
                    />
                   <div className="mt-2 mb-2">
                   {/* <InputSiavi
                        name="fecha"
                        onChange={handleChange}
                        value={values.fecha}
                        label="Fecha"
                        validacion={true}
                        type="date"
                    /> */}
                    {
                        (cargando) ?
                            <div className="p-2"><Spinner /></div> :
                            <div className="mt-3">
                                {(CheckPermiso('EPGrabarCausaPenal') === true) &&
                                <ButtonSiavi
                                    type="button"
                                    text="Agregar"
                                    onClick={handleSubmit}
                                />
                    }
                            </div>
                    }
                     </div>
                   <div className="mt-4">
                       <Label text="Historial de causas penales"/>
                        <TableSiavi
                            headers={headerTable}
                            body={
                                causasPenales?.results?.map((item,index) =>(
                                    <tr className={clases.tr} key={"tr"+index}>
                                    <td className={clases.td}>{item.numero_causa_penal}</td>
                                    <td className={clases.td}>{item.organo_jurisdiccional?.nombre_fiscalia}</td>
                                    <td className={clases.td}>{moment(item.fecha_creacion).format("DD-MM-YYYY")}</td>
                                </tr>
                                ))
                            }
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
};
