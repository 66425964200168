import axios from "axios"
import moment from "moment";

const {
    REACT_APP_API_URL
} = process.env;

export const getSindicadoDelitos = async (idCaso,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/expsindicadodelito/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProcesos = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEtapasProcesales = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/etapaprocesal/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getResultadosAudiencias = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/resultadoaudiencia/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMedidasDesjudicializadoras = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/medidadesjudicializadora/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCausasPenales = async (idCaso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/expedientecausapenal/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTiposProcesosSecundarios = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoprocesosecundario/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadosProcesosSecundarios = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/estadoprocesosecundario/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEstadosProcesos = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/estadoproceso/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoApelaciones = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoapelacionespecialcasacion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInterponentes = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/interponente/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getReparacionesDignas = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/reparaciondigna/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteProceso = async (idCaso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedienteproceso/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAudiencias = async (idCaso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?es_audiencia=true&completada=false&caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpedienteProceso = async (values,idCaso, token) => {
    try {
        let body ={
            proceso_secundario: values.proceso_secundario,
            fecha: (values.fecha === "") ? null : values.fecha,
            comentarios: values.comentarios,
            caso: idCaso,
            expediente: values.expediente,
            expediente_sindicado: (values.expediente_sindicado === "") ? null : values.expediente_sindicado,
            tipo_proceso: values.tipo_proceso,
            etapa_procesal: values.etapa_procesal,
            estado_proceso: values.estado_proceso,
            resultado_audiencia: (values.resultado_audiencia === "") ? null : values.resultado_audiencia,
            medidada_desjudializadora: (values.medida === "") ? null : values.medida,
            expediente_causa_penal:(values.causa_penal === "") ? null : values.causa_penal,
            tipo_apelacion_especial_casacion: (values.tipo_apelacion2 === "") ? null : values.tipo_apelacion2,
            seguimiento_caso_accion: (values.audiencia === "") ? null : values.audiencia,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedienteproceso/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpedienteProceso = async (values,idCaso, token) => {
    try {
        let body ={
            proceso_secundario: values.proceso_secundario,
            fecha: values.fecha,
            comentarios: values.comentarios,
            caso: idCaso,
            expediente: values.expediente,
            expediente_sindicado: values.expediente_sindicado,
            tipo_proceso: values.tipo_proceso,
            etapa_procesal: values.etapa_procesal,
            estado_proceso: values.estado_proceso,
            resultado_audiencia: (values.resultado_audiencia === "") ? null : values.resultado_audiencia,
            medidada_desjudializadora: (values.medida === "") ? null : values.medida,
            expediente_causa_penal : (values.causa_penal === "") ? null : values.causa_penal
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expedienteproceso/${values.id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProcesoSecundario = async (idExpedienteProceso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedienteprocesosecundario/?exp_proceso=${idExpedienteProceso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postInterponente = async (idExpProcesoSecundario,idInterponente,token) => {
    try {
        const fecha = new Date();
        
        let body ={
            fecha: moment(fecha).format('YYYY-MM-DD'),
            exp_proceso_secundario: idExpProcesoSecundario,
            interponente: idInterponente
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedienteprocesointerponente/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 


        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInterponente = async (idExpProcesoSecundario, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedienteprocesointerponente/?exp_proceso_secundario=${idExpProcesoSecundario}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteInterponente = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expedienteprocesointerponente/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const postExpedienteProcesoSecundario = async (values,idExpedienteProceso, token) => {
    let interponentes = [];

    values.interponentes.forEach(element => {
        interponentes.push(Number(element.value));
    });

    try {
        let body ={
            fecha: values.fecha_proceso_secundario,
            interponentes: interponentes,
            amparo_provisional: values.amparo_provisional,
            comentarios: values.comentarios_secundarios ,
            exp_proceso: idExpedienteProceso,
            tipo_proceso_secundario: (values.tipo_proceso_secundario === "") ? null : values.tipo_proceso_secundario,
            estado_proceso_secundario: (values.estado_proceso_secundario === "") ? null : values.estado_proceso_secundario ,
            tipo_apelacion_especial_casacion:  (values.tipo_apelacion === "") ? null : values.tipo_apelacion,
            expediente_causa_penal: (values.causa_penal_proceso_secundario === "") ? null : values.causa_penal_proceso_secundario
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedienteprocesosecundario/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAgregarProcesoSecundario = async (id, token) => {
    try {
        let body ={
            proceso_secundario:true,
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expedienteproceso/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpedienteProcesoSecundario = async (values,idExpedienteProceso,idProcesoSecundario, token) => {
    try {
        let body ={
            fecha: values.fecha_proceso_secundario,
            amparo_provisional: values.amparo_provisional,
            comentarios: values.comentarios_secundarios ,
            exp_proceso: idExpedienteProceso,
            tipo_proceso_secundario: (values.tipo_proceso_secundario === "") ? null : values.tipo_proceso_secundario,
            estado_proceso_secundario: (values.estado_proceso_secundario === "") ? null : values.estado_proceso_secundario ,
            tipo_apelacion_especial_casacion:  (values.tipo_apelacion === "") ? null : values.tipo_apelacion,
            expediente_causa_penal: (values.causa_penal === "") ? null : values.expediente_causa_penal
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expedienteprocesosecundario/${idProcesoSecundario}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpedienteSentencia = async (values,idCaso,idExpedienteProceso, token) => {
    try {
        let body ={
            fecha_sentencia: values.fecha_sentencia,
            numero_sentencia: values.numero_sentencia,
            sentencia_externa: values.sentencia_externa,
            sentencia_previa: values.sentencia_previa,
            ejercio_reparacion_digna_via_penal: false,
            reparacion_digna: (values.reparacion_digna_via_penal === "1") ? true : false,
            caso: idCaso,
            expediente: values.expediente,
            exp_proceso: idExpedienteProceso,
            exp_reparacion_digna: null,
            descripcion: values.descripcion,
            juzgado: values.juzgado,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedientesentencia/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAudiencia = async (observaciones,id, token) => {
    try {
        let body ={
            completada:true,
            observaciones_gestiona: observaciones
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteSentencia = async (expediente,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedientesentencia/?expediente=${expediente}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteExpedienteSentencia = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expedientesentencia/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const postExpedienteSentenciaDetalle = async (values,token) => {
    try {
        let body ={
            sentencia_condenatoria: values.sentencia_condenatoria,
            anios_condena: values.anios_condena,
            meses_condena: values.meses_condena,
            comentarios: values.comentarios,
            expediente_sentencia: Number(values.expediente_sentencia),
            exp_sindicado_delito: Number(values.sindicado),
            conmutable: values.conmutable
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expsentenciadetalle/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const putExpedienteSentenciaDetalle = async (values,token) => {
    try {
        let body ={
            sentencia_condenatoria: values.sentencia_condenatoria,
            anios_condena: values.anios_condena,
            meses_condena: values.meses_condena,
            comentarios: values.comentarios,
            expediente_sentencia: Number(values.expediente_sentencia),
            exp_sindicado_delito: Number(values.sindicado)
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expsentenciadetalle/${values.idExpSentenciaDetalle}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteSentenciaDetalle = async (idExpedienteSentencia,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expsentenciadetalle/?expediente_sentencia=${idExpedienteSentencia}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteExpedienteSentenciaDetalle = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expsentenciadetalle/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
}



export const postExpReparacionDigna = async (values,idCaso,idExpediente,idExpedienteProceso, token) => {
    try {
        let body ={
            fecha: values.fecha,
            rep_digna_externa: values?.rep_digna_externa,
            rep_digna_previa : values?.rep_digna_previa,
            caso: idCaso,
            expediente: idExpediente,
            exp_proceso: idExpedienteProceso
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expedientereparaciondigna/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteExpReparacionDigna = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/legal/expedientereparaciondigna/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postExpReparacionDignaDetalle = async (values,idCaso,idExpReparacionDigna, token) => {
    try {
        let body ={
            monto: values.monto.replace('Q','').replace(',','').replace(',',''),
            especificaciones:values.especificaciones ,
            caso: idCaso,
            expediente_reparacion_digna: idExpReparacionDigna,
            reparacion_digna: values.reparacion_digna
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpReparacionDignaDetalle = async (values,idCaso,idExpReparacionDigna, token) => {
    try {
        let body = {
            monto: values.monto,
            especificaciones:values.especificaciones ,
            caso: idCaso,
            expediente_reparacion_digna: idExpReparacionDigna,
            reparacion_digna: values.reparacion_digna
        } 

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/${values.idRepacionDignaDetalle}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const deleteExpReparacionDignaDetalle = async (id,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/${id}/`;
        let result = await fetch(urlFetch, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteSentencias = async (idCaso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedientesentencia/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteReparacionDigna = async (idCaso, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expedientereparaciondigna/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpReparacionDignaDetalle = async (idExpReparacionDigna, token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/?expediente_reparacion_digna=${idExpReparacionDigna}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientes = async(idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expediente/?caso_persona__caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};