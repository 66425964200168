/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../components/common/InputText";
import { getMotivosVisitas, getUsuarios } from "./services";
import { SelectSiavi } from "../../../components/common/Select"
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [motivos, setMotivos] = useState([]);
    const [profesionales, setProfesionales] = useState([]);
    let filtros = "";

    useEffect(() => {
        getMotivosVisitas(token).then((data) => {
            setMotivos(data?.results);
        })
    }, []);

    useEffect(() => {
        getUsuarios(token).then((data) => {
            setProfesionales(data?.results);
        })
    }, []);

    const [state, setState] = useState({
        motivo: "",
        profesional: "",
        atendido: "",
        fecha_desde: "",
        fecha_hasta: "",
    });

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const filtroatendido = [
        {
            id: "true",
            value: "Si"
        },
        {
            id: "false",
            value: "No"
        }
    ]

    return (
        <div className="grid grid-cols-12 mx-5">
            <div className="col-span-12 mt-3">
                <div className="mb-2">
                    <SelectSiavi
                        name="motivo"
                        value={state.motivo}
                        label="Motivo visita"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir motivo"
                        list={
                            motivos?.map((item, index) => {
                                return (
                                    <option key={item.nombre + index} value={item.id}>
                                        {item.nombre}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="profesional"
                        value={state.profesional}
                        label="Profesional"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Elegir profesional"
                        list={
                            profesionales?.map((item, index) => {
                                return (
                                    <option key={item.first_name + index} value={item?.id}>
                                        {item?.first_name} {item?.last_name} - {item?.tipo_puesto?.puesto}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
                <div className="mb-2">
                    <SelectSiavi
                        name="atendido"
                        value={state.atendido}
                        label="Atendido"
                        onChange={onChange}
                        valueInit=""
                        selectInit="Seleccionar atencion"
                        list={
                            filtroatendido?.map((item, index) => {
                                return (
                                    <option key={item.value + index} value={item.id}>
                                        {item.value}
                                    </option>
                                );
                            })
                        }
                    />
                </div>
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4 mx-1">
                <InputSiavi
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha inicio"
                />
            </div>
            <div className="col-spa-12 xl:col-span-6 lg:col-span-6 md:col-span-6 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha fin"
                />
            </div>
            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `motivo_visita=${state.motivo}&profesional_al_que_visita=${state.profesional}&atendido=${state.atendido}&fecha__gte=${state.fecha_desde}&fecha__lte=${state.fecha_hasta}`;
                        props.setUrlFech(undefined)
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}
