export const FormDetalle = (props) => {
    return (
        <div className="rounded-md border">
            <div className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Fecha-Hora Planificada</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.fecha_hora_planificada_inicia}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Juzgado</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.gestion_institucion?.institucion?.nombre}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Tipo Proceso</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.expediente?.tipo_proceso?.tipo_proceso}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Etapa Procesal - Estado Proceso</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.tipo_audiencia?.etapa_proceso_detalle?.nombre} - {props?.detalle?.tipo_audiencia?.nombre}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Expediente</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.expediente?.numero_expediente}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Víctima</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`${props?.detalle?.victima?.primer_nombre} ${props?.detalle?.victima?.primer_apellido}`}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Querellante</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`${props?.detalle?.querellante?.primer_nombre} ${props?.detalle?.querellante?.primer_apellido}`}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Causas Penales</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.causa_penal?.numero_causa_penal}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Denuncia</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.caso?.no_denuncia}
                    </dd>
                </div>
                <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Completada</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props?.detalle?.completada === true ? (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                Sí
                            </span>
                        ) : (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                                No
                            </span>
                        )}
                    </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-100">
                    <div className="text-sm font-medium text-gray-500">Profesional Asignado</div>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`${props?.detalle?.profesional_asignado?.first_name} ${props?.detalle?.profesional_asignado?.last_name}`}
                    </dd>
                </div>
            </div>
        </div>
    );
};
