/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { Formik, ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { TrashIcon } from "@heroicons/react/outline";
import { SelectSiavi } from "../../../../../components/common/Select";
import {
    putExpedienteProceso,
    getProcesoSecundario,
    putExpedienteProcesoSecundario,
    getInterponente,
    getInterponentes,
    deleteInterponente,
    postInterponente
} from "./services";
import ButonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalGenerico from "../../../../../components/common/Modal";
import Alert from "../../../../../components/common/Alert";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import Divider from "../../../../../components/common/Divider";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    expediente: Yup.string().required("expediente es requerido"),
    expediente_sindicado: Yup.string().required(
        "expediente sindicado es requerido"
    ),
    etapa_procesal: Yup.string().required("etapa procesal es requerida"),
    estado_proceso: Yup.string().required("estado del proceso es requerido"),
    fecha: Yup.string().required("fecha es requerida"),
    proceso_secundario: Yup.boolean(),
    numero_sentencia: Yup.string().when("requiere_sentencia", {
        is: true,
        then: Yup.string().required("numero sentencia es requerido"),
    }),
    fecha_sentencia: Yup.string().when("requiere_sentencia", {
        is: true,
        then: Yup.string().required("fecha sentencia es requerida"),
    }),
    tipo_proceso_secundario: Yup.string().when("proceso_secundario", {
        is: true,
        then: Yup.string().required("tipo proceso es requerido"),
    }),
    estado_proceso_secundario: Yup.string().when("proceso_secundario", {
        is: true,
        then: Yup.string().required("estado proceso es requerido"),
    }),
});

const useFetchExpedientesProcesos = (idExpedienteProceso, token) => {
    const [state, setState] = useState({
        procesoSecundario: [],
    });

    useEffect(() => {
        getProcesoSecundario(idExpedienteProceso, token).then((data) => {
            setState({
                procesoSecundario: data,
            });
        });
    }, []);
    return state;
};

const useFetchGetInterponentes = (idExpedienteProcesoSecundario, refetch, token) => {
    const [state, setState] = useState({
        interponentes: [],
    });

    useEffect(() => {
        getInterponente(idExpedienteProcesoSecundario, token).then((data) => {
            setState({
                interponentes: data,
            });
        });
    }, [idExpedienteProcesoSecundario, refetch]);
    return state;
};

const useFetchInterponentes = (token) => {
    const [state, setState] = useState({
        listInterponentes: [],
    });

    useEffect(() => {
        getInterponentes(token).then((data) => {
            setState({
                listInterponentes: data,
            });
        });
    }, []);
    return state;
};

export const Actualizar = (props) => {
    const token = useSelector((state) => state?.loginReducer?.data?.token);
    const [cargando, setCargando] = useState(false);
    const [cargandoInterponente, setCargandoInterponente] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [interponente, setInterponente] = useState(0);
    const registro = props.registro;
    const idCaso = props?.registro?.caso;
    const idExpedienteProceso = props?.registro?.id;
    //const [expSindicados, setExpSindicados] = useState([]);
    const [requiereSentencia, setRequiereSentencia] = useState(false);
    //const [mostrarFormSentencia, setmostrarFormSentencia] = useState(false);
    const [sindicadoConocido, setSindicadoConocido] = useState(true);
    const [motivo, setMotivo] = useState(false);
    const { procesoSecundario } = useFetchExpedientesProcesos(idExpedienteProceso, token);
    const procesosecundario = procesoSecundario?.results?.[0];
    const { listInterponentes } = useFetchInterponentes(token);
    const { interponentes } = useFetchGetInterponentes(procesosecundario?.id, refetch, token);

      
  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Expedientes";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    retorno = true;  // mm comentar para que funcione
    return retorno;

  }



    const headersTableInterponentes = [
        "Interponente",
        "Fecha",
        "Acciones",
    ]
    
    return (
        <Formik
            initialValues={{
                id: registro?.id,
                numero_sentencia: "",
                fecha_sentencia: "",
                reparacion_digna_via_penal: false,

                expediente: registro?.expediente?.id || "",
                expediente_sindicado: registro?.expediente_sindicado?.sindicado?.id || "",
                etapa_procesal: registro?.etapa_procesal?.id || "",
                resultado_audiencia: registro?.resultado_audiencia?.id || "",
                tipo_proceso: registro?.expediente?.tipo_proceso?.id || "",
                estado_proceso: registro?.estado_proceso?.id,
                medida: "",
                proceso_secundario: registro?.proceso_secundario || false,
                fecha: registro?.fecha || "",
                comentarios: registro?.comentarios || "",

                tipo_proceso_secundario: procesosecundario?.tipo_proceso_secundario || "",
                estado_proceso_secundario: procesosecundario?.estado_proceso_secundario || "",
                tipo_apelacion: procesosecundario?.tipo_apelacion_especial_casacion || "",
                amparo_provisional: procesosecundario?.amparo_provisional || false,
                fecha_proceso_secundario: procesosecundario?.fecha,
                comentarios_secundarios: procesosecundario?.comentarios || "",
                causa_penal: procesoSecundario?.expediente_causa_penal || "",

                requiere_sentencia: false,
                sentencia_externa: false,
                interponente: ""
            }}
            validationSchema={validaciones}
            enableReinitialize
            onSubmit={async (values) => {
                values.sentencia_externa = requiereSentencia;
                setCargando(true);
                await putExpedienteProceso(
                    values,
                    idCaso,
                    token
                ).then((data) => {
                    if (data?.status === 200 || data?.status === 201) {
                        putExpedienteProcesoSecundario(values, idExpedienteProceso, procesosecundario.id, token).then(() => {
                            toast.success("proceso actualizado con éxito..!");
                            setCargando(false);
                        })
                    }
                })
                    .catch(function (error) {
                        if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                        ) {
                            toast.error(error.response.data.message);
                            setCargando(false);
                        } else if (error.response.status >= 500) {
                            toast.error("Error interno comuniquese con el administrador");
                            setCargando(false);
                        }
                    });
            }}
        >
            {({ values, handleSubmit, setFieldValue, handleChange }) => (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-3 border rounded-md p-4">
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                             <SelectSiavi
                                disabled={true}
                                label="Tipo proceso"
                                validacion={true}
                                name="expediente"
                                value={values.expediente}
                                onChange={(e) => {
                                    setFieldValue("expediente", e.target.value);
                                    props?.expedientes?.forEach((element) => {
                                        if (element.id === Number(e.target.value)) {
                                            //setExpSindicados(element);
                                            setFieldValue("tipo_proceso", element?.tipo_proceso?.id);
                                            if (element?.tipo_proceso?.requiere_sentencia_previa === true) {
                                                setRequiereSentencia(true);
                                                setFieldValue("requiere_sentencia", true);
                                                //setmostrarFormSentencia(true);
                                            }
                                        }
                                    });
                                }}
                                valueInit=""
                                selectInit="Seleccione tipo proceso"
                                list={
                                    props.expedientes?.map((item) => (
                                        <option
                                            key={item?.tipo_proceso?.tipo_proceso + item?.id}
                                            value={item.id}
                                        >
                                            {item?.tipo_proceso?.tipo_proceso}
                                        </option>
                                    ))
                                }
                            />
                        </div>
                       {//mmazariegos
                       /* <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                            <SelectSiavi
                                label="Sindicado"
                                validacion={true}
                                disabled={true}
                                name="expediente_sindicado"
                                value={values.expediente_sindicado}
                                onChange={(e) => {
                                    setFieldValue("expediente_sindicado", e.target.value);
                                    props.expSindicados?.sindicados.forEach(element => {
                                        if (element.id === Number(e.target.value)) {
                                            setSindicadoConocido(element?.sindicado?.conoce_al_sindicado);
                                        }
                                    });
                                }}
                                valueInit=""
                                selectInit="Seleccione sindicado"
                                list={
                                    props.registro?.expediente?.sindicados?.map((item) => (
                                        <option key={item.id} value={item?.sindicado?.id}>
                                            {`${(item?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado No Individualizado' : item?.sindicado?.primer_nombre + ' ' + item?.sindicado?.segundo_nombre + ' ' + item?.sindicado?.primer_apellido + ' ' + item?.sindicado?.segundo_apellido}`}
                                        </option>
                                    ))
                                }
                            />
                            </div>*/}
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                            <SelectSiavi
                                label="Etapa procesal"
                                validacion={true}
                                disabled={true}
                                name="etapa_procesal"
                                value={values.etapa_procesal}
                                onChange={(e) => {
                                    setFieldValue('etapa_procesal', e.target.value)
                                }}
                                valueInit=""
                                selectInit="Elegir etapa procesal"
                                list={
                                    props.etapasProcesales?.results.map((item) => {
                                        return (
                                            <option key={item.nombre} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                             <SelectSiavi
                                label="Estado proceso"
                                validacion={true}
                                disabled={true}
                                name="estado_proceso"
                                value={values.estado_proceso}
                                onChange={(e) => {
                                    setFieldValue("estado_proceso", e.target.value);
                                    props.estadosProcesos?.results?.forEach((element) => {
                                        if (element.id === Number(e.target.value)) {
                                            setFieldValue("medidaDesjudializadora", element.habilita_medida_desjudicializadora);
                                        }
                                    });
                                }}
                                valueInit=""
                                selectInit="Elegir estado proceso"
                                list={
                                    props.estadosProcesos?.results?.map((item, index) => {
                                        return (
                                            <option key={item.nombre + index} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                            <SelectSiavi
                                label="Resultado audiencia"
                                validacion={true}
                                disabled={true}
                                name="resultado_audiencia"
                                value={values.resultado_audiencia}
                                onChange={handleChange}
                                valueInit=""
                                selectInit="Elegir resultado audiencia"
                                list={
                                    props.audiencias?.results?.filter(x => x.estado_proceso.id === Number(values.estado_proceso)).map((item) => {
                                        return (
                                            <option key={item.nombre} value={item.id}>
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                        {
                            values.medidaDesjudializadora &&
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                <SelectSiavi
                                    label="Medida desjudicializadora"
                                    validacion={true}
                                    disabled={true}
                                    name="medida"
                                    value={values.medida}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir medida desjudicializadora"
                                    list={
                                        props.medidasDesjudicializadoras?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>
                        }
                        {/*   //mmazariegos
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                            <div className="flex flex-col justify-end items-center">
                                <Label text="Acción constitucional/Impugnación" />
                                <Toggle
                                    disabled
                                    enabled={values.proceso_secundario}
                                    name="proceso_secundario"
                                    onChange={handleChange}
                                />
                            </div>
                    </div> */}
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                            <InputSiavi
                                name="fecha"
                                type="date"
                                disabled={true}    //mmazaariegos
                                onChange={handleChange}
                                value={values.fecha}
                                maximo={props.bloqueoFecha()}
                                label="Fecha"
                            />
                            <ErrorMessage
                                name="fecha"
                                component="span"
                                className="font-normal text-xs text-maivi-secondaryRed"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-8">
                            <Label text="Comentarios" />
                            <TextAreaSiavi
                                placeholder="comentarios"
                                disabled={true}     //mmazariegos
                                name="comentarios"
                                value={values.comentarios}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {
                        /*  --mmazariegos
                        values.proceso_secundario && 
                        <div className="grid grid-cols-12 gap-2 mt-2">
                            <div className="col-span-12 xl:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
                                <h1 className="mb-4 xl:mb-1 lg:mb-1 md:mb-1 text-sm">
                                    Acción constitucional/Impugnación
                                </h1>
                                <Divider />
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <SelectSiavi
                                            label="Acción constitucional / Impugnación"
                                            validacion={true}
                                            disabled={true}
                                            name="tipo_proceso_secundario"
                                            value={values.tipo_proceso_secundario}
                                            onChange={handleChange}
                                            valueInit=""
                                            selectInit="Elegir acción"
                                            list={
                                                props?.procesosSecundarios?.results?.map((item) => {
                                                    return (
                                                        <option key={item?.nombre} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <SelectSiavi
                                            label="Estado proceso"
                                            validacion={true}
                                            disabled={true}
                                            name="estado_proceso_secundario"
                                            value={values.estado_proceso_secundario}
                                            onChange={(e) => {
                                                setFieldValue("estado_proceso_secundario", e.target.value);
                                                props.estadosProcesosSecundarios?.results?.forEach(element => {
                                                    if (element.id === Number(e.target.value)) {
                                                        setMotivo(element.habilita_apelacion_especial);
                                                    }
                                                });
                                            }}
                                            valueInit={null}
                                            selectInit="Elegir estado del proceso"
                                            list={
                                                props.estadosProcesosSecundarios?.results?.map((item) => {
                                                    return (
                                                        <option key={item.nombre} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                    </div>
                                    {motivo && (
                                        <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                            <SelectSiavi
                                            label="Motivo"
                                            validacion={true}
                                            disabled={true}
                                            name="tipo_apelacion"
                                            value={values.tipo_apelacion}
                                            onChange={handleChange}
                                            valueInit=""
                                            selectInit="Elegir Motivo"
                                            list={
                                                props?.apelaciones?.results?.map((item) => {
                                                    return (
                                                        <option key={item.nombre} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                        </div>
                                    )}
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <div className="flex flex-col justify-center items-center">
                                            <Label text="Amparo provisional" />
                                            <Toggle
                                                disabled={true}
                                                enabled={values.amparo_provisional}
                                                onChange={handleChange}
                                                name="amparo_provisional"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <Label text="Fecha" />
                                        <InputSiavi
                                            name="fecha_proceso_secundario"
                                            type="date"
                                            onChange={handleChange}
                                            value={values.fecha_proceso_secundario}
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-8 lg:col-span-8">
                                        <Label text="Comentarios" />
                                        <TextAreaSiavi
                                            placeholder="comentarios"
                                            value={values.comentarios_secundarios}
                                            name="comentarios_secundarios"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-span-8 xl:col-span-4 lg:col-span-4 md:col-span-6">
                                        <SelectSiavi
                                            label="Interponentes"
                                            validacion={true}
                                            disabled={true}
                                            name="interponente"
                                            value={values.interponente}
                                            onChange={handleChange}
                                            valueInit={null}
                                            selectInit="Elegir interponente"
                                            list={
                                                listInterponentes?.results?.map((item) => {
                                                    return (
                                                        <option key={item.nombre} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-span-4 xl:col-span-2 lg:col-span-2 md:col-span-3 flex items-end">
                                        {
                                            (cargandoInterponente) ?
                                                <Spinner /> :
                                                (CheckPermiso('XXX') === true) &&
                                                <ButtonSiavi
                                                    type="button"
                                                    text="Agregar"
                                                    onClick={async () => {
                                                        setCargandoInterponente(true);
                                                        await postInterponente(
                                                            procesosecundario?.id,
                                                            values.interponente,
                                                            token
                                                        ).then((data) => {
                                                            if (data?.status === 200 || data?.status === 201) {
                                                                toast.success("interponente agregado con éxito..!");
                                                                setCargandoInterponente(false);
                                                                setRefetch(true);
                                                                setRefetch(false);
                                                            }
                                                        }).catch(function (error) {
                                                            if (
                                                                error.response.status >= 400 &&
                                                                error.response.status <= 450
                                                            ) {
                                                                toast.error(error.response.data.message);
                                                                setCargandoInterponente(false);
                                                            } else if (error.response.status >= 500) {
                                                                toast.error("Error interno comuniquese con el administrador");
                                                                setCargandoInterponente(false);
                                                            }
                                                        });
                                                    }}
                                                />
                                        }
                                    </div>
                                    <div className="col-span-12">
                                        <TableSiavi
                                            headers={headersTableInterponentes}
                                            body={
                                                interponentes?.results?.map((item) => (
                                                    <tr key={item?.interponente?.nombre} className="hover:bg-maivi-gray-5 cursor-pointer">
                                                        <td className={props.tdClass}>{item?.interponente?.nombre}</td>
                                                        <td className={props.tdClass}>{item?.fecha}</td>
                                                        <td className={props.tdClass}>
                                                        {(CheckPermiso('XXX') === true) &&
                                                            <button
                                                                type="button"
                                                                className={props.classAcciones}
                                                                onClick={() => {
                                                                    setInterponente(item);
                                                                    setBorrar(true);
                                                                }}
                                                            >
                                                                <TrashIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                                <span className="tooltiptext">Eliminar</span>
                                                            </button>
                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                 */   }
                    {borrar && (
                        <ModalGenerico
                            title={`Esta seguro de borrar el registro ${interponente?.interponente?.nombre}`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setBorrar(false);
                            }}
                            children={
                                <ButonesConfirmar
                                    aceptar={() => {
                                        deleteInterponente(interponente?.id, token).then(() => {
                                            toast.success("registro eliminado con éxito");
                                            setRefetch(true);
                                            setRefetch(false);
                                            setBorrar(false);
                                        });
                                    }}
                                    cancelar={() => {
                                        setBorrar(false);
                                    }}
                                />
                            }
                        />
                    )}
                    <div className="grid grid-cols-12 gap-2">
                        {
                            !sindicadoConocido &&
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 mt-2">
                                <Alert type="warning" mensaje="No puede avanzar en el proceso penal si el sindicado no es individualizado" />
                            </div>
                        }
                        <div className="cols-span-12 xl:col-span-3 lg:col-span-3 mt-2 mb-2">
                            {
                                (cargando) ?
                                    <Spinner /> :
                                    sindicadoConocido &&
                                    (false) &&
                                    <ButtonSiavi
                                        type="button"
                                        text="Actualizar"
                                        onClick={handleSubmit}
                                    />
                            }
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
};
