/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clases } from "../../../../../../helpers/clases";
import { FilterIcon, PlusCircleIcon, RefreshIcon } from "@heroicons/react/solid";
import { FormDetalle } from "./Detalle";
import { Gestionar } from "./gestionar";
import { acciones } from "./services";
import TableGeneric from "../../../../../../components/common/TableGeneric";
import ModalGenerico from "../../../../../../components/common/Modal";
import ModalBig from "../../../../../../components/common/ModalTwo";
import Spinner from "../../../../../../components/common/Spinner";
import ButtonAdd from "../../../../../../components/common/ButtonAdd";
import Filtros from "../../../../seguimientos/filtros";

const columns = [
  { title: "No.", value: "id", tipo: "text" },
  { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
  { title: "Servicio", value: "accion_seguimiento.servicio.nombre", tipo: "text" },
  {
    title: "Nombre",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  { title: "Tipo seguimiento", value: "accion_seguimiento.nombre", tipo: "text" },
  
  { title: "Fecha planificada", value: "fecha_hora_planificada_inicia", tipo: "datetime" },
  { title: "Fecha completada", value: "fecha_completado", tipo: "date" },
    {
    title: "Profesional asignado",
    value: null,
    values: [
      "profesional_asignado.first_name",
      "profesional_asignado.last_name"
    ],
    tipo: "text"
  }
];

export const TableSeguimientos = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [order, setOrder] = useState("-fecha_accion");
  const [endpoint] = useState(`accionesseguimientoscaso?caso=${props.caso.id}`);
  const [detalle, setDetalle] = useState(false);
  const [registro, setRegistro] = useState({});
  const [gestionar, setGestionar] = useState(false);
  const [filtros, setFiltros] = useState("");
  const [filtrar, setFiltrar] = useState(false);
  const [refetch,setRefetch] = useState(false);
  const permiso = "accionesseguimientoscaso";


const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "ExpedientesAL";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
      let retorno = false;
      permisosRegistro?.forEach(menu => {
          if (menu?.permiso === permisoView) {
              menu?.opciones?.forEach(op => {
                  if (op.permiso === permiso) {
                      retorno = op.ver;
                  }
              })
          }
      });
      
      //        retorno = true;  // mm comentar para que funcione
      //        retorno = true;  // mm comentar para que funcione
      return retorno;

  }

  useEffect(() => {
    setTableLoading(true);
    acciones(urlFetch, props?.caso?.id, filtros, token).then((data) => {
      data?.results?.forEach(element => {
        if (element.es_vencida) {
          element.fondo = 'bg-red-50'
        } else if (element.es_por_vencer) {
          element.fondo = 'bg-yellow-50'
        } else if (element.completada) {
          element.fondo = ''
        }
      });
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, sede, order,refetch, endpoint, filtros]);

  const handleVer = (registro) => {
    setRegistro(registro);
    setDetalle(true);
  }

  const opcion = (registro) => {
    return (
      <button
        type="button"
        onClick={() => {
          setRegistro(registro);
          setGestionar(true);
        }}
        className={clases.addIcon}
      >
        <PlusCircleIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
        <span className="tooltiptext">Gestionar</span>
      </button>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <span></span>
        <div>
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setFiltrar(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
           <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
            onClick={() => {
              setFiltros("")
            }}
          />
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <TableGeneric
            dataTable={dataTable}
            tableColumns={columns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={true}
            order={order}
            setOrder={setOrder}
            handleVer={handleVer}
            addAccion={(CheckPermiso('GestionarSeguimientos') === true)}
            opcion1={opcion}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {detalle && (
        <ModalBig
          title={`Detalle de acción`}
          open={detalle}
          closeModal={() => { }}
          cerrar={() => {
            setDetalle(false);
          }}
          children={
            <FormDetalle registro={registro} token={token} />
          }
        />
      )}
      {gestionar && (
        <ModalGenerico
          title={`Gestionar`}
          open={gestionar}
          closeModal={() => { }}
          cerrar={() => {
            setGestionar(false);
          }}
          children={
            <Gestionar
              registro={registro}
              setGestionar={setGestionar}
              setRefetch={setRefetch}
            />
          }
        />
      )}

      {filtrar && (
        <ModalGenerico
          title={`Filtros`}
          open={filtrar}
          closeModal={() => { }}
          cerrar={() => {
            setFiltrar(false);
          }}
          children={
            <Filtros
              setFiltrar={setFiltrar}
              setFiltros={setFiltros}
              setUrlFech={setUrlFech}
              token={token}
            />
          }
        />
      )}
    </div>
  );
};
