export const FormDetalle = (props) => {
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Número Orientación
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props.detalle.id}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Fecha
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props.detalle.fecha}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">Nombre</div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {`${props.detalle.persona_detalle.primer_nombre} ${props.detalle.persona_detalle.segundo_nombre} ${props.detalle.persona_detalle.primer_apellido} ${props.detalle.persona_detalle.segundo_apellido}`}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Caso Admitido
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {(props.detalle.caso_admitido) ? 'Si' : 'No'}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-sm font-medium text-gray-500">
                            Profesional
                        </div>
                        <div className="text-sm text-gray-900 sm:col-span-2">
                            {props?.detalle?.profesional?.first_name}  {props?.detalle?.profesional?.last_name}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
