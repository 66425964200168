import { React} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtencionVictimologica } from "./atencion_victimologica";

const validaciones = Yup.object().shape({
  tipo_persona: Yup.string().required('tipo persona es requerido'),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormAtencionVictimologica = (props) => {
  const caso = props.caso;
  const delitos = props.delitos;

  return (
    <Formik
      initialValues={{
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
       
      }
     }
    >
      {(props) => (
        <AtencionVictimologica
          {...props}
          customSwitchChange={customSwitchChange}
          caso={caso}
          delitos={delitos}
        />
      )}
    </Formik>
  );
};
