export const FormDetalle = (props) => {
    return (
        <div className="bg-white overflow-hidiven border rounded-lg mt-4">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <div className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Servicio interno</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.nombre}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Descripción</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.descripcion}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Subárea</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props?.detalle?.sub_area?.nombre}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Asistencia legal</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.asitencia_legal === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Atención victimológica</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.atencion_victimologica === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Estado</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.estado === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Trabajo Social</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.ts === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Psicología</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.ps === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Medicina</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.md === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Litigio Estratégico</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.litigio_estrategico === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="text-xs font-medium text-gray-500">Impugnaciones</div>
                        <div className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                            {props.detalle.impugnaciones === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Activo
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Inactivo
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
