import axios from "axios";

const { REACT_APP_API_URL } = process.env;


export const exportarResumen = async (url, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/${url}` ;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};


export const exportarData = async (sede,filters,order, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/accionesseguimientoscasolist/?sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&${filters}&ordering=${order}` ;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getAudiencias = async (urlPage, search,sede,filters,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&completadas=false&es_audiencia=true&ordering=${order}&${filters}`
        :`${urlPage}&search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&completadas=false&es_audiencia=true&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const audiencias = async (urlPage,sede,filters,order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?sede_accion=${sede}&profesional_asignado__isnull=false&completadas=false&es_audiencia=true&${filters}&ordering=${order}`
        : `${urlPage}&sede_accion=${sede}&profesional_asignado__isnull=false&completadas=false&es_audiencia=true&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getInstitucionGestion = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&institucion__tiene_materia=true`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postAsignaciones = async (profesional, expedientes,token) => {
  try {
      let ArregloExpedientes = [];
      expedientes.forEach(element => {
        ArregloExpedientes.push(element.id);
      });

      let body ={
        profesional: profesional.value,
        expedientes:ArregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getHistorialAudiencia = async (urlPage, token) => {
  try {
      let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/registro/casoseguimientoaccionhistoricoplanificacion/` : urlPage;

      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAudienciasTodas = async (urlPage, search,sede,filters,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&ordering=${order}&${filters}`
        :`${urlPage}&search=${search.value}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const audienciasTodas = async (urlPage,sede,filters,order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&${filters}&ordering=${order}`
        : `${urlPage}&sede_accion=${sede}&profesional_asignado__isnull=false&es_audiencia=true&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const putAcciones = async (id, profesional, data, token) => {
  try {
      let body = {
          seguimiento: data.seguimiento,
          es_nna: data.es_nna,
          tipo_audiencia: (data.tipo_audiencia === "") ? null : data.tipo_audiencia,
          fecha_accion: (data.fecha_accion === "") ? null : data.fecha_accion,
          fecha_asigna: (data.fecha_asigna === "") ? null : data.fecha_asigna,
          fecha_hora_planificada_inicia: (data.fecha_hora_planificada_inicia === "") ? null : data.fecha_hora_planificada_inicia,
          fecha_hora_planificada_fin: (data.fecha_hora_planificada_fin === "") ? null : data.fecha_hora_planificada_fin,
          requiere_autorizacion: false,
          fecha_autoriza: (data.fecha_autoriza === "") ? null : data.fecha_autoriza,
          autorizado: data.autorizado,
          fecha_completado: (data.fecha_completado === "") ? null : data.fecha_completado,
          estado: data.estado,
          accion_planificada: data.accion_planificada,
          asignar_accion: data.asignar_accion,
          es_accion: data.es_accion,
          es_seguimiento: data.es_seguimiento,
          es_audiencia: data.es_audiencia,
          completada: data.completada,
          requiere_nombramiento: data.requiere_nombramiento,
          observaciones: (data.observaciones === "") ? null : data.observaciones,
          detalle_lugar: data.detalle_lugar,
          especificaciones_modalidad: data.especificaciones_modalidad,
          tipo: (data.tipo === "") ? null : data.tipo,
          caso: (data.caso === 0) ? null : data.caso,
          expediente: (data.expediente === 0) ? null : data.expediente,
          persona: (data.persona === 0) ? null : data.persona,
          victima: data.victima,
          querellante: data.querellante,
          modalidad: (data.modalidad === "") ? null : data.modalidad,
          referencia: (data.referencia === "") ? null : data.referencia,
          departamento: (data.departamento === "") ? null : data.departamento,
          municipio: (data.municipio === "") ? null : data.municipio,
          accion_seguimiento: (data.accion_seguimiento === "") ? null : data.accion_seguimiento,
          gestion_institucion: (data.gestion_institucion === "") ? null : data.gestion_institucion,
          profesional_asignado: profesional.value,
          profesional_autoriza: (data.profesional_autoriza === "") ? null : data.profesional_autoriza,
          profesional_realiza: (data.profesional_realiza === "") ? null : data.profesional_realiza,
          nombramiento: data.nombramiento,
          profesional_crea: data.profesional_crea,
          sede_accion: data.sede_accion
      }

      let bodyResult = axios({
          method: 'PUT',
          url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
          data: body,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return bodyResult;

  } catch (e) {
      console.log('error', e);
  }
};