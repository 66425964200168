import { types } from "../types/types";

export const formPersonaReducer = (state = {}, action) => {
    switch (action.type) {
        case types.savePersona:
            return {
                data: action.payload,
            }
        case types.cleanPersona:
            return {
                data: action.payload,
            }
        default:
            return state;
    }
}

