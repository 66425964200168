import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../../../components/common/InputText";
import { SelectSiavi } from "../../../../../components/common/Select";
import {
  getEscolaridadVictima,
  getIdiomaVictima,
  getOrigenEtnicoVictima,
  getPaisVictima,
  getReligionVictima,
  getOrientacionSexualVictima,
  getTipoProfesion,
  getEstadoCivilVictima,
  getIdiomaMaya,
  getIdentidadGeneroVictima,
  getSituacionLaboralVictima,
} from "../victima/services";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";

export const DatosDemograficos = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [escolaridad, setEscolaridades] = useState([]);
  const [origenetnico, setOrigenetnico] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [religiones, setReligiones] = useState([]);
  const [paises, setPaises] = useState([]);
  const [orientacionsexual, setOrientacionsexual] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [estadocivil, setEstadocivil] = useState([]);
  const [idiomasmayas, setIdiomasmayas] = useState([]);
  const [identidadgenero, setIdentidadgenero] = useState([]);
  const [situacionlaboral, setSituacionlaboral] = useState([]);
  const [origenetnicomaya, setOrigenetnicomaya] = useState([]);
  const origenEtnicoMaya = props?.configuracion?.habilita_origen_etnico?.nombre;
  const idiomaMaya = props?.configuracion?.habilita_idioma_maya?.nombre;
  let origenEtnicoMayaTrue = false;
  //let idiomaMayaTrue = false;

  if (origenEtnicoMaya === "MAYA") {
    origenEtnicoMayaTrue = true
  }

  if (idiomaMaya === "MAYA") {
    //idiomaMayaTrue = true
  }

  useEffect(() => {
    getEscolaridadVictima(token).then((data) => {
      setEscolaridades(data)
    });
  }, [token]);

  useEffect(() => {
    getOrigenEtnicoVictima(token).then((data) => {
      setOrigenetnico(data)
    });
  }, [token]);

  useEffect(() => {
    getIdiomaVictima(token).then((data) => {
      setIdiomas(data)
    });
  }, [token]);

  useEffect(() => {
    getReligionVictima(token).then((data) => {
      setReligiones(data)
    });
  }, [token]);

  useEffect(() => {
    getPaisVictima(token).then((data) => {
      setPaises(data)
    });
  }, [token]);

  useEffect(() => {
    getOrientacionSexualVictima(token).then((data) => {
      setOrientacionsexual(data)
    });
  }, [token]);

  useEffect(() => {
    getTipoProfesion(token).then((data) => {
      setProfesiones(data)
    });
  }, [token]);

  useEffect(() => {
    getEstadoCivilVictima(token).then((data) => {
      setEstadocivil(data)
    });
  }, [token]);

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setIdiomasmayas(data)
    });
  }, [token]);

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setOrigenetnicomaya(data)
    });
  }, [token]);

  useEffect(() => {
    getIdentidadGeneroVictima(token).then((data) => {
      setIdentidadgenero(data)
    });
  }, [token]);

  useEffect(() => {
    getSituacionLaboralVictima(token).then((data) => {
      setSituacionlaboral(data)
    });
  }, [token]);

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Estado civil"
          name="estado_civil"
          value={props.values?.estado_civil}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir estado civil"
          list={estadocivil?.results?.map((item) => {
            return (
              <option key={item.nombre + "ec"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Orientación sexual"
          name="orientacion_sexual"
          value={props.values?.orientacion_sexual}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir orientación sexual"
          list={orientacionsexual?.results?.map((item) => {
            return (
              <option key={item.nombre + "os"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Identidad de género"
          name="identidad_genero"
          value={props.values.identidad_genero}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir identidad género"
          list={identidadgenero?.results?.map((item) => {
            return (
              <option key={item.nombre + "ig"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <SelectSiavi
          label="Lee y escribe"
          name="lee_escribe"
          value={props.values?.lee_escribe}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir alfabetismo"
          list={[
            <option value="ALFABETO">ALFABETA</option>,
            <option value="ANALFABETO">ANALFABETA</option>
          ]}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Escolaridad"
          name="escolaridad"
          value={props.values?.escolaridad}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir escolaridad"
          list={escolaridad?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Profesión/oficio"
          name="profesion"
          value={props.values?.profesion}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir tipo profesion"
          list={profesiones?.results?.map((item) => {
            return (
              <option key={item.nombre + "tp"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Situación laboral"
          name="situacion_laboral"
          value={props.values.situacion_laboral}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir situación laboral"
          list={situacionlaboral?.results?.map((item) => {
            return (
              <option key={item.nombre + "sl"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Origen Étnico"
          name="origen_etnico"
          value={props.values?.origen_etnico}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir origen etnico"
          list={origenetnico?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      {origenEtnicoMayaTrue && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <SelectSiavi
            label="Origen Étnico Maya"
            name="origen_etnico_maya"
            value={props.values?.origen_etnico_maya}
            onChange={props.handleChange}
            validacion={true}
            valueInit=""
            selectInit="Elegir origen etnico maya"
            list={origenetnicomaya?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          />
        </div>)}
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Idioma"
          name="idioma"
          value={props.values?.idioma}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir idioma"
          list={idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <SelectSiavi
            label="Idioma maya"
            name="idioma_maya"
            value={props.values?.idioma_maya}
            onChange={props.handleChange}
            validacion={true}
            valueInit=""
            selectInit="Elegir idioma maya"
            list={idiomasmayas?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          />
        </div>
       
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Idioma de preferencia"
          name="idioma_preferencia"
          value={props.values.idioma_preferencia}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir idioma de preferencia"
          list={idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <SelectSiavi
          label="Religión"
          name="religion"
          value={props.values?.religion}
          onChange={props.handleChange}
          validacion={true}
          valueInit=""
          selectInit="Elegir religión"
          list={religiones?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="En contexto o tránsito migratorio" />
        <Toggle enabled={props.values.en_migracion} name="en_migracion" />
      </div>
      {props?.values?.en_migracion === true && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <SelectSiavi
            label="En migración - país origen"
            name="pais_origen"
            value={props.values?.pais_origen}
            onChange={props.handleChange}
            validacion={true}
            valueInit=""
            selectInit="Elegir país origen"
            list={paises?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          />
        </div>
      )}
      {props?.values?.en_migracion === true && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <SelectSiavi
            label="En migración - país destino"
            name="pais_destino"
            value={props.values?.pais_destino}
            onChange={props.handleChange}
            validacion={true}
            valueInit=""
            selectInit="Elegir país destino"
            list={paises?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          />
        </div>
      )}
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="correo_electronico"
          value={props.values.correo_electronico}
          onChange={props.handleChange}
          label="Correo Electrónico"
        />
      </div>
    </div>
  );
};
