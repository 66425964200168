import TableSiavi from "../../../../../components/common/TableSiavi";
import { SelectSiavi } from "../../../../../components/common/Select";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useEffect, useState } from "react";
import {
  reparacionDignaCasoPersona,
  getReparacionDignaCasoPersona,
} from "./services";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";
import moment from "moment";
import { ButtonSiaviCancelar } from "../../../../../components/common/ButtonCancelar";

const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";
const colorGreen =
  "rounded-lg bg-green-100 px-3 py-0.5 text-xs font-medium text-green-700";
const colorRed =
  "rounded-lg bg-red-100 px-3 py-0.5 text-xs font-medium text-red-700";

export const CasoPersonaRD = ({
  caso,
  token,
  reparacionesDignas,
  showModal,
}) => {
  const [dateFetch, setDate] = useState(moment());
  const [personas, setPersonas] = useState([]);
  const [idReparacionDigna, setIdReparacionDigna] = useState(null);
  const [listPersonasRD, setListPersonasRD] = useState([]);
  const [state, setState] = useState({
    showAlert: false,
    cargando: false,
    cargandoTable: false,
    nombreReparacion: "",
    mensaje: "",
  });

  useEffect(() => {
    if (idReparacionDigna !== null) {
      setState({ ...state, cargandoTable: true });
      getReparacionDignaCasoPersona(token, idReparacionDigna).then((data) => {
        setListPersonasRD(data?.results);
        setState({ ...state, cargandoTable: false });
      });
    }
  }, [idReparacionDigna, dateFetch]);

  const guardarPersonas = async () => {
    if (personas.length === 0) {
      setState({
        ...state,
        showAlert: true,
        mensaje: "Debe seleccionar al menos una persona",
      });
    } else if (idReparacionDigna === null) {
      setState({
        ...state,
        showAlert: true,
        mensaje: "Selecione una reparación digna",
      });
    } else {
      setState({ ...state, cargando: true });

      for (const item of personas) {
        let body = {
          reparacion_digna: idReparacionDigna,
          caso_persona: item,
        };

        await reparacionDignaCasoPersona(body, token)
          .then((data) => {
           
          })
          .catch(function (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
      }

      for(const item of caso?.personas){
        let element = document.getElementById(item.persona.primer_nombre);
        element.checked = false;
      }

      setState({ ...state, cargando: false });
      setPersonas([]);
      setDate(moment());
    }
  };

  return (
    <div>

<div className="grid grid-cols-12 gap-3 mt-2">
      <div className="col-span-6 border rounded-md p-2">
        <div className="w-full mb-1 flex items-end">
          <div className="w-72">
            <SelectSiavi
              label="Fecha de la reparación digna"
              initValue=""
              selectInit="Elegir reparación digna"
              name="reparacion_digna"
              className="w-72 mb-2"
              onChange={(e) => {
                setIdReparacionDigna(Number(e.target.value));
                setState({
                  ...state,
                  showAlert: false,
                });
              }}
              list={reparacionesDignas?.map((item) => {
                return (
                  <option key={item.fecha} value={item.id}>
                    {moment(item.fecha).format("DD-MM-YYYY")}
                  </option>
                );
              })}
            />
          </div>
          <div className="w-32 mb-2 ml-3">
            <ButtonSiavi
              text="Agregar"
              cargando={state.cargando}
              onClick={() => {
                guardarPersonas();
              }}
            />
          </div>
        </div>
        {state.showAlert && (
          <div className="mb-2">
            <Alert type="error" mensaje={state.mensaje} />
          </div>
        )}
        <TableSiavi
          headers={[
            "",
            "Nombre",
            "Es víctima",
            "Víctima indirecta",
            "Es Querrellante",
          ]}
          body={caso?.personas?.map((ps) => (
            <tr
              key={`${ps.persona.primer_nombre}-${ps.persona.primer_apellido}`}
              className="hover:bg-maivi-gray-5 cursor-pointer"
            >
              <td className={tdClass}>
                <input
                  id={ps.persona.primer_nombre}
                  name="checkTable"
                  type="checkbox"
                  className="bg-maivi-primaryLightBlue h-4 w-4 rounded-lg mr-2"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setState({
                        ...state,
                        showAlert: false,
                      });
                      setPersonas([...personas, ps.id]);
                    } else {
                      let casosPersonas = personas.filter((x) => x !== ps.id);
                      setPersonas(casosPersonas);
                    }
                  }}
                />
              </td>
              <td className={tdClass}>
                {ps.persona.primer_nombre} {ps.persona.segundo_nombre}{" "}
                {ps.persona.primer_apellido} {ps.persona.segundo_apellido}
              </td>
              <td className={tdClass}>
                {ps?.victima === true ? (
                  <span className={colorGreen}>Si</span>
                ) : (
                  <span className={colorRed}>No</span>
                )}
              </td>
              <td className={tdClass}>
                {ps.es_victima_indirecta === true ? (
                  <span className={colorGreen}>Si</span>
                ) : (
                  <span className={colorRed}>No</span>
                )}
              </td>
              <td className={tdClass}>
                {ps.querellante === true ? (
                  <span className={colorGreen}>Si</span>
                ) : (
                  <span className={colorRed}>No</span>
                )}
              </td>
            </tr>
          ))}
        />
      </div>
      <div className="col-span-6 rounded-md border p-3">
        <span className="text-maivi-primaryFont ">
          Víctimas relacionadas a la reparación digna
        </span>
        <TableSiavi
          cargando={state.cargandoTable}
          headers={["Nombre", "Fecha de reparación digna"]}
          body={listPersonasRD?.map((ps) => (
            <tr
              key={`${ps.caso_persona?.persona.primer_nombre}-${ps.caso_persona?.persona.primer_apellido}`}
              className="hover:bg-maivi-gray-5 cursor-pointer"
            >
              <td className={tdClass}>
                {ps.caso_persona?.persona.primer_nombre}{" "}
                {ps.caso_persona?.persona.segundo_nombre}{" "}
                {ps.caso_persona?.persona.primer_apellido}{" "}
                {ps.caso_persona?.persona.segundo_apellido}
              </td>
              <td className={tdClass}>
                {moment(ps?.reparacion_digna?.fecha).format("DD-MM-YYYY")}
              </td>
            </tr>
          ))}
        />
      </div>
    </div>
<div className="mt-2 flex flex-row justify-between">
        <span></span>
        <div className="w-32">
          <ButtonSiaviCancelar
            className="mr-1"
            text="Salir"
            onClick={() => {
              showModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
