/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
    getProfesionales,
    getInstitucionGestion
} from "./services";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {

    let filtros = "";
    const [state, setState] = useState({
        profesional: "",
        gestion_institucion: "",
        completada: "",
        fecha_desde: "",
        fecha_hasta: ""
    });

    const [profesionales, setProfesionales] = useState([]);
    const [instituciones, setInstituciones] = useState([]);

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        getProfesionales(props.token).then((data) => {
            setProfesionales(data);
        });
    }, []);

    useEffect(() => {
        getInstitucionGestion(props.token).then((data) => {
            setInstituciones(data);
        });
    }, []);

    return (
        <div className="max-w-7xl px-4 sm:px-6 md:px-6">
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    name="profesional"
                    label="Profesional asignado"
                    value={state.profesional}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir profesional asignado"
                    list={
                        profesionales?.results?.map((item, index) => {
                            return <option key={item?.first_name + index} value={item.id}>{item?.first_name} {item?.last_name}</option>
                        })
                    }
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_desde"
                    value={state.fecha_desde}
                    onChange={onChange}
                    label="Fecha Planificada inicio"
                />
            </div>
            <div className="col-span-12 mt-4">
                <InputSiavi
                    type="date"
                    name="fecha_hasta"
                    value={state.fecha_hasta}
                    onChange={onChange}
                    label="Fecha Planificada fin"
                />
            </div>
            <div className="col-span-12 mt-4">
                <SelectSiavi
                    label="Órgano Jurisdiccional"
                    name="gestion_institucion"
                    value={state.gestion_institucion}
                    onChange={onChange}
                    initValue=""
                    selectInit="Elegir órgano jurisdiccional"
                    className="mb-3"
                    list={
                        instituciones?.results?.map((item, index) => {
                            return <option key={item?.institucion?.nombre + index} value={item.institucion.id}>{item?.institucion?.nombre}</option>
                        })
                    }
                />
            </div>
            <div className="mt-1">
                <SelectSiavi
                    label="Completada"
                    name="completada"
                    value={state.completada}
                    onChange={onChange}
                    valueInit=""
                    selectInit="Elegir"
                    list={
                        [
                            <option value="true">Sí</option>,
                            <option value="false">No</option>
                        ]
                    }
                />
            </div>

            <div className="col-span-12 mt-4">
                <ButtonSiavi
                    text="Buscar"
                    onClick={() => {
                        filtros = `gestion_institucion=${state.gestion_institucion}&completada=${state.completada}&profesional_asignado=${state.profesional}&fecha_hora_planificada_inicia__gte=${state.fecha_desde}&fecha_hora_planificada_inicia__lte=${state.fecha_hasta}`;
                        props.setFilters(filtros);
                        props.setOpenFilters(false);
                    }}
                />
            </div>
            {props.isloading === true ? <Spinner /> : ""}
        </div>
    );
}