import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  postExpReparacionDigna,
  postExpReparacionDignaDetalle,
  getReparacionesDignas,
  getExpedienteReparacionDigna,
  getExpReparacionDignaDetalle,
  deleteExpReparacionDigna,
  putExpReparacionDignaDetalle,
  getResolucionOtorgaRD,
  getProfesionales,
} from "./services";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/solid";
import { Tab } from "@headlessui/react";
import { CasoPersonaRD } from "./casoPersonaRD";
import { SindicadosRD } from "./sindicadosRD";
import { DetalleRD } from "./detalleRD";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Spinner from "../../../../../components/common/Spinner";
import Divider from "../../../../../components/common/Divider";
import Alert from "../../../../../components/common/Alert";
import toast from "react-hot-toast";
import moment from "moment";
import { ButtonSiaviCancelar } from "../../../../../components/common/ButtonCancelar";

const validaciones = Yup.object().shape({
  reparacion_digna: Yup.number().required("reparación digna es requerida"),
});

export const ReparacionDigna = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idUser = useSelector((state) => state?.loginReducer?.data?.usuario?.id);
  const permisosRegistro = useSelector((state) =>
    state.ChangePermisos?.menus?.registros?.menus
      ? state.ChangePermisos?.menus?.registros?.menus
      : []
  );
  const idExpedienteProceso = props?.registro?.id;
  const idExpediente = props?.registro?.expediente?.id;
  const numero_expediente = props?.registro?.expediente?.numero_expediente;
  const idCaso = props?.registro?.caso;
  const [cargando, setCargando] = useState(true);
  const [cargandoDetalle, setCargandoDetalle] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [detalleReparaciones, setDetalleReparaciones] = useState([]);
  const [reparaciones, setReparaciones] = useState([]);
  const [expReparaciones, setExpReparaciones] = useState([]);
  const [idReparacionDigna, setIdReparacionDigna] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [crearReparacion, setCrearReparacion] = useState(false);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [mostrarMonto, setMostrarMonto] = useState(true);
  const [catalogoResolucionesRD, setCatalogoResolucionesRD] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const [idProfesional, setIdProfesional] = useState(null);
  const [idResolucion, setIdResolucion] = useState(null);
  const animatedComponents = makeAnimated();
  const optionsProfesionales = [];

  const tdClass = "px-1 row-td text-maivi-primaryFont text-xs";
  const permisoView = "ExpedientesAL"; //mm permiso padre

  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach((menu) => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach((op) => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        });
      }
    });
    return retorno;
  };

  useEffect(() => {
    getReparacionesDignas(token).then((data) => {
      setReparaciones(data);
    });
  }, [token]);

  useEffect(() => {
    getResolucionOtorgaRD(token).then((data) => {
      setCatalogoResolucionesRD(data?.results);
    });
  }, []);

  useEffect(() => {
    getProfesionales(token).then((data) => {
      setProfesionales(data?.results);
    });
  }, []);

  useEffect(() => {
    getExpedienteReparacionDigna(idCaso, token).then((data) => {
      setExpReparaciones(data);
      setCargando(false);
    });
  }, [token, idCaso, setCargando, refetch]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const menusReparacionDigna = ["General", "Víctimas", "Sindicados", "Detalle"];

  profesionales?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name}`,
    };

    optionsProfesionales.push(reg);
  });

  const changeSelectProfesionales = (e) => {
    setIdProfesional(e.value);
  };

  return (
    <div>
      <Formik
        initialValues={{
          monto: "",
          fecha: "",
          especificaciones: "",
          reparacion_digna: "",
          idRepacionDignaDetalle: 0,
          rep_digna_externa: false,
          rep_digna_previa: false,
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (isEdit) {
            await putExpReparacionDignaDetalle(
              values,
              idCaso,
              idReparacionDigna,
              token
            )
              .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                  getExpReparacionDignaDetalle(idReparacionDigna, token).then(
                    (data) => {
                      setDetalleReparaciones(data?.results);
                      resetForm({});
                    }
                  );
                  toast.success("Registro actualizado con éxito..!");
                  setRefetch(true);
                  setRefetch(false);
                  setNuevoRegistro(false);
                  setEdit(false);
                }
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
          } else {
            await postExpReparacionDignaDetalle(
              values,
              idCaso,
              idReparacionDigna,
              token
            )
              .then(async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  getExpReparacionDignaDetalle(idReparacionDigna, token).then(
                    (data) => {
                      setDetalleReparaciones(data?.results);
                      resetForm({});
                    }
                  );
                  toast.success("Registro creado con éxito..!");
                  resetForm({});
                  setNuevoRegistro(false);
                  setRefetch(true);
                  setRefetch(false);
                }
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12">
                <Tab.Group>
                  <Tab.List className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 bg-blue-50 rounded-md p-1 ">
                    {menusReparacionDigna.map((item) => {
                      return (
                        <Tab
                          key={item}
                          className={({ selected }) =>
                            classNames(
                              "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                              "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                              selected
                                ? "bg-white shadow"
                                : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                            )
                          }
                          onClick={() => {
                            if (item === "General") {
                              props.setFullWidth(false);
                            } else if (item === "Víctimas") {
                              props.setFullWidth(false);
                            } else if (item === "Sindicados") {
                              props.setFullWidth(false);
                            } else {
                              props.setFullWidth(true);
                            }
                          }}
                        >
                          {item}
                        </Tab>
                      );
                    })}
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-12 border p-3 rounded-md hover:shadow-sm mt-1">
                          <div className="flex flex-row justify-between items-end">
                          <h1 className="mb-1 text-maivi-primaryFont text-sm">
                            Agregar reparación digna
                          </h1>
                          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 mb-2">
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
                                {numero_expediente}
                              </span>
                            </div>
                          </div>
                          <Divider />
                          <div className="grid grid-cols-12 gap-3 mb-2 items-end">
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                              <InputSiavi
                                type="date"
                                name="fecha"
                                value={values.fecha}
                                onChange={handleChange}
                                validacion={true}
                                maximo={moment().format("YYYY-MM-DD")}
                                label="Fecha"
                              />
                            </div>
                            {/* <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                              <SelectSiavi
                                label="Resolución Otorga R.D."
                                valueInit=""
                                selectInit="Seleccionar"
                                onChange={(e) => {
                                  setIdResolucion(e.target.value);
                                }}
                                list={catalogoResolucionesRD?.map((item) => (
                                  <option value={item.id} key={item.nombre}>
                                    {item?.nombre}
                                  </option>
                                ))}
                              />
                            </div> */}
                            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4">
                              <label className="font-normal text-maivi-primaryFont text-sm">
                                Profesional
                              </label>
                              <Select
                                placeholder="Seleccionar"
                                components={animatedComponents}
                                name="profesionales"
                                className="basic-multi-select text-xs z-50 -py-2 text-maivi-primaryFont"
                                options={optionsProfesionales}
                                onChange={changeSelectProfesionales}
                                maxMenuHeight={150}
                              />
                            </div>
                            <div className="col-span-12 xl:col-span-1 lg:col-span-2 md:col-span-3">
                            {CheckPermiso("GrabarRepDigna") === true && (
                                <ButtonSiavi
                                  type="button"
                                  disabled={isSubmitting}
                                  text="Crear"
                                  onClick={async () => {
                                    if (values.fecha !== "") {
                                      setCargando(true);
                                      await postExpReparacionDigna(
                                        values,
                                        idCaso,
                                        idExpediente,
                                        idExpedienteProceso,
                                        idResolucion,
                                        idProfesional,
                                        token
                                      )
                                        .then(async (data) => {
                                          if (
                                            data?.status === 200 ||
                                            data?.status === 201
                                          ) {
                                            toast.success(
                                              "Reparación digna creada con éxito..!"
                                            );
                                            setRefetch(true);
                                            setRefetch(false);
                                            setCargando(false);
                                          }
                                        })
                                        .catch(function (error) {
                                          if (
                                            error.response.status >= 400 &&
                                            error.response.status <= 450
                                          ) {
                                            setCargando(false);
                                            toast.error(
                                              error.response.data.message
                                            );
                                          } else if (
                                            error.response.status >= 500
                                          ) {
                                            toast.error(
                                              "Error interno comuniquese con el administrador"
                                            );
                                          }
                                        });
                                    } else {
                                      setCrearReparacion(true);
                                      setTimeout(() => {
                                        setCrearReparacion(false);
                                      }, 4000);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {crearReparacion && (
                            <div className="mb-1">
                              <Alert
                                type="error"
                                mensaje="Fecha es requerida"
                              />
                            </div>
                          )}
                          <TableSiavi
                            cargando={cargando}
                            headers={["ID","Expediente", "Profesional", "Fecha", "Acciones"]}
                            body={expReparaciones?.results?.map((item) => (
                              <tr
                                key={item.fecha}
                                className="hover:bg-maivi-gray-5 cursor-pointer"
                              >
                                 <td className={tdClass}>
                                  {item?.id}
                                </td>
                                <td className={tdClass}>
                                  {item?.expediente?.numero_expediente}
                                </td>
                                <td className={tdClass}>
                                  {item?.profesional?.first_name} {item?.profesional?.last_name}
                                </td>
                                {/* <td className={tdClass}>
                                  {item?.resolucion_otorga_rd?.nombre}
                                </td> */}
                                <td className={tdClass}>
                                  {moment(item?.fecha).format("DD-MM-YYYY")}
                                </td>
                                <td className={tdClass}>
                                  {CheckPermiso("EliminarRepDigna") ===
                                    true && (
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        setCargando(true);
                                        await deleteExpReparacionDigna(
                                          item.id,
                                          token
                                        )
                                          .then((data) => {
                                            if (
                                              data?.status === 200 ||
                                              data?.status === 201
                                            ) {
                                              setCargando(false);
                                              toast.success(
                                                "Registro eliminado con éxito...!"
                                              );
                                              setRefetch(true);
                                              setRefetch(false);
                                            } else {
                                              setRefetch(true);
                                              setRefetch(false);
                                              toast.success(
                                                "Registro eliminado con éxito...!"
                                              );
                                            }
                                          })
                                          .catch(function (error) {
                                            if (
                                              error.response.status === 400 ||
                                              error.response.status === 401
                                            ) {
                                              setCargando(false);
                                              toast.error(
                                                error.response.data.message
                                              );
                                            } else if (
                                              error.response.status >= 500
                                            ) {
                                              toast.error(
                                                "Error interno comuniquese con el administrador"
                                              );
                                            }
                                          });
                                      }}
                                      className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                          rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                    >
                                      <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row items-end justify-between">
                        <span></span>
                        <div className="w-32">
                        <ButtonSiaviCancelar
                            className="mr-1"
                            text="Salir"
                            onClick={() => {
                              props.showModal(false)
                            }}
                          />
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <CasoPersonaRD
                        token={token}
                        registro={props.registro}
                        caso={props.caso}
                        reparacionesDignas={expReparaciones?.results}
                        usuario={idUser}
                        showModal={props.showModal}
                      />
                    </Tab.Panel>
                    <Tab.Panel>
                      <SindicadosRD
                        token={token}
                        registro={props.registro}
                        caso={props.caso}
                        reparacionesDignas={expReparaciones?.results}
                        usuario={idUser}
                        showModal={props.showModal}
                      />
                    </Tab.Panel>
                    <Tab.Panel>
                      <DetalleRD
                        token={token}
                        registro={props.registro}
                        caso={props.caso}
                        reparacionesDignas={expReparaciones?.results}
                        usuario={idUser}
                        showModal={props.showModal}
                      />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
