 const Contenedor = (props) => {
    return (
      <div className="min-h-screen bg-maivi-gray-5 lg:pt-2 sm:pt-0 pb-10 mb-50 overflow-y-auto">
        <main className="overflow-hidden mb-5">
            <div className="absolute w-full  bg-warm-gray-50"/>
            <div className="mx-auto px-0 sm:px-2 lg:px-2">
              <div className="relative bg-white shadow-xl">
                  <div className="py-4 px-6 sm:px-2 lg:col-span-2 xl:p-8">
                  <h1 className="inline text-2xl font-extrabold tracking-tight text-maivi-primaryLightBlue ">{props.title}</h1>
                    {props.children}
                  </div>
              </div>
            </div>
        </main>
      </div>
    )
  }
  

  export default Contenedor;