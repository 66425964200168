import {
    types
} from "../types/types";
export const ChangePermisos = (state = {
    menus: []
}, action) => {
    switch (action.type) {
        case types.Permisos:
            return {
                menus: action.payload
            }
            default:
                return state;
    }
}