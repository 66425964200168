/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { SelectSiavi } from "../../../../components/common/Select";
import { InputSiavi } from "../../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../components/common/Button";
import { DatosDemograficos } from "./datos_demograficos";
import {
    postPersona,
    getNacionalidades,
    getPaises,
    getTipoDocumentos,
    buscarPersona,
    putPersona
} from "./services";
import Alert from "../../../../components/common/Alert";
import BotonesConfirmar from "../../../../components/common/ButtonsConfirmacion";
import ModalGenerico from "../../../../components/common/Modal";
import Toggle from "../../../../components/common/Toggle";
import Label from "../../../../components/common/Label";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    //noDocumento: Yup.string().max(20, 'máximo 20 caracteres'),
    nacionalidad: Yup.string().required("nacionalidad es requerida"),
    pais: Yup.string()
        .required("pais es requerido"),
    primerNombre: Yup.string()
        .required("primer nombre es requerido")
        .max(30, "máximo 30 caracteres"),
    segundoNombre: Yup.string()
        .max(30, "máximo 30 caracteres"),
    primerApellido: Yup.string()
        .required("primer apellido es requerido")
        .max(30, "máximo 30 caracteres"),
    apellidoCasada: Yup.string().max(30, 'máximo 30 caracteres'),
    fechaNacimiento: Yup.string().required("fecha nacimiento es requerida"),
    sexo: Yup.string().required("sexo es requerido"),
    //alias: Yup.string().required("este campo es requerido").max(30, 'máximo 30 caracteres')
});

const handleChangeInput = (values) => {
    let fechaNac = new Date(values.fechaNacimiento);
    let yearNacimiento = fechaNac.getFullYear();
    let mesNacimiento = fechaNac.getMonth();
    const d = new Date();
    let yearActual = d.getFullYear();
    let edad = yearActual - yearNacimiento;
    let m = d.getMonth() - mesNacimiento;
  
    if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
      edad--;
    }
  
    if (values.fechaNacimiento) {
      values.edad = edad;
    }
  };

export const AddPersona = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const sede = useSelector((state) => state.ChangeSede.sedeId);
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [paises, setPaises] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);
    const [personaExiste, setPersonaExiste] = useState(false);
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [state, setState] = useState({
        pais: "",
        tipoDocumento: "",
        noDocumento: "",
    });

    useEffect(() => {
        getTipoDocumentos(token).then((data) => {
            setTipoDocumentos(data);
        });
    }, []);

    useEffect(() => {
        getPaises(token).then((data) => {
            setPaises(data);
        });
    }, []);

    useEffect(() => {
        getNacionalidades(token).then((data) => {
            setNacionalidades(data);
        });
    }, []);


    function handleInputChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function busqueda() {
        if (
            state.pais !== "" &&
            state.noDocumento !== "" &&
            state.tipoDocumento !== ""
        ) {
            buscarPersona(
                state.pais,
                state.tipoDocumento,
                state.noDocumento,
                token
            ).then((data) => {
                if (data.count > 0) {
                    setPersonaExiste(true);
                    props.setPersona(data?.results[0]);
                }
            });
        }
    }

    return (
        <Formik
            initialValues={{
                tipoDocumento: props.editar ? props?.registro?.tipo_documento : "",
                noDocumento: props.editar ? props?.registro?.no_documento : "",
                pais: props.editar ? props?.registro?.pais : "",
                primeraVisita: true,
                sin_documento: props.editar ? props?.registro?.sin_documento : false,
                primerNombre: props.editar ? props?.registro?.primer_nombre : "",
                segundoNombre: props.editar ? props?.registro?.segundo_nombre : "",
                tercerNombre: props.editar ? props?.registro?.otros_nombres : "",
                primerApellido: props.editar ? props?.registro?.primer_apellido : "",
                segundoApellido: props.editar ? props?.registro?.segundo_apellido : "",
                apellidoCasada: props.editar ? props?.registro?.apellido_casada : "",
                fechaNacimiento: props.editar ? props?.registro?.fecha_nacimiento : "",
                edad: 0,
                sexo: props.editar ? props?.registro?.sexo : "",
                nacionalidad: props.editar ? props?.registro?.nacionalidad : "",
                alias: props.editar ? props?.registro?.como_le_gusta_que_le_llamen : "",
                sede: sede,

                estado_civil: props.editar ? props?.registro?.estado_civil : "",
                idioma: props.editar ? props?.registro?.idioma : "",
                religion: props.editar ? props?.registro?.religion : "",
                escolaridad:props.editar ? props?.registro?.escolaridad : "",
                profesion: props.editar ? props?.registro?.profesion : "",
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                if(props.editar){
                    await putPersona(props.registro.id, values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje("Registro actualizado éxitosamente");
                            props?.setRefetch(true);
                            props?.setRefetch(false);
                            setTimeout(() => {
                                props?.setNuevaPersona(false);
                            }, 1000);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            setMensaje("Error interno comuniquese con el administrador");
                        }
                    });
                } else {
                    await postPersona(values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje("Registro creado éxitosamente");
                            props?.setRefetch(true);
                            props?.setRefetch(false);
                            props?.setPersona(data.data);
                            setTimeout(() => {
                                props.setNuevaPersona(false);
                            }, 1200);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 400 || error.response.status === 401) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            setMensaje("Error interno comuniquese con el administrador");
                        }
                    });
                }
            }}
        >
            {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                <div className="border p-5 rounded-lg" onChange={handleChangeInput(values)}>
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12">
                            <Label text="Sin Documento" />
                            <Toggle enabled={values.sin_documento} name="sin_documento" />
                        </div>
                        {!values.sin_documento && (
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
                                <SelectSiavi
                                    label="Tipo de documento"
                                    name="tipoDocumento"
                                    value={values.tipoDocumento}
                                    valueInit=""
                                    selectInit="Seleccionar"
                                    onChange={(e) => {
                                        setFieldValue("tipoDocumento", e.target.value);
                                        handleInputChange(e);
                                    }}
                                    onBlur={() => {
                                        busqueda();
                                    }}
                                    list={
                                        tipoDocumentos?.data?.results?.map((item) => {
                                            return (
                                                <option
                                                    key={item.nombre}
                                                    value={item.id}
                                                    className="text-sm"
                                                >
                                                    {item.nombre}
                                                </option>
                                            );
                                        })
                                    }
                                />
                            </div>)}
                        {!values.sin_documento && (
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="noDocumento"
                                    onChange={(e) => {
                                        setFieldValue("noDocumento", e.target.value);
                                        handleInputChange(e);
                                    }}
                                    onBlur={() => {
                                        busqueda();
                                    }}
                                    value={values.noDocumento}
                                    label="No. documento"
                                />
                            </div>)}
                        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6">
                            <SelectSiavi
                                label="Pais"
                                validacion={true}
                                name="pais"
                                value={values.pais}
                                valueInit=""
                                selectInit="Seleccionar"
                                onChange={(e) => {
                                    setFieldValue("pais", e.target.value);
                                    handleInputChange(e);
                                }}
                                onBlur={() => {
                                    busqueda();
                                }}
                                list={
                                    paises?.data?.results?.map((item) => {
                                        return (
                                            <option
                                                key={item.nombre}
                                                value={item.id}
                                                className="text-sm"
                                            >
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                    </div>
                    <h1 className="mt-5 mb-1 text-base">Datos generales</h1>
                    <Divider />
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="text"
                                name="primerNombre"
                                value={values.primerNombre}
                                onChange={handleChange}
                                validacion={true}
                                label="Primer nombre"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="text"
                                name="segundoNombre"
                                value={values.segundoNombre}
                                onChange={handleChange}
                                validacion={true}
                                label="Segundo nombre"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="text"
                                name="tercerNombre"
                                value={values.tercerNombre}
                                onChange={handleChange}
                                validacion={true}
                                label="Otros nombres"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                name="primerApellido"
                                type="text"
                                value={values.primerApellido}
                                onChange={handleChange}
                                validacion={true}
                                label="Primer apellido"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                name="segundoApellido"
                                type="text"
                                value={values.segundoApellido}
                                onChange={handleChange}
                                validacion={true}
                                label="Segundo apellido"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="text"
                                name="apellidoCasada"
                                value={values.apellidoCasada}
                                onChange={handleChange}
                                validacion={true}
                                label="Apellido de casada"
                            />
                        </div>

                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="date"
                                name="fechaNacimiento"
                                value={values.fechaNacimiento}
                                onChange={handleChange}
                                validacion={true}
                                label="fecha nacimiento"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <InputSiavi
                                type="number"
                                name="edad"
                                value={values.edad}
                                onChange={handleChange}
                                validacion={true}
                                label="Edad"
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <SelectSiavi
                                label="Sexo"
                                validacion={true}
                                name="sexo"
                                value={values.sexo}
                                onChange={handleChange}
                                valueInit=""
                                selectInit="Seleccionar"
                                list={
                                    [<option value="HOMBRE">Hombre</option>,
                                    <option value="MUJER">Mujer</option>]
                                }
                            />
                        </div>
                        <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6">
                            <SelectSiavi
                                label="Nacionalidad"
                                validacion={true}
                                name="nacionalidad"
                                value={values.nacionalidad}
                                onChange={handleChange}
                                valueInit=""
                                selectInit="Seleccionar"
                                list={
                                    nacionalidades?.data?.results?.map((item) => {
                                        return (
                                            <option
                                                key={item.nombre}
                                                value={item.id}
                                                className="text-sm"
                                            >
                                                {item.nombre}
                                            </option>
                                        );
                                    })
                                }
                            />
                        </div>
                    </div>
                    <h1 className="mt-5 mb-1 text-base">Datos demograficos</h1>
                    <Divider />
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 ">
                           <DatosDemograficos values={values} handleChange={handleChange} registro={props.registro} editar={props.editar}/>
                        </div>
                        <div className="col-span-12">
                            <Divider />
                        </div>
                        <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
                            <ButtonSiavi
                                type="button"
                                disabled={isSubmitting}
                                text="Guardar"
                                onClick={() => {
                                    handleSubmit();
                                }}
                            />
                            <ButtonSiavi
                                type="button"
                                className="mx-1"
                                disabled={isSubmitting}
                                text="Regresar"
                                onClick={() => {
                                    props.setNuevaPersona(false);
                                }}
                            />
                        </div>
                        <div className="sm:col-span-10 col-span-12">
                            <div className="col-span-12">
                                {isSubmitting && <Spinner />}
                                {hasError && <Alert type="error" mensaje={mensaje} />}
                                {success && <Alert type="success" mensaje={mensaje} />}
                            </div>
                        </div>
                    </div>
                    {personaExiste && (
                        <ModalGenerico
                            title={`Ya existe una persona registrada con esos datos, ¿desea agregarla a la referencia?`}
                            open={personaExiste}
                            closeModal={() => { }}
                            cerrar={() => {
                                setPersonaExiste(false);
                            }}
                            children={
                                <BotonesConfirmar
                                    aceptar={() => {
                                        props.setNuevaPersona(false);
                                    }}
                                    cancelar={() => {
                                        setPersonaExiste(false);
                                        resetForm({});
                                    }}
                                />
                            }
                        />
                    )}
                </div>
            )}
        </Formik>
    );
};
