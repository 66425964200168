import axios from "axios";
const {
    REACT_APP_API_URL
} = process.env;

export const getServicioInstituto = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/serviciopropio/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSubAreaSustantiva = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/subarea/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postServicioInstituto = async ({nombre, descripcion, sub_area, estado,asitencia_legal,atencion_victimologica,codigo_expediente,litigio_estrategico,impugnaciones,ts,ps,md,pq,modifica_solo_profesional_asignado,tiene_seguimiento_legal},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            sub_area,
            estado,
            asitencia_legal,
            atencion_victimologica,
            codigo_expediente,
            litigio_estrategico,
            impugnaciones,
            ts,
            ps,
            md,
            pq,
            modifica_solo_profesional_asignado,
            tiene_seguimiento_legal
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putServicioInstituto = async (id, {nombre,descripcion,sub_area,estado,asitencia_legal,atencion_victimologica,codigo_expediente,litigio_estrategico,impugnaciones,ts,ps,md,pq,modifica_solo_profesional_asignado,tiene_seguimiento_legal},token) => {
    try {
        let body = {
            nombre,
            descripcion,
            sub_area,
            estado,
            asitencia_legal,
            atencion_victimologica,
            codigo_expediente,
            litigio_estrategico,
            impugnaciones,
            ts,
            ps,
            md,
            pq,
            modifica_solo_profesional_asignado,
            tiene_seguimiento_legal
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteServicioInstituto = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoProcesos = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/tipoproceso/?page_size=1000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
};


export const getProcesos = async (idServicio,token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/catalogos/serviciopropiotipoproceso/?page_size=1000&servicio_interno=${idServicio}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteProceso = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/serviciopropiotipoproceso/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postAddTipoProceso = async (servicio_interno, tipo_proceso,token) => {
  try {
    let body = {
        servicio_interno: servicio_interno,
        tipo_proceso: tipo_proceso
    }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/catalogos/serviciopropiotipoproceso/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};