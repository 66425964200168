/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
  getModalidades,
  getAccionesSeguimiento,
  //getProfesionales 
} from "./services";
import Spinner from "../../../components/common/Spinner";

export default function Filtros(props) {
  let filtros = "";
  const [modalidades, setModalidades] = useState([]);
  const [accionesSeguimiento, setAccionesSeguimiento] = useState([]);
  //const [profesionales, setProfesionales] = useState([]);

  const [state, setState] = useState({
    profesional: "",
    fecha_desde: "",
    fecha_hasta: "",
    accion_seguimiento: "",
    modalidad: ""
  });

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  // useEffect(() => {
  //   getProfesionales(props.token).then((data) => {
  //     setProfesionales(data);
  //   });
  // }, []);

  useEffect(() => {
    getModalidades(props.token).then((data) => {
      setModalidades(data);
    })
  }, []);

  useEffect(() => {
    getAccionesSeguimiento(props.token).then((data) => {
      setAccionesSeguimiento(data);
    })
  }, [])

  return (
    <div className="mx-4">
      {/* <div className="col-span-12">
        <SelectSiavi
          name="profesional"
          label="Profesional asignado"
          value={state.profesional}
          onChange={onChange}
          initValue=""
          selectInit="Elegir profesional asignado"
          list={
            profesionales?.results?.map((item, index) => {
              return <option key={item?.first_name + index} value={item.id}>{item?.first_name} {item?.last_name}</option>
            })
          }
        />
      </div> */}
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="accion_seguimiento"
          label="Tipo acción seguimiento"
          value={state.accion_seguimiento}
          onChange={onChange}
          initValue=""
          selectInit="Elegir acción seguimiento"
          list={
            accionesSeguimiento?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <SelectSiavi
          name="modalidad"
          label="Modalidad"
          value={state.modalidad}
          onChange={onChange}
          initValue=""
          selectInit="Elegir modalidad"
          list={
            modalidades?.results?.map((item, index) => {
              return <option key={item.nombre + index} value={item.id}>{item.nombre}</option>
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha fin"
        />
      </div>
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `&${state.profesional === "" ? "" : `profesional_asignado=${state.profesional}`} &${state.modalidad === "" ? "" : `modalidad=${state.modalidad}`}&${state.accion_seguimiento === "" ? "" : `accion_seguimiento=${state.accion_seguimiento}`}&${state.fecha_desde === "" ? "" : `fecha_hora_planificada_inicia__gte=${state.fecha_desde}`}&${state.fecha_hasta === "" ? "" : `fecha_hora_planificada_fin__lte=${state.fecha_hasta}`}`;
            props.setUrlFech(undefined);
            props.setFiltros(filtros);
            props.setFiltrar(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
