export const Resumeaudienciasreasignadas = [
  { name: 'id', value: 'id' },
  { name: 'Accion planificada', value: 'accion_planificada' },
  { name: 'Accion seguimiento', value: 'accion_seguimiento' },
  { name: 'Accion servicio relacion', value: 'accion_servicio_relacion' },
  { name: 'Accion servicio solicitado', value: 'accion_servicio_solicitado' },
  { name: 'Asignar accion', value: 'asignar_accion' },
  { name: 'Autorizado', value: 'autorizado' },
  { name: 'Caso', value: 'caso' },
  { name: 'Causa penal', value: 'causa_penal' },
  { name: 'Completada', value: 'completada' },
  { name: 'Departamento', value: 'departamento' },
  { name: 'Detalle lugar', value: 'detalle_lugar' },
  { name: 'Es accion', value: 'es_accion' },
  { name: 'Es audiencia', value: 'es_audiencia' },
  { name: 'Es nna', value: 'es_nna' },
  { name: 'Es seguimiento', value: 'es_seguimiento' },
  { name: 'Especificaciones modalidad', value: 'especificaciones_modalidad' },
  { name: 'Estado', value: 'estado' },
  { name: 'expediente', value: 'expediente' },
  { name: 'Fecha accion', value: 'fecha_accion' },
  { name: 'Fecha asigna', value: 'fecha_asigna' },
  { name: 'Fecha autoriza', value: 'fecha_autoriza' },
  { name: 'Fecha completado', value: 'fecha_completado' },
  { name: 'Fecha creacion', value: 'fecha_creacion' },
  { name: 'Fecha hora fin', value: 'fecha_hora_fin' },
  { name: 'Fecha hora inicia', value: 'fecha_hora_inicia' },
  { name: 'Fecha hora planificada_fin', value: 'fecha_hora_planificada_fin' },
  { name: 'Fecha hora planificada_inicia', value: 'fecha_hora_planificada_inicia' },
  { name: 'Fecha requerido', value: 'fecha_requerido' },
  { name: 'Gestion institucion', value: 'gestion_institucion' },
  { name: 'Gestionado', value: 'gestionado' },
  { name: 'Gestionar', value: 'gestionar' },
  { name: 'Habilita planificacion', value: 'habilita_planificacion' },
  { name: 'institucion', value: 'institucion' },
  { name: 'modalidad', value: 'modalidad' },
  { name: 'motivo_cambio_fecha', value: 'motivo_cambio_fecha' },
  { name: 'municipio', value: 'municipio' },
  { name: 'nombramiento', value: 'nombramiento' },
  { name: 'observaciones', value: 'observaciones' },
  { name: 'observaciones_gestiona', value: 'observaciones_gestiona' },
  { name: 'orientacion', value: 'orientacion' },
  { name: 'persona', value: 'persona' },
  { name: 'Profesional asigna', value: 'profesional_asigna' },
  { name: 'Profesional asignado', value: 'profesional_asignado' },
  { name: 'Profesional autoriza', value: 'profesional_autoriza' },
  { name: 'Profesional crea', value: 'profesional_crea' },
  { name: 'Profesional realiza', value: 'profesional_realiza' },
  { name: 'Querellante', value: 'querellante' },
  { name: 'Recurso', value: 'recurso' },
  { name: 'Recurso disponibilidad', value: 'recurso_disponibilidad' },
  { name: 'Referencia', value: 'referencia' },
  { name: 'Requiere asignacion', value: 'requiere_asignacion' },
  { name: 'Requiere autorizacion', value: 'requiere_autorizacion' },
  { name: 'Requiere nombramiento', value: 'requiere_nombramiento' },
  { name: 'Requiere recurso', value: 'requiere_recurso' },
  { name: 'Resultado audiencia', value: 'resultado_audiencia' },
  { name: 'Sede accion', value: 'sede_accion' },
  { name: 'Seguimiento', value: 'seguimiento' },
  { name: 'Tipo', value: 'tipo' },
  { name: 'Tipo audiencia', value: 'tipo_audiencia' },
  { name: 'Victima', value: 'victima' }
];

export const Resumeaudienciacambiofecha =
[
  { name: 'Audiencia', value: 'audiencia' },
  { name: 'Total', value: 'total' }
];

export const Resumeaudienciarealizadasnoprogramadas =
[
  { name: 'Audiencia', value: 'audiencia' },
  { name: 'Total', value: 'total' }
];


export const Resumeaudienciaresultadoaudiencia =
[
  { name: 'Audiencia', value: 'audiencia' },
  { name: 'Etapa Procesal', value: 'etapa_procesal' },
  { name: 'Resultado Audiencia', value: 'resultado_audiencia' },
  { name: 'Tipo Proceso', value: 'tipo_proceso' },
  { name: 'Total', value: 'total' }
];


export const Resumeaudienciatipoproceso =
[
  { name: 'Audiencia', value: 'audiencia' },
  { name: 'Etapa Procesal', value: 'etapa_procesal' },
  { name: 'Tipo Proceso', value: 'tipo_proceso' },
  { name: 'Total', value: 'total' }
];
