/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { ScaleIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./detalle";
import { diligenciasUrgentes, getDiligenciasUrgentes } from "./services";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import CustomTable from "../../../../components/common/Table";

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { title: "Seguimiento", value: "seguimiento", tipo: "text" },
    { title: "Tipo Diligencia", value: "tipo_diligencia.nombre", tipo: "text" },
    { title: "No. Documento", value: "numero_documento", tipo: "text" },
    { title: "Plazo", value: "plazo", tipo: "text" },
    { title: "Fecha Enterado", value: "fecha_enterado", tipo: "date" },
    {
        title: "Urgente",
        value: "urgente",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
        ordenar: "urgente"
    }
];

export const TableNotificacionesLegales = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const sede = useSelector((state) => state.ChangeSede.sedeId);
    const profesional = useSelector((state) => state.loginReducer?.data?.usuario?.user?.id);
    const permiso = "expedientediligenciasurgentes";
    const [verDetalle, setDetalle] = useState(false);
    const [notificacionesDetalle, setNotificacionesDetalle] = useState({});
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");

    const fetchDiligenciasUrgentes = () => {
        setTableLoading(true);
        diligenciasUrgentes(urlFetch, sede, profesional, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    };

    const fetchGetDiligenciasUrgentes = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setTableLoading(true);
            console.log(urlFetch, search, sede, profesional, order, token)
            getDiligenciasUrgentes(urlFetch, search, sede, profesional, order, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    useEffect(fetchDiligenciasUrgentes, [urlFetch, token, sede, profesional]);
    useEffect(fetchGetDiligenciasUrgentes, [urlFetch, search, sede, profesional, order, token]);

    const handleVer = (item) => {
        setNotificacionesDetalle(item);
        setDetalle(true);
    };

    const pages = [
        {
            name: "Mis registros",
            href: "/mis-registros",
            current: false,
        },
        { name: "Notificaciones Legales", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
                <BreadCrumb navegacion={pages} />
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={true}
                        showEditar={false}
                        showEliminar={false}
                        setOrder={setOrder}
                        order={order}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de Notificación Legal"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <FormDetalle detalle={notificacionesDetalle} />
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
