import { React, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { InputSiavi } from "../../../../../components/common/InputText";
import { PaperClipIcon, TrashIcon } from "@heroicons/react/outline";
import { GestionArchivos } from "../../../../../components/ArchivosSiavi/archivos";
import {
  getPrimerContacto,
  getReparacionseDignas,
  postPrimerContacto,
  deletePrimerContacto,
} from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../../../components/common/Label";
import TableSiavi from "../../../../../components/common/TableSiavi";
import ModalAmplio from "../../../../../components/common/ModalAmplio";
import toast from "react-hot-toast";
import moment from "moment";
import ModalGenerico from "../../../../../components/common/Modal";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  fecha: Yup.string().required("fecha es requerida"),
});

const inactivarButton = `hover:bg-red-300 inline-flex items-center p-1 border border-transparent 
rounded-md text-maivi-gray-50 hover:text-white  focus:ring-red-500 tooltip`;
const classAcciones = `p-1 tooltip hover:bg-gray-200 rounded-md`;

export const PrimerContacto = ({ token, idCaso, idCasoPersona }) => {
  const selectRef = useRef(null);
  const [state, setState] = useState({
    cargandoListado: false,
    listadoPrimerContacto: [],
    confirmarBorrado: false,
    registro: {},
  });
  const animatedComponents = makeAnimated();
  const [catalogoReparaciones, setCatalogoReparaciones] = useState([]);
  const [reparacionesSeleccionadas, setReparacionesSeleccionadas] = useState(
    []
  );
  const [fetchDate, setDate] = useState(new Date());
  const [registro, setRegistro] = useState(null);
  const [gestionArchivos, setGestionArchivos] = useState(false);

  useEffect(async () => {
    await getReparacionseDignas(token).then((data) => {
      let listado = [];
      for (const item of data?.results) {
        let reg = {
          value: item.id,
          label: item?.nombre,
        };

        listado.push(reg);
      }

      setCatalogoReparaciones(listado);
    });
  }, []);

  useEffect(async () => {
    setState({ ...state, cargandoListado: true });
    await getPrimerContacto(token,idCasoPersona).then((data) => {
      setState({
        ...state,
        cargandoListado: false,
        listadoPrimerContacto: data.results.sort(compareFechas),
      });
    });
  }, [fetchDate]);

  const changeReparaciones = (e) => {
    let ids = [];
    for (const item of e) {
      ids.push(item.value);
    }
    setReparacionesSeleccionadas(ids);
  };

  const borrarRegistro = async (id) => {
    setState({
      ...state,
      cargandoListado: true,
    });
    await deletePrimerContacto(id, token)
      .then(() => {
        toast.success("Registro eliminado con éxito.");
        setState({ 
          ...state, 
          cargandoListado: false,
          confirmarBorrado: false
        });
        setDate(new Date());
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status >= 500) {
          toast.error("Error interno comuniquese con el administrador");
        }
      });
  };

  function compareFechas(a, b) {
    const fecha1 = new Date(a.fecha);
    const fecha2 = new Date(b.fecha);

    if (fecha1 > fecha2) {
      return -1;
    }
    if (fecha1 < fecha2) {
      return 1;
    }
    return 0;
  }


  return (
    <div>
      <Formik
        initialValues={{
          comentarios: "",
          fecha: moment().format("YYYY-MM-DD"),
          valores: null,
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (reparacionesSeleccionadas.length === 0) {
            toast.error("debe de seleccionar al menos una reparación digna");
          } else {
            for (const item of reparacionesSeleccionadas) {
              let body = {
                fecha: values.fecha,
                comentarios: values.comentarios,
                estado: true,
                caso: idCaso,
                caso_persona: idCasoPersona,
                expectativa_rep_digna: item,
                usuario: 1,
              };

              await postPrimerContacto(body, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    toast.success("Registro agregado con éxito..!");
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status >= 400 &&
                    error.response.status <= 450
                  ) {
                    toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    toast.error(
                      "Error interno comuniquese con el administrador"
                    );
                  }
                });
            }
            setDate(new Date());
            setReparacionesSeleccionadas([]);
            selectRef.current.clearValue();
            resetForm({});
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-4 p-3 border rounded-md">
                <div>
                  <Label text="Medidas de reparación digna" />
                  <Select
                    ref={selectRef}
                    placeholder="Seleccione una o mas reparaciones dignas"
                    components={animatedComponents}
                    isMulti={true}
                    name="reparaciones_dignas"
                    className="basic-multi-select mb-2 text-xs text-maivi-primaryFont"
                    options={catalogoReparaciones}
                    onChange={changeReparaciones}
                    maxMenuHeight={140}
                  />
                </div>
                <div className="mt-2">
                  <Label text="Comentarios" />
                  <TextAreaSiavi
                    rows={8}
                    cols={5}
                    name="comentarios"
                    value={values.comentarios}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-2">
                  <InputSiavi
                    type="date"
                    value={values.fecha}
                    name="fecha"
                    onChange={handleChange}
                    label="Fecha"
                    maximo={moment().format("YYYY-MM-DD")}
                    validacion={true}
                  />
                </div>
                <div className="mt-2">
                  <ButtonSiavi
                    cargando={isSubmitting}
                    type="submit"
                    disabled={isSubmitting}
                    text="Guardar"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
              <div className="col-span-8 p-3 border rounded-md">
                <TableSiavi
                  cargando={state.cargandoListado}
                  headers={[
                    "Expectativa reparación digna",
                    "Comentarios",
                    "Fecha",
                    "Acciones",
                  ]}
                  body={state.listadoPrimerContacto.map((item, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer hover:bg-gray-100"
                    >
                      <td className="px-2 py-1 text-xs text-maivi-primaryFont">
                        {item.expectativa_rep_digna.nombre}
                      </td>
                      <td className="px-2 py-1 text-xs text-maivi-primaryFont">
                        {item.comentarios}
                      </td>
                      <td className="px-2 py-1 text-xs text-maivi-primaryFont">
                        {moment(item.fecha).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <button
                          type="button"
                          className={inactivarButton}
                          onClick={() => {
                            setState({
                              ...state,
                              confirmarBorrado: true,
                              registro: item,
                            });
                          }}
                        >
                          <TrashIcon className="h-6 w-6 text-red-500 hover:text-white" />
                          <span className="tooltiptext">Eliminar</span>
                        </button>
                        <button
                          type="button"
                          className={classAcciones}
                          onClick={() => {
                            setRegistro(item.id);
                            setGestionArchivos(true);
                          }}
                        >
                          <PaperClipIcon className="h-6 w-6 text-maivi-primaryFont" />
                          <span className="tooltiptext">Adjuntar archivos</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>

      {gestionArchivos && (
        <ModalAmplio
          title={`Adjuntar archivos`}
          open={gestionArchivos}
          closeModal={() => {}}
          cerrar={() => {
            setGestionArchivos(false);
          }}
          children={
            <GestionArchivos
              idRecurso={registro}
              token={token}
              recurso="EXPECTATIVAREPARACIONDIGNA"
              claseDocumento="expectativareparaciondigna"
            />
          }
        />
      )}
      {state.confirmarBorrado && (
        <ModalGenerico
          title={`¿Desea eliminar el registro ${state.registro.expectativa_rep_digna.nombre}?`}
          open={state.confirmarBorrado}
          closeModal={() => {}}
          cerrar={() => {
            setState({ ...state, confirmarBorrado: false });
          }}
          children={
            <BotonesConfirmar
              isLoading={state.cargandoListado}
              aceptar={() => {
                borrarRegistro(state.registro.id);
              }}
              cancelar={() => {
                setState({ ...state, confirmarBorrado: false });
              }}
            />
          }
        />
      )}
    </div>
  );
};
