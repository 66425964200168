
const {
    REACT_APP_API_URL
} = process.env;

  export const getReferencias = async (urlPage, search,filters,order,profesional, token) => {
    try {
      let urlFetch = urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/referencia/?search=${search.value}&${filters}&referencia_interna=true&solicitud_referencia=false&asignado=true&profesional_asignado=${profesional}&ordering=${order}`
          :`${urlPage}&search=${search.value}&${filters}referencia_interna=true&solicitud_referencia=false&asignado=true&ordering=${order}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(data => data.json());
  
      return result;
    } catch (e) {
      console.log("error", e);
    }
  };
  
  export const referencias = async (urlPage,filters,order,profesional, token) => {
    try {
      let urlFetch =
        urlPage === undefined
          ? `${REACT_APP_API_URL}/registro/referencia/?referencia_interna=true&solicitud_referencia=false&asignado=true&profesional_asignado=${profesional}&ordering=${order}`
          : `${urlPage}&referencia_interna=true&solicitud_referencia=false&asignado=true&ordering=${order}`;
      let result = await fetch(urlFetch, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((data) => data.json());
  
      return result;
    } catch (e) {
      console.log("error", e);
    }
  };

  export const getProfesionales = async (token) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };