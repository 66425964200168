import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getAtenciones = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/atencion/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicioPropioAtencion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPregunta = async ({pregunta, respuesta, palabras_clave, estado,url},token) => {
    try {
        let body = {
            pregunta: pregunta,
            respuesta: respuesta,
            palabras_clave: palabras_clave,
            estado: estado,
            url:url
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/preguntasfrecuentessiavi/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPregunta = async (id, {pregunta, respuesta, palabras_clave, url, estado},token) => {
    try {
        let body = {
            pregunta: pregunta,
            respuesta: respuesta,
            palabras_clave: palabras_clave,
            estado: estado,
            url:url
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/preguntasfrecuentessiavi/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAtencion = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};