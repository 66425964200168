import React, { useEffect, useState } from "react";
import Toggle from "../../../../components/common/Toggle";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { getDeptosSedes, getMunicipiosSedes, getTipoSedes, getSedesExp } from "../services";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Return from "../../../../components/common/ButtonSalir";

const useFetchSedesExp = (token) => {
  const [state, setState] = useState({
    sedes: [],
  });

  useEffect(() => {
    getSedesExp(token).then((sedes) => {
      setState({
        sedes: sedes,
      });
    });
  }, [token]);

  return state;
};

const useFetchDepartamento = (token) => {
  const [state, setState] = useState({
    departamentos: [],
  });

  useEffect(() => {
    getDeptosSedes(token).then((departamentos) => {
      setState({
        departamentos: departamentos,
      });
    });
  }, [token]);

  return state;
};

const useFetchMunicipio = (token) => {
  const [state, setState] = useState({
    municipios: [],
  });

  useEffect(() => {
    getMunicipiosSedes(token).then((municipios) => {
      setState({
        municipios: municipios,
      });
    });
  }, [token]);

  return state;
};

const useFetchTipoSede = (token) => {
  const [state, setState] = useState({
    tiposedes: [],
  });

  useEffect(() => {
    getTipoSedes(token).then((tiposedes) => {
      setState({
        tiposedes: tiposedes,
      });
    });
  }, [token]);

  return state;
};

export const Crear = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje,
  setFieldValue
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { departamentos } = useFetchDepartamento(token);
  const { municipios } = useFetchMunicipio(token);
  const { tiposedes } = useFetchTipoSede(token);
  const { sedes } = useFetchSedesExp(token);
  const [coberturasAgregadas, setCoberturasAgregadas] = useState([]);
  //const [filtroCobertura, setFiltroCobertura] = useState("");

  const selectClass = `mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue 
    block w-full sm:text-sm border-maivi-gray-10 rounded-md`;
  const buttonAddClass = `inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont
     hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue`;
  const buttonRemoveClass = `inline-flex items-center p-1 border border-transparent rounded-md text-maivi-primaryFont 
    hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue`;

  const pages = [
    {
      name: "Sedes",
      href: "/catalogos/sedes",
      current: false,
    },
    { name: "Crear", href: "#", current: true },
  ];

  let deptoCobertura =
    values.deptocobertura == null ? values.departamento : values.deptocobertura;

  return (
    <form autoComplete="off">
      <div className="grid-cols-12 container mx-auto -mt-32 mb-10 justify-center">
        <div className="col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
          <div
            className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center
          lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 pt-5"
          >
            <BreadCrumbTwo navegacion={pages} />
            <div className="flex flex-row">
              <h1 className="text-lg text-maivi-primaryFont mr-2">
                Crear sede
              </h1>
              <Return ruta="/catalogos/sedes" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 border-2 rounded-md p-5">
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    type="text"
                    name="sede"
                    id="sede"
                    onChange={handleChange}
                    value={values.sede}
                    validacion={true}
                    label="Sede*"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    type="text"
                    name="direccion"
                    id="direccion"
                    value={values.direccion}
                    onChange={handleChange}
                    validacion={true}
                    label="Dirección*"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    id="telefono"
                    name="telefono"
                    type="text"
                    value={values.telefono}
                    onChange={handleChange}
                    validacion={true}
                    label="Teléfono"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    id="codigo"
                    name="codigo"
                    type="codigo"
                    value={values.codigo}
                    onChange={handleChange}
                    validacion={true}
                    label="Código"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <InputSiavi
                    type="text"
                    name="descripcion"
                    id="descripcion"
                    value={values.descripcion}
                    onChange={handleChange}
                    validacion={true}
                    label="Descripción*"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 ">
                  <Label text="Departamento*" />
                  <select
                    id="departamento"
                    name="departamento"
                    autoComplete="off"
                    value={values.departamento}
                    onChange={handleChange}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir departamento
                    </option>
                    {departamentos?.results?.map((item, index) => {
                      return (
                        <option key={item.id + "DP" + index} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="departamento"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>

                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <Label text="Municipio*" />
                  <select
                    as="select"
                    id="municipio"
                    name="municipio"
                    autoComplete="off"
                    value={values.municipio}
                    onChange={handleChange}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir municipio
                    </option>
                    {municipios?.results
                      ?.filter(
                        (mn) =>
                          Number(mn.departamento) ===
                          Number(values.departamento)
                      )
                      .map((item, index) => {
                        return (
                          <option
                            key={item.nombre + "MN" + index}
                            value={item.id}
                          >
                            {item.nombre}
                          </option>
                        );
                      })}
                  </select>
                  <ErrorMessage
                    name="municipio"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>

                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                  <Label text="Tipo sede*" />
                  <select
                    as="select"
                    id="tipo_sede"
                    name="tipo_sede"
                    autoComplete="off"
                    value={values.tipo_sede}
                    onChange={handleChange}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir Tipo sede
                    </option>
                    {tiposedes?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="tipo_sede"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>

                <div className="col-span-12 xl:col-span-3 lg:col-span-3 flex flex-col justify-center items-center">
                  <Label text={`${values.estado ? "Activo" : "Inactivo"}`} />
                  <div className="mt-1">
                    <Toggle enabled={values.estado} name="estado" />
                  </div>
                </div>

                <div className="col-span-12 xl:col-span-3 lg:col-span-3 flex flex-col justify-center items-center">
                  <Label text="Hacen turnos" />
                  <div className="mt-1">
                    <Toggle enabled={values.hacen_turnos} name="hacen_turnos" />
                  </div>
                </div>
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                  <Label text="Es Sede Seguimiento" />
                  <div className="mt-1">
                    <Toggle enabled={values.es_sede_seguimiento} name="es_sede_seguimiento" />
                  </div>
                </div>
                {!values.es_sede_seguimiento && (
                  <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                    <Label text="Sede Seguimiento" />
                    <select
                      as="select"
                      id="sede_seguimiento"
                      name="sede_seguimiento"
                      autoComplete="off"
                      value={values.sede_seguimiento}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="" disabled>
                        Elegir Sede Seguimiento
                      </option>
                      {sedes?.results?.map((item) => {
                        return (
                          <option key={item.sede} value={item.id}>
                            {item.sede}
                          </option>
                        );
                      })}
                    </select>
                  </div>)}
                <div className="col-span-12 xl:col-span-6 lg:col-span-6 mt-5 ">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      values.cobertura = [];
                      coberturasAgregadas.forEach(cover => values.cobertura.push(cover.id));
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                    text="Crear"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-6 lg:col-span-6 border-2 rounded-md p-5">
              <div className="col-span-12 xl:col-span-6 lg:col-span-12">
                <div className="bg-white sm:rounded-lg">
                  <div className="grid grid-cols-2 grid-flow-col gap-2">
                    <div className="col-span-12 xl:col-span-6 lg:col-span-6 mb-2 ">
                      <Label text="Departamento para Cobertura" />
                      <select
                        id="deptocobertura"
                        name="deptocobertura"
                        autoComplete="off"
                        value={deptoCobertura}
                        onChange={handleChange}
                        className={selectClass}
                      >
                        <option value="" disabled>
                          Elegir departamento
                        </option>
                        {departamentos?.results?.map((item, index) => {
                          return (
                            <option
                              key={item.id + "DPCOB" + index}
                              value={item.id}
                            >
                              {item.nombre}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-span-12 xl:col-span-6 lg:col-span-6 mt-6 ">
                      <ButtonSiavi
                        type="button"
                        onClick={() => {
                          let coberturas = coberturasAgregadas;

                          municipios?.results.forEach((mn) => {
                            if (Number(mn.departamento) === Number(deptoCobertura)) {

                              let bandera = true;
                              coberturasAgregadas.forEach(cober => {
                                if (Number(cober.id) === Number(mn.id)) {
                                  bandera = false;
                                }
                              })
                              if (bandera) {
                                coberturas.push(mn)
                              }
                            }
                          });
                          setCoberturasAgregadas(coberturas);
                          setFieldValue('deptocobertura', '');
                        }}
                        text="Agregar Todos"
                      />
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
                    <table className="table-fixed min-w-full divide-y divide-maivi-gray-10 ">
                      <thead className="bg-maivi-gray-5">
                        <tr>
                          <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                            Cobertura
                          </th>
                          <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                            Agregar
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                        {/*   { getFilterCoberturas().map((item, index) => ( */}
                        {municipios?.results
                          ?.filter(
                            (mn) =>
                              Number(mn.departamento) === Number(deptoCobertura)
                          )
                          .map((item, index) => (
                            <tr
                              key={item.id + "COB" + index}
                              id={item.id}
                              className="hover:bg-maivi-gray-5"
                            >
                              <td className="px-6 whitespace-nowrap">
                                <div className="text-xs text-maivi-primaryFont">
                                  {item.nombre}
                                </div>
                              </td>
                              <td className="px-6 whitespace-nowrap">
                                <div className="text-xs text-maivi-primaryFont">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      let element = document.getElementById(
                                        item.id
                                      );
                                      element.classList.add("bg-green-50");
                                      setCoberturasAgregadas([
                                        ...coberturasAgregadas,
                                        item,
                                      ]);
                                    }}
                                    className={buttonAddClass}
                                  >
                                    Agregar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3 col-span-6">
                <div className="bg-white shadow sm:rounded-lg mt-4">
                  <div className="p-2">
                    <h3 className="text-sm leading-2 font-medium text-gray-900">
                      Coberturas agregadas
                    </h3>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-60 overflow-y-auto">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <table className="min-w-full divide-y divide-maivi-gray-10">
                        <thead className="bg-maivi-gray-5">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                            >
                              Cobertura
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider"
                            >
                              Borrar
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                          {coberturasAgregadas?.map((item, index) => (
                            <tr
                              key={item.id + "COBA" + index}
                              className="bg-green-50"
                            >
                              <td className="px-6 whitespace-nowrap">
                                <div className="text-xs text-maivi-primaryFont">
                                  {item.nombre}
                                </div>
                              </td>
                              <td className="px-6 whitespace-nowrap">
                                <div className="text-xs ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      let old = coberturasAgregadas.filter(
                                        (x) => x.id !== item.id
                                      );
                                      setCoberturasAgregadas([...old]);
                                    }}
                                    className={buttonRemoveClass}
                                  >
                                    Borrar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mt-6 mb-2">
                {hasError && <Alert type="error" mensaje={mensaje} />}
                {success && <Alert type="success" mensaje={mensaje} />}
              </div>
              {isSubmitting && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
