import axios from "axios";
const { REACT_APP_API_URL } = process.env;

export const getCaso = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/caso/${id}/`

        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getOrientacion = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacion/${id}/`

        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getProfesionales = async (expediente, token) => {
    try {
        //let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${expediente?.servicio?.id}&sedesusuario__sede=${expediente?.sede_expediente?.id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getTodosProfesionales = async (expediente, token) => {
    try {
        //let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${expediente?.servicio?.id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const postAsignaciones = async (profesional, expedientes, token) => {
    try {
        let body = {
            profesional: profesional,
            expedientes: expedientes
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedienteFull= async (id, token) => {
    try {

        let result = axios({
            method: 'GET',
            url: `${REACT_APP_API_URL}/registro/expediente/${id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putExpediente = async (id, profesional, sede, token) => {
    try {
        let body = {
            profesional_asignado: profesional,
            sede_expediente: sede
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expediente/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putReabrirExpediente = async (id, fecha_activacion, expediente_cerrado, fecha_cierre, autoriza_cierre, token) => {
    try {
        let body = {
            fecha_activacion: fecha_activacion,
            expediente_cerrado: expediente_cerrado,
            fecha_cierre: fecha_cierre,
            autoriza_cierre: autoriza_cierre
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expediente/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipiosGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getInstitucionGestion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/servicioexterno/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPoblaciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/poblacion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (servicio, directores, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/?page_size=200&estado=true&encuesta=false&profesional=true&por_servicio=true&servicio=${servicio}&directores=${directores}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/modalidad/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getInstituciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPreguntas = async (idFormulario, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formulariospreguntas/?formulario=${idFormulario}&page_size=200`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

//export const getFormularioCasoPersona = async (idCasoPersona,token) => {
export const getFormularioCasoPersona = async (idCasoPersona, expediente,token) => {
    try {
        
        let result = await fetch(`${REACT_APP_API_URL}/registro/formulariocasopersona/?page_size=1000&expediente=${expediente}&caso_persona=${idCasoPersona}`, {
        //let result = await fetch(`${REACT_APP_API_URL}/registro/formulariocasopersona/?page_size=1000&caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormularioCasoPersona = async (data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            nombre_solicita: data.nombre_solicita,
            fecha_solicitud: (data.fecha_solicitud === "") ? null : data.fecha_solicitud,
            institucion_solicita: (data.institucion_solicita === "") ? null : data.institucion_solicita,
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: Number(data.formulario),
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormularioCasoPersona = async (id, data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            nombre_solicita: data.nombre_solicita,
            fecha_solicitud: (data.fecha_solicitud === "") ? null : data.fecha_solicitud,
            institucion_solicita: (data.institucion_solicita === "") ? null : data.institucion_solicita,
            fecha_formulario: data.fecha_formulario,
            editable: data.editable,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormularioCasoPersona = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postInstrumentos = async (editar, formulario, listadoPreguntas, token) => {
    try {
        let listado = [];
        await listadoPreguntas.forEach(element => {
            let objeto = {};
            objeto.pregunta = element.id_pregunta;
            objeto.editada = editar;
            objeto.texto_extenso = element.texto_extenso;
            objeto.respuesta_corta = element.respuesta_corta;
            objeto.valor_seleccion = element.valor_seleccion;
            objeto.formulario_caso_persona = formulario.id;
            objeto.id = element?.id
            listado.push(objeto);
        });

        let body = {
            editado: editar,
            formulario_caso_persona: formulario.id,
            respuestas: listado
        }

        console.log(body)

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersonalist/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};




export const getCasoPersonaRespuestas = async (idFormulario, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/formulariocasopersonarespuestas/?page_size=300&formulario_caso_persona=${idFormulario}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProNombramiento = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNombramientos = async (idExpediente, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/nombramientos/?expediente=${idExpediente}&page_size=25&ordering=-fecha`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postNombramientos = async (data, token) => {
    try {
        let body = {
            lugar_destino: data.lugar_destino,
            fecha: (data.fecha === "") ? null : data.fecha,
            fecha_hora_salida: (data.fecha_hora_salida === "") ? null : data.fecha_hora_salida,
            fecha_hora_retorno: (data.fecha_hora_retorno === "") ? null : data.fecha_hora_retorno,
            fecha_autorizado: (data.fecha_autorizado === "") ? null : data.fecha_autorizado,
            autorizado: data.autorizado,
            estado: data.estado,
            diligencia_realizar: data.diligencia_realizar,
            observaciones: data.observaciones,
            usuario_requiere_nombramiento: (data.usuario_requiere_nombramiento === "") ? null : data.usuario_requiere_nombramiento,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            departamento: (data.departamento === 0) ? null : data.departamento,
            municipio: (data.municipio === 0) ? null : data.municipio,
            usuario_autorizado: (data.usuario_autorizado === 0) ? null : data.usuario_autorizado,
            accion_seguimiento: (data.accion_seguimiento === 0) ? null : data.accion_seguimiento,
            usuario_creacion: "",
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/nombramientos/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putNombramientos = async (id, data, token) => {
    try {
        let body = {
            lugar_destino: data.lugar_destino,
            fecha: (data.fecha === "") ? null : data.fecha,
            fecha_hora_salida: (data.fecha_hora_salida === "") ? null : data.fecha_hora_salida,
            fecha_hora_retorno: (data.fecha_hora_retorno === "") ? null : data.fecha_hora_retorno,
            fecha_autorizado: (data.fecha_autorizado === "") ? null : data.fecha_autorizado,
            autorizado: data.autorizado,
            estado: data.estado,
            diligencia_realizar: data.diligencia_realizar,
            observaciones: data.observaciones,
            usuario_requiere_nombramiento: (data.usuario_requiere_nombramiento === "") ? null : data.usuario_requiere_nombramiento,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            departamento: (data.departamento === "") ? null : data.departamento,
            municipio: (data.municipio === "") ? null : data.municipio,
            usuario_autorizado: (data.usuario_autorizado === "") ? null : data.usuario_autorizado,
            accion_seguimiento: (data.accion_seguimiento === 0) ? null : data.accion_seguimiento,
            //usuario_creacion: (data.usuario_creacion === "") ? null : data.usuario_creacion,
            usuario_creacion: ""
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/nombramientos/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteNombramientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/nombramientos/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesNombramientos = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/nombramientos/?accion_seguimiento=${id}&ordering=-fecha`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCambiarEstadoExpediente = async (data, token) => {
    try {
        let newDate = new Date().toISOString().slice(0, 10);
        let body = {
            estado_nuevo: data.estado,
            motivo_cambio: data.motivo_cambio,
            caso: data.caso,
            fecha: newDate,
            expediente: data.expediente,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientehistoricoestado/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getHistorialEstadosExpedientes = async (idExpediente, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientehistoricoestado/?expediente=${idExpediente}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCambiarEstadoCaso = async (data, token) => {
    try {
        let newDate = new Date().toISOString().slice(0, 10);
        let body = {
            estado_nuevo: data.estado,
            motivo_cambio: data.motivo_cambio,
            caso: data.caso,
            fecha: newDate,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casohistoricoestado/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const reabriCaso = async (data, token) => {
    try {
        let body = {
            caso_cerrado: data.caso_cerrado,
            fecha_cierre: null,
            usuario_cierre:null,
            fecha_autoriza_cierre: null,
            autoriza_cierre: data.usuario
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/caso/${data.id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getHistorialEstadosCaso = async (caso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casohistoricoestado/?caso=${caso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMensajeriaSMS = async (data, token) => {
    try {
        let newDate = new Date().toISOString().slice(0, 10);;
        let body = {
            estado_nuevo: data.estado,
            motivo_cambio: data.motivo_cambio,
            caso: data.caso,
            fecha: newDate,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/historicoestadosms/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getHistorialSMS = async (caso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/historicoestadosms/?caso=${caso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDetalleReparacionDigna = async (token,idCasoPersona) => {
    try {
        let urlFetch =  `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/?page_size=300&medida_otorgada=true&rep_digna_caso_persona__caso_persona=${idCasoPersona}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getServiciosInternos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMedidasVictimologicas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/medidavictimologica/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMedidaVictimologica = async (values, token) => {
    try {
        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/legal/registromedidavictimologica/`,
            data: values,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};


export const getMedidasVictimologicasPersona = async (token, idCasoPersona) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/legal/registromedidavictimologica/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getHistorial = async (token,id,isMedida) => {
    try {
        let url = isMedida ? `${REACT_APP_API_URL}/legal/histexpedientereaparaciondignadetalle/?medida_victimologica=${id}`
         : `${REACT_APP_API_URL}/legal/histexpedientereaparaciondignadetalle/?reparacion_digna_detalle=${id}`;
        
        let result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getStatusMedidas = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/statusmedidareparaciondigna/?page_size=300&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDetalleReparacionDigna = async (body,token) => {
    try {
       
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/expreparaciondignadetalle/${body.id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const putMedidaVictimologica = async (body,token) => {
    try {
       
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/legal/registromedidavictimologica/${body.id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};