/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getReferencias, getProfesionales, referencias, asignarReferencias, exportarData } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { DocumentAddIcon, FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import toast from "react-hot-toast";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import ExportButton from "../../../components/common/ExportButton";
import { columReferencias, referenciaexternalist, referenciainternalist, seguimientoreferenciaexternalist } from "./columnas";
import { exportarResumen } from "../audienciasPlanificadas/services";

const tableColumns = [
  { title: "Caso", value: "caso.numero_caso", tipo: "text" },
  { title: "Fecha", value: "fecha_creacion", tipo: "date", ordenar: "fecha_creacion" },
  { title: "Referencia", value: "codigo_referencia", tipo: "text" },
  { title: "Fecha solicitud", value: "fecha_creacion_referencia_ts", tipo: "date" },
  {
    title: "Nombre Víctima",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  {
    title: "Profesional crea",
    value: null,
    values: [
      "profesional_crea.first_name",
      "profesional_crea.last_name",
    ],
    tipo: "text",
    ordenar: "profesional_crea_referencia_ts__nombre"
  },
  {
    title: "Profesional solicita",
    value: null,
    values: [
      "profesional_crea_referencia_ts.first_name",
      "profesional_crea_referencia_ts.last_name",
    ],
    tipo: "text"
  },
  {
    title: "Asignado",
    value: "asignado",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Servicio",
    value: "referencias_internas.nombre",
    tipo: "text"
  },
  {
    title: "Atención",
    value: "asistencia_atencion_especifica",
    tipo: "text"
  }
];

export const TableReferencias = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);

  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [registros, setRegistros] = useState([]);
  const [profesional] = useState(0);
  const [cargando, setCargando] = useState(false);
  const [servicio, setServicio] = useState("");
  const [order, setOrder] = useState("-fecha_creacion");
  const [profesionales, setProfesionales] = useState([]);
  const permiso = "referencias";
  const optionsProfesionales = [];

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro

  const permisoView = "Referencias";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;
  }



  const exportResumen = (url) => {
    setTableLoading(true);
    exportarResumen(url, token).then(async data => {
      let fileName = 'Resumen';
      let dataExport = data;
      let columns = [];

      if (url === 'referenciainternalist') {
        columns = referenciainternalist;
        fileName = 'ReferenciasInternas';
      }

      if (url === 'referenciaexternalist') {
        columns = referenciaexternalist;
        fileName = 'ReferenciasExternas';
      }

      if (url === 'seguimientoreferenciaexternalist') {
        columns = seguimientoreferenciaexternalist;
        fileName = 'SeguiminetoReferenciaEX';
      }

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo: 'xls' });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    })
  }

  const exportData = (tipo) => {
    setTableLoading(true);
    exportarData(filters, token).then(async (data) => {
      const fileName = 'Referencias';
      let dataExport = data;
      let columns = columReferencias;
      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));
    });
  }

  const registrosSeleccionados = (items) => {
    setRegistros(items);
  };

  useEffect(() => {
    setTableLoading(true);
    referencias(urlFetch, search, filters, order, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, filters, order, sede]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setUrlFech(undefined);
      setTableLoading(true);
      getReferencias(urlFetch, search, sede, filters, order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, filters, sede, order]);

  useEffect(() => {
    getProfesionales(token).then((data) => {
      setProfesionales(data);
    });
  }, [token]);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - Asignaciones: ${item?.asignaciones}`,
    };

    if (servicio === "") {
      optionsProfesionales.push(reg);
    } else {
      item.servicios.forEach(element => {
        if (element?.servicio_detalle?.nombre === servicio) {
          optionsProfesionales.push(reg);
        }
      });
    }
  });

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Referencias", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div>
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="-mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
              setFilters("");
            }}
            icon={<RefreshIcon className="-mr-1 h-5" />}
          />

          {(CheckPermiso('exportarreferencias') === true) &&    //mm permiso agregado      
            <ExportButton
              handlePdf={() => {
                exportData('pdf');
              }}
              show={'xls'}
              handleExcel={() => {
                exportData('xls');
              }}
            />
          }
          {(CheckPermiso('exportarresumen') === true) &&    //mm permiso agregado      
            <ExportButton
              title={'Resumen'}
              handleOpciones={exportResumen}
              opciones={[
                { text: 'Referencias Internas', value: 'referenciainternalist' },
                { text: 'Referencias Externas', value: 'referenciaexternalist' },
                { text: 'Seguimiento Referencia', value: 'seguimientoreferenciaexternalist' },
              ]}
            />
          }
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showEliminar={false}
            showEditar={true}
            showVer={true}
            order={order}
            setOrder={setOrder}
            agregar={true}
            select={false}
            registrosSeleccionados={registrosSeleccionados}
            agregar1="Convertir a expediente"
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {agregar && (
        <ModalGenerico
          title={"Asignar referencias"}
          open={agregar}
          closeModal={() => { }}
          cerrar={() => {
            setAgregar(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentAddIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <div className="mb-16">
              <BotonesConfirmar
                aceptar={async () => {
                  setCargando(true);
                  await asignarReferencias(registros, profesional, token)
                    .then((data) => {
                      if (data?.status >= 200 || data?.status <= 210) {
                        toast.success('Asignaciones realizadas con éxito');
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                        setCargando(false);
                      }
                    })
                    .catch(function (error) {
                      if (error.response.status >= 400 || error.response.status <= 410) {
                        setCargando(false);
                        toast.error(error.response.data.message);
                      } else if (error.response.status >= 500) {
                        setCargando(false);
                        toast.error('Error interno comuniquese con el administrador');
                      }
                    });
                }}
                cancelar={() => {
                  setAgregar(false);
                }}
              />
              {cargando && <Spinner />}
            </div>
          }
        />
      )}
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
              setServicio={setServicio}
              setUrlFech={setUrlFech}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
    </div>
  );
};
