import { useState } from "react";
import { sendPassword } from "./services";
import { Formik } from "formik";
import { PreguntasSecretas } from "./preguntasSecretas";
import { ButtonSiavi } from "../../../components/common/Button";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import ModalGenerico from "../../../components/common/Modal";

export const OlvidePassword = (props) => {
  const [responderPreguntas, setResponder] = useState(false);
  const [hasError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [cargando, setCargando] = useState(false);

  const enviarEmail = async (user) => {
    setCargando(true);
    sendPassword(user)
      .then((data) => {
        if (data.status === 200) {
          setError(false);
          setCargando(false);
          setSuccess(true);
          setMensaje(data.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setCargando(false);
          setSuccess(false);
        }

        if (error.response.status === 500) {
          setCargando(false);
          setError(true);
          setMensaje("Ocurrio un error");
        }
      });
  };

  return (
    <Formik
      initialValues={{
        pregunta1: "",
        pregunta2: "",
        respuesta1: "",
        respuesta2: "",
      }}
      //validationSchema={validaciones}
      onSubmit={async (values) => {
        setCargando(true);
        sendPassword(props.username)
          .then((data) => {
            if (data.status >= 200 && data.status <= 250) {
              setError(false);
              setCargando(false);
              setSuccess(true);
              setMensaje("Se ha enviado un correo con la nueva contraseña favor revisar su bandeja.")
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setError(true);
              setCargando(false);
              setSuccess(false);
              setMensaje(error?.response?.data?.message);
            }

            if (error.response.status === 500) {
              setCargando(false);
              setError(true);
              setSuccess(false);
              setMensaje("Ocurrio un error comuniquese con el administrador");
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-6">
            <div className="text-center">
              <h1>Como desea recuperar su clave</h1>
            </div>
            <div className="py-3 flex flex-row ">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  setResponder(true);
                }}
                text="Preguntas secretas"
                className="mx-2"
              />
              <ButtonSiavi
                type="button"
                onClick={() => {
                  enviarEmail(props.username);
                }}
                text="Enviar email"
              />
            </div>
          <div className="text-center">
          <label
              className="font-medium text-maivi-primaryBlue hover:text-maivi-primaryLightBlue cursor-pointer"
              onClick={() => {
                window.location.reload();
              }}
            >
              volver a inicio de sesion
            </label>
          </div>
          </div>
          {isSuccess && <Alert type="success" mensaje={mensaje} />}
          {hasError && <Alert type="error" mensaje={mensaje} />}
          {cargando && <Spinner />}
          {responderPreguntas && (
            <ModalGenerico
              title={`Responde a las preguntas secretas para recuperar tu contraseña!`}
              open={true}
              closeModal={() => { }}
              cerrar={() => { setResponder(false) }}
              children={<PreguntasSecretas user={props.username} />}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

