import { types } from "../types/types";

export const ChangeSede = (state = { sedeId: 0 }, action) => {
    switch (action.type) {
        case types.ChangeSede:
            return {
                sedeId: action.payload,
            } 
        default:
            return state;
    }
}

