import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
    deleteSeleccionDetalle,
    getSeleccionSeleccionDetalle,
    postSeleccionDetalle,
    putSeleccionDetalle,
    getTipoValorEncuesta
} from "../seleccionDetalle/services";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import FormEliminar from "../../../components/common/FormEliminar";
import { SelectSiavi } from "../../../components/common/Select";
import toast from 'react-hot-toast';
import Picker from 'emoji-picker-react';
import * as Yup from "yup";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Valor", value: "valor", tipo: "text" },
    { title: "Texto Mostrado", value: "text_mostrado", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const validaciones = Yup.object().shape({
    valor: Yup.string()
        .required("Valor es requerido")
        .max(100, "Máximo 100 caracteres")
});

const useFetchTipoValor = (token) => {
    const [state, setState] = useState({
        tipo_valor: [],
    });

    useEffect(() => {
        getTipoValorEncuesta(token).then((tipo_valor) => {
            setState({
                tipo_valor: tipo_valor,
            });
        });
    }, [token]);

    return state;
};

export const GestionesSeleccionDetalle = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idSeleccionDetalle, setIdSeleccionDetalle] = useState("");
    const [seleccionDetalle, setSeleccionDetalle] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idSeleccion = props?.seleccion?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const { tipo_valor } = useFetchTipoValor(token);
    const [chosenEmoji, setChosenEmoji] = useState(null);

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject.emoji);
    };

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "catalogoselecciondetalle";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdSeleccionDetalle(id);
        setEliminar(true);
    };

    const handleEditar = (gestionSeleccionDetalle) => {
        setEdit(true);
        setSeleccionDetalle(gestionSeleccionDetalle);
        setChosenEmoji(gestionSeleccionDetalle.representacion);
    };

    const fetchSeleccionDetalle = () => {
        getSeleccionSeleccionDetalle(idSeleccion, token).then((subdominios) => {
            setDataTable(subdominios);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchSeleccionDetalle, [token, idSeleccion, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    catalogo_seleccion: props.seleccion.id,
                    valor: isEdit ? seleccionDetalle?.valor : "",
                    representacion: isEdit ? seleccionDetalle?.representacion : "",
                    text_mostrado: isEdit ? seleccionDetalle?.text_mostrado : "",
                    tipo_valor: isEdit ? seleccionDetalle?.tipo_valor : "",
                    estado: isEdit ? seleccionDetalle?.estado : true,
                    representacion_emoji: isEdit ? seleccionDetalle?.representacion_emoji : false
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putSeleccionDetalle(seleccionDetalle.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.valor = "";
                                    values.estado = true;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postSeleccionDetalle(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 border p-3 rounded-md">
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-12 xl:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="valor"
                                            value={values.valor}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="*Valor"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="text_mostrado"
                                            value={values.text_mostrado}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Texto Mostrado"
                                        />
                                    </div>
                                    {props.seleccion.encuestas && (
                                        <div className="col-span-12 xl:col-span-6">
                                            <Label text="Tipo Valor" />
                                            <div className="mt-1">
                                                <SelectSiavi
                                                    name="tipo_valor"
                                                    value={values.tipo_valor}
                                                    onChange={handleChange}
                                                    initValue=""
                                                    selectInit="Elegir tipo valor"
                                                    list={
                                                        tipo_valor?.results?.filter(x => x.estado === true).map((item) => {
                                                            return (
                                                                <option key={item.nombre} value={item.id}>
                                                                    {item.nombre}
                                                                </option>
                                                            );
                                                        })
                                                    }

                                                />
                                            </div>
                                        </div>)}

                                    {props.seleccion.encuestas && (
                                        <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                            <Label text="Representación Emoji" />
                                            <Toggle enabled={values?.representacion_emoji} name="representacion_emoji" />
                                        </div>)}

                                    <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                        <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                        <Toggle enabled={values.estado} name="estado" />
                                    </div>
                                    {permisoAgregar && (
                                        <div className="col-span-12 xl:col-span-6 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-5">
                                            <ButtonSiavi
                                                type="submit"
                                                disabled={isSubmitting}
                                                text={isEdit ? "Actualizar" : "Guardar"}
                                                onClick={() => {
                                                    values.representacion = chosenEmoji;
                                                    handleSubmit();
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6  border p-3 rounded-md">
                            <div className="grid grid-cols-12 gap-2">
                            {values?.representacion_emoji && (
                                <div className="col-span-12">
                                        <Label text="Representación " />
                                        <label className="text-5xl">{chosenEmoji}</label>                  
                                </div>)}
                                {
                                    values?.representacion_emoji && 
                                  <div className="col-span-12">
                                        <Picker
                                            onEmojiClick={onEmojiClick}
                                            disableSearchBar={false}
                                            pickerStyle={{ width: '100%',height:'250px' }}
                                        />
                                  </div>
                                }
                            </div>

                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto mt-2">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteSeleccionDetalle(idSeleccionDetalle, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
