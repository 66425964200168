import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getDominios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/dominiorecuperacion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSubdominios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/subdominiorecuperacion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEvaluaciones = async (idCaso,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnostica/?caso=${idCaso}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionDiagnostico = async (idCasoPersona, idCaso,observaciones, idtipo, token) => {
    try {
        let body ={
            tipo: idtipo, //"final",
            resultado: 0,
            observaciones: observaciones,
            editable: true,
            caso_persona: idCasoPersona,
            caso: idCaso,
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnostica/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionDiagnosticoDominio = async (idEvaluacion,idDominio,observaciones, token) => {
    try {
        let body ={
            observaciones: observaciones || "",
            dominio: idDominio,
            evaluacion: idEvaluacion
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnosticodominio/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionDiagnosticaDetalle = async (resultado,idEvaluacion,idDominio,idSubdominio, token) => {
    try {
        let body ={
            resultado_inicial: resultado,
            observaciones:null ,
            evaluacion: idEvaluacion,
            dominio: idDominio,
            subdominio: idSubdominio
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnosticadetalle/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEvaluacionDiagnosticaDetalle = async (id,idEvaluacion,idDominio,idSubdominio, resultado,token) => {
    try {
        let body ={
            resultado_inicial: resultado,
            evaluacion:idEvaluacion,
            dominio:idDominio,
            subdominio: idSubdominio
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnosticadetalle/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionDiagnosticaProfesional = async (idEvaluacion,token) => {
    try {
        let body ={
            evaluacion: idEvaluacion
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/evaluacion-victima/evaluaciondiagnosticaprofesional/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=1000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const getPlanTratamientos = async (idEvaluacion, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/plantratamientopreliminar/?evaluacion=${idEvaluacion}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPlanTratamientoPreliminar = async (values,idEvaluacion,token) => {
    try {
        let body ={
            plan: values.plan,
            realizado: false,
            comentario_realizado: values.comentario_plan_tratamiento,
            evaluacion: idEvaluacion,
            servicio: values.servicio
        }

        let result = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/evaluacion-victima/plantratamientopreliminar/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPlanTratamientoPreliminar = async (values,idEvaluacion,token) => {
    try {
        let body ={
            plan: values.plan,
            realizado: false,
            comentario_realizado: values.comentario_plan_tratamiento,
            evaluacion: idEvaluacion,
            servicio: values.servicio
        }

        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/evaluacion-victima/plantratamientopreliminar/${values.idPlan}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteTratamiento = async(id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/evaluacion-victima/plantratamientopreliminar/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonasCaso = async (idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}