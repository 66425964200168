/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { FilterIcon, RefreshIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { FormDetalle } from "./detalle";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { diligenciasUrgentes } from "./services";
import ModalGenerico from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import CustomTable from "../../../../components/common/Table";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import Filtros from "./filtros";

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { title: "Fecha creación", value: "fecha_creacion", tipo: "date" },
    { title: "Expediente", value: "expediente.caso_persona.caso.numero_caso", tipo: "text" },
    {
        title: "Urgente",
        value: "urgente",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
        ordenar: "urgente"
    },
    { title: "Tipo", value: "tipo_diligencia.nombre", tipo: "text" },
    { title: "No. Documento", value: "numero_documento", tipo: "text" },
    { title: "Plazo", value: "plazo", tipo: "date" },
    {
        title: "Enterado",
        value: "enterado",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },

    {
        title: "Profesional asignado",
        value: null,
        values: [
            "profesional_asignado.first_name",
            "profesional_asignado.last_name",
        ],
        tipo: "text"
    },
    { title: "Fecha Enterado", value: "fecha_enterado", tipo: "date" },

];

export const TableNotificacionesLegales = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const [verDetalle, setDetalle] = useState(false);
    const [notificacionesDetalle, setNotificacionesDetalle] = useState({});
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("-id");
    const [openFiltros, setOpenFiltros] = useState("");
    const [filtros, setFiltros] = useState("");
    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );
    const permiso = "expedientediligenciasurgentes";
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            //permisoAgregar = true;
        }
    });

    const handleVer = (item) => {
        setNotificacionesDetalle(item);
        setDetalle(true);
    };

    useEffect(() => {
        setTableLoading(true);
        diligenciasUrgentes(urlFetch, search, order, filtros, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    }, [order, filtros, urlFetch])

    const fetchGetDiligencias = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setUrlFech(undefined);
            setTableLoading(true);
            diligenciasUrgentes(urlFetch, search, order, filtros, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    useEffect(fetchGetDiligencias, [urlFetch, search, filtros, order]);

    const pages = [
        {
            name: "Funcionalidades",
            href: "/funcionalidades-generales",
            current: false,
        },
        { name: "Notificaciones Legales", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
                <BreadCrumb navegacion={pages} />
                <div>
                    <ButtonAdd
                        className="mr-2"
                        text="Filtros"
                        type="button"
                        onClick={() => {
                            setOpenFiltros(true);
                        }}
                        icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                    />
                    <ButtonAdd
                        text="Refrescar"
                        icon={<RefreshIcon className="ml-3 -mr-1 h-5" />}
                        onClick={() => {
                            setFiltros("")
                        }}
                    />
                </div>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={true}
                        showEditar={false}
                        showEliminar={false}
                        setOrder={setOrder}
                        order={order}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de notificación legal"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            children={
                                <FormDetalle 
                                    detalle={notificacionesDetalle} 
                                    token={token}    
                                />
                            }
                        />
                    )}
                    {openFiltros && (
                        <ModalGenerico
                            title="Filtros"
                            open={openFiltros}
                            closeModal={() => { }}
                            cerrar={() => {
                                setOpenFiltros(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <FilterIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                            children={
                                <Filtros
                                    setOpenFiltros={setOpenFiltros}
                                    setFiltros={setFiltros}
                                    token={token}
                                    setUrlFech={setUrlFech}
                                />
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
