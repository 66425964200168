import { React, useEffect, useState, Fragment } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { getNacionalidadVictima, getOrigenEtnicoVictima, getIdiomaVictima, getIdiomaMaya } from "../victima/services";
import { useSelector } from "react-redux";
import { bloqueoFecha } from "../../../../../helpers/utils";
import Toggle from "../../../../../components/common/Toggle";
import Label from "../../../../../components/common/Label";
import NumberFormat from 'react-number-format';
import { getPeligrosidadSindicado } from "./services";

const useFetchNacionalidad = (token) => {
  const [state, setState] = useState({
    nacionalidad: [],
  });

  useEffect(() => {
    getNacionalidadVictima(token).then((nacionalidad) => {
      setState({
        nacionalidad: nacionalidad,
      });
    });
  }, [token]);

  return state;
};

const useFetchPeligrosidad = (token) => {
  const [state, setState] = useState({
    peligrosidad: [],
  });

  useEffect(() => {
    getPeligrosidadSindicado(token).then((peligrosidad) => {
      setState({
        peligrosidad: peligrosidad,
      });
    });
  }, [token]);

  return state;
};

const useFetchOrigenEtnico = (token) => {
  const [state, setState] = useState({
    origenetnico: [],
  });

  useEffect(() => {
    getOrigenEtnicoVictima(token).then((origenetnico) => {
      setState({
        origenetnico: origenetnico,
      });
    });
  }, [token]);

  return state;
};


const useFetchIdioma = (token) => {
  const [state, setState] = useState({
    idiomas: [],
  });

  useEffect(() => {
    getIdiomaVictima(token).then((idiomas) => {
      setState({
        idiomas: idiomas,
      });
    });
  }, [token]);

  return state;
};

const useFetchIdiomaMaya = (token) => {
  const [state, setState] = useState({
    idiomasmayas: [],
  });

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setState({
        idiomasmayas: data,
      });
    });
  }, [token]);

  return state;
};

const useFetchOrigenEtnicoMaya = (token) => {
  const [state, setState] = useState({
    origenetnicomaya: [],
  });

  useEffect(() => {
    getIdiomaMaya(token).then((data) => {
      setState({
        origenetnicomaya: data,
      });
    });
  }, [token]);

  return state;
};


// const useFetchIdiomaPref = (token) => {
//   const [state, setState] = useState({
//     idiomas: [],
//   });

//   useEffect(() => {
//     getIdiomaVictima(token).then((idiomaspref) => {
//       setState({
//         idiomaspref: idiomaspref,
//       });
//     });
//   }, [token]);

//   return state;
// };

export const DatosGeneralesVictimario = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { nacionalidad } = useFetchNacionalidad(token);
  const { peligrosidad } = useFetchPeligrosidad(token);
  const { origenetnico } = useFetchOrigenEtnico(token);
  const { idiomas } = useFetchIdioma(token);
  const { idiomasmayas } = useFetchIdiomaMaya(token);
  const { origenetnicomaya } = useFetchOrigenEtnicoMaya(token);
  const origenEtnicoMaya = props?.configuracion?.habilita_origen_etnico?.nombre;
  const idiomaMaya = props?.configuracion?.habilita_idioma_maya?.nombre;
  let origenEtnicoMayaTrue = false;
  let idiomaMayaTrue = false;

  if (origenEtnicoMaya === "MAYA") {
    origenEtnicoMayaTrue = true
  }

  if (idiomaMaya === "MAYA") {
    idiomaMayaTrue = true
  }

  return (
    <div className="grid grid-cols-12 gap-3">
      {props.values.conoce_al_sindicado &&
        <Fragment className="col-span-12">
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              type="text"
              name="primer_nombre"
              value={props.values.primer_nombre}
              onChange={props.handleChange}
              label="*Primer nombre"
              validacion={true}
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              type="text"
              name="segundo_nombre"
              value={props.values.segundo_nombre}
              onChange={props.handleChange}
              validacion={true}
              label="Segundo nombre"
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              type="text"
              name="otros_nombres"
              value={props.values.otros_nombres}
              onChange={props.handleChange}
              validacion={true}
              label="Tercer Nombre"
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              name="primer_apellido"
              type="text"
              value={props.values.primer_apellido}
              onChange={props.handleChange}
              validacion={true}
              label="Primer apellido"
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              name="segundo_apellido"
              type="text"
              value={props.values.segundo_apellido}
              onChange={props.handleChange}
              validacion={true}
              label="Segundo apellido"
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              type="text"
              name="apellido_casada"
              value={props.values.apellido_casada}
              onChange={props.handleChange}
              validacion={true}
              label="Apellido de casada"
            />
          </div>
          <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
            <InputSiavi
              type="date"
              name="fecha_nacimiento"
              value={props.values.fecha_nacimiento}
              onChange={props.handleChange}
              validacion={true}
              label="Fecha de nacimiento"
              maximo={bloqueoFecha()}
            />
          </div>

        </Fragment>
      }
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
        <Label text={(props.values.conoce_al_sindicado) ? "Edad" : "Edad estimada"} />
        <NumberFormat
          name="edad"
          value={props.values.edad}
          onChange={props.handleChange}
          allowNegative={false}
          className="mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
          focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
          sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <Label text="Nacionalidad" />
        <select
          name="nacionalidad"
          value={props.values.nacionalidad}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" disabled>
            Elegir Nacionalidad
          </option>
          {nacionalidad?.results?.map((item) => {
            return (
              <option key={item.nombre + "n"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="nacionalidad"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="pseudonimo"
          value={props.values.pseudonimo}
          onChange={props.handleChange}
          validacion={true}
          label="Pseudónimo"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12">
        <Label text="*Sexo" />
        <select
          name="sexo"
          value={props.values.sexo}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="">Seleccionar</option>
          <option value="HOMBRE">HOMBRE</option>
          <option value="MUJER">MUJER</option>
        </select>
        <ErrorMessage
          name="sexo"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      {/* <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Pertenece a Pandilla" />
        <Toggle
          enabled={props.values.pertenece_a_pandilla}
          name="pertenece_a_pandilla"
        />
      </div> */}
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2 flex flex-col items-center justify-center">
        <Label text="Uso de arma de fuego" />
        <Toggle
          enabled={props.values.uso_de_arma_de_fuego}
          name="uso_de_arma_de_fuego"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <Label text="Peligrosidad" />
        <select
          name="peligrocidad"
          value={props.values.peligrocidad}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" disabled>
            Elegir Peligrosidad
          </option>
          {peligrosidad?.results?.map((item) => {
            return (
              <option key={item.nombre + "p"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
      </div>
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <Label text="Origen Étnico" />
        <select
          name="origen_etnico"
          value={props.values.origen_etnico}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir origen etnico
          </option>
          {origenetnico?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="origen_etnico"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      {origenEtnicoMayaTrue && (
        <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
          <Label text="Origen Étnico Maya" />
          <select
            name="origen_etnico_maya"
            value={props.values?.origen_etnico_maya}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="">
              Elegir origen etnico maya
            </option>
            {origenetnicomaya?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="origen_etnico_maya"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>)}
      <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
        <Label text="Idioma" />
        <select
          name="idioma"
          value={props.values.idioma}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir Idioma
          </option>
          {idiomas?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="idioma"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      {idiomaMayaTrue && (
        <div className="sm:col-span-6 lg:col-span-3 xl:col-span-3 md:col-span-4 col-span-12 mb-2">
          <Label text="Idioma maya" />
          <select
            name="idioma_maya"
            value={props.values.idioma_maya}
            onChange={props.handleChange}
            className={props.selectClass}
          >
            <option value="" >
              Elegir Idioma Maya
            </option>
            {idiomasmayas?.results?.map((item) => {
              return (
                <option key={item.nombre} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
          <ErrorMessage
            name="idioma_maya"
            component="span"
            className="font-medium text-xs text-maivi-secondaryRed"
          />
        </div>)}
      {/*       <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Idioma de preferencia" />
        <select
          name="idioma_preferencia"
          value={props.values.idioma_preferencia}
          onChange={props.handleChange}
          className={props.selectClass}
        >
          <option value="" >
            Elegir Idioma de preferencia
          </option>
          {idiomaspref?.results?.map((item) => {
            return (
              <option key={item.nombre} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="idioma_preferencia"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div> */}
    </div>
  );
};
