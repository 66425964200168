import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { SelectSiavi } from "../../../../components/common/Select";
import { XCircleIcon } from "@heroicons/react/outline";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import {
  getPermisos,
  postProfesionalAtencion,
  getAtenciones,
  getRolesUsuarios,
  getSedesUsuarios,
  getTipoProfesionalUsuarios,
  getTipoPuestoUsuarios,
  deleteProfesionalAtencion,
  postUsuarioPermiso,
  deleteUsuarioPermiso,
  postRolesUsuario,
  deleteRolesUsuario,
  postSedesUsuario,
  deleteSedesUsuario,
  resetPassword,
  getUser,
  getServicios,
  postProfesionalServicio,
  deleteProfesionalServicio,
  getRolesVista
} from "../services";
import FormEliminar from "../../common/FormEliminar";
import Spinner from "../../../../components/common/Spinner";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import toast from "react-hot-toast";
import Return from "../../../../components/common/ButtonSalir";
import Divider from "../../../../components/common/Divider";
import ButtonsConfirmacion from "../../../../components/common/ButtonsConfirmacion";
import ModalGenerico from "../../../../components/common/Modal";
import ModalAmplio from "../../../../components/common/ModalAmplio";
import Toggle from "../../../../components/common/Toggle";
import PdfViewer from "../../../../components/common/PdfViewer";
import { ValidarCodigo } from "./validar_codigo";

export const Registrar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje,
  usuarioId,
  user
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [sedes, setSedes] = useState({});
  const [puestos, setPuestos] = useState({});
  const [profesionales, setProfesionales] = useState({});
  const [permisos, setPermisos] = useState({});
  const [roles, setRoles] = useState({});
  const [rolesVista, setRolesVista] = useState({});

  const [atenciones, setAtenciones] = useState({});
  const [servicios, setServicios] = useState({});
  const [sedesAgregadas, setSedesAgregadas] = useState(values.sedes);
  const [rolesAgregados, setRolesAgregados] = useState(values.groups);
  const [permisosAgregados, setPermisosAgregados] = useState(values.user_permissions);
  const [atencionesAgregadas, setAtencionesAgregadas] = useState(values.atenciones);
  const [serviciosAgregados, setServiciosAgregados] = useState(values.servicios);

  const [eliminar, setEliminar] = useState(false);
  const [eliminarSede, setEliminarSede] = useState(false);
  const [eliminarRol, setEliminarRol] = useState(false);
  const [eliminarPermiso, setEliminarPermiso] = useState(false);
  const [eliminarAtencion, setEliminarAtencion] = useState(false);
  const [eliminarServicio, setEliminarServicio] = useState(false);
  const [nombreEliminar, setNombreEliminar] = useState(false);
  const [itemEliminar, setItemEliminar] = useState("");
  const [resetear, setResetear] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [imprimir, setImprimir] = useState(false);
  const [usuario, setUsuario] = useState({});
  const [validarCodigo,setValidarCodigo] = useState(false);

  console.log(user);

  useEffect(() => {
    getSedesUsuarios(token).then((data) => {
      setSedes(data)
    });
  }, [token]);

  useEffect(() => {
    getTipoPuestoUsuarios(token).then((data) => {
      setPuestos(data)
    });
  }, [token]);

  useEffect(() => {
    getTipoProfesionalUsuarios(token).then((data) => {
      setProfesionales(data)
    });
  }, [token]);

  useEffect(() => {
    getRolesUsuarios(token).then((data) => {
      setRoles(data)
    });
  }, [token]);

  useEffect(() => {
    getPermisos(token).then((data) => {
      setPermisos(data)
    });
  }, [token]);

  useEffect(() => {
    getAtenciones(token).then((data) => {
      setAtenciones(data);
    });
  }, [token]);

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, [token]);

  useEffect(() => {
    getRolesVista(token).then((data) => {
      setRolesVista(data)
    });
  }, [token]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const deleteSede = (item) => {
    deleteSedesUsuario(item.id, token);
    let old = sedesAgregadas.filter((x) => x.id !== item.id);
    setSedesAgregadas([...old]);
  }

  const deleteRol = (item) => {
    deleteRolesUsuario(item.id, token);
    let old = rolesAgregados.filter((x) => x.id !== item.id);
    setRolesAgregados([...old]);
  }

  const deletePermiso = (item) => {
    deleteUsuarioPermiso(item.id, token);
    let old = permisosAgregados.filter((x) => x.id !== item.id);
    setPermisosAgregados([...old]);
  }

  const deleteAtencion = (item) => {
    deleteProfesionalAtencion(item.id, token);
    let old = atencionesAgregadas.filter((x) => x.id !== item.id);
    setAtencionesAgregadas([...old]);
  }

  const deleteServicio = (item) => {
    deleteProfesionalServicio(item.id, token);
    let old = serviciosAgregados.filter((x) => x.id !== item.id);
    setServiciosAgregados([...old]);
  }

  const buttonAddClass = `inline-flex items-center p-1 border border-transparent rounded-md 
  text-maivi-gray-50 hover:text-white hover:bg-maivi-primaryLightBlue focus:ring-maivi-primaryLightBlue`;
  const buttonRemoveClass = `inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 
  hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue`;

  const componentTable = (titulo, titulo2, body) => {
    return <div className="sm:col-span-3 col-span-6">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
        <div className="border-t border-gray-200 px-4 py-1 sm:p-0 h-64 overflow-y-auto">
          {
            (titulo2 === "") ? "" :
              <h3 className="text-sm leading-2 font-medium text-gray-900 p-1 ml-3">
                {titulo2}
              </h3>
          }
          <table className="table-auto min-w-full divide-y divide-maivi-gray-10 ">
            <thead className="bg-maivi-gray-5">
              <tr>
                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                  {titulo}
                </th>
                <th className="px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider">
                  Agregar
                </th>
              </tr>
            </thead>
            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
              {body}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }

  const menu = [
    {
      name: "Sedes"
    },
    {
      name: "Roles"
    },
    {
      name: "Permisos"
    },
    {
      name: "Atenciones"
    },
    {
      name: "Servicios"
    }
  ]
  return (
    <form autoComplete="off">
      <div className="mb-4 lg:grid-cols-2 gap-1 justify-center items-start sm:grid md:grid lg:flex  sm:grid-cols-1 sm:gap-4 -mt-32">
        <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
          <div className="flex flex-row justify-between">
            <h1>Editar usuario</h1>
            <Return ruta="/seguridad/usuarios/" />
          </div>
          <Divider />
          <div className="grid grid-cols-6 gap-3">
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                type="text"
                name="username"
                value={values.username}
                onChange={handleChange}
                disabled={true}
                validacion={true}
                label="Nombre de usuario"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                type="text"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                validacion={true}
                label="*Nombre"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                validacion={true}
                label="*Apellido"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                name="codigo_area"
                type="number"
                value={values.codigo_area}
                onChange={handleChange}
                validacion={true}
                label="Codigo area"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                name="telefono"
                type="number"
                value={values.telefono}
                onChange={handleChange}
                validacion={true}
                label="*Teléfono"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                validacion={true}
                label="*Correo Electrónico"
              />
            </div>
            <div className="sm:col-span-3 col-span-6 mt-1">
              <InputSiavi
                type="text"
                name="codigo_empleado"
                value={values.codigo_empleado}
                onChange={handleChange}
                label="Código empleado"
              />
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2  col-span-6 flex text-center flex-col mt-1">
              <Label text="Usuario interno" />
              <div className="mt-1">
                <Toggle
                  enabled={values.usuarioInterno}
                  name="usuarioInterno"
                  id="usuarioInterno"
                />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Inactivo/Activo" />
              <div className="mt-1">
                <Toggle enabled={values.activo} name="activo" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6  flex text-center flex-col">
              <Label text="Bloqueado/desbloqueado" />
              <div className="mt-1">
                <Toggle enabled={values.estado} name="estado" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Staff" />
              <div className="mt-1">
                <Toggle enabled={values.isStaff} name="isStaff" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Súper usuario" />
              <div className="mt-1">
                <Toggle enabled={values.superUsuario} name="superUsuario" />
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-2 col-span-6 flex text-center flex-col">
              <Label text="Hábilitado para turno" />
              <div className="mt-1">
                <Toggle
                  enabled={values.habilitadoTurno}
                  name="habilitadoTurno"
                />
                <ErrorMessage
                  name="habilitadoTurno"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
            </div>
            {values.usuarioInterno ? (
              <React.Fragment>
                <div className="sm:col-span-3 col-span-6 mt-1">
                  <SelectSiavi
                    name="sede"
                    value={values.sede}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir sede"
                    validacion={true}
                    label="Sede principal"
                    list={sedes?.results?.map((item) => {
                      return (
                        <option key={item.sede} value={item.id}>
                          {item.sede}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <SelectSiavi
                    name="tipoPuesto"
                    value={values.tipoPuesto}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir puesto"
                    validacion={true}
                    label="Tipo de puesto"
                    list={puestos?.results?.map((item) => {
                      return (
                        <option key={item.puesto} value={item.id}>
                          {item.puesto}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <SelectSiavi
                    name="tipoProfesion"
                    value={values.tipoProfesion}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir profesión"
                    validacion={true}
                    label="Tipo de profesión"
                    list={profesionales?.results?.map((item) => {
                      return (
                        <option key={item.profesion} value={item.id}>
                          {item.profesion}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <div className="mt-1">
                    <InputSiavi
                      type="text"
                      name="numeroColegiado"
                      value={values.numeroColegiado}
                      onChange={handleChange}
                      label="Número de colegiado"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3 col-span-6">
                  <SelectSiavi
                    name="rol_vista"
                    value={values.rol_vista}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir rol vista"
                    validacion={true}
                    label="Rol vista"
                    list={rolesVista?.results?.map((item) => {
                      return (
                        <option
                          key={item.nombre}
                          value={item.id}
                        >
                          {item?.nombre}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="sm:col-span-3 col-span-6 flex items-end">
                  <ButtonSiavi
                    type="button"
                    onClick={() => {
                      values.otra_sede = sedesAgregadas;
                      values.groups = rolesAgregados;
                      values.user_permissions = permisosAgregados;
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                    text="Actualizar"
                  />
                </div>
                <div className="col-span-6">
                  <button
                    onClick={() => {
                      setResetear(true);
                    }}
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 
                    font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Resetear contraseña
                  </button>
                </div>
               { !user?.telefono_confirmado &&
                 <div className="col-span-6">
                 <button
                   onClick={() => {
                     setValidarCodigo(true);
                   }}
                   type="button"
                   className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 
                   font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none 
                   focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                 >
                   Validar codigo
                 </button>
               </div>
               }
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
          <div className="mt-6">
            {hasError && <Alert type="error" mensaje={mensaje} />}
            {success && <Alert type="success" mensaje={mensaje} />}
          </div>

          {isSubmitting && <Spinner />}
        </div>
        {!values.usuarioInterno ? (
          ""
        ) : (
          <div className="shadow-xl sm:rounded-md px-2 py-3 bg-maivi-secondaryWhite">
            <Tab.Group>
              <Tab.List className="grid grid-cols-5 gap-6 rounded-md bg-blue-50 p-1">
                {menu.map((item) => {
                  return (
                    <Tab
                      key={item.name}
                      disabled={item.block}
                      className={({ selected }) =>
                        classNames(
                          "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                          "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                          selected
                            ? "bg-white shadow"
                            : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                        )
                      }
                    >
                      {item.name}
                    </Tab>
                  );
                })}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  {componentTable("Sedes", "",
                    sedes?.results?.map((item) => (
                      <tr
                        key={item.id + item.sede}
                        id={item.id}
                        className="hover:bg-maivi-gray-5"
                      >
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-gray-50">
                            {item.sede}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap">
                          <div className="text-xs text-maivi-gray-50">
                            <button
                              type="button"
                              onClick={() => {
                                postSedesUsuario(values.id, item.id, token).then((data) => {
                                  if (data?.status === 200 || data?.status === 201) {
                                    setSedesAgregadas([
                                      ...sedesAgregadas,
                                      data.data,
                                    ]);
                                    toast.success("Sede agregada con éxito..!");
                                  }
                                })
                                  .catch(function (error) {
                                    if (
                                      error.response.status >= 400 &&
                                      error.response.status <= 450
                                    ) {
                                      toast.error(error.response.data.message);
                                    } else if (error.response.status >= 500) {
                                      toast.error("Error interno comuniquese con el administrador");
                                    }
                                  });
                              }}
                              className={buttonAddClass}
                            >
                              Agregar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {
                    componentTable("Sede", "Sedes agregadas",
                      sedesAgregadas?.map((item) => (
                        <tr key={item.sede.sede} className="bg-green-50">
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.sede.sede}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  setEliminar(true);
                                  setItemEliminar(item);
                                  setEliminarSede(true);
                                  setNombreEliminar(item.sede.sede)
                                }}
                                className={buttonRemoveClass}
                              >
                                Borrar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </Tab.Panel>
                <Tab.Panel>
                  {
                    componentTable("Rol", "",
                      roles?.results?.map((item) => (
                        <tr
                          key={item.id + item.name}
                          id={item.id}
                          className="hover:bg-maivi-gray-5"
                        >
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.name}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  postRolesUsuario(values.id, item.id, token).then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                      setRolesAgregados([
                                        ...rolesAgregados,
                                        data.data,
                                      ]);
                                      toast.success("Rol agregado con éxito..!");
                                    }
                                  })
                                    .catch(function (error) {
                                      if (
                                        error.response.status >= 400 &&
                                        error.response.status <= 450
                                      ) {
                                        toast.error(error.response.data.message);
                                      } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                      }
                                    });
                                }}
                                className={buttonAddClass}
                              >
                                Agregar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                  {
                    componentTable("Rol", "Roles agregados",
                      rolesAgregados?.map((item) => (
                        <tr key={item.group.name} className="bg-green-50">
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.group.name}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  setEliminar(true);
                                  setItemEliminar(item);
                                  setEliminarRol(true);
                                  setNombreEliminar(item.group.name);
                                }}
                                className={buttonRemoveClass}
                              >
                                Borrar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </Tab.Panel>
                <Tab.Panel>
                  {
                    componentTable("Permiso", "",
                      permisos?.results?.map((item) => (
                        <tr
                          key={item.id + item.name}
                          id={item.id}
                          className="hover:bg-maivi-gray-5"
                        >
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.name}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  postUsuarioPermiso(values.id, item.id, token).then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                      setPermisosAgregados([
                                        ...permisosAgregados,
                                        data.data,
                                      ]);
                                      toast.success("Permiso agregado con éxito..!");
                                    }
                                  })
                                    .catch(function (error) {
                                      if (
                                        error.response.status >= 400 &&
                                        error.response.status <= 450
                                      ) {
                                        toast.error(error.response.data.message);
                                      } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                      }
                                    });
                                }}
                                className={buttonAddClass}
                              >
                                Agregar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                  {
                    componentTable("Permiso", "Permisos agregados",
                      permisosAgregados?.map((item) => (
                        <tr key={item.permission.name} className="bg-green-50">
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.permission.name}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  setEliminar(true);
                                  setItemEliminar(item);
                                  setEliminarPermiso(true);
                                  setNombreEliminar(item.permission.name);
                                }}
                                className={buttonRemoveClass}
                              >
                                Borrar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </Tab.Panel>
                <Tab.Panel>
                  {
                    componentTable("Atencion", "",
                      atenciones?.results?.map((item) => (
                        <tr
                          key={item.id + item.nombre}
                          id={item.id}
                          className="hover:bg-maivi-gray-5"
                        >
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.nombre}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  postProfesionalAtencion(values.id, item.id, token).then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                      setAtencionesAgregadas([
                                        ...atencionesAgregadas,
                                        data.data,
                                      ]);
                                      toast.success("Atención agregada con éxito..!");
                                    }
                                  })
                                    .catch(function (error) {
                                      if (
                                        error.response.status >= 400 &&
                                        error.response.status <= 450
                                      ) {
                                        toast.error(error.response.data.message);
                                      } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                      }
                                    });
                                }}
                                className={buttonAddClass}
                              >
                                Agregar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                  {
                    componentTable("Atencion", "Atenciones agregadas",
                      atencionesAgregadas?.map((item) => (
                        <tr key={item?.atencion_detalle?.nombre} className="bg-green-50">
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item?.atencion_detalle?.nombre}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  setEliminar(true);
                                  setItemEliminar(item);
                                  setEliminarAtencion(true);
                                  setNombreEliminar(item.atencion_detalle.nombre);
                                }}
                                className={buttonRemoveClass}
                              >
                                Borrar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </Tab.Panel>
                <Tab.Panel>
                  {
                    componentTable("Servicio", "",
                      servicios?.results?.map((item) => (
                        <tr
                          key={item.id + item.nombre}
                          id={item.id}
                          className="hover:bg-maivi-gray-5"
                        >
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item.nombre}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  postProfesionalServicio(values.id, item.id, token).then((data) => {
                                    if (data?.status === 200 || data?.status === 201) {
                                      setServiciosAgregados([
                                        ...serviciosAgregados,
                                        data.data,
                                      ]);
                                      toast.success("Servicio agregado con éxito..!");
                                    }
                                  })
                                    .catch(function (error) {
                                      if (
                                        error.response.status >= 400 &&
                                        error.response.status <= 450
                                      ) {
                                        toast.error(error.response.data.message);
                                      } else if (error.response.status >= 500) {
                                        toast.error("Error interno comuniquese con el administrador");
                                      }
                                    });
                                }}
                                className={buttonAddClass}
                              >
                                Agregar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                  {
                    componentTable("Servicio", "Servicios agregadas",
                      serviciosAgregados?.map((item) => (
                        <tr key={item?.servicio_detalle?.nombre} className="bg-green-50">
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              {item?.servicio_detalle?.nombre}
                            </div>
                          </td>
                          <td className="px-6 whitespace-nowrap">
                            <div className="text-xs text-maivi-gray-50">
                              <button
                                type="button"
                                onClick={() => {
                                  setEliminar(true);
                                  setItemEliminar(item);
                                  setEliminarServicio(true);
                                  setNombreEliminar(item?.servicio_detalle?.nombre);
                                }}
                                className={buttonRemoveClass}
                              >
                                Borrar
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            {eliminar && (
              <ModalGenerico
                title={`¿Esta seguro de eliminar el registro ${nombreEliminar}?`}
                open={eliminar}
                closeModal={() => { }}
                cerrar={() => {
                  setEliminar(false);
                }}
                icon={
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                  </div>
                }
                children={
                  <FormEliminar
                    aceptar={() => {
                      if (eliminarSede) {
                        deleteSede(itemEliminar);
                        setEliminarSede(false);
                      } else if (eliminarRol) {
                        deleteRol(itemEliminar);
                        setEliminarRol(false);
                      } else if (eliminarPermiso) {
                        deletePermiso(itemEliminar);
                        setEliminarPermiso(false);
                      } else if (eliminarAtencion) {
                        deleteAtencion(itemEliminar);
                        setEliminarAtencion(false);
                      } else if (eliminarServicio) {
                        deleteServicio(itemEliminar);
                        setEliminarAtencion(false);
                      }
                      setEliminar(false);
                    }}
                    cancelar={() => {
                      setEliminar(false);
                    }}
                  />
                }
              />
            )}
            {resetear && (
              <ModalGenerico
                title={`¿Esta seguro que desea resetear la contraseña?`}
                open={resetear}
                closeModal={() => { }}
                cerrar={() => {
                  setResetear(false);
                }}
                children={
                  <div>
                    <ButtonsConfirmacion
                      aceptar={async () => {
                        setCargando(true);
                        await resetPassword(values.username, token)
                          .then((data) => {
                            if (data?.status === 200 || data?.status === 201) {
                              getUser(usuarioId, token).then((data) => {
                                setCargando(false);
                                toast.success("Contraseña reseteada con éxito");
                                setUsuario(data);
                                setImprimir(true);
                                setResetear(false);
                              });
                            }
                          })
                          .catch(function (error) {
                            if (
                              error.response.status >= 400 &&
                              error.response.status <= 450
                            ) {
                              setCargando(false);
                              toast.error(error.response.data.message)
                            } else if (error.response.status >= 500) {
                              setCargando(false);
                              toast.error("Error interno comuniquese con el administrador")
                            }
                          });
                      }}
                      cancelar={() => {
                        setResetear(false);
                      }}
                    />
                    {cargando && <Spinner />}
                  </div>
                }
              />
            )}

            {imprimir && (
              <ModalAmplio
                title={`Boleta de acceso para usuario`}
                open={imprimir}
                closeModal={() => { }}
                cerrar={() => {
                  setImprimir(false);
                }}
                children={
                  <PdfViewer
                    data={usuario}
                    reporte={"reset-password"}
                    style={{ height: "80vh" }}
                  />
                }
              />
            )}

          {validarCodigo && (
              <ModalGenerico
                title={`Validar código`}
                open={validarCodigo}
                closeModal={() => { }}
                cerrar={() => {
                  setValidarCodigo(false);
                }}
                children={
                 <ValidarCodigo 
                  usuario={user}
                  setValidarCodigo={setValidarCodigo}
                 />
                }
              />
            )}
          </div>
        )}
      </div>
    </form>
  );
};


//1131