/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { FilterIcon, PaperClipIcon } from "@heroicons/react/outline";
import { getGenericCatalogo, getGenericCatalogoSearch } from "../../../helpers/catalogos";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import CustomTable from "../../../components/common/Table";
import ModalGenerico from "../../../components/common/Modal";
import Filtros from "../../catalogos/biblioteca/filtros";
import ButtonRefresh from "../../catalogos/common/ButtonRefresh";
import ModalAmplio from "../../../components/common/ModalAmplio";
import { GestionArchivos } from "../../../components/ArchivosSiavi/archivos";

const tableColumns = [
    { title: "id", value: "id", tipo: "text", ordenar: "id" },
    { title: "Fecha", value: "fecha_creacion", tipo: "text", ordenar: "fecha_creacion" },
    { title: "Nombre Documento", value: "nombre_documento", tipo: "text", ordenar: "nombre_documento" },
    { title: "Palabras Clave", value: "palabras_clave", tipo: "text" },
    { title: "Servicio", value: "servicio.nombre", tipo: "text", ordenar: "servicio.nombre" },
    { title: "Clasificación", value: "clasificacion.nombre", tipo: "text" },
    {
        title: "Por Servicio",
        value: "por_servicio",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

export const Biblioteca = () => {
    const query = new URLSearchParams(window.location.search);
    const value_route = query.get("value");
    const permisos = useSelector((state) => state.loginReducer.data.usuario.user.permissions);
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const [reFetch, setReFetch] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState("estado=true");
    const [tipo] = useState(()=>{
        if(value_route === "asistencia_legal"){
            return "&servicio__asitencia_legal=true";
        } else if(value_route === "atencion_victimologica"){
            return "&servicio__atencion_victimologica=true";
        } else {
            return ""
        }
    });
    const [archivos, setArchivos] = useState(false);
    const [recurso, setRecurso] = useState("");
    const permiso = "biblioteca";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    useEffect(() => {
        getGenericCatalogo(permiso, search, token, urlFetch, setTableLoading, setDataTable, order, filters+tipo);
        setReFetch(false);
    }, [urlFetch, token, reFetch, filters]);

    useEffect(() => {
        getGenericCatalogoSearch(permiso, search, token, setUrlFech, setTableLoading, setDataTable, order, filters);
    }, [search, reFetch, filters]);

    const pages = [
        {
            name: "Funcionalidades",
            href: "/funcionalidades-generales",
            current: false,
        },
        { name: `Biblioteca`, href: "#", current: true },
    ];

    const handleArchivos = (recurso) => {
        setArchivos(true);
        setRecurso(recurso);
    };

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row items-end">
                <BreadCrumb navegacion={pages} />
                {permisoAgregar && (
                    <div>
                        <ButtonAdd
                            className="ml-2"
                            text="Filtros"
                            type="button"
                            onClick={() => {
                                setOpenFilters(true);
                            }}
                            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                        />
                        <ButtonRefresh setReFetch={setReFetch} setFilters={setFilters} />
                    </div>
                )}
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={false}
                        setOrder={setOrder}
                        order={order}
                        showEditar={false}
                        showEliminar={false}
                        ocultarAcciones={false}
                        showAdjunt={true}
                        handleArchivos={handleArchivos}
                        nameAdjunt="Ver/Descargar"
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {openFilters && (
                        <ModalGenerico
                            title={`Aplicar filtros`}
                            open={openFilters}
                            closeModal={() => { }}
                            cerrar={() => {
                                setOpenFilters(false);
                            }}
                            children={
                                <Filtros 
                                    setFilters={setFilters} 
                                    setOpenFilters={setOpenFilters}
                                />
                            }
                        />
                    )}
                    {archivos && (
                        <ModalAmplio
                            title={`Adjuntar archivos`}
                            open={true}
                            closeModal={() => { }}
                            cerrar={() => {
                                setArchivos(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <PaperClipIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                            children={
                                <GestionArchivos
                                    idRecurso={recurso.id}
                                    recurso="BIBLIOTECA"
                                    claseDocumento="biblioteca"
                                />
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
