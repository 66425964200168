import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextAreaSiavi } from "../common/TextArea";
import { InputSiavi } from "../common/InputText";
import { ButtonSiavi } from "../common/Button";
import { useSelector } from "react-redux";
import { MapIcon, XCircleIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./Detalle";
import {
  deleteGestionDireccion,
  getDeptosVictima,
  getDireccionesVictima,
  getGrupoHabitacionalVictima,
  getMunicipioVictima,
  getTipoContactoVictima,
  getVialidadVictima,
  postGestionDireccion,
  putGestionDireccion,
} from "../../modulos/funcionalidades-generales/orientacion/editar/victima/services";
import {
  postGestionDireccionVictimario,
  putGestionDireccionVictimario
} from "../../modulos/funcionalidades-generales/orientacion/editar/sindicados/services";
import Spinner from "../common/Spinner";
import Label from "../common/Label";
import Toggle from "../common/Toggle";
import CustomTable from "../common/Table";
import ModalGenerico from "../common/Modal";
import FormEliminar from "../common/FormEliminar";
import Alert from "../common/Alert";
import * as Yup from "yup";

const tableColumns = [
  {
    title: "Tipo Contacto",
    value: "tipo_contacto_detalle.nombre",
    tipo: "text",
  },
  {
    title: "Principal",
    value: "direccion_principal",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
  },
];

const validaciones = Yup.object().shape({
  tipo_contacto: Yup.string()
    .required("Tipo Contacto es requerido")
    .max(100, "Máximo 100 caracteres"),
  departamento: Yup.string()
    .required("Departamento es requerido")
    .max(100, "Máximo 100 caracteres"),
  municipio: Yup.string()
    .required("Municipio es requerido")
    .max(100, "Máximo 100 caracteres"),
  referencia_ubicacion: Yup.string()
    .required("Referencia Ubicación es requerida")
    .max(100, "Máximo 100 caracteres"),
  grupo_habitacional: Yup.string()
    .max(100, "Máximo 100 caracteres"),
  nombre_grupo: Yup.string()
    .max(100, "Máximo 100 caracteres"),
  vialidad: Yup.string()
    .max(150, "Máximo 150 caracteres"),
  nombre_vialidad: Yup.string()
    .max(100, "Máximo 100 caracteres"),
  numero_vialidad: Yup.string()
    .max(150, "Máximo 150 caracteres"),
  numero_casa: Yup.string()
    .max(100, "Máximo 100 caracteres"),
  letra_casa: Yup.string()
    .max(100, "Máximo 100 caracteres"),
});

const useFetchTipoContacto = (token) => {
  const [state, setState] = useState({
    tipoContacto: [],
  });

  useEffect(() => {
    getTipoContactoVictima(token).then((tipoContacto) => {
      setState({
        tipoContacto: tipoContacto,
      });
    });
  }, [token]);

  return state;
};

const useFetchDepto = (token) => {
  const [state, setState] = useState({
    deptos: [],
  });

  useEffect(() => {
    getDeptosVictima(token).then((deptos) => {
      setState({
        deptos: deptos,
      });
    });
  }, [token]);

  return state;
};

const useFetchMunicipio = (token) => {
  const [state, setState] = useState({
    municipios: [],
  });

  useEffect(() => {
    getMunicipioVictima(token).then((municipios) => {
      setState({
        municipios: municipios,
      });
    });
  }, [token]);

  return state;
};

const useFetchGrupoHabitacional = (token) => {
  const [state, setState] = useState({
    grupoHabitacional: [],
  });

  useEffect(() => {
    getGrupoHabitacionalVictima(token).then((grupoHabitacional) => {
      setState({
        grupoHabitacional: grupoHabitacional,
      });
    });
  }, [token]);

  return state;
};

const useFetchVialidad = (token) => {
  const [state, setState] = useState({
    vialidad: [],
  });

  useEffect(() => {
    getVialidadVictima(token).then((vialidad) => {
      setState({
        vialidad: vialidad,
      });
    });
  }, [token]);

  return state;
};

export const GestionDireccion = (props) => {
  const [verDetalle, setDetalle] = useState(false);
  const [direccionDetalle, setDireccionDetalle] = useState({});
  const [isloading, setLoading] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [idDireccion, setIdDireccion] = useState("");
  const [direccion, setDireccion] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const token = useSelector((state) => state.loginReducer.data.token);
  const { tipoContacto } = useFetchTipoContacto(token);
  const { municipios } = useFetchMunicipio(token);
  const { grupoHabitacional } = useFetchGrupoHabitacional(token);
  const { vialidad } = useFetchVialidad(token);
  const { deptos } = useFetchDepto(token);
  let idPersona;
  let idSindicado;
  if (props.persona) {
    idPersona = props?.persona?.id;
    idSindicado = '';
  } else {
    idSindicado = props?.sindicado?.idSindicado;
    idPersona = '';
  }
  const [reFetch, setReFetch] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [nombreTipoContacto, setNombreTipoContacto] = useState("");
  const [nombreDepartamento, setNombreDepartamento] = useState("");
  const [nombreMunicipio, setNombreMunicipio] = useState("");
  const [nombrevialidad, setNombreVialidad] = useState("");
  const [nombreGrupo, setNombreGrupo] = useState("");

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );

  const permiso = "gestiondireccion";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  const handleVer = (item) => {
    setDireccionDetalle(item);
    setDetalle(true);
  };

  const handleEliminar = (id) => {
    setIdDireccion(id);
    setEliminar(true);
  };

  const handleEditar = (gestionDireccion) => {
    setEdit(true);
    setDireccion(gestionDireccion);
  };

  const fetchDirecciones = () => {
    getDireccionesVictima(idPersona, idSindicado, token).then((direcciones) => {
      setDataTable(direcciones);
      setTableLoading(false);
      setReFetch(false);
    });
  }

  useEffect(fetchDirecciones, [token, idPersona, idSindicado, reFetch]);

  const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
    focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
    sm:text-sm border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont`;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          persona: props?.persona?.id ? props?.persona?.id : null,
          sindicado: props?.sindicado?.idSindicado ? props?.sindicado?.idSindicado : null,
          nombre: isEdit ? direccion?.nombre : "",
          tipo_contacto: isEdit ? direccion?.tipo_contacto : "",
          municipio: isEdit ? direccion?.municipio_detalle?.id : "",
          departamento: isEdit ? direccion.municipio_detalle.departamento_detalle.id : "",
          zona: isEdit ? direccion.zona : "",
          grupo_habitacional: isEdit ? direccion?.grupo_habitacional : "",
          nombre_grupo: isEdit ? direccion?.nombre_grupo : "",
          vialidad: isEdit ? direccion?.vialidad : "",
          numero_vialidad: isEdit ? direccion?.numero_vialidad : "",
          nombre_vialidad: isEdit ? direccion?.nombre_vialidad : "",
          numero_casa: isEdit ? direccion?.numero_casa : "",
          letra_casa: isEdit ? direccion?.letra_casa : "",
          referencia_ubicacion: isEdit ? direccion?.referencia_ubicacion : "",
          otros: isEdit ? direccion?.otros : "",
          direccion_principal: isEdit ? direccion?.direccion_principal : true,
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (values.sindicado === null) {
            if (isEdit) {
              await putGestionDireccion(direccion.id, values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Dirección actualizada exitosamente");
                    setReFetch(true);
                    setEdit(false);
                    resetForm({});
                    values.direccion_principal = false;
                  } else {
                    setError(true);
                    setSuccess(false);
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            } else {
              await postGestionDireccion(values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Dirección agregada exitosamente");
                    setReFetch(true);
                    resetForm({});
                  } else {
                    setError(true);
                    setSuccess(false);
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            }
          } else {
            if (isEdit) {
              await putGestionDireccionVictimario(direccion.id, values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Dirección actualizada exitosamente");
                    setReFetch(true);
                    setEdit(false);
                    resetForm({});
                    values.direccion_principal = false;
                  } else {
                    setError(true);
                    setSuccess(false);
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    console.log(error.response.data.message)
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            } else {
              console.log(values)
              await postGestionDireccionVictimario(values, token)
                .then((data) => {
                  if (data?.status === 200 || data?.status === 201) {
                    setSuccess(true);
                    setError(false);
                    setMensaje("Dirección agregada exitosamente");
                    setReFetch(true);
                    resetForm({});
                  } else {
                    setError(true);
                    setSuccess(false);
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.status === 400 ||
                    error.response.status === 401
                  ) {
                    setError(true);
                    setSuccess(false);
                    setMensaje(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    setError(true);
                    setSuccess(false);
                    setMensaje("Error interno comuniquese con el administrador");
                  }
                });
            }
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-auto grid grid-cols-12 gap-3">
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="*Tipo Contacto" />
                <div className="mt-1">
                  <select
                    name="tipo_contacto"
                    value={values.tipo_contacto}
                    onChange={(e) => {
                      setFieldValue('tipo_contacto', e.target.value);
                      let combo = document.getElementById("tipo_contacto");
                      let selected = combo?.options[combo.selectedIndex].text;
                      setNombreTipoContacto(selected);
                    }}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir Tipo Contacto
                    </option>
                    {tipoContacto?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="tipo_contacto"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="*Departamento" />
                <div className="mt-1">
                  <select
                    name="departamento"
                    value={values.departamento}
                    onChange={(e) => {
                      setFieldValue("departamento",e.target.value);
                      let combo = document.getElementById("departamento");
                      let selected = combo?.options[combo.selectedIndex].text;
                      setNombreDepartamento(selected);
                    }}
                    className={selectClass}
                  >
                    <option value="">
                      Elegir Departamento
                    </option>
                    {deptos?.results?.map((item) => {
                      return (
                        <option
                          key={item.id + "dp" + item.nombre}
                          value={item.id}
                        >
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="departamento"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="*Municipio" />
                <div className="mt-1">
                  <select
                    name="municipio"
                    value={values.municipio}
                    onChange={(e) => {
                      setFieldValue('municipio', e.target.value);
                      let combo = document.getElementById("municipio");
                      let selected = combo?.options[combo.selectedIndex].text;
                      setNombreMunicipio(selected);
                    }}
                    className={selectClass}
                  >
                    <option value="">
                      Elegir Municipio
                    </option>
                    {municipios?.results?.filter(x => x.departamento === Number(values.departamento)).map((item) => {
                      return (
                        <option key={item.nombre + "mn" + item.id} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="municipio"
                    component="span"
                    className="font-medium text-xs text-maivi-secondaryRed"
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="zona"
                  value={values.zona}
                  onChange={handleChange}
                  label="Zona"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="Grupo Habitacional" />
                <div className="mt-1">
                  <select
                    name="grupo_habitacional"
                    value={values.grupo_habitacional}
                    onChange={(e) => {
                      setFieldValue('grupo_habitacional', e.target.value);
                      let combo = document.getElementById("grupo_habitacional");
                      let selected = combo?.options[combo.selectedIndex].text;
                      setNombreGrupo(selected);
                    }}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir Grupo Habitacional
                    </option>
                    {grupoHabitacional?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="nombre_grupo"
                  value={values.nombre_grupo}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre del Grupo Habitacional"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="Vialidad" />
                <div className="mt-1">
                  <select
                    name="vialidad"
                    value={values.vialidad}
                    onChange={(e) => {
                      setFieldValue('vialidad', e.target.value);
                      let combo = document.getElementById("vialidad");
                      let selected = combo?.options[combo.selectedIndex].text;
                      setNombreVialidad(selected);
                    }}
                    className={selectClass}
                  >
                    <option value="" disabled>
                      Elegir Vialidad
                    </option>
                    {vialidad?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="numero_vialidad"
                  value={values.numero_vialidad}
                  onChange={handleChange}
                  validacion={true}
                  label="Número/Nombre Vialidad"
                />
              </div>
              {/*               <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="nombre_vialidad"
                  value={values.nombre_vialidad}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre Vialidad"
                />
              </div> */}
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="numero_casa"
                  value={values.numero_casa}
                  onChange={handleChange}
                  validacion={true}
                  label="Número/Letra Casa/Apartamento/Lote"
                />
              </div>
              {/*               <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <InputSiavi
                  type="text"
                  name="letra_casa"
                  value={values.letra_casa}
                  onChange={handleChange}
                  validacion={true}
                  label="Letra Casa/Apartamento/Lote"
                />
              </div> */}
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                <Label text="*Referencia ubicación" />
                <TextAreaSiavi
                  name="referencia_ubicacion"
                  type="text"
                  rows={2}
                  columns={50}
                  value={values.referencia_ubicacion}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="referencia_ubicacion"
                  component="span"
                  className="font-medium text-xs text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 mb-4">
                <Label text="Otros" />
                <TextAreaSiavi
                  name="otros"
                  type="text"
                  rows={2}
                  columns={50}
                  value={values.otros}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center mt-5">
                <Label text="Dirección Principal" />
                <Toggle
                  enabled={values.direccion_principal}
                  name="direccion_principal"
                  onChange={handleChange}
                />
              </div>
              {permisoAgregar && (
                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                  <ButtonSiavi
                    type="submit"
                    disabled={isSubmitting}
                    text={isEdit ? "Actualizar" : "Guardar"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            </div>
            {isSubmitting && <Spinner />}
            {
              nombreTipoContacto &&
              <h1 className="text-sm text-maivi-primaryFont uppercase">{
                `Direccion de ${nombreTipoContacto}, vialidad ${values.numero_vialidad} ${nombrevialidad}, ${values.numero_casa} ${values.letra_casa},
                 ${values.grupo_habitacional} ${nombreGrupo}, zona ${values.zona}, ${values.referencia_ubicacion},
                 ${nombreMunicipio}, 
                 ${nombreDepartamento}, 
                 GUATEMALA`
              }
              </h1>
            }

          </form>
        )}
      </Formik>
      <div className="mb-4 mt-3">
        {
          success && <Alert type="success" mensaje={mensaje} />
        }
        {
          error && <Alert type="error" mensaje={mensaje} />
        }
      </div>
      <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
        <CustomTable
          dataTable={dataTable}
          tableColumns={tableColumns}
          handleEliminar={handleEliminar}
          handleEditar={handleEditar}
          handleVer={handleVer}
          permiso={permiso}
          showEliminar={true}
          showVer={true}
          showEditar={true}
        />
        {tableLoading && (
          <div className="mt-10 mb-10">
            <Spinner />
          </div>
        )}
        {verDetalle && (
          <ModalGenerico
            title={"Detalle de dirección"}
            open={verDetalle}
            closeModal={() => { }}
            cerrar={() => {
              setDetalle(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <MapIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
              </div>
            }
            children={
              <FormDetalle detalle={direccionDetalle} cancelar={setEliminar} />
            }
          />
        )}
        {isEliminar && (
          <ModalGenerico
            title={`¿Esta seguro de eliminar el registro?`}
            open={isEliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
              </div>
            }
            children={
              <FormEliminar
                aceptar={() => {
                  setLoading(true);
                  deleteGestionDireccion(idDireccion, token).then(() => {
                    setEliminar(false);
                    setLoading(false);
                    setReFetch(true);
                  });
                }}
                isloading={isloading}
                cancelar={() => {
                  setEliminar(false);
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
