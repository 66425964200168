import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const getSedes = async (urlPage,token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/sedes/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSedesExp = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDeptosSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/departamento/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCoberturasSedes = async (token,id) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedecobertura/?sede=${id}&page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMunicipiosSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/municipio/?page_size=3000`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tiposede/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSede = async (id,token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSedes = async (data,token) => {

    let sedeSeguimiento;

    if(Number(data.sede_seguimiento) === 0 ){
        sedeSeguimiento = null;
    }else{
        sedeSeguimiento = Number(data.sede_seguimiento);
    }

    try {
        let body = {
            sede: data.sede,
            direccion: data.direccion,
            departamento: Number(data.departamento),
            municipio: Number(data.municipio),
            telefono: data.telefono,
            codigo: data.codigo,
            descripcion: data.descripcion,
            hacen_turnos: data.hacen_turnos,
            tipo_sede: Number(data.tipo_sede),
            estado: data.estado,
            cobertura: data.cobertura,
            es_sede_seguimiento: data.es_sede_seguimiento,
            sede_seguimiento: sedeSeguimiento
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/sedes/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSedes = async (id,data,token) => {

    let sedeSeguimiento;

    if(Number(data.sede_seguimiento) === 0 ){
        sedeSeguimiento = null;
    }else{
        sedeSeguimiento = Number(data.sede_seguimiento);
    }

    try {
        let body = {
            sede: data.sede,
            direccion: data.direccion,
            departamento: Number(data.departamento),
            municipio: Number(data.municipio),
            telefono: data.telefono,
            codigo: data.codigo,
            descripcion: data.descripcion,
            hacen_turnos: data.hacen_turnos,
            tipo_sede: Number(data.tipo_sede),
            estado: data.estado,
            es_sede_seguimiento: data.es_sede_seguimiento,
            sede_seguimiento: sedeSeguimiento
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/sedes/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSedes = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSedeCobertura = async (idSede,idCobertura,token) => {
    try {
        let body = {
            sede: idSede,
            cobertura: idCobertura
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/catalogos/sedecobertura/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const deleteSedesCobertura = async (id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedecobertura/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};