import axios from "axios";

const {
  REACT_APP_API_URL
} = process.env;


export const getRecursos = async (urlPage, search, order, filtros, token) => {
  try {
    let urlFetch = urlPage === undefined
      ? `${REACT_APP_API_URL}/catalogos/recursodisponibilidad/?search=${search.value}&ordering=${order}&${filtros}`
      : `${urlPage}&search=${search.value}&ordering=${order}&${filtros}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const recursos = async (urlPage, search, order, filtros, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/catalogos/recursodisponibilidad/?search=${search.value}&ordering=${order}&${filtros}`
        : `${urlPage}&search=${search.value}&ordering=${order}&${filtros}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};


export const getCatalogoRecursos = async (token) => {
  try {
    let result = await fetch(`${REACT_APP_API_URL}/catalogos/recursos/?page_size=3000&estado=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getCasos = async (buscar, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/caso/?page_size=100&search=${buscar}&estado=true`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getExpedientes = async (caso, profesional, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/expediente/?page_size=100&caso=${caso}&profesional_asignado=${profesional}`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const getAcciones = async (id, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};


export const actualizarRecurso = async (id, data, token) => {
  try {
    let body = {
      disponible: data.disponible,
      descripcion: data.descripcion,
      fecha: data.fecha,
      hora: data.hora,
      recurso: data?.recurso?.id,
      accion_recurso: data?.accion
    }
    let result = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/catalogos/recursodisponibilidad/${id}/`,
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return result;

  } catch (e) {
    console.log('error', e);
  }
};

export const actualizarAccion = async (id, tipo_accion,usuario,now, token) => {

  try {

    let body = {

      //estado: false,
      accion_seguimiento: tipo_accion,
      profesional_cambia_estado: usuario,
      fecha_cambia_estado: now,
      observaciones:"CANCELACIÓN - Espacio Físico Reservado"
    }
    let result = axios({
      method: 'PUT',
      url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    });

    return result;

  } catch (e) {
    console.log('error', e);
  }
};