import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const acciones = async (urlPage,caso,filtros, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?caso=${caso}&es_seguimiento=true&${filtros}`
        :`${urlPage}&es_seguimiento=true&caso=${caso}&${filtros}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

// export const acciones = async (urlPage,tipo,filters,order, token) => {
//   try {
//     let urlFetch =
//       urlPage === undefined
//         ? `${REACT_APP_API_URL}/registro/${tipo}/?es_accion=true${filters}&ordering=${order}`
//         : `${urlPage}&${filters}&es_accion=true&ordering=${order}`;
//     let result = await fetch(urlFetch, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//       },
//     }).then((data) => data.json());

//     return result;
//   } catch (e) {
//     console.log("error", e);
//   }
// };

export const getModalidades = async(token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/catalogos/modalidad/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAccionesSeguimiento = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postAsignaciones = async (profesional, expedientes,token) => {
  try {
      let ArregloExpedientes = [];
      expedientes.forEach(element => {
        ArregloExpedientes.push(element.id);
      });

      let body ={
        profesional: profesional.value, 
        expedientes:ArregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getResumen = async(tipo,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/resumenaccionescaso/?${tipo}=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const putAcciones = async (id,body,token) => {
  try {
      let result = axios({
          method: 'PUT',
          url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getHistoricoFechas = async(id,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/casoseguimientoaccionhistoricoplanificacion/?caso_seguimiento_accion=${id}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getHistoricoAsignaciones = async(id,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/historicoasignaciones/?accion_seguimiento_caso=${id}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getMotivos = async(token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/motivocambioplanificacion/?estado=true&page_size=1000`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };