/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { bitacora, bitacoraExport } from "./services";
import { FilterIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { FormDetalle } from "./Detalle";
import ModalBig from "../../../components/common/ModalTwo";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import ModalGenerico from "../../../components/common/Modal";
import ExportButton from "../../../components/common/ExportButton";

const tableColumns = [
  { title: "Fecha", value: "fecha", tipo: "datetime", ordenar: "createdAt" },
  { title: "Tabla", value: "tabla", tipo: "text", ordenar: "tabla" },
  { title: "Módulo", value: "modulo", tipo: "text" },
  { title: "Transacción", value: "transaccion", tipo: "text", ordenar: "transaccion" },
  { title: "Usuario", value: "usuario", tipo: "text", ordenar: "usuario" }
];

const permiso = "bitacora";

export const TableBitacora = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const [verDetalle, setDetalle] = useState(false);
  const [bitacoraDetalle, setBitacoraDetalle] = useState({});
  const [urlFetch, setUrlFech] = useState("page=1");
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filtrar, setFiltrar] = useState(false);
  const [order, setOrder] = useState("createdAt");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [tabla, setTabla] = useState("");
  const [transaccion, setTransaccion] = useState("");
  const [profesional, setProfesional] = useState("");
  const [buscar, setBuscar] = useState("");
  const [modulo, setModulo] = useState("");

  const pages = [
    { name: 'Seguridad', href: '/seguridad', current: false },
    { name: 'Bitacora', href: '#', current: true },
  ]

  const exportData = (tipo) => {
    setTableLoading(true);
    bitacoraExport(fechaInicio, fechaFin, tabla, modulo, transaccion, profesional, buscar, token).then( async(data) => {
      const fileName = 'Bitacora';
      let dataExport = data?.data;
      let columns = [
        { name: "Fecha", value: "fecha" },
        { name: "Tabla", value: "tabla" },
        { name: "Módulo", value: "modulo" },
        { name: "Transacción", value: "transaccion" },
        { name: "Usuario", value: "usuario" }
      ];

      let raw = JSON.stringify({ columns, data: dataExport, fileName, tipo });
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: 'follow'
      };
      await fetch(`${process.env.REACT_APP_URL_REPORTES}/export-file`, requestOptions)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          setTableLoading(false);
          window.open(url, '_blank');
        })
        .catch(error => console.log('error', error));

    });
  }

  const handleVer = (item) => {
    setBitacoraDetalle(item);
    setDetalle(true);
  }

  useEffect(() => {
    setTableLoading(true);
    bitacora(Number(urlFetch.split("=")?.[1]), fechaInicio, fechaFin, tabla, modulo, transaccion, profesional, order, buscar, token).then((data) => {
      setDataTable(data?.data);
      setTableLoading(false);
    });
  }, [fechaInicio, fechaFin, tabla, modulo, transaccion, profesional, order, buscar, urlFetch])

  const fetchBuscarBitacora = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setBuscar(search.value);
      let value = {
        value: search.value,
        fetch: false,
        clean: false,
      };
      store.dispatch(ChangeSearch({ ...search, ...value }));
    }
  };

  useEffect(fetchBuscarBitacora, [search]);

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div className="justify-end">
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setFiltrar(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
          <ExportButton
            handlePdf={() => {
              exportData('pdf');
            }}
            show={'ambos'}
            handleExcel={() => {
              exportData('xls');
            }}
          />
        </div>
      </div>
      <div className="flex flex-col pt-2">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-md overflow-hidden border-b border-maivi-gray-10 sm:rounded-lg">
              <CustomTable
                dataTable={dataTable}
                tableColumns={tableColumns}
                handleVer={handleVer}
                fetchPage={setUrlFech}
                pagination={true}
                permiso={permiso}
                setOrder={setOrder}
                order={order}
              />
              {tableLoading && (
                <div className="mt-10 mb-10">
                  <Spinner />
                </div>
              )}
              {verDetalle && (
                <ModalBig
                  title={"Detalle de bitacora"}
                  open={verDetalle}
                  closeModal={() => { }}
                  cerrar={() => { setDetalle(false) }}
                  children={
                    <FormDetalle detalle={bitacoraDetalle} />
                  }
                />
              )}
              {filtrar && (
                <ModalGenerico
                  title={"Filtros"}
                  open={filtrar}
                  closeModal={() => { }}
                  cerrar={() => { setFiltrar(false) }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                      <FilterIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                    </div>
                  }
                  children={
                    <Filtros
                      setFechaInicio={setFechaInicio}
                      setFechaFin={setFechaFin}
                      filtrar={setFiltrar}
                      setTabla={setTabla}
                      setModulo={setModulo}
                      setTransaccion={setTransaccion}
                      setProfesional={setProfesional}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
