import axios from 'axios';

const {
    REACT_APP_API_URL
} = process.env;

export const postPersona = async (data,token) => {
    let numeroDocto;
    let tipoDocto;
    if(data.noDocumento === "" && Number(data.tipoDocumento) === 0){
        numeroDocto = null;
        tipoDocto = null;
    }else{
        numeroDocto = data.noDocumento;
        tipoDocto = Number(data.tipoDocumento);
    }
    try {
        let body = {
            no_documento: numeroDocto,
            primera_visita: data.primeraVisita,
            sin_documento: data.sin_documento,
            primer_nombre: data.primerNombre,
            segundo_nombre: data.segundoNombre,
            segundo_apellido: data.segundoApellido,
            otros_nombres: data.tercerNombre,
            primer_apellido: data.primerApellido,
            apellido_casada: data.apellidoCasada,
            fecha_nacimiento: data.fechaNacimiento,
            sexo: data.sexo,
            como_le_gusta_que_le_llamen: data.alias,
            tipo_documento: tipoDocto,
            pais: Number(data.pais),
            nacionalidad: Number(data.nacionalidad),
            sede: Number(data.sede)
        }

        let bodyResult = axios({
            method: 'post',
            url: `${REACT_APP_API_URL}/registro/gestionpersonaregistro/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonas = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getNacionalidades = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/nacionalidad/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPaises = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/pais/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoDocumentos = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/tipodocumento/?page_size=1000&estado=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const buscarPersona = async (pais,tipo_documento,documento,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?pais=${pais}&tipo_documento=${tipo_documento}&search=${documento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getClaseDocumentos = async (token) => {
    try {
        let bodyResult = axios({
            method: 'get',
            url: `${REACT_APP_API_URL}/catalogos/clasedocumento/?search=personas`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};