
const {
    REACT_APP_API_URL
} = process.env;

export const getAcciones = async (urlPage, token) => {
    try {
        console.log("ERR:", urlPage);
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/accionesseguimientos/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicioPropio = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtencion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAccionesParaSeg = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=3000&estado=true&accion=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAcciones = async (values, token) => {
    try {
        let body = {
            nombre: values.nombre,
            descripcion: values.descripcion,
            estado: values.estado,
            accion: values.accion,
            seguimiento: values.seguimiento,
            aplica_meta: values.aplica_meta,
            requiere_autorizacion: values.requiere_autorizacion,
            es_audiencia: values.es_audiencia,
            habilita_planificacion: values.habilita_planificacion,
            servicio: (values.servicio === "" ? null : values.servicio),
            atencion: (values.atencion === "" ? null : values.atencion),
            es_procuracion: values.es_procuracion,
            solicita_accion: values.solicita_accion,
            seguimiento_accion_solicitada: values.seguimiento_accion_solicitada,
            rep_digna: values.rep_digna,
            medida_victimologica: values.medida_victimologica
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putAcciones = async (id, values, token) => {
    try {
        let body = {
            nombre: values.nombre,
            descripcion: values.descripcion,
            estado: values.estado,
            accion: values.accion,
            seguimiento: values.seguimiento,
            aplica_meta: values.aplica_meta,
            requiere_autorizacion: values.requiere_autorizacion,
            es_audiencia: values.es_audiencia,
            habilita_planificacion: values.habilita_planificacion,
            servicio: (values.servicio === "" ? null : values.servicio),
            atencion: (values.atencion === "" ? null : values.atencion),
            es_procuracion: values.es_procuracion,
            solicita_accion: values.solicita_accion,
            seguimiento_accion_solicitada: values.seguimiento_accion_solicitada,
            rep_digna: values.rep_digna,
            medida_victimologica: values.medida_victimologica
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteAcciones = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};