import {
    store
} from '../app/redux/store/store';
import {
    ChangeSearch
} from '../app/redux/actions/search';

const {
    REACT_APP_API_URL
} = process.env;



export const getGenericCatalogoSearch = (permiso, search, token, setUrlFech, setTableLoading, setDataTable, order) => {
    if ((search.fetch && search.value.length >= search.min) || (!search.fetch && search.clean)) {
        setUrlFech(undefined);
        setTableLoading(true);

        getCatalogos(undefined, permiso, token, search.value, order).then((municipio) => {
            setDataTable(municipio);
            setTableLoading(false);
            let value = {
                value: search.value,
                fetch: false,
                clean: false
            };
            store.dispatch(ChangeSearch({...search,...value}));
        });
    }
}


export const getGenericCatalogo = (permiso, search, token, urlFetch, setTableLoading, setDataTable, order,filters) => {
    getCatalogos(urlFetch, permiso, token, search.value, order,filters).then((municipio) => {
        setDataTable(municipio);
        setTableLoading(false);
    });
}


export const getCatalogos = async (urlPage, recurso, token, search, order,filters) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/catalogos/${recurso}/?search=${search}&ordering=${order}&${filters}` :
            `${urlPage}&search=${search}&ordering=${order}&${filters}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCatalogos = async (body, recurso, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/${recurso}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCatalogos = async (id, body, recurso, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/${recurso}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCatalogos = async (id, recurso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/${recurso}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGenericSeguridadSearch = (permiso, search, token, setUrlFech, setTableLoading, setDataTable, order) => {
    if ((search.fetch && search.value.length >= search.min)|| (!search.fetch && search.clean)) {
        setUrlFech(undefined);
        setTableLoading(true);

        getSeguridad(undefined, permiso, token, search.value, order).then((municipio) => {
            setDataTable(municipio);
            setTableLoading(false);
            let value = {
                value: search.value,
                fetch: false,
                clean: false
            };
            store.dispatch(ChangeSearch({...search,...value}));
        });
    }

}


export const getGenericSeguridad = (permiso, search, token, urlFetch, setTableLoading, setDataTable, order,filters) => {
    getSeguridad(urlFetch, permiso, token, search.value, order,filters).then((municipio) => {
        setDataTable(municipio);
        setTableLoading(false);
    });
}

export const getSeguridad = async (urlPage, recurso, token, search, order,filters) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/seguridad/${recurso}/?search=${search}&ordering=${order}&${filters}` :
            `${urlPage}&search=${search}&ordering=${order}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSeguridad = async (body, recurso, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/${recurso}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSeguridad = async (id, body, recurso, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/${recurso}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSeguridad = async (id, recurso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/${recurso}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGenericEvaluacionVictimaSearch = (permiso, search, token, setUrlFech, setTableLoading, setDataTable, order,filters) => {
    if ((search.fetch && search.value.length >= search.min)|| (!search.fetch && search.clean)) {
        setUrlFech(undefined);
        setTableLoading(true);

        getEvaluacionVictima(undefined, permiso, token, search.value, order,filters).then((municipio) => {
            setDataTable(municipio);
            setTableLoading(false);
            let value = {
                value: search.value,
                fetch: false,
                clean: false
            };
            store.dispatch(ChangeSearch({...search,...value}));
        });
    }

}

export const getGenericEvaluacionVictima = (permiso, search, token, urlFetch, setTableLoading, setDataTable, order,filters) => {
    getEvaluacionVictima(urlFetch, permiso, token, search.value, order,filters).then((municipio) => {
        setDataTable(municipio);
        setTableLoading(false);
    });
}

export const getEvaluacionVictima = async (urlPage, recurso, token, search, order,filters) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/evaluacion-victima/${recurso}/?search=${search}&ordering=${order}&${filters}` :
            `${urlPage}&search=${search}&ordering=${order}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvaluacionVictima = async (body, recurso, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/${recurso}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEvaluacionVictima = async (id, body, recurso, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/${recurso}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteEvaluacionVictima = async (id, recurso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/evaluacion-victima/${recurso}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGenericRegistroSearch = (permiso, search, token, setUrlFech, setTableLoading, setDataTable, order) => {
    if ((search.fetch && search.value.length >= search.min)|| (!search.fetch && search.clean)) {
        setUrlFech(undefined);
        setTableLoading(true);

        getRegistro(undefined, permiso, token, search.value, order).then((municipio) => {
            setDataTable(municipio);
            setTableLoading(false);
            let value = {
                value: search.value,
                fetch: false,
                clean: false
            };
            store.dispatch(ChangeSearch({...search,...value}));
        });
    }

}

export const getGenericRegistro = (permiso, search, token, urlFetch, setTableLoading, setDataTable, order,filters) => {
    getRegistro(urlFetch, permiso, token, search.value, order, filters).then((municipio) => {
        setDataTable(municipio);
        setTableLoading(false);
    });
}

export const getRegistro = async (urlPage, recurso, token, search, order,filters) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/registro/${recurso}/?search=${search}&ordering=${order}&${filters}` :
            `${urlPage}&search=${search}&ordering=${order}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postRegistro = async (body, recurso, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/${recurso}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putRegistro = async (id, body, recurso, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/${recurso}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRegistro = async (id, recurso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/${recurso}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGenericGruposSearch = (permiso, search, token, setUrlFech, setTableLoading, setDataTable, order) => {
    if ((search.fetch && search.value.length >= search.min)|| (!search.fetch && search.clean)) {
        setUrlFech(undefined);
        setTableLoading(true);

        getGrupos(undefined, permiso, token, search.value, order).then((municipio) => {
            setDataTable(municipio);
            setTableLoading(false);
            let value = {
                value: search.value,
                fetch: false,
                clean: false
            };
            store.dispatch(ChangeSearch({...search,...value}));
        });
    }

}

export const getGenericGrupos = (permiso, search, token, urlFetch, setTableLoading, setDataTable, order,filters) => {
    getGrupos(urlFetch, permiso, token, search.value, order, filters).then((municipio) => {
        setDataTable(municipio);
        setTableLoading(false);
    });
}

export const getGrupos = async (urlPage, recurso, token, search, order,filters) => {
    try {
        let urlFetch = urlPage === undefined ?
            `${REACT_APP_API_URL}/grupos-profesionales/${recurso}/?search=${search}&ordering=${order}&${filters}` :
            `${urlPage}&search=${search}&ordering=${order}`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postGrupos = async (body, recurso, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/${recurso}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putGrupos = async (id, body, recurso, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/${recurso}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteGrupos = async (id, recurso, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/grupos-profesionales/${recurso}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};