import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getBandeja = async (urlPage, search,profesional,order,filters,token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/bandejaprofesionaldestino/?search=${search.value}&profesional_destino=${profesional}&${filters}&ordering=${order}`
        :`${urlPage}&search=${search.value}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const bandeja = async (urlPage,search,profesional,order,filters,token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/bandejaprofesionaldestino/?search=${search.value}&profesional_destino=${profesional}&${filters}&ordering=${order}`
        : `${urlPage}&search=${search.value}&profesional_destino=${profesional}&${filters}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const putBandeja = async (values,token) => {
  try {
      let body ={
        leido: values.leido,
        //fecha_leido: values.fecha_leido,
        enviado_al_correo: values.enviado_al_correo,
        correo_destino: values.correo_destino,
        respondido: values.respondido,
        bandeja: values.bandeja.id,
        profesional_destino: values.profesional_destino.id
      }

      let result = axios({
          method: 'PUT',
          url: `${REACT_APP_API_URL}/registro/bandejaprofesionaldestino/${values.id}/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const putDiligencia = async (id,token) => {
  try {
      let body ={
        enterado: true
      }

      let result = axios({
          method: 'PUT',
          url: `${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/${id}/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postAsignaciones = async (expedientes,token) => {
  try {
      let body ={
        aceptado: true, 
        expedientes:expedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/aceptarasignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      }); 

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getDiligencia = async (id,token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/${id}/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getDiligenciasFiltro = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/registro/expedientediligenciasurgentes/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postBandejaUsuario = async(bandeja,caso,mensaje,usuario, token) => {
  try {
      let body = {
          bandeja: bandeja,
          titulo: "Respuesta a profesional",
          mensaje: mensaje,
          tipo_accion: null,
          tipo_seguimiento: null,
          requiere_respuesta: true,
          notificacion_legal: false,
          profesional_origen: usuario,
          caso: caso,
          //persona: null,
          expediente: null,
          tipo_mensaje: null,
          atencion: null,
          referencia: null
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/bandejausuario/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
    let result = await fetch(urlFetch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(data => data.json());

    return result;

  } catch (e) {
    console.log('error', e);
  }
};