import { React, useState } from "react";
import Divider from "../../../../../components/common/Divider";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import Label from "../../../../../components/common/Label";
import ButtonAdd from "../../../../../components/common/ButtonAdd";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { IdentificacionVictimario } from "./identificacion";
import { DatosResidencia } from "./datosResidencia";
import { DatosGeneralesVictimario } from "./datosGenerales";
import { BuscarSindicado } from "./buscarSindicado";
import { RasgosFisicos } from "./rasgosFisicos";
import { DatosDomicilio } from "./datosDomicilio";
import { Sindicados } from "./sindicados";
import { UserAddIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";


const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
text-xs border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont`;

export const Victimario = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  hasError,
  success,
  mensaje,
  setFieldValue,
  resetForm,
  idCaso,
  exp,
  configuracion
}) => {


  const token = useSelector((state) => state.loginReducer.data.token);

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //  retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const [nuevo, setNuevo] = useState(false)
  return (
    <form autoComplete="off" className="mt-2" onChange={customChange(values)}>
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-12  border p-3 rounded-md shadow-sm hover:shadow-md">
          <div className="flex flex-row justify-between items-center">
            <h1 className="mb-3 text-maivi-primaryFont text-sm">
              Buscar sindicado
            </h1>
            {(CheckPermiso('OSindicadoAgregarNuevo') === true) &&    //mm permiso agregado
              <ButtonAdd
                className="-mt-2 mb-0.5"
                type="button"
                onClick={() => {
                  setNuevo(true);
                  resetForm({});
                }}
                icon={<UserAddIcon className=" -mr-1 h-5" />}
              />
            }
          </div>

          <Divider />
          <BuscarSindicado
            values={values}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            resetForm={resetForm}
            setNuevo={setNuevo}
          />
        </div>
        {/* <div className="col-span-12 xl:col-span-5 lg:col-span-5 border p-3 rounded-md shadow-sm hover:shadow-md">
            <Alert type="error" mensaje="debes seleccionar un sindicado" />
            <ButtonSiavi
              type="button"
              text="Agregar"
              onClick={handleSubmit}
              className="mt-2"
            />
          </div> */}
      </div>
      {
        !nuevo &&
        <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
          <h1 className="mb-1 text-maivi-primaryFont text-sm">Sindicados agregados</h1>
          <Sindicados setFieldValue={setFieldValue} setNuevo={setNuevo} idCaso={idCaso} exp={exp} />
        </div>
      }
      {
        nuevo &&
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md mt-2">
            <h1 className="mb-1 text-maivi-primaryFont text-sm">
              Identificación
            </h1>
            <Divider />
            <IdentificacionVictimario
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              selectClass={selectClass}
              resetForm={resetForm}
            />
          </div>
          <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
            <h1 className="mb-1 text-maivi-primaryFont text-sm">Datos generales</h1>
            <Divider />
            <DatosGeneralesVictimario
              values={values}
              conoce_al_sindicado={values.conoce_al_sindicado}
              handleChange={handleChange}
              selectClass={selectClass}
              configuracion={configuracion}
            />
          </div>
          {
            (!values.conoce_al_sindicado) ?
              <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
                <h1 className="mb-1 text-maivi-primaryFont text-sm">
                  Rasgos fisicos
                </h1>
                <Divider />
                <RasgosFisicos
                  values={values}
                  handleChange={handleChange}
                  selectClass={selectClass}
                />
              </div> : ""
          }
          {
            (values.conoce_al_sindicado) ?
              <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
                <h1 className="mb-1 text-maivi-primaryFont text-sm">
                  Datos Demográficos
                </h1>
                <Divider />
                <DatosResidencia
                  values={values}
                  handleChange={handleChange}
                  selectClass={selectClass}
                />
              </div> : ""
          }

          {
            (values.actualizarSindicado) ?
              <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
                <h1 className="mb-1 text-base">Direcciones / Teléfonos</h1>
                <Divider />
                <DatosDomicilio
                  values={values}
                  handleChange={handleChange}
                  selectClass={selectClass}
                />
              </div> : ""
          }
          <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
            <Label text="Observaciones" />
            <TextAreaSiavi
              rows={3}
              cols={3}
              name="observaciones"
              value={values.observaciones}
              onChange={handleChange}
            />
            <div className="flex flex-row mt-2">
            {(CheckPermiso('OSindicadoActualizaCrea') === true) &&    //mm permiso agregado
              <ButtonSiavi
                type="button"
                onClick={handleSubmit}
                disabled={isSubmitting}
                text={values.actualizarSindicado ? "Actualizar" : "Crear"}
              />
        }
              {(CheckPermiso('OSindicadoAsignarExpediente') === true) &&    //mm permiso agregado
              <ButtonSiavi
                type="button"
                className="mx-1"
                text="Asignar"
                onClick={() => {
                  setFieldValue("modalExpediente",true)
                }}
              />
              }
              <ButtonSiavi
                type="button"
                text="Regresar"
                onClick={() => {
                  setNuevo(false)
                }}
              />
            </div>
            <div className="col-span-12 mt-4 mb-4">
              {isSubmitting && <Spinner />}
              {hasError && <Alert type="error" mensaje={mensaje} />}
              {success && <Alert type="success" mensaje={mensaje} />}
            </div>
          </div>
        </div>
      }
    </form>
  );
};
