import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getPersonasCaso = async (idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const getAcciones = async (persona, urlPage, token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?persona=${persona}&es_accion=true`
        : `${urlPage}&persona=${persona}&es_accion=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?estado=true&page_size=300`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoAccionesSeguimientos = async (tipo,id,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&${tipo}=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (servicio, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${servicio}`;
        let result = await fetch(urlFetch, {                                                                                     
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const actualizarRecurso = async (id,data,token) => {
    try {
        let body ={
            disponible: false,
            descripcion: data.descripcion,
            fecha: data.fecha,
            hora: data.hora,
            recurso: data?.recurso?.id,
            accion_recurso: data?.accion
        }
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/recursodisponibilidad/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };

  export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};


export const putAcciones = async (id, data, token) => {
    try {
        let body = {
            seguimiento: data.seguimiento,
            es_nna: data.es_nna,
            tipo_audiencia: (data.tipo_audiencia === "") ? null : data.tipo_audiencia,
            fecha_accion: (data.fecha_accion === "") ? null : data.fecha_accion,
            fecha_asigna: (data.fecha_asigna === "") ? null : data.fecha_asigna,
            fecha_hora_planificada_inicia: (data.fecha_hora_planificada_inicia === "") ? null : data.fecha_hora_planificada_inicia,
            fecha_hora_planificada_fin: (data.fecha_hora_planificada_fin === "") ? null : data.fecha_hora_planificada_fin,
            requiere_autorizacion: false,
            fecha_autoriza: (data.fecha_autoriza === "") ? null : data.fecha_autoriza,
            autorizado: data.autorizado,
            fecha_completado: (data.fecha_completado === "") ? null : data.fecha_completado,
            estado: data.estado,
            accion_planificada: data.accion_planificada,
            asignar_accion: data.asignar_accion,
            es_accion: data.es_accion,
            es_seguimiento: data.es_seguimiento,
            es_audiencia: data.es_audiencia,
            completada: data.completada,
            requiere_nombramiento: data.requiere_nombramiento,
            observaciones: (data.observaciones === "") ? null : data.observaciones,
            detalle_lugar: data.detalle_lugar,
            especificaciones_modalidad: data.especificaciones_modalidad,
            tipo: (data.tipo === "") ? null : data.tipo,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            persona: (data.persona === 0) ? null : data.persona,
            victima: data.victima,
            querellante: data.querellante,
            modalidad: (data.modalidad === "") ? null : data.modalidad,
            referencia: (data.referencia === "") ? null : data.referencia,
            departamento: (data.departamento === "") ? null : data.departamento,
            municipio: (data.municipio === "") ? null : data.municipio,
            accion_seguimiento: (data.accion_seguimiento === "") ? null : data.accion_seguimiento,
            institucion: (data.institucion === "") ? null : data.institucion,
            profesional_asignado: (data.profesional_asignado === "") ? null : data.profesional_asignado,
            profesional_autoriza: (data.profesional_autoriza === "") ? null : data.profesional_autoriza,
            profesional_realiza: (data.profesional_realiza === "") ? null : data.profesional_realiza,
            nombramiento: data.nombramiento,
            profesional_crea: data.profesional_crea,
            sede_accion: data.sede_accion,
            accion_servicio_solicitado: data.seguimiento_accion_solicitada,
            fecha_requerido : (data.fecha_requerido === "") ? null : data.fecha_requerido,
            requiere_recurso: data?.requiere_recurso,
            recurso: data?.recurso,
            recurso_disponibilidad : data?.recurso_disponibilidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const postAcciones = async (data, token) => {
    try {
        let body = {
            seguimiento: data.seguimiento,
            es_nna: data.es_nna,
            tipo_audiencia: (data.tipo_audiencia === "") ? null : data.tipo_audiencia,
            fecha_accion: (data.fecha_accion === "") ? null : data.fecha_accion,
            fecha_asigna: (data.fecha_asigna === "") ? null : data.fecha_asigna,
            fecha_hora_planificada_inicia: (data.fecha_hora_planificada_inicia === "") ? null : data.fecha_hora_planificada_inicia,
            fecha_hora_planificada_fin: (data.fecha_hora_planificada_fin === "") ? null : data.fecha_hora_planificada_fin,
            requiere_autorizacion: data.requiere_autorizacion || false,
            fecha_autoriza: (data.fecha_autoriza === "") ? null : data.fecha_autoriza,
            autorizado: data.autorizado,
            fecha_completado: (data.fecha_completado === "") ? null : data.fecha_completado,
            estado: data.estado,
            accion_planificada: data.accion_planificada,
            asignar_accion: data.asignar_accion,
            es_accion: data.es_accion,
            es_seguimiento: data.es_seguimiento,
            es_audiencia: data.es_audiencia,
            completada: data.completada,
            requiere_nombramiento: data.requiere_nombramiento,
            observaciones: (data.observaciones === "") ? null : data.observaciones,
            detalle_lugar: data.detalle_lugar,
            especificaciones_modalidad: data.especificaciones_modalidad,
            tipo: (data.tipo === "") ? null : data.tipo,
            caso: (data.caso === 0) ? null : data.caso,
            expediente: (data.expediente === 0) ? null : data.expediente,
            persona: (data.persona === 0) ? null : data.persona,
            victima: data.victima,
            querellante: data.querellante,
            modalidad: (data.modalidad === "") ? null : data.modalidad,
            referencia: (data.referencia === "") ? null : data.referencia,
            departamento: (data.departamento === "") ? null : data.departamento,
            municipio: (data.municipio === "") ? null : data.municipio,
            accion_seguimiento: (data.accion_seguimiento === "") ? null : data.accion_seguimiento,
            gestion_institucion:  null,
            institucion: (data.institucion === "") ? null : data.institucion,
            profesional_asignado: (data.profesional_asignado === "") ? null : data.profesional_asignado,
            profesional_autoriza: (data.profesional_autoriza === "") ? null : data.profesional_autoriza,
            profesional_realiza: (data.profesional_realiza === "") ? null : data.profesional_realiza,
            nombramiento: data.nombramiento,
            profesional_crea: data.profesional_crea,
            sede_accion: data.sede_accion,
            accion_servicio_solicitado: data?.seguimiento_accion_solicitada,
            fecha_requerido : (data.fecha_requerido === "") ? null : data.fecha_requerido,
            requiere_recurso: data?.requiere_recurso,
            recurso: data?.recurso,
            recurso_disponibilidad : data?.recurso_disponibilidad,
            gestionar : data?.gestionar || false
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};