/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { useSelector } from "react-redux";
import {
  postNarrativa,
  getSindicadoDelitos,
  getNarrativas,
  postNarrativaSindicados,
  getExpedienteSindicadoDelito,
  getTemporalidad,
  deleteTemporalidad
} from "../services";
import { MostrarNarrativa } from "./mostrar";
import { XCircleIcon } from "@heroicons/react/outline";
import { Temporalidad } from "./temporalidad";
import FormEliminar from "../../../../../components/common/FormEliminar";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import ModalTwo from "../../../../../components/common/ModalTwo";
import Divider from "../../../../../components/common/Divider";
import CustomTable from "../../../../../components/common/Table";
import ModalGenerico from "../../../../../components/common/Modal";
import toast from "react-hot-toast";
import ItemsNarrativas from "./items";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  narrativa: Yup.string().required("narrativa es requerida"),
  expedienteSeleccionado: Yup.string().required("expediente es requerido"),
});

const selectClass = `shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block  
sm:text-sm  rounded-md bg-white text-maivi-primaryFont w-2/5 mb-2 -mt-2`;
const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";

const tableColumns = [
  {
    title: "Día",
    value: "dia",
    tipo: "text",
  },
  {
    title: "Mes",
    value: "mes",
    tipo: "text",
  },
  {
    title: "Año",
    value: "anio",
    tipo: "text",
  },
  {
    title: "Edad",
    value: "edad",
    tipo: "text",
  },
  {
    title: "Aldea/Caserio",
    value: "aldea_caserio",
    tipo: "text",
  },
  {
    title: "Dirección",
    value: "direccion",
    tipo: "text",
  },
  {
    title: "Zona",
    value: "zona",
    tipo: "text",
  },
  {
    title: "Lugar",
    value: "tipo_lugar",
    tipo: "text",
  },
  {
    title: "Departamento",
    value: "departamento.nombre",
    tipo: "text",
  },
  {
    title: "Municipio",
    value: "municipio.nombre",
    tipo: "text",
  },
];

export const Narrativa = (props) => {
  const idCaso = props?.caso?.id;
  const token = useSelector((state) => state.loginReducer.data.token);
  const expedientes = props?.caso?.expedientes;
  const [refetch, setRefeth] = useState(false);
  const [delitosSindicado, setDelitosSindicado] = useState([]);
  const [delitosFiltrar, setDelitosFiltrar] = useState([]);
  const [narrativas, setNarrativas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [cargandoNarrativas, setCargandoNarrativas] = useState(false);
  const [errorNarrativa, setErrorNarrativa] = useState(false);
  const [sindicadosElegidos, setSindicadosElegidos] = useState([]);
  const [nuevo, setNuevo] = useState(false);
  const [itemNarrativa, setItemNarrativa] = useState({});
  const [temporalidades, setTemporalidades] = useState([]);
  const [idTemporalidad, setIdTemporalidad] = useState("");
  const [isEliminar, setEliminar] = useState(false);
  const [temporalidad, setTemporalidad] = useState("");
  const [refetchTemp, setReFetchTemp] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [edit, setIsEdit] = useState(false);

  const fetchSindicadoDelitos = () => {
    setCargando(true);
    getSindicadoDelitos(idCaso, token).then((data) => {
      setDelitosSindicado([]);
      setDelitosFiltrar(data?.results);
      setCargando(false);
    });
  };

  const fetchNarrativas = () => {
    setCargandoNarrativas(true);
    getNarrativas(idCaso, token).then(async (data) => {
      setNarrativas(data);
      setItemNarrativa(data?.results?.[0]);
      setCargandoNarrativas(false);
    });
  };


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //    retorno = true;  // mm comentar para que funcione
    return retorno;

  }


  useEffect(fetchSindicadoDelitos, [token, idCaso, refetch]);
  useEffect(fetchNarrativas, [token, idCaso, refetch]);

  useEffect(() => {
    if (itemNarrativa?.id !== undefined) {
      setTableLoading(true);

      getTemporalidad(itemNarrativa?.id, token).then((data) => {
        setTableLoading(false);
        setTemporalidades(data);
      });
    }
  }, [itemNarrativa, refetchTemp]);

  const handleEliminar = (id) => {
    setIdTemporalidad(id);
    setEliminar(true);
  };

  const handleEditar = (temporalidad) => {
    setTemporalidad(temporalidad);
    setIsEdit(true);
  };

  const headersexpedientesindicados = [
    "Seleccionar",
    "Sindicado",
    "Delito"
  ];

  return (
    <Formik
      initialValues={{
        narrativa: "",
        narrativaEditar: itemNarrativa?.narrativa,
        expedienteSeleccionado: "",
        expSindicadoDelito: ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values, { resetForm }) => {
        if (sindicadosElegidos.length > 0) {
          await postNarrativa(
            values.narrativa,
            idCaso,
            values.expedienteSeleccionado,
            token
          )
            .then(async (data) => {
              if (data?.status === 200 || data?.status === 201) {
                toast.success("narrativa agregada con éxito..!");
                let idEcho = data?.data?.id;

                sindicadosElegidos.forEach(async (element) => {
                  await postNarrativaSindicados(
                    idCaso,
                    element,
                    idEcho,
                    token
                  );
                });
                setSindicadosElegidos([]);
                setRefeth(true);
                setRefeth(false);
                resetForm({});
                setNuevo(false);
              }
            })
            .catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          setErrorNarrativa(true);
          setTimeout(() => {
            setErrorNarrativa(false);
          }, 4000);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form>
          <div className="flex flex-row justify-between items-center">
            <ItemsNarrativas narrativas={narrativas} setItemNarrativa={setItemNarrativa} values={values} />
            <div className="-mt-2 mb-2">
              {(CheckPermiso('OHCrear') === true) &&    //mm permiso agregado        
                <ButtonSiavi
                  type="button"
                  text="Crear"
                  className="justify-end -py-2"
                  onClick={() => {
                    setNuevo(true);
                  }}
                />
              }
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12">
              {
                (narrativas?.results?.length === 0) ?
                  <Alert type="info" mensaje="Por favor agregue los hechos para este caso" /> :
                  <MostrarNarrativa
                    setRefeth={setRefeth}
                    idCaso={idCaso}
                    itemNarrativa={itemNarrativa}
                    tdClass={tdClass}
                    selectClass={selectClass}
                    values={values}
                    handleChange={handleChange}
                    delitosFiltrar={delitosFiltrar}
                    cargandoNarrativas={cargandoNarrativas}
                  />
              }

            </div>
            {
              <div className="col-span-12 border rounded-md">
                {
                  (temporalidades?.results?.length === 0) ?
                    <Alert type="warning" mensaje="Por favor registre tiempo y lugar para los hechos" /> :
                    <CustomTable
                      dataTable={temporalidades}
                      tableColumns={tableColumns}
                      permiso={"exphechoslugartemporalidad"}
                      //showEliminar={true}
                      showEliminar={(CheckPermiso('OHEliminaTemporalidad') === true) }    //mm permiso agregado   
                                            //showEditar={true}
                      showEditar={(CheckPermiso('OHEditarTemporalidad') === true) }    //mm permiso agregado        
                      showVer={false}
                      handleEliminar={handleEliminar}
                      handleEditar={handleEditar}
                    />
                }
                {tableLoading && (
                  <div className="mt-10 mb-10">
                    <Spinner />
                  </div>
                )}
              </div>
            }
          </div>
          {isSubmitting && <Spinner />}
          {nuevo && (
            <ModalTwo
              title={`Nuevo registro`}
              open={nuevo}
              closeModal={() => { }}
              cerrar={() => {
                setNuevo(false);
              }}
              children={
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-5 rounded-md shadow-sm hover:shadow-md">
                    <div className="flex flex-row justify-between ">
                      <h1 className="mb-1 text-base">Hechos</h1>
                      <select
                        name="expedienteSeleccionado"
                        value={values.expedienteSeleccionado}
                        className={selectClass}
                        onChange={(e) => {
                          setCargando(true);
                          setDelitosSindicado([]);
                          setFieldValue("expedienteSeleccionado", e.target.value);

                          let tipo_proceso = 0;
                          expedientes.forEach(element => {
                            if (element.id === Number(e.target.value)) {
                              tipo_proceso = element?.tipo_proceso?.id
                            }
                          });
                          getExpedienteSindicadoDelito(idCaso, tipo_proceso, token).then((data) => {
                            setDelitosSindicado(data.results);
                            setCargando(false);
                          });
                        }}
                      >
                        <option value="">Seleccione tipo proceso</option>
                        {expedientes?.filter(x => x.asitencia_legal).map((item, index) => (
                          <option
                            key={index + 'pr'}
                            value={item?.id}
                          >
                            {item?.tipo_proceso?.tipo_proceso}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name="expedienteSeleccionado"
                        component="span"
                        className="font-medium text-xs text-maivi-secondaryRed"
                      />
                    </div>
                    <Divider />
                    <div className="grid grid-cols-12 items-end justify-end gap-2 -mt-3">
                      <div className="col-span-12">
                        <TextAreaSiavi
                          rows={3}
                          cols={40}
                          validacion={true}
                          name="narrativa"
                          onChange={handleChange}
                          value={values.narrativa}
                          placeholder="narrativa de hechos"
                        />
                      </div>
                      {errorNarrativa && (
                        <div className="col-span-12">
                          <Alert
                            type="error"
                            mensaje="debe seleccionar al menos un sindicado al que asociara la narrativa."
                          />
                        </div>
                      )}
                      <div className="col-span-0 xl:col-span-9 lg:col-span-9 md:col-span-6">
                      </div>
                      <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-6">
                        <ButtonSiavi
                          text="Guardar"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
                    <h1 className="">Sindicados y delitos asociados</h1>
                    <TableSiavi
                      cargando={cargando}
                      headers={headersexpedientesindicados}
                      empty="No existen registros asociados"
                      body={
                        delitosSindicado?.map((item, index) => (
                          <tr
                            key={item?.expediente_sindicado?.sindicado?.primer_nombre + index}
                            className="hover:bg-maivi-gray-5 cursor-pointer"
                          >
                            <td className={tdClass}>
                              <input
                                name="checkTable"
                                type="checkbox"
                                className="bg-maivi-primaryLightBlue h-5 w-5 rounded mr-2"
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setSindicadosElegidos([
                                      ...sindicadosElegidos,
                                      item.id,
                                    ]);
                                  } else {
                                    let sindicados = sindicadosElegidos.filter(
                                      (x) => x !== item.id
                                    );
                                    setSindicadosElegidos(sindicados);
                                  }
                                }}
                              />
                            </td>
                            <td
                              className={`${(item?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? 'text-sm italic' : 'px-2 row-td text-xs text-maivi-primaryFont'}`}
                            >
                              {`${(item?.expediente_sindicado?.sindicado?.conoce_al_sindicado === false) ? 'Sindicado no individualizado' : item?.expediente_sindicado?.sindicado?.primer_nombre + ' ' + item.expediente_sindicado.sindicado.primer_apellido}`}

                            </td>
                            <td className={tdClass}>{item?.delito?.nombre}</td>
                          </tr>
                        ))
                      }
                    />
                  </div>
                </div>
              }
            />
          )}
          {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setIsLoading(true);
                    deleteTemporalidad(idTemporalidad, token).then(() => {
                      setEliminar(false);
                      setIsLoading(false);
                      setReFetchTemp(true);
                      setReFetchTemp(false);
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
          {edit && (
            <ModalTwo
              title={`Agregar Lugar y Tiempo`}
              open={edit}
              closeModal={() => { }}
              cerrar={() => {
                setIsEdit(false);
              }}
              children={
                <Temporalidad
                  token={token}
                  idCaso={idCaso}
                  idExpediente={itemNarrativa?.expediente?.id}
                  idHecho={itemNarrativa?.id}
                  temporalidad={temporalidad}
                  isEdit={true}
                  refetchTemp={setReFetchTemp}
                  setIsEdit={setIsEdit}
                />
              }
            />
          )}
        </form>
      )}
    </Formik>
  );
};
