import { React, useEffect, useState } from "react";
import Toggle from "../../../../components/common/Toggle";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import Return from "../../../../components/common/ButtonSalir";
import { ButtonSiavi } from "../../../../components/common/Button";
import { getPaises, getIdiomaMaya, getOrigenEtnico, getNacionalidad } from "./services";
import { useSelector } from "react-redux";

const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

export const Actualizar = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);

  const useFetchPaises = () => {
    const [state, setState] = useState({
      paises: [],
    });

    useEffect(() => {
      getPaises(token).then((data) => {
        setState({
          paises: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchIdiomaMaya = () => {
    const [state, setState] = useState({
      idiomamaya: [],
    });

    useEffect(() => {
      getIdiomaMaya(token).then((data) => {
        setState({
          idiomamaya: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchOrigenEtnico = () => {
    const [state, setState] = useState({
      origen: [],
    });

    useEffect(() => {
      getOrigenEtnico(token).then((data) => {
        setState({
          origen: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchNacionalidad = () => {
    const [state, setState] = useState({
      nacionalidad: [],
    });

    useEffect(() => {
      getNacionalidad(token).then((data) => {
        setState({
          nacionalidad: data,
        });
      });
    }, []);

    return state;
  };


  const { paises } = useFetchPaises();
  const { idiomamaya } = useFetchIdiomaMaya();
  const { origen } = useFetchOrigenEtnico();
  const { nacionalidad } = useFetchNacionalidad();

  const pages = [
    { name: "Catálogos", href: "/catalogos", current: false },
    { name: "Configuración General", href: "#", current: true },
  ];
  return (
    <form autoComplete="off">
      <div className="lg:grid-cols-2 gap-12 sm:grid sm:grid-cols-1 sm:gap-12 md:grid lg:flex -mt-24 mb-10 justify-center items-start">
        <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
          <div className="flex justify-center col-span-12">

          </div>
          <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-8">
            <BreadCrumbTwo navegacion={pages} />
            <div className="mt-5 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  flex flex-row justify-center items-center">
              <h1 className="mr-5 text-xl text-maivi-primaryFont">
                Actualizar configuración general
              </h1>
              <Return ruta="/catalogos" />
            </div>
          </div>

          <h1 className="mb-1 text-base">Datos Generales</h1>
          <Divider />
          <div className="grid grid-cols-12 gap-6">
            <div className="sm:col-span-4 col-span-12">
              <Label text="Nombre de Institución" />
              <InputSiavi
                type="text"
                name="nombre_institucion"
                id="nombre_institucion"
                onChange={handleChange}
                value={values.nombre_institucion}
              />
              <ErrorMessage
                name="nombre_institucion"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="NIT" />
              <InputSiavi
                type="text"
                name="nit"
                id="nit"
                onChange={handleChange}
                value={values.nit}
              />
              <ErrorMessage
                name="nit"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Dirección" />
              <InputSiavi
                type="text"
                name="direccion"
                id="direccion"
                onChange={handleChange}
                value={values.direccion}
              />
              <ErrorMessage
                name="direccion"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Idioma Preferente" />
              <InputSiavi
                type="text"
                name="idioma_preferente"
                id="idioma_preferente"
                onChange={handleChange}
                value={values.idioma_preferente}
              />
              <ErrorMessage
                name="idioma_preferente"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Moneda" />
              <InputSiavi
                type="text"
                name="moneda"
                id="moneda"
                onChange={handleChange}
                value={values.moneda}
              />
              <ErrorMessage
                name="moneda"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="País" />
              <select
                id="pais"
                name="pais"
                autoComplete="off"
                value={values.pais}
                onChange={handleChange}
                className={selectClass}
              >
                <option value="">Seleccionar</option>
                {paises?.data?.results?.map((item) => {
                  return (
                    <option
                      key={item.nombre}
                      value={item.id}
                      className="text-sm"
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
              <ErrorMessage
                name="pais"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Habilita Idioma Maya" />
              <select
                id="habilita_idioma_maya"
                name="habilita_idioma_maya"
                autoComplete="off"
                value={values.habilita_idioma_maya}
                onChange={handleChange}
                className={selectClass}
              >
                <option value="">Seleccionar</option>
                {idiomamaya?.data?.results?.map((item) => {
                  return (
                    <option
                      key={item.nombre}
                      value={item.id}
                      className="text-sm"
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Habilita Origen Étnico Maya" />
              <select
                id="habilita_origen_etnico"
                name="habilita_origen_etnico"
                autoComplete="off"
                value={values.habilita_origen_etnico}
                onChange={handleChange}
                className={selectClass}
              >
                <option value="">Seleccionar</option>
                {origen?.data?.results?.map((item) => {
                  return (
                    <option
                      key={item.nombre}
                      value={item.id}
                      className="text-sm"
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Nacionalidad Default" />
              <select
                id="nacionalidad_default"
                name="nacionalidad_default"
                autoComplete="off"
                value={values.nacionalidad_default}
                onChange={handleChange}
                className={selectClass}
              >
                <option value="">Seleccionar</option>
                {nacionalidad?.data?.results?.map((item) => {
                  return (
                    <option
                      key={item.nombre}
                      value={item.id}
                      className="text-sm"
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Prefijo Teléfono" />
              <InputSiavi
                type="text"
                name="prefijo_telefono"
                id="prefijo_telefono"
                onChange={handleChange}
                value={values.prefijo_telefono}
              />
              <ErrorMessage
                name="prefijo_telefono"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Intentos Contraseña" />
              <InputSiavi
                type="text"
                name="intentos_contrasena"
                id="intentos_contrasena"
                onChange={handleChange}
                value={values.intentos_contrasena}
              />
              <ErrorMessage
                name="intentos_contrasena"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Días atención de una visita" />
              <InputSiavi
                type="text"
                name="dias_atiende_visita"
                id="dias_atiende_visita"
                onChange={handleChange}
                value={values.dias_atiende_visita}
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Días para registrar acciones" />
              <InputSiavi
                type="text"
                name="dias_registro_acciones"
                id="dias_registro_acciones"
                onChange={handleChange}
                value={values.dias_registro_acciones}
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Días para registrar finalización de acciones" />
              <InputSiavi
                type="text"
                name="dias_finalizo_acciones"
                id="dias_finalizo_acciones"
                onChange={handleChange}
                value={values.dias_finalizo_acciones}
              />
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 flex text-center flex-col">
              <Label text="Notificaciones Activas" />
              <div className="mt-1">
                <Toggle
                  enabled={values.notificaciones_activas}
                  name="notificaciones_activas"
                  id="notificaciones_activas"
                />
              </div>
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 flex text-center flex-col">
              <Label text="Mensajes Autocuidado" />
              <div className="mt-1">
                <Toggle
                  enabled={values.mensajes_autocuidado}
                  name="mensajes_autocuidado"
                  id="mensajes_autocuidado"
                />
              </div>
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 flex text-center flex-col">
              <Label text="Una Víctima por Caso" />
              <div className="mt-1">
                <Toggle
                  enabled={values.victimaxcaso}
                  name="victimaxcaso"
                  id="victimaxcaso"
                />
              </div>
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 flex text-center flex-col">
              <Label text="Crear Caso sin Orientación" />
              <div className="mt-1">
                <Toggle
                  enabled={values.caso_sin_orientacion}
                  name="caso_sin_orientacion"
                  id="caso_sin_orientacion"
                />
              </div>
            </div>
            <div className="sm:col-span-4 md:col-span-3 lg:col-span-2  col-span-12 flex text-center flex-col">
              <Label text="Comunicación Víctima" />
              <div className="mt-1">
                <Toggle
                  enabled={values.comunicacion_victima}
                  name="comunicacion_victima"
                  id="comunicacion_victima"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6">
            <div className="sm:col-span-10 col-span-12">
              <div className="col-span-12">
                {isSubmitting && (
                  <Spinner />
                )}
                {hasError && (
                  <Alert
                    type="error"
                    mensaje={mensaje}
                  />
                )}
                {success && (
                  <Alert
                    type="success"
                    mensaje={mensaje}
                  />
                )}
              </div>
            </div>
            <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
                text="Actualizar"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
