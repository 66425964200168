import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getSindicados = async (buscar,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/sindicado/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPersonas = async (buscar,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?search=${buscar}&page_size=20`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deletePersonaCaso = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPersona = async (data,token) => {
    try {
        let idsDiscapacidades = [];
        let idsAtenciones = [];

        data?.atenciones?.forEach(element => {
            idsAtenciones.push(element.id);
        });

        data?.discapacidades?.forEach(element => {
            idsDiscapacidades.push(element.id);
        });

        let body = {
            sede: data?.sede,
            otros_nombres: data.otros_nombres,
            lee_escribe: data?.lee_escribe,
            idioma_preferencia:data?.idioma_preferencia,
            situacion_laboral: (data?.situacion_laboral === "") ? null :data?.situacion_laboral,
            identidad_genero: (data?.identidad_genero === "") ? null :data?.identidad_genero,
            require_atencion_diferenciada: data?.require_atencion_diferenciada,
            en_migracion: data?.en_migracion,
            pais_origen: (data?.pais_origen === "") ? null :data?.pais_origen,
            pais_destino: (data?.pais_destino === "") ? null :data?.pais_destino,
            primera_visita: false,
            primer_nombre: data?.primer_nombre,
            segundo_nombre: data?.segundo_nombre,
            segundo_apellido: data?.segundo_apellido,
            primer_apellido: data?.primer_apellido,
            apellido_casada: data?.apellido_casada,
            fecha_nacimiento: (data.fecha_nacimiento === "") ? null : data?.fecha_nacimiento,
            sexo: data?.sexo,
            como_le_gusta_que_le_llamen: data?.pseudonimo,
            correo_electronico: data?.correo_electronico,
            estado_civil:data?.estado_civil,
            orientacion_sexual: (data?.orientacion_sexual === "") ? null :data?.orientacion_sexual,
            escolaridad: (data?.escolaridad === "") ? null :data?.escolaridad,
            profesion: (data?.profesion === "") ? null :data?.profesion,
            origen_etnico: (data?.origen_etnico === "") ? null :data?.origen_etnico,
            idioma:data?.idioma,
            idioma_maya: (data?.idioma_maya === "") ? null :data?.idioma_maya,
            religion: (data?.religion === "") ? null :data?.religion,
            departamento:data?.departamento,
            municipio:data?.municipio,
            nacionalidad:data?.nacionalidad,
            relacion_con_victimario:data?.relacion_con_victimario,
            discapacidades: idsDiscapacidades,
            atencion_diferenciada: idsAtenciones,

            sin_documento: data.sin_documento,
            victima_fallecida: data.victima_fallecida,
            tipo_documento: (data?.tipo_documento === "") ? null : data?.tipo_documento,
            no_documento: (data?.no_documento === "") ? null: data?.no_documento,
            pais: (data?.pais === "") ? null : data?.pais,
            edad: data.edad
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const buscarPersona = async (pais,tipo_documento,documento,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona/?pais=${pais}&tipo_documento=${tipo_documento}&search=${documento}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPersona = async (data,token) => {
    try {
        let body = {
            sede: data?.sede,
            otros_nombres: data.otros_nombres,
            lee_escribe: data?.lee_escribe,
            idioma_preferencia:data?.idioma_preferencia,
            situacion_laboral: (data?.situacion_laboral === "") ? null :data?.situacion_laboral,
            identidad_genero: (data?.identidad_genero === "") ? null :data?.identidad_genero,
            require_atencion_diferenciada: data?.require_atencion_diferenciada,
            en_migracion: data?.en_migracion,
            pais_origen: (data?.pais_origen === "") ? null :data?.pais_origen,
            pais_destino: (data?.pais_destino === "") ? null :data?.pais_destino,
            primera_visita: false,
            primer_nombre: data?.primer_nombre,
            segundo_nombre: data?.segundo_nombre,
            segundo_apellido: data?.segundo_apellido,
            primer_apellido: data?.primer_apellido,
            apellido_casada: data?.apellido_casada,
            fecha_nacimiento: data?.fecha_nacimiento,
            sexo: data?.sexo,
            como_le_gusta_que_le_llamen: data?.pseudonimo,
            correo_electronico: data?.correo_electronico,
            estado_civil:data?.estado_civil,
            orientacion_sexual: (data?.orientacion_sexual === "") ? null :data?.orientacion_sexual,
            escolaridad: (data?.escolaridad === "") ? null :data?.escolaridad,
            profesion: (data?.profesion === "") ? null :data?.profesion,
            origen_etnico: (data?.origen_etnico === "") ? null :data?.origen_etnico,
            idioma:data?.idioma,
            idioma_maya: (data?.idioma_maya === "") ? null :data?.idioma_maya,
            religion: (data?.religion === "") ? null :data?.religion,
            departamento:data?.departamento,
            municipio:data?.municipio,
            nacionalidad:data?.nacionalidad,
            relacion_con_victimario:data?.relacion_con_victimario,
            edad: data?.edad,
            sin_documento: data.sin_documento,
            victima_fallecida: data.victima_fallecida,
            tipo_documento: (data?.tipo_documento === "") ? null : data?.tipo_documento,
            no_documento: (data?.no_documento === "") ? null: data?.no_documento,
            pais: (data?.pais === "") ? null : data?.pais,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/${data.persona}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConfiguraciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/configuracion/1/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasoPersonaConsentimientos = async (idCasoPersona,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersonaconsentimiento/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaConsentimientos = async (data,idCasoPersona,token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
            representante: (data.representante === "") ? null : data.representante
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaConsentimientos = async (data,idCasoPersona,token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${data.idPlan}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCasoPersonaConsentimientos = async (id,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getServicios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/serviciopropio/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/?page_size=200&consentimiento_informado=true&unificado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaVictima = async (id,data,token) => {
    try {
        let body = {
            estado: data.estado,
            usuario_ultimo_cambio_estado: data.usuario
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getExpedientes = async (persona,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expediente/?estado=true&caso_persona__persona=${persona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};
