import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getFormularioCasoPersona = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/formulariocasopersona/?${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postFormularioCasoPersona = async (data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putFormularioCasoPersona = async (id, data, idCasoPersona, idOrientacion, idExpediente, idSede, token) => {
    try {
        let body = {
            fecha_formulario: data.fecha_formulario,
            editable: true,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: (data.fecha_ultima_modificacion === "") ? null : data.fecha_ultima_modificacion,
            codigo: (data.codigo === "") ? null : data.codigo,
            accion_seguimiento_caso: (data.accion_seguimiento_caso === "") ? null : data.accion_seguimiento_caso,
            formulario: data.formulario,
            caso_persona: (idCasoPersona === 0) ? null : idCasoPersona,
            orientacion: (idOrientacion === 0) ? null : idOrientacion,
            expediente: (idExpediente === 0) ? null : idExpediente,
            profesional_evaluado: (data.profesional_evaluado === "") ? null : data.profesional_evaluado,
            atencion: (data.atencion === 0) ? null : data.atencion,
            sede: (idSede === 0) ? null : idSede,
            modalidad: data.modalidad
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteFormularioCasoPersona = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/formulariocasopersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getFormularios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/formularios/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getModalidades = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/modalidad/?page_size=200&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getConsentimientos = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/consentimientoinformado/?page_size=200&consentimiento_informado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasoPersonaConsentimientos = async (idCasoPersona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/casopersonaconsentimiento/?caso_persona=${idCasoPersona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putCasoPersonaConsentimientos = async (data, idCasoPersona, token) => {
    try {
        let body = {
            observaciones: data.observaciones,
            caso_persona: idCasoPersona,
            consentimiento: data.tipo_consentimiento,
            servicio: data.servicio,
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${data.idPlan}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteCasoPersonaConsentimientos = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersonaconsentimiento/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getDiagnosticoMedico = async (idExpediente, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientediagnosticomedico/?expediente=${idExpediente}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postDiagnosticoMedico = async (data, token) => {
    try {
        let body = {
            motivo_consulta: data.motivo_consulta,
            impresion_diagnostico_inicial: data.impresion_diagnostico_inicial,
            plan_educacional: data.plan_educacional,
            diagnostico_final: data.diagnostico_final,
            conclusiones: data.conclusiones,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: data.fecha_ultima_modificacion,
            usuario_ultima_modificacion: data.usuario_ultima_modificacion,
            caso: data.caso,
            expediente: data.expediente,
            servicio: data.servicio,
            persona: data.persona,

            antecedentes_familiares: data.antecedentes_familiares,
            antecedentes_personales: data.antecedentes_personales,
            antecedentes_go:data.antecedentes_familiares,
            FUR: data.fur,
            G: data.g,
            P:data.p,
            C:data.c,
            A8:data.a8,
            HV:data.hv,
            HM:data.hm,
            fecha_papanicolaou:data.fecha_papanicolaou,
            papanicolaou:data.papanicolaou,
            revision_sistemas:data.revision_sistemas,
            examen_fisico:data.examen_fisico,
            examen_perinatal:data.examen_perinatal,
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedientediagnosticomedico/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putDiagnosticoMedico = async (id, data, token) => {
    try {
        let body = {
            motivo_consulta: data.motivo_consulta,
            impresion_diagnostico_inicial: data.impresion_diagnostico_inicial,
            plan_educacional: data.plan_educacional,
            diagnostico_final: data.diagnostico_final,
            conclusiones: data.conclusiones,
            observaciones: data.observaciones,
            fecha_ultima_modificacion: data.fecha_ultima_modificacion,
            usuario_ultima_modificacion: data.usuario_ultima_modificacion,
            caso: data.caso,
            expediente: data.expediente,
            servicio: data.servicio,
            persona: data.persona
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedientediagnosticomedico/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteDiagnosticoMedico = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedientediagnosticomedico/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getSignosDiagnostico = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesignosvitales/?expediente_diagnostico=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postSignosVitales = async (data, token) => {
    try {
        let body = {
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            caso: data.caso,
            fecha: data.fecha,
            presion_arterial: data.presion_arterial,
            frecuencia_cardiaca: data.frecuencia_cardiaca,
            pulso: data.pulso,
            frecuencia_respiratoria: data.frecuencia_respiratoria,
            temperatura: data.temperatura,
            peso: data.peso,
            talla: data.talla,
            gmt: data.gmt,
            cpo2: data.cpo2
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/expedientesignosvitales/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putSignosVitales = async (id, data, token) => {
    try {
        let body = {
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            caso: data.caso,
            fecha: data.fecha,
            presion_arterial: data.presion_arterial,
            frecuencia_cardiaca: data.frecuencia_cardiaca,
            pulso: data.pulso,
            frecuencia_respiratoria: data.frecuencia_respiratoria,
            temperatura: data.temperatura,
            peso: data.peso,
            talla: data.talla,
            gmt: data.gmt,
            cpo2: data.cpo2
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/expedientesignosvitales/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteSignosVitales = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedientesignosvitales/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getEvolucionDiagnostico = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expedienteevolucionmedica/?expediente_diagnostico=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postEvolucionMedica = async (data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            numero_cita: data.numero_cita,
            historia_clinica_evolucion: data.historia_clinica_evolucion,
            laboratorios: data.laboratorios,
            tratamiento: data.tratamiento,
            observaciones: data.observaciones,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            caso: data.caso
        }

        let bodyResult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/registro/expedienteevolucionmedica/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putEvolucionMedica = async (id, data, token) => {
    try {
        let body = {
            fecha: data.fecha,
            numero_cita: data.numero_cita,
            historia_clinica_evolucion: data.historia_clinica_evolucion,
            laboratorios: data.laboratorios,
            tratamiento: data.tratamiento,
            observaciones: data.observaciones,
            expediente_diagnostico: data.expediente_diagnostico,
            profesional: data.profesional,
            expediente: data.expediente,
            caso: data.caso
        }

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/expedienteevolucionmedica/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteEvolucionMedica = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/expedienteevolucionmedica/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};