/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { useSelector } from "react-redux";
import { getDeptosVictima, getMunicipioVictima } from "../victima/services";
import { putTemporalidad,postTemporalidad } from "../services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import Alert from "../../../../../components/common/Alert";
import Divider from "../../../../../components/common/Divider";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  year : Yup.number().required("Año es requerido"),
  departamento : Yup.string().required("Departamento es requerido"),
  municipio : Yup.string().required("Municipio es requerido"),
  edad: Yup.number().required("Edad es requerida").max(100, "Máximo 100 años"),
  lugar: Yup.string().required("Lugar es requerido"),
});

export const Temporalidad = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [ municipios,setMunicipios ] = useState([]);
  const [deptos,setDeptos] = useState([]);
  const [departamentoId, setDepartamentoId] = useState(0);
  const [error, setError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [temporalidad, setTemporalidad] = useState("");

  useEffect(() => {
    getDeptosVictima(token).then((data) => {
      setDeptos(data)
    });
  }, [token]);

  useEffect(() => {
    getMunicipioVictima(token).then((data) => {
     setMunicipios(data)
    });
  }, [token]);

  useEffect(() => {
    if(props.isEdit){
      setEdit(props?.isEdit);
      setTemporalidad(props.temporalidad);
      setDepartamentoId(props.temporalidad.departamento.id);
    }
  }, []);

  const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
    focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
    sm:text-sm border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont`;

    const year = (new Date()).getFullYear();
    const years2 = Array.from(new Array(101),(val, index) => year -index);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          dia: isEdit ? temporalidad?.dia : "",
          mes: isEdit ? temporalidad?.mes : "",
          year: isEdit ? temporalidad?.anio : "",
          edad: isEdit ? temporalidad?.edad : "",
          observaciones: isEdit ? temporalidad?.observaciones : "",
          departamento: isEdit ? temporalidad?.departamento?.id : "",
          municipio: isEdit ? temporalidad?.municipio?.id : "",
          aldea: isEdit ? temporalidad?.aldea_caserio : "",
          direccion: isEdit ? temporalidad?.direccion : "",
          zona: isEdit ? temporalidad?.zona : "",
          lugar: isEdit ? temporalidad?.tipo_lugar : "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (isEdit) {
            await putTemporalidad(temporalidad.id ,values, props.idCaso, props.idExpediente, props.idHecho, props.token)
              .then( async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  setError(false);
                  toast.success("Temporalidad actualizada con éxito..!");
                  props.refetchTemp(true);
                  props.refetchTemp(false);
                  props.setIsEdit(false);
                  setEdit(false);
                } 
              })
              .catch(function (error) {
                if (
                  error.response.status === 400 ||
                  error.response.status === 401
                ) {
                  setError(true);
                  setMensaje(error.response.data.message);
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  setError(true);
                  setMensaje("Error interno comuniquese con el administrador");
                  toast.error("Error interno comuniquese con el administrador");
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                }
              });
          } else {
            await postTemporalidad(
              values,
              props.idCaso,
              props.idExpediente,
              props.idHecho,
              props.token
            )
              .then(async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  toast.success("Temporalidad agregada con éxito...");
                  resetForm({});
                  setTimeout(() => {
                    window.location.reload();
                  }, 1200);
                }
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  setError(true);
                  setMensaje(error.response.data.message);
                  toast.error(error.response.data.message);
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                } else if (error.response.status >= 500) {
                  setError(true);
                  setMensaje("Error interno comuniquese con el administrador");
                  toast.error("Error interno comuniquese con el administrador");
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                }
              });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-6 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <div className="flex flex-row justify-between ">
                  <h1 className="mb-1 text-base text-maivi-primaryFont">
                    Datos principales de los hechos
                  </h1>
                </div>
                <Divider />
                <div className="grid grid-cols-12 items-end justify-end gap-2">
                  <div className="col-span-12">
                    <Label text="Fecha en que sucedieron los hechos" />
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <Label text="Dia" />
                    <select
                      name="dia"
                      value={values.dia}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="">Elegir dia</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6">
                     <Label text="Mes" />
                     <select
                      name="mes"
                      value={values.mes}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="">Elegir mes</option>
                      <option value="1">Enero</option>
                      <option value="2">Febrero</option>
                      <option value="3">Marzo</option>
                      <option value="4">Abril</option>
                      <option value="5">Mayo</option>
                      <option value="6">Junio</option>
                      <option value="7">Julio</option>
                      <option value="8">Agosto</option>
                      <option value="9">Septiembre</option>
                      <option value="10">Octubre</option>
                      <option value="11">Noviembre</option>
                      <option value="12">Diciembre</option>
                    </select>
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6">
                     <Label text="Año" />
                     <select
                      name="year"
                      value={values.year}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="">Elegir Año</option>
                      {
                        years2.map((year, index) => {
                          return <option key={`year${index}`} value={year}>{year}</option>
                        })
                      }
                    </select>
                    <ErrorMessage
                      name="year"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="number"
                      name="edad"
                      value={values.edad}
                      onChange={handleChange}
                      validacion={true}
                      label="*Edad víctima"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-12 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <Label text="Observaciones" />
                    <TextAreaSiavi
                      rows={2}
                      cols={50}
                      name="observaciones"
                      value={values.observaciones}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <h1 className="mb-1 text-maivi-primaryFont">
                  Lugar donde sucedieron los hechos
                </h1>
                <Divider />
                <div className="grid grid-cols-12 items-end justify-end gap-2">
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <Label text="Departamento" />
                    <select
                      name="departamento"
                      onChange={(e) => {
                        setFieldValue("departamento", e.target.value);
                        setDepartamentoId(Number(e.target.value));
                      }}
                      value={values.departamento}
                      className={selectClass}
                    >
                      <option value="">
                        Elegir Departamento
                      </option>
                      {deptos?.results?.map((item) => {
                        return (
                          <option
                            key={item.id + "dp" + item.nombre}
                            value={item.id}
                          >
                            {item.nombre}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      name="departamento"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <Label text="Municipio" />
                    <select
                      name="municipio"
                      value={values.municipio}
                      onChange={(e) => {
                        setFieldValue("municipio", e.target.value);
                      }}
                      className={selectClass}
                    >
                      <option value="">
                        Elegir Municipio
                      </option>
                      {municipios?.results
                        ?.filter(
                          (x) => x.departamento === Number(departamentoId)
                        )
                        .map((item) => {
                          return (
                            <option
                              key={item.nombre + "mn" + item.id}
                              value={item.id}
                            >
                              {item.nombre}
                            </option>
                          );
                        })}
                    </select>
                    <ErrorMessage
                      name="municipio"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="aldea"
                      value={values.aldea}
                      onChange={handleChange}
                      validacion={true}
                      label="Aldea / caserio"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="direccion"
                      value={values.direccion}
                      onChange={handleChange}
                      validacion={true}
                      label="Direccion"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <InputSiavi
                      type="text"
                      name="zona"
                      value={values.zona}
                      onChange={handleChange}
                      validacion={true}
                      label="Zona"
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 lg:col-span-6 sm:col-span-6 md:col-span-6">
                    <Label text="*Lugar" />
                    <select
                      name="lugar"
                      value={values.lugar}
                      onChange={handleChange}
                      className={selectClass}
                    >
                      <option value="">Seleccione lugar</option>
                      <option value="PUBLICO">PUBLICO</option>
                      <option value="PRIVADO">PRIVADO</option>
                      <option value="INDETERMINADO">INDETERMINADO</option>
                    </select>
                    <ErrorMessage
                      name="lugar"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-0 xl:col-span-9 lg:col-span-9 md:col-span-6">
                    {error && <Alert type="error" mensaje={mensaje} />}
                  </div>
                  <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-6">
                    <ButtonSiavi
                      type="button"
                      disabled={isSubmitting}
                      text={isEdit ? "Actualizar" : "Guardar"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
