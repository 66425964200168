/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/common/Table";
import { getHistorialAsignaciones } from "./services";

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Fecha Asignación", value: "fecha_asignacion", tipo: "date" },
    { title: "Observaciones", value: "observaciones", tipo: "text" },
    { title: "No. Expediente", value: "expediente.numero_expediente", tipo: "text" },
    {
        title: "Profesional Asignado",
        value: null,
        values: [
            "profesional_asignado.first_name",
            "profesional_asignado.last_name"
        ],
        tipo: "text"
    },
    {
        title: "Profesional Anterior",
        value: null,
        values: [
            "profesional_anterior.first_name",
            "profesional_anterior.last_name"
        ],
        tipo: "text"
    }
];

export const TableHistorialAsignacionesExp = (props) => {
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const [reFetch, setReFetch] = useState(false);
    const expediente = props.registro.id;
    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "histasignacionexpediente";
    //let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            //permisoAgregar = true;
        }
    });

    const fetchHistorial = () => {
        getHistorialAsignaciones(urlFetch, expediente ,props.token).then((historial) => {
          setDataTable(historial);
          setTableLoading(false);
          setReFetch(false);
        });
      }
    
      useEffect(fetchHistorial, [props.token, urlFetch, reFetch]);

    return (
        <div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={false}
                        showEliminar={false}
                        showEditar={false}
                        setOrder={setOrder}
                        order={order}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
