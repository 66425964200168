import { Link } from "react-router-dom";
import { DynamicIcon } from "../../components/common/DynamicIcon";
import { useSelector } from "react-redux";

const menu = [
  {
    permiso: "MisExpedientes",
    name: "Mis Expedientes",
    href: "/mis-expedientes",
    icon: 'DocumentTextIcon'
  },
  {
    permiso: "MisNotificacionesLegales",
    name: "Mis Notificaciones Legales",
    href: "/mis-notificaciones-legales",
    icon: 'FolderOpenIcon'
  },
  {
    permiso: "MisReferencias",
    name: "Mis Referencias",
    href: "/mis-referencias",
    icon: 'UserGroupIcon'
  },
  {
    permiso: "MisExpedientesHistoricos",
    name: "Mis Expedientes históricos",
    href: "/mis-expedientes-historicos",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "MisSeguimientosLegales",
    name: "Mis Seguimientos Legales",
    href: "/mis-seguimientos",
    icon: 'ClipboardCheckIcon'
  },
  
  {
    permiso: "MisProcuracionesProgramadas",
    name: "Mis Procuraciones",
    href: "/mis-procuraciones",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "MisAudiencias",
    name: "Mis Audiencias",
    href: "/mis-audiencias",
    icon: 'ClipboardCheckIcon' 
  },
  {
    permiso: "MisAudienciasProgramadas",
    name: "Mis Audiencias programadas",
    href: "/mis-audiencias-planificadas",
    icon: 'ClipboardCheckIcon'
  },
  {
    permiso: "MisAccionesVictimológicas",
    name: "Mis Acciones Victimológicas",
    href: "/mis-acciones",
    icon: 'ClipboardCheckIcon'
  }, 
  {
    permiso: "MisAtencionesComplementarias",
    name: "Mis Atenciones complementarias",
    href: "/mis-atenciones-acciones",
    icon: 'ClipboardCheckIcon'
  },
];



export const Menu = () => {

  const permisos = useSelector(state => state.ChangePermisos?.menus?.misregistros?.menus ? state.ChangePermisos?.menus?.misregistros?.menus: []);
console.log(permisos);

  const checkPermiso = (item) => {
    let retorno = false;

    permisos.forEach(p => {
      if(p.permiso === item.permiso){
        retorno = p.ver;
      }
    })

    //retorno = true;
    return retorno;
  }
  return (
    <div>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 m-0">
        {menu.map((item, index) =>
          checkPermiso(item) &&
          <Link key={`${index}-mis`} to={item.href}>
            <li className="col-span-1 flex shadow-lg rounded-md h-22">
              <div className="flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-maivi-primaryLightBlue"></div>
              <div className="h-24 flex-1 flex flex-col items-center justify-between border-t border-r border-b border-maivi-primaryLightBlue bg-white rounded-r-md whitespace-pre-line break-word hover:bg-maivi-secondaryBlueHover">
                <div className="flex flex-col items-center text-sm truncate my-auto">
                  <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                  <p className="text-xs xl:text-sm lg:text-xs md:text-xs sm:text-xs text-gray-900 text-center font-medium">
                    {item.name}
                  </p>
                </div>
              </div>
            </li>
          </Link>
        )}
      </ul>
    </div>
  );
};
