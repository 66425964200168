import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { SelectSiavi } from "../../../../../components/common/Select";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import {
    postExpCriterioCierre,
    getMotivoCierre,
    getCriterioCierre,
    postGestionaCriterioCierre,
    putExpCriterioCierre,
    deleteGestionaCriterioCierre,
    getExpCriterioCierre,
    deleteExpCriterioCierre,
    getExpCriterioCierreGestion,
    getGestionaCriterioCierreCaso
} from "./services";
import Spinner from "../../../../../components/common/Spinner";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import Label from "../../../../../components/common/Label";
import Toggle from "../../../../../components/common/Toggle";
import moment from "moment";

const validaciones = Yup.object().shape({
    // resultado: Yup.string()
    //     .required("Resultado es requerido")
    //     .max(150, "Máximo 150 caracteres"),
});

const useFetchExpCierre = (idCaso, setCargando, refetch, token) => {
    const [state, setState] = useState({
        expCierre: [],
    });

    useEffect(() => {
        getGestionaCriterioCierreCaso(idCaso, token).then((data) => {
            setState({
                expCierre: data,
            });

            setCargando(false);
        });
    }, [idCaso, setCargando, refetch, token]);

    return state;
};

const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";
const thClass = "px-2 py-1 text-left font-medium text-maivi-primaryFont text-sm";

export const CierreCaso = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.user?.id);
    const gestionFecha = bloqueoFecha();
    const idCaso = props?.infoCaso?.id;
    const [cargando, setCargando] = useState(true);
    const [refetch, setRefetch] = useState(false);
    const [motivos, setMotivos] = useState([]);
    const [criterios, setCriterios] = useState([]);
    const [detalleCierres, setCierresDetalle] = useState([]);
    const { expCierre } = useFetchExpCierre(idCaso, setCargando, refetch, token);
    const [idExpCriterioCierre, setIdExpCriterioCierre] = useState(0);
    const [edit, setEdit] = useState(false);
    const [nuevoRegistro, setNuevoRegistro] = useState(false);

    useEffect(() => {
        getMotivoCierre(token).then((data) => {
            setMotivos(data);
        });
    }, [token]);

    useEffect(() => {
        getCriterioCierre(token).then((data) => {
            setCriterios(data);
        });
    }, [token]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    caso: props.infoCaso.id,
                    expediente: null,
                    tipo_proceso: null,
                    resultado: "",
                    comentarios: "",
                    fecha: gestionFecha,
                    fecha_gestionado: gestionFecha,
                    se_cumple_criterio: false,
                    motivo: "",
                    usuario_gestionado: idUser,
                    profesional_crea: idUser,
                    gestiona_cierre: false,
                    fecha_gestiona: "",
                    cierre_autorizado: null,
                    observaciones: "",
                    profesional_gestiona: idUser,
                    profesional_solicita: idUser
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (edit) {
                        await putExpCriterioCierre(idExpCriterioCierre, values, token)
                            .then(async (data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                    getExpCriterioCierreGestion(idExpCriterioCierre, token).then(
                                        (data) => {
                                            setCierresDetalle(data?.results);
                                        }
                                    );
                                    toast.success("Cierre del Expediente actualizada con éxito..!");
                                    setRefetch(true);
                                    resetForm({});
                                    setEdit(false);
                                    setNuevoRegistro(false);
                                    setRefetch(false);
                                } else {
                                    toast.success("Cierre del Expediente actualizada con éxito..!");
                                }
                            })
                            .catch(function (error) {
                                if (
                                    error.response.status >= 400 &&
                                    error.response.status <= 450
                                ) {
                                    toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                    toast.error("Error interno comuniquese con el administrador");
                                }
                            });

                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-3">
                            <div className="col-span-12 xl:col-span-5 lg:col-span-5 border p-3 rounded-md shadow-sm hover:shadow-md">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <SelectSiavi
                                            label="Motivo Cierre"
                                            name="motivo"
                                            value={values.motivo}
                                            onChange={(e) => {
                                                setFieldValue("motivo", e.target.value);
                                            }}
                                            validacion={true}
                                            valueInit=""
                                            selectInit="Elegir motivo"
                                            list={
                                                motivos?.results?.map((item) => {
                                                    return (
                                                        <option key={item.nombre + "mc" + item.id} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <InputSiavi
                                            type="text"
                                            name="observaciones"
                                            id="observaciones"
                                            value={values.observaciones}
                                            onChange={handleChange}
                                            label="Observaciones"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                        <InputSiavi
                                            type="date"
                                            name="fecha_gestiona"
                                            id="fecha_gestiona"
                                            value={values.fecha_gestiona}
                                            onChange={handleChange}
                                            label="Fecha"
                                        />
                                    </div>
                                    <div className="col-span-12 xl:col-span-6 lg:col-span-6 flex items-end justify-end">
                                        <ButtonSiavi
                                            type="button"
                                            text="Crear gestión"
                                            onClick={async () => {
                                                setCargando(true);
                                                await postGestionaCriterioCierre(values, token)
                                                    .then(async (data) => {
                                                        if (data?.status === 200 || data?.status === 201) {
                                                            toast.success("Gestión de cierre creada con éxito..!");
                                                            setRefetch(true);
                                                            await criterios?.results?.filter(x => x.motivo_cierre === Number(values.motivo)).forEach((item) => {
                                                                values.gestionado = data.data.id;
                                                                values.fecha = data.data.fecha_gestiona;
                                                                values.expediente = null;
                                                                values.caso = data.data.caso.id;
                                                                values.tipo_proceso = null;
                                                                values.criterio = item.id;
                                                                postExpCriterioCierre(values, token)
                                                                    .then((data) => {
                                                                        if (data?.status === 200 || data?.status === 201) {
                                                                            getExpCriterioCierreGestion(idExpCriterioCierre, token).then(
                                                                                (data) => {
                                                                                    setCierresDetalle(data?.results);
                                                                                }
                                                                            );
                                                                            toast.success("Cierre del Expediente creada con éxito..!");
                                                                            setNuevoRegistro(false);
                                                                            setRefetch(true);
                                                                        } else {
                                                                            toast.success("Cierre del Expediente creada con éxito..!");
                                                                        }
                                                                    })
                                                                    .catch(function (error) {
                                                                        if (
                                                                            error.response.status >= 400 &&
                                                                            error.response.status <= 450
                                                                        ) {
                                                                            toast.error(error.response.data.message);
                                                                        } else if (error.response.status >= 500) {
                                                                            toast.error("Error interno comuniquese con el administrador");
                                                                        }
                                                                    });
                                                            })
                                                            resetForm({});
                                                            setRefetch(false);
                                                            setCargando(false);
                                                        }
                                                    })
                                                    .catch(function (error) {
                                                        if (
                                                            error.response.status >= 400 &&
                                                            error.response.status <= 450
                                                        ) {
                                                            setCargando(false);
                                                            toast.error(error.response.data.message);
                                                        } else if (error.response.status >= 500) {
                                                            setCargando(false);
                                                            toast.error("Error interno comuniquese con el administrador");
                                                        }
                                                    });
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    (cargando) ? <div className="mt-4"><Spinner /> </div> :
                                        <table className="table w-full border mt-5 mb-5">
                                            <thead className="bg-maivi-gray-5 rounded-md">
                                                <tr>
                                                    <th className={thClass}>Caso</th>
                                                    <th className={thClass}>Fecha gestiona</th>
                                                    <th className={thClass}>Observaciones</th>
                                                    <th className={thClass}>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                                                {expCierre?.results?.map((item) => (
                                                    <tr
                                                        className="hover:bg-maivi-gray-5 cursor-pointer"
                                                        onClick={() => {
                                                            getExpCriterioCierreGestion(item.id, token).then(
                                                                (data) => {
                                                                    setCierresDetalle(data?.results);
                                                                }
                                                            );
                                                            resetForm({});
                                                            setEdit(false);
                                                            setIdExpCriterioCierre(item.id);
                                                        }}
                                                    >
                                                        <td className={tdClass}>{item?.caso?.numero_caso}</td>
                                                        <td className={tdClass}>{moment(item?.fecha_gestiona).format("DD-MM-YYYY")}</td>
                                                        <td className={tdClass}>{item?.observaciones}</td>
                                                        <td className={tdClass}>
                                                            <button
                                                                type="button"
                                                                onClick={async () => {
                                                                    setCargando(true);
                                                                    await deleteGestionaCriterioCierre(item.id, token)
                                                                        .then((data) => {
                                                                            if (data?.status === 200 || data?.status === 201) {
                                                                                setCargando(false);
                                                                                toast.success("Registro eliminado con éxito...!");
                                                                                setRefetch(true);
                                                                                setRefetch(false);
                                                                            } else {
                                                                                setRefetch(true);
                                                                                setRefetch(false);
                                                                                toast.success("Registro eliminado con éxito...!");
                                                                            }
                                                                        })
                                                                        .catch(function (error) {
                                                                            if (
                                                                                error.response.status === 400 ||
                                                                                error.response.status === 401
                                                                            ) {
                                                                                setCargando(false);
                                                                                toast.error(error.response.data.message);
                                                                            } else if (error.response.status >= 500) {
                                                                                toast.error(
                                                                                    "Error interno comuniquese con el administrador"
                                                                                );
                                                                            }
                                                                        });
                                                                }}
                                                                className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                            >
                                                                <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                            </button>
                                                            {/* <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setNuevoRegistro(true);
                                                                    setIdExpCriterioCierre(item.id);
                                                                    setEdit(false);
                                                                }}
                                                                className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                                                            >
                                                                <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                            </button> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                }

                            </div>
                            <div className="col-span-12 xl:col-span-7 lg:col-span-7 border p-3 rounded-md shadow-sm hover:shadow-md ">
                                <Label text="Agregar detalle de cierre" />
                                {
                                    nuevoRegistro &&
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-6 flex flex-col justify-center items-center">
                                            <Label text="Se cumple criterio" />
                                            <Toggle enabled={values.se_cumple_criterio} name="se_cumple_criterio" />
                                        </div>
                                        <div className="col-span-6">
                                            <InputSiavi
                                                type="text"
                                                name="resultado"
                                                id="resultado"
                                                value={values.resultado}
                                                onChange={handleChange}
                                                validacion={true}
                                                label="*Resultado"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <InputSiavi
                                                type="text"
                                                name="comentarios"
                                                id="comentarios"
                                                value={values.comentarios}
                                                onChange={handleChange}
                                                validacion={true}
                                                label="Comentarios"
                                            />
                                        </div>
                                        <div className="col-span-6 mt-5">
                                            <ButtonSiavi
                                                type="submit"
                                                disabled={isSubmitting}
                                                text={(edit) ? 'Actualizar' : 'Guardar'}
                                            />
                                        </div>
                                    </div>
                                }
                                <table className="table w-full border mt-2">
                                    <thead className="bg-maivi-gray-5 rounded-md">
                                        <tr>
                                            <th className={thClass}>Caso</th>
                                            <th className={thClass}>Criterio</th>
                                            <th className={thClass}>Fecha Gestionado</th>
                                            <th className={thClass}>Se Cumple Criterio</th>
                                            <th className={thClass}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                                        {detalleCierres?.map((item) => (
                                            <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                                                <td className={tdClass}>{item?.caso?.numero_caso}</td>
                                                <td className={tdClass}>{item?.criterio?.nombre}</td>
                                                <td className={tdClass}>{moment(item?.fecha_gestionado).format("DD-MM-YYYY")}</td>
                                                <td className={tdClass}>{(item?.se_cumple_criterio === true) ? "Sí" : "No"}</td>
                                                <td className={tdClass}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setFieldValue('resultado', item?.resultado);
                                                            setFieldValue('comentarios', item?.comentarios);
                                                            setFieldValue('fecha_gestionado', item?.fecha_gestionado);
                                                            setFieldValue('se_cumple_criterio', item?.se_cumple_criterio);
                                                            setFieldValue('criterio', item.criterio.id);
                                                            setFieldValue('gestionado', item.gestionado.id);
                                                            setFieldValue('expediente', null);
                                                            setFieldValue('caso', item?.caso?.id);
                                                            setFieldValue('tipo_proceso', null);
                                                            setEdit(true);
                                                            setNuevoRegistro(true);
                                                            setIdExpCriterioCierre(item.id)
                                                        }}
                                                        className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                             rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                    >
                                                        <PencilIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            deleteExpCriterioCierre(item.id, token).then(() => {
                                                                getExpCriterioCierre(idExpCriterioCierre, token).then((data) => {
                                                                    setCierresDetalle(data?.results);
                                                                });
                                                                toast.success('registro eliminado con éxito');
                                                            });
                                                        }}
                                                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                             rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                                                    >
                                                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};