import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { putAcciones, getProfesionales } from "./services";
import Spinner from "../../../components/common/Spinner";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import moment from "moment";

const validaciones = Yup.object().shape({
    //observaciones: Yup.string().required("observaciones son requeridas"),
});

export const Gestiones = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const profesional = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [profesionales, setProfesionales] = useState([]);
    //const [cargando, setCargando] = useState(false);
    const optionsProfesionales = [];
    const [accion, setAccion] = useState("")

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setProfesionales(data);
        });
    }, [token]);

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
        };
        optionsProfesionales.push(reg);
    });


    return (
        <Formik
            initialValues={{

            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                let body = {};
                if (accion === "aceptar") {
                    body = {
                        autorizado: true,
                        fecha_autoriza: moment().format("YYYY-MM-DD"),
                        completado: true,
                        fecha_completado: moment().format("YYYY-MM-DD"),
                        profesional_realiza: profesional
                    }
                } else {
                    body = {
                        autorizado: false,
                        fecha_autoriza: moment().format("YYYY-MM-DD"),
                        profesional_autoriza: profesional,
                        estado:false
                    }
                }

                await putAcciones(props.registro.id, body, token).then((data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                        toast.success('accion completada con éxito');
                        props.setGestionar(false);
                    }
                })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 410) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error('Error interno comuniquese con el administrador');
                        }
                    });
            }}
        >
            {({ isSubmitting, handleSubmit }) => (
                <div className="mx-5">
                    <div className="flex flex-row">
                        <ButtonSiavi
                            type="submit"
                            disabled={isSubmitting}
                            text={"Gestionar"}
                            onClick={() => {
                                setAccion("aceptar")
                                handleSubmit();
                            }}
                        />
                        <ButtonSiavi
                            type="submit"
                            disabled={isSubmitting}
                            text="Rechazar"
                            className="mx-2"
                            onClick={() => {
                                setAccion("rechazar");
                                handleSubmit();
                            }}
                        />
                        <ButtonSiavi
                            type="submit"
                            disabled={isSubmitting}
                            text="Cancelar"
                            onClick={() => {
                                props.setGestionar(false);
                            }}
                        />
                    </div>
                    <div className="mt-3 mb-3">
                        {isSubmitting && <Spinner />}
                    </div>
                </div>
            )}
        </Formik>
    );
};
