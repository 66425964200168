import { React } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getSubAreaSustantivaTipoPuesto, postTipoPuesto, putTipoPuesto } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
  puesto: Yup.string()
    .required("Puesto es requerido")
    .max(60, "Máximo 60 caracteres"),
  sub_area: Yup.string()
    .required("Sub área sustantiva es requerida")
    .max(100, "Máximo 100 caracteres"),
});

const useFetchSubAreaSustantiva = (token) => {
  const [state, setState] = useState({
    sub_area: [],
  });

  useEffect(() => {
    getSubAreaSustantivaTipoPuesto(token).then((sub_area) => {
      setState({
        sub_area: sub_area,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { sub_area } = useFetchSubAreaSustantiva(token);
  return (
    <div>
      <Formik
        initialValues={{
          puesto: props.editar ? props.value.puesto : "",
          sub_area: props.editar ? props.value.sub_area : "",
          estado: props.editar ? props.value.estado : true,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putTipoPuesto(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              }else{
                props.setReFetch(true);
                toast.success('Registro actualizado con éxito...!')
              }
            });
          } else {
            await postTipoPuesto(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              }else{
                props.setReFetch(true);
                toast.success('Registro creado con éxito...!')
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="puesto"
                  id="puesto"
                  value={values.puesto}
                  onChange={handleChange}
                  validacion={true}
                  label="Puesto*"
                />
              </div>
              <div className="col-span-6 mt-4">
                <Label text="Subárea sustantiva*" />
                <div className="mt-1">
                  <select
                    id="sub_area"
                    name="sub_area"
                    autoComplete="off"
                    value={values.sub_area}
                    onChange={handleChange}
                    className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue
                     focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                  >
                    <option value="" disabled>Elegir sub área sustantiva</option>
                    {sub_area?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="sub_area"
                    component="span"
                    className="text-sm text-maivi-secondaryRed"
                  />
                </div>
              </div>
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
