/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getExpediente } from "./services";

export const FormDetalle = (props) => {
    const [expediente, setExpediente] = useState({});
    useEffect(() => {
        getExpediente(props.detalle.expediente.id, props.token).then((data) => {
            setExpediente(data);
        });
    }, [])

    console.log(props.detalle);
    console.log(expediente)
    return (
        <div className="bg-white border rounded-md">
            <div className=" border-gray-200 p-0">
                <div className="sm:divide-y sm:divide-gray-200 ">
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            caso
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.caso?.numero_caso}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Expediente
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.expediente?.numero_expediente}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Tipo proceso
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.tipo_proceso?.tipo_proceso}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Tipo diligencia
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.tipo_diligencia?.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Urgente
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.urgente === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Enterado
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props.detalle.enterado === true ? (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Sí
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    No
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">Plazo</div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.plazo ? moment(props?.detalle?.plazo).format("DD-MM-YYYY") : ""}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Fecha enterado
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.fecha_enterado ? moment(props?.detalle?.fecha_enterado).format("DD-MM-YYYY") : ""}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Tipo documento
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.tipo_documento?.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Número documento
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.numero_documento}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Profesional asignado
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {expediente?.profesional_asignado?.first_name}  {expediente?.profesional_asignado?.last_name}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Modalidad
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.modalidad?.nombre}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Usuario Creación
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.usuario_creacion?.first_name} {props?.detalle?.usuario_creacion?.last_name}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Usuario enterado
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.usuario_enterado?.first_name} {props?.detalle?.usuario_enterado?.last_name}
                        </div>
                    </div>
                    <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                        <div className="text-xs font-medium text-gray-500">
                            Comentarios
                        </div>
                        <div className="text-xs text-gray-900 sm:col-span-2">
                            {props?.detalle?.comentarios}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
