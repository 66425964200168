import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import { ButtonSiavi } from "../../../../components/common/Button";
import {  getProfesionales,getDelitos,getMunicipio,getSedes } from "../../services";
import { useSelector } from "react-redux";
import { RenderJasper } from "../../RenderJasper";
import { descargarExcel } from "../../services";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

export const Parametros = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const usuario = useSelector((state) => (state.loginReducer.data.usuario.user.first_name+" "+state.loginReducer.data.usuario.user.last_name));
  const animatedComponents = makeAnimated();
  const [sede, setSede] = useState(null);
  const [profesional, setProfesional] = useState(null);
  const [profesionalList, setProfesionalList] = useState([]);
  const [delito,setDelito]=useState(null);
  const [expediente, setExpediente] = useState(null);
  const [edad, setEdad] = useState(null);
  const [departamento, setDepartamento] =useState(null)
  const [municipio, setMunicipio] = useState(null);
  const [municipioList,setMunicipioList]=useState([]);

  let data =[]
  let data2=[]
  let data3=[]

  const useFetchProfesionales = () => {
    const [state, setState] = useState({
      profesionales: [],
    });
    
    useEffect(() => {
      getProfesionales(token).then((data) => {
        setState({
          profesionales: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchSedes = () => {
    const [state, setState] = useState({
      sedes: [],
    });
    
    useEffect(() => {
      getSedes(token).then((data) => {
        setState({
          sedes: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchDelito = () => {
    const [state, setState] = useState({
      delitos: [],
    });
    
    useEffect(() => {
      getDelitos(token).then((data) => {
        setState({
          delitos: data,
        });
      });
    }, []);

    return state;
  };

  const useFetchMunicipio = () => {
    const [state, setState] = useState({
      municipios: [],
    });
    
    useEffect(() => {
      getMunicipio(token).then((data) => {
        setState({
          municipios: data,
        });
      });
    }, []);

    return state;
  };

  const {sedes}=useFetchSedes();
  const { profesionales } = useFetchProfesionales();
  const { delitos } = useFetchDelito();
  const { municipios } = useFetchMunicipio();
//Área 1 es para DSV y área 2 es para DAL
  {profesionales?.results?.map((item) => {
    if(item?.tipo_puesto?.sub_area_detalle?.area==2)
    {
      data.push({sedeId:item?.sede?.id,sede:item?.sede?.sede,profesionalId:item?.id,profesional:item.first_name+' '+item.last_name})
    }
  })}

  {municipios?.results?.map((item) => {
    
      data2.push({departamentoId:item?.departamento_detalle?.id,departamento:item?.departamento_detalle?.nombre,municipioId:item?.id,municipio:item.nombre})
    
  })}

  {sedes?.results?.map((item) => {
    
    data3.push({sedeId:item.id,sede:item.sede})
  
})}

  data.sort(function(a,b)
  {
    if (a.profesional>b.profesional)
    {
      return 1
    }
    if(a.profesional<b.profesional)
    {
      return -1
    }
    return 0
  })

  var groupBy = (arr, prop) =>{ 
    return arr.reduce((objs, obj) => {
        const sedeId = obj[prop];
        if (sedeId) {
            let fi = objs.findIndex(x => x.sedeId == sedeId);
            if (fi>=0) {
                objs[fi].profesionales = [...objs[fi].profesionales, {profesionalId:obj.profesionalId,profesional:obj.profesional}];
            } else {
                objs.push({
                    sedeId: sedeId,
                    sede:obj.sede,
                    profesionales: [{profesionalId:obj.profesionalId,profesional:obj.profesional}]
                })
            }
        }
        return objs;
    }, []);
}

var groupBy2 = (arr, prop) =>{ 
  return arr.reduce((objs, obj) => {
      const departamentoId = obj[prop];
      if (departamentoId) {
          let fi = objs.findIndex(x => x.departamentoId == departamentoId);
          if (fi>=0) {
              objs[fi].municipios = [...objs[fi].municipios, {municipioId:obj.municipioId,municipio:obj.municipio}];
          } else {
              objs.push({
                  departamentoId: departamentoId,
                  departamento:obj.departamento,
                  municipios: [{municipioId:obj.municipioId,municipio:obj.municipio}]
              })
          }
      }
      return objs;
  }, []);
}

// Dynamically create select list
let edades = [];
for (var i = 0; i < 121; i++) {
edades.push({id:i,value:i})
}

 
  // handle change event of the sede dropdown
  const handleSedeChange = (obj) => {
    setSede(obj);
   // setProfesionalList(obj.profesionales);
   // setProfesional(null);
  };
  
  // handle change event of the profesional dropdown
  const handleProfesionalChange = (obj) => {
    setProfesional(obj);
  };

  // handle change event of the profesional dropdown
  const handleDelitoChange = (obj) => {
    setDelito(obj);
  };

   // handle change event of the accion dropdown
   const handleExpedienteChange = (obj) => {
    setExpediente(obj);
    //console.log(expediente)
  };

  // handle change event of the accion dropdown
  const handleEdadChange = (obj) => {
    setEdad(obj);
    //console.log(expediente)
  };

    // handle change event of the sede dropdown
  const handleDepartamentoChange = (obj) => {
    setDepartamento(obj);
    setMunicipioList(obj.municipios);
    setMunicipio(null);
  };
  
  // handle change event of the profesional dropdown
  const handleMunicipioChange = (obj) => {
    setMunicipio(obj);
  };

//console.log(groupBy(data,'sedeId'))
  const pages = [
    { name: "Reportes", href: "/reportes", current: false },
    { name: "Sentencias DAL", href: "#", current: true },  ];

  return (
    <form autoComplete="off">
      <div className="lg:grid-cols-2 gap-12 sm:grid sm:grid-cols-1 sm:gap-12 md:grid lg:flex -mt-24 mb-10 justify-center items-start">
        <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
          <div className="flex justify-center col-span-12">

          </div>
          <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-8">
            <BreadCrumbTwo navegacion={pages} />
          </div>

          <h1 className="mb-1 text-base">Reporte de Sentencias</h1>
          <h2 className="block text-sm font-normal">Información específica por tipo de sentencia de Dirección de Asistencia Legal</h2>
          <Divider />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">

          <div className="grid grid-cols-1  lg:grid-cols-4 gap-4 lg:gap-8">
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha inicio:" />
              <InputSiavi
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                onChange={handleChange}
                value={values.fechaInicio}
              />
              <ErrorMessage
                name="fechaInicio"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha Fin:" />
              <InputSiavi
                type="date"
                name="fechaFin"
                id="fechaFin"
                onChange={handleChange}
                value={values.fechaFin}
              />
              <ErrorMessage
                name="fechaFin"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
           
            <div className="sm:col-span-4 col-span-12">
              <Label text="Sede" />
              <Select
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
          placeholder="Seleccione una sede"
          value={sede}
          options={data3}
          onChange={handleSedeChange}
          getOptionLabel={x => x.sede}
          getOptionValue={x => x.sedeId}
          defaultValue={"0" || 'Todas'}
        />
        <br />
        <Label text="Profesionales" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Seleccione un profesional"
          value={profesional}
          options={data}
          onChange={handleProfesionalChange}
          getOptionLabel={x => x.profesional}
          getOptionValue={x => x.profesionalId}
          defaultValue={"0" || 'Todos'}
        />

<br />
        <Label text="Delitos" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Seleccione un delito"
          value={delito}
          options={delitos.results}
          onChange={handleDelitoChange}
          getOptionLabel={x => x.nombre}
          getOptionValue={x => x.id}
          defaultValue={"0" || 'Todos'}
        />

<br />
              <Label text="Tipo de Sentencia" />
              <Select
              isClearable
          placeholder="Todas"
          value={expediente}
          options={[{id:0,valor:"ABSOLUTORIA"},{id:1,valor:"CONDENATORIA"}]}
          onChange={handleExpedienteChange}
          getOptionLabel={x => x.valor}
          getOptionValue={x => x.id}
          defaultValue={"0" || 'Todas'}
        />
        <br />
        <Label text="Edad" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Seleccione la edad"
          value={edad}
          options={edades}
          onChange={handleEdadChange}
          getOptionLabel={x => x.id}
          getOptionValue={x => x.value}
          defaultValue={"0" || 'Todqs'}
        />
        <br />
        <Label text="Departamento" />
              <Select
          placeholder="Seleccione un departamento"
          value={departamento}
          options={groupBy2(data2,'departamentoId')}
          onChange={handleDepartamentoChange}
          getOptionLabel={x => x.departamento}
          getOptionValue={x => x.departamentoId}
          defaultValue={"0" || 'Todos'}
        />
        <br />
        <Label text="Municipios" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Seleccione un municipio"
          value={municipio}
          options={municipioList}
          onChange={handleMunicipioChange}
          getOptionLabel={x => x.municipio}
          getOptionValue={x => x.municipioId}
          defaultValue={"0" || 'Todos'}
        />




              <ErrorMessage
                name="sedes"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>

            <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
                text="Generar Reporte"
              />
            </div>
            <div className="sm:col-span-2 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  const param ={usuario:usuario,
                    fechaInicio:values.fechaInicio,
                    fechaFin:values.fechaFin,
                    sedes:sede==null?[]:sede,
                    profesionales:profesional==null?0:profesional.profesionalId,
                    delitos:delito==null?0:delito,
                  usuario:usuario,
                  expediente:expediente,
                  edades:edad==null?0:edad,
                  departamentos:departamento==null?0:departamento.departamentoId,
                  municipios:municipio==null?0:municipio 
                  }
                  descargarExcel('/DAL/Sentencias',param);
                }}
                disabled={isSubmitting}
                text="Descargar Excel"
              />
            </div>

          </div>


            <div className="grid grid-cols-1 col-span-3">
              <div className="col-span-12">
                {isSubmitting && (
                  <Spinner />
                )}
                {hasError && (
                  <Alert
                    type="error"
                    mensaje={mensaje}
                  />
                )}
                {success && (
                  <><Alert
                  type="success"
                  mensaje={mensaje} /><RenderJasper
                  fechaInicio={values.fechaInicio}
                  fechaFin={values.fechaFin}
                  sedes={sede==null?[]:sede}      
                  profesionales={profesional==null?0:profesional}
                  delitos={delito==null?0:delito}
                  usuario={usuario}
                  expediente={expediente}
                  edades={edad==null?0:edad}
                  departamentos={departamento==null?0:departamento.departamentoId}      
                  municipios={municipio==null?0:municipio}
                  reporte={"/DAL/Sentencias"} />
                    </>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  );
};
