import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../components/common/InputText";
import { TextAreaSiavi } from "../../../components/common/TextArea";
import { ButtonSiavi } from "../../../components/common/Button";
import { useSelector } from "react-redux";
import { putAcciones, getProfesionales } from "./services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { bloqueoFecha, bloqueoFechaHora } from "../../../helpers/utils";

const validaciones = Yup.object().shape({
    observaciones: Yup.string().required("observaciones son requeridas"),
    fecha_completada: Yup.string().required("fecha es requerida")
});

export const Gestionar = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const profesional = useSelector((state) => state.loginReducer.data.usuario.user.id);
    const [profesionales, setProfesionales] = useState([]);
    const [profesionalSelect, setProfesional] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [completada, setCompletada] = useState(false);
    const [reasignar, setReasignar] = useState(false);
    const [cambioFecha, setCambioFecha] = useState(false);
    const animatedComponents = makeAnimated();
    const optionsProfesionales = [];

    useEffect(() => {
        getProfesionales(token).then((data) => {
            setProfesionales(data);
        });
    }, [token]);

    profesionales?.results?.forEach((item) => {
        let reg = {
            value: item.id,
            label: `${item?.first_name} ${item?.last_name} - ${item?.tipo_puesto?.puesto}`,
        };
        optionsProfesionales.push(reg);
    });

    const changeSelectProfesionales = (e) => {
        setProfesional(e);
    };

    return (
        <Formik
            initialValues={{
                observaciones: "",
                completada: true,
                fecha_completada: "",
                profesional: profesional,
                fecha_hora_planificada_inicia: "",
                observaciones_fecha: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                if (completada) {
                    let body = {
                        observaciones_gestiona: values.observaciones,
                        completada: values.completada,
                        fecha_completado: values.fecha_completada,
                        profesional_autoriza: values.profesional,
                        accion_seguimiento : props?.registro?.accion_seguimiento?.id
                    }

                    await putAcciones(props.registro.id, body, token).then((data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success('reasignacion realizada con éxito');
                            props.setGestionar(false);
                        }
                    })
                        .catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 410) {
                                toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                                toast.error('Error interno comuniquese con el administrador');
                            }
                        });
                }
            }}
        >
            {({ values, handleChange, isSubmitting, handleSubmit }) => (
                <div className="mx-5">
                    <TextAreaSiavi
                        rows={6}
                        name="observaciones"
                        value={values.observaciones}
                        onChange={handleChange}
                        validacion={true}
                        label="Observaciones"
                    />
                    <div className="mt-4 mb-2">
                        <InputSiavi
                            type="date"
                            name="fecha_completada"
                            value={values.fecha_completada}
                            onChange={handleChange}
                            validacion={true}
                            label="Fecha completada"
                        />
                    </div>
                    <div className="flex flex-row">
                        <ButtonSiavi
                            type="submit"
                            disabled={isSubmitting}
                            text={"Completada"}
                            onClick={() => {
                                setCompletada(true);
                                handleSubmit();
                            }}
                        />
                        <ButtonSiavi
                            type="submit"
                            className="ml-2"
                            disabled={isSubmitting}
                            text="Reasignar"
                            onClick={() => {
                                if (props.registro.es_vencida === true) {
                                    toast.error("No se puede reasignar una audiencia vencida");
                                } else {
                                    setReasignar(true);
                                }
                            }}
                        />
                        <ButtonSiavi
                            className="ml-2"
                            type="submit"
                            disabled={isSubmitting}
                            text="Cambiar fecha"
                            onClick={() => {
                                setCambioFecha(true);
                            }}
                        />
                    </div>
                    <div className="mt-3 mb-3">
                        {isSubmitting && <Spinner />}
                    </div>
                    {reasignar && (
                        <ModalGenerico
                            title={`Reasignar accion`}
                            open={reasignar}
                            closeModal={() => { }}
                            cerrar={() => {
                                setReasignar(false);
                            }}
                            children={
                                <div className="mb-28">
                                    <Select
                                        isMulti={false}
                                        placeholder="Seleccionar"
                                        components={animatedComponents}
                                        name="servicios"
                                        className="basic-multi-select text-sm w-full mb-4"
                                        classNamePrefix="seleccione"
                                        options={optionsProfesionales}
                                        onChange={changeSelectProfesionales}
                                        maxMenuHeight={140}
                                    />
                                    <ButtonSiavi
                                        type="button"
                                        text="Reasignar"
                                        onClick={async () => {
                                            setCargando(true);
                                            let body = {
                                                profesional_asignado: profesionalSelect.value
                                            }

                                            await putAcciones(props.registro.id, body, token).then((data) => {
                                                if (data?.status >= 200 && data?.status <= 210) {
                                                    toast.success('reasignacion realizada con éxito');
                                                    setCargando(false);
                                                    setReasignar(false);
                                                    props.setGestionar(false);
                                                }
                                            })
                                                .catch(function (error) {
                                                    if (error.response.status >= 400 && error.response.status <= 410) {
                                                        toast.error(error.response.data.message);
                                                        setCargando(false);
                                                    } else if (error.response.status >= 500) {
                                                        setCargando(false);
                                                        toast.error('Error interno comuniquese con el administrador');
                                                    }
                                                });
                                        }}
                                    />
                                    <div className="mt-3 mb-3">
                                        {cargando && <Spinner />}
                                    </div>
                                </div>
                            }
                        />
                    )}

                    {cambioFecha && (
                        <ModalGenerico
                            title={`Cambiar fecha`}
                            open={cambioFecha}
                            closeModal={() => { }}
                            cerrar={() => {
                                setCambioFecha(false);
                            }}
                            children={
                                <div className="mb-5">
                                    <InputSiavi
                                        type="datetime-local"
                                        maximo={bloqueoFecha()}
                                        name="fecha_hora_planificada_inicia"
                                        value={values.fecha_hora_planificada_inicia}
                                        minimo={bloqueoFechaHora()}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Fecha"
                                        className="mb-4"
                                    />
                                    <TextAreaSiavi
                                        label="Observaciones"
                                        name="observaciones_fecha"
                                        value={values.observaciones_fecha}
                                        onChange={handleChange}
                                        className="mb-4"
                                    />
                                    <ButtonSiavi
                                        type="button"
                                        text="Cambiar fecha"
                                        onClick={async () => {
                                            setCargando(true);
                                            let body = {
                                                fecha_hora_planificada_inicia: values.fecha_hora_planificada_inicia,
                                                observaciones_gestiona: values.observaciones_fecha,
                                            }

                                            await putAcciones(props.registro.id, body, token).then((data) => {
                                                if (data?.status >= 200 && data?.status <= 210) {
                                                    toast.success('cambio de fecha realizado con éxito');
                                                    setReasignar(false);
                                                    props.setGestionar(false);
                                                    setCargando(false);
                                                }
                                            })
                                                .catch(function (error) {
                                                    if (error.response.status >= 400 && error.response.status <= 410) {
                                                        toast.error(error.response.data.message);
                                                        setCargando(false);
                                                    } else if (error.response.status >= 500) {
                                                        setCargando(false);
                                                        toast.error('Error interno comuniquese con el administrador');
                                                    }
                                                });
                                        }}
                                    />
                                    <div className="mt-3 mb-3">
                                        {cargando && <Spinner />}
                                    </div>
                                </div>
                            }
                        />
                    )}
                </div>
            )}
        </Formik>
    );
};
