import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { postRecursos, putRecursos, getSedes, getUsuarios } from "./services";
import { useSelector } from "react-redux";
import { InputSiavi } from "../../../components/common/InputText";
import { SelectSiavi } from "../../../components/common/Select";
import { ButtonSiavi } from "../../../components/common/Button";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';
import * as Yup from "yup";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(255, "Máximo 100 caracteres"),
    profesional_responsable: Yup.string()
        .required("Profesional Responsable es requerido")
        .max(255, "Máximo 100 caracteres"),
    sede_recurso: Yup.string()
        .required("Sede Recurso es requerido")
        .max(255, "Máximo 100 caracteres")
});

const useFetchSedes = (token) => {
    const [state, setState] = useState({
        sedes: [],
    });

    useEffect(() => {
        getSedes(token).then((sedes) => {
            setState({
                sedes: sedes,
            });
        });
    }, [token]);

    return state;
};

const useFetchProResponsable = (token) => {
    const [state, setState] = useState({
        profesional_responsable: [],
    });

    useEffect(() => {
        getUsuarios(token).then((profesional_responsable) => {
            setState({
                profesional_responsable: profesional_responsable,
            });
        });
    }, [token]);

    return state;
};

const useFetchProAutoriza = (token) => {
    const [state, setState] = useState({
        profesional_autoriza: [],
    });

    useEffect(() => {
        getUsuarios(token).then((profesional_autoriza) => {
            setState({
                profesional_autoriza: profesional_autoriza,
            });
        });
    }, [token]);

    return state;
};

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { sedes } = useFetchSedes(token);
    const { profesional_responsable } = useFetchProResponsable(token);
    const { profesional_autoriza } = useFetchProAutoriza(token);

    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    descripcion: props.editar ? props.value.descripcion : "",
                    sede_recurso: props.editar ? props.value.sede_recurso.id : "",
                    profesional_responsable: props.editar ? props.value.profesional_responsable.id : "",
                    profesional_autoriza: props.editar ? props?.value?.profesional_autoriza?.id : null,
                    estado: props.editar ? props.value.estado : true,
                    requiere_autorizacion: props.editar ? props.value.requiere_autorizacion : false,
                    disponibilidad_por_dia: props.editar ? props.value.disponibilidad_por_dia : false,
                    disponibilidad_por_hora: props.editar ? props.value.disponibilidad_por_hora : false,
                    fecha_activado: props.editar ? props.value.fecha_activado : "",
                    fecha_inactivo: props.editar ? props.value.fecha_inactivo : "",
                    horario_inicio_disponibilidad: props.editar ? props.value.horario_inicio_disponibilidad : "",
                    horario_fin_disponibilidad: props.editar ? props.value.horario_fin_disponibilidad : ""
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putRecursos(props.value.id, values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro actualizado con éxito...!')
                            }
                        });
                    } else {
                        await postRecursos(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                props.setReFetch(true);
                                toast.success('Registro creado con éxito...!')
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mx-auto grid grid-cols-12 gap-3 p-3 border rounded-lg items-end">
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Nombre"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="text"
                                    name="descripcion"
                                    value={values.descripcion}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Descripción"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="*Sede Recurso"
                                    name="sede_recurso"
                                    value={values.sede_recurso}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir sede"
                                    list={sedes?.results?.filter((x) => x.es_sede_seguimiento === true).map((item, index) => {
                                        return (
                                            <option key={item.sede + index} value={item.id}>
                                                {item.sede}
                                            </option>
                                        );
                                    })}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text="Disponibilidad por día" />
                                <Toggle enabled={values.disponibilidad_por_dia} name="disponibilidad_por_dia" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text="Disponibilidad por hora" />
                                <Toggle enabled={values.disponibilidad_por_hora} name="disponibilidad_por_hora" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_activado"
                                    value={values.fecha_activado}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Fecha Inicia Disponibilidad"
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <InputSiavi
                                    type="date"
                                    name="fecha_inactivo"
                                    value={values.fecha_inactivo}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="Fecha Fin Disponibilidad"
                                />
                            </div>
                            {values.disponibilidad_por_hora && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="time"
                                        name="horario_inicio_disponibilidad"
                                        id="horario_inicio_disponibilidad"
                                        value={values.horario_inicio_disponibilidad}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Horario Inicio Disponibilidad"
                                    />
                                </div>)}
                            {values.disponibilidad_por_hora && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <InputSiavi
                                        type="time"
                                        name="horario_fin_disponibilidad"
                                        id="horario_fin_disponibilidad"
                                        value={values.horario_fin_disponibilidad}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="Horario Fin Disponibilidad"
                                    />
                                </div>)}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                <SelectSiavi
                                    label="*Profesional Responsable"
                                    name="profesional_responsable"
                                    value={values.profesional_responsable}
                                    onChange={handleChange}
                                    valueInit=""
                                    selectInit="Elegir profesional"
                                    list={profesional_responsable?.results?.map((item, index) => {
                                        return (
                                            <option key={item.username + index} value={item.id}>
                                                {item.first_name} {item.last_name}
                                            </option>
                                        );
                                    })}
                                />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text="¿Requiere Autorización?" />
                                <Toggle enabled={values.requiere_autorizacion} name="requiere_autorizacion" />
                            </div>
                            {values.requiere_autorizacion && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4">
                                    <SelectSiavi
                                        label="Profesional Autoriza"
                                        name="profesional_autoriza"
                                        value={values.profesional_autoriza}
                                        onChange={handleChange}
                                        valueInit=""
                                        selectInit="Profesional autoriza"
                                        list={profesional_autoriza?.results?.map((item, index) => {
                                            return (
                                                <option key={item.username + index + item.id} value={item.id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            );
                                        })}
                                    />
                                </div>
                            )}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {values.disponibilidad_por_hora
                                ?
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={props.editar ? "Actualizar" : "Guardar"}
                                    />
                                </div>
                                :
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-6 md:col-span-4 text-center">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={props.editar ? "Actualizar" : "Guardar"}
                                    />
                                </div>
                            }
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};