import { React } from "react";
import { BuscarPersona } from "./buscarPersona";

const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
text-xs border-maivi-gray-10 rounded-md bg-white text-maivi-primaryFont`;

export const Personas = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  customChange,
  setFieldValue,
  resetForm,
  caso,
  delitos,
  configuracion
}) => {
  return (
    <form autoComplete="off" onChange={customChange(values)}>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 xl:col-span-12">
          <BuscarPersona
            values={values}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            selectClass={selectClass}
            caso={caso}
            delitos={delitos}
            configuracion={configuracion}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleChange={handleChange}
          />
        </div>
      </div>
    </form>
  );
};
