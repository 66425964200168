import { React, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postPregunta, putPregunta } from "./services";
import Spinner from "../../../components/common/Spinner";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";

const validaciones = Yup.object().shape({
  pregunta: Yup.string()
    .required("Pregunta es requerida")
    .max(200, "Máximo 200 caracteres"),
});

export const Form = (props) => {
  const [isLoading, setCargando] = useState(false);
  const token = useSelector((state) => state.loginReducer.data.token);
  return (
    <div>
      <Formik
        initialValues={{
          pregunta: (props.editar) ? props.value.pregunta : "",
          estado: (props.editar) ? props.value.estado : false,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          setCargando(true);
          if (props.editar) {
            await putPregunta(props.id, values, token).then(() => {
              window.location = "/seguridad/preguntas";
            });
          } else {
            await postPregunta(values, token).then(() => {
              window.location = "/seguridad/preguntas";
            });
          }
          setCargando(false);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <Label text="Pregunta*" />
                <Field
                  type="text"
                  name="pregunta"
                  id="pregunta"
                  value={values.pregunta}
                  onChange={handleChange}
                  className="mt-1 shadow-sm py-2 px-3 border focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                />
                <ErrorMessage
                  name="pregunta"
                  component="span"
                  className="text-sm text-maivi-secondaryRed"
                />
              </div>
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isLoading && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
