/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../common/Button";
import { TextAreaSiavi } from "../common/TextArea";
import { clases } from "../../helpers/clases";
import { Formik } from "formik";
import { SelectSiavi } from "../common/Select";
import {
    getTipoSMSVictima,
    getMensajes,
    getTelefonos,
    postSms
} from "./services";
import TableSiavi from "../common/TableSiavi";
import Spinner from "../common/Spinner";
import moment from "moment";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Label from "../common/Label";

const validaciones = Yup.object().shape({
    mensaje: Yup.string()
      .required("Mensaje es requerido").max(150, "Máximo 150 caracteres"),
  });
  
export const MensajeriaSMS = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state?.loginReducer?.data?.usuario?.user?.id);
    const [tipos, setTipos] = useState([]);
    const [telefono,setTelefono] = useState(undefined);
    const [cargando, setCargando] = useState(false);
    const [mensajes, setMensajes] = useState([]);
    const [refetch,setRefetch] = useState(false);
    
    useEffect(() => {
        if (props?.por_caso) {
            getTelefonos(props?.registro?.victima?.id, token).then((data) => {
                data?.results?.forEach(element => {
                    if(element?.enviar_sms && element?.telefono_confirmado){
                        setTelefono(element);
                    }
                });
            });
        } else if(props?.por_expediente){
            getTelefonos(props?.registro?.caso_persona?.persona?.id, token).then((data) => {
                data?.results?.forEach(element => {
                    if(element?.enviar_sms && element?.telefono_confirmado){
                        setTelefono(element);
                    }
                });
            });
        } else if(props?.por_accion){
            getTelefonos(props?.registro?.expediente?.caso_persona?.persona?.id, token).then((data) => {
                data?.results?.forEach(element => {
                    if(element?.enviar_sms && element?.telefono_confirmado){
                        setTelefono(element);
                    }
                });
            });
        }

    },[])

    useEffect(() => {
        let filtros =""

        if(props?.por_caso){
            filtros ="para_victima=true"
            getTipoSMSVictima(filtros,token).then((data) => {
                setTipos(data?.results);
            });
        } else if(props?.por_expediente) {
            filtros ="por_servicio=true"
            getTipoSMSVictima(filtros,token).then((data) => {
                setTipos(data?.results);
            });
        }  else if(props?.por_accion) {
            filtros ="por_accion_seguimiento=true"
            getTipoSMSVictima(filtros,token).then((data) => {
                setTipos(data?.results);
            });
        }
      
    }, []);

    useEffect(() => {
        let filtro="";
        setCargando(true);
        if(props?.por_caso){
            filtro = `caso=${props?.registro.id}`;
            getMensajes(filtro, token).then((data) => {
                setMensajes(data?.results);
                setCargando(false);
            });
        } else if(props?.por_expediente) {
            filtro = `expediente=${props?.registro.id}`;
            getMensajes(filtro, token).then((data) => {
                setMensajes(data?.results);
                setCargando(false);
            });
        }  else if(props?.por_accion) {
            filtro = `expediente=${props?.registro?.expediente?.id}`;
            getMensajes(filtro, token).then((data) => {
                setMensajes(data?.results);
                setCargando(false);
            });
        }
    }, [refetch]);

    return (
        <Formik
            initialValues={{
                numero_destino: "",
                codigo_area: "",
                mensaje: "",
                mensaje_enviado: true,
                para_victima: true,
                por_caso: true,
                por_expediente: false,
                para_profesional: false,
                tipo_mensaje: "",
                caso_persona: null,
                caso: null,
                expediente: null,
                servicio: null,
                profesional_destino: null,
                editable: true
            }}
            validationSchema={validaciones}
            onSubmit={async (values,{resetForm}) => {
                if(props.por_caso){
                    values.numero_destino = telefono?.numero_telefono;
                    values.codigo_area = telefono?.codigo_area;
                    values.caso = props?.registro?.id;
                    values.mensaje_enviado = true;
                    values.para_victima = true;
                    values.por_caso = true;
                    values.por_expediente = false;
                    values.para_profesional = false;
                    values.caso_persona = null;
                    values.expediente = null;
                    values.servicio = null;
                    values.profesional_destino = null;
                    values.usuario_envia = usuario;
                } else if(props.por_expediente){
                    values.numero_destino = telefono?.numero_telefono;
                    values.codigo_area = telefono?.codigo_area;
                    values.caso = props?.registro?.caso;
                    values.mensaje_enviado = true;
                    values.para_victima = true;
                    values.por_caso = false;
                    values.por_expediente = true;
                    values.para_profesional = false;
                    values.caso_persona = props?.registro?.caso_persona?.id;
                    values.expediente = props?.registro?.id;
                    values.servicio = props?.registro?.servicio?.id;
                    values.profesional_destino = null;
                    values.usuario_envia = usuario;
                } else if(props.por_accion){
                    values.numero_destino = telefono?.numero_telefono;
                    values.codigo_area = telefono?.codigo_area;
                    values.caso = props?.registro?.expediente?.caso;
                    values.mensaje_enviado = true;
                    values.para_victima = true;
                    values.por_caso = false;
                    values.por_expediente = false;
                    values.para_profesional = false;
                    values.caso_persona = props?.registro?.expediente?.caso_persona?.id;
                    values.expediente = props?.registro?.expediente?.id;
                    values.servicio = props?.registro?.expediente?.servicio_propio?.id;
                    values.profesional_destino = null;
                    values.usuario_envia = usuario;
                }

                await postSms(values, token)
                .then((data) => {
                  if (data?.status >= 200 && data?.status <= 210) {
                    toast.success("Mensaje enviado con exito");
                    setRefetch(true);
                    setTimeout(() => {
                        setRefetch(false)
                    }, 300);
                    resetForm({})
                  }
                })
                .catch(function (error) {
                  if (error.response.status >= 400 && error.response.status <= 410) {
                    toast.error(error.response.data.message);
                  } else if (error.response.status >= 500) {
                    toast.error("Error interno comuniquese con el administrador");
                  }
                });
            }}
        >
            {({ values,setFieldValue,handleSubmit, handleChange, isSubmitting}) => (
                <div className="grid grid-cols-12 gap-3 p-3 ">
                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-5 border p-3 rounded-lg hover:shadow-md">
                           <div className="mb-3">
                           <SelectSiavi 
                                label="Tipo mensaje"
                                name="tipo_mensaje"
                                defaultValue=""
                                valueInit=""
                                selectInit="Tipo mensaje"
                                value={values.tipo_mensaje}
                                onChange={(e)=>{
                                    setFieldValue("tipo_mensaje", e.target.value);
                                    tipos.forEach(element => {
                                        if (element.id === Number(e.target.value)) {
                                            setFieldValue("editable", element.editable)
                                            if(props?.por_caso){
                                                setFieldValue("mensaje", element.mensaje
                                                .replace('{NOMBRE}',`${props?.registro?.victima?.primer_nombre} ${props?.registro?.victima?.primer_apellido} `)
                                                .replace('{NUMERO_CASO}',props?.registro?.numero_caso))
                                            } else if(props?.por_expediente){
                                                 setFieldValue("mensaje", element.mensaje
                                                .replace('{NOMBRE}',`${props?.registro?.caso_persona?.persona?.primer_nombre} ${props?.registro?.caso_persona?.persona?.primer_apellido} `)
                                                .replace('{NUMERO_CASO}', props?.registro?.caso_persona?.caso?.numero_caso)
                                                .replace('{PROFESIONAL_ASIGNADO}',`${props?.registro?.profesional_asignado?.first_name} ${props?.registro?.profesional_asignado?.last_name}`)
                                                .replace('{SERVICIO}',props?.registro?.servicio?.nombre))
                                            } else if(props?.accion_seguimiento){

                                            }

                                        }
                                    });
                                }}
                                list={
                                    tipos.map((item,index) =>{
                                        return <option key={`t-${index}`} value={item.id} >{item.nombre}</option>
                                    })
                                }
                            />
                           </div>
                           <div  className="mb-3">
                                <TextAreaSiavi
                                    label="Mensaje"
                                    rows={8}
                                    disabled={!values.editable}
                                    name="mensaje"
                                    value={values.mensaje}
                                    onChange={handleChange}
                                    validacion={true}
                                />
                                <Label text={`${values.mensaje.length} de 150 caracteres`}/>
                           </div>
                            {
                                isSubmitting ? <Spinner />
                                :
                                <ButtonSiavi
                                    type="button"
                                    text="Enviar mensaje"
                                    onClick={handleSubmit}
                                />
                            }
                         
                    </div>
                    <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-7 border p-3 rounded-lg hover:shadow-md " >
                        <div className="grid grid-cols-12 gap-3 ">
                            <div className="col-span-12 overflow-y-auto overflow-x-auto h-72 ">
                                <TableSiavi
                                    cargando={cargando}
                                    empty="No existen mensajes registrados"
                                    headers={[
                                        'Mensaje',
                                        'Destino',
                                        'Fecha'
                                    ]}
                                    body={
                                        mensajes?.map((item, index) =>
                                            <tr key={index} className="cursor-pointer hover:bg-gray-100">
                                                <td className={clases.td}>{item?.mensaje}</td>
                                                <td className={clases.td}>{item?.numero_destino}</td>
                                                <td className={clases.td}>{moment(item?.fecha_envio).format("DD-MM-YYYY")}</td>
                                            </tr>
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};
