import { React } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { postClasificacionBiblio, putClasificacionBiblio } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import Label from "../../../components/common/Label";
import { ButtonSiavi } from "../../../components/common/Button";
import { InputSiavi } from "../../../components/common/InputText";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const validaciones = Yup.object().shape({
    nombre: Yup.string()
        .required("Nombre es requerido")
        .max(100, "Máximo 100 caracteres")
});

export const Form = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);

    return (
        <div>
            <Formik
                initialValues={{
                    nombre: props.editar ? props.value.nombre : "",
                    estado: props.editar ? props.value.estado : true,
                }}
                validationSchema={validaciones}
                onSubmit={async (values) => {
                    if (props.editar) {
                        await putClasificacionBiblio(props.value.id, values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message);
                            } else {
                                props.setReFetch(true);
                                toast.success("Registro actualizado con éxito...!");
                            }
                        });
                    } else {
                        await postClasificacionBiblio(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message);
                            } else {
                                props.setReFetch(true);
                                toast.success("Registro creado con éxito...!");
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
                            <div className="col-span-6">
                                <InputSiavi
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    value={values.nombre}
                                    onChange={handleChange}
                                    validacion={true}
                                    label="*Nombre"
                                />
                            </div>
                            <div className="col-span-6 mt-5 flex flex-col">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            <div className="py-3 text-right">
                                <ButtonSiavi
                                    type="submit"
                                    disabled={isSubmitting}
                                    text={props.editar ? "Actualizar" : "Guardar"}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
        </div>
    );
};