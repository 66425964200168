/* eslint-disable array-callback-return */
import { React } from "react";
import { SelectSiavi } from "./Select";
import { TextAreaSiavi } from "./TextArea";
import { InputSiavi } from "./InputText";

export default function FormularioGenerico(props) {
    let agrupaciones = [];

    const listado = props.listadoPreguntas.sort(function (a, b) {
        if (a.ordenamiento > b.ordenamiento) {
            return 1;
        }
        if (a.ordenamiento < b.ordenamiento) {
            return -1;
        }
        return 0;
    });

    let agDesc = undefined;
    let tempAgrupaciones = {
        agrupacion: undefined,
        preguntas: []
    };

    for (let index = 0; index <= listado.length; index++) {
        if (index === 0) {
            agDesc = listado[index]?.agrupacion?.descripcion;
            tempAgrupaciones.agrupacion = agDesc;
        }
        else if (agDesc !== listado[index]?.agrupacion?.descripcion) {
            agrupaciones.push(tempAgrupaciones);
            tempAgrupaciones = {
                agrupacion: listado[index]?.agrupacion?.descripcion,
                preguntas: []
            };
        }

        agDesc = listado[index]?.agrupacion?.descripcion;
        tempAgrupaciones.preguntas.push(listado[index]);

        if (index === (listado.length-1) && agDesc === undefined) {
            agrupaciones.push(tempAgrupaciones);
        }
    }

    return (
        <div className="h-96 overflow-y-scroll mb-4">
            {
                agrupaciones?.map((group, index) => {
                    return <div key={index} className="border p-3 rounded-lg border-gray-500 mb-3">
                        <p className="text-maivi-primaryFont mb-2">{group?.agrupacion}</p>
                        {
                            group?.preguntas?.map((item, index) => {
                                if (item?.tipo_pregunta?.texto_extenso) {
                                    return <TextAreaSiavi
                                        key={"text-area-" + index}
                                        label={item?.pregunta}
                                        defaultValue={item?.texto_extenso}
                                        placeholder={item?.hint}
                                        rows={2}
                                        cols={2}
                                        required={item?.obligatorio}
                                        className="mb-2"
                                        onChange={(e) => {
                                            let listado = props.listadoPreguntas;
                                            props.listadoPreguntas.forEach((element,index) => {
                                                if(item?.id_pregunta === element.id_pregunta){
                                                    listado[index].texto_extenso = e.target.value;
                                                }
                                            });
                                            props.setListadoPreguntas(listado);
                                        }}
                                    />

                                } else if (item?.tipo_pregunta?.seleccion_simple) {
                                    return <SelectSiavi
                                        key={"select-" + index}
                                        label={item?.pregunta}
                                        required={item?.obligatorio}
                                        valueInit=""
                                        selectInit="Seleccionar"
                                        defaultValue={item?.valor_seleccion}
                                        className="mb-2"
                                        onChange={(e) => {
                                            let indice = e.target.selectedIndex;
                                            let listado = props.listadoPreguntas;

                                            props.listadoPreguntas.forEach((element,index) => {
                                                if(item?.id_pregunta === element.id_pregunta){
                                                    listado[index].respuesta_corta = e.target.options[indice].text;
                                                    listado[index].valor_seleccion = e.target.value;
                                                }
                                            });

                                            props.setListadoPreguntas(listado);
                                        }}
                                        list={
                                            item?.catalogo_seleccion?.detalles?.map((item2, index) =>
                                                <option value={item2.id} key={item2.id + index}>{item2?.text_mostrado}</option>
                                            )
                                        }
                                    />
                                }
                                else if (item?.tipo_pregunta?.linea_texto) {
                                    return <InputSiavi
                                        key={"input-" + index}
                                        name={item.id + index}
                                        label={item?.pregunta}
                                        required={item?.obligatorio}
                                        defaultValue={item?.respuesta_corta}
                                        placeholder={item?.hint}
                                        className="mb-2"
                                        onChange={(e) => {
                                            let listado = props.listadoPreguntas;
                                            props.listadoPreguntas.forEach((element,index) => {
                                                if(item?.id_pregunta === element.id_pregunta){
                                                    listado[index].respuesta_corta = e.target.value;
                                                }
                                            });
                                            props.setListadoPreguntas(listado);
                                        }}
                                    />

                                }

                                else if (item?.tipo_pregunta?.numerico) {
                                    return <InputSiavi
                                        type="number"
                                        key={"input-" + index}
                                        name={item.id + index}
                                        label={item?.pregunta}
                                        required={item?.obligatorio}
                                        defaultValue={item?.respuesta_corta}
                                        placeholder={item?.hint}
                                        className="mb-2"
                                        onChange={(e) => {
                                            let listado = props.listadoPreguntas;
                                            props.listadoPreguntas.forEach((element,index) => {
                                                if(item?.id_pregunta === element.id_pregunta){
                                                    listado[index].respuesta_corta = e.target.value;
                                                }
                                            });
                                            props.setListadoPreguntas(listado);
                                        }}
                                    />

                                }

                                else if (item?.tipo_pregunta?.fecha) {
                                    return <InputSiavi
                                        type="date"
                                        key={"input-" + index}
                                        name={item.id + index}
                                        label={item?.pregunta}
                                        required={item?.obligatorio}
                                        defaultValue={item?.respuesta_corta}
                                        placeholder={item?.hint}
                                        className="mb-2"
                                        onChange={(e) => {
                                            let listado = props.listadoPreguntas;
                                            props.listadoPreguntas.forEach((element,index) => {
                                                if(item?.id_pregunta === element.id_pregunta){
                                                    listado[index].respuesta_corta = e.target.value;
                                                }
                                            });
                                            props.setListadoPreguntas(listado);
                                        }}
                                    />

                                }
                            })
                        }
                    </div>
                })
            }
        </div>
    );
}