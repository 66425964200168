import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getPersonasCaso = async (idCaso,token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/casopersona/?caso=${idCaso}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
}

export const getAcciones = async (persona, urlPage, token) => {
    try {
        let urlFetch =
        urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/?persona=${persona}&es_accion=true`
        : `${urlPage}&persona=${persona}&es_accion=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getAtenciones = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/atencion/?estado=true&page_size=300`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCatalogoAccionesSeguimientos = async (servicio,token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=200&estado=true&servicio=${servicio}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (servicio, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&profesionalservicios__servicio=${servicio}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const actualizarRecurso = async (id,data,token) => {
    try {
        let body ={
            disponible: false,
            // descripcion: data.descripcion,
            // fecha: data.fecha,
            // hora: data.hora,
            recurso: data?.recurso?.id,
            accion_recurso: data?.accion
        }
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/catalogos/recursodisponibilidad/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };