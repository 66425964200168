import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { Registrar } from "./editar";
import { putUsuario } from "../services";
import { 
  useParams,
  useHistory 
} from "react-router-dom";
import { getUser } from "../services";
import { useSelector } from "react-redux";
import * as Yup from "yup";

// Validation with Yup
const validaciones = Yup.object().shape({
  username: Yup.string()
    .required("usuario es requerido")
    .max(25, "máximo 25 caracteres"),
  firstName: Yup.string()
    .required("nombre es requerido")
    .max(50, "máximo 50 characters"),
  lastName: Yup.string().required("apellido es requerido").max(50, "máximo 50 caracteres"),
  email: Yup.string()
    .required("email es requerido")
    .max(100, "máximo 100 caracteres")
    .email("email invalido"),
  telefono: Yup.string().required("telefono es requerido").max(10, "máximo 10 caracteres"),
  habilitadoTurno: Yup.boolean().required("campo requerido"),
  usuarioInterno: Yup.boolean().required("campo requerido"),
  codigo_empleado:Yup.string().max(100, "máximo 100 caracteres")
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormEditar = () => {
  const { id } = useParams();
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoanding] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const token = useSelector((state) => state.loginReducer.data.token);
  const history = useHistory();

  useEffect(() => {
    const datos = async () => {
      await getUser(id,token).then((data) => {
        setUser(data);
        setLoanding(false);
      });
    };
    datos();
  }, [id,token]);

  return loading ? (
    <div></div>
  ) : (
    <Formik
      initialValues={{
        id: id,
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        telefono: user?.telefono || "",
        email: user?.email || "",
        username: user?.username || "",
        numeroColegiado: user?.numero_colegiado || "",
        sede: user?.sede?.id || "",
        tipoPuesto: user?.tipo_puesto || "",
        tipoProfesion: user?.tipo_profesional || "",
        estado: user?.estado || true,
        isStaff: user?.is_staff || false,
        activo: user?.activo || true,
        superUsuario: user?.is_superuser || false,
        habilitadoTurno: user?.habilitado_para_turno || true,
        usuarioInterno: user?.usuario_interno || false,
        sedes: user?.otra_sede || [],
        groups: user?.groups || [],
        user_permissions: user?.user_permissions || [],
        atenciones: user?.profesionalatenciones_detalle || [],
        servicios: user?.servicios || [],
        codigo_empleado: user?.codigo_empleado || "",
        rol_vista: user?.rol_vista || "",
        codigo_area : user?.codigo_area || ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await putUsuario(id,values, token)
        .then((data) => {
          if (data?.status === 200 || data?.status === 201) {
            setSuccess(true);
            setError(false);
            setMensaje("usuario actualizado éxitosamente");
            setTimeout(() => {
               history.push('/seguridad/usuarios');
            }, 2000);
          } 
        })
        .catch(function (error) {
          if (
            error.response.status === 400 ||
            error.response.status === 401
          ) {
            setError(true);
            setSuccess(false);
            setMensaje(error.response.data.message);
          } else if (error.response.status >= 500) {
            setError(true);
            setSuccess(false);
            setMensaje("Error interno comuniquese con el administrador");
          }
        });
      }}
    >
      {(props) => (
        <Registrar
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
          usuarioId={id}
          user={user}
        />
      )}
    </Formik>
  );
};
