import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const exportarData = async (filters, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/gestionpersona-list/?${filters}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
}

export const getOrientaciones = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacion/?page_size=1000&persona__id=${id}`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const deletePersona = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/gestionpersona/${id}/`;

        let result = axios({
            method: 'DELETE',
            url: urlFetch,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getUsuarios = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getMotivoVisitaOrientacion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/motivovisita/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postMotivoVisitaOrientacion = async (values, token) => {
    try {
        let body = {
            persona: values.persona,
            atendido: values.atendido,
            sede: values.sede,
            motivo_visita: Number(values.motivo_visita),
            profesional_al_que_visita: (Number(values.profesional_al_que_visita) === 0) ? null : Number(values.profesional_al_que_visita),
            denuncia: "",
            presento_denuncia: values.denuncia,
            observaciones: values.observaciones
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/registro/visitasedeorientacion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const getPersonas = async (urlPage, search,filters, token) => {
    try {
        let urlFetch = urlPage === undefined
            ? `${REACT_APP_API_URL}/registro/gestionpersona/?search=${search.value}&${filters}`
            : `${urlPage}&search=${search.value}&${filters}`;

        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then(data => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const personas = async (urlPage, search, filters, token) => {
    try {
        let urlFetch =
            urlPage === undefined
                ? `${REACT_APP_API_URL}/registro/gestionpersona/?${filters}`
                : `${urlPage}&search=${search.value}&${filters}`;
        let result = await fetch(urlFetch, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }).then((data) => data.json());

        return result;
    } catch (e) {
        console.log("error", e);
    }
};

export const getExpedientes = async (persona, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/registro/expediente/?estado=true&caso_persona__persona=${persona}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getOrientacionesValidacion = async (id, token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/registro/orientacion/?page_size=10&persona__id=${id}&estado=true`;
        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const putPersona = async (id, data, token) => {

    let body = {
        id: data.id,
        estado: data.estado,
        usuario_ultimo_cambio_estado: data.usuario_modificacion,
        sin_documento : data.sin_documento
    }

    try {

        let bodyResult = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestionpersona/${id}/`,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return bodyResult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCasosPersonas = async (idPersona,urlPage,token) => {
    try {
      let urlFetch = urlPage === undefined
      ? `${REACT_APP_API_URL}/registro/casopersona/?persona=${idPersona}`
      : `${urlPage}&persona=${idPersona}&caso__isnull=true&expediente__isnull=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };

  export const getSedes = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getProfesionales = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
  };
