/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { InputSiavi } from "../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { postExpMedidaCoercion, getMedidaCoercionHistorico } from "../services";
import Spinner from "../../../../../components/common/Spinner";
import Label from "../../../../../components/common/Label";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TableSiavi from "../../../../../components/common/TableSiavi";
import { clases } from "../../../../../helpers/clases";

const validaciones = Yup.object().shape({
  fecha: Yup.string().required("fecha es requerida"),
  medidaCoercion: Yup.string().required("medida coerción es requerida"),
  comentarios: Yup.string().max(300, "maximo 300 caracteres"),
});

const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
sm:text-sm  rounded-md bg-white text-maivi-primaryFont`;

export const MedidaCoercion = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [medidaCoercion, setMedidaCoercion] = useState([]);
  const [cargandoMedidaCoercion, setCargandoMedidaCoercion] = useState(false);

  useEffect(() => {
    setCargandoMedidaCoercion(true);
    getMedidaCoercionHistorico(props.idExpediente, token).then((data) => {
      setMedidaCoercion(data?.results);
      setCargandoMedidaCoercion(false);
    });
  }, [token, props.refetch]);

  return (
    <div>
      <Formik
        initialValues={{
          fecha: "",
          comentarios: "",
          medidaCoercion: "",
          textMedidaCoercion: ""
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.sindicadoConocido === false && values.textMedidaCoercion !== "LIBRE") {
            toast.error("Debe de conocer al sindicado para agregar una medida de coerción");
          } else {
            await postExpMedidaCoercion(values, props.idExpediente, props.token)
              .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                  props.setRefetch(true);
                  toast.success("Medida coerción agregada con éxito..!");
                  // props.cerrar();
                  props.setRefetch(false);
                } else {
                  props.setRefetch(true);
                  toast.success("Medida coerción agregada con éxito..!");
                  props.setRefetch(false);
                }
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
          }

        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 p-3 ">
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 border p-3 rounded-lg hover:shadow-md">
                <div className="grid grid-cols-12 gap-3 ">
                  <div className="col-span-12 py-3">
                    <Label text="*Medida coerción" />
                    <select
                      id="medidaCoercion"
                      name="medidaCoercion"
                      value={values.medidaCoercion}
                      onChange={(e) => {
                        setFieldValue("medidaCoercion", e.target.value);
                        let combo = document.getElementById("medidaCoercion");
                        let text = combo.options[combo.selectedIndex].text;
                        setFieldValue("textMedidaCoercion", text);
                      }}
                      className={selectClass}
                    >
                      <option value="">Medida coerción</option>
                      {props.medidasCoerciones?.results?.map((item) => {
                        return (
                          <option key={item.nombre + "mc"} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      name="medidaCoercion"
                      component="span"
                      className="font-normal text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-12">
                    <InputSiavi
                      type="date"
                      name="fecha"
                      value={values.fecha}
                      onChange={handleChange}
                      validacion={true}
                      label="*Fecha"
                      maximo={bloqueoFecha()}
                    />
                  </div>
                  <div className="col-span-12">
                    <Label text="Comentarios" />
                    <TextAreaSiavi
                      row={2}
                      cols={40}
                      name="comentarios"
                      value={values.comentarios}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="comentarios"
                      component="span"
                      className="font-medium text-xs text-maivi-secondaryRed"
                    />
                  </div>
                  <div className="col-span-12">
                    <ButtonSiavi
                      type="submit"
                      disabled={isSubmitting}
                      text="Guardar"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 border p-3 rounded-lg hover:shadow-md " >
                <div className="grid grid-cols-12 gap-3 ">
                  <div className="col-span-12 overflow-y-auto overflow-x-auto h-72 ">
                    <TableSiavi
                      cargando={cargandoMedidaCoercion}
                      headers={[
                        'Fecha',
                        'Medida Coerción'
                      ]}
                      body={
                        medidaCoercion.map((item, index) =>
                          <tr key={index} className="cursor-pointer hover:bg-gray-100">
                            <td className={clases.td}>{item?.fecha}</td>
                            <td className={clases.td}>{item?.medida_coercion?.nombre}</td>
                          </tr>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {isSubmitting && <Spinner />}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
