import { React, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postEtapaProcesal, putEtapaProcesal, getTipoProcesoEtapaProcesal } from "./services";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(255, "Máximo 100 caracteres"),
  descripcion: Yup.string()
    .required("Descripción es requerido")
    .max(255, "Máximo 100 caracteres"),
  tipo_proceso: Yup.string()
    .required("Tipo Proceso es requerido")
    .max(255, "Máximo 100 caracteres"),
});

const useFetchTipoProceso = (token) => {
  const [state, setState] = useState({
    tipo_proceso: [],
  });

  useEffect(() => {
    getTipoProcesoEtapaProcesal(token).then((tipo_proceso) => {
      setState({
        tipo_proceso: tipo_proceso,
      });
    });
  }, [token]);

  return state;
};

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { tipo_proceso } = useFetchTipoProceso(token);

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          descripcion: props.editar ? props.value.descripcion : "",
          tipo_proceso: props.editar ? props.value.tipo_proceso : "",
          estado: props.editar ? props.value.estado : true,
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putEtapaProcesal(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro actualizado con éxito...!')
              }
            });
          } else {
            await postEtapaProcesal(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro creado con éxito...!')
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-6">
              <div className="col-span-6">
                <InputSiavi
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre*"
                />
              </div>
              <div className="col-span-6 mt-4">
                <InputSiavi
                  type="text"
                  name="descripcion"
                  id="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  validacion={true}
                  label="Descripción*"
                />
                <div className="col-span-6 mt-4">
                  <Label text="Tipo Proceso*" />
                  <div className="mt-1">
                    <select
                      id="tipo_proceso"
                      name="tipo_proceso"
                      autoComplete="off"
                      value={values.tipo_proceso}
                      onChange={handleChange}
                      className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue
                       focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                    >
                      <option value="" disabled>Elegir tipo proceso</option>
                      {tipo_proceso?.results?.filter(x => x.estado === true).map((item) => {
                        return (
                          <option key={item.tipo_proceso} value={item.id}>
                            {item.tipo_proceso}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage
                      name="tipo_proceso"
                      component="span"
                      className="text-sm text-maivi-secondaryRed"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 mt-5">
                <Label text={`${values.estado? 'Activo':'Inactivo'}`} />
                <Toggle enabled={values.estado} name="estado" />
              </div>
              <div className="py-3 text-right">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
