/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { FormOrientacion } from "./orientacion/form";
import { FormVictimas } from "./victimas/form";
import { FormVictimario } from "./sindicados/form";
import { FormRiesgos } from "./riesgos/form";
import { Procesos } from "./procesos/procesos_delitos";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Echos } from "./echos/echos";
import { FormQuerellantes } from "./querellantes/form";
import { FormEvaluacion } from "./evaluacion/form";
import { store } from "../../../../app/redux/store/store";
import { ChangeOrientacionTab } from "../../../../app/redux/actions/orientacionTab";
import { PrinterIcon } from "@heroicons/react/solid";
import {
  getOrientacion,
  getPersonasCasoDetails,
  getExpedienteSindicados,
  getExpedienteSindicadoDelitos,
  getCasoEvaluacionRiesgo,
  getCasoHechos,
  getCasoHechosTemporalidad,
  postValidarFicha,
  getDiligenciasExpediente
} from "./services";
import Modal from "../../../../components/common/ModalAmplio";
import Spinner from "../../../../components/common/Spinner";
import Return from "../../../../components/common/ButtonSalir";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import PdfViewer from "../../../../components/common/PdfViewer";
import toast from "react-hot-toast";
import moment from "moment";
import { getCausasPenales } from "../../casos/editar/generales/services";

export const Editar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user.username
  );
  const OrientacionTab = useSelector((state) => state.ChangeOrientacionTab);
  const query = new URLSearchParams(window.location.search);
  const casoAdmitido = query.get("admitido");
  const [orientacion, setOrientacion] = useState({});
  const [ficha, setFicha] = useState(false);
  const [fichaData, setFichaData] = useState({});
  const [cargando, setCargando] = useState(false);
  const { id } = useParams();
  const [loading, setLoanding] = useState(true);
  const pages = [
    {
      name: "Orientación Legal-Victimológica",
      href: "/funcionalidades-generales/listar-orientacion",
      current: false,
    },
    { name: "Editar", href: "#", current: true },
  ];

  useEffect(() => {
    let expedienteAsigando = false;
    getOrientacion(token, id).then((data) => {
      setOrientacion(data);
      setLoanding(false);

      data?.caso?.expedientes?.forEach(element => {
        if (element?.profesional_asignado !== null) {
          expedienteAsigando = true;
        }
      });

      if (expedienteAsigando) {
        toast.error("Esta orientación no puede editarse porque uno o mas de sus expedientes ya fueron asignados",)
      }

      const defaultTab = OrientacionTab.tabs;
      if (casoAdmitido === "null" || casoAdmitido === "false" || expedienteAsigando) {
        defaultTab.forEach((element) => {
          if (element.index !== 0) {
            element.block = true;
          }
        });
      } else {
        defaultTab.forEach((element) => {
          if (element.index !== 0) {
            element.block = false;
          }
        });
      }
      store.dispatch(ChangeOrientacionTab(defaultTab));
    });
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getIndexTab = () => {
    let pocision = 0;

    OrientacionTab.tabs.forEach((element, index) => {
      if (element.active) {
        pocision = index;
      }
    });

    return pocision;
  };

  const changeTab = (indexTab) => {
    const defaultTab = OrientacionTab.tabs;

    defaultTab.forEach((element, index) => {
      if (index === indexTab) {
        element.active = true;
      } else {
        element.active = false;
      }
    });

    store.dispatch(ChangeOrientacionTab(defaultTab));
  };

  const printFicha = async () => {

    if (casoAdmitido === false || casoAdmitido === 'false') {
      setCargando(true);
      return await getPersonasCasoDetails([orientacion.persona], token).then(async (data) => {
        setFicha(true);
        let persona_detalle = data.personas[0];
        let orientacionSend = { ...orientacion, persona_detalle: persona_detalle};
        setFichaData({
          reporte: 'ficha-tecnica-no-admisible',
          orientacion: orientacionSend,
          usuario: usuarioLogin,
          codigoIso: "DAL-DSV-FOR-4"
        });

        setCargando(false);
      });
    }
    let personas = [];

    orientacion.caso.personas.forEach((p) => {
      personas.push(p.persona.id);
    });

    await postValidarFicha(orientacion.id, token).then(async (data) => {
      if (data?.status >= 200 && data?.status <= 210) {
        setCargando(true);
        setFicha(true);
        await getPersonasCasoDetails(personas, token).then(async (data) => {
          await getExpedienteSindicados(orientacion.caso.id, token).then(
            async (sindicadosList) => {
              await getExpedienteSindicadoDelitos(orientacion.caso.id, token).then(
                async (delitosSindicados) => {
                  await getCasoEvaluacionRiesgo(orientacion.caso.id, token).then(
                    async (evaluacionRiesgos) => {
                      await getCasoHechos(orientacion.caso.id, token).then(
                        async (casoHechos) => {
                          await getCasoHechosTemporalidad(
                            orientacion.caso.id,
                            token
                          ).then(async(casoHechosTemp) => {

                            let expedientes = orientacion.caso.expedientes;
                            let diligenciasUrgentes = [];

                            await expedientes.forEach(async (exp, index) => {
                              await getDiligenciasExpediente(exp.id, token).then(async diligencias => {

                                diligencias.results.forEach(dil => {
                                  diligenciasUrgentes.push(dil);
                                })
                                await getCausasPenales(orientacion.caso.id, token).then( causaPenal => {
                                  if (index === (expedientes.length - 1)) {
                                    setFichaData({
                                      reporte: 'ficha-tecnica',
                                      orientacion: orientacion,
                                      causaPenal: causaPenal.results,
                                      personas: data.personas,
                                      sindicados: sindicadosList?.results,
                                      delitos: delitosSindicados?.results,
                                      riesgos: evaluacionRiesgos?.results,
                                      hechos: casoHechos?.results,
                                      hechosTemp: casoHechosTemp?.results,
                                      usuario: usuarioLogin,
                                      diligencias: diligenciasUrgentes,
                                      codigoIso: "DAL-DSV-FOR-3"
                                    });
                                    setCargando(false);

                                  }
                                })
                              });
                            });
                          });
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        });
      }
    }).catch(function (error) {
      if (
        error.response.status >= 400 &&
        error.response.status <= 450
      ) {
        toast.error(error.response.data.message);
      } else if (error.response.status >= 500) {
        toast.error("Error interno comuniquese con el administrador");
      }
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="mx-2 xl:mx-5 lg:mx-5 md:mx-2 -mt-32 mb-10">
      <div className="border sm:rounded-md px-2 xl:px-6 lg:px-4  py-6 bg-maivi-secondaryWhite">
        <div
          className="flex flex-col justify-center items-center md:justify-between lg:justify-between
       lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-2"
        >
          <BreadCrumbTwo navegacion={pages} />
          <div className="mt-5 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  flex flex-row justify-center items-center">
            <h1 className="mr-5 text-sm text-maivi-primaryFont">
              {orientacion?.caso?.numero_caso}{" "}
              {orientacion?.caso?.victima?.primer_nombre}{" "}
              {orientacion?.caso?.victima?.segundo_nombre}{" "}
              {orientacion?.caso?.victima?.primer_apellido}{" "}
              {orientacion?.caso?.victima?.segundo_apellido}
            </h1>
            <PrinterIcon
              className="h-6 w-6 text-maivi-primaryFont cursor-pointer"
              onClick={() => {
                printFicha();
              }}
            />
            <Return ruta="/funcionalidades-generales/listar-orientacion" />
          </div>
        </div>
        <Tab.Group defaultIndex={getIndexTab()} onChange={changeTab}>
          <Tab.List className="grid grid-cols-3 xl:grid-cols-10 lg:grid-cols-5 md:grid-cols-4 bg-blue-50 rounded-md p-1">
            {OrientacionTab.tabs.map((item) => {
              return (
                <Tab
                  key={item.name}
                  disabled={item.block}
                  className={({ selected }) =>
                    classNames(
                      "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                      "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                      selected
                        ? "bg-white shadow"
                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                    )
                  }
                >
                  {item.name}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <FormOrientacion orientacion={orientacion} />
            </Tab.Panel>
            <Tab.Panel>
              <FormVictimas
                caso={orientacion.caso}
                delitos={orientacion.delitos_detalle}
              />
            </Tab.Panel>
            <Tab.Panel>
              <FormQuerellantes
                caso={orientacion.caso}
                delitos={orientacion.delitos_detalle}
              />
            </Tab.Panel>
            <Tab.Panel>
              <FormVictimario caso={orientacion.caso} />
            </Tab.Panel>
            <Tab.Panel>
              <Procesos
                caso={orientacion.caso}
                ocultar_secciones={false}
                orientacion={orientacion}
                es_caso={false}
              />
            </Tab.Panel>
            <Tab.Panel>
              <Echos caso={orientacion.caso} />
            </Tab.Panel>
            {/* <Tab.Panel>
              <FormEstadoProceso caso={orientacion.caso} />
            </Tab.Panel> */}
            <Tab.Panel>
              <FormRiesgos caso={orientacion.caso} />
            </Tab.Panel>
            <Tab.Panel>
              <FormEvaluacion caso={orientacion.caso} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className="flex flex-row justify-start items-stretch">
          <h1 className="mt-2 text-xs text-maivi-primaryFont">
            Creado por: {orientacion?.caso?.usuario_creacion?.first_name}{" "}
            {orientacion?.caso?.usuario_creacion?.last_name}{" "}
            {moment(orientacion?.caso?.fecha).format("DD-MM-YYYY")}
          </h1>
        </div>
        {ficha && (
          <Modal
            title={`Ficha técnica`}
            open={ficha}
            closeModal={() => { }}
            cerrar={() => {
              setFicha(false);
            }}
            children={
              cargando ?
                <Spinner /> : <PdfViewer
                  data={fichaData}
                  reporte={fichaData.reporte}
                  style={{ height: "80vh" }}
                />
            }
          />
        )}
      </div>
    </div>
  );
};
