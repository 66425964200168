/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from "@headlessui/react";
import { getExpedientePersona, getAccionesPersona } from "../../../pages/services";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/Spinner";
import CustomTable from "../../../components/common/Table";
import TableGeneric from "../../../components/common/TableGeneric";
import moment from "moment";
import ModalGenerico from "../../../components/common/Modal";
import { ClipboardCheckIcon } from "@heroicons/react/outline";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';
import { putVisita, getConfiguracion } from "./services";
import { getCasosPersonas } from "../registrarPersona/listar/services";

const tableColumns = [
    { title: "Tipo", value: "accion_seguimiento.nombre", tipo: "text" },
    { title: "Descripcion", value: "accion_seguimiento.descripcion", tipo: "text" },
    { title: "Fecha creacion", value: "fecha_creacion", tipo: "date" },
    { title: "Profesional asignado", value: null, values: ["profesional_asignado.first_name", "profesional_asignado.last_name"], tipo: "text" },
];

const tableColumnsExpedientes = [
    { title: "Expediente", value: "numero_expediente", tipo: "text" },
    { title: "Servicio", value: "servicio.nombre", tipo: "text" },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
    { title: "Fecha creacion", value: "fecha_creacion", tipo: "date" },
    { title: "Profesional asignado", value: null, values: ["profesional_asignado.first_name", "profesional_asignado.last_name"], tipo: "text" },
];

const tableColumnsCasosPersonas = [
    { title: "Expediente", value: "caso.numero_caso", tipo: "text" },
    {
        title: "Querellante",
        value: "querellante",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
    {
        title: "Rep. Legal",
        value: "representante_legal",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
    {
        title: "Víctima",
        value: "victima",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
    {
        title: "Víctima indirecta",
        value: "es_victima_indirecta",
        tipo: "boolean",
        text: { true: "Si", false: "No" },
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const config = 1;

const useFetchConfiguracion = (token) => {
    const [state, setState] = useState({
        configuraciones: [],
    });

    useEffect(() => {
        getConfiguracion(config, token).then((configuraciones) => {
            setState({
                configuraciones: configuraciones,
            });
        });
    }, [token]);

    return state;
};

export const SeguimientoCaso = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { configuraciones } = useFetchConfiguracion(token);
    const [expedientes, setExpedientes] = useState({});
    const [acciones, setAcciones] = useState({});
    const [urlFetch, setUrlFech] = useState(undefined);
    const [urlFetchExpedientes, setUrlFechExpedientes] = useState(undefined);
    const [cargando, setCargando] = useState(false);
    const [cargandoAcciones, setCargandoAcciones] = useState(false);
    let fecha_visita = new Date(props?.detalle?.fecha);
    let fecha_hoy = new Date();
    let dias_atiende_visita = configuraciones?.dias_atiende_visita;
    let fecha_comparar = fecha_hoy.getDate() - dias_atiende_visita;
    let comparacionFechas = fecha_visita.getDate() >= fecha_comparar;
    const [isloading, setLoading] = useState(false);
    const [isNotAtendido, setNotAtendido] = useState(false);
    const [caso, setCaso] = useState("");
    const [casosPersonas, setCasosPersonas] = useState({});
    const [cargandoCasosPersonas, setCargandoCasosPersonas] = useState(false);
    const [urlFetchCasosPersona, setUrlFechCasosPersona] = useState(undefined);

    useEffect(() => {
        setCargando(true);
        getExpedientePersona(props?.Persona?.id, urlFetchExpedientes, token).then((data) => {
            setExpedientes(data);
            setCargando(false);
        });
    }, [token, urlFetchExpedientes]);

    useEffect(() => {
        setCargandoAcciones(true);
        getAccionesPersona(props?.Persona?.id, urlFetch, token).then((data) => {
            setAcciones(data);
            setCargandoAcciones(false);
        });
    }, [urlFetch, token]);

    useEffect(() => {
        setCargandoCasosPersonas(true);
        getCasosPersonas(props?.Persona?.id, urlFetchCasosPersona, token).then((data) => {
            setCasosPersonas(data);
            setCargandoCasosPersonas(false);
        });
    }, [urlFetchCasosPersona, token]);

    const handleVer = (registro) => {
        if (props.detalle.atendido === false || props.detalle.atendido === null && comparacionFechas === true) {
            setCaso(registro.caso);
            setNotAtendido(true);
        } else {
            window.location = `/funcionalidades-generales/editar-caso/${registro.caso}`
        }
    }

    const handleVerExpPersona = (registro) => {
        if (props.detalle.atendido === false || props.detalle.atendido === null && comparacionFechas === true) {
            setCaso(registro.caso.id);
            setNotAtendido(true);
        } else {
            window.location = `/funcionalidades-generales/editar-caso/${registro.caso.id}`
        }
    }

    return (
        <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-6 ">
                <div className="bg-white border rounded-md hover:shadow-lg">
                    <div className=" border-gray-200 p-0">
                        <div className="sm:divide-y sm:divide-gray-200 ">
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Nombre
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {`${props.detalle.persona.primer_nombre} ${props.detalle.persona.segundo_nombre} ${props.detalle.persona.primer_apellido} ${props.detalle.persona.segundo_apellido}`}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Sexo
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.persona.sexo}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">Fecha nacimiento</div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {moment(props.detalle.persona.fecha_nacimiento).format("DD-MM-YYYY")}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Edad
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.persona.edad}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Como le gusta que le llamen
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.persona.como_le_gusta_que_le_llamen}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">Tipo documento</div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.persona?.tipo_documento_detalle?.nombre}

                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    No. documento
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.persona.no_documento}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Pais
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.persona?.pais_detalle?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Nacionalidad
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props?.detalle?.persona?.nacionalidad_detalle?.nombre}
                                </div>
                            </div>
                            <div className="py-2 grid grid-cols-3 gap-2 px-3 hover:bg-gray-100">
                                <div className="text-xs font-medium text-gray-500">
                                    Primera visita
                                </div>
                                <div className="text-xs text-gray-900 sm:col-span-2">
                                    {props.detalle.persona.primera_visita === true ? (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            Sí
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                            No
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6 p-3 border rounded-lg hover:shadow-lg">
                <Tab.Group>
                    <Tab.List className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 bg-blue-50 rounded-md p-1">
                        <Tab
                            key="casos"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Expedientes
                        </Tab>
                        <Tab
                            key="acciones"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Acciones
                        </Tab>
                        <Tab
                            key="caso-personas"
                            disabled={false}
                            className={({ selected }) =>
                                classNames(
                                    "p-2 text-xs font-medium text-maivi-primaryFont rounded-md",
                                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                    selected
                                        ? "bg-white shadow"
                                        : "text-maivi-primaryFont hover:bg-white/[0.12] hover:text-maivi-secondaryLightBlue"
                                )
                            }
                        >
                            Expedientes persona
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="mt-2">
                                <TableGeneric
                                    dataTable={expedientes}
                                    tableColumns={tableColumnsExpedientes}
                                    fetchPage={setUrlFechExpedientes}
                                    pagination={true}
                                    permiso={"expediente"}
                                    showEliminar={false}
                                    showEditar={false}
                                    showVer={true}
                                    handleVer={handleVer}
                                    hideAcciones={false}
                                />
                                <div className="mt-2">
                                    {
                                        cargando &&
                                        <Spinner />
                                    }
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="mt-2">
                                <CustomTable
                                    dataTable={acciones}
                                    tableColumns={tableColumns}
                                    fetchPage={setUrlFech}
                                    pagination={true}
                                    permiso={"expediente"}
                                    showEliminar={false}
                                    showEditar={false}
                                    showVer={false}
                                    hideAcciones={true}
                                />
                                <div className="mt-2">
                                    {
                                        cargandoAcciones &&
                                        <Spinner />
                                    }
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="mt-2">
                                <CustomTable
                                    dataTable={casosPersonas}
                                    tableColumns={tableColumnsCasosPersonas}
                                    fetchPage={setUrlFechCasosPersona}
                                    pagination={true}
                                    permiso={"expediente"}
                                    showEliminar={false}
                                    showEditar={false}
                                    showVer={true}
                                    handleVer={handleVerExpPersona}
                                    hideAcciones={false}
                                />
                                <div className="mt-2">
                                    {
                                        cargandoCasosPersonas &&
                                        <Spinner />
                                    }
                                </div>
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            {
                isNotAtendido && (
                    <ModalGenerico
                        title={`¿Desea marcar como atendida esta visita?`}
                        open={isNotAtendido}
                        closeModal={() => { }}
                        cerrar={() => { setNotAtendido(false) }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <ClipboardCheckIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={async () => {
                                    setLoading(true);
                                    console.log('-->>UPDATE!!');
                                    await putVisita(props?.detalle?.id, props?.detalle, token)
                                        .then((data) => {
                                            if (data?.status === 200 || data?.status === 201) {
                                                toast.success('Registro actualizado con éxito...!');
                                                setLoading(false);
                                                setNotAtendido(false);
                                                window.location = `/funcionalidades-generales/editar-caso/${caso}`
                                            } else {
                                                setLoading(false);
                                                setNotAtendido(false);
                                                window.location = `/funcionalidades-generales/editar-caso/${caso}`
                                                toast.success('Registro actualizado con éxito...!')
                                            }
                                        })
                                        .catch(function (error) {
                                            if (error.response.status === 400 || error.response.status === 401) {
                                                setNotAtendido(false);
                                                setLoading(false);
                                                toast.error(error.response.data.message);
                                            } else if (error.response.status >= 500) {
                                                toast.error('Error interno comuniquese con el administrador')
                                            }
                                        });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setNotAtendido(false);
                                    window.location = `/funcionalidades-generales/editar-caso/${caso}`
                                }}
                            />
                        }
                    />
                )
            }
        </div>
    );
}
