/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { XCircleIcon, UserIcon } from "@heroicons/react/outline";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ButtonAdd from "../../../components/common/ButtonAdd";
import { FormDetalle } from "./Detalle";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/common/Table";
import FormEliminar from "../common/FormEliminar";
import { deleteSeguridad, getGenericSeguridad, getGenericSeguridadSearch} from "../../../helpers/catalogos";
import toast from 'react-hot-toast';

const tableColumns = [
  { title: "Usuario", value: "username", tipo: "text", ordenar: "username" },
  { title: "Nombre", value: "first_name", tipo: "text", ordenar: "first_name" },
  { title: "Apellidos", value: "last_name", tipo: "text", ordenar: "last_name" },
  {
    title: "Activo",
    value: "is_active",
    tipo: "boolean",
    text: { true: "Sí", false: "No" }
  },
  {
    title: "Desbloqueado",
    value: "estado",
    tipo: "boolean",
    text: { true: "Activo", false: "Inactivo" },
    ordenar: "estado"
  },
  {
    title: "Personal Contratado",
    value: "is_staff",
    tipo: "boolean",
    text: { true: "Sí", false: "No" }
  },
  { title: "Sede Principal", value: "sede.sede", tipo: "text" },
];

export const TableUsuarios = () => {
  const [isEliminar, setEliminar] = useState(false);
  const [idUsuario, setIdUsuario] = useState("");
  const [isloading, setLoading] = useState(false);
  const [verDetalle, setDetalle] = useState(false);
  const [userDetalle, setUserDetalle] = useState({});
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const token = useSelector((state) => state.loginReducer.data.token);
  const [order, setOrder] = useState("");
  const search = useSelector((state) => state.inputChange.search);
  const [reFetch, setReFetch] = useState(false);

  const permisos = useSelector(
    (state) => state.loginReducer.data.usuario.user.permissions
  );
  const permiso = "user";

  let permisoAgregar = false;
  let addPermiso = `add_${permiso}`;

  permisos.forEach((permiso) => {
    if (addPermiso === permiso.codename) {
      permisoAgregar = true;
    }
  });

  let recurso = "registrar";

  useEffect(() => {
    getGenericSeguridad(recurso, search, token, urlFetch, setTableLoading, setDataTable, order);
    setReFetch(false);
    setEliminar(false);
    setLoading(false);
  }, [urlFetch, token, reFetch]);
  useEffect(() => {
    getGenericSeguridad(recurso, search, token, urlFetch, setTableLoading, setDataTable, order);
  }, [order]);
  useEffect(()=>{
    getGenericSeguridadSearch(recurso, search, token, setUrlFech, setTableLoading, setDataTable, order);
  }, [search]);

  const handleVer = (item) => {
    setUserDetalle(item);
    setDetalle(true);
  };

  const handleEliminar = (id) => {
    setIdUsuario(id);
    setEliminar(true);
  };

  const linkEditar = `/seguridad/usuarios/editar/`;

  const pages = [
    { name: "Seguridad", href: "/seguridad", current: false },
    { name: "Usuarios", href: "#", current: true },
  ];
  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        {permisoAgregar && (
          <Link to="/seguridad/usuarios/registrar" type="button">
            <ButtonAdd text="Crear" type="button" />
          </Link>
        )}
      </div>
        <div className="py-2 inline-block w-full">
          <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
            <CustomTable
              dataTable={dataTable}
              tableColumns={tableColumns}
              handleVer={handleVer}
              handleEliminar={handleEliminar}
              fetchPage={setUrlFech}
              pagination={true}
              permiso={permiso}
              link={true}
              linkTo={linkEditar}
              setOrder={setOrder}
              order={order}
            />
            {tableLoading && (
              <div className="mt-10 mb-10">
                <Spinner />
              </div>
            )}
            {verDetalle && (
              <ModalGenerico
                title={"Detalle de usuario"}
                open={verDetalle}
                closeModal={() => {}}
                cerrar={() => {
                  setDetalle(false);
                }}
                icon={
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                    <UserIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                  </div>
                }
                children={
                  <FormDetalle detalle={userDetalle} cancelar={setEliminar} />
                }
              />
            )}
            {isEliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro?`}
              open={isEliminar}
              closeModal={() => { }}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    setLoading(true);
                    deleteSeguridad(idUsuario, recurso, token).then((data) => {
                      if (data?.message !== undefined) {
                        setLoading(false); 
                        toast.error(data.message)
                      }else{
                        setReFetch(true);
                        setLoading(false);
                        toast.success('Registro eliminado con éxito...!')
                      }
                    });
                  }}
                  isloading={isloading}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
