
const {
    REACT_APP_API_URL
} = process.env;

export const getInstituciones = async (urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/catalogos/institucion/` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postInstitucion = async ({ nombre, estado, tiene_materia, denuncia_valida, tipo_institucion, categoria_institucion }, token) => {
    try {
        let body = {
            nombre,
            estado,
            tiene_materia,
            denuncia_valida,
            tipo_institucion,
            categoria_institucion
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putInstitucion = async (id, { nombre, estado, tiene_materia, denuncia_valida, tipo_institucion, categoria_institucion }, token) => {
    try {
        let body = {
            nombre,
            estado,
            tiene_materia,
            denuncia_valida,
            tipo_institucion,
            categoria_institucion
        }

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteInstitucion = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/institucion/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getTipoInstitucion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/tipoinstitucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getCategoriaInstitucion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/categoriainstitucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionInstitucion = async (token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getGestionInstitucionPorFiltro = async (id, token) => {
    try {
        let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&institucion=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};