/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../common/InputText";
import { useSelector } from "react-redux";
import { EyeIcon, XCircleIcon } from "@heroicons/react/outline";
import { SiaviFile } from "../common/SiaviFile";
import { getClaseDocumentos, getArchivos, deleteArchivo } from "./services";
import { clases } from "../../helpers/clases";
import { DownloadIcon, TrashIcon } from "@heroicons/react/solid";
import { SelectSiavi } from "../common/Select";
import toast from "react-hot-toast";
import FormEliminar from "../common/FormEliminar";
import Alert from "../common/Alert";
import ModalGenerico from "../common/Modal";
import ModalAmplio from "../common/ModalTwo";
import Spinner from "../common/Spinner";
import PdfViewer from "../common/PdfViewer";
import CustomTable from "../common/Table";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string().required("nombre es requerido"),
});

const tableColumns = [
  { title: "Tipo archivo", value: "clase_documento.nombre", tipo: "text" },
  { title: "Nombre", value: "nombre_documento", tipo: "text" },
  { title: "Fecha", value: "fecha_creacion", tipo: "date" },
];

export const GestionArchivos = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idRecurso = (props?.sin_filtro) ? "" : props.idRecurso;
  const recurso = (props?.sin_filtro) ? "" : props.recurso;
  const filtros = props?.filtros || "";
  const expediente = props.expediente || null;
  const caso = props.caso || null;
  const [isloading, setLoading] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [tipos_archivos, setTiposArchivos] = useState([]);
  const [clase, setClase] = useState(0);
  const [extensiones, setExtensiones] = useState("");
  const [contador, setContador] = useState(0);
  const [multiplesArchivos, setMultiplesArchivos] = useState(true);
  const [habilitar, setHabilitar] = useState(false);
  const [refetch, setReFetch] = useState(false);
  const [previewPDF, setPreviewPDF] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [urlFetch, setUrlFech] = useState(undefined);

  useEffect(() => {
    getClaseDocumentos(props?.recurso, token).then((data) => {
      setTiposArchivos(data?.results);
    });
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getArchivos(idRecurso, recurso, urlFetch,filtros, token).then((data) => {
      setArchivos(data?.results);
      setDataTable(data);
      setTableLoading(false);
    });
  }, [refetch,urlFetch]);

  const opciones = (item) => {
    return <span>
      <button
        onClick={() => {
          let fileName = `${item.nombre_documento}-${item.clase_documento.recurso}-${item.recurso_id}`;
          setPreviewPDF({
            url: item.path,
            name: fileName
          });
        }}
        className={clases.addIcon2}
      >
        <EyeIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
      </button>
      <button
        onClick={() => {
          window.open(item?.path);
        }}
        className={clases.addIcon2}
      >
        <DownloadIcon className="h-5 w-5 text-maivi-primaryFont hover:text-white" />
      </button>
      <button
        type="button"
        onClick={() => {
          deleteArchivo(item.id, token).then(() => {
            toast.success("Archivo eliminado con exito");
            setReFetch(true);
            setReFetch(false);
            //resetForm({});
          });
        }}
        className={clases.trashIcon}
      >
        <TrashIcon className="h-5 w-5 text-maivi-secondaryRed hover:text-white" />
      </button>
    </span>
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          nombre: "",
          tipo_archivo: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => { }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {
              (props?.ocultar_formulario) ? "" :
              <div className="grid grid-cols-12 gap-3 items-end">
              <div className="col-span-12 xl:col-span-3 lg:col-span-3 sm:col-span-6 md:col-span-4 mt-2">
                <div className="mt-1">
                  <SelectSiavi
                    label="Tipo archivo*"
                    name="tipo_archivo"
                    value={values.tipo_archivo}
                    valueInit=""
                    selectInit="Elegir tipo archivo"
                    onChange={(e) => {
                      setFieldValue("tipo_archivo", e.target.value);
                      setFieldValue("nombre", "");
                      tipos_archivos.forEach((element) => {
                        if (element.id === Number(e.target.value)) {
                          setExtensiones(element.extension_permitida);
                          setClase(element.id);
                          setMultiplesArchivos(element.multiples_archivos);
                        }
                      });
                      archivos.forEach((element) => {
                        if (element.clase_documento.id === Number(e.target.value)) {
                          setContador(1);
                        }
                      });
                    }}
                    list={
                      tipos_archivos?.map((item, index) => {
                        return (
                          <option key={item.nombre + index} value={item.id}>
                            {item.nombre}
                          </option>
                        );
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-3 lg:col-span-3 sm:col-span-6 md:col-span-4 mt-2">
                <InputSiavi
                  name="nombre"
                  value={values.nombre}
                  validacion={true}
                  label="Nombre de archivo"
                  onChange={(e) => {
                    setFieldValue('nombre', e.target.value);
                    if (contador > 0 && multiplesArchivos === false) {
                      setHabilitar(false);
                    } else {
                      setHabilitar(true);
                    }
                  }}
                />
              </div>
              {values.tipo_archivo !== "" ? (
                <div
                  className="col-span-12 xl:col-span-6 lg:col-span-6 mt-2"
                  onClick={() => {
                    if (contador > 0 && multiplesArchivos === false) {
                      setHabilitar(false);
                      setError(true);
                      setMensaje(
                        "No se permite subir mas de un archivo de este tipo"
                      );
                      setTimeout(() => {
                        setError(false);
                      }, 4000);
                    } else {
                      if (values.nombre.length < 3) {
                        setHabilitar(false);
                        setError(true);
                        setMensaje("debe escribir un nombre al archivo");
                        setTimeout(() => {
                          setError(false);
                        }, 4000);
                      } else {
                        setHabilitar(true);
                      }
                    }
                  }}
                >
                  <fieldset disabled={habilitar ? false : true}>
                    <SiaviFile
                      nombre_documento={values.nombre}
                      clase_documento={clase}
                      recurso_id={props.idRecurso}
                      expediente={expediente}
                      caso={caso}
                      extension_permitida={extensiones}
                      refetch={setReFetch}
                      reset={resetForm}
                    />
                  </fieldset>
                </div>
              ) : (
                ""
              )}
              </div>
            }
         
            {isSubmitting && <Spinner />}
            <div className="mb-4 mt-2">
              {error && <Alert type="error" mensaje={mensaje} />}
            </div>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto -mt-2">
              <CustomTable
                dataTable={dataTable}
                tableColumns={tableColumns}
                permiso="orientacion"
                fetchPage={setUrlFech}
                pagination={true}
                agregar={false}
                showEliminar={false}
                showEditar={false}
                showVer={false}
                addAccion={true}
                opciones={opciones}
              />
               {tableLoading && (
                <div className="mt-10 mb-10">
                  <Spinner />
                </div>
              )}
              {previewPDF && (
                <ModalAmplio
                  title={`Visualizando Documento`}
                  open={previewPDF !== false}
                  closeModal={() => { }}
                  cerrar={() => {
                    setPreviewPDF(false);
                  }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                      <EyeIcon className="h-6 w-6 text-maivi" />
                    </div>
                  }
                  children={<PdfViewer reporte="render-pdf" data={previewPDF} style={{ height: "80vh" }} />}
                />
              )}
              {isEliminar && (
                <ModalGenerico
                  title={`¿Esta seguro de eliminar el registro?`}
                  open={isEliminar}
                  closeModal={() => { }}
                  cerrar={() => {
                    setEliminar(false);
                  }}
                  icon={
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                    </div>
                  }
                  children={
                    <FormEliminar
                      aceptar={() => {
                        setLoading(true);
                      }}
                      isloading={isloading}
                      cancelar={() => {
                        setEliminar(false);
                      }}
                    />
                  }
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
