/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getReferencias,getProfesionales,convertirReferencias,referencias } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { DocumentAddIcon, FilterIcon, ViewGridAddIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import Filtros from "./filtros";
import toast from "react-hot-toast";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";

const tableColumns = [
  { title: "Fecha Crea", value: "fecha_creacion_referencia_ts", tipo: "date", ordenar:"fecha_creacion"},
  { title: "Sede", value: "caso.sede_creacion.sede", tipo: "text"},

  { title: "Caso", value: "caso.numero_caso", tipo: "text"},
  { title: "No. Referencia", value: "codigo_referencia_ts", tipo: "text"},
  
  { 
    title: "Nombre Víctima", 
    value: null, 
    values:[
      "persona.primer_nombre", 
      "persona.segundo_nombre", 
      "persona.primer_apellido", 
      "persona.segundo_apellido"
    ], 
    tipo: "text" 
  },
  { 
    title: "Profesional", 
    value: null, 
    values:[
      "profesional_crea_referencia_ts.first_name", 
      "profesional_crea_referencia_ts.last_name", 
    ], 
    tipo: "text",
    ordenar:"profesional_crea_referencia_ts"
  },
];

const useFetchProfesionales = (token) => {
  const [state, setState] = useState({
    profesionales: [],
  });

  useEffect(() => {
    getProfesionales(token).then((data) => {
      setState({
        profesionales: data,
      });
    });
  }, [token]);

  return state;
};

export const TableReferenciasTrabajoSocial = () => {
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [registros,setRegistros] = useState([]);
  const [cargando,setCargando] = useState(false);
  const [servicio,setServicio]= useState("");
  const [ order, setOrder] = useState("-fecha_creacion")
  const permiso = "referencias";

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Referenciasatrabajosocial";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;

  }

  const fetchReferencias = () => {
    setTableLoading(true);
    referencias(urlFetch, filters,order, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetReferencias = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getReferencias(urlFetch, search, sede,filters,order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  useEffect(fetchReferencias, [urlFetch, filters,order, sede]);
  useEffect(fetchGetReferencias, [urlFetch, search, filters, sede,order]);
  useEffect( ()=>{
    setRegistros([]);
  },[urlFetch]);
  
  const { profesionales } = useFetchProfesionales(token);
  const optionsProfesionales = [];

  const registrosSeleccionados = (items) => {
    setRegistros(items);
  };

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - Asignaciones: ${item?.asignaciones}`,
    };

    if(servicio === ""){
      optionsProfesionales.push(reg);
    } else {
      item.servicios.forEach(element => {
        if(element?.servicio_detalle?.nombre === servicio){
          optionsProfesionales.push(reg);
        }
    });
   }
  });

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Referencias a trabajo social", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
       <div>
       {(CheckPermiso('convertirreferencias') === true) &&    //mm permiso agregado      
       <ButtonAdd
          className="ml-2"
          text="Convertir"
          type="button"
          onClick={() => {
            if(registros.length === 0){
              toast.error('Debe de seleccionar al menos una referencia.')
            } 
            else{
              setAgregar(true);
            }
          }}
          icon={<ViewGridAddIcon className="ml-3 -mr-1 h-5" />}
        />
}
         <ButtonAdd
          className="ml-2"
          text="Filtros"
          type="button"
          onClick={() => {
            setOpenFilters(true);
          }}
          icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
        />
       </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={false}
            select={true}
            registrosSeleccionados={registrosSeleccionados}
            order={order}
            setOrder={setOrder}
            show
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {agregar && (
        <ModalGenerico
          title={"Convertir a referencias"}
          open={agregar}
          closeModal={() => { }}
          cerrar={() => {
            setAgregar(false);
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentAddIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <div className="">
              <BotonesConfirmar 
                aceptar={async ()=>{
                  setCargando(true);
                  await convertirReferencias(registros , token)
                  .then((data) => {
                    if (data?.status === 200 || data?.status === 201 ) {
                      toast.success('Referencias convertidas con éxito');
                      setCargando(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 300);
                    } 
                  })
                  .catch(function (error) {
                    if (error.response.status === 400 || error.response.status === 401) {
                        setCargando(false);
                        toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                        setCargando(false);
                        toast.error('Error interno comuniquese con el administrador');
                    }
                  });
                }}
                cancelar={()=>{
                  setAgregar(false);
                }}
              />
              { cargando && <Spinner />}
            </div>
          }
        />
      )}
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros 
              setFilters={setFilters} 
              setOpenFilters={setOpenFilters} 
              token={token}
              setServicio={setServicio}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
    </div>
  );
};
