import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Registrar } from "./Registrar";
import { postUsuario } from "../services";
import { useSelector } from "react-redux";
import { ValidarCodigo } from "./validar_codigo";
import ModalGenerico from "../../../../components/common/Modal";

// Validation with Yup
const validaciones = Yup.object().shape({
  username: Yup.string()
    .required("usuario es requerido")
    .max(25, "máximo 25 caracteres"),
  firstName: Yup.string()
    .required("nombre es requerido")
    .max(50, "máximo 50 characters"),
  lastName: Yup.string()
    .required("apellido es requerido")
    .max(50, "máximo 50 caracteres"),
  email: Yup.string()
    .required("email es requerido")
    .max(100, "máximo 100 caracteres")
    .email("email invalido"),
  telefono: Yup.string()
    .required("telefono es requerido")
    .max(10, "máximo 8 caracteres"),
  codigo_area: Yup.string()
    .required("codigo de area es requerido")
    .max(10, "máximo 10 caracteres"),
  habilitadoTurno: Yup.boolean().required("campo requerido"),
  usuarioInterno: Yup.boolean().required("campo requerido"),
  codigo_empleado: Yup.string().max(100, "máximo 100 caracteres"),
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormRegistrar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [validarCodigo, setValidarCodigo] = useState(false);
  const [user, setUser] = useState({});

  return (
      <div>
            <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        telefono: "",
        email: "",
        username: "",
        numeroColegiado: "",
        sede: "",
        tipoPuesto: "",
        tipoProfesion: "",
        rol_vista: "",
        estado: true,
        isStaff: false,
        activo: true,
        superUsuario: false,
        habilitadoTurno: true,
        usuarioInterno: true,
        groups: [],
        otra_sede: [],
        user_permissions: [],
        codigo_empleado: "",
        enviar_sms: true,
        codigo_area:""
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await postUsuario(values, token)
          .then((data) => {
            if (data?.status === 200 || data?.status === 201) {
              setSuccess(true);
              setError(false);
              setMensaje("usuario creado éxitosamente");
              setUser(data.data);
              setValidarCodigo(true);
            }
          })
          .catch(function (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              setError(true);
              setSuccess(false);
              setMensaje(error.response.data.message);
            } else if (error.response.status >= 500) {
              setError(true);
              setSuccess(false);
              setMensaje("Error interno comuniquese con el administrador");
            }
          });
      }}
    >
      {(props) => (
        <Registrar
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
        />
      )}
    
    </Formik>
      {validarCodigo && (
        <ModalGenerico
          title={`Validar código`}
          open={validarCodigo}
          closeModal={() => { }}
          cerrar={() => {
            setValidarCodigo(false);
          }}
          children={
            <ValidarCodigo
              usuario={user}
              setValidarCodigo={setValidarCodigo}
            />
          }
        />
      )}
      </div>
  );
};
