/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { getReferencias, getProfesionales, referencias, asignarReferencias } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { DocumentAddIcon, FilterIcon, RefreshIcon, ViewGridAddIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import {
  getDiligenciasExpediente,
  getExpedienteSindicados,
  getFullCasoDetails,
  getSindicadoDelitos
} from "../orientacion/editar/services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "../../../components/common/Label";
import ModalGenerico from "../../../components/common/Modal";
import ModalBig from "../../../components/common/ModalTwo";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import PdfViewer from "../../../components/common/PdfViewer";
import Filtros from "./filtros";
import toast from "react-hot-toast";
import { getExpedienteProceso } from "../orientacion/editar/litigio_estrategico/services";

const tableColumns = [
  { title: "Caso", value: "caso.numero_caso", tipo: "text" },
  { title: "Fecha", value: "fecha_creacion", tipo: "date", ordenar: "fecha_creacion" },
  { title: "Referencia", value: "codigo_referencia", tipo: "text" },
  { title: "Fecha solicitud", value: "fecha_creacion_referencia_ts", tipo: "date" },
  {
    title: "Nombre Víctima",
    value: null,
    values: [
      "persona.primer_nombre",
      "persona.segundo_nombre",
      "persona.primer_apellido",
      "persona.segundo_apellido"
    ],
    tipo: "text"
  },
  {
    title: "Profesional Crea",
    value: null,
    values: [
      "profesional_crea.first_name",
      "profesional_crea.last_name",
    ],
    tipo: "text",
    ordenar: "profesional_crea_referencia_ts__nombre"
  },
  {
    title: "Profesional Solicita",
    value: null,
    values: [
      "profesional_crea_referencia_ts.first_name",
      "profesional_crea_referencia_ts.last_name",
    ],
    tipo: "text"
  },
  {
    title: "Asignado",
    value: "asignado",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
  },
  {
    title: "Servicio",
    value: "referencias_internas.nombre",
    tipo: "text"
  },
  //{
  //  title: "Atención",
  //  value: "asistencia_atencion_especifica",
  //  tipo: "text"
  //}
];

export const TableReferencias = () => {
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [agregar, setAgregar] = useState(false);
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState("");
  const [registros, setRegistros] = useState([]);
  const [profesional, setProfesional] = useState(0);
  const [cargando, setCargando] = useState(false);
  const [servicio, setServicio] = useState("");
  const [order, setOrder] = useState("-fecha_creacion");
  const [profesionales, setProfesionales] = useState([]);
  const permiso = "referencias";
  const animatedComponents = makeAnimated();
  const optionsProfesionales = [];
  const [imprimirData, setImprimirData] = useState(null);

  const usuarioLogin = useSelector(
    (state) => state.loginReducer.data.usuario.user
  );

  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro

  const permisoView = "Asignacióndereferencias";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    // retorno = true;  // mm comentar para que funcione
    return retorno;
  }

  const registrosSeleccionados = (items) => {
    setRegistros(items);
  };

  const changeSelectProfesionales = (e) => {
    setProfesional(e);
  };

  useEffect(() => {
    setTableLoading(true);
    referencias(urlFetch, filters, order, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  }, [urlFetch, filters, order, sede]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getReferencias(urlFetch, search, sede, filters, order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, search, filters, sede, order]);

  useEffect(() => {
    getProfesionales(token).then((data) => {
      setProfesionales(data);
    });
  }, [token]);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item?.first_name} ${item?.last_name} - Asignaciones: ${item?.asignaciones}`,
    };

    if (servicio === "") {
      optionsProfesionales.push(reg);
    } else {
      item.servicios.forEach(element => {
        if (element?.servicio_detalle?.nombre === servicio) {
          optionsProfesionales.push(reg);
        }
      });
    }
  });

  const pages = [
    {
      name: "Funcionalidades",
      href: "/funcionalidades-generales",
      current: false,
    },
    { name: "Asignación de referencias", href: "#", current: true },
  ];

  //https://api.dev.institutodelavictima.gob.gt/server/api/registro/referencia/?caso=&orientacion=&referencia_ts=unknown&referencia_interna=unknown&sede=&expediente__profesional_asignado=&codigo_referencia=&referencias_internas__atencion=&referencias_internas__servicio_interno=&expediente_ts__profesional_asignado=&solicitud_referencia=unknown&expediente__servicio=&persona=&asignado=unknown&tipo_referencia__nombre=&profesional_crea_referencia_ts=&profesional_crea=&fecha_creacion=&fecha=&fecha_creacion_referencia_ts=&profesional_asignado=&codigo_referencia__isnull=false&referencias_internas__servicio_interno__isnull=unknown&fecha__gte=&fecha__lte=&fecha_creacion__gte=&fecha_creacion__lte=&fecha_creacion_referencia_ts__gte=&fecha_creacion_referencia_ts__lte=
  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div>
          {(CheckPermiso('asignarreferencias') === true) &&    //mm permiso agregado      
            <ButtonAdd
              className="ml-2"
              text="Asignar"
              type="button"
              onClick={() => {
                if (registros.length === 0) {
                  toast.error('Debe de seleccionar al menos una referencia.')
                }
                else {
                  let servicioDiferente = false;
                  let sedeDiferente = false;
                  let servicio = registros[0]?.referencias_internas?.nombre;
                  let sede = registros[0].sede.sede;

                  registros.forEach(element => {
                    if (servicio !== element?.referencias_internas?.nombre) {
                      servicioDiferente = true;
                    }
                    if (sede !== element.sede.sede) {
                      sedeDiferente = true;
                    }
                  });

                  if (servicioDiferente || sedeDiferente) {
                    toast.error("Debe elegir servicios iguales, y que pertenezcan a la misma sede para realizar la asignacion");
                  } else {
                    setAgregar(true);
                  }
                }
              }}
              icon={<ViewGridAddIcon className="-mr-1 h-5" />}
            />
          }
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="-mr-1 h-5" />}
          />
          <ButtonAdd
            className="ml-2"
            text="Refrescar"
            type="button"
            onClick={() => {
              setFilters("");
            }}
            icon={<RefreshIcon className="-mr-1 h-5" />}
          />
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            showEliminar={false}
            showEditar={true}
            showVer={true}
            order={order}
            setOrder={setOrder}
            agregar={true}
            select={true}
            registrosSeleccionados={registrosSeleccionados}
            agregar1="Convertir a expediente"
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>

      {agregar && (
        <ModalBig
          title={"Asignar referencias"}
          open={agregar}
          closeModal={() => {
            setAgregar(false);
            setImprimirData(null);
            window.location.reload();
          }}
          cerrar={() => {
            setAgregar(false);
            setImprimirData(null);
            window.location.reload();
          }}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <DocumentAddIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
            </div>
          }
          children={
            <div className="grid grid-cols-12 gap-2 mb-10">
              <div className="col-span-5 border rounded-lg p-3">
                <Label text="Profesional" />
                <div className="mt-1">
                  <Select
                    isDisabled={imprimirData == null ? false : true}
                    placeholder="Sel."
                    components={animatedComponents}
                    name="servicios"
                    className="basic-multi-select text-sm"
                    classNamePrefix="seleccione"
                    options={optionsProfesionales}
                    onChange={changeSelectProfesionales}
                    maxMenuHeight={140}
                  />
                </div>

                {!imprimirData &&
                  <BotonesConfirmar
                    aceptar={async () => {
                      setCargando(true);
                      await asignarReferencias(registros, profesional, token)
                        .then(async (data) => {
                          if (data?.status >= 200 || data?.status <= 210) {
                            toast.success('Asignaciones realizadas con éxito');
                            let profSend = {};
                            profesionales.results.forEach(item => {
                              if (item.id === profesional.value) {
                                profSend = item;
                              }
                            })

                            let asignacionesSend = [];

                            await registros.forEach(async (asig, index) => {
                              await getSindicadoDelitos(asig.caso?.id, token).then(async (delitos) => {
                                await getExpedienteSindicados(asig.caso?.id, token).then(async sindicado => {
                                  await getFullCasoDetails(asig.caso?.id, token).then(async personasCaso => {
                                    await getDiligenciasExpediente(asig.expediente?.id, token).then(async diligencias => {
                                      await getExpedienteProceso(asig.caso?.id, token).then(procesos => {

                                        let etapaProcesal = "";

                                        personasCaso.expedientes.forEach(exp => {
                                          if (exp.ultima_etapa_procesal.etapa_procesal) {
                                            etapaProcesal += ` ${exp.ultima_etapa_procesal.etapa_procesal.nombre} ${etapaProcesal !== "" ? ',' : ''} `;
                                          }
                                        })

                                        asignacionesSend.push({
                                          ...asig.expediente,
                                          etapaProcesal: etapaProcesal,
                                          persona: asig.persona,
                                          servicio: asig.referencias_internas,
                                          sede_expediente: asig.sede,
                                          delitos: delitos.results,
                                          sindicados: sindicado.results,
                                          personasCaso: personasCaso,
                                          diligencias: diligencias,
                                          etapaProcesal: procesos.results
                                        })
                                        if (index === (registros.length - 1)) {
                                          setCargando(false);
                                          setImprimirData({
                                            profesional: profSend,
                                            asignaciones: asignacionesSend,
                                            usuario: usuarioLogin,
                                            referencia: true
                                          })
                                        }
                                      });
                                    });
                                  });
                                })
                              })
                            })
                          }
                        })
                        .catch(function (error) {
                          if (error.response.status >= 400 || error.response.status <= 410) {
                            setCargando(false);
                            toast.error(error.response.data.message);
                          } else if (error.response.status >= 500) {
                            setCargando(false);
                            toast.error('Error interno comuniquese con el administrador');
                          }
                        });
                    }}
                    cancelar={() => {
                      setAgregar(false);
                    }}
                  />
                }
                {cargando && <Spinner />}
              </div>
              <div className="col-span-7 border rounded-lg p-3">
                {cargando && <Spinner />}
                {imprimirData &&
                  <PdfViewer
                    data={imprimirData}
                    reporte={"asignaciones"}
                    style={{ height: "70vh" }}
                  />
                }
              </div>
            </div>
          }
        />
      )}
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
              setServicio={setServicio}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
    </div>
  );
};
