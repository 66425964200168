const {
    REACT_APP_API_URL
} = process.env;


export const getSede = async (id,token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/catalogos/sedes/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};
