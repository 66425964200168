import { types } from "../types/types";

export const ChangeOrientacionTab = (value) => {
  return {
    type: types.ChangeOrientacionTab,
    payload: value,
  };
};

export const SelectedDefaultTabOrientacion = () => {
    let tabs = [
        { index: 0, active: true, block: false, name:"Admisibilidad" },
        { index: 1, active: false, block: true, name:"Víctimas" },
        { index: 2, active: false, block: true, name:"Querellantes / representante legal" },
        { index: 3, active: false, block: true, name:"Sindicado" },
        { index: 4, active: false, block: true, name:"Procesos/Delitos" },
        { index: 5, active: false, block: true, name:"Hechos" },
        { index: 6, active: false, block: true, name:"Riesgos" },
        { index: 8, active: false, block: true, name:"Evaluación diagnóstica" },
    ]
  return tabs;
};
