import React, { useEffect, useState } from "react";
import { ButtonSiavi } from "../../../../components/common/Button";
import { useSelector } from "react-redux";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import { 
    getServicio, 
    getAtencion, 
    getIdioma, 
    getIdiomaMaya, 
    //getArea, 
    //getUsuarios 
} from "../services";
import Toggle from "../../../../components/common/Toggle";
import Spinner from "../../../../components/common/Spinner";
import Label from "../../../../components/common/Label";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Return from "../../../../components/common/ButtonSalir";

const useFetchServicio = (token) => {
    const [state, setState] = useState({
        servicios: [],
    });

    useEffect(() => {
        getServicio(token).then((servicios) => {
            setState({
                servicios: servicios,
            });
        });
    }, [token]);

    return state;
};

const useFetchAtencion = (token) => {
    const [state, setState] = useState({
        atenciones: [],
    });

    useEffect(() => {
        getAtencion(token).then((atenciones) => {
            setState({
                atenciones: atenciones,
            });
        });
    }, [token]);

    return state;
};

const useFetchIdioma = (token) => {
    const [state, setState] = useState({
        idiomas: [],
    });

    useEffect(() => {
        getIdioma(token).then((idiomas) => {
            setState({
                idiomas: idiomas,
            });
        });
    }, [token]);

    return state;
};

const useFetchIdiomaMaya = (token) => {
    const [state, setState] = useState({
        idiomasmayas: [],
    });

    useEffect(() => {
        getIdiomaMaya(token).then((idiomasmayas) => {
            setState({
                idiomasmayas: idiomasmayas,
            });
        });
    }, [token]);

    return state;
};

// const useFetchArea = (token) => {
//     const [state, setState] = useState({
//         areas: [],
//     });

//     useEffect(() => {
//         getArea(token).then((areas) => {
//             setState({
//                 areas: areas,
//             });
//         });
//     }, [token]);

//     return state;
// };

// const useFetchUsuarioAutoriza = (token) => {
//     const [state, setState] = useState({
//         autorizado: [],
//     });

//     useEffect(() => {
//         getUsuarios(token).then((autorizado) => {
//             setState({
//                 autorizado: autorizado,
//             });
//         });
//     }, [token]);

//     return state;
// };

// const useFetchUsuarioInactiva = (token) => {
//     const [state, setState] = useState({
//         inactivacion: [],
//     });

//     useEffect(() => {
//         getUsuarios(token).then((inactivacion) => {
//             setState({
//                 inactivacion: inactivacion,
//             });
//         });
//     }, [token]);

//     return state;
// };

export const Editar = ({
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    customChange,
    hasError,
    success,
    mensaje
}) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const { servicios } = useFetchServicio(token);
    const { atenciones } = useFetchAtencion(token);
    const { idiomas } = useFetchIdioma(token);
    const { idiomasmayas } = useFetchIdiomaMaya(token);
    //const { areas } = useFetchArea(token);
    //const { autorizado } = useFetchUsuarioAutoriza(token);
    //const { inactivacion } = useFetchUsuarioInactiva(token);
    const [activaIdiomaMaya, setActivaIdiomaMaya] = useState(false);

    const selectClass = `mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue 
    block w-full sm:text-sm border-maivi-gray-10 rounded-md`;

    const pages = [
        {
            name: "Encuestas",
            href: "/catalogos/encuesta",
            current: false,
        },
        { name: "Actualizar", href: "#", current: true },
    ];

    return (
        <form autoComplete="off" onChange={customChange(values)}>
            <div className="grid-cols-12 container mx-auto -mt-32 mb-10 justify-center">
                <div className="col-span-12 shadow-xl sm:rounded-md px-4 pb-10 bg-maivi-secondaryWhite">
                    <div
                        className="flex flex-col justify-center items-center xl:justify-between lg:justify-between md:items-center
          lg:flex-row xl:flex-row md:flex-col sm:flex-col mb-3 pt-5"
                    >
                        <BreadCrumbTwo navegacion={pages} />
                        <div className="flex flex-row">
                            <h1 className="text-lg text-maivi-primaryFont mr-2">
                                Editar
                            </h1>
                            <Return ruta="/catalogos/encuesta" />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-12 xl:col-span-12 lg:col-span-12 border-2 rounded-md p-5">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <InputSiavi
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        onChange={handleChange}
                                        value={values.nombre}
                                        validacion={true}
                                        label="*Nombre"
                                    />
                                </div>
                                <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                                    <InputSiavi
                                        type="text"
                                        name="descripcion"
                                        id="descripcion"
                                        value={values.descripcion}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="*Descripción"
                                    />
                                </div>
                                {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Encuesta" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.encuesta} name="encuesta" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Profesional" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.profesional} name="profesional" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Usuario Víctima" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.usuario_victima} name="usuario_victima" />
                                    </div>
                                </div>
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Por Orientación" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_orientacion} name="por_orientacion" />
                                    </div>
                                </div> */}
                                {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Autorizado" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.autorizado} name="autorizado" />
                                    </div>
                                </div>
                                {values.autorizado && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <InputSiavi
                                            type="date"
                                            name="fecha_autoriza"
                                            id="fecha_autoriza"
                                            value={values.fecha_autoriza}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Fecha Autoriza"
                                        />
                                    </div>
                                )}
                                {values.autorizado && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 ">
                                        <Label text="Usuario Autoriza" />
                                        <select
                                            id="usuario_autoriza"
                                            name="usuario_autoriza"
                                            autoComplete="off"
                                            value={values.usuario_autoriza}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir usuario autoriza
                                            </option>
                                            {autorizado?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.id + "UA" + index} value={item.id}>
                                                        {item.username}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text={`${values.estado ? "Activo" : "Inactivo"}`} />
                                    <div className="mt-1">
                                        <Toggle enabled={values.estado} name="estado" disabled={`${values.estado ? "" : "disabled"}`} />
                                    </div>
                                </div>
                                {!values.estado && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <InputSiavi
                                            type="date"
                                            name="fecha_inactivacion"
                                            id="fecha_inactivacion"
                                            value={values.fecha_inactivacion}
                                            onChange={handleChange}
                                            validacion={true}
                                            label="Fecha Inactivación"
                                        />
                                    </div>
                                )}
                                {!values.estado && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 ">
                                        <Label text="Usuario Inactivación" />
                                        <select
                                            id="usuario_inactivacion"
                                            name="usuario_inactivacion"
                                            autoComplete="off"
                                            value={values.usuario_inactivacion}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir usuario inactivación
                                            </option>
                                            {inactivacion?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.id + "UI" + index} value={item.id}>
                                                        {item.username}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )} */}
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Por Servicio" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_servicio} name="por_servicio" />
                                    </div>
                                </div>
                                {values.por_servicio && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 ">
                                        <Label text="Servicio" />
                                        <select
                                            id="servicio"
                                            name="servicio"
                                            autoComplete="off"
                                            value={values.servicio}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir servicio
                                            </option>
                                            {servicios?.results?.map((item, index) => {
                                                return (
                                                    <option key={item.id + "DP" + index} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                {/* <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Por Área" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_area} name="por_area" />
                                    </div>
                                </div>

                                {values.por_area && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <Label text="Área" />
                                        <select
                                            as="select"
                                            id="area"
                                            name="area"
                                            autoComplete="off"
                                            value={values.area}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir área
                                            </option>
                                            {areas?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )} */}

                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center">
                                    <Label text="Por Atención" />
                                    <div className="mt-1">
                                        <Toggle enabled={values.por_atencion} name="por_atencion" />
                                    </div>
                                </div>

                                {values.por_atencion && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <Label text="Atención" />
                                        <select
                                            as="select"
                                            id="atencion"
                                            name="atencion"
                                            autoComplete="off"
                                            value={values.atencion}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir atención
                                            </option>
                                            {atenciones?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                    <Label text="*Idioma" />
                                    <select
                                        as="select"
                                        id="idioma"
                                        name="idioma"
                                        autoComplete="off"
                                        value={values.idioma}
                                        onChange={(e) => {
                                            setFieldValue("idioma", e.target.value);
                                            if (e.target.selectedOptions[0].innerText === "MAYA") {
                                                setActivaIdiomaMaya(true)
                                            } else {
                                                setActivaIdiomaMaya(false)
                                            }
                                        }}
                                        className={selectClass}
                                    >
                                        <option value="" disabled>
                                            Elegir Idioma
                                        </option>
                                        {idiomas?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ErrorMessage
                                        name="idioma"
                                        component="span"
                                        className="font-medium text-xs text-maivi-secondaryRed"
                                    />
                                </div>

                                {activaIdiomaMaya && (
                                    <div className="col-span-12 xl:col-span-4 lg:col-span-4">
                                        <Label text="Idioma Maya" />
                                        <select
                                            as="select"
                                            id="idioma_maya"
                                            name="idioma_maya"
                                            autoComplete="off"
                                            value={values.idioma_maya}
                                            onChange={handleChange}
                                            className={selectClass}
                                        >
                                            <option value="" disabled>
                                                Elegir idioma maya
                                            </option>
                                            {idiomasmayas?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                )}

                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 flex flex-col justify-center items-center mt-5">
                                    <ButtonSiavi
                                        type="button"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        text="Actualizar"
                                    />
                                </div>
                                {isSubmitting && <Spinner />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
