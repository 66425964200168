import { React } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { putMedidaVictimologica } from "../services";
import { SelectSiavi } from "../../../../../components/common/Select";
import { Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import moment from "moment";
import { InputSiavi } from "../../../../../components/common/InputText";

const validaciones = Yup.object().shape({
  status: Yup.string().required("estado es requerido"),
  fecha_modificacion: Yup.string().required("fecha es requerida"),
  comentario_estado: Yup.string().required('comentario es requerido')
});

export const ModificarStatusMV = ({
  token,
  statusMedidas,
  medidaVictimologica,
  state,
  setState,
  setDate,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          id: medidaVictimologica.id,
          servicio: medidaVictimologica?.status?.id,
          medida_victimologica: medidaVictimologica?.medida_victimologica.id,
          especificaciones: medidaVictimologica.especificaciones,
          comentario_estado:"",
          caso_persona: medidaVictimologica?.caso_persona.id,
          status: medidaVictimologica?.status?.id,
          fecha: medidaVictimologica?.fecha,
          estado: true,
          expediente: medidaVictimologica?.expediente?.id,
          fecha_modificacion: moment().format("YYYY-MM-DD")
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          await putMedidaVictimologica(values, token)
            .then(() => {
              toast.success("Registro actualizado con éxito...!");
              setState({
                ...state,
                cambiarStatusVM: false,
              });

              setDate(new Date());
            })
            .catch(function (error) {
              if (
                error.response.status === 400 ||
                error.response.status === 401
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mx-5">
              <div className="col-span-12 mt-3">
                <SelectSiavi
                  label="Estado"
                  valueInit=""
                  selectInit="Elegir estado"
                  value={values.status}
                  onChange={handleChange}
                  name="status"
                  validacion={true}
                  list={statusMedidas.map((item) => {
                    return (
                      <option key={item.nombre} value={item.id}>
                        {item.nombre}
                      </option>
                    );
                  })}
                />
              </div>
              <div className="mt-3">
                  <InputSiavi
                    type="date"
                    name="fecha_modificacion"
                    id="fecha_modificacion"
                    maximo={moment().format("YYYY-MM-DD")}
                    value={values.fecha_modificacion}
                    onChange={handleChange}
                    validacion={true}
                    label="Fecha modificacion"
                  />
                </div>
              <div className="mt-3">
                <TextAreaSiavi 
                  value={values.comentario_estado}
                  label="Comentario cambio de estado"
                  name="comentario_estado"
                  validacion={true}
                  onChange={handleChange}
                  rows={5}
                  cols={5}
                />
              </div>

              <div className="py-3 text-right">
                <ButtonSiavi
                  cargando={isSubmitting}
                  type="submit"
                  disabled={isSubmitting}
                  text="Guardar"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
