import { React, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Crear } from "./Crear";
import { postBiblioteca } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

// Validation with Yup
const validaciones = Yup.object().shape({
    nombre_documento: Yup.string()
        .required("Nombre Documento es requerido")
        .max(100, "máximo 100 caracteres"),
    palabras_clave: Yup.string()
        .required("Palabras Clave es requerido")
        .max(100, "máximo 100 caracteres")
});

const customSwitchChange = (setEnabled, e) => {
    setEnabled(e);
};

export const FormCrear = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const [hasError, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const history = useHistory();

    return (
        <Formik
            initialValues={{
                nombre_documento: "",
                palabras_clave: "",
                estado: true,
                por_servicio: false,
                fecha_creacion: "",
                clasificacion: "",
                servicio: ""
            }}
            validationSchema={validaciones}
            onSubmit={async (values) => {
                await postBiblioteca(values, token)
                    .then((data) => {
                        if (data?.status === 200 || data?.status === 201) {
                            setSuccess(true);
                            setError(false);
                            setMensaje('Registro creado con éxito...!');
                            toast.success('Registro creado con éxito...!')
                            history.push('/catalogos/biblioteca');
                        } else {
                            setError(true);
                            setSuccess(false);
                        }
                    })
                    .catch(function (error) {
                        if (
                            error.response.status === 400 ||
                            error.response.status === 401
                        ) {
                            setError(true);
                            setSuccess(false);
                            setMensaje(error.response.data.message);
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            setError(true);
                            setSuccess(false);
                            toast.error("Error interno comuniquese con el administrador");
                        }
                    });
            }}
        >
            {(props) => (
                <div>
                    <Crear
                        {...props}
                        customSwitchChange={customSwitchChange}
                        hasError={hasError}
                        success={success}
                        mensaje={mensaje}
                    />
                </div>

            )}
        </Formik>
    );
};
