import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { getNacionalidadVictima } from "../victima/services";
import { useSelector } from "react-redux";
import { bloqueoFecha } from "../../../../../helpers/utils";
import Label from "../../../../../components/common/Label";
import NumberFormat from 'react-number-format';

const useFetchNacionalidad = (token) => {
  const [state, setState] = useState({
    nacionalidad: [],
  });

  useEffect(() => {
    getNacionalidadVictima(token).then((nacionalidad) => {
      setState({
        nacionalidad: nacionalidad,
      });
    });
  }, [token]);

  return state;
};

export const DatosGenerales = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { nacionalidad } = useFetchNacionalidad(token);

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          type="text"
          name="primer_nombre"
          value={props?.values?.primer_nombre}
          onChange={props?.handleChange}
          label="Primer nombre"
          validacion={true}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          type="text"
          name="segundo_nombre"
          value={props?.values?.segundo_nombre}
          onChange={props?.handleChange}
          validacion={true}
          label="Segundo nombre"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          type="text"
          name="otros_nombres"
          value={props?.values?.otros_nombres}
          onChange={props?.handleChange}
          validacion={true}
          label="Otros nombres"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          name="primer_apellido"
          type="text"
          value={props?.values?.primer_apellido}
          onChange={props?.handleChange}
          validacion={true}
          label="Primer apellido"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          name="segundo_apellido"
          type="text"
          value={props?.values?.segundo_apellido}
          onChange={props?.handleChange}
          validacion={true}
          label="Segundo apellido"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          type="text"
          name="apellido_casada"
          value={props?.values?.apellido_casada}
          onChange={props?.handleChange}
          validacion={true}
          label="Apellido de casada"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <InputSiavi
          type="date"
          name="fecha_nacimiento"
          value={props?.values?.fecha_nacimiento}
          onChange={props?.handleChange}
          validacion={true}
          label="Fecha de nacimiento"
          maximo={bloqueoFecha()}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <Label text="Edad" />
        <NumberFormat 
          name="edad"
          value={props.values.edad}
          onChange={props.handleChange}
          allowNegative={false}
          className="mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
          focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
          sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <Label text="Nacionalidad" />
        <select
          name="nacionalidad"
          value={props?.values?.nacionalidad}
          onChange={props?.handleChange}
          className={props?.selectClass}
        >
          <option value="" >
            Elegir Nacionalidad
          </option>
          {nacionalidad?.results?.map((item) => {
            return (
              <option key={item.nombre + "n"} value={item.id}>
                {item.nombre}
              </option>
            );
          })}
        </select>
        <ErrorMessage
          name="nacionalidad"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12 mb-2">
        <InputSiavi
          type="text"
          name="pseudonimo"
          value={props?.values?.pseudonimo}
          onChange={props?.handleChange}
          validacion={true}
          label="Pseudónimo"
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4 xl:col-span-3 md:col-span-6 col-span-12">
        <Label text="Sexo" />
        <select
          name="sexo"
          value={props?.values?.sexo}
          onChange={props?.handleChange}
          className={props?.selectClass}
        >
          <option value="">Seleccionar</option>
          <option value="HOMBRE">HOMBRE</option>
          <option value="MUJER">MUJER</option>
        </select>
        <ErrorMessage
          name="sexo"
          component="span"
          className="font-medium text-xs text-maivi-secondaryRed"
        />
      </div>
    </div>
  );
};
