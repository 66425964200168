import { React, useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputSiavi } from "../../../../../../components/common/InputText";
import {
  getPlanTratamientos,
  postPlanTratamientoPreliminar,
  putPlanTratamientoPreliminar,
  deleteTratamiento
} from "./services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../../components/common/Button";
import { TrashIcon, PencilAltIcon } from "@heroicons/react/solid";
import Label from "../../../../../../components/common/Label";
import Spinner from "../../../../../../components/common/Spinner";
import toast from "react-hot-toast";

const selectClass = `mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
sm:text-sm  rounded-md bg-white text-maivi-primaryFont`;


const validaciones = Yup.object().shape({
  plan: Yup.string()
    .required("plan es requerido")
    .max(100, "Máximo 100 caracteres"),
  servicio: Yup.string()
    .required("servicio es requerido")
});

const useFetchPlanes = (idEvaluacion, refetch, token) => {
  const [state, setState] = useState({
    planes: [],
  });

  useEffect(() => {
    getPlanTratamientos(idEvaluacion, token).then((data) => {
      setState({
        planes: data,
      });
    });
  }, [idEvaluacion, token, refetch]);

  return state;
};



export const AddTratamientoPreliminar = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [refetch, setRefetch] = useState(false);
  const { planes } = useFetchPlanes(props.idEvaluacion, refetch, token);
  const [editar, setEditar] = useState(false);


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre
  
  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso === permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
  
    //        retorno = true;  // mm comentar para que funcione
   //       retorno = false;  // mm comentar para que funcione
    return retorno;
  
  }


  return (
    <div>
      <Formik
        initialValues={{
          plan: "",
          servicio: "",
          comentario_plan_tratamiento: "",
          idPlan: 0
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (editar) {
            await putPlanTratamientoPreliminar(values, props.idEvaluacion, token)
              .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                  toast.success("Registro actualizado con éxito...!");
                  setRefetch(true);
                  setRefetch(false);
                  resetForm({});
                  setEditar(false);
                } else {
                  setRefetch(true);
                  setRefetch(false);
                  toast.success("Registro actualizado con éxito...!");
                }
              })
              .catch(function (error) {
                if (
                  error.response.status === 400 ||
                  error.response.status === 401
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error(
                    "Error interno comuniquese con el administrador"
                  );
                }
              });
          } else {
            await postPlanTratamientoPreliminar(values, props.idEvaluacion, token)
              .then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                  toast.success("Registro creado con éxito...!");
                  setRefetch(true);
                  setRefetch(false);
                  resetForm({});
                } else {
                  setRefetch(true);
                  setRefetch(false);
                  toast.success("Registro creado con éxito...!");
                }
              })
              .catch(function (error) {
                if (
                  error.response.status === 400 ||
                  error.response.status === 401
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error(
                    "Error interno comuniquese con el administrador"
                  );
                }
              });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 xl:col-span-6 lg:col-span-4 md:col-span-6">
                  <InputSiavi
                    name="plan"
                    value={values.plan}
                    onChange={handleChange}
                    validacion={true}
                    label="Acción"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 lg:col-span-4 md:col-span-6">
                  <Label text="Servicio" />
                  <select
                    name="servicio"
                    value={values.servicio}
                    onChange={handleChange}
                    className={selectClass}
                  >
                    <option value={null}>Elegir servicio</option>
                    {props?.servicios?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="servicio"
                    component="span"
                    className="text-xs text-maivi-secondaryRed"
                  />
                </div>
                <div className="col-span-12 xl:col-span-9 lg:col-span-9 md:col-span-8">
                  <Label text="Comentarios" />
                  <InputSiavi
                    name="comentario_plan_tratamiento"
                    value={values.comentario_plan_tratamiento}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 flex items-end ">
                  {(CheckPermiso('OEDIAgregarPlanTratamiento') === true) &&
                    <ButtonSiavi
                      text={(editar) ? 'Actualizar' : 'Guardar'}
                      onClick={handleSubmit}
                    />
                  }
                </div>
              </div>
              <table className="table w-full border mt-3">
                <thead className="bg-maivi-gray-5 rounded-md">
                  <tr>
                    <th className={props.thClass}>Acción</th>
                    <th className={props.thClass}>Servicio</th>
                    <th className={props.thClass}>Fecha</th>
                    <th className={props.thClass}>Comentario</th>
                    <th className={props.thClass}>Acciones</th>
                  </tr>
                </thead>
                <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
                  {planes.results?.map((item) => (
                    <tr key={item.id} className="hover:bg-maivi-gray-5 cursor-pointer">
                      <td className={props.tdClass}>
                        {item?.plan}
                      </td>
                      <td className={props.tdClass}>
                        {item?.servicio?.nombre}
                      </td>
                      <td className={props.tdClass}>
                        {item?.fecha_realizado}
                      </td>
                      <td className={props.tdClass}>
                        {item?.comentario_realizado}
                      </td>
                      <td className={props.tdClass}>
                        {(CheckPermiso('OEDIEditarPlanTratamiento') === true) &&
                          <button
                            type="button"
                            onClick={() => {
                              setFieldValue('plan', item?.plan);
                              setFieldValue('servicio', item?.servicio.id);
                              setFieldValue('comentario_plan_tratamiento', item?.comentario_realizado);
                              setFieldValue('idPlan', item.id);
                              setEditar(true);
                            }}
                            className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                          >
                            <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                          </button>
                        }
                        {(CheckPermiso('OEDIEliminarPlanTratamiento') === true) &&
                          <button
                            type="button"
                            onClick={async () => {
                              await deleteTratamiento(item.id, token)
                                .then((data) => {
                                  if (data?.status === 200 || data?.status === 201) {
                                    toast.success("Registro eliminado con éxito...!");
                                    setRefetch(true);
                                    setRefetch(false);
                                  } else {
                                    setRefetch(true);
                                    setRefetch(false);
                                    toast.success("Registro eliminado con éxito...!");
                                  }
                                })
                                .catch(function (error) {
                                  if (
                                    error.response.status === 400 ||
                                    error.response.status === 401
                                  ) {
                                    toast.error(error.response.data.message);
                                  } else if (error.response.status >= 500) {
                                    toast.error(
                                      "Error interno comuniquese con el administrador"
                                    );
                                  }
                                });
                            }}
                            className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                                rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                          >
                            <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
