import { React } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import {
  putConsentimientoInformado,
} from "./services";
import Alert from "../../../components/common/Alert";
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import toast from "react-hot-toast";
import Label from "../../../components/common/Label";
import * as Yup from "yup";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const validaciones = Yup.object().shape({
 
});

export const Autorizar = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const consentimiento = props?.consentimiento;
  
  return (
    <div>
      <Formik
        initialValues={{
          autorizado: consentimiento.autorizado,
          estado: consentimiento.estado,
          es_autorizado : consentimiento.autorizado
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
            values.nombre = consentimiento.nombre;
            values.descripcion = consentimiento.descripcion;
            values.unificado = consentimiento.unificado;
            values.consentimiento = consentimiento.consentimiento;
            values.fecha_autorizacion = consentimiento.fecha_autorizacion;
            values.fecha_inactivacion = consentimiento.fecha_inactivacion;
            values.servicio = consentimiento?.servicio?.id;
            values.atencion = consentimiento?.atencion?.id;
            values.idioma = consentimiento?.idioma?.id;
            values.idioma_maya = consentimiento?.idioma_maya?.id;
            values.es_nna = consentimiento?.es_nna;
            values.por_atencion = consentimiento.por_atencion;
            values.por_servicio = consentimiento.por_servicio;

            await putConsentimientoInformado(
                consentimiento.id,
                values,
                token
              ).then((data) => {
                if (data?.message !== undefined) {
                  toast.error(data.message);
                } else {
                  props.setReFetch(true);
                  props.setGestionar(false);
                  toast.success("Registro actualizado con éxito...!");
                  props.setReFetch(false);
                }
              });
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form>
            <div className="grid grid-cols-12 gap-3">
                  {
                      !values.es_autorizado &&
                      <div className="col-span-12 xl:col-span-6 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    <Label text="Autorizar" />
                    <Toggle enabled={values.autorizado} name="autorizado" />
                  </div> 
                  }
                  <div className="col-span-12 xl:col-span-6 lg:col-span-1 md:col-span-4 flex flex-col justify-center items-center">
                    <Label text="Activar" />
                    <Toggle enabled={values.estado} name="estado" />
                  </div> 
            </div>
           {
               (values.autorizado && values.es_autorizado === false) &&
               <div className="mt-4">
               <Alert type="warning" mensaje="¿Una vez autorizado no podrá editarlo nuevamente desea continuar? " />
           </div>
           }
            {
               values.es_autorizado &&
               <div className="mt-4">
               <Alert type="warning" mensaje="Este registro ya fue autorizado" />
           </div>
           }
            <BotonesConfirmar 
                aceptar={handleSubmit}
                cancelar={() =>{
            console.log(props.consentimiento);

                    props.setGestionar(false);
                }}
            />
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
