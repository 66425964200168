/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../components/common/Button";
import { SelectSiavi } from "../../../components/common/Select";
import { InputSiavi } from "../../../components/common/InputText";
import {
  getProfesionales,
  getInstitucionGestion
} from "./services";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Spinner from "../../../components/common/Spinner";
import Label from "../../../components/common/Label";

export default function Filtros(props) {
  const animatedComponents = makeAnimated();
  const optionsProfesionales = [];
  const optionsInstituciones = [];
  let filtros = "";
  
  const [state, setState] = useState({
    profesional_asignado: "",
    gestion_institucion: "",
    completada: "",
    fecha_desde: "",
    fecha_hasta: ""
  });

  const [profesionales, setProfesionales] = useState([]);
  const [instituciones, setInstituciones] = useState([]);

  function onChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getProfesionales(props.token).then((data) => {
      setProfesionales(data);
    });
  }, []);

  useEffect(() => {
    getInstitucionGestion(props.token).then((data) => {
      setInstituciones(data);
    });
  }, []);

  profesionales?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: `${item.first_name} ${item.last_name} - ${item?.tipo_puesto?.puesto}`,
    };
    optionsProfesionales.push(reg);
  });

  const changeSelectProfesional = (e) => {
    setState({
      ...state,
      profesional_asignado: e.value,
    });
  };

  instituciones?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre_fiscalia,
    };
    optionsInstituciones.push(reg);
  });

  const changeSelectInstitucion = (e) => {
    setState({
      ...state,
      gestion_institucion: e.value,
    });
  };

  return (
    <div className="max-w-7xl px-4 sm:px-6 md:px-6">
         <div className="col-span-12 mt-1">
        <Label text="Profesional asignado" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="profesional"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsProfesionales}
          onChange={changeSelectProfesional}
        />
      </div>
      <div className="col-span-12 mt-4">
      <Label text="Institución" />
        <Select
          placeholder="Seleccionar"
          components={animatedComponents}
          name="institucion"
          className={`basic-multi-select text-xs text-maivi-primaryFont`}
          classNamePrefix="seleccione"
          options={optionsInstituciones}
          onChange={changeSelectInstitucion}
          maxMenuHeight={230}
        />
        {/* <SelectSiavi
          label="Institución"
          name="gestion_institucion"
          value={state.gestion_institucion}
          onChange={onChange}
          initValue=""
          selectInit="Elegir institución"
          className="mb-3"
          list={
            instituciones?.results?.map((item,index) => {
              return <option key={item?.institucion?.nombre + index} value={item.institucion.id}>{item?.nombre_fiscalia}</option>
            })
          }
        /> */}
      </div>
      <div className="mt-1">
        <SelectSiavi 
         label="Completada"
         name="completada"
         value={state.completada}
         onChange={onChange}
         valueInit=""
         selectInit="Elegir"
         list={
            [
              <option value="true">Sí</option>,
              <option value="false">No</option>
            ]
         }
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_desde"
          value={state.fecha_desde}
          onChange={onChange}
          label="Fecha Planificada inicio"
        />
      </div>
      <div className="col-span-12 mt-4">
        <InputSiavi
          type="date"
          name="fecha_hasta"
          value={state.fecha_hasta}
          onChange={onChange}
          label="Fecha Planificada fin"
        />
      </div>
    
   
      <div className="col-span-12 mt-4">
        <ButtonSiavi
          text="Buscar"
          onClick={() => {
            filtros = `gestion_institucion=${state.gestion_institucion}&completada=${state.completada}&profesional_asignado=${state.profesional_asignado}&fecha_hora_planificada_inicia__gte=${state.fecha_desde}&fecha_hora_planificada_inicia__lte=${state.fecha_hasta}`;
            props.setFilters(filtros);
            props.setOpenFilters(false);
          }}
        />
      </div>
      {props.isloading === true ? <Spinner /> : ""}
    </div>
  );
}
