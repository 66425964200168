import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    deleteFormulariosPreguntas,
    getFormulariosPreguntasFormulario,
    postFormulariosPreguntas,
    putFormulariosPreguntas,
    getTipoPregunta,
    getSeleccion
} from "./services";
import Spinner from "../../../components/common/Spinner";
import { InputSiavi } from "../../../components/common/InputText";
import { ButtonSiavi } from "../../../components/common/Button";
import Label from "../../../components/common/Label";
import Toggle from "../../../components/common/Toggle";
import CustomTable from "../../../components/common/Table";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../components/common/Modal";
import { XCircleIcon } from "@heroicons/react/outline";
import FormEliminar from "../../../components/common/FormEliminar";
import toast from 'react-hot-toast';

const tableColumns = [
    { title: "id", value: "id", tipo: "text" },
    { title: "Pregunta", value: "pregunta", tipo: "text" },
    { title: "Tipo Pregunta", value: "tipo_pregunta.nombre", tipo: "text" },
    {
        title: "Obligatorio",
        value: "obligatorio",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Estado",
        value: "estado",
        tipo: "boolean",
        text: { true: "Activo", false: "Inactivo" },
    },
];

const validaciones = Yup.object().shape({
    pregunta: Yup.string()
        .required("Pregunta es requerido")
        .max(150, "Máximo 150 caracteres")
});

const useFetchTipoPregunta = (token) => {
    const [state, setState] = useState({
        tipo_preguntas: [],
    });

    useEffect(() => {
        getTipoPregunta(token).then((tipo_preguntas) => {
            setState({
                tipo_preguntas: tipo_preguntas,
            });
        });
    }, [token]);

    return state;
};

const useFetchSeleccion = (token) => {
    const [state, setState] = useState({
        catalogo_selecciones: [],
    });

    useEffect(() => {
        getSeleccion(token).then((catalogo_selecciones) => {
            setState({
                catalogo_selecciones: catalogo_selecciones,
            });
        });
    }, [token]);

    return state;
};

export const GestionesFormulariosPreguntas = (props) => {
    const [isloading, setLoading] = useState(false);
    const [isEliminar, setEliminar] = useState(false);
    const [idFormulariosPreguntas, setIdFormulariosPreguntas] = useState("");
    const [formulariosPreguntas, setFormulariosPreguntas] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const token = useSelector((state) => state.loginReducer.data.token);
    const idFormularios = props?.formularios?.id;
    const [reFetch, setReFetch] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [isSeleccion, setIsSeleccion] = useState(false);
    const { tipo_preguntas } = useFetchTipoPregunta(token);
    const { catalogo_selecciones } = useFetchSeleccion(token);

    const permisos = useSelector(
        (state) => state.loginReducer.data.usuario.user.permissions
    );

    const permiso = "formulariospreguntas";

    let permisoAgregar = false;
    let addPermiso = `add_${permiso}`;

    permisos.forEach((permiso) => {
        if (addPermiso === permiso.codename) {
            permisoAgregar = true;
        }
    });

    const handleEliminar = (id) => {
        setIdFormulariosPreguntas(id);
        setEliminar(true);
    };

    const handleEditar = (gestionFormulariosPreguntas) => {
        setEdit(true);
        setFormulariosPreguntas(gestionFormulariosPreguntas);
    };

    const fetchFormulariosPreguntas = () => {
        getFormulariosPreguntasFormulario(idFormularios, token).then((formulariospreguntas) => {
            setDataTable(formulariospreguntas);
            setTableLoading(false);
            setReFetch(false);
        });
    };

    useEffect(fetchFormulariosPreguntas, [token, idFormularios, reFetch]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    formulario: props.formularios.id,
                    pregunta: isEdit ? formulariosPreguntas?.pregunta : "",
                    hint: isEdit ? formulariosPreguntas?.hint : "",
                    tipo_pregunta: isEdit ? formulariosPreguntas?.tipo_pregunta?.id : "",
                    catalogo_seleccion: isEdit ? formulariosPreguntas?.catalogo_seleccion?.id : "",
                    estado: isEdit ? formulariosPreguntas?.estado : true,
                    obligatorio: isEdit ? formulariosPreguntas?.obligatorio : false
                }}
                validationSchema={validaciones}
                onSubmit={async (values, { resetForm }) => {
                    if (isEdit) {
                        await putFormulariosPreguntas(formulariosPreguntas.id, values, token)
                            .then((data) => {
                                if (data?.message !== undefined) {
                                    toast.error(data.message);
                                } else {
                                    setReFetch(true);
                                    toast.success('Registro actualizado con éxito...!');
                                    values.pregunta = "";
                                    values.hint = "";
                                    values.tipo_pregunta = "";
                                    values.catalogo_seleccion = "";
                                    values.estado = true;
                                    values.obligatorio = false;
                                    setEdit(false);
                                }
                            })
                    } else {
                        await postFormulariosPreguntas(values, token).then((data) => {
                            if (data?.message !== undefined) {
                                toast.error(data.message)
                            } else {
                                setReFetch(true);
                                toast.success('Registro creado con éxito...!');
                                resetForm({});
                            }
                        });
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-full mx-auto grid grid-cols-12 gap-6 items-end">
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <div className="mt-1">
                                    <InputSiavi
                                        type="text"
                                        name="pregunta"
                                        id="pregunta"
                                        value={values.pregunta}
                                        onChange={handleChange}
                                        validacion={true}
                                        label="*Pregunta"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 mt-2">
                                <InputSiavi
                                    type="text"
                                    name="hint"
                                    id="hint"
                                    value={values.hint}
                                    onChange={handleChange}
                                    validacion={false}
                                    label="Ayuda de la Pregunta"
                                />
                            </div>
                            <div className="col-span-6 mt-5">
                                <Label text="Tipo Pregunta" />
                                <div className="mt-1">
                                    <select
                                        name="tipo_pregunta"
                                        value={values.tipo_pregunta}
                                        onChange={(e) => {
                                            setFieldValue("tipo_pregunta", e.target.value);
                                            tipo_preguntas.results.forEach(element => {
                                                if (element.id === Number(e.target.value)) {
                                                    setIsSeleccion(element?.seleccion_simple);
                                                }
                                            });
                                        }}
                                        className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                    >
                                        <option value="" disabled>
                                            Elegir Tipo Pregunta
                                        </option>
                                        {tipo_preguntas?.results?.map((item) => {
                                            return (
                                                <option key={item.nombre} value={item.id}>
                                                    {item.nombre}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            {isSeleccion && (
                                <div className="col-span-6 mt-5">
                                    <Label text="Lista de Selección Múltiple" />
                                    <div className="mt-1">
                                        <select
                                            name="catalogo_seleccion"
                                            value={values.catalogo_seleccion}
                                            onChange={handleChange}
                                            className="mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md"
                                        >
                                            <option value="" disabled>
                                                Elegir Selección Múltiple
                                            </option>
                                            {catalogo_selecciones?.results?.map((item) => {
                                                return (
                                                    <option key={item.nombre} value={item.id}>
                                                        {item.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>)}
                            {!isSeleccion && (
                                <div className="col-span-6 mt-5"></div>
                            )}
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text="Obligatorio" />
                                <Toggle enabled={values.obligatorio} name="obligatorio" />
                            </div>
                            <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 text-center mb-5">
                                <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                                <Toggle enabled={values.estado} name="estado" />
                            </div>
                            {permisoAgregar && (
                                <div className="col-span-12 xl:col-span-4 lg:col-span-4 sm:col-span-4 md:col-span-4 mb-5">
                                    <ButtonSiavi
                                        type="submit"
                                        disabled={isSubmitting}
                                        text={isEdit ? "Actualizar" : "Guardar"}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {isSubmitting && <Spinner />}
                    </form>
                )}
            </Formik>
            <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                <CustomTable
                    dataTable={dataTable}
                    tableColumns={tableColumns}
                    handleEliminar={handleEliminar}
                    handleEditar={handleEditar}
                    permiso={permiso}
                    showEliminar={true}
                    showVer={false}
                    showEditar={true}
                />
                {tableLoading && (
                    <div className="mt-10 mb-10">
                        <Spinner />
                    </div>
                )}
                {isEliminar && (
                    <ModalGenerico
                        title={`¿Esta seguro de eliminar el registro?`}
                        open={isEliminar}
                        closeModal={() => { }}
                        cerrar={() => {
                            setEliminar(false);
                        }}
                        icon={
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                            </div>
                        }
                        children={
                            <FormEliminar
                                aceptar={() => {
                                    setLoading(true);
                                    deleteFormulariosPreguntas(idFormulariosPreguntas, token).then((data) => {
                                        if (data?.message !== undefined) {
                                            setLoading(false);
                                            toast.error(data.message)
                                            setEliminar(false);
                                        } else {
                                            setEliminar(false);
                                            setReFetch(true);
                                            setLoading(false);
                                            toast.success('Registro eliminado con éxito...!')
                                        }
                                    });
                                }}
                                isloading={isloading}
                                cancelar={() => {
                                    setEliminar(false);
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
