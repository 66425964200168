import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Editar } from "./editar";
import { putSedes } from "../services";
import { useParams } from "react-router-dom";
import { getSede } from "../services";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';

// Validation with Yup
const validaciones = Yup.object().shape({
  codigo: Yup.string().max(10,'máximo 10 caracteres'),
  sede: Yup.string()
      .required("Sede es requerida")
      .max(100, "máximo 100 caracteres"),
  direccion: Yup.string().required("direccion es requerida")
      .max(200, "máximo 200 caracteres"),
  telefono: Yup.string().max(20,'Maximo 20 caracteres'),
  descripcion: Yup.string().required('descripcion es requerida')
      .max(100,"máximo 100 caracteres"),
  municipio: Yup.string()
      .required("municipio es requerido"),
  departamento: Yup.string()
      .required("departamento es requerido"),
  tipo_sede: Yup.string()
      .required("sede es requerida")
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

export const FormEditar = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { id } = useParams();
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [sede, setSedes] = useState({});
  const [loading, setLoanding] = useState(true);

  useEffect(() => {
    const datos = async () => {
      await getSede(id,token).then((data) => {
        setSedes(data);
        setLoanding(false);
      });
    };
    datos();
  }, [id,token]);

  return loading ? (
    <div></div>
  ) : (
    <Formik
      initialValues={{
        id: id,
        sede: sede?.sede || "",
        direccion: sede?.direccion || "",
        departamento: sede?.departamento || "",
        municipio: sede?.municipio || "",
        telefono: sede?.telefono || "",
        codigo: sede?.codigo || "",
        descripcion: sede?.descripcion || "",
        hacen_turnos: sede?.hacen_turnos || false,
        tipo_sede: sede?.tipo_sede || "",
        estado: sede?.estado || true,
        cobertura: sede?.cobertura || [],
        es_sede_seguimiento: sede?.es_sede_seguimiento || false,
        sede_seguimiento: sede?.sede_seguimiento || ""
      }}
      validationSchema={validaciones}
      onSubmit={async (values) => {
        await putSedes(id,values,token).then((data) => {
          if (data?.status === 200) {
            setSuccess(true);
            setError(false); 
            setMensaje('Registro actualizado con éxito...!');
            toast.success('Registro actualizado con éxito...!');
          } else {
            setError(true);
            setSuccess(false);
          }
        }).catch(function (error) {
          if (error.response.status === 400 || error.response.status === 401) {
            setError(true);
            setSuccess(false);
            setMensaje(error.response.data.message);
            toast.error(error.response.data.message);
          } else if (error.response.status >= 500) {
            setError(true);
            setSuccess(false);
            setMensaje(error.response.data.message);
            toast.error("Error interno comuniquese con el administrador")
          }
        });
      }}
    >
      {(props) => (
        <Editar
          {...props}
          customSwitchChange={customSwitchChange}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
        />
      )}
    </Formik>
  );
};
