import { React, useState, useEffect } from "react";
import { InputSiavi } from "../../../../../components/common/InputText";
import { getPersonas } from "./services";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { TrashIcon } from "@heroicons/react/solid";
import { ErrorMessage } from "formik";
import { DatosDemograficos } from "./datosDemograficos";
import { DatosGenerales } from "./datosGenerales";
import { DatosDomicilio } from "../victima/datosDomicilio";
import { AgregarConsentimiento } from "./consentimiento";
import { Identificacion } from "./indentificacion";
import { Discapacidades } from "./discapacidades";
import { Atenciones } from "../querellantes/atenciones";
import { AgregarServicios } from "./servicios";
import {
  UserAddIcon,
  PencilAltIcon,
  SearchIcon,
  ClipboardCheckIcon,
  XCircleIcon,
  FolderAddIcon,
  RefreshIcon,
  DocumentAddIcon
} from "@heroicons/react/outline";
import {
  getPersonasCaso,
  getParentesco,
  postCasoPersonaVictima,
  putVictimaQuerellante
} from "../services";
import {
  deletePersonaCaso,
} from "./services";
import { clases } from "../../../../../helpers/clases";
import { FormReferencia } from "./referencia";
import { CambiarEstado } from "./cambiar_estado";
import Spinner from "../../../../../components/common/Spinner";
import Alert from "../../../../../components/common/Alert";
import Divider from "../../../../../components/common/Divider";
import Label from "../../../../../components/common/Label";
//import Toggle from "../../../../../components/common/Toggle";
import ButtonAdd from "../../../../../components/common/ButtonAdd";
import ModalGenerico from "../../../../../components/common/Modal";
import ModalTwo from "../../../../../components/common/ModalTwo";
import BotonesConfirmar from "../../../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import FormEliminar from "../../../../../components/common/FormEliminar";
import moment from "moment";

const tdClass = "px-3 py-1 row-td text-maivi-primaryFont text-xs";
const thClass = "px-3 py-1 text-left font-medium text-maivi-primaryFont text-xs";

const useFethParentesco = (token) => {
  const [state, setState] = useState({
    parentescos: [],
  });

  useEffect(() => {
    getParentesco(token).then((data) => {
      setState({
        parentescos: data,
      });
    });
  }, [token]);

  return state;
};

const opciones = [
  {
    value: "1",
    title: "Es víctima"
  },
  {
    value: "0",
    title: "Es víctima indirecta"
  }
]

export const BuscarPersona = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idCaso = props?.caso.id;
  const [hasVictima, setHasVictima] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { parentescos } = useFethParentesco(token);
  const [cargando, setCargando] = useState(false);
  const [cargandoGuardado, setCargandoGuardado] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(false);
  const [personaElegida, setPersonaElegida] = useState(0);
  const [errorGuardar, setErrorGuardar] = useState(false);
  const [nombre, setNombre] = useState("");
  const [nueva, setNueva] = useState(props.values?.nueva_persona);
  const [isQuerellante, setIsQuerellante] = useState(false);
  const [cancelarReemplazo, setCancelarReemplazo] = useState(false);
  const [victimaxcaso] = useState(true);
  const [servicios, setServicios] = useState(false);
  const [idPersona, setIdPersona] = useState(0);
  const [consentimiento, setConsentimiento] = useState(false);
  const [idCasoPersona, setIdCasoPersona] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [isEliminar, setEliminar] = useState(false);
  const [doctoPersona, setDoctoPersona] = useState("");
  const [agregarServicios, setAgregarServicios] = useState(false);
  const [convertirQuerellante, setConvertirQuerellante] = useState(false);
  const [casoPersona, setCasoPersona] = useState({});
  const [personasCaso, setPersonaCaso] = useState(false);
  const [registro, setRegistro] = useState({});
  const [addReferencia, setAddReferencia] = useState(false);
  const [cambiarEstado, setCambiarEstado] = useState(false);
  const configuracion = props?.configuracion;
  const expedientes = props?.caso.expedientes;


  const permisosRegistro = useSelector(state => state.ChangePermisos?.menus?.registros?.menus ? state.ChangePermisos?.menus?.registros?.menus : []);  //mm buscar el registro


  const permisoView = "Orientacion";  //mm permiso padre

  //mm procedimiento de validacion de permisos
  const CheckPermiso = (permiso) => {
    let retorno = false;
    permisosRegistro?.forEach(menu => {
      if (menu?.permiso == permisoView) {
        menu?.opciones?.forEach(op => {
          if (op.permiso === permiso) {
            retorno = op.ver;
          }
        })
      }
    });
    //  retorno = true;  // mm comentar para que funcione
    return retorno;

  }



  useEffect(() => {
    getPersonasCaso(idCaso, token).then((data) => {
      setPersonaCaso(data);
      data?.results?.forEach((element) => {
        if (element.victima === true) {
          setHasVictima(true);
        }
      });
    });
  }, [idCaso, token, refetch]);

  return nueva ? (
    <div className="grid grid-cols-12 gap-3 mt-2">
      <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
        <h1 className="mb-1 text-maivi-primaryFont text-sm">Identificación</h1>
        <Divider />
        <Identificacion
          values={props.values}
          handleChange={props.handleChange}
          selectClass={props.selectClass}
          setFieldValue={props.setFieldValue}
          resetForm={props.resetForm}
        />
      </div>
      <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
        <h1 className="mb-1 text-maivi-primaryFont text-sm">Datos generales</h1>
        <Divider />
        <DatosGenerales
          values={props.values}
          handleChange={props.handleChange}
          selectClass={props.selectClass}
          setFieldValue={props.setFieldValue}
        />
      </div>
      <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
        <h1 className="mb-1 text-maivi-primaryFont text-sm">
          Datos demograficos
        </h1>
        <Divider />
        <DatosDemograficos
          values={props.values}
          handleChange={props.handleChange}
          selectClass={props.selectClass}
          setFieldValue={props.setFieldValue}
          configuracion={configuracion}
        />
      </div>
      {
        props.values.editarPersona &&
        <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 border p-5 rounded-md shadow-sm hover:shadow-md ">
          <h1 className="mb-1 text-base">Direcciones / Teléfonos</h1>
          <Divider />
          <DatosDomicilio
            persona={props?.values?.infoPersona}
          />
        </div>
      }
      <Discapacidades
        values={props.values}
        setFieldValue={props.setFieldValue}
      />
      <Atenciones
        values={props.values}
        setFieldValue={props.setFieldValue}
      />
      <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
        <div className="flex flex-row justify-between">
          <h1 className="mb-1 text-maivi-primaryFont text-sm">{`Agregar persona ${nombre}`}</h1>
          {/*           <h1 className="mb-1 text-maivi-primaryFont text-sm">{`Caso: ${props?.caso?.numero_caso}`}</h1> */}
        </div>
        <Divider />
        <div className="grid grid-cols-12 gap-3 items-end justify-end">
          {/* {(props.values.nueva_persona) ? '' :
            <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6 flex flex-col items-center justify-center">
              <Label text="Estatus persona" />
              <Toggle
                enabled={props.values.status_caso_persona}
                name="status_caso_persona"
              />
            </div>
          } */}
          <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6 flex flex-row items-center justify-center">
            {opciones.map((item, index) => (
              <div key={item.id + index} className="flex m-1 ml-5">
                <input
                  name="es_victima"
                  type="radio"
                  onChange={props.handleChange}
                  value={item.value}
                  checked={props.values.es_victima === item.value ? true : false}
                  className="bg-maivi-primaryLightBlue h-7 w-7  border-gray-300"
                />
                <label className="ml-1 block text-sm font-medium text-gray-700">
                  {item.title}
                </label>
              </div>
            ))}
          </div>
          {
            (props.values.es_victima === "0") &&

            <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-6">
              <Label text="Relación con la víctima" />
              <select
                name="relacion"
                value={props.values.relacion}
                onChange={props.handleChange}
                className={props.selectClass}
              >
                <option value="">Relación con víctima</option>
                {parentescos?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
              <ErrorMessage
                name="relacion"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
          }
          <div className="col-span-12 xl:col-span-3 lg:col-span-2 md:col-span-6">
            {props.isSubmitting ? (
              <Spinner />
            ) : (
              victimaxcaso &&
              <ButtonSiavi
                type="button"
                text={(props.values.editarPersona) ? 'Actualizar' : 'Guardar'}
                onClick={async () => {
                  if (props.values.editarPersona) {
                    let querellantes = 0;
                    personasCaso?.results.forEach(element => {
                      if (element.querellante === true) {
                        querellantes++;
                      }
                    });

                    if (querellantes === 1 && props.values.querellante === false) {
                      querellantes = 0;

                      personasCaso?.results?.forEach(element => {
                        if (element.persona.id === props.values.persona && element.querellante === false) {
                          querellantes++;
                        }
                      });

                      if (querellantes <= 0) {
                        toast.error('El caso debe tener al menos 1 querellante, por favor agregue uno nuevo antes de deshabilitar el actual.');
                      } else {
                        await props.handleSubmit();
                      }
                    } else {
                      if (querellantes > 1 && props.values.querellante) {
                        setIsQuerellante(true);
                      } else {
                        setRefetch(true);

                        await props.handleSubmit();
                      }
                    }
                  } else {
                    await props.handleSubmit();
                  }
                }}
              />
            )}
          </div>
          <div className="col-span-6 xl:col-span-3 lg:col-span-4 md:col-span-4 ">
            <ButtonSiavi
              type="button"
              text="Regresar"
              onClick={() => {
                setNueva(false);
              }}
            />
          </div>
          <div className="col-span-12">
            {errorGuardar && <Alert type="error" mensaje={mensaje} />}
          </div>
          {isQuerellante && (
            <ModalGenerico
              title={`¿Ya existe al menos un querellante, desea reemplazarlo?`}
              open={isQuerellante}
              closeModal={() => { }}
              cerrar={() => {
                setIsQuerellante(false);
              }}
              children={
                <div>
                  <BotonesConfirmar
                    cancelar={async () => {
                      setCancelarReemplazo(true);
                    }}
                    aceptar={async () => {
                      props.setFieldValue('remplazar_querellante', true);
                      setRefetch(true);
                      setRefetch(false);
                      await props.handleSubmit();
                      setRefetch(false);
                      setIsQuerellante(false);
                    }}
                  />
                </div>
              }
            />
          )}

          {cancelarReemplazo && (
            <ModalGenerico
              title={`¿Este caso tendrá dos querellantes, esta seguro que quiere continuar?`}
              open={cancelarReemplazo}
              closeModal={() => { }}
              cerrar={() => {
                setCancelarReemplazo(false);
              }}
              children={
                <div>
                  <BotonesConfirmar
                    cancelar={async () => {
                      setCancelarReemplazo(false);
                      setIsQuerellante(false);
                    }}
                    aceptar={async () => {
                      props.setFieldValue('remplazar_querellante', false);
                      setRefetch(true);
                      setRefetch(false);
                      await props.handleSubmit();
                      setCancelarReemplazo(false);
                      setIsQuerellante(false);
                      setRefetch(false);
                    }}
                  />
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-12 gap-3 mt-2">
      <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md">
        <div className="flex flex-row justify-between">
          <h1 className="mb-1 text-maivi-primaryFont text-sm">
            Nombre de la víctima a buscar:
          </h1>
          {(CheckPermiso('OVVictimaNueva') === true) &&    //mm permiso agregado
            <ButtonAdd
              className="-mt-2 mb-0.5"
              type="button"
              onClick={() => {
                setNueva(true);
                props.resetForm({});
                props.setFieldValue('editarPersona', false)
                props.setFieldValue("nueva_persona", true);
              }}
              icon={<UserAddIcon className=" -mr-1 h-5" />}
            />
          }
        </div>
        <Divider />
        <div className="grid grid-cols-12 items-end justify-end gap-2 -mt-3">
          <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-6">
            <InputSiavi
              type="text"
              name="busqueda"
              value={props.values.busqueda}
              onChange={props.handleChange}
              placeholder="Nombre de la víctima a buscar"
              className="uppercase"
            />
          </div>
          <div className="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-3">
            <ButtonSiavi
              type="button"
              text="Buscar"
              onClick={async () => {
                if (props.values.busqueda.length > 2) {
                  setCargando(true);
                  await getPersonas(props.values.busqueda, token).then(
                    (data) => {
                      setPersonas(data.results);
                      if (data.results.length === 0) {
                        setError(true);
                        setMensaje("No se encontró ningun registro");
                      }
                    }
                  );
                  setCargando(false);
                } else {
                  if (props.values.busqueda === "") {
                    setError(true);
                    setMensaje("El campo busqueda no puede estar vacio");
                  } else {
                    setError(true);
                    setMensaje(
                      "Debe ingresar una busqueda con un minimo de 3 caracteres"
                    );
                  }
                }

                setTimeout(() => {
                  setError(false);
                }, 5000);
              }}
            />
          </div>
          <div className="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-3">
            <ButtonSiavi
              type="button"
              text="Limpiar"
              onClick={async (e) => {
                props.resetForm({});
                setPersonas({});
              }}
            />
          </div>
        </div>

        <div className="col-span-12 mb-5 overflow-x-auto mt-3 max-h-40">
          {cargando && (
            <div className="m-2">
              <Spinner />
            </div>
          )}
          {personas?.length > 0 ? (
            <table className="table w-full overflow-x-auto ">
              <thead className="bg-maivi-gray-5">
                <tr>
                  <th scope="col" className={thClass}>
                    Nombre
                  </th>
                  <th scope="col" className={thClass}>
                    Fecha nac.
                  </th>
                  <th scope="col" className={thClass}>
                    Identificación
                  </th>
                </tr>
              </thead>
              <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
                {personas?.map((item) => (
                  <tr
                    id={item.id}
                    key={item.id}
                    className="cursor-pointer"
                    onClick={() => {
                      let row;
                      personas.forEach((element) => {
                        let row = document.getElementById(element.id);
                        row.classList.remove("bg-green-50");
                      });
                      row = document.getElementById(item.id);
                      row.classList.add("bg-green-50");
                      setPersonaElegida(item.id);
                      props.setFieldValue('persona', item.id);
                      setNombre(`${item.primer_nombre} ${item.primer_apellido}`);
                    }}
                  >
                    <td className="px-2 py-1 row-td">
                      <div className="text-xs text-maivi-primaryFont">
                        {item.primer_nombre} {item.segundo_nombre}{" "}
                        {item.tercer_nombre} {item.primer_apellido}{" "}
                        {item.segundo_apellido}
                      </div>
                    </td>
                    <td className="px-2 row-td">
                      <div className="text-xs text-maivi-primaryFont">
                        {item.fecha_nacimiento}
                      </div>
                    </td>
                    <td className="px-2 row-td">
                      <div className="text-xs text-maivi-primaryFont">
                        {item.no_documento}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            ""
          )}
          {error && <Alert type="error" mensaje={mensaje} />}
        </div>
      </div>
      <div className="col-span-12 xl:col-span-6 border p-3 rounded-md shadow-sm hover:shadow-md ">
        <div className="flex flex-row justify-between">
          <h1 className="mb-1 text-maivi-primaryFont text-sm">{`Agregar persona ${nombre}`}</h1>
          {/* <h1 className="mb-1 text-maivi-primaryFont text-sm">{`Caso: ${props?.caso?.numero_caso}`}</h1> */}
        </div>
        <Divider />
        <div className="grid grid-cols-12 gap-3 items-end justify-end">
          <div className="col-span-12 xl:col-span-5 lg:col-span-6 md:col-span-6 flex flex-row items-center justify-center">
            {opciones.map((item) => (
              <div key={item.id} className="flex m-1 ml-5">
                <input
                  name="es_victima"
                  type="radio"
                  onChange={props.handleChange}
                  value={item.value}
                  checked={props.values.es_victima === item.value ? true : false}
                  className="bg-maivi-primaryLightBlue h-7 w-7  border-gray-300"
                />
                <label className="ml-1 block text-sm font-medium text-gray-700">
                  {item.title}
                </label>
              </div>
            ))}
          </div>
          {
            (props.values.es_victima === "0") &&
            <div className="col-span-12 xl:col-span-4 lg:col-span-6 md:col-span-6">
              <Label text="Relación con la víctima" />
              <select
                name="relacion"
                value={props.values.relacion}
                onChange={props.handleChange}
                className={props.selectClass}
              >
                <option value="">Relación con víctima</option>
                {parentescos?.results?.map((item, index) => {
                  return (
                    <option key={item.nombre + index} value={item.id}>
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
              <ErrorMessage
                name="relacion"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
          }
           {(CheckPermiso('OVAgregarVictima') === true) &&    //mm permiso agregado
               
          <div className="col-span-12 xl:col-span-3 lg:col-span-6 md:col-span-6">
            {
              (cargandoGuardado) ?
                <Spinner /> :
                <ButtonSiavi
                  type="button"
                  text="Agregar"
                  onClick={async () => {
                    if (personaElegida === 0) {
                      setErrorGuardar(true);
                      setMensaje("Debe seleccionar una persona");
                      setTimeout(() => {
                        setErrorGuardar(false);
                      }, 5000);
                    } else {
                      if (props.values.relacion === "" && props.values.es_victima === "0") {
                        setErrorGuardar(true);
                        setMensaje("la relacion es requerida");
                        setTimeout(() => {
                          setErrorGuardar(false);
                        }, 5000);
                      } else {
                        setRefetch(true);
                        setCargandoGuardado(true);
                        props.values.persona = personaElegida;
                        await postCasoPersonaVictima(props.values, idCaso, token).then((data) => {
                          if (data?.status === 200 || data?.status === 201) {
                            toast.success("Persona agregada con éxito..!");
                            setCargandoGuardado(false);
                            setRefetch(false);
                            props.resetForm({});
                            setPersonas({});
                          } else {
                            toast.success("Persona agregada con éxito..!");
                          }
                        }).catch(function (error) {
                          if (
                            error.response.status >= 400 &&
                            error.response.status <= 450
                          ) {
                            toast.error(error.response.data.message);
                            setCargandoGuardado(false);
                          } else if (error.response.status >= 500) {
                            setCargandoGuardado(false);
                            toast.error("Error interno comuniquese con el administrador");
                          }
                        });
                      }
                    }
                  }}

                />
            }
            
          </div>
}
          <div className="col-span-12">
            {errorGuardar && <Alert type="error" mensaje={mensaje} />}
          </div>
        </div>
      </div>
      <div className="col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
        <h1 className="text-maivi-primaryFont text-sm">
          Personas agregadas
        </h1>
        {hasVictima === false ? (
          <div className="mt-1">
            <Alert
              type="warning"
              mensaje="Este caso no tiene una víctima asociada"
            />
          </div>
        ) : (
          ""
        )}
        <div className="">
          <table className="table w-full border overflow-x-auto ">
            <thead className="bg-maivi-gray-5 rounded-md">
              <tr>
                <th className={thClass}>Nombre</th>
                <th className={thClass}>Identificación</th>
                <th className={thClass}>Fecha nacimiento</th>
                <th className={thClass}>Relación con víctima</th>
                <th className={thClass}>Es víctima</th>
                <th className={thClass}>Es víctima indirecta</th>
                <th className={thClass}>Acciones</th>
              </tr>
            </thead>
            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10 ">
              {personasCaso?.results?.filter(x => (x.victima === true || x.es_victima_indirecta === true) && (x.estado === true)).map((item, index) => (
                <tr
                  key={`per-${index}`}
                  className={`hover:bg-maivi-gray-5 cursor-pointer ${item.victima ? "bg-blue-50" : ""}`}
                >
                  <td className={tdClass}>
                    {item?.persona?.primer_nombre} {item?.persona?.segundo_nombre}{" "}
                    {item?.persona?.primer_apellido}{" "}
                    {item?.persona?.segundo_apellido}
                  </td>
                  <td className={tdClass}>{item?.persona?.no_documento}</td>
                  <td className={tdClass}>{(item?.persona?.fecha_nacimiento === null) ? '' : moment(item?.persona?.fecha_nacimiento).format("DD-MM-YYYY")}</td>
                  <td className={tdClass}>{item?.parentesco?.nombre}</td>
                  <td className={tdClass}>
                    {item?.victima ? (
                      <label
                        className={`${item.victima
                          ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                          : ""
                          }`}
                      >
                        Si
                      </label>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className={tdClass}>
                    {item?.es_victima_indirecta ? (
                      <label
                        className={`${item.es_victima_indirecta
                          ? "px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800"
                          : ""
                          }`}
                      >
                        Si
                      </label>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className={`${tdClass} flex flex-row`}>
                    {(CheckPermiso('OVEditar') === true) &&    //mm permiso agregado
                      <button
                        type="button"
                        onClick={() => {
                          props.setFieldValue("infoPersona", item?.persona);
                          props.setFieldValue("no_documento", item?.persona?.no_documento);
                          props.setFieldValue("tipo_documento", item?.persona?.tipo_documento);
                          props.setFieldValue("pais", item?.persona?.pais);

                          props.setFieldValue("primer_nombre", item?.persona?.primer_nombre);
                          props.setFieldValue("segundo_nombre", item?.persona?.segundo_nombre);
                          props.setFieldValue("otros_nombres", item?.persona?.otros_nombres);
                          props.setFieldValue("primer_apellido", item?.persona?.primer_apellido);
                          props.setFieldValue("segundo_apellido", item?.persona?.segundo_apellido);
                          props.setFieldValue("apellido_casada", item?.persona?.apellido_casada);
                          props.setFieldValue("fecha_nacimiento", item?.persona?.fecha_nacimiento);
                          props.setFieldValue("edad", item?.persona?.edad);
                          props.setFieldValue("nacionalidad", item?.persona?.nacionalidad);
                          props.setFieldValue("pseudonimo", item?.persona?.como_le_gusta_que_le_llamen);
                          props.setFieldValue("victima_fallecida", item?.persona?.victima_fallecida);

                          props.setFieldValue("sexo", item?.persona?.sexo);
                          props.setFieldValue("estado_civil", item?.persona?.estado_civil);
                          props.setFieldValue("orientacion_sexual", item?.persona?.orientacion_sexual);
                          props.setFieldValue("identidad_genero", item?.persona?.identidad_genero);
                          props.setFieldValue("departamento", item?.persona?.departamento);
                          props.setFieldValue("municipio", item?.persona?.municipio);
                          props.setFieldValue("lee_escribe", item?.persona?.lee_escribe);
                          props.setFieldValue("escolaridad", item?.persona?.escolaridad);
                          props.setFieldValue("profesion", item?.persona?.profesion);
                          props.setFieldValue("situacion_laboral", item?.persona?.situacion_laboral);
                          props.setFieldValue("origen_etnico", item?.persona?.origen_etnico?.id);
                          props.setFieldValue("idioma", item?.persona?.idioma);
                          props.setFieldValue("idioma_maya", item?.persona?.idioma_maya);
                          props.setFieldValue("origen_etnico_maya", item?.persona?.origen_etnico_maya);
                          props.setFieldValue("idioma_preferencia", item?.persona?.idioma_preferencia);
                          props.setFieldValue("religion", item?.persona?.religion);
                          props.setFieldValue("relacion_con_la_victima", item?.persona?.relacion_con_victimario);
                          props.setFieldValue("personaExiste", true);
                          props.setFieldValue("persona", item?.persona?.id);
                          props.setFieldValue("casoPersona", item?.id);
                          props.setFieldValue("correo_electronico", item?.persona?.correo_electronico);

                          props.setFieldValue("querellante", item?.querellante);
                          props.setFieldValue("status_caso_persona", item?.status_caso_persona);
                          props.setFieldValue("es_victima_directa", item?.es_victima_indirecta);
                          props.setFieldValue("relacion", item?.parentesco?.id);
                          props.setFieldValue("tipo_persona", item?.tipo_persona?.id);
                          props.setFieldValue("esVictima", item?.victima);
                          props.setFieldValue("victima", item?.victima);
                          props.setFieldValue("editarPersona", true);
                          props.setFieldValue("discapacidades", item?.persona?.discapacidades || []);
                          props.setFieldValue("atenciones", item?.persona?.atencion_diferenciada || []);
                          props.setFieldValue("idPersona", item?.persona?.id);
                          props.setFieldValue("es_victima", (item?.victima) ? "1" : "0");
                          props.setFieldValue("sin_documento", item?.persona?.sin_documento);
                          setNueva(true);
                        }}
                        className={clases.addIcon}>
                        <PencilAltIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">Editar</span>
                      </button>
                    }
                    {(CheckPermiso('OVConvertirQuerellante') === true) &&    //mm permiso agregado
                      <button
                        type="button"
                        onClick={() => {
                          if (item?.persona?.victima_fallecida) {
                            toast.error('No se puede convertir a querellante una víctima fallecida')
                          } else {
                            setConvertirQuerellante(true);
                            setCasoPersona(item);
                          }
                        }}
                        className={clases.addIcon}>
                        <RefreshIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">Agregar como querellante</span>
                      </button>
                    }
                    <button
                      type="button"
                      onClick={() => {
                        setIdPersona(item?.persona?.id)
                        setServicios(true);
                      }}
                      className={clases.addIcon}>
                      <SearchIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Ver procesos y servicios</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIdCasoPersona(item.id);
                        setConsentimiento(true);
                      }}
                      className={clases.addIcon}>
                      <ClipboardCheckIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                      <span className="tooltiptext">Consentimiento informado</span>
                    </button>
                    {(CheckPermiso('OVServicioslegales') === true) &&    //mm permiso agregado
                      <button
                        type="button"
                        onClick={() => {
                          setRegistro(item);
                          setAgregarServicios(true);
                        }}
                        className={clases.addIcon}>
                        <FolderAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">Agregar servicios legales</span>
                      </button>
                    }
                    {(CheckPermiso('OVRefInterna') === true) &&    //mm permiso agregado

                      <button
                        type="button"
                        onClick={() => {
                          setRegistro(item);
                          setAddReferencia(true);
                          console.log(item);
                        }}
                        className={clases.addIcon}>
                        <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        <span className="tooltiptext">Agregar referencia</span>
                      </button>
                    }
                    {(CheckPermiso('OVCambioEstado') === true) &&    //mm permiso agregado

                      <button
                        type="button"
                        className={clases.trashIcon}
                        onClick={() => {
                          setCambiarEstado(true);
                          setRegistro(item);
                        }}
                      >
                        <XCircleIcon className="h-6 w-6 text-maivi-secondaryRed hover:text-white" />
                        <span className="tooltiptext">Cambiar estado</span>
                      </button>
                    }
                    {(CheckPermiso('OVEliminar') === true) &&    //mm permiso agregado

                      <button
                        type="button"
                        onClick={async () => {
                          setIdPersona(item.id);
                          setDoctoPersona(item?.persona?.no_documento);
                          setEliminar(true);
                        }}
                        className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent rounded-md 
                    text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue tooltip`}>
                        <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                        <span className="tooltiptext">Eliminar</span>
                      </button>
                    }
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        {isEliminar && (
          <ModalGenerico
            title={`¿Esta seguro de eliminar el registro con No. Identificación ${doctoPersona}?`}
            open={isEliminar}
            closeModal={() => { }}
            cerrar={() => {
              setEliminar(false);
            }}
            icon={
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
              </div>
            }
            children={
              <FormEliminar
                aceptar={async () => {
                  setLoading(true);
                  await deletePersonaCaso(idPersona, token)
                    .then((data) => {
                      if (data?.status === 200 || data?.status === 201) {
                        toast.success("Registro eliminado con éxito...!");
                        setRefetch(true);
                        setLoading(false);
                        setRefetch(false);
                        setEliminar(false);
                      } else {
                        setRefetch(true);
                        setLoading(false);
                        setRefetch(false);
                        setEliminar(false);
                        toast.success("Registro eliminado con éxito...!");
                      }
                    })
                    .catch(function (error) {
                      if (
                        error.response.status === 400 ||
                        error.response.status === 401
                      ) {
                        toast.error(error.response.data.message);
                        setLoading(false);
                      } else if (error.response.status >= 500) {
                        setLoading(false);
                        toast.error(
                          "Error interno comuniquese con el administrador"
                        );
                      }
                    });
                }}
                isloading={isloading}
                cancelar={() => {
                  setEliminar(false);
                }}
              />
            }
          />
        )}

        {isQuerellante && (
          <ModalGenerico
            title={`¿Ya existe al menos un querellante, desea reemplazarlo?`}
            open={isQuerellante}
            closeModal={() => { }}
            cerrar={() => {
              setIsQuerellante(false);
            }}
            children={
              <div>
                <BotonesConfirmar
                  cancelar={async () => {
                    setCancelarReemplazo(true);
                  }}
                  aceptar={async () => {
                    props.setFieldValue('remplazar_querellante', true);
                    setRefetch(true);
                    setRefetch(false);
                    await props.handleSubmit();
                    setRefetch(false);
                    setIsQuerellante(false);
                  }}
                />
              </div>
            }
          />
        )}

        {agregarServicios && (
          <ModalGenerico
            title={`Agregar servicios`}
            open={agregarServicios}
            closeModal={() => { }}
            cerrar={() => {
              setAgregarServicios(false);
            }}
            children={
              <AgregarServicios
                orientacion={props?.caso?.orientacion}
                persona={registro}
                setAgregarServicios={setAgregarServicios}
              />
            }
          />
        )}

        {cancelarReemplazo && (
          <ModalGenerico
            title={`¿Este caso tendrá dos querellantes, esta seguro que quiere continuar?`}
            open={cancelarReemplazo}
            closeModal={() => { }}
            cerrar={() => {
              setCancelarReemplazo(false);
            }}
            children={
              <div>
                <BotonesConfirmar
                  cancelar={async () => {
                    setCancelarReemplazo(false);
                    setIsQuerellante(false);
                  }}
                  aceptar={async () => {
                    props.setFieldValue('remplazar_querellante', false);
                    setRefetch(true);
                    setRefetch(false);
                    await props.handleSubmit();
                    setCancelarReemplazo(false);
                    setIsQuerellante(false);
                    setRefetch(false);
                  }}
                />
              </div>
            }
          />
        )}

        {convertirQuerellante && (
          <ModalGenerico
            title={`¿Desea agregar esta persona tambien a querellantes?`}
            open={convertirQuerellante}
            closeModal={() => { }}
            cerrar={() => {
              setConvertirQuerellante(false);
            }}
            children={
              <div>
                <BotonesConfirmar
                  isloading={cargandoGuardado}
                  cancelar={async () => {
                    setConvertirQuerellante(false);
                  }}
                  aceptar={async () => {
                    setCargandoGuardado(true);
                    props.values.persona = casoPersona?.persona?.id;
                    props.values.status_caso_persona = casoPersona?.status_caso_persona;
                    props.values.relacion = casoPersona?.parentesco?.id;
                    props.values.es_victima = casoPersona?.victima;
                    props.values.querellante = true;
                    props.values.victima = casoPersona.victima;
                    props.values.es_victima_indirecta = casoPersona?.es_victima_indirecta;

                    await putVictimaQuerellante(props.values, idCaso, casoPersona.id, token).then((data) => {
                      if (data?.status === 200 || data?.status === 201) {
                        toast.success("Persona agregada con éxito..!");
                        setCargandoGuardado(false);
                        props.resetForm({});
                        setPersonas({});
                        setConvertirQuerellante(false);
                      }
                    }).catch(function (error) {
                      if (
                        error.response.status >= 400 &&
                        error.response.status <= 450
                      ) {
                        toast.error(error.response.data.message);
                        setCargandoGuardado(false);
                      } else if (error.response.status >= 500) {
                        setCargandoGuardado(false);
                        toast.error("Error interno comuniquese con el administrador");
                      }
                    });
                  }}
                />
              </div>
            }
          />
        )}

        {servicios && (
          <ModalGenerico
            title={`Servicios y tipos de procesos brindados`}
            open={servicios}
            closeModal={() => { }}
            cerrar={() => {
              setServicios(false);
            }}
            children={
              <div className="bg-white shadow-lg sm:rounded-lg mt-2">
                <div className="border-t border-gray-200 px-2 py-2">
                  {
                    // eslint-disable-next-line array-callback-return
                    expedientes?.map((item) => {
                      if (item?.caso_persona?.persona?.id === idPersona) {
                        return (
                          <div className="p-2 grid grid-cols-3">
                            <span className="text-sm font-medium text-gray-500">Expdediente</span>
                            <div className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {item?.numero_expediente}
                            </div>
                            <span className="text-sm font-medium text-gray-500">Servicio</span>
                            <div className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {item?.servicio?.nombre}
                            </div>
                            <span className="text-sm font-medium text-gray-500">Tipo proceso</span>
                            <div className=" text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {item?.tipo_proceso?.tipo_proceso}
                            </div>
                          </div>
                        )
                      }
                    })
                  }
                </div>
              </div>
            }
          />
        )}

        {consentimiento && (
          <ModalTwo
            title="Consentimiento informado"
            open={consentimiento}
            closeModal={() => { }}
            cerrar={() => {
              setConsentimiento(false);
            }}
            children={
              <AgregarConsentimiento
                delitos={props.delitos}
                thClass={thClass}
                tdClass={tdClass}
                idCasoPersona={idCasoPersona}
                personas={personasCaso?.results}
              />
            }
          />
        )}

        {
          addReferencia &&
          <ModalTwo
            title="Agregar referencia"
            open={true}
            closeModal={() => { }}
            children={
              <FormReferencia
                caso={props.caso}
                expediente={null}
                //servicio={1}
                setMostrarReferencia={setAddReferencia}
                persona={registro.persona.id}
              />
            }
            cerrar={() => {
              setAddReferencia(false);
            }}
          />
        }

        {cambiarEstado && (
          <ModalGenerico
            title={"¿Desea cambiar el estado de la víctima?"}
            open={cambiarEstado}
            closeModal={() => { }}
            cerrar={() => {
              setCambiarEstado(false);
            }}
            children={
              <CambiarEstado
                registro={registro}
                setCambiarEstado={setCambiarEstado}
                setReFetch={setRefetch}
              />
            }
          />
        )}
      </div>
    </div>

  );
};
