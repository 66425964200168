/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import { 
  //getAcciones, 
  postTipoSms, 
  putTipoSms 
} from "./services";
import { InputSiavi } from "../../../components/common/InputText";
import { SelectSiavi } from "../../../components/common/Select";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../components/common/Button";
import { TextAreaSiavi } from "../../../components/common/TextArea";
import { getServicios } from "./services";
import Label from "../../../components/common/Label";
import toast from 'react-hot-toast';
import Spinner from "../../../components/common/Spinner";
import Toggle from "../../../components/common/Toggle";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Nombre es requerido")
    .max(60, "Máximo 60 caracteres"),
});

export const Form = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const [servicios, setServicios] = useState([]);

  useEffect(() => {
    getServicios(token).then((data) => {
      setServicios(data);
    });
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          nombre: props.editar ? props.value.nombre : "",
          mensaje: props.editar ? props.value.mensaje : "",
          estado: props.editar ? props.value.estado : false,
          para_victima: props.editar ? props.value.para_victima : true,
          para_profesional: props.editar ? props.value.para_profesional : false,
          por_caso: props.editar ? props.value.por_caso : true,
          por_servicio: props.editar ? props.value.por_servicio : false,
          por_accion_seguimiento: false,
          referencia: props.editar ? props.value.referencia : null,
          editable: props.editar ? props.value.editable : false
        }}
        validationSchema={validaciones}
        onSubmit={async (values) => {
          if (props.editar) {
            await putTipoSms(props.value.id, values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro actualizado con éxito...!')
              }
            });
          } else {
            await postTipoSms(values, token).then((data) => {
              if (data?.message !== undefined) {
                toast.error(data.message)
              } else {
                props.setReFetch(true);
                toast.success('Registro creado con éxito...!')
              }
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 items-end">
              <div className="col-span-12">
                <InputSiavi
                  type="text"
                  name="nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  validacion={true}
                  label="Nombre*"
                />
              </div>
              <div className="col-span-6 border rounded-lg hover:shadow-md p-3">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12">
                    <Label text="Destinatario" />
                    <hr />
                  </div>
                  <div className="col-span-3">
                    <Label text={`Víctima`} />
                    <Toggle enabled={values.para_victima} name="para_victima" onChange={(e) => {
                      setFieldValue("para_profesional", values.para_victima);
                    }} />
                  </div>
                  <div className="col-span-3">
                    <Label text={`Profesional`} />
                    <Toggle enabled={values.para_profesional} name="para_profesional" onChange={() => {
                      setFieldValue("para_victima", values.para_profesional);
                    }} />
                  </div>
                </div>
              </div>

              <div className="col-span-6 border rounded-lg hover:shadow-md p-3">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12">
                    <Label text="Tipo mensaje" />
                    <hr />
                  </div>
                  <div className="col-span-3">
                    <Label text={`Caso`} />
                    <Toggle enabled={values.por_caso} name="por_caso" onChange={() => {
                      setFieldValue("por_servicio", values.por_caso);
                    }} />
                  </div>

                  <div className="col-span-3">
                    <Label text={`Servicio`} />
                    <Toggle enabled={values.por_servicio} name="por_servicio" onChange={() => {
                      setFieldValue("por_caso", values.por_servicio);
                    }} />
                  </div>
                  <div className="col-span-3">
                    <Label text={`${values.estado ? 'Activo' : 'Inactivo'}`} />
                    <Toggle enabled={values.estado} name="estado" onChange={(e) => { console.log(e); }} />
                  </div>
                  <div className="col-span-3">
                    <Label text={`${values.editable ? 'Editable' : 'No editable'}`} />
                    <Toggle enabled={values.editable} name="editable" onChange={() => { }} />
                  </div>
                </div>
              </div>

              {values.por_caso &&
                <div className="col-span-12 border rounded-lg hover:shadow-md p-3">
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                      <Label text="Parametros por caso" />
                      <hr />
                    </div>
                    <ul>
                      <li className="text-gray-700 text-sm">
                      {`{numero_caso}`}
                      </li>
                      <li className="text-gray-700 text-sm">
                      {`{nombre}`}
                      </li>
                    </ul>
                  </div>
                </div>
              }

              {values.por_servicio &&
               <div className="col-span-12 border rounded-lg hover:shadow-md p-3">
               <div className="grid grid-cols-12 gap-2">
                 <div className="col-span-12">
                   <Label text="Parametros Por Servicio" />
                   <hr />
                 </div>

                <div className="col-span-5">
                  <SelectSiavi
                    label="Servicio"
                    name="referencia"
                    value={values.referencia}
                    onChange={handleChange}
                    valueInit=""
                    selectInit="Elegir servicio"
                    list={servicios?.results?.map((item) => {
                      return (
                        <option key={item.nombre} value={item.id}>
                          {item.nombre}
                        </option>
                      );
                    })}
                  />
                </div>
                <div className="col-span-12">
                <ul>
                   <li className="text-gray-700 text-sm">
                   {`{numero_caso}`}
                   </li>
                   <li className="text-gray-700 text-sm">
                   {`{nombre}`}
                   </li>
                   <li className="text-gray-700 text-sm">
                   {`{profesional_asignado}`}
                   </li>
                   <li className="text-gray-700 text-sm">
                   {`{servicio}`}
                   </li>
                 </ul>
                </div>
               </div>
             </div>
              }
              <div className="col-span-12">
                <TextAreaSiavi
                  rows={6}
                  name="mensaje"
                  value={values.mensaje}
                  onChange={handleChange}
                  validacion={true}
                  label="Mensaje"
                />
              </div>
              <div className="col-span-3">
                <ButtonSiavi
                  type="submit"
                  disabled={isSubmitting}
                  text={props.editar ? "Actualizar" : "Guardar"}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
    </div>
  );
};
