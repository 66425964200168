/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { reabriCaso } from "../services";
import Toggle from "../../../../../components/common/Toggle";
import Spinner from "../../../../../components/common/Spinner";
import toast from "react-hot-toast";
import Label from "../../../../../components/common/Label";

export const Reabrir = (props) => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const usuario = useSelector((state) => state.loginReducer.data.usuario.user.id);
    console.log(props.caso)
    return (
        <Formik
            initialValues={{
                caso_cerrado: props?.caso?.caso_cerrado,
                usuario: usuario,
                id: props?.caso?.id
            }}
            onSubmit={async (values, { resetForm }) => {
                await reabriCaso(values, token)
                    .then(async (data) => {
                        if (data?.status >= 200 && data?.status <= 210) {
                            toast.success('registro actualizado con éxito');
                            resetForm({});
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status >= 400 && error.response.status <= 450) {
                            toast.error(error.response.data.message);
                        } else if (error.response.status >= 500) {
                            toast.error('Error interno comuniquese con el administrador');
                        }
                    });
            }}>
            {({ values, handleSubmit, isSubmitting }) => (
                <div className="grid grid-cols-12 gap-2 mb-10">
                    <div className="col-span-12 flex flex-col items-center justify-center">
                        <Label text="Estado" />
                        <Toggle
                            enabled={values.caso_cerrado}
                            name="caso_cerrado"
                        />
                    </div>
                    <div className="col-span-12 mt-3">
                        {
                            (isSubmitting) ? <Spinner /> :
                                <ButtonSiavi
                                    type="button"
                                    text="Guardar"
                                    onClick={handleSubmit}
                                />
                        }
                    </div>
                </div>
            )}
        </Formik>
    );
};
