import axios from "axios";

const {
    REACT_APP_API_URL
} = process.env;

export const getPaises = async (token) => {
    try {
        let urlFetch = `${REACT_APP_API_URL}/catalogos/pais/?page_size=400&estado=true`;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const validarCodigo = async (id,data,token) => {
    try {
        let body ={
            codigo_confirmacion: data.codigo,
        }
        let result = axios({
            method: 'PUT',
            url: `${REACT_APP_API_URL}/registro/gestiontelefono/${id}/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }); 
  
        return result;
  
    } catch (e) {
        console.log('error', e);
    }
  };