/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TextAreaSiavi } from "../../../components/common/TextArea";
import { ButtonSiavi } from "../../../components/common/Button";
import {
  getBandeja,
  bandeja,
  putBandeja,
  putDiligencia,
  getDiligencia,
  postBandejaUsuario
} from "./services";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import TableNotificaciones from "../../../components/common/TableNotificaciones";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";
import Filtros from "./filtros";
import { FilterIcon } from "@heroicons/react/outline";
import ButtonAdd from "../../../components/common/ButtonAdd";

const tableColumns = [
  { title: "id", value: "id", tipo: "text", ordenar: "id" },
  { title: "Tipo", value: "bandeja.titulo", tipo: "text" },
  { title: "Mensaje", value: "bandeja.mensaje", tipo: "text" },
  {
    title: "Enviado por ",
    value: null,
    values: [
      "bandeja.profesional_origen.first_name",
      "bandeja.profesional_origen.last_name"
    ],
    tipo: "text",
    ordenar: "bandeja__profesional_origen__username"
  },
  { title: "Fecha enviado", value: "bandeja.fecha_enviado", tipo: "datetime", ordenar: "bandeja__fecha_enviado" },
  {
    title: "Leído",
    value: "leido",
    tipo: "boolean",
    text: { true: "Sí", false: "No" },
    ordenar: "leido"
  },
];

export const TNotificaciones = () => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const profesional = useSelector((state) => state.loginReducer?.data?.usuario?.user?.id);
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [confirmarDiligencia, setConfirmarDiligencia] = useState(false);
  const [registro, setRegistro] = useState({});
  const [detalleMensaje, setDetalleMensaje] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargandoDiligencia, setCargandoDiligencia] = useState(false);
  const [diligencia, setDiligencia] = useState({});
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [responderProfesional, setResponderProfesional] = useState(false);
  const [order, setOrder] = useState("-id&-bandeja_fecha_enviado");
  const [reFetch, setReFetch] = useState(false);
  const [filters, setFilters] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const permiso = "bandejaprofesionaldestino";

  useEffect(() => {
    setTableLoading(true);
    bandeja(urlFetch,search, profesional, order, filters, token).then((data) => {
      setDataTable(data);
      setReFetch(false);
      setTableLoading(false);
    });
  }, [urlFetch, token, sede, profesional, filters, order, reFetch]);

  useEffect(() => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getBandeja(urlFetch, search, profesional, order, filters, token)
        .then((data) => {
          setDataTable(data);
          setReFetch(false);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  }, [urlFetch, token, search, profesional, filters, order, reFetch]);

  const registroSeleccionado = (item) => {
    if (item.diligencia !== null) {
      if (item.leido === true) {
        setCargandoDiligencia(true);
        setRegistro(item);
        setDetalleMensaje(true);
        setMostrarDetalle(true);
        getDiligencia(item.diligencia, token).then((data) => {
          setDiligencia(data);
          setCargandoDiligencia(false);
        });
      } else {
        setRegistro(item);
        setConfirmarDiligencia(true);
      }
    }

    else {
      setMostrarDetalle(false);
      if (item.leido === false) {
        item.leido = true;
        putBandeja(item, token);
      }
      setDetalleMensaje(true);
      setRegistro(item);
      if (item.bandeja_profesionales) {
        setResponderProfesional(true);
      } else {
        setResponderProfesional(false);
      }
    }
  };

  const [state, setState] = useState({
    mensaje: "",
  });

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const pages = [
    {
      name: "Mis registros",
      href: "/mis-registros",
      current: false,
    },
    { name: "Notificaciones", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
        <div>
          <ButtonAdd
            className="ml-2"
            text="Filtros"
            type="button"
            onClick={() => {
              setOpenFilters(true);
            }}
            icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
          />
        </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <TableNotificaciones
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={false}
            select={false}
            registroSeleccionado={registroSeleccionado}
            setOrder={setOrder}
            order={order}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>

      {detalleMensaje && (
        <ModalGenerico
          title={"Detalle de mensaje"}
          open={detalleMensaje}
          closeModal={() => { }}
          cerrar={() => {
            setDetalleMensaje(false);
          }}
          children={
            <div className="py-3">
              <p className="text-sm font-medium text-gray-900">Mensaje</p>
              <p className="line-clamp-2 text-sm text-gray-600">{`${registro.bandeja.mensaje} , `}
                <Link className="font-bold text-red-400" to={`/funcionalidades-generales/editar-caso/${registro?.bandeja?.caso?.id}`}>PUEDE VER EL EXPEDIENTE DANDO CLIC AQUÍ</Link></p>
              {
                (cargandoDiligencia) && <Spinner />
              }
              {
                (mostrarDetalle) ?
                  <div>
                    <p className="text-sm font-medium text-gray-900 mt-3">Expediente</p>
                    <div className="mb-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{diligencia?.expediente?.numero_expediente}</p>
                    </div>
                    <p className="text-sm font-medium text-gray-900">Comentarios</p>
                    <div className="mb-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{diligencia?.comentarios}</p>
                    </div>
                    <p className="text-sm font-medium text-gray-900">Tipo diligencia</p>
                    <div className="mb-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{diligencia?.tipo_diligencia?.nombre}</p>
                    </div>
                    <p className="text-sm font-medium text-gray-900">Usuario creacion</p>
                    <div className="mb-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{diligencia?.usuario_creacion?.first_name} {diligencia?.usuario_creacion?.last_name}</p>
                    </div>
                  </div>
                  : ''
              }
              {
                responderProfesional &&
                <div className="mt-4">
                  <TextAreaSiavi
                    label="Responder"
                    name="mensaje"
                    rows={3}
                    cols={2}
                    className="mb-2"
                    onChange={handleInputChange}
                  />
                  <ButtonSiavi
                    className="mb-2"
                    text="Enviar"
                    type="button"
                    onClick={async () => {
                      if (state.mensaje === "") {
                        toast.error("La respuesta es requerida");
                      } else {
                        setCargando(true);
                        await postBandejaUsuario(registro?.bandeja?.id, registro?.bandeja?.caso?.id, state.mensaje, profesional, token)
                          .then(async (data) => {
                            if (data?.status >= 200 && data?.status <= 210) {
                              registro.leido = true;
                              await putBandeja(registro, token);
                              toast.success('Respuesta enviada con éxito.');
                              setCargando(false);
                              setConfirmarDiligencia(false);
                              setDetalleMensaje(false);
                            }
                          })
                          .catch(function (error) {
                            if (error.response.status >= 400 && error.response.status <= 410) {
                              setCargando(false);
                              toast.error(error.response.data.message);
                            } else if (error.response.status >= 500) {
                              setCargando(false);
                              toast.error('Error interno comuniquese con el administrador');
                            }
                          });
                      }
                    }}
                  />
                  {
                    cargando && <Spinner />
                  }
                </div>
              }
            </div>
          }
        />
      )}
      {confirmarDiligencia && (
        <ModalGenerico
          title={"¿Confirmar de recibida la notificacion?"}
          open={confirmarDiligencia}
          closeModal={() => { }}
          cerrar={() => {
            setConfirmarDiligencia(false);
          }}
          children={
            <BotonesConfirmar
              aceptar={async () => {
                setCargando(true);
                await putDiligencia(registro.diligencia, token)
                  .then(async (data) => {
                    if (data?.status >= 200 && data?.status <= 210) {
                      registro.leido = true;
                      await putBandeja(registro, token);
                      toast.success('Notificacion confirmada.');
                      setCargando(false);
                      setConfirmarDiligencia(false);
                      setDetalleMensaje(true);
                    }
                  })
                  .catch(function (error) {
                    if (error.response.status >= 400 && error.response.status <= 410) {
                      setCargando(false);
                      toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                      setCargando(false);
                      toast.error('Error interno comuniquese con el administrador');
                    }
                  });
              }}
              cancelar={() => {
                setConfirmarDiligencia(false);
              }}
              isloading={cargando}
            />
          }
        />
      )}
      {openFilters && (
        <ModalGenerico
          title={`Aplicar filtros`}
          open={openFilters}
          closeModal={() => { }}
          cerrar={() => {
            setOpenFilters(false);
          }}
          children={
            <Filtros
              setFilters={setFilters}
              setOpenFilters={setOpenFilters}
              token={token}
            />
          }
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <FilterIcon className="h-6 w-6 text-green-600" />
            </div>
          }
        />
      )}
    </div>
  );
};
