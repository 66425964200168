import { React, useEffect, useState } from "react";
import { getExpedientes,postAsignaciones,expedientes } from "./services";
import { store } from "../../../app/redux/store/store";
import { ChangeSearch } from "../../../app/redux/actions/search";
import { ViewGridAddIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalGenerico from "../../../components/common/Modal";
import Spinner from "../../../components/common/Spinner";
import BreadCrumb from "../../../components/common/BreadCrumb";
import CustomTable from "../../../components/common/Table";
import ButtonAdd from "../../../components/common/ButtonAdd";
import BotonesConfirmar from "../../../components/common/ButtonsConfirmacion";
import toast from "react-hot-toast";

const tableColumns = [
  
  { title: "Expediente", value: "numero_expediente", tipo: "text" },
  { title: "Fecha caso", value: "fecha", tipo: "date" },
  { title: "Ultima asignación", value: "fecha_asignacion", tipo: "date"},
  {
    title: "Recibido",
    value: "aceptado",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
    ordenar: "aceptado"
  },
  {   
    title: "Cerrado",
    value: "expediente_cerrado",
    tipo: "boolean",
    text: { true: "Si", false: "No" },
    ordenar: "expediente_cerrado"
  },
  //{ title: "Identificación", value: "caso_persona.persona.no_documento", tipo: "text" },
  { 
    title: "Nombre", 
    value: null, 
    values:[
      "caso_persona.persona.primer_nombre", 
      "caso_persona.persona.segundo_nombre", 
      "caso_persona.persona.primer_apellido", 
      "caso_persona.persona.segundo_apellido"
    ], 
    tipo: "text" 
  },
  { title: "Sede", value: "sede_expediente.sede", tipo: "text" },
];

export const TableExpedientes = () => {
  const [urlFetch, setUrlFech] = useState(undefined);
  const [dataTable, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [agregar, setAgregar] = useState(false);
  const token = useSelector((state) => state.loginReducer.data.token);
  const search = useSelector((state) => state.inputChange.search);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const profesional = useSelector((state) => state.loginReducer?.data?.usuario?.user?.id);
  const [registros,setRegistros] = useState([]);
  const [cargando,setCargando] = useState(false);
  const [order, setOrder] = useState("");
  let history = useHistory();
  const permiso = "expediente";

  const fetchExpedientes = () => {
    setTableLoading(true);
    expedientes(urlFetch,sede,profesional, token).then((data) => {
      setDataTable(data);
      setTableLoading(false);
    });
  };

  const fetchGetExpedientes = () => {
    if (
      (search.fetch && search.value.length >= search.min) ||
      (!search.fetch && search.clean)
    ) {
      setTableLoading(true);
      getExpedientes(urlFetch, search, sede,profesional,order, token)
        .then((data) => {
          setDataTable(data);
          setTableLoading(false);
        })
        .then(() => {
          let value = {
            value: search.value,
            fetch: false,
            clean: false,
          };
          store.dispatch(ChangeSearch({ ...search, ...value }));
        });
    }
  };

  useEffect(fetchExpedientes, [urlFetch, token, sede, profesional]);
  useEffect(fetchGetExpedientes, [urlFetch, search, sede, profesional,order,token]);

  const registrosSeleccionados = (items) => {
    setRegistros(items);
  };

  const handleVer = (item) => {
    history.push(`/funcionalidades-generales/editar-caso/${item.caso}`);
  };

  const pages = [
    {
      name: "Mis registros",
      href: "/mis-registros",
      current: false,
    },
    { name: "Mis expedientes", href: "#", current: true },
  ];

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row">
        <BreadCrumb navegacion={pages} />
       <div>
       <ButtonAdd
          className="ml-2"
          text="Recibir asignaciones"
          type="button"
          onClick={() => {
            if(registros.length === 0){
              toast.error('Debe de seleccionar al menos un expediente.')
            } else{
              setAgregar(true);
            }
          }}
          icon={<ViewGridAddIcon className="ml-3 -mr-1 h-5" />}
        />
       </div>
      </div>
      <div className="py-2 inline-block w-full">
        <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
          <CustomTable
            dataTable={dataTable}
            tableColumns={tableColumns}
            fetchPage={setUrlFech}
            pagination={true}
            permiso={permiso}
            agregar={false}
            showEliminar={false}
            showEditar={false}
            showVer={true}
            select={true}
            setOrder={setOrder}
            order={order}
            registrosSeleccionados={registrosSeleccionados}
            handleVer={handleVer}
          />
          {tableLoading && (
            <div className="mt-10 mb-10">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {agregar && (
        <ModalGenerico
          title={"¿Desea aceptar las asignaciones seleccionadas?"}
          open={agregar}
          closeModal={() => { }}
          cerrar={() => {
            setAgregar(false);
          }}
          children={
            <div className="py-3">
              <BotonesConfirmar 
                aceptar={async ()=>{
                  setCargando(true);
                  await postAsignaciones(registros , token)
                  .then((data) => {
                    if (data?.status >= 200 || data?.status <= 210 ) {
                      toast.success('Asignaciones aceptadas con éxito');
                      setTimeout(() => {
                        setAgregar(false);
                      }, 1300);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                      setCargando(false);
                    } 
                  })
                  .catch(function (error) {
                    if (error.response.status >= 400 || error.response.status <= 410) {
                        setCargando(false);
                        toast.error(error.response.data.message);
                    } else if (error.response.status >= 500) {
                        setCargando(false);
                        toast.error('Error interno comuniquese con el administrador');
                    }
                  });
                }}
                cancelar={()=>{
                  setAgregar(false);
                }}
              />
              { cargando && <Spinner />}
            </div>
          }
        />
      )}
    </div>
  );
};
