import Spinner from "../../../components/common/Spinner";

export default function FormEliminar(props){
    return (
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4 max-w-lg">
            <div className="col-span-6"></div>
            <div className="py-3 text-center">
              <button
                className="inline-flex w-5/12 m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-red-400 hover:bg-maivi-secondaryRed focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                onClick={props.aceptar}
              >
                Si
              </button>
              <button
                className="inline-flex w-5/12 m-2  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium 
                rounded-md text-maivi-secondaryWhite bg-maivi-primaryBlue hover:bg-maivi-primaryLightBlue focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-maivi-secondaryBlue"
                onClick={props.cancelar}
              >
                No
              </button>
            </div>
            {(props.isloading === true) ? <Spinner /> : ''}
          </div>
        </div>
      </div>
    );
  };