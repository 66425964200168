import { types } from "../types/types";
import { SelectTipoFiltro } from "../actions/tipoFiltro";
export const TipoFiltro = (state = { tipo: SelectTipoFiltro() }, action) => {
    switch (action.type) {
        case types.TipoFiltro:
            return { tipo: action.payload }
        default:
            return state;
    }
}
