import { React, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { InputSiavi } from "../../../../components/common/InputText";
import Divider from "../../../../components/common/Divider";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumbTwo from "../../../../components/common/BreadCrumbTwo";
import Alert from "../../../../components/common/Alert";
import Label from "../../../../components/common/Label";
import { ButtonSiavi } from "../../../../components/common/Button";
import {  getProfesionales } from "../../services";
import { useSelector } from "react-redux";
import { RenderJasper } from "../../RenderJasper";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
const selectClass =
  "mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md";

export const Parametros = ({
  handleSubmit,
  isSubmitting,
  values,
  handleChange,
  hasError,
  success,
  mensaje
}) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const usuario = useSelector((state) => (state.loginReducer.data.usuario.user.first_name+" "+state.loginReducer.data.usuario.user.last_name));
  const animatedComponents = makeAnimated();
  const [sede, setSede] = useState(null);
  const [profesional, setProfesional] = useState(null);
  const [profesionalList, setProfesionalList] = useState([]);
  
  let data =[]

  const useFetchProfesionales = () => {
    const [state, setState] = useState({
      profesionales: [],
    });
    
    useEffect(() => {
      getProfesionales(token).then((data) => {
        setState({
          profesionales: data,
        });
      });
    }, []);

    return state;
  };

  const { profesionales } = useFetchProfesionales();
//Área 1 es para DSV y área 2 es para DAL
  {profesionales?.results?.map((item) => {
    if(item?.tipo_puesto?.sub_area_detalle?.area==2)
    {
      data.push({sedeId:item?.sede?.id,sede:item?.sede?.sede,profesionalId:item?.id,profesional:item.first_name+' '+item.last_name})
    }
  })}

  data.sort(function(a,b)
  {
    if (a.profesional>b.profesional)
    {
      return 1
    }
    if(a.profesional<b.profesional)
    {
      return -1
    }
    return 0
  })

  var groupBy = (arr, prop) =>{ 
    return arr.reduce((objs, obj) => {
        const sedeId = obj[prop];
        if (sedeId) {
            let fi = objs.findIndex(x => x.sedeId == sedeId);
            if (fi>=0) {
                objs[fi].profesionales = [...objs[fi].profesionales, {profesionalId:obj.profesionalId,profesional:obj.profesional}];
            } else {
                objs.push({
                    sedeId: sedeId,
                    sede:obj.sede,
                    profesionales: [{profesionalId:obj.profesionalId,profesional:obj.profesional}]
                })
            }
        }
        return objs;
    }, []);
}

 
  // handle change event of the sede dropdown
  const handleSedeChange = (obj) => {
    setSede(obj);
    setProfesionalList(obj.profesionales);
    setProfesional(null);
  };
  
  // handle change event of the profesional dropdown
  const handleProfesionalChange = (obj) => {
    setProfesional(obj);
  };

//console.log(groupBy(data,'sedeId'))
  const pages = [
    { name: "Reportes", href: "/reportes", current: false },
    { name: "Medición de Rendimiento DAL", href: "#", current: true },  ];

  return (
    <form autoComplete="off">
      <div className="lg:grid-cols-2 gap-12 sm:grid sm:grid-cols-1 sm:gap-12 md:grid lg:flex -mt-24 mb-10 justify-center items-start">
        <div className="shadow-xl sm:rounded-md px-6 py-6 bg-maivi-secondaryWhite">
          <div className="flex justify-center col-span-12">

          </div>
          <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-row mb-8">
            <BreadCrumbTwo navegacion={pages} />
          </div>

          <h1 className="mb-1 text-base">Reporte de Medición de Rendimiento Laboral</h1>
          <h2 className="block text-sm font-normal">Información del rendimioento laboral del profesional de la Dirección de Asistencia Legal</h2>
          <Divider />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
          <div className="grid grid-cols-1  lg:grid-cols-4 gap-4 lg:gap-8">
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha inicio:" />
              <InputSiavi
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                onChange={handleChange}
                value={values.fechaInicio}
              />
              <ErrorMessage
                name="fechaInicio"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
            <div className="sm:col-span-4 col-span-12">
              <Label text="Fecha Fin:" />
              <InputSiavi
                type="date"
                name="fechaFin"
                id="fechaFin"
                onChange={handleChange}
                value={values.fechaFin}
              />
              <ErrorMessage
                name="fechaFin"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>
           
            <div className="sm:col-span-4 col-span-12">
              <Label text="Sede" />
              <Select
          placeholder="Seleccione una sede"
          value={sede}
          options={groupBy(data,'sedeId')}
          onChange={handleSedeChange}
          getOptionLabel={x => x.sede}
          getOptionValue={x => x.sedeId}
          defaultValue={"0" || 'Todas'}
        />
        <br />
        <Label text="Profesionales" />
        <Select
         isMulti
         closeMenuOnSelect={false}
         components={animatedComponents}
          placeholder="Todos"
          value={profesional}
          options={profesionalList}
          onChange={handleProfesionalChange}
          getOptionLabel={x => x.profesional}
          getOptionValue={x => x.profesionalId}
          defaultValue={"0" || 'Todos'}
        />
              <ErrorMessage
                name="sedes"
                component="span"
                className="font-medium text-xs text-maivi-secondaryRed"
              />
            </div>

            <div className="sm:col-span-4 col-span-12 mt-0 mb-0 flex items-end justify-items-end">
              <ButtonSiavi
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
                text="Generar Reporte"
              />
            </div>

          </div>


            <div className="grid grid-cols-1 col-span-3">
              <div className="col-span-12">
                {isSubmitting && (
                  <Spinner />
                )}
                {hasError && (
                  <Alert
                    type="error"
                    mensaje={mensaje}
                  />
                )}
                {success && (
                  <><Alert
                  type="success"
                  mensaje={mensaje} /><RenderJasper
                  fechaInicio={values.fechaInicio}
                  fechaFin={values.fechaFin}
                  sedes={sede==null?0:sede.sedeId}      
                  profesionales={profesional==null?0:profesional}
                  usuario={usuario}
                  reporte={"/DAL/Metas"} />
                    </>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  );
};
