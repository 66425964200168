/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { FilterIcon, ScaleIcon } from "@heroicons/react/outline";
import { FormDetalle } from "./detalle";
import { referencias, getReferencias } from "./services";
import { store } from "../../../../app/redux/store/store";
import { ChangeSearch } from "../../../../app/redux/actions/search";
import { useSelector } from "react-redux";
import ModalGenerico from "../../../../components/common/Modal";
import Spinner from "../../../../components/common/Spinner";
import BreadCrumb from "../../../../components/common/BreadCrumb";
import CustomTable from "../../../../components/common/Table";
import ButtonAdd from "../../../../components/common/ButtonAdd";
import Filtros from "./filtros";

const tableColumns = [
    { title: "Caso", value: "caso.numero_caso", tipo: "text" },
    { title: "Fecha", value: "fecha_creacion", tipo: "date", ordenar: "fecha_creacion" },
    { title: "Codigo", value: "codigo_referencia", tipo: "text" },
    { title: "Fecha solicitud", value: "fecha_creacion_referencia_ts", tipo: "date" },
    {
        title: "Persona",
        value: null,
        values: [
            "persona.primer_nombre",
            "persona.segundo_nombre",
            "persona.primer_apellido",
            "persona.segundo_apellido"
        ],
        tipo: "text"
    },
    {
        title: "Usuario creacion",
        value: null,
        values: [
            "profesional_crea.first_name",
            "profesional_crea.last_name",
        ],
        tipo: "text",
        ordenar: "profesional_crea_referencia_ts__nombre"
    },
    {
        title: "Usuario solicita",
        value: null,
        values: [
            "profesional_crea_referencia_ts.first_name",
            "profesional_crea_referencia_ts.last_name",
        ],
        tipo: "text"
    },
    {
        title: "Asignado",
        value: "asignado",
        tipo: "boolean",
        text: { true: "Sí", false: "No" },
    },
    {
        title: "Servicio",
        value: "referencias_internas.nombre",
        tipo: "text"
    },
    {
        title: "Atención",
        value: "asistencia_atencion_especifica",
        tipo: "text"
    }
];

export const TableMisReferencias = () => {
    const token = useSelector((state) => state.loginReducer.data.token);
    const search = useSelector((state) => state.inputChange.search);
    const profesional = useSelector((state) => state.loginReducer?.data?.usuario?.user?.id);
    const [verDetalle, setDetalle] = useState(false);
    const [referenciasDetalle, setReferenciasDetalle] = useState({});
    const [urlFetch, setUrlFech] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [order, setOrder] = useState("");
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState("");
    const permiso = "referencias";

    const fetchReferencias = () => {
        setTableLoading(true);
        referencias(urlFetch, filters, order, profesional, token).then((data) => {
            setDataTable(data);
            setTableLoading(false);
        });
    };

    const fetchGetReferencias = () => {
        if (
            (search.fetch && search.value.length >= search.min) ||
            (!search.fetch && search.clean)
        ) {
            setTableLoading(true);
            getReferencias(urlFetch, search, filters, order, profesional, token)
                .then((data) => {
                    setDataTable(data);
                    setTableLoading(false);
                })
                .then(() => {
                    let value = {
                        value: search.value,
                        fetch: false,
                        clean: false,
                    };
                    store.dispatch(ChangeSearch({ ...search, ...value }));
                });
        }
    };

    useEffect(fetchReferencias, [urlFetch, token, order, filters, profesional]);
    useEffect(fetchGetReferencias, [urlFetch, search, filters, profesional, order, token]);

    const handleVer = (item) => {
        setReferenciasDetalle(item);
        setDetalle(true);
    };

    const pages = [
        {
            name: "Mis registros",
            href: "/mis-referencias",
            current: false,
        },
        { name: "Mis Referencias", href: "#", current: true },
    ];

    return (
        <div>
            <div className="flex flex-col justify-between lg:flex-row xl:flex-row md:flex-row sm:flex-">
                <BreadCrumb navegacion={pages} />
                <div>
                    <ButtonAdd
                        className="ml-2"
                        text="Filtros"
                        type="button"
                        onClick={() => {
                            setOpenFilters(true);
                        }}
                        icon={<FilterIcon className="ml-3 -mr-1 h-5" />}
                    />
                </div>
            </div>
            <div className="py-2 inline-block w-full">
                <div className="shadow border-b border-maivi-gray-10 sm:rounded-lg overflow-x-auto">
                    <CustomTable
                        dataTable={dataTable}
                        tableColumns={tableColumns}
                        handleVer={handleVer}
                        fetchPage={setUrlFech}
                        pagination={true}
                        permiso={permiso}
                        showVer={true}
                        showEditar={false}
                        showEliminar={false}
                        setOrder={setOrder}
                        order={order}
                    />
                    {tableLoading && (
                        <div className="mt-10 mb-10">
                            <Spinner />
                        </div>
                    )}
                    {verDetalle && (
                        <ModalGenerico
                            title={"Detalle de Referencia"}
                            open={verDetalle}
                            closeModal={() => { }}
                            cerrar={() => {
                                setDetalle(false);
                            }}
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <ScaleIcon className="h-6 w-6 text-maivi-primaryLightBlue" />
                                </div>
                            }
                            children={
                                <FormDetalle detalle={referenciasDetalle} />
                            }
                        />
                    )}
                    {openFilters && (
                        <ModalGenerico
                            title={`Aplicar filtros`}
                            open={openFilters}
                            closeModal={() => { }}
                            cerrar={() => {
                                setOpenFilters(false);
                            }}
                            children={
                                <Filtros
                                    setFilters={setFilters}
                                    setOpenFilters={setOpenFilters}
                                    token={token}
                                />
                            }
                            icon={
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <FilterIcon className="h-6 w-6 text-green-600" />
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
