import { React, useState, useEffect } from "react";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
  getDiscapacidadVictima,
  postPersonaDiscapacidad,
  deleteDiscapacidades,
} from "../victima/services";
import ModalGenerico from "../../../../../components/common/Modal";
import FormEliminar from "../../common/FormEliminar";

const useFetchDiscapacidades = (token) => {
  const [state, setState] = useState({
    discapacidades: [],
  });

  useEffect(() => {
    getDiscapacidadVictima(token).then((data) => {
      setState({
        discapacidades: data,
      });
    });
  }, [token]);

  return state;
};

export const Discapacidades = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { discapacidades } = useFetchDiscapacidades(token);
  const [ eliminar, setEliminar] = useState(false);
  const [ nombreEliminar, setNombreEliminar] = useState(false);
  const [ itemEliminar, setItemEliminar] = useState("");
  const [ eliminarDiscapacidad, setEliminarDiscapacidad] = useState(false);
  const [ idDiscapacidades, setIdDiscapacidades] = useState([]);

  const selectClass = `mt-1 shadow-sm py-2 px-3 border bg-white focus:ring-maivi-secondaryBlue 
  focus:border-maivi-secondaryBlue block w-full sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont`;
  const thClass =
    "px-6 py-1 text-left text-sm font-medium text-maivi-primaryFont tracking-wider";
  const buttonRemoveClass = `inline-flex items-center p-1 border border-transparent rounded-md ;
  text-maivi-primaryFont hover:text-white hover:bg-maivi-secondaryRed focus:ring-maivi-primaryLightBlue`;

  const deleteManys = (item) => {
    if (eliminarDiscapacidad) {
      if(props.values.editarPersona){
        deleteDiscapacidades(item.id, token);
      }
      let filtrar = props.values.discapacidades.filter((x) => x.id !== item.id);
      props.setFieldValue('discapacidades', filtrar);
    }
  };

  return (
    <div className="col-span-12 xl:col-span-6 lg:col-span-6 md:col-span-12 sm:col-span-12 border p-3 rounded-md shadow-sm hover:shadow-md">
      <h1 className="text-base">Tipo de discapacidad</h1>
      <div className="grid grid-cols-12 gap-2 items-end">
        <div className="lg:col-span-9 xl:col-span-8 sm:col-span-6 md:col-span-6 col-span-12 ">
          <select
            id="discapacidades"
            name="discapacidades"
            className={selectClass}
          >
            <option value="">Seleccionar</option>
            {discapacidades?.results?.map((item) => {
              return (
                <option key={item.nombre + "os"} value={item.id}>
                  {item.nombre}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-span-12 xl:col-span-4 lg:col-span-3 md:col-span-4 mt-1">
          <ButtonSiavi
            type="button"
            onClick={async () => {
              let combo = document.getElementById("discapacidades");
              let selected = combo.options[combo.selectedIndex].text;
              let selectedValue = combo.options[combo.selectedIndex].value;
              if (selected !== "Seleccionar") {
                if(props.values.editarPersona){
                  await postPersonaDiscapacidad(props?.values?.idPersona, selectedValue, token).then(
                    (data) => {
                     let result = data;
                     props.setFieldValue('discapacidades',[...props.values.discapacidades, result]);
                   }
                 );
                } else{
                  let arreglo = {
                    discapacidad_detalle:{
                      nombre: selected
                    },
                    id: Number(selectedValue)
                  } 
                 
                  setIdDiscapacidades([
                    ...idDiscapacidades,
                    Number(selectedValue)
                  ]);
                  props.setFieldValue('discapacidades', [...props?.values?.discapacidades, arreglo]);
                  props.setFieldValue('idsDiscapacidades',idDiscapacidades);
                }
              }
            }}
            text="Agregar"
          />
        </div>
        <div className="col-span-12 overflow-x-auto">
          <table className="w-full divide-y divide-maivi-gray-10 mt-2">
            <thead className="bg-maivi-gray-5">
              <tr>
                <th scope="col" className={thClass}>
                  Nombre
                </th>
                <th scope="col" className={thClass}>
                  Borrar
                </th>
              </tr>
            </thead>
            <tbody className="bg-maivi-secondaryWhite divide-y divide-maivi-gray-10">
              {props?.values?.discapacidades?.map((item) => (
                <tr
                  key={item.discapacidad?.nombre + item?.id}
                  className="bg-green-50"
                >
                  <td className="px-6 whitespace-nowrap">
                    <div className="text-xs text-maivi-primaryFont">
                      {item.discapacidad?.nombre}
                    </div>
                  </td>
                  <td className="px-6 whitespace-nowrap">
                    <div className="text-xs text-maivi-primaryFont">
                      <button
                        type="button"
                        onClick={() => {
                          setEliminar(true);
                          setItemEliminar(item);
                          setEliminarDiscapacidad(true);
                          setNombreEliminar(item.discapacidad?.nombre);
                        }}
                        className={buttonRemoveClass}
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {eliminar && (
            <ModalGenerico
              title={`¿Esta seguro de eliminar el registro ${nombreEliminar}?`}
              open={eliminar}
              closeModal={() => {}}
              cerrar={() => {
                setEliminar(false);
              }}
              icon={
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XCircleIcon className="h-6 w-6 text-maivi-secondaryDarkRed" />
                </div>
              }
              children={
                <FormEliminar
                  aceptar={() => {
                    deleteManys(itemEliminar);
                    setEliminar(false);
                  }}
                  cancelar={() => {
                    setEliminar(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
