import { Link } from "react-router-dom";
import { StarIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../app/redux/store/store";
import { ChangeFavoritos } from "../../app/redux/actions/favoritos";
import { saveFavoritos } from '../../helpers/utils';
import { DynamicIcon } from "../../components/common/DynamicIcon";

const menu = [
  {
    permiso: "Usuarios",
    name: "Mantemimiento usuarios",
    href: "/seguridad/usuarios",
    icon: 'UserGroupIcon',
  },
  {
    permiso: "Roles",
    name: "Creación de roles",
    href: "/seguridad/roles",
    icon: 'LockClosedIcon',
  },
  {
    permiso: "Roles",
    name: "Roles Vista",
    href: "/seguridad/roles-vista",
    icon: 'LockClosedIcon',
  },
  {
    permiso: "Bitacora",
    name: "Bitácora",
    href: "/seguridad/bitacora",
    icon: 'ViewListIcon',
  },
  {
    permiso: "Preguntas",
    name: "Preguntas",
    href: "/seguridad/preguntas",
    icon: 'ViewListIcon',
  },
];

export const Menu = () => {
  const menuFavoritos = useSelector(state => state.ChangeFavoritos.menus ? state.ChangeFavoritos.menus : []);
  const permisos = useSelector(state => state.ChangePermisos?.menus?.seguridad?.menus ? state.ChangePermisos?.menus?.seguridad?.menus: []);
  const idUser = useSelector(state => state?.loginReducer?.data?.usuario?.id);
  const [favoritos, setFavoritos] = useState(menuFavoritos);
  let history = useHistory();
  
  const markFavorite = (item) => {
    let menu = { ...item, ubicacion: item?.icon?.props?.ubicacion };
    menu.icon = item?.icon?.type?.name;
    let data = menuFavoritos;
    let find = true;
    data.forEach((mn, index) => {
      if (item.href === mn.href) {
        data.splice(index, 1)
        find = false;
      }
    })
    if (find) {
      data.push(menu)
    }
    store.dispatch(ChangeFavoritos(data));
    saveFavoritos(data, idUser);
    setFavoritos(data);
    history.push("/seguridad");
  }

  const isFavorite = (item) => {
    let retorno = false;
    favoritos.forEach(mn => {
      if (item.href === mn.href) {
        retorno = true;
      }
    })

    return retorno;
  }

  const checkPermiso = (item) => {
    let retorno = false;
   
    permisos.forEach(p => {
      if(p.text === item.permiso){
        retorno = p.ver;
      }
    })

    //retorno = true;
    return retorno;
  }

  return (
    <div>
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 m-0">
        {menu.map((item, index) =>
        checkPermiso(item) &&
          <li key={`${index}-seg`} className="flex shadow-lg rounded-md h-20">
            <div onDoubleClick={() => markFavorite(item)} className="cursor-pointer flex-shrink-0 flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md bg-maivi-primaryLightBlue">
              {isFavorite(item) === true && <StarIcon />}
            </div>
            <Link key={item.name} to={item.href} className="h-22 flex-1 flex flex-col items-center justify-between border border-maivi-primaryLightBlue bg-white rounded-r-md break-word hover:bg-maivi-secondaryBlueHover">
              <div className="flex flex-col items-center my-auto">
              <DynamicIcon icon={item.icon} ubicacion={item.ubicacion} />
                <p className="xl:text-xs lg:text-sm md:text-sm sm:text-sm text-gray-900 text-center font-medium">
                  {item.name}
                </p>
              </div>
            </Link>

          </li>
        )}
      </ul>
    </div>
  );
};
