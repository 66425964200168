import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Personas } from "./persona";
import { postCasoPersonaQuerellante, putCasoPersonaQuerellante, getConfiguracion } from "../services";
import { postPersona,putPersona } from "./services";
import toast from "react-hot-toast";

const useFetchConf = (token) => {
  const [state, setState] = useState({
    conf: [],
  });

  useEffect(() => {
    getConfiguracion(token).then((conf) => {
      setState({
        conf: conf,
      });
    });
  }, [token]);

  return state;
};

const validaciones = Yup.object().shape({
  //tipo_persona:Yup.string().required('tipo persona es requerido'),
  //relacion: Yup.string().required('relacion es requerida'),

  relacion: Yup.string().when('actualizarPersona',{
    is:false,
    then: Yup.string().required('relacion es requerida')
  }),

  tipo_documento: Yup.string().when('nueva_persona',{
    is:true,
    then: Yup.string().required('tipo documento es requerido')
  }),

  no_documento : Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required('numero de documento es requerido')
  }),

  pais: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required('pais es requerido')
  }),

  primer_nombre: Yup.string().when("nueva_persona",{
    is:true,
    then:Yup.string().required("primer nombre es requerido")
  }),

  primer_apellido: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("primer apellido es requerido")
  }),

  fecha_nacimiento: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("fecha nacimiento es requerida")
  }),

  sexo: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("sexo es requerido")
  }),

  // pseudonimo:Yup.string().when("nueva_persona",{
  //   is: true,
  //   then: Yup.string().required("este campo es requerido").max(30,'máximo 30 caracteres')
  // }),

  nacionalidad: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("nacionalidad es requerida")
  }),

  estado_civil: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("estado civil es requerido")
  }).nullable(),

/*   departamento: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("departamento es requerido")
  }).nullable(),

  municipio: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("municipio es requerido")
  }).nullable(), */

  idioma: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("idioma es requerido")
  }).nullable(),

  // idioma_preferencia: Yup.string().when("nueva_persona",{
  //   is:true,
  //   then: Yup.string().required("este campo es requerido")
  // }).nullable(),

  // idioma_maya: Yup.string().nullable().when("habilita_idioma_maya", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("idioma maya es requerido")
  // }),

  pais_origen: Yup.string().when("en_migracion",{
    is:true,
    then: Yup.string().required("pais origen es requerido")
  }).nullable(),
  
  pais_destino: Yup.string().when("en_migracion",{
    is:true,
    then: Yup.string().required("pais destino es requerido")
  }).nullable(),

  orientacion_sexual: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("orientacion sexual es requerida")
  }).nullable(),

  identidad_genero: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("identidad de genero requerida")
  }).nullable(),

  origen_etnico: Yup.string().when("nueva_persona",{
    is:true,
    then: Yup.string().required("origen etnico es requerido")
  }).nullable(),

  // origen_etnico_maya: Yup.string().nullable().when("habilita_origen_etnico", {
  //   is: "",
  //   then: Yup.string(),
  //   otherwise: Yup.string().required("origen etnico maya es requerido")
  // }),

/*   departamento: Yup.string().nullable().when("nacionalidad_default", {
    is: "",
    then: Yup.string(),
    otherwise: Yup.string().required("departamento es requerido")
  }),

  municipio: Yup.string().nullable().when("nacionalidad_default", {
    is: "",
    then: Yup.string(),
    otherwise: Yup.string().required("municipio es requerido")
  }), */
});

const customSwitchChange = (setEnabled, e) => {
  setEnabled(e);
};

const handleChangeInput = (values) => {
  let fechaNac = new Date(values.fecha_nacimiento);
  let yearNacimiento = fechaNac.getFullYear();
  let mesNacimiento = fechaNac.getMonth();
  const d = new Date();
  let yearActual = d.getFullYear();
  let edad = yearActual - yearNacimiento;
  let m = d.getMonth() - mesNacimiento;

  if (m < 0 || (m === 0 && d.getDate() < fechaNac.getDate())) {
    edad--;
  }

  if (values.fecha_nacimiento) {
    values.edad = edad;
  }
};

export const FormQuerellantes = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const { conf } = useFetchConf(token);
  const sede = useSelector((state) => state.ChangeSede.sedeId);
  const [hasError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const idCaso = props?.caso?.id;
  const caso = props?.caso;
  const delitos = props?.delitos;

  return (
    <Formik
      initialValues={{
        nueva_persona:false,
        tipo_documento:"",
        no_documento:"",
        pais:"",
        primer_nombre: "",
        segundo_nombre: "",
        otros_nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        apellido_casada: "",
        fecha_nacimiento: "",
        edad: 0,
        nacionalidad: "",
        pseudonimo: "",
        sexo: "",

        estado_civil: "",
        orientacion_sexual: "",
        identidad_genero:"",
        departamento: "",
        municipio: "",
        lee_escribe: "",
        escolaridad: "",
        profesion: "",
        religion: "",
        origen_etnico: "",
        idioma: "",
        idioma_preferencia:"",
        origen_etnico_maya:"",
        otro_idioma: "",
        en_migracion:false,
        correo_electronico: "",

        relacion_con_la_victima: "",
        tipo_persona:"",
        status_caso_persona: true,
        querellante: true,
        representante_legal: false,
        victima: "0",
        esVictima:false,
        es_victima_directa:false,
        remplazar_querellante:false,
        editarPersona: false,
        casoPersona:0,
        persona:0,
        busqueda:"",
        relacion:"",
        sede: sede,
        discapacidades:[],
        idsDiscapacidades: [],
        atenciones:[],
        idAtenciones :[],
        actualizarPersona:false,
        habilita_idioma_maya: conf.habilita_idioma_maya,
        habilita_origen_etnico: conf.habilita_origen_etnico,
        nacionalidad_default: conf.nacionalidad_default,
        victima_fallecida: false
      }}
      validateOnMount={true}
      validationSchema={validaciones}
      onSubmit={async (values,{resetForm}) => {
        values.sede = sede;
        if(values.nueva_persona){
          if(values.personaExiste){
            await putPersona(values,token);
            await postCasoPersonaQuerellante(values,idCaso,token).then(() =>{
              toast.success('Persona agregada con éxito..!');
              resetForm({});
            });
          } else {
            await postPersona(values,token).then(async(data) => {
              if (data?.status === 200 || data?.status === 201) {
                let id = data.data.id;
                values.persona = id;
                await postCasoPersonaQuerellante(values,idCaso,token).then(data =>{
                  toast.success('Persona agregada con éxito..!');
                  resetForm({});
                });
              } else {
                toast.success("Persona agregada con éxito..!");
              }
            }).catch(function (error) {
              if (
                error.response.status >= 400 &&
                error.response.status <= 450
              ) {
                toast.error(error.response.data.message);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
          }

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          await putPersona(values,token).then(async(data) =>{
            if(data?.status === 200 || data?.status === 201){
              await putCasoPersonaQuerellante(values,idCaso,values.casoPersona,token).then(async(data) => {
                if (data?.status === 200 || data?.status === 201) {
                  toast.success('Persona actualizada con éxito..!');
                  resetForm({});
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                } else {
                  resetForm({});
                  toast.success("Persona actualizada con éxito..!");
                }
              }).catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
            }
          }).catch(function (error) {
            if (
              error.response.status >= 400 &&
              error.response.status <= 450
            ) {
              toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
              toast.error("Error interno comuniquese con el administrador");
            }
          });
        }
      }}
    >
      {(props) => (
        <Personas
          {...props}
          customSwitchChange={customSwitchChange}
          customChange={handleChangeInput}
          hasError={hasError}
          success={success}
          mensaje={mensaje}
          setError={setError}
          setMensaje={setMensaje}
          setSuccess={setSuccess}
          caso={caso}
          delitos={delitos}
          configuracion={conf}
        />
      )}
    </Formik>
  );
};


