import { React, useState, useEffect } from "react";
import { Formik } from "formik";
import { InputSiavi } from "../../../../../components/common/InputText";
import { TextAreaSiavi } from "../../../../../components/common/TextArea";
import { ButtonSiavi } from "../../../../../components/common/Button";
import { useSelector } from "react-redux";
import { TrashIcon, DocumentAddIcon, PencilIcon, EyeIcon } from "@heroicons/react/outline";
import { bloqueoFecha } from "../../../../../helpers/utils";
import { SelectSiavi } from "../../../../../components/common/Select";
import { Detalle } from "./detalle";
import {
  postExpedienteSentenciaDetalle,
  getExpedienteSentenciaDetalle,
  deleteExpedienteSentenciaDetalle,
  putExpedienteSentenciaDetalle,
  getExpedienteSentencia,
  postExpedienteSentencia,
  deleteExpedienteSentencia,
  getInstituciones
} from "./services";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import TableSiavi from "../../../../../components/common/TableSiavi";
import Spinner from "../../../../../components/common/Spinner";
import Toggle from "../../../../../components/common/Toggle";
import Divider from "../../../../../components/common/Divider";
import Label from "../../../../../components/common/Label";
import NumberFormat from 'react-number-format';
import toast from "react-hot-toast";
import Alert from "../../../../../components/common/Alert";
import * as Yup from "yup";
import ModalGenerico from "../../../../../components/common/Modal";

const validaciones = Yup.object().shape({
  sindicado: Yup.string().required("sindicado es requerido"),
  meses_condena: Yup.number().max(11, "numero maximo 11 meses").nullable()
});

export const Sentencia = (props) => {
  const token = useSelector((state) => state.loginReducer.data.token);
  const idExpedienteProceso = props.registro.id;
  const idCaso = props.registro.caso;
  const animatedComponents = makeAnimated();
  const [cargando, setCargando] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [edit, setEdit] = useState(false);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [idSentencia, setIdSentencia] = useState(0);
  const [detalleSentencias, setSentenciasDetalle] = useState([]);
  const [expSentencias, setExpSentencias] = useState([]);
  const [instituciones,setInstituciones] = useState([]);
  const [error, setError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [verDetalle,setVerDetalle] = useState(false);
  const [registro,setRegistro] = useState({}); 
  const [value,setValue] = useState("");
  const [institucionselecionada,setInstitucionSeleccionada] = useState(0);
  let listadoInstituciones = [];
  const tdClass = "px-2 py-1 row-td text-maivi-primaryFont text-xs";

  const opciones = [
    {
      value: "1",
      title: "Reparación digna"
    },
    {
      value: "0",
      title: "Vía Civil"
    }
  ]

  useEffect(() => {
    getExpedienteSentencia(idExpedienteProceso, token).then((data) => {
      setExpSentencias(data);
      setCargando(false);
    });
  }, [idExpedienteProceso, setCargando, refetch, token]);

  useEffect(() => {
    getInstituciones(token).then((data) => {
      setInstituciones(data);
    });
  }, [token]);

  instituciones?.results?.forEach((item) => {
    let reg = {
      value: item.id,
      label: item.nombre_fiscalia
    };
    listadoInstituciones.push(reg);
  });

  const changeSelectInstitucion = (e) => {
    setInstitucionSeleccionada(e.value);
    setValue(e);
  };

  return (
    <div>
      <Formik
        initialValues={{
          anios_condena: null,
          meses_condena: null,
          sindicado: "",
          sentencia: "",
          sentencia_condenatoria: false,
          comentarios: "",
          expediente: props.registro.expediente.id,
          numero_sentencia: "",
          fecha_sentencia: "",
          reparacion_digna_via_penal: "",
          conmutable: false,
          descripcion: "",
        }}
        validationSchema={validaciones}
        onSubmit={async (values, { resetForm }) => {
          if (edit) {
            await putExpedienteSentenciaDetalle(values, token)
              .then(async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  getExpedienteSentenciaDetalle(idSentencia, token).then(
                    (data) => {
                      setSentenciasDetalle(data?.results);
                    }
                  );
                  toast.success("Sentencia detalle actualizada con éxito..!");
                  setRefetch(true);
                  resetForm({});
                  setEdit(false);
                  setNuevoRegistro(false);
                  setRefetch(false);
                } 
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });

          } else {
            await postExpedienteSentenciaDetalle(values, token)
              .then(async (data) => {
                if (data?.status === 200 || data?.status === 201) {
                  await getExpedienteSentenciaDetalle(idSentencia, token).then(
                    (data) => {
                      setSentenciasDetalle(data?.results);
                    }
                  );
                  toast.success("Sentencia detalle creada con éxito..!");
                  setNuevoRegistro(false);
                  setRefetch(true);
                  setRefetch(false);
                  resetForm({});
                } 
              })
              .catch(function (error) {
                if (
                  error.response.status >= 400 &&
                  error.response.status <= 450
                ) {
                  toast.error(error.response.data.message);
                } else if (error.response.status >= 500) {
                  toast.error("Error interno comuniquese con el administrador");
                }
              });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 xl:col-span-5 lg:col-span-5 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 xl:col-span-6 lg:col-span-4">
                    <InputSiavi
                      name="numero_sentencia"
                      onChange={handleChange}
                      value={values.numero_sentencia}
                      label="Numero sentencia"
                      validacion={true}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6 lg:col-span-4">
                    <InputSiavi
                      type="date"
                      name="fecha_sentencia"
                      onChange={handleChange}
                      value={values.fecha_sentencia}
                      maximo={bloqueoFecha()}
                      validacion={true}
                      label="Fecha sentencia"
                    />
                  </div>
                  <div className="col-span-12 mt-1">
                    <Label text="Juzgado que dicta sentencia" />
                    <Select
                      value={value}
                      placeholder="Seleccionar institucion"
                      components={animatedComponents}
                      className={`basic-multi-select text-xs text-maivi-primaryFont`}
                      classNamePrefix="seleccione"
                      options={listadoInstituciones}
                      onChange={changeSelectInstitucion}
                      maxMenuHeight={140}
                    />
                  </div>
                  <div className="col-span-12 flex flex-row items-center justify-center">
                    {opciones.map((item) => (
                      <div key={item.id} className="flex m-1 ml-5">
                        <input
                          name="reparacion_digna_via_penal"
                          type="radio"
                          onChange={handleChange}
                          value={item.value}
                          checked={values.reparacion_digna_via_penal === item.value ? true : false}
                          className="bg-maivi-primaryLightBlue h-7 w-7  border-gray-300"
                        />
                        <label className="ml-1 block text-sm font-medium text-maivi-primaryFont">
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                  {
                    (values.reparacion_digna_via_penal === "1") &&
                    <div className="col-span-12">
                      <TextAreaSiavi
                        label="Descripción"
                        rows={2}
                        name="descripcion"
                        value={values.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                  }
                  <div className="col-span-12 xl:col-span-6 lg:col-span-4 flex items-end justify-end">
                    <ButtonSiavi
                      type="button"
                      text="Crear sentencia"
                      onClick={async () => {
                        values.juzgado = institucionselecionada;
                        if (values.reparacion_digna_via_penal === "") {
                          setError(true);
                          setMensaje("Debe elegir si es reparación digna o via civil");
                          setTimeout(() => {
                            setError(false);
                          }, 4000);
                        } else {
                          setCargando(true);
                          await postExpedienteSentencia(values, idCaso, idExpedienteProceso, token)
                            .then(async (data) => {
                              if (data?.status === 200 || data?.status === 201) {
                                toast.success("Sentencia creada con éxito..!");
                                setRefetch(true);
                                setRefetch(false);
                                resetForm({});
                                setCargando(false);
                              } 
                            })
                            .catch(function (error) {
                              if (
                                error.response.status >= 400 &&
                                error.response.status <= 450
                              ) {
                                setCargando(false);
                                toast.error(error.response.data.message);
                              } else if (error.response.status >= 500) {
                                setCargando(false);
                                toast.error("Error interno comuniquese con el administrador");
                              }
                            });
                        }
                      }}
                    />
                  </div>
                  {
                    error &&
                    <div className="col-span-12 mt-2">
                      <Alert type="error" mensaje={mensaje} />
                    </div>
                  }
                </div>
                <TableSiavi 
                  cargando={cargando}
                  headers={[
                    'Expediente',
                    'Numero sentencia',
                    'Fecha sentencia',
                    'Acciones'
                  ]}
                  body= {expSentencias?.results?.map((item) => (
                    <tr
                      className="hover:bg-maivi-gray-5 cursor-pointer"
                      onClick={() => {
                        getExpedienteSentenciaDetalle(item.id, token).then(
                          (data) => {
                            setSentenciasDetalle(data?.results);
                          }
                        );
                        resetForm({});
                        setEdit(false);
                        setIdSentencia(item.id);
                        setFieldValue('expediente_sentencia', item?.id);
                      }}
                    >
                      <td className={tdClass}>{item?.expediente?.numero_expediente}</td>
                      <td className={tdClass}>{item?.numero_sentencia}</td>
                      <td className={tdClass}>{item?.fecha_sentencia}</td>
                      <td className={tdClass}>
                        <button
                          type="button"
                          onClick={async () => {
                            setCargando(true);
                            await deleteExpedienteSentencia(item.id, token)
                              .then((data) => {
                                if (data?.status === 200 || data?.status === 201) {
                                  setCargando(false);
                                  toast.success("Registro eliminado con éxito...!");
                                  setRefetch(true);
                                  setRefetch(false);
                                }
                              })
                              .catch(function (error) {
                                if (
                                  error.response.status === 400 ||
                                  error.response.status === 401
                                ) {
                                  setCargando(false);
                                  toast.error(error.response.data.message);
                                } else if (error.response.status >= 500) {
                                  toast.error(
                                    "Error interno comuniquese con el administrador"
                                  );
                                }
                              });
                          }}
                          className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                          rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                        >
                          <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setNuevoRegistro(true);
                            setIdSentencia(item.id);
                            setFieldValue('expediente_sentencia', item?.id);
                            setEdit(false);
                          }}
                          className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                        >
                          <DocumentAddIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setRegistro(item);
                            setVerDetalle(true);
                          }}
                          className="hover:bg-maivi-primaryLightBlue  inline-flex items-center p-1 border border-transparent rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue "
                        >
                          <EyeIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div className="col-span-12 xl:col-span-7 lg:col-span-7 border p-5 rounded-md shadow-sm hover:shadow-md mt-4">
                <Label text="Agregar detalle de sentencias" />
                <Divider />
                {
                  nuevoRegistro &&
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-6">
                      <SelectSiavi 
                        label="*Sindicado"
                        validacion={true}
                        name="sindicado"
                        value={values.sindicado}
                        onChange={handleChange}
                        valueInit=""
                        selectInit="Elegir sindicado"
                        list= {props.sindicados?.results?.map((item) => {
                          return (
                            <option
                              key={
                                item?.expediente_sindicado?.sindicado
                                  ?.primer_nombre + item.id
                              }
                              value={item.id}
                            >
                              {`${item?.expediente_sindicado?.sindicado?.primer_nombre} ${item?.expediente_sindicado?.sindicado?.primer_apellido} - ${item?.delito?.nombre}`}
                            </option>
                          );
                        })}
                      />
                    </div>
                    <div className="col-span-3 flex flex-col justify-center items-center">
                      <Label text={(values.sentencia_condenatoria) ? "Sentencia condenatoria" : "Sentencia absolutoria"} />
                      <Toggle
                        enabled={values.sentencia_condenatoria}
                        name="sentencia_condenatoria"
                      />
                    </div>
                    <div className="col-span-3 flex flex-col justify-center items-center">
                      <Label text={(values.conmutable) ? "Conmutable" : "Inconmutable"} />
                      <Toggle
                        enabled={values.conmutable}
                        name="conmutable"
                      />
                    </div>
                    {
                      values.sentencia_condenatoria &&
                      <div className="col-span-6">
                        <Label text="Años condena" />
                        <NumberFormat
                          name="anios_condena"
                          thousandSeparator={false}
                          value={values.anios_condena}
                          onChange={handleChange}
                          allowNegative={false}
                          className="mt-1 shadow-sm py-2 px-3 border focus:outline-none focus:ring-2 
                        focus:ring-offset-maivi-primaryLightBlue focus:border-transparent block w-full 
                        sm:text-sm border-maivi-gray-10 rounded-md text-maivi-primaryFont"
                        />
                      </div>
                    }
                    {
                      values.sentencia_condenatoria &&
                      <div className="col-span-6">
                        <InputSiavi
                          name="meses_condena"
                          value={values.meses_condena}
                          onChange={handleChange}
                          validacion={true}
                          label="Meses"
                        />
                      </div>
                    }
                    <div className="col-span-12">
                      <TextAreaSiavi
                        label="Comentarios"
                        rows={2}
                        name="comentarios"
                        value={values.comentarios}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="xl:col-span-8 lg:col-span-8 md:col-span-8">
                    </div>
                    <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4">
                      <ButtonSiavi
                        type="submit"
                        disabled={isSubmitting}
                        text={(edit) ? 'Actualizar' : 'Guardar'}
                      />
                    </div>
                  </div>
                }
                <TableSiavi 
                  headers={[
                    'Sindicado',
                    'Delito',
                    'Sentencia condenatoria',
                    'Acciones'
                  ]}
                  body={detalleSentencias?.map((item) => (
                    <tr className="hover:bg-maivi-gray-5 cursor-pointer">
                      <td className={tdClass}>{`${item?.exp_sindicado_delito?.expediente_sindicado?.sindicado.primer_nombre} ${item?.exp_sindicado_delito?.expediente_sindicado?.sindicado.primer_apellido}`}</td>
                      <td className={tdClass}>{item?.exp_sindicado_delito?.delito?.nombre}</td>
                      <td className={tdClass}>{(item?.sentencia_condenatoria) ? 'Si' : 'No'}</td>
                      <td className={tdClass}>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue('anios_condena', item?.anios_condena);
                            setFieldValue('conmutable', item?.conmutable);
                            setFieldValue('meses_condena', item?.meses_condena);
                            setFieldValue('sentencia_condenatoria', item?.sentencia_condenatoria);
                            setFieldValue('comentarios', item?.comentarios);
                            setFieldValue('sindicado', item.exp_sindicado_delito.id);
                            setFieldValue('idExpSentenciaDetalle', item?.id);
                            setFieldValue('expediente_sentencia', item?.expediente_sentencia);
                            setEdit(true);
                            setNuevoRegistro(true);
                          }}
                          className={`hover:bg-maivi-primaryLightBlue items-center p-1 border border-transparent 
                           rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                        >
                          <PencilIcon className="h-6 w-6 text-maivi-primaryFont hover:text-white" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            deleteExpedienteSentenciaDetalle(item.id, token).then(() => {
                              getExpedienteSentenciaDetalle(idSentencia, token).then((data) => {
                                setSentenciasDetalle(data?.results);
                              });
                              toast.success('registro eliminado con éxito');
                            });
                          }}
                          className={`hover:bg-maivi-secondaryRed items-center p-1 border border-transparent 
                           rounded-md text-maivi-gray-50 hover:text-white  focus:ring-maivi-primaryLightBlue`}
                        >
                          <TrashIcon className="h-6 w-6  text-maivi-secondaryRed hover:text-white" />
                        </button>
                      </td>
                    </tr>
                  ))}
                />
              </div>
            </div>
            {isSubmitting && <Spinner />}
          </form>
        )}
      </Formik>
      {verDetalle && (
          <ModalGenerico
            title={"Detalle"}
            open={verDetalle}
            closeModal={() => { }}
            cerrar={() => {
              setVerDetalle(false);
            }}
            children={
             <Detalle 
              registro={registro}
             />
            }
          />
        )}
    </div>
  );
};
