import axios from "axios";

const {
    REACT_APP_API_URL,
    REACT_APP_BITACORA_URL
} = process.env;

export const getRoles = async (urlPage, token) => {
    try {
        let urlFetch = urlPage === undefined ? `${REACT_APP_API_URL}/seguridad/rolvista/?page_size=10` : urlPage;

        let result = await fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const postPermisos = async (id, permisos) => {
    try {
        let body = {
            rolId: id,
            reportes:permisos.reportes,
            registros: permisos.registros,
            seguridad: permisos.seguridad,
            atencionvictimologica: permisos.atencionvictimologica,
            asistencialegal: permisos.asistencialegal,
            menus: permisos.menus,
            misregistros: permisos.misregistros
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_BITACORA_URL}/v1/permiso`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const getPermisos = async (id) => {
    try {
        let result = await fetch(`${REACT_APP_BITACORA_URL}/v1/permiso/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(data => data.json());

        return result;

    } catch (e) {
        console.log('error', e);
    }
};

export const getRol = async (id, token) => {
    try {
        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/rolvista/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(data => data.json());

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};


export const postRol = async (nombre, token) => {
    try {
        let body = {
            nombre: nombre
        }

        let Bodyresult = axios({
            method: 'POST',
            url: `${REACT_APP_API_URL}/seguridad/rolvista/`,
            data: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const putRol = async (values, id, token) => {
    try {
        let body = values

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/rolvista/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};

export const deleteRol = async (id, token) => {
    try {

        let Bodyresult = await fetch(`${REACT_APP_API_URL}/seguridad/rolvista/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        return Bodyresult;

    } catch (e) {
        console.log('error', e);
    }
};