import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getAudiencias = async (idPro,urlPage, search,tipo,filters,order, token) => {
  try {
    let urlFetch = urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/${tipo}/?search=${search.value}&es_audiencia=true&profesional_asignado=${idPro}&ordering=${order}&${filters}`
        :`${urlPage}&search=${search.value}&es_audiencia=true&profesional_asignado=${idPro}&ordering=${order}&${filters}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(data => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const audiencias = async (idPro,urlPage,tipo,filters,order, token) => {
  try {
    let urlFetch =
      urlPage === undefined
        ? `${REACT_APP_API_URL}/registro/${tipo}/?es_audiencia=true&profesional_asignado=${idPro}&${filters}&ordering=${order}`
        : `${urlPage}&${filters}&es_audiencia=true&profesional_asignado=${idPro}&ordering=${order}`;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const exportarData = async (filters,order, token) => {
  try {
    let urlFetch = `${REACT_APP_API_URL}/registro/accionesseguimientoscasolist/?es_audiencia=true&${filters}&ordering=${order}` ;
    let result = await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => data.json());

    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getModalidades = async(token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/catalogos/modalidad/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getAccionesSeguimiento = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/accionesseguimientos/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getSedes = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/catalogos/sedes/?page_size=1000&estado=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getProfesionales = async (token) => {
  try {
      let urlFetch =  `${REACT_APP_API_URL}/seguridad/registrar/?page_size=1000&estado=true&is_active=true&usuario_interno=true&ordering=asignaciones`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const postAsignaciones = async (profesional, expedientes,token) => {
  try {
      let ArregloExpedientes = [];
      expedientes.forEach(element => {
        ArregloExpedientes.push(element.id);
      });

      let body ={
        profesional: profesional.value,
        expedientes:ArregloExpedientes
      }

      let result = axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/registro/asignacionexpedientes/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getResumen = async(tipo,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/resumenaccionescaso/?${tipo}=true`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const putAcciones = async (id,body,token) => {
  try {
      let result = axios({
          method: 'PUT',
          url: `${REACT_APP_API_URL}/registro/accionesseguimientoscaso/${id}/`,
          data: JSON.stringify(body),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          },
      });

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getHistoricoFechas = async(id,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/casoseguimientoaccionhistoricoplanificacion/?caso_seguimiento_accion=${id}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getHistoricoAsignaciones = async(id,token) => {
  try {
      let urlFetch = `${REACT_APP_API_URL}/registro/historicoasignaciones/?accion_seguimiento_caso=${id}`;
      let result = await fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};

export const getInstitucionGestion = async (token) => {
  try {
      let result = await fetch(`${REACT_APP_API_URL}/catalogos/gestioninstitucion/?page_size=3000&estado=true&institucion__tiene_materia=true`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': token
          }
      }).then(data => data.json());

      return result;

  } catch (e) {
      console.log('error', e);
  }
};